import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class OrgActiveConfirmPop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Business: [],
    };
    this.PutStatusToOne = this.PutStatusToOne.bind(this);
  }

  PutStatusToOne(event) {
    event.preventDefault();
    fetch(`${BASE_URLs}AddOrganization/Put1`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: JSON.stringify({
        Status: "1",
        company_Id: this.props.company_Id,
        Email: this.props.Email,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.toString() === "The Entity is Activated Successfully") {
          toast.success(result, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setTimeout(function () {
            document.getElementById("Dash").click();
          }, 4000);
        } else {
          toast.error(result, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        document.getElementById("close").click();
      });
  }
  render() {
    return (
      <>
        <Link id="Dash" style={{ display: "none" }} to="DashBoard" />
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div
            className="popinnernormal"
            style={{ width: "50%", padding: "2%" }}
          >
            {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}}  ><CloseIcon/></button> */}
            <h5>
              Do you confirm all the details provided by{" "}
              <b> {this.props.company_Name}</b> are correct ?
            </h5>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <Link
                  onClick={this.PutStatusToOne}
                  type="button"
                  className="btn"
                >
                  Yes
                </Link>
                &nbsp;&nbsp;&nbsp;
                <button id="close" className="btn" onClick={this.props.close}>
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
