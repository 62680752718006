import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class PanConfirmpop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Business: [],
    };
  }

  CheckPAN(company_Id) {
    fetch(`${BASE_URLs}AddOrganization/verifypan`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company_Id: company_Id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          document.getElementById("nobtn").click();
        },
        (error) => {}
      );
  }
  render() {
    var w = window.innerWidth;

    return (
      <>
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "2%" }}
          >
            {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}}  ><CloseIcon/></button> */}
            <h5>You are verifying PAN?</h5>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <Link
                  onClick={() => this.CheckPAN(this.props.companyId)}
                  type="button"
                  className="btn"
                >
                  Yes
                </Link>
                &nbsp;&nbsp;&nbsp;
                <button id="nobtn" className="btn" onClick={this.props.close}>
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
