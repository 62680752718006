import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import SuccessPopup1 from "./SuccessPopup1";
import { BASE_URLs } from "../Config/Base";
export default class SearchPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Announcement: [],
      AllAnnouncement: [],
      showResults: false,
      EditSkillValues: [],
      id: "",
      firstName: "",
      allmessage: "",
      usermsgId: "",
      receivemsg: "",
      email: "",
      displayName: "",
      isButtonVisible: true,
    };
    this.initialState = this.state;
  }
  GetAnnouncement(AdminEmail) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };

    fetch(`${BASE_URLs}UserBank/OatUser?AdminEmail=` + AdminEmail, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          AllAnnouncement: data,
          Announcement: data,
          showResults: true,
        });
        // console.log("AdminEmail", data);
      })
      .catch((error) => {
        console.error("Error fetching user messages:", error);
      });
  }
  myFunction = (e) => {
    const Announcement = this.state.AllAnnouncement.filter((item) =>
      item.firstName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Announcement, showResults: true });
  };

  componentDidMount() {
    this.GetAnnouncement(this.props.values.email);
  }

  EditSkillModel = (e, fn, al, uid, rmsg, em, mb) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditSkillValues: {
        id: e,
        firstName: fn,
        allmessage: al,
        usermsgId: uid,
        receivemsg: rmsg,
        email: em,
        displayName: mb,
      },
    });
    this.setState((prevState) => ({
      EditSkillModel: !prevState.EditSkillModel,
    }));
  };

  formclicking() {
    this.setState({
      isButtonVisible: false,
    });
  }

  backentity() {
    document.getElementById("searchpop").style.display = "none";
    setTimeout(() => {
      document.getElementById("EntityList").style.display = "block";
    }, 5);
  }
  render() {
    var w = window.innerWidth;
    const { Announcement, showResults, isButtonVisible } = this.state;
    return (
      <>
        {this.state.EditSkillModel ? (
          <div className="row">
            <div className="col-lg-6">
              <SuccessPopup1
                id=""
                values={this.state.EditSkillValues}
                close={() => this.EditSkillModel()}
              />
            </div>
          </div>
        ) : null}
        {isButtonVisible && (
          <div
            className="pop"
            style={{
              backgroundColor: "transparent",
              left: "38%",
              marginTop: "-65px",
            }}
            id="searchpop"
          >
            <div>
              <ul className="text-right boxs">
                <li
                  className="chat-single-box card-shadow bg-white active"
                  data-id="1"
                  style={{ width: "340px" }}
                >
                  <div className="had-container">
                    <div>
                      {/* <span defaultValue={this.props.values.company_Name}>{this.props.values.company_Name}</span> */}
                    </div>
                    <div className="chat-header p-10 bg-white">
                      <div className="box-tools d-inline-block">
                        <button
                          style={{
                            float: "right",
                            backgroundColor: "#a0a3a305",
                            color: "#797070",
                            border: "1px solid #a3a5a505",
                          }}
                          onClick={this.backentity}
                        >
                          <CloseIcon />
                        </button>
                      </div>
                      <input
                        type="text"
                        className="form-control  search-text"
                        placeholder="Search"
                        // onChange={this.myFunction}
                      />
                    </div>
                    <div
                      className="chat-body p-10"
                      style={{ overflow: "scroll", height: "505px" }}
                    >
                      {showResults && (
                        <>
                          {Announcement.map((item, AdminEmail) => (
                            <>
                              <div
                                className="media userlist-box"
                                data-id="1"
                                data-status="online"
                                data-toggle="tooltip"
                                data-placement="left"
                                key={item.AdminEmail}
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.EditSkillModel(
                                    item.id,
                                    item.firstName,
                                    item.allmessage,
                                    item.usermsgId,
                                    item.receivemsg,
                                    item.email,
                                    item.displayName
                                  );
                                  this.formclicking();
                                }}
                              >
                                <a className="media-left">
                                  <img
                                    className="media-object img-circle"
                                    src="assets/images/avatar-1.png"
                                    alt="Generic placeholder image"
                                    style={{
                                      width: "32px",
                                      marginTop: "8px",
                                      marginLeft: "2px",
                                    }}
                                  />
                                </a>
                                <div
                                  className="media-body"
                                  style={{ textAlign: "initial" }}
                                >
                                  <div className="row">
                                    <div className="col-lg-8">
                                      <div
                                        className="f-13 chat-header"
                                        style={{
                                          backgroundColor: "transparent",
                                        }}
                                      >
                                        {" "}
                                        {/* <span style={{ color: "Gray" }}>{Announcement.teamId}</span>&nbsp; */}
                                        <span
                                          style={{
                                            color: "black",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {item.firstName}
                                        </span>
                                        <br />
                                        <span
                                          style={{
                                            color: "Gray",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {item.email}
                                        </span>
                                      </div>
                                    </div>
                                    &nbsp;&nbsp;&nbsp;
                                    <center>
                                      <div
                                        style={{
                                          backgroundColor: "rgb(156, 145, 244)",
                                          height: "22px",
                                          width: "47px",
                                          marginTop: "13px",
                                        }}
                                      >
                                        <Link
                                          style={{
                                            color: "white",
                                            marginTop: "5px",
                                          }}
                                        >
                                          Add
                                        </Link>
                                      </div>
                                    </center>
                                  </div>
                                </div>
                                <br />
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </>
    );
  }
}
