import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base";
export default class DesignationDash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      Pending: [],
      comlogo: [],
      Notification: [],
    };
  }
  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }

  componentDidMount() {
    this.GetOrgLogo();
    this.Getpending();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }
  Getall() {
    fetch(`${BASE_URLs}AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }
  Getpending() {
    fetch(`${BASE_URLs}AddOrganization/pendingcount`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Pending: data });
      });
  }

  render() {
    return (
      <div>
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                {/*  Start */}
                <div className="page-body">
                  <div className="row">
                    <div className="col-md-12 col-xl-4">
                      <div className="card counter-card-1">
                        <div className="card-block-big">
                          <div>
                            <h3>500</h3>
                            <p>people are in vacation</p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-pink"
                                role="progressbar"
                                style={{ width: "70%" }}
                                aria-valuenow={70}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-comment" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4">
                      <div className="card counter-card-2">
                        <div className="card-block-big">
                          <div>
                            <h3>2500</h3>
                            <p>Teams per Month</p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-success"
                                role="progressbar"
                                style={{ width: "50%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-coffee-mug" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4">
                      <div className="card counter-card-3">
                        <div className="card-block-big">
                          <div>
                            <h3>200</h3>
                            <p>ABC</p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-default"
                                role="progressbar"
                                style={{ width: "90%" }}
                                aria-valuenow={90}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-upload" />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-xl-8">
                      <div className="card">
                        <div className="card-block">
                          <figure className="pie-chart">
                            <figcaption>
                              HR
                              <span style={{ color: "#4e79a7" }} />
                              <br />
                              CEO
                              <span style={{ color: "#f28e2c" }} />
                              <br />
                              Team Leader
                              <span style={{ color: "#e15759" }} />
                              <br />
                              SEO
                              <span style={{ color: "#76b7b2" }} />
                              <br />
                              Tester
                              <span style={{ color: "#59a14f" }} />
                              <br />
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-xl-4">
                      <div className="card table-card widget-primary-card">
                        <div className="row-table">
                          <div className="col-sm-3 card-block-big">
                            <i className="icofont icofont-star" />
                          </div>
                          <div className="col-sm-9">
                            <h4>4000 +</h4>
                            <h6>Ratings Received</h6>
                          </div>
                        </div>
                      </div>
                      <div className="card table-card widget-success-card">
                        <div className="row-table">
                          <div className="col-sm-3 card-block-big">
                            <i className="icofont icofont-trophy-alt" />
                          </div>
                          <div className="col-sm-9">
                            <h4>17</h4>
                            <h6>Achievements</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}

                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}
