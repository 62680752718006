import React, { Component } from "react";
import $ from "jquery";
import "../Css/Sign-Up.css";
import LogoCompo from "../Common/LogoCompo";
import RegMsgPop from "../Common/RegMsgPop";
import LoadingPage from "../Common/LoadingPage";
import { BASE_URLs } from "../Config/Base";
import { Image_URLs } from "../Config/Base";
import CryptoJS from 'crypto-js';

$(function () {
  $("#noSpace").on("keypress", function (e) {
    if (e.which === 32) {
      return false;
    }
  });
});
$(document).ready(function () {
  $("#Name").bind("copy paste", function (e) {
    e.preventDefault();
  });
});
$(document).ready(function () {
  $("#EntityName").bind("copy paste", function (e) {
    e.preventDefault();
  });
});
$(document).ready(function () {
  $("#reguser").hide();
});
export default class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Name: "",
      username: "",
      EntityName: "",
      Email: "",
      Password: "",
      cpassword: "",
      formErrors: {},
      SuccessModel: false,
      Eye: false,
      Eye1: false,
      LinkStatus: "",
      RegData: {},
      loading: false,
    };
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  getUnique = (array, key) => {
    if (typeof key !== "function") {
      const property = key;
      key = function (item) {
        return item[property];
      };
    }
    return Array.from(
      array
        .reduce(function (map, item) {
          const k = key(item);
          if (!map.has(k)) map.set(k, item);
          return map;
        }, new Map())
        .values()
    );
  };

  handleChange = (e) => {
    var entityname = document.getElementById("EntityName").value;
    var username = document.getElementById("Name").value;
    var email = document.getElementById("Email").value;
    var password = document.getElementById("password").value;
    var cpassword = document.getElementById("cpassword").value;
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let formErrors = {};
    let formIsValid = true;
    //Entityname

    //Entityname
    if (!entityname || entityname.trim() == "") {
      formIsValid = false;
      formErrors["entitynameErr"] = "Entity Name is required";
    } else if (entityname.length < 3) {
      formIsValid = false;
      formErrors["entitynameErr"] = "A minimum of 3 characters are required";
    }
    //User name
    else if (!username || username.trim() == "") {
      formIsValid = false;
      formErrors["usernameErr"] = "User Name is required";
    } else if (username.length < 3) {
      formIsValid = false;
      formErrors["usernameErr"] = "A minimum of 3 characters are required";
    }

    //Email
    else if (!email || email.trim() == "") {
      formIsValid = false;
      formErrors["emailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      formIsValid = false;
      formErrors["emailErr"] = "Invalid Email Id";
    } else if (!password || password.trim() == "") {
      formIsValid = false;
      formErrors["passwordErr"] = " Password is required";
    } else if (!/^(?=.*[A-Z])/.test(password)) {
      formIsValid = false;
      formErrors["passwordErr"] = "At least one Capital letter is  required";
    } else if (!/^(?=.*[a-z])/.test(password)) {
      formIsValid = false;
      formErrors["passwordErr"] = "At least one small letter is  required";
    } else if (!/\d/.test(password)) {
      formIsValid = false;
      formErrors["passwordErr"] = "At least one number is required";
    } else if (!/^(?=.*[-+_!@#$%^&*., ?])/.test(password)) {
      formIsValid = false;
      formErrors["passwordErr"] = "At least one Symbol is required";
    } else if (password.length < 8) {
      formIsValid = false;
      formErrors["passwordErr"] = "A minimum of 8 characters are required";
    } else if (!cpassword || cpassword.trim() == "") {
      formIsValid = false;
      formErrors["cpasswordErr"] = "Confirm Password is required";
    } else if (password !== cpassword) {
      /* else if(!(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(cpassword))){ 
  formIsValid = false;    
  formErrors["cpasswordErr"] = "Invalid Confirm Password."; 
} */
      formIsValid = false;
      formErrors["cpasswordErr"] = "Password and Confirm Password must be same";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  handleFormValidation() {
    var entityname = document.getElementById("EntityName").value;
    var username = document.getElementById("Name").value;
    var email = document.getElementById("Email").value;
    var password = document.getElementById("password").value;
    var cpassword = document.getElementById("cpassword").value;

    let formErrors = {};
    let formIsValid = true;

    //Entityname
    if (!entityname || entityname.trim() === "") {
      formIsValid = false;
      formErrors["entitynameErr"] = "Entity Name is required";
    } else if (entityname.length < 3) {
      formIsValid = false;
      formErrors["entitynameErr"] = "A minimum of 3 characters are required";
    } else if (entityname.charAt(0) === " " || entityname.charAt(entityname.length - 1) === " ") {
      formIsValid = false;
      formErrors["entitynameErr"] = "First and last characters cannot be spaces";
    }
    //User name
    if (!username || username.trim() == "") {
      formIsValid = false;
      formErrors["usernameErr"] = "User Name is required";
    } else if (username.length < 3) {
      formIsValid = false;
      formErrors["usernameErr"] = "A minimum of 3 characters are required";
    }
    else if (username.charAt(0) === " " || username.charAt(username.length - 1) === " ") {
      formIsValid = false;
      formErrors["usernameErr"] = "First and last characters cannot be spaces";
    }
    //Email
    if (!email || email.trim() == "") {
      formIsValid = false;
      formErrors["emailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      formIsValid = false;
      formErrors["emailErr"] = "Invalid Email Id";
    }
    if (!password || password.trim() == "") {
      formIsValid = false;
      formErrors["passwordErr"] = "Password is required";
    } else if (!/^(?=.*[A-Z])/.test(password)) {
      formIsValid = false;
      formErrors["passwordErr"] = "At least one Capital letter is required";
    } else if (!/^(?=.*[a-z])/.test(password)) {
      formIsValid = false;
      formErrors["passwordErr"] = "At least one small letter is required";
    } else if (!/\d/.test(password)) {
      formIsValid = false;
      formErrors["passwordErr"] = "At least one number is required";
    } else if (!/^(?=.*[-+_!@#$%^&*., ?])/.test(password)) {
      formIsValid = false;
      formErrors["passwordErr"] = "At least one Symbol  is required";
    } else if (password.length < 8) {
      formIsValid = false;
      formErrors["passwordErr"] = "A minimum of 8 characters are required";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(
        password
      )
    ) {
      formIsValid = false;
      formErrors["passwordErr"] = "Invalid Password";
    }

    if (!cpassword || cpassword.trim() == "") {
      formIsValid = false;
      formErrors["cpasswordErr"] = "Confirm Password is required";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(
        cpassword
      )
    ) {
      formIsValid = false;
      formErrors["cpasswordErr"] = "Invalid Password";
    } else if (password !== cpassword) {
      /* else if(!(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(cpassword))){
  formIsValid = false;    
  formErrors["cpasswordErr"] = "Invalid Confirm Password."; 
} */
      formIsValid = false;
      formErrors["cpasswordErr"] = "Password and Confirm Password must be same";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  Entity() {
    document.getElementById("reguser").setAttribute("style", " display:none;");
    document
      .getElementById("entity")
      .setAttribute("style", " display: contents;");
  }
  User() {
    document.getElementById("entity").setAttribute("style", " display:none;");
    document
      .getElementById("reguser")
      .setAttribute("style", "display: contents;");
  }

  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));

    var page = {
      pathname: "/AdminOtp",
      state: this.state.RegData,
    };
    this.props.history.push(page);
  };

  componentDidMount() {
    let encryptedData = sessionStorage.getItem('encryptedData');
  
    // console.log("encryptdata ----- 1", encryptedData)
    
    if (encryptedData) {
      let bytes = CryptoJS.AES.decrypt(encryptedData, 'yourSecretKey');
      let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      this.setState({
        EntityName: decryptedData.map((d) => d.EntityName) || '',
        Name: decryptedData.map((d) => d.Name) || '',
        Email: decryptedData.map((d) => d.Email) || '',
        Password: decryptedData.map((d) => d.Password) || '',
        cpassword: decryptedData.map((d) => d.cpassword) || '',
      });
    } else {
      // Clear state if encrypted data is not present
      this.setState({
        EntityName: '',
        Name: '',
        Email: '',
        Password: '',
        cpassword: '',
      });
    }
  }
  

  togglePassword = () => {
    this.setState({ Eye: !this.state.Eye });
  };
  togglePassword1 = () => {
    this.setState({ Eye1: !this.state.Eye1 });
  };

  submit = (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const data1 = new FormData();
// Import crypto-js library
const CryptoJS = require('crypto-js');

// Your data array
let dataArray = [
  { 'EntityName': this.state.EntityName, 'Name':  this.state.Name, 'Email': this.state.Email, 'Password': this.state.Password, 'cpassword': this.state.cpassword }
];

// Convert dataArray to JSON string
let jsonString = JSON.stringify(dataArray);

// Encryption key (replace 'yourSecretKey' with a secure key)
let encryptionKey = 'yourSecretKey';

// Encrypt the JSON string
let encryptedData = CryptoJS.AES.encrypt(jsonString, encryptionKey).toString();

// Store the encrypted string in session storage
sessionStorage.setItem('encryptedData', encryptedData);


    

    this.setState({
      RegData: Object.fromEntries(data),
    });

    Object.entries(Object.fromEntries(data)).forEach(([key, value]) => {
      data1.set(key, value);
    });

    
    if (this.handleFormValidation()) {
      setTimeout(() => {
        this.setState({ loading: true });
      }, 500);

      var options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Origin": `${Image_URLs}`,
        },
        body: data,
      };

      fetch(`${BASE_URLs}Authenticate/ValidEntity`, options)
        .then((res) => res.json())
        .then((resp) => {

          

          if (resp.status === "Already Exist") {
            setTimeout(() => {
              this.setState({ loading: false });
            }, 500);
            document.getElementById("snackbar").innerHTML = resp.message;
            var x = document.getElementById("snackbar");
            x.className = "show";
            setTimeout(function () {
              x.className = x.className.replace("show", "");
            }, 3000);
          }

          if (
            resp.message ===
            "Failed : DuplicateUserName ,User creation failed!."
          ) {
            setTimeout(() => {
              this.setState({ loading: false });
            }, 500);

            document.getElementById("snackbar").innerHTML =
              "" + this.state.Name + " is Already Taken";
            x = document.getElementById("snackbar");
            x.className = "show";
            setTimeout(function () {
              x.className = x.className.replace("show", "");
            }, 3000);
          }
          let myCipher = this.cipher("mySecretSalt");

          if (resp.status === "Successcreated") {
            setTimeout(() => {
              this.setState({ loading: false });
            }, 500);
            localStorage.setItem("amcode", myCipher(resp.otp));
            // // console.log("otp",resp.otp)

            this.setState({ SuccessModel: true });
          }

          if (
            resp.message === "Failed : DuplicateUserName User creation failed!."
          ) {
            setTimeout(() => {
              this.setState({ loading: false });
            }, 500);
            alert("" + this.state.Name + " is Already Taken");
          }
        });

      setTimeout(() => {
        this.setState({ loading: false });
      }, 500);
    }
  };

  cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) =>
      text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };

  render() {
    var w = window.innerWidth;
    const { usernameErr, entitynameErr, emailErr, passwordErr, cpasswordErr } =
      this.state.formErrors;
    return (
      <div>
        <div>
          <div className="bgImg" />
          <div className="content">
            <br />
            <LogoCompo />
            <div
              className={
                w < 992
                  ? "  h-100 align-items-center justify-content-center"
                  : "d-flex h-100 align-items-center justify-content-center"
              }
            >
              <div className="container">
                {this.state.SuccessModel ? (
                  <RegMsgPop id="Success" close={() => this.SuccessModel()} />
                ) : null}
                <div className="row">
                  <div
                    className={
                      w < 992
                        ? " col-sm-12 ml-auto mr-auto"
                        : "col-md-4 col-sm-6 ml-auto mr-auto"
                    }
                  >
                    <div className="card-copy">
                      <div className="card-body">
                        <form id="entity" onSubmit={this.submit}>
                          <br />
                          <br />
                          <br />
                          <div>
                            <h4 className="title1">
                              <span className="shadows">Sign Up</span>
                            </h4>
                          </div>
                          <div className="signUp">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div id="snackbar"></div>
                            </div>
                            <span>
                              <input
                                type="text"
                                className="form"
                                onChange={this.handleChange}
                                onFocusCapture={this.handleChange}
                                maxLength="25"
                                id="EntityName"
                                onPaste="return false"
                                onKeyPress={(event) => {
                                  if (!/^[a-z0-9 ]+$/i.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                name="EntityName"
                                placeholder="Entity Name"
                                autoComplete="off"
                                value={this.state.EntityName}
                                style={{ cursor: "auto" }}
                              />
                              {entitynameErr && (
                                <div
                                  style={{ color: "red", paddingBottom: 10 }}
                                >
                                  {entitynameErr}
                                </div>
                              )}{" "}
                              <span className="underline" />
                            </span>
                            <span>
                              <input
                                type="text"
                                id="Name"
                                className="form" //onKeyPress={(event) => {if (!/^[a-zA-Z]+$/.test(event.key)) {event.preventDefault();}}}
                                maxLength="20"
                                onChange={this.handleChange}
                                onFocusCapture={this.handleChange}
                                name="Name"
                                placeholder="User Name"
                                autoComplete="off"
                                style={{ cursor: "auto" }}
                                value={this.state.Name}
                              />
                              {usernameErr && (
                                <div
                                  style={{ color: "red", paddingBottom: 10 }}
                                >
                                  {usernameErr}
                                </div>
                              )}{" "}
                              <span className="underline" />
                              <span
                                className={usernameErr ? " showError" : ""}
                              ></span>
                            </span>
                            <span>
                              <input
                                type="text"
                                className="form"
                                onChange={this.handleChange}
                                onFocusCapture={this.handleChange}
                                maxLength="50"
                                id="Email"
                                name="Email"
                                placeholder="Email"
                                autoComplete="off"
                                style={{ cursor: "auto" }}
                                value={this.state.Email}
                              />
                              {emailErr && (
                                <div
                                  style={{ color: "red", paddingBottom: 10 }}
                                >
                                  {emailErr}
                                </div>
                              )}{" "}
                              <span className="underline" />
                              <span
                                className={emailErr ? " showError" : ""}
                              ></span>
                            </span>
                            <>
                              <div style={{ display: "flex" }}>
                                <input
                                  type={!this.state.Eye ? "password" : "text"}
                                  className="form"
                                  id="password"
                                  maxLength="20"
                                  onFocusCapture={this.handleChange}
                                  onChange={this.handleChange}
                                  name="Password"
                                  placeholder="Password"
                                  autoComplete="off"
                                  style={{ cursor: "auto" }}
                                  value={this.state.Password}
                                />
                                <span
                                  className={
                                    !this.state.Eye
                                      ? "icofont icofont-eye eyelogo"
                                      : "icofont icofont-eye-blocked eyelogo"
                                  }
                                  id="eye"
                                  onClick={this.togglePassword}
                                ></span>
                                {/* eye-blocked */}
                              </div>
                              {passwordErr && (
                                <div>
                                  <span
                                    style={{ color: "red", paddingBottom: 10 }}
                                  >
                                    {passwordErr}
                                  </span>
                                  <br />
                                </div>
                              )}{" "}
                              <span className="underline" />
                            </>

                            <>
                              <div style={{ display: "flex" }}>
                                <input
                                  type={!this.state.Eye1 ? "password" : "text"}
                                  id="cpassword"
                                  className="form"
                                  maxLength="20"
                                  onChange={this.handleChange}
                                  onFocusCapture={this.handleChange}
                                  name="cpassword"
                                  placeholder="Confirm Password"
                                  autoComplete="off"
                                  style={{ cursor: "auto" }}
                                  value={this.state.cpassword}
                                />
                                <span
                                  className={
                                    !this.state.Eye1
                                      ? "icofont icofont-eye eyelogo"
                                      : "icofont icofont-eye-blocked eyelogo"
                                  }
                                  id="eye"
                                  onClick={this.togglePassword1}
                                ></span>
                                {/* eye-blocked */}
                              </div>
                              {cpasswordErr && (
                                <div>
                                  <span
                                    style={{ color: "red", paddingBottom: 10 }}
                                  >
                                    {cpasswordErr}
                                  </span>
                                  <br />
                                </div>
                              )}{" "}
                              <span className="underline" />
                            </>
                          </div>

                          <div
                            className="text-center"
                            style={{ marginTop: "-3px" }}
                          >
                            <button
                              type="submit"
                              className="but"
                              style={{ width: "83.36px", margin: "auto" }}
                            >
                              Register
                            </button>
                          </div>
                          <div
                            className="text-center"
                            style={{ marginTop: "10px" }}
                          >
                            <a
                              href
                              onClick={() => {
                                this.props.history.push("./Login_form");
                              }}
                              style={{
                                // float: "inline-end",
                                color: "#b152e9",
                                cursor: "pointer",
                              }}
                            >
                              Sign In
                            </a>
                          </div>
                        </form>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
          </div>
        </div>
        {this.state.loading === true ? <LoadingPage /> : null}
      </div>
    );
  }
}
