import { React, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';

const FilterPop = ({ data, FilteredData, TogglePop, tapKey }) => {
  const [Alpha, SetAlpha] = useState([
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ]);
  const [TapFilterValue, setTapFilterValue] = useState('');
  const [DisplayWidth, setDisplayWidth] = useState(window.innerWidth);

  const SearchFilter = (e) => {
    setTapFilterValue('');
    const filteredData = data.filter((obj) =>
      Object.keys(obj).some((key) =>
        String(obj[key]).toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
    FilteredData(filteredData);
  };

  const TapFiltering = (v, i) => {
    document.getElementById('SearchBox').value = '';
    // this.setState({ Learn : this.state.Learning });
    console.log('data', data);
    setTapFilterValue(v);
    const filteredData = data
      .filter((obj) => !Object.values(obj).every((value) => value === null))
      .filter(
        (f) =>
          // Object.keys(f).some((key) =>
          String(f[tapKey]).charAt(0).toLowerCase() === v.toLowerCase()
        // )
      );
    //  console.log(v.toLowerCase(),"Learning",Learning);
    FilteredData(filteredData);
  };

  return (
    <div
      className="popSearchTeams"
    >
      <div
        className="popSearchinnernormalTeams"
      >
        <div className="popSearchinnertitle" id="popSearchinnernormal">
          <button
          id="close"
          style={{
            float: "right",
            backgroundColor: "#a0a3a305",
            color: "#797070",
            border: "1px solid #a3a5a505",
            marginTop: "-20px",
            marginRight: "-20px",
          }}
            onClick={() => TogglePop()}
          >
            <CloseIcon />
          </button>

        <div
          class="checkboxes"
        >
            <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{
                            fontSize: "20px",
                            color: "gray",
                            marginLeft: "-1%",
                            position: "absolute",
                            marginTop: "0.5%",
                          }}
                        ></i>
          <input
            className="Searchbox"
            placeholder="Search"
            id="SearchBox"
            style={{ float: "right", width: "80%"  }}
            onKeyUp={SearchFilter}
          />
          <br></br>

        
            {Alpha.map((e, i) => (
           <button
           className="filter"
             onClick={() => TapFiltering(e, i)}
           >
               {e} 
              </button>
            ))}
             <br></br>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterPop;
