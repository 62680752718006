import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';
import AddDepartmentpop from '../../Common/AddDepartmentpop';
import AddDesignationModel from '../../Common/AddDesignationModel';
import AddLocationModel from '../../Common/AddLocationModel';
import AddManagerPop from '../../Common/AddManagerPop';
import CommonSuccess from '../../Common/CommonSuccess';
import Filterpopstatustopic from '../../Common/Filterpopstatustopic';
import SideBar from '../../Common/Sidebar';
import '../../Css/Addskills.css';
import { BASE_URLs } from '../../Config/Base';
import { event } from 'jquery';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const options = [
  { value: 'islamabad', label: 'Islamabad' },
  { value: 'lahore', label: 'Lahore' },
  { value: 'karachi', label: 'Karachi' },
];

export default class AddTrainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Active: [],
      InActive: [],
      FirstName: '',
      LastName: '',
      Email: '',
      Mobile: '',
      Department: '',
      Designation: '',
      RoleName: '',
      formErrors: {},
      Notification: [],
      Departmentdrop: [],
      message: '',
      status: '',
      DepartModel: false,
      DesignationModel: false,
      ManagerModel: false,
      LocationModel: false,
      selectedOptions: [],
      selectedCheckboxValues: [],
      optionList: [
        { value: 'Winner', label: 'Winner' },
        { value: 'AboutUs', label: 'AboutUs' },
        { value: 'Terms&Conditions', label: 'Terms&Conditions' },
        { value: 'PrivacyPolicy', label: 'PrivacyPolicy' },
        { value: 'Unfollow', label: 'Unfollow' },
        { value: 'Report', label: 'Report' },
        { value: 'Transaction', label: 'Transaction' },
      ],
      Teams: [],
      ListOptions: [],
      Traineroption: [],
      selectedValues: [],
      department: [],
      selectedDepartment: '',
      trainerName: [],
      trainerData: [],
      FinaltrainerData: [],
      selectedTrainerName: '',
      trainerRole: '',
      selectedRole: '',
      trainerID: '',
      currentDate: null,
      AllRoles: [],
      isOpen: false,
      Trainers: [],
      Filterpopstatusadd: false,
      Search: [],
      design: '',
      dept: '',
      FinaltrainerData1: [],
      depts: 'Department',
      selectedJobRole: 'Designation',
      TrainersChecked: false,
      checkedItems: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangedept = this.handleChangedept.bind(this);
    this.SelectDepartment = this.SelectDepartment.bind(this);
  }
  GetDate() {
    const today = new Date();
    const formattedDate = today.toISOString().slice(0, 10);
    this.setState({ currentDate: formattedDate });
  }
  GetDepartment() {
    let url = `${BASE_URLs}Department/DepartmentGet`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Departmentdrop: data,
        });
      });
  }
  handleSelect = (selectedList, selectedItem) => {
    this.setState({ selectedValues: selectedList });
  };

  Team() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Team/GetTeam`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex((item) => item.department === value.department) ===
            index
          );
        });
        this.setState({
          department: filteredData,
          //selectedDepartment: filteredData[0].department,
        });
        this.fetchItems(data[0].department);
      });
  }

  fetchItems(departmentValue) {
    const optionVal = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(
      `${BASE_URLs}Team/GetTeambyDepartment?Department=${departmentValue}`,
      optionVal
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({
            trainerName: data,
            // selectedTrainerName: data.firstName, // Select the first item by default
            trainerID: data[0].id,
            // trainerData: data,
          });
          // this.setState({
          //   FinaltrainerData: [],
          // });
        } else {
          this.setState({
            trainerName: [],
          });
        }
      })
      .catch((error) => console.error(error));
  }
  fetchRole(TrainerName, TrainerID) {
    const optionVal = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(
      `${BASE_URLs}Team/GetRolebyTrainerName?Firstname=${TrainerName}`,
      optionVal
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          // trainerName: data,
          // selectedRole: data,
          trainerRole: data[0].role,
        });
      })
      .catch((error) => console.error(error));
  }

  handleChangedept = (event) => {
    //   const department = event.target.value;
    //   // this.setState({ SelectedDepartment: event.target.value });
    //

    //   if(event.target.value==""){
    //     this.setState({ FinaltrainerData: this.state.FinaltrainerData1 });
    //    }
    //    else{

    //   let final = this.state.FinaltrainerData1.filter((e) => {
    //     if (e.department === department) {
    //       return e;
    //     }
    //   });

    //    this.setState({ FinaltrainerData: final });
    //

    // }
    this.setState({
      depts: event.target.value,
    });
  };
  handleChangedesignation = (event) => {
    //   const designation = event.target.value;

    //   if(event.target.value==""){
    //     this.setState({ FinaltrainerData: this.state.FinaltrainerData1 });
    //    }
    //    else{

    //   let final = this.state.FinaltrainerData1.filter((e) => {
    //     if (e.designation === designation) {
    //       return e;
    //     }
    //   });

    //   this.setState({ FinaltrainerData: final });
    //
    // }
    this.setState({
      selectedJobRole: event.target.value,
    });
  };
  handleChange = (event) => {
    this.setState({ dept: event.target.value });

    var dd = this.state.Trainers.forEach((e) => {
      var split = e.userId;
      // [5,''vjvd487348543, 'Arunachalam']
      //[]
      this.setState({ trainerid1: split });

      //
      //
      // data.append("FirstName",split[1]);
      // data.append("TrainerId",split[2]);
      // data.append("TrainingId",split[0]);
    });

    // if (event.target.dept == 0) {
    //
    //   window.location.reload();
    // }
    // alert("Before");
    // this.DefaultMenu();

    // this.setState({
    //   catval: event.target.value
    // })
    let url = `${BASE_URLs}Training/GetDeptbased?Department=${event.target.value}&UserId=${this.state.Id}`;

    // let user = JSON.parse(localStorage.getItem("user"));
    // const accessToken = user;
    //

    //
    //alert("After");
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },

      //body:JSON.stringify(data)
    })
      // alert("After0")
      .then((response) => response.json())
      .then((data) => {
        //   this.DefaultMenu();
        //alert(data);

        this.setState({
          Search: data,
        });
        // alert("After1");
        //  this.DefaultMenu();

        for (var i = 0; i < data.length; i++) {
          this.setState({ topic: data[i].trainingitem });
        }
        //
      });
  };

  handleChangeDesign = (event) => {
    //  this.setState({ design: event.target.value });

    var dd = this.state.Trainers.forEach((e) => {
      var split = e.userId;
      // [5,''vjvd487348543, 'Arunachalam']
      //[]
      this.setState({ trainerid1: split });

      //
      //
      // data.append("FirstName",split[1]);
      // data.append("TrainerId",split[2]);
      // data.append("TrainingId",split[0]);
    });

    // if (event.target.dept == 0) {
    //
    //   window.location.reload();
    // }
    // alert("Before");
    // this.DefaultMenu();

    // this.setState({
    //   catval: event.target.value
    // })
    let url = `${BASE_URLs}Training/Getdesignbased?Department=${this.state.dept}&Design=${event.target.value}&UserId=${this.state.Id}`;

    // let user = JSON.parse(localStorage.getItem("user"));
    // const accessToken = user;

    //
    //alert("After");
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },

      //body:JSON.stringify(data)
    })
      // alert("After0")
      .then((response) => response.json())
      .then((data) => {
        //   this.DefaultMenu();
        //alert(data);

        this.setState({
          Search: data,
        });
        // alert("After1");
        //  this.DefaultMenu();

        for (var i = 0; i < data.length; i++) {
          this.setState({ topic: data[i].trainingitem });
        }
        //
      });
  };

  handleTrainerNameChange = (event) => {
    const name = event.target.value;
    //  this.setState({ SelectedDepartment: event.target.value });

    // this.fetchItems(department);

    let final = this.state.FinaltrainerData1.filter((item) =>
      item.firstName.toLowerCase().includes(name.toLowerCase())
    );
    this.setState({ FinaltrainerData: final });

    // const trainerName = event.target.value;
    //
    // let selected_id = this.state.trainerName.filter(
    //   (e) => e.firstName === trainerName.split("$$$")[0]
    // );
    //
    // this.setState({ selectedTrainerName: trainerName });
    // this.fetchRole(trainerName, selected_id[0].id);
  };
  componentDidMount() {
    this.GetDepartment();
    this.GetDate();
    this.Team();
    this.GetAllRole();
    this.GetTrainer();

    var role = JSON.parse(localStorage.Role);
    if (role.toString() === 'Platform Admin') {
      document.getElementById('navright').setAttribute('style', ' display:;');
      document.getElementById('mainorg').setAttribute('style', '  display:;');
    }
  }
  closePopup = () => {
    this.setState({ isOpen: false });
  };

  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));
    this.props.history.push('/TrainerSchedule');
  };
  SelectManager = (e) => {
    let dept = document.getElementById('Manager').value;
    if (dept.toLowerCase() === 'add') {
      this.setState((prevState) => ({
        ManagerModel: !prevState.ManagerModel,
      }));
      document.getElementById('Manager').value = '';
    }
  };
  SelectLocation = (e) => {
    let dept = document.getElementById('Locations').value;
    if (dept.toLowerCase() === 'add') {
      this.setState((prevState) => ({
        LocationModel: !prevState.LocationModel,
      }));
      document.getElementById('Locations').value = '';
    }
  };
  SelectDepartment = (e) => {
    let dept = document.getElementById('Department').value;
    if (dept.toLowerCase() === 'add') {
      this.setState((prevState) => ({
        DepartModel: !prevState.DepartModel,
      }));

      document.getElementById('Department').value = '';
      //this.props.history.push('/Department')
    }
  };
  SelectDesignation = (e) => {
    let dept = document.getElementById('Designation').value;
    if (dept.toLowerCase() === 'add') {
      this.setState((prevState) => ({
        DesignationModel: !prevState.DesignationModel,
      }));
      document.getElementById('Designation').value = '';
    }
  };
  // handleSubmit = (event) => {
  //   event.preventDefault();

  //   const { checkedItems } = this.state;
  //   const checkedCount = Object.values(checkedItems).filter(
  //     (isChecked) => isChecked
  //   ).length;
  //   if (checkedCount === 0) {
  //     alert('Please select at least one User!');
  //   } else {
  //     const data = new FormData(event.target);
  //     let dd = this.state.selectedCheckboxValues.forEach((e) => {
  //       let split = e.split('$$$');
  //       data.append('FirstName', split[1]);
  //       data.append('TrainerId', split[4]);
  //       data.append('TrainingTopicId', split[3]);
  //       data.append('TrainerData', e);
  //     });
  //     data.append('Trainer_UserID', this.state.Id);
  //     for (var pair of data.entries()) {
  //     }
  //     fetch(`${BASE_URLs}Trainer/AddTrainer`, {
  //       method: 'POST',
  //       headers: {
  //         Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
  //       },
  //       body: data,
  //     })
  //       .then((res) => res.json())
  //       .then((resp) => {
  //         this.setState({ status: resp.status });
  //         this.setState({ message: resp.message });
  //         this.SuccessModel();
  //       });
  //   }
  //   // var TrainerTopicID=document.getElementById('TrainerTopicID').value;
  // };
  handleSubmit = (event) => {
    event.preventDefault();
  
    const { checkedItems } = this.state;
    const checkedCount = Object.values(checkedItems).filter(
      (isChecked) => isChecked
    ).length;
    if (checkedCount === 0) {
      alert('Please select at least one User!');
    } else {
      const data = new FormData(event.target);
      this.state.selectedCheckboxValues.forEach((e) => {
        let split = e.split('$$$');
        data.append('FirstName', split[1]);
        data.append('TrainerId', split[4]);
        data.append('TrainingTopicId', split[3]);
        data.append('TrainerData', e);
      });
      data.append('Trainer_UserID', this.state.Id);
  
      fetch(`${BASE_URLs}Trainer/AddTrainer`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          this.setState({ status: resp.status });
          this.setState({ message: resp.message });
          this.SuccessModel();
  
          if (resp.status === 'Success') {
            toast.success('Trainer added successfully!');
          }
        });
    }
  };
  
  checkChangeDesignation = (event) => {
    const { name, checked, value } = event.target;
    this.setState({
      TrainersChecked: event.target.checked,
    });
    this.setState((prevState) => ({
      checkedItems: {
        ...prevState.checkedItems,
        [value]: checked,
      },
    }));
    this.setState((prevState) => {
      const selectedCheckboxValues = checked
        ? [...prevState.selectedCheckboxValues, value]
        : prevState.selectedCheckboxValues.filter((val) => val !== value);
      return { selectedCheckboxValues };
    });
  };
  DepartModel = () => {
    this.setState((prevState) => ({
      DepartModel: !prevState.DepartModel,
    }));
    this.GetDepartment();
  };
  DesignationModel = () => {
    this.setState((prevState) => ({
      DesignationModel: !prevState.DesignationModel,
    }));
    this.GetDesignation();
  };
  ManagerModel = () => {
    this.setState((prevState) => ({
      ManagerModel: !prevState.ManagerModel,
    }));
    this.GetManager();
  };
  LocationModel = () => {
    this.setState((prevState) => ({
      LocationModel: !prevState.LocationModel,
    }));
    this.GetLocation();
  };

  GetAllRole() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Designation/GetMasterDesignation`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          AllRoles: data,
        });
      });
  }

  openThePopup = (department, userId, trainerName) => {
    this.setState({ Id: userId });

    this.setState({ firstName: trainerName });

    this.setState({ isOpen: true });
    this.GetTrainingItems(department, userId);
  };

  GetTrainingItems = (Department, userId) => {
    const checking = Department;

    let url = `${BASE_URLs}Training/GetTrainingItems?Department=${Department}&TrainingId=${userId}`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Trainers: data,
        });
      });
  };
  GetTrainer = (Department, userId) => {
    const checking = Department;

    let url = `${BASE_URLs}Trainer/GetFullTeam`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          FinaltrainerData: data,
          FinaltrainerData1: data,
        });
      });
  };

  handleCancel = () => {
    this.setState({ depts: 'Department', selectedJobRole: 'Designation' });
    this.setState({ filteredSearch: this.state.FinaltrainerData });
    this.setState({ selectedCheckboxValues: [] });
    this.setState({ checkedItems: {} });
  };

  render() {
    const filteredSearch = this.state.FinaltrainerData.filter(
      (item) =>
        (this.state.depts === 'Department' ||
          item.department === this.state.depts) &&
        (this.state.selectedJobRole === 'Designation' ||
          item.designation === this.state.selectedJobRole)
    );

    const {
      trainerData,
      FinaltrainerData,
      Department,
      Filterpopstatus,
      Filterpopstatus1,
      DepartmentGrouped,
      Trainers,
      Search,
      DesignSearch,
      Filterpopstatusadd,
      isOpen,
      checkedItems,
    } = this.state;

    return (
      <div>
        <SideBar active={window.location.hash} />
        {this.state.DepartModel ? (
          <AddDepartmentpop close={() => this.DepartModel()} />
        ) : null}
        {this.state.DesignationModel ? (
          <AddDesignationModel close={() => this.DesignationModel()} />
        ) : null}
        {this.state.ManagerModel ? (
          <AddManagerPop close={() => this.ManagerModel()} />
        ) : null}
        {this.state.LocationModel ? (
          <AddLocationModel close={() => this.LocationModel()} />
        ) : null}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: 'left' }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                class="icofont icofont-arrow-left"
                                style={{ fontSize: '45px', color: 'white' }}
                              />
                            </a>{' '}
                          </div>
                          <h4 style={{ marginRight: '3%' }} className="title">
                            Add Trainer
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        {this.state.SuccessModel ? (
                          <CommonSuccess
                            id="Success"
                            message={this.state.message}
                            status={this.state.status}
                            close={() => this.SuccessModel()}
                          />
                        ) : null}
                        <div className="card-block2">
                          <div className="row" style={{ marginTop: '3%' }}>
                            <div
                              className="container"
                              style={{ maxWidth: '100%' }}
                            >
                              <form
                                autoComplete="off"
                                encType="multipart/form-data"
                                onSubmit={this.handleSubmit}
                              >
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="container">
                                      <div
                                        className="row"
                                        style={{ padding: '8px' }}
                                      >
                                        <div className="col-sm-4">
                                          <select
                                            className="effect-16"
                                            type="text"
                                            name="Department"
                                            id="Department"
                                            value={this.state.depts}
                                            onChange={this.handleChangedept}
                                          >
                                            <option value="Department">
                                              All
                                            </option>
                                            {this.state.Departmentdrop.map(
                                              (Departmentdrop) => (
                                                <option
                                                  value={
                                                    Departmentdrop.department
                                                  }
                                                >
                                                  {Departmentdrop.department}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          <span style={{ color: '#a29d9d' }}>
                                            Department
                                          </span>
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-4">
                                          <select
                                            className="effect-16"
                                            type="text"
                                            value={this.state.selectedJobRole}
                                            name="Role"
                                            id="JobRole"
                                            onChange={
                                              this.handleChangedesignation
                                            }
                                          >
                                            <option value="Designation">
                                              All
                                            </option>
                                            {this.state.AllRoles.map(
                                              (JobDrop) => (
                                                <option
                                                  value={JobDrop.designation}
                                                >
                                                  {JobDrop.designation}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          <span style={{ color: '#a29d9d' }}>
                                            Designation
                                          </span>
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-4">
                                          <input
                                            className="effect-16"
                                            placeholder="Search"
                                            type="text"
                                            name="TrainerName"
                                            id="TrainerName"
                                            onChange={
                                              this.handleTrainerNameChange
                                            }
                                          />

                                          <span style={{ color: '#a29d9d' }}>
                                            Name
                                            <span
                                              style={{ color: 'red' }}
                                            ></span>
                                          </span>
                                          <span className="underline" />
                                        </div>
                                        <div className="col-sm-4">
                                          <input
                                            className="effect-16"
                                            style={{ marginTop: '-6px' }}
                                            name="Trainersince"
                                            id="Trainersince"
                                            type="hidden"
                                            value={this.state.currentDate}
                                          />
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-lg-12 dt-responsive table-responsive table-res">
                                  <div
                                    className="AddTrainer"
                                    style={{
                                      maxWidth: '71%',
                                      marginLeft: '-15%',
                                      width: '100%',
                                    }}
                                  >
                                    {this.state.FinaltrainerData.length ===
                                    0 ? null : (
                                      <div class="table-responsive table-alignLeft">
                                        <div className="table-header">
                                          <div class="table-row">
                                            <div class="table-cell"></div>
                                            <div
                                              class="table-cell"
                                              style={{ textAlign: 'justify' }}
                                            >
                                              Department
                                            </div>
                                            <div
                                              class="table-cell"
                                              style={{ textAlign: 'justify' }}
                                            >
                                              Name
                                            </div>
                                            <div
                                              class="table-cell"
                                              style={{ textAlign: 'justify' }}
                                            >
                                              Designation
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="table-hr"
                                          style={{ marginLeft: '16%' }}
                                        ></div>
                                        <div class="table-body">
                                          {filteredSearch.map((trainerData) => (
                                            <div
                                              class="table-row"
                                              style={{ textAlign: 'left' }}
                                            >
                                              <div
                                                class="table-cell"
                                                style={{ paddingLeft: '3%' }}
                                              >
                                                <input
                                                  type="checkbox"
                                                  value={
                                                    trainerData.department +
                                                    '$$$' +
                                                    trainerData.firstName +
                                                    '$$$' +
                                                    trainerData.designation +
                                                    '$$$' +
                                                    trainerData.id +
                                                    '$$$' +
                                                    trainerData.user_Id
                                                  }
                                                  style={{ marginLeft: '81%' }}
                                                  onChange={
                                                    this.checkChangeDesignation
                                                  }
                                                />
                                              </div>

                                              <div class="table-cell">
                                                {trainerData.department}
                                              </div>
                                              <div class="table-cell">
                                                {trainerData.firstName}
                                              </div>
                                              <div class="table-cell">
                                                {trainerData.designation}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    )}

                                    {this.state.FinaltrainerData.length ===
                                    0 ? null : (
                                      <div className="savebtn">
                                        <button
                                          type="submit"
                                          className="but "
                                          style={{
                                            height: '39.5px',
                                            width: '86.36px',
                                            marginTop: '2.7%',
                                            marginLeft: '37%',
                                          }}
                                        >
                                          Save
                                        </button>
                                        &nbsp; &nbsp; &nbsp;
                                        <button
                                          type="reset"
                                          className=" btn-Secondary"
                                          style={{ height: '39.5px' }}
                                          onClick={this.handleCancel}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div> */}

                                <div
                                  style={{
                                    maxWidth: '100%',
                                    overflowX: 'auto',
                                    textAlign: 'center',
                                  }}
                                >
                                  {filteredSearch.length === 0 ? (
                                    <b>No trainers to add</b>
                                  ) : (
                                    <table
                                      style={{
                                        width: '92%',
                                        marginLeft: '45px',
                                      }}
                                    >
                                      <thead className="tableline">
                                        <tr>
                                          <th> </th>
                                          <th
                                            style={{
                                              textAlign: 'justify',
                                              paddingLeft: '10px',
                                            }}
                                          >
                                            Department
                                          </th>
                                          <th
                                            style={{
                                              textAlign: 'justify',
                                              paddingLeft: '10px',
                                            }}
                                          >
                                            Name
                                          </th>
                                          <th
                                            style={{
                                              textAlign: 'justify',
                                              paddingLeft: '10px',
                                            }}
                                          >
                                            Designation
                                          </th>
                                          <th
                                            style={{
                                              textAlign: 'justify',
                                              paddingLeft: '10px',
                                            }}
                                          ></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {filteredSearch.map(
                                          (trainerData, index) => (
                                            <tr key={index}>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  value={
                                                    trainerData.department +
                                                    '$$$' +
                                                    trainerData.firstName +
                                                    '$$$' +
                                                    trainerData.designation +
                                                    '$$$' +
                                                    trainerData.id +
                                                    '$$$' +
                                                    trainerData.user_Id
                                                  }
                                                  style={{
                                                    marginLeft: '60%',
                                                    padding: '10px',
                                                  }}
                                                  onChange={
                                                    this.checkChangeDesignation
                                                  }
                                                  checked={
                                                    checkedItems[
                                                      `${trainerData.department}$$$${trainerData.firstName}$$$${trainerData.designation}$$$${trainerData.id}$$$${trainerData.user_Id}`
                                                    ]
                                                  }
                                                />
                                              </td>
                                              <td style={{ padding: '10px' }}>
                                                {trainerData.department}
                                              </td>
                                              <td style={{ padding: '10px' }}>
                                                {trainerData.firstName}
                                              </td>
                                              <td style={{ padding: '10px' }}>
                                                {trainerData.designation}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  )}
                                </div>
                                {this.state.FinaltrainerData.length ===
                                0 ? null : (
                                  <div className="savebtn">
                                    <button
                                      type="submit"
                                      className="but"
                                      style={{
                                        height: '39.5px',
                                        width: '86.36px',
                                        marginTop: '2.7%',
                                        marginLeft: '37%',
                                      }}
                                    >
                                      Save
                                    </button>
                                    &nbsp; &nbsp; &nbsp;
                                    <button
                                      type="button"
                                      className="btn-Secondary"
                                      style={{ height: '39.5px' }}
                                      onClick={this.handleCancel}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                )}

                                <br />
                                <br />
                                <br />
                                <br />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="pop">
            <div className="popinnernormal">
              <form
                autoComplete="off"
                encType="multipart/form-data"
                style={{ width: '135%' }}
                onSubmit={this.handleSubmit}
              >
                <button
                  style={{
                    marginLeft: '44%',
                    backgroundColor: '#a0a3a305',
                    color: '#797070',
                    border: '1px solid #a3a5a505',
                  }}
                  onClick={this.closePopup}
                >
                  <CloseIcon />
                </button>
                <div className="row" tyle={{ padding: '8px' }}>
                  <div className="col-sm-3">
                    <select
                      className="effect-16"
                      type="text"
                      name="Department"
                      value={this.state.dept}
                      id="Department"
                      onChange={this.handleChange}
                      style={{ width: '72%' }}
                    >
                      <option value="">Department</option>
                      {this.state.department.map((Departmentdrop) => (
                        <option value={Departmentdrop.department}>
                          {Departmentdrop.department}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-3">
                    <select
                      className="effect-16"
                      type="text"
                      name="JobRole"
                      style={{ width: '88%', marginLeft: '-30%' }}
                      id="JobRole"
                      onChange={this.handleChangeDesign}
                    >
                      <option value="">Designation</option>
                      {this.state.AllRoles.map((JobDrop) => (
                        <option value={JobDrop.designation}>
                          {JobDrop.designation}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <input
                      className="Searchbox"
                      placeholder="Search"
                      onClick={() => {
                        this.setState({ Filterpopstatusadd: true });
                      }}
                    />
                  </div>

                  <div className="col-sm-8">
                    <div className="dt-responsive table-responsive table-res">
                      {(() => {
                        if (this.state.Search.length === 0) {
                          return <p>There are no Trainers</p>;
                        } else {
                          return (
                            <table
                              id="DepSheet"
                              className="table nowrap"
                              style={{ maxWidth: '90%' }}
                            >
                              <thead>
                                <tr>
                                  <th>
                                    <input type="checkbox" hidden />
                                  </th>

                                  <th>Department</th>
                                  <th>Designation</th>
                                  <th>Topics</th>
                                  <th>Description</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Search.length > 0 ? (
                                  <>
                                    {Search.map((checkbox) => (
                                      <tr key={checkbox.training_Id}>
                                        <td>
                                          <input
                                            name="TrainerData"
                                            type="checkbox"
                                            value={
                                              checkbox.training_Id +
                                              '$$$' +
                                              this.state.firstName +
                                              '$$$' +
                                              this.state.Id
                                            }
                                            onChange={
                                              this.checkChangeDesignation
                                            }
                                          />
                                        </td>
                                        <td>{checkbox.department}</td>
                                        <td>{checkbox.designation}</td>
                                        <td>{checkbox.trainingitem}</td>
                                        <td>{checkbox.description}</td>
                                      </tr>
                                    ))}
                                  </>
                                ) : (
                                  <p>No Trainers found</p>
                                )}
                              </tbody>
                            </table>
                          );
                        }
                      })()}

                      {this.state.selectedOptions.map((option, index) => (
                        <li key={index}>{option}</li>
                      ))}
                    </div>
                  </div>
                </div>
                {Filterpopstatusadd ? (
                  <Filterpopstatustopic
                    data={this.state.topic}
                    FilteredData={(newValue) => {
                      this.setState({ Search: newValue });
                    }}
                    TogglePop={(e) => {
                      this.setState({
                        Filterpopstatusadd: !Filterpopstatusadd,
                      });
                    }}
                  />
                ) : null}
                <div className="row">
                  <div
                    className="col-sm-12"
                    style={{ textAlign: 'center' }}
                  ></div>
                </div>
                {(() => {
                  if (this.state.Search.length === 0) {
                    return null;
                  } else {
                    return (
                      <div className="col-sm-4">
                        <button
                          className="button-30"
                          type="submit"
                          style={{
                            color: 'rgb(83, 81, 171)',
                            marginLeft: '98%',
                          }}
                        ></button>
                      </div>
                    );
                  }
                })()}
              </form>
              <br />
            </div>
          </div>
        )}
      </div>
    );
  }
}
