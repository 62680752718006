import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base";
export default class UserAnnouncementpopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Messages: [],
      Profile_Pic: [],
      Notification: [],
      Announce: [],
      category: [],
      AddAnnounce: [],
    };
  }
  myFunction = (e) => {
    e.preventDefault();

    const Announcement = this.state.AddAnnounce.filter((item) =>
      item.category.toLowerCase().includes(e.target.value.toLowerCase())
    );

    this.setState({ Announcement });
  };

  GetMessages() {
    let user = JSON.parse(localStorage.getItem("user"));
    var Contact_Id = this.props.location.state.Contact_Id;
    const accessToken = user;
    fetch(`${BASE_URLs}Contact/AdminMessageid?Contact_Id=` + Contact_Id, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Messages: data,
        });

        // console.log("Value", data);

        //
      });
  }

  GetUserProfile() {
    let url = `${BASE_URLs}Profile/Userprofile`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Profile_Pic: data,
        });

        // //
      });
  }
  componentDidMount() {
    this.GetMessages();
    this.GetUserProfile();

    //  var role =JSON.parse(localStorage.Role);
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  render() {
    const { Messages } = this.state;
    return (
      <div>
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="glowBox">
                          <div>
                            <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                          </div>
                          <h4 className="title">Messages</h4>
                        </div>

                        <div className="card-block">
                          {Messages.map((Message) => (
                              <>
                                {Message.image === "-" ? (
                                  <div className="col-lg-12">
                                    <div
                                      className="card-Announcement"
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        padding: "2%",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <h6
                                        key={Message.contact_Id}
                                        style={{ margin: "0%" }}
                                      >
                                        <center>
                                          {" "}
                                          <span>Message:</span>&nbsp;
                                          <span> {Message.cMsg}</span>
                                        </center>
                                      </h6>
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    {Message.image !== "-" ? (
                                      <div className="col-lg-12">
                                        <div
                                          className="card-Announcement"
                                          style={{
                                            position: "relative",
                                            overflow: "hidden",
                                            backgroundColor: "white",
                                          }}
                                        >
                                          <h6 key={Message.contact_Id} style={{}}>
                                            <center>
                                              {" "}
                                              <span>Message:</span>&nbsp;
                                              <span>{Message.cMsg}</span>
                                            </center>
                                          </h6>
                                          <div className="row">
                                            <div className="col-lg-6">
                                              <img
                                                alt="announceimg"
                                                width="320"
                                                height="240"
                                                src={Message.image}
                                                style={{ width: "inherit" }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        {Message.image === "-"? (
                                          <div className="col-lg-12">
                                            <div
                                              className="card-Announcement"
                                              style={{
                                                position: "relative",
                                                overflow: "hidden",
                                                backgroundColor: "white",
                                              }}
                                            >
                                              <div className="col-lg-12">
                                                <h6
                                                  key={Message.contact_Id}
                                                  style={{}}
                                                >
                                                  <center>
                                                    {" "}
                                                    <span>Message:</span>&nbsp;
                                                    <span>
                                                      {" "}
                                                      {Message.cMsg}
                                                    </span>
                                                  </center>
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="col-lg-12">
                                            <div
                                              className="card-Announcement"
                                              style={{
                                                position: "relative",
                                                overflow: "hidden",
                                                backgroundColor: "white",
                                              }}
                                            >
                                              <h6
                                                key={Message.contact_Id}
                                                style={{}}
                                              >
                                                <center>
                                                  {" "}
                                                  <span>Message:</span>&nbsp;
                                                  <span> {Message.cMsg}</span>
                                                </center>
                                              </h6>
                                              <div className="row">
                                                <div
                                                  className="col-lg-12"
                                                  style={{ padding: "0% 28%" }}
                                                >
                                                  <img
                                                    alt="announceimg"
                                                    width="320"
                                                    height="240"
                                                    src={Message.image}
                                                    style={{ width: "inherit" }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ))}
                          <div>
                            <div style={{ textAlign: "center" }}>
                              <button
                                href
                                onClick={() => this.props.history.goBack()}
                                type="button"
                                style={{
                                  width: "130px",
                                  backgroundColor: "#7f63efde",
                                  color: "white",
                                }}
                                className="dropdown_item-2"
                                className="btn "
                              >
                                Back
                              </button>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
