import React, { Component } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import "../../Css/SkillMaster.css";
import { Link } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base";
export default class SkillMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      skills: [],
      Addskills: [],
      Id: "",
      SkillName: "",
      SkillDescription: "",
      Category: "",
      Notification: [],
      skillsgun: "",
      catedrop: "",

      category: [],
      comlogo: [],
      Skillname: [],
      Sort: [],
      Skillfilt: [],
    };

    this.initialState = this.state;
  }

  handleSubmit = async (e) => {
    let result = await fetch(`${BASE_URLs}Skill/post`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        SkillName: this.state.SkillName,
        SkillDescription: this.state.SkillDescription,
        Category: this.state.Category,
      }),
    });
    result = await result.json();

    window.alert(result.message);
  };

  handlecheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecount")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecount");
      let count = checkboxes.length - 1;

      if (document.getElementById("selectall").checked) {
        msg.innerHTML = "<span> " + count + "  selected </span> ";
      } else {
        msg.innerHTML = "<span> " + checkboxes.length + " selected </span> ";
      }
    } else {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("selectall").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("selectall").checked = true;
      }
    } else {
      if (document.getElementById("selectall").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("selectall").checked = false;
        }
      }
    }
  }

  checkAll(e) {
    var checkboxes = document.getElementsByName("Id_delete");

    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  Multidelete(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",

        //Authorization: `Bearer ${JSON.parse(localStorage.user)}`
      },
      body: data,
    };
    fetch(`${BASE_URLs}Skill/Multidelete`, options)
      .then((response) => response.json())
      .then((data) => {});
    // this.Team();
    document
      .getElementById("delete")
      .setAttribute("style", "visibility: hidden;");
    document
      .getElementById("deletecount")
      .setAttribute("style", "visibility: hidden;");
    var checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );

    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
    alert("Are you Sure You want to delete?");
    //check.checked = false;
  }

  GetAddSkill() {
    let url = `${BASE_URLs}Skill/GetSkills`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Addskills: data,
          Sort: data,
          Skillfilt: data,
        });
      });
  }

  deleteSkill = (id) => {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}Skill/DeleteEMPS?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          alert(resp);
        });
      });
    }
  };
  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };
  componentDidMount() {
    this.GetAddSkill();
    this.GetSkillname();
    this.Getcategory();
    this.GetOrgLogo();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }
  Getall() {
    fetch(`${BASE_URLs}AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }

  Edit(company_Id) {
    //localStorage.setItem("status","Pending")
    var page = {
      pathname: "/EditSkill",
      state: {
        Id: company_Id,
      },
    };

    this.props.history.push(page);
  }

  Edit1(company_Id) {
    //localStorage.setItem("status","Pending")
    var page = {
      pathname: "/EditEmpSkill",
      state: {
        Id: company_Id,
      },
    };

    this.props.history.push(page);
  }

  Getcategory() {
    fetch(`${BASE_URLs}Skill/GetCategoryDrop`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ category: data });
      });
  }

  myFunction = (e) => {
    e.preventDefault();
    const Addskills = this.state.Skillfilt.filter(
      (item) =>
        item.category.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.skillName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Addskills });
  };

  GetSkillname() {
    fetch(`${BASE_URLs}Skill/GetSkillNameDrop`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Skillname: data });
      });
  }

  Sort = (e) => {
    e.preventDefault();
    if (e.target.value === "1") {
      const arr = this.state.Addskills.sort((a, b) =>
        a.category > b.category ? 1 : b.category > a.category ? -1 : 0
      );
      this.setState({ arr });
    } else if (e.target.value === "2") {
      const arr = this.state.Addskills.sort((a, b) =>
        a.category < b.category ? 1 : b.category < a.category ? -1 : 0
      );
      this.setState({ arr });
    } else if (e.target.value === "3") {
      const arr = this.state.Addskills.sort((a, b) =>
        a.skillName > b.skillName ? 1 : b.skillName > a.skillName ? -1 : 0
      );
      this.setState({ arr });
    } else if (e.target.value === "4") {
      const arr = this.state.Addskills.sort((a, b) =>
        a.skillName < b.skillName ? 1 : b.skillName < a.skillName ? -1 : 0
      );
      this.setState({ arr });
    }
  };

  render() {
    var username = JSON.parse(localStorage.uname);
    const { category } = this.state;
    var filename = `O_${username}_AdminSkill`;

    const { Addskills } = this.state;

    return (
      <div>
        <SideBar active={window.location.hash} />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <Link onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </Link>{" "}
                          </div>
                          <h4 className="title">Skills </h4>
                        </div>
                        <div className="card-header-right"></div>

                        <br />
                        <div className="card-block">
                          <div
                            className="row"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <div className="col-lg-2">
                              <select
                                name="Category"
                                onChange={this.myFunction}
                                id="myInput"
                                className="btn"
                                style={{
                                  borderColor: "white",
                                  color: "white",
                                  textAlign: "center",
                                  width: "150px",
                                  height: "42px",
                                }}
                              >
                                <option className="drop-options" value="">
                                  category
                                </option>
                                {category.map((category) => (
                                  <option
                                    className="drop-options"
                                    value={category.category}
                                  >
                                    {category.category}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-2">
                              <Link
                                to="/SkillMaster"
                                style={{
                                  color: "white",
                                  height: "40px",
                                  width: "150px",
                                }}
                                className="btn"
                              >
                                {" "}
                                Master List
                              </Link>
                            </div>
                            <div className="col-lg-2">
                              {Addskills.length === 0 ? null : (
                                <ReactHTMLTableToExcel
                                  className="but"
                                  table="skillsheet"
                                  filename={filename}
                                  sheet="Sheet"
                                  buttonText="Export to excel"
                                  id="exportxl"
                                  style={{ height: "40px" }}
                                />
                              )}
                            </div>
                            <div className="col-lg-6">
                              {Addskills.length === 0 ? null : (
                                <input
                                  className="Searchbox"
                                  placeholder="Search"
                                  onKeyUp={this.myFunction}
                                  style={{ float: "right", height: "40px" }}
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div
                          id="selectalldiv"
                          style={{ visibility: "hidden" }}
                        ></div>
                        <br />
                        <br />
                        <div className="card-block2">
                          <form
                            autoComplete="on"
                            encType="multipart/form-data"
                            onSubmit={this.Multidelete}
                          >
                            <button
                              type="submit"
                              className="multitrash"
                              id="delete"
                              style={{ float: "right", visibility: "hidden" }}
                            >
                              <i
                                className="icofont icofont-delete-alt"
                                style={{ color: "darkgray" }}
                              ></i>
                            </button>
                            <span
                              id="deletecount"
                              className="multitrash"
                              style={{ visibility: "hidden", fontSize: "17px" }}
                            ></span>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {Addskills.length === 0 ? null : (
                              <table id="skillsheet" className="table  nowrap">
                                <thead>
                                  <tr>
                                    <th>
                                      {" "}
                                      <input
                                        type="checkbox"
                                        id="selectall"
                                        onClick={() => {
                                          this.checkAll(this);
                                        }}
                                      />{" "}
                                    </th>
                                    <th>
                                      Category&nbsp;&nbsp;
                                      <select
                                        onChange={this.Sort}
                                        id="category"
                                        className="btn"
                                        style={{
                                          borderColor: "white",
                                          color: "white",
                                          textAlign: "left",
                                          width: "20px",
                                          padding: "0",
                                        }}
                                      >
                                        <option
                                          className="drop-options"
                                          value=""
                                        >
                                          {" "}
                                        </option>
                                        <option
                                          className="drop-options"
                                          value="1"
                                        >
                                          {" "}
                                          A-Z
                                        </option>
                                        <option
                                          className="drop-options"
                                          value="2"
                                        >
                                          {" "}
                                          Z-A
                                        </option>
                                      </select>
                                    </th>
                                    <th>
                                      Skill Name&nbsp;&nbsp;
                                      <select
                                        onChange={this.Sort}
                                        id="Sort"
                                        className="btn"
                                        style={{
                                          borderColor: "white",
                                          color: "white",
                                          textAlign: "left",
                                          width: "20px",
                                          padding: "0",
                                        }}
                                      >
                                        <option
                                          className="drop-options"
                                          value=""
                                        >
                                          {" "}
                                        </option>
                                        <option
                                          className="drop-options"
                                          value="3"
                                        >
                                          {" "}
                                          A-Z
                                        </option>
                                        <option
                                          className="drop-options"
                                          value="4"
                                        >
                                          {" "}
                                          Z-A
                                        </option>
                                      </select>
                                    </th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Addskills.map((skill) => (
                                    <tr>
                                      <td>
                                        <input
                                          type="checkbox"
                                          name="Id_delete"
                                          onChange={this.handlecheckbox}
                                          defaultValue={skill.id}
                                        />
                                      </td>
                                      <td>{skill.category}</td>
                                      <td>{skill.skillName}</td>
                                      <td>
                                        <div className="dropdown">
                                          <MoreVertIcon />
                                          <div className="dropdown-content">
                                            <a
                                              href
                                              onClick={() =>
                                                this.Edit(skill.id)
                                              }
                                            >
                                              Edit
                                            </a>
                                            <span
                                              style={{ cursor: "cursor" }}
                                              onClick={() =>
                                                this.deleteSkill(skill.id)
                                              }
                                            >
                                              {" "}
                                              Delete{" "}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}{" "}
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
