import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import $ from "jquery";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CategoryPop from "../../Common/CategoryPop";
import EditCategory from "../../Common/EditCategory";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../../Config/Base";
import CategoryExl from "../../Assets/Files/CategoryExl.xlsx";
//
//        Skill master page to display from sidebar
var XLSX = require("xlsx");

$(document).ready(function () {
  $(".notfound").hide();

  // Search User columns
  $("#myInpcccut1").keyup(function () {
    // Search Text
    var search = $(this).val();

    // Hide all table tbody rows
    $("table tbody tr").hide();

    // Count total search result
    var len = $(
      'table tbody tr:not(.notfound) td:contains("' + search + '")'
    ).length;

    if (len > 0) {
      // Searching text in columns and show match row
      $('table tbody tr:not(.notfound) td:contains("' + search + '")').each(
        function () {
          $(this).closest("tr").show();
        }
      );
    } else {
      $(".notfound").show();
    }
  });

  $.expr[":"].contains = $.expr.createPseudo(function (arg) {
    return function (elem) {
      return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
  });
});
export default class Attendees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      skills: [],
      Addskills: [],
      Addcategory: [],
      Id: "",
      SkillName: "",
      SkillDescription: "",
      Category: "",
      Notification: [],
      skillsgun: "",
      catedrop: "",
      rows: [],
      cols: [],
      currentSheet: {},
      category: [],
      comlogo: [],
      Skillname: [],
      Sort: [],
      Skillfilt: [],
      CategoryModel: false,
      EditCategoryModel: false,
      post: [],
      number: "1",
      postPerPage: "10",
      EditCategoryValues: [],
      checkboxes: [
        {},
        // { id: 1, isChecked: false },
      ],
      selectAllChecked: false,
      Attendees: [],
      TrainingID: this.props.location.state.id,
      AttendeesUserId: [{ isChecked: false }],
      selectedUserID: [{}],
      checkboxValues: [],
      selectedCheckboxValues: [],
      AllCheckboxValue: [],
    };
    this.initialState = this.state;
  }

  ChangePage = ({ selected }) => {
    this.setState({ number: selected + 1 });
  };

  exportXlsx(filename) {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("deletcateg")) {
      var checkboxes = document.getElementsByName("deletcateg");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("Categorysheet");
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }

  GetAddSkill() {
    let url = `${BASE_URLs}Skill/MasterGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Addskills: data,
          Sort: data,
          Skillfilt: data,
        });
      });
  }

  CategoryModel = () => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("deletcateg")) {
      var checkboxes = document.getElementsByName("deletcateg");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    this.setState((prevState) => ({
      CategoryModel: !prevState.CategoryModel,
    }));
    this.Getcategory();
  };

  EditCategoryModel = (Id, category) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("deletcateg")) {
      var checkboxes = document.getElementsByName("deletcateg");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditCategoryValues: {
        Id: Id,
        category: category,
      },
    });

    this.setState((prevState) => ({
      EditCategoryModel: !prevState.EditCategoryModel,
    }));

    this.Getcategory();
  };

  GetSkill() {
    let url = `${BASE_URLs}Skill/Get`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          skills: data,
        });
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  getAttendies = () => {
    let url =
      `${BASE_URLs}Training/GetAttendeesList?TrainingID=` +
      this.state.TrainingID;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Attendees: data,
          AttendeesUserId: data,
        });
        // console.log("Learning", data);
        // console.log("AttendeesUserID", this.state.AttendeesUserId);
      });
  };

  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };
  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });
      });
  }

  componentDidMount() {
    this.getAttendies();
  }

  myFunction = (e) => {
    e.preventDefault();

    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("deletcateg")) {
      var checkboxes = document.getElementsByName("deletcateg");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    const category = this.state.Addcategory.filter((item) =>
      item.category.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ category });
  };

  handlecheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecount")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecount");
      let count = checkboxes.length - 1;
      if (document.getElementById("selectall").checked) {
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      } else {
        msg.innerHTML =
          checkboxes.length === 1
            ? "<span> " + checkboxes.length + " Record is selected </span> "
            : "<span> " + checkboxes.length + " Records are selected </span> ";
      }
    } else {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("selectall").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("selectall").checked = true;
      }
    } else {
      if (document.getElementById("selectall").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("selectall").checked = false;
        }
      }
    }
  }

  checkAll(e) {
    var checkboxes = document.getElementById("User_Id");

    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  Multidelete = (event) => {
    event.preventDefault();
    // console.log("Value", event.target);
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    const data = new FormData(event.target);
    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    fetch(`${BASE_URLs}Training/UpdateConfirmation`, options)
      .then((response) => response.json())
      .then((data) => {
        // this.Getcategory();
        // this.GetAddSkill();
        toast.success(data.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
        var checkboxes = document.querySelectorAll(
          'input[type="checkbox"]:checked'
        );

        for (var i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false;
        }
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    // Get the selected checkbox values from the state
    const { selectedCheckboxValues } = this.state;

    const formData = new FormData();

    // Append the selected checkbox values to the FormData
    selectedCheckboxValues.forEach((value) => {
      formData.append("User_Id", value);
    });
    formData.append("Training_Id", this.state.TrainingID);

    // Make the API request with the selected checkbox values
    fetch(`${BASE_URLs}Training/UpdateConfirmation`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        Accept: "application/json",
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((resp) => {
        this.setState({ status: resp.Status });
        this.setState({ message: resp.Message });
        this.SuccessModel();
      })

      .catch((error) => {
        console.error(error);
      });
  };

  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));
    this.props.history.push("/TrainerActiveTraining");
  };

  handleMultiselectChange = (checkboxId) => {
    this.setState((prevState) => ({
      selectedUserID: [...prevState.selectedUserID, checkboxId],
    }));

    // console.log("SelectedUserId's", this.state.selectedUserID);
  };

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState((prevState) => {
      const selectedCheckboxValues = checked
        ? [...prevState.selectedCheckboxValues, name]
        : prevState.selectedCheckboxValues.filter((value) => value !== name);

      // console.log("ValuesSelected", selectedCheckboxValues);
      return { selectedCheckboxValues };
    });
  };

  render() {
    var username = JSON.parse(localStorage.uname);
    const { category } = this.state;

    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <h4 className="title">Categories </h4>
                        </div>
                        {this.state.CategoryModel ? (
                          <CategoryPop
                            id="Success"
                            close={() => this.CategoryModel()}
                          />
                        ) : null}
                        {this.state.EditCategoryModel ? (
                          <EditCategory
                            id="Success"
                            values={this.state.EditCategoryValues}
                            close={() => this.EditCategoryModel("", "")}
                          />
                        ) : null}

                        <div className="card-block2">
                          <div
                            id="selectalldiv"
                            style={{ visibility: "hidden" }}
                          >
                            &nbsp;&nbsp;
                          </div>

                          <form onSubmit={this.handleSubmit}>
                            <button
                              type="button"
                              className="multitrash"
                              id="delete"
                              style={{ float: "right", visibility: "hidden" }}
                            ></button>

                            <span
                              id="deletecount"
                              className="multitrash"
                              style={{ visibility: "hidden", fontSize: "17px" }}
                            ></span>
                            <div
                              id="base-style_filter"
                              className="dataTables_filter"
                            >
                              <div
                                className="dt-responsive table-responsive table-res"
                                // style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}
                              >
                                {this.state.Attendees.length === 0 ? null : (
                                  <table
                                    id="skillsheet"
                                    className="table   nowrap SkillGrid"
                                  >
                                    <thead style={{ textAlign: "left" }}>
                                      <tr>
                                        <th>
                                          {" "}
                                          <input
                                            type="checkbox"
                                            id="selectall"
                                            onClick={() => {
                                              this.checkAll(this);
                                            }}
                                          />{" "}
                                        </th>
                                        <th>Attendees &nbsp;&nbsp;</th>

                                        {/* <th style={{ textAlign: "center" }}>Actions</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/* {this.state.Attendees.map(category =>
                                                                            <tr>
                                                                                 <td> <input type="checkbox" name="User_Id" onChange={this.handlecheckbox} defaultValue={checkboxId} /></td> 
                                                                                <td> <input type="checkbox" name="User_Id"
                                                                                    //  onChange={this.handleMultiselectChange} 
                                                                                    onChange={() => this.handleCheckboxChange(category.userId)}
                                                                                    defaultValue={category.userId} /></td> */}

                                      {this.state.Attendees.map((value) => (
                                        <tr>
                                          <td>
                                            <label key={value}>
                                              {/* {value.userId} */}

                                              <input
                                                type="checkbox"
                                                name={value.userId}
                                                Id="User_Id"
                                                // checked={this.state.selectedCheckboxValues.includes(value)}
                                                onChange={
                                                  this.handleCheckboxChange
                                                }
                                                defaultValue={value.userId}
                                              />
                                            </label>
                                          </td>

                                          <td>{value.displayName}</td>
                                          <span
                                            name="Training_ID"
                                            visibility="false"
                                            defaultValue={this.state.TrainingID}
                                          ></span>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                )}
                              </div>
                              <div className="row">
                                <div
                                  className="col-sm-12"
                                  style={{ textAlign: "center" }}
                                >
                                  <button
                                    type="submit"
                                    className="but "
                                    style={{
                                      height: "39.5px",
                                      width: "86.36px",
                                      marginTop: "-0.3%",
                                    }}
                                  >
                                    Confirm
                                  </button>
                                  &nbsp; &nbsp; &nbsp;
                                </div>
                              </div>
                            </div>

                            <br />

                            {/* for Exporting Excel */}
                            <table
                              id="Categorysheet"
                              className="table   nowrap SkillGrid"
                              hidden
                            >
                              <thead style={{ textAlign: "left" }}>
                                <tr>
                                  <th style={{ textDecoration: "bold" }}>
                                    Category &nbsp;&nbsp;
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {category.map((category) => (
                                  <tr>
                                    <td>{category.category}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>

                            <br />
                            <br />
                            <br />
                            <br />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
