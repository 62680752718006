import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";

export default class AddBusinessPop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      ButtonDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var Business = document.getElementById("BusinessUnit").value;

    if (!Business) {
      formIsValid = false;
      formErrors["BusinessUnit"] = "Business Unit  is required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };
  GetBusiness() {
    let url = `${BASE_URLs}Business/BusinessGet`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Business: data,
        });
      });
  }

  componentDidMount() {
    this.GetBusiness();
  }

  handleSubmit(event) {
    var Business = document.getElementById("BusinessUnit").value;
    event.preventDefault();
    if (this.Validation()) {
      this.setState({
        ButtonDisabled: true,
      });
      const data = new FormData();
      data.append("BusinessUnit", Business);
      fetch(`${BASE_URLs}Business/AddBusiness`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // 'Accept': 'application/json',
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({
              ButtonDisabled: false,
            });
            document.getElementById("Businesspop").style.display = "none";

            setTimeout(function () {
              if (document.getElementById("close")) {
                document.getElementById("close").click();
              }
            }, 4000);
            this.GetBusiness();
            // document.getElementById("popup").style.display = "none"
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  }

  // handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault();
  //   }
  // };

  // handleKeyPress1 = (event)=>{
  //   if (event.charCode === 13) {
  //   event.preventDefault();
  //   if (this.Validation()) {
  //     const data = new FormData();

  //     fetch(`${BASE_URLs}Business/AddBusiness", {
  //       method: 'POST',
  //       headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //         // 'Accept': 'application/json',
  //         // 'Content-Type': 'application/json',
  //         Authorization: `Bearer ${JSON.parse(localStorage.user)}`
  //       },
  //       body: data,
  //     }).then(res => res.json()).then((resp) => {
  //       if (resp.status === "Success") {
  //         toast.success(resp.message, {
  //           position: "top-center",
  //           autoClose: 4000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //         setTimeout(function () { if(document.getElementById("close")){document.getElementById("close").click();} }, 2000);
  //         this.GetBusiness();
  //         document.getElementById("popup").style.display = "none"
  //       }
  //       else {
  //         toast.error(resp.message, {
  //           position: "top-center",
  //           autoClose: 4000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       }
  //     })

  //   }
  // }
  // }

  render() {
    var w = window.innerWidth;

    const { Business } = this.state.formErrors;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop" id="popup">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="Businesspop"
          >
            <form autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
              <button
                id="close"
                style={{
                  float: "right",
                  backgroundColor: "#a0a3a305",
                  color: "#797070",
                  border: "1px solid #a3a5a505",
                }}
                onClick={this.props.close}
              >
                <CloseIcon />
              </button>
              <div className="row" style={{ padding: "8px" }}>
                <div className="col-lg-3"></div>
                <div className="col-lg-8">
                  <input
                    className="form"
                    maxLength="50"
                    onKeyDown={this._handleKeyDown}
                    id="BusinessUnit"
                    name="BusinessUnit"
                    type="text"
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress1}
                  />
                  {Business == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Add Business
                      <span style={{ color: "red", float: "left" }}></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {Business}
                    </div>
                  )}{" "}
                  <span className="underline" />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    disabled={this.state.ButtonDisabled}
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
