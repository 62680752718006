import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base";
export default class SkillMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edus: [],
      Id: "",
      UG: "",
      PG: "",
      Specialization: "",
      formErrors: {},
      Notification: [],
      value: this.props.location.state,
      Details: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.initialState = this.state;
  }

  handleFormValidation() {
    let ug = document.getElementById("ugv").value;
    let Specialization = document.getElementById("Specialization").value;
    let formErrors = {};
    let formIsValid = true;

    if (!ug) {
      formIsValid = false;
      formErrors["ugErr"] = "CourseName is required";
    }
    if (!Specialization) {
      formIsValid = false;
      formErrors["specsErr"] = "Specialization is required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    if (this.handleFormValidation()) {
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(`${BASE_URLs}Education/EditUG`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          /*  Authorization: `Bearer ${JSON.parse(localStorage.user)}` */
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          alert(resp);
          //alert(resp.status);

          if (resp === "Education Updated Successfully") {
            this.props.history.push("/EducationGrid");
          }
        });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };
  componentDidMount() {
    this.GetUserEducation();
  }

  HandleUg() {
    var UG = document.getElementById("ug").value;

    if (UG === "others") {
      document.getElementById("ugtext").value = "";
      document.getElementById("ugtext").type = "text";
    } else {
      document.getElementById("ugtext").value = "None";
      document.getElementById("ugtext").type = "hidden";
    }
  }

  HandlePg() {
    var UG = document.getElementById("pg").value;

    if (UG === "others") {
      document.getElementById("pgtext").value = "";
      document.getElementById("pgtext").type = "text";
    } else {
      document.getElementById("pgtext").value = "None";
      document.getElementById("pgtext").type = "hidden";
    }
  }
  GetUserEducation() {
    var Id = this.props.location.state.Id;
    let url = `${BASE_URLs}Education/GetUserEducat?Id=` + Id;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Details: data,
        });
      });
  }

  render() {
    const { ugErr, specsErr } = this.state.formErrors;
    const { Details } = this.state;
    return (
      <div>
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header"></div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title">Edit</h4>
                        </div>
                        <div className="card-header-right"></div>

                        <div className="card-block2">
                          <form
                            style={{ marginTop: "5%" }}
                            onSubmit={this.handleSubmit}
                          >
                            <div
                              className="container"
                              style={{ margin: "0 35%" }}
                            >
                              <input
                                className="form"
                                name="Degree"
                                id="ug"
                                onChange={this.handleChange}
                                type="hidden"
                              />
                              <div className="row">
                                {Details.map((Details) => (
                                  <div className="col-3 input-effect">
                                    <input
                                      className="form"
                                      defaultValue={Details.courseName}
                                      id="ugv"
                                      name="CourseName"
                                      onChange={this.handleChange}
                                      type="text"
                                    />
                                    <input
                                      className="form"
                                      name="Id"
                                      value={Details.id}
                                      onChange={this.handleChange}
                                      type="hidden"
                                    />
                                    {!ugErr ? (
                                      <span style={{ color: "#a29d9d" }}>
                                        CourseName
                                      </span>
                                    ) : (
                                      <div style={{ color: "red" }}>
                                        {ugErr}
                                      </div>
                                    )}

                                    <span className="underline" />
                                  </div>
                                ))}
                              </div>
                              <div className="row">
                                <div className="col-3 input-effect">
                                  {Details.map((Details) => (
                                    <input
                                      className="form"
                                      id="Specialization"
                                      name="Specialization"
                                      type="text"
                                      defaultValue={Details.specialization}
                                      onChange={this.handleChange}
                                    />
                                  ))}
                                  {!specsErr ? (
                                    <span style={{ color: "#a29d9d" }}>
                                      Specialization
                                    </span>
                                  ) : (
                                    <div style={{ color: "red" }}>
                                      {specsErr}
                                    </div>
                                  )}

                                  <span className="underline" />
                                </div>
                              </div>
                              <br />
                              <button
                                type="reset"
                                className=" btn-Secondary"
                                style={{ margin: "0px 80px" }}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="but"
                                style={{
                                  margin: "2px -63px",
                                  height: "40.4px",
                                  width: "83.36px",
                                }}
                              >
                                Save
                              </button>
                              <br /> <br />
                              {/* onClick={() => {this.handleSubmit()}}    <button onClick={()=>this.props.history.goBack()}>Back</button> */}
                            </div>
                          </form>
                          <br /> <br /> <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
