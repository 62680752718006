import { React, useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PlusOne from '@material-ui/icons/Add';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddTrainingAddDesignation = ({ closeThePopup, array, state }) => {
  const [Designation, setDesignation] = useState(array);
  const [Selectall, setSelectall] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredDesignation, setfilteredDesignation] = useState(array);

  const [SelectedIds, setSelectedIds] = useState([]);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);

    const data = searchJobs(e.target.value);
    setfilteredDesignation(data);
    console.log('Hello World', data);
  };

  const searchJobs = (keyword) => {
    const results = [];

    // First, search for an exact match and push it to results
    const exactMatch = Designation.find((job) =>
      job.designation.toLowerCase().startsWith(keyword.toLowerCase())
    );
    if (exactMatch) {
      results.push(exactMatch);
    }

    // Then, search for partial matches and push them to results
    // const exactMatch = jobList.find(job => job.designation.toLowerCase().startsWith(keyword.toLowerCase()));

    const partialMatches = Designation.filter(
      (job) =>
        job.designation.toLowerCase().includes(keyword.toLowerCase()) &&
        job !== exactMatch
    );
    results.push(...partialMatches);
    return results;
  };

  const handleChange = (event, id) => {
    let change = filteredDesignation.map((e, index) => {
      if (id === e.id) {
        return { ...e, ...{ check: event.target.checked } };
      } else {
        //  const result = SelectedIds.filter((e)=>e!==id);
        //  console.log("result",result);
        // setSelectedIds(result)
      }
      return { ...e };
    });
    let change1 = Designation.map((e, index) => {
      if (id === e.id) {
        return { ...e, ...{ check: event.target.checked } };
      } else {
        //  const result = SelectedIds.filter((e)=>e!==id);
        //  console.log("result",result);
        // setSelectedIds(result)
      }
      return { ...e };
    });

    change.length === change.filter((e) => e.check === true).length
      ? setSelectall(true)
      : setSelectall(false);

    setDesignation(change1);
    setfilteredDesignation(change);
  };

  function validateCheckbox() {
    console.log('SelectedIds', SelectedIds);
    let status = filteredDesignation.filter((e) => e.check === true);
    if (status.length == 0) {
      toast.error('Please select at least one Designation', {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      state(Designation);
      closeThePopup();
    }
  }

  return (
    //   <div className="pop">
    //   <div className="popinnernormalss">
    //     <form autoComplete="off" encType="multipart/form-data">
    //       <button
    //         style={{
    //           float: "right",
    //           backgroundColor: "#a0a3a305",
    //           color: "#797070",
    //           border: "1px solid #a3a5a505",
    //         }}
    //         onClick={closeThePopup}
    //       >
    //         <CloseIcon />
    //       </button>

    //       <div className="row" style={{ padding: "8px" }}>
    //         <div className="col-sm-3"></div>
    //         <div className="col-sm-8">
    //           <div class="DesignationPophHeader">Designation</div>

    //           {/* <div className="col-sm-4 col-md-4 col-lg-4"> */}
    //             <div className="search-box">
    //               <input
    //                 className="form"
    //                 type="text"
    //                 placeholder="Search..."
    //                 onChange={handleSearchChange}
    //               />
    //             </div>
    //           {/* </div> */}

    //           <div className="dt-responsive table-responsive table-res" id="DesignationPop">

    //             {filteredDesignation.length === 0 ? (
    //               "There are no ' Designations '"
    //             ) : (
    //               <table
    //                 id="DepSheet"
    //                 className="table nowrap"
    //                 style={{ maxWidth: "100%", marginTop: "12%" }}
    //               >

    //                 {/* <thead>
    //                   <tr>
    //                     <th>
    //                       <input
    //                         type="checkbox"
    //                         checked={Selectall}
    //                         onChange={(e) => handleSelectAll(e)}
    //                       />
    //                     </th>
    //                     <th>Designation</th>
    //                   </tr>
    //                 </thead> */}
    //                 <tbody>
    //                   <tr><th></th></tr>
    //                   {filteredDesignation.map((Designation, i) => (
    //                     <tr key={i}>
    //                       <td>
    //                         <input
    //                           type="checkbox"
    //                           value={Designation.id + "$$$" + Designation.designation}
    //                           checked={Designation.check}
    //                           onChange={(e) => handleChange(i, e)}
    //                         />
    //                       </td>
    //                       <td>{Designation.designation}</td>
    //                     </tr>
    //                   ))}
    //                 </tbody>
    //               </table>
    //             )}
    //           </div>
    //           <div class="DesigantionPopFooter">
    //             <button
    //               className="but"
    //               style={{
    //                 height: "39.5px",
    //                 width: "86.36px",
    //                 marginTop: "-1%",
    //               }}
    //               onClick={closeThePopup}
    //             >
    //               Add
    //             </button>
    //           </div>
    //         </div>
    //         <div className="col-sm-4"></div>
    //       </div>

    //       <div className="row">
    //         <div className="col-sm-12" style={{ textAlign: "center" }}></div>
    //       </div>
    //     </form>
    //     <br />
    //   </div>
    // </div>

    <div class="popup" id="myPopup">
      <div class="popup-contentss">
        <div class="popup-headers">
          <b>Designation</b>
          <br />
          <br />
          <input
            className="form"
            type="text"
            placeholder="Search..."
            onChange={handleSearchChange}
            style={{ width: '51%', marginLeft: 'auto', marginRight: 'auto' }}
          />
          {/* )} */}
          <div style={{ marginTop: '5px' }}></div>
          <div class="popup-close" onClick={closeThePopup}>
            {' '}
            <CloseIcon />
          </div>
        </div>
        <div class="popup-bodysss">
          {filteredDesignation.length === 0 ? (
            <p style={{ marginTop: '70px', marginLeft: '-77px' }}>
              {' '}
              "There are no ' Designations '"
            </p>
          ) : (
            <table
              id="DepSheet"
              className="table nowrap mx-auto"
              style={{ maxWidth: '100%' }}
            >
              <tbody>
                {/* <tr><th></th></tr> */}
                {filteredDesignation.map((Designation, i) => (
                  <tr key={i}>
                    <td>
                      <input
                        type="checkbox"
                        value={Designation.id + '$$$' + Designation.designation}
                        checked={Designation.check}
                        onChange={(e) => handleChange(e, Designation.id)}
                      />
                    </td>
                    <td>{Designation.designation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <div class="popup-footer">
          <div></div>
          {filteredDesignation.length === 0 ? null : (
            <button
              className="but"
              type="button"
              id="Savebut"
              onClick={validateCheckbox}
            >
              Assign
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default AddTrainingAddDesignation;
