import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import AddTraineePop from "../../Common/AddTraineePop";
import AddTraineeExistingPop from "../../Common/AddTraineeExistingPop";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../../Config/Base";
export default class EditActiveTrainer extends Component {
  constructor() {
    super();

    const currentDate = new Date().toISOString().slice(0, 10); // Get current date in "YYYY-MM-DD" format

    this.state = {
      selectedDate: currentDate,
      formErrors: {},
      Details: [],
      Training: [],
      FileDocs: [],
      Departmentdrop: [],
      enabled: false,
      EditSkillModel: false,
      imageName: "",
      EditModel: false,
      EditManagerValues: [],
      selectedOption: "",
      isOpen: false,
      AllTraineePopModel: false,
      AssignedTraineePopModel: false,
      data: {},
      error: null,
      showToastMessage: false,
      inputValue: "",
    };
  }

  handleInputChange = (event) => {
    this.setState({ inputValue: event.target.value });
  };

  postReasonToAdmin = async (event) => {
    event.preventDefault();
    const { authorizationToken } = this.props;
    const url = `${BASE_URLs}Notification/SendReasonNotification`;
    const data = { description: document.getElementById("Reason").value };

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      });
      const responseData = response.data;
      this.setState({ data: responseData, error: null });
      // // console.log("Request",data.message)
      toast.success("Reason sent to Admin successfully!");
      this.setState({ showToastMessage: true });
      // Redirect to another page after 3 seconds (3000 milliseconds)

      setTimeout(() => {
        this.props.history.push("/TrainerActiveTraining");
      }, 3000);
    } catch (error) {
      this.setState({ data: {}, error: error.message });
      toast.error("Failed to send reason!");
    }
  };

  AddUserDetails = async (event) => {
    event.preventDefault();
    const { authorizationToken } = this.props;
    const url = `${BASE_URLs}Training/StoreUserDetails`;
    const data = {
      MeetingLink: document.getElementById("MeetingLink").value,
      TrainerConfirm: document.getElementById("TrainerConfirm").value,
      TrainingId: document.getElementById("TrainingId").value,
      StartDate: document.getElementById("start").value,
    };

    // console.log("PostedData", data);

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      });
      const responseData = response.data;
      this.setState({ data: responseData, error: null });
      // // console.log("Request",data.message)
      toast.success("User Joined successfully!");
      this.setState({ showToastMessage: true });
      // Redirect to another page after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        const newTab = window.open(
          document.getElementById("MeetingLink").value,
          "_blank"
        );
        newTab.focus();
      }, 3000);
    } catch (error) {
      this.setState({ data: {}, error: error.message });
      toast.error("Failed to Join!");

      // console.log("error", error);
    }
  };

  componentDidMount() {
    window.addEventListener("load", this.Getloggeddet);
    this.Getloggeddet();
  }

  GetCourseName() {
    let url = `${BASE_URLs}Course/GetCourseName`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          CourseName: data,
          Course: data,
        });

        //
      });
  }

  handleSelectChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  openPopup = () => {
    this.setState({ isOpen: true });
  };

  closePopup = () => {
    this.setState({ isOpen: false });
  };

  Attendees = () => {
    var TrainingID = this.props.location.state.id;
    var page = {
      pathname: "/Attendees",
      state: {
        id: TrainingID,
      },
    };
    this.props.history.push(page);
    // console.log("AttendeesID", TrainingID);
  };
  AllTraineePopModel = () => {
    this.setState((prevState) => ({
      AllTraineePopModel: !prevState.AllTraineePopModel,
    }));
  };
  AssignedTraineePopModel = () => {
    this.setState((prevState) => ({
      AssignedTraineePopModel: !prevState.AssignedTraineePopModel,
    }));

    // this.setState({ AssignedTraineePopModel: true });
  };

  Getloggeddet = async (event) => {
    let Department = JSON.parse(localStorage.getItem("deptofloggeduser"));
    // var Training_Id = this.props.location.state.training_Id;
    // if(this.props.location.state.training_Id == 0 || this.props.location.state.Id != 0)
    //   {s
    //// console.log("bjdbfkdsf", this.props.location.state.training_Id);
    // localStorage.setItem('training_Id', JSON.stringify(this.props.location.state.training_Id));
    // console.log("bjdbfkdsf", this.props.location.state.id);
    localStorage.setItem(
      "training_Id",
      JSON.stringify(this.props.location.state.Id)
    );
    let roleofloggeduser = localStorage.getItem("roleofloggeduser");
    let training_Id = this.props.location.state.id;
    //     if(Department != null && roleofloggeduser=='User')
    //   {

    let url = `${BASE_URLs}Trainer/GetActiveTrainerId?Id=` + training_Id;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    // console.log(accessToken);
    await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Details: data,
        });

        // console.log("My User Data", data);
        //  localStorage.setItem('deptofloggeduser', JSON.stringify(data[0].department));
        //localStorage.setItem('roleofloggeduser', JSON.stringify(data[0].trainer_Info));
      });
    //   }
  };

  render() {
    const { Details, selectedOption, isOpen } = this.state;
    var Role = localStorage.roleofloggeduser;
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.hash} />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">
                            Active Training
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        {this.state.AllTraineePopModel ? (
                          <AddTraineePop
                            id="Success"
                            close={() => this.AllTraineePopModel()}
                          />
                        ) : null}
                        {this.state.AssignedTraineePopModel ? (
                          <AddTraineeExistingPop
                            id="ExistingPop"
                            close={() => this.AssignedTraineePopModel()}
                          />
                        ) : null}
                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                          >
                            <div className="row" style={{ marginTop: "3%" }}>
                              <div className="container">
                                {/* <div className="col btnalign">
                                                                    <select value={this.state.selectedOption} onChange={this.handleSelectChange}>
                                                                        <option value="Trainer">Trainer</option>
                                                                        <option value="User">User</option>
                                                                    </select>
                                                                </div> */}

                                <div className="container">
                                  {/* <input type="text" defaultValue={this.props.location.state.training_Id} /> */}

                                  <div
                                    className="row"
                                    style={{ padding: "8px" }}
                                  >
                                    {/* {Details.map((detail) => (
                                                                            <div className="col-sm-6" >
                                                                                <input className="effect-16" name="TrainingItem" defaultValue={detail.trainingitem} id="trainingitem" type="text" maxLength="50" placeholder='Training Item'
                                                                                />
                                                                                <span style={{ color: '#a29d9d' }} >Training Item</span> <span style={{ color: "red" }}></span>

                                                                            </div>
                                                                        ))}
                                                                        <div className="col-sm-6" >
                                                                            {Details.map((detail) => (
                                                                                <input className="effect-16" name="Department" id="department" type="text" defaultValue={detail.department} maxLength="50" placeholder='Department'
                                                                                />
                                                                            ))}
                                                                            <span style={{ color: '#a29d9d' }} >Department</span> <span style={{ color: "red" }}></span>

                                                                        </div> */}

                                    {(() => {
                                      if (Role == "User") {
                                        return (
                                          <div className="col-sm-6">
                                            {Details.map((detail) => (
                                              <input
                                                className="effect-16"
                                                name="TrainingId"
                                                id="TrainingId"
                                                type="text"
                                                defaultValue={detail.id}
                                                maxLength="50"
                                                placeholder="Trainer Name"
                                              />
                                            ))}
                                            <span style={{ color: "#a29d9d" }}>
                                              Training Id
                                            </span>{" "}
                                            <span
                                              style={{ color: "red" }}
                                            ></span>
                                          </div>
                                        );
                                      } else {
                                        return <div></div>;
                                      }
                                    })()}

                                    <div className="col-sm-6">
                                      {/* {Details.map((detail) => ( */}
                                      <input
                                        className="effect-16"
                                        name="StartDate"
                                        id="start"
                                        type="date"
                                        maxLength="50"
                                        value={this.state.selectedDate}
                                      />
                                      {/* ))} */}
                                      <span style={{ color: "#a29d9d" }}>
                                        Join Date
                                      </span>{" "}
                                      <span style={{ color: "red" }}></span>
                                    </div>

                                    {/* {Details.map((detail) => (
                                                                            <div className="col-sm-6" >
                                                                                <div></div>
                                                                                <input className="effect-16" name="EndDate" id="end" type="date" onFocus={this.myDate} maxLength="50"
                                                                                    defaultValue={detail.endDate.slice(0, 10)}
                                                                                />
                                                                                <span style={{ color: '#a29d9d' }} >End Date</span> <span style={{ color: "red" }}>*</span>

                                                                            </div>
                                                                        ))} */}

                                    <div className="col-sm-6">
                                      {Details.map((detail) => (
                                        <input
                                          className="effect-16"
                                          name="MeetingLink"
                                          id="MeetingLink"
                                          type="text"
                                          defaultValue={detail.meetingLink}
                                        />
                                      ))}
                                      <span style={{ color: "#a29d9d" }}>
                                        Meeting Link
                                      </span>{" "}
                                      <span style={{ color: "red" }}></span>
                                    </div>

                                    <div className="col-sm-6">
                                      {/* {Details.map((detail) => ( */}
                                      <input
                                        className="effect-16"
                                        name="TrainerConfirm"
                                        id="TrainerConfirm"
                                        type="text"
                                        defaultValue="false"
                                      />
                                      {/* ))} */}
                                      <span style={{ color: "#a29d9d" }}>
                                        Trainer Confirm
                                      </span>{" "}
                                      <span style={{ color: "red" }}></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                            {(() => {
                              if (Role == "User") {
                                return (
                                  <div>
                                    <div className="row">
                                      <div
                                        className="col-sm-12"
                                        style={{ textAlign: "center" }}
                                      >
                                        <button
                                          type="submit"
                                          className="but "
                                          style={{
                                            height: "39.5px",
                                            width: "86.36px",
                                            marginTop: "-0.3%",
                                          }}
                                          onClick={this.AddUserDetails}
                                        >
                                          JOIN
                                        </button>
                                        &nbsp; &nbsp; &nbsp;
                                      </div>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div>
                                    <div className="row">
                                      <div
                                        className="col-sm-12"
                                        style={{ textAlign: "center" }}
                                      >
                                        {/* <button>  <Link to='/AddTraineePop' type="button" style={{ width: "150px", background: "white" }} className="btn ">Add Trainees </Link></button>&nbsp;  &nbsp;  &nbsp; */}
                                        <button
                                          type="button"
                                          className="but "
                                          onClick={() =>
                                            this.AllTraineePopModel()
                                          }
                                          style={{
                                            height: "39.5px",
                                            width: "150px",
                                            marginTop: "-0.3%",
                                          }}
                                        >
                                          <Link style={{ color: "white" }}>
                                            Add More Trainees
                                          </Link>
                                        </button>
                                        &nbsp; &nbsp; &nbsp;
                                        <button
                                          type="button"
                                          className="but "
                                          onClick={this.openPopup}
                                          style={{
                                            height: "39.5px",
                                            width: "86.36px",
                                            marginTop: "-0.3%",
                                          }}
                                        >
                                          Cancel
                                        </button>
                                        &nbsp; &nbsp; &nbsp;
                                        {/* <button type="deny" className="but " style={{ height: '39.5px', width: '86.36px', marginTop: "-0.3%" }}><Link to="/Attendees" style={{ color: "white" }}>Attendees</Link></button>&nbsp;  &nbsp;  &nbsp; */}
                                        <button
                                          type="deny"
                                          className="but "
                                          style={{
                                            height: "39.5px",
                                            width: "86.36px",
                                            marginTop: "-0.3%",
                                          }}
                                          onClick={this.Attendees}
                                        >
                                          Attendees
                                        </button>
                                        &nbsp; &nbsp; &nbsp;
                                        <button
                                          type="button"
                                          className="but "
                                          onClick={() =>
                                            this.AssignedTraineePopModel()
                                          }
                                          style={{
                                            height: "39.5px",
                                            width: "150px",
                                            marginTop: "-0.3%",
                                          }}
                                        >
                                          <Link style={{ color: "white" }}>
                                            Assigned Trainees
                                          </Link>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })()}
                            <br /> <br />
                            <br /> <br />
                          </form>
                        </div>
                        {/* <div className="dt-responsive table-responsive table-res">

                                    <table id="DepartSheet" className="table nowrap" style={{ maxWidth: "70%" }}>
                                        <thead>
                                            <tr>
                                                <th>Trainer Name</th>
                                                <th>Department</th>
                                                <th>Session</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {Trainername.map(Department => <tr>
                                                <td>{Department.trainerName}</td>
                                                <td>{Department.department}</td>
                                                <td>{Department.trainingItem}</td>
                                                <td >{Department.startDate}</td>
                                                <td>{Department.endDate}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>

                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="pop">
            <div className="popinnernormal">
              <form
                autoComplete="off"
                encType="application/json-patch+json"
                onSubmit={this.postReasonToAdmin}
              >
                <button
                  style={{
                    float: "right",
                    backgroundColor: "#a0a3a305",
                    color: "#797070",
                    border: "1px solid #a3a5a505",
                  }}
                  onClick={this.closePopup}
                >
                  <CloseIcon />
                </button>

                <div className="row" style={{ padding: "8px" }}>
                  <div className="col-sm-3"></div>
                  <div className="col-sm-8">
                    <input
                      className="form"
                      maxLength="50"
                      id="Reason"
                      name="Reason"
                      type="text"
                    />
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Reason
                    </span>
                    <div style={{ color: "red", float: "left" }}> </div>
                    {/* <input type="hidden" name="otherdepartment" className="form" id="departmenttext" /> */}
                    <div style={{ color: "red", float: "left" }}></div>
                    <span className="underline" />
                  </div>
                  <div className="col-sm-4"></div>
                </div>

                <div className="row">
                  <div className="col-sm-12" style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      className="but"
                      style={{
                        height: "39.5px",
                        width: "130px",
                        marginTop: "-0.3%",
                      }}
                    >
                      Request to Admin
                    </button>
                  </div>
                </div>
              </form>
              <br />
            </div>
          </div>
        )}
      </div>
    );
  }
}
