import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import "../../Css/Addskills.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Style } from "@material-ui/icons";
import { BASE_URLs } from "../../Config/Base";
export default class AddLearning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Training: [],
      Trainername: [],
      Department: [],
      plainArray: ["Arul", "Vimal", "Shaarik", "Azar"],
      objectArray: [
        { key: "Arul", id: "1" },
        { key: "Vimal", id: "1" },
        { key: "Shaarik", id: "2" },
        { key: "Azar", id: "2" },
      ],
      selectedValues: [
        { key: "Arul", id: "1" },
        { key: "Vimal", id: "1" },
      ],
      selectValue: "",
      startDate: null,
      endDate: null,
      filteredData: [],
      selectTrainerName: [],
      trainerName: [],
      filteredData: [],
      searchName: [],
    };
    this.myDate = this.myDate.bind(this);
  }

  handleChange = (e) => {
    this.setState({ selectValue: e.target.value });
  };

  myDate = () => {
    const startDate = document.getElementById("start").value;
    const duration = document.getElementById("duration").value;
    const Dates = document.getElementById("end").value;

    if (this.state.selectValue === "1 Week") {
      const date = new Date(startDate);
      // console.log(duration);

      const newDate = this.addDays1(date, duration);
      // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      // console.log(dateFormat);

      document.getElementById("end").value = dateFormat;
    } else if (this.state.selectValue === "2 Week") {
      const date = new Date(startDate);
      // console.log(duration);

      const newDate = this.addDays2(date, duration);
      // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      // console.log(dateFormat);

      document.getElementById("end").value = dateFormat;
    } else if (this.state.selectValue === "3 Week") {
      const date = new Date(startDate);
      // console.log(duration);

      const newDate = this.addDays3(date, duration);
      // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      // console.log(dateFormat);

      document.getElementById("end").value = dateFormat;
    } else if (this.state.selectValue === "4 Week") {
      const date = new Date(startDate);
      // console.log(duration);

      const newDate = this.addDays4(date, duration);
      // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      // console.log(dateFormat);
      document.getElementById("end").value = dateFormat;
    } else {
      const date = new Date(Dates);
      // console.log(duration);

      const newDate = this.addDays5(date, duration);
      // console.log(newDate);

      const getYear = date.toLocaleString("default", { year: "numeric" });
      const getMonth = date.toLocaleString("default", { month: "2-digit" });
      const getDay = date.toLocaleString("default", { day: "2-digit" });
      const dateFormat = getYear + "-" + getMonth + "-" + getDay;
      // console.log(dateFormat);
      document.getElementById("end").value = dateFormat;
    }
  };

  addDays1 = (date) => {
    date.setDate(date.getDate() + 7);
    return date;
  };

  addDays2 = (date) => {
    date.setDate(date.getDate() + 14);
    return date;
  };
  addDays3 = (date) => {
    date.setDate(date.getDate() + 21);
    return date;
  };
  addDays4 = (date) => {
    date.setDate(date.getDate() + 28);
    return date;
  };
  addDays5 = (date) => {
    date.setDate(date.getDate() + this.state.selectValue);
    return date;
  };

  fetchData = async () => {
    const { selectTrainerName, startDate, endDate } = this.state;
    var PropsDept = this.props.location.state.department;
    let url = `${BASE_URLs}Learning/Trainers?startDate=${this.state.startDate}&endDate=${this.state.endDate}&department=${PropsDept}`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    const reqData = await fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer" + accessToken,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "Access-Control-Request-Headers",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    });
    const resData = await reqData.json();
    for (let i = 0; i < resData.length; i++) {
      selectTrainerName.push(resData[i].trainerName);
      // console.log(resData[i]);
      // console.log(resData[i].trainerName);
    }
    this.setState({
      trainerName: resData,
    });
    // // // this.setState({
    // // //     selectTrainerName: filteredData,
    // // // })
    // // // const filteredData = selectTrainerName.filter((value, index, self) => {
    // // //     return self.find(item => item === value) === index;
    // // // });

    // // const searchName = this.props.location.state.department;
    // // const filteredData = reqData.filter(item =>
    // //     item.department.toLowerCase().includes(searchName.toLowerCase())
    // // );
    // this.setState({ filteredData, searchName });

    return selectTrainerName;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    // if (this.Validation()) {
    var role = JSON.parse(localStorage.Role);
    const url = `${BASE_URLs}Training/AddAssign`;
    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        ContentType: "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    const response = await fetch(url, options);
    const result = await response.json();

    if (result.status === "Success") {
      toast.success(result.message, {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // }
    //  this.GetCourseName();
  };

  GetTrainername() {
    let url = `${BASE_URLs}Training/GetTrainerName`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Trainername: data,
        });
        // console.log(data, "Trainers");
      });
  }

  Getdetail() {
    var Id = this.props.location.state.training_Id;
    // var upload_id = '1029'
    let url = `${BASE_URLs}Training/GetAssignTraining?training_Id=` + Id;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((result) => result.json())
      .then((data) => {
        this.setState({
          Training: data,
        });
        // console.log(data);
      });
  }

  handledepartment = () => {
    const Training_Id = document.getElementById("Trainer_Id").value;
    // console.log("asdfg", Training_Id);
    const data = {
      training_Id: Training_Id,
    };
    // console.log("asdfg", data);
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(`${BASE_URLs}Training/SelectTrainerDetail`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((data) => {
        this.setState({
          Department: data,
        });
      })

      // .then(response => {
      //     // console.log(response.data, "dfghjkl;");
      //      department(response.data);
      // })
      .catch((error) => {
        // console.log(error);
      });
  };

  componentDidMount() {
    this.Getdetail();
    this.GetTrainername();
    this.fetchData();
  }

  render() {
    const { Training, Trainername, selectTrainerName, trainerName } =
      this.state;
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.hash} />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">
                            Assign
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="row" style={{ marginTop: "3%" }}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12">
                                    {Training.map((Training) => (
                                      <>
                                        {" "}
                                        <input
                                          type="text"
                                          hidden
                                          name="Training_Id"
                                          defaultValue={Training.training_Id}
                                        />
                                      </>
                                    ))}
                                    {Training.map((Training) => (
                                      <>
                                        {" "}
                                        <input
                                          type="text"
                                          hidden
                                          name="Department"
                                          defaultValue={Training.department}
                                        />
                                      </>
                                    ))}
                                    {Training.map((Training) => (
                                      <>
                                        {" "}
                                        <input
                                          type="text"
                                          hidden
                                          name="Trainingitem"
                                          defaultValue={Training.trainingitem}
                                        />
                                      </>
                                    ))}

                                    <div className="container">
                                      {/* <input type="text" defaultValue={this.props.location.state.training_Id} /> */}

                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            name="StartDate"
                                            id="start"
                                            type="date"
                                            maxLength="50"
                                            selected={this.state.startDate}
                                            onChange={(e) =>
                                              this.setState({
                                                startDate: e.target.value,
                                              })
                                            }
                                          />
                                          <span style={{ color: "#a29d9d" }}>
                                            Start Date
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>
                                        <div className="col-sm-6">
                                          <select
                                            className="effect-16"
                                            name="duration"
                                            type="text"
                                            onChange={this.handleChange}
                                          >
                                            <option value="">Select</option>
                                            <option
                                              value="1 Week"
                                              id="duration"
                                            >
                                              1 Week
                                            </option>
                                            <option
                                              value="2 Week"
                                              id="duration"
                                            >
                                              2 Week
                                            </option>
                                            <option
                                              value="3 Week"
                                              id="duration"
                                            >
                                              3 Week
                                            </option>
                                            <option
                                              value="4 Week"
                                              id="duration"
                                            >
                                              4 Week
                                            </option>
                                            <option
                                              value="others"
                                              id="duration"
                                            >
                                              Others
                                            </option>
                                          </select>
                                          <span style={{ color: "#a29d9d" }}>
                                            Duration of Training
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>
                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            name="EndDate"
                                            id="end"
                                            type="date"
                                            onFocus={this.myDate}
                                            maxLength="50"
                                            selected={this.state.endDate}
                                            onFocusCapture={(e) =>
                                              this.setState({
                                                endDate: e.target.value,
                                              })
                                            }
                                          />
                                          <span style={{ color: "#a29d9d" }}>
                                            End Date
                                          </span>{" "}
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </div>
                                        <div className="col-sm-6">
                                          {this.state.selectTrainerName.length >
                                          0 ? (
                                            <select
                                              className="effect-16"
                                              name="TrainerName"
                                              id="Trainer_Id"
                                              type="text"
                                              onInputCapture={
                                                this.handledepartment
                                              }
                                            >
                                              <option value="">Select</option>
                                              {/* <option value={this.state.selectTrainerName}>{selectTrainerName}</option> */}
                                              {this.state.trainerName.map(
                                                (Trainername) => (
                                                  <option
                                                    key={
                                                      Trainername.training_Id
                                                    }
                                                    value={
                                                      Trainername.trainerName
                                                    }
                                                  >
                                                    {Trainername.trainerName}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          ) : (
                                            <select
                                              className="effect-16"
                                              name="TrainerName"
                                              id="Trainer_Id"
                                              type="text"
                                              onFocus={this.fetchData}
                                            >
                                              <option value="">Select</option>
                                            </select>
                                          )}
                                          <span style={{ color: "#a29d9d" }}>
                                            Trainers
                                          </span>{" "}
                                          <span style={{ color: "red" }}></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div
                                    className="col-sm-12"
                                    style={{ textAlign: "center" }}
                                  >
                                    <button
                                      type="submit"
                                      className="but "
                                      style={{
                                        height: "39.5px",
                                        width: "86.36px",
                                        marginTop: "-0.3%",
                                      }}
                                    >
                                      Save
                                    </button>
                                    &nbsp; &nbsp; &nbsp;
                                    <button
                                      type="reset"
                                      className=" btn-Secondary"
                                      style={{ height: "39.5px" }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                                <br /> <br />
                                <br /> <br />
                              </div>
                            </div>
                          </form>
                        </div>
                        {/* <div className="dt-responsive table-responsive table-res">

                                                    <table id="DepartSheet" className="table nowrap" style={{ maxWidth: "70%" }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Trainer Name</th>
                                                                <th>Department</th>
                                                                <th>Session</th>
                                                                <th>Start Date</th>
                                                                <th>End Date</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {Trainername.map(Department => <tr>
                                                                <td>{Department.trainerName}</td>
                                                                <td>{Department.department}</td>
                                                                <td>{Department.trainingItem}</td>
                                                                <td >{Department.startDate}</td>
                                                                <td>{Department.endDate}</td>
                                                            </tr>)}
                                                        </tbody>
                                                    </table>

                                                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
