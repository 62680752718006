import React, { Component, createRef } from "react";
import axios from 'axios';
import CloseIcon from "@material-ui/icons/Close";
import "../Css/Chat.css";
import EntityList from "./EntityList";
import Picker from "emoji-picker-react";
import Swal from "sweetalert2";
import DragDrop from "./DragDrop";
import Picturepop from "./Picturepop";
import File1 from "../Assets/images/document.png";
import DefaultProfile from "../Assets/images/profile.png";
import onlinedot from "../Assets/images/online-dot.png";
import offlinedot from "../Assets/images/offline-dot.png";
import doubletick from "../Assets/images/double-check.webp";
import singletick from "../Assets/images/single-check.png";
import { BASE_URLs } from "../Config/Base";
import { Image_URLs } from "../Config/Base";
import Profile from "../Assets/images/profile.png";
import video from "../Assets/images/videoplay.png";

export default class GroupPop extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(null);
    this.containerRef = React.createRef();
    this.state = {
      AddSkillModel: false,
      Message: [],
      Allmessage: [],
      AllMembers: [],
      AllMember: [],
      Popmsg: [],
      message: [],
      message: "",
      ReceivepopMsg: [],
      isButton1Visible: true,
      Groupname: "",
      GroupTitle: this.props.values.groupName,
      Deleteuserid: "",
      Deleteusername: "",
      chosenEmoji: null,
      showEmoji: false,
      setShowEmoji: "",
      showMenu: false,
      attachMenu: false,
      Infopopup: false,
      Editpopup: false,
      GrpnameEdit: false,
      Enddisc: false,
      Leavedisc: false,
      Deleteuser: false,
      Addmembers: [],
      AllAddmembers: [],
      selectedOptions: [],
      MultiVentureId: "",
      filterurl: [],
      createdby: "",
      Privacy: "",
      Memberslist: [],
      DName: false,
      documentname: "",
      deleteId: "",
      deleteEvery: "",
      deletesender: "",
      deletedate: "",
      deletemsg: "",
      deletepic: "",
      deletedoc: "",
      isVisible: false,
      MsgOption: false,
      letters: [],
      Group: [],
      isdocumentpopOpen: false,
      Documentpopup: false,
      ispicturepopOpen: false,
      Picturepopup: false,
      ImgPath: "",
      ProfileEditPop: false,
      fileUploadState: "",
      isMemberpopupOpen: false,
      Memberpopup: true,
      EImage: "",
      SampleImage: "",
      DUsername: [],
      ForwardMembers: [],
      forwardpopup: false,
      selectedOptionsFwd: [],
      selectedOption: "option1",
      reloadbutton: true,
      mergedUsers: [],
      EndStatus: "",
      Status: "",
      hiddenpop: false,
      ishiddenpopupOpen: false,
      isPopupOpen: false,
      radio1: false,
      radio2: false,
      hover: false,
      Minimized: false,
      selectedUserIds: [],
      selectedUserName: [],
      selectedUsers: [],
      selectedUsersA: [],
      selectedGroupIds: [],
      selectedGroupName: [],
      selectedGroup: [],
      selectedGroupsA: [],
      MuteDisc: false,
      MuteStatus: [],
      UnMuteDisc: false,
      Seen: false,
      seenId: "",
      seenGroupId: "",
      seenMessage: "",
      SeenMsgMembers: [],
      FilterSeenMsgMembers: [],
      datetimesec: "",
      initialScroll: true,
      EditDName: false,
      checkClosedDate: [],
      MemberAccess: [],
      AllMemberAccess: [],
      NonAccess: [],
      AllNonAccess: [],
      Seenside: true,
      EditInput: false,
      updatedGroupTitle: "",
      width: window.innerWidth,
      windowWidth: window.innerWidth,
      height: window.innerWidth < 700 ? 297 : 340,
      emojiWidth: window.innerWidth < 500 ? 275 : 315
    };

    this.inputReference = React.createRef();
    this.AddSkillModel = this.AddSkillModel.bind(this);
    this.handleEmojiClick = this.handleEmojiClick.bind(this);
    this.myEmoji = this.myEmoji.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.attachMenu = this.attachMenu.bind(this);
    this.deleteMenu = this.deleteMenu.bind(this);
    this.displaypopup = this.displaypopup.bind(this);
    this.displayeditpop = this.displayeditpop.bind(this);
    this.grpnameedit = this.grpnameedit.bind(this);
    this.enddisc = this.enddisc.bind(this);
    this.Leavedisc = this.Leavedisc.bind(this);
    this.Deletepop = this.Deletepop.bind(this);
    this.Addpop = this.Addpop.bind(this);
    this.Documentpop = this.Documentpop.bind(this);
    this.Picturepop = this.Picturepop.bind(this);
    this.ProfileEditPop = this.profileEditpopup(this);
    this.Memberpop = this.Memberpop.bind(this);
    this.hiddenpop = this.openpop.bind(this);
    this.MuteDisc = this.MuteDisc.bind(this);
    this.UnMuteDisc = this.UnMuteDisc.bind(this);
  }

  formatDateAA2 = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  Leavedisc(e) {
    e.preventDefault();
    this.setState({
      Leavedisc: !this.state.Leavedisc,
      Deleteuser: false,
      GrpnameEdit: false,
      ProfileEditPop: false,
      attachMenu: false,
      MuteDisc: false,
      UnMuteDisc: false,
    });
    this.setState({
      showMenu: false,
    });
  }

  fetchClosedDate = async () => {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    try {
      const response = await axios.get(`${BASE_URLs}Chat/CheckPrivacyandCreatedby`, options);
      this.setState({ checkClosedDate: response.data });
    } catch (error) {
      console.error('Error fetching closed date:', error);
    }
    setInterval(1000);
  };

  changeImage = () => {
    this.setState({ hover: true });
  };

  restoreImage = () => {
    this.setState({ hover: false });
  };

  handleOptionChange1 = (e) => {
    this.setState({
      selectedOption: e.target.value,
    });
  };

  handleOptionChange = (e) => {
    this.setState({
      selectedOption: e.target.value,
    });
  };

  handleChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  LeaveDiscussion = (GrpId, Gname) => {
    var Gname = document.getElementById("Gname").value;
    const data = {
      Id: GrpId,
      GroupName: Gname,
      GroupMember: this.state.mergedUsers,
      Profile: this.state.ImgPath,
    };

    axios({
      method: "POST",
      url: `${BASE_URLs}Chat/LeaveDiscussion`,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        if (response.data.status === "Success") {
          this.setState({
            Leavedisc: false,
          });
          document.getElementById("input-group write-msg").style.display =
            "none";
        }
        this.GetMemberAccess(this.props.values.groupId);
        this.GetNonAccess(this.props.values.groupId);
      })


      .catch((error) => {
      });
  };

  myFunction = (e) => {
    const AllMembers = this.state.AllMember.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ AllMembers });
  };

  handleKeyPress = (event) => {
    if (event.charCode === 13 && !this.state.EditInput) {
      event.preventDefault();
      const { message, picture_Path, document_Path } = this.state;
      if (!message.toString().trim() && !picture_Path && !document_Path) {
        alert("Please enter a message, select an image, or choose a document.");
      } else {
        const formData = new FormData();
        formData.append("Message", message);
        formData.append("Group_Name", this.state.updatedGroupTitle.length === 0 ? this.state.GroupTitle : this.state.updatedGroupTitle);
        formData.append("Group_id", this.props.values.groupId);
        formData.append("GroupMember", this.state.mergedUsers);
        formData.append("Profile", this.state.ImgPath);
        axios({
          method: "POST",
          url: `${BASE_URLs}contact/GroupChatMessage`,
          data: formData,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        })
          .then((response) => {
            setTimeout(() => {
              this.HideTextBox(this.props.values.groupId);
            }, 500);
            this.setState({
              message: "",
              picture_Path: null,
              document_Path: null,
            });
          })
          .catch((error) => {
            // Handle error
          });
      }
    }
  
    if (event.charCode === 13 && this.state.EditInput) {
      event.preventDefault();
      document.getElementById("input-groupEdit").style.display = "none";
      document.getElementById("input-group write-msg").style.display = "flex";
      const data = {
        GroupId: this.state.deleteEvery,
        Message: this.state.deletemsg,
        SenderId: this.state.deletesender,
        DateTimeSec: this.state.datetimesec,
      };
      axios({
        method: "POST",
        url: `${BASE_URLs}Chat/EditChatMessage`,
        data: data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      })
        .then((response) => {
          this.setState({ message: "", deletemsg: "" });
          setTimeout(() => {
            this.HideTextBoxEdit(this.props.values.groupId);
          }, 500);
          this.setState({ EditInput: false })
        })
        .catch((error) => {
          // Handle error
        });
    };
  };

  handleTextChange = (event) => {
    this.setState({
      message: event.target.value,
      deletemsg: event.target.value,
    });
  };

  documentPopup = () => {
    this.setState({
      isdocumentpopOpen: false,
      Documentpopup: false,
    });

    setTimeout(() => {
      this.HideTextBox(this.props.values.groupId);
    }, 500);
  };

  memberPopup = () => {
    this.setState({
      ismemberpopOpen: false,
      Memberpopup: false,
    });

    setTimeout(() => {
      this.HideTextBox(this.props.values.groupId);
    }, 500);
  };

  SeenMessages = () => {
    document.getElementById("input-groupEdit").style.display = "none";
    const data = {
      Message: this.state.seenMessage,
      GroupId: this.state.seenGroupId,
      Datetime: this.state.deletedate
    };
    axios({
      method: "POST",
      url: `${BASE_URLs}Chat/SeenMessage`,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((data) => {
        this.setState({
          FilterSeenMsgMembers: data.data,
          Seen: !this.state.Seen,
          Seen: true,
          MsgOption: false,
          isVisible: false,
          initialScroll: window.innerWidth <= 990
        });
        const SingleMember = Array.from(
          new Set(this.state.FilterSeenMsgMembers.map(JSON.stringify))
        ).map(JSON.parse);
        this.setState({
          SeenMsgMembers: SingleMember,
        });
      })

      .catch((error) => {
      });

    setTimeout(() => {
      this.HideTextBox(this.props.values.groupId);
      if (this.state.initialScroll) {
        const scrollOptions = {
          top: document.body.scrollHeight, // Scroll to the bottom of the body
          behavior: 'smooth' // Apply smooth scrolling behavior
        };
        window.scrollTo(scrollOptions);
      }
    }, 500);
  };

  picturePopup = () => {
    this.setState({
      ispicturepopOpen: false,
      Picturepopup: false,
    });

    setTimeout(() => {
      this.HideTextBox(this.props.values.groupId);
    }, 500);
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const { message, picture_Path, document_Path } = this.state;
    if (!message.toString().trim() && !picture_Path && !document_Path) {
      alert("Please enter a message, select an image, or choose a document.");
    } else {
      const formData = new FormData();
      formData.append("Message", message);
      formData.append("Group_Name", this.state.updatedGroupTitle.length === 0 ? this.state.GroupTitle : this.state.updatedGroupTitle);
      formData.append("Group_id", this.props.values.groupId);
      formData.append("GroupMember", this.state.mergedUsers);
      formData.append("Profile", this.state.ImgPath);
      axios({
        method: "POST",
        url: `${BASE_URLs}contact/GroupChatMessage`,
        data: formData,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      })
        .then((response) => {
          event.target.reset();
          setTimeout(() => {
            this.HideTextBox(this.props.values.groupId);
          }, 500);
  
          this.setState({
            message: "",
            picture_Path: null,
            document_Path: null,
          });
        })
        .catch((error) => {
        });
    }
  };

  UpdateGroupName = (event) => {
    var grpname = document.getElementById("Groupname").value;
    var GrpId = document.getElementById("GrpId").value;
    if (!grpname) {
      alert("Group Name is required");
    }
    if (grpname) {
      const data = {
        GroupId: GrpId,
        GroupName: grpname,
      };
      axios({
        method: "POST",
        url: `${BASE_URLs}contact/EditGroupName`,
        data: data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      })
        .then((response) => {
          if (response.data.status === "Success") {
            this.setState({
              GrpnameEdit: false,
              GroupTitle: grpname,
            });
            this.SubmitEditMessage();
            alert("Updated Successfully");
            let status = localStorage.getItem("AllDataa");
            let statusdata = JSON.parse(status);
            let stss = statusdata.map((e) => e.createDateTime);
            this.GetAllMessage(this.props.values.groupId, stss);
          }
        })
        .catch((error) => {
          alert("failed !");
        });
    }
  };
  GetMessages() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}Contact/GetSendmsg`, config)
      .then((response) => {
        this.setState({
          Messages: response.data,
        });
      })
      .catch((error) => {
      });
  }
  
  GetGroupName() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    axios.get(`${BASE_URLs}Contact/GetGroupName`, config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({
            Groupname: response.data,
          });
        } else {
        }
      })
      .catch((error) => {
        // Handle error
      });
  }

  SearchClick = () => {
    if (window.innerWidth <= 900) {
    const headerElement = document.getElementById("Grouppop-header");
   if (headerElement) {
      headerElement.style.height = this.state.Search ? "" : "83px"; // Example height values
    }
   }
  if (this.state.Search) {
      document.getElementById("searchresM").value = "";
      document.getElementById("FilterErr").innerHTML = "";
      this.setState({
        Allmessage: this.state.Allmessage1, // Assuming Allmessage1 holds all original messages
        showResults: false, // Optionally reset showResults if needed
      });
    }

    this.setState((prevState) => ({
      Search: !prevState.Search,
    }));
  };

  MessageFilter = (e) => {
    const Allmessage = this.state.Allmessage1.filter((item) =>
      item.message.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ Allmessage, showResults: true });
    if (Allmessage.length === 0) {
      document.getElementById("FilterErr").innerHTML = "Message Not Found";
    } else {
      document.getElementById("FilterErr").innerHTML = "";
    }
  };

  markMessageAsSeen = (group_id) => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    const body = {
      id: group_id,
    };
    axios.post(`${BASE_URLs}Notification/Msgcount`, body, config)
      .then((response) => {
      })
      .catch((error) => {
      });
  };

  GetAllMessage(Id, date) {
    var Email = JSON.parse(localStorage.email);
    const data = {
      id: Id,
      date: date[0],
      members: this.state.Memberslist,
    };
    axios({
      method: "POST",
      url: `${BASE_URLs}Contact/GetGroupMsg`,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        let dd = response.data.map((d) => {
          if (d.message === "have been Left") {
            return d
          }
        }).filter(function (x) {
          return x !== undefined;
        })

        let dispun = dd.map((d) => {
          return d.displayName
        })

        this.setState({
          Allmessage: response.data,
          Allmessage1: response.data,
        });

        this.markMessageAsSeen(this.props.values.groupId)
        var curlength = response.data.length; // Corrected syntax
        if (curlength !== this.state.prevLength) {
          this.setState({
            initialScroll: true,
            prevLength: curlength,
          });
        }

        if (this.state.initialScroll === true) {
          this.scrollIntoView();
          this.setState({
            initialScroll: false,
          });
        }
        localStorage.setItem("AllMessagessss", JSON.stringify(response.data));
        let msgdata = localStorage.getItem("AllMessagessss");
        let msg = JSON.parse(msgdata);
        let arr = msg.map((e) => e.message);
        this.setState({ object: arr });
        const isValidUrl = (url) => {
          const regexp =
            /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
          return regexp.test(url);
        };
        const validUrls = arr.filter(isValidUrl);
        this.setState({ filterurl: validUrls });
      });
  }

  GetAllMessageEdit(Id, date) {
    const data = {
      id: Id,
      date: date[0],
    };
    axios({
      method: "POST",
      url: `${BASE_URLs}Contact/GetGroupMsg`,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        this.setState({
          Allmessage: response.data,
        });
        localStorage.setItem("AllMessagessss", JSON.stringify(response.data));
        let msgdata = localStorage.getItem("AllMessagessss");
        let msg = JSON.parse(msgdata);
        let arr = msg.map((e) => e.message);
        this.setState({ object: arr });
        const isValidUrl = (url) => {
          const regexp =
            /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
          return regexp.test(url);
        };
        const validUrls = arr.filter(isValidUrl);
        this.setState({ filterurl: validUrls });
      });
  }

  scrollIntoView() {
    this.myRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  GetAllMembers(Id) {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    const fetchData = () => {
      axios.get(`${BASE_URLs}Contact/GetGroupMembers?Id=${Id}`, config)
        .then((response) => {
          this.setState({
            AllMembers: response.data,
            AllMember: response.data,
            GroupTitle: this.props.values.groupName,
          });
  
          setTimeout(() => {
            this.GetTeamMembers();
          }, 500);
  
          localStorage.setItem("AllGroupMember", JSON.stringify(response.data));
          var UserId = JSON.parse(localStorage.userId);
          let Member = localStorage.getItem("AllGroupMember");
          let Members = JSON.parse(Member);
          let lMembers = Members.map((e) => e.user_id);
          let Membersstr = lMembers.toString();
          let MembersName = Members.map((e) => e.name);
          let MembersNamestr = MembersName.toString();
          this.setState({
            Memberslist: Membersstr,
            Group: {
              GroupId: Id,
              GroupName: this.props.values.groupName,
              GroupMembers: Membersstr,
              Profile: this.state.ImgPath,
            },
          });
        })
        .catch((error) => {
          // Handle error
        });
    };
  
    fetchData();
    const refreshInterval = setInterval(fetchData, 5000);
    this.setState({ refreshInterval });
  }
  isAtTop = () => {
    const bodyScrollTop =
      document.body.scrollTop || document.documentElement.scrollTop;
    const threshold = 10;
    return bodyScrollTop < threshold;
  };

  fetchClosedDate = async () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    try {
      const response = await axios.get(
        `${BASE_URLs}Chat/CheckPrivacyandCreatedby`,
        config
      );
      this.setState({ checkClosedDate: response.data });
    } catch (error) {
      // Handle error
    }
    setInterval(1000);
  };
  

  GetMemberAccess(group_id) {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    const body = {
      Id: group_id,
    };
  
    axios.post(`${BASE_URLs}Chat/GetAccessAddMember`, body, config)
      .then((response) => {
        this.setState({
          MemberAccess: response.data,
          AllMemberAccess: response.data,
          selectedUserIds: [],
          selectedUsers: [],
        });
      })
      .catch((error) => {
        // Handle error
      });
  }

  GetNonAccess(group_id) {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    const body = {
      Id: group_id,
    };
  
    axios.post(`${BASE_URLs}chat/GetNonAccessAddMember`, body, config)
      .then((response) => {
        this.setState({
          NonAccess: response.data,
          AllNonAccess: response.data,
          selectedUserIds: [],
          selectedUsers: [],
        });
      })
      .catch((error) => {
        // Handle error
      });
  }


  componentDidMount() {
    this.GetForwardMembers();
    this.SeenMessages();
    this.GetGroupUsers(this.props.values.groupId);
    this.GetGroupId(this.props.values.groupName);
    this.fetchClosedDate(this.props.values.groupId);
    this.GetMemberAccess(this.props.values.groupId);
    this.GetNonAccess(this.props.values.groupId);
  async function getMessageInstantly() {
      while (!this.state.Search) {
        this.GetAddmembers();
        let status = localStorage.getItem("AllDataa");
        let statusdata = JSON.parse(status);
        let stss = statusdata.map((e) => e.createDateTime);
        this.GetAllMessage(this.props.values.groupId, stss);
        for (const d of this.state.Allmessage) {
          if (
            d.message.includes("Changed this Group Name") &&
            !d.message.includes("have been Left")
          ) {
            this.setState({
              updatedGroupTitle: d.group_Name,
            });
          }
        }
        await new Promise((resolve) => setTimeout(resolve, 5000));
      }
    }
    
    // Call the function
    getMessageInstantly.call(this);
    setTimeout(() => {
      this.GetAddmembers(this.props.values.groupId);
      this.GetMessages();
    }, 5);

    this.scrollIntoView({ behavior: "smooth" });
    this.HideTextBox(this.props.values.groupId);
    this.DUHideTextBox(this.props.values.groupId);
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    this.GetAllMembers(this.props.values.groupId);
    this.setState({ letters: alphabet });
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener('resize', this.handleResize);
    this.setState({
      Seenside: this.updateSeenside()
    });
  }

  handleClickOutside = (event) => {
    if (
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target)
    ) {
      this.setState({
        isVisible: false,
        Seen: false,
        showMenu: false,
        MsgOption: false,
        ProfileEditPop: false,
      });
    }
  };

  handleFilter = (letter, event) => {
    event.preventDefault();
    const Addmembers = this.state.AllAddmembers.filter((item) => {
      return item.name.charAt(0).toLowerCase() === letter.toLowerCase();
    });
    this.setState({ Addmembers, showResults: true });
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.Seen && !prevState.Seen) {
      window.scrollTo(0, 990);
    }
    if (prevState.windowWidth !== this.state.windowWidth) {
      this.setState({
        Seenside: this.updateSeenside()
      });
    }
  }
  handleArrowClick() {
    this.AddSkillModel();
    this.props.close();
  }
  myEmoji = () => {
    const changeState = this.state.showEmoji;
    if (changeState) {
      this.setState({
        showEmoji: false,
      });
    } else {
      this.setState({
        showEmoji: true,
        attachMenu: false,
        Addpopup: false,
        showMenu: false,
        deleteMenu: false,
      });
    }
  };
  handleEmojiClick = (emojiObject) => {
    this.setState((prevState) => ({
      message: prevState.message + emojiObject.emoji,
      showEmoji: false,
      deletemsg: prevState.deletemsg + emojiObject.emoji,
    }));
  };
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.AddSkillModel();
      this.props.close();
    }
  }
  AddSkillModel() {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    this.setState((prevState) => ({
      AddSkillModel: !prevState.AddSkillModel,
    }));
  }
  handleButton1Click = () => {
    this.setState({
      isButton1Visible: false,
    });
  };
  toggleMenu() {
    this.setState({
      showMenu: !this.state.showMenu,
      Addpopup: false,
      attachMenu: false,
      showEmoji: false,
      deleteMenu: false,
    });
  }
  attachMenu() {
    this.setState({
      attachMenu: !this.state.attachMenu,
      showEmoji: false,
      Addpopup: false,
      showMenu: false,
      deleteMenu: false,
      Deleteuser: false,
      Enddisc: false,
      ProfileEditPop: false,
      GrpnameEdit: false,
      MuteDisc: false,
      UnMuteDisc: false,
    });
  }

  MessageOption(id, groupId, sd, cd, msg, pp, dp, dts) {
    // // console.log("dtsssssss------", dts);
    document.getElementById("input-groupEdit").style.display = "none";
    document.getElementById("input-group write-msg").style.display = "flex";
    this.setState({
      MsgOption: !this.state.MsgOption,
      MsgOption: true,
      deleteId: id,
      deleteEvery: groupId,
      deletesender: sd,
      deletedate: cd,
      deletemsg: msg,
      deletepic: pp,
      deletedoc: dp,
      seenId: id,
      seenGroupId: groupId,
      seenMessage: msg,
      forwardpopup: false,
      GrpnameEdit: false,
      Deleteuser: false,
      Enddisc: false,
      ProfileEditPop: false,
      attachMenu: false,
      MuteDisc: false,
      UnMuteDisc: false,
      datetimesec: dts,
    });
  }
  CanceEdit() {
    document.getElementById("input-groupEdit").style.display = "none";
    document.getElementById("input-group write-msg").style.display = "flex";
  }
  EditMessage = () => {
    this.setState({ EditInput: true })
    document.getElementById("input-groupEdit").style.display = "flex";
    document.getElementById("input-group write-msg").style.display = "none";
  }
  deleteMenu() {
    this.setState({
      isVisible: !this.state.isVisible,

      isVisible: true,
      attachMenu: false,
      Addpopup: false,
      showMenu: false,
      showEmoji: false,
      MsgOption: false,
      Seen: false,
    });
  }
  profileEditpopup = () => {
    this.setState({
      ProfileEditPop: !this.state.ProfileEditPop,
      Deleteuser: false,
      Enddisc: false,
      GrpnameEdit: false,
      attachMenu: false,
      MuteDisc: false,
      UnMuteDisc: false,
    });
  };
  hideprofileEditpopup = (e) => {
    this.setState({
      ProfileEditPop: false,
      EImage: this.state.ImgPath,
    });
  };

  displaypopup() {
    this.setState({ Infopopup: !this.state.Infopopup });
    this.setState({
      showMenu: false,
    });
    this.GetAllMembers(this.props.values.groupId);
  }
  hidedisplaypop = () => {
    this.setState({
      Infopopup: false,
    });
  };

  displayeditpop() {
    this.setState({ Editpopup: !this.state.Editpopup });
    this.setState({
      showMenu: false,
    });
    this.GetAllMembers(this.props.values.groupId);
  }
  hideEditpop = () => {
    this.setState({
      Editpopup: false,
      GrpnameEdit: false,
      Deleteuser: false,
      ProfileEditPop: false,
    });
  };

  documentpop() {
    this.setState({ Documentpopup: !this.state.Documentpopup });
  }

  memberpop() {
    this.setState({ Memberpopup: !this.state.Memberpopup });
  }

  picturepop() {
    this.setState({ Picturepopup: !this.state.Picturepopup });
  }

  grpnameedit() {
    this.setState({
      GrpnameEdit: !this.state.GrpnameEdit,
      Deleteuser: false,
      Enddisc: false,
      ProfileEditPop: false,
      attachMenu: false,
      MuteDisc: false,
      UnMuteDisc: false,
    });
    this.GetAllMembers(this.props.values.groupId);
  }

  hidegrpnameEditpop = () => {
    this.setState({
      GrpnameEdit: false,
    });
    document.getElementById("grpnameform").reset();
  };

  editgrpName() {
    var grpname = document.getElementById("Gname").value;

    if (!grpname) {
      alert("Group Name is required");
    }
  }

  hidedocumentpop = () => {
    this.setState({
      Documentpopup: false,
    });
  };

  hidememberpop = () => {
    this.setState({
      Memberpopup: false,
    });
  };

  hidepicturepop = () => {
    this.setState({
      Picturepopup: false,
    });
  };

  MuteDisc(e) {
    e.preventDefault();
    this.setState({
      MuteDisc: !this.state.MuteDisc,
      Deleteuser: false,
      Enddisc: false,
      ProfileEditPop: false,
      attachMenu: false,
      GrpnameEdit: false,
      UnMuteDisc: false,
    });
  }

  UnMuteDisc(e) {
    e.preventDefault();
    this.setState({
      UnMuteDisc: !this.state.UnMuteDisc,
      Deleteuser: false,
      Enddisc: false,
      ProfileEditPop: false,
      attachMenu: false,
      GrpnameEdit: false,
      MuteDisc: false,
    });
  }

  hideMuteDisc = () => {
    this.setState({
      MuteDisc: false,
    });
  };

  hideUnMuteDisc = () => {
    this.setState({
      UnMuteDisc: false,
    });
  };

  enddisc(e) {
    e.preventDefault();
    this.setState({
      Enddisc: !this.state.Enddisc,
      Deleteuser: false,
      GrpnameEdit: false,
      ProfileEditPop: false,
      attachMenu: false,
      MuteDisc: false,
      UnMuteDisc: false,
    });
    this.setState({
      showMenu: false,
    });
  }

  hideenddiscpop = () => {
    this.setState({
      Enddisc: false,
      Leavedisc: false,
    });
  };

  endDiscussion = (GrpId) => {
    
    var Gname = document.getElementById("Gname").value;
    // let GrpId = document.getElementById('groupId').value

    const data = {
      Id: GrpId,
      uname: localStorage.getItem("uname").split(`"`)[1],
      GroupName: Gname,
      GroupMember: this.state.mergedUsers,
      Profile: this.state.ImgPath,
    };

    axios({
      method: "POST",

      url: `${BASE_URLs}Chat/EndDiscussion`,

      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log(response.data);

        if (response.data.status === "Success") {
          // alert("You Exists");
          Swal.fire({
            position: "center",

            icon: "error",

            text: "Discussion Ended",

            showConfirmButton: false,

            timer: 3000,
            customClass: "Group-swal-container",
          });

          this.setState({
            Enddisc: false,
             EndStatus:0,
             Status:0
          });
          this.fetchClosedDate(this.props.values.groupId);     

          document.getElementById("input-group write-msg").style.display =
            "none";

          document.getElementById("exitspan").style.display = "block";
          // document.getElementById('Chat-Input-outer').style.backgroundColor = "#d5d5d5"
          document.getElementById("AddIcon").style.display = "none";
          document.getElementById("MenuIcon").style.display = "none";
        }
      })

      .catch((error) => {
        // // console.log(error);

        //alert("failed!");
      });
  };

  MuteDiscussion = (GrpId) => {
    // let GrpId = document.getElementById('groupId').value

    const data = {
      Id: GrpId,
      // uname: localStorage.getItem("uname").split(`"`)[1]
    };

    axios({
      method: "POST",

      url: `${BASE_URLs}Chat/Mute`,

      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log("checkedd", response.data);

        if (response.data.status === "Success") {
          alert("Discussion Muted");
          this.GetGroupUsers(this.props.values.groupId);
          // Swal.fire({
          //   position: "center",

          //   icon: "error",

          //   title: "Discussion has been Muted",

          //   showConfirmButton: false,

          //   timer: 3000,
          // });

          this.setState({
            MuteDisc: false,
          });
        }
      })

      .catch((error) => {
        // // console.log(error);

        //alert("failed!");
      });
  };

  UnMuteDiscussion = (GrpId) => {
    // let GrpId = document.getElementById('groupId').value

    const data = {
      Id: GrpId,
      // uname: localStorage.getItem("uname").split(`"`)[1]
    };

    axios({
      method: "POST",

      url: `${BASE_URLs}Chat/UnMute`,

      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log("checkeddss", response.data);

        if (response.data) {
          alert("Discussion Unmuted");
          this.GetGroupUsers(this.props.values.groupId);
          // Swal.fire({
          //   position: "center",

          //   icon: "error",

          //   title: "Discussion has been Muted",

          //   showConfirmButton: false,

          //   timer: 3000,
          // });

          this.setState({
            UnMuteDisc: false,
          });
        }
      })

      .catch((error) => {
        // // console.log(error);
        //alert("failed!");
      });
  };

  GetGroupUsers = (GrpId) => {
    const data = {
      Id: GrpId,
    };

    axios({
      method: "POST",

      url: `${BASE_URLs}Chat/GetGroupUsers`,

      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        this.setState({
          MuteStatus: response.data,
        });
        // // console.log("checkeddss", this.state.MuteStatus);
      })

      .catch((error) => {
        // // console.log(error);

        //alert("failed!");
      });
  };

  HideTextBox = (Id) => {
    // let Id = document.getElementById('groupid').value

    const data = {
      Id: Id,
    };

    axios({
      method: "POST",

      url: `${BASE_URLs}Chat/EDHideTextBox`,

      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log(response.data);
        localStorage.setItem("AllDataa", JSON.stringify(response.data));
        var UserId = JSON.parse(localStorage.userId);

        var Email = JSON.parse(localStorage.email);

        let status = localStorage.getItem("AllDataa");
        let statusdata = JSON.parse(status);
        let sts = statusdata.map((e) => e.end_Status);

        let stss = statusdata.map((e) => e.end_Status_ChangeDate);
        let stss1 = statusdata.map((e) => e.status);

        // let Admin = statusdata.map((e) => e.created_by);
        // let CheckAdmin = Admin.toString();
        //  let CAdmin =  CheckAdmin.inclues(`UserId`)

        this.GetAllMessage(this.props.values.groupId, stss);
        // // console.log(sts);
        //alert(stss)
        let statusstr = sts.toString();
        let statusstr1 = stss1.toString();
        this.setState({
          EndStatus: statusstr,
          Status: statusstr1,
        });

        let num = statusstr.includes("0");

        let statusA = localStorage.getItem("ListAllDataa");

        let statusdataA = JSON.parse(statusA);

        let stsA = statusdataA.map((e) => e.end_Status);

        // // console.log(stsA);

        //alert(stss)

        let statusstrA = stsA.toString();

        let numA = statusstrA.includes("0");

        let member = this.state.Memberslist.includes(Email);
        // alert(member)
        if (num === true) {
          document.getElementById("input-group write-msg").style.display =
            "none";
          document.getElementById("exitspan").style.display = "block";
          document.getElementById("AddIcon").style.display = "none";
          document.getElementById("MenuIcon").style.display = "none";
        }

        if (numA === true) {
          document.getElementById("input-group write-msg").style.display =
            "none";
          document.getElementById("removespan").style.display = "block";
          document.getElementById("AddIcon").style.display = "none";
          document.getElementById("MenuIcon").style.display = "none";
        }

        if (member === false) {
          document.getElementById("input-group write-msg").style.display =
            "none";
        }

        if (num === false && numA == false && member == true) {
          document.getElementById("input-group write-msg").style.display =
            "flex";
        }

        localStorage.setItem("Status", num);

        localStorage.setItem("StatusA", numA);

        localStorage.setItem("StatusB", member);
      })

      .catch((error) => {
        // // console.log(error);

        //alert("failed!");
      });
  };
  HideTextBoxEdit = (Id) => {
    // let Id = document.getElementById('groupid').value

    const data = {
      Id: Id,
    };

    axios({
      method: "POST",

      url: `${BASE_URLs}Chat/EDHideTextBox`,

      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log(response.data);
        localStorage.setItem("AllDataa", JSON.stringify(response.data));
        var UserId = JSON.parse(localStorage.userId);

        let status = localStorage.getItem("AllDataa");
        let statusdata = JSON.parse(status);
        let sts = statusdata.map((e) => e.status);

        let stss = statusdata.map((e) => e.status_ChangeDate);

        // let Admin = statusdata.map((e) => e.created_by);
        // let CheckAdmin = Admin.toString();
        //  let CAdmin =  CheckAdmin.inclues(`UserId`)

        this.GetAllMessageEdit(this.props.values.groupId, stss);
      })
      .catch((error) => {
        // // console.log(error);

        //alert("failed!");
      });
  };
  DUHideTextBox = (Id) => {
    // let Id = document.getElementById('groupid').value
    const data = {
      Id: Id,
    };
    axios({
      method: "POST",
      url: `${BASE_URLs}Chat/DUHideTextBox`,
      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log(response.data);

        localStorage.setItem("ListAllDataa", JSON.stringify(response.data));

        var UserId = JSON.parse(localStorage.userId);
      })

      .catch((error) => {
        // // console.log(error);

        //alert("failed!");
      });
  };

  Deletepop(user_id, name) {
    this.setState({
      Deleteuser: !this.state.Deleteuser,

      Deleteuserid: user_id,
      Deleteusername: name,
      Enddisc: false,
      ProfileEditPop: false,
      attachMenu: false,
      MuteDisc: false,
      UnMuteDisc: false,
      GrpnameEdit: false,
    });
  }

  hideDeletepop = () => {
    this.setState({
      Deleteuser: false,
    });
  };

  DeleteUserGroup = () => {
    let Id = document.getElementById("groupid").value;
    let duserid = document.getElementById("duserid").value;
    let dusername = document.getElementById("dusername").value;

    // let Both =dusername+" [ "+duserid+" ] "
    let Both = dusername;
    const data = {
      Id: Id,
      UserName: duserid,
      Uname: localStorage.getItem("uname").split(`"`)[1],
    };
    // // console.log(data, "delete user");
    axios({
      method: "POST",
      url: `${BASE_URLs}Chat/DeleteUserGroup`,

      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log(response.data);

        this.setState({
          Deleteuser: false,
        });

        this.GetAllMembers(Id);

        this.DeleteMessage(
          this.props.values.groupId,
          this.props.values.groupName,
          Both
        );
this.GetMemberAccess(this.props.values.groupId);
        this.GetNonAccess(this.props.values.groupId);
      })

      .catch((error) => {
        // // console.log(error);

        //alert("failed!")
      });
  };

  DeleteMessageChat = () => {
    // let Id = document.getElementById('id').value;

    // let dusername = document.getElementById('name').value;

    const data = {
      Id: this.state.deleteId,

      // UserName: dusername
    };

    // // console.log(data, "delete message");

    Swal.fire({
      // title: "Are you sure",

      text: "Are you sure want to delete message?",

      icon: "warning",

      showCancelButton: true,

      confirmButtonColor: "#3085d6",

      cancelButtonColor: "#d33",

      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "POST",

          url: `${BASE_URLs}Chat/DeleteMessage`,

          data: data,

          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-Type": "multipart/form-data",

            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        })
          .then((response) => {
            // // console.log(response.data);

            this.setState({
              deleteMenu: false,
            });
          })

          .catch((error) => {
            // // console.log(error);

            //alert("failed!")
          });
      }

      if (result.isDismissed) {
        this.setState({
          deleteMenu: false,
        });
      }

      setTimeout(() => {
        this.HideTextBox(this.props.values.groupId);
      }, 500);
    });
  };

  EditChatMessage = () => {
    document.getElementById("input-groupEdit").style.display = "none";
    document.getElementById("input-group write-msg").style.display = "flex";
    // let Id = document.getElementById('groupid').value
    const data = {
      GroupId: this.state.deleteEvery,
      Message: this.state.deletemsg,
      SenderId: this.state.deletesender,
      // CreateDateTime: this.state.deletedate,
      DateTimeSec: this.state.datetimesec,
    };

    // // console.log("Edit____Data", data);
    axios({
      method: "POST",
      url: `${BASE_URLs}Chat/EditChatMessage`,
      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log(response.data);
        this.setState({ message: "" });

        setTimeout(() => {
          this.HideTextBoxEdit(this.props.values.groupId);
        }, 500);

        this.setState({ EditInput: false })
      })

      .catch((error) => {
        // // console.log(error);

        //alert("failed!");
      });
  };

  DeleteMessageEveryone = () => {
    // let Id = document.getElementById('id').value;

    //let dusername = document.getElementById('name').value;

    const data = {
      GroupId: this.state.deleteEvery,

      SenderId: this.state.deletesender,

      DateTimeSec: this.state.datetimesec,

      Message: this.state.deletemsg,

      PicturePath: this.state.deletepic,

      DocumentPath: this.state.deletedoc,
    };

    // // console.log(data, "delete message");

    Swal.fire({
      // title: "Are you sure",

      text: "Are you sure want to delete message?",

      icon: "warning",

      showCancelButton: true,

      confirmButtonColor: "#3085d6",

      cancelButtonColor: "#d33",

      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "POST",

          url: `${BASE_URLs}Chat/DeleteMessageEveryone`,

          data: data,

          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-Type": "multipart/form-data",

            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        })
          .then((response) => {
            // // console.log(response.data);

            this.setState({
              deleteMenu: false,
            });
          })

          .catch((error) => {
            // // console.log(error);

            //alert("failed!")
          });
      }

      if (result.isDismissed) {
        this.setState({
          deleteMenu: false,
        });
      }

      setTimeout(() => {
        this.HideTextBox(this.props.values.groupId);
      }, 500);
    });
  };

  DeleteMessage = (Id, GroupName, name) => {
    var MultiMembers = this.state.selectedOptions.map((item) => item.label);

    let userId = MultiMembers.toString();

    // let String_User = this.state.selectedUserName.toString();

    const data = {
      // group_Name: GroupName,

      Group_Name: GroupName,

      Group_id: Id,

      GroupMember: this.state.Memberslist,

      Message: name,
      Profile: this.state.ImgPath,
    };

    axios({
      method: "POST",

      url: `${BASE_URLs}contact/GroupDeleteMemberMessage`,

      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log(response.data);

        // this.setState({

        //   Deleteuser: false,

        // });

        this.GetAddmembers(this.props.values.groupId);
        this.GetAllMembers(this.props.values.groupId);

        setTimeout(() => {
          this.HideTextBox(this.props.values.groupId);
        }, 500);
      })

      .catch((error) => {
        // // console.log(error);

        alert("failed !");
      });
  };

  GetAddmembers(Id) {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}Contact/GetGroupAddMembers?Id=${Id}`, config)
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({
            Addmembers: response.data,
            AllAddmembers: response.data,
          });
          // // console.log("Addmemberssss", response.data);
        } else {
          // Handle the case when data is not an array
          console.error("Received data is not an array:", response.data);
        }
      })
      .catch((error) => {
        // Handle error
      });
  }
  filteraddmember = (e) => {
    const Addmembers = this.state.AllAddmembers.filter(
      (item) => item.name.toLowerCase().includes(e.target.value.toLowerCase())
      // item.email.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Addmembers });

    this.setState({
      GetAddmemberspop: true,
    });
  };

  Addpop() {
    this.setState({
      Addpopup: !this.state.Addpopup,
      showMenu: false,
      forwardpopup: false,
    });
  }
  hideForwardpop = () => {
    this.setState({
      forwardpopup: false,
      selectedGroupIds: [],
    });

    this.GetForwardMembers();
  };
  hideAddpop = () => {
    this.setState({
      Addpopup: false,
      selectedOptions: [],
    });

    this.GetAddmembers(this.props.values.groupId);
    this.GetAllMembers(this.props.values.groupId);
    // document.getElementById('AddmemberEmail').value = ""
    document.getElementById("AddmemberError").innerHTML = "";
    // document.getElementById('MemberlnErr').innerHTML = ""
  };

  Documentpop() {
    this.setState({
      Documentpopup: !this.state.Documentpopup,
      isdocumentpopOpen: true,

      attachMenu: false,
    });
  }

  Memberpop() {
    this.setState({
      Memberpopup: !this.state.Memberpopup,
      isMemberpopupOpen: true,
    });
  }
  openpop = () => {
    this.setState({
      hiddenpop: !this.state.hiddenpop,
      ishiddenpopupOpen: true,
    });
  };

  Picturepop() {
    this.setState({
      Picturepopup: !this.state.Picturepopup,
      ispicturepopOpen: true,
      attachMenu: false,
    });
  }

  onChange = () => {
    // let userId = document.getElementById('AddmemberEmail').value
    var MultiMembers = this.state.selectedOptions.map((item) => item.value);
    let userId = MultiMembers.toString();

    if (!userId) {
      document.getElementById("AddmemberError").innerHTML = "Choose Any Member";
      //document.getElementById('addbutton').disabled = true
    } else {
      document.getElementById("AddmemberError").innerHTML = "";
      //document.getElementById('addbutton').disabled = false
    }
  };
  SubmitAddMessage = (Id, GroupName) => {
    // var MultiMembers = this.state.selectedOptions.map((item) => item.name);
    let userId = this.state.selectedUserName.toString();

    // let String_User = this.state.selectedUserName.toString();
    const data = {
      // group_Name: GroupName,

      Group_Name: GroupName,
      Group_id: Id,
      GroupMember: this.state.mergedUsers,
      Message: userId,
      Profile: this.state.ImgPath,
    };

    axios({
      method: "POST",
      url: `${BASE_URLs}contact/GroupAddMemberMessage`,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log(response.data);
        this.setState({
          selectedUserName: [],
        });

        setTimeout(() => {
          this.HideTextBox(this.props.values.groupId);
        }, 500);
      })
      .catch((error) => {
        // // console.log(error);
        alert("failed !");
      });
  };

  GetTeamMembers = () => {
    const data = {
      username: this.state.createdby,
      groupId: this.props.values.groupId,
    };

    axios({
      method: "POST",
      url: `${BASE_URLs}contact/GetTeamMembers`,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log("testing", response.data);

        var EntityMail = response.data.map((e) => e.userName);

        var StrEntity = EntityMail.toString();

        var Both = StrEntity + "," + this.state.Memberslist;

        this.setState({
          mergedUsers: Both,
        });

        // // console.log(this.state.mergedUsers, "Check55");

        // let usersMap = {};
        // this.state.DUsername.forEach(group => {
        //   group.forEach(user => {
        //     usersMap[user.userName] = usersMap[user.userName] || {
        //       id: user.id, userName: user.userName,
        //       normalizedUserName: user.normalizedUserName,
        //       name: user.name,
        //       email: user.email,
        //       normalizedEmail: user.normalizedEmail,
        //       emailConfirmed: user.emailConfirmed,
        //       passwordHash: user.passwordHash,
        //       securityStamp: user.securityStamp,
        //       concurrencyStamp: user.concurrencyStamp,
        //       phoneNumber: user.phoneNumber,
        //       phoneNumberConfirmed: user.phoneNumberConfirmed,
        //       twoFactorEnabled: user.twoFactorEnabled,
        //       lockoutEnd: user.lockoutEnd,
        //       lockoutEnabled: user.lockoutEnabled,
        //       accessFailedCount: user.accessFailedCount,
        //       organization: user.organization,
        //       entityName: user.entityName,
        //       created_Date: user.created_Date,
        //       reminder: user.reminder,
        //       entityUsers: user.entityUsers
        //     };
        //   });
        // });

        // let dm = Object.values(usersMap).reduce((result, user) => {
        //   let groupIndex = result.findIndex(group => group.some(u => u.userName === user.userName));

        //   if (groupIndex === -1) {
        //     result.push([user]);
        //   } else {
        //     result[groupIndex].push(user);
        //   }
        //   return result;
        // }, []);

        // let op = [];
        // let tempMap = {};

        // for (let i = 0; i < dm.length; i++) {
        //   for (let j = 0; j < dm[i].length; j++) {
        //     const entityUsers = dm[i][j].entityUsers;

        //     if (!tempMap[entityUsers]) {
        //       tempMap[entityUsers] = [];
        //     }

        //     tempMap[entityUsers].push(dm[i][j]);
        //   }
        // }
        // for (const key in tempMap) {
        //   op.push(tempMap[key]);
        // }

        // // console.log("checking", op);

        // var mergedUsers = [];
        // for (var i = 0; i < op.length; i++) {
        //   var userstr = op[i].map((user) => user.userName)
        //   var str = userstr.toString();
        //   mergedUsers = mergedUsers.concat(str);
        //   // console.log("checked", mergedUsers)
        //   this.setState({
        //     mergedUsers: mergedUsers
        //   })
        // }

        // var userstr = op.map((user)=>user.userName)
        // var str = userstr.toString();
        // // console.log("checked",userstr)
      })
      .catch((error) => {
        // // console.log(error);
        alert("failed !");
      });
  };
  AddGroupMember = () => {
    var MultiMembers = this.state.selectedOptions.map((item) => item.value);
    //let check =document.getElementById('CheckAddmemberEmail').value
    //  // console.log(check,"hhheeheheh")
    //let Id = document.getElementById('groupid').value
    //let userId = document.getElementById('AddmemberEmail').value

    // let userId = MultiMembers.toString()
    // alert(userIddd)

    let userId = this.state.selectedUserIds.toString();

    let Id = document.getElementById("groupid").value;

    if (!userId) {
      alert("Choose Any Member");
    } else {

      const dataA = {     
        Group_Name: this.props.values.groupName,
        Group_id: Id,
        GroupMember: userId,
        Profile: this.state.ImgPath,
      };
  
      axios({
        method: "POST",
        url: `${BASE_URLs}contact/AddmemberStartMessage`,
        data: dataA,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      })
        .then((response) => {
          // // console.log(response.data);
       
        })
        .catch((error) => {
          // // console.log(error);
          alert("failed !");
        });



      const data = {
        id: Id,
        userid: userId,
      };
      // // console.log(data);
      setTimeout(() => {
      axios({
        method: "POST",

        url: `${BASE_URLs}Chat/AddGroupMember`,

        data: data,

        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "multipart/form-data",

          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      })
        .then((response) => {
          // // console.log(response.data);

          if (response.data.status === "Success") {
            alert(" User Added successfully ");
            this.setState({

              selectedUsers: [],
            });


            // document.getElementById('AddmemberEmail').value = ""
          }

          this.setState({
            isPopupOpen: false,
            // selectedUserIds: [],
            selectedUsers: [],
            selectedUserIds: [],
            // selectedUserName:[]
          });


          // // console.log("AllMemberslist", this.state.selectedUsers)

          this.GetMemberAccess(this.props.values.groupId);
          this.GetNonAccess(this.props.values.groupId);
          this.GetAllMembers(this.props.values.groupId);

          setTimeout(() => {
            this.SubmitAddMessage(
              this.props.values.groupId,
              this.props.values.groupName
            );
          }, 1000);
        })


        .catch((error) => {
          // // console.log(error);

          alert("User Can't Insert.");
        });
      }, 10);
    }
  };

  GetGroupId(GroupName) {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}Contact/GetGroupId?GroupName=${GroupName}`, config)
      .then((response) => {
        localStorage.setItem("GroupDataB", JSON.stringify(response.data));
        let groupdetail = localStorage.getItem("GroupDataB");
        let group = JSON.parse(groupdetail);
        let groupadmin = group.map((e) => e.created_by);
        let createdBy = groupadmin[0];
  
        let groupprivacy = group.map((e) => e.privacy);
        let GPrivacy = groupprivacy[0];
  
        let profile = group.map((e) => e.profile_Pic);
  
        let groupCreateDate = group.map((e) => e.created_Date);
  
        let groupCreatedName = group.map((e) => e.created_Name);
  
        //alert(profile)
        this.setState({
          createdby: createdBy,
          Privacy: GPrivacy,
          ImgPath: profile,
          createdDate: groupCreateDate,
          createdName: groupCreatedName,
        });
  
        // // console.log("CreatedBy", response.data);
      })
      .catch((error) => {
        // Handle error
      });
  }

  handleSelect = (data) => {
    var MultiMembers = this.state.selectedOptions.map((item) => item.value);
    let userId = MultiMembers.toString();

    if (userId) {
      document.getElementById("AddmemberError").innerHTML = "";
      //document.getElementById('addbutton').disabled = true
    } else {
      document.getElementById("AddmemberError").innerHTML = "";
      //document.getElementById('addbutton').disabled = false
    }

    // if (data.length <= 5) {

    //   this.setState({
    //     ...this.state.selectedOptions = data
    //   });
    // }

    // if (data.length > 5) {
    //   document.getElementById('MemberlnErr').innerHTML = "Maximum length is 5"
    // }
    // else{
    //   document.getElementById('MemberlnErr').innerHTML = " "
    // }
    this.setState({
      ...(this.state.selectedOptions = data),
    });
  };

  private = (e) => {
    e.preventDefault();
  };

  GroupPrivate = (e) => {
    e.preventDefault();
  
    Swal.fire({
      text: "Are you sure you want to change this Discussion to Public?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      customClass: {
        title: "swal-title",
        content: "swal-text",
        confirmButton: "swal-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        var Gname = this.state.updatedGroupTitle.length === 0 ? this.state.GroupTitle : this.state.updatedGroupTitle;
        var GId = document.getElementById("GId").value;
  
        const config = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        };
  
        const body = {
          GroupName: Gname,
          Group_id: GId,
          GroupMember: this.state.mergedUsers,
        };
  
        axios.post(`${BASE_URLs}contact/GroupPrivate`, body, config)
          .then((response) => {
            if (response.data.status === "success") {
              if (this.state.Privacy === 1) {
                this.setState({
                  privacy: 0,
                });
              } else {
                this.setState({
                  privacy: 1,
                });
              }
            }
            Swal.fire({
              text: "Discussion has been changed to Public!!",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                this.setState({ reloadbutton: !this.state.reloadbutton });
                this.setState({
                  privacy: 1,
                });
              }
            });
          })
          .catch((error) => {
            console.error("Error updating Seen field:", error);
            // Handle error
          });
      }
    });
  };

  GroupPublic = () => {
    Swal.fire({
      text: "Are you sure you want to change this Discussion to Private?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        var Gname = this.state.updatedGroupTitle.length === 0 ? this.state.GroupTitle : this.state.updatedGroupTitle;
        var GId = document.getElementById("GId").value;
  
        const config = {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        };
  
        const body = {
          GroupName: Gname,
          Group_id: GId,
          GroupMember: this.state.mergedUsers,
        };
  
        axios.post(`${BASE_URLs}contact/GroupPublic`, body, config)
          .then((response) => {
            // Handle the response data if needed
            Swal.fire({
              text: "Discussion has been changed to Private!!",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result.isConfirmed) {
                this.setState({ reloadbutton: !this.state.reloadbutton });
                this.setState({
                  privacy: 0,
                });
              }
            });
          })
          .catch((error) => {
            // Handle error
            // console.error("Error updating Seen field:", error);
          });
      }
    });
  };
  Checkclose = () => {
    this.props.close();

    // props.close
  };

  documentName = (dn) => {
    this.setState({
      DName: true,
      documentname: dn,
    });
  };

  documentNameL = () => {
    // if(dn==this.state.documentname && cd==this.state.documentdate)

    // {

    setTimeout(() => {
      this.setState({
        DName: false,
      });
    }, 500);
    // }
  };
  fileUploadAction = () => this.inputReference.current.click();
  UpdateGrpImg = () => {
    this.setState({
      EImage: "",
    });
    this.profileEditpopup();
  };
  fileUploadInputChange = (e) => {
    this.setState({
      EImage: "",
    });
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return;
    }

    if (!(selectedFile instanceof Blob || selectedFile instanceof File)) {
      return;
    }

    this.setState({
      fileUploadState: selectedFile,
      EImage: `${Image_URLs}Images/ImagePath_${selectedFile.name}`, // Accessing the name property of the selected file
      SampleImage: URL.createObjectURL(selectedFile)
    });
  };


  UpdateGroupProfile = (event) => {
    event.preventDefault();
    var OldImageChat = document.getElementById("OldImageChat").src;
    var GrpId = document.getElementById("GrpId").value;

    const data = {
      ImagePath: this.state.fileUploadState,
      GroupId: GrpId,
      OldImage: OldImageChat,
    };

    // // console.log(data, "updateprofile check");
    axios({
      method: "POST",
      url: `${BASE_URLs}Chat/UpdateProfile`,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log(response.data);
        if (response.data.status === "Success") {
          this.setState({
            ProfileEditPop: false,

            ImgPath: this.state.EImage,
          });
          this.GroupProfileEditMessage();

          alert("Updated Successfully");
          let status = localStorage.getItem("AllDataa");
          let statusdata = JSON.parse(status);

          let stss = statusdata.map((e) => e.createDateTime);
          this.GetAllMessage(this.props.values.groupId, stss);
        }
      })
      .catch((error) => {
        // // console.log(error);
        alert("failed !");
      });
  };

  handleClose = () => {
    this.setState({
      isVisible: false,
    });
  };
  resetEditImage = () => {
    this.setState({
      EImage: "",
    });
  };

  deleteProfile = () => {
    this.setState({
      EImage: DefaultProfile,
    });
  };
  ForwardChatMsg = (event) => {
    event.preventDefault();

    // var groupId = this.state.selectedOptionsFwd.map((item) => item.value);

    // let GroupId = groupId.toString();

    let GroupId = this.state.selectedGroupName.toString();

    const data = {
      Message: this.state.deletemsg,

      Picture_Path: this.state.deletepic,

      Document_Path: this.state.deletedoc,

      Group_id: GroupId,

    };

    // // console.log(data, "updatename check");

    axios({
      method: "POST",

      url: `${BASE_URLs}contact/GroupFwdMessage`,

      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log(response.data);

        this.setState({
          selectedOptionsFwd: [],

          forwardpopup: false,
          selectedGroupIds: [],
          selectedGroupName: []
        });
        this.GetForwardMembers();

        setTimeout(() => {
          this.HideTextBox(this.props.values.groupId);
        }, 500);
      })

      .catch((error) => {
        // // console.log(error);

        alert("Choose any Group");
      });
  };

  forwardopenpop = () => {
    this.setState({
      forwardpopup: !this.state.forwardpopup,

      MsgOption: false,
    });
  };
  GetForwardMembers = () => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}Chat/GetForwardMembers`, config)
      .then((response) => {
        this.setState({
          ForwardMembers: response.data,
          AllForwardMembers: response.data,
        });
        // // console.log("GroupList", response.data);
      })
      .catch((error) => {
        // Handle error
      });
  };

  handleSelectFwd = (data) => {
    var MultiMembers = this.state.selectedOptionsFwd.map((item) => item.value);
    let userId = MultiMembers.toString();

    this.setState({
      ...(this.state.selectedOptionsFwd = data),
    });
  };

  // componentDidMount() {

  //   window.addEventListener('resize', this.handleResize);
  //   this.handleResize();
  // }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  // handleResize = () => {

  //   const windowWidth = window.innerWidth;
  //   const shouldHide = windowWidth <= 1000;

  //   this.setState({
  //     Memberpopup: shouldHide,
  //   });
  // };

  openPopup = () => {
    this.setState({
      isPopupOpen: true,

      ProfileEditPop: false,
      Deleteuser: false,
      Enddisc: false,
      GrpnameEdit: false,
      attachMenu: false,
      MuteDisc: false,
      UnMuteDisc: false,
      showEmoji: false,
    });
  };

  closePopup = () => {
    this.setState({
      isPopupOpen: false,
      selectedUserIds: [],
      selectedUsers: [],
    });

    this.GetMemberAccess(this.props.values.groupId);
    this.GetNonAccess(this.props.values.groupId);
    this.GetAllMembers(this.props.values.groupId);
  };

  formatDateAA = (dateString) => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const oneWeekAgo = new Date(currentDate.getTime() - 7 * oneDay);

    const isToday =
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    const isYesterday =
      inputDate.getDate() === currentDate.getDate() - 1 &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    const isBeforeYesterday =
      inputDate.getTime() > oneWeekAgo.getTime() && !isYesterday;

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    if (isToday) {
      const timeOnly = inputDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return `${timeOnly}`;
    } else if (isYesterday) {
      return `${inputDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`;
    } else if (isBeforeYesterday) {
      // const formattedDate = inputDate.toLocaleDateString("en-US", { weekday: "long" });
      const formattedTime = inputDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return `${formattedTime}`;
    } else {
      return inputDate.toLocaleString("en-US", options);
    }
  };

  formatDateAA1 = (dateString) => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const oneWeekAgo = new Date(currentDate.getTime() - 7 * oneDay);

    const isToday =
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    const isYesterday =
      inputDate.getDate() === currentDate.getDate() - 1 &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    const isBeforeYesterday =
      inputDate.getTime() > oneWeekAgo.getTime() && !isYesterday;

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const options1 = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    if (isToday) {
      return "Today";
    } else if (isYesterday) {
      return "Yesterday";
    } else if (isBeforeYesterday) {
      const formattedDate = inputDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      // const formattedTime = inputDate.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
      return `${formattedDate}`;
    } else {
      return inputDate.toLocaleString("en-US", options1);
    }
  };

  FilterOption = (option, searchText) => {
    if (!searchText) {
      return true;
    }

    const label = option.label.toLowerCase();
    searchText = searchText.toLowerCase();
    const value = option.value.toLowerCase();

    return label.startsWith(searchText) || value.startsWith(searchText);
  };

  toggleMinimize = () => {
    const headerElement = document.getElementById("Grouppop-header");
    if (headerElement) {
        headerElement.style.height = this.state.Minimized ? "" : "52px"; // Example height values
        headerElement.style.marginLeft = this.state.Minimized ? "" : "-1px";
        headerElement.style.marginBottom = this.state.Minimized ? "" : "10px";
    }
    this.setState((prevState) => ({
      Minimized: !prevState.Minimized,
    }));
  };

  SubmitEditMessage = () => {
    // let String_User = this.state.selectedUserName.toString();

    var grpname = document.getElementById("Groupname").value;

    var GrpId = document.getElementById("GrpId").value;

    var members = this.state.AllMembers;

    let lMembers = members.map((e) => e.user_id);

    let Membersstr = lMembers.toString();
    const data = {
      // group_Name: GroupName,

      Group_Name: grpname,
      Group_id: GrpId,
      GroupMember: Membersstr,
      Profile: this.state.ImgPath,
    };
    // // console.log(data, "edittttttttttttttt");

    axios({
      method: "POST",
      url: `${BASE_URLs}contact/GroupNameEditMessage`,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log("jdfjdh", response.data);
      })
      .catch((error) => {
        // // console.log(error);
        alert("failed!");
      });
  };

  GroupProfileEditMessage = () => {
    // let String_User = this.state.selectedUserName.toString();

    var grpname = document.getElementById("Groupname").value;

    var GrpId = document.getElementById("GrpId").value;

    var members = this.state.AllMembers;

    let activeMembers = members.filter((member) => member.end_Status === 1 && member.status === 1);

    let lMembers = activeMembers.map((e) => e.user_id);

    let Membersstr = lMembers.toString();
    const data = {
      // group_Name: GroupName,

      Group_Name: grpname,
      Group_id: GrpId,
      GroupMember: Membersstr,
      Profile: this.state.ImgPath,
    };
    //  // console.log(data,'edittttttttttttttt')

    axios({
      method: "POST",
      url: `${BASE_URLs}contact/GroupProfileEditMessage`,
      data: data,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log(response.data);
      })
      .catch((error) => {
        // // console.log(error);
        alert("failed !");
      });
  };
  OneononeUser() {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}UserBank/GetAllUserPop`, config)
      .then((response) => {
        // Handle the response data if needed
        // // console.log("GPoneonone", response.data);
      })
      .catch((error) => {
        // Handle error
      });
  }
  handleUserRemove = (email, name) => {
    this.setState((prevState) => {
      const updatedSelectedUsers = prevState.selectedUsers.filter(
        (user) => user.email !== email
      );
      const updatedSelectedUserIds = prevState.selectedUserIds.filter(
        (userId) => userId !== email
      );
      const updatedSelectedUserName = prevState.selectedUserName.filter(
        (userName) => userName !== name
      );

      //const updatedSelectedUsersA = prevState.selectedUsersA.filter((usersA) => usersA.email !== email);

      return {
        selectedUsers: updatedSelectedUsers,
        selectedUserIds: updatedSelectedUserIds,
        selectedUserName: updatedSelectedUserName,

        //selectedUsersA: updatedSelectedUsersA
      };
    });
  };
  handleGroupRemove = (group_Name, group_id) => {
    this.setState((prevState) => {
      const updatedSelectedGroup = prevState.selectedGroup.filter(
        (user) => user.group_Name !== group_Name
      );

      const updatedSelectedGroupIds = prevState.selectedGroupIds.filter(
        (id) => id !== group_id
      );

      return {
        selectedGroup: updatedSelectedGroup,
        selectedGroupIds: updatedSelectedGroupIds,
      };
    });
  };
  handleUserSelect = (id) => {
    this.setState((prevState) => {
      const selectedUserIds = prevState.selectedUsers.map((user) => user.id);
      if (selectedUserIds.includes(id)) {
        return {
          selectedUsers: prevState.selectedUsers.filter(
            (user) => user.id !== id
          ),
        };
      }
      const userToAdd = prevState.Addmembers.find((user) => user.id === id);
      return {
        selectedUsers: [...prevState.selectedUsers, userToAdd],
      };
    });
  };

  handleUserSelectionChange = (event) => {
    //document.getElementById("Searchbox2").style.display = "block";
    const { value, checked } = event.target;
    this.setState((prevState) => {
      let selectedUsers = [...prevState.selectedUsers];
      let selectedUserIds = [...prevState.selectedUserIds];
      let selectedUsersA = [...prevState.selectedUsersA];
      let selectedUserName = [...prevState.selectedUserName];
      if (checked) {
        const userToAdd = prevState.Addmembers.find(
          (user) => user.email === value
        );
        if (userToAdd) {
          selectedUsers.push(userToAdd);
          selectedUserIds.push(userToAdd.email);
        }

        const userNameToAdd = prevState.Addmembers.find(
          (user) => user.email === value
        );
        if (userNameToAdd) {
          // let Both = userNameToAdd.name + " [ " + userNameToAdd.email + " ] ";
          let Both = userNameToAdd.name;
          selectedUsersA.push(userNameToAdd);
          selectedUserName.push(Both);
        }
      } else {
        const userNameToAdd = prevState.Addmembers.find(
          (user) => user.email === value
        );

        selectedUsers = selectedUsers.filter((user) => user.email !== value);
        selectedUserIds = selectedUserIds.filter((email) => email !== value);

        selectedUsersA = selectedUsersA.filter((user) => user.email !== value);
        selectedUserName = selectedUserName.filter(
          (name) => name !== userNameToAdd.name
        );
      }

      return { selectedUsers, selectedUserIds, selectedUserName };
    });
    // this.handleUserRemove()
  };

  handleGroupSelectionChange = (event) => {
    //document.getElementById("Searchbox2").style.display = "block";
    const { value, checked } = event.target;
    this.setState((prevState) => {
      let selectedGroup = [...prevState.selectedGroup];
      let selectedGroupIds = [...prevState.selectedGroupIds];
      let selectedGroupsA = [...prevState.selectedGroupsA];
      let selectedGroupName = [...prevState.selectedGroupName];
      if (checked) {
        const userToAdd = prevState.ForwardMembers.find(
          (user) => user.group_Name === value
        );
        if (userToAdd) {
          selectedGroup.push(userToAdd);
          selectedGroupIds.push(userToAdd.group_Name);
        }

        const userNameToAdd = prevState.ForwardMembers.find(
          (user) => user.group_Name === value
        );
        if (userNameToAdd) {
          // let Both = userNameToAdd.name + " [ " + userNameToAdd.email + " ] ";
          let Both = userNameToAdd.group_id;
          selectedGroupsA.push(userNameToAdd);
          selectedGroupName.push(Both);
        }
      } else {
        const userNameToAdd = prevState.ForwardMembers.find(
          (user) => user.group_Name === value
        );
        selectedGroup = selectedGroup.filter(
          (user) => user.group_Name !== value
        );
        selectedGroupIds = selectedGroupIds.filter(
          (group_Name) => group_Name !== value
        );

        selectedGroupsA = selectedGroupsA.filter(
          (user) => user.group_Name !== value
        );
        selectedGroupName = selectedGroupName.filter(
          (group_id) => group_id !== userNameToAdd.group_id
        );
      }

      return { selectedGroup, selectedGroupIds, selectedGroupName };
    });
    // this.handleUserRemove()
  };

  Addmemberfilter = (e) => {
    clearInterval(this.interval);
    const MemberAccess = this.state.AllMemberAccess.filter(
      (item) =>
        item.firstName.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ MemberAccess, showResults: true });


    const NonAccess = this.state.AllNonAccess.filter(
      (item) =>
        item.firstName.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ NonAccess, showResults: true });

    if (MemberAccess.length === 0 && NonAccess.length === 0) {
      document.getElementById("AddmemberFilterErr").innerHTML =
        " Member Not Found";
    } else {
      document.getElementById("AddmemberFilterErr").innerHTML = "";
    }
  };

  forwardfilter = (e) => {
    const ForwardMembers = this.state.AllForwardMembers.filter(
      (item) =>
        item.group_Name.toLowerCase().startsWith(e.target.value.toLowerCase())
      // item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ ForwardMembers, showResults: true });
    if (ForwardMembers.length === 0) {
      document.getElementById("ForwardFilterErr").innerHTML =
        " Group Name Not Found";
    } else {
      document.getElementById("ForwardFilterErr").innerHTML = "";
    }
  };
  changeDName = () => {
    this.setState({ EditDName: true });
  };

  restoreDName = () => {
    this.setState({ EditDName: false });
  };

  truncateTitle(title, maxLength, maxWidth) {
    if (title.length > maxLength && window.innerWidth < maxWidth) {
      return title.substring(0, maxLength) + "...";
    }
    return title;
  }

  updateSeenside = () => {
    const { windowWidth } = this.state;
    let Seenside = true;
  
    // Check window width and update Seenside if necessary
    if (windowWidth <= 990) {
      Seenside = false;
    }
  
    return Seenside;
  };

  handleResize = () => {
    this.setState(prevState => ({
      width: window.innerWidth,
      height: window.innerWidth < 700 ? 297 : 340,
      emojiWidth: window.innerWidth < 700 ? 275 : 315,
      windowWidth: window.innerWidth,
      Seenside: this.updateSeenside()
    }));
  };

  Menu = () => {
    this.setState(prevState => ({
      Seenside: !prevState.Seenside,
      initialScroll: window.innerWidth <= 990
    }));
  };

  render() {
    var w = window.innerWidth;
    const {
      selectedUserIds,
      selectedUsers,
      selectedGroup,
      selectedGroupIds,
      Message,
      Allmessage,
      AllMembers,
      AllMember,
      Popmsg,
      message,
      ReceivepopMsg,
      isButton1Visible,
      isButton2Visible,
      isBackArrowVisible,
      showEmoji,
      GroupTitle,
      Deleteuserid,
      Deleteusername,

      Addmembers,
      filterurl,
      createdby,
      Privacy,
      selectedOptions,
      Memberslist,
      DName,
      isdocumentpopOpen,
      ispicturepopOpen,
      documentname,
      documentdate,
      isVisible,
      MsgOption,
      ImgPath,
      EImage,
      SampleImage,
      forwardpopup,
      ForwardMembers,
      selectedOptionsFwd,
      mergedUsers,
      EndStatus,
      hover,
      Minimized,
      Status,
      MuteStatus,
      SeenMsgMembers,
      Seen,
      EditDName,
      checkClosedDate,
      MemberAccess,
      NonAccess,
      Seenside,
      height,
      emojiWidth,
    } = this.state;
    const { windowWidth } = this.state;
    const newSrc =
      "https://i.pinimg.com/1200x/64/53/c8/6453c8226817e6ab85a6321aeee19e80.jpg";
    const mail = localStorage.getItem("email");
    // // console.log(mail)
    const EMail = mail.replace(/"/g, "");
    // // console.log(cleanedMail);

    const Name = localStorage.getItem("uname");
    //// console.log(Name)
    const name = Name.replace(/"/g, "");
    //   {
    //     data.map((item) =>
    //         const propertyName = item.productName;

    //         <div className="flex-container card" key={item._id}>
    //             <div className="content">
    //                 <p>{propertyName}</p>
    //             </div>
    //         </div>
    //     )
    // }

    const options = Addmembers.map((item) => {
      return {
        //label:`${item.name}${item.email}`,
        label: item.name,

        value: item.email,

        // name: `${item.name}${" [ "}${item.email}${" ] "}`
        name: `${item.name}`,
      };
    });

    const formatOptionLabel = ({ value, label }) => (
      <div>
        <div>{label}</div>

        <div style={{ fontSize: "12px" }}>{value}</div>
      </div>
    );
    // const option=(Addmembers.map((member)=>{
    // value;{member.name}
    // }))
    const messages = [...Popmsg, ...ReceivepopMsg];
    var UserId = JSON.parse(localStorage.userId);
    // // console.log("fffffffffff", UserId);
    var Email = JSON.parse(localStorage.email);

    const menuStyles = {
      position: "absolute",
      top: "110px",
      zIndex: "100",
      left: "48%",
      backgroundColor: "white",
      padding: "10px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      display: this.state.showMenu ? "block" : "none",
      cursor: "pointer",
      borderRadius: "10px",
    };

    const LeaveDisucpop = {
      position: "absolute",
      zIndex: "2",
      // top: "175px",

      left: "16%",

      backgroundColor: "white",
      display: this.state.Leavedisc ? "block" : "none",
      cursor: "pointer",
      //  width: "28%",
      // height: "36%",
      height: "200px",
      borderRadius: "5px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
    };

    const attachStyles = {
      position: "absolute",
      //  top: "400px",

      // marginTop:"22%",
      alignItems: "center",
      zIndex: "100",
      left: "46%",
      backgroundColor: "white",
      padding: "10px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      display: this.state.attachMenu ? "block" : "none",
      cursor: "pointer",
      borderRadius: "9px",
    };

    const deleteStyles = {
      position: "fixed",
      // top: "350px",
      alignItems: "center",
      zIndex: "100",
      // left: "30%",
      // width: "300px",
      backgroundColor: "white",
      padding: "10px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      display: this.state.isVisible ? "block" : "none",
      cursor: "pointer",
      borderRadius: "9px",
    };
    const MessageOption = {
      position: "absolute",
      // top: "350px",
      alignItems: "center",
      zIndex: "100",
      // left: "19.5%",
      width: "130px",
      backgroundColor: "white",
      padding: "10px",
      boxShadow: "rgb(0 0 0 / 20%) 0px 2px 5px",
      display: this.state.MsgOption ? "block" : "none",
      cursor: "pointer",
      borderRadius: "none",
      bottom: "10%"
    };

    const MessageOptionSender = {
      position: "fixed",
      top: "350px",
      alignItems: "center",
      zIndex: "100",
      left: "39.5%",
      width: "300px",
      backgroundColor: "white",
      padding: "10px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      display: this.state.deleteMenu ? "block" : "none",
      cursor: "pointer",
      borderRadius: "9px",
    };
    const infopopup = {
      position: "absolute",
      zIndex: "100",
      top: "70px",
      left: "-10%",
      backgroundColor: "white",
      display: this.state.Infopopup ? "block" : "none",
      cursor: "pointer",
      width: "67%",
      height: "80.5%",
      border: "3px solid rgb(156 145 244)",
    };

    const editpopup = {
      position: "absolute",
      zIndex: "100",
      top: "70px",
      left: "-10%",
      backgroundColor: "white",
      display: this.state.Editpopup ? "block" : "none",
      cursor: "pointer",
      width: "67%",
      height: "80.5%",
      border: "3px solid rgb(156 145 244)",
    };

    const grpnameeditpop = {
      position: "absolute",
      zIndex: "100",
      // top: "188px",
      left: "15.5%",
      backgroundColor: "white",
      display: this.state.GrpnameEdit ? "block" : "none",
      cursor: "pointer",
      // width: "100%",
      // height: "36%",
      height: "200px",
      // border: '1px solid #ddd ',
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      borderRadius: "5px",
    };
    const profileeditpop = {
      position: "absolute",
      zIndex: "100",
      backgroundColor: "white",
      display: this.state.ProfileEditPop ? "block" : "none",
      cursor: "pointer",
      height: "228px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      borderRadius: "5px",
    };
    const documentpop = {
      position: "absolute",

      zIndex: "100",

      // top: "13px",

      // left: "3%",

      backgroundColor: "white",

      display: this.state.Documentpopup ? "block" : "none",

      cursor: "pointer",

      // width: "84%",

      // height: "100.5%",

      border: "3px solid rgb(156 145 244)",
    };

    const picturepop = {
      position: "absolute",

      zIndex: "100",

      // top: "13px",

      // left: "3%",

      backgroundColor: "white",

      display: this.state.Picturepopup ? "block" : "none",

      cursor: "pointer",

      // width: "84%",

      // height: "100.5%",

      border: "3px solid rgb(156 145 244)",
    };

    const endDisucpop = {
      position: "absolute",
      zIndex: "2",
      // top: "175px",

      left: "16%",

      backgroundColor: "white",
      display: this.state.Enddisc ? "block" : "none",
      cursor: "pointer",
      //  width: "28%",
      // height: "36%",
      height: "200px",
      borderRadius: "5px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
    };

    const MuteDisucpop = {
      position: "absolute",
      zIndex: "2",
      // top: "188px",
      left: "16%",
      backgroundColor: "white",
      display: this.state.MuteDisc ? "block" : "none",
      cursor: "pointer",
      // width: "24%",
      // height: "36%",
      height: "200px",
      borderRadius: "5px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
    };

    const UnMuteDisucpop = {
      position: "absolute",
      zIndex: "2",
      // top: "188px",
      left: "16%",
      backgroundColor: "white",
      display: this.state.UnMuteDisc ? "block" : "none",
      cursor: "pointer",
      // width: "24%",
      // height: "36%",
      height: "200px",
      borderRadius: "5px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
    };

    const deletepop = {
      position: "absolute",
      zIndex: "100",
      // top: "188px",
      left: "15.5%",
      backgroundColor: "white",
      display: this.state.Deleteuser ? "block" : "none",
      cursor: "pointer",
      // width: "38%",
      //height: "36%",
      height: "200px",
      borderRadius: "5px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
    };

    const seenStyles = {
      // position: "fixed",
      // top: "350px",
      // alignItems: "center",
      // zIndex: "100",
      // left: "30%",
      // width: "300px",
      // backgroundColor: "white",
      // padding: "10px",
      // boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      display: this.state.Seen ? "block" : "none",
      // cursor: "pointer",
      // borderRadius: "9px",
    };

    const searchpop = {
      zIndex: "100",
      display: this.state.Search ? "block" : "none",
    };

    const errorpop = {
    };

    const addpop = {
      zIndex: "100",
      display: this.state.Addpopup ? "block" : "none",
      
    };
    const Memberpop = {
      zIndex: "100",
      display: this.state.Memberpopup ? "block" : "none",
    };

    const hiddenpop = {
      zIndex: "100",
      display: this.state.hiddenpop ? "block" : "none",
    };
    const forwardpop = {
      zIndex: "101",
      display: this.state.forwardpopup ? "block" : "none",
    };

    const Fwdoptions = ForwardMembers.map((item) => {
      return {
        //label:`${item.name}${item.email}`,
        label: item.group_Name,

        value: item.group_id,

        name: item.profile_Pic,
      };
    });

    const formatOptionLabelFwd = ({ name, label }) => (
      // <center>
      <div className="row">
        <div style={{ fontSize: "12px" }} className="col-lg-4">
          <img
            src={name}
            style={{ height: "30px", width: "30px", borderRadius: "50%" }}
          />
        </div>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <div>
          <center>
            <b style={{ marginTop: "8px" }}>{label}</b>
          </center>
        </div>
      </div>
      // </center>
    );

    var Accessed = JSON.parse(localStorage.access);
    var Accessed_to = JSON.parse(localStorage.accessed_to);
    // var Members = JSON.parse(localStorage.AllGroupMember);

    // // console.log("Allmemberssss",Members)

    const regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return (
      <>
        {this.state.AddSkillModel ? (
          <EntityList id="" close={() => this.AddSkillModel()} />
        ) : null}
        {isButton1Visible && (
          <div
            className={Minimized ? "popA" : "popD"}
            style={
              Minimized
                ? {
                  //  marginTop: "48%",
                  //   marginLeft: "452px",
                  // width: "25%",
                  // backgroundColor: "transparent",
                  // height: "92px",
                  // position: "absolute",
                  //   marginTop:'180px',
                  //  marginLeft:'23%',
                  // zIndex: "+21",
                }
                : {
                  backgroundColor: "#f6f9fa",
                  width: "100%",
                  // marginTop: "15.7%",
                  // marginLeft: "29%",
                  // marginTop: "5.7%",
                  // marginLeft: "20%",
                  zIndex: "+10",
                }
            }
            // style={{ backgroundColor: "transparent", width: "100%" }}
            // id={ Minimized?'':"minimizediv"}
            id="minimizediv"
          >
            {/* <SideBar active={window.location.hash}/> */}
            {/* {this.state.AddSkillModel?  <EntityList id=""  close={()=>this.AddSkillModel()}/>:null}
           {this.state. AddGroupModel?<ChatGrupPop id="" close={()=>this.AddGroupModel()}></ChatGrupPop>:null} */}
            <div
              id="GroupPop-Chat"
            //  style={{width:"80%"}}
            >
              <div>
                <div className="main-body">
                  <div>
                    <div className="">
                      <div className="page-header-title"></div>
                    </div>
                    <div className="page-body">
                      <div>
                        <form
                          id="contactForm"
                          autoComplete="off"
                          encType="multipart/form-data"
                          onSubmit={this.handleSubmit}
                        >
                          <div
                            className="had-container"
                            id="had-container1"
                            style={{ marginTop: "-0.6%" }}
                          >
                            <div
                              className="chat-header p-10 bg-gray"
                              // style={{ backgroundColor: "rgb(156 145 244)",width:"80%",height:"60px" }}
                              id="Grouppop-header"

                            // style={minimizestyle}
                            >
                              {Minimized ? null : (
                                <button
                                  style={{
                                    float: "left",
                                    backgroundColor: "#a0a3a305",
                                    color: "#fff",
                                    // border: "1px solid #a3a5a505",
                                    cursor: "pointer",
                                    marginTop: "1%",
                                    border: "none",
                                  }}
                                  onClick={() => {

                                    this.props.close();
                                    clearInterval(this.interval);

                                  }}
                                //  onClick={this.OneononeUser}
                                >
                                  <i
                                    className="icofont icofont-arrow-left"
                                    id="backgroup"
                                  ></i>
                                  {/* <className="fa-solid fa-arrow-turn-left" /> */}
                                </button>
                              )}
                              <div className="user-info d-inline-block f-left">
                                <div
                                  className="Namepass"
                                  
                                >
                                  <input
                                    type="hidden"
                                    name="Group_id"
                                    defaultValue={this.props.values.groupId}
                                  />
                                  <input
                                    type="hidden"
                                    name="Group_Name"
                                    defaultValue={GroupTitle}
                                  />

                                  <input
                                    type="hidden"
                                    name="Profile"
                                    defaultValue={ImgPath}
                                  />
                                  <span
                                    className="userNames"
                                  //defaultValue={this.props.values.groupId}
                                  >
                                    {Minimized ? null : (
                                      <>
                                        {createdby === UserId && EndStatus == 1 ? (
                                          <a
                                            href="javascript:void(0);"
                                            title="Change Profile"
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "600",
                                              color: "white",
                                              textShadow: "2px 2px #6f60e7",
                                            }}
                                          >
                                            <img
                                              src={hover ? newSrc : ImgPath.toString() === `${Image_URLs}Images/profile.png` ? Profile : ImgPath}
                                              className="img-circle img-chat-profile"
                                              id="imgProfile"
                                              alt="img"
                                              onClick={() => {
                                                this.fileUploadAction();
                                                this.UpdateGrpImg();
                                              }}
                                              onMouseOver={this.changeImage}
                                              onMouseOut={this.restoreImage}
                                            />
                                          </a>
                                        ) : (
                                          <a
                                            href="javascript:void(0);"
                                            title=" Discussion Profile"
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "600",
                                              color: "white",
                                              textShadow: "2px 2px #6f60e7",
                                            }}
                                          >
                                            <img
                                              src={hover ? newSrc : ImgPath.toString() === `${Image_URLs}Images/profile.png` ? Profile : ImgPath}
                                              className="img-circle img-chat-profile"
                                              alt="img"
                                              // onClick={this.profileEditpopup}
                                              // onMouseOver={this.changeImage}
                                              // onMouseOut={this.restoreImage}
                                            />
                                          </a>
                                        )}
                                      </>
                                    )}
                                    &nbsp;&nbsp;
                                    <a
                                      href="javascript:void(0);"
                                      title={GroupTitle}
                                      style={{
                                        fontWeight: "600",
                                        color: "white",
                                        textShadow: "2px 2px #6f60e7",
                                        cursor: "default",
                                      }}
                                      className="GroupTi"
                                    >
                                      <span>
                                        {createdby === UserId &&
                                          EndStatus == 1 ? (
                                          <span
                                            onClick={this.grpnameedit}
                                            title="Edit Discussion Name"
                                            onMouseOver={this.changeDName}
                                            onMouseOut={this.restoreDName}
                                            className={
                                              EditDName ? "PrivateLink" : ""
                                            }
                                          >
                                            {this.truncateTitle(this.state.updatedGroupTitle.length === 0 ? GroupTitle : this.state.updatedGroupTitle, 7, 700)}
                                          </span>
                                        ) : (
                                          <span title={GroupTitle}>
                                            {this.truncateTitle(this.state.updatedGroupTitle.length === 0 ? GroupTitle : this.state.updatedGroupTitle, 7, 700)}
                                          </span>
                                        )}
                                        &nbsp;
                                        {Minimized ? null : (
                                          <>
                                            &nbsp;
                                            {Privacy === 0 &&
                                              this.state.reloadbutton === true &&
                                              EndStatus == 1 &&
                                              Status == 1 && createdby === UserId ? (
                                              <>
                                                <a title="Private">
                                                  <span
                                                    className="PrivateLink"
                                                    onClick={this.GroupPrivate}
                                                  >
                                                    (&nbsp;Private&nbsp;)
                                                  </span>
                                                </a>
                                                &nbsp;
                                              </>
                                            ) : (
                                              <>
                                                {EndStatus == 1 &&
                                                  Status == 1 && createdby === UserId ? (
                                                  <a title="Public">
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                      onClick={this.GroupPublic}
                                                      className="PrivateLink"
                                                    >
                                                      (&nbsp;Public&nbsp;)
                                                    </span>
                                                  </a>
                                                ) : null}
                                                &nbsp;
                                              </>
                                            )}
                                            &nbsp;&nbsp;&nbsp;
                                          </>
                                        )}
                                        {/* {Minimized ? null : (
                                          <>
                                            {createdby === UserId &&
                                            EndStatus == 1 ? (
                                              <a
                                                href="javascript:void(0);"
                                                title="Change Discussion Name"
                                                style={{
                                                  fontSize: "18px",
                                                  fontWeight: "600",
                                                  color: "white",
                                                  textShadow: "2px 2px #6f60e7",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-pencil"
                                                  aria-hidden="true"
                                                  onClick={this.grpnameedit}
                                                ></i>
                                              </a>
                                            ) : null}
                                          </>
                                        )} */}
                                      </span>
                                    </a>
                                    {/* &nbsp;&nbsp;&nbsp;&nbsp;
                                   
                                    <i className="fa fa-search" title="Search" aria-hidden="true" onClick={this.SearchClick}
                                     style={{
                                      fontSize: "18px",
                                      fontWeight: "600",
                                      color: "white",
                                      textShadow: "2px 2px #6f60e7",
                                      
                                    }}
                                    ></i> */}
                                    {/* <input
                                            className="Searchbox"
                                            placeholder="Search Messages"
                                            onChange={this.MessageFilter}
                                            style={searchpop}
                                          /> */}
                                    {/* <center>
                                      <span
                                        id="FilterErr"
                                        style={errorpop}
                                      ></span>
                                    </center> */}
                                  </span>

                                  {/* <i
                            onClick={() => {
                              this.AddSkillModel();
                              
                              this.handleButton1Click();
                            }}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                this.AddSkillModel();
                                
                                this.handleButton1Click();
                              }
                            }}
                            className="icofont icofont-arrow-left chatButtonTextColor"
                            style={{ fontSize: "45px" }}
                          /> */}
                                </div>
                              </div>


                              <div
                                className="box-tools d-inline-block"
                                id="refont"
                                style={{ float: "right" }}
                              > 
                              
                                {Minimized ? null :
                                <>
                                {windowWidth  <= 990 && (
                               <div 
                               style={{
                                float: "right",
                                backgroundColor: "#a0a3a305",
                                color: "#797070",
                                border: "1px solid #a3a5a505",
                                cursor: "pointer",
                                marginTop:"2px",
                                marginLeft:"5px",
                              }} 
                              onClick={this.Menu}> 
                              <i className="fa fa-ellipsis-v" aria-hidden="true"
                              ></i>
                              </div> 
                              
                              )}</> }
                              &nbsp;&nbsp; 
                                <span
                                  id="close"
                                  style={{
                                    float: "right",
                                    backgroundColor: "#a0a3a305",
                                    color: "#797070",
                                    border: "1px solid #a3a5a505",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {

                                    this.props.close();
                                    clearInterval(this.interval);

                                  }}
                                //  onClick={this.OneononeUser}
                                >
                                  &nbsp;&nbsp;
                                  <CloseIcon />
                                </span>

                                <div
                                  style={{
                                    float: "right",
                                    backgroundColor: "#a0a3a305",
                                    color: "#797070",
                                    border: "1px solid #a3a5a505",
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {Minimized ? null : (
                                    <>
                                      {MuteStatus.map((muted) => {
                                        return (
                                          <>
                                            {muted.muteStatus === 0 &&
                                              EndStatus == 1 &&
                                              Status == 1 ? (
                                              <i
                                                className="fa-solid fa-microphone"
                                                id="mute"
                                                style={{
                                                  fontWeight: "600",
                                                }}
                                                onClick={this.MuteDisc}
                                              ></i>
                                            ) : (
                                              <>
                                                {EndStatus == 1 &&
                                                  Status == 1 ? (
                                                  <i
                                                    className="fa-solid fa-microphone-slash"
                                                    id="mute"
                                                    style={{
                                                      fontWeight: "600",
                                                    }}
                                                    onClick={this.UnMuteDisc}
                                                  ></i>
                                                ) : ( <i
                                                  className="fa-solid fa-microphone"
                                                  id="mute"
                                                  style={{
                                                    fontWeight: "600",
                                                    visibility:"hidden"
                                                  }}
                                                ></i>)}
                                              </>
                                            )}
                                          </>
                                        );
                                      })}{" "}
                                      &nbsp;&nbsp;
                                      <i
                                        className="fa fa-search"
                                        title="Search"
                                        aria-hidden="true"
                                        onClick={this.SearchClick}
                                        style={{
                                          fontWeight: "600",
                                          // color: "white",
                                          // textShadow: "2px 2px #6f60e7",
                                        }}
                                      ></i>
                                      &nbsp;&nbsp;
                                    </>
                                  )}
                                  <span onClick={this.toggleMinimize}>
                                    {" "}
                                    {Minimized ? (
                                      <span className="fa fa-window-maximize"></span>
                                    ) : (
                                      <span className="fa fa-minus"></span>
                                    )}
                                  </span>
                                </div>
                                {Minimized ? null :
                                  <div style={{ marginLeft: "-80%" }}>
                                    {" "}
                                    <input
                                      className="Searchbox"
                                      placeholder="Search Messages"
                                      id="searchresM"
                                      onChange={this.MessageFilter}
                                      style={searchpop}
                                    />{" "}
                                  </div>
                                }
                                {/* <div id="sidenav" onClick={this.openpop}  style={{
                                    float: "right",
                                    backgroundColor: "#a0a3a305",
                                    color: "#797070",
                                    border: "1px solid #a3a5a505",
                                   
                                  }}><i className="ti ti-menu"></i></div> */}
                                <div
                                  style={{
                                    float: "right",
                                    backgroundColor: "#a0a3a305",
                                    color: "#797070",
                                    border: "1px solid #a3a5a505",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                >
                                  {/* <button>Private</button>
                          <button>Public</button> */}
                                  <input
                                    type="hidden"
                                    id="Gname"
                                    defaultValue={this.props.values.groupName}
                                  />
                                  <input
                                    type="hidden"
                                    id="GId"
                                    defaultValue={this.props.values.groupId}
                                  />
                                  {/* {Privacy == 1 && createdby == UserId ? <span > <b style={{ fontSize: "12px" }} onClick={this.GroupPublic}>Public</b></span> : null}  {Privacy == 1 ? <span><i className="fa fa-unlock" aria-hidden="true" ></i></span> : null}&nbsp;&nbsp;&nbsp;
                          {Privacy == 0 && createdby == UserId ? <span > <b style={{ fontSize: "12px" }} onClick={this.GroupPrivate}>Private</b></span> : null} {Privacy == 0 ? <span><i className="fa fa-lock" aria-hidden="true" ></i></span> : null}&nbsp;&nbsp;&nbsp;
                          {Privacy == 1 || createdby == UserId ? <i className="fa fa-user-plus" aria-hidden="true" onClick={this.Addpop} id="AddIcon"></i> : null}&nbsp;&nbsp;&nbsp;
                          <i className="fa fa-ellipsis-v" aria-hidden="true" onClick={this.toggleMenu} id="MenuIcon" ></i> */}
                                </div>
                              </div>
                            </div>

                            <div style={menuStyles} ref={this.containerRef}>
                              <ul id="listmenu" style={{ lineHeight: "2.0em" }}>
                                {Privacy == 1 || createdby === UserId ? (
                                  <>
                                    <li
                                      style={{ float: "left" }}
                                      onClick={this.displayeditpop}
                                    >
                                      Edit
                                    </li>
                                    <br></br>
                                  </>
                                ) : null}

                                <li
                                  style={{ float: "left" }}
                                  onClick={this.displaypopup}
                                >
                                  Info
                                </li>
                                <br></br>
                                {Privacy == 1 || createdby === UserId ? (
                                  <>
                                    <li onClick={this.enddisc}>
                                      End Discussion
                                    </li>
                                  </>
                                ) : null}
                              </ul>
                            </div>
                            <div id="attachments" style={attachStyles}>
                              <ul id="listmenu">
                                <label style={{ marginTop: "8px" }}>
                                  <span
                                    className="fa fa-picture-o"
                                    aria-hidden="true"
                                    style={{
                                      color: "#5d4fd0",
                                      marginLeft: "7px",
                                      cursor: "pointer",
                                    }}
                                    onClick={this.Picturepop}
                                  ></span>
                                </label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <br />
                                <label style={{ marginTop: "8px" }}>
                                  <span
                                    className="fa fa-file"
                                    aria-hidden="true"
                                    style={{
                                      color: "#5d4fd0",
                                      cursor: "pointer",
                                    }}
                                    onClick={this.Documentpop}
                                  ></span>
                                </label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                              </ul>
                            </div>

                            <div className="deletepopA" style={deletepop}>
                              <center style={{ marginTop: "50px" }}>
                                <input
                                  defaultValue={this.props.values.groupId}
                                  type="hidden"
                                  id="groupid"
                                ></input>
                                <input
                                  defaultValue={Deleteuserid}
                                  type="hidden"
                                  id="duserid"
                                ></input>
                                <input
                                  defaultValue={Deleteusername}
                                  type="hidden"
                                  id="dusername"
                                ></input>
                                <h6>
                                  Are You Sure Wants to Remove?{" "}
                                  {/* <span style={{ color: "red" }}>Remove?</span> */}
                                </h6>
                                <h6>
                                  <span style={{ color: "red" }}>
                                    "{Deleteusername}"
                                  </span>
                                </h6>
                                <br></br>
                                <span
                                  id="GroupopYesbtn"
                                  onClick={this.DeleteUserGroup}
                                >
                                  Yes
                                </span>{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                <span
                                  id='GroupopNobtn'
                                  onClick={this.hideDeletepop}
                                >
                                  No
                                </span>
                              </center>

                              <br></br>
                            </div>

                            <div style={infopopup}>
                              <i
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                                style={{
                                  color: "gray",
                                  float: "left",
                                  fontSize: "20px",
                                  marginLeft: "20px",
                                  marginTop: "20px",
                                }}
                                onClick={this.hidedisplaypop}
                              ></i>
                              <div>
                                <div style={{ marginTop: "48px" }}>
                                  <span>
                                    <center>
                                      <a href="javascript:void(0);" title="Me">
                                        <img
                                          style={{
                                            width: "85px",
                                            height: "85px",
                                          }}
                                          // src="assets/images/avatar-1.png"
                                          src={ImgPath}
                                          className="img-circle img-chat-profile"
                                          alt="Me"
                                        />
                                      </a>
                                      <br></br> <br></br>
                                      <h4
                                        style={{
                                          fontFamily: "Copperplate",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {this.props.values.groupName}
                                      </h4>
                                    </center>
                                  </span>
                                </div>

                                <center>
                                  <h6>
                                    Group: {this.state.AllMember.length}{" "}
                                    Participants
                                  </h6>
                                  <div>
                                    <input
                                      type="text"
                                      className="Searchbox"
                                      placeholder="Search"
                                      onChange={this.myFunction}
                                    />
                                    {/* <div className="form-icon">
                              <i className="icofont icofont-search"></i>
                            </div> */}
                                  </div>
                                  <br></br>
                                </center>
                                <div className="text-center">
                                  <center>
                                    <div
                                      style={{
                                        height: "300px",
                                        overflow: "scroll",
                                      }}
                                    >
                                      {AllMembers.map((member) => {
                                        return (
                                          <>
                                            <ul
                                              style={{
                                                listStyle: "none",
                                                padding: "0",
                                                width: "250px",
                                              }}
                                            >
                                              <li
                                                style={{
                                                  padding: " 10px 10px",
                                                  border: "1px solid #DDDDDD",
                                                }}
                                              >
                                                {member.name}
                                              </li>
                                              {/* <li style={{padding:" 10px 10px",border:"1px solid #DDDDDD"}}>{member.name}</li> */}
                                            </ul>
                                          </>
                                        );
                                      })}
                                    </div>
                                  </center>
                                </div>
                              </div>
                            </div>

                            <div style={editpopup}>
                              <i
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                                style={{
                                  color: "gray",
                                  float: "left",
                                  fontSize: "20px",
                                  marginLeft: "20px",
                                  marginTop: "20px",
                                }}
                                onClick={this.hideEditpop}
                              ></i>
                              <div style={{ marginTop: "48px" }}>
                                <span>
                                  <center>
                                    <a href="javascript:void(0);" title="Me">
                                      <div>
                                        {" "}
                                        <i
                                          className="fa fa-pencil"
                                          aria-hidden="true"
                                          onClick={this.profileEditpopup}
                                          style={{
                                            fontSize: "25px",
                                            color: "#fff",
                                            marginTop: "2px",
                                            position: "absolute",
                                            marginLeft: "19px",
                                            backgroundColor: "#4020bfde",
                                            borderRadius: "43px",
                                            width: "28px",
                                            height: "28px",
                                          }}
                                        ></i>{" "}
                                      </div>
                                      <img
                                        style={{
                                          width: "85px",
                                          height: "85px",
                                        }}
                                        // src="assets/images/avatar-1.png"
                                        src={ImgPath}
                                        className="img-circle img-chat-profile"
                                        alt="Me"
                                        onClick={this.profileEditpopup}
                                      />
                                    </a>
                                    <br></br> <br></br>
                                    <h4
                                      style={{
                                        fontFamily: "Copperplate",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {GroupTitle} &nbsp;
                                      <i
                                        className="fa fa-edit"
                                        style={{ fontSize: "15px" }}
                                        onClick={this.grpnameedit}
                                      ></i>
                                    </h4>
                                  </center>
                                </span>
                              </div>

                              <center>
                                <h6>
                                  Group: {this.state.AllMember.length}{" "}
                                  Participants &nbsp;
                                  {/* <i className="fa fa-user-plus" aria-hidden="true"></i> */}
                                </h6>
                                <div
                                // className="right-icon-control"  style={{ width: '250px' }}
                                >
                                  <input
                                    type="text"
                                    // className="form-control  search-text"
                                    className="Searchbox"
                                    placeholder="Search"
                                    onChange={this.myFunction}
                                  />
                                  {/* <div className="form-icon">
                            <i className="icofont icofont-search"></i>
                          </div> */}
                                </div>
                                <br></br>
                              </center>
                              <div className="text-center">
                                <center>
                                  <div
                                    style={{
                                      height: "300px",
                                      overflow: "scroll",
                                    }}
                                  >
                                    {AllMembers.map((member) => {
                                      return (
                                        <>
                                          <ul
                                            style={{
                                              listStyle: "none",
                                              padding: "0",
                                              width: "250px",
                                            }}
                                          >
                                            <li
                                              style={{
                                                padding: " 10px 10px",
                                                border: "1px solid #DDDDDD",
                                                height: "39px",
                                              }}
                                            >
                                              <span style={{ float: "left" }}>
                                                {member.name}
                                                <br />
                                                {member.email}
                                              </span>
                                              <span style={{ float: "right" }}>
                                                {" "}
                                                <i
                                                  className="fa fa-times"
                                                  aria-hidden="true"
                                                  style={{
                                                    color: "gray",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.Deletepop(
                                                      member.user_id,
                                                      member.name
                                                    )
                                                  }
                                                ></i>
                                              </span>
                                            </li>
                                          </ul>

                                          {/* DELETE USER */}

                                          {/* <div style={deletepop}>

                                  <center style={{ marginTop: "50px" }}>
                                    <input defaultValue={this.props.values.groupId} type="hidden" id="groupid"></input>

                                    <input defaultValue={Deleteuserid} type="hidden" id="duserid"></input>
                                    <input defaultValue={Deleteusername} type="hidden" id="dusername"></input>
                                    
                                    <h6>Are You Sure Want to <span style={{ color: "red" }}>Delete?</span></h6>
                                    <br></br>
                                    <span className="btn" onClick={this.DeleteUserGroup}>Confirm</span> &nbsp;&nbsp;&nbsp;&nbsp; <span className="btn" onClick={this.hideDeletepop}>Cancel</span>
                                  </center>

                                  <br></br>

                                </div> */}
                                        </>
                                      );
                                    })}
                                  </div>
                                </center>
                              </div>
                            </div>
                            {Minimized ? null : (
                              <>
                                {createdby == UserId ? (
                                  <div>
                                    <div
                                      className="grpnameeditpopA"
                                      style={grpnameeditpop}
                                    >
                                      {/* <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                        style={{
                                          color: "gray",
                                          float: "right",
                                          fontSize: "15px",
                                          marginRight: "20px",
                                          marginTop: "10px",
                                        }}
                                        onClick={this.hidegrpnameEditpop}
                                      ></i> */}
                                      <center>
                                        <h6
                                          style={{
                                            marginTop: "14px",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Edit Name
                                        </h6>
                                        <br></br>
                                        <form id="grpnameform">
                                          <input
                                            defaultValue={GroupTitle}
                                            placeholder="Discussion Name"
                                            style={{ padding: "9px", width: "66%", marginTop: "-1%", lineHeight: "0", marginBottom: "5%" }}
                                            id="Groupname"
                                            maxLength={30}
                                            className="form"
                                          ></input>

                                          <input
                                            defaultValue={
                                              this.props.values.groupId
                                            }
                                            placeholder="Group Name"
                                            type="hidden"
                                            id="GrpId"
                                          ></input>
                                        </form>
                                        {/* {groupnameErr &&(
   <div style={{color:"red"}}>
    <span className="">Group Name is required</span>
    </div>
  )} */}

                                        <br></br>

                                        <span
                                           id="GroupopYesbtn"
                                          onClick={this.UpdateGroupName}
                                        >
                                          Update
                                        </span>
                                        &nbsp;&nbsp;&nbsp;
                                        <span
                                        id='GroupopNobtn'
                                          onClick={this.hidegrpnameEditpop}
                                        >
                                          Close
                                        </span>
                                      </center>
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            )}

                            {/* END Discussion */}
                            <div className="endDisucpopA" style={endDisucpop}>
                              <center style={{ marginTop: "50px" }}>
                                <input
                                  defaultValue={this.props.values.groupId}
                                  type="hidden"
                                  id="groupId"
                                ></input>
                                <h5>
                                  Are you sure wants to End this Discussion?
                                </h5>
                                <br></br>
                                <span
                                  id="GroupopYesbtn"
                                  onClick={() =>
                                    this.endDiscussion(
                                      this.props.values.groupId
                                    )
                                  }
                                >
                                  Yes
                                </span>{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                <span
                                  id='GroupopNobtn'
                                  onClick={this.hideenddiscpop}
                                >
                                  No
                                </span>
                              </center>

                              <br></br>
                            </div>

                            {/* Leave Discussion */}
                            <div className="endDisucpopA" style={LeaveDisucpop}>
                              <center style={{ marginTop: "50px" }}>
                                <input
                                  defaultValue={this.props.values.groupId}
                                  type="hidden"
                                  id="groupId"
                                ></input>
                                <h5>
                                  Are you sure wants to Leave?
                                </h5>
                                <br></br><br></br>
                                <span
                                  id="GroupopYesbtn"
                                  onClick={() =>
                                    this.LeaveDiscussion(
                                      this.props.values.groupId
                                    )
                                  }
                                >
                                  Yes
                                </span>{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                <span
                                  id='GroupopNobtn'
                                  onClick={this.hideenddiscpop}
                                >
                                  No
                                </span>
                              </center>

                              <br></br>
                            </div>

                            {/* Mute Discussion */}
                            <div style={MuteDisucpop} className="endDisucpopA">
                              <center style={{ marginTop: "50px" }}>
                                <input
                                  defaultValue={this.props.values.groupId}
                                  type="hidden"
                                  id="groupId"
                                ></input>
                                <h5>
                                  Are you sure wants to Mute this Discussion?
                                </h5>
                                <br></br>
                                <span
                                  // className="btn"
                                  id="GroupopYesbtn"
                                  onClick={() =>
                                    this.MuteDiscussion(
                                      this.props.values.groupId
                                    )
                                  }
                                >
                                  Yes
                                </span>{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                <span
                                  id='GroupopNobtn'
                                  onClick={this.hideMuteDisc}
                                >
                                  No
                                </span>
                              </center>

                              <br></br>
                            </div>

                            {/* UnMute Discussion */}
                            <div style={UnMuteDisucpop} className="UnMutePopA">
                              <center style={{ marginTop: "50px" }}>
                                <input
                                  defaultValue={this.props.values.groupId}
                                  type="hidden"
                                  id="groupId"
                                ></input>
                                <h5>
                                  Are you sure wants to Unmute this Discussion?
                                </h5>
                                <br></br>
                                <span
                                  id="GroupopYesbtn"
                                  onClick={() =>
                                    this.UnMuteDiscussion(
                                      this.props.values.groupId
                                    )
                                  }
                                >
                                  Yes
                                </span>{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                <span
                                  id='GroupopNobtn'
                                  onClick={this.hideUnMuteDisc}
                                >
                                  No
                                </span>
                              </center>

                              <br></br>
                            </div>

                            {/* DeleteMessage Pop */}
                            {isVisible ? (
                              <div className="endDisucpopB" style={deleteStyles} ref={this.containerRef}>
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                  style={{
                                    color: "gray",
                                    marginLeft: "90%",
                                    fontSize: "17px",
                                  }}
                                  onClick={this.handleClose}
                                ></i>
                                <center>
                                  <span>
                                    <img
                                      src="https://static.thenounproject.com/png/659539-200.png"
                                      style={{ height: '25px', width: '25px' }}></img>&nbsp;
                                    <span >Delete Message?</span>
                                    <br />
                                  </span>

                                  <div style={{ display: 'inline-flex', gap: '15px', padding: '20px' }} id="spandiV">

                                    <span
                                      id="Deletelistbtns"
                                      onClick={this.DeleteMessageChat}
                                    // style={{ float: "right" }}
                                    >
                                      Delete for me
                                    </span>


                                    {this.state.deletesender == UserId ? (
                                      <>
                                        <span
                                          id="Deletelistbtns"
                                          onClick={this.DeleteMessageEveryone}
                                        // style={{ float: "right" }}
                                        >
                                          Delete for Everyone
                                        </span>
                                        <br />
                                      </>
                                    ) : null}

                                  </div>
                                </center>
                              </div>

                            ) : null}

                            {/* {Seen ? (
                              <div style={seenStyles} ref={this.containerRef}> */}
                            {/* <input type="hidden" id="id"></input>

<input type="hidden" id="name"></input> */}
                            {/* {SeenMsgMembers.map((Members) => (
                                  <span style={{ float: "" }}>
                                    <>
                                      <ul
                                        style={{
                                          listStyle: "none",
                                          padding: "0",
                                          width: "95%",
                                          textAlign: "left",
                                          display: "block",
                                          // listStyleType:'circle'
                                        }}
                                      >
                                        {EMail ==
                                        Members.group_Member ? null : (
                                          <li
                                            style={{
                                              padding: " 10px 10px",
                                              borderBottom: "1px solid #DDDDDD",
                                              display: "block",
                                            }}
                                          >
                                            <i
                                              className="fa-solid fa-circle"
                                              style={{
                                                fontSize: "7px",
                                                color: "gray",
                                              }}
                                            ></i>
                                            &nbsp;&nbsp;&nbsp;
                                            <b>
                                              {Members.name}{" "}
                                              <span
                                                className="Fontsize_In_chat"
                                                style={{
                                                  color: "Gray",
                                                  whiteSpace: "nowrap",
                                                }}
                                              ></span>
                                            </b>
                                          </li>
                                        )}
                                      </ul>
                                    </>
                                  </span>
                                ))}
                                <br />
                              </div>
                            ) : null} */}

                            {/* Edit Profile  */}
                            {Minimized ? null : (
                              <>
                                {createdby == UserId ? (
                                  <div
                                    style={profileeditpop}
                                    id="ProfileEditPop"
                                    ref={this.containerRef}
                                  >
                                    <form id="EditProfile">
                                      <br></br>
                                      <center style={{ marginTop: "-6%" }}>
                                        <div>
                                          <a
                                            href="javascript:void(0);"
                                            title="Delete Profile"
                                          >
                                            <i
                                              className="fa fa-ban"
                                              aria-hidden="true"
                                              onClick={this.deleteProfile}
                                              style={{
                                                fontSize: "25px",
                                                color: "#fff",
                                                marginTop: "28px",
                                                position: "absolute",
                                                marginLeft: "30px",
                                                color: "red",
                                                borderRadius: "43px",
                                                width: "30px",
                                                height: "30px",
                                              }}
                                            ></i>{" "}
                                          </a>
                                        </div>
                                        <br />
                                        <br></br>
                                        <a
                                          href="javascript:void(0);"
                                          title="Update Profile"
                                        >
                                          <input
                                            accept="image/*"
                                            type="file"
                                            hidden
                                            ref={this.inputReference}
                                            onChange={
                                              this.fileUploadInputChange
                                            }
                                          />
                                          {!EImage ? (
                                            <img
                                              src={ImgPath.toString() === `${Image_URLs}Images/profile.png` ? Profile : ImgPath}
                                              className="img-circle img-chat-profile"
                                              alt="Me"
                                              id="OldImageChat"
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                                marginTop: "-23px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={SampleImage}
                                              className="img-circle img-chat-profile"
                                              id="OldImageChat"
                                              alt="Me"
                                              style={{
                                                width: "100px",
                                                height: "100px",
                                                marginTop: "-23px",
                                                // marginLeft: "25%",
                                              }}
                                            />
                                          )}
                                        </a>
                                        <br></br>
                                        <br></br>
                                        <div
                                          className="profileGTbtn"
                                          onClick={this.UpdateGroupProfile}
                                          style={{
                                            width: "80px",
                                            color: "Gray",
                                            backgroundColor: "lightGray",
                                            position: "fixed",
                                            marginLeft: "-38px",
                                          }}
                                        >
                                          save &nbsp;&nbsp;
                                          <i
                                            className="fa-solid fa-upload"
                                            style={{ color: "Gray" }}
                                          ></i>
                                        </div>
                                      </center>
                                    </form>
                                  </div>
                                ) : null}
                              </>
                            )}

                            {/* Forwardpop */}

                            {this.state.forwardpopup && (
                              <>
                                {Minimized ? null : (
                                  <div className="popup">
                                    <div className="popup-content">
                                      <center id="DiscForwardPop">
                                        <div>
                                          <center>
                                            <h6>
                                              <b>Forward To</b>
                                            </h6>
                                          </center>

                                          <center>
                                            <input
                                              className="DiscSearchbox"
                                              placeholder="Search"
                                              onChange={this.forwardfilter}
                                            />
                                            <form id="ForwardMemberPopA">
                                              <center>
                                                <br />
                                                <span
                                                  id="ForwardFilterErr"
                                                  style={{ color: "red" }}
                                                ></span>
                                              </center>
                                              {ForwardMembers.map(
                                                (Announcement) => (
                                                  <div
                                                    className="media userlist-box"
                                                    data-id="1"
                                                    data-status="online"
                                                    data-username="Josephin Doe"
                                                    data-toggle="tooltip"
                                                    data-placement="left"
                                                    style={{
                                                      // padding: "5px",
                                                      height: "55px",
                                                    }}
                                                  >
                                                    <div
                                                      className="checkbox-wrapper-12"
                                                      id="forwardCheckbox"
                                                    // style={{
                                                    //   marginTop: "4%",
                                                    // }}
                                                    >
                                                      <div className="cbx">
                                                        <input
                                                          type="checkbox"
                                                          title="Add"
                                                          className="check"
                                                          id="Checkboxchat"
                                                          value={
                                                            Announcement.group_Name
                                                          }
                                                          checked={selectedGroupIds.includes(
                                                            Announcement.group_Name
                                                          )}
                                                          onChange={
                                                            this
                                                              .handleGroupSelectionChange
                                                          }
                                                        />
                                                        <label for="cbx-12"></label>
                                                        <svg
                                                          width="15"
                                                          height="14"
                                                          viewbox="0 0 15 14"
                                                          fill="none"
                                                        >
                                                          <path d="M2 8.36364L6.23077 12L13 2"></path>
                                                        </svg>
                                                      </div>

                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        version="1.1"
                                                      >
                                                        <defs>
                                                          <filter id="goo-12">
                                                            <fegaussianblur
                                                              in="SourceGraphic"
                                                              stddeviation="4"
                                                              result="blur"
                                                            ></fegaussianblur>
                                                            <fecolormatrix
                                                              in="blur"
                                                              mode="matrix"
                                                              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                                                              result="goo-12"
                                                            ></fecolormatrix>
                                                            <feblend
                                                              in="SourceGraphic"
                                                              in2="goo-12"
                                                            ></feblend>
                                                          </filter>
                                                        </defs>
                                                      </svg>
                                                    </div>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <a
                                                      className="media-left"
                                                      style={{
                                                        marginTop: "-2%",
                                                      }}
                                                    >
                                                      <img
                                                        className="media-object img-circle"
                                                        src={
                                                          Announcement.profile_Pic === `${Image_URLs}Images/profile.png` ? Profile : Announcement.profile_Pic
                                                        }
                                                        alt="img"
                                                        style={{
                                                          marginTop: "20px",
                                                          width: "35px",
                                                          height: "35px",
                                                        }}
                                                      />
                                                    </a>
                                                    <div
                                                      className="media-body"
                                                      style={{
                                                        textAlign: "initial",
                                                      }}
                                                    >
                                                      <div
                                                        className="f-13 chat-header"
                                                        style={{
                                                          color: "black",
                                                        }}
                                                      >
                                                        {" "}
                                                        <li
                                                          key={Announcement.id}
                                                          value={
                                                            Announcement.id
                                                          }
                                                          style={{
                                                            listStyleType:
                                                              "none",
                                                          }}
                                                        >
                                                          <label
                                                          // style={{
                                                          //   padding: "2px",
                                                          //   marginTop:'4%'
                                                          // }}
                                                          >
                                                            <span
                                                              className="Fontsize_In_chat"
                                                              style={{
                                                                color: "black",
                                                                whiteSpace:
                                                                  "nowrap",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            ></span>
                                                            <br />
                                                            <span
                                                              className="Fontsize_In_chat"
                                                              style={{
                                                                color: "black",
                                                                whiteSpace:
                                                                  "nowrap",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              {
                                                                Announcement.group_Name
                                                              }
                                                              &nbsp;
                                                              <span
                                                                className="Fontsize_In_chat"
                                                                style={{
                                                                  color: "Gray",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                }}
                                                              >
                                                                ({" "}
                                                                {Announcement.privacy ===
                                                                  0 ? (
                                                                  <span>
                                                                    Private
                                                                  </span>
                                                                ) : (
                                                                  <span>
                                                                    Public
                                                                  </span>
                                                                )}
                                                                )
                                                              </span>
                                                            </span>
                                                            <br />
                                                          </label>
                                                        </li>
                                                      </div>
                                                    </div>
                                                    <input
                                                      hidden
                                                      type="text"
                                                      name="User_Id"
                                                    />
                                                  </div>
                                                )
                                              )}
                                            </form>

                                            {/* <span
                                              className="btn"
                                              id="addbutton"
                                              disabled="disabled"
                                              onClick={this.ForwardChatMsg}
                                            >
                                              Forward
                                            </span>{" "}
                                            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                            <span
                                              className="btn"
                                              id="Cancelbutton"
                                              onClick={this.hideForwardpop}
                                            >
                                              Cancel
                                            </span> */}
                                            <div style={{ marginTop: '4%' }}>
                                              <span
                                                className="AddmemberpopFrdbtn"
                                                id="addbutton"
                                                onClick={this.ForwardChatMsg}
                                                disabled="disabled"
                                              >
                                                Forward
                                              </span>{" "}
                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                              <span
                                                className="Addmemberpopbtns"
                                                id="ACancelbutton"
                                                onClick={this.hideForwardpop}
                                              >
                                                Cancel

                                              </span></div>
                                          </center>
                                        </div>
                                      </center>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}

                            {/* Hide Member pop */}
                            <div
                              style={hiddenpop}
                              id="HideMemberPop-Chat"
                              className="mchat"
                            >
                              <center style={{ marginLeft: "3%" }}>
                                <br></br>
                                {EndStatus != 0 ? (
                                  <button
                                    className="Endbutton-42"
                                    onClick={this.enddisc}
                                  >
                                    <span style={{ fontSize: "10px" }}>
                                      End
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      {" "}
                                      Discussion
                                    </span>
                                  </button>
                                ) : null}
                                &nbsp;&nbsp;
                                {Privacy == 0 &&
                                  createdby == UserId &&
                                  this.state.reloadbutton === true ? (
                                  <button
                                    className="Privatebutton-42"
                                    onClick={this.GroupPrivate}
                                  >
                                    <span style={{ fontSize: "10px" }}>
                                      Private
                                    </span>
                                  </button>
                                ) : Privacy == 0 && createdby != UserId ? (
                                  <button
                                    className="Privatebutton-42"
                                    onClick={this.private}
                                  >
                                    <span style={{ fontSize: "10px" }}>
                                      Private
                                    </span>
                                  </button>
                                ) : (
                                  <button
                                    className="Privatebutton-42"
                                    onClick={this.private}
                                  >
                                    <span style={{ fontSize: "10px" }}>
                                      Public
                                    </span>
                                  </button>
                                )}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {createdby == UserId ? (
                                  <i
                                    className="fa fa-plus"
                                    id="GroupAddIcon"
                                    onClick={this.openPopup}
                                    style={{
                                      color: "#594eaa",
                                      fontSize: "20px",
                                      marginTop: "8px",
                                      position: "absolute",
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                ) : null}
                                <br></br>
                                <br></br>
                              </center>
                              <br></br>
                              <div>
                                <div
                                  style={{
                                    height: "300px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                    textAlign: "left",
                                  }}
                                  id="test"
                                >
                                  {AllMembers.map((member) => {
                                    return (
                                      <>
                                        <center>
                                          <ul
                                            style={{
                                              listStyle: "none",
                                              padding: "0",
                                              width: "95%",
                                              textAlign: "left",
                                              marginLeft: "3%",
                                            }}
                                          >
                                            <li
                                              style={{
                                                padding: " 10px 10px",
                                                border: "1px solid #DDDDDD",
                                              }}
                                            >
                                              {EMail === member.user_id ? (
                                                <b>You</b>
                                              ) : (
                                                <b>{member.name}</b>
                                              )}
                                              {/* <b>{member.name}</b> */}
                                              {/* <br></br> */}
                                              <span>{member.user_id}</span>
                                              {createdby == UserId ? (
                                                <span
                                                  style={{
                                                    float: "right",
                                                    marginTop: "-25px",
                                                  }}
                                                >
                                                  {" "}
                                                  <i
                                                    className="fa fa-times"
                                                    aria-hidden="true"
                                                    style={{
                                                      color: "gray",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      this.Deletepop(
                                                        member.user_id,
                                                        member.name
                                                      )
                                                    }
                                                  ></i>
                                                </span>
                                              ) : null}
                                            </li>
                                          </ul>
                                        </center>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            {this.state.isPopupOpen && (
                              <div className="popup">
                                <div className="popup-content">
                                  <center id="DiscAddPop">
                                    <div>
                                      <center>
                                        <h6
                                          className="placeholder"
                                          style={{ fontWeight: "bold", marginTop: "-2%" }}
                                        >
                                          Add Member
                                        </h6>
                                        <center>
                                          <input
                                            className="DiscSearchbox"
                                            placeholder="Search"
                                            onChange={this.Addmemberfilter}
                                          />
                                          <center>
                                            <form id="memberFormA" hidden>
                                              <ul
                                                style={{
                                                  width: "100%",
                                                  paddingLeft: "0px",
                                                  marginTop: "5%",
                                                }}
                                              >
                                                {selectedUsers.map(
                                                  (Addmembers) => (
                                                    <li
                                                      className="Fontsize_In_chat"
                                                      key={Addmembers.id}
                                                      style={{
                                                        color: "black",
                                                        display: "inline-block",
                                                        overflowX: "scroll",
                                                        marginLeft: "10px",
                                                      }}
                                                    >
                                                      <CloseIcon
                                                        onClick={() => {
                                                          this.handleUserRemove(
                                                            Addmembers.email,
                                                            Addmembers.name
                                                          );
                                                        }}
                                                        style={{
                                                          fontSize: "14px",

                                                          color: "red",

                                                          position: "absolute",

                                                          marginTop: "-3px",

                                                          backgroundColor:
                                                            "#d6d6d6",

                                                          borderRadius: "50%",

                                                          marginLeft: "26px",
                                                        }}
                                                      />
                                                      <img
                                                        className="media-object img-circle"
                                                        // src="assets/images/avatar-1.png"
                                                        src={
                                                          Addmembers.profile_Picture
                                                        }
                                                        alt="img"
                                                        style={{
                                                          height: "32px",
                                                          width: "32px",
                                                        }}
                                                      />
                                                      <br />
                                                      {Addmembers.name}{" "}
                                                      <input
                                                        hidden
                                                        type="text"
                                                        className="grupuser"
                                                        value={Addmembers.name}
                                                        name="FirstName"
                                                      />
                                                      <input
                                                        hidden
                                                        type="text"
                                                        className="grupuser"
                                                        value={Addmembers.email}
                                                        name="Email"
                                                      />
                                                      <input
                                                        hidden
                                                        type="text"
                                                        name="User_Id"
                                                      />
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </form>
                                          </center>
                                          <form id="AddMemberPopA">
                                            <center>
                                              <br />
                                              <span
                                                id="AddmemberFilterErr"
                                                style={{ color: "red" }}
                                              ></span>
                                            </center>
                                            {MemberAccess.map(
                                              (Announcement) => (
                                                <>
                                                  {Accessed === 1 &&
                                                    Accessed_to === 1 ? (
                                                    <div
                                                      className="media userlist-box"
                                                      data-id="1"
                                                      data-status="online"
                                                      data-username="Josephin Doe"
                                                      data-toggle="tooltip"
                                                      data-placement="left"
                                                      style={{
                                                        // padding: "5px",
                                                        height: "55px",
                                                      }}
                                                    >
                                                      <div
                                                        className="checkbox-wrapper-12"
                                                        style={{
                                                          marginTop: "4%",
                                                        }}
                                                      >
                                                        <div className="cbx">
                                                          <input
                                                            type="checkbox"
                                                            title="Add"
                                                            className="check"
                                                            id="Checkboxchat"
                                                            value={
                                                              Announcement.email
                                                            }
                                                            // checked={selectedUserIds.includes(
                                                            //   Announcement.email
                                                            // )}
                                                            onChange={
                                                              this
                                                                .handleUserSelectionChange
                                                            }
                                                          />
                                                          <label for="cbx-12"></label>
                                                          <svg
                                                            width="15"
                                                            height="14"
                                                            viewbox="0 0 15 14"
                                                            fill="none"
                                                          >
                                                            <path d="M2 8.36364L6.23077 12L13 2"></path>
                                                          </svg>
                                                        </div>

                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          version="1.1"
                                                        >
                                                          <defs>
                                                            <filter id="goo-12">
                                                              <fegaussianblur
                                                                in="SourceGraphic"
                                                                stddeviation="4"
                                                                result="blur"
                                                              ></fegaussianblur>
                                                              <fecolormatrix
                                                                in="blur"
                                                                mode="matrix"
                                                                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                                                                result="goo-12"
                                                              ></fecolormatrix>
                                                              <feblend
                                                                in="SourceGraphic"
                                                                in2="goo-12"
                                                              ></feblend>
                                                            </filter>
                                                          </defs>
                                                        </svg>
                                                      </div>
                                                      &nbsp;&nbsp;&nbsp;
                                                      <a
                                                        className="media-left"
                                                        style={{
                                                          marginTop: "-2%",
                                                        }}
                                                      >
                                                        <img
                                                          className="media-object img-circle"
                                                          src={
                                                            Announcement.profile_Picture
                                                          }
                                                          alt="img"
                                                          style={{
                                                            marginTop: "20px",
                                                            width: "35px",
                                                            height: "35px",
                                                          }}
                                                        />
                                                      </a>
                                                      <div
                                                        className="media-body"
                                                        style={{
                                                          textAlign: "initial",
                                                        }}
                                                      >
                                                        <div
                                                          className="f-13 chat-header"
                                                          style={{
                                                            color: "black",
                                                          }}
                                                        >
                                                          {" "}
                                                          <li
                                                            key={
                                                              Announcement.id
                                                            }
                                                            value={
                                                              Announcement.id
                                                            }
                                                            style={{
                                                              listStyleType:
                                                                "none",
                                                            }}
                                                          >
                                                            <label
                                                              style={
                                                                {
                                                                  marginTop: "-2%"
                                                                }
                                                              }
                                                            >
                                                              <span
                                                                className="Fontsize_In_chat"
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                              ></span>
                                                              <br />
                                                              <span
                                                                className="Fontsize_In_chat"
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                              >
                                                                {
                                                                  Announcement.firstName
                                                                }
                                                              </span>
                                                              &nbsp;
                                                              <span
                                                                className="Fontsize_In_chat"
                                                                style={{
                                                                  color: "Gray",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                }}
                                                              >
                                                                (
                                                                {
                                                                  Announcement.email
                                                                }
                                                                )
                                                              </span>
                                                            </label>
                                                          </li>
                                                        </div>
                                                      </div>
                                                      <input
                                                        hidden
                                                        type="text"
                                                        name="User_Id"
                                                      />
                                                    </div>
                                                  ) : null}
                                                </>
                                              )
                                            )}
                                            <>
                                              {NonAccess.map((Announcement) => (
                                                <>
                                                  {(Accessed === 1 &&
                                                    Accessed_to === 0) ||
                                                    (Accessed === 0 &&
                                                      Accessed_to === 0) ||
                                                    (Accessed === 0 &&
                                                      Accessed_to === 1) ? (
                                                    <div
                                                      className="media userlist-box"
                                                      data-id="1"
                                                      data-status="online"
                                                      data-username="Josephin Doe"
                                                      data-toggle="tooltip"
                                                      data-placement="left"
                                                      style={{
                                                        // padding: "5px",
                                                        height: "55px",
                                                      }}
                                                    >
                                                      <div
                                                        className="checkbox-wrapper-12"
                                                        style={{
                                                          marginTop: "4%",
                                                        }}
                                                      >
                                                        <div className="cbx">
                                                          <input
                                                            type="checkbox"
                                                            title="Add"
                                                            className="check"
                                                            id="Checkboxchat"
                                                            value={
                                                              Announcement.email
                                                            }
                                                            // checked={selectedUserIds.includes(
                                                            //   Announcement.email
                                                            // )}
                                                            onChange={
                                                              this
                                                                .handleUserSelectionChange
                                                            }
                                                          />
                                                          <label for="cbx-12"></label>
                                                          <svg
                                                            width="15"
                                                            height="14"
                                                            viewbox="0 0 15 14"
                                                            fill="none"
                                                          >
                                                            <path d="M2 8.36364L6.23077 12L13 2"></path>
                                                          </svg>
                                                        </div>

                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          version="1.1"
                                                        >
                                                          <defs>
                                                            <filter id="goo-12">
                                                              <fegaussianblur
                                                                in="SourceGraphic"
                                                                stddeviation="4"
                                                                result="blur"
                                                              ></fegaussianblur>
                                                              <fecolormatrix
                                                                in="blur"
                                                                mode="matrix"
                                                                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                                                                result="goo-12"
                                                              ></fecolormatrix>
                                                              <feblend
                                                                in="SourceGraphic"
                                                                in2="goo-12"
                                                              ></feblend>
                                                            </filter>
                                                          </defs>
                                                        </svg>
                                                      </div>
                                                      &nbsp;&nbsp;&nbsp;
                                                      <a
                                                        className="media-left"
                                                        style={{
                                                          marginTop: "-2%",
                                                        }}
                                                      >
                                                        <img
                                                          className="media-object img-circle"
                                                          src={
                                                            Announcement.profile_Picture
                                                          }
                                                          alt="img"
                                                          style={{
                                                            marginTop: "20px",
                                                            width: "35px",
                                                            height: "35px",
                                                          }}
                                                        />
                                                      </a>
                                                      <div
                                                        className="media-body"
                                                        style={{
                                                          textAlign: "initial",
                                                        }}
                                                      >
                                                        <div
                                                          className="f-13 chat-header"
                                                          style={{
                                                            color: "black",
                                                          }}
                                                        >
                                                          {" "}
                                                          <li
                                                            key={
                                                              Announcement.id
                                                            }
                                                            value={
                                                              Announcement.id
                                                            }
                                                            style={{
                                                              listStyleType:
                                                                "none",
                                                            }}
                                                          >
                                                            <label
                                                              style={
                                                                {
                                                                  marginTop: "-2%"
                                                                }
                                                              }
                                                            >
                                                              <span
                                                                className="Fontsize_In_chat"
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                              ></span>
                                                              <br />
                                                              <span
                                                                className="Fontsize_In_chat"
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                  fontWeight:
                                                                    "bold",
                                                                }}
                                                              >
                                                                {
                                                                  Announcement.firstName
                                                                }
                                                              </span>
                                                              &nbsp;
                                                              <span
                                                                className="Fontsize_In_chat"
                                                                style={{
                                                                  color: "Gray",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                }}
                                                              >
                                                                (
                                                                {
                                                                  Announcement.email
                                                                }
                                                                )
                                                              </span>
                                                            </label>
                                                          </li>
                                                        </div>
                                                      </div>
                                                      <input
                                                        hidden
                                                        type="text"
                                                        name="User_Id"
                                                      />
                                                    </div>
                                                  ) : null}
                                                </>
                                              ))}
                                            </>
                                          </form>
                                        </center>
                                        <div style={{ marginTop: '4%' }}>
                                          <span
                                            className="AddmemberpopAddbtn"
                                            id="addbutton"
                                            onClick={this.AddGroupMember}
                                            disabled="disabled"
                                          >
                                            Add
                                          </span>{" "}
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                          <span
                                            className="Addmemberpopbtns"
                                            id="ACancelbutton"
                                            onClick={this.closePopup}
                                          >
                                            Cancel

                                          </span></div>
                                      </center>
                                    </div>
                                  </center>
                                </div>
                              </div>
                            )}
                            {/* ADD USER */}

                            {/* <div style={addpop} id="MemberPop-Chat">

                              <center style={{ marginTop: "30px" }}>

                                {/* <label className="custom-field one" style={{marginTop:"-30px"}}>
  <input type="email" placeholder=" "  list="contacts"   id="AddmemberEmail" onKeyUp={this.onChange}/>
  <span className="placeholder">Add Member</span>
</label>
<br></br>
<span id="AddmemberError" style={{color:"red"}}></span>
<br></br>
<br></br>


        <datalist id="contacts">
        {Addmembers.map((member) =>{
                   
                   return (<> 
               
               <option value={member.email}>{member.name}</option>
                  
               

                   </>
                    );
      })}

</datalist> */}
                            {/* <h6 className="placeholder" style={{fontWeight:'bold'}}>Add Member</h6>
                               
                                <center style={{width:"90%"}}>
                                <span id="MemberlnErr" style={{ color: "red" }}></span>
                                <ReactSelect
                                  options={options}
                                  value={selectedOptions}
                                  onChange={this.handleSelect}
                                  isSearchable={true}
                                  isMulti
                                  isClearable
                                  formatOptionLabel={formatOptionLabel}
                                  maxMenuHeight={180} 
                                 maxMenuWidth={500}
                              
                                /></center> */}

                            {/* <Multiselect
                  options={this.state.Addmembers}
                  getSelectedItems={Addmembers}
                  isObject={false}
                  /> */}
                            {/* {selectedOptions} */}

                            {/* <div className="checkboxes">
                                  {this.state.letters.map(
                                    (letter, index) => (
                                      <button
                                        className="alphabet"
                                        key={index}
                                        onClick={(event) =>
                                          this.handleFilter(
                                            letter,
                                            event
                                          )
                                        }
                                      >
                                        {letter}
                                      </button>
                                    )
                                  )}
                                </div>


                                <span id="AddmemberError" style={{ color: "red" }}></span>
                                <br></br>
                              
                                <span className="btn" id="addbutton" onClick={this.AddGroupMember} disabled="disabled">Add</span> &nbsp;&nbsp;&nbsp;&nbsp; <span className="btn" onClick={this.hideAddpop}>Cancel</span>
                              </center>

                              <br></br>

                            </div>  */}

                            <div style={documentpop} id="DocPop">
                              <span style={{ textAlign: "center" }}>
                                {isdocumentpopOpen && (
                                  <DragDrop
                                    values={this.state.Group}
                                    handleClose={() => this.documentPopup()}
                                    AllMembers={this.state.mergedUsers}
                                  />
                                )}
                              </span>
                            </div>

                            <div style={picturepop} id="DocPop">
                              <span style={{ textAlign: "center" }}>
                                {ispicturepopOpen && (
                                  <Picturepop
                                    values={this.state.Group}
                                    handleClose={() => this.picturePopup()}
                                    AllMembers={this.state.mergedUsers}
                                  />
                                )}
                              </span>
                            </div>
                            {Minimized ? null : (
                              <div>
                                <div
                                  className="chat-body p-10"
                                  // style={{
                                  //   overflowY: "scroll",
                                  //   overflowX: "hidden",
                                  //   height: "468px",
                                  //   // width:"80%"
                                  // }}

                                  id="chat-hadcontainer"
                                >
                                  <div className="row">
                                    <div className="col-lg-8">
                                      <div className="chat-container-left">
                                        <div className="messages">
                                          <div
                                            className="containers"
                                            style={{ width: "100%" }}
                                          >
                                            {Allmessage.map(
                                              (message, index) => {
                                                let Sender = message.senderId;
                                                {
                                                  /* // console.log('rrrrrrrr', Sender) */
                                                }
                                                const isSender =
                                                  Sender.includes(UserId);
                                                {
                                                  /* // console.log(isSender,'ssssssssssss') */
                                                }
                                                let text =
                                                  message.document_Path;
                                                let result = text.slice(45, 85);
                                                let result1 = text.slice(45);
                                                const member =
                                                  this.state.Memberslist.includes(
                                                    Email
                                                  );
                                                const privacy =
                                                  this.state.Privacy;
                                                return (
                                                  <>
                                                    {(member &&
                                                      privacy === 0) ||
                                                      privacy === 1 ? (
                                                      <>
                                                        {message.createDateTime &&
                                                          message.rowNum === 1 ? (
                                                          <>
                                                          <div style={{margin:"15px"}}>

                                                            <span
                                                              style={{
                                                                color: "gray",
                                                                backgroundColor:
                                                                  "#ddd",
                                                                height: "35px",
                                                                padding:
                                                                  "5px 5px 5px 3px",
                                                                borderRadius:
                                                                  "7px",
                                                              }}
                                                            >
                                                              &nbsp;
                                                              {this.formatDateAA1(
                                                                message.createDateTime
                                                              )}
                                                            </span>
                                                            </div>
                                                          </>
                                                        ) : null}

                                                        {message.type ==
                                                          null ? (
                                                          <>
                                                            {message.message.includes('Left') ?
                                                              <center>
                                                                <div
                                                                  style={{
                                                                    // padding:
                                                                      //   "10px 10px",
                                                                    overflowWrap:
                                                                      "break-word",
                                                                    fontSize:
                                                                      "8px",
                                                                    // margin:
                                                                      //   "10px",
                                                                    width: "50%",
                                                                    margin:"15px"
                                                                  }}
                                                                >
                                                                  {isSender ? (
                                                                    <p
                                                                      style={{
                                                                        // display:
                                                                        //   "contents",
                                                                        textAlign:
                                                                          "center",
                                                                        verticalAlign:
                                                                          "middle",
                                                                          marginBottom: "0px",
                                                                      }}
                                                                    >
                                                                      You{" "}
                                                                      {
                                                                        message.message
                                                                      }{" "}
                                                                    </p>
                                                                  ) : (
                                                                    <>
                                                                      <p
                                                                        style={{
                                                                          // display:
                                                                          //   "contents",
                                                                          textAlign:
                                                                            "center",
                                                                          verticalAlign:
                                                                            "middle",
                                                                            marginBottom:"0px"
                                                                        }}
                                                                      >
                                                                        {
                                                                          message.displayName
                                                                        }{" "}
                                                                        {
                                                                          message.message
                                                                        }{" "}
                                                                      </p>
                                                                    </>
                                                                  )}
                                                                </div>
                                                              </center> :
<>
                                                              {message.message.includes('Removed')?
                                                              <center>
                                                                <div
                                                                  style={{
                                                                    // padding:
                                                                    //   "10px 10px",
                                                                    overflowWrap:
                                                                      "break-word",
                                                                    fontSize:
                                                                      "8px",
                                                                    // margin:
                                                                    //   "10px",
                                                                    width: "50%",
                                                                    margin:"10px"
                                                                  }}
                                                                >
                                                                  {isSender ? (
                                                                    <p
                                                                      style={{
                                                                        // display:
                                                                        //   "contents",
                                                                        textAlign:
                                                                          "center",
                                                                        verticalAlign:
                                                                          "middle",
                                                                          marginBottom:"0px"
                                                                      }}
                                                                    >
                                                                      You{" "}
                                                                      {
                                                                        message.message
                                                                      }{" "}
                                                                    </p>
                                                                  ) : (
                                                                    <>
                                                                      <p
                                                                        style={{
                                                                          // display:
                                                                          //   "contents",
                                                                          textAlign:
                                                                            "center",
                                                                          verticalAlign:
                                                                            "middle",
                                                                            marginBottom: "0px"
                                                                        }}
                                                                      >
                                                                        {
                                                                          message.displayName
                                                                        }{" "}
                                                                        {
                                                                          message.message.includes(name)?message.message.replace(name, 'You'):message.message
                                                                        }{" "}
                                                                      </p>
                                                                    </>
                                                                  )}
                                                                </div>
                                                                {/* <br></br> */}
                                                              </center>:
                                                              <center>
                                                                <div
                                                                  style={{
                                                                    padding:
                                                                      "10px 10px",
                                                                    overflowWrap:
                                                                      "break-word",
                                                                    backgroundColor:
                                                                      "#ddd",
                                                                    borderRadius:
                                                                      "7px",
                                                                    fontSize:
                                                                      "8px",
                                                                    margin:
                                                                      "15px",
                                                                    width: "50%",
                                                                  }}
                                                                >
                                                                  {isSender ? (
                                                                    <p
                                                                      style={{
                                                                        // display:
                                                                        //   "contents",
                                                                        textAlign:
                                                                          "center",
                                                                        verticalAlign:
                                                                          "middle",
                                                                          marginBottom: "0px"
                                                                        
                                                                      }}
                                                                    >
                                                                      You{" "}
                                                                      {
                                                                        message.message
                                                                      }{" "}
                                                                    </p>
                                                                  ) : (
                                                                    <>
                                                                      <p
                                                                        style={{
                                                                          // display:
                                                                          //   "contents",
                                                                          textAlign:
                                                                            "center",
                                                                          verticalAlign:
                                                                            "middle",
                                                                            marginBottom:"0px"
                                                                        }}
                                                                      >
                                                                        {
                                                                          message.displayName
                                                                        }{" "}
                                                                        {
                                                                          message.message.includes(name)?message.message.replace(name, 'You'):message.message
                                                                        }{" "}
                                                                      </p>
                                                                    </>
                                                                  )}
                                                                </div>
                                                                {/* <br></br> */}
                                                              </center>
                                                            } </>}

                                                          </>
                                                        ) : (
                                                          <div
                                                            className={`message-${isSender
                                                              ? "orange"
                                                              : "blue"
                                                              }`}
                                                            key={index}
                                                            style={{
                                                              height: "auto",
                                                              overflowWrap:
                                                                "break-word",
                                                              marginTop: "8px",
                                                            }}
                                                          >
                                                            {/* MessageOption */}
                                                            {MsgOption &&
                                                              this.state
                                                                .deleteId ==
                                                              message.id ? (
                                                              <div
                                                                style={
                                                                  MessageOption
                                                                }
                                                                ref={
                                                                  this
                                                                    .containerRef
                                                                }
                                                              >
                                                                {/* <input type="hidden" id="id"></input>
                                                    <input type="hidden" id="name"></input> */}
                                                                <center>
                                                                  {isSender ? (
                                                                    <>
                                                                      <span
                                                                        onClick={
                                                                          this
                                                                            .EditMessage
                                                                        }
                                                                        id="MsgOption"
                                                                      >
                                                                        <i
                                                                          className="fa fa-pencil-square-o"
                                                                          aria-hidden="true"
                                                                          style={{
                                                                            float:
                                                                              "left",
                                                                            marginLeft:
                                                                              "20px",
                                                                          }}
                                                                        ></i>
                                                                        <b
                                                                          style={{
                                                                            position:
                                                                              "absolute",
                                                                            marginLeft:
                                                                              "-23px",
                                                                          }}
                                                                        >
                                                                          Edit
                                                                        </b>
                                                                      </span>
                                                                      <br />{" "}
                                                                      <hr></hr>
                                                                      <span
                                                                        onClick={
                                                                          this
                                                                            .SeenMessages
                                                                        }
                                                                        id="MsgOption"
                                                                      >
                                                                        <i
                                                                          className="fa fa-eye"
                                                                          aria-hidden="true"
                                                                          style={{
                                                                            float:
                                                                              "left",
                                                                            marginLeft:
                                                                              "20px",
                                                                          }}
                                                                        ></i>
                                                                        <b
                                                                          style={{
                                                                            position:
                                                                              "absolute",
                                                                            marginLeft:
                                                                              "-25px",
                                                                          }}
                                                                        >
                                                                          Seen
                                                                        </b>
                                                                      </span>
                                                                      <br />{" "}
                                                                      <hr></hr>
                                                                    </>
                                                                  ) : null}
                                                                  {/* <span onClick={this.EditMessage} id="MsgOption">
                                                        <i className="fa fa-pencil-square-o" aria-hidden="true" style={{ float: "left", marginLeft: "20px" }}></i>
                                                        <b style={{ position: "absolute", marginLeft: "-33px" }}>Edit</b></span>
                                                      <br /> <hr></hr> */}
                                                                  <span
                                                                    onClick={
                                                                      this
                                                                        .deleteMenu
                                                                    }
                                                                    id="MsgOption"
                                                                  >
                                                                    <i
                                                                      className="fa fa-trash"
                                                                      aria-hidden="true"
                                                                      style={{
                                                                        float:
                                                                          "left",
                                                                        marginLeft:
                                                                          "20px",
                                                                      }}
                                                                    ></i>
                                                                    <b
                                                                      style={{
                                                                        position:
                                                                          "absolute",
                                                                        marginLeft:
                                                                          "-23px",
                                                                      }}
                                                                    >
                                                                      Delete
                                                                    </b>
                                                                  </span>
                                                                  <br />
                                                                  <hr></hr>
                                                                  <span
                                                                    onClick={
                                                                      this
                                                                        .forwardopenpop
                                                                    }
                                                                    id="MsgOption"
                                                                  >
                                                                    <i
                                                                      className="fa fa-share"
                                                                      aria-hidden="true"
                                                                      style={{
                                                                        float:
                                                                          "left",
                                                                        marginLeft:
                                                                          "20px",
                                                                      }}
                                                                    ></i>
                                                                    <b
                                                                      style={{
                                                                        position:
                                                                          "absolute",
                                                                        marginLeft:
                                                                          "-25px",
                                                                      }}
                                                                    >
                                                                      Forward
                                                                    </b>
                                                                  </span>
                                                                  <br />
                                                                </center>
                                                              </div>
                                                            ) : null}
                                                            {member && EndStatus == 1 && Status == 1 ? (
                                                              <i
                                                                className="fa fa-ellipsis-h"
                                                                style={{
                                                                  float:
                                                                    "Right",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                aria-hidden="true"
                                                                onClick={() =>
                                                                  this.MessageOption(
                                                                    message.id,
                                                                    message.group_id,
                                                                    message.senderId,
                                                                    message.createDateTime,
                                                                    message.message,
                                                                    message.picture_Path,
                                                                    message.document_Path,
                                                                    message.dateTimeSec
                                                                  )
                                                                }
                                                              ></i>
                                                            ) : null}
                                                            {!isSender ? (
                                                              <h6
                                                                style={{
                                                                  fontWeight:
                                                                    "600",
                                                                  // color:
                                                                  //   "rgb(215 205 252)",
                                                                }}
                                                              >
                                                                <a
                                                                  className="media-left"
                                                                  style={{
                                                                    marginTop:
                                                                      "-2%",
                                                                  }}
                                                                >
                                                                  <img
                                                                    className="media-object img-circle"
                                                                    // src={

                                                                    //   message.particularUserProfile === null
                                                                    //     ? (message.profile.toString() === `${Image_URLs}Images/profile.png` ? Profile : message.particularUserProfile)
                                                                    //     : message.particularUserProfile
                                                                    // }
                                                                    src={

                                                                      message.particularUserProfile
                                                                    }
                                                                    alt={message.particularUserProfile}
                                                                    style={{
                                                                      // marginTop: "20px",
                                                                      width:
                                                                        "35px",
                                                                      height:
                                                                        "35px",
                                                                    }}
                                                                  />&nbsp;{
                                                                    message.displayName
                                                                  }
                                                                </a>
                                                                <br />
                                                                {message.editStatus ===
                                                                  1 ? (
                                                                  <span
                                                                    style={{
                                                                      fontWeight:
                                                                        "200",
                                                                      color:
                                                                        "grey",
                                                                      fontSize:
                                                                        "10px",
                                                                    }}
                                                                  >
                                                                    Edited
                                                                  </span>
                                                                ) : null}
                                                              </h6>
                                                            ) : (
                                                              <>
                                                                {message.editStatus ===
                                                                  1 ? (
                                                                  <h6
                                                                    style={{
                                                                      fontWeight:
                                                                        "200",
                                                                      color:
                                                                        "grey",
                                                                      fontSize:
                                                                        "10px",
                                                                    }}
                                                                  >
                                                                    Edited
                                                                  </h6>
                                                                ) : null}
                                                              </>
                                                            )}
                                                            {message.forwarded ===
                                                              1 ? (
                                                              <h6
                                                                style={{
                                                                  fontWeight:
                                                                    "200",
                                                                  color: "grey",
                                                                  fontSize:
                                                                    "10px",
                                                                }}
                                                              >
                                                                <i
                                                                  className="fa fa-share"
                                                                  aria-hidden="true"
                                                                ></i>
                                                                &nbsp;&nbsp;Forwarded
                                                              </h6>
                                                            ) : null}
                                                            {message.picture_Path ===
                                                              "-" &&
                                                              message.document_Path ===
                                                              "-" ? (
                                                              <>
                                                                <p
                                                                  className="message-content"
                                                                  name="Popmsg"
                                                                // style={{ wordWrap: "break-word" }}
                                                                >
                                                                  {regexp.test(
                                                                    message.message
                                                                  ) == true ? (
                                                                    <>
                                                                      {filterurl.map(
                                                                        (
                                                                          item
                                                                        ) => (
                                                                          <p>
                                                                            {message.message ==
                                                                              item ? (
                                                                              <a
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                  textDecoration:
                                                                                    "underline",
                                                                                }}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                href={
                                                                                  item
                                                                                }
                                                                              >
                                                                                {
                                                                                  item
                                                                                }
                                                                              </a>
                                                                            ) : null}
                                                                          </p>
                                                                        )
                                                                      )}{" "}
                                                                    </>
                                                                  ) : (
                                                                    <div>
                                                                      {/* <i className="fa fa-ellipsis-h" style={{ float: "Right" }} aria-hidden="true"
                                                  onClick={() => this.deleteMenu(message.id, message.group_id, message.senderId, message.createDateTime,
                                                    message.message, message.picture_Path, message.document_Path)}></i> */}
                                                                      <p>
                                                                        {
                                                                          message.message
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                  )}
                                                                </p>{" "}
                                                              </>
                                                            ) : (
                                                              <>
                                                                {message.picture_Path !==
                                                                  "-" &&
                                                                  message.document_Path !==
                                                                  "-" ? (
                                                                  <div>
                                                                    <div>
                                                                      <p
                                                                        className="message-content"
                                                                        name="Popmsg"
                                                                      // style={{ wordWrap: "break-word" }}
                                                                      >
                                                                        {message.message !=
                                                                          "-" ? (
                                                                          <>
                                                                            {
                                                                              message.message
                                                                            }
                                                                          </>
                                                                        ) : null}
                                                                      </p>
                                                                      <br />
                                                                      <div>
                                                                        <img
                                                                          id="fileImg"
                                                                          color="#0275d8"
                                                                          width="80"
                                                                          height="80"
                                                                          src={
                                                                            message.picture_Path
                                                                          }
                                                                          style={{
                                                                            width:
                                                                              "inherit",
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      <div>
                                                                        <a
                                                                          href={
                                                                            message.document_Path
                                                                          }
                                                                          name="picture"
                                                                          readOnly
                                                                          value={
                                                                            message.document_Path
                                                                          }
                                                                          title=""
                                                                          target="_blank"
                                                                          style={{
                                                                            width:
                                                                              "120px",
                                                                            height:
                                                                              "200px",
                                                                          }}
                                                                        >
                                                                          <embed
                                                                            src={
                                                                              message.document_Path
                                                                            }
                                                                            id="Gstiframe"
                                                                            title=""
                                                                            scrolling="no"
                                                                            target="_blank"
                                                                            style={{
                                                                              width:
                                                                                "100px",
                                                                              height:
                                                                                "100px",
                                                                              pointerEvents:
                                                                                "none",
                                                                            }}
                                                                          ></embed>
                                                                          <p>
                                                                            {
                                                                              message.document_Path
                                                                            }
                                                                          </p>
                                                                        </a>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                ) : (
                                                                  <>
                                                                    {message.picture_Path ===
                                                                      "-" &&
                                                                      message.document_Path !==
                                                                      "-" ? (
                                                                      <div>
                                                                        <p
                                                                          className="message-content"
                                                                          name="Popmsg"
                                                                        // style={{
                                                                        //   wordWrap: "break-word",
                                                                        // }}
                                                                        >
                                                                          {message.message !=
                                                                            "-" ? (
                                                                            <>
                                                                              {
                                                                                message.message
                                                                              }
                                                                            </>
                                                                          ) : null}
                                                                        </p>
                                                                        {/* <i className="fa fa-ellipsis-h" style={{ float: "Right" }} aria-hidden="true"
                                                    onClick={() => this.deleteMenu(message.id, message.group_id, message.senderId, message.createDateTime,
                                                      message.message, message.picture_Path, message.document_Path)}></i> */}
                                                                        <br />
                                                                        <a
                                                                          href={
                                                                            message.document_Path
                                                                          }
                                                                          name="picture"
                                                                          readOnly
                                                                          value={
                                                                            message.document_Path
                                                                          }
                                                                          title=""
                                                                          target="_blank"
                                                                          style={{
                                                                            width:
                                                                              "120px",
                                                                            height:
                                                                              "200px",
                                                                          }}
                                                                        >
                                                                          {/* <embed
                                                    src={message.document_Path}
                                                    title=""
                                                    scrolling="no"
                                                    target="_blank"
                                                    style={{
                                                      width: "100px",
                                                      height: "100px",
                                                      pointerEvents: "none",
                                                    }}
                                                  ></embed> */}
                                                                          <div
                                                                            className="row"
                                                                            style={{
                                                                              width:
                                                                                "180px",
                                                                              height:
                                                                                "60px",
                                                                              borderRadius:
                                                                                "5px",
                                                                              boxShadow:
                                                                                "rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px",
                                                                              background:
                                                                                "#e8e8e8",
                                                                              alignItems:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            <div className="col-lg-3">
                                                                              <img
                                                                                src={
                                                                                  File1
                                                                                }
                                                                                style={{
                                                                                  height:
                                                                                    "30px",
                                                                                  width:
                                                                                    "30px",
                                                                                }}
                                                                              ></img>
                                                                            </div>
                                                                            <div className="col-lg-9">
                                                                              {message
                                                                                .document_Path
                                                                                .length >
                                                                                85 ? (
                                                                                <>
                                                                                  <div
                                                                                    style={{
                                                                                      opacity:
                                                                                        DName &&
                                                                                          documentname ==
                                                                                          message.id
                                                                                          ? 1
                                                                                          : 0,
                                                                                      width:
                                                                                        "150px",
                                                                                      height:
                                                                                        "auto",
                                                                                      overflowWrap:
                                                                                        "break-word",
                                                                                      zIndex:
                                                                                        "109",
                                                                                      border:
                                                                                        "1px solid black",
                                                                                      background:
                                                                                        "#0000007d",
                                                                                      color:
                                                                                        "white",
                                                                                      alignItems:
                                                                                        "center",
                                                                                      position:
                                                                                        "absolute",
                                                                                      top: "-50px",
                                                                                      left: "-50px",
                                                                                    }}
                                                                                  >
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize:
                                                                                          "10px",
                                                                                      }}
                                                                                    >
                                                                                      {
                                                                                        result1
                                                                                      }
                                                                                    </p>
                                                                                  </div>
                                                                                  <p
                                                                                    style={{
                                                                                      marginTop:
                                                                                        "10px",
                                                                                      textAlign:
                                                                                        "center",
                                                                                      fontSize:
                                                                                        "10px",
                                                                                      fontWeight:
                                                                                        "600",
                                                                                      color:
                                                                                        "#19578b",
                                                                                    }}
                                                                                    onMouseOver={(
                                                                                      e
                                                                                    ) => {
                                                                                      e.preventDefault();
                                                                                      this.documentName(
                                                                                        message.id
                                                                                      );
                                                                                    }}
                                                                                    onMouseLeave={
                                                                                      this
                                                                                        .documentNameL
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      result
                                                                                    }
                                                                                    ...
                                                                                  </p>
                                                                                </>
                                                                              ) : (
                                                                                <p
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "10px",
                                                                                    textAlign:
                                                                                      "center",
                                                                                    fontSize:
                                                                                      "10px",
                                                                                    fontWeight:
                                                                                      "600",
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    result
                                                                                  }
                                                                                </p>
                                                                              )}
                                                                            </div>
                                                                          </div>
                                                                          {/* <br></br>
                                                  <p>
                                                    {message.document_Path}
                                                  </p> */}
                                                                        </a>
                                                                      </div>
                                                                    ) : (
                                                                      <div>
                                                                        {/* <i className="fa fa-ellipsis-h" style={{ float: "Right" }} aria-hidden="true"
                                                    onClick={() => this.deleteMenu(message.id, message.group_id, message.senderId, message.createDateTime,
                                                      message.message, message.picture_Path, message.document_Path)}></i> */}
                                                                        <p
                                                                          className="message-content"
                                                                          name="Popmsg"
                                                                        // style={{
                                                                        //   wordWrap: "break-word",
                                                                        // }}
                                                                        >
                                                                          {message.message !=
                                                                            "-" ? (
                                                                            <>
                                                                              {
                                                                                message.message
                                                                              }
                                                                            </>
                                                                          ) : null}
                                                                        </p>
                                                                        <br />
                                                                        <a
                                                                          href={
                                                                            message.picture_Path
                                                                          }
                                                                          name="picture"
                                                                          readOnly
                                                                          value={
                                                                            message.picture_Path
                                                                          }
                                                                          title=""
                                                                          target="_blank"
                                                                          style={{
                                                                            width:
                                                                              "120px",
                                                                            height:
                                                                              "200px",
                                                                          }}
                                                                        >
                                                                          <div>
                                                                            {message.picture_Path.includes(
                                                                              ".mp4"
                                                                            ) ? (
                                                                              <>
                                                                                {" "}
                                                                                <img
                                                                                  alt="Video"
                                                                                  width="30"
                                                                                  height="30"
                                                                                  src={
                                                                                    video
                                                                                  }
                                                                                  title="Click to play video"
                                                                                  style={{
                                                                                    width:
                                                                                      "inherit",
                                                                                  }}
                                                                                />{" "}
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "#000",
                                                                                  }}
                                                                                >
                                                                                  Video
                                                                                  File
                                                                                </span>{" "}
                                                                              </>
                                                                            ) : (
                                                                              <img
                                                                                alt="chatimg"
                                                                                width="80"
                                                                                height="80"
                                                                                src={
                                                                                  message.picture_Path
                                                                                }
                                                                              // style={{
                                                                              //   width:
                                                                              //     "inherit",
                                                                              // }}
                                                                              />
                                                                            )}
                                                                          </div>
                                                                        </a>
                                                                      </div>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                            {isSender &&
                                                              message.displayStatus == 1 ? (
                                                              <img
                                                                src={doubletick}
                                                                style={{
                                                                  // marginTop: "50px",
                                                                  marginLeft: "-2px",
                                                                  width: "20px",
                                                                  float: "right",
                                                                }}
                                                                title="Seen"
                                                              />
                                                            ) : isSender ? <img
                                                              src={singletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft: "-2px",
                                                                width: "20px",
                                                                float: "right",
                                                              }}
                                                              title="Unread"
                                                            /> : null}
                                                            {/* {
                                                              isSender && message.displayStatus === 1 ? <p>double tick</p> : <p>Single tick</p>
                                                            } */}

                                                            <br /><br />
                                                            <div className="message-timestamp-right">
                                                              {/* <TimeAgo
                                      date={message.createDateTime}
                                      live={true}
                                      format={(value, unit, suffix) => {
                                        if (unit === "second") {
                                          // Custom formatting for seconds
                                          return `${value} seconds ago`;
                                        }
                                        // Default formatting for other units
                                        return `${value} ${unit}${suffix}`;
                                      }}
                                      locale="en-US"
                                      style={{ color: "gray" }}
                                    /> */}
                                                              <span
                                                                style={{
                                                                  color: "gray",
                                                                }}
                                                              >
                                                                <i className="fa fa-clock-o"></i>
                                                                &nbsp;
                                                                {this.formatDateAA(
                                                                  message.createDateTime
                                                                )}
                                                              </span>
                                                              {/* <ReactTimeAgo date={message.createDateTime} locale="en-US" timeStyle="round-minute" style={{ color: "gray" }} /> */}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </>
                                                    ) : !member &&
                                                      privacy === 1 &&
                                                      message.privacy === 1 ? (
                                                      <>
                                                        {message.createDateTime &&
                                                          message.rowNum === 1 ? (
                                                          <>
                                                            <span
                                                              style={{
                                                                color: "gray",
                                                                backgroundColor:
                                                                  "#8eb6d859",
                                                                height: "35px",
                                                                padding:
                                                                  "5px 5px 5px 3px",
                                                                borderRadius:
                                                                  "7px",
                                                              }}
                                                            >
                                                              &nbsp;
                                                              {this.formatDateAA1(
                                                                message.createDateTime
                                                              )}
                                                            </span>
                                                          </>
                                                        ) : null}
                                                        {message.type ==
                                                          null ? (
                                                          <>
                                                            <center>
                                                              <div
                                                                style={{
                                                                  width: "max",
                                                                  height:
                                                                    "35px",
                                                                  display:
                                                                    "table",
                                                                  padding:
                                                                    "10px 10px",
                                                                  overflowWrap:
                                                                    "break-word",
                                                                    backgroundColor:
                                                                      "#ddd",
                                                                  borderRadius:
                                                                    "7px",
                                                                  fontSize:
                                                                    "8px",
                                                                }}
                                                              >
                                                                {isSender ? (
                                                                  <p
                                                                    style={{
                                                                      display:
                                                                        "table-cell",
                                                                      textAlign:
                                                                        "center",
                                                                      verticalAlign:
                                                                        "middle",
                                                                    }}
                                                                  >
                                                                    You{" "}
                                                                    {
                                                                      message.message
                                                                    }{" "}
                                                                  </p>
                                                                ) : (
                                                                  <>
                                                                    <p
                                                                      style={{
                                                                        display:
                                                                          "table-cell",
                                                                        textAlign:
                                                                          "center",
                                                                        verticalAlign:
                                                                          "middle",
                                                                      }}
                                                                    >
                                                                      {
                                                                        message.displayName
                                                                      }{" "}
                                                                      {
                                                                        message.message
                                                                      }{" "}
                                                                    </p>
                                                                  </>
                                                                )}
                                                              </div>
                                                            </center>{" "}
                                                          </>
                                                        ) : (
                                                          <div
                                                            className={`message-${isSender
                                                              ? "orange"
                                                              : "blue"
                                                              }`}
                                                            key={index}
                                                            style={{
                                                              height: "auto",
                                                              overflowWrap:
                                                                "break-word",
                                                              marginTop: "8px",
                                                            }}
                                                          >
                                                            {/* MessageOption */}
                                                            {MsgOption &&
                                                              this.state
                                                                .deleteId ==
                                                              message.id ? (
                                                              <div
                                                                style={
                                                                  MessageOption
                                                                }
                                                                ref={
                                                                  this
                                                                    .containerRef
                                                                }
                                                              >
                                                                {/* <input type="hidden" id="id"></input>
                                                    <input type="hidden" id="name"></input> */}
                                                                <center>
                                                                  {isSender ? (
                                                                    <>
                                                                      <span
                                                                        onClick={
                                                                          this
                                                                            .EditMessage
                                                                        }
                                                                        id="MsgOption"
                                                                      >
                                                                        <i
                                                                          className="fa fa-pencil-square-o"
                                                                          aria-hidden="true"
                                                                          style={{
                                                                            float:
                                                                              "left",
                                                                            marginLeft:
                                                                              "20px",
                                                                          }}
                                                                        ></i>
                                                                        <b
                                                                          style={{
                                                                            position:
                                                                              "absolute",
                                                                            marginLeft:
                                                                              "-23px",
                                                                          }}
                                                                        >
                                                                          Edit
                                                                        </b>
                                                                      </span>
                                                                      <br />{" "}
                                                                      <hr></hr>
                                                                      <span
                                                                        onClick={
                                                                          this
                                                                            .SeenMessages
                                                                        }
                                                                        id="MsgOption"
                                                                      >
                                                                        <i
                                                                          className="fa fa-eye"
                                                                          aria-hidden="true"
                                                                          style={{
                                                                            float:
                                                                              "left",
                                                                            marginLeft:
                                                                              "20px",
                                                                          }}
                                                                        ></i>
                                                                        <b
                                                                          style={{
                                                                            position:
                                                                              "absolute",
                                                                            marginLeft:
                                                                              "-25px",
                                                                          }}
                                                                        >
                                                                          Seen
                                                                        </b>
                                                                      </span>
                                                                      <br />{" "}
                                                                      <hr></hr>
                                                                    </>
                                                                  ) : null}
                                                                  {/* <span onClick={this.EditMessage} id="MsgOption">
                                                        <i className="fa fa-pencil-square-o" aria-hidden="true" style={{ float: "left", marginLeft: "20px" }}></i>
                                                        <b style={{ position: "absolute", marginLeft: "-33px" }}>Edit</b></span>
                                                      <br /> <hr></hr> */}
                                                                  <span
                                                                    onClick={
                                                                      this
                                                                        .deleteMenu
                                                                    }
                                                                    id="MsgOption"
                                                                  >
                                                                    <i
                                                                      className="fa fa-trash"
                                                                      aria-hidden="true"
                                                                      style={{
                                                                        float:
                                                                          "left",
                                                                        marginLeft:
                                                                          "20px",
                                                                      }}
                                                                    ></i>
                                                                    <b
                                                                      style={{
                                                                        position:
                                                                          "absolute",
                                                                        marginLeft:
                                                                          "-23px",
                                                                      }}
                                                                    >
                                                                      Delete
                                                                    </b>
                                                                  </span>
                                                                  <br />
                                                                  <hr></hr>
                                                                  <span
                                                                    onClick={
                                                                      this
                                                                        .forwardopenpop
                                                                    }
                                                                    id="MsgOption"
                                                                  >
                                                                    <i
                                                                      className="fa fa-share"
                                                                      aria-hidden="true"
                                                                      style={{
                                                                        float:
                                                                          "left",
                                                                        marginLeft:
                                                                          "20px",
                                                                      }}
                                                                    ></i>
                                                                    <b
                                                                      style={{
                                                                        position:
                                                                          "absolute",
                                                                        marginLeft:
                                                                          "-25px",
                                                                      }}
                                                                    >
                                                                      Forward
                                                                    </b>
                                                                  </span>
                                                                  <br />
                                                                </center>
                                                              </div>
                                                            ) : null}
                                                            {member ? (
                                                              <i
                                                                className="fa fa-ellipsis-h"
                                                                style={{
                                                                  float:
                                                                    "Right",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                aria-hidden="true"
                                                                onClick={() =>
                                                                  this.MessageOption(
                                                                    message.id,
                                                                    message.group_id,
                                                                    message.senderId,
                                                                    message.createDateTime,
                                                                    message.message,
                                                                    message.picture_Path,
                                                                    message.document_Path,
                                                                    message.dateTimeSec
                                                                  )
                                                                }
                                                              ></i>
                                                            ) : null}
                                                            {!isSender ? (
                                                              <h6
                                                                style={{
                                                                  fontWeight:
                                                                    "600",
                                                                  color:
                                                                    "#09679f",
                                                                }}
                                                              >
                                                                <a
                                                                  className="media-left"
                                                                  style={{
                                                                    marginTop:
                                                                      "-2%",
                                                                  }}
                                                                >
                                                                  <img
                                                                    className="media-object img-circle"
                                                                    src={
                                                                      message.particularUserProfile
                                                                    }
                                                                    alt="img"
                                                                    style={{
                                                                      // marginTop: "20px",
                                                                      width:
                                                                        "35px",
                                                                      height:
                                                                        "35px",
                                                                    }}
                                                                  />&nbsp;{
                                                                    message.displayName
                                                                  }
                                                                </a>
                                                                <br />
                                                                {message.editStatus ===
                                                                  1 ? (
                                                                  <span
                                                                    style={{
                                                                      fontWeight:
                                                                        "200",
                                                                      color:
                                                                        "grey",
                                                                      fontSize:
                                                                        "10px",
                                                                    }}
                                                                  >
                                                                    Edited
                                                                  </span>
                                                                ) : null}
                                                              </h6>
                                                            ) : (
                                                              <>
                                                                {message.editStatus ===
                                                                  1 ? (
                                                                  <h6
                                                                    style={{
                                                                      fontWeight:
                                                                        "200",
                                                                      color:
                                                                        "grey",
                                                                      fontSize:
                                                                        "10px",
                                                                    }}
                                                                  >
                                                                    Edited
                                                                  </h6>
                                                                ) : null}
                                                              </>
                                                            )}
                                                            {message.forwarded ===
                                                              1 ? (
                                                              <h6
                                                                style={{
                                                                  fontWeight:
                                                                    "200",
                                                                  color: "grey",
                                                                  fontSize:
                                                                    "10px",
                                                                }}
                                                              >
                                                                <i
                                                                  className="fa fa-share"
                                                                  aria-hidden="true"
                                                                ></i>
                                                                &nbsp;&nbsp;Forwarded
                                                              </h6>
                                                            ) : null}
                                                            {message.picture_Path ===
                                                              "-" &&
                                                              message.document_Path ===
                                                              "-" ? (
                                                              <>
                                                                {/* <DocumentViewer
  url="https://drive.google.com/file/d/0B5ImRpiNhCfGZDVhMGEyYmUtZTdmMy00YWEyLWEyMTQtN2E2YzM3MDg3MTZh/preview"
  viewer="url"
  style={{width:"100%",height:"50vh"}}
>
</DocumentViewer> */}
                                                                <p
                                                                  className="message-content"
                                                                  name="Popmsg"
                                                                // style={{ wordWrap: "break-word" }}
                                                                >
                                                                  {regexp.test(
                                                                    message.message
                                                                  ) == true ? (
                                                                    <>
                                                                      {filterurl.map(
                                                                        (
                                                                          item
                                                                        ) => (
                                                                          <p>
                                                                            {message.message ==
                                                                              item ? (
                                                                              <a
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                  textDecoration:
                                                                                    "underline",
                                                                                }}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                href={
                                                                                  item
                                                                                }
                                                                              >
                                                                                {
                                                                                  item
                                                                                }
                                                                              </a>
                                                                            ) : null}
                                                                          </p>
                                                                        )
                                                                      )}{" "}
                                                                    </>
                                                                  ) : (
                                                                    <div>
                                                                      {/* <i className="fa fa-ellipsis-h" style={{ float: "Right" }} aria-hidden="true"
                                                  onClick={() => this.deleteMenu(message.id, message.group_id, message.senderId, message.createDateTime,
                                                    message.message, message.picture_Path, message.document_Path)}></i> */}
                                                                      <p>
                                                                        {
                                                                          message.message
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                  )}
                                                                </p>{" "}
                                                              </>
                                                            ) : (
                                                              <>
                                                                {message.picture_Path !==
                                                                  "-" &&
                                                                  message.document_Path !==
                                                                  "-" ? (
                                                                  <div>
                                                                    <div>
                                                                      <p
                                                                        className="message-content"
                                                                        name="Popmsg"
                                                                      // style={{ wordWrap: "break-word" }}
                                                                      >
                                                                        {message.message !=
                                                                          "-" ? (
                                                                          <>
                                                                            {
                                                                              message.message
                                                                            }
                                                                          </>
                                                                        ) : null}
                                                                      </p>
                                                                      <br />
                                                                      <div>
                                                                        <img
                                                                          id="fileImg"
                                                                          color="#0275d8"
                                                                          width="80"
                                                                          height="80"
                                                                          src={
                                                                            message.picture_Path
                                                                          }
                                                                        // style={{
                                                                        //   width:
                                                                        //     "inherit",
                                                                        // }}
                                                                        />
                                                                      </div>
                                                                      <div>
                                                                        <a
                                                                          href={
                                                                            message.document_Path
                                                                          }
                                                                          name="picture"
                                                                          readOnly
                                                                          value={
                                                                            message.document_Path
                                                                          }
                                                                          title=""
                                                                          target="_blank"
                                                                          style={{
                                                                            width:
                                                                              "120px",
                                                                            height:
                                                                              "200px",
                                                                          }}
                                                                        >
                                                                          <embed
                                                                            src={
                                                                              message.document_Path
                                                                            }
                                                                            id="Gstiframe"
                                                                            title=""
                                                                            scrolling="no"
                                                                            target="_blank"
                                                                            style={{
                                                                              width:
                                                                                "100px",
                                                                              height:
                                                                                "100px",
                                                                              pointerEvents:
                                                                                "none",
                                                                            }}
                                                                          ></embed>
                                                                          <p>
                                                                            {
                                                                              message.document_Path
                                                                            }
                                                                          </p>
                                                                        </a>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                ) : (
                                                                  <>
                                                                    {message.picture_Path ===
                                                                      "-" &&
                                                                      message.document_Path !==
                                                                      "-" ? (
                                                                      <div>
                                                                        <p
                                                                          className="message-content"
                                                                          name="Popmsg"
                                                                        // style={{
                                                                        //   wordWrap: "break-word",
                                                                        // }}
                                                                        >
                                                                          {message.message !=
                                                                            "-" ? (
                                                                            <>
                                                                              {
                                                                                message.message
                                                                              }
                                                                            </>
                                                                          ) : null}
                                                                        </p>
                                                                        {/* <i className="fa fa-ellipsis-h" style={{ float: "Right" }} aria-hidden="true"
                                                    onClick={() => this.deleteMenu(message.id, message.group_id, message.senderId, message.createDateTime,
                                                      message.message, message.picture_Path, message.document_Path)}></i> */}
                                                                        <br />
                                                                        <a
                                                                          href={
                                                                            message.document_Path
                                                                          }
                                                                          name="picture"
                                                                          readOnly
                                                                          value={
                                                                            message.document_Path
                                                                          }
                                                                          title=""
                                                                          target="_blank"
                                                                          style={{
                                                                            width:
                                                                              "120px",
                                                                            height:
                                                                              "200px",
                                                                          }}
                                                                        >
                                                                          {/* <embed
                                                    src={message.document_Path}
                                                    title=""
                                                    scrolling="no"
                                                    target="_blank"
                                                    style={{
                                                      width: "100px",
                                                      height: "100px",
                                                      pointerEvents: "none",
                                                    }}
                                                  ></embed> */}
                                                                          <div
                                                                            className="row"
                                                                            style={{
                                                                              width:
                                                                                "180px",
                                                                              height:
                                                                                "60px",
                                                                              borderRadius:
                                                                                "5px",
                                                                              boxShadow:
                                                                                "rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px",
                                                                              background:
                                                                                "#e8e8e8",
                                                                              alignItems:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            <div className="col-lg-3">
                                                                              <img
                                                                                src={
                                                                                  File1
                                                                                }
                                                                                style={{
                                                                                  height:
                                                                                    "30px",
                                                                                  width:
                                                                                    "30px",
                                                                                }}
                                                                              ></img>
                                                                            </div>
                                                                            <div className="col-lg-9">
                                                                              {message
                                                                                .document_Path
                                                                                .length >
                                                                                85 ? (
                                                                                <>
                                                                                  <div
                                                                                    style={{
                                                                                      opacity:
                                                                                        DName &&
                                                                                          documentname ==
                                                                                          message.id
                                                                                          ? 1
                                                                                          : 0,
                                                                                      width:
                                                                                        "150px",
                                                                                      height:
                                                                                        "auto",
                                                                                      overflowWrap:
                                                                                        "break-word",
                                                                                      zIndex:
                                                                                        "109",
                                                                                      border:
                                                                                        "1px solid black",
                                                                                      background:
                                                                                        "#0000007d",
                                                                                      color:
                                                                                        "white",
                                                                                      alignItems:
                                                                                        "center",
                                                                                      position:
                                                                                        "absolute",
                                                                                      top: "-50px",
                                                                                      left: "-50px",
                                                                                    }}
                                                                                  >
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize:
                                                                                          "10px",
                                                                                      }}
                                                                                    >
                                                                                      {
                                                                                        result1
                                                                                      }
                                                                                    </p>
                                                                                  </div>
                                                                                  <p
                                                                                    style={{
                                                                                      marginTop:
                                                                                        "10px",
                                                                                      textAlign:
                                                                                        "center",
                                                                                      fontSize:
                                                                                        "10px",
                                                                                      fontWeight:
                                                                                        "600",
                                                                                      color:
                                                                                        "#19578b",
                                                                                    }}
                                                                                    onMouseOver={(
                                                                                      e
                                                                                    ) => {
                                                                                      e.preventDefault();
                                                                                      this.documentName(
                                                                                        message.id
                                                                                      );
                                                                                    }}
                                                                                    onMouseLeave={
                                                                                      this
                                                                                        .documentNameL
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      result
                                                                                    }
                                                                                    ...
                                                                                  </p>
                                                                                </>
                                                                              ) : (
                                                                                <p
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "10px",
                                                                                    textAlign:
                                                                                      "center",
                                                                                    fontSize:
                                                                                      "10px",
                                                                                    fontWeight:
                                                                                      "600",
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    result
                                                                                  }
                                                                                </p>
                                                                              )}
                                                                            </div>
                                                                          </div>
                                                                          {/* <br></br>
                                                  <p>
                                                    {message.document_Path}
                                                  </p> */}
                                                                        </a>
                                                                      </div>
                                                                    ) : (
                                                                      <div>
                                                                        {/* <i className="fa fa-ellipsis-h" style={{ float: "Right" }} aria-hidden="true"
                                                    onClick={() => this.deleteMenu(message.id, message.group_id, message.senderId, message.createDateTime,
                                                      message.message, message.picture_Path, message.document_Path)}></i> */}
                                                                        <p
                                                                          className="message-content"
                                                                          name="Popmsg"
                                                                        // style={{
                                                                        //   wordWrap: "break-word",
                                                                        // }}
                                                                        >
                                                                          {message.message !=
                                                                            "-" ? (
                                                                            <>
                                                                              {
                                                                                message.message
                                                                              }
                                                                            </>
                                                                          ) : null}
                                                                        </p>
                                                                        <br />
                                                                        <a
                                                                          href={
                                                                            message.picture_Path
                                                                          }
                                                                          name="picture"
                                                                          readOnly
                                                                          value={
                                                                            message.picture_Path
                                                                          }
                                                                          title=""
                                                                          target="_blank"
                                                                          style={{
                                                                            width:
                                                                              "120px",
                                                                            height:
                                                                              "200px",
                                                                          }}
                                                                        >
                                                                          <div>
                                                                            {message.picture_Path.includes(
                                                                              ".mp4"
                                                                            ) ? (
                                                                              <>
                                                                                {" "}
                                                                                <img
                                                                                  alt="Video"
                                                                                  width="30"
                                                                                  height="30"
                                                                                  src={
                                                                                    video
                                                                                  }
                                                                                  title="Click to play video"
                                                                                  style={{
                                                                                    width:
                                                                                      "inherit",
                                                                                  }}
                                                                                />{" "}
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "#000",
                                                                                  }}
                                                                                >
                                                                                  Video
                                                                                  File
                                                                                </span>{" "}
                                                                              </>
                                                                            ) : (
                                                                              <img
                                                                                alt="chatimg"
                                                                                width="80"
                                                                                height="80"
                                                                                src={
                                                                                  message.picture_Path
                                                                                }
                                                                              // style={{
                                                                              //   width:
                                                                              //     "inherit",
                                                                              // }}
                                                                              />
                                                                            )}
                                                                          </div>
                                                                        </a>
                                                                      </div>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                            <br />
                                                            <div className="message-timestamp-right">
                                                              {/* <TimeAgo
                                      date={message.createDateTime}
                                      live={true}
                                      format={(value, unit, suffix) => {
                                        if (unit === "second") {
                                          // Custom formatting for seconds
                                          return `${value} seconds ago`;
                                        }
                                        // Default formatting for other units
                                        return `${value} ${unit}${suffix}`;
                                      }}
                                      locale="en-US"
                                      style={{ color: "gray" }}
                                    /> */}
                                                              <span
                                                                style={{
                                                                  color: "gray",
                                                                }}
                                                              >
                                                                <i className="fa fa-clock-o"></i>
                                                                &nbsp;
                                                                {this.formatDateAA(
                                                                  message.createDateTime
                                                                )}
                                                              </span>
                                                              {/* <ReactTimeAgo date={message.createDateTime} locale="en-US" timeStyle="round-minute" style={{ color: "gray" }} /> */}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </>
                                                    ) : (!member &&
                                                      privacy === 0 &&
                                                      message.privacy !== 1 &&
                                                      message.publicchat ==
                                                      0) ||
                                                      message.publicchat ==
                                                      1 ? (
                                                      <>
                                                        {message.createDateTime &&
                                                          message.rowNum === 1 ? (
                                                          <>
                                                            <span
                                                              style={{
                                                                color: "gray",
                                                                backgroundColor:
                                                                  "#8eb6d859",
                                                                height: "35px",
                                                                padding:
                                                                  "5px 5px 5px 3px",
                                                                borderRadius:
                                                                  "7px",
                                                              }}
                                                            >
                                                              &nbsp;
                                                              {this.formatDateAA1(
                                                                message.createDateTime
                                                              )}
                                                            </span>
                                                          </>
                                                        ) : null}
                                                        {message.type ==
                                                          null ? (
                                                          <>
                                                            <center>
                                                              <div
                                                                style={{
                                                                  width: "max",
                                                                  height:
                                                                    "35px",
                                                                  display:
                                                                    "table",
                                                                  padding:
                                                                    "10px 10px",
                                                                  overflowWrap:
                                                                    "break-word",
                                                                    backgroundColor:
                                                                      "#ddd",
                                                                  borderRadius:
                                                                    "7px",
                                                                  fontSize:
                                                                    "8px",
                                                                }}
                                                              >
                                                                {isSender ? (
                                                                  <p
                                                                    style={{
                                                                      display:
                                                                        "table-cell",
                                                                      textAlign:
                                                                        "center",
                                                                      verticalAlign:
                                                                        "middle",
                                                                    }}
                                                                  >
                                                                    You{" "}
                                                                    {
                                                                      message.message
                                                                    }{" "}
                                                                  </p>
                                                                ) : (
                                                                  <>
                                                                    <p
                                                                      style={{
                                                                        display:
                                                                          "table-cell",
                                                                        textAlign:
                                                                          "center",
                                                                        verticalAlign:
                                                                          "middle",
                                                                      }}
                                                                    >
                                                                      {
                                                                        message.displayName
                                                                      }{" "}
                                                                      {
                                                                        message.message
                                                                      }{" "}
                                                                    </p>
                                                                  </>
                                                                )}
                                                              </div>
                                                              
                                                            </center>{" "}
                                                          </>
                                                        ) : (
                                                          <div
                                                            className={`message-${isSender
                                                              ? "orange"
                                                              : "blue"
                                                              }`}
                                                            key={index}
                                                            style={{
                                                              height: "auto",
                                                              overflowWrap:
                                                                "break-word",
                                                              marginTop: "8px",
                                                            }}
                                                          >
                                                            {/* MessageOption */}
                                                            {MsgOption &&
                                                              this.state
                                                                .deleteId ==
                                                              message.id ? (
                                                              <div
                                                                style={
                                                                  MessageOption
                                                                }
                                                                ref={
                                                                  this
                                                                    .containerRef
                                                                }
                                                              >
                                                                {/* <input type="hidden" id="id"></input>
                                                    <input type="hidden" id="name"></input> */}
                                                                <center>
                                                                  {isSender ? (
                                                                    <>
                                                                      <span
                                                                        onClick={
                                                                          this
                                                                            .EditMessage
                                                                        }
                                                                        id="MsgOption"
                                                                      >
                                                                        <i
                                                                          className="fa fa-pencil-square-o"
                                                                          aria-hidden="true"
                                                                          style={{
                                                                            float:
                                                                              "left",
                                                                            marginLeft:
                                                                              "20px",
                                                                          }}
                                                                        ></i>
                                                                        <b
                                                                          style={{
                                                                            position:
                                                                              "absolute",
                                                                            marginLeft:
                                                                              "-23px",
                                                                          }}
                                                                        >
                                                                          Edit
                                                                        </b>
                                                                      </span>
                                                                      <br />{" "}
                                                                      <hr></hr>
                                                                      <span
                                                                        onClick={
                                                                          this
                                                                            .SeenMessages
                                                                        }
                                                                        id="MsgOption"
                                                                      >
                                                                        <i
                                                                          className="fa fa-eye"
                                                                          aria-hidden="true"
                                                                          style={{
                                                                            float:
                                                                              "left",
                                                                            marginLeft:
                                                                              "20px",
                                                                          }}
                                                                        ></i>
                                                                        <b
                                                                          style={{
                                                                            position:
                                                                              "absolute",
                                                                            marginLeft:
                                                                              "-25px",
                                                                          }}
                                                                        >
                                                                          Seen
                                                                        </b>
                                                                      </span>
                                                                      <br />{" "}
                                                                      <hr></hr>
                                                                    </>
                                                                  ) : null}
                                                                  {/* <span onClick={this.EditMessage} id="MsgOption">
                                                        <i className="fa fa-pencil-square-o" aria-hidden="true" style={{ float: "left", marginLeft: "20px" }}></i>
                                                        <b style={{ position: "absolute", marginLeft: "-33px" }}>Edit</b></span>
                                                      <br /> <hr></hr> */}
                                                                  <span
                                                                    onClick={
                                                                      this
                                                                        .deleteMenu
                                                                    }
                                                                    id="MsgOption"
                                                                  >
                                                                    <i
                                                                      className="fa fa-trash"
                                                                      aria-hidden="true"
                                                                      style={{
                                                                        float:
                                                                          "left",
                                                                        marginLeft:
                                                                          "20px",
                                                                      }}
                                                                    ></i>
                                                                    <b
                                                                      style={{
                                                                        position:
                                                                          "absolute",
                                                                        marginLeft:
                                                                          "-23px",
                                                                      }}
                                                                    >
                                                                      Delete
                                                                    </b>
                                                                  </span>
                                                                  <br />
                                                                  <hr></hr>
                                                                  <span
                                                                    onClick={
                                                                      this
                                                                        .forwardopenpop
                                                                    }
                                                                    id="MsgOption"
                                                                  >
                                                                    <i
                                                                      className="fa fa-share"
                                                                      aria-hidden="true"
                                                                      style={{
                                                                        float:
                                                                          "left",
                                                                        marginLeft:
                                                                          "20px",
                                                                      }}
                                                                    ></i>
                                                                    <b
                                                                      style={{
                                                                        position:
                                                                          "absolute",
                                                                        marginLeft:
                                                                          "-25px",
                                                                      }}
                                                                    >
                                                                      Forward
                                                                    </b>
                                                                  </span>
                                                                  <br />
                                                                </center>
                                                              </div>
                                                            ) : null}
                                                            {member ? (
                                                              <i
                                                                className="fa fa-ellipsis-h"
                                                                style={{
                                                                  float:
                                                                    "Right",
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                aria-hidden="true"
                                                                onClick={() =>
                                                                  this.MessageOption(
                                                                    message.id,
                                                                    message.group_id,
                                                                    message.senderId,
                                                                    message.createDateTime,
                                                                    message.message,
                                                                    message.picture_Path,
                                                                    message.document_Path,
                                                                    message.dateTimeSec
                                                                  )
                                                                }
                                                              ></i>
                                                            ) : null}
                                                            {!isSender ? (
                                                              <h6
                                                                style={{
                                                                  fontWeight:
                                                                    "600",
                                                                  // color:
                                                                  //   "#09679f",
                                                                }}
                                                              >
                                                                <a
                                                                  className="media-left"
                                                                  style={{
                                                                    marginTop:
                                                                      "-2%",
                                                                  }}
                                                                >
                                                                  <img
                                                                    className="media-object img-circle"
                                                                    src={
                                                                      message.particularUserProfile
                                                                    }
                                                                    alt="img"
                                                                    style={{
                                                                      // marginTop: "20px",
                                                                      width:
                                                                        "35px",
                                                                      height:
                                                                        "35px",
                                                                    }}
                                                                  />&nbsp;{
                                                                    message.displayName
                                                                  }
                                                                </a>
                                                                <br />
                                                                {message.editStatus ===
                                                                  1 ? (
                                                                  <span
                                                                    style={{
                                                                      fontWeight:
                                                                        "200",
                                                                      color:
                                                                        "grey",
                                                                      fontSize:
                                                                        "10px",
                                                                    }}
                                                                  >
                                                                    Edited
                                                                  </span>
                                                                ) : null}
                                                              </h6>
                                                            ) : (
                                                              <>
                                                                {message.editStatus ===
                                                                  1 ? (
                                                                  <h6
                                                                    style={{
                                                                      fontWeight:
                                                                        "200",
                                                                      color:
                                                                        "grey",
                                                                      fontSize:
                                                                        "10px",
                                                                    }}
                                                                  >
                                                                    Edited
                                                                  </h6>
                                                                ) : null}
                                                              </>
                                                            )}
                                                            {message.forwarded ===
                                                              1 ? (
                                                              <h6
                                                                style={{
                                                                  fontWeight:
                                                                    "200",
                                                                  color: "grey",
                                                                  fontSize:
                                                                    "10px",
                                                                }}
                                                              >
                                                                <i
                                                                  className="fa fa-share"
                                                                  aria-hidden="true"
                                                                ></i>
                                                                &nbsp;&nbsp;Forwarded
                                                              </h6>
                                                            ) : null}
                                                            {message.picture_Path ===
                                                              "-" &&
                                                              message.document_Path ===
                                                              "-" ? (
                                                              <>
                                                                {/* <DocumentViewer
  url="https://drive.google.com/file/d/0B5ImRpiNhCfGZDVhMGEyYmUtZTdmMy00YWEyLWEyMTQtN2E2YzM3MDg3MTZh/preview"
  viewer="url"
  style={{width:"100%",height:"50vh"}}
>
</DocumentViewer> */}
                                                                <p
                                                                  className="message-content"
                                                                  name="Popmsg"
                                                                // style={{ wordWrap: "break-word" }}
                                                                >
                                                                  {regexp.test(
                                                                    message.message
                                                                  ) == true ? (
                                                                    <>
                                                                      {filterurl.map(
                                                                        (
                                                                          item
                                                                        ) => (
                                                                          <p>
                                                                            {message.message ==
                                                                              item ? (
                                                                              <a
                                                                                style={{
                                                                                  color:
                                                                                    "blue",
                                                                                  textDecoration:
                                                                                    "underline",
                                                                                }}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                href={
                                                                                  item
                                                                                }
                                                                              >
                                                                                {
                                                                                  item
                                                                                }
                                                                              </a>
                                                                            ) : null}
                                                                          </p>
                                                                        )
                                                                      )}{" "}
                                                                    </>
                                                                  ) : (
                                                                    <div>
                                                                      {/* <i className="fa fa-ellipsis-h" style={{ float: "Right" }} aria-hidden="true"
                                                  onClick={() => this.deleteMenu(message.id, message.group_id, message.senderId, message.createDateTime,
                                                    message.message, message.picture_Path, message.document_Path)}></i> */}
                                                                      <p>
                                                                        {
                                                                          message.message
                                                                        }
                                                                      </p>
                                                                    </div>
                                                                  )}
                                                                </p>{" "}
                                                              </>
                                                            ) : (
                                                              <>
                                                                {message.picture_Path !==
                                                                  "-" &&
                                                                  message.document_Path !==
                                                                  "-" ? (
                                                                  <div>
                                                                    <div>
                                                                      <p
                                                                        className="message-content"
                                                                        name="Popmsg"
                                                                      // style={{ wordWrap: "break-word" }}
                                                                      >
                                                                        {message.message !=
                                                                          "-" ? (
                                                                          <>
                                                                            {
                                                                              message.message
                                                                            }
                                                                          </>
                                                                        ) : null}
                                                                      </p>
                                                                      <br />
                                                                      <div>
                                                                        <img
                                                                          id="fileImg"
                                                                          color="#0275d8"
                                                                          width="80"
                                                                          height="80"
                                                                          src={
                                                                            message.picture_Path
                                                                          }
                                                                        // style={{
                                                                        //   width:
                                                                        //     "inherit",
                                                                        // }}
                                                                        />
                                                                      </div>
                                                                      <div>
                                                                        <a
                                                                          href={
                                                                            message.document_Path
                                                                          }
                                                                          name="picture"
                                                                          readOnly
                                                                          value={
                                                                            message.document_Path
                                                                          }
                                                                          title=""
                                                                          target="_blank"
                                                                          style={{
                                                                            width:
                                                                              "120px",
                                                                            height:
                                                                              "200px",
                                                                          }}
                                                                        >
                                                                          <embed
                                                                            src={
                                                                              message.document_Path
                                                                            }
                                                                            id="Gstiframe"
                                                                            title=""
                                                                            scrolling="no"
                                                                            target="_blank"
                                                                            style={{
                                                                              width:
                                                                                "100px",
                                                                              height:
                                                                                "100px",
                                                                              pointerEvents:
                                                                                "none",
                                                                            }}
                                                                          ></embed>
                                                                          <p>
                                                                            {
                                                                              message.document_Path
                                                                            }
                                                                          </p>
                                                                        </a>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                ) : (
                                                                  <>
                                                                    {message.picture_Path ===
                                                                      "-" &&
                                                                      message.document_Path !==
                                                                      "-" ? (
                                                                      <div>
                                                                        <p
                                                                          className="message-content"
                                                                          name="Popmsg"
                                                                        // style={{
                                                                        //   wordWrap: "break-word",
                                                                        // }}
                                                                        >
                                                                          {message.message !=
                                                                            "-" ? (
                                                                            <>
                                                                              {
                                                                                message.message
                                                                              }
                                                                            </>
                                                                          ) : null}
                                                                        </p>
                                                                        {/* <i className="fa fa-ellipsis-h" style={{ float: "Right" }} aria-hidden="true"
                                                    onClick={() => this.deleteMenu(message.id, message.group_id, message.senderId, message.createDateTime,
                                                      message.message, message.picture_Path, message.document_Path)}></i> */}
                                                                        <br />
                                                                        <a
                                                                          href={
                                                                            message.document_Path
                                                                          }
                                                                          name="picture"
                                                                          readOnly
                                                                          value={
                                                                            message.document_Path
                                                                          }
                                                                          title=""
                                                                          target="_blank"
                                                                          style={{
                                                                            width:
                                                                              "120px",
                                                                            height:
                                                                              "200px",
                                                                          }}
                                                                        >
                                                                          {/* <embed
                                                    src={message.document_Path}
                                                    title=""
                                                    scrolling="no"
                                                    target="_blank"
                                                    style={{
                                                      width: "100px",
                                                      height: "100px",
                                                      pointerEvents: "none",
                                                    }}
                                                  ></embed> */}
                                                                          <div
                                                                            className="row"
                                                                            style={{
                                                                              width:
                                                                                "180px",
                                                                              height:
                                                                                "60px",
                                                                              borderRadius:
                                                                                "5px",
                                                                              boxShadow:
                                                                                "rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px",
                                                                              background:
                                                                                "#e8e8e8",
                                                                              alignItems:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            <div className="col-lg-3">
                                                                              <img
                                                                                src={
                                                                                  File1
                                                                                }
                                                                                style={{
                                                                                  height:
                                                                                    "30px",
                                                                                  width:
                                                                                    "30px",
                                                                                }}
                                                                              ></img>
                                                                            </div>
                                                                            <div className="col-lg-9">
                                                                              {message
                                                                                .document_Path
                                                                                .length >
                                                                                85 ? (
                                                                                <>
                                                                                  <div
                                                                                    style={{
                                                                                      opacity:
                                                                                        DName &&
                                                                                          documentname ==
                                                                                          message.id
                                                                                          ? 1
                                                                                          : 0,
                                                                                      width:
                                                                                        "150px",
                                                                                      height:
                                                                                        "auto",
                                                                                      overflowWrap:
                                                                                        "break-word",
                                                                                      zIndex:
                                                                                        "109",
                                                                                      border:
                                                                                        "1px solid black",
                                                                                      background:
                                                                                        "#0000007d",
                                                                                      color:
                                                                                        "white",
                                                                                      alignItems:
                                                                                        "center",
                                                                                      position:
                                                                                        "absolute",
                                                                                      top: "-50px",
                                                                                      left: "-50px",
                                                                                    }}
                                                                                  >
                                                                                    <p
                                                                                      style={{
                                                                                        fontSize:
                                                                                          "10px",
                                                                                      }}
                                                                                    >
                                                                                      {
                                                                                        result1
                                                                                      }
                                                                                    </p>
                                                                                  </div>
                                                                                  <p
                                                                                    style={{
                                                                                      marginTop:
                                                                                        "10px",
                                                                                      textAlign:
                                                                                        "center",
                                                                                      fontSize:
                                                                                        "10px",
                                                                                      fontWeight:
                                                                                        "600",
                                                                                      color:
                                                                                        "#19578b",
                                                                                    }}
                                                                                    onMouseOver={(
                                                                                      e
                                                                                    ) => {
                                                                                      e.preventDefault();
                                                                                      this.documentName(
                                                                                        message.id
                                                                                      );
                                                                                    }}
                                                                                    onMouseLeave={
                                                                                      this
                                                                                        .documentNameL
                                                                                    }
                                                                                  >
                                                                                    {
                                                                                      result
                                                                                    }
                                                                                    ...
                                                                                  </p>
                                                                                </>
                                                                              ) : (
                                                                                <p
                                                                                  style={{
                                                                                    marginTop:
                                                                                      "10px",
                                                                                    textAlign:
                                                                                      "center",
                                                                                    fontSize:
                                                                                      "10px",
                                                                                    fontWeight:
                                                                                      "600",
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    result
                                                                                  }
                                                                                </p>
                                                                              )}
                                                                            </div>
                                                                          </div>
                                                                          {/* <br></br>
                                                  <p>
                                                    {message.document_Path}
                                                  </p> */}
                                                                        </a>
                                                                      </div>
                                                                    ) : (
                                                                      <div>
                                                                        {/* <i className="fa fa-ellipsis-h" style={{ float: "Right" }} aria-hidden="true"
                                                    onClick={() => this.deleteMenu(message.id, message.group_id, message.senderId, message.createDateTime,
                                                      message.message, message.picture_Path, message.document_Path)}></i> */}
                                                                        <p
                                                                          className="message-content"
                                                                          name="Popmsg"
                                                                        // style={{
                                                                        //   wordWrap: "break-word",
                                                                        // }}
                                                                        >
                                                                          {message.message !=
                                                                            "-" ? (
                                                                            <>
                                                                              {
                                                                                message.message
                                                                              }
                                                                            </>
                                                                          ) : null}
                                                                        </p>
                                                                        <br />
                                                                        <a
                                                                          href={
                                                                            message.picture_Path
                                                                          }
                                                                          name="picture"
                                                                          readOnly
                                                                          value={
                                                                            message.picture_Path
                                                                          }
                                                                          title=""
                                                                          target="_blank"
                                                                          style={{
                                                                            width:
                                                                              "120px",
                                                                            height:
                                                                              "200px",
                                                                          }}
                                                                        >
                                                                          <div>
                                                                            <img
                                                                              alt="chatimg"
                                                                              width="80"
                                                                              height="80"
                                                                              src={
                                                                                message.picture_Path
                                                                              }
                                                                            // style={{
                                                                            //   width:
                                                                            //     "inherit",
                                                                            // }}
                                                                            />
                                                                          </div>
                                                                        </a>
                                                                      </div>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                            <br />
                                                            <div className="message-timestamp-right">
                                                              {/* <TimeAgo
                                      date={message.createDateTime}
                                      live={true}
                                      format={(value, unit, suffix) => {
                                        if (unit === "second") {
                                          // Custom formatting for seconds
                                          return `${value} seconds ago`;
                                        }
                                        // Default formatting for other units
                                        return `${value} ${unit}${suffix}`;
                                      }}
                                      locale="en-US"
                                      style={{ color: "gray" }}
                                    /> */}
                                                              <span
                                                                style={{
                                                                  color: "gray",
                                                                }}
                                                              >
                                                                <i className="fa fa-clock-o"></i>
                                                                &nbsp;
                                                                {this.formatDateAA(
                                                                  message.createDateTime
                                                                )}
                                                              </span>
                                                              {/* <ReactTimeAgo date={message.createDateTime} locale="en-US" timeStyle="round-minute" style={{ color: "gray" }} /> */}
                                                            </div>
                                                          </div>
                                                        )}
                                                      </>
                                                    ) : null}
                                                  </>
                                                );
                                              }
                                            )}
                                            <div ref={this.myRef} />
                                            <center>
                                              <span
                                                id="FilterErr"
                                                style={errorpop}
                                              ></span>
                                            </center>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {Seenside && Seen == false ? (
                                      <div className="col-lg-4">
                                        <div
                                          className="chat-container-right"

                                        // style={{
                                        //   position: "fixed",
                                        //   height: "71.2%",
                                        //   width: "23%",
                                        //   backgroundColor: "white",
                                        // }}
                                        //                               style={{ borderLeft: '1px solid #DDDDDD',
                                        //    height: '502px',
                                        //   position: 'absolute',
                                        //   left: '50%',
                                        //   marginLeft: '18%',
                                        //  width:'19.5%',
                                        //   top: '20.5%',
                                        //   background:'white'
                                        //   }}
                                        >
                                          <div className="mchat10">
                                            <div className="mchat">
                                              {/* <center>

<span className="btn" style={{color:"red"}} onClick={this.enddisc}>End Discussion</span>
{Privacy == 0 ?<span className="btn" onClick={this.GroupPrivate}>Private</span>: <span className="btn" >Public</span>}<br></br>
<span className="btn" onClick={this.Addpop}>Add Members</span>  

</center> */}
                                              {/* <center style={{marginLeft: "3%"}}>
  <br></br>
  {/* <h6 style={{ marginTop: "40px" }}><b>{GroupTitle}: {this.state.AllMember.length} Participants</b></h6> */}
                                              {/* {EndStatus != 0 ? <button className="Endbutton-42" onClick={this.enddisc} >
    <span style={{ fontSize: "10px" }}>End</span>
    <span style={{ fontSize: "10px", marginTop: "10px" }}> Discussion</span>
  </button> : null}&nbsp;&nbsp;
  {Privacy == 0 && createdby == UserId && this.state.reloadbutton === true ? (<button className="Privatebutton-42" onClick={this.GroupPrivate}>
    <span style={{ fontSize: "10px" }}>Private</span>
  </button>) : (Privacy == 0 && createdby != UserId ? <button className="Privatebutton-42" onClick={this.private}><span style={{ fontSize: "10px" }}>Private</span></button> : <button className="Privatebutton-42" onClick={this.private}>
    <span style={{ fontSize: "10px" }} >Public</span></button>)}&nbsp;&nbsp;&nbsp;&nbsp;
  {createdby == UserId && EndStatus != 0 ? <i className="fa fa-user-plus" id="GroupAddIcon" onClick={this.openPopup} style={{ color: "#594eaa",fontSize:"20px", marginTop: "8px", position: "absolute" }} aria-hidden="true"></i> : null}
  <br></br>
  <br></br>
</center> */}
                                              <div style={{ marginLeft: "7%", width: "10%" }}>
                                                <br></br>
                                                {/* {Privacy === 0 && this.state.reloadbutton === true  ?
<><div style={{marginBottom:"-6%"}}>
<label style={{color:"green"}}>
<input
          id="option1"
            type="radio"
            value="option1"
            checked={this.state.selectedOption === 'option1'}
            onChange={this.handleOptionChange1}
            style={{marginLeft:"-6%"}}
          />
<span style={{marginLeft:"6%"}}>This discussion is Private</span>
</label>
<br></br>
<label style={{marginLeft:"-4%"}}>
<input
           id="option2"
            type="radio"
            value="option2"
            checked={this.state.selectedOption === 'option2'}
            onChange={this.handleOptionChange}
           // onClick={this.GroupPrivate}
            style={{marginLeft:"-29%"}}
          />
         { (<label   style={{marginLeft:"19%",marginTop:"2%"}}>
<div onClick={this.GroupPrivate} style={{ fontSize: "15px",color:"red",cursor:"pointer",marginLeft:"-15%" }} >Make it as Public</div> 
</label>)}
</label>
</div>
<br/>
</>
        :
<>
       {<span style={{ fontSize: "15px", color:"gray",cursor:"pointer",marginLeft:"7%"}}><p style={{color:"red",marginTop:"-6%",marginBottom:"2%"}}>This discussion is Public</p>All the user can view the discussion</span>} &nbsp;&nbsp;&nbsp;&nbsp;  
</>} */}

                                                {/* <div
                                              className="row"
                                              id="p2"
                                              style={{ marginTop: "-10%" }}
                                            >
                                              {Privacy === 0 &&
                                              this.state.reloadbutton ===
                                                true ? (
                                                <>
                                                  <div
                                                    id="btnp"
                                                    style={{
                                                      marginBottom: "-6%",
                                                      marginTop: "9%",
                                                    }}
                                                  >
                                                    <span
                                                      className="GTbuttons"
                                                      style={{
                                                        marginLeft: "93%",
                                                        marginTop: "1%",
                                                        border:
                                                          "2px solid rgb(156 145 244)",
                                                      }}
                                                    >
                                                      <b>Private</b>
                                                    </span>

                                                    <br></br>
                                                    {
                                                      <div
                                                        className="GTbuttons"
                                                        onClick={
                                                          this.GroupPrivate
                                                        }
                                                        style={{
                                                          top: "-50%",
                                                          marginLeft: "240%",
                                                        }}
                                                      >
                                                        <b>Public</b>
                                                      </div>
                                                    }
                                                  </div>
                                                  <br />
                                                </>
                                              ) : (
                                                <>
                                                  {
                                                    <span
                                                      style={{
                                                        marginLeft: "24%",
                                                        marginTop: "9%",
                                                        marginBottom: "4%",
                                                        color: "gray",
                                                      }}
                                                    >
                                                      <b>
                                                        This discussion is
                                                        Public
                                                      </b>
                                                      <br></br>
                                                      <b>
                                                        All the user can view
                                                        the Chat
                                                      </b>
                                                    </span>
                                                  }{" "}
                                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                                </>
                                              )}
                                            </div> */}


                                                {createdby == UserId &&
                                                  EndStatus != 0 ? (
                                                  <>
                                                    <span
                                                      className="btn"
                                                      style={{
                                                        width: "3%",
                                                        color: "gray",
                                                        backgroundColor:
                                                          "lightGray",
                                                        borderRadius: "50%",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <span
                                                        onClick={this.openPopup}
                                                        id="GroupAddIcon"
                                                      >
                                                        <i
                                                          className="fa-solid fa-user-plus"
                                                          aria-hidden="true"
                                                        ></i>
                                                        &nbsp;&nbsp;Add
                                                        Participants
                                                      </span>
                                                    </span>
                                                    <br></br>
                                                    {/* <p
                                                      style={{
                                                        color: "black",
                                                        marginLeft: "14%",
                                                        marginTop: "2%",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Group Participants :
                                                    </p> */}
                                                  </>
                                                ) : null}

                                                {/* &nbsp;&nbsp;&nbsp;
                                                {EndStatus != 0 &&
                                                Status == 1 ? (
                                                  <span
                                                    className="btn"
                                                    style={{
                                                      width: "3%",
                                                      color: "gray",
                                                      backgroundColor:
                                                        "lightGray",
                                                      borderRadius: "50%",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <span
                                                      onClick={this.enddisc}
                                                      id="enddiscresp"
                                                    >
                                                      <i
                                                        className="fa-solid fa-arrow-right-from-bracket"
                                                        aria-hidden="true"
                                                      ></i>
                                                      &nbsp;&nbsp;
                                                    </span>
                                                  </span>
                                                ) : null}
                                                <br />
                                                <br /> */}
                                                {/* {createdby !== UserId &&
                                                EndStatus != 0 ? ( */}
                                                <span
                                                  style={
                                                    {
                                                      // width:"3%",
                                                      // color:"gray",
                                                      // backgroundColor:"lightGray",
                                                      // borderRadius:"50%",
                                                      // alignItems:"center"
                                                    }
                                                  }
                                                >

                                                </span>
                                                {/* ) : null} */}
                                                {/*  {EndStatus != 0 ? <button className="Endbutton-42" onClick={this.enddisc} style={{marginLeft:"17%",marginTop:"0%"}} >
 <span style={{ fontSize: "10px" }}>End</span>
<span style={{ fontSize: "10px", marginTop: "10px" }}> Discussion</span> 
</button> : null}*/}

                                                {/* <br></br>
                                              <br></br> */}
                                              </div>

                                              {/* {createdby == UserId && EndStatus != 0 ? <i className="fa fa-user-plus" id="GroupAddIcon" onClick={this.openPopup} style={{ color: "#594eaa",fontSize:"20px", marginTop: "8px", marginLeft:"3%", position: "absolute" }} aria-hidden="true"></i> : null}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                              {/* {Privacy == 0 && createdby == UserId && this.state.reloadbutton === true ? (<label  className="high" onClick={this.GroupPrivate} style={{marginLeft:"19%",marginTop:"2%"}}>
    <a style={{ fontSize: "15px",color:"#4BB543",cursor:"pointer",marginLeft:"-34%" }} >This Discussion is Private</a>
  </label>) : (Privacy == 0 && createdby != UserId ? <label className="high" onClick={this.private}><span style={{ fontSize: "10px",marginLeft:"-10%" }}>Private</span></label> : <label className="high" onClick={this.private} style={{marginLeft:"19%",marginTop:"2%"}}>
    <span style={{ fontSize: "15px", color:"#4BB543 ",cursor:"pointer",marginLeft:"-34%" }} >This Discussion is Public</span></label>)}&nbsp;&nbsp;&nbsp;&nbsp;
    <br />
    {createdby == UserId && EndStatus != 0 ? <i className="fa fa-user-plus" id="GroupAddIcon" onClick={this.openPopup} style={{ color: "#594eaa",fontSize:"20px", marginTop: "8px", marginLeft:"-7%", position: "absolute" }} aria-hidden="true"></i> : null}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    {EndStatus != 0 ? <button className="Endbutton-42" onClick={this.enddisc} style={{marginLeft:"-1%"}} >
    <span style={{ fontSize: "10px" }}>End</span>
    <span style={{ fontSize: "10px", marginTop: "10px" }}> Discussion</span>
  </button> : null}
  <br></br>
  <br></br>
</center>   */}

                                              <br></br>
                                              <div style={{ marginTop: "-6%" }}>
                                                <p
                                                  style={{
                                                    color: "black",
                                                    marginTop: "2%",
                                                  }}
                                                >
                                                  Group Participants :
                                                </p>
                                                <div
                                                  id={createdby === UserId ? 'rightside' : 'rightsideA'}
                                                // style={{
                                                //   height: "335px",
                                                //   overflowY: "scroll",
                                                //   overflowX: "hidden",
                                                //   textAlign: "left",
                                                // }}
                                                >
                                                  {AllMembers.map((member) => {
                                                    return (
                                                      <>
                                                        <center>
                                                          <ul
                                                            style={{
                                                              listStyle: "none",
                                                              padding: "0",
                                                              textAlign: "left",
                                                            }}
                                                            id="srll"
                                                          >
                                                            {member.end_Status_ChangeDate === null ?
                                                              <>
                                                                {member.end_Status === 1 && member.status === 1 ?
                                                                  <li
                                                                    style={{
                                                                      padding:
                                                                        " 9px 9px",
                                                                      borderBottom:
                                                                        "1px solid #DDDDDD",
                                                                    }}
                                                                  >
                                                                    {/* <i
                                                              className="fa-solid fa-circle"
                                                              style={{
                                                                fontSize: "7px",
                                                                color: "gray",
                                                              }}
                                                            ></i> */}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    {member.activeStatus ===
                                                                      1 && member.end_Status === 1 ? (
                                                                      <>
                                                                        <img
                                                                          src={
                                                                            onlinedot
                                                                          }
                                                                          className="media-object img-circle"
                                                                          style={{
                                                                            marginTop:
                                                                              "0px",
                                                                            width:
                                                                              "10px",
                                                                            animation:
                                                                              "greenonline 2s infinite",
                                                                            // position: "absolute",
                                                                          }}
                                                                          title="Online"
                                                                        />
                                                                      </>
                                                                    ) : member.end_Status === 1 ? (
                                                                      <>
                                                                        <img
                                                                          src={
                                                                            offlinedot
                                                                          }
                                                                          className="media-object img-circle"
                                                                          style={{
                                                                            marginTop:
                                                                              "0px",
                                                                            width:
                                                                              "10px",
                                                                            animation:
                                                                              "offlinego 2s infinite",
                                                                            // position: "absolute",
                                                                          }}
                                                                          title="Offline"
                                                                        />
                                                                      </>
                                                                    ) : null}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    {EMail ===
                                                                      member.user_id && createdby === UserId ? (
                                                                      <b>
                                                                        You{" "}
                                                                        <span
                                                                          className="Fontsize_In_chat"
                                                                          style={{
                                                                            color:
                                                                              "Gray",
                                                                            whiteSpace:
                                                                              "nowrap",
                                                                          }}
                                                                        >
                                                                          (
                                                                          {
                                                                            member.user_id
                                                                          }
                                                                          )
                                                                        </span>&nbsp;&nbsp;
                                                                        <span style={{
                                                                          display: "inline-block",
                                                                          padding: "2px 5px",
                                                                          backgroundColor: "lightgreen",
                                                                          borderRadius: "10px",
                                                                          color: "gray",
                                                                          fontSize: "6px",
                                                                        }}>
                                                                          Group Admin
                                                                        </span>
                                                                      </b>
                                                                    ) : member.end_Status === 1 ? (
                                                                      <b>
                                                                        {member.name}{" "}
                                                                        <span
                                                                          className="Fontsize_In_chat"
                                                                          style={{
                                                                            color:
                                                                              "Gray",
                                                                            whiteSpace:
                                                                              "nowrap",
                                                                          }}
                                                                        >
                                                                          (
                                                                          {
                                                                            member.user_id
                                                                          }
                                                                          )
                                                                        </span>&nbsp;&nbsp;
                                                                        {member.created_Name === member.name ?
                                                                          <span style={{
                                                                            display: "inline-block",
                                                                            padding: "2px 5px",
                                                                            backgroundColor: "lightgreen",
                                                                            borderRadius: "10px",
                                                                            color: "gray",
                                                                            fontSize: "6px",
                                                                          }}>
                                                                            Group Admin
                                                                          </span>
                                                                          : null}
                                                                      </b>
                                                                    ) : null}
                                                                    {/* <b>{member.name}</b> */}
                                                                    {createdby ==
                                                                      UserId ? (
                                                                      <span
                                                                        style={{
                                                                          float:
                                                                            "right",
                                                                          marginRight:
                                                                            "-3%",
                                                                        }}
                                                                      >
                                                                        {" "}
                                                                        {EMail !==
                                                                          member.user_id &&
                                                                          EndStatus ==
                                                                          1 && member.end_Status === 1 ? (
                                                                          <i
                                                                            className="fa fa-times"
                                                                            aria-hidden="true"
                                                                            style={{
                                                                              color:
                                                                                "gray",
                                                                              cursor:
                                                                                "pointer",
                                                                            }}
                                                                            onClick={() =>
                                                                              this.Deletepop(
                                                                                member.user_id,
                                                                                member.name
                                                                              )
                                                                            }
                                                                          ></i>
                                                                        ) : null}
                                                                      </span>
                                                                    ) : null}
                                                                  </li> : null}</> : <>
                                                                {member.status === 1 && member.leaving === 0 ?
                                                                  <li
                                                                    style={{
                                                                      padding:
                                                                        " 9px 9px",
                                                                      borderBottom:
                                                                        "1px solid #DDDDDD",
                                                                    }}
                                                                  >
                                                                    {/* <i
                                                              className="fa-solid fa-circle"
                                                              style={{
                                                                fontSize: "7px",
                                                                color: "gray",
                                                              }}
                                                            ></i> */}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    {member.activeStatus ===
                                                                      1 && member.end_Status === 0 ? (
                                                                      <>
                                                                        <img
                                                                          src={
                                                                            onlinedot
                                                                          }
                                                                          className="media-object img-circle"
                                                                          style={{
                                                                            marginTop:
                                                                              "0px",
                                                                            width:
                                                                              "10px",
                                                                            animation:
                                                                              "greenonline 2s infinite",
                                                                            // position: "absolute",
                                                                          }}
                                                                          title="Online"
                                                                        />
                                                                      </>
                                                                    ) : member.end_Status === 0 ? (
                                                                      <>
                                                                        <img
                                                                          src={
                                                                            offlinedot
                                                                          }
                                                                          className="media-object img-circle"
                                                                          style={{
                                                                            marginTop:
                                                                              "0px",
                                                                            width:
                                                                              "10px",
                                                                            animation:
                                                                              "offlinego 2s infinite",
                                                                            // position: "absolute",
                                                                          }}
                                                                          title="Offline"
                                                                        />
                                                                      </>
                                                                    ) : null}
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    {EMail ===
                                                                      member.user_id && createdby === UserId ? (
                                                                      <b>
                                                                        You{" "}
                                                                        <span
                                                                          className="Fontsize_In_chat"
                                                                          style={{
                                                                            color:
                                                                              "Gray",
                                                                            whiteSpace:
                                                                              "nowrap",
                                                                          }}
                                                                        >
                                                                          (
                                                                          {
                                                                            member.user_id
                                                                          }
                                                                          )
                                                                        </span>&nbsp;&nbsp;
                                                                        <span style={{
                                                                          display: "inline-block",
                                                                          padding: "2px 5px",
                                                                          backgroundColor: "lightgreen",
                                                                          borderRadius: "10px",
                                                                          color: "gray",
                                                                          fontSize: "6px",
                                                                        }}>
                                                                          Group Admin
                                                                        </span>
                                                                      </b>
                                                                    ) : member.end_Status === 0 ? (
                                                                      <b>
                                                                        {member.name}{" "}
                                                                        <span
                                                                          className="Fontsize_In_chat"
                                                                          style={{
                                                                            color:
                                                                              "Gray",
                                                                            whiteSpace:
                                                                              "nowrap",
                                                                          }}
                                                                        >
                                                                          (
                                                                          {
                                                                            member.user_id
                                                                          }
                                                                          )
                                                                        </span>&nbsp;&nbsp;
                                                                        {member.created_Name === member.name ?
                                                                          <span style={{
                                                                            display: "inline-block",
                                                                            padding: "2px 5px",
                                                                            backgroundColor: "lightgreen",
                                                                            borderRadius: "10px",
                                                                            color: "gray",
                                                                            fontSize: "6px",
                                                                          }}>
                                                                            Group Admin
                                                                          </span>
                                                                          : null}
                                                                      </b>
                                                                    ) : null}

                                                                  </li> : null}</>}
                                                            {/* <li style={{padding:" 10px 10px",border:"1px solid #DDDDDD"}}>{member.name}</li> */}
                                                          </ul>
                                                        </center>
                                                        {/* <div style={deletepop}>

    <center style={{ marginTop: "50px" }}>
      <input defaultValue={this.props.values.groupId} type="hidden" id="groupid"></input>

      <input defaultValue={Deleteuserid} type="hidden" id="duserid"></input>
      <input defaultValue={Deleteusername} type="hidden" id="dusername"></input>
      
      <h6>Are You Sure Want to <span style={{ color: "red" }}>Delete?</span></h6>
      <br></br>
      <span className="btn" onClick={this.DeleteUserGroup}>Confirm</span> &nbsp;&nbsp;&nbsp;&nbsp; <span className="btn" onClick={this.hideDeletepop}>Cancel</span>
    </center>

    <br></br>

  </div> */}
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                                <br />
                                                <center
                                                  style={{
                                                    marginRight: "76%",
                                                    cursor: "pointer",
                                                    // marginTop: "-15%",
                                                  }}
                                                >

                                                  {EndStatus != 0 && createdby === UserId &&
                                                    Status == 1 ? (
                                                    <span
                                                      className="btn"
                                                      style={{
                                                        width: "3%",
                                                        color: "gray",
                                                        backgroundColor:
                                                          "lightGray",
                                                        borderRadius: "50%",
                                                        alignItems: "center",
                                                        marginBottom: "13px",
                                                      }}
                                                    >
                                                      <span
                                                        onClick={this.enddisc}
                                                        id="enddiscresp"
                                                      >
                                                        <i
                                                          className="fa-solid fa-arrow-right-from-bracket"
                                                          aria-hidden="true"
                                                        ></i>
                                                        &nbsp;&nbsp;End Discussion
                                                      </span>
                                                    </span>
                                                  ) : (

                                                    <>
                                                      {AllMembers.map((member) => {
                                                        return (
                                                          <>
                                                            {EMail === member.user_id && member.end_Status === 1 && member.status === 1 ?
                                                              <span
                                                                className="btn"
                                                                style={{
                                                                  width: "3%",
                                                                  color: "gray",
                                                                  backgroundColor:
                                                                    "lightGray",
                                                                  borderRadius: "50%",
                                                                  alignItems: "center",
                                                                  marginBottom: "13px",
                                                                }}
                                                              >
                                                                <span
                                                                  onClick={this.Leavedisc}
                                                                  id="enddiscresp"
                                                                >
                                                                  <i
                                                                    className="fa-solid fa-arrow-right-from-bracket"
                                                                    aria-hidden="true"
                                                                  ></i>
                                                                  &nbsp;&nbsp;Leave Discussion
                                                                </span>
                                                              </span> : null}
                                                          </>
                                                        );
                                                      })}


                                                    </>
                                                  )}

                                                </center>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        <div className="col-lg-4" style={seenStyles} ref={this.containerRef}>
                                          <div
                                            className="chat-container-right"
                                          >
                                            <div style={{
                                              marginTop: "14px",
                                              color: "gray",
                                              marginLeft: "-7%",
                                              marginBottom: "19px",
                                              alignItems: "center",
                                            }}
                                            >
                                              <i className="fa-solid fa-eye" style={{ fontSize: "17px", marginLeft: "39px" }}></i>&nbsp;&nbsp;&nbsp;<span style={{ color: "black", fontSize: "17px" }}><b>Seen By</b></span>
                                              <i className="fa fa-times" style={{ fontSize: "17px", float: "right", marginRight: "10px" }} onClick={() => {
                                                this.setState({
                                                  Seen: false,
                                                });
                                              }}></i>
                                            </div>
                                            <div className="mchat10" style={{ overflow: "hidden scroll", height: "340px" }}>
                                              <div>
                                                {SeenMsgMembers.map((Members) => (
                                                  <span style={{ float: "" }}>
                                                    <>
                                                      <ul
                                                        style={{
                                                          listStyle: "none",
                                                          padding: "0",
                                                          width: "95%",
                                                          textAlign: "left",
                                                          display: "block",
                                                        }}
                                                      >
                                                        {EMail ==
                                                          Members.group_Member ? null : (
                                                          <li
                                                            style={{
                                                              padding: " 10px 10px",
                                                              borderBottom: "1px solid #DDDDDD",
                                                              display: "block",
                                                            }}
                                                          >
                                                            <i
                                                              className="fa-solid fa-circle"
                                                              style={{
                                                                fontSize: "7px",
                                                                color: "gray",
                                                              }}
                                                            ></i>
                                                            &nbsp;&nbsp;&nbsp;
                                                            {Members.name}{" "}
                                                            <span
                                                              className="Fontsize_In_chat"
                                                              style={{
                                                                color: "Gray",
                                                                whiteSpace: "nowrap",
                                                              }}
                                                            ></span>
                                                          </li>
                                                        )}
                                                      </ul>
                                                    </>
                                                  </span>
                                                ))}
                                                <br />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div
                                  id="Chat-Input-outer"
                                >
                                  <div
                                    className="chat-footer b-t-muted"
                                    style={{ backgroundColor: "white" }}
                                  >
                                    {AllMember.map((member) => {
                                      return (
                                        <center>
                                          {checkClosedDate.map((cd) => {
                                            if (cd.group_id === member.group_Id && member.end_Status_ChangeDate !== null)
                                              return (
                                                <span
                                                  id="exitspan"
                                                  style={{ display: "none" }}
                                                >
                                                  Discussion has been Ended by{" "}
                                                  {cd.closed_Name === name ? 'You' : cd.closed_Name}{" "}on{" "}
                                                  {this.formatDateAA2(
                                                    cd.closed_Date
                                                  )}
                                                </span>
                                              );
                                          })}
                                        </center>
                                      );
                                    })}
                                    {AllMembers.map((member) => {
                                      return (
                                        <>
                                          {EMail === member.user_id && member.status === 1 ?
                                            <div
                                              className="input-group write-msg"
                                              id="input-group write-msg"
                                              style={{
                                                border: "1px solid #bfbfbe",
                                                padding: "2px 2px",
                                              }}
                                            >
                                              <input
                                                type="text"
                                                className="form-control input-value"
                                                placeholder="Type a Message"
                                                value={this.state.message}
                                                onChange={this.handleTextChange}
                                                name="Message"
                                                style={{ border: "none" }}
                                                onKeyPress={this.handleKeyPress}
                                              />
                                              {Privacy == 0 ? (
                                                <input
                                                  type="hidden"
                                                  value={Memberslist}
                                                  name="GroupMember"
                                                />
                                              ) : (
                                                <input
                                                  type="hidden"
                                                  value={mergedUsers}
                                                  name="GroupMember"
                                                />
                                              )}
                                              <input
                                                type="file"
                                                id="file"
                                                name="Picture_Path"
                                                accept="image/*"
                                                onChange={this.handleFileChange}
                                                onFocus={(e) =>
                                                  (e.target.type = "file")
                                                }
                                                style={{ display: "none" }}
                                              />
                                              <span
                                                className="fa fa-smile-o"
                                                aria-hidden="true"
                                                style={{
                                                  marginTop: "10px",
                                                  fontSize: "18px",
                                                }}
                                                onClick={this.myEmoji}
                                              ></span>
                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                              {showEmoji && (
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    zIndex: 100,
                                                  }}
                                                  id="DragpopEmoji1"
                                                >
                                                  <Picker
                                                    onEmojiClick={this.handleEmojiClick}
                                                    height={height}
                                                    width={emojiWidth}
                                                  />
                                                </div>
                                              )}
                                              <label
                                                style={{
                                                  marginTop: "8px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-paperclip"
                                                  style={{ fontSize: "20px" }}
                                                  aria-hidden="true"
                                                  onClick={this.attachMenu}
                                                  id="AttachIcon"
                                                ></i>
                                              </label>
                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                              <span className="input-group-btn">
                                                <button
                                                  id="paper-btn"
                                                  className="btn btn-secondary"
                                                  type="submit"
                                                >
                                                  <i className="icofont icofont-paper-plane"></i>
                                                </button>
                                              </span>
                                            </div> : null}
                                        </>
                                      );
                                    })}

                                    <div
                                      className="input-group write-msg"
                                      id="input-groupEdit"
                                      style={{
                                        border: "1px solid #bfbfbe",
                                        padding: "2px 2px",
                                      }}
                                    >
                                      <input
                                        type="text"
                                        className="form-control input-value"
                                        placeholder="Type a Message"
                                        value={this.state.deletemsg}
                                        onChange={this.handleTextChange}
                                        name="Message"
                                        style={{ border: "none" }}
                                        onKeyPress={this.handleKeyPress}
                                      />
                                      {Privacy == 0 ? (
                                        <input
                                          type="hidden"
                                          value={Memberslist}
                                          name="GroupMember"
                                        />
                                      ) : (
                                        <input
                                          type="hidden"
                                          value={mergedUsers}
                                          name="GroupMember"
                                        />
                                      )}
                                      <input
                                        type="file"
                                        id="file"
                                        name="Picture_Path"
                                        accept="image/*"
                                        onChange={this.handleFileChange}
                                        onFocus={(e) =>
                                          (e.target.type = "file")
                                        }
                                        style={{ display: "none" }}
                                      />
                                      <span
                                        className="fa fa-smile-o"
                                        aria-hidden="true"
                                        style={{
                                          marginTop: "10px",
                                          fontSize: "18px",
                                        }}
                                        onClick={this.myEmoji}
                                      ></span>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      {showEmoji && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            zIndex: 100,
                                          }}
                                          id="DragpopEmoji1"
                                        >
                                          <Picker
                                            onEmojiClick={this.handleEmojiClick}
                                            height={height}
                                            width={emojiWidth}
                                          />
                                        </div>
                                      )}
                                      <span className="input-group-btn">
                                        <span
                                          id="paper-btn"
                                          className="btn btn-secondary"
                                        // type="submit"
                                        >
                                          <i
                                            className="fa fa-check"
                                            onClick={this.EditChatMessage}
                                          ></i>{" "}
                                          &nbsp;&nbsp;{" "}
                                          <i
                                            className="fa fa-times"
                                            onClick={this.CanceEdit}
                                          ></i>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div >
          </div >
        )
        }
      </>
    );
  }
}