import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import SideBar from "../Common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class AddTrainingpopDesign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RelationshipDrop: [],
      formErrors: {},
      Training: [],
      Departmentdrop: [],
      Jobdrop: [],
      department: [],
      selectedDepartment: "",
      DeptSelected: null,
      selectedDesignation: "",
      rolevalue: [],
      Designation: [],
      JobRole: [],
      AllRoles: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    var dept = document.getElementById("Department").value;
    // // console.log("Departmentsssssssss",this.state.selectedDepartment)
    const data = new FormData(e.target);
    data.append("Department", this.state.selectedDepartment);
    for (var pair of data.entries()) {
      // console.log(pair[0] + ", " + pair[1]);
    }
    data.append("JobRole", this.props.location.state.data.designation);
    // if (this.Validation()) {
    var role = JSON.parse(localStorage.Role);
    const url =
      role.toString() === "Platform Admin"
        ? `${BASE_URLs}Training/AddTraining`
        : `${BASE_URLs}Training/AdminAddTraining`;
    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    const response = await fetch(url, options);
    const result = await response.json();

    if (result.status === "Success") {
      toast.success(result.message, {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // setTimeout(function () { document.getElementById("close").click(); }, 4000);

      // this.GetTraining();
    } else {
      toast.error(result.message, {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    this.SuccessModel();

    // }
    //  this.GetCourseName();
  };
  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));

    this.props.history.push("/TrainingSchedule");
  };

  handleChange = (event) => {
    const dept = event.target.value;

    this.setState({ selectedDepartment: dept });

    let url = `${BASE_URLs}Training/GetRoleBasedDept?Department=` + dept;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        Accept: "application/json",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          rolevalue: data,
        });
        // console.log("RoleValue", this.state.rolevalue);
      });
  };

  handleDesignationChange = (event) => {
    this.setState({ selectedDesignation: event.target.value });
  };

  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }

  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var TrainingName = document.getElementsByName("CategoryMaster");
    var ReferenceDocument = document.getElementsByName("Ref_Doc");

    if (!TrainingName[0].value) {
      formIsValid = false;
      formErrors["TrainingName"] = "Training Name is required";
    }

    if (!ReferenceDocument[0].value) {
      formIsValid = false;
      formErrors["ReferenceDocument"] = "Document Required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  GetTraining() {
    // alert("called")
    //  // console.log("called or not",this.props.location.state.data.training_Id)
    let url = `${BASE_URLs}Training/SAGetTraining`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Training: data,
        });
      });
  }

  GetDepartment() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    // fetch('${BASE_URLs}Team/GetTeam', options)
    fetch(`${BASE_URLs}Department/DepartmentGet`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex((item) => item.department === value.department) ===
            index
          );
        });
        // console.log("-----------------------------", filteredData);
        this.setState({
          department: filteredData,
        });
      });
  }

  GetAllRole() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Team/GetAllJRole`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        // const filteredData = data.filter((value, index, self) => {
        //   return self.findIndex(item => item.jobRole === value.jobRole) === index;
        // });

        //       for (let i = 0; i < data.length; i++) {
        //         this.state.AllRoles.push(data[i].JobRole);
        //  }
        this.setState({
          AllRoles: data,
        });

        // console.log("hjcbcxscczxibsdbcs", this.state.AllRoles);
      });
  }
  GetDesignation() {
    let url = `${BASE_URLs}Designation/GetMasterDesignation`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Designation: data,
          Desi: data,
        });

        // console.log("Designation", this.state.Designation);

        //
      });
  }

  GetJobRole() {
    // alert("hi");
    var dept = document.getElementById("Department").value;
    if (dept != "") {
      // alert("hi");
      // console.log("dept", dept);
      const options = {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      };
      fetch(`${BASE_URLs}Team/GetJobRole?Department=` + dept, options)
        .then((response) => response.json())
        .then((data) => {
          // Filter out the duplicates
          // const filteredData = data.filter((value, index, self) => {
          //   return self.findIndex(item => item.department === value.department) === index;
          // });
          this.setState({
            JobRole: data,
          });
          // console.log("this.state.JobRole", this.state.JobRole);
        });
    }
  }
  componentDidMount() {
    this.GetTraining();
    this.GetDepartment();
    this.GetDesignation();
    this.GetAllRole();
  }
  render() {
    var w = window.innerWidth;

    const { ReferenceDocument, TrainingName } = this.state.formErrors;
    const { Training, Departmentdrop, Jobdrop } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">
                            Add Training
                          </h4>
                        </div>

                        <form
                          autoComplete="off"
                          onSubmit={this.handleSubmit}
                          style={{ padding: "8px" }}
                        >
                          <div className="row" style={{ padding: "12px" }}>
                            <div className="col-lg-4"></div>
                            <div className="col-lg-4">
                              <select
                                className="effect-16"
                                type="text"
                                // onClick={this.GetJobRole}
                                // value={this.state.selectedDepartment}

                                name="Department"
                                id="Department"
                              >
                                <option value="">Select</option>
                                {this.state.department.map((Departmentdrop) => (
                                  <option value={Departmentdrop.department}>
                                    {Departmentdrop.department}
                                  </option>
                                ))}
                              </select>
                              {TrainingName == null ? (
                                <span
                                  style={{ color: "#a29d9d", float: "left" }}
                                >
                                  Department{" "}
                                </span>
                              ) : (
                                <div style={{ color: "red", float: "left" }}>
                                  {TrainingName}
                                </div>
                              )}

                              <div className="space">
                                <select
                                  className="effect-16"
                                  disabled
                                  type="text"
                                  value={
                                    this.props.location.state.data.designation
                                  }
                                  name="JobRole"
                                  id="JobRole"
                                  onChange={this.handleChange}
                                >
                                  <option value="">
                                    {this.props.location.state.data.designation}
                                  </option>
                                  {this.state.AllRoles.map((JobDrop) => (
                                    <option value={JobDrop.designation}>
                                      {JobDrop.designation}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <span style={{ color: "#a29d9d", float: "left" }}>
                                Designation
                              </span>
                            </div>
                          </div>
                          <div className="row" style={{ padding: "12px" }}>
                            <div className="col-lg-4"></div>
                            <div className="col-lg-4">
                              <input
                                className="form"
                                maxLength="50"
                                id="Trainingitem"
                                name="Trainingitem"
                                type="text"
                              />
                              {TrainingName == null ? (
                                <span
                                  style={{ color: "#a29d9d", float: "left" }}
                                >
                                  Training Item
                                </span>
                              ) : (
                                <div style={{ color: "red", float: "left" }}>
                                  {TrainingName}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row" style={{ padding: "12px" }}>
                            <div className="col-lg-4"></div>
                            <div className="col-lg-4">
                              <input
                                className="form"
                                maxLength="50"
                                id="description"
                                name="description"
                                type="text"
                              />
                              {TrainingName == null ? (
                                <span
                                  style={{ color: "#a29d9d", float: "left" }}
                                >
                                  Description{" "}
                                </span>
                              ) : (
                                <div style={{ color: "red", float: "left" }}>
                                  {TrainingName}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row" style={{ padding: "12px" }}>
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4">
                              {/* <select className="effect-16" name="SchedulingDuration" id="duration" type="text" onChange={this.handleDateChange}>
                                                                                        <option value="">Select</option>
                                                                                        <option value="1 Week" id="duration">1 Week</option>
                                                                                        <option value="2 Week" id="duration">2 Week</option>
                                                                                        <option value="3 Week" id="duration">3 Week</option>
                                                                                        <option value="4 Week" id="duration">4 Week</option>
                                                                                    </select>

                                                                                    <span style={{ color: '#a29d9d' }} >Duration of Training</span> <span style={{ color: "red" }}>*</span>
                                                                                     */}
                              <select
                                className="effect-16"
                                name="Duration"
                                id="duration"
                                type="text"
                                onChange={this.handleDateChange}
                              >
                                <option value="">Select</option>
                                <option value="1 Hour" id="duration">
                                  1 Hour
                                </option>
                                <option value="2 Hours" id="duration">
                                  2 Hours
                                </option>
                                <option value="3 Hours" id="duration">
                                  3 Hours
                                </option>
                                <option value="4 Hours" id="duration">
                                  4 Hours
                                </option>
                                <option value="5 Hours" id="duration">
                                  5 Hours
                                </option>
                                <option value="6 Hours" id="duration">
                                  6 Hours
                                </option>
                                <option value="7 Hours" id="duration">
                                  7 Hours
                                </option>
                                <option value="8 Hours" id="duration">
                                  8 Hours
                                </option>
                              </select>
                              <span style={{ color: "#a29d9d" }}>
                                Duration of Training
                              </span>{" "}
                              <span style={{ color: "red" }}>*</span>
                            </div>
                          </div>

                          <div className="row" style={{ padding: "12px" }}>
                            <div className="col-lg-4"></div>
                            <div className="col-lg-4">
                              <input
                                className="form"
                                maxLength="20"
                                id="Ref_Doc"
                                name="Ref_Docs"
                                multiple
                                onFocus={(e) => (e.target.type = "file")}
                                type="text"
                              />
                              {TrainingName == null ? (
                                <span
                                  style={{ color: "#a29d9d", float: "left" }}
                                >
                                  Ref Doc{" "}
                                </span>
                              ) : (
                                <div style={{ color: "red", float: "left" }}>
                                  {TrainingName}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row" style={{ padding: "12px" }}>
                            <div
                              className="col-sm-12"
                              style={{ textAlign: "center", height: "70px" }}
                            >
                              <button
                                type="submit"
                                className="but"
                                style={{
                                  height: "39.5px",
                                  width: "86.36px",
                                  marginTop: "-0.3%",
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          <br />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
