import React, { Component } from "react";
import Piechart from "../Piechart";
import Pie from "../../Common/Pie";
import SideBar from "../../Common/Sidebar";

export default class ManagerDash extends Component {
  render() {
    return (
      <div>
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                {/*  Start */}
                <div className="page-body">
                  <div className="row">
                    <div className="col-md-12 col-xl-3">
                      <div className="card counter-card-1">
                        <div className="card-block-big">
                          <div>
                            {/* <h3></h3> */}
                            <p></p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-pink"
                                role="progressbar"
                                style={{ width: "70%" }}
                                aria-valuenow={70}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-comment" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4">
                      <div className="card counter-card-2">
                        <div className="card-block-big">
                          <div>
                            {/* <h3></h3> */}
                            <p></p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-success"
                                role="progressbar"
                                style={{ width: "50%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-coffee-mug" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4">
                      <div className="card counter-card-3">
                        <div className="card-block-big">
                          <div>
                            {/* <h3></h3> */}
                            <p></p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-default"
                                role="progressbar"
                                style={{ width: "90%" }}
                                aria-valuenow={90}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-upload" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-8">
                      <div className="card">
                        <div className="card-header">
                          <h5>Manager Counts</h5>
                        </div>

                        <Piechart />

                        {/* <div id="areachart" className="p-t-40 rickshaw_graph">
          <svg width="787.45" height={200}><path className="path" d="M0,160.39603960396042Q111.11285714285714,147.1947194719472,128.20714285714286,136.63366336633663C153.84857142857143,120.79207920792079,230.77285714285713,5.544554455445546,256.4142857142857,1.980198019801982S358.98,87.12871287128714,384.62142857142857,100.99009900990099S487.1871428571429,140.59405940594058,512.8285714285714,140.59405940594058S615.3942857142857,108.9108910891089,641.0357142857143,100.99009900990099S743.6014285714285,57.42574257425744,769.2428571428571,61.3861386138614Q786.3371428571428,64.02640264026404,897.45,140.59405940594058L897.45,200Q786.3371428571428,200,769.2428571428571,200C743.6014285714285,200,666.6771428571429,200,641.0357142857143,200S538.47,200,512.8285714285714,200S410.2628571428571,200,384.62142857142857,200S282.05571428571426,200,256.4142857142857,200S153.84857142857143,200,128.20714285714286,200Q111.11285714285714,200,0,200Z" fill="#1abc9c" stroke="none" strokeWidth={2} opacity={1} /></svg></div> */}
                        <div className="card-block"></div>
                        <div className="card-footer widget-content-footer">
                          <p className="m-b-0">v</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 col-xl-4">
                      <Pie />

                      <div className="card table-card widget-success-card">
                        <div className="row-table">
                          <div className="col-sm-3 card-block-big">
                            <i className="icofont icofont-trophy-alt" />
                          </div>
                          <div className="col-sm-9">
                            {/* <h4></h4>
            <h6></h6> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}
