import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
import CloseIcon from "@material-ui/icons/Close";
export default class EditAdminFamilyModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RelationshipDrop: [],
      formErrors: {},
      isButtonDisabled: false,
      isChrome: false,
    };
  }

  RelationshipDropList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Family/RelationshipDropdown`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ RelationshipDrop: data });
      });
  }

  FamlySubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    if (this.FamilyValidation()) {
      this.setState({ isButtonDisabled: true });
      /*     */
      const url = `${BASE_URLs}Family/EditAdminFamily`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };

      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function () {
          if (document.getElementById("close")) {
            document.getElementById("close").click();
          }
        }, 4000);
        this.setState({ isButtonDisabled: true });
      } else
      {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({ isButtonDisabled: false });
        // if (document.getElementById("close")) {
        //   document.getElementById("close").click();
        // }
      }
    }
  };

  componentDidMount() {
    this.RelationshipDropList();
    const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
    this.setState({ isChrome });
  }
  FamilyValidation() {
   
    var mobPattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?(?!0+$)(?:[6-9]\d{9}|[6-9]\d{2}-\d{3}-\d{4})$/;

    var Firstname = document.getElementById("Firstname").value;
    var Lastname = document.getElementById("Lastname").value;
    var Contact = document.getElementById("famContact").value;
    var Email = document.getElementById("famEmail").value; /**/
    var Relationship = document.getElementById("Relationship").value;
    var emergencyContact = document.getElementById("Trainer").checked;

    let formErrors = {};
    let formIsValid = true;

    if (!Firstname) {
      formIsValid = false;
      formErrors["Firstname"] = "First Name  is required";
    }else if (Firstname.length < 3) {
      formIsValid = false;
      formErrors["Firstname"] = "Minimum 3 Characters Required";
    }
    if (!Lastname) {
      formIsValid = false;
      formErrors["Lastname"] = "Last Name  is required";
    }

    if (this.props.value.emergencyContact === "Yes") {
      if (!Contact) {
        formIsValid = false;
        formErrors["Contact"] = "Contact  is required";
      } else if (!mobPattern.test(Contact)) {
        formIsValid = false;
        formErrors["Contact"] = "Enter valid Contact!.";
      }
    }

    if(this.props.value.emergencyContact === "Yes")
    {
      if(Contact.length < 10 || !mobPattern.test(Contact)){
        formIsValid = false;
        formErrors["Contact"] = "Enter the valid Contact";
      }
    }

    if (this.props.value.emergencyContact === "No") {
      if (Contact !== "" && Contact !== "-" && Contact.length < 10) {
        formIsValid = false;
        formErrors["Contact"] = "Enter a valid Contact Number";
      }
      else if (!mobPattern.test(Contact)) {
        formIsValid = false;
        formErrors["Contact"] = " Enter a valid Contact Number";
      }

    }
   
    // if (!Email) {
    //   formIsValid = false;
    //   formErrors["Email"] = "Email is required";
    // }

    // else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)))   {
    //   formIsValid = false;
    //   formErrors["Email"] = "Enter valid Email!.";
    // }
    if (!Relationship) {
      formIsValid = false;
      formErrors["Relationship"] = "Relationship is required";
    }
    
    /*if (!DOBinfo) {    
     formIsValid = false;    
     formErrors["DOBinfo"] = "DOB is required" ;    
   }
   if (!Bloodgroupinfo) {    
     formIsValid = false;    
     formErrors["Bloodgroupinfo"] = "Blood Group is required" ;    
   } */

  
   if (Email!== '-' && Email!== '') {
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
       formErrors["Email"] = "Enter valid Email";
    }
  }
    this.setState({ formErrors: formErrors });

    //document.getElementById("add-row").style.display = "none";
    return formIsValid;
  }

  handleChange = (e) => {
    var Firstname = document.getElementById("Firstname").value;
    var Lastname = document.getElementById("Lastname").value;
    var Contact = document.getElementById("famContact").value;
    var Email = document.getElementById("famEmail").value; /**/
    var Relationship = document.getElementById("Relationship").value;
    var emergencyContact = document.getElementById("Trainer").checked;

    const { name, value } = e.target;
    this.setState({ [name]: value });
    let formErrors = {};
    let formIsValid = true;

    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
    //Email
    if (!Firstname) {
      formIsValid = false;
      formErrors["Firstname"] = "First Name is required";
    } else if (Firstname.length < 3) {
      formIsValid = false;
      formErrors["Firstname"] = "Minimum 3 Characters Required";
    } else if (!Lastname) {
      formIsValid = false;
      formErrors["Lastname"] = "Last Name is required";
    }
    else if (!Relationship) {
      formIsValid = false;
      formErrors["Relationship"] = "Relationship is required";
    } 
    else if(this.props.value.emergencyContact === "Yes")
    {
      if (!Contact) {
        formIsValid = false;
        formErrors["Contact"] = "Contact number is required";
      }
      else if(Contact.length < 10 || !mobPattern.test(Contact)){
        formIsValid = false;
        formErrors["Contact"] = "Enter the valid Contact";
      }
    }
  
   
    else if (emergencyContact === true) {
      if (!Contact) {
        formIsValid = false;
        // formErrors["ContactErr"] = "Contact number is required";
      } else if (!mobPattern.test(Contact)) {
        formIsValid = false;
        // formErrors["ContactErr"] = " Enter the valid Contact Number";
      }

      // else if (!Email) {
      //   formIsValid = false;
      //   formErrors["EmailErr"] = "Email is required";
      // }
      // else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email))) {
      //   formIsValid = false;
      //   formErrors["EmailErr"] = "Enter valid Email";
      // }
      
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  render() {
    const { Firstname, MiddleName, Lastname, Contact, Email, Relationship } =
      this.state.formErrors;
    const { RelationshipDrop } = this.state;
    const { isChrome } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div className="popinnernormal">
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>
            <h1 className="popinnertitle">Edit </h1>

            <form
              id="familyform"
              autoComplete="off"
              onSubmit={this.FamlySubmit.bind(this)}
            >
              <div
                className="row justify-content-center"
                style={{ width: "100%" }}
              >
                <div className="col-lg-5 ">
                  <input
                    style={{ display: "none" }}
                    type="hidden"
                    name="CreatedDate"
                    value={this.props.value.created_Date}
                  />
                  <input
                    className="effect-16"
                    name="FirstName"
                    type="text"
                    maxLength="20"
                    id="Firstname"
                    defaultValue={this.props.value.FirstName}
                    onChange={this.handleChange}
                    placeholder="First Name"
                  />
                  {Firstname == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      First Name
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {Firstname}
                    </div>
                  )}
                </div>
                <div className="col-lg-5">
                  <input
                    className="effect-16"
                    name="MiddleName"
                    type="text"
                    maxLength="20"
                    id="MiddleName"
                    defaultValue={this.props.value.MiddleName}
                    onChange={this.handleChange}
                    placeholder="Middle Name"
                  />
                  <span
                    style={{
                      color: "#a29d9d",
                      float: "left",
                    }}
                  >
                    Middle Name
                  </span>
                </div>
              </div>
              <br />
              {/* <div className="col-lg-5"> 
<input style={{display:"none"}}type="hidden"name="Id" value={this.props.id}/>
<input className="effect-16"  name="MiddleName" type="text" maxLength="20" id="MiddleName" defaultValue={this.props.value.MiddleName}   placeholder="Middle Name" />
<span  style={{color:'#a29d9d',float:"left"}}>Middle Name</span> 
</div> */}
              <div
                className="row justify-content-center"
                style={{ width: "100%" }}
              >
                <div className="col-lg-5">
                  <input
                    className="effect-16"
                    name="LastName"
                    type="text"
                    maxLength="20"
                    id="Lastname"
                    defaultValue={this.props.value.LastName}
                    onChange={this.handleChange}
                    placeholder="Last Name"
                  />
                  {Lastname == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Last Name
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {Lastname}
                    </div>
                  )}
                </div>


                {/* */}

                <div className="col-lg-5">
                  <input
                    className="effect-16"
                    name="Contact"
                    type="text"
                    maxLength="10"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    id="famContact"
                    defaultValue={this.props.value.Contact}
                    onChange={this.handleChange} 
                    placeholder="Contact"
                  />
                  {Contact == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Contact
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>{Contact}</div>
                  )}
                </div>
                <br />
                <div
                  className="row justify-content-center"
                  style={{ width: "100%" }}
                >
                  <div className="col-lg-5">
                    <input
                      className="effect-16"
                      name="Email"
                      type="text"
                      id="famEmail"
                      maxLength="50"
                      defaultValue={this.props.value.Email}
                      onChange={this.handleChange}
                     placeholder="Email"
                    />
                    {!Email ? (
                      <span style={{ color: "#a29d9d", float: "left" }}>
                        Email
                      </span>
                    ) : (
                      <div style={{ color: "red", float: "left" }}>{Email}</div>
                    )}
                  </div>



                  <div className="col-lg-5">
                  <input
                    type="hidden"
                    name="oldrelation"
                    defaultValue={
                      this.props.value.Relationship
                    }
                  />
                    <select
                      className="effect-16"
                      name="Relationship"
                      id="Relationship"
                      defaultValue={this.props.value.Relationship}
                    onChange={this.handleChange}
                    placeholder="City"
                      style={{
                        // Apply the marginLeft style only if the browser is Chrome
                        ...(isChrome && { marginLeft: '-6px' })
                      }}
                    >
                      <option value="">Relationship</option>
                      <option hidden>{this.props.value.Relationship}</option>
                      {RelationshipDrop.map((RelationshipDrop) =>
                        RelationshipDrop.relationship ===
                          this.props.value.Relationship ? (
                          <option value={RelationshipDrop.relationship} selected>
                            {RelationshipDrop.relationship}
                          </option>
                        ) : (
                          <option value={RelationshipDrop.relationship}>
                            {RelationshipDrop.relationship}
                          </option>
                        )
                      )}
                    </select>
                    {Relationship == null ? (
                      <span style={{ color: "#a29d9d", float: "left" }}>
                        Relationship
                      </span>
                    ) : (
                      <div style={{ color: "red", float: "left" }}>
                        {Relationship}
                      </div>
                    )}
                  </div>
                </div>
                <br />
                <div className="col-lg-5" style={{ marginTop: "3%" }}>
                  <span
                    style={{ color: "rgb(162, 157, 157)", marginLeft: "-30%" }}
                  >
                    Emergency Contact
                  </span>
                  &nbsp;&nbsp;
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="Trainer"
                      defaultChecked={
                        this.props.value.emergencyContact === "Yes"
                          ? true
                          : false
                      }
                      name="EmergencyContact"
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </div>
              <br />
              <div className="col-lg-12">
                <div className="row  justify-content-center">
                  <button
                    type="submit"
                    className="button-30 sk-edit"
                    style={{
                      color: "#8e75ef",
                      fontWeight: "bold",
                      height: "39.5px",
                      width: "83.36px",
                    }}
                    disabled={this.state.isButtonDisabled}
                  >
                    Update
                  </button>
                  <button
                    type="reset"
                    className="button-30 sk-edit"
                    style={{
                      color: "#716b6b",
                      fontWeight: "bold",
                      marginLeft: "2%",
                      height: "39.5px",
                    }}
                    onClick={() => this.setState({ formErrors: "" })}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}
