import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
import AddLocationModel from "../Common/AddLocationModel";
export default class AddHolidaysPop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RelationshipDrop: [],
      LocationDrop: [],
      LocationModel: false,
      isChrome: false,
      formErrors: {},
      ButtonDisabled:false
    };
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.Validation()) {
      const data = new FormData(e.target);

      this.setState({
        ButtonDisabled: true,
      });

      // console.log("check", data);

      const url = `${BASE_URLs}Holiday/AddHoliday`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();
      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({
          ButtonDisabled: true,
        });
        document.getElementById("addform").style.display = "none";
        setTimeout(function () {
          if (document.getElementById("close")) {
            document.getElementById("close").click();
          }
        }, 2000);
        this.GetManager();
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setTimeout(function () {
          document.getElementById("close").click();
        }, 2000);
        this.GetManager();
      }
    }
  };

  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var Date = document.getElementById("Date").value;
    var Reason = document.getElementById("Reason").value.trim();
    var Location = document.getElementById("Location").value.trim();

    if (!Date || Date.trim() === "") {
      formIsValid = false;
      formErrors["Date"] = "Date is required";
    }
    if (!Reason || Reason.trim() === "") {
      formIsValid = false;
      formErrors["Reason"] = "Occassion is required";
    } else if (Reason.length < 3) {
      formIsValid = false;
      formErrors["Reason"] = "A minimum 3 characters is required";
    }
    if (!Location || Location.trim() === "") {
      formIsValid = false;
      formErrors["Location"] = "Branch is required";
    } else if (Location.length < 3) {
      formIsValid = false;
      formErrors["Location"] = "A minimum 3 characters is required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  getMinDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;

    const formattedMonth = month.toString().padStart(2, "0");
    const formattedDate = `${year}-${formattedMonth}-01`;

    return formattedDate;
  };

  GetManager() {
    let url = `${BASE_URLs}Manager/ManagerGet`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Manager: data,
        });
      });
  }

  handleChange = (e) => {
    let formErrors = {};
    let formIsValid = true;
    var Date = document.getElementById("Date").value;
    var Reason = document.getElementById("Reason").value.trim();
    var Location = document.getElementById("Location").value.trim();

    if (!Date || Date.trim() === "") {
      formIsValid = false;
      formErrors["Date"] = "Date is required";
    } else if (!Reason || Reason.trim() === "") {
      formIsValid = false;
      formErrors["Reason"] = "Occassion is required";
    } else if (Reason.length < 3) {
      formIsValid = false;
      formErrors["Reason"] = "A minimum 3 characters is required";
    } else if (!Location || Location.trim() === "") {
      formIsValid = false;
      formErrors["Location"] = "Branch  is required";
    } else if (Location.length < 3) {
      formIsValid = false;
      formErrors["Location"] = "A minimum 3 characters is required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  componentDidMount() {
    this.GetManager();
    this.GetLocation();
    const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
    this.setState({ isChrome });
  }
  
  GetLocation() {
    let url = `${BASE_URLs}AdminLocation/GetLocation`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          LocationDrop: data,
        });
        // console.log("location", data);

        //
      });
  }
  SelectLocation = (e) => {
    let dept = document.getElementById("Location").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        LocationModel: !prevState.LocationModel,
      }));
      document.getElementById("Location").value = "";
    }
  };

  LocationModel = () => {
    this.setState((prevState) => ({
      LocationModel: !prevState.LocationModel,
      SkillModel: false
    }));

    this.GetLocation();
  };

  render() {
    const { Date, Reason, Location } = this.state.formErrors;
    const {LocationDrop} = this.state;
    const { isChrome } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        {this.state.LocationModel ? (
          <AddLocationModel close={() => this.LocationModel()} />
        ) : null}

        <div className="pop">
          <div
            className="popinnernormal"
            style={{ width: "42%", padding: "1%" }}
            id="addform"
          >
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>
            <br />
            <br />
            <form
              autoComplete="off"
              encType="multipart/form-data"
              onSubmit={this.handleSubmit}
            >
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <input
                    className="form"
                    name="Date"
                    type="text"
                    id="Date"
                    onFocus={(e) => (e.target.type = "date")}
                    onChange={this.handleChange}
                    min={this.getMinDate()}
                  />
                  {Date == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Date<span style={{ color: "red", float: "left" }}></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>{Date}</div>
                  )}
                </div>

                <div className="col-lg-5">
                  <input
                    className="form"
                    name="Reason"
                    id="Reason"
                    type="text"
                    onChange={this.handleChange}
                  />
                  {Reason == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      {" "}
                      Occassion
                      <span style={{ color: "red", float: "left" }}></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>{Reason}</div>
                  )}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <select
                    className="effect-16"
                    name="LocationType"
                    // defaultValue={"Primary Location"}
                    id="Location"
                    type="text"
                    onInputCapture={this.SelectLocation}
                    onChange={this.handleChange}
                    style={{
                      // Apply the marginLeft style only if the browser is Chrome
                      ...(isChrome && { marginLeft: '-8px' })
                    }}
                  >
                     <option value="">Select</option>
                     
                     {LocationDrop.map((location, index) => (
                     <option key={index} value={location.branch}>
      {location.branch}
    </option>
  ))}
                                          </select>
                  {Location == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Branch
                      <span style={{ color: "red", float: "left" }}></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {Location}
                    </div>
                  )}{" "}
                </div>

                <div
                  className="col-lg-5"
                  style={{
                    marginTop: "20px",
                    color: "#495057",
                    display: "flex",
                    paddingLeft: "12px",
                  }}
                >
                  <div>&nbsp;Optional &nbsp;&nbsp;</div>
                  <label className="switch">
                    <input type="checkbox" name="Holiday" />
                    <span className="slider round" />
                  </label>
                </div>
              </div>

              <br />

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    disabled={this.state.ButtonDisabled}
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
            <br />
          </div>
        </div>
      </>
    );
  }
}
