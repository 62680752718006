import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base";
export default class SkillMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      skills: [],
      Id: "",
      SkillName: "",
      SkillDescription: "",
      Category: "",
      id: this.props.match.params.id,
      value: this.props.location.state,
      formErrors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.state;
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.target);

    const url = `${BASE_URLs}Skill/EditSkill`;
    const options = {
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };

    const response = await fetch(url, options);
    await response.json();
    if (response.ok) {
      alert("success");
      this.props.history.push("/SkillMaster");
    }
  };

  handleClick = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);

    fetch(`${BASE_URLs}Skill/EditSkill`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        //Authorization: "Bearer " +accessToken
      },
      body: JSON.stringify(data),
    });

    //alert("Added your Comment Successfully!");
  };

  handleFormValidation() {
    let SkillName = document.getElementById("SkillName").value;
    let SkillDescription = document.getElementById("SkillDescription").value;
    let Category = document.getElementById("Category").value;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!SkillName) {
      formIsValid = false;
      formErrors["SkillNameErr"] = "Skill Name is required";
    }
    if (!SkillDescription) {
      formIsValid = false;
      formErrors["SkillDescriptionErr"] = "Skill Description is required";
    }

    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  EditSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    if (this.handleFormValidation()) {
      let url = `${BASE_URLs}Skill/EditSkill`;
      fetch(url, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        },
        body: data,
      }).then((result) => {
        result.json().then((resp) => {
          alert(resp);
          this.props.history.push("/SkillGrid");
        });
      });
    }
  };

  GetSkill() {
    var Id = this.props.location.state.Id;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    let url = `${BASE_URLs}Skill/GetUserSkill?Id=` + Id;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          skills: data,
        });

        //
      });
  }

  deleteSkill(id) {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}Skill/Delete?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          alert(resp);
        });
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };
  componentDidMount() {
    this.GetSkill();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  render() {
    const { SkillNameErr, SkillDescriptionErr, CategoryErr } =
      this.state.formErrors;

    const { skills } = this.state;
    return (
      <div>
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header"></div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title">Edit</h4>
                        </div>
                        <div className="card-header-right"></div>
                        <br />
                        <br />
                        <div className="card-block2">
                          {/*         <div className="j-wrapper ">
                           */}
                          <form onSubmit={this.EditSubmit}>
                            <div
                              className="container"
                              style={{ margin: "0 35%" }}
                            >
                              <div className="row">
                                <div className="col-3 input-effect">
                                  {skills.map((skill) => (
                                    <select
                                      className="form"
                                      name="Category"
                                      id="Category"
                                      onChange={this.handleChange}
                                      defaultValue={skill.category}
                                      type="text"
                                      placeholder="City"
                                    >
                                      <option defaultChecked>
                                        {" "}
                                        {skill.category}
                                      </option>
                                      <option value=""> Category</option>
                                      <option Value="IT">IT</option>
                                      <option value="HR">HR</option>
                                      <option value="Finance">FINANCE</option>
                                      <option value="Production">
                                        PRODUCTION
                                      </option>
                                      <option value="Production">
                                        BANKING
                                      </option>
                                      <option value="Production">
                                        ACCOUNTING
                                      </option>
                                    </select>
                                  ))}
                                  {!CategoryErr ? (
                                    <span style={{ color: "#a29d9d" }}>
                                      Category
                                    </span>
                                  ) : (
                                    <div style={{ color: "red" }}>
                                      {CategoryErr}
                                    </div>
                                  )}

                                  <span className="underline" />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-3 input-effect">
                                  {skills.map((skill) => (
                                    <input
                                      className="form"
                                      name="SkillName"
                                      Id="SkillName"
                                      type="text"
                                      maxLength="40"
                                      onChange={this.handleChange}
                                      defaultValue={skill.skillName}
                                      placeholder="Skill Name"
                                    />
                                  ))}
                                  {!SkillNameErr ? (
                                    <span style={{ color: "#a29d9d" }}>
                                      Name{" "}
                                    </span>
                                  ) : (
                                    <div style={{ color: "red" }}>
                                      {SkillNameErr}
                                    </div>
                                  )}
                                  <span className="underline" />
                                </div>
                              </div>
                              <div className="row">
                                {skills.map((skill) => (
                                  <div className="col-3 input-effect">
                                    <input
                                      className="form"
                                      name="SkillDescription"
                                      type="text"
                                      maxLength="200"
                                      id="SkillDescription"
                                      defaultValue={skill.skillDescription}
                                      onChange={this.handleChange}
                                      placeholder="Skill Description"
                                    />
                                    <input
                                      type="hidden"
                                      name="Id"
                                      value={skill.id}
                                    />
                                    {!SkillDescriptionErr ? (
                                      <span style={{ color: "#a29d9d" }}>
                                        Description{" "}
                                      </span>
                                    ) : (
                                      <div style={{ color: "red" }}>
                                        {SkillDescriptionErr}
                                      </div>
                                    )}
                                    <span className="underline" />
                                  </div>
                                ))}
                              </div>
                              <button
                                type="reset"
                                className=" btn-Secondary"
                                style={{ margin: "0px 80px" }}
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="but"
                                style={{
                                  margin: "2px -63px",
                                  height: "40.4px",
                                  width: "83.36px",
                                }}
                              >
                                Save
                              </button>
                              <br /> <br />
                            </div>
                          </form>
                          <br /> <br /> <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
