import React, { Component } from "react";

import LogoCompo from "../Common/LogoCompo";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class Forgot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      otp: "",
      Npwd: "",
      CPwd: "",
      value: this.props.location.state,
      formErrors: {},
      Eye1: false,
      Eye2: false,
      Mail: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleFormValidation() {
    var Npwd = document.getElementById("Npwd").value;
    var CPwd = document.getElementById("CPwd").value;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!Npwd) {
      formIsValid = false;
      formErrors["NpwdErr"] = " New Password is required";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(
        Npwd
      )
    ) {
      formIsValid = false;
      formErrors["NpwdErr"] = "Invalid Password";
    }
    if (!CPwd) {
      formIsValid = false;
      formErrors["CPwdErr"] = " Confirm Password is required";
    } else if (CPwd !== Npwd) {
      formIsValid = false;
      formErrors["CPwdErr"] =
        " New Password and Confirm  Password doesn't match";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  togglePassword1 = () => {
    this.setState({ Eye1: !this.state.Eye1 });
  };

  togglePassword2 = () => {
    this.setState({ Eye2: !this.state.Eye2 });
  };

  handleChange = (e) => {
    var Npwd = document.getElementById("Npwd").value;
    var CPwd = document.getElementById("CPwd").value;

    let formErrors = {};
    let formIsValid = true;
    const { name, value } = e.target;
    this.setState({ [name]: value });

    //Email
    if (!Npwd) {
      formIsValid = false;
      formErrors["NpwdErr"] = " New Password is required";
    } else if (!/^(?=.*[A-Z])/.test(Npwd)) {
      formIsValid = false;
      formErrors["NpwdErr"] = "At least one Capital letter is required";
    } else if (!/^(?=.*[a-z])/.test(Npwd)) {
      formIsValid = false;
      formErrors["NpwdErr"] = "At least one small letter is required";
    } else if (!/\d/.test(Npwd)) {
      formIsValid = false;
      formErrors["NpwdErr"] = "At least one number is required";
    } else if (!/^(?=.*[-+_!@#$%^&*., ?])/.test(Npwd)) {
      formIsValid = false;
      formErrors["NpwdErr"] = "At least one Symbol is required";
    } else if (Npwd.length < 8) {
      formIsValid = false;
      formErrors["NpwdErr"] = "A Minimum of 8  characters are required";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(
        Npwd
      )
    ) {
      formIsValid = false;
      formErrors["NpwdErr"] = "Invalid Password";
    } else if (!CPwd) {
      formIsValid = false;
      formErrors["CPwdErr"] = " Confirm Password is required";
    } else if (Npwd !== CPwd) {
      formIsValid = false;
      formErrors["CPwdErr"] =
        " New Password and Confirm  Password doesn't match";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.handleFormValidation()) {
      const data = new FormData(event.target);
      const url = `${BASE_URLs}Forgot/ChangePwd`;
      const options = {
        method: "POST",
        body: data,
      };

      const response = await fetch(url, options);
      const result = await response.json();
      if (result.status === "Success") {
        localStorage.removeItem("rml");
        localStorage.removeItem("mlcode");
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          this.props.history.push("/Login_form");
        }, 4000);
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  decipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
      encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
  };

  componentDidMount() {
    var mail = localStorage.rml;
    const myDecipher = this.decipher("mySecretSalt");
    mail = myDecipher(mail).toString();
    mail = JSON.parse(mail);
    this.setState({ Mail: mail });
  }
  render() {
    var w = window.innerWidth;

    const { NpwdErr, CPwdErr } = this.state.formErrors;

    return (
      <div>
        <div>
          <div className="bgImg" />
          <div className="content">
            <br />
            <LogoCompo />
            <ToastContainer
              position="top-center"
              autoClose={1500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div
              className={
                w < 992
                  ? "  h-100 align-items-center justify-content-center"
                  : " d-flex h-100 align-items-center justify-content-center"
              }
            >
              <div className="container">
                <div className="row">
                  <div
                    className={
                      w < 992
                        ? " col-sm-12 ml-auto mr-auto"
                        : "col-md-4 col-sm-6 ml-auto mr-auto"
                    }
                  >
                    <div className="card-copy">
                      <div className="card-body">
                        <form id="myForm" onSubmit={this.handleSubmit}>
                          <br />
                          <br />
                          <br />{" "}
                          <div>
                            <h4 className="title1">
                              <span className="shadows">New Password</span>
                            </h4>
                          </div>
                          <br />
                          <div className="signUp">
                            <input
                              type="hidden"
                              name="Email"
                              value={this.state.Mail}
                            ></input>
                            <>
                              <div style={{ display: "flex" }}>
                                <input
                                  type={!this.state.Eye1 ? "password" : "text"}
                                  className="form"
                                  id="Npwd"
                                  onChange={this.handleChange}
                                  name="Npwd"
                                  maxLength="20"
                                  autoComplete="off"
                                />
                                <span
                                  className={
                                    !this.state.Eye1
                                      ? "icofont icofont-eye eyelogo"
                                      : "icofont icofont-eye-blocked eyelogo"
                                  }
                                  id="eye"
                                  onClick={this.togglePassword1}
                                ></span>
                                {/* eye-blocked */}
                              </div>
                              <span
                                className={NpwdErr ? " showError" : ""}
                              ></span>
                              {!NpwdErr ? (
                                <span
                                  style={{ color: "#a29d9d", float: "left" }}
                                >
                                  New Password
                                </span>
                              ) : (
                                <div style={{ color: "red", float: "left" }}>
                                  {NpwdErr}
                                </div>
                              )}
                              <span className="underline" />
                            </>

                            <>
                              <div style={{ display: "flex" }}>
                                <input
                                  type={!this.state.Eye2 ? "password" : "text"}
                                  className="form"
                                  id="CPwd"
                                  onChange={this.handleChange}
                                  name="CPwd"
                                  maxLength="20"
                                  autoComplete="off"
                                />
                                <span
                                  className={
                                    !this.state.Eye2
                                      ? "icofont icofont-eye eyelogo"
                                      : "icofont icofont-eye-blocked eyelogo"
                                  }
                                  id="eye"
                                  onClick={this.togglePassword2}
                                ></span>
                                {/* eye-blocked */}
                              </div>

                              {!CPwdErr ? (
                                <span
                                  style={{ color: "#a29d9d", float: "left" }}
                                >
                                  Confirm Password
                                </span>
                              ) : (
                                <div style={{ color: "red", float: "left" }}>
                                  {CPwdErr}
                                </div>
                              )}
                              <span className="underline" />
                            </>
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="but"
                              style={{ width: "83.36px" }}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
