import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { BASE_URLs } from "../../Config/Base";
import CloseIcon from "@material-ui/icons/Close";
import { Image_URLs } from "../../Config/Base";
import fileImg from "../../Assets/images/document.png";
export default class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Notify: [],
      Active: [],
      InActive: [],
      Pending: [],
      All: [],
      allNotify: [],
      User: [],
      renewel: [],
      Notification: [],
      Unreadcount: "0",
      ContactMessages: [],
      AllContactMessages: [],
      NoRequest: [],
      Request: [],
      ContactMessagesCount: "",
      NotifyCount: "",
      delay: false,
      Filterpop: false,
      letters: [],
      isOpen: false,
      MsgNotify: []
    };
    this.Noticount = this.Noticount.bind(this);
  }

  GetallNotify() {
    fetch(`${BASE_URLs}Notification/GetallNotify`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ allNotify: data });
      });
  }

  Noticount = (notify_Id, company_Id, contact_Id) => {
    fetch(`${BASE_URLs}Notification/ChangeSeen`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: JSON.stringify({
        Seen: 1,
        notify_Id: notify_Id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("new");
        },
        (error) => { }
      );

    this.GetNotification();
    if (company_Id !== null) {
      var page = {
        pathname: "/OrganizationDetail",
        state: {
          Id: company_Id,
        },
      };

      this.props.history.push(page);
    }
  };

  GetContactmsg(contact_Id) {
    // var Contact_Id = this.props.location.state.Id;
    this.setState({
      isOpen: true
    })
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(
      `${BASE_URLs}Contact/GetContactmsg?Contact_Id=` + contact_Id,
      options
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ MsgNotify: data });
        if (data.length !== 0) {
          this.setState({ Unreadcount: data[0].count });
        }
      });
  }
  Contactcount = (notify_Id, contact_Id) => {
    fetch(`${BASE_URLs}Notification/ChangeSeen`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "*/*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: JSON.stringify({
        Seen: 1,
        notify_Id: notify_Id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => { },
        (error) => { }
      );

    if (contact_Id !== null) {

      this.GetContactmsg(contact_Id)
      // var page = {
      //   pathname: "/Contactmsg",
      //   state: {
      //     Id: contact_Id,
      //   },
      // };

      // this.props.history.push(page);
    }
    this.GetNotification();
  };

  UserOrg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    fetch(`${BASE_URLs}AddOrganization/GetOrg`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          User: data,
        });
      });
  }

  renewel() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}AddOrganization/Renewel`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ renewel: data });
      });
  }

  detailData(company_Id) {
    // localStorage.setItem("status","Active")
    var page = {
      pathname: "/OrganizationDetail",
      state: {
        Id: company_Id,
        Status: "Pending",
      },
    };

    this.props.history.push(page);
  }
  detailData1(company_Id) {
    localStorage.setItem("Id", company_Id);
    localStorage.setItem("status", "Inactive");
  }

  componentDidMount() {
    this.GetNotification();
    this.GetallNotify();
    setTimeout(() => {
      this.setState({
        delay: true
      })
    }, 500);

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
  }

  GetNotification() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Notification/GetNotificationMsg`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Notify: data.filter((Tasks) => Tasks.type !== "c") });
        this.setState({
          NotifyCount: data
            .filter((Tasks) => Tasks.type !== "c")
            .filter((Tasks) => Tasks.seen === false).length,
        });

        this.setState({
          ContactMessages: data.filter((Tasks) => Tasks.type === "c"),
        });
        this.setState({
          AllContactMessages: data.filter((Tasks) => Tasks.type === "c"),
        });
        this.setState({
          ContactMessagesCount: data
            .filter((Tasks) => Tasks.type === "c")
            .filter((Tasks) => Tasks.seen === false).length,
        });

        if (data.length !== 0) {
          this.setState({ Unreadcount: data[0].count });
        }
      });
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  filterfunction = (e) => {
    const ContactMessages = this.state.AllContactMessages.filter(
      (item) =>
        item.description?.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ ContactMessages, showResults: true });

  }
  handleFilter = (letter, event) => {
    event.preventDefault();
    const ContactMessages = this.state.AllContactMessages.filter((item) => {
      return (
        item.description?.toLowerCase().includes(letter.toLowerCase())
      );
    });
    this.setState({ ContactMessages, showResults: true });

  };
  popupclose = () => {
    this.setState({
      isOpen: false
    })
  }
  getFileType(path) {
    const extension = path.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) {
      return 'image';
    } else if (['mp4', 'avi', 'mov', 'wmv', 'mkv'].includes(extension)) {
      return 'video';
    } else if (['pdf', 'xls', 'xlsx'].includes(extension)) {
      return 'document';
    } else {
      return 'file';
    }
  }
  handleImageClick = (url) => {
    window.open(url, '_blank');
     };
  render() {
    const {
      Notify,
      NoRequest,
      Request,
      ContactMessages,
      NotifyCount,
      ContactMessagesCount, delay, Filterpop, isOpen, MsgNotify
    } = this.state;

    return (
      <>
        <SideBar active={window.location.hash} />
        {/* start */}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a
                              href
                              onClick={() => this.props.history.goBack()}
                            >
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title" style={{ marginRight: '45px' }}>Messages </h4>
                        </div>
                        {isOpen ?
                          <div className="col-lg-12">
                            <div className="popup" style={{ textAlign: 'left' }}>
                              <div className="popup-content">
                                <div className="Announcedetailspop" >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                    style={{
                                      color: "gray",
                                      marginLeft: "90%",
                                      fontSize: "17px",
                                      cursor: 'pointer'
                                    }}
                                    onClick={this.popupclose}
                                  ></i>
                                  <br />
                                  <center><h6><b>Message Details</b></h6></center>
                                  <div className="card-block" style={{ overflow: "scroll" }}>
                                    <div>
                                      {MsgNotify.map((Notify) => (
                                        <>
                                        <div style={{ width:"97%",height: "237px", overflow: "scroll"}}>

                                          <span
                                                  key={Notify.id}
                                                  style={{ margin: "0%" }}
                                                >

                                                  <b>Title:</b> &nbsp; <span
                                                    style={{ overflowWrap: "break-word", width: "50%", }}
                                                  >  {Notify.cSubject}</span><br /><br />
                                                  <b>Subject:</b> &nbsp; <span
                                                    style={{ overflowWrap: "break-word", width: "50%", }}>{Notify.cMsg}</span>
                                                    <br /><br />
                                                    <b>Attachements:</b> &nbsp;
                                                    <br />
                                                    <span
                                                  // style={{ color: "#11439b" }}
                                                  target="_blank"
                                                  href={Notify.image}
                                                  style={{color: "#11439b" , overflowWrap: "break-word", width: "50%", }}
                                                >

{Notify.image.split(',').map((imageUrl, index) => (
  <div style={{ display: "inline-block", marign: "10px" }} key={index}>
  <div style={{margin:"10px"}}>
                                                {this.getFileType(imageUrl) === 'image' ? (
                                                  
                                                  <span style={{ margin:"30px" }}>
                                                  <b style={{color:'black'}}>Image:&nbsp;</b>

{imageUrl.split(',').map((Url, index) => (
<img
key={index}
alt={`Image ${index}`}
src={imageUrl.trim()} 
style={{ width: "100px", height: "100px" }}
onClick={() => this.handleImageClick(Url.trim())}
/>
))}
                                                  </span>
                                                ) : this.getFileType(imageUrl) === 'video' ? (
                                                  <span style={{ display: "flex", margin:"30px" }}>
                                                    <span><b style={{color:'black'}}>video:</b></span>
                                                    <video
                                                      controls
                                                      style={{ width: "125px", height: '118px', marginTop: '-62px', marginLeft:"8px" }}
                                                    >
                                                      <source src={imageUrl} type="video/mp4" />
                                                      <source src={imageUrl} type="video/ogg" />
                                                    </video>
                                                  </span>
                                                ) : (
                                                  <span style={{margin:"30px"}}>
                                                    <b style={{color:'black'}}>File:</b> 
                                                    <img
                                                      src={fileImg}
                                                      style={{ height: "30px", width: "30px" }}
                                                    />
                                                    &nbsp;
                                                    <a
                                                      href={imageUrl}
                                                      name="picture"
                                                      readOnly
                                                      value={imageUrl}
                                                      title=""
                                                      target="_blank"
                                                    >
                                                      <span>
                                                        {/* {imageUrl.slice("https://localhost:44369/Images/Announcement_Image_".length)} */}
                                                        {imageUrl.replace(
                                                    `${Image_URLs}/Images/`,
                                                    " "
                                                  )}
                                                      </span>
                                                    </a>
                                                  </span>
                                                )}
                                                </div>                          
                                                  </div>
  ))}

                                                  
                                                </span>
                                                </span>
                                           
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                </div> </div> </div></div> : null}
                        <div className="card-block">
                        <div className="j-tabs-container md-tabs">
                          <input
                            id="tab2"
                            type="radio"
                            name="tabs"
                            defaultChecked
                          />
                          <label
                            id="Requests"
                            className="j-tabs-label"
                            htmlFor="tab2"
                            title="Requests"
                            style={{marginLeft:'23px'}}
                          >
                            {/* {this.state.ContactMessages.length === 0 ? (
                              <span>&nbsp;&nbsp;No Message&nbsp;&nbsp;</span>
                            ) : ( */}
                            <span>
                              &nbsp;&nbsp;Messages
                              {ContactMessagesCount === 0
                                ? null
                                : " (" + ContactMessagesCount + ")"}{" "}
                              &nbsp;&nbsp;
                            </span>
                            {/* )} */}
                          </label>
                          <input
                            type="text"
                            className="Searchbox"
                            id="CNSearch"
                            placeholder="Search"
                            onClick={() => {
                              this.setState({
                                Filterpop: true,
                                Filterpop: !this.state.Filterpop,
                              });
                            }}
                            readOnly

                            style={{ float: 'right'}}

                          />

                          <div id="tabs-section-2" className="j-tabs-section">
                            <div
                              className="tab-pane"
                              id="notes"
                              role="tabpanel"
                            >
                              <div className="card-block">

                                <div className="dt-responsive table-responsive"
                                  id={ContactMessages.length <= 6 ? "NoAnnLength" : "Above10"}
                                >
                                  {ContactMessages.length === 0 && delay ?
                                    <center>

                                      <p>No Record</p>
                                    </center>
                                    : null}
                                  {ContactMessages.length !== 0 && delay ? <table
                                    id="add-row-table3" className="table nowrap">
                                    <thead id='tablepadding'><tr>
                                      <th style={{color:'#7367f0'}}> Message Details</th>

                                    </tr>
                                    </thead>
                                    <tbody id='Notitablepadding'>
                                      <tr> <td>
                                        {ContactMessages.map((Notify) => (
                                          <>
                                            {Notify.seen === false ? (
                                              // Unreaded noti
                                              <>
                                                {Notify.type === "c" ? (
                                                  <>
                                                    <Link
                                                      // to="/Contactmsg"
                                                      id="btnEdu"
                                                      onClick={() =>
                                                        this.Contactcount(
                                                          Notify.notify_Id,
                                                          Notify.contact_Id
                                                        )
                                                      }
                                                      style={{
                                                        padding: "0px",
                                                        color: "#000100",
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {" "}
                                                      <span className="hoverable-span">
                                                        {Notify.description}{" "}
                                                      </span>{" "}
                                                    </Link>
                                                    <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                      {Notify.time}
                                                    </span>
                                                    <hr id="NotiHR"></hr>
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            ) : (
                                              // Readed noti
                                              <>
                                                {Notify.type === "c" ? (
                                                  <>
                                                    <Link
                                                      // to="/Contactmsg"
                                                      onClick={() =>
                                                        this.Contactcount(
                                                          Notify.notify_Id,
                                                          Notify.contact_Id
                                                        )
                                                      }
                                                      style={{
                                                        padding: "0px",
                                                        color: "gray",
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      {" "}
                                                      <span className="hoverable-span">
                                                        {Notify.description}{" "}
                                                      </span>{" "}
                                                    </Link>
                                                    <span className="notification-time" style={{ color: "gray", fontSize: "12px" }}>
                                                      {Notify.time}
                                                    </span>
                                                    <hr id="NotiHR"></hr>
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ))}
                                      </td></tr>
                                    </tbody>


                                  </table> : null}

                                </div>
                                {/* <ul
                                  className="show-notification"
                                  style={{
                                    height: "300px",
                                    overflow: "scroll",
                                  }}
                                >
                                  <li>
                                    {ContactMessages.map((Notify) => (
                                      <>
                                        {Notify.seen === false ? (
                                          // Unreaded noti
                                          <>
                                            {Notify.type === "c" ? (
                                              <>
                                                <Link
                                                  to="/Contactmsg"
                                                  id="btnEdu"
                                                  onClick={() =>
                                                    this.Contactcount(
                                                      Notify.notify_Id,
                                                      Notify.contact_Id
                                                    )
                                                  }
                                                  style={{
                                                    padding: "0px",
                                                    color: "#000100",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {" "}
                                                  <b>
                                                    {Notify.description}{" "}
                                                  </b>{" "}
                                                </Link>
                                                <span className="notification-time">
                                                  {Notify.time}
                                                </span>
                                                <br />
                                                <br />
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          // Readed noti
                                          <>
                                            {Notify.type === "c" ? (
                                              <>
                                                <Link
                                                  to="/Contactmsg"
                                                  onClick={() =>
                                                    this.Contactcount(
                                                      Notify.notify_Id,
                                                      Notify.contact_Id
                                                    )
                                                  }
                                                  style={{
                                                    padding: "0px",
                                                    color: "gray",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {" "}
                                                  <b>
                                                    {Notify.description}{" "}
                                                  </b>{" "}
                                                </Link>
                                                <span className="notification-time">
                                                  {Notify.time}
                                                </span>
                                                <br />
                                                <br />
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        )}
                                      </>
                                    ))}
                                  </li>
                                </ul> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {Filterpop && (
                  <div className="popSearchTeam">
                    <div className="popSearchinnernormalTeam">
                      <div
                        className="popSearchinnertitle"
                        id="popSearchinnernormal"
                      >
                        <button
                          id="close"
                          style={{
                            float: "right",
                            backgroundColor: "#a0a3a305",
                            color: "#797070",
                            border: "1px solid #a3a5a505",
                            marginTop: "-20px",
                            marginRight: "-20px",
                          }}
                          onClick={() => {
                            this.setState({ Filterpop: false });
                            this.GetNotification();
                          }}
                        >
                          <CloseIcon />
                        </button>

                        <div className="checkboxes">
                          <i
                            className="fa fa-search"
                            aria-hidden="true"
                            style={{
                              fontSize: "20px",
                              color: "gray",
                              marginLeft: "-1%",
                              position: "absolute",
                              marginTop: "0.5%",
                            }}
                          ></i>
                          <input
                            className="Searchbox"
                            placeholder="Search"
                            onChange={this.filterfunction}
                            style={{ float: "right", width: "80%" }}
                          />
                          <br></br>
                          {this.state.letters.map(
                            (letter, index) => (
                              <button
                                className="filter"
                                key={index}
                                onClick={(event) =>
                                  this.handleFilter(
                                    letter,
                                    event
                                  )
                                }
                              >
                                {letter}
                              </button>
                            )
                          )}
                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* End */}
      </>
    );
  }
}
