import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
import Autocomplete from "react-autocomplete";
export default class EditManagerPop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Department: [],
      ManagerNAme: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.Validation()) {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}Manager/EditManager`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(function () {
              if (document.getElementById("close")) {
                document.getElementById("close").click();
              }
            }, 4000);
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            document.getElementById("Editpop").style.display = "none";
            setTimeout(function () {
              document.getElementById("close").click();
            }, 2000);
          }
        });
    }
  }
  Validation() {
    let formErrors = {};
    let formIsValid = true;
    // var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;

    var ManagerName = document.getElementById("Manager_Name").value;
    var Exp = document.getElementById("Department").value;

    if (!ManagerName) {
      formIsValid = false;
      formErrors["ManagerNameerr"] = "Manager Name  is required.";
    }
    if (!Exp) {
      formIsValid = false;
      formErrors["ExpErr"] = "Acquired Year is required.";
    }

    this.setState({ formErrors: formErrors });

    //document.getElementById("add-row").style.display = "none";
    return formIsValid;
  }
  GetDepartment() {
    let url = `${BASE_URLs}Department/GetMasterDepartment`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Department: data,
        });
      });
  }
  GetManagerName() {
    let url = `${BASE_URLs}Manager/GetManagerAutocomplete`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // this.setState({
        //   ManagerNAme: data,
        // });
        this.setState(
          {
            ManagerNAme: data,
          },
          () => {
            // console.log(this.state.ManagerNAme,'nameeee');
          }
        );
        
      });
  }
  componentDidMount() {
    this.GetDepartment();
    this.GetManagerName();
  }


  render() {
    const { ManagerNameerr, ExpErr } = this.state.formErrors;
    const { Department,ManagerNAme } = this.state;
    return (
      <>
        {" "}
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />{" "}
        <div className="pop">
          <div className="popinnernormal" style={{ padding: "1%" }} id="Editpop">
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>
            <br />
            <form autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
              <div className="row" style={{ padding: "8px" }}>
                <div className="col-sm-6">
                  <input
                    type="hidden"
                    name="Id"
                    defaultValue={this.props.values.id}
                  />
                  {/* <input
                    className="form"
                    maxLength="20"
                    defaultValue={this.props.values.ManagerName}
                    id="Manager_Name"
                    name="Manager_Name"
                    type="text"
                    onChange={this.handleChange}
                    placeholder="Manager Name"
                  /> */}
                  <select
                    className="effect-16 selectleftalign"
                    id="Manager_Name"
                    name="Manager_Name"
                    defaultValue={this.props.values.ManagerName}

                    type="text"
                    placeholder="Manager Name"
                  >
                    <option hidden>{this.props.values.ManagerName}</option>
                    <option value=""> Manager Name</option>
                   {ManagerNAme.map((name) => ( 
                  <option
                      selected={
                        name.label ===
                        this.props.values.ManagerName
                      }
                      value={name.label}
                    >
                      {name.label}
                    </option> 
                    ))} 

                  </select>
                  {/* <div  style={{ zIndex: "1000" ,display:"block"}}>
                   <Autocomplete
                    className="form"
                    // style={{ textAlign: "left" }}
                    items={this.state.ManagerNAme}
                    shouldItemRender={(item, value) =>
                      item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
                    }
                    getItemValue={(item) => item.label}
                    renderItem={(item, isHighlighted) => (
                     
                      <div
                        style={{
                          background: isHighlighted ? "#bcf5bc" : "white",
                        }}
                        key={item.id}
                      >
                        {item.label}
                      </div>
                    )}
                    defaultValue={this.props.values.ManagerName}
                    onChange={(e) => {
                      this.setState({ value: e.target.value });
                      this.handleChange();
                    }}
                    onKeyDown={this._handleKeyDown}
                    // To set the state variable to value
                    // selected from dropdown
                    onSelect={(val) => this.setState({ value: val })}
                    // Added style in Autocomplete component
                    inputProps={{
                      style: {
                        display: "flex",
                      },
                      className: "form",
                      name: "Manager_Name",
                      id: "Manager_Name",
                      type: "text",
                    }}
                    id="Manager_Name"
                    name="Manager_Name"
                  /></div> */}
                  {ManagerNameerr == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Manager Name<span style={{ color: "red" }}></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {ManagerNameerr}
                    </div>
                  )}{" "}
                  <span className="underline" />
                </div>
                <div className="col-sm-6">
                  <input
                    type="hidden"
                    name="Id"
                    defaultValue={this.props.values.id}
                  />
                  <select
                    className="effect-16"
                    id="Department"
                    name="AddDepartment"
                    defaultValue={this.props.values.department}
                    type="text"
                    placeholder="DepartmentName"
                  >
                    <option value="">Select</option>
                    {Department.map((Department) => (
                      <option
                        selected={
                          this.props.values.department === Department.department
                        }
                        value={Department.department}
                      >
                        {Department.department}
                      </option>
                    ))}
                  </select>
                  {/* <input type="hidden" name="Id" defaultValue={this.props.values.id} />  
      <input className="form" maxLength="20" defaultValue={this.props.values.department} id="Department" name="DepartmentName" type="text" onChange={this.handleChange}  placeholder="Department Name" /> */}
                  {ExpErr == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Department Name<span style={{ color: "red" }}></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>{ExpErr}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
            <br />
          </div>
        </div>
      </>
    );
  }
}
