import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class EditSkillPop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RelationshipDrop: [],
      formErrors: {},
      Department: [],
      ButtonDisabled: false,
    };
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };

  handleSubmit = async (e) => {
    var SkillName = document.getElementById("SkillName").value;
    var Id = document.getElementById("Id").value;
    e.preventDefault();
    if (this.Validation()) {
      this.setState({
        ButtonDisabled: true,
      });
      const data = new FormData();
      data.append("SkillName", SkillName);
      data.append("Id", Id);
      var role = JSON.parse(localStorage.Role);
      const url =
        role.toString() === "Platform Admin"
          ? `${BASE_URLs}SuperAdmin/SAEditSkillName`
          : `${BASE_URLs}Skill/EditSkillName`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({
          ButtonDisabled: true,
        });
        document.getElementById("Editpop").style.display = "none";
        setTimeout(function () {
          if (document.getElementById("close")) {
            document.getElementById("close").click();
          }
        }, 4000);
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  Validation() {
    var SkillName = document.getElementById("SkillName").value;
    let formErrors = {};
    let formIsValid = true;
    //Email
    if (!SkillName) {
      formIsValid = false;
      formErrors["SkillNameErr"] = "Skill Name is required";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  GetDepartment() {
    let url = `${BASE_URLs}Department/GetMasterDepartment`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Department: data,
        });
      });
  }

  componentDidMount() {
    // this.RelationshipDropList();
  }

  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  render() {
    var w = window.innerWidth;
    const { SkillNameErr } = this.state.formErrors;
    const { Department } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="Editpop"
          >
            <form
              autoComplete="off"
              encType="multipart/form-data"
              onSubmit={this.handleSubmit}
            >
              <button
                id="close"
                style={{
                  float: "right",
                  backgroundColor: "#a0a3a305",
                  color: "#797070",
                  border: "1px solid #a3a5a505",
                }}
                onClick={this.props.close}
              >
                <CloseIcon />
              </button>

              <div className="row" style={{ padding: "8px" }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <input
                    type="hidden"
                    id="Id"
                    name="Id"
                    defaultValue={this.props.values.id}
                    onKeyDown={this._handleKeyDown}
                  />
                  <input
                    className="form"
                    maxLength="50"
                    defaultValue={this.props.values.SkillName}
                    onKeyDown={this._handleKeyDown}
                    id="SkillName"
                    onChange={(e) => {
                      this.AutoCaps(e);
                    }}
                    onKeyPress={this.handleKeyPress}
                    name="SkillName"
                    type="text"
                  />
                  {!SkillNameErr ? (
                    <div style={{ color: "#a29d9d", textAlign: "left" }}>
                      Skill Name
                    </div>
                  ) : (
                    <div style={{ color: "red", textAlign: "left" }}>
                      {SkillNameErr}
                    </div>
                  )}
                  <span className="underline" />
                </div>
                <div className="col-sm-4"></div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
