import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import CommonSuccess from "../../Common/CommonSuccess";
import { Link } from "react-router-dom";
import AddDepartmentpop from "../../Common/AddDepartmentpop";
import AddDesignationModel from "../../Common/AddDesignationModel";
import AddManagerPop from "../../Common/AddManagerPop";
import AddLocationModel from "../../Common/AddLocationModel";
import "../../Css/Addskills.css";
import Multiselect from "multiselect-react-dropdown";
import { BASE_URLs } from "../../Config/Base";
const options = [
  { value: "islamabad", label: "Islamabad" },
  { value: "lahore", label: "Lahore" },

  { value: "karachi", label: "Karachi" },
];
export default class AddTrainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Active: [],
      InActive: [],
      FirstName: "",
      LastName: "",
      Email: "",
      Mobile: "",
      Department: "",
      Designation: "",
      RoleName: "",
      formErrors: {},
      Notification: [],
      Departmentdrop: [],
      message: "",
      status: "",
      DepartModel: false,
      DesignationModel: false,
      ManagerModel: false,
      LocationModel: false,
      selectedOptions: [],
      optionList: [
        { value: "Winner", label: "Winner" },
        { value: "AboutUs", label: "AboutUs" },
        { value: "Terms&Conditions", label: "Terms&Conditions" },
        { value: "PrivacyPolicy", label: "PrivacyPolicy" },
        { value: "Unfollow", label: "Unfollow" },
        { value: "Report", label: "Report" },
        { value: "Transaction", label: "Transaction" },
      ],
      Teams: [],
      ListOptions: [],
      Traineroption: [],
      selectedValues: [],
      department: [],
      selectedDepartment: "",
      trainerName: [],
      selectedTrainerName: "",
      trainerRole: "",
      selectedRole: "",
      trainerID: "",
      currentDate: null,
      trainerIDData: [],
      trainerdate: "",
      fetchTrainerName: "",
      fetchTrainerDept: "",
      propsPassId: "",
      isVisible: false,
      isBtnvisible: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.SelectDepartment = this.SelectDepartment.bind(this);
  }
  GetDate() {
    const today = new Date();
    const formattedDate = today.toISOString().slice(0, 10);
    this.setState({ currentDate: formattedDate });
  }
  GetDepartment() {
    let url = `${BASE_URLs}Department/DepartmentGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Departmentdrop: data,
        });
      });
  }
  handleSelect = (selectedList, selectedItem) => {
    this.setState({ selectedValues: selectedList });
  };

  Team() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Team/GetTeam`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex((item) => item.department === value.department) ===
            index
          );
        });
        this.setState({
          department: filteredData,
          selectedDepartment: filteredData.department,
        });
        this.fetchItems(data[0].department);
        // console.log("TeamsData", data);
        // console.log("TeamsDisplayName", data[0].displayName);
        // console.log("Department", data[0].department);
      });
  }

  fetchItemsbyId() {
    const { trainerid } = this.props.location.state;
    // console.log("propsID", trainerid);
    const optionVal = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Team/GetTeambyId?id=${trainerid}`, optionVal)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          trainerName: data,
          trainerID: data[0].id,
          trainerIDData: data[0],
          fetchTrainerDept: data[0].department,
          fetchTrainerName: data[0].trainerName,
          propsPassId: trainerid,
        });
        // console.log("PropsValue", trainerid);
        // console.log("Matching Trainer", this.state.trainerIDData);
        console.log(
          "MatchingTrainerName",
          this.state.trainerIDData.trainerName
        );
        // console.log("MatchingTrainerDept", this.state.trainerIDData.department);

        const changedateformat = this.state.trainerIDData.trainersince;
        const parsedDate = new Date(
          new Date(changedateformat).getFullYear(),
          new Date(changedateformat).getMonth(),
          new Date(changedateformat).getDate() + 1
        );
        const formattedDate = parsedDate.toISOString().slice(0, 10);
        this.setState({ trainerdate: formattedDate });

        // console.log("Matching Date", formattedDate);
      })
      .catch((error) => console.error(error));
  }

  fetchItems(departmentValue) {
    const optionVal = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(
      `${BASE_URLs}Team/GetTeambyDepartment?Department=${departmentValue}`,
      optionVal
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          trainerName: data,
          // selectedTrainerName: data.firstName, // Select the first item by default
          trainerID: data[0].id,
          trainerData: data,
        });
        // console.log("TrainerDisplayName", data[0].firstName);
        // console.log("TrainerUserID", data[0].userId);
        // console.log("ID", data[0].id);
        // console.log("all trainer", { data });
      })
      .catch((error) => console.error(error));
  }
  fetchRole(TrainerName, TrainerID) {
    // console.log("cccrrrtt", TrainerID, TrainerName);
    const optionVal = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(
      `${BASE_URLs}Team/GetRolebyTrainerName?Firstname=${TrainerName}&id=${TrainerID}`,
      optionVal
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          // trainerName: data,
          // selectedRole: data,
          trainerRole: data[0].role,
        });
        // console.log("TrainerRole", data[0].role);
        // console.log("SelectedRole", this.state.selectedrole.role);
      })
      .catch((error) => console.error(error));
  }

  handleChange = (event) => {
    const department = event.target.value;
    this.setState({ selectedDepartment: department });
    this.fetchItems(department);
  };

  handleTrainerNameChange = (event) => {
    const trainerName = event.target.value;
    // console.log("tname", trainerName);
    let selected_id = this.state.trainerName.filter(
      (e) => e.firstName === trainerName
    );
    // console.log(selected_id[0].id);
    this.setState({ selectedTrainerName: trainerName });
    this.fetchRole(trainerName, selected_id[0].id);
  };
  componentDidMount() {
    this.GetDepartment();
    this.GetDate();
    this.fetchItemsbyId();
    this.Team();

    var role = JSON.parse(localStorage.Role);
    if (role.toString() === "Platform Admin") {
      document.getElementById("navright").setAttribute("style", " display:;");
      document.getElementById("mainorg").setAttribute("style", "  display:;");
    }
  }
  Getallactive() {
    fetch(`${BASE_URLs}AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }

  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));
    this.props.history.push("/TrainerSchedule");
  };
  SelectManager = (e) => {
    let dept = document.getElementById("Manager").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        ManagerModel: !prevState.ManagerModel,
      }));
      document.getElementById("Manager").value = "";
    }
  };
  SelectLocation = (e) => {
    let dept = document.getElementById("Locations").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        LocationModel: !prevState.LocationModel,
      }));
      document.getElementById("Locations").value = "";
    }
  };
  SelectDepartment = (e) => {
    let dept = document.getElementById("Department").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        DepartModel: !prevState.DepartModel,
      }));

      document.getElementById("Department").value = "";
      //this.props.history.push('/Department')
    }
  };
  SelectDesignation = (e) => {
    let dept = document.getElementById("Designation").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        DesignationModel: !prevState.DesignationModel,
      }));
      document.getElementById("Designation").value = "";
    }
  };
  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    fetch(`${BASE_URLs}Trainer/EditTrainer`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        this.setState({ status: resp.status });
        this.setState({ message: resp.message });
        this.SuccessModel();
      });
    this.setState({ isBtnvisible: false });
  }
  handleChangeDate = (event) => {
    const newDateValue = event.target.value;
    this.setState({ trainerdate: newDateValue });
  };
  DepartModel = () => {
    this.setState((prevState) => ({
      DepartModel: !prevState.DepartModel,
    }));
    this.GetDepartment();
  };
  DesignationModel = () => {
    this.setState((prevState) => ({
      DesignationModel: !prevState.DesignationModel,
    }));
    this.GetDesignation();
  };
  ManagerModel = () => {
    this.setState((prevState) => ({
      ManagerModel: !prevState.ManagerModel,
    }));
    this.GetManager();
  };
  LocationModel = () => {
    this.setState((prevState) => ({
      LocationModel: !prevState.LocationModel,
    }));
    this.GetLocation();
  };
  emailundisableTxt = () => {
    document.getElementById("Department").disabled = false;
    document.getElementById("TrainerName").disabled = false;
    document.getElementById("Trainersince").disabled = false;
    this.setState({ isBtnvisible: true });
  };
  render() {
    return (
      <div>
        <SideBar active={window.location.hash} />
        {this.state.DepartModel ? (
          <AddDepartmentpop close={() => this.DepartModel()} />
        ) : null}
        {this.state.DesignationModel ? (
          <AddDesignationModel close={() => this.DesignationModel()} />
        ) : null}
        {this.state.ManagerModel ? (
          <AddManagerPop close={() => this.ManagerModel()} />
        ) : null}
        {this.state.LocationModel ? (
          <AddLocationModel close={() => this.LocationModel()} />
        ) : null}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">
                            Edit Trainer
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        {this.state.SuccessModel ? (
                          <CommonSuccess
                            id="Success"
                            message={this.state.message}
                            status={this.state.status}
                            close={() => this.SuccessModel()}
                          />
                        ) : null}
                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="row" style={{ marginTop: "3%" }}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="container">
                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        <div className="col-sm-6">
                                          <select
                                            className="effect-16"
                                            type="text"
                                            disabled="true"
                                            value={
                                              this.state.selectedDepartment
                                            }
                                            name="Department"
                                            id="Department"
                                            onChange={this.handleChange}
                                          >
                                            <option
                                              value={
                                                this.state.fetchTrainerDept
                                              }
                                            >
                                              {this.state.fetchTrainerDept}
                                            </option>

                                            {this.state.department.map(
                                              (Departmentdrop) => (
                                                <option
                                                  value={
                                                    Departmentdrop.department
                                                  }
                                                >
                                                  {Departmentdrop.department}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          <span style={{ color: "#a29d9d" }}>
                                            Department
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </span>
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          <input
                                            type="text"
                                            className="effect-16"
                                            value={
                                              this.state.trainerRole
                                                ? this.state.trainerRole
                                                : this.state.trainerIDData.role
                                            }
                                            readOnly
                                            name="Role"
                                            id="Role"
                                          />
                                          <span style={{ color: "#a29d9d" }}>
                                            Role
                                          </span>
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          <select
                                            className="effect-16"
                                            disabled="true"
                                            value={
                                              this.state.selectedTrainerName
                                            }
                                            type="text"
                                            name="TrainerName"
                                            id="TrainerName"
                                            onChange={
                                              this.handleTrainerNameChange
                                            }
                                          >
                                            <option
                                              value={
                                                this.state.fetchTrainerName
                                              }
                                            >
                                              {this.state.fetchTrainerName}
                                            </option>

                                            {/* <option value="">Select</option> */}
                                            {this.state.trainerName.map(
                                              (Departmentdrop) => (
                                                <option
                                                  value={
                                                    Departmentdrop.firstName
                                                  }
                                                >
                                                  {Departmentdrop.firstName}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          <span style={{ color: "#a29d9d" }}>
                                            Trainer Name
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </span>
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            style={{ marginTop: "-6px" }}
                                            disabled="true"
                                            name="Trainersince"
                                            id="Trainersince"
                                            type="date"
                                            onChange={this.handleChangeDate}
                                            value={this.state.trainerdate}
                                          />
                                        </div>
                                        <div className="col-sm-6">
                                          <input
                                            className="effect-16"
                                            style={{ marginTop: "-6px" }}
                                            name="Id"
                                            id="id"
                                            type="hidden"
                                            value={this.state.propsPassId}
                                          />
                                        </div>
                                        <div className="col-sm-6">
                                          <Link to="/LearningSchedule">
                                            <button
                                              type="submit"
                                              className="but "
                                              style={{
                                                height: "39.5px",
                                                width: "186.36px",
                                                marginTop: "-0.3%",
                                                marginLeft: "30%",
                                              }}
                                            >
                                              Add One More Training
                                            </button>
                                            &nbsp; &nbsp; &nbsp;
                                          </Link>
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div
                                    className="col-sm-12"
                                    style={{ textAlign: "center" }}
                                  >
                                    {this.state.isBtnvisible ? (
                                      <button
                                        type="submit"
                                        className="but "
                                        style={{
                                          height: "39.5px",
                                          width: "86.36px",
                                          marginTop: "-0.3%",
                                        }}
                                      >
                                        Update
                                      </button>
                                    ) : (
                                      <Link
                                        type="button"
                                        onClick={this.emailundisableTxt}
                                        className="but"
                                        id="EditBtn"
                                        style={{
                                          height: "39.5px",
                                          width: "86.36px",
                                          marginTop: "-0.3%",
                                          padding: "9px",
                                        }}
                                      >
                                        Edit
                                      </Link>
                                    )}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <button
                                      type="reset"
                                      className=" btn-Secondary"
                                      style={{ height: "39.5px" }}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
