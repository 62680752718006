import { ReactExcel, readFile } from '@ramonak/react-excel';
import React, { Component } from 'react';
import { OutTable } from 'react-excel-renderer';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FilterPop from '../../Common/FilterPopLearningSchedule';
import SideBar from '../../Common/Sidebar';
import { BASE_URLs } from '../../Config/Base';
 var XLSX = require('xlsx');

export default class LearningSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      currentdate: '',
      All: [],
      skills: [],
      Addskills: [],
      Id: '',
      completeddatevalue: [],
      OngoingClass: [],
      currentdatevalue: [],
      SkillName: '',
      SkillDescription: '',
      Category: '',
      Notification: [],
      Learning: [],
      Learn: [],
      rows: [],
      cols: [],
      currentSheet: {},
      category: [],
      comlogo: [],
      Skillname: [],
      Sort: [],
      Skillfilt: [],
      AddSkillModel: false,
      AddLearning: false,
      EditSkillModel: false,
      post: [],
      number: '1',
      postPerPage: '10',
      EditSkillValues: [],
      TapFilter: [],
      TapFilterValue: '',
      TapFilterIndex: '',
      Filterpopstatus: false,
      Department: [],
      Depart: [],
      toggleState: 1,
      ExportState: [],
    };

    this.initialState = this.state;
  }

  // convertTo12HourFormat(time24) {
  //   const [hour, minute] = time24.split(":");
  //   let period = "AM";
  //   let hour12 = parseInt(hour, 10);

  //   if (hour12 >= 12) {
  //     period = "PM";
  //     if (hour12 > 12) {
  //       hour12 -= 12;
  //     }
  //   }
  //

  //   //return `${hour12}:${minute} ${period}`;
  // }

  GetDepartment() {
    let url = `${BASE_URLs}Department/DepartmentGet`;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Department: data,
          Depart: data,
        });

        //
      });
  }

  handleUpload = (event) => {
    var inputElement = document.getElementById('files');
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf('.'));
    var allowedExtensionsRegx = /(\.xlsx)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);
    if (isAllowed) {
      const file = event.target.files[0];
      readFile(file).then((readedData) =>
        this.setState({ initialData: readedData })
      );
      /* file upload logic goes here... */
      return true;
    } else {
      alert('Invalid File Type.');
      return false;
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);

      fetch(`${BASE_URLs}Learning/LearningExcel`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp === undefined) {
            alert('0  Records  inserted ');
          } else if (resp === 0) {
            alert('Records added Successfully');
          } else {
            alert(+resp + ' Records not inserted ');
          }
          this.GetLearning();
          this.setState({ initialData: undefined });
        });
      var file = document.getElementById('files');
      var emptyFile = document.createElement('input');
      emptyFile.type = 'file';
      file.files = emptyFile.files;
    } catch (error) {}
    this.GetLearning();
  };

  componentDidMount() {
    this.GetLearning();
    this.toggleTab(2);
    setTimeout(() => {
      this.GetLearning();
    }, 1500);
  }
  // componentDidUpdate(){
  //
  //   this.GetLearning();

  // }

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById('LearningSheet');
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, '' + filename + '.xlsx');
  }

  onKeyUp = (e) => {
    const Learning = this.state.Learn.filter(
      (item) =>
        item.department.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.trainingitem
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.startDate.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.schedulingDuration
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );

    this.setState({ Learning });
  };

  DetailEdit(
    Id,
    department,
    startDate,
    endDate,
    trainingitem,
    // schedulingDuration,
    // trainerName,
    trainees,
    scheduleTime
  ) {
    var page = {
      pathname: '/EditSchedule',
      state: {
        Id: Id,
        department: department,
        startDate: startDate,
        endDate: endDate,
        trainingitem: trainingitem,
        // schedulingDuration: schedulingDuration,
        // trainerName: trainerName,
        trainees: trainees,
        scheduleTime: scheduleTime,
      },
    };
    this.props.history.push(page);
  }

  GetLearning() {
    let url = `${BASE_URLs}Learning/GetLearning?Department=0`;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        data =  data.map(e=>{

 
     
          
          return {
              ...e,
              StartDateTime:e.startDate.split('T')[0]+"T"+e.scheduleTime+":00",
              EndDateTime:e.startDate.split('T')[0]+"T"+e.scheduleEndTime+":00"
              
          }
          
          
          })  


        let currentDate = new Date().toJSON().slice(0, 10);

        console.log(
          'data%%%%%%%%%%%%%',
          data 
        );

        this.setState({
          ExportState: data
            .sort(
              (a, b) =>
                new Date(a.StartDateTime.slice(0, 10)) -
                new Date(b.StartDateTime.slice(0, 10))
            )
            .filter(
              (e) =>
                e.startDate.slice(0, 10) >= currentDate &&
                e.startDate.slice(0, 10) != currentDate &&
                e.scheduleTime >= '00:00'
            )
            .map((e) => {
              return {
                ...e,
                Hours: TimeMod(e.scheduleTime),
                EndHours: TimeMod(e.scheduleEndTime),

                Duration: Duration(e.schedulingDuration),
              };
            }),

          currentdatevalue: data
            .sort(
              (a, b) =>
                new Date(a.StartDateTime.slice(0, 10)) -
                new Date(b.StartDateTime.slice(0, 10))
            )
            .filter(
              (e) =>
                e.startDate.slice(0, 10) >= currentDate &&
                e.startDate.slice(0, 10) != currentDate &&
                e.scheduleTime >= '00:00'
            )
            .map((e) => {
              return {
                ...e,
                Hours: TimeMod(e.scheduleTime),
                EndHours: TimeMod(e.scheduleEndTime),

                Duration: Duration(e.schedulingDuration),
              };
            }),

          completeddatevalue: data
            .sort(
              (a, b) =>
                new Date(b.StartDateTime.slice(0, 10)) -
                new Date(a.StartDateTime.slice(0, 10))
            )
            .filter(
              (e) =>
                e.startDate.slice(0, 10) != currentDate &&
                e.startDate.slice(0, 10) <= currentDate
            )
            .map((e) => {
              return {
                ...e,
                Hours: TimeMod(e.scheduleTime),
                EndHours: TimeMod(e.scheduleEndTime),

                Duration: Duration(e.schedulingDuration),
              };
            }),

          // completedDateValue: data.filter(e => {
          //   const classStartDate = new Date(e.startDate);
          //   const classEndDate = new Date(classStartDate.getTime() + e.duration.slice(0,1) * 60 * 60 * 1000); // Convert duration to milliseconds
          //
          //

          //   return classEndDate <= new Date() && classStartDate < new Date();
          // }),

          OngoingClass: data
            .sort(
              (a, b) =>
                new Date(a.StartDateTime.slice(0, 10)) -
                new Date(b.StartDateTime.slice(0, 10))
            )
            .filter((e) => e.startDate.slice(0, 10) == currentDate)
            .map((e) => {
              return {
                ...e,
                Hours: TimeMod(e.scheduleTime),
                EndHours: TimeMod(e.scheduleEndTime),

                Duration: Duration(e.schedulingDuration),
              };
            }),
        });

        //
        //

        this.setState({
          Learning: data.sort(
            (a, b) =>
              new Date(a.StartDateTime.slice(0, 10)) -
              new Date(b.StartDateTime.slice(0, 10))
          ),
          Learn: data.sort(
            (a, b) =>
              new Date(a.StartDateTime.slice(0, 10)) -
              new Date(b.StartDateTime.slice(0, 10))
          ),
        });

        
        




        let array = data
          .filter((e) => e.trainingitem !== null)
          .map((e) => e.trainingitem.slice(0, 1));
        let uniq = [
          ...new Set(
            array.map((x) => (typeof x === 'string' ? x.toUpperCase() : x))
          ),
        ];
        this.setState({ TapFilter: uniq.sort() });

        function TimeMod(time) {
          const Hour = time.split(':')[0];
          const minutes = time.split(':')[1];
          if (Hour > 12) {
            time = Hour - 12;
            time = time < 10 ? '0' + time : time;

            return time + ':' + minutes + ' PM';
          } else if (Hour == 0) {
            return 12 + ':' + minutes + ' AM';
          } else if (Hour == 12) {
            return 12 + ':' + minutes + ' PM';
          } else {
            return time + ' AM';
          }
        }
        function Duration(duration) {
          console.log('duration------------------', duration);
          let hour = duration.split(':')[0];
          let minute = duration.split(':')[1];
          let m = String(minute);
          m === '0' ? (m = '00') : (m = minute);
          hour = parseInt(hour) < 0 ? parseInt(hour) + 24 : hour;

          if (hour == 1 && minute == '00') {
            return Number(hour) + ' Hour';
          } else if (hour == 1 && minute != '00') {
            return Number(hour) + ':' + m + ' Hours';
          } else if (hour > 1 && minute != '00') {
            return Number(hour) + ':' + m + ' Hours';
          } else if (hour > 1 && minute == '00') {
            return Number(hour) + ' Hours';
          } else if (hour == 0 && m != '00') {
            return m + ' Minutes';
          }
          //  else if (hour == 0 && m == '00') {
          //   return 'Whole day';
          // } else {
          //   return 'Whole day';
          // }
        }

        // minute=="0"?"0"+minute:minute

        function addTimeMultiple(timesToAdd, time) {
          let totalHours = time.hours * timesToAdd;
          let totalMinutes = time.minutes * timesToAdd;

          totalHours += Math.floor(totalMinutes / 60);
          totalMinutes %= 60;

          if (time.hours == '00' && totalMinutes == '00') {
            return `${timesToAdd} ${timesToAdd === 1 ? 'day' : 'days'}`;
          }
          return `${totalHours}:${totalMinutes < 10 ? '0' : ''}${
            totalMinutes == '0' ? '0' + totalMinutes : totalMinutes
          } Hours`;
        }
      });











  }
  filterSearch = (data) => {
    const { toggleState } = this.state;
    function TimeMod(time) {
      const Hour = time.split(':')[0];
      const minutes = time.split(':')[1];
      if (Hour > 12) {
        time = Hour - 12;
        time = time < 10 ? '0' + time : time;

        return time + ':' + minutes + ' PM';
      } else if (Hour == 0) {
        return 12 + ':' + minutes + ' AM';
      } else {
        return time + ' AM';
      }
    }
    function Duration(duration) {
      console.log('duration------------------', duration);
      let hour = duration.split(':')[0];
      let minute = duration.split(':')[1];
      let m = String(minute);
      m === '0' ? (m = '00') : (m = minute);
      hour = parseInt(hour) < 0 ? parseInt(hour) + 24 : hour;

      if (hour == 1 && minute == '00') {
        return Number(hour) + ' Hour';
      } else if (hour == 1 && minute != '00') {
        return Number(hour) + ':' + m + ' Hours';
      } else if (hour > 1 && minute != '00') {
        return Number(hour) + ':' + m + ' Hours';
      } else if (hour > 1 && minute == '00') {
        return Number(hour) + ' Hours';
      } else if (hour == 0 && m != '00') {
        return m + ' Minutes';
      }
      //  else if (hour == 0 && m == '00') {
      //   return 'Whole day';
      // } else {
      //   return 'Whole day';
      // }
    }

    let currentDate = new Date().toJSON().slice(0, 10);
    this.setState({
      currentdatevalue: data
        .sort(
          (a, b) =>
            new Date(a.StartDateTime.slice(0, 10)) -
            new Date(b.StartDateTime.slice(0, 10))
        )
        .filter(
          (e) =>
            e.startDate.slice(0, 10) >= currentDate &&
            e.startDate.slice(0, 10) != currentDate &&
            e.scheduleTime >= '00:00'
        )
        .map((e) => {
          return {
            ...e,
            Hours: TimeMod(e.scheduleTime),
            EndHours: TimeMod(e.scheduleEndTime),
            Duration: Duration(e.schedulingDuration),
          };
        }),
      completeddatevalue: data
        .sort(
          (a, b) =>
            new Date(b.StartDateTime.slice(0, 10)) -
            new Date(a.StartDateTime.slice(0, 10))
        )
        .filter(
          (e) =>
            e.startDate.slice(0, 10) != currentDate &&
            e.startDate.slice(0, 10) <= currentDate
        )
        .map((e) => {
          return {
            ...e,
            Hours: TimeMod(e.scheduleTime),
            EndHours: TimeMod(e.scheduleEndTime),
            Duration: Duration(e.schedulingDuration),
          };
        }),
      OngoingClass: data
        .sort(
          (a, b) =>
            new Date(a.StartDateTime.slice(0, 10)) -
            new Date(b.StartDateTime.slice(0, 10))
        )
        .filter(
          (e) =>
            e.startDate.slice(0, 10) == currentDate &&
            e.startDate.slice(0, 10) == currentDate
        )
        .map((e) => {
          return {
            ...e,
            Hours: TimeMod(e.scheduleTime),
            EndHours: TimeMod(e.scheduleEndTime),
            Duration: Duration(e.schedulingDuration),
          };
        }),
    });

    if (toggleState === 1) {
      this.setState({
        ExportState: data
          .sort(
            (a, b) =>
              new Date(a.StartDateTime.slice(0, 10)) -
              new Date(b.StartDateTime.slice(0, 10))
          )
          .filter(
            (e) =>
              e.startDate.slice(0, 10) != currentDate &&
              e.startDate.slice(0, 10) <= currentDate
          )
          .map((e) => {
            return {
              ...e,
              Hours: TimeMod(e.scheduleTime),
              EndHours: TimeMod(e.scheduleEndTime),
              Duration: Duration(e.schedulingDuration),
            };
          }),
      });
    } else if (toggleState === 3) {
      this.setState({
        ExportState: data
          .sort(
            (a, b) =>
              new Date(a.StartDateTime.slice(0, 10)) -
              new Date(b.StartDateTime.slice(0, 10))
          )
          .filter(
            (e) =>
              e.startDate.slice(0, 10) == currentDate &&
              e.startDate.slice(0, 10) == currentDate
          )
          .map((e) => {
            return {
              ...e,
              Hours: TimeMod(e.scheduleTime),
              EndHours: TimeMod(e.scheduleEndTime),
              Duration: Duration(e.schedulingDuration),
            };
          }),
      });
    } else if (toggleState === 2) {
      this.setState({
        ExportState: data
          .filter(
            (e) =>
              e.startDate.slice(0, 10) >= currentDate &&
              e.startDate.slice(0, 10) != currentDate &&
              e.scheduleTime >= '00:00'
          )
          .map((e) => {
            return {
              ...e,
              Hours: TimeMod(e.scheduleTime),
              EndHours: TimeMod(e.scheduleEndTime),
              Duration: Duration(e.schedulingDuration),
            };
          }),
      });
    }
  };

  InviteTraining = (Id) => {
    this.setState({ loading: true });
    var options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Training/InviteTraining?Id=${Id}`, options)
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === 'Error') {
          toast.error(resp.message, {
            position: 'top-center',
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ loading: false });
        } else {
          toast.success(resp.message, {
            position: 'top-center',
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({ loading: false });
        }
      });

    this.GetLearning();
  };

  TapFiltering = (v, i) => {
    // this.setState({ Learn : this.state.Learning });
    this.setState({ TapFilterValue: v });
    const Learning = this.state.Learn.filter(
      (e) => e.trainingitem !== null
    ).filter(
      (obj) => obj.trainingitem.charAt(0).toLowerCase() === v.toLowerCase()
    );
    //
    this.setState({ Learning });
  };

  toggleTab = (index) => {
    const { OngoingClass, currentdatevalue, completeddatevalue } = this.state;
    this.setState({ toggleState: index });

    if (index === 1) {
      this.setState({ ExportState: completeddatevalue });
    } else if (index === 3) {
      this.setState({ ExportState: OngoingClass });
    } else if (index === 2) {
      this.setState({ ExportState: currentdatevalue });
    }
  };

  render() {
    const { Learning, TapFilter, TapFilterValue, Filterpopstatus } = this.state;
    const { toggleState, ExportState } = this.state;

    var Entityname = JSON.parse(localStorage.entityName);
    let tab = '';
    if (toggleState === 1) {
      tab = 'Completed';
    } else if (toggleState === 3) {
      tab = 'Today';
    } else if (toggleState === 2) {
      tab = 'Upcoming';
    }

    var filename = `O_${Entityname}_${tab}_Learning`;
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {Filterpopstatus ? (
          <FilterPop
            data={this.state.Learn}
            FilteredData={(newValue) => {
              console.log('newValue', newValue.length);
              this.filterSearch(newValue);
              // this.setState({ Learning: newValue });
            }}
            TogglePop={(e) => {
              this.setState({ Filterpopstatus: !Filterpopstatus });

              // this.filterSearch(this.state.Learn);
              if (toggleState === 3) {
                // if (this.state.OngoingClass.length === 0) {
                  this.filterSearch(this.state.Learn);
                // }
              } else if (toggleState === 2) {
                // if (this.state.currentdatevalue.length === 0) {
                  this.filterSearch(this.state.Learn);
                // }
              } else if (toggleState === 1) {
                // if (this.state.completeddatevalue.length === 0) {
                  this.filterSearch(this.state.Learn);
                // }
              }

              // if (this.state.Learning.length === 0) {
              //   this.filterSearch(this.state.Learn);
              // }
              // if (this.state.Learning.length === 0) {
              //   this.setState({ Learning: this.state.Learn });
              // }

              // this.setState((prevState) => ({
              //   isPopupOpen: !prevState.Filterpopstatus,
              // }));
            }}
            tapKey={'trainingitem'}
          />
        ) : null}
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card" id="card">
                        <div className="glowBox">
                          <div style={{ float: 'left' }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                class="icofont icofont-arrow-left"
                                style={{ fontSize: '45px', color: 'white' }}
                              />
                            </a>{' '}
                          </div>
                          <h4 className="title">Training Schedule</h4>
                        </div>

                        <br />

                        <form onSubmit={this.handleSubmit}>
                          <div className="card-block">
                            <div className="row">
                              <div className="col-lg-3 col-md-5 col-sm-6 mb-2">
                                <Link
                                  to="/AddLearning"
                                  type="button"
                                  style={{
                                    width: '100%',
                                    background: 'white',
                                    margin: 0,
                                  }}
                                  id="addRow"
                                  className="btn dropdown_item-2"
                                >
                                  Schedule Training Session
                                </Link>
                              </div>
                              <div className="col-lg-4 col-md-5 col-sm-4 mb-2">
                                <button
                                  className="btn"
                                  type="button"
                                  onClick={() => this.exportXlsx(filename)}
                                >
                                  Export to excel
                                </button>
                              </div>
                              <div
                                className="col-lg-4 col-md-4 col-sm-4"
                                style={{ padding: '1%' }}
                              >
                                {Filterpopstatus ? null : (
                                  <input
                                    className="Searchbox"
                                    placeholder="Search"
                                    onClick={() => {
                                      this.setState({ Filterpopstatus: true });
                                    }}
                                    style={{ float: 'right' }}
                                  />
                                )}
                              </div>

                              {this.state.initialData !== undefined ? (
                                <ReactExcel
                                  initialData={this.state.initialData}
                                  onSheetUpdate={(currentSheet) =>
                                    this.setState({
                                      CurrentSheet: currentSheet,
                                    })
                                  }
                                  activeSheetClassName="active-sheet"
                                  reactExcelClassName="react-excel"
                                  name="LearningFile"
                                />
                              ) : null}

                              {this.state.initialData !== undefined ? (
                                <div className="savebtndiv">
                                  <button
                                    className="btn"
                                    id=""
                                    type="submit"
                                    style={{ width: '150px' }}
                                  >
                                    Save
                                  </button>
                                </div>
                              ) : null}
                              <OutTable
                                data={this.state.rows}
                                columns={this.state.cols}
                                tableClassName="ExcelTable2007"
                                tableHeaderRowclassName="heading"
                              />
                            </div>
                          </div>
                        </form>

                        <br />
                        <div className="j-tabs-container md-tabs">
                          <div style={{ marginLeft: '40px' }}>
                            <input id="tab1" type="radio" name="tabs" />
                            <label
                              id="supertab"
                              onClick={() => this.toggleTab(1)}
                              style={{ border: '1px solid lightgray' }}
                              className="j-tabs-label"
                              htmlFor="tab1"
                              title="Completed"
                            >
                              <span>&nbsp;&nbsp;Completed&nbsp;&nbsp;</span>
                            </label>

                            <input id="tab3" type="radio" name="tabs" />
                            <label
                              id="FinalTab"
                              onClick={() => this.toggleTab(3)}
                              style={{ border: '1px solid lightgray' }}
                              className="j-tabs-label"
                              htmlFor="tab3"
                              title="Today"
                            >
                              <span>&nbsp;Today&nbsp;&nbsp;</span>
                            </label>

                            <input
                              id="tab9"
                              type="radio"
                              name="tabs"
                              defaultChecked
                            />
                            <label
                              id="pendingtab"
                              onClick={() => this.toggleTab(2)}
                              style={{ border: '1px solid lightgray' }}
                              className="j-tabs-label"
                              htmlFor="tab9"
                              title="Upcoming"
                            >
                              <span>&nbsp;Upcoming &nbsp;&nbsp;</span>
                            </label>
                          </div>

                          <br />

                          <div className="contentss-tabs">
                            <div
                              className={
                                toggleState === 1
                                  ? 'contentss  active-contentss'
                                  : 'contentss'
                              }
                            >
                              {this.state.completeddatevalue.length === 0 ? (
                                <span
                                  style={{
                                    textAlign: 'center',
                                    marginLeft: '30%',
                                    marginTop: '4%',
                                  }}
                                >
                                  No records{' '}
                                </span>
                              ) : (
                                <div className="card-block2">
                                  <div>
                                    {/* ///////////////////// */}

                                    <div class="table-responsive table-alignLeft">
                                      <div className="table-header table-hr">
                                        <div
                                          class="table-row "
                                          style={{ textAlign: 'left' }}
                                        >
                                          <div class="table-cell">Date</div>
                                          <div class="table-cell">Time</div>
                                          <div class="table-cell">
                                            Training Topic
                                          </div>
                                          <div class="table-cell">
                                            Description
                                          </div>
                                          <div class="table-cell">
                                            Department
                                          </div>
                                          <div class="table-cell">Duration</div>
                                        </div>
                                      </div>
                                      <div class="table-body">
                                        {this.state.completeddatevalue.map(
                                          (Learning) => (
                                            <>
                                              <div
                                                class="table-row"
                                                style={{
                                                  textAlign: 'left',
                                                  borderBottom:
                                                    '0px solid rgba(0, 0, 0, 0.1)',
                                                }}
                                                key={Learning.training_Id}
                                              >
                                                <div
                                                  class="table-cell" //style={{ paddingLeft: '1.5%' }}
                                                >
                                                  {Learning.startDate
                                                    ? Learning.startDate.slice(
                                                        0,
                                                        10
                                                      )
                                                    : null}
                                                </div>
                                                <div
                                                  class="table-cell"
                                                  style={{
                                                    whiteSpace: 'break-spaces',
                                                  }}
                                                >
                                                  {Learning.Hours +
                                                    ' ' +
                                                    'to' +
                                                    ' ' +
                                                    Learning.EndHours}
                                                </div>
                                                <div class="table-cell">
                                                  {Learning.trainingitem
                                                    .length > 17 ? (
                                                    <div>
                                                      {Learning.trainingitem.substring(
                                                        0,
                                                        17
                                                      )}
                                                      ...
                                                    </div>
                                                  ) : (
                                                    Learning.trainingitem
                                                  )}
                                                </div>
                                                <div class="table-cell">
                                                  {Learning.trainingDescription
                                                    .length > 20 ? (
                                                    <div>
                                                      {Learning.trainingDescription.substring(
                                                        0,
                                                        20
                                                      )}
                                                      ...
                                                    </div>
                                                  ) : (
                                                    Learning.trainingDescription
                                                  )}
                                                </div>
                                                <div class="table-cell">
                                                  {Learning.department}
                                                </div>
                                                <div class="table-cell">
                                                  {Learning.Duration}
                                                </div>

                                                {/* <div class="table-cell" style={{ paddingLeft: '2.5%' }}>{Training.duration}</div> */}
                                              </div>

                                              <div
                                                class="table-row"
                                                style={{ textAlign: 'left' }}
                                                key={Learning.training_Id}
                                              >
                                                <div class="table-cell">
                                                  Trainer name
                                                </div>
                                                <div class="table-cell">
                                                  {Learning.trainerName1}
                                                </div>
                                                <div class="table-cell"></div>
                                                <div
                                                  class="table-cell"
                                                  style={{ paddingLeft: '2%' }}
                                                ></div>
                                                <div
                                                  class="table-cell"
                                                  style={{ paddingLeft: '2%' }}
                                                ></div>
                                                <div
                                                  class="table-cell"
                                                  style={{ paddingLeft: '2%' }}
                                                ></div>
                                              </div>

                                              <div
                                                className=""
                                                style={{ marginLeft: '5px' }}
                                              ></div>
                                            </>
                                          )
                                        )}
                                      </div>
                                    </div>

                                    {/*  */}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div
                              className={
                                toggleState === 2
                                  ? 'contentss  active-contentss'
                                  : 'contentss'
                              }
                            >
                              {/* /////currentdatevalue */}
                              {this.state.currentdatevalue.length === 0 ? (
                                <span
                                  style={{
                                    textAlign: 'center',
                                    marginLeft: '30%',
                                    marginTop: '4%',
                                  }}
                                >
                                  No records{' '}
                                </span>
                              ) : (
                                <div className="card-block2">
                                  <div className="sizevary">
                                    <div class="table-responsive table-alignLeft">
                                      <div className="table-header table-hr">
                                        <div
                                          class="table-row "
                                          style={{ textAlign: 'left' }}
                                        >
                                          <div class="table-cell">Date</div>
                                          <div class="table-cell">Time</div>
                                          <div class="table-cell">
                                            Training Topic
                                          </div>
                                          <div class="table-cell">
                                            Description
                                          </div>
                                          <div class="table-cell">
                                            Department
                                          </div>
                                          <div class="table-cell">Duration</div>

                                          <div class="table-cell"></div>
                                        </div>
                                      </div>

                                      {/* <div
                                        className="table-hr"
                                        style={{
                                          marginLeft: '-10px',
                                          width: '91%',
                                        }}
                                      ></div> */}
                                      <div class="table-body" id="tablebody">
                                        {this.state.currentdatevalue.map(
                                          (Learning) => (
                                            <>
                                              <div
                                                class="table-row"
                                                style={{
                                                  borderBottom:
                                                    '0px solid rgba(0, 0, 0, 0.1)',
                                                }}
                                                key={Learning.training_Id}
                                              >
                                                <div
                                                  class="table-cell" //style={{ paddingLeft: '1.5%' }}
                                                >
                                                  {Learning.startDate
                                                    ? Learning.startDate.slice(
                                                        0,
                                                        10
                                                      )
                                                    : null}
                                                </div>
                                                <div
                                                  class="table-cell"
                                                  style={{
                                                    whiteSpace: 'break-spaces',
                                                  }}
                                                >
                                                  {Learning.Hours +
                                                    ' ' +
                                                    'to' +
                                                    ' ' +
                                                    Learning.EndHours}
                                                </div>
                                                <div class="table-cell">
                                                  {Learning.trainingitem
                                                    .length > 17 ? (
                                                    <div>
                                                      {Learning.trainingitem.substring(
                                                        0,
                                                        17
                                                      )}
                                                      ...
                                                    </div>
                                                  ) : (
                                                    Learning.trainingitem
                                                  )}
                                                </div>
                                                <div class="table-cell">
                                                  {Learning.trainingDescription
                                                    .length > 18 ? (
                                                    <div>
                                                      {Learning.trainingDescription.substring(
                                                        0,
                                                        18
                                                      )}
                                                      ...
                                                    </div>
                                                  ) : (
                                                    Learning.trainingDescription
                                                  )}
                                                </div>
                                                <div class="table-cell">
                                                  {Learning.department}
                                                </div>
                                                <div class="table-cell">
                                                  {Learning.Duration}
                                                </div>

                                                {/* <div class="table-cell" style={{ paddingLeft: '2.5%' }}>{Training.duration}</div> */}

                                                <div class="table-cell">
                                                  <button
                                                    className="button-30 sk-edit"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.DetailEdit(
                                                        Learning.id,
                                                        Learning.department,
                                                        Learning.startDate,
                                                        Learning.endDate,
                                                        Learning.trainingitem,
                                                        // Learning.schedulingDuration,Assign Trainers
                                                        // Learning.trainerName,
                                                        Learning.trainees,
                                                        Learning.Hours
                                                      );
                                                    }}
                                                  >
                                                    <i
                                                      class="fa fa-info"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </button>{' '}
                                                  &nbsp;&nbsp;&nbsp;
                                                </div>
                                              </div>

                                              <div
                                                class="table-row"
                                                style={{ textAlign: 'left' }}
                                                key={Learning.training_Id}
                                              >
                                                <div class="table-cell">
                                                  Trainer name
                                                </div>
                                                <div class="table-cell">
                                                  {Learning.trainerName1}
                                                </div>
                                                <div class="table-cell"></div>
                                                <div
                                                  class="table-cell"
                                                  style={{ paddingLeft: '2%' }}
                                                ></div>
                                                <div
                                                  class="table-cell"
                                                  style={{ paddingLeft: '2%' }}
                                                ></div>
                                                <div
                                                  class="table-cell"
                                                  style={{ paddingLeft: '2%' }}
                                                ></div>

                                                <div class="table-cell"></div>
                                              </div>

                                              <div
                                                className=" "
                                                style={{
                                                  marginLeft: '5px',
                                                  width: '90%',
                                                }}
                                              ></div>
                                            </>
                                          )
                                        )}
                                      </div>
                                    </div>

                                    {/* //////////////// */}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div
                              className={
                                toggleState === 3
                                  ? 'contentss  active-contentss'
                                  : 'contentss'
                              }
                            >
                              {this.state.OngoingClass.length === 0 ? (
                                <span
                                  style={{
                                    textAlign: 'center',
                                    marginLeft: '30%',
                                    marginTop: '4%',
                                  }}
                                >
                                  No records{' '}
                                </span>
                              ) : (
                                <div className="card-block2">
                                  <div

                                  // id="LearningcheduleTable"
                                  >
                                    {/* ///OngoingClass */}

                                    <div class="table-responsive table-alignLeft">
                                      <div className="table-header table-hr">
                                        <div
                                          class="table-row"
                                          style={{ textAlign: 'left' }}
                                        >
                                          <div class="table-cell">Date</div>
                                          <div class="table-cell">Time</div>
                                          <div class="table-cell">
                                            Training Topic
                                          </div>
                                          <div class="table-cell">
                                            Description
                                          </div>
                                          <div class="table-cell">
                                            Department
                                          </div>
                                          <div class="table-cell">Duration</div>
                                        </div>
                                      </div>

                                      <div class="table-body">
                                        {this.state.OngoingClass.map(
                                          (Learning) => (
                                            <>
                                              <div
                                                class="table-row"
                                                style={{
                                                  textAlign: 'left',
                                                  borderBottom:
                                                    '0px solid rgba(0, 0, 0, 0.1)',
                                                }}
                                                key={Learning.training_Id}
                                              >
                                                <div class="table-cell">
                                                  {Learning.startDate
                                                    ? Learning.startDate.slice(
                                                        0,
                                                        10
                                                      )
                                                    : null}
                                                </div>
                                                <div
                                                  class="table-cell"
                                                  style={{
                                                    textAlign: 'left',
                                                    whiteSpace: 'break-spaces',
                                                  }}
                                                >
                                                  {Learning.Hours +
                                                    ' ' +
                                                    'to' +
                                                    ' ' +
                                                    Learning.EndHours}
                                                </div>
                                                <div
                                                  class="table-cell"
                                                  style={{ textAlign: 'left' }}
                                                >
                                                  {Learning.trainingitem
                                                    .length > 17 ? (
                                                    <div>
                                                      {Learning.trainingitem.substring(
                                                        0,
                                                        17
                                                      )}
                                                      ...
                                                    </div>
                                                  ) : (
                                                    Learning.trainingitem
                                                  )}
                                                </div>
                                                <div
                                                  class="table-cell"
                                                  style={{ textAlign: 'left' }}
                                                >
                                                  {Learning.trainingDescription
                                                    .length > 20 ? (
                                                    <div>
                                                      {Learning.trainingDescription.substring(
                                                        0,
                                                        20
                                                      )}
                                                      ...
                                                    </div>
                                                  ) : (
                                                    Learning.trainingDescription
                                                  )}
                                                </div>
                                                <div
                                                  class="table-cell"
                                                  style={{ textAlign: 'left' }}
                                                >
                                                  {Learning.department}
                                                </div>
                                                <div
                                                  class="table-cell"
                                                  style={{ textAlign: 'left' }}
                                                >
                                                  {Learning.Duration}
                                                </div>

                                                {/* <div class="table-cell" style={{ paddingLeft: '2.5%' }}>{Training.duration}</div> */}
                                              </div>

                                              <div
                                                class="table-row"
                                                style={{ textAlign: 'left' }}
                                                key={Learning.training_Id}
                                              >
                                                <div class="table-cell">
                                                  Trainer name
                                                </div>
                                                <div class="table-cell">
                                                  {Learning.trainerName1}
                                                </div>
                                                <div class="table-cell"></div>
                                                <div
                                                  class="table-cell"
                                                  style={{}}
                                                ></div>
                                                <div
                                                  class="table-cell"
                                                  style={{}}
                                                ></div>

                                                {/* <div class="table-cell" style={{ paddingLeft: '2.5%' }}>{Training.duration}</div> */}

                                                <div class="table-cell"></div>
                                              </div>

                                              <div
                                                className=" "
                                                style={{ marginLeft: '5px' }}
                                              ></div>
                                            </>
                                          )
                                        )}
                                      </div>
                                    </div>

                                    {/* //////////// */}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <table id="LearningSheet" hidden className="table nowrap">
          <thead>
            <tr>
              <th>Date</th>
              <th>Time</th>
              <th>Training Topics</th>
              <th>Description</th>
              <th>Department</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            {ExportState.map((Learning) => (
              <>
                <tr>
                  {/* <td><input type="checkbox" id="selectall" /></td> */}
                  <td>
                    {Learning.startDate
                      ? Learning.startDate.slice(0, 10)
                      : null}
                  </td>
                  <td>
                    {' '}
                    {Learning.Hours + ' ' + 'to' + ' ' + Learning.EndHours}
                  </td>
                  <td>{Learning.trainingitem}</td>

                  <td>{Learning.trainingDescription}</td>
                  <td> {Learning.department}</td>
                  <td> {Learning.Duration}</td>
                  {/* <td>{Learning.trainees}</td> */}
                </tr>

                <tr>
                  <td>Trainer name : </td>
                  <td>{Learning.trainerName1}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
