import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { BASE_URLs } from "../../Config/Base";
export default class AddOrganization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company_Id: "",
      Company_Name: "",
      aliasname: "",
      com_logo: "",
      adrs1: "",
      country: "",
      state: "",
      city: "",
      mobile: "",
      lanline: "",
      Email: "",
      SEmail: "",
      personname: "",
      Zip: "",
      Preview: null,
      GSTno: "",
      PANno: "",
      GST: "",
      Pan: "",
      formErrors: {},
      Details: [],
      comlogo: [],
      OrgEmail: [],
      value: this.props.location.state,
      Notification: [],
      Reason: "",
      EntStatus: "",
      backOptions: false,
      isSelected: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.fileInputRef = React.createRef();
  }

  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }
  HandleImage = (e) => {
    var src = URL.createObjectURL(e.target.files[0]);
    this.setState({ Preview: src });
  };
  handleFocus = (event) => event.target.select();
  handleFormValidation() {
    //const { GST,Pan } = this.state;
    var Company_Name = document.getElementById("Company_Name").value;
    var Personname = document.getElementById("personname").value;
    var Email = document.getElementById("Email").value;
    var City = document.getElementById("City").value;
    var mobile = document.getElementById("mobile").value;
    var adrs1 = document.getElementById("adrs1").value;
    var state = document.getElementById("state").value;
    var country = document.getElementById("country").value;
    var Zip = document.getElementById("Zip").value;
    var GSTno = document.getElementById("GSTno").value;
    var PANno = document.getElementById("PANno").value;
    var Profilepicinfo = document.getElementById("Profilepicinfo").value;
    var lanline = document.getElementById("lanline").value;
    var SEmail = document.getElementById("SEmail").value;

    var website = document.getElementById("website").value;
    var reg = /(.*?)\.(jpg|pdf|jpeg|png|docx)$/;
    var clogo = /(.*?)\.(jpg|jpeg|png)$/;
    var zipf = Zip.slice(0, 1);
    let formErrors = {};
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

    let formIsValid = true;

    //CompanyName name
    if (!Company_Name) {
      formIsValid = false;
      formErrors["Company_NameErr"] = "Entity Name is required";
    }
    //Alias name

    //Mobile Number
    if (!mobile) {
      formIsValid = false;
      formErrors["mobileErr"] = "Primary Mobile Number is required";
    } else {
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
      if (!mobPattern.test(mobile)) {
        formIsValid = false;
        formErrors["mobileErr"] = "Invalid Mobile Number";
      }
    }
    // SEmail
    if (lanline.trim()) {
      if (!mobPattern.test(lanline)) {
        formIsValid = false;
        formErrors["lanlineErr"] = "Invalid Mobile Number";
      }
    }
    // SEmail

    if (SEmail.trim()) {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(SEmail)) {
        formIsValid = false;
        formErrors["SEmailErr"] = "Invalid Email Id";
      }
    }

    /*  if (!lanline) {    
      formIsValid = false;    
      formErrors["lanlineErr"] = "lanline is required" ;    
  } */
    //Email
    if (!Email) {
      formIsValid = false;
      formErrors["EmailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
      formErrors["EmailErr"] = "Invalid email id";
    }

    //contact person
    if (!Personname) {
      formIsValid = false;
      formErrors["personnameErr"] = "Contact Person is required";
    } else if (Personname.length < 3) {
      formIsValid = false;
      formErrors["personnameErr"] = "Minimum 3 characters required";
    }
    //addres
    if (!adrs1) {
      formIsValid = false;
      formErrors["adrs1Err"] = "Address is required";
    } else if (adrs1.length < 3) {
      formIsValid = false;
      formErrors["adrs1Err"] = "Minimum 3 characters required";
    }
    //country
    if (!country) {
      formIsValid = false;
      formErrors["countryErr"] = "Select country";
    } else if (country.length < 3) {
      formIsValid = false;
      formErrors["countryErr"] = "Minimum 3 characters required";
    }
    //state
    if (!state) {
      formIsValid = false;
      formErrors["stateErr"] = "State is required";
    } else if (state.length < 3) {
      formIsValid = false;
      formErrors["stateErr"] = "Minimum 3 characters required";
    }
    //city
    if (!City) {
      formIsValid = false;
      formErrors["cityErr"] = "City is required";
    } else if (City.length < 3) {
      formIsValid = false;
      formErrors["cityErr"] = "Minimum 3 characters required";
    }

    //Pincode
    else if (!Zip || Zip === " ") {
      formIsValid = false;
      formErrors["ZipErr"] = "Pincode is required";
    } else if (zipf === "0") {
      formIsValid = false;
      formErrors["ZipErr"] = "Pincode should not start with 0";
    } else if (Zip.length < 6) {
      formIsValid = false;
      formErrors["ZipErr"] = "Minimum 6 is required";
    }
    if (!GSTno) {
      formIsValid = false;
      formErrors["GSTnoErr"] = "GST Number is required";
    }
    if (!PANno) {
      formIsValid = false;
      formErrors["PANnoErr"] = "PAN Number is required";
    }
    if (!website || website === " ") {
      formIsValid = false;
      formErrors["websiteErr"] = "Official website is required";
    }
    // else if (!/^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/.test(website)) {
    //   formIsValid = false;
    //   formErrors["websiteErr"] = "In valid website";
    // }
    if (!Profilepicinfo === false) {
      if (!Profilepicinfo.match(clogo)) {
        formIsValid = false;
        formErrors["Profilepicerr"] = "only jpeg,png format is allowded";
      }
    }
    if (document.getElementById("GST") !== null) {
      if (!document.getElementById("GST").value) {
        formIsValid = false;
        formErrors["GSTErr"] = "GST is required";
      }
    }
    if (document.getElementById("Pan") !== null) {
      if (!document.getElementById("Pan").value) {
        formIsValid = false;
        formErrors["PanErr"] = "PAN Copy is required";
      }
    }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleImageChange(e) {
    e.preventDefault();
    this.setState({ imagePreviewUrl: URL.createObjectURL(e.target.files[0]) });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleFormValidation()) {
      const data = new FormData(event.target);

      fetch(`${BASE_URLs}AddOrganization/EditOrg`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          mode: "no-cors",
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            // document.getElementById("success-msg").innerHTML = resp.message;

            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setTimeout(() => {
              this.props.history.push("/Organization");
            }, 4000);
          }
        });

      this.setState({ imagePreviewUrl: null });
    }
  }

  GetStatusOne() {
    var Id = this.props.location.state.Id;
    let url = `${BASE_URLs}AddOrganization/detail?company_Id=` + Id;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Reason: data[0].reason,
          EntStatus: data[0].status,
        });
        this.setState({
          Details: data,
        });
      });
  }

  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });
        //
      });
  }

  GetOrgEmail() {
    let url = `${BASE_URLs}AddOrganization/GetorgEmail`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ OrgEmail: data });
      });
  }

  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push("./Organization");
    } else {
      this.GetStatusOne();
    }
    this.GetOrgLogo();
    this.GetOrgEmail();
  }

  backFunctionality = () => {
    
    document.getElementById('Profilepicinfo').value = ''; // Reset input file field
    this.setState({ Preview: null });

    if (this.state.backOptions === true) {
      // console.log("Hai");
      this.setState({
        backOptions: false,
      });
    } else {
      this.props.history.push("/Organization");
    }
  };
  handleredirect = (event) => {
    event.preventDefault();

    alert("Successfully registered");

    this.props.history.push("/Organization");
  };
  handleChange = (e) => {
    this.setState({
      backOptions: true,
    });
    const { name, value } = e.target;
    this.setState({ [name]: value });

    var country = document.getElementById("country").value;
    if (country == "India") {
      // alert(country);

      this.setState({
        isSelected: true,
      });
    } else if (country != "India") {
      this.setState({
        isSelected: false,
      });
    }

  };

  handleIconClick = () => {
 
    this.fileInputRef.current.click();
  };

  render() {
    const { Details } = this.state;
    const {
      Company_NameErr,
      Profilepicerr,
      mobileErr,
      lanlineErr,
      SEmailErr,
      personnameErr,
      adrs1Err,
      countryErr,
      stateErr,
      cityErr,
      ZipErr,
      GSTnoErr,
      PANnoErr,
      GSTErr,
      PanErr,
      websiteErr,
    } = this.state.formErrors;
    return (
      <div>
        <SideBar active={window.location.hash} />
        {/* start */}

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <Link to="/Organization">
                              {" "}
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </Link>{" "}
                          </div>
                          <h4 style={{ marginRight: "4%" }} className="title">
                            {" "}
                            Entity Profile
                          </h4>
                        </div>
                        <div className="card-header-right">
                          <ToastContainer
                            id="toast"
                            position="top-center"
                            autoClose={1500}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          >
                            <Link to="">Yes</Link>
                          </ToastContainer>
                        </div>
                        <div className="card-block2" >
                          <form onSubmit={this.handleSubmit}>
                            <div className="row" style={{marginTop:"10px"}}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <table className="table m-0">
                                      <tbody>
                                        <div className="row">
                                          <div className="col-sm-3"></div>
                                          {Details.map((Details) => (
                                            <div
                                              className="col-sm-6 clogo"
                                              style={{
                                                flexDirection: "column",
                                              }}
                                            >
                                              <label
                                                style={{ height: "105px" }}
                                              >
                                                <input
                                                  type="hidden"
                                                  name="OldCom_logo"
                                                  value={Details.com_logo}
                                                />
                                                <img
                                                  alt="venturelogo"
                                                  src={
                                                    !this.state.Preview
                                                      ? Details.com_logo
                                                      : this.state.Preview
                                                  }
                                                  id="clogo"
                                                  style={{
                                                    height: "100px",
                                                    width: "100px",
                                                    marginLeft: "4.6%",
                                                    borderRadius: "50px",
                                                  }}
                                                /> </label>
                                                <center>
                                                <i
                                                  className=" fa fa-pencil editbtn"
                                                  aria-hidden="true"
                                                  style={{ fontSize: "20px",  marginLeft: "-160%",
                                                  paddingTop: "8px",
                                                        paddingLeft: "4px",
                                                        marginTop:'-15px'
                                                   }}
                                                  onClick={this.handleIconClick}
                                                ></i>
                                                <input
                                                  htmlFor="clogo"
                                                  onChange={this.HandleImage}
                                                  ref={this.fileInputRef}
                                                  style={{ display: "none" }}
                                                  id="Profilepicinfo"
                                                  name="Com_logo"
                                                  type="file"
                                                />
                                             </center>
                                              <span
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  // marginLeft: "-20px",
                                                  color: "#a7a2a2",
                                                  marginTop:'-30px'
                                                }}
                                              >
                                                Entity Logo
                                              </span>

                                              {!Profilepicerr ? (
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  {" "}
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {Profilepicerr}
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                          <div className="col-sm-3"></div>
                                        </div>
                                        <div
                                          className="row"
                                          style={{
                                            paddingBottom: "2%",
                                            display: "none",
                                          }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <input
                                                className="effect-16"
                                                value={Details.company_Name}
                                                id="Company_Name"
                                                name="Company_Name"
                                                readOnly
                                                onChange={this.handleChange}
                                                type="text"
                                                placeholder="Entity Name"
                                              />
                                            ))}
                                            {!Company_NameErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Entity Name
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {Company_NameErr}
                                              </div>
                                            )}
                                          </div>

                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <input
                                                className="effect-16"
                                                id="personname"
                                                name="personname"
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                Value={Details.personname}
                                                maxLength="50"
                                                onChange={this.handleChange}
                                                type="text"
                                                placeholder="Contact Person name"
                                              />
                                            ))}
                                            {!personnameErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Contact Person{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {personnameErr}
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{
                                            paddingBottom: "2%",
                                            display: "none",
                                          }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <input
                                                className="effect-16"
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                type="text"
                                                id="Email"
                                                disabled
                                                name="Email"
                                                Value={Details.email}
                                                onChange={this.handleChange}
                                                placeholder="Primary Email "
                                              />
                                            ))}
                                            <span style={{ color: "#a29d9d" }}>
                                              Email
                                            </span>
                                          </div>

                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <input
                                                className="effect-16"
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                maxLength="10"
                                                id="mobile"
                                                name="mobile"
                                                onChange={this.handleChange}
                                                Value={Details.mobile}
                                                type="text"
                                                placeholder="Primary Mobile Number"
                                              />
                                            ))}
                                            {!mobileErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Primary Mobile Number
                                              </span>
                                            ) : (
                                              <span
                                                style={{ color: "red" }}
                                                className={
                                                  mobileErr ? " showError" : ""
                                                }
                                              >
                                                {mobileErr}
                                              </span>
                                            )}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{
                                            paddingBottom: "2%",
                                            display: "none",
                                          }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <input
                                                className="effect-16"
                                                maxLength="10"
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                id="lanline"
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                name="lanline"
                                                Value={Details.lanline}
                                                onChange={this.handleChange}
                                                type="text"
                                                placeholder="Alternate Mobile number"
                                              />
                                            ))}
                                            <span
                                              className={
                                                lanlineErr ? " showError" : ""
                                              }
                                            ></span>
                                            {!lanlineErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Alternate Mobile Number
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {lanlineErr}
                                              </div>
                                            )}
                                          </div>

                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <input
                                                className="effect-16"
                                                type="text"
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                name="SEmail"
                                                maxLength="50"
                                                id="SEmail"
                                                Value={Details.sEmail}
                                                onChange={this.handleChange}
                                                placeholder="Secondary Email "
                                              />
                                            ))}
                                            {!SEmailErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Secondary Email{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {" "}
                                                {SEmailErr}
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ paddingBottom: "2%" }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <input
                                                className="effect-16"
                                                type="text"
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                Value={Details.adrs1}
                                                id="adrs1"
                                                name="adrs1"
                                                onChange={this.handleChange}
                                                placeholder="Address "
                                              />
                                            ))}

                                            {!adrs1Err ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Address{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {adrs1Err}
                                              </div>
                                            )}
                                          </div>

                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <input
                                                className="effect-16"
                                                id="City"
                                                name="city"
                                                onInputCapture={this.AutoCaps}
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[a-zA-Z\s ]+/.test(
                                                      event.key
                                                    )
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                maxLength="50"
                                                Value={Details.city}
                                                onChange={this.handleChange}
                                                type="text"
                                                placeholder="City"
                                              />
                                            ))}
                                            {!cityErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                City{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {cityErr}
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ paddingBottom: "2%" }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <>
                                              {this.state.isSelected ? (
                                                <select
                                                  id="state"
                                                  name="state"
                                                  className="effect-16"
                                                  onChange={(e) => {
                                                    this.handleChange(e);
                                                    // this.AutoCaps(e);
                                                  }}
                                                  // onFocus={this.handleFocus}
                                                  defaultValue={Details.state}
                                                  onKeyPress={(event) => {
                                                    if (
                                                      !/[a-zA-Z\s ]+/.test(
                                                        event.key
                                                      )
                                                    ) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                >
                                                  <option value="">
                                                    Select state
                                                  </option>
                                                  <option value="Andaman and Nicobar Islands">
                                                    Andaman and Nicobar
                                                    Islands
                                                  </option>
                                                  <option value="Andhra Pradesh">
                                                    Andhra Pradesh
                                                  </option>
                                                  <option value="Arunachal Pradesh">
                                                    Arunachal Pradesh
                                                  </option>
                                                  <option value="Assam">
                                                    Assam
                                                  </option>
                                                  <option value="Bihar">
                                                    Bihar
                                                  </option>
                                                  <option value="Chandigarh">
                                                    Chandigarh
                                                  </option>
                                                  <option value="Chhattisgarh">
                                                    Chhattisgarh
                                                  </option>
                                                  <option value="Dadra and Nagar Haveli">
                                                    Dadra and Nagar Haveli
                                                  </option>
                                                  <option value="Daman and Diu">
                                                    Daman and Diu
                                                  </option>
                                                  <option value="Delhi">
                                                    Delhi
                                                  </option>
                                                  <option value="Goa">
                                                    Goa
                                                  </option>
                                                  <option value="Gujarat">
                                                    Gujarat
                                                  </option>
                                                  <option value="Haryana">
                                                    Haryana
                                                  </option>
                                                  <option value="Himachal Pradesh">
                                                    Himachal Pradesh
                                                  </option>
                                                  <option value="Jammu and Kashmir">
                                                    Jammu and Kashmir
                                                  </option>
                                                  <option value="Jharkhand">
                                                    Jharkhand
                                                  </option>
                                                  <option value="Karnataka">
                                                    Karnataka
                                                  </option>
                                                  <option value="Kerala">
                                                    Kerala
                                                  </option>
                                                  <option value="Ladakh">
                                                    Ladakh
                                                  </option>
                                                  <option value="Lakshadweep">
                                                    Lakshadweep
                                                  </option>
                                                  <option value="Madhya Pradesh">
                                                    Madhya Pradesh
                                                  </option>
                                                  <option value="Maharashtra">
                                                    Maharashtra
                                                  </option>
                                                  <option value="Manipur">
                                                    Manipur
                                                  </option>
                                                  <option value="Meghalaya">
                                                    Meghalaya
                                                  </option>
                                                  <option value="Mizoram">
                                                    Mizoram
                                                  </option>
                                                  <option value="Nagaland">
                                                    Nagaland
                                                  </option>
                                                  <option value="Odisha">
                                                    Odisha
                                                  </option>
                                                  <option value="Puducherry">
                                                    Puducherry
                                                  </option>
                                                  <option value="Punjab">
                                                    Punjab
                                                  </option>
                                                  <option value="Rajasthan">
                                                    Rajasthan
                                                  </option>
                                                  <option value="Sikkim">
                                                    Sikkim
                                                  </option>
                                                  <option value="Tamil Nadu">
                                                    Tamil Nadu
                                                  </option>
                                                  <option value="Telangana">
                                                    Telangana
                                                  </option>
                                                  <option value="Tripura">
                                                    Tripura
                                                  </option>
                                                  <option value="Uttar Pradesh">
                                                    Uttar Pradesh
                                                  </option>
                                                  <option value="Uttarakhand">
                                                    Uttarakhand
                                                  </option>
                                                  <option value="West Bengal">
                                                    West Bengal
                                                  </option>
                                                </select>
                                              ) : (
                                              <input
                                                className="effect-16"
                                                onChange={(e) => {
                                                  this.handleChange(e);
                                                  this.AutoCaps(e);
                                                }}
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[a-zA-Z\s ]+/.test(
                                                      event.key
                                                    )
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onFocus={this.handleFocus}
                                                type="text"
                                                id="state"
                                                name="state"
                                                maxLength="50"
                                                style={{ color: "#736e6e" }}
                                                defaultValue={Details.state}
                                                
                                                placeholder="State"
                                              />
                                              )}
                                              </>
                                            ))}

                                            <span
                                              className={
                                                stateErr ? " showError" : ""
                                              }
                                            ></span>
                                            {!stateErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                State{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {stateErr}
                                              </div>
                                            )}
                                          </div>

                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <select
                                                className="effect-16"
                                                name="country"
                                                id="country"
                                                style={{ color: "#736e6e" }}
                                                defaultValue={Details.country}
                                                onChange={this.handleChange}
                                                type="text"
                                              >
                                                <option value={Details.country}>
                                                  {Details.country}
                                                </option>
                                                <option value="Afghanistan">
                                                  Afghanistan
                                                </option>
                                                <option value="Åland Islands">
                                                  Åland Islands
                                                </option>
                                                <option value="Albania">
                                                  Albania
                                                </option>
                                                <option value="Algeria">
                                                  Algeria
                                                </option>
                                                <option value="American Samoa">
                                                  American Samoa
                                                </option>
                                                <option value="Andorra">
                                                  Andorra
                                                </option>
                                                <option value="Angola">
                                                  Angola
                                                </option>
                                                <option value="Anguilla">
                                                  Anguilla
                                                </option>
                                                <option value="Antarctica">
                                                  Antarctica
                                                </option>
                                                <option value="Antigua and Barbuda">
                                                  Antigua and Barbuda
                                                </option>
                                                <option value="Argentina">
                                                  Argentina
                                                </option>
                                                <option value="Armenia">
                                                  Armenia
                                                </option>
                                                <option value="Aruba">
                                                  Aruba
                                                </option>
                                                <option value="Australia">
                                                  Australia
                                                </option>
                                                <option value="Austria">
                                                  Austria
                                                </option>
                                                <option value="Azerbaijan">
                                                  Azerbaijan
                                                </option>
                                                <option value="Bahamas">
                                                  Bahamas
                                                </option>
                                                <option value="Bahrain">
                                                  Bahrain
                                                </option>
                                                <option value="Bangladesh">
                                                  Bangladesh
                                                </option>
                                                <option value="Barbados">
                                                  Barbados
                                                </option>
                                                <option value="Belarus">
                                                  Belarus
                                                </option>
                                                <option value="Belgium">
                                                  Belgium
                                                </option>
                                                <option value="Belize">
                                                  Belize
                                                </option>
                                                <option value="Benin">
                                                  Benin
                                                </option>
                                                <option value="Bermuda">
                                                  Bermuda
                                                </option>
                                                <option value="Bhutan">
                                                  Bhutan
                                                </option>
                                                <option value="Bolivia">
                                                  Bolivia
                                                </option>
                                                <option value="Bosnia and Herzegovina">
                                                  Bosnia and Herzegovina
                                                </option>
                                                <option value="Botswana">
                                                  Botswana
                                                </option>
                                                <option value="Bouvet Island">
                                                  Bouvet Island
                                                </option>
                                                <option value="Brazil">
                                                  Brazil
                                                </option>
                                                <option value="British Indian Ocean Territory">
                                                  British Indian Ocean Territory
                                                </option>
                                                <option value="Brunei Darussalam">
                                                  Brunei Darussalam
                                                </option>
                                                <option value="Bulgaria">
                                                  Bulgaria
                                                </option>
                                                <option value="Burkina Faso">
                                                  Burkina Faso
                                                </option>
                                                <option value="Burundi">
                                                  Burundi
                                                </option>
                                                <option value="Cambodia">
                                                  Cambodia
                                                </option>
                                                <option value="Cameroon">
                                                  Cameroon
                                                </option>
                                                <option value="Canada">
                                                  Canada
                                                </option>
                                                <option value="Cape Verde">
                                                  Cape Verde
                                                </option>
                                                <option value="Cayman Islands">
                                                  Cayman Islands
                                                </option>
                                                <option value="Central African Republic">
                                                  Central African Republic
                                                </option>
                                                <option value="Chad">
                                                  Chad
                                                </option>
                                                <option value="Chile">
                                                  Chile
                                                </option>
                                                <option value="China">
                                                  China
                                                </option>
                                                <option value="Christmas Island">
                                                  Christmas Island
                                                </option>
                                                <option value="Cocos (Keeling) Islands">
                                                  Cocos (Keeling) Islands
                                                </option>
                                                <option value="Colombia">
                                                  Colombia
                                                </option>
                                                <option value="Comoros">
                                                  Comoros
                                                </option>
                                                <option value="Congo">
                                                  Congo
                                                </option>
                                                <option value="Congo, The Democratic Republic of The">
                                                  Congo, The Democratic Republic
                                                  of The
                                                </option>
                                                <option value="Cook Islands">
                                                  Cook Islands
                                                </option>
                                                <option value="Costa Rica">
                                                  Costa Rica
                                                </option>
                                                <option value="Cote D'ivoire">
                                                  Cote D'ivoire
                                                </option>
                                                <option value="Croatia">
                                                  Croatia
                                                </option>
                                                <option value="Cuba">
                                                  Cuba
                                                </option>
                                                <option value="Cyprus">
                                                  Cyprus
                                                </option>
                                                <option value="Czech Republic">
                                                  Czech Republic
                                                </option>
                                                <option value="Denmark">
                                                  Denmark
                                                </option>
                                                <option value="Djibouti">
                                                  Djibouti
                                                </option>
                                                <option value="Dominica">
                                                  Dominica
                                                </option>
                                                <option value="Dominican Republic">
                                                  Dominican Republic
                                                </option>
                                                <option value="Ecuador">
                                                  Ecuador
                                                </option>
                                                <option value="Egypt">
                                                  Egypt
                                                </option>
                                                <option value="El Salvador">
                                                  El Salvador
                                                </option>
                                                <option value="Equatorial Guinea">
                                                  Equatorial Guinea
                                                </option>
                                                <option value="Eritrea">
                                                  Eritrea
                                                </option>
                                                <option value="Estonia">
                                                  Estonia
                                                </option>
                                                <option value="Ethiopia">
                                                  Ethiopia
                                                </option>
                                                <option value="Falkland Islands (Malvinas)">
                                                  Falkland Islands (Malvinas)
                                                </option>
                                                <option value="Faroe Islands">
                                                  Faroe Islands
                                                </option>
                                                <option value="Fiji">
                                                  Fiji
                                                </option>
                                                <option value="Finland">
                                                  Finland
                                                </option>
                                                <option value="France">
                                                  France
                                                </option>
                                                <option value="French Guiana">
                                                  French Guiana
                                                </option>
                                                <option value="French Polynesia">
                                                  French Polynesia
                                                </option>
                                                <option value="French Southern Territories">
                                                  French Southern Territories
                                                </option>
                                                <option value="Gabon">
                                                  Gabon
                                                </option>
                                                <option value="Gambia">
                                                  Gambia
                                                </option>
                                                <option value="Georgia">
                                                  Georgia
                                                </option>
                                                <option value="Germany">
                                                  Germany
                                                </option>
                                                <option value="Ghana">
                                                  Ghana
                                                </option>
                                                <option value="Gibraltar">
                                                  Gibraltar
                                                </option>
                                                <option value="Greece">
                                                  Greece
                                                </option>
                                                <option value="Greenland">
                                                  Greenland
                                                </option>
                                                <option value="Grenada">
                                                  Grenada
                                                </option>
                                                <option value="Guadeloupe">
                                                  Guadeloupe
                                                </option>
                                                <option value="Guam">
                                                  Guam
                                                </option>
                                                <option value="Guatemala">
                                                  Guatemala
                                                </option>
                                                <option value="Guernsey">
                                                  Guernsey
                                                </option>
                                                <option value="Guinea">
                                                  Guinea
                                                </option>
                                                <option value="Guinea-bissau">
                                                  Guinea-bissau
                                                </option>
                                                <option value="Guyana">
                                                  Guyana
                                                </option>
                                                <option value="Haiti">
                                                  Haiti
                                                </option>
                                                <option value="Heard Island and Mcdonald Islands">
                                                  Heard Island and Mcdonald
                                                  Islands
                                                </option>
                                                <option value="Holy See (Vatican City State)">
                                                  Holy See (Vatican City State)
                                                </option>
                                                <option value="Honduras">
                                                  Honduras
                                                </option>
                                                <option value="Hong Kong">
                                                  Hong Kong
                                                </option>
                                                <option value="Hungary">
                                                  Hungary
                                                </option>
                                                <option value="Iceland">
                                                  Iceland
                                                </option>
                                                <option value="India">
                                                  India
                                                </option>
                                                <option value="Indonesia">
                                                  Indonesia
                                                </option>
                                                <option value="Iran, Islamic Republic of">
                                                  Iran, Islamic Republic of
                                                </option>
                                                <option value="Iraq">
                                                  Iraq
                                                </option>
                                                <option value="Ireland">
                                                  Ireland
                                                </option>
                                                <option value="Isle of Man">
                                                  Isle of Man
                                                </option>
                                                <option value="Israel">
                                                  Israel
                                                </option>
                                                <option value="Italy">
                                                  Italy
                                                </option>
                                                <option value="Jamaica">
                                                  Jamaica
                                                </option>
                                                <option value="Japan">
                                                  Japan
                                                </option>
                                                <option value="Jersey">
                                                  Jersey
                                                </option>
                                                <option value="Jordan">
                                                  Jordan
                                                </option>
                                                <option value="Kazakhstan">
                                                  Kazakhstan
                                                </option>
                                                <option value="Kenya">
                                                  Kenya
                                                </option>
                                                <option value="Kiribati">
                                                  Kiribati
                                                </option>
                                                <option value="Korea, Democratic People's Republic of">
                                                  Korea, Democratic People's
                                                  Republic of
                                                </option>
                                                <option value="Korea, Republic of">
                                                  Korea, Republic of
                                                </option>
                                                <option value="Kuwait">
                                                  Kuwait
                                                </option>
                                                <option value="Kyrgyzstan">
                                                  Kyrgyzstan
                                                </option>
                                                <option value="Lao People's Democratic Republic">
                                                  Lao People's Democratic
                                                  Republic
                                                </option>
                                                <option value="Latvia">
                                                  Latvia
                                                </option>
                                                <option value="Lebanon">
                                                  Lebanon
                                                </option>
                                                <option value="Lesotho">
                                                  Lesotho
                                                </option>
                                                <option value="Liberia">
                                                  Liberia
                                                </option>
                                                <option value="Libyan Arab Jamahiriya">
                                                  Libyan Arab Jamahiriya
                                                </option>
                                                <option value="Liechtenstein">
                                                  Liechtenstein
                                                </option>
                                                <option value="Lithuania">
                                                  Lithuania
                                                </option>
                                                <option value="Luxembourg">
                                                  Luxembourg
                                                </option>
                                                <option value="Macao">
                                                  Macao
                                                </option>
                                                <option value="Macedonia, The Former Yugoslav Republic of">
                                                  Macedonia, The Former Yugoslav
                                                  Republic of
                                                </option>
                                                <option value="Madagascar">
                                                  Madagascar
                                                </option>
                                                <option value="Malawi">
                                                  Malawi
                                                </option>
                                                <option value="Malaysia">
                                                  Malaysia
                                                </option>
                                                <option value="Maldives">
                                                  Maldives
                                                </option>
                                                <option value="Mali">
                                                  Mali
                                                </option>
                                                <option value="Malta">
                                                  Malta
                                                </option>
                                                <option value="Marshall Islands">
                                                  Marshall Islands
                                                </option>
                                                <option value="Martinique">
                                                  Martinique
                                                </option>
                                                <option value="Mauritania">
                                                  Mauritania
                                                </option>
                                                <option value="Mauritius">
                                                  Mauritius
                                                </option>
                                                <option value="Mayotte">
                                                  Mayotte
                                                </option>
                                                <option value="Mexico">
                                                  Mexico
                                                </option>
                                                <option value="Micronesia, Federated States of">
                                                  Micronesia, Federated States
                                                  of
                                                </option>
                                                <option value="Moldova, Republic of">
                                                  Moldova, Republic of
                                                </option>
                                                <option value="Monaco">
                                                  Monaco
                                                </option>
                                                <option value="Mongolia">
                                                  Mongolia
                                                </option>
                                                <option value="Montenegro">
                                                  Montenegro
                                                </option>
                                                <option value="Montserrat">
                                                  Montserrat
                                                </option>
                                                <option value="Morocco">
                                                  Morocco
                                                </option>
                                                <option value="Mozambique">
                                                  Mozambique
                                                </option>
                                                <option value="Myanmar">
                                                  Myanmar
                                                </option>
                                                <option value="Namibia">
                                                  Namibia
                                                </option>
                                                <option value="Nauru">
                                                  Nauru
                                                </option>
                                                <option value="Nepal">
                                                  Nepal
                                                </option>
                                                <option value="Netherlands">
                                                  Netherlands
                                                </option>
                                                <option value="Netherlands Antilles">
                                                  Netherlands Antilles
                                                </option>
                                                <option value="New Caledonia">
                                                  New Caledonia
                                                </option>
                                                <option value="New Zealand">
                                                  New Zealand
                                                </option>
                                                <option value="Nicaragua">
                                                  Nicaragua
                                                </option>
                                                <option value="Niger">
                                                  Niger
                                                </option>
                                                <option value="Nigeria">
                                                  Nigeria
                                                </option>
                                                <option value="Niue">
                                                  Niue
                                                </option>
                                                <option value="Norfolk Island">
                                                  Norfolk Island
                                                </option>
                                                <option value="Northern Mariana Islands">
                                                  Northern Mariana Islands
                                                </option>
                                                <option value="Norway">
                                                  Norway
                                                </option>
                                                <option value="Oman">
                                                  Oman
                                                </option>
                                                <option value="Pakistan">
                                                  Pakistan
                                                </option>
                                                <option value="Palau">
                                                  Palau
                                                </option>
                                                <option value="Palestinian Territory, Occupied">
                                                  Palestinian Territory,
                                                  Occupied
                                                </option>
                                                <option value="Panama">
                                                  Panama
                                                </option>
                                                <option value="Papua New Guinea">
                                                  Papua New Guinea
                                                </option>
                                                <option value="Paraguay">
                                                  Paraguay
                                                </option>
                                                <option value="Peru">
                                                  Peru
                                                </option>
                                                <option value="Philippines">
                                                  Philippines
                                                </option>
                                                <option value="Pitcairn">
                                                  Pitcairn
                                                </option>
                                                <option value="Poland">
                                                  Poland
                                                </option>
                                                <option value="Portugal">
                                                  Portugal
                                                </option>
                                                <option value="Puerto Rico">
                                                  Puerto Rico
                                                </option>
                                                <option value="Qatar">
                                                  Qatar
                                                </option>
                                                <option value="Reunion">
                                                  Reunion
                                                </option>
                                                <option value="Romania">
                                                  Romania
                                                </option>
                                                <option value="Russian Federation">
                                                  Russian Federation
                                                </option>
                                                <option value="Rwanda">
                                                  Rwanda
                                                </option>
                                                <option value="Saint Helena">
                                                  Saint Helena
                                                </option>
                                                <option value="Saint Kitts and Nevis">
                                                  Saint Kitts and Nevis
                                                </option>
                                                <option value="Saint Lucia">
                                                  Saint Lucia
                                                </option>
                                                <option value="Saint Pierre and Miquelon">
                                                  Saint Pierre and Miquelon
                                                </option>
                                                <option value="Saint Vincent and The Grenadines">
                                                  Saint Vincent and The
                                                  Grenadines
                                                </option>
                                                <option value="Samoa">
                                                  Samoa
                                                </option>
                                                <option value="San Marino">
                                                  San Marino
                                                </option>
                                                <option value="Sao Tome and Principe">
                                                  Sao Tome and Principe
                                                </option>
                                                <option value="Saudi Arabia">
                                                  Saudi Arabia
                                                </option>
                                                <option value="Senegal">
                                                  Senegal
                                                </option>
                                                <option value="Serbia">
                                                  Serbia
                                                </option>
                                                <option value="Seychelles">
                                                  Seychelles
                                                </option>
                                                <option value="Sierra Leone">
                                                  Sierra Leone
                                                </option>
                                                <option value="Singapore">
                                                  Singapore
                                                </option>
                                                <option value="Slovakia">
                                                  Slovakia
                                                </option>
                                                <option value="Slovenia">
                                                  Slovenia
                                                </option>
                                                <option value="Solomon Islands">
                                                  Solomon Islands
                                                </option>
                                                <option value="Somalia">
                                                  Somalia
                                                </option>
                                                <option value="South Africa">
                                                  South Africa
                                                </option>
                                                <option value="South Georgia and The South Sandwich Islands">
                                                  South Georgia and The South
                                                  Sandwich Islands
                                                </option>
                                                <option value="Spain">
                                                  Spain
                                                </option>
                                                <option value="Sri Lanka">
                                                  Sri Lanka
                                                </option>
                                                <option value="Sudan">
                                                  Sudan
                                                </option>
                                                <option value="Suriname">
                                                  Suriname
                                                </option>
                                                <option value="Svalbard and Jan Mayen">
                                                  Svalbard and Jan Mayen
                                                </option>
                                                <option value="Swaziland">
                                                  Swaziland
                                                </option>
                                                <option value="Sweden">
                                                  Sweden
                                                </option>
                                                <option value="Switzerland">
                                                  Switzerland
                                                </option>
                                                <option value="Syrian Arab Republic">
                                                  Syrian Arab Republic
                                                </option>
                                                <option value="Taiwan, Province of China">
                                                  Taiwan, Province of China
                                                </option>
                                                <option value="Tajikistan">
                                                  Tajikistan
                                                </option>
                                                <option value="Tanzania, United Republic of">
                                                  Tanzania, United Republic of
                                                </option>
                                                <option value="Thailand">
                                                  Thailand
                                                </option>
                                                <option value="Timor-leste">
                                                  Timor-leste
                                                </option>
                                                <option value="Togo">
                                                  Togo
                                                </option>
                                                <option value="Tokelau">
                                                  Tokelau
                                                </option>
                                                <option value="Tonga">
                                                  Tonga
                                                </option>
                                                <option value="Trinidad and Tobago">
                                                  Trinidad and Tobago
                                                </option>
                                                <option value="Tunisia">
                                                  Tunisia
                                                </option>
                                                <option value="Turkey">
                                                  Turkey
                                                </option>
                                                <option value="Turkmenistan">
                                                  Turkmenistan
                                                </option>
                                                <option value="Turks and Caicos Islands">
                                                  Turks and Caicos Islands
                                                </option>
                                                <option value="Tuvalu">
                                                  Tuvalu
                                                </option>
                                                <option value="Uganda">
                                                  Uganda
                                                </option>
                                                <option value="Ukraine">
                                                  Ukraine
                                                </option>
                                                <option value="United Arab Emirates">
                                                  United Arab Emirates
                                                </option>
                                                <option value="United Kingdom">
                                                  United Kingdom
                                                </option>
                                                <option value="United States">
                                                  United States
                                                </option>
                                                <option value="United States Minor Outlying Islands">
                                                  United States Minor Outlying
                                                  Islands
                                                </option>
                                                <option value="Uruguay">
                                                  Uruguay
                                                </option>
                                                <option value="Uzbekistan">
                                                  Uzbekistan
                                                </option>
                                                <option value="Vanuatu">
                                                  Vanuatu
                                                </option>
                                                <option value="Venezuela">
                                                  Venezuela
                                                </option>
                                                <option value="Viet Nam">
                                                  Viet Nam
                                                </option>
                                                <option value="Virgin Islands, British">
                                                  Virgin Islands, British
                                                </option>
                                                <option value="Virgin Islands, U.S.">
                                                  Virgin Islands, U.S.
                                                </option>
                                                <option value="Wallis and Futuna">
                                                  Wallis and Futuna
                                                </option>
                                                <option value="Western Sahara">
                                                  Western Sahara
                                                </option>
                                                <option value="Yemen">
                                                  Yemen
                                                </option>
                                                <option value="Zambia">
                                                  Zambia
                                                </option>
                                                <option value="Zimbabwe">
                                                  Zimbabwe
                                                </option>
                                              </select>
                                            ))}

                                            <span
                                              className={
                                                countryErr ? " showError" : ""
                                              }
                                            ></span>
                                            {!countryErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Country{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {countryErr}
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ paddingBottom: "2%" }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <input
                                                className="effect-16"
                                                style={{ width: "100%" }}
                                                maxLength="6"
                                                id="Zip"
                                                name="Zip"
                                                Value={Details.zip}
                                                onChange={this.handleChange}
                                                onKeyPress={(event) => {
                                                  const isLetter = /[a-zA-Z]/i.test(event.key);
                                                  if (isLetter) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onInputCapture={(event) => {
                                                  if (!/^[0-9]*$/.test(event.target.value)) {
                                                    event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                                  }
                                                }}
                                                type="text"
                                                placeholder="Pin Code"
                                              />
                                            ))}

                                            <span
                                              className={
                                                ZipErr ? " showError" : ""
                                              }
                                            ></span>
                                            {!ZipErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Pincode
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {ZipErr}
                                              </div>
                                            )}
                                          </div>

                                          <div
                                            className="col-sm-5"
                                            style={{ display: "none" }}
                                          >
                                            {Details.map((Details) => (
                                              <input
                                                className="effect-16"
                                                maxLength="50"
                                                readOnly
                                                id="website"
                                                name="website"
                                                Value={Details.website}
                                                onChange={this.handleChange}
                                                type="text"
                                              />
                                            ))}
                                            <span
                                              className={
                                                PANnoErr ? " showError" : ""
                                              }
                                            ></span>
                                            {!websiteErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Official Website
                                                <span
                                                  style={{ color: "red" }}
                                                ></span>{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {websiteErr}
                                              </div>
                                            )}
                                          </div>

                                          {Details.map((Details) => (
                                            <div className="col-sm-1">
                                              <input
                                                type="hidden"
                                                name="OldPan"
                                                defaultValue={Details.pan}
                                              />

                                              <input
                                                type="hidden"
                                                name="OldGST"
                                                defaultValue={Details.gst}
                                              />
                                            </div>
                                          ))}
                                        </div>

                                        <div
                                          className="row"
                                          style={{ display: "none" }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <input
                                                className="effect-16"
                                                readOnly
                                                id="PANno"
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                name="PANno"
                                                Value={Details.paNno}
                                                onChange={this.handleChange}
                                                type="text"
                                              />
                                            ))}
                                            <span
                                              className={
                                                PANnoErr ? " showError" : ""
                                              }
                                            ></span>
                                            {!PANnoErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                PAN Number{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {PANnoErr}
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-sm-5">
                                            {Details.map((Details) => (
                                              <input
                                                className="effect-16"
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                readOnly
                                                id="GSTno"
                                                name="GSTno"
                                                style={{ width: "100%" }}
                                                Value={Details.gsTno}
                                                onChange={this.handleChange}
                                                type="text"
                                                placeholder="GST Number"
                                              />
                                            ))}
                                            <span
                                              className={
                                                GSTnoErr ? " showError" : ""
                                              }
                                            ></span>
                                            {!GSTnoErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                GST Number
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {GSTnoErr}
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>
                                        {/* {(() => { 
                              if (Details.status===1){
                                return (
    <div className="row" style={{display:"none"}} >
    <div className="col-sm-1"></div>
    <div className="col-sm-5" >     
{Details.map(Details=>  <div>
  <input className="effect-16"   name="Pan" defaultValue={Details.pan}    onFocus={(e) => e.target.type = 'file'} onChange={this.handleImageChange} type="text" id="Pan"  />
<input type ="hidden" name="OldPan" defaultValue={Details.pan} />
{!PanErr  ? <span style={{color:'#a29d9d'}}>Upload PAN Copy</span>: 
    <div style={{ color: "red" }}>{PanErr}</div>   }
                 

</div>)}
</div>
  
    <div className="col-sm-5" >     
{Details.map(Details=>  <div>
  <input className="effect-16"   name="GST"  defaultValue={Details.gst}  onFocus={(e) => e.target.type = 'file'} onChange={this.handleImageChange} type="text" id="GST"  />
<input type ="hidden" name="OldGST" defaultValue={Details.gst} />
{!GSTErr? <span style={{color:'#a29d9d'}}>Upload GST </span>: 
    <div style={{ color: "red" }}>{GSTErr}</div>   }
 
</div>)}
</div>
           
    <div className="col-sm-1"></div>               
    </div>
                                 ) }
                                 })()}
                                   */}

                                        {/* {this.state.Reason ==="Insufficient Documents"?this.state.Reason:"cccc"} */}
                                        {(() => {
                                          if (
                                            this.state.Reason ===
                                              "Insufficient Documents" &&
                                            this.state.EntStatus === 0
                                          ) {
                                            return (
                                              <div
                                                className="row"
                                                style={{ display: "visible" }}
                                              >
                                                <div className="col-sm-1"></div>
                                                <div className="col-sm-5">
                                                  {Details.map((Details) => (
                                                    <div>
                                                      <input
                                                        className="effect-16"
                                                        name="Pan"
                                                        defaultValue={Details.pan.substring(
                                                          Details.pan.lastIndexOf(
                                                            "/"
                                                          ) + 1
                                                        )}
                                                        onFocus={(e) =>
                                                          (e.target.type =
                                                            "file")
                                                        }
                                                        onChange={
                                                          this.handleImageChange
                                                        }
                                                        type="text"
                                                        id="Pan"
                                                      />
                                                      <input
                                                        type="hidden"
                                                        name="OldPan"
                                                        defaultValue={
                                                          Details.pan
                                                        }
                                                      />
                                                      {!PanErr ? (
                                                        <span
                                                          style={{
                                                            color: "#a29d9d",
                                                          }}
                                                        >
                                                          Upload PAN Copy
                                                        </span>
                                                      ) : (
                                                        <div
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          {PanErr}
                                                        </div>
                                                      )}
                                                    </div>
                                                  ))}
                                                </div>

                                                <div className="col-sm-5">
                                                  {Details.map((Details) => (
                                                    <div>
                                                      <input
                                                        className="effect-16"
                                                        name="GST"
                                                        defaultValue={Details.gst.substring(
                                                          Details.gst.lastIndexOf(
                                                            "/"
                                                          ) + 1
                                                        )}
                                                        onFocus={(e) =>
                                                          (e.target.type =
                                                            "file")
                                                        }
                                                        onChange={
                                                          this.handleImageChange
                                                        }
                                                        type="text"
                                                        id="GST"
                                                      />
                                                      <input
                                                        type="hidden"
                                                        name="OldGST"
                                                        defaultValue={
                                                          Details.gst
                                                        }
                                                      />
                                                      {!GSTErr ? (
                                                        <span
                                                          style={{
                                                            color: "#a29d9d",
                                                          }}
                                                        >
                                                          Upload GST{" "}
                                                        </span>
                                                      ) : (
                                                        <div
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          {GSTErr}
                                                        </div>
                                                      )}
                                                    </div>
                                                  ))}
                                                </div>

                                                <div className="col-sm-1"></div>
                                              </div>
                                            );
                                          }
                                        })()}

                                        <br />

                                        <div className="row">
                                          <div
                                            className="col-sm-12"
                                            style={{ textAlign: "center" }}
                                          >
                                            <button
                                              type="submit"
                                              className="but"
                                              style={{
                                                height: "39.5px",
                                                width: "86.36px",
                                                marginTop: "-0.5%",
                                                border: "none",
                                              }}
                                            >
                                              Save
                                            </button>
                                            &nbsp; &nbsp; &nbsp;
                                            <button
                                              type="reset"
                                              onClick={this.backFunctionality}
                                              className="btn-Secondary"
                                              style={{ height: "39.5px" }}
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div></div>
                          </form>
                          <br />
                          <br />
                          <br />
                          {/* </div> */}
                        </div>
                      </div>
                      <br />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End */}
      </div>
    );
  }
}
