import CloseIcon from '@material-ui/icons/Close';
import { ReactExcel, readFile } from '@ramonak/react-excel';
import React, { Component } from 'react';
import { OutTable } from 'react-excel-renderer';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import TrainingXL from '../../Assets/Files/Training.xlsx';
import AddTrainingpop from '../../Common/AddTrainingpop';
import FilterPopDesignation from '../../Common/FilterPopDesignation';
import FilterPopTrainingSchedule from '../../Common/FilterPopTrainingSchedule';
import SideBar from '../../Common/Sidebar';
import '../../Css/Tab.css';
import { BASE_URLs } from '../../Config/Base';
import axios from 'axios';

var XLSX = require('xlsx');

export default class TrainingSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Active: [],
      InActive: [],
      All: [],
      comlogo: [],
      Training: [],
      Trainingsss: [],
      Train: [],
      formErrors: {},
      DepartModel: false,
      EditModel: false,
      EditDesigntionValues: [],
      Notification: [],
      rows: [],
      cols: [],
      toggleState: 1,
      allrolevalue: [],
      selectedroleValue: '',
      TrainingList: [],
      FilteredDesignation: [],
      UpdatedFilteredDesignation: [],
      Filterpopstatus: false,
      Filterpopstatus1: false,
      Department: [],
      AllDepartments: [],
      isOpen: false,
      popupdesignation: '',
      PopupFilteredDesignation: '',
      PopupFilteredValue: '',
      PopupDropDownFiltered: '',
      selectedIds: [],
      desigFilter: '0',
      deptFilter: '0',
      topicFilter: '0',
      descripFilter: '0',
      newIDCheckAll: [],
      selectedCheckboxes: {},
      selectAllChecked: false,
      Departmentdrop: [],
      Topicdrop: [],
      Descriptiondrop: [],
      buttonsData: [],
      buttonsVisibility: [],
      AssignedTopics: [],
    };
  }

  get = false;
  removeDuplicates(data) {
    const uniqueSet = new Set();
    data.forEach((item) => {
      uniqueSet.add(JSON.stringify(item));
    });
    const uniqueArray = Array.from(uniqueSet).map((item) => JSON.parse(item));
    return uniqueArray;
  }

  removeDuplicatesByField(data, fieldName) {
    const uniqueData = [];
    const uniqueValues = new Set();

    data.forEach((item) => {
      if (!uniqueValues.has(item[fieldName])) {
        uniqueValues.add(item[fieldName]);
        uniqueData.push(item);
      }
    });

    let value = uniqueData.map((e) => {
      return { ...e, check: false };
    });

    return value;
  }

  // GetTraining() {
  //   let url = `${BASE_URLs}Training/GetAdminTraining`;
  //   let user = JSON.parse(localStorage.getItem("user"));
  //   const accessToken = user;
  //   fetch(url, {
  //     method: "GET",
  //     headers: {
  //       Authorization: "Bearer " + accessToken,
  //       "Content-type": "application/json",
  //       Accept: "application/json",
  //       "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const uniqueData = this.removeDuplicatesByField(data, "description");
  //       this.setState({
  //         Training: uniqueData,
  //         Trainingsss: data.filter(
  //           (e) => e.designation !== null && e.status !== 0
  //         ),
  //         Train: uniqueData,
  //         PopupFilteredDesignation: data.filter((e) => e.designation !== null),
  //         Topicdrop: uniqueData,
  //       });

  //       {
  //         const { Trainingsss } = this.state;

  //         let data = Trainingsss.reduce((r, e) => {
  //           let designationName = e.designation;

  //           if (!r[designationName])
  //             r[designationName] = { designationName, record: [e] };
  //           else r[designationName].record.push(e);

  //           return r;
  //         }, {});

  //         let result = Object.values(data);
  //         this.setState({ FilteredDesignation: result });
  //         this.setState({ UpdatedFilteredDesignation: result });

  //         this.sortAlphabetically(this.state.FilteredDesignation);
  //       }
  //     });
  // }

  GetTraining() {
    let url = `${BASE_URLs}Training/GetAdminTraining`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    axios
      .get(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
        },
      })
      .then((response) => {
        const data = response.data;
        const uniqueData = this.removeDuplicatesByField(data, 'description');
        this.setState({
          Training: uniqueData,
          Trainingsss: data.filter(
            (e) => e.designation !== null && e.status !== 0
          ),
          Train: uniqueData,
          PopupFilteredDesignation: data.filter((e) => e.designation !== null),
          Topicdrop: uniqueData,
        });

        const { Trainingsss } = this.state;

        let newData = Trainingsss.reduce((r, e) => {
          let designationName = e.designation;

          if (!r[designationName])
            r[designationName] = { designationName, record: [e] };
          else r[designationName].record.push(e);

          return r;
        }, {});

        let result = Object.values(newData);
        this.setState({ FilteredDesignation: result });
        this.setState({ UpdatedFilteredDesignation: result });

        this.sortAlphabetically(this.state.FilteredDesignation);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
  }
  sortAlphabetically = (event) => {
    const { FilteredDesignation } = this.state;
    let filter = [...event].sort((a, b) => {
      return a.designationName < b.designationName ? -1 : 1;
    });

    this.setState({ FilteredDesignation: filter });
  };

  componentDidMount() {
    this.GetTraining();
    this.GetAllRoles();
    this.GetDepartment();
  }

  Multidelete = (event) => {
    event.preventDefault();
    if (document.getElementById('selectall')) {
      document.getElementById('selectall').checked = false;
    }
    const data = new FormData(event.target);
    if (
      window.confirm('Are you sure you want to delete this record permanently?')
    ) {
      var options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}Training/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetTraining();
          alert(resp.message);
        });
      document
        .getElementById('delete')
        .setAttribute('style', 'visibility: hidden;');
      document
        .getElementById('deletecount')
        .setAttribute('style', 'visibility: hidden;');
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
  };

  DeleteButton = (id, desigId) => {
    const data = new FormData();
    data.append('Id_delete', id);
    data.append('desgId_delete', desigId);

    for (var pair of data.entries()) {
    }

    if (
      window.confirm('Are you sure you want to delete this record permanently?')
    ) {
      var options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}Training/Designationdelete`, options)
        .then((response) => response.json())
        .then((data) => {
          this.GetTraining();
          toast.success(data.message, {
            position: 'top-center',
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  handlecheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById('selectalldiv')
        .setAttribute('style', 'visibility:visible ;');
    } else {
      document
        .getElementById('selectalldiv')
        .setAttribute('style', 'visibility:hidden ;');
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById('delete')
        .setAttribute('style', 'visibility:visible ;');
      document
        .getElementById('deletecount')
        .setAttribute(
          'style',
          'visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);'
        );
      var msg = document.getElementById('deletecount');
      let count = checkboxes.length - 1;

      if (document.getElementById('selectall').checked) {
        msg.innerHTML = '<span> ' + count + ' selected </span> ';
      } else {
        msg.innerHTML = '<span> ' + checkboxes.length + ' selected </span> ';
      }
    } else {
      document
        .getElementById('delete')
        .setAttribute('style', 'visibility: hidden;');
      document
        .getElementById('deletecount')
        .setAttribute('style', 'visibility: hidden;');
    }
    if (document.getElementById('selectall').checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById('selectall').checked = true;
      }
    } else {
      if (document.getElementById('selectall').checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById('selectall').checked = false;
        }
      }
    }
  }
  handleUpload = (event) => {
    if (document.getElementById('selectall')) {
      document.getElementById('selectall').checked = false;
    }
    if (document.getElementsByName('Id_delete')) {
      var checkboxes = document.getElementsByName('Id_delete');
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById('delete')
          .setAttribute('style', 'visibility: hidden;');
        document
          .getElementById('deletecount')
          .setAttribute('style', 'visibility: hidden;');
      }
    }

    var inputElement = document.getElementById('files');
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf('.'));
    var allowedExtensionsRegx = /(\.xlsx)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);
    if (isAllowed) {
      const file = event.target.files[0];
      readFile(file).then((readedData) =>
        this.setState({ initialData: readedData })
      );
      /* file upload logic goes here... */
      return true;
    } else {
      alert('Invalid File Type.');
      return false;
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);

      fetch(`${BASE_URLs}Training/TrainingExcel`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          this.setState({ initialData: undefined });
          var file = document.getElementById('files');
          var emptyFile = document.createElement('input');
          emptyFile.type = 'file';
          file.files = emptyFile.files;
          this.GetTraining();
          if (resp === undefined) {
            alert('0  Records  added ');
          }
          if (resp === 0) {
            alert('Records added successfully');
          } else if (resp > 0) {
            let msg =
              resp == 1
                ? resp + ' record not added'
                : resp + ' records not added';
            alert(msg);
          } else {
            alert(resp);
          }
          // this.GetAddSkill();
        });
    } catch (error) {}
  };

  GetAllRoles() {
    let url = `${BASE_URLs}Training/GetAllRoles`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        Accept: 'application/json',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          allrolevalue: data,
        });
      });
  }

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById('TrainingSheet');
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, '' + filename + '.xlsx');
  }

  DepartModel = () => {
    this.setState((prevState) => ({
      DepartModel: !prevState.DepartModel,
    }));
    this.GetTraining();
  };
  EditModel = (training_Id, categoryMaster, ref_Doc) => {
    this.setState({
      EditDesigntionValues: {
        training_Id: training_Id,
        categoryMaster: categoryMaster,
        ref_Doc: ref_Doc,
      },
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));

    var page = {
      pathname: '/EditTrainingpop',
      state: {
        training_Id: training_Id,
        categoryMaster: categoryMaster,
        ref_Doc: ref_Doc,
      },
    };
    this.props.history.push(page);

    // this.GetTraining();
  };
  AssignModel = (training_Id, trainingitem, department) => {
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));

    var page = {
      pathname: '/AssignTraining',
      state: {
        training_Id: training_Id,
        trainingitem: trainingitem,
        department: department,
      },
    };
    this.props.history.push(page);

    // this.GetTraining();
  };

  handleCheckAll = () => {
    this.setState(
      (prevState) => {
        const { selectAllChecked, PopupFilteredValue, selectedIds } = prevState;

        const updatedSelectedCheckboxes = {};

        if (!selectAllChecked) {
          PopupFilteredValue.forEach((Training) => {
            updatedSelectedCheckboxes[Training.desigId] = true;
          });
        }

        this.setState({
          selectAllChecked: !selectAllChecked,
          selectedCheckboxes: updatedSelectedCheckboxes,
        });

        const allSelected = PopupFilteredValue.every((Training) =>
          this.state.selectedIds.includes(Training.desigId)
        );

        const updatedSelectedIds = allSelected
          ? this.state.selectedIds.filter(
              (id) =>
                !PopupFilteredValue.map(
                  (Training) => Training.desigId
                ).includes(id)
            )
          : [
              ...this.state.selectedIds,
              ...PopupFilteredValue.map((Training) => Training.desigId),
            ];

        let finalSelectedIds = updatedSelectedIds
          .filter((e) => e != null)
          .map((e) => e.toString());
        let uniqueSet = new Set(finalSelectedIds);
        let resultArray = Array.from(uniqueSet);

        if (!selectAllChecked) {
          return {
            selectedIds: resultArray,
          };
        } else {
          return {
            selectedIds: [],
          };
        }
      },
      () => {}
    );
  };

  checkedAll(e) {
    const { value, checked } = e.target;
    this.setState({ selectAllChecked: checked });

    let data1 = this.state.PopupDropDownFiltered.map((e, index) => {
      return { ...e, check: checked };
    });
    let Checkarray = data1.map((e) => e.training_Id);

    let data2 = this.state.Training.map((e, index) => {
      if (Checkarray.indexOf(e.training_Id) !== -1) {
        return { ...e, check: checked };
      }
      return e;
    });

    this.setState({ Training: data2 });

    console.log(data1.map((e) => e.training_Id));

    let selectal =
      this.state.PopupDropDownFiltered.length ==
      data1.filter((v) => v.check === true).length;

    this.setState({ selectAllChecked: selectal });

    this.setState({ PopupDropDownFiltered: data1 });

    let ids = data1.filter((v) => v.check === true).map((e) => e.training_Id);

    this.setState({ selectedIds: ids });
  }

  handleCheckboxChange = (event) => {
    const desigId = event.target.value;
    const { value, checked } = event.target;

    let checkedboxes = document.querySelectorAll(
      'input[id="selectedall"]:checked'
    );
    let Total = document.querySelectorAll('input[type="checkbox"]');
    if (document.getElementById('selectedall').checked === false) {
      if (checkedboxes.length === Total.length - 1) {
        document.getElementById('selectedall').checked = true;
      }

      if (checkedboxes.length !== Total.length) {
        document.getElementById('selectedall').checked = true;
      }
    } else {
      if (document.getElementById('selectedall').checked) {
        if (checkedboxes.length - 2 !== Total.length) {
          document.getElementById('selectedall').checked = false;
        }
      }
    }

    this.setState((prevState) => ({
      selectedCheckboxes: {
        ...prevState.selectedCheckboxes,
        [value]: checked,
      },
    }));
    this.setState(
      (prevState) => {
        const { selectedIds } = prevState;

        if (selectedIds.includes(desigId)) {
          return {
            selectedIds: selectedIds.filter((id) => id !== desigId),
          };
        } else {
          return {
            selectedIds: [...selectedIds, desigId],
          };
        }
      },

      () => {}
    );
  };
  handleCheckboxChange1 = (event, i) => {
    this.setState({ selectAllChecked: false });

    const { checked } = event.target;

    let data1 = this.state.PopupDropDownFiltered.map((e, index) => {
      if (e.training_Id == i) {
        return { ...e, check: checked };
      }
      return e;
    });
    let data2 = this.state.Training.map((e, index) => {
      if (e.training_Id == i) {
        return { ...e, check: checked };
      }
      return e;
    });
    let selectal =
      this.state.PopupDropDownFiltered.length ==
      data1.filter((v) => v.check === true).length;

    this.setState({ selectAllChecked: selectal });

    this.setState({ PopupDropDownFiltered: data1 });
    this.setState({ Training: data2 });

    let ids = data1.filter((v) => v.check === true).map((e) => e.training_Id);

    this.setState({ selectedIds: ids });
  };

  fetchButtonsData() {
    const { Training } = this.state;
    const buttonsData = [Training];
    const buttonsVisibility = buttonsData.map((button) => {
      // Determine visibility based on data availability
      return /* true or false */;
    });
    this.setState({ buttonsData, buttonsVisibility });
  }

  Detail = (TrainingId, designationName, e) => {
    e.preventDefault();

    // console.log("TrainingId",TrainingId);

    this.setState({ selectAllChecked: false });
    const desigName = designationName;
    const traID = TrainingId;
    const newID = [];

    if (traID.length >= 0) {
      traID.forEach((item) => {
        newID.push(item.training_Id);
      });
    }
    this.setState({ AssignedTopics: newID });
    const { Training } = this.state;

    this.setState({ PopupFilteredValue: [] });

    const popupFilter = Training.filter((item) => {
      return (
        !newID.includes(item.training_Id) && item.designation !== desigName
      );
    });

    this.setState({ PopupFilteredValue: popupFilter }, () => {});

    this.setState({ PopupDropDownFiltered: popupFilter });

    this.setState({ popupdesignation: designationName });
    setTimeout(() => {
          this.setState({ isOpen: true });

    }, 1000);
  };

  GetDepartment() {
    let url = `${BASE_URLs}Department/DepartmentGet`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Departmentdrop: data,
        });
      });
  }

  handleClick = (e) => {
    e.preventDefault();
    console.log('this.state.selectedIds', this.state.selectedIds);
    if (this.state.selectedIds.length === 0) {
      toast.error('Please select at least one Topic', {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    }

    let url = `${BASE_URLs}Training/DesignationAddTraining?Designation=${this.state.popupdesignation}&Desig_ID=${this.state.selectedIds}`;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.GetTraining();
        this.setState({ toggleState: 2 });
        toast.success(data.message, {
          position: 'top-center',
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    this.setState({ PopupDropDownFiltered: this.state.PopupFilteredValue,desigFilter: '0',
    deptFilter: '0',
    topicFilter: '0',
    descripFilter: '0' });
    this.setState({ isOpen: false });
    this.setState({ selectedIds: [] });
    this.setState({ selectedCheckboxes: false });

    // window.location.reload();
  };

  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));
    setTimeout(() => {
      this.props.history.push('/TrainingSchedule');
    }, 1000);
  };

  closePopup = () => {
    this.GetTraining();
    this.setState({ descripFilter: '0' });
    this.setState({ topicFilter: '0' });
    this.setState({ deptFilter: '0' });
    this.setState({ isOpen: false, selectedCheckboxes: false });
    this.setState({ selectedIds: [] });
  };
  onKeyUp = (e) => {
    const Training = this.state.Train.filter(
      (item) =>
        item.department.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.trainingitem
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.description.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Training });
  };

  Edit1(training_Id) {
    var page = {
      pathname: '/EditTrainingpop',
      state: {
        training_Id: training_Id,
      },
    };

    this.props.history.push(page);
  }

  toggleTab = (index) => {
    this.setState({ toggleState: index });
  };

  handleChange = (event) => {
    const { Training, deptFilter, topicFilter, descripFilter, AssignedTopics } =
      this.state;

    const Desig = event.target.value;

    this.setState({ desigFilter: Desig });

    const popupFilter = Training.filter((item) => {
      return (
        (Desig === '0' || item.designation === Desig) &&
        (deptFilter === '0' || item.department === deptFilter) &&
        (topicFilter === '0' || item.trainingitem === topicFilter) &&
        (descripFilter === '0' || item.description === descripFilter)
      );
    });

    const popupFilter1 = popupFilter.filter((item) => {
      return (
        !AssignedTopics.includes(item.training_Id) && item.designation !== Desig
      );
    });

    this.setState({ PopupDropDownFiltered: popupFilter1 });

    const popupFilter2 = popupFilter1.filter((item) => {
      if (descripFilter === '0' && deptFilter === '0' && topicFilter === '0') {
        return Training;
      }
      return deptFilter === '0' || item.department === deptFilter;
    });

    console.log(popupFilter2.filter((e) => !e.check));

    this.setState({
      Topicdrop: popupFilter2,
    });
  };

  handleChange1 = (event) => {
    this.setState({ descripFilter: '0' });
    this.setState({ topicFilter: '0' });
    this.setState({ selectAllChecked: false });
    const {
      Training,
      desigFilter,
      topicFilter,
      descripFilter,
      AssignedTopics,
    } = this.state;

    const Dept = event.target.value;

    this.setState({ deptFilter: Dept });

    const popupFilter = Training.filter((item) => {
      if (descripFilter === '0' && Dept === '0' && topicFilter === '0') {
        return Training;
      }
      return (
        (desigFilter === '0' || (item.designation === desigFilter) !== 0
          ? '0'
          : desigFilter) &&
        (Dept === '0' || item.department === Dept) &&
        (topicFilter === '0' || (item.trainingitem === topicFilter) !== 0
          ? '0'
          : topicFilter) &&
        (descripFilter === '0' || (item.description === descripFilter) !== 0
          ? '0'
          : descripFilter)
      );
    });
    //

    const popupFilter1 = popupFilter.filter((item) => {
      return (
        !AssignedTopics.includes(item.training_Id) &&
        item.designation !== desigFilter
      );
    });

    this.setState({ PopupDropDownFiltered: popupFilter1 });
    const popupFilter2 = popupFilter1.filter((item) => {
      if (descripFilter === '0' && Dept === '0' && topicFilter === '0') {
        return Training;
      }
      return Dept === '0' || item.department === Dept;
    });

    this.setState({
      Topicdrop: popupFilter2,
    });

    let selectal =
      popupFilter1.length ==
      popupFilter1.filter((v) => v.check === true).length;

    this.setState({ selectAllChecked: selectal });
  };

  handleChange2 = (event) => {
    const { Training, desigFilter, deptFilter, descripFilter, AssignedTopics } =
      this.state;

    this.setState({ selectAllChecked: false });

    const Topic = event.target.value;

    this.setState({ topicFilter: Topic });

    const popupFilter = Training.filter((item) => {
      if (descripFilter === '0' && deptFilter === '0' && Topic === '0') {
        return Training;
      }
      return (
        (desigFilter === '0' || item.designation === desigFilter) &&
        (deptFilter === '0' || item.department === deptFilter) &&
        (Topic === '0' || item.trainingitem === Topic) &&
        (descripFilter === '0' || item.description === descripFilter)
      );
    });

    const popupFilter1 = popupFilter.filter((item) => {
      return (
        !AssignedTopics.includes(item.training_Id) &&
        item.designation !== desigFilter
      );
    });

    this.setState({ PopupDropDownFiltered: popupFilter1 });
    const popupFilter2 = popupFilter1.filter((item) => {
      if (descripFilter == '0' && deptFilter == '0' && Topic == '0') {
        return Training;
      }
      return deptFilter === '0' || item.department.includes(deptFilter);
    });

    this.setState({
      Topicdrop: popupFilter2,
    });
    let selectal =
      popupFilter1.length ==
      popupFilter1.filter((v) => v.check === true).length;

    this.setState({ selectAllChecked: selectal });
  };

  handleChange3 = (event) => {
    const { Training, desigFilter, deptFilter, topicFilter, AssignedTopics } =
      this.state;

    this.setState({ selectAllChecked: false });

    const Description = event.target.value;

    this.setState({ descripFilter: Description });

    const popupFilter = Training.filter((item) => {
      if (Description == '0' && deptFilter == '0' && topicFilter == '0') {
        return Training;
      }
      return (
        (desigFilter === '0' || item.designation === desigFilter) &&
        (deptFilter === '0' || item.department === deptFilter) &&
        (topicFilter === '0' || item.trainingitem === topicFilter) &&
        (Description === '0' || item.description.includes(Description))
      );
    });

    const popupFilter1 = popupFilter.filter((item) => {
      return (
        !AssignedTopics.includes(item.training_Id) &&
        item.designation !== desigFilter
      );
    });

    this.setState({ PopupDropDownFiltered: popupFilter1 });
    const popupFilter2 = popupFilter1.filter((item) => {
      if (Description === '0' && deptFilter === '0' && topicFilter === '0') {
        return Training;
      }
      return deptFilter === '0' || item.department.includes(deptFilter);
    });

    this.setState({
      Topicdrop: popupFilter2,
    });

    let selectal =
      popupFilter1.length ==
      popupFilter1.filter((v) => v.check === true).length;

    this.setState({ selectAllChecked: selectal });
  };

  // validateCheckbox = () => {
  //   const checkboxes = document.querySelectorAll('input[type="checkbox"]');
  //   let isChecked = false;

  //   checkboxes.forEach((checkbox) => {
  //     if (checkbox.checked) {
  //       isChecked = true;
  //       this.handleClick()
  //     }
  //   });

  //   if (!isChecked) {
  //     toast.error("Please select at least one Trainer", {
  //       position: "top-center",
  //       autoClose: 3200,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // }

  render() {
    const {
      Training,
      Train,
      Trainings,
      TrainingList,
      FilteredDesignation,
      isOpen,
      selectedIds,
      selectAllChecked,
      selectedCheckboxes,
    } = this.state;

    if (this.get == false) {
      this.GetTraining();
      this.get = true;
      console.log('get called---');
    }

    var Entityname = JSON.parse(localStorage.entityName);
    var filename = `O_${Entityname}_TrainingItem`;
    const { toggleState } = this.state;
    const { Department, Filterpopstatus, Filterpopstatus1 } = this.state;

    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        {Filterpopstatus ? (
          <FilterPopDesignation
            data={this.state.UpdatedFilteredDesignation}
            FilteredData={(newValue) => {
              this.setState({ FilteredDesignation: newValue });
            }}
            TogglePop={(e) => {
              this.setState({ Filterpopstatus: !Filterpopstatus });
              this.setState({ Training: Train });

              // if (this.state.FilteredDesignation.length == 0) {
                this.setState({
                  FilteredDesignation: this.state.UpdatedFilteredDesignation,
                });
              // }

              // if (this.state.Department == 0) {
              //   this.setState({ Department: this.state.Depart });
              // }
            }}
          />
        ) : null}

        {Filterpopstatus1 ? (
          <FilterPopTrainingSchedule
            data={Train}
            FilteredData={(newValue) => {
              this.setState({ Training: newValue });
            }}
            TogglePop={(e) => {
              this.setState({ Filterpopstatus1: !Filterpopstatus1 });
              this.setState({ Training: Train });

              if (this.state.Training.length == 0) {
                this.setState({ Training: Train });
              }
            }}
          />
        ) : null}
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  {/* <div className="page-header-title"></div> */}
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card" id="card">
                        <div className="glowBox">
                          <div style={{ float: 'left' }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                class="icofont icofont-arrow-left"
                                style={{ fontSize: '45px', color: 'white' }}
                              />
                            </a>{' '}
                          </div>
                          <h4 className="title">Training Topics</h4>
                        </div>
                        <br />
                        <div className="j-tabs-container md-tabs">
                          <br />
                          <div style={{ marginLeft: '49px' }}>
                            <input
                              id="tab1"
                              type="radio"
                              name="tabs"
                              defaultChecked
                            />
                            <label
                              id="supertab"
                              onClick={() => this.toggleTab(1)}
                              style={{ border: '1px solid lightgray' }}
                              className="j-tabs-label"
                              htmlFor="tab1"
                              title="Training Topic"
                            >
                              <span>
                                &nbsp;&nbsp;Training Topic&nbsp;&nbsp;
                              </span>
                            </label>
                            <input id="tab9" type="radio" name="tabs" />
                            <label
                              id="pendingtab"
                              onClick={() => this.toggleTab(2)}
                              style={{ border: '1px solid lightgray' }}
                              className="j-tabs-label"
                              htmlFor="tab9"
                              title="Designation Based Topic"
                            >
                              <span>
                                &nbsp;Designation Based Topic&nbsp;&nbsp;
                              </span>
                            </label>
                          </div>
                          {/* <br /> */}

                          <div className="contentss-tabs">
                            <div
                              className={
                                toggleState === 1
                                  ? 'contentss  active-contentss'
                                  : 'contentss'
                              }
                            >
                              {this.state.DepartModel ? (
                                <AddTrainingpop
                                  close={() => this.DepartModel()}
                                />
                              ) : null}
                              <form onSubmit={this.handleSubmit}>
                                {/* <div className="card-block">
                                  <div className="row">
                                    <div className="col-lg-2 col-md-4 col-sm-6">
                                      <Link
                                        to="./AddTrainingpop"
                                        type="button"
                                        style={{ width: "150px", background: "white" }}
                                        className="btn"
                                      >
                                        Add
                                      </Link>
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6">
                                      <label
                                        htmlFor="files"
                                        className="btn"
                                        id="addBtn"
                                        name="TrainingFile"
                                      >
                                        Import From Excel
                                      </label>
                                      <input
                                        id="files"
                                        type="file"
                                        accept=".xlsx"
                                        style={{ display: "none" }}
                                        onChange={this.handleUpload}
                                        name="TrainingFile"
                                      />
                                    </div>
                                    <div className="col-lg-2 col-md-4 col-sm-6">
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() => this.exportXlsx(filename)}
                                      >
                                        Export to Excel
                                      </button>
                                    </div>

                                    <OutTable
                                      data={this.state.rows}
                                      columns={this.state.cols}
                                      tableClassName="ExcelTable2007"
                                      tableHeaderRowclassName="heading"
                                    />

                                    <div className="col-lg-2 col-md-4 col-sm-6">
                                      <a
                                        className="btn"
                                        href={TrainingXL}
                                        type="button"
                                        style={{ color: "#5035ce" }}
                                      >
                                        <i
                                          title="Download Template"
                                          className="icofont icofont-download"
                                        ></i>{" "}
                                        Download
                                      </a>
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                      {Filterpopstatus1 ? null : (
                                        <input
                                          className="Searchbox"
                                          placeholder="Search"
                                          onClick={() => {
                                            this.setState({ Filterpopstatus1: true });
                                          }}
                                          style={{ float: "right" }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div> */}

                                <div className="card-block">
                                  <div className="row">
                                    <div
                                      className="col-lg-2 col-md-4 col-sm-6"
                                      style={{ padding: '1%' }}
                                    >
                                      <Link
                                        to="./AddTrainingpop"
                                        type="button"
                                        style={{
                                          width: '150px',
                                          background: 'white',
                                        }}
                                        className="btn"
                                      >
                                        Add
                                      </Link>
                                    </div>
                                    <div
                                      className="col-lg-2 col-md-4 col-sm-6"
                                      style={{ padding: '1%' }}
                                    >
                                      <label
                                        htmlFor="files"
                                        className="btn"
                                        id="addBtn"
                                        name="TrainingFile"
                                      >
                                        Import From Excel
                                        <input
                                          id="files"
                                          type="file"
                                          accept=".xlsx"
                                          style={{ display: 'none' }}
                                          onChange={this.handleUpload}
                                          name="TrainingFile"
                                        />
                                      </label>
                                    </div>
                                    <div
                                      className="col-lg-2 col-md-4 col-sm-6"
                                      style={{ padding: '1%' }}
                                    >
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={() =>
                                          this.exportXlsx(filename)
                                        }
                                      >
                                        Export to Excel
                                      </button>
                                    </div>
                                    <div
                                      className="col-lg-2 col-md-4 col-sm-6"
                                      style={{ padding: '1%' }}
                                    >
                                      <a
                                        className="btn"
                                        href={TrainingXL}
                                        type="button"
                                        style={{ color: '#5035ce' }}
                                      >
                                        <i
                                          title="Download Template"
                                          className="icofont icofont-download"
                                        ></i>{' '}
                                        Download
                                      </a>
                                    </div>
                                    <div
                                      className="col-lg-4 col-md-6 col-sm-6"
                                      style={{ padding: '1%' }}
                                    >
                                      {Filterpopstatus1 ? null : (
                                        <input
                                          className="Searchbox"
                                          placeholder="Search"
                                          onClick={() => {
                                            this.setState({
                                              Filterpopstatus1: true,
                                            });
                                          }}
                                          style={{ float: 'right' }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>

                                {this.state.initialData !== undefined ? (
                                  <ReactExcel
                                    initialData={this.state.initialData}
                                    onSheetUpdate={(currentSheet) =>
                                      this.setState({
                                        CurrentSheet: currentSheet,
                                      })
                                    }
                                    activeSheetClassName="active-sheet"
                                    reactExcelClassName="react-excel"
                                    name="TrainingFile"
                                  />
                                ) : null}
                                {this.state.initialData !== undefined ? (
                                  <div className="col-lg-12">
                                    <div className="savebtndiv">
                                      <button
                                        className="btn"
                                        id=""
                                        type="submit"
                                        style={{ width: '150px' }}
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                ) : null}
                              </form>

                              <div
                                class="table-responsive table-alignLeft"
                                id="tablerep"
                              >
                                <div className="table-header table-hr">
                                  <div class="table-row">
                                    <div class="table-cell">Department</div>
                                    <div class="table-cell">Training Topic</div>
                                    <div class="table-cell">Description</div>
                                    <div class="table-cell">Duration</div>
                                    <div class="table-cellCount">Documents</div>
                                    <div class="table-cellCount">Trainers</div>
                                    <div class="table-cellCount">
                                      Designation
                                    </div>
                                    <div class="table-cell"></div>
                                  </div>
                                </div>
                                <div class="table-body">
                                  {Training.map((Training) => (
                                    <div
                                      class="table-row"
                                      style={{
                                        textAlign: 'left',
                                        borderBottom:
                                          '1px solid rgba(0, 0, 0, 0.1)',
                                        marginLeft: '2%',
                                        width: '100%',
                                      }}
                                      key={Training.training_Id}
                                    >
                                      <div
                                        class="table-cell"
                                        style={{ paddingLeft: '1%' }}
                                      >
                                        {Training.department.length > 10 ? (
                                          <div>
                                            {Training.department.substring(
                                              0,
                                              10
                                            )}
                                            ....
                                          </div>
                                        ) : (
                                          Training.department
                                        )}
                                      </div>
                                      <div class="table-cell">
                                        {Training.trainingitem.length > 15 ? (
                                          <div>
                                            {Training.trainingitem.substring(
                                              0,
                                              15
                                            )}
                                            ...
                                          </div>
                                        ) : (
                                          Training.trainingitem
                                        )}
                                      </div>
                                      <div
                                        class="table-cell"
                                        style={{ paddingLeft: '2%' }}
                                      >
                                        {Training.description.length > 17 ? (
                                          <div>
                                            {Training.description.substring(
                                              0,
                                              17
                                            )}
                                            ...
                                          </div>
                                        ) : (
                                          Training.description
                                        )}
                                      </div>
                                      <div
                                        class="table-cell"
                                        style={{ paddingLeft: '2.5%' }}
                                      >
                                        {Training.duration === '1 Hours'
                                          ? '1 Hour'
                                          : Training.duration}
                                      </div>
                                      <div class="table-cell">
                                        {Training.filecount}
                                      </div>
                                      <div class="table-cell">
                                        {Training.trainercount}
                                      </div>
                                      <div
                                        class="table-cell"
                                        style={{ textAlign: 'left' }}
                                      >
                                        {Training.designationcount}
                                      </div>
                                      <div class="table-cell">
                                        <button
                                          className="button-30 sk-edit"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.Edit1(Training.training_Id);
                                          }}
                                        >
                                          <i
                                            class="fa fa-info"
                                            aria-hidden="true"
                                          ></i>
                                        </button>{' '}
                                        &nbsp;&nbsp;&nbsp;
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>

                              {/* <div className="table-responsive">
                                <table className="table">
                                  <thead>
                                    <tr className="text-center">
                                      <th>Department</th>
                                      <th>Training Topic</th>
                                      <th>Description</th>
                                      <th>Duration</th>
                                      <th>Documents</th>
                                      <th>Trainers</th>
                                      <th>Designation</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody style={{ position:'sticky', maxHeight: '190px', overflowY: 'auto' }}>
                                    {Training.map((Training) => (
                                      <tr key={Training.training_Id} className="text-center">
                                        <td>{Training.department}</td>
                                        <td>{Training.trainingitem}</td>
                                        <td>
                                          {Training.description.length > 10 ? (
                                            <div>{Training.description.substring(0, 15)}....</div>
                                          ) : (
                                            Training.description
                                          )}
                                        </td>
                                        <td>{Training.duration}</td>
                                        <td>{Training.filecount}</td>
                                        <td>{Training.trainercount}</td>
                                        <td>{Training.designationcount}</td>
                                        <td>
                                          <button className="btn btn-primary button-30 sk-edit" onClick={(e) => { e.preventDefault(); this.Edit1(Training.training_Id); }}>
                                            <i className="fa fa-info" aria-hidden="true"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div> */}

                              {toggleState === 1 ? (
                                <table
                                  id="TrainingSheet"
                                  className="table nowrap"
                                  style={{ maxWidth: '100%', display: 'none' }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Department</th>
                                      <th>Training Topic</th>
                                      <th>Description</th>
                                      <th>Duration</th>
                                      <th>Documents</th>
                                      <th>Trainers</th>
                                      <th>Designation</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Training.map((Training) => (
                                      <tr>
                                        <td>{Training.department}</td>
                                        <td>{Training.trainingitem}</td>
                                        <td>{Training.description}</td>
                                        <td>{Training.duration}</td>
                                        <td style={{ textAlign: 'end' }}>
                                          {Training.filecount}
                                        </td>
                                        <td>{Training.trainercount}</td>
                                        <td>{Training.designationcount}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              ) : (
                                <table
                                  id="TrainingSheet"
                                  className="table nowrap"
                                  style={{ marginTop: '-11px' }}
                                >
                                  {this.state.FilteredDesignation.map(
                                    (Trainings) => (
                                      <>
                                        <tr></tr>
                                        <tr>
                                          <th>
                                            <b>{Trainings.designationName}</b>
                                          </th>
                                        </tr>
                                        <>
                                          <tr>
                                            <th
                                              style={{
                                                textAlign: 'left',
                                              }}
                                            >
                                              Department
                                            </th>
                                            <th
                                              style={{
                                                textAlign: 'left',
                                              }}
                                            >
                                              Training Topic
                                            </th>
                                            <th
                                              style={{
                                                textAlign: 'left',
                                              }}
                                            >
                                              Description
                                            </th>
                                            <th
                                              style={{
                                                textAlign: 'left',
                                              }}
                                            >
                                              Documents
                                            </th>
                                            <th
                                              style={{
                                                textAlign: 'left',
                                              }}
                                            >
                                              Trainers
                                            </th>
                                          </tr>
                                          {Trainings.record.map((Training) => (
                                            <tr>
                                              <td>{Training.department}</td>
                                              <td>{Training.trainingitem}</td>
                                              <td>{Training.description}</td>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                }}
                                              >
                                                {Training.filecount}
                                              </td>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                }}
                                              >
                                                {Training.trainercount}
                                              </td>
                                            </tr>
                                          ))}
                                        </>
                                      </>
                                    )
                                  )}
                                </table>
                              )}
                            </div>
                            <div
                              className={
                                toggleState === 2
                                  ? 'contentss  active-contentss'
                                  : 'contentss'
                              }
                            >
                              <div className="card-block">
                                <div className="row">
                                  <div className="col btnalign">
                                    <Link
                                      to="./AddTrainingpop"
                                      type="button"
                                      style={{
                                        width: '150px',
                                        background: 'white',
                                      }}
                                      className="btn "
                                    >
                                      Add{' '}
                                    </Link>
                                  </div>
                                  <div
                                    className="col btnalign"
                                    style={{ marginRight: '185px' }}
                                  >
                                    <button
                                      className="btn"
                                      type="button"
                                      onClick={() => this.exportXlsx(filename)}
                                    >
                                      Export to excel
                                    </button>
                                  </div>

                                  <OutTable
                                    data={this.state.rows}
                                    columns={this.state.cols}
                                    tableClassName="ExcelTable2007"
                                    tableHeaderRowclassName="heading"
                                  />

                                  {/* <div className="col btnalign">
                                    <a
                                      className="btn"
                                      href={TrainingXL}
                                      type="button"
                                      style={{ color: "#5035ce" }}
                                    >
                                      <i
                                        title="Download Template"
                                        class="icofont icofont-download"
                                      ></i>{" "}
                                      Download
                                    </a>
                                  </div> */}
                                  <div className="col btnalign"></div>
                                  <div className="col-lg-4">
                                    {Filterpopstatus ? null : (
                                      <input
                                        className="Searchbox"
                                        placeholder="Search"
                                        onClick={() => {
                                          this.setState({
                                            Filterpopstatus: true,
                                          });
                                        }}
                                        style={{ float: 'right' }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="card-block2">
                                <form>
                                  <button
                                    type="submit"
                                    className="multitrash"
                                    id="delete"
                                    style={{
                                      float: 'right',
                                      visibility: 'hidden',
                                    }}
                                  >
                                    <i
                                      class="icofont icofont-delete-alt"
                                      style={{ color: 'darkgray' }}
                                    ></i>
                                  </button>
                                  <div>
                                    {' '}
                                    <span
                                      id="deletecount"
                                      className="multitrash"
                                      style={{
                                        visibility: 'hidden',
                                        fontSize: '17px',
                                      }}
                                    ></span>
                                  </div>
                                  <div
                                    id="selectalldiv"
                                    style={{ visibility: 'hidden' }}
                                  ></div>
                                  {/* <br /> */}

                                  <div className="TrainingscheduleTable dt-responsive table-responsive table-res">
                                    <>
                                      <table
                                        id="DepSheet"
                                        className="table nowrap"
                                        style={{ marginTop: '-11px' }}
                                      >
                                        {this.state.FilteredDesignation.map(
                                          (Trainings) => (
                                            <>
                                              <tr>
                                                <th>
                                                  <input
                                                    disabled
                                                    className="designationMenu"
                                                    value={
                                                      Trainings.designationName
                                                    }
                                                  ></input>
                                                </th>
                                                <td>
                                                  <button
                                                    className="button-20 sk-edit"
                                                    onClick={(e) =>
                                                      this.Detail(
                                                        Trainings.record,
                                                        Trainings.designationName,
                                                        e
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      class="icofont icofont-plus"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </button>
                                                </td>
                                              </tr>
                                              <>
                                                <tr>
                                                  <th
                                                    style={{
                                                      textAlign: 'left',
                                                    }}
                                                  >
                                                    Department
                                                  </th>
                                                  <th
                                                    style={{
                                                      textAlign: 'left',
                                                    }}
                                                  >
                                                    Training Topic
                                                  </th>
                                                  <th
                                                    style={{
                                                      textAlign: 'left',
                                                    }}
                                                  >
                                                    Description
                                                  </th>
                                                  <th
                                                    style={{
                                                      textAlign: 'left',
                                                    }}
                                                  >
                                                    Documents
                                                  </th>
                                                  <th
                                                    style={{
                                                      textAlign: 'left',
                                                    }}
                                                  >
                                                    Trainers
                                                  </th>
                                                </tr>
                                                {Trainings.record.map(
                                                  (Training) => (
                                                    <tr>
                                                      <td>
                                                        {Training.department}
                                                      </td>
                                                      <td>
                                                        {Training.trainingitem
                                                          .length > 10 ? (
                                                          <div>
                                                            {Training.trainingitem.substring(
                                                              0,
                                                              15
                                                            )}
                                                            ....
                                                          </div>
                                                        ) : (
                                                          Training.trainingitem
                                                        )}
                                                      </td>
                                                      <td>
                                                        {Training.description
                                                          .length > 10 ? (
                                                          <div>
                                                            {Training.description.substring(
                                                              0,
                                                              15
                                                            )}
                                                            ....
                                                          </div>
                                                        ) : (
                                                          Training.description
                                                        )}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: 'center',
                                                        }}
                                                      >
                                                        {Training.filecount}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: 'center',
                                                        }}
                                                      >
                                                        {Training.trainercount}
                                                      </td>

                                                      <td
                                                        style={{
                                                          textAlign: 'center',
                                                        }}
                                                      >
                                                        <button
                                                          className="button-30"
                                                          type="button"
                                                          style={{
                                                            color:
                                                              'rgb(255, 0, 0)',
                                                          }}
                                                          onClick={() =>
                                                            this.DeleteButton(
                                                              Training.training_Id,
                                                              Training.desigId
                                                            )
                                                          }
                                                        >
                                                          <i className="icofont icofont-minus"></i>
                                                        </button>
                                                        &nbsp;
                                                        <button
                                                          className="button-30 sk-edit"
                                                          onClick={(e) => {
                                                            e.preventDefault();
                                                            this.Edit1(
                                                              Training.training_Id
                                                            );
                                                          }}
                                                        >
                                                          <i
                                                            class="fa fa-info"
                                                            aria-hidden="true"
                                                          ></i>
                                                        </button>
                                                        &nbsp;&nbsp;&nbsp;
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </>
                                            </>
                                          )
                                        )}
                                      </table>
                                    </>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {isOpen && (          
          <div className="pop">
            <div
              className="popinnernormalls"
              style={{ height: "470px" }}
            >
              {" "}
              <div className="row" style={{ padding: "8px" }}>
                <div className="col-md-6" style={{ marginLeft: "60px" }}>
                  {this.state.popupdesignation.length > 13 ?
                    (
                      <b style={{ marginLeft: "100px" }}>
                        {this.state.popupdesignation}
                      </b>
                    ) :
                    (
                      <b style={{ marginLeft: "165px" }}>
                        {this.state.popupdesignation}
                      </b>
                    )
                  }
                </div>
                <div className="col-sm-3">
                  <button
                    style={{
                      backgroundColor: "#a0a3a305",
                      color: "#797070",
                      border: "1px solid #a3a5a505",
                      marginRight: "-255px",
                    }}
                    onClick={this.closePopup}
                  >
                    <CloseIcon />
                  </button>
                </div>
                <div className="col-sm-8" style={{ maxWidth: "100.666667%" }}>
                  <div
                    className="dt-responsive table-responsive table-res"
                    style={{ overflowX: "hidden" }}
                  ></div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <div className="row" style={{ padding: "20px" }}>
                    <div className="col-sm-3">
                      <select
                        className="effect-16"
                        type="text"
                        name="Department"
                        value={this.state.dept}
                        id="Department"
                        onChange={this.handleChange}
                      >
                        <option value="">Designation</option>
                        {this.state.PopupFilteredValue.map((popValue) => (
                          <option value={popValue.designation}>
                            {popValue.designation}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-sm-3">
                      <select
                        className="effect-16"
                        type="text"
                        value={this.state.selectedJobRole}
                        name="JobRole"
                        id="JobRole"
                        onChange={this.handleChange1}
                      >
                        <option value="">Department</option>
                        {this.state.PopupFilteredValue.map((popValue) => (
                          <option value={popValue.department}>
                            {popValue.department}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-sm-3">
                      <select
                        className="effect-16"
                        type="text"
                        value={this.state.selectedJobRole}
                        name="JobRole"
                        id="JobRole"
                        onChange={this.handleChange2}
                      >
                        <option value="">Training Topic</option>
                        {this.state.PopupFilteredValue.map((popValue) => (
                          <option value={popValue.trainingitem}>
                            {popValue.trainingitem}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-sm-3">
                      <select
                        className="effect-16"
                        type="text"
                        value={this.state.selectedJobRole}
                        name="JobRole"
                        id="JobRole"
                        onChange={this.handleChange3}
                      >
                        <option value="">Description</option>

                        {this.state.PopupFilteredValue.map((popValue) => (
                          <option value={popValue.description}>
                            {popValue.description.length > 10
                              ? popValue.description.slice(0, 10) + "...."
                              : popValue.description}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div
                    
                    id="TrainerSchedulePop"
                  >
                    {this.state.PopupDropDownFiltered === "" ? (
                      <>
                        {this.state.PopupFilteredValue.length === 0 ? (
                          "No records"
                        ) : (
                          <table
                            id="DepSheet"
                            className="table nowrap"
                            style={{ maxWidth: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th>
                                  <input
                                    type="checkbox"
                                    onChange={this.handleCheckAll}
                                    checked={selectAllChecked}
                                  />
                                </th>
                                <th>Designation</th>
                                <th id="AlignHeader">Department</th>
                                <th id="AlignHeader2">Training Topic</th>
                                <th id="AlignHeader3">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.PopupFilteredValue.map((Training) => (
                                <tr>
                                  <td>
                                    <input
                                      type="checkbox"
                                      value={Training.desigId}
                                      onChange={this.handleCheckboxChange}
                                      checked={
                                        selectedCheckboxes[Training.desigId] ||
                                        false
                                      }
                                    />
                                  </td>
                                  <td>{Training.designation}</td>
                                  <td>{Training.department}</td>
                                  <td>{Training.trainingitem}</td>
                                  <td>{Training.description}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </>
                    ) : (
                      <>
                        {this.state.PopupFilteredValue.length === 0 ? (
                          "No records"
                        ) : (
                          <table
                            id="DepSheet"
                            className="table nowrap"
                            style={{ maxWidth: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th id="AlignCheckBox">
                                  <input
                                    type="checkbox"
                                    onChange={this.handleCheckAll}
                                    checked={selectAllChecked}
                                  />
                                </th>
                                <th id="AlignHeader">Designation</th>
                                <th id="AlignHeader1">Department</th>
                                <th id="AlignHeader2">Training Topic</th>
                                <th id="AlignHeader3">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.PopupDropDownFiltered.map(
                                (Training) => (
                                  <tr>
                                    <td>
                                      <input
                                        type="checkbox"
                                        value={Training.desigId}
                                        onChange={this.handleCheckboxChange}
                                        checked={
                                          selectedCheckboxes[
                                          Training.desigId
                                          ] || false
                                        }
                                      />
                                    </td>
                                    <td>{Training.designation}</td>
                                    <td>{Training.department}</td>
                                    <td>{Training.trainingitem}</td>
                                    <td>
                                      {Training.description.length > 10 ? (
                                        <div>
                                          {Training.description.substring(
                                            0,
                                            15
                                          )}
                                          ....
                                        </div>
                                      ) : (
                                        Training.description
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        )}
                      </>
                    )}
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <button
                      className="but"
                      type="button"
                      onClick={this.handleClick}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        )} */}

        {isOpen && (
          <div class="popup" id="myPopup">
            <div class="popup-content">
              <div class="popup-header">
                <b>
                  {this.state.popupdesignation}
                  <div className="row">
                    <div className="col-sm-12" style={{ textAlign: 'center' }}>
                      <div className="row" style={{ padding: '8px' }}>
                        <div className="col-sm-4">
                          <select
                            className="effect-16"
                            type="text"
                            value={this.state.selectedJobRole}
                            name="JobRole"
                            id="JobRole"
                            onChange={this.handleChange1}
                          >
                            <option value="0">All</option>
                            {this.state.Departmentdrop.map((popValue) => (
                              <option value={popValue.department}>
                                {popValue.department}
                              </option>
                            ))}
                          </select>
                          <span
                            class="dptspan"
                            style={{ color: '#a29d9d', marginRight: '105px' }}
                          >
                            Department
                          </span>{' '}
                        </div>

                        <div className="col-sm-4" style={{ maxWidth: '195px' }}>
                          <select
                            className="effect-16"
                            type="text"
                            value={this.state.topicFilter}
                            name="JobRole"
                            id="JobRole"
                            onChange={this.handleChange2}
                            style={{ marginLeft: '5px' }}
                          >
                            <option value="0">All</option>
                            {this.state.Topicdrop.map((popValue) => (
                              <option value={popValue.trainingitem}>
                                {popValue.trainingitem}
                              </option>
                            ))}
                          </select>
                          <span
                            class="topicspan"
                            style={{ color: '#a29d9d', marginRight: '55px' }}
                          >
                            Training Topic
                          </span>{' '}
                        </div>
                        <div className="col-sm-4">
                          <select
                            className="effect-16"
                            type="text"
                            value={this.state.descripFilter}
                            name="JobRole"
                            id="JobRole"
                            onChange={this.handleChange3}
                          >
                            <option value="0">All</option>

                            {this.state.Topicdrop.map((popValue) => (
                              <option value={popValue.description}>
                                {popValue.description.length > 10
                                  ? popValue.description.slice(0, 10) + '....'
                                  : popValue.description}
                              </option>
                            ))}
                          </select>
                          <span
                            class="topicspan"
                            style={{ color: '#a29d9d', marginRight: '105px' }}
                          >
                            Description
                          </span>{' '}
                        </div>
                      </div>
                      {/* {Filterpopstatusadd ? (
            <Filterpopstatustopic
              data={this.state.Search}
              FilteredData={(newValue) => {
                this.setState({ Search: newValue });
              }}
              TogglePop={(e) => {
                this.setState({
                  Filterpopstatusadd: !Filterpopstatusadd,
                });
              }}
            />
          ) : null} */}
                    </div>
                  </div>
                </b>

                <div class="popup-close" onClick={this.closePopup}>
                  {' '}
                  <CloseIcon />
                </div>
              </div>
              <div class="popup-bodys">
                {this.state.PopupDropDownFiltered.length === 0 ? (
                  <span style={{ marginLeft: '-16%' }}>
                    <b>No Records</b>
                  </span>
                ) : (
                  <table class="popup-table">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            id="selectedall"
                            onClick={(e) => {
                              this.checkedAll(e);
                            }}
                            checked={this.state.selectAllChecked}
                          />
                        </th>

                        <th>Department</th>

                        <th>Training Topic</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.PopupDropDownFiltered.map((Training, i) => (
                        <tr>
                          <td>
                            <input
                              type="checkbox"
                              name="design"
                              id="checkedall"
                              value={Training.training_Id}
                              onChange={(e) =>
                                this.handleCheckboxChange1(
                                  e,
                                  Training.training_Id
                                )
                              }
                              checked={Training.check}
                            />
                          </td>

                          {/* <td>{Training.department}</td> */}
                          <td>{Training.department}</td>
                          {/* <td>{Training.trainingitem}</td> */}
                          <td>{Training.trainingitem}</td>
                          <td>
                            {Training.description.length > 10 ? (
                              <div>
                                {Training.description.substring(0, 28)}
                                ....
                              </div>
                            ) : (
                              Training.description
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              {/* {Filterpopstatusadd ? (
      <Filterpopstatustopic
      data={this.state.Search}
      FilteredData={(newValue) => {
        this.setState({ Search: newValue });
      }}
      TogglePop={(e) => {
        this.setState({
          Filterpopstatusadd: !Filterpopstatusadd,
        });
      }}
      />
      ) : null} */}

              <div class="popup-footerss">
                <div></div>
                {this.state.PopupDropDownFiltered.length === 0 ? null : (
                  <button
                    className="but"
                    type="button"
                    id="Savebut"
                    onClick={this.handleClick}
                    style={{ marginBottom: '13px' }}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
