import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import "../Css/Chat.css";
import EntityList from "./EntityList";
import TimeAgo from "react-timeago";
import ReactTimeAgo from "react-time-ago";
import Picker from "emoji-picker-react";
import { BASE_URLs } from "../Config/Base";
export default class SuccessPopup1 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(null);
    this.state = {
      AddSkillModels: false,
      Entity: false,
      Allmessage: [],
      isButton1Visible: true,
      Popmsg: [],
      message: [],
      ReceivepopMsg: [],
      createDateTime: new Date(),
      picture_Path: null,
      document_Path: null,
      selectedDocumentName: "",
      active: false,
      chosenEmoji: null,
      showEmoji: false,
      setShowEmoji: "",
      msg: "",
      message: "",
    };
    this.AddSkillModels = this.AddSkillModels.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmojiClick = this.handleEmojiClick.bind(this);
    this.myEmoji = this.myEmoji.bind(this);
  }
  handleDocumentChange = (event) => {
    const selectedDocument = event.target.files[0];
    this.setState({
      document_Path: selectedDocument,
      selectedDocumentName: selectedDocument.name,
    });
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  handleTextChange = (event) => {
    this.setState({ message: event.target.value });
  };
  handleFileChange = (event) => {
    this.setState({ picture_Path: event.target.files[0] });
  };
  handleSubmit = (event) => {
    const { message, picture_Path, document_Path } = this.state;
    if (!message.toString().trim() && !picture_Path && !document_Path) {
      alert("Please enter a message, select an image, or choose a document.");
    } else {
      event.preventDefault();
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}Contact/OneOnOne`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          event.target.reset();
          this.GetmsgPop(this.props.values.userName);
          this.GetReceivemsgPop(this.props.values.firstName);
          this.setState({
            message: "",
            picture_Path: null,
            document_Path: null,
          });
        });
    }
  };

  GetMessages() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Contact/GetSendmsg`, options)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          this.setState({
            Messages: data,
          });
          // console.log("message", data);
        } else {
          console.error("Received data is not an array:", data);
        }
      });
  }

  GetAllMessage(Id) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Contact/GetAllmsg?Id=` + Id, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Allmessage: data,
        });
        // console.log("AllMessage", data);
      });
  }

  GetmsgPop(UserName) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };

    fetch(`${BASE_URLs}Contact/GetmsgPop?UserName=` + UserName, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          Popmsg: data,
        });
        // console.log("Receive", data);
      })
      .catch((error) => {
        console.error("Error fetching user messages:", error);
      });
  }

  GetReceivemsgPop(FirstName) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };

    fetch(
      `${BASE_URLs}Contact/GetReceivemsgPop?FirstName=` + FirstName,
      options
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          ReceivepopMsg: data,
        });
        // console.log("ReceivepopMsg", data);
      })
      .catch((error) => {
        console.error("Error fetching user messages:", error);
      });
  }

  componentDidMount() {
    this.GetMessages();
    this.GetAllMessage();
    this.GetmsgPop(this.props.values.userName);
    this.GetReceivemsgPop(this.props.values.firstName);
    this.timerID = setInterval(() => {
      this.setState({ createDateTime: new Date() });
    }, 1000);
    this.setState({ active: true });
    this.scrollIntoView();
  }
  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.timerID);
    this.setState({ active: false });
  }
  componentDidUpdate() {
    this.scrollIntoView();
  }
  scrollIntoView() {
    this.myRef.current?.scrollIntoView();
  }
  handleArrowClick() {
    this.AddSkillModels();
    this.props.close();
  }
  handleKeyPress(event) {
    if (event.key === "Enter") {
      this.AddSkillModels();
      this.props.close();
    }
  }
  myEmoji = () => {
    const changeState = this.state.showEmoji;
    if (changeState) {
      this.setState({
        showEmoji: false,
      });
    } else {
      this.setState({
        showEmoji: true,
      });
    }
  };
  handleChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };
  handleEmojiClick = (emojiObject) => {
    this.setState((prevState) => ({
      message: prevState.message + emojiObject.emoji,
      showEmoji: false,
    }));
  };
  AddSkillModels() {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    this.setState((prevState) => ({
      AddSkillModel: !prevState.AddSkillModel,
    }));
  }
  handleButton1Click = () => {
    this.setState({
      isButton1Visible: false,
    });
  };
  EntityListOpen = () => {
    this.setState({
      Entity: true,
    });

    document.getElementById("successclose").style.display = "none";
    // this.props.close()
  };
  render() {
    var w = window.innerWidth;
    const {
      Message,
      Allmessage,
      isButton1Visible,
      isButton2Visible,
      isBackArrowVisible,
      Popmsg,
      message,
      picture_Path,
      document_Path,
      ReceivepopMsg,
      showEmoji,
      Entity,

      msg,
    } = this.state;
    const messages = [...Popmsg, ...ReceivepopMsg];
    messages.sort(
      (a, b) => new Date(a.createDateTime) - new Date(b.createDateTime)
    );
    return (
      <>
        {Entity && <EntityList id="" close={() => this.AddSkillModels()} />}
        {isButton1Visible && (
          <div
            className="pop"
            style={{ backgroundColor: "transparent", marginLeft: "28%" }}
          >
            <form
              id="contactForm"
              autoComplete="off"
              encType="multipart/form-data"
              onSubmit={this.handleSubmit}
            >
              <ul className="text-right boxs">
                <li
                  className="chat-single-box card-shadow active"
                  data-id="1"
                  style={{ marginTop: "-128px" }}
                >
                  <div
                    className="had-container"
                    id="successclose"
                    style={{ width: "970px", marginLeft: "-910px" }}
                  >
                    <div
                      className="chat-header p-10 bg-gray"
                      style={{ backgroundColor: "rgb(156 145 244)" }}
                    >
                      <div className="user-info d-inline-block f-left">
                        <div className="Namepass" style={{ marginTop: "1px" }}>
                          {/* <i
                            onClick={() => {
                              this.AddSkillModels();

                              this.handleButton1Click();
                            }}
                            className="icofont icofont-arrow-left chatButtonTextColor"
                            style={{ fontSize: "45px" }}
                          /> */}
                          <input
                            type="hidden"
                            name="UserName"
                            defaultValue={this.props.values.userName}
                          />
                          <span
                            className="userNames"
                            defaultValue={this.props.values.firstName}
                          >
                            <a
                              href="javascript:void(0);"
                              title="Me"
                              style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                color: "white",
                                textShadow: "2px 2px #6f60e7",
                              }}
                            >
                              <img
                                src="assets/images/avatar-1.png"
                                className="img-circle img-chat-profile"
                                alt="Me"
                              />
                              &nbsp;&nbsp;{this.props.values.firstName}
                            </a>
                          </span>
                        </div>
                      </div>
                      <div className="box-tools d-inline-block">
                        <button
                          id="close"
                          style={{
                            float: "right",
                            backgroundColor: "#a0a3a305",
                            color: "#797070",
                            border: "1px solid #a3a5a505",
                            //opacity:"0"
                          }}
                          onClick={this.props.close}
                          // onClick={()=>{

                          //   this.EntityListOpen()
                          // }}
                        >
                          <CloseIcon />
                        </button>
                      </div>
                    </div>
                    <div
                      className="chat-body p-10"
                      style={{
                        overflowY: "scroll",
                        overflowX: "hidden",
                        height: "470px",
                      }}
                    >
                      <div className="message-scrooler">
                        <div className="messages">
                          <div
                            className="containers"
                            style={{ width: "128vh" }}
                          >
                            {messages.map((message, index) => {
                              const isSender = Popmsg.includes(message);

                              return (
                                <div
                                  className={`message-${
                                    isSender ? "orange" : "blue"
                                  }`}
                                  key={index}
                                  style={{
                                    height: "auto",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {message.picture_Path === "-" &&
                                  message.document_Path === "-" ? (
                                    <p
                                      className="message-content"
                                      name="Popmsg"
                                      // style={{ wordWrap: "break-word" }}
                                    >
                                      {message.message}
                                    </p>
                                  ) : (
                                    <>
                                      {message.picture_Path !== "-" &&
                                      message.document_Path !== "-" ? (
                                        <div>
                                          <div>
                                            <p
                                              className="message-content"
                                              name="Popmsg"
                                              // style={{ wordWrap: "break-word" }}
                                            >
                                              {message.message}
                                            </p>

                                            <br />

                                            <div>
                                              <img
                                                alt="chatimg"
                                                width="80"
                                                height="80"
                                                src={message.picture_Path}
                                                style={{ width: "inherit" }}
                                              />
                                            </div>
                                            <div>
                                              <embed
                                                src={message.document_Path}
                                                id="Gstiframe"
                                                title=""
                                                scrolling="no"
                                                target="_blank"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  pointerEvents: "none",
                                                }}
                                              ></embed>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {message.picture_Path === "-" &&
                                          message.document_Path !== "-" ? (
                                            <div>
                                              <p
                                                className="message-content"
                                                name="Popmsg"
                                                // style={{
                                                //   wordWrap: "break-word",
                                                // }}
                                              >
                                                {message.message}
                                              </p>
                                              <br />
                                              <embed
                                                src={message.document_Path}
                                                id="Gstiframe"
                                                title=""
                                                scrolling="no"
                                                target="_blank"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  pointerEvents: "none",
                                                }}
                                              ></embed>
                                            </div>
                                          ) : (
                                            <div>
                                              <p
                                                className="message-content"
                                                name="Popmsg"
                                                // style={{
                                                //   wordWrap: "break-word",
                                                // }}
                                              >
                                                {message.message}
                                              </p>
                                              <br />

                                              <div>
                                                <img
                                                  alt="chatimg"
                                                  width="80"
                                                  height="80"
                                                  src={message.picture_Path}
                                                  style={{ width: "inherit" }}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                  <br />
                                  <div className="message-timestamp-right">
                                    {/* <TimeAgo
                                      date={message.createDateTime}
                                      live={true}
                                      format={(value, unit, suffix) => {
                                        if (unit === "second") {
                                          // Custom formatting for seconds
                                          return `${value} seconds ago`;
                                        }

                                        // Default formatting for other units
                                        return `${value} ${unit}${suffix}`;
                                      }}
                                      locale="en-US"
                                      style={{ color: "gray" }}
                                    /> */}

                                    <ReactTimeAgo
                                      date={message.createDateTime}
                                      locale="en-US"
                                      timeStyle="round-minute"
                                      style={{ color: "gray" }}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                            <div ref={this.myRef} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="Chat-Input-outer">
                      <div
                        className="chat-footer b-t-muted"
                        style={{ backgroundColor: "white" }}
                      >
                        <div
                          className="input-group write-msg"
                          id="Chat-input"
                          style={{
                            border: "1px solid #bfbfbe",
                            padding: "2px 2px",
                          }}
                        >
                          {/* <input
                          type="text"
                          className="form-control input-value"
                          placeholder="Type a Message"
                          name="Message"
                        ></input> */}
                          <input
                            type="text"
                            className="form-control input-value"
                            placeholder="Type a Message"
                            name="Message"
                            value={this.state.message}
                            onChange={this.handleTextChange}
                            onKeyPress={this.handleKeyPress}
                            style={{ border: "none" }}
                          ></input>
                          {/* {this.state.picture_Path && (
                            <div>
                              {this.state.picture_Path.type.startsWith(
                                "image/"
                              ) && (
                                <img
                                  style={{ width: "5vh", height: "5vh" }}
                                  src={URL.createObjectURL(
                                    this.state.picture_Path
                                  )}
                                  alt="Uploaded Image"
                                />
                              )} */}
                          {/* {this.state.file.type.startsWith('video/') && (
              <video controls>
                <source src={URL.createObjectURL(this.state.file)} type={this.state.file.type} />
                Your browser does not support the video tag.
              </video>
            )} */}
                          {/* {this.state.picture_Path.type.startsWith(
                                "application/pdf"
                              ) && (
                                <embed
                                  src={URL.createObjectURL(
                                    this.state.picture_Path
                                  )}
                                  width="50"
                                  height="35"
                                  type="application/pdf"
                                />
                              )} */}
                          {/* {this.state.picture_Path.type.startsWith(
                                "application/msword"
                              ) && (
                                <iframe
                                  src={`https://docs.google.com/gview?url=${URL.createObjectURL(
                                    this.state.picture_Path
                                  )}&embedded=true`}
                                  width="50"
                                  height="35"
                                ></iframe>
                              )} */}
                          {/* </div>
                          )} */}
                          {picture_Path && (
                            <div>
                              <img
                                src={URL.createObjectURL(picture_Path)}
                                alt="Preview"
                                style={{ width: "5vh", height: "5vh" }}
                              />
                            </div>
                          )}
                          {document_Path && (
                            <div>
                              {/* <h4>Preview Document:</h4> */}
                              {document_Path.type ===
                              "application/pdf/msword" ? (
                                <embed
                                  src={URL.createObjectURL(document_Path)}
                                  width="50"
                                  height="60"
                                  type="application/pdf/msword"
                                />
                              ) : (
                                <p>{document_Path.name}</p>
                                // <p></p>
                              )}
                            </div>
                          )}
                          <input
                            type="hidden"
                            name="UserName"
                            defaultValue={this.props.values.email}
                          />
                          <span
                            className="fa fa-smile-o"
                            aria-hidden="true"
                            style={{ marginTop: "10px", fontSize: "18px" }}
                            onClick={this.myEmoji}
                          ></span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {/* <input
                          type="file"
                          id="file"
                          name="Picture_Path"
                          onFocus={(e) => (e.target.type = "file")}
                          style={{ display: "none" }}
                        /> */}
                          {showEmoji && (
                            <div
                              style={{
                                position: "absolute",
                                top: "-450px",
                                marginLeft: "300px",
                              }}
                            >
                              <Picker onEmojiClick={this.handleEmojiClick} />
                            </div>
                          )}
                          {/* <input
                          type="file"
                          id="file"
                          name="Picture_Path"
                          onFocus={(e) => (e.target.type = "file")}
                          style={{ display: "none" }}
                        /> */}
                          <input
                            type="file"
                            id="file"
                            name="Picture_Path"
                            accept="image/*"
                            // onChange={this.handleInputChange}
                            // onChange={this.handleImageUpload}
                            onChange={this.handleFileChange}
                            onFocus={(e) => (e.target.type = "file")}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="file" style={{ marginTop: "8px" }}>
                            <span className="fa fa-picture-o"> </span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="file"
                            id="files"
                            name="Document_Path"
                            onChange={this.handleDocumentChange}
                            accept="application/pdf,application/msword"
                            onFocus={(e) => (e.target.type = "file")}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="files" style={{ marginTop: "8px" }}>
                            <span className="fa fa-file"> </span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="input-group-btn">
                            <button
                              id="paper-btn"
                              className="btn btn-secondary"
                              type="submit"
                              style={{
                                border: "none",
                                background: "#ffffff",
                                color: "#5035ce",
                              }}
                            >
                              <i className="icofont icofont-paper-plane"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </form>
          </div>
        )}
      </>
    );
  }
}
