import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";

const EditBusinessPop = (props) => {
  const [formErrors, setFormErrors] = useState({});

  const Validation = () => {
    let errors = {};
    let formIsValid = true;
    var BusinessUnit = document.getElementById("BusinessUnit").value;

    if (!BusinessUnit) {
      formIsValid = false;
      errors["BusinessUnit"] = "Business Unit is required";
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (Validation()) {
      const data = new FormData(event.target);

      fetch(`${BASE_URLs}Business/EditBusiness`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(function () {
              document.getElementById("close").click();
            }, 4000);
          }
        });
    }
  };

  const w = window.innerWidth;
  const { BusinessUnit } = formErrors;

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="pop">
        <div
          className={
            w > 768
              ? "popinnernormal oneFieldPop"
              : "popinnernormal oneFieldPopMobile"
          }
          style={{ padding: "1%" }}
        >
          <button
            id="close"
            style={{
              float: "right",
              backgroundColor: "#a0a3a305",
              color: "#797070",
              border: "1px solid #a3a5a505",
            }}
            onClick={props.close}
          >
            <CloseIcon />
          </button>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="row" style={{ padding: "8px" }}>
              <div className="col-sm-3"></div>
              <div className="col-sm-8">
                <input
                  type="hidden"
                  name="Id"
                  defaultValue={props.values.id}
                />
                <input
                  className="form"
                  maxLength="50"
                  defaultValue={props.values.BusinessUnit}
                  id="BusinessUnit"
                  name="BusinessUnit"
                  type="text"
                  placeholder="Business Unit "
                />
                {BusinessUnit == null ? (
                  <span style={{ color: "#a29d9d", float: "left" }}>
                    {" "}
                    Name<span style={{ color: "red", float: "left" }}></span>
                  </span>
                ) : (
                  <span
                    style={{ color: "red", float: "left" }}
                    className={BusinessUnit ? " showError" : ""}
                  >
                    {BusinessUnit}
                  </span>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12" style={{ textAlign: "center" }}>
                <button
                  type="submit"
                  className="but"
                  style={{
                    height: "39.5px",
                    width: "86.36px",
                    marginTop: "-0.3%",
                  }}
                >
                  Save
                </button>
              </div>
            </div>
            <br />
          </form>
        </div>
      </div>
    </>
  );
};

export default EditBusinessPop;