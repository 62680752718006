import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class AddOfficeDocumentModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      ButtonDisabled:false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

    GetOfficeDocument() {
    let url = `${BASE_URLs}AdminOfficeDocuments/GetCompanyDocument`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          OfficeDocument: data,
          Doc: data,
        });
        // console.log("CD", data);
      });
  }


  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.Validation()) {
      const data = new FormData(e.target);
      this.setState({
        ButtonDisabled: true,
      });
      const url = `${BASE_URLs}AdminOfficeDocuments/AddOfficeDocument`;

      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };

      const response = await fetch(url, options);
      const result = await response.json();
      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({
          ButtonDisabled: true,
        });
        document.getElementById("AddPopup").style.display = "none";
        setTimeout(function () {
          if (document.getElementById("close")) {
            document.getElementById("close").click();
          }
        }, 4000);

        this.GetOfficeDocument();
        // this.props.close;
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  componentDidMount() {
    this.GetOfficeDocument();
  }
  
  Validation() {
    let formErrors = {};
    let formIsValid = true;
    // var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
    var OfficeDocumentTitle = document.getElementById(
      "OfficeDocumentTitle"
    ).value;
    var OfficeDocumentFile =
      document.getElementById("OfficeDocumentFile").value;
    var Category = document.getElementById("CategoryA").value;
    var OtherCategory = document.getElementById("ugtext").value;
    if (!Category || Category.trim() == "") {
      formIsValid = false;
      formErrors["Categoryerr"] = "Category is required";
    } else if (Category === "others") {
      if (!OtherCategory || OtherCategory.trim() == "") {
        formIsValid = false;
        formErrors["OtherCategoryerr"] = "Category is required";
      }
    }
    if (!OfficeDocumentTitle || OfficeDocumentTitle.trim() == "") {
      formIsValid = false;
      formErrors["OfficeDocumentTitleerr"] = "Document Title is required";
    } else if (!OfficeDocumentFile || OfficeDocumentFile.trim() == "") {
      formIsValid = false;
      formErrors["OfficeDocumentFileErr"] = "Document File is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  HandleUg() {
    var UG = document.getElementById("CategoryA").value;

    if (UG === "others") {
      document.getElementById("ugtext").value = "";
      document.getElementById("ugtext").type = "text";
    } else {
      document.getElementById("ugtext").value = "None";
      document.getElementById("ugtext").type = "hidden";
    }
  }
  handlechange = (e) => {
    let formErrors = {};
    let formIsValid = true;
    // var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
    var OfficeDocumentTitle = document.getElementById(
      "OfficeDocumentTitle"
    ).value;
    var OfficeDocumentFile =
      document.getElementById("OfficeDocumentFile").value;
    var Category = document.getElementById("CategoryA").value;
    var OtherCategory = document.getElementById("ugtext").value;
    if (!Category || Category.trim() == "") {
      formIsValid = false;
      formErrors["Categoryerr"] = "Category is required";
    } else if (!OfficeDocumentTitle || OfficeDocumentTitle.trim() == "") {
      formIsValid = false;
      formErrors["OfficeDocumentTitleerr"] = "Document Title is required";
    } else if (!OfficeDocumentFile || OfficeDocumentFile.trim() == "") {
      formIsValid = false;
      formErrors["OfficeDocumentFileErr"] = "Document File is required";
    } else if (Category === "others") {
      if (!OtherCategory || OtherCategory.trim() == "") {
        formIsValid = false;
        formErrors["OtherCategoryerr"] = "Category is required";
      }
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  };

  render() {
    var w = window.innerWidth;
    const {
      OfficeDocumentTitleerr,
      OfficeDocumentFileErr,
      Categoryerr,
      OtherCategoryerr,
    } = this.state.formErrors;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal threeFieldPop"
                : "popinnernormal threeFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="AddPopup"
          >
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>
            <br />
            <br />
            <form autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
              <div className="row" style={{ padding: "8px" }}>
                <div className="col-lg-4">
                  <select
                    className="form"
                    id="CategoryA"
                    name="Category"
                    onInputCapture={this.HandleUg}
                    onChange={this.handlechange}
                    placeholder="Location Type"
                    style={{ color: "#868b8f" }}
                  >
                    <option value="">Select Category</option>
                    <option value="HR">HR</option>
                    <option value="others">Others</option>
                  </select>{" "}
                  {Categoryerr == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Category
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {Categoryerr}
                    </div>
                  )}
                  <input
                    type="hidden"
                    className="form"
                    id="ugtext"
                    name="OtherCategory"
                  />{" "}
                  {OtherCategoryerr == null ? null : (
                    <div style={{ color: "red", float: "left" }}>
                      {OtherCategoryerr}
                    </div>
                  )}
                  <span className="underline" />
                </div>

                <div className="col-lg-4">
                  <input
                    className="form"
                    maxLength="50"
                    id="OfficeDocumentTitle"
                    onChange={this.handlechange}
                    name="OfficeDocumentTitle"
                    type="text"
                  />
                  {OfficeDocumentTitleerr == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Document Title{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {OfficeDocumentTitleerr}
                    </div>
                  )}{" "}
                  <span className="underline" />
                </div>

                <div className="col-lg-4">
                  <input
                    className="form"
                    id="OfficeDocumentFile"
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handlechange}
                    name="OfficeDocumentFile"
                    multiple
                    onFocus={(e) => (e.target.type = "file")}
                    type="text"
                  />
                  {OfficeDocumentFileErr == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Document File{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {OfficeDocumentFileErr}
                    </div>
                  )}{" "}
                  <span className="underline" />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    disabled={this.state.ButtonDisabled}
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
