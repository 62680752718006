import React, { useState } from "react";
import $ from "jquery";
import "../../Css/Sign-Up.css";
import LogoCompo from "../../Common/LogoCompo";
import { toast } from "react-toastify";
import { BASE_URLs } from "../../Config/Base";

const SignUp = ({ history }) => {
  const [state, setState] = useState({
    OPwd: "",
    NPwd: "",
    CPwd: "",
    formErrors: {},
    Profile_Pic: [],
    Notification: [],
    Eye: false,
    Eye1: false,
  });

  const togglePassword = (type) => {
    setState((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  const handleFormValidation = () => {
    let formErrors = {};
    let formIsValid = true;

    if (!state.NPwd) {
      formIsValid = false;
      formErrors["NPwdErr"] = "New Password is required";
    } else if (!/^(?=.*[A-Z])/.test(state.NPwd)) {
      formIsValid = false;
      formErrors["NPwdErr"] = "At least one Capital letter is required";
    } else if (!/^(?=.*[a-z])/.test(state.NPwd)) {
      formIsValid = false;
      formErrors["NPwdErr"] = "At least one small letter is required";
    } else if (!/\d/.test(state.NPwd)) {
      formIsValid = false;
      formErrors["NPwdErr"] = "At least one number is required";
    } else if (!/^(?=.*[-+_!@#$%^&*., ?])/.test(state.NPwd)) {
      formIsValid = false;
      formErrors["NPwdErr"] = "At least one Symbol is required";
    } else if (state.NPwd.length < 8) {
      formIsValid = false;
      formErrors["NPwdErr"] = "A Minimum of 8 characters are required";
    }
    else if (state.NPwd.includes(" ")) {
      formIsValid = false;
      formErrors["NPwdErr"] = "Spaces are not allowed in the New Password";
    }

    if (!state.CPwd) {
      formIsValid = false;
      formErrors["CPwdErr"] = "Confirm New Password is required";
    } else if (state.CPwd !== state.NPwd) {
      formIsValid = false;
      formErrors["CPwdErr"] =
        "New Password and Confirm Password don't match";
    }
    else if (state.CPwd.includes(" ")) {
      formIsValid = false;
      formErrors["CPwdErr"] = "Spaces are not allowed in the Confirm New Password";
    }

    setState((prevState) => ({ ...prevState, formErrors }));
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (handleFormValidation()) {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}Authenticate/ResetPassword`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      setTimeout(() => {
        history.push("/Login_form");
      }, 4000);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  
    let formErrors = {};
    let formIsValid = true;
  
    if (name === "NPwd") {
      if (!value) {
        formIsValid = false;
        formErrors["NPwdErr"] = "New Password is required";
      } else if (!/^(?=.*[A-Z])/.test(value)) {
        formIsValid = false;
        formErrors["NPwdErr"] = "At least one Capital letter is required";
      } else if (!/^(?=.*[a-z])/.test(value)) {
        formIsValid = false;
        formErrors["NPwdErr"] = "At least one small letter is required";
      } else if (!/\d/.test(value)) {
        formIsValid = false;
        formErrors["NPwdErr"] = "At least one number is required";
      } else if (!/^(?=.*[-+_!@#$%^&*., ?])/.test(value)) {
        formIsValid = false;
        formErrors["NPwdErr"] = "At least one Symbol is required";
      } else if (value.length < 8) {
        formIsValid = false;
        formErrors["NPwdErr"] = "A Minimum of 8 characters are required";
      }
      else if (value.includes(" ")) {
        formIsValid = false;
        formErrors["NPwdErr"] = "Spaces are not allowed in the  New Password";
      }
    }
  
    if (name === "CPwd") {
      if (!value) {
        formIsValid = false;
        formErrors["CPwdErr"] = "Confirm New Password is required";
      } else if (value !== state.NPwd) {
        formIsValid = false;
        formErrors["CPwdErr"] = "New Password and Confirm Password don't match";
      }
      else if (value.includes(" ")) {
        formIsValid = false;
        formErrors["CPwdErr"] = "Spaces are not allowed in the Confirm New Password";
      }
    }
  
    setState((prevState) => ({ ...prevState, formErrors }));
  };
  

  var w = window.innerWidth;

  const { NPwdErr, CPwdErr } = state.formErrors;

  return (
    <div>
      <div className="bgImg" />
      <div className="content">
        <br />
        <LogoCompo />
        <div
          className={
            w < 992
              ? "h-100 align-items-center justify-content-center"
              : "d-flex h-100 align-items-center justify-content-center"
          }
        >
          <div className="container">
            <div className="row">
              <div
                className={
                  w < 992
                    ? " col-lg-12 ml-auto mr-auto"
                    : "col-md-4 col-sm-6 ml-auto mr-auto"
                }
              >
                <div className="card-copy">
                  <div className="card-body">
                    <form id="myForm" onSubmit={handleSubmit}>
                      <br />
                      <br />
                      <br />{" "}
                      <div>
                        <h4 className="title1">
                          <span className="shadows">Reset Password</span>
                        </h4>
                      </div>
                      <div className="signUp">
                        <>
                          <div style={{ display: "flex" }}>
                            <input
                              type={!state.Eye ? "password" : "text"}
                              className="form"
                              onChange={handleChange}
                              id="NPwd"
                              name="NPwd"
                              maxLength="20"
                              placeholder="New Password"
                              autoComplete="off"
                            />
                            <span
                              className={
                                !state.Eye
                                  ? "icofont icofont-eye eyelogo"
                                  : "icofont icofont-eye-blocked eyelogo"
                              }
                              id="eye"
                              onClick={() => togglePassword("Eye")}
                            ></span>
                          </div>
                          <span className="underline" />
                          <span className={NPwdErr ? " showError" : ""}></span>
                          {NPwdErr && (
                            <div style={{ color: "red", paddingBottom: 10 }}>
                              {NPwdErr}
                            </div>
                          )}
                        </>

                        <>
                          <div style={{ display: "flex" }}>
                            <input
                              type={!state.Eye1 ? "password" : "text"}
                              className="form"
                              onChange={handleChange}
                              id="CPwd"
                              name="CPwd"
                              maxLength="20"
                              placeholder="Confirm Password"
                              autoComplete="off"
                            />
                            <span
                              className={
                                !state.Eye1
                                  ? "icofont icofont-eye eyelogo"
                                  : "icofont icofont-eye-blocked eyelogo"
                              }
                              id="eye"
                              onClick={() => togglePassword("Eye1")}
                            ></span>
                          </div>
                          <span className="underline" />
                          <span className={CPwdErr ? " showError" : ""}></span>
                          {CPwdErr && (
                            <div style={{ color: "red", paddingBottom: 10 }}>
                              {CPwdErr}
                            </div>
                          )}
                        </>
                      </div>
                      <div
                        className="text-center"
                        style={{ marginTop: "10px" }}
                      >
                        <button
                          type="submit"
                          className="but"
                          style={{ width: "83.36px" }}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;