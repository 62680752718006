import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../Config/Base";
export default class LogoutConfirmPop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      UserId: localStorage.getItem("userId"),
    };
  }
  componentDidMount() {
    let dd = localStorage.getItem("userId");
    sessionStorage.setItem("userIdValue", dd);
  }
  Logout() {
    var Id = sessionStorage.getItem("userIdValue");
    // var Id =  localStorage.getItem("foruserDetailId",Id)

    // console.log("IDDDDDDD", Id);

    let url = `${BASE_URLs}Team/UpdateActiveStatus?Id=${Id.split(`"`)[1]}`;

    // console.log("Url-----------", url);

    fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    });

    setTimeout(() => {
      localStorage.clear();
      var allCookies = document.cookie.split(";");
      for (var i = 0; i < allCookies.length; i++) {
        document.cookie =
          allCookies[i] + "=;expires=" + new Date(0).toUTCString();
      }

      document.getElementById("logout").click();

      //this.props.history.push('./Login_form')
    }, 2000);
  }

  render() {
    var w = window.innerWidth;
    return (
      <>
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
          >
            <h5>Are you sure you want to Logout? </h5>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <Link id="logout" to="/Login_form"></Link>
                <Link type="button" onClick={this.Logout} className="btn">
                  Yes
                </Link>
                &nbsp;&nbsp;&nbsp;
                <button id="noo" className="btn" onClick={this.props.close}>
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
