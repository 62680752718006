import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../../Config/Base";
export default class UserRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      formErrors: {},
      Pending: [],
      Notification: [],
      Profile_Pic: [],
      isButtonDisabledA: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();

    if (this.handleFormValidation()) {
      this.setState({ isButtonDisabledA: true });
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}UserRequest/AddRequest`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          document.getElementById("requestsave").disabled = true;
          document.getElementById("userrequest").reset();
          this.setState({ isButtonDisabledA: false });
        });
    }

    setTimeout(() => {
      document.getElementById("requestsave").disabled = false;
    }, 1000);

    setTimeout(() => {   
      var page = {
        pathname: "/Profile",
        
      };
      this.props.history.push(page);
    }, 4000);
  }

  handleChange = (e) => {
    let description = document.getElementById("description").value;
    let title = document.getElementById("title").value;
    let Type = document.getElementById("Type").value;
    let formErrors = {};
    let formIsValid = true;
    //Email
    if (!description) {
      formIsValid = false;
      formErrors["DescriptionErr"] = "Description is required";
    }
    if (!title) {
      formIsValid = false;
      formErrors["TitleErr"] = "Title is required";
    }
    if (!Type) {
      formIsValid = false;
      formErrors["TypeErr"] = "Request Type is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  };

  handleFormValidation() {
    let description = document.getElementById("description").value;
    let title = document.getElementById("title").value;
    
    let Type = document.getElementById("Type").value;
    let formErrors = {};
    let formIsValid = true;
    //Email
    if (!description) {
      formIsValid = false;
      formErrors["DescriptionErr"] = "Description is required";
    }
    if (!title) {
      formIsValid = false;
      formErrors["TitleErr"] = "Title is required";
    }
    if (!Type) {
      formIsValid = false;
      formErrors["TypeErr"] = "Request Type is required";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  GetUserProfile() {
    let url = `${BASE_URLs}Profile/Userprofile`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Profile_Pic: data,
        });
      });
  }
  componentDidMount() {
    this.GetUserProfile();

    this.Getpending();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
        //
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
        //
      });
  }
  Getall() {
    fetch(`${BASE_URLs}AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
        //
      });
  }
  Getpending() {
    fetch(`${BASE_URLs}AddOrganization/pendingcount`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Pending: data });
      });
  }

  render() {
    const { DescriptionErr, TypeErr, TitleErr } = this.state.formErrors;

    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar default active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">
                            {" "}
                            Add Request
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            id="userrequest"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="container" style={{ marginTop: "3%" }}>
    <div className="col-lg-12">

    <div className="row">
                                <div className="col-lg-3  "></div>

                                <div className="col-lg-6  ">
                                <input
                className="form"
                name="title"
                id="title"
                type="textarea"
                maxLength={50}
                placeholder="Subject"
                style={{ height: "32.6px", overflow: "hidden" }}
                onChange={this.handleChange}
            />
            {TitleErr == null ? (
                <span style={{ color: "#a29d9d" }}>Subject</span>
            ) : (
                <div style={{ color: "red" }}>{TitleErr}</div>
            )}
                                  <span className="underline" />
                                </div>
                                <div className="col-lg-3  "></div>
                              </div>    

                              <div className="row">
                                <div className="col-lg-3  "></div>

                                <div className="col-lg-6  ">
                                <textarea
                className="form"
                name="Description"
                id="description"
                type="textarea"
                placeholder="Description"
                style={{ height: "32.6px", overflow: "hidden" }}
                onChange={this.handleChange}
            />
            {DescriptionErr == null ? (
                <span style={{ color: "#a29d9d" }}>Description</span>
            ) : (
                <div style={{ color: "red" }}>{DescriptionErr}</div>
            )}
            <span className="underline" />
                                </div>
                                <div className="col-lg-3  "></div>
                              </div>
                              <div className="row">
                                <div className="col-lg-3  "></div>

                                <div className="col-lg-6  ">
                                <select
                className="form selectleftalign"
                onChange={this.handleChange}
                name="Type"
                id="Type"
            >
                <option value="">Request Type</option>
                <option value="ASkill">Skill</option>
                <option value="AEducation">Education</option>
                <option value="Ageneral">General</option>
                <option value="Aothers">Others</option>
            </select>
            {TypeErr == null ? (
                <span style={{ color: "#a29d9d" }}>Request Type</span>
            ) : (
                <div style={{ color: "red" }}>{TypeErr}</div>
            )}
            <span className="underline" />
                                </div>
                              </div>
        
    </div>
    <div className="col-sm-12" style={{ textAlign: "center" }}>
        <button
            id="requestsave"
            type="submit"
            className="but"
            style={{ height: "39.5px", width: "86.36px", marginTop: "-0.3%" }}
            disabled={this.state.isButtonDisabledA}
        >
            Send
        </button>
        &nbsp; &nbsp; &nbsp;
        <button
            type="reset"
            className=" btn-Secondary"
            style={{ height: "39.5px" }}
        >
            Cancel
        </button>
    </div>
    <br /> <br />
    <br /> <br />
</div>

                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
