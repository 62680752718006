import React, { Component } from "react";
import AddTrainerpop from "../../Common/AddTrainerpop";
import EditTrainerpop from "../../Common/EditTrainerpop";
import SideBar from "../../Common/Sidebar";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import { OutTable } from "react-excel-renderer";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../../Config/Base";
var XLSX = require("xlsx");
export default class TrainingSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      DepartmentName: "",
      DepartModel: false,
      comlogo: [],
      Department: [],
      Depart: [],
      formErrors: {},
      EditModel: false,
      EditDesigntionValues: [],
      Notification: [],
      rows: [],
      cols: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("DepartSheet");
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }

  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }

  GetDepartment() {
    // let url = `${BASE_URLs}Department/DepartmentGet";
    let url = `${BASE_URLs}Training/SAGetTraining`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Department: data,
          Depart: data,
        });
        // console.log("Training", data);

        //
      });
  }

  DepartModel = () => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    this.setState((prevState) => ({
      DepartModel: !prevState.DepartModel,
    }));
    this.GetDepartment();
  };
  EditModel = (e, fn) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditDesigntionValues: {
        id: e,
        DepartmentName: fn,
      },
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));
    this.GetDepartment();
  };

  componentDidMount() {
    this.GetOrgLogo();
    this.GetDepartment();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleFormValidation() {
    const {
      FirstName,
      LastName,
      Email,
      Mobile,
      Department,
      Designation,
      RoleName,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!FirstName) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "First Name is required";
    }
    if (!LastName) {
      formIsValid = false;
      formErrors["LastNameErr"] = "Last Name is required";
    }
    if (!Email) {
      formIsValid = false;
      formErrors["EmailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
      formErrors["EmailErr"] = "Invalid Email id.";
    }
    if (!Mobile) {
      formIsValid = false;
      formErrors["MobileErr"] = "Mobile is required";
    }
    // else if (!Mobile >= 10) {
    //   formIsValid = false;
    //   formErrors["MobileErr"] = "Invalid Mobile number.";
    // }
    else {
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
      if (!mobPattern.test(Mobile)) {
        formIsValid = false;
        formErrors["MobileErr"] = "Invalid phone number.";
      }
    }
    if (!Department) {
      formIsValid = false;
      formErrors["departmentErr"] = "department is required";
    }
    if (!Designation) {
      formIsValid = false;
      formErrors["designationErr"] = "designation is required";
    }
    if (!RoleName) {
      formIsValid = false;
      formErrors["rolesErr"] = "Role is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}Department/DepartmentExcel`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp === undefined) {
            alert("0  Records  inserted ");
          } else if (resp === 0) {
            alert("Records added Successfully");
          } else {
            alert(+resp + " Records not inserted ");
          }
          this.GetDepartment();
          this.setState({ initialData: undefined });
        });
      var file = document.getElementById("files");
      var emptyFile = document.createElement("input");
      emptyFile.type = "file";
      file.files = emptyFile.files;
    } catch (error) {}
    this.GetDepartment();
  };

  handleUpload = (event) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    var inputElement = document.getElementById("files");
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf("."));
    var allowedExtensionsRegx = /(\.xlsx)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);
    if (isAllowed) {
      const file = event.target.files[0];
      readFile(file).then((readedData) =>
        this.setState({ initialData: readedData })
      );
      /* file upload logic goes here... */
      return true;
    } else {
      alert("Invalid File Type.");
      return false;
    }
  };

  Noticount = async (Id) => {
    await fetch(`${BASE_URLs}Notification/Noticount`, {
      method: "PUT",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Notification_Id: Id,
      }),
    }).then((result) => {
      result.json().then((resp) => {
        localStorage.setItem("success", JSON.stringify(resp.status));

        var a = localStorage.getItem("success");
        var b = JSON.parse(a);
        if (b === "Success") {
          window.location.href = "/Organization";
          localStorage.removeItem("success");
        }
      });
    });
  };

  Handledepartment() {
    var department = document.getElementById("department").value;

    if (department === "others") {
      document.getElementById("departmenttext").value = "";
      document.getElementById("departmenttext").type = "text";
    } else {
      document.getElementById("departmenttext").value = "None";
      document.getElementById("departmenttext").type = "hidden";
    }
  }

  checkAll(e) {
    var checkboxes = document.getElementsByName("Id_delete");
    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + "  selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  handlecheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecount")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecount");
      let count = checkboxes.length - 1;

      if (document.getElementById("selectall").checked) {
        msg.innerHTML = "<span> " + count + " selected </span> ";
      } else {
        msg.innerHTML = "<span> " + checkboxes.length + " selected </span> ";
      }
    } else {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("selectall").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("selectall").checked = true;
      }
    } else {
      if (document.getElementById("selectall").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("selectall").checked = false;
        }
      }
    }
  }

  onKeyUp = (e) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    const Department = this.state.Depart.filter((item) =>
      item.department.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Department });
  };

  Multidelete = (event) => {
    event.preventDefault();
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    // if(document.getElementsByName('Id_delete')){
    // var checkboxes = document.getElementsByName('Id_delete');
    // for (var i = 0; i < checkboxes.length; i++) {
    //   checkboxes[i].checked = false;
    //   document.getElementById("delete").setAttribute('style', 'visibility: hidden;');
    //   document.getElementById("deletecount").setAttribute('style', 'visibility: hidden;');
    // }}
    const data = new FormData(event.target);

    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}Training/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetDepartment();
          alert(resp.message);
        });

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      //check.checked = false;
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
  };
  DeleteButton = (id) => {
    const data = new FormData();
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    // if(document.getElementsByName('Id_delete')){
    // var checkboxes = document.getElementsByName('Id_delete');
    // for (var i = 0; i < checkboxes.length; i++) {
    //   checkboxes[i].checked = false;
    //   document.getElementById("delete").setAttribute('style', 'visibility: hidden;');
    //   document.getElementById("deletecount").setAttribute('style', 'visibility: hidden;');
    // }}
    data.append("Id_delete", id);
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}Department/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetDepartment();
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      //check.checked = false;
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
  };
  render() {
    var username = JSON.parse(localStorage.uname);
    var filename = `O_${username}_Department`;

    const { Department } = this.state;
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div>
                            <div style={{ float: "left" }}>
                              <Link
                                to
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </Link>{" "}
                            </div>
                          </div>
                          <h4 style={{ marginRight: "4%" }} className="title">
                            {" "}
                            Trainers List{" "}
                          </h4>
                        </div>
                        {this.state.EditModel ? (
                          <EditTrainerpop
                            values={this.state.EditDesigntionValues}
                            close={() => this.EditModel()}
                          />
                        ) : null}
                        {this.state.DepartModel ? (
                          <AddTrainerpop close={() => this.DepartModel()} />
                        ) : null}

                        <div className="card-block">
                          <form onSubmit={this.handleSubmit}>
                            <div className="row">
                              <div className="col btnalign">
                                <Link
                                  type="button"
                                  onClick={this.DepartModel}
                                  style={{
                                    width: "150px",
                                    background: "white",
                                  }}
                                  className="btn "
                                >
                                  Add{" "}
                                </Link>
                              </div>
                              {/* <div className="col btnalign">
                         
                            <label htmlFor="files" className="btn" id="addBtn" name="Departments" >Import From Excel</label>
                            <input
                              id="files"
                              type='file'
                              accept='.xlsx'
                              style={{ display: 'none' }}
                              onChange={this.handleUpload}
                              name="Departments"
                            />
                             </div> */}
                              {/* <div className="col btnalign">
                                                        <button  className="btn" type="button" onClick={()=>this.exportXlsx(filename)}>
                              Export to excel
                                </button> */}

                              {/* <ReactHTMLTableToExcel
                            className="btn "
                            table="DepartSheet"
                            filename={filename}
                            sheet="Sheet"
                            buttonText="Export to Excel " id="exportxlUG" />     */}

                              {/* </div> */}
                              {/* <div className="col btnalign">
                                <a href={DepartmentExl} className="download" style={{padding:"2px 15px",boxShadow:" -2px  -2px 16px -2px #5f7ae14a"}}   ><i title="Download Template" className="icofont icofont-cloud-download"></i> </a>
                              </div> */}
                              <div className="col-lg-4">
                                {this.state.Depart.length === 0 ? null : (
                                  <input
                                    onKeyUp={this.onKeyUp}
                                    className="Searchbox"
                                    placeholder="Search"
                                    style={{ float: "right" }}
                                  />
                                )}
                              </div>
                            </div>

                            {this.state.initialData !== undefined ? (
                              <ReactExcel
                                initialData={this.state.initialData}
                                onSheetUpdate={(currentSheet) =>
                                  this.setState({ CurrentSheet: currentSheet })
                                }
                                activeSheetClassName="active-sheet"
                                reactExcelClassName="react-excel"
                                name="Departments"
                              />
                            ) : null}
                            {this.state.initialData !== undefined ? (
                              <div className="savebtndiv">
                                <button
                                  className="btn"
                                  id=""
                                  type="submit"
                                  style={{ width: "150px" }}
                                >
                                  RajaRajan
                                </button>
                              </div>
                            ) : null}

                            <OutTable
                              data={this.state.rows}
                              columns={this.state.cols}
                              tableClassName="ExcelTable2007"
                              tableHeaderRowclassName="heading"
                            />
                          </form>
                        </div>
                        <br />
                        <br />

                        <div className="card-block2">
                          <form onSubmit={this.Multidelete}>
                            <button
                              type="submit"
                              className="multitrash"
                              id="delete"
                              style={{ float: "right", visibility: "hidden" }}
                            >
                              <i
                                className="icofont icofont-delete-alt"
                                style={{ color: "darkgray" }}
                              ></i>
                            </button>
                            <div>
                              {" "}
                              <span
                                id="deletecount"
                                className="multitrash"
                                style={{
                                  visibility: "hidden",
                                  fontSize: "17px",
                                }}
                              ></span>
                            </div>
                            <div
                              id="selectalldiv"
                              style={{ visibility: "hidden" }}
                            ></div>

                            <div
                              className="dt-responsive table-responsive table-res"
                              // style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                              {this.state.Department.length === 0 ? null : (
                                <table
                                  id="DepSheet"
                                  className="table nowrap"
                                  style={{ maxWidth: "70%" }}
                                >
                                  <thead>
                                    <tr>
                                      <th>
                                        <input
                                          type="checkbox"
                                          id="selectall"
                                          onClick={() => {
                                            this.checkAll(this);
                                          }}
                                        />{" "}
                                      </th>
                                      <th>Trainer's Name</th>
                                      <th>Department</th>
                                      <th>Role</th>
                                      <th>Assigned date as a Trainer</th>
                                      <th>Current Training Session List</th>
                                      <th style={{ textAlign: "center" }}>
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Department.map((Department) => (
                                      <tr>
                                        <td>
                                          <input
                                            type="checkbox"
                                            name="Id_delete"
                                            onChange={this.handlecheckbox}
                                            defaultValue={
                                              Department.training_Id
                                            }
                                          />
                                        </td>
                                        <td>Sushma</td>
                                        <td>IT</td>
                                        <td>Trainer</td>
                                        <td>27-4-2024</td>
                                        <td>
                                          React Native Functional Component{" "}
                                        </td>

                                        <td style={{ textAlign: "center" }}>
                                          <button
                                            className="button-30 sk-edit"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.EditModel(
                                                Department.id,
                                                Department.department
                                              );
                                            }}
                                            // src="https://img.icons8.com/android/22/000000/pencil.png"
                                          >
                                            Edit&nbsp;
                                            <i
                                              className="fa fa-pencil"
                                              aria-hidden="true"
                                            ></i>
                                          </button>{" "}
                                          &nbsp;&nbsp;&nbsp;
                                          <button
                                            className="button-30 sk-del"
                                            onClick={() =>
                                              this.DeleteButton(Department.id)
                                            }
                                          >
                                            Delete&nbsp;
                                            <i
                                              className="fa fa-trash-o"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div>
                          </form>
                        </div>
                        <br />
                        <br />
                        <table
                          id="DepartSheet"
                          hidden
                          className="table nowrap"
                          style={{ maxWidth: "70%" }}
                        >
                          <thead>
                            <tr>
                              <th> Departments</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Department.map((Department) => (
                              <tr>
                                <td>{Department.department}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
