import React, { Component } from "react";
import AddOfficeDocumentModel from "../../Common/AddOfficeDocumentModel";
import EditDocumentModel from "../../Common/EditDocumentModel";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { ToastContainer, toast } from "react-toastify";
import CompanyDocExl from "../../Assets/Files/CompanyDocExl.xlsx";
import CloseIcon from "@material-ui/icons/Close";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import { OutTable } from "react-excel-renderer";
import { BASE_URLs } from "../../Config/Base"
import fileImg from "../../Assets/images/document.png";
import "../../Css/excel.css";
import { Image_URLs } from "../../Config/Base";
import DataTable from "react-data-table-component";
var XLSX = require("xlsx");

export default class AdminOfficeDocument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      DepartmentName: "",
      DocModel: false,
      comlogo: [],
      Location: [],
      formErrors: {},
      OfficeDocument: [],
      Notification: [],
      EditDocumentValues: [],
      EditModel: false,
      letters: [],
      Filterpop: false,
      Doc: [],
      rows: [],
      cols: [],
      FilterData: "",
      isOpen: false,
      Document: [],
      showExcel: false,
      filterValue:'',
      showSelectAll: false,
      showDelete: false,
      showDeleteCount: false,
      selectAllChecked: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("DocSheetExpo");
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }

  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });
      });
  }
  popupclose = () => {
    this.setState({
      isOpen: false
    })
  }
  DocModel = () => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState((prevState) => ({
      DocModel: !prevState.DocModel,
    }));

    this.GetOfficeDocument();
  };

  EditModel = (e, fn, fs, ofc) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditDocumentValues: {
        id: e,
        category: fn,
        officeDocumentTitle: fs,
        officeDocument: ofc,
      },
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));

    this.GetOfficeDocument();
  };

  componentDidMount() {
    this.GetOrgLogo();
    this.GetOfficeDocument();
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
  }

  getFileType(path) {
    const extension = path.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) {
      return 'image';
    } else if (['mp4', 'avi', 'mov', 'wmv', 'mkv'].includes(extension)) {
      return 'video';
    } else if (['pdf', 'xls', 'xlsx'].includes(extension)) {
      return 'document';
    } else {
      return 'file';
    }
  }
  GetOfficeDocument() {
    let url = `${BASE_URLs}AdminOfficeDocuments/GetCompanyDocument`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          OfficeDocument: data,
          Doc: data,
        });
        // console.log("CD", data);
      });
  }

  onKeyUp = (e) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    const OfficeDocument = this.state.Doc.filter(
      (item) =>
        item.category.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.officeDocumentTitle
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
    );
    this.setState({ OfficeDocument });
  };

  handleDocFilter = (letter, event) => {
    event.preventDefault();
    const OfficeDocument = this.state.Doc.filter((item) => {
      return (
        item.category.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.officeDocumentTitle
          .charAt(0)
          .toLowerCase()
          .includes(letter.toLowerCase())
      );
    });
    this.setState({
      OfficeDocument,
      showResults: true,
      FilterData: OfficeDocument,
    });
  };

  checkAll(e) {
    var checkboxes = document.getElementsByName("Id_delete");

    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;margin-top: 30px;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top: 30px;"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + "  selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  handlecheckbox = (state) => {
    const selectedRows = state.selectedRows;

    this.setState({ SelectedNames: selectedRows });
    this.setState({
      showSelectAll: selectedRows.length >= 2,
      showDelete: selectedRows.length >= 1,
      showDeleteCount: selectedRows.length > 0,
    });
  };

  // handlecheckbox() {
  //   let checkboxes = document.querySelectorAll(
  //     '#tablepadding input[type="checkbox"]:checked'
  //   );
  //   let Total = document.querySelectorAll('input[type="checkbox"]');
  //   // selectalldiv
  //   if (checkboxes.length >= 2) {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:visible ;");
  //   } else {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:hidden ;");
  //   }
  //   if (checkboxes.length >= 1) {
  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility:visible ;margin-top: 30px;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute(
  //         "style",
  //         "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top: 30px;"
  //       );
  //     var msg = document.getElementById("deletecount");
  //     let count = checkboxes.length - 1;

  //     if (document.getElementById("tablepadding").checked) {
  //       msg.innerHTML = "<span> " + count + " selected </span> ";
  //     } else {
  //       msg.innerHTML = "<span> " + checkboxes.length + " selected </span> ";
  //     }
  //   } else {
  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility: hidden;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute("style", "visibility: hidden;");
  //   }
  //   if (document.getElementById("selectall").checked === false) {
  //     if (checkboxes.length === Total.length - 1) {
  //       //
  //       document.getElementById("selectall").checked = true;
  //     }
  //   } else {
  //     if (document.getElementById("selectall").checked) {
  //       if (checkboxes.length - 2 !== Total.length) {
  //         document.getElementById("selectall").checked = false;
  //       }
  //     }
  //   }
  // }

  Multidelete = (event) => {
    event.preventDefault();
    const data = new FormData();
    this.state.SelectedNames.forEach((row) => {
      data.append("Id_delete", row.id);
    });

    // console.log("FormData:", data);
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${JSON.parse(localStorage.user)}`
        },
        body: data,
      };
      fetch(`${BASE_URLs}AdminOfficeDocuments/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      this.GetOfficeDocument();
      this.setState({ initialData: undefined });
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      //check.checked = false;
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
    this.GetOfficeDocument();
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  DeleteButton = (id) => {
    const data = new FormData();
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    // if(document.getElementsByName('Id_delete')){
    // var checkboxes = document.getElementsByName('Id_delete');
    // for (var i = 0; i < checkboxes.length; i++) {
    //   checkboxes[i].checked = false;
    //   document.getElementById("delete").setAttribute('style', 'visibility: hidden;');
    //   document.getElementById("deletecount").setAttribute('style', 'visibility: hidden;');
    // }}
    data.append("Id_delete", id);
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}AdminOfficeDocuments/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetOfficeDocument();
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      //check.checked = false;
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
  };

  handleUpload = (event) => {
    this.setState({ showExcel: true });

    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document.getElementById("delete").setAttribute("style", "visibility: hidden;");
        document.getElementById("deletecount").setAttribute("style", "visibility: hidden;");
      }
    }

    // Get the uploaded file
    var inputElement = document.getElementById("files");
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf("."));
    var allowedExtensionsRegx = /(\.xlsx|\.xlsm)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);

    // Check if the file is allowed
    if (isAllowed) {
      // Read the file using FileReader
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        // Check if the parsed data is empty
        if (parsedData.length === 0) {
          toast.error("The uploaded Excel file is empty.", { autoClose: 3200 });
          this.setState({ initialData: [], showExcel: false });
        } else {
          this.setState({ initialData: parsedData });
        }
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsBinaryString(files[0]);

      return true; // Indicate successful file upload
    } else {
      alert("Invalid File Type.");
      return false; // Indicate failed file upload
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}AdminOfficeDocuments/DocumentExcel`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp === undefined) {
            alert("0  Records  inserted ");
          }
          if (resp === 0) {
            alert("Records added Successfully");
          } else {
            alert(+resp + " Records not inserted ");
          }
          this.GetOfficeDocument();
          this.setState({ initialData: undefined });
        });
      var file = document.getElementById("files");
      var emptyFile = document.createElement("input");
      emptyFile.type = "file";
      file.files = emptyFile.files;
    } catch (error) { }
  };


  GetDocument(Id) {

    this.setState({
      isOpen: true
    })

    let url = `${BASE_URLs}AdminOfficeDocuments/GetDocument?Id=` + Id;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Document: data,
        });
        // console.log("user", data);
      });
  }

  cancel = () => {

    var inputElement = document.getElementById("files");
    inputElement.value = "";

    this.setState({ showExcel: false });

  };

  handleImageClick = (url) => {
 window.open(url, '_blank');
  };
  render() {
    var username = JSON.parse(localStorage.uname);
    const { OfficeDocument, Filterpop, FilterData, isOpen, Document, showExcel,filterValue } = this.state;
    var filename = `${username}_OfficeDocuments`;
    const customStyles = {
      headCells: {
        style: {
          flex: "0.5" 
        }
      },
      header: {
        style: {
          fontWeight: 'bold',
          fontSize: "14px",
          flex: "0"  
        },
      },
      headCells: {
        style: {
          fontWeight: 'bold',
          fontSize: "14px",
          flex: "0" ,
          borderBottom: "1px solid #ccc",
          
        },
      },
   
      columns: {
        idHeader: {
          style: {
            fontWeight: 'bold',
            fontSize: "14px",
            flex: "0" 
          },
        },
      },
    };

    const columns = [
      {
        name:'Title',
        selector: row => row.officeDocumentTitle,
        id: "isheader",
        grow: 1,
        sortable: false, 
      },
      {
        name:'Category',
        selector: row => row.category,
        id: "isheader",
        grow: 1,
        sortable: false, 
      },
      {
        name:'Office Document',
        selector: row => row.officeDocument,
        id: "isheader",
        grow: 1,
        sortable: false, 
        cell: row => <span style={{cursor:'pointer'}}   onClick={(e) => {
          e.preventDefault();
          this.GetDocument(row.id)
        }}>Show Document</span>,
      },
     
      {
        name: 'Actions',
        grow: 0,
        cell: row => (
          <div>
            <button
              className="button-30 sk-del"
              onClick={(e) => {
                e.preventDefault();
                this.DeleteButton(row.id);
              }}
              title="Delete"
              style={{ marginLeft: "-10px" }}
            >
              <i className="fa fa-minus" aria-hidden="true"></i>
            </button>
            &nbsp;&nbsp;
            {" "}
            <button
              className="Annbuttons sk-edit"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.EditModel(
                  row.id,
                  row.officeDocumentTitle,
                  row.category,
                  row.officeDocument
                );
              }}
              title="Edit"
            >
              <i className="fa fa-edit" aria-hidden="true"></i>
            </button>
          </div>
        )
      }
    ];
    return (
      <div>
        <SideBar active={window.location.hash} />

        {this.state.DocModel ? (
          <AddOfficeDocumentModel close={() => this.DocModel()} />
        ) : null}
        {this.state.EditModel ? (
          <EditDocumentModel
            values={this.state.EditDocumentValues}
            close={() => this.EditModel()}
          />
        ) : null}
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">
                            Office Documents{" "}
                          </h4>
                        </div>
                        {isOpen ?
                          <div className="col-lg-12">
                            <div className="popup" style={{ textAlign: 'left' }}>
                              <div className="popup-content">
                                <div className="Announcedetailspop" >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                    style={{
                                      color: "gray",
                                      marginLeft: "90%",
                                      fontSize: "17px",
                                      cursor: 'pointer'
                                    }}
                                    onClick={this.popupclose}
                                  ></i>
                                  <br />
                                  <center><h6><b>Attachment</b></h6></center>
                                  <div className="card-block">

                                    {Document.map((OfficeDocument) => (
                                      <>
                                        <div style={{ height: "237px", overflow: "scroll" }}>
                                          {OfficeDocument.officeDocument.split(',').map((imageUrl, index) => (
                                            <div style={{ display: "inline-block", marign: "10px" }} key={index}>

                                              <div style={{ margin: "10px" }}>
                                                {this.getFileType(imageUrl) === 'image' ? (

                                                  <span style={{ margin: "30px" }}>
                                                    <b>Image:&nbsp;</b>

                                                    {imageUrl.split(',').map((Url, index) => (
          <img
            key={index}
            alt={`Image ${index}`}
            src={imageUrl.trim()} 
            style={{ width: "100px", height: "100px" }}
            onClick={() => this.handleImageClick(Url.trim())}
          />
        ))}
                                                    {/* <img
                                                      alt={`Image ${index}`}
                                                      src={imageUrl}
                                                   
                                                      style={{ width: "100px", height: "100px" }}
                                                      onClick={() => this.handleImageClick(OfficeDocument.officeDocument)}
                                                    /> */}
                                                  </span>
                                                ) : this.getFileType(imageUrl) === 'video' ? (
                                                  <span style={{ display: "flex", margin: "30px" }}>
                                                    <span><b>video:</b></span>
                                                    <video
                                                      controls
                                                      style={{ width: "125px", height: '118px', marginTop: '-62px', marginLeft: "8px" }}
                                                    >
                                                      <source src={imageUrl} type="video/mp4" />
                                                      <source src={imageUrl} type="video/ogg" />
                                                    </video>
                                                  </span>
                                                ) : (
                                                  <span style={{ margin: "30px" }}>
                                                    <b>File:</b>
                                                    <img
                                                      src={fileImg}
                                                      style={{ height: "30px", width: "30px" }}
                                                    />
                                                    &nbsp;
                                                    <a
                                                      href={imageUrl}
                                                      name="picture"
                                                      readOnly
                                                      value={imageUrl}
                                                      title=""
                                                      target="_blank"
                                                    >
                                                      <span>
                                                        {imageUrl.includes(`https://localhost:44369/Images/OfficeDocument_`) ?
                                                          <>  {imageUrl.replace("https://localhost:44369/Images/OfficeDocument_", "")}</> :
                                                          <>  {imageUrl.replace("https://apitest.oneawesometeam.com/Images/OfficeDocument_", "")}</>
                                                        }
                                                        {/* {imageUrl.replace("https://localhost:44369/Images/OfficeDocument_", "")} */}

                                                      </span>
                                                    </a>
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </>
                                    ))}
                                    {/* <div>
                                  {SaAnnouncement &&
                                    SaAnnouncement.map &&
                                    SaAnnouncement.map((Announcement) => (
                                      <>
                                        {Announcement.image === "-" &&
                                          Announcement.video === "-" ? (
                                          <div >
                                            <div
                                            >
                                              <span
                                                key={Announcement.id}
                                                style={{ margin: "0%" }}
                                              >

                                                <b>Subject:</b> &nbsp; <span
                                                  style={{ overflowWrap: "break-word", width: "50%", }}
                                                > {Announcement.title}</span><br /><br />
                                                <b>Description:</b> &nbsp; <span
                                                  style={{ overflowWrap: "break-word", width: "50%", }}> {Announcement.message}</span>

                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                          <div style={{ width:"97%",height: "237px", overflow: "scroll"}}>
                                            {Announcement.image.split(',').map((imageUrl, index) => (
                                              <div style={{ display: "inline-block", marign: "10px" }} key={index}>
                                                {index === 0 && (
    <>
    <span style={{ margin: "38px" }}>
      <b>Subject:</b> &nbsp;
      <span style={{ overflowWrap: "break-word", width: "50%" }}>
        {Announcement.title}
      </span>
    </span>
    <br /><br />
    <span style={{ margin: "38px" }}>
      <b>Description:</b> &nbsp;
      <span style={{ overflowWrap: "break-word", width: "50%" }}>
        {Announcement.message}
      </span>
    </span>
    <br /><br />
    </>
)}
                                                <div style={{margin:"10px"}}>
                                                {this.getFileType(imageUrl) === 'image' ? (
                                                  
                                                  <span style={{ margin:"30px" }}>
                                                    <b>Image:</b> 
                                                    <img
                                                      alt={`Image ${index}`}
                                                      src={imageUrl}
                                                      style={{ width: "100px", height: "100px" }}
                                                    />
                                                  </span>
                                                ) : this.getFileType(imageUrl) === 'video' ? (
                                                  <span style={{ display: "flex", margin:"30px" }}>
                                                    <span><b>video:</b></span>
                                                    <video
                                                      controls
                                                      style={{ width: "125px", height: '118px', marginTop: '-62px', marginLeft:"8px" }}
                                                    >
                                                      <source src={imageUrl} type="video/mp4" />
                                                      <source src={imageUrl} type="video/ogg" />
                                                    </video>
                                                  </span>
                                                ) : (
                                                  <span style={{margin:"30px"}}>
                                                    <b>File:</b> 
                                                    <img
                                                      src={fileImg}
                                                      style={{ height: "30px", width: "30px" }}
                                                    />
                                                    &nbsp;
                                                    <a
                                                      href={imageUrl}
                                                      name="picture"
                                                      readOnly
                                                      value={imageUrl}
                                                      title=""
                                                      target="_blank"
                                                    >
                                                      <span>
                                                      {imageUrl.replace(
                                                    `${Image_URLs}/Images/`,
                                                    " "
                                                  )}
                                                      </span>
                                                    </a>
                                                  </span>
                                                )}
                                                </div>
                                                </div>
                                            ))}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    ))}
                                </div> */}
                                  </div>
                                </div> </div> </div></div> : null}
                        <div className="card-block">
                          <form onSubmit={this.handleSubmit}>
                            <div className="row">
                              <div className="col btnalign">
                                <h5 className="card-header-text">
                                  <Link
                                    onClick={this.DocModel}
                                    className="btn"
                                    style={{ width: "150px" }}
                                    onKeyPress={this.handleKeyPress}
                                  >
                                    Add{" "}
                                  </Link>
                                </h5>
                              </div>
                              <div className="col btnalign" >
                                <label
                                  htmlFor="files"
                                  className="btn"
                                  id="addBtn"
                                  name="OfficeDocumentFiles"
                                >
                                  Import From Excel
                                </label>
                                <input
                                  id="files"
                                  type="file"
                                  accept=".xlsx"
                                  style={{ display: "none" }}
                                  onChange={this.handleUpload}
                                  name="OfficeDocumentFiles"
                                />
                              </div>
                              <div style={{ padding: "7px" }} ></div>
                              <div className="col btnalign">
                                <button
                                  className="btn"
                                  type="button"
                                  onKeyPress={this.handleKeyPress}
                                  onClick={() => this.exportXlsx(filename)}
                                >
                                  Export to excel
                                </button>
                              </div>
                              <div
                                className="col btnalign"
                                onKeyDown={this.handleKeyPress}
                              >
                                <a
                                  className="btn"
                                  href={CompanyDocExl}
                                  type="button"
                                  style={{ color: "#5035ce" }}
                                >
                                  <i
                                    title="Download Template"
                                    className="icofont icofont-download"
                                  ></i>{" "}
                                  Download a template
                                </a>
                              </div>
                              <OutTable
                                data={this.state.rows}
                                columns={this.state.cols}
                                tableClassName="ExcelTable2007"
                                tableHeaderRowclassName="heading"
                              />
                              {/* <div
                                className="col btnalign"
                                onKeyDown={this.handleKeyPress}
                              >
                                <a
                                  className="btn"
                                  href={CompanyDocExl}
                                  type="button"
                                  style={{ color: "#5035ce" }}
                                >
                                  <i
                                    title="Download Template"
                                    className="icofont icofont-download"
                                  ></i>{" "}
                                  Download
                                </a>
                              </div> */}
                              <div
                                className="col-lg-1"
                                style={{
                                  height: "73px",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {/* Just for design */}
                              </div>


                              <div>
                                <input
                                  onKeyUp={this.onKeyUp}
                                  className="Searchbox"
                                  id="CNSearch"
                                  placeholder="Search"
                                  onKeyPress={this.handleKeyPress}
                                  onClick={() => {
                                    this.setState({
                                      // Filterpop: true,
                                      Filterpop: !this.state.Filterpop,
                                    });
                                  }}
                                  readOnly
                                />
                              </div>
                            </div>
                            <div>
                                <input
                                  className="Searchbox"
                                  id="CNSearch1"
                                  placeholder="Search"
                                  onKeyPress={this.handleKeyPress}
                                  onKeyUp={this.myFunction}
                                  value={filterValue}
                                  onClick={() => {
                                    this.setState({
                                      Filterpop: !this.state.Filterpop,
                                    });
                                  }}
                                  readOnly
                                />
                              </div>
                            <center>
                              {showExcel && this.state.initialData !== undefined && (
                                <table className="table" id="tableDesign">
                                  <thead >
                                    <tr>
                                      {Object.keys(this.state.initialData[0]).map((key) => (
                                        <th key={key} id="team1design">{key}</th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.initialData.map((row, index) => (
                                      <tr key={index}>
                                        {Object.values(row).map((value, index) => (
                                          <td key={index} id="teamdesign">{value}</td>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </center>
                            {showExcel && this.state.initialData !== undefined && (
                              <div className="savebtndiv">
                                <button
                                  className="btn"
                                  id=""
                                  type="submit"
                                  style={{ width: "150px" }}
                                >
                                  Save
                                </button>
                                <span
                                  className="btn"
                                  id="add-row"
                                  style={{ width: "150px" }}
                                  onClick={this.cancel}
                                >
                                  Cancel
                                </span>

                              </div>)}
                            <br />
                          </form>
                        </div>


                        <div className="card-block2">
                         <form
                            onSubmit={this.Multidelete}
                            onKeyPress={this.handleKeyPress}
                            // style={{ marginTop: "-2%" }}
                            style={{ marginTop:'-28px' }}
                          >
                            <button
                              type="submit"
                              className="multitrash"
                              id="delete"
                              style={{ float: "right", visibility: this.state.showDelete ? 'visible' : 'hidden',marginTop:"30px" }}
                            >
                              <i
                                className="icofont icofont-delete-alt"
                                style={{ color: "darkgray" }}
                              ></i>
                            </button>
                            <span
                              id="deletecount"
                              className="multitrash"
                              style={{ visibility: this.state.showDeleteCount ? 'visible' : 'hidden', fontSize: "17px",marginTop:"30px" }}
                            >
                              {this.state.showDeleteCount && (
                              <span style={{backgroundColor: "#007bff",background: "linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)"}}>{this.state.SelectedNames.length} selected</span>
                               )}
                            </span>
                            <div id="selectalldiv" style={{ visibility: this.state.showSelectAll ? 'hidden' : 'hidden' }}>
                            <input type="checkbox" id="selectall" checked={this.state.selectAllChecked} onChange={this.handleSelectAllChange} />&nbsp;&nbsp;
                            </div>

                          <center>
                            <div className="dt-responsive table-responsive" style={{maxWidth:"60%"}}>
        <DataTable
          columns={columns}
          data={OfficeDocument}
          selectableRows
          onSelectedRowsChange={this.handlecheckbox} // Assuming handlecheckbox is a method to handle row selection
          pagination
          customStyles={customStyles}
        />
      </div>
      </center>

                          
                        </form>
                        <br />
                        <br />
                    </div>
                    <table
                      id="DocSheetExpo"
                      className="table nowrap"
                      style={{ display: "none" }}
                    >
                      <thead>
                        <tr>
                          {/* <th><input type="checkbox" id="selectall"  onClick= {() => {this.checkAll(this)}} /> </th> */}
                          <th>Category </th>
                          <th> Title </th>
                          {/* <th>Office Document </th> */}
                          {/* <th>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {OfficeDocument.map((OfficeDocument) => (
                          <tr>
                            {/* <td><input type="checkbox" name="Id_delete" onChange={this.handlecheckbox}
                                           defaultValue={OfficeDocument.id} /></td> */}
                            <td> {OfficeDocument.category} </td>
                            <td>{OfficeDocument.officeDocumentTitle}</td>
                            {/* //<td><a href={OfficeDocument.officeDocument}   id="if"value={OfficeDocument.officeDocument} title="Iframe Example" target="_blank" style={{width:'200px',height:'200px'}}>Show Document</a></td> */}
                            {/* <td>
                                            
                                          <button className="button-30 sk-edit" type="button"
                        
                        onClick={(e)=>{e.preventDefault();this.EditModel(OfficeDocument.id,OfficeDocument.category,OfficeDocument.officeDocumentTitle,OfficeDocument.officeDocument)}}
                        //  src="https://img.icons8.com/android/22/000000/pencil.png"/

                         >Edit&nbsp;<i className="fa fa-pencil" aria-hidden="true"></i> </button>&nbsp;&nbsp;&nbsp; 
                                        
                                    </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {Filterpop && (
              <div className="popSearchTeam">
                <div className="popSearchinnernormalTeam">
                  <div
                    className="popSearchinnertitle"
                    id="popSearchinnernormal"
                  >
                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                        marginTop: "-20px",
                        marginRight: "-20px",
                      }}
                      onClick={() => {
                        this.setState({ Filterpop: false });
                        this.GetOfficeDocument();
                      }}
                    >
                      <CloseIcon />
                    </button>

                    <div className="checkboxes">
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                        style={{
                          fontSize: "20px",
                          color: "gray",
                          marginLeft: "-1%",
                          position: "absolute",
                          marginTop: "0.5%",
                        }}
                      ></i>
                      <input
                        className="Searchbox"
                        placeholder="Search"
                        onKeyUp={this.onKeyUp}
                        style={{ float: "right", width: "80%" }}
                      />
                      <br></br>
                      {this.state.letters.map(
                        (letter, index) => (
                          <button
                            className="filter"
                            key={index}
                            onClick={(event) =>
                              this.handleDocFilter(
                                letter,
                                event
                              )
                            }
                          >
                            {letter}
                          </button>
                        )
                      )}
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* End */}
          </div>
        </div>
      </div>
    );
  }
}
