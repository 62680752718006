import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class AddCoursePop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RelationshipDrop: [],
      formErrors: {},
      CourseName: [],
    };
  }

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };

  handleSubmit = async (e) => {
    var CourseName = document.getElementById("CourseName").value;
    e.preventDefault();

    if (this.Validation()) {
      const data = new FormData();
      data.append("CourseName", CourseName);
      var role = JSON.parse(localStorage.Role);
      const url =
        role.toString() === "Platform Admin"
          ? `${BASE_URLs}SuperAdmin/SACourse`
          : `${BASE_URLs}Course/AddCourseName`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        document.getElementById("Addpop").style.display = "none";
        setTimeout(function () {
          document.getElementById("close").click();
        }, 4000);

        this.GetCourseName();
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        document.getElementById("Addpop").style.display = "none";
        setTimeout(function () {
          document.getElementById("close").click();
        }, 4000);
      }
    }
    this.GetCourseName();
  };

  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;

    let formErrors = {};
    let formIsValid = true;
    var CourseName = document.getElementById("CourseName").value;

    // var otherdepartment = document.getElementById("departmenttext").value
    if (!CourseName) {
      formIsValid = false;
      formErrors["CourseName"] = "Course Name  is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var CourseName = document.getElementById("CourseName").value;

    // var otherdepartment = document.getElementById("departmenttext").value
    if (!CourseName) {
      formIsValid = false;
      formErrors["CourseName"] = "Course Name  is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  Handledepartment() {
    var DepartmentName = document.getElementsByName("DepartmentName"); //.value;

    if (DepartmentName[0].value === "Others") {
      document.getElementById("departmenttext").value = "";
      document.getElementById("departmenttext").type = "text";
    }
  }

  GetCourseName() {
    let url = `${BASE_URLs}Course/GetCourseName`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          CourseName: data,
        });
      });
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  componentDidMount() {
    this.GetCourseName();
  }
  render() {
    var w = window.innerWidth;

    const { otherdepartment, CourseName } = this.state.formErrors;
    //    const {CourseName}=this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="Addpop"
          >
            <form autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
              <button
                id="close"
                style={{
                  float: "right",
                  backgroundColor: "#a0a3a305",
                  color: "#797070",
                  border: "1px solid #a3a5a505",
                }}
                onClick={this.props.close}
              >
                <CloseIcon />
              </button>

              <div className="row" tyle={{ padding: "8px" }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <input
                    className="form"
                    maxLength="50"
                    id="CourseName"
                    onChange={(e) => {
                      this.AutoCaps(e);
                    }}
                    name="CourseName"
                    type="text"
                    onKeyPress={this.handleKeyPress}
                    onKeyDown={this.handleKeyDown}
                  />

                  {!CourseName ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Add Course
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {CourseName}
                    </div>
                  )}
                  <input
                    type="hidden"
                    Name="otherdepartment"
                    className="form"
                    id="departmenttext"
                  />
                  {!otherdepartment ? null : (
                    <div style={{ color: "red", float: "left" }}>
                      {otherdepartment}
                    </div>
                  )}
                  <span className="underline" />
                </div>
                <div className="col-sm-4"></div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
            <br />
          </div>
        </div>
      </>
    );
  }
}
