import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/images/OATlogo.png";
import OTPmsgpop from "../../Common/OTPmsgpop";
import { ToastContainer, toast } from "react-toastify";
import Planet from "../../Common/Planet";
import { BASE_URLs } from "../../Config/Base"
import { Image_URLs } from "../../Config/Base"

const AdminOtp = (props) => {
  const [otp, setOtp] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [successModel, setSuccessModel] = useState(false);
  const [counter, setCounter] = useState(60);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);
  
    // When counter reaches 0, clear the interval and show the button
    if (counter === 0) {
      clearInterval(timer);
      localStorage.clear();
      document.getElementById("rrr").style.display = "initial";
      document.getElementById("timer").style.display = "none";
    }
  
    return () => clearInterval(timer);
  }, [counter]);
  const handleFormValidation = () => {
    let errors = {};
    let isValid = true;

    if (!otp) {
      isValid = false;
      errors["otpErr"] = "OTP is Required";
    }
    else if (otp.charAt(0) === " " || otp.charAt(otp.length - 1) === " ") {
      isValid = false;
      errors["otpErr"] = "First and last characters cannot be spaces";
    }
    setFormErrors(errors);
    return isValid;
  };

  const handleSuccessModel = () => {
    setSuccessModel((prevModel) => !prevModel);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!navigator.onLine) {
      return alert("You are Offline, Check your internet Connection");
    }

    
    let OTP = localStorage.getItem("amcode");
if(OTP === null)
{
  toast.error("OTP Expired", {
    position: "top-center",
    autoClose: 3200,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}
else
{
  const myDecipher = decipher("mySecretSalt");
  OTP = JSON.parse(myDecipher(OTP).toString());
  const enteredOtp = otp.toString();
  
  

  if (handleFormValidation()) {
    setLoading(true);

    

    if (OTP.toString() === enteredOtp) {
      const data = new FormData();
      Object.entries(props.location.state).forEach(([key, value]) => {
        data.set(key, value);
      });

      try {
        const response = await fetch(`${BASE_URLs}Authenticate/register`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Origin": `${Image_URLs}`,
          },
          body: data,
        });

        const resp = await response.json();

        if (resp.status === "Successcreated") {
          localStorage.removeItem("amcode");
          localStorage.removeItem("Id");
          setTimeout(() => {
            setLoading(false);
          }, 500);
          handleSuccessModel();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 500);

      toast.error("Invalid OTP !!", {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setOtp("");
    }
}

    }
  };

  const handleReset = async (e) => {
    e.preventDefault();

    setCounter(60);

    // Check if props.location and props.location.state are defined
    if (!props.location || !props.location.state) {
      console.error("Props or props.location.state is undefined");
      return;
    }

    const { Email, Name } = props.location.state;

    // Check if Email and Name are defined
    if (!Email || !Name) {
      console.error("Email or Name is undefined in props.location.state");
      return;
    }

    document.getElementById("rrr").setAttribute("style", "display:none;");
    document.getElementById("timer").setAttribute("style", "display:contents;");

    const v = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 0) {
          clearInterval(v);
          document.getElementById("rrr").setAttribute("style", "display:initial;");
          document.getElementById("timer").setAttribute("style", "display:none;");
          return 60; // Reset counter to 60
        } else {
          return prevCounter - 1;
        }
      });
    }, 1000);

    const url = `${BASE_URLs}Forgot/AdminResendOtp?Email=${Email}&Username=${Name}`;
    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
      },
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      if (result.status === "Success") {
        sessionStorage.clear();
        toast.success("Resended OTP Successfully", {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      //   setTimeout(() => {
      //     document.getElementById("rrr").setAttribute("style", "display:contents ;");
      //     document
      // .getElementById("timer")
      // .setAttribute("style", "display:none ;");
      // setCounter(60);
      //   }, 3200);
        
      } 
      const myCipher = cipher("mySecretSalt");
      localStorage.setItem("amcode", myCipher(JSON.stringify(result.otp)));
    } catch (error) {
      console.error(error);
    }
  };

  const decipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
      encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
  };

  const cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) =>
      text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
  };

  var w = window.innerWidth;

  const { otpErr } = formErrors;

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {loading && <Planet />}
      <div>
        <div className="bgImg" />
        <div className="content">
          <br />
          <div className="row">
            <div className="col-sm-12" style={{ textAlign: "center" }}>
              <img
                alt="logo"
                src={logo}
                style={{ height: "80px", width: "80px" }}
              />
              &nbsp;&nbsp;&nbsp;
              <h3 style={{ color: "white" }}>One Awesome Team</h3>
            </div>
          </div>

          <br />
          <br />
          {w > 500 ? (
            <>
              {" "}
              <br />
              <br />
            </>
          ) : null}

          <div
            className={
              w < 992
                ? "  h-100 align-items-center justify-content-center"
                : " d-flex h-100 align-items-center justify-content-center"
            }
          >
            <div className="container">
              <div className="row">
                <div
                  className={
                    w < 992
                      ? " col-sm-12 ml-auto mr-auto"
                      : "col-md-4 col-sm-6 ml-auto mr-auto"
                  }
                >
                  <div className="card-copy">
                    <div className="card-body">
                      {successModel && (
                        <OTPmsgpop id="Success" close={handleSuccessModel} />
                      )}

                      <form id="myForm" onSubmit={handleSubmit}>
                        <br />
                        <br />
                        <br />
                        <div className="glowBox">
                          <h4 className="title">OTP</h4>
                        </div>

                        <div className="signUp">
                          <span>
                            <input
                              type="hidden"
                              name="Id"
                              value={localStorage.Id}
                            />
                            <input
                              className="form"
                              name="otp"
                              type="text"
                              id="OTP"
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                              placeholder="Enter OTP"
                            />
                            {otpErr && (
                              <span style={{ color: "red", paddingBottom: 10 }}>
                                {otpErr}
                              </span>
                            )}
                            <span className="underline" />
                          </span>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn"
                            style={{ width: "83.36px" }}
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button
                            onClick={handleReset}
                            id="rrr"
                            className="btn"
                          >
                            {" "}
                            Resend OTP
                          </button>
                          <span id="timer" style={{ color: "gray" }}>
                            OTP Expires in{" "}
                            <span style={{ color: "green" }}>
                              <b>{counter}</b>
                            </span>{" "}
                            Seconds{" "}
                          </span>
                        </div>
                        <br />
                        <div style={{ textAlign: "center" }}>
                          {" "}
                          {props.location.state ? (
                            <Link style={{ color: "#b152e9" }} to="/Sign-up">
                              If OTP not received, Please confirm your Email is{" "}
                              {props.location.state.Email}
                            </Link>
                          ) : null}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminOtp;