import React, { Component } from "react";
import SideBar from "./Sidebar";
import CloseIcon from "@material-ui/icons/Close";
import TimeAgo from "react-timeago";
import Userchating from "../Screen/User/Userchating";
import Picker from "emoji-picker-react";
import EntityListUser from "../Screen/User/EntityListUser";
import { BASE_URLs } from "../Config/Base";
export class UserChatPopUp extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(null);
    this.state = {
      AddSkillModels: false,
      isButton1Visible: true,
      Allmessage: [],
      Usermessage: [],
      Userside: [],
      Receive: [],
      Send: [],
      Team: [],
      Teams: [],
      Popmsg: [],
      ReceivepopMsg: [],
      UserName: [],
      ParticularId: [],
      display: [],
      displayName: [],
      filterDisplayName: "",
      createDateTime: new Date(),
      chosenEmoji: null,
      showEmoji: false,
      setShowEmoji: "",
      active: false,
      msg: "",
      message: "",
      picture_Path: null,
      document_Path: null,
      selectedDocumentName: "",
    };
    this.handleEmojiClick = this.handleEmojiClick.bind(this);
    this.myEmoji = this.myEmoji.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.AddSkillModels = this.AddSkillModels.bind(this);
  }
  handleFileChange = (event) => {
    this.setState({ picture_Path: event.target.files[0] });
  };
  handleTextChange = (event) => {
    this.setState({ message: event.target.value });
  };
  scrollIntoView() {
    this.myRef.current?.scrollIntoView();
  }
  handleDocumentChange = (event) => {
    const selectedDocument = event.target.files[0];
    this.setState({
      document_Path: selectedDocument,
      selectedDocumentName: selectedDocument.name,
    });
  };
  handleArrowClick() {
    this.AddSkillModels();
    this.props.close();
  }
  // Team() {
  //   const options = {
  //     method: "GET",
  //       headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
  //     },
  //   };
  //   fetch(`${BASE_URLs}Team/GetName", options)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       this.setState({
  //         Team: data,
  //         Teams: data,
  //       });
  //       // console.log("UserSide", data);
  //     });
  // }

  // GetReceivemsgPop(FirstName) {
  //   const options = {
  //     method: "GET",
  //       headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
  //     },
  //   };

  //   fetch(
  //     `${BASE_URLs}Contact/GetReceivemsgPop?FirstName=" +
  //       FirstName,
  //     options
  //   )
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Error: " + response.status);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       this.setState({
  //         ReceivepopMsg: data,
  //       });
  //       // console.log("ReceivepopMsg", data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user messages:", error);
  //     });
  // }
  myEmoji = () => {
    const changeState = this.state.showEmoji;
    if (changeState) {
      this.setState({
        showEmoji: false,
      });
    } else {
      this.setState({
        showEmoji: true,
      });
    }
  };
  handleEmojiClick = (emojiObject) => {
    this.setState((prevState) => ({
      message: prevState.message + emojiObject.emoji,
      showEmoji: false,
    }));
  };
  GetUserChatPop(User_Name) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };

    fetch(`${BASE_URLs}contact/GetUserName?UserId` + User_Name, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          ReceivepopMsg: data,
        });
        // console.log("ReceivepopMsg", data);
      })
      .catch((error) => {
        console.error("Error fetching user messages:", error);
      });
  }

  handleSubmit = (event) => {
    const { message, picture_Path, document_Path } = this.state;
    if (!message.toString().trim() && !picture_Path && !document_Path) {
      alert("Please enter a message, select an image, or choose a document.");
    } else {
      event.preventDefault();
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}Contact/Receiversend`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          event.target.reset();
          this.GetUserside();
          this.GetMessageAll();
          this.GetSendAll();
          this.setState({
            message: "",
            picture_Path: null,
            document_Path: null,
          });
        });

      // document.getElementById("contactForm").reset();

      // {
      //   // this.GetmsgPop();
      //   this.GetUserside();
      //   this.GetMessageAll();
      // }
    }
  };

  GetUsermsg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };

    fetch(`${BASE_URLs}Contact/GetUsermsg`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Usermessage: data,
        });
        // console.log("UserMessage", data);
      })
      .catch((error) => {
        console.error("Error fetching user messages:", error);
      });
  }

  GetMessageAll(ParticularId) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };

    fetch(
      `${BASE_URLs}Contact/GetmsgUser?ParticularId=` + ParticularId,
      options
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          Receive: data,
        });
        // console.log("Receives", data);
        // console.log("DisplayName", data[0].displayName);
      })
      .catch((error) => {
        console.error("Error fetching user messages:", error);
      });
  }

  fetchData = async (ParticularId) => {
    const { display } = this.state;
    let url = `${BASE_URLs}Contact/GetmsgUser?ParticularId=` + ParticularId;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    const reqData = await fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer" + accessToken,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "Access-Control-Request-Headers",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    });

    const resData = await reqData.json();
    for (let i = 0; i < resData.length; i++) {
      display.push(resData[i].displayName);
      // console.log(resData[i].displayName);
    }

    const filteredArray = display.filter((value, index, array) => {
      return array.indexOf(value) == index;
    });
    this.setState({ filterDisplayName: filteredArray[0] });
  };

  GetSendAll(SenderId) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };

    fetch(`${BASE_URLs}Contact/GetsendmsgUser?SenderId=` + SenderId, options)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error: " + response.status);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({
          Send: data,
        });
        // console.log("Receiver", data);
      })
      .catch((error) => {
        console.error("Error fetching user messages:", error);
      });
  }

  GetUserside(Email) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    fetch(`${BASE_URLs}Contact/Getuserside?Email=` + Email, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Userside: data,
        });
        // console.log("Usersidemessage", data);
      })
      .catch((error) => {
        console.error("Error fetching user messages:", error);
      });
  }

  GetDisplayName(Display) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };

    fetch(`${BASE_URLs}contact/GetUserName?DisplayName=` + Display, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Name: data,
        });
        // console.log("Name", data);
      })
      .catch((error) => {
        console.error("Error fetching user messages:", error);
      });
  }

  AddSkillModels() {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    this.setState((prevState) => ({
      AddSkillModel: !prevState.AddSkillModel,
    }));
  }

  handleButton1Click = () => {
    this.setState({
      isButton1Visible: false,
    });
  };

  componentDidMount() {
    this.GetUserside();
    this.GetUsermsg();
    // this.GetReceivemsgPop();
    this.GetUserChatPop();
    this.GetMessageAll();
    this.fetchData();
    // this.GetUser();
    this.GetSendAll();
    this.GetDisplayName();
    this.timerID = setInterval(() => {
      this.setState({ createDateTime: new Date() });
    }, 1000);
    // this.Team();
    this.setState({ active: true });
    this.scrollIntoView();
  }
  componentDidUpdate() {
    this.scrollIntoView();
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.timerID);
  }

  // handleChange = () => {
  //   this.setState({ display: [] });
  // };

  render() {
    const {
      Message,
      Allmessage,
      Usermessage,
      Userside,
      Receive,
      Send,
      isButton1Visible,
      filterDisplayName,
      displayName,
      firstName,
      Team,
      Teams,
      picture_Path,
      document_Path,
      selectedDocumentName,
      showEmoji,
    } = this.state;
    const messages = [...Receive, ...Send];

    messages.sort(
      (a, b) => new Date(a.createDateTime) - new Date(b.createDateTime)
    );
    // var user = JSON.parse(localStorage.username);

    return (
      <>
        {this.state.AddSkillModel ? (
          <EntityListUser id="" close={() => this.AddSkillModels()} />
        ) : null}
        <div>
          <SideBar active={window.location.hash} />
          {isButton1Visible && (
            <div
              className="pop"
              style={{ backgroundColor: "transparent", marginLeft: "28%" }}
            >
              <form
                id="contactForm"
                autoComplete="off"
                encType="multipart/form-data"
                onSubmit={this.handleSubmit}
                // onChange={this.handleChange}
              >
                <ul className="text-right boxs">
                  <li
                    className="chat-single-box card-shadow bg-white active"
                    data-id="1"
                    style={{ marginTop: "-146px" }}
                  >
                    <div className="had-container">
                      <div
                        className="chat-header p-10 bg-gray"
                        style={{ backgroundColor: "#7666f0b8" }}
                      >
                        <div className="user-info d-inline-block f-left">
                          <div
                            className="Namepass"
                            style={{ marginTop: "-10px" }}
                          >
                            <input type="hidden" name="Id" />
                            <span className="userNames">
                              <a href="javascript:void(0);" title="Me">
                                <img
                                  src="assets/images/avatar-1.png"
                                  className="img-circle img-chat-profile"
                                  alt="Me"
                                  onChange={this.fetchData}
                                />
                                &nbsp;&nbsp;{filterDisplayName}
                              </a>
                            </span>
                          </div>
                        </div>
                        <div className="box-tools d-inline-block">
                          <button
                            id="close"
                            style={{
                              float: "right",
                              backgroundColor: "#a0a3a305",
                              color: "#797070",
                              border: "1px solid #a3a5a505",
                            }}
                            onClick={this.props.close}
                          >
                            <CloseIcon />
                          </button>
                        </div>
                      </div>
                      <div
                        className="chat-body p-10"
                        style={{ overflow: "scroll", height: "493px" }}
                      >
                        <div className="message-scrooler">
                          <div className="messages">
                            <div
                              className="containers"
                              style={{ width: "87vh" }}
                            >
                              {messages.map((message, index) => {
                                const isSender = Send.includes(message);

                                return (
                                  <div
                                    className={`message-${
                                      isSender ? "orange" : "blue"
                                    }`}
                                    key={index}
                                    style={{ height: "auto" }}
                                  >
                                    {/* <p className="message-content" name="Popmsg">
                                  {message.message}
                                </p> */}
                                    {message.picture_Path === "-" &&
                                    message.document_Path === "-" ? (
                                      <p
                                        className="message-content"
                                        name="Popmsg"
                                        style={{ wordWrap: "break-word" }}
                                      >
                                        {message.message}
                                      </p>
                                    ) : (
                                      <>
                                        {message.picture_Path !== "-" &&
                                        message.document_Path !== "-" ? (
                                          <div>
                                            <div>
                                              <p
                                                className="message-content"
                                                name="Popmsg"
                                              >
                                                {message.message}
                                              </p>

                                              <br />

                                              <div>
                                                <img
                                                  alt="chatimg"
                                                  width="80"
                                                  height="80"
                                                  src={message.picture_Path}
                                                  style={{ width: "inherit" }}
                                                />
                                              </div>
                                              <div>
                                                <embed
                                                  src={message.document_Path}
                                                  id="Gstiframe"
                                                  title=""
                                                  scrolling="no"
                                                  target="_blank"
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    pointerEvents: "none",
                                                  }}
                                                ></embed>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                            {message.picture_Path === "-" &&
                                            message.document_Path !== "-" ? (
                                              <div>
                                                <p
                                                  className="message-content"
                                                  name="Popmsg"
                                                  style={{
                                                    wordWrap: "break-word",
                                                  }}
                                                >
                                                  {message.message}
                                                </p>
                                                <br />
                                                <embed
                                                  src={message.document_Path}
                                                  id="Gstiframe"
                                                  title=""
                                                  scrolling="no"
                                                  target="_blank"
                                                  style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    pointerEvents: "none",
                                                  }}
                                                ></embed>
                                              </div>
                                            ) : (
                                              <div>
                                                <p
                                                  className="message-content"
                                                  name="Popmsg"
                                                  style={{
                                                    wordWrap: "break-word",
                                                  }}
                                                >
                                                  {message.message}
                                                </p>
                                                <br />

                                                <div>
                                                  <img
                                                    alt="chatimg"
                                                    width="80"
                                                    height="80"
                                                    src={message.picture_Path}
                                                    style={{ width: "inherit" }}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                    <br />
                                    <div className="message-timestamp-right">
                                      <TimeAgo
                                        date={message.createDateTime}
                                        live={true}
                                        format={(value, unit, suffix) => {
                                          if (unit === "second") {
                                            // Custom formatting for seconds
                                            return `${value} seconds ago`;
                                          }

                                          // Default formatting for other units
                                          return `${value} ${unit}${suffix}`;
                                        }}
                                        locale="en-US"
                                        style={{ color: "gray" }}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                              <div ref={this.myRef} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="chat-footer b-t-muted"
                        style={{ backgroundColor: "white" }}
                      >
                        <div className="input-group write-msg">
                          <input
                            type="text"
                            className="form-control input-value"
                            placeholder="Type a Message"
                            name="Message"
                            value={this.state.message}
                            // onChange={this.handleTextChange}
                            onChange={this.handleTextChange}
                          />
                          <input
                            type="hidden"
                            name="UserName"
                            defaultValue={this.props.values.email}
                          />
                          {picture_Path && (
                            <div>
                              <img
                                src={URL.createObjectURL(picture_Path)}
                                alt="Preview"
                                style={{ width: "5vh", height: "5vh" }}
                              />
                            </div>
                          )}
                          {document_Path && (
                            <div>
                              {/* <h4>Preview Document:</h4> */}
                              {document_Path.type ===
                              "application/pdf/msword" ? (
                                <embed
                                  src={URL.createObjectURL(document_Path)}
                                  width="50"
                                  height="60"
                                  type="application/pdf/msword"
                                />
                              ) : (
                                <p>{document_Path.name}</p>
                                // <p></p>
                              )}
                            </div>
                          )}
                          <input type="hidden" name="ReceiversId" />
                          <span
                            className="fa fa-smile-o"
                            aria-hidden="true"
                            onClick={this.myEmoji}
                            style={{ marginTop: "10px", fontSize: "18px" }}
                          ></span>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {showEmoji && (
                            <div
                              style={{
                                position: "absolute",
                                top: "-450px",
                                marginLeft: "300px",
                              }}
                            >
                              <Picker onEmojiClick={this.handleEmojiClick} />
                            </div>
                          )}
                          <input
                            type="file"
                            id="file"
                            name="Picture_Path"
                            onFocus={(e) => (e.target.type = "file")}
                            style={{ display: "none" }}
                            onChange={this.handleFileChange}
                          />
                          <label htmlFor="file" style={{ marginTop: "8px" }}>
                            <span className="fa fa-picture-o"> </span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="file"
                            id="files"
                            name="Document_Path"
                            onChange={this.handleDocumentChange}
                            onFocus={(e) => (e.target.type = "file")}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="files" style={{ marginTop: "8px" }}>
                            <span className="fa fa-file"> </span>
                          </label>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className="input-group-btn">
                            <button
                              id="paper-btn"
                              className="btn btn-secondary"
                              type="submit"
                            >
                              <i className="icofont icofont-paper-plane"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
          )}
        </div>
      </>
    );
  }
}
export default UserChatPopUp;
