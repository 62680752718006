import CloseIcon from '@material-ui/icons/Close';
import { ReactExcel, readFile } from '@ramonak/react-excel';
import React, { Component } from 'react';
import { OutTable } from 'react-excel-renderer';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import AddTrainerpop from '../../Common/AddTrainerpop';
import EditTrainerpop from '../../Common/EditTrainerpop';
import FilterPop from '../../Common/FilterPop';
import Filterpopstatustopic from '../../Common/Filterpopstatustopic';
import SideBar from '../../Common/Sidebar';
import { BASE_URLs } from '../../Config/Base';
var XLSX = require('xlsx');

export default class TrainerSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      DepartmentName: '',
      DepartModel: false,
      comlogo: [],
      Department: [],
      Depart: [],
      topic: [],
      DepartmentGrouped: [],
      DepartGrouped: [],
      formErrors: {},
      EditModel: false,
      EditDesigntionValues: [],
      Notification: [],
      rows: [],
      cols: [],
      trainerid1: '',
      trainerid: [],
      Filterpopstatus: false,
      Filterpopstatus1: false,
      Filterpopstatusadd: false,
      Trainers: [],
      design: '',
      AllRoles: [],
      DesignSearch: [],
      Search: [],
      dept: '',
      isOpen: false,
      selectedOptions: [],
      deptValue: [],
      Id: [],
      firstName: [],
      selectedCheckboxValues: [],
      selectedCheckboxValuess: [],
      dept: 'Department',
      selectedJobRole: 'Designation',
      SearchData: [],
      filteredSearch: [],
      filteredSearchDrop: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDesign = this.handleChangeDesign.bind(this);
  }

  DeleteTopicButton = (id, desigId) => {
    const data = new FormData();
    data.append('TrainingTopicId', desigId);
    data.append('UserId', id);

    for (var pair of data.entries()) {
    }

    if (
      window.confirm('Are you sure you want to delete this record permanently?')
    ) {
      var options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}Trainer/TrainerTopicDelete`, options)
        .then((response) => response.json())
        .then((data) => {
          this.GetDepartment();
          toast.success(data.message, {
            position: 'top-center',
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  changeState = (storingUserID) => {
    // if (this.state.storingUserID !== "undefined") {
    let url = `${BASE_URLs}Trainer/UpdateTrainer?TrainerID=` + storingUserID;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success(data.message, {
          position: 'top-center',
          autoClose: 2200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
 
                  this.GetDepartment();

         
        
      });
    // }
  };
  checkChangeDesignation = (event, Id) => {
    const { name, checked, value } = event.target;
    const { selectedCheckboxValuess } = this.state;

    let data = this.state.Search.map((e) => {
      if (e.training_Id === Id) {
        return { ...e, check: checked };
      }
      return e;
    });
    let data1 = this.state.filteredSearch.map((e) => {
      if (e.training_Id === Id) {
        return { ...e, check: checked };
      }
      return e;
    });

    if (
      this.state.Search.length === data.filter((e) => e.check === true).length
    ) {
      console.log('yes');
      if (document.getElementById('selectedall')) {
        document.getElementById('selectedall').checked = true;
      }
    } else {
      console.log('no');
      if (document.getElementById('selectedall')) {
        document.getElementById('selectedall').checked = false;
      }
    }
    console.log('data1', data1);

    this.setState({
      //Search: data,

      filteredSearch: data1,
    });

    let selectedCheckboxValues1 = checked
      ? [...selectedCheckboxValuess.filter((val) => val != value), value]
      : selectedCheckboxValuess.filter((val) => val != value);

    this.setState({ selectedCheckboxValuess: selectedCheckboxValues1 });
    return;
    let totalChecked = document.querySelectorAll('input[name="design"]');
    let checkedboxes2 = document.querySelectorAll('input[id="selectedall"]');
    let checkedboxes = document.querySelectorAll('input[id="checkedall"]');
    let Total = document.querySelectorAll('input[name="design"]');

    let checkedtotal = 0;
    for (var i of totalChecked) {
      if (i.checked) {
        checkedtotal += 1;
      } else {
      }
    }

    // if (document.getElementById("selectedall").checked) {
    // } else {

    // }

    if (document.getElementById('selectedall').checked === false) {
      if (Total.length - 1 === checkedtotal) {
        if (document.getElementById('selectedall')) {
          document.getElementById('selectedall').checked = true;
        }
      }
    } else {
      if (document.getElementById('selectedall').checked) {
        if (checkedboxes.length - 2 !== Total.length) {
          document.getElementById('selectedall').checked = false;
        }
      }
    }

    //const { selectedCheckboxValuess } = this.state;

    let selectedCheckboxValues = checked
      ? [...selectedCheckboxValuess.filter((val) => val != value), value]
      : selectedCheckboxValuess.filter((val) => val != value);

    this.setState({ selectedCheckboxValuess: selectedCheckboxValues });
  };
  handleSelectedRows = () => {};

  fetchData = async (firstname, TrainerId, TopicId) => {
    if (this.state.selectedCheckboxValuess.length === 0) {
      toast.error('Please select at least one Topic', {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      this.setState({ dept: 'Department', selectedJobRole: 'Designation' });
      const data = new FormData();
      if (document.getElementById('selectedall').checked == true) {
        const data = new FormData();
        // for (var i = 0; i < this.state.Search.length; i++) {
        //
        //   data.append("TrainingId", this.state.Search[i].training_Id);
        //   data.append("FirstName", this.state.DepartmentGrouped[0].trainerName);
        //   data.append("TrainerId", this.state.DepartmentGrouped[0].TrainerUserId);
        // }
        //
        //

        // for (const [key, value] of data.entries()) {
        //
        // }
        let dd = this.state.selectedCheckboxValuess.forEach((e) => {
          let split = e.split('$$$');
          // [5,''vjvd487348543, 'Arunachalam']
          //[]
          if (split[1] !== undefined) data.append('FirstName', split[1]);
          data.append('TrainerId', split[2]);
          data.append('TrainingId', split[0]);
        });

        for (const [key, value] of data.entries()) {
        }
        const url = `${BASE_URLs}Training/AssignedTrainers`;
        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
          body: data,
        };
        const response = await fetch(url, options);
        const result = await response.json();

        if (result.status === 'Success') {
          toast.success(result.message, {
            position: 'top-center',
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(result.message, {
            position: 'top-center',
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.GetDepartment();

        this.setState({ isOpen: false });

        this.setState({
          selectedCheckboxValues: [],
          selectedCheckboxValuess: [],
        });
      } else {
        let dd = this.state.selectedCheckboxValuess.forEach((e) => {
          let split = e.split('$$$');
          // [5,''vjvd487348543, 'Arunachalam']
          //[]
          if (split[1] !== undefined) {
            data.append('FirstName', split[1]);
            data.append('TrainerId', split[2]);
            data.append('TrainingId', split[0]);
          }
        });

        for (const [key, value] of data.entries()) {
        }
        var is_selected = [];
        const url = `${BASE_URLs}Training/AssignedTrainers`;

        const options = {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
          body: data,
        };
        const response = await fetch(url, options);
        const result = await response.json();

        if (result.status === 'Success') {
          toast.success(result.message, {
            position: 'top-center',
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(result.message, {
            position: 'top-center',
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.GetDepartment();

        this.setState({ isOpen: false });

        this.setState({
          selectedCheckboxValues: [],
          selectedCheckboxValuess: [],
        });
      }
    }
  };

  GetAllRole() {
    let url = `${BASE_URLs}Designation/GetMasterDesignation`;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          AllRoles: data,
        });
      });
  }

  openThePopup = (department, userId, trainerName) => {
    this.setState({ Id: userId });

    this.setState({ firstName: trainerName });

    this.setState({ isOpen: true });
    this.GetTrainingItems(department, userId);
    this.setState({ Search: [] });
  };

  closePopup = () => {
    this.setState({ selectedCheckboxValuess: [] });
    this.setState({ design: '' });
    this.setState({ dept: '' });
    this.setState({ isOpen: false });
    this.setState({ filteredSearchDrop: this.state.Search });

    this.setState({
      dept: 'Department',
      selectedJobRole: 'Designation',
      isOpen: false,
    });
  };

  GetTrainingItems = (Department, userId) => {
    const checking = Department;

    let url = `${BASE_URLs}Training/GetTrainingItems?TrainingId=` + userId;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        let data1 = data.map((e) => {
          return { ...e, check: false };
        });

        this.setState({
          Search: data1,
          SearchData: data1,
          filteredSearch: data1,
          filteredSearchDrop: data1,
        });

        //
        for (var i = 0; i < data1.length; i++) {
          this.setState({ topic: data1[i].trainingitem });
        }
      });
  };

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById('DepartSheet');
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, '' + filename + '.xlsx');
  }

  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }

  handleChange = (event) => {
    const filteredSearch = this.state.Search.filter(
      (item) =>
        (event.target.value === 'Department' ||
          item.department === event.target.value) &&
        (this.state.selectedJobRole === 'Designation' ||
          item.trainingitem === this.state.selectedJobRole)
    );
    const filteredSearchDrop = this.state.Search.filter(
      (item) =>
        event.target.value === 'Department' ||
        item.department === event.target.value
    );

    if (filteredSearch.filter((e) => e.check).length == filteredSearch.length) {
      if (document.getElementById('selectedall')) {
        document.getElementById('selectedall').checked = true;
      }
    } else {
      if (document.getElementById('selectedall')) {
        document.getElementById('selectedall').checked = false;
      }
    }

    this.setState({
      dept: event.target.value,
      filteredSearch: filteredSearch,
      filteredSearchDrop: filteredSearchDrop,
    });
  };

  handleChangeDesign = (event) => {
    const filteredSearch = this.state.Search.filter(
      (item) =>
        (this.state.dept === 'Department' ||
          item.department === this.state.dept) &&
        (event.target.value === 'Designation' ||
          item.trainingitem === event.target.value)
    );
    const filteredSearchDrop = this.state.Search.filter(
      (item) =>
        this.state.dept === 'Department' || item.department === this.state.dept
    );

    if (filteredSearch.filter((e) => e.check).length == filteredSearch.length) {
      if (document.getElementById('selectedall')) {
        document.getElementById('selectedall').checked = true;
      }
    } else {
      if (document.getElementById('selectedall')) {
        document.getElementById('selectedall').checked = false;
      }
    }

    this.setState({
      selectedJobRole: event.target.value,
      filteredSearch: filteredSearch,
      filteredSearchDrop: filteredSearchDrop,
    });
  };

  GetAllDepartment() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Department/DepartmentGet`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex((item) => item.department === value.department) ===
            index
          );
        });

        this.setState({
          department: filteredData,
        });
      });
  }
  GetDepartment() {
    let url = `${BASE_URLs}Trainer/GetAllTrainers`;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        let GroupedbyName = data
          .sort((a, b) => {
            return a.trainerName < b.trainerName ? -1 : 1;
          })
          .reduce((r, e) => {
            const {
              department,
              trainerName,
              role,
              trainersince,
              active,
              trainer_UserId,
            } = e;
            // let trainerName = e.trainerName;

            if (!r[trainer_UserId])
              r[trainer_UserId] = {
                trainerName,
                department,
                role,
                trainersince,
                active,
                record: [e],
              };
            else r[trainer_UserId].record.push(e);

            return r;
          }, {});

        let TopicMerged = Object.values(GroupedbyName).map((e) => {
          return {
            trainerName: e.trainerName,
            department: e.department,
            role: e.role,
            trainersince: e.trainersince,
            TrainerUserId: e.record[0].trainer_UserId,
            active: e.active,

            record:
              e.record[0].trainingTopics === null
                ? []
                : e.record[0].trainingTopics.split(',,').map((ee) => {
                    return {
                      TopicId: ee.split('$$$')[1],
                      TraingItem: ee.split('$$$')[0],
                      Description: ee.split('$$$')[2],
                      Department: ee.split('$$$')[3],
                      Duration: ee.split('$$$')[4],
                      TrainerUserId: e.record[0].trainer_UserId,
                    };
                  }),
          };
        });
        console.log('GroupedbyName ^', GroupedbyName);
        console.log('data ^', data);
        console.log('TopicMerged ^', TopicMerged);
        this.setState({
          Department: data.sort((a, b) => {
            return a.trainerName < b.trainerName ? -1 : 1;
          }),
          Depart: data.sort((a, b) => {
            return a.trainerName < b.trainerName ? -1 : 1;
          }),
          DepartmentGrouped: TopicMerged,
          DepartGrouped: TopicMerged,
        });

        //
      });
  }

  DepartModel = () => {
    if (document.getElementById('selectall')) {
      document.getElementById('selectall').checked = false;
    }
    if (document.getElementsByName('trainer_UserID')) {
      var checkboxes = document.getElementsByName('trainer_UserID');

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById('delete')
          .setAttribute('style', 'visibility: hidden;');
        document
          .getElementById('deletecount')
          .setAttribute('style', 'visibility: hidden;');
      }
    }
    this.setState((prevState) => ({
      DepartModel: !prevState.DepartModel,
    }));
    this.GetDepartment();
  };
  EditModel = (e, fn) => {
    if (document.getElementById('selectall')) {
      document.getElementById('selectall').checked = false;
    }
    if (document.getElementsByName('trainer_UserID')) {
      var checkboxes = document.getElementsByName('trainer_UserID');
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById('delete')
          .setAttribute('style', 'visibility: hidden;');
        document
          .getElementById('deletecount')
          .setAttribute('style', 'visibility: hidden;');
      }
    }

    this.setState({
      EditDesigntionValues: {
        id: e,
        DepartmentName: fn,
      },
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));
    this.GetDepartment();
  };

  componentDidMount() {
    this.GetOrgLogo();
    this.GetDepartment();
    this.GetAllDepartment();
    this.GetAllRole();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('uname');
    localStorage.removeItem('Role');
    localStorage.removeItem('user');
    localStorage.removeItem('org');
    localStorage.clear();
    this.props.history.push('/Login_form');
  };
  // handleChange = (e) => {
  //   const { name, value } = e.target;
  //   this.setState({ [name]: value });
  // };
  handleFormValidation() {
    const {
      FirstName,
      LastName,
      Email,
      Mobile,
      Department,
      Designation,
      RoleName,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!FirstName) {
      formIsValid = false;
      formErrors['FirstNameErr'] = 'First Name is required';
    }
    if (!LastName) {
      formIsValid = false;
      formErrors['LastNameErr'] = 'Last Name is required';
    }
    if (!Email) {
      formIsValid = false;
      formErrors['EmailErr'] = 'Email is required';
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
      formErrors['EmailErr'] = 'Invalid Email id.';
    }
    if (!Mobile) {
      formIsValid = false;
      formErrors['MobileErr'] = 'Mobile is required';
    }
    // else if (!Mobile >= 10) {
    //   formIsValid = false;
    //   formErrors["MobileErr"] = "Invalid Mobile number.";
    // }
    else {
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
      if (!mobPattern.test(Mobile)) {
        formIsValid = false;
        formErrors['MobileErr'] = 'Invalid phone number.';
      }
    }
    if (!Department) {
      formIsValid = false;
      formErrors['departmentErr'] = 'department is required';
    }
    if (!Designation) {
      formIsValid = false;
      formErrors['designationErr'] = 'designation is required';
    }
    if (!RoleName) {
      formIsValid = false;
      formErrors['rolesErr'] = 'Role is required';
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}Trainer/TrainerExcel`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp === undefined) {
            alert('0  Records  inserted ');
          } else if (resp === 0) {
            alert('Records added successfully');
          } else {
            alert(+resp + ' Records not inserted ');
          }
          this.GetDepartment();
          this.setState({ initialData: undefined });
        });
      var file = document.getElementById('files');
      var emptyFile = document.createElement('input');
      emptyFile.type = 'file';
      file.files = emptyFile.files;
    } catch (error) {}
    this.GetDepartment();
  };

  handleUpload = (event) => {
    if (document.getElementById('selectall')) {
      document.getElementById('selectall').checked = false;
    }
    if (document.getElementsByName('trainer_UserID')) {
      var checkboxes = document.getElementsByName('trainer_UserID');
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById('delete')
          .setAttribute('style', 'visibility: hidden;');
        document
          .getElementById('deletecount')
          .setAttribute('style', 'visibility: hidden;');
      }
    }

    var inputElement = document.getElementById('files');
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf('.'));
    var allowedExtensionsRegx = /(\.xlsx)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);
    if (isAllowed) {
      const file = event.target.files[0];
      readFile(file).then((readedData) =>
        this.setState({ initialData: readedData })
      );
      /* file upload logic goes here... */
      return true;
    } else {
      alert('Invalid File Type.');
      return false;
    }
  };

  Noticount = async (Id) => {
    await fetch(`${BASE_URLs}Notification/Noticount`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Notification_Id: Id,
      }),
    }).then((result) => {
      result.json().then((resp) => {
        localStorage.setItem('success', JSON.stringify(resp.status));
        var a = localStorage.getItem('success');
        var b = JSON.parse(a);
        if (b === 'Success') {
          window.location.href = '/Organization';
          localStorage.removeItem('success');
        }
      });
    });
  };

  Handledepartment() {
    var department = document.getElementById('department').value;

    if (department === 'others') {
      document.getElementById('departmenttext').value = '';
      document.getElementById('departmenttext').type = 'text';
    } else {
      document.getElementById('departmenttext').value = 'None';
      document.getElementById('departmenttext').type = 'hidden';
    }
  }

  checkAll(e) {
    var checkboxes = document.getElementsByName('trainer_UserID');
    var sa = document.getElementById('selectall');
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById('delete')
          .setAttribute('style', 'visibility:visible ;');
        document
          .getElementById('deletecount')
          .setAttribute(
            'style',
            'visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);'
          );
        var msg = document.getElementById('deletecount');
        msg.innerHTML = '<span> ' + count + '  selected </span> ';
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById('delete')
          .setAttribute('style', 'visibility: hidden;');
        document
          .getElementById('deletecount')
          .setAttribute('style', 'visibility: hidden;');
      }
    }
  }

  handlecheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      // document
      //   .getElementById("selectalldiv")
      //   .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById('selectalldiv')
        .setAttribute('style', 'visibility:hidden ;');
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById('delete')
        .setAttribute('style', 'visibility:visible ;');
      document
        .getElementById('deletecount')
        .setAttribute(
          'style',
          'visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);'
        );
      var msg = document.getElementById('deletecount');
      let count = checkboxes.length - 1;

      if (document.getElementById('selectall').checked) {
        msg.innerHTML = '<span> ' + count + ' selected </span> ';
      } else {
        msg.innerHTML = '<span> ' + checkboxes.length + ' selected </span> ';
      }
    } else {
      document
        .getElementById('delete')
        .setAttribute('style', 'visibility: hidden;');
      document
        .getElementById('deletecount')
        .setAttribute('style', 'visibility: hidden;');
    }
    if (document.getElementById('selectall').checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById('selectall').checked = true;
      }
    } else {
      if (document.getElementById('selectall').checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById('selectall').checked = false;
        }
      }
    }
  }

  onKeyUp = (e) => {
    if (document.getElementById('selectall')) {
      document.getElementById('selectall').checked = false;
    }
    if (document.getElementsByName('trainer_UserID')) {
      var checkboxes = document.getElementsByName('trainer_UserID');
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById('delete')
          .setAttribute('style', 'visibility: hidden;');
        document
          .getElementById('deletecount')
          .setAttribute('style', 'visibility: hidden;');
      }
    }

    const Department = this.state.Depart.filter((item) =>
      item.department.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Department });
  };

  Multidelete = (event) => {
    event.preventDefault();
    if (document.getElementById('selectall')) {
      document.getElementById('selectall').checked = false;
    }
    // if(document.getElementsByName('trainer_UserID')){
    // var checkboxes = document.getElementsByName('trainer_UserID');
    // for (var i = 0; i < checkboxes.length; i++) {
    //   checkboxes[i].checked = false;
    //   document.getElementById("delete").setAttribute('style', 'visibility: hidden;');
    //   document.getElementById("deletecount").setAttribute('style', 'visibility: hidden;');
    // }}
    const data = new FormData(event.target);

    if (
      window.confirm('Are you sure you want to delete all record permanently?')
    ) {
      var options = {
        method: 'POST',
        headers: {
          // Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}Trainer/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetDepartment();
          alert(resp.message);
        });

      document
        .getElementById('delete')
        .setAttribute('style', 'visibility: hidden;');
      document
        .getElementById('deletecount')
        .setAttribute('style', 'visibility: hidden;');
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      //check.checked = false;
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
  };

  checkedAll(e) {
    const { name, checked, value } = e.target;

    if (
      this.state.selectedJobRole === 'Designation' &&
      this.state.dept === 'Department'
    ) {
      if (this.state.filteredSearch.length != this.state.Search.length) {
        let data1 = this.state.filteredSearch.map((e) => {
          return { ...e, check: checked };
        });

        this.setState({ filteredSearch: data1 });
      } else {
        let data = this.state.Search.map((e) => {
          return { ...e, check: checked };
        });

        let data1 = this.state.filteredSearch.map((e) => {
          return { ...e, check: checked };
        });

        this.setState({ Search: data, filteredSearch: data1 });

        // this.setState({selectedCheckboxValuess:""})
        let arr = [];
        let Checkedvalue = data.filter((e) => e.check == true);

        if (Checkedvalue.length == 0) {
          this.setState({ selectedCheckboxValuess: [] });
        } else {
          let gg = Checkedvalue.forEach((e) => {
            arr.push(
              e.training_Id +
                '$$$' +
                this.state.firstName +
                '$$$' +
                this.state.Id
            );
          });
          this.setState({ selectedCheckboxValuess: arr });

          console.log('arr |', arr);
        }
      }
    } else {
      if (checked == false) {
        let IdArray = this.state.filteredSearch.map((e) => e.training_Id);

        let data = this.state.Search.map((e) => {
          if (IdArray.indexOf(e.training_Id) !== -1) {
            return { ...e, check: checked };
          }

          return e;
        });

        let data1 = this.state.filteredSearch.map((e) => {
          if (IdArray.indexOf(e.training_Id) !== -1) {
            return { ...e, check: checked };
          }

          return e;
        });

        this.setState({ Search: data, filteredSearch: data1 });

        let arr = [];
        let Checkedvalue = data.filter((e) => e.check == true);

        if (Checkedvalue.length == 0) {
          this.setState({ selectedCheckboxValuess: [] });
        } else {
          let gg = Checkedvalue.forEach((e) => {
            arr.push(
              e.training_Id +
                '$$$' +
                this.state.firstName +
                '$$$' +
                this.state.Id
            );
          });

          this.setState({ selectedCheckboxValuess: arr });
        }
      } else {
        let IdArray = this.state.filteredSearch.map((e) => e.training_Id);

        let data = this.state.Search.map((e) => {
          if (IdArray.indexOf(e.training_Id) !== -1) {
            return { ...e, check: true };
          }

          return e;
        });

        let data1 = this.state.filteredSearch.map((e) => {
          if (IdArray.indexOf(e.training_Id) !== -1) {
            return { ...e, check: true };
          }

          return e;
        });

        this.setState({ Search: data, filteredSearch: data1 });

        let arr = [];
        let Checkedvalue = data.filter((e) => e.check == true);

        if (Checkedvalue.length == 0) {
          this.setState({ selectedCheckboxValuess: [] });
        } else {
          let gg = Checkedvalue.forEach((e) => {
            arr.push(
              e.training_Id +
                '$$$' +
                this.state.firstName +
                '$$$' +
                this.state.Id
            );
          });

          this.setState({ selectedCheckboxValuess: arr });

          console.log('arr else |', this.state.selectedCheckboxValuess);
        }

        console.log('value', value);
      }
    }

    var checkboxes = document.getElementsByName('design');
    var selectedValues = [];

    var sa = document.getElementById('selectedall');

    let count = checkboxes.length;

    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        selectedValues.push(checkboxes[i].value);
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;

        document

          .getElementById('delete')

          .setAttribute('style', 'visibility: hidden;');

        document

          .getElementById('deletecount')

          .setAttribute('style', 'visibility: hidden;');
      }
    }

    console.log('$');
  }

  DeleteButton = (id) => {
    const data = new FormData();
    if (document.getElementById('selectall')) {
      document.getElementById('selectall').checked = false;
    }
    // if(document.getElementsByName('trainer_UserID')){
    // var checkboxes = document.getElementsByName('trainer_UserID');
    // for (var i = 0; i < checkboxes.length; i++) {
    //   checkboxes[i].checked = false;
    //   document.getElementById("delete").setAttribute('style', 'visibility: hidden;');
    //   document.getElementById("deletecount").setAttribute('style', 'visibility: hidden;');
    // }}
    data.append('trainer_UserID', id);
    if (
      window.confirm('Are you sure you want to delete this record permanently?')
    ) {
      var options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}Department/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetDepartment();
          if (resp.status === 'Success') {
            toast.success(resp.message, {
              position: 'top-center',
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          this.GetDepartment();
        });

      document
        .getElementById('delete')
        .setAttribute('style', 'visibility: hidden;');
      document
        .getElementById('deletecount')
        .setAttribute('style', 'visibility: hidden;');
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      //check.checked = false;
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
  };

  filter = (newValue) => {
    let GroupedbyName = newValue
      .sort((a, b) => {
        return a.trainerName < b.trainerName ? -1 : 1;
      })
      .reduce((r, e) => {
        const {
          department,
          trainerName,
          role,
          trainersince,
          active,
          trainer_UserId,
        } = e;
        // let trainerName = e.trainerName;

        if (!r[trainer_UserId])
          r[trainer_UserId] = {
            trainerName,
            department,
            role,
            trainersince,
            active,
            record: [e],
          };
        else r[trainer_UserId].record.push(e);

        return r;
      }, {});

    let TopicMerged = Object.values(GroupedbyName).map((e) => {
      return {
        trainerName: e.trainerName,
        department: e.department,
        role: e.role,
        trainersince: e.trainersince,
        TrainerUserId: e.record[0].trainer_UserId,
        active: e.active,

        record:
          e.record[0].trainingTopics === null
            ? []
            : e.record[0].trainingTopics.split(',,').map((ee) => {
                return {
                  TopicId: ee.split('$$$')[1],
                  TraingItem: ee.split('$$$')[0],
                  Description: ee.split('$$$')[2],
                  Department: ee.split('$$$')[3],
                  Duration: ee.split('$$$')[4],
                  TrainerUserId: e.record[0].trainer_UserId,
                };
              }),
      };
    });

    this.setState({
      DepartmentGrouped: TopicMerged,
      DepartGrouped: TopicMerged,
    });
  };

  validateCheckbox = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    let isChecked = false;

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        isChecked = true;
        this.handleClick();
      }
    });

    if (!isChecked) {
      toast.error('Please select at least one Trainer', {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  Filter = (newValue) => {
    let GroupedbyName = newValue
      .sort((a, b) => {
        return a.trainerName < b.trainerName ? -1 : 1;
      })
      .reduce((r, e) => {
        const {
          department,
          trainerName,
          role,
          trainersince,
          active,
          trainer_UserId,
        } = e;
        // let trainerName = e.trainerName;

        if (!r[trainer_UserId])
          r[trainer_UserId] = {
            trainerName,
            department,
            role,
            trainersince,
            active,
            record: [e],
          };
        else r[trainer_UserId].record.push(e);

        return r;
      }, {});

    let TopicMerged = Object.values(GroupedbyName).map((e) => {
      return {
        trainerName: e.trainerName,
        department: e.department,
        role: e.role,
        trainersince: e.trainersince,
        TrainerUserId: e.record[0].trainer_UserId,
        active: e.active,

        record:
          e.record[0].trainingTopics === null
            ? []
            : e.record[0].trainingTopics.split(',,').map((ee) => {
                return {
                  TopicId: ee.split('$$$')[1],
                  TraingItem: ee.split('$$$')[0],
                  Description: ee.split('$$$')[2],
                  Department: ee.split('$$$')[3],
                  Duration: ee.split('$$$')[4],
                  TrainerUserId: e.record[0].trainer_UserId,
                };
              }),
      };
    });

    this.setState({
      DepartmentGrouped: TopicMerged,
      DepartGrouped: TopicMerged,
    });
  };

  render() {
    var username = JSON.parse(localStorage.uname);
    var filename = `O_${username}_Trainer_Export`;

    const {
      selectedCheckboxValuess,
      Department,
      Filterpopstatus,
      Filterpopstatus1,
      DepartmentGrouped,
      Trainers,
      Search,
      DesignSearch,
      Filterpopstatusadd,
      isOpen,
      filteredSearch,
      filteredSearchDrop,
    } = this.state;

    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Trainers View Page Search Box */}
        {Filterpopstatus ? (
          <FilterPop
            data={this.state.Depart}
            FilteredData={(newValue) => {
              this.Filter(newValue);
              this.setState({ Department: newValue });
            }}
            TogglePop={(e) => {
              // Department

              // if (this.state.Department.length === 0) {
                this.Filter(this.state.Depart);
                this.setState({ Department: this.state.Depart });
              // }
              this.setState({ Filterpopstatus: !Filterpopstatus });
            }}
            tapKey={'trainerName'}
          />
        ) : null}
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card" id="card">
                        <div className="glowBox">
                          <div>
                            <div style={{ float: 'left' }}>
                              <Link
                                to
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  class="icofont icofont-arrow-left"
                                  style={{ fontSize: '45px', color: 'white' }}
                                />
                              </Link>{' '}
                            </div>
                          </div>
                          <h4 style={{ marginRight: '4%' }} className="title">
                            {' '}
                            Trainers List{' '}
                          </h4>
                        </div>
                        {this.state.EditModel ? (
                          <EditTrainerpop
                            values={this.state.EditDesigntionValues}
                            close={() => this.EditModel()}
                          />
                        ) : null}
                        {this.state.DepartModel ? (
                          <AddTrainerpop close={() => this.DepartModel()} />
                        ) : null}

                        <div className="card-block">
                          <form
                            // style={{ marginTop: "20px" }}
                            onSubmit={this.handleSubmit}
                          >
                            <div className="">
                              <div className="row">
                                <div className="col-lg-2">
                                  <Link
                                    to="/AddTrainer"
                                    type="button"
                                    style={{
                                      width: '150px',
                                      background: 'white',
                                    }}
                                    id="addRow"
                                    class="dropdown_item-2"
                                    className="btn "
                                  >
                                    Add{' '}
                                  </Link>
                                </div>
                                {/* <div className="col btnalign">
                                  <label
                                    htmlFor="files"
                                    className="btn"
                                    id="addBtn"
                                    name="Trainer_Info"
                                  >
                                    Import From Excel
                                  </label>
                                  <input
                                    id="files"
                                    type="file"
                                    accept=".xlsx"
                                    style={{ display: "none" }}
                                    onChange={this.handleUpload}
                                    name="Trainer_Info"
                                  />
                                </div> */}
                                <div className="col-lg-2">
                                  <button
                                    className="btn"
                                    type="button"
                                    onClick={() => this.exportXlsx(filename)}
                                  >
                                    Export to excel
                                  </button>
                                </div>

                                {/* <div
                                  className="col btnalign"
                                  clas
                                  style={{
                                    width: "100px",
                                  }}
                                >
                                  <a
                                    class="btn icofont icofont-download"
                                    type="button"
                                    href={TrainerExl}
                                  >
                                    &nbsp; Download
                                  </a>
                                </div> */}

                                {/* <div className="col btnalign">
                                  {/* <a href={TrainerExl} className=" download"  style={{padding:"2px 15px 2px 15px"}}><i title="Download Template" class="icofont icofont-cloud-download"></i></a> */}
                                {/* <a
                                    href={TrainerExl}
                                    className="download"
                                    style={{
                                      padding: "2px 15px",
                                      boxShadow:
                                        " -2px  -2px 16px -2px #5f7ae14a",
                                    }}
                                  >
                                    <i
                                      title="Download Template"
                                      class="icofont icofont-cloud-download"
                                    ></i>
                                  </a> */}
                                {/* </div> */}
                                <div className="col-lg-4"></div>
                                <div className="col-lg-4">
                                  {Filterpopstatus ? null : (
                                    <input
                                      className="Searchbox"
                                      placeholder="Search"
                                      onClick={() => {
                                        this.setState({
                                          Filterpopstatus: true,
                                        });
                                      }}
                                      //onKeyUp={this.myFunction}
                                      style={{ float: 'right' }}
                                    />
                                  )}
                                </div>
                              </div>
                              {this.state.initialData !== undefined ? (
                                <ReactExcel
                                  initialData={this.state.initialData}
                                  onSheetUpdate={(currentSheet) =>
                                    this.setState({
                                      CurrentSheet: currentSheet,
                                    })
                                  }
                                  activeSheetClassName="active-sheet"
                                  reactExcelClassName="react-excel"
                                  name="Skills"
                                />
                              ) : null}

                              {this.state.initialData !== undefined ? (
                                <div className="savebtndiv">
                                  <button
                                    className="btn"
                                    id=""
                                    type="submit"
                                    style={{ width: '150px' }}
                                  >
                                    Save
                                  </button>
                                </div>
                              ) : null}
                              <OutTable
                                data={this.state.rows}
                                columns={this.state.cols}
                                tableClassName="ExcelTable2007"
                                tableHeaderRowclassName="heading"
                              />
                            </div>
                          </form>
                        </div>

                        <div className="card-block2">
                          <form onSubmit={this.Multidelete}>
                            <button
                              type="submit"
                              className="multitrash"
                              id="delete"
                              style={{ float: 'right', visibility: 'hidden' }}
                            >
                              <i
                                class="icofont icofont-delete-alt"
                                style={{ color: 'darkgray' }}
                              ></i>
                            </button>
                            <div>
                              {' '}
                              <span
                                id="deletecount"
                                className="multitrash"
                                style={{
                                  visibility: 'hidden',
                                  fontSize: '17px',
                                }}
                              ></span>
                            </div>
                            <div
                              id="selectalldiv"
                              style={{ visibility: 'hidden' }}
                            ></div>

                            <div
                              // className="dt-responsive table-responsive table-res"
                              id="TrainercheduleTable"
                              style={{ width: '100%', textAlign: 'center' }}

                              // style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                            >
                              {this.state.DepartmentGrouped.length === 0 ? (
                                <span //style={{ marginLeft: '-60%' }}
                                >
                                  <b>No Records</b>
                                </span>
                              ) : (
                                <table
                                  id="DepSheet"
                                  className="table nowrap"
                                  style={{ maxWidth: '100%', marginTop: '-1%' }}
                                >
                                  {/* <tr style={{ verticalAlign: "middle" }}>
                                    <th className="gg">
                                      <input
                                        type="checkbox"
                                        id="selectall"
                                        onClick={() => {
                                          this.checkAll(this);
                                        }}
                                      />
                                    </th>
                                    Select All
                                  </tr> */}
                                  {DepartmentGrouped.map(
                                    (DepartmentGrouped, i) => (
                                      <>
                                        <tr
                                          style={{ verticalAlign: 'middle' }}
                                          className="designationMenu"
                                        >
                                          <th
                                            style={{
                                              verticalAlign: 'middle',
                                            }}
                                          >
                                            {/* <input
                                              type="checkbox"
                                              name="trainer_UserID"
                                              id="trainer_UserID"
                                              onChange={this.handlecheckbox}
                                              defaultValue={
                                                DepartmentGrouped.TrainerUserId
                                              }
                                            /> */}
                                          </th>

                                          <th
                                            className="gg"
                                            style={{
                                              verticalAlign: 'middle',
                                              textAlign: 'left',
                                            }}
                                          >
                                            Name :{' '}
                                            {DepartmentGrouped.trainerName}
                                          </th>
                                          <th
                                            className="gg"
                                            style={{
                                              verticalAlign: 'middle',
                                            }}
                                          >
                                            Department :{' '}
                                            {DepartmentGrouped.department}
                                          </th>
                                          <th
                                            className="gg"
                                            style={{
                                              textAlign: 'left',
                                              verticalAlign: 'middle',
                                            }}
                                          >
                                            {DepartmentGrouped.role}
                                          </th>
                                          <th
                                            className="gg"
                                            style={{
                                              textAlign: 'left',
                                              verticalAlign: 'middle',
                                            }}
                                          >
                                            {/* {DepartmentGrouped.trainersince.slice(
                                              0,
                                              10
                                            )} */}
                                          </th>
                                          <th
                                            className="gg"
                                            style={{
                                              verticalAlign: 'middle',
                                            }}
                                          ></th>

                                          {DepartmentGrouped.active ? (
                                            <th
                                              className="gg"
                                              style={{
                                                verticalAlign: 'middle',
                                                color: '#80e939',
                                              }}
                                            >
                                              Active{' '}
                                            </th>
                                          ) : (
                                            <th
                                              className="gg"
                                              style={{
                                                verticalAlign: 'middle',
                                                color: '#ea5a5a',
                                              }}
                                            >
                                              Deactive
                                            </th>
                                          )}

                                          <th
                                            style={{
                                              verticalAlign: 'middle',
                                            }}
                                            className="gg"
                                          >
                                            &nbsp;&nbsp;&nbsp;
                                            <label className="switch">
                                              <input
                                                type="checkbox"
                                                checked={
                                                  DepartmentGrouped.active
                                                    ? true
                                                    : false
                                                }
                                                onClick={() =>
                                                  this.changeState(
                                                    DepartmentGrouped.TrainerUserId
                                                  )
                                                }
                                              />
                                              <span className="slider round" />
                                            </label>
                                          </th>
                                          {/* <th></th> */}
                                        </tr>
                                        {/* {DepartmentGrouped.record.length !==
                                        0 ? ( */}
                                        <tr className="designationMenu1">
                                          {/* <th>Trainer's Name</th> */}
                                          <th></th>
                                          <th
                                            style={{
                                              verticalAlign: 'middle',
                                            }}
                                          >
                                            Department
                                          </th>
                                          <th
                                            style={{
                                              textAlign: 'left',
                                              verticalAlign: 'middle',
                                            }}
                                          >
                                            Topic
                                          </th>
                                          <th
                                            style={{
                                              textAlign: 'left',
                                              verticalAlign: 'middle',
                                            }}
                                          >
                                            Description
                                          </th>
                                          <th
                                            style={{
                                              textAlign: 'left',
                                              verticalAlign: 'middle',
                                            }}
                                          >
                                            Duration
                                          </th>
                                          <th></th>
                                          <th
                                            style={{
                                              textAlign: 'left',
                                              verticalAlign: 'middle',
                                            }}
                                          >
                                            {(() => {
                                              if (
                                                DepartmentGrouped.active == true
                                              ) {
                                                return (
                                                  <div className="col-sm-6">
                                                    <button
                                                      type="button"
                                                      className="button-30 sk-edit"
                                                      onClick={() =>
                                                        this.openThePopup(
                                                          DepartmentGrouped.department,
                                                          DepartmentGrouped.TrainerUserId,
                                                          DepartmentGrouped.trainerName
                                                        )
                                                      }
                                                    >
                                                      {/* <i
                                                        class="icofont icofont-plus"
                                                        aria-hidden="true"
                                                      ></i> */}{' '}
                                                      <b> &nbsp;Add Topic</b>
                                                    </button>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className="col-sm-6">
                                                    <button
                                                      type="button"
                                                      className="button-30 sk-edit"
                                                      disabled
                                                      onClick={() =>
                                                        this.openThePopup(
                                                          DepartmentGrouped.department,
                                                          DepartmentGrouped.TrainerUserId,
                                                          DepartmentGrouped.trainerName
                                                        )
                                                      }
                                                    >
                                                      <b> Add Topic</b>
                                                    </button>
                                                  </div>
                                                );
                                              }
                                            })()}
                                          </th>
                                          {/* <th>Active Training Session</th> */}
                                          {/* <th>Assign topics</th> */}

                                          <th
                                            style={{ textAlign: 'center' }}
                                          ></th>
                                        </tr>
                                        {/*   ) : null} */}
                                        {DepartmentGrouped.record.map(
                                          (Department, i) => (
                                            // <tbody
                                            //   style={{ textAlign: "center" }}
                                            // >
                                            <tr style={{ border: 'hidden' }}>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                }}
                                              ></td>
                                              <td
                                                style={{
                                                  textAlign: 'center',
                                                }}
                                              >
                                                {Department.Department}
                                              </td>
                                              <td>{Department.TraingItem}</td>

                                              <td>
                                                {Department.Description.length >
                                                10 ? (
                                                  <div>
                                                    {Department.Description.substring(
                                                      0,
                                                      15
                                                    )}
                                                    ....
                                                  </div>
                                                ) : (
                                                  Department.Description
                                                )}
                                              </td>
                                              <td>{Department.Duration}</td>
                                              <td></td>
                                              <td>
                                                &nbsp; &nbsp; &nbsp; &nbsp;
                                                &nbsp; &nbsp;
                                                <button
                                                  className="button-30"
                                                  type="button"
                                                  style={{
                                                    color: 'rgb(255, 0, 0)',
                                                  }}
                                                  onClick={() =>
                                                    this.DeleteTopicButton(
                                                      DepartmentGrouped.TrainerUserId,
                                                      Department.TopicId
                                                    )
                                                  }
                                                >
                                                  <i className="icofont icofont-minus"></i>
                                                </button>
                                              </td>
                                            </tr>
                                          )
                                          // </tbody>
                                        )}
                                      </>
                                    )
                                  )}
                                </table>
                              )}
                            </div>
                          </form>
                        </div>
                        <br />
                        <br />

                        <table
                          id="DepartSheet"
                          className="table nowrap"
                          style={{ maxWidth: '100%' }}
                          hidden
                        >
                          {/* <tr style={{ verticalAlign: "middle" }}>
                                    <th className="gg">
                                      <input
                                        type="checkbox"
                                        id="selectall"
                                        onClick={() => {
                                          this.checkAll(this);
                                        }}
                                      />
                                    </th>
                                    Select All
                                  </tr> */}
                          {DepartmentGrouped.map((DepartmentGrouped, i) => (
                            <>
                              <tr
                                style={{ verticalAlign: 'middle' }}
                                className="designationMenu"
                              >
                                <th
                                  style={{
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  {/* <input
                                              type="checkbox"
                                              name="trainer_UserID"
                                              id="trainer_UserID"
                                              onChange={this.handlecheckbox}
                                              defaultValue={
                                                DepartmentGrouped.TrainerUserId
                                              }
                                            /> */}
                                </th>

                                <th
                                  className="gg"
                                  style={{
                                    verticalAlign: 'middle',
                                    textAlign: 'left',
                                  }}
                                >
                                  <b> Name :</b> {DepartmentGrouped.trainerName}
                                </th>
                                <th
                                  className="gg"
                                  style={{
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  <b>
                                    Department : {DepartmentGrouped.department}
                                  </b>
                                </th>
                                <th
                                  className="gg"
                                  style={{
                                    textAlign: 'left',
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  {DepartmentGrouped.role}
                                </th>
                                <th
                                  className="gg"
                                  style={{
                                    textAlign: 'left',
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  {/* {DepartmentGrouped.trainersince.slice(
                                              0,
                                              10
                                            )} */}
                                </th>
                                <th
                                  className="gg"
                                  style={{
                                    verticalAlign: 'middle',
                                  }}
                                ></th>

                                <th
                                  style={{
                                    verticalAlign: 'middle',
                                  }}
                                  className="gg"
                                >
                                  &nbsp;&nbsp;&nbsp;
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        DepartmentGrouped.active ? true : false
                                      }
                                      onClick={() =>
                                        this.changeState(
                                          DepartmentGrouped.TrainerUserId
                                        )
                                      }
                                    />
                                    <span className="slider round" />
                                  </label>
                                </th>
                                {/* <th></th> */}
                              </tr>
                              {/* {DepartmentGrouped.record.length !==
                                        0 ? ( */}
                              <tr className="designationMenu1">
                                {/* <th>Trainer's Name</th> */}
                                <th></th>
                                <th
                                  style={{
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  Department
                                </th>
                                <th
                                  style={{
                                    textAlign: 'left',
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  Topic
                                </th>
                                <th
                                  style={{
                                    textAlign: 'left',
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  Description
                                </th>
                                <th
                                  style={{
                                    textAlign: 'left',
                                    verticalAlign: 'middle',
                                  }}
                                >
                                  Duration
                                </th>

                                <th style={{ textAlign: 'center' }}></th>
                              </tr>

                              {DepartmentGrouped.record.map(
                                (Department, i) => (
                                  <tr style={{ border: 'hidden' }}>
                                    <td
                                      style={{
                                        textAlign: 'center',
                                      }}
                                    ></td>
                                    <td
                                      style={{
                                        textAlign: 'center',
                                      }}
                                    >
                                      {Department.Department}
                                    </td>
                                    <td>{Department.TraingItem}</td>

                                    <td>{Department.Description}</td>
                                    <td>{Department.Duration}</td>
                                  </tr>
                                )

                                // </tbody>
                              )}
                            </>
                          ))}
                          <tr></tr> <tr></tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End */}
            </div>
          </div>
        </div>{' '}
        {/* add topic pop  */}
        {isOpen && (
          <div class="popup" id="myPopup">
            <div class="popup-content">
              <div class="popup-header">
                <b>
                  {this.state.popupdesignation}
                  <div className="row">
                    <div className="col-sm-12" style={{ textAlign: 'center' }}>
                      <div className="row" style={{ padding: '8px' }}>
                        <div className="col-sm-4">
                          <select
                            className="effect-16"
                            type="text"
                            name="Department"
                            value={this.state.dept}
                            id="Department"
                            onChange={this.handleChange}
                          >
                            <option value="Department">All</option>
                            {this.state.department.map((Departmentdrop) => (
                              <option
                                key={Departmentdrop.department}
                                value={Departmentdrop.department}
                              >
                                {Departmentdrop.department}
                              </option>
                            ))}
                          </select>
                          <span
                            class="dptspan"
                            style={{ color: '#a29d9d', marginRight: '105px' }}
                          >
                            Department
                          </span>{' '}
                        </div>

                        <div className="col-sm-4">
                          <select
                            className="effect-16"
                            type="text"
                            value={this.state.selectedJobRole}
                            name="JobRole"
                            id="JobRole"
                            onChange={this.handleChangeDesign}
                            style={{ marginLeft: '5px' }}
                          >
                            <option value="Designation">All</option>
                            {filteredSearchDrop.map((JobDrop) => (
                              <option
                                key={JobDrop.trainingitem}
                                value={JobDrop.trainingitem}
                              >
                                {JobDrop.trainingitem}
                              </option>
                            ))}
                          </select>
                          <span
                            class="topicspan"
                            style={{ color: '#a29d9d', marginRight: '75px' }}
                          >
                            Training Topic
                          </span>{' '}
                        </div>

                        <div className="col-sm-4">
                          <input
                            className="effect-16"
                            placeholder="Search"
                            onClick={() => {
                              this.setState({
                                Filterpopstatusadd: true,
                                dept: 'Department',
                                selectedJobRole: 'Designation',
                                filteredSearch: Search,
                              });
                              if (
                                this.state.Search.filter((e) => e.check)
                                  .length == this.state.Search.length
                              ) {
                                if (document.getElementById('selectedall')) {
                                  document.getElementById(
                                    'selectedall'
                                  ).checked = true;
                                }
                              } else {
                                if (document.getElementById('selectedall')) {
                                  document.getElementById(
                                    'selectedall'
                                  ).checked = false;
                                }
                              }
                            }}
                            style={{ width: '100%', marginLeft: 'inherit' }}
                          />
                        </div>
                      </div>
                      {Filterpopstatusadd ? (
                        <Filterpopstatustopic
                          data={this.state.Search}
                          FilteredData={(newValue) => {
                            this.setState({ filteredSearch: newValue });
                          }}
                          TogglePop={(e) => {
                            this.setState({
                              Filterpopstatusadd: !Filterpopstatusadd,
                            });
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                </b>

                <div class="popup-close" onClick={this.closePopup}>
                  {' '}
                  <CloseIcon />
                </div>
              </div>
              <div class="popup-bodys">
                {filteredSearch.length === 0 ? (
                  <span style={{ marginLeft: '-13%' }}>
                    <b>No Records</b>
                  </span>
                ) : (
                  <table class="popup-table">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            id="selectedall"
                            name="design"
                            onClick={(e) => {
                              this.checkedAll(e);
                            }}
                          />
                        </th>

                        <th>Department</th>
                        <th>Training Topic</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredSearch.map((checkbox) => (
                        <tr key={checkbox.training_Id}>
                          <td>
                            <input
                              type="checkbox"
                              name="design"
                              id="checkedall"
                              checked={checkbox.check}
                              value={
                                checkbox.training_Id +
                                '$$$' +
                                this.state.firstName +
                                '$$$' +
                                this.state.Id
                              }
                              onChange={(e) =>
                                this.checkChangeDesignation(
                                  e,
                                  checkbox.training_Id
                                )
                              }
                            />
                          </td>
                          <td>{checkbox.department}</td>
                          {/* <td>{checkbox.designationcount}</td> */}
                          <td>{checkbox.trainingitem}</td>
                          <td>
                            {checkbox.description.length > 10 ? (
                              <div>
                                {checkbox.description.substring(0, 28)}
                                ....
                              </div>
                            ) : (
                              checkbox.description
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              {Filterpopstatusadd ? (
                <Filterpopstatustopic
                  data={this.state.SearchData}
                  FilteredData={(newValue) => {
                    this.setState({ filteredSearch: newValue });
                  }}
                  TogglePop={(e) => {
                    this.setState({
                      Filterpopstatusadd: !Filterpopstatusadd,
                    });

                    if (this.state.filteredSearch.length === 0) {
                      this.setState({ filteredSearch: filteredSearchDrop });
                    }
                  }}
                />
              ) : null}
              {filteredSearch.length === 0 ? null : (
                <div class="popup-footerss">
                  <div></div>

                  <button
                    className="but"
                    type="button"
                    id="Savebut"
                    style={{ marginBottom: '8px' }}
                    onClick={() =>
                      this.fetchData(
                        this.state.firstName,
                        this.state.Id,
                        Trainers.training_Id
                      )
                    }
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
