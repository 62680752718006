import React, { useState, useEffect } from "react";
import "../Css/SigninStyle.css";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import LogoCompo from "../Common/LogoCompo";
import Invalid from "../Common/Invalid";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingPage from "../Common/LoadingPage";
import { BASE_URLs } from "../Config/Base";

const Signin = (props) => {
  const [email, setEmail] = useState("");
  const [PasswordL, setPasswordL] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [value, setValue] = useState(props.location.state);
  const [OrgEmail, setOrgEmail] = useState([]);
  const [Announcement, setAnnouncement] = useState({});
  const [SuccessModel, setSuccessModel] = useState(false);
  const [Eye, setEye] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear()
    loadCaptchaEnginge(6);
    setTimeout(() => {
      sessionStorage.clear();
      // console.log("sessionStorage cleared after 10 seconds");
    }, 10000); // 10000 milliseconds = 10 seconds
  }, []);

  const handleFormValidation = () => {
    var email = document.getElementById("email").value;
    var PasswordL = document.getElementById("PasswordL").value;
    let formErrors = {};
    let formIsValid = true;

    let capcha = document.getElementById("user_captcha_input").value;

    if (!email || email.trim() === "") {
      formIsValid = false;
      formErrors["emailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      formIsValid = false;
      formErrors["emailErr"] = "Invalid Email Address";
    }
    if (!PasswordL || PasswordL.trim() === "") {
      formIsValid = false;
      formErrors["passwordErr"] = "Password is required";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(
        PasswordL
      )
    ) {
      formIsValid = false;
      formErrors["passwordErr"] = "Invalid Password";
    }
    if (!capcha || capcha.trim() === "") {
      formIsValid = false;
      formErrors["capchaErr"] = "Captcha is required";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const handleChange = (e) => {
    var email = document.getElementById("email").value;
    var PasswordL = document.getElementById("PasswordL").value;
    const { name, value } = e.target;

    if (name === "email") {
      setEmail(value);
    } else if (name === "PasswordL") {
      setPasswordL(value);
    }

    let formErrors = {};
    let formIsValid = true;

    let capcha = document.getElementById("user_captcha_input").value;

    if (!email || email.trim() === "") {
      formIsValid = false;
      formErrors["emailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      formIsValid = false;
      formErrors["emailErr"] = "Invalid Email Address";
    } else if (!PasswordL || PasswordL.trim() === "") {
      formIsValid = false;
      formErrors["passwordErr"] = "Password is required";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/.test(
        PasswordL
      )
    ) {
      formIsValid = false;
      formErrors["passwordErr"] = "Invalid Password";
    } else if (!capcha || capcha.trim() === "") {
      formIsValid = false;
      formErrors["capchaErr"] = "Captcha is required";
    }

    setFormErrors(formErrors);
    return formIsValid;
  };

  const performLogin = () => {
    if (navigator.onLine === false) {
      return alert("You are Offline,Check your internet Connection");
    }

    var capchaValue = document.getElementById("user_captcha_input").value;

    if (handleFormValidation()) {
      if (validateCaptcha(capchaValue) === true) {
        setLoading(true);

        let url2 = `${BASE_URLs}Team/UpdateInactiveStatus?Email=${email}`;
        // console.log("Url-----------Login", url2);

        fetch(url2, {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
        });

        const options = {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            Email: email,
            Password: PasswordL,
          }),
        };

        const url = `${BASE_URLs}Authenticate/Login`;

        fetch(url, options)
          .then((response) => response.json())

          .then((result) => {
            if (result.status === "Success") {
              localStorage.setItem("user", JSON.stringify(result.token));
              localStorage.setItem("Role", JSON.stringify(result.role));
              localStorage.setItem("org", JSON.stringify(result.org));
              localStorage.setItem("uname", JSON.stringify(result.uname));
              localStorage.setItem("userId", JSON.stringify(result.userId));
              localStorage.setItem("email", JSON.stringify(result.email));      
              localStorage.setItem(
                "entityName",
                JSON.stringify(result.entityName)
              );
              localStorage.setItem("Entity", JSON.stringify(result.entUsers));
              localStorage.setItem("access", JSON.stringify(result.access));
              localStorage.setItem(
                "accessed_to",
                JSON.stringify(result.accessed_to)
              );
              localStorage.setItem("adminId", JSON.stringify(result.adminId));

              let url = `${BASE_URLs}Announcement/GetSAdminAnnouncementpop`;
              let user = JSON.parse(localStorage.getItem("user"));
              const accessToken = user;

              fetch(url, {
                method: "GET",
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET, POST, PUT, DELETE, OPTIONS",
                  Authorization: "Bearer " + accessToken,
                  "Content-type": "application/json",
                  Accept: "application/json",
                  "Access-Control-Allow-Headers":
                    "Access-Control-Request-Headers ",
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  setAnnouncement(data);
                 
                 
                });

              toast.success("Logged In Successfully", {
                position: "top-center",
                autoClose: 3200,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });

            
              setTimeout(() => {
                var role = JSON.parse(localStorage.Role);
                role = role.toString();
                // superadmin

                if (role === "Platform Admin") {
                  props.history.push("/DashBoard");
                }

                // Admin

                if (role === "Admin") {

                 
                 

                    if (JSON.parse(localStorage.org) === 3) {
                      props.history.push("/AdminHome");
                    }
                    if (JSON.parse(localStorage.org) === 4) {
                      props.history.push("/AdminHome");
                    }
                    if (JSON.parse(localStorage.org) === 0) {
                      props.history.push("/DashBoard");
                    }
                    if (JSON.parse(localStorage.org) === 1) {
                      props.history.push("/DashBoard");
                    }
                    if (JSON.parse(localStorage.org) === 2) {
                      props.history.push("/DashBoard");
                    }else 
                      if (result.remind === true) {
                        setAnnouncement.length === 1 &&
                          props.history.push("/AnnouncementPopup");
                      }
                    
                
              }

                // User

                if (role === "User") {
                  props.history.push("/UserDashboard");
                }
              }, 4000);
            }

            if (result.title === "Unauthorized") {
              setSuccessModel(true);
              document.getElementById("user_captcha_input").value = "";
              loadCaptchaEnginge(6);
            }

            if (result.status === "Error") {
              alert(result.message);
              document.getElementById("myForm").reset();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        document.getElementById("user_captcha_input").value = "";
        toast.error("Captcha Does not Match", {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);
      }
    }
  };

  const handleSubmit = (event) => {
    // event.preventDefault();
    setIsSubmitting(true);

    setIsSubmitting(false);
  };

  const togglePassword = () => {
    setEye(!Eye);
  };

  return (
    <div>
      <div>
        <div className="bgImg" />
        <div className="content">
          <br />
          <LogoCompo />
          <ToastContainer
            position="top-center"
            autoClose={1500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {SuccessModel ? (
            <Invalid id="Success" close={() => setSuccessModel(false)} />
          ) : null}
          <div
            className={
              window.innerWidth < 992
                ? "  h-100 align-items-center justify-content-center"
                : " d-flex h-100 align-items-center justify-content-center"
            }
          >
            <div className="container">
              <div className="row">
                <div
                  className={
                    window.innerWidth < 992
                      ? " col-sm-12 ml-auto mr-auto"
                      : "col-md-4 col-sm-6 ml-auto mr-auto"
                  }
                >
                  {" "}
                  <div className="card-copy">
                    <div className="card-body">
                      <form
                        action={`${BASE_URLs}Authenticate/Login`}
                        id="myForm"
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                      >
                        <br />
                        <br />
                        <br />{" "}
                        <div>
                          <h4 className="title1">
                            <span className="shadows">Sign In</span>
                          </h4>
                        </div>
                        <div className="signUp">
                          <span>
                            <input
                              type="text"
                              className="form"
                              onChange={handleChange}
                              name="email"
                              id="email"
                              placeholder="Email "
                              maxLength="50"
                              autoComplete="off"
                              style={{ cursor: "auto" }}
                            />
                            <span className="underline" />
                            {formErrors.emailErr && (
                              <span style={{ color: "red", paddingBottom: 10 }}>
                                {formErrors.emailErr}
                              </span>
                            )}
                          </span>
                          <>
                            <div style={{ display: "flex" }}>
                              <input
                                type={!Eye ? "password" : "text"}
                                className="form"
                                onChange={handleChange}
                                id="PasswordL"
                                name="PasswordL"
                                maxLength="20"
                                placeholder="Password"
                                autoComplete="off"
                              />
                              <span
                                className={
                                  !Eye
                                    ? "icofont icofont-eye eyelogo"
                                    : "icofont icofont-eye-blocked eyelogo"
                                }
                                id="eye"
                                onClick={togglePassword}
                              ></span>
                            </div>
                            <span className="underline" />
                            {formErrors.passwordErr && (
                              <div>
                                <span
                                  style={{ color: "red", paddingBottom: 10 }}
                                >
                                  {formErrors.passwordErr}
                                </span>
                                <br />
                              </div>
                            )}
                          </>
                          <span>
                            <input
                              className="form"
                              placeholder="Enter Captcha "
                              onChange={handleChange}
                              autoComplete="off"
                              id="user_captcha_input"
                              name="user_captcha_input"
                              type="text"
                            ></input>
                            <span className="underline" />
                            {formErrors.capchaErr && (
                              <span style={{ color: "red", paddingBottom: 10 }}>
                                {formErrors.capchaErr}
                              </span>
                            )}
                          </span>
                        </div>
                        <div>
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            <LoadCanvasTemplate />
                          </span>

                          <div
                            className="text-center"
                            style={{ marginTop: "10px" }}
                          >
                            <button
                              type="submit"
                              id="loginbtn"
                              onClick={performLogin}
                              className="but"
                              style={{ width: "83.36px", margin: "auto" }}
                            >
                              Login
                            </button>
                          </div>

                          <div
                            className="text-center"
                            style={{ marginTop: "10px" }}
                          >
                            <Link
                              to="./Forgot"
                              style={{
                                // float: "inline-end",
                                color: "#b152e9",
                              }}
                            >
                              Forgot password?
                            </Link>
                          </div>

                          <div
                            className="text-center"
                            style={{ marginTop: "10px" }}
                          >
                            New User?{" "}
                            <a
                              href
                              onClick={() => {
                                props.history.push("./Sign-up");
                              }}
                              style={{
                                // float: "inline-end",
                                color: "rgb(177, 82, 233)",
                                cursor: "pointer",
                              }}
                            >
                              Sign Up
                            </a>{" "}
                          </div>
                          <br />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? <LoadingPage /> : null}
    </div>
  );
};
export default Signin;
