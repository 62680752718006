import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class SPEditAnnouncement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    document.getElementById("savebtn").disabled = true;
    const data = new FormData(event.target);

    fetch(`${BASE_URLs}Announcement/SAEditPreAnnouncement`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === "Success") {
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          document.getElementById("EditAnnpop").style.display = "none";
          setTimeout(function () {
            document.getElementById("close").click();
          }, 4000);
        }
      });
  }
   handleChange = (e) => {
    const { name, value, files } = e.target;
    this.setState({ [name]: value });
    let formErrors = {};
    let formIsValid = true;
    var Title = document.getElementById("title").value;
    var Desc = document.getElementById('desc').value;
    var roles = document.getElementById("roles").value;
   
    if (!Title) {
      formIsValid = false;
      formErrors["TitleErr"] = "Title is required";
    }
    if (!Desc) {
      formIsValid = false;
      formErrors["DescErr"] = "Description is required";
    }
    if (!roles) {
      formIsValid = false;
      formErrors["rolesErr"] = "Select Entity ";
    }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  };
  render() {
    var w = window.innerWidth;
    const { BusinessUnit,TitleErr,DescErr,rolesErr } = this.state.formErrors;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal AnnoneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="EditAnnpop"
          >
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button><br />
            <form autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
            <div className="container">
              <div className="row"  style={{
                  alignItems: "center",
                  justifyContent: "center",
                  // width:'98%'
                }}>
                 <input
                    type="hidden"
                    name="CreatedDate"
                    defaultValue={this.props.values.createdDate}
                  />
               
                <div className="col-lg-5 input-effect">

                  <input
                    type="hidden"
                    name="Id"
                    defaultValue={this.props.values.id}
                  />
                  <textarea
                    className="form"
                    defaultValue={this.props.values.title}
                    id="title"
                    name="title"
                    type="text"
                    onChange={this.handleChange}
                    placeholder=" "
                   
                  />
               
                  {!TitleErr ? (
                                    <span style={{color:'rgb(162, 157, 157)',float: "left"}}>Subject</span>
                                  ) : (
                                    <div style={{ color: "red",float: "left" }}>
                                      {TitleErr}
                                    </div>
                                  )}
                                  <span className="underline" />
                </div>
                <div className="col-lg-5 input-effect">
                  <input
                    type="hidden"
                    name="Id"
                    defaultValue={this.props.values.id}
                  />
                  <textarea
                    className="form"
                    defaultValue={this.props.values.message}
                    id="desc"
                    name="message"
                    type="text"
                    onChange={this.handleChange}
                    placeholder=""
                  />

{!DescErr ? (
                                    <span style={{color:'rgb(162, 157, 157)',float: "left"}}>Description</span>
                                  ) : (
                                    <div style={{ color: "red",float: "left" }}>
                                      {DescErr}
                                    </div>
                                  )}
                                  <span className="underline" />
                </div>
                <div className="col-lg-5 input-effect">
                <input
                    type="hidden"
                    name="Id"
                    defaultValue={this.props.values.id}
                  />
                                  <select
                                    className="form selectleftalign"
                                    defaultValue={this.props.values.role}
                                    id="roles"
                                    name="Role"
                                    onChange={this.handleChange}
                                  >
                                    <option value="">Select</option>
                                
                                    <option value="All Entities">All Entities</option>
                                    <option value="Active Entities">Active Entities</option>
                                    <option value="InActive Entities">InActive Entities</option>

                              
                                  </select>

                                  {!rolesErr ? (
                                    <span style={{color:'rgb(162, 157, 157)',float: "left"}}>Entities</span>
                                  ) : (
                                    <div style={{ color: "red",float: "left" }}>
                                      {rolesErr}
                                    </div>
                                  )}
                                  <span className="underline" />
                                </div>
                                <div className="col-lg-5">
                                <input
                    type="hidden"
                    name="Id"
                    defaultValue={this.props.values.id}
                  />
                  <input
                    type="hidden"
                    name="oldimage"
                    defaultValue={
                      this.props.values.image
                    }
                  />
                                <input
                    className="form"
                    defaultValue={this.props.values.image}
                    id="image"
                    name="image"
                    onFocus={(e) => (e.target.type = "file")}
                    onChange={this.handleChange}
                    placeholder=""
                    multiple
                  />
<span style={{color:'rgb(162, 157, 157)',float: "left" }}>Upload Related Files</span>
                                  <span className="underline" />
                                </div>

                                
              </div>
</div>
              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                    id="savebtn"
                  >
                    Save
                  </button>
                </div>
              </div>
              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
