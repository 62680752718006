import React, { Component } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "../../Css/Textbox.css";
import $ from "jquery";
import EditFamilyModel from "../../Common/EditFamilyModel";
import EditEmergencyModel from "../../Common/EditEmergencyModel";
import EditSkillModel from "../../Common/EditSkillModel";
import EditUserEduModel from "../../Common/EditUserEduModel";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { BASE_URLs } from "../../Config/Base";
export default class userprofile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: [],
      Name: "",
      HName: "",
      gender: "",
      DOB: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      maritalstatus: "",
      MobileNo: "",
      Profile: "",
      email: "",
      location: "",
      twitter: "",
      skype: "",
      website: "",
      openedu: false,
      openfam: false,
      openskill: false,
      imagePreviewUrl: null,
      imgCoverPreviewUrl: "",
      Degree: "",
      Institution: "",
      University: "",
      Graduation_Year: "",
      Contact: "",
      Email: "",
      Relationship: "",
      EmergencyContact: "",
      comlogo: [],
      Bloodgroup: "",
      formErrors: {},
      Profile_Pic: [],
      EducationDetail: [],
      SkillDetails: [],
      FamilyDetails: [],
      EmergencyDetails: [],
      Bankdetail: [],
      FamModel: false,
      FamilyEditId: "",
      EmergencyModel: false,
      EmergencyEditId: "",
      SkillModel: false,
      SkillEditId: "",
      RelationshipDrop: [],
      EditFamValues: [],
      HouseTypeDrop: [],
      EducationDrop: [],
      EditECValues: [],
      UserEduModel: false,
      UserEduEditId: "",
      UploadCertificate: false,
      SKillAdminCategoryDrop: [],
      EducationCourseName: [],
      bank: false,
      isSelected: false,
      isSelected1: false,
      isReadOnly: false,
      isButtonDisabled: false,
      isButtonDisabledA: false,
      isButtonDisabledB: false,
      SKillAdminNameDrop: [],
      maxDate: this.calculateMaxDate(),
      Preview: null,
      checkbox1Checked: false,
      checkbox2Checked: false,
      showing: true,
      showing1: true,
      showing2: true,
      clickCount: 0,
      errorMessage: '',
      isChrome: false,
      initialValues: {},
      ButtonDisabled: false,
    };
    this.initialState = this.state;
    this.FamModel = this.FamModel.bind(this);
    this.EmergencyModel = this.EmergencyModel.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleCoverImageChange = this.handleCoverImageChange.bind(this);
    this.handleEducatValidation = this.handleEducatValidation.bind(this);
    this.DepentsformValidation = this.DepentsformValidation.bind(this);
    // this.ContactsValidation = this.ContactsValidation.bind(this);
    this.handleSkillValidation = this.handleSkillValidation.bind(this);
    this.FamilyDetailList = this.FamilyDetailList.bind(this);
    this.EmergencyDetailList = this.EmergencyDetailList.bind(this);
    //this.EducationDetailList=this.EducationDetailList.bind(this);

    this.fileInputRef = React.createRef();
  }

  today = new Date();
  calculateMaxDate() {
    const today = new Date();
    const maxDate = today.toISOString().split("T")[0];
    return maxDate;
  }

  DelUserEducation = (cdate, uId) => {
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      const data = {
        CreatedDate: cdate,
        UserId: uId
      };
      // console.log("dellll", data);

      axios({
        method: "POST",
        url: `${BASE_URLs}UserEducationDetails/DelUserEducation`,
        data: data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "multipart/form-data",

          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      }).then((response) => {
        if (response.data.status === "Success") {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.EducationDetailList();
      });
    }
  };

  DelUserFamily = (cdate, uId) => {
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      const data = {
        CreatedDate: cdate,
        UserId: uId
      };

      axios({
        method: "POST",
        url: `${BASE_URLs}Family/DelUserFamily`,
        data: data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "multipart/form-data",

          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      }).then((response) => {
        if (response.data.status === "Success") {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.FamilyDetailList();
      });
    }
  };
  DelUserSkill = (Id) => {
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      const data = {
        Id: Id,
      };

      axios({
        method: "POST",
        url: `${BASE_URLs}UserSkillDetails/DelUserSkill`,
        data: data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "multipart/form-data",

          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      }).then((response) => {
        if (response.data.status === "Success") {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.SkillDetailList();
      });
    }
  };

  handleCheckboxChange = (checkboxNumber) => {
    if (checkboxNumber === 1) {
      this.setState({
        checkbox1Checked: true,
        checkbox2Checked: false,
      });
    } else {
      this.setState({
        checkbox1Checked: false,
        checkbox2Checked: true,
      });
    }
  };

  handlePermanentAddress = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let formErrors = {};
    let formIsValid = true;

    var PermanentAddress_Country = document.getElementById(
      "PermanentAddress_Country"
    ).value;
    var PermanentAddress_State = document.getElementById(
      "PermanentAddress_State"
    ).value;

    if (PermanentAddress_Country == "India") {
      // alert(country);

      this.setState({
        isSelected1: true,
      });
    } else if (PermanentAddress_Country != "India") {
      this.setState({
        isSelected1: false,
      });
    }

    // if (
    //   !PermanentAddress_Country === "" ||
    //   !PermanentAddress_Country ||
    //   PermanentAddress_Country === "-"
    // ) {
    //   formIsValid = false;
    //   // formErrors["PermanentAddress_Country"] = "Country is required";
    // } else if (
    //   PermanentAddress_State === "" ||
    //   !PermanentAddress_State ||
    //   PermanentAddress_State === "-"
    // ) {
    //   formIsValid = false;
    //   // formErrors["PermanentAddress_State"] = "State is required";
    // }

    //  var PermanentAddress = document.getElementById("PermanentAddress").value;
    //  var PermanentAddress_Country = document.getElementById(
    //    "PermanentAddress_Country"
    //  ).value;
    //  var PermanentAddress_State = document.getElementById(
    //    "PermanentAddress_State"
    //  ).value;
    //  var PermanentAddress_City = document.getElementById(
    //    "PermanentAddress_City"
    //  ).value;
    //  var PermanentAddress_Pincode = document.getElementById(
    //    "PermanentAddress_Pincode"
    //  ).value;

    //  if ( PermanentAddress === "-") {
    //    formIsValid = false;
    //    formErrors["PermanentAddress"] = "Permanent Address is required";
    //  } else if (PermanentAddress.length < 3) {
    //    formIsValid = false;
    //    formErrors["PermanentAddress"] = "A Minimum of 3 characters are required";
    //  } else if (

    //    PermanentAddress_City === "-"
    //  ) {
    //    formIsValid = false;
    //    formErrors["PermanentAddress_City"] = "City is required";
    //  } else if (PermanentAddress_City.length < 3) {
    //    formIsValid = false;
    //    formErrors["PermanentAddress_City"] = "A Minimum of 3 characters are required";
    //  } else if (
    //   !PermanentAddress_Country ||
    //    PermanentAddress_Country === "-"
    //  ) {
    //    formIsValid = false;
    //    formErrors["PermanentAddress_Country"] = "Country is required";
    //  } else if (

    //    PermanentAddress_State === "-"
    //  ) {
    //    formIsValid = false;
    //    formErrors["PermanentAddress_State"] = "State is required";
    //  } else if (

    //    PermanentAddress_Pincode === "-"
    //  ) {
    //    formIsValid = false;
    //    formErrors["PermanentAddress_Pincode"] = "Pincode is required";
    //  } else if (PermanentAddress_Pincode.length < 6) {
    //    formIsValid = false;
    //    formErrors["PermanentAddress_Pincode"] = "Maximum 6 Charatcers required";
    //  } 

    this.setState({ formErrors: formErrors });

    return formIsValid;
  };

  handlecheckbox() {
    var checkbox = document.getElementById("SameAddress").checked;

    var PermanentAddress = document.getElementById("PermanentAddress");
    // var PermanantAddress2 = document.getElementById('PermanantAddress2')
    var PermanentAddress_Country = document.getElementById(
      "PermanentAddress_Country"
    );
    var PermanentAddress_State = document.getElementById(
      "PermanentAddress_State"
    );
    var PermanentAddress_City = document.getElementById(
      "PermanentAddress_City"
    );
    var PermanentAddress_Pincode = document.getElementById(
      "PermanentAddress_Pincode"
    );

    var CurrentAddress = document.getElementById("CurrentAddress").value;
    // var CurrentAddress2 = document.getElementById("CurrentAddress2").value
    var CurrentAddress_Country = document.getElementById(
      "CurrentAddress_Country"
    ).value;
    var CurrentAddress_State = document.getElementById(
      "CurrentAddress_State"
    ).value;
    var CurrentAddress_City = document.getElementById(
      "CurrentAddress_City"
    ).value;
    var CurrentAddress_Pincode = document.getElementById(
      "CurrentAddress_Pincode"
    ).value;
    if (checkbox === true) {
      PermanentAddress.value = CurrentAddress;
      // PermanantAddress2.value = CurrentAddress2
      PermanentAddress_Country.value = CurrentAddress_Country;
      PermanentAddress_State.value = CurrentAddress_State;
      PermanentAddress_City.value = CurrentAddress_City;
      PermanentAddress_Pincode.value = CurrentAddress_Pincode;
    } else {
      PermanentAddress.value = "-";
      // PermanantAddress2.value = ""
      PermanentAddress_Country.value = "-";
      PermanentAddress_State.value = "-";
      PermanentAddress_City.value = "-";
      PermanentAddress_Pincode.value = "-";
    }
  }

  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var Qualification = document.getElementById("Qualification").value;
    var St_date = document.getElementById("St_date").value;
    var Coursename = document.getElementById("Coursename").value;
    var End_date = document.getElementById("End_date").value;
    var Coursetype = document.getElementById("Coursetype").value;
    var clg_name = document.getElementById("clg_name").value;
    var stream = document.getElementById("stream").value;
    var unisty_name = document.getElementById("unisty_name").value;

    if (!Qualification) {
      formIsValid = false;
      formErrors["Qualification"] = "Qualification  is required";
    }

    if (!St_date) {
      formIsValid = false;
      formErrors["St_date"] = "Start Date is required";
    }

    if (!Coursename) {
      formIsValid = false;
      formErrors["Coursename"] = "Course Name is required";
    }
    if (!End_date) {
      formIsValid = false;
      formErrors["End_date"] = "End Date is required";
    } else if (St_date !== null && End_date !== null) {
      if (St_date === End_date) {
        formIsValid = false;
        formErrors["End_date"] = " Start Date and End Date  is Same";
      }
      if (new Date(St_date).getTime() > new Date(End_date).getTime()) {
        formIsValid = false;

        formErrors["End_date"] = " Start Date Should less then  End Date";
      }
    }

    if (!Coursetype) {
      formIsValid = false;
      formErrors["Coursetype"] = "Course Type is required";
    }
    if (clg_name !== "" && clg_name.length < 3) {
      formIsValid = false;
      formErrors["clg_name"] = "A minimum of 3 characters is required";
    }
    if (stream !== "" && stream.length < 3) {

      formIsValid = false;
      formErrors["stream"] = "A minimum of 3 characters is required";
    }
    if (!unisty_name) {
      formIsValid = false;
      formErrors["unisty_name"] = "University Name is required";
    }

    else if (unisty_name.length <= 2) {
      formIsValid = false;
      formErrors["unisty_name"] = "A Minimum of 3 characters are required";
    }

    if (clg_name) {
      if (clg_name.length <= 2) {
        formIsValid = false;
        formErrors["clg_name"] = "A Minimum of 3 characters are required";
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  EduSubmit = async (e) => {
    e.preventDefault();
    if (this.Validation()) {
      this.setState({ isButtonDisabledA: true });

      const data = new FormData(e.target);

      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      await fetch(`${BASE_URLs}UserEducationDetails/AddUserEducation`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Error") {
            setTimeout(() => {
              this.setState({ isButtonDisabledA: false });
            }, 3200);
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          this.EducationDetailList();
          if (resp.status !== "Error") {
            // this.EducationDetailList();        
            document.getElementById("EduForm").reset();
            this.setState({ openedu: false, showing: true, clickCount: 0, });
            this.setState({ isButtonDisabledA: false });
          }
        })
    }

  };

  handleTextareaClick = (e) => {

    const isChecked = e.target.checked;
    this.setState({
      isReadOnly: isChecked ? !this.state.isReadOnly : false
    });

  };

  handleTextarea = () => {

    var Pvalue = document.getElementById('PermanentAddress').value;
    var Cvalue = document.getElementById('CurrentAddress').value;
    var status = document.getElementById('SameAddress')

    if (Pvalue !== Cvalue || Pvalue === '-') {
      status.checked = false
      this.setState({
        isReadOnly: false,
      });

    }
    else {
      status.checked = true
      this.setState({
        isReadOnly: true,
      });
    }

    this.setState({ formErrors: "" });
  };

  handleEducatValidation() {
    const { Degree, Institution, University, Graduation_Year } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Education
    if (!Degree) {
      formIsValid = false;
      formErrors["DegreeErr"] = "Degree is required";
      if (Degree === "10th" || Degree === "12th") {
        formIsValid = true;
        //
        //formErrors["DegreeErr"] = "Degree is required" ;
      } else if (!University) {
        formIsValid = false;
        formErrors["marksErr"] = "University is required";
      }
    }

    if (!Institution) {
      formIsValid = false;
      formErrors["institute_nameErr"] = "Institution is required";
    }
    if (!Graduation_Year) {
      formIsValid = false;
      formErrors["pasyearErr"] = "Graduation  Year is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  HandleImage = (e) => {
    var src = URL.createObjectURL(e.target.files[0]);
    this.setState({ Preview: src });
  };

  // HandleImage = (e) => {
  //   const file = e.target.files[0];
  //   const fileType = file.type.split('/')[0]; // Get the file type (e.g., 'image', 'application', etc.)

  //   // Check if the file type is 'image'
  //   if (fileType === 'image') {
  //     const src = URL.createObjectURL(file);
  //     this.setState({ Preview: src, errorMessage: '' });
  //   } else {
  //     // If file type is not 'image', display an error message
  //     this.setState({ errorMessage: 'Please select an image file.' });
  //   }
  // };

  resetImage = () => {
    this.setState({
      Preview: "",
    });
  };

  handleSkillValidation() {
    const { SkillName, SkillDescription, Category, Experience } = this.state;
    let formErrors = {};
    let formIsValid = true;
    var uploadcertificate =
      document.getElementById("UploadCertificate").checked;

    if (!SkillName) {
      formIsValid = false;
      formErrors["SkillNameErr"] = "Name is required";
    }
    // if (!SkillDescription) {
    //   formIsValid = false;
    //   formErrors["SkillDescriptionErr"] = "Description is required";
    // }
    // else if (SkillDescription.length < 3) {
    //   formIsValid = false;
    //   formErrors["SkillDescriptionErr"] = "A minimum 3 characters required";
    // }

    // if (!Category) {
    //   formIsValid = false;
    //   formErrors["CategoryErr"] = "Category is required";
    // }
    if (!Experience) {
      formIsValid = false;
      formErrors["ExperienceErr"] = "Experience is required";
    }
    if (uploadcertificate) {
      var skillcoursename = document.getElementById("skillcoursename").value;
      var skillCoursestartdate = document.getElementById(
        "skillCoursestartdate"
      ).value;
      var skillCourseEnddate =
        document.getElementById("skillCourseEnddate").value;
      //Email
      if (!skillcoursename) {
        formIsValid = false;
        formErrors["skillcoursenameErr"] = " Course Name is required";
      }
      if (!skillCoursestartdate) {
        formIsValid = false;
        formErrors["skillCoursestartdateErr"] = "Start From is required";
      }
      if (!skillCourseEnddate) {
        formIsValid = false;
        formErrors["skillCourseEnddateErr"] = "End is required";
      }
      var St_date = document.getElementById("skillCoursestartdate").value;
      var End_date = document.getElementById("skillCourseEnddate").value;
      var skillCertificate = document.getElementById("SkillCertificate").value;
      if (new Date(St_date).getTime() >= new Date(End_date).getTime()) {
        formIsValid = false;

        formErrors["skillCourseEnddateErr"] =
          " Start From Should less then End From";
      }
      if (!skillCertificate) {
        formIsValid = false;
        formErrors["skillCertificateErr"] = " Certificate is required";
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  formreset = () => {
    document.getElementById("EduForm").reset();
    this.setState({ formErrors: "" });
  };
  skillformreset = () => {
    document.getElementById("skillform").reset();
    this.setState({ formErrors: "", UploadCertificate:false,checkbox1Checked:false,checkbox2Checked:false });
  };

  familyformreset = () => {
    document.getElementById("familyform").reset();
    this.setState({ formErrors: "" });
  };

  DepentsformValidation() {
    var FirstName = document.getElementById("FirstNameFam").value;
    var LastName = document.getElementById("LastNameFam").value;
    var Contact = document.getElementById("ContactFam").value;
    var Email = document.getElementById("EmailFam").value;
    var Relationship = document.getElementById("RelationshipFam").value;
    var EmergencyContact = document.getElementById("EmergencyContact").checked;
    let formErrors = {};
    let formIsValid = true;

    var mobPattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?(?!0+$)(?:[6-9]\d{9}|[6-9]\d{2}-\d{3}-\d{4})$/;
    //Email
    if (!FirstName) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "First Name is required";
    } else if (FirstName.length < 3) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "Minimum 3 Characters Required";
    }
    if (!LastName) {
      formIsValid = false;
      formErrors["LastNameErr"] = "Last Name is required";
    }

    if (EmergencyContact === true) {

      if (!Contact) {
        formIsValid = false;
        formErrors["ContactErr"] = "Contact number is required";
      } else if (!mobPattern.test(Contact)) {
        formIsValid = false;
        formErrors["ContactErr"] = " Enter the valid Contact Number";
      }
    }

    if (EmergencyContact === false) {
      if (Contact !== "" && Contact !== "-" && Contact.length < 10 && !mobPattern.test(Contact)) {
        formIsValid = false;
        formErrors["ContactErr"] = "Enter a valid Contact Number";
      }
    }
    //123

    // if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email))) {
    //   formIsValid = false;
    //   formErrors["EmailErr"] = "Enter valid Email";
    // }
    if (!Relationship) {
      formIsValid = false;
      formErrors["RelationshipErr"] = "Relationship is required";
    }


    else if (Email.trim()) {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
        formIsValid = false;
        formErrors["EmailErr"] = "Enter a valid Email";
      }
    }
    this.setState({ formErrors: formErrors });

    //document.getElementById("add-row2").style.display = "none";
    return formIsValid;
  }
  handlecontactChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let formErrors = {};
    let formIsValid = true;
    // var mobPattern =
    //   /((\+*)((0[ -]*)*|((91 )*))((\d{10})+|(\d{10})+))|\d{5}([- ]*)\d{6}/;
    var mobPattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?(?!0+$)(?:[6-9]\d{9}|[6-9]\d{2}-\d{3}-\d{4})$/;
    var officialEmail = document.getElementById("OfficialEmail").value; //personal email
    var PhoneNumber = document.getElementById("PhoneNumber").value;
    var PersonalEmail = document.getElementById("PersonalEmail").value; //auto email
    var AlternatePhoneNo = document.getElementById("AlternatePhoneNo").value;
    var HomeNumber = document.getElementById("HomeNumber").value;
    // var Email = document.getElementById("Homeno").value
    // if (!PhoneNumber||PhoneNumber==='-') {
    //   formIsValid = false;
    //   formErrors["contactphnerr"] = "Office Number is required";
    // }
    // else if (!mobPattern.test(PhoneNumber)) {
    //   formIsValid = false;
    //   formErrors["contactphnerr"] = " Enter the valid Office Number";
    // }

    if (!HomeNumber || HomeNumber === "-") {
      formIsValid = false;
      formErrors["HomeErr"] = "Phone Number is required";
    } else if (!mobPattern.test(HomeNumber)) {
      formIsValid = false;
      formErrors["HomeErr"] = " Enter the valid Phone Number";
    }
    else if (!officialEmail.trim() || officialEmail === "-") {
      formIsValid = false;
      formErrors["officialEmailerr"] = " Enter the Personal Email";
    }
    else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(officialEmail)) {
      formIsValid = false;
      formErrors["officialEmailerr"] = "Enter valid Personal Email";
    }
    else if (officialEmail === PersonalEmail) {
      formIsValid = false;
      formErrors["officialEmailerr"] =
        "Personal Email and Official Email should not be same";
    }
    //   else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(officialEmail))) {
    //     formIsValid = false;
    //     formErrors["PersonalEmailerr"] = "Enter valid Personal Email";
    //   }
    // }
    else if (AlternatePhoneNo.trim() && AlternatePhoneNo !== "-") {
      if (PhoneNumber === AlternatePhoneNo) {
        formIsValid = false;
        formErrors["AlternatePhoneNoerr"] = "Office Number and Alternate Phone Number should not be same";
      } else if (HomeNumber === AlternatePhoneNo) {
        formIsValid = false;
        formErrors["AlternatePhoneNoerr"] = "Phone Number and Alternate Phone Number should not be same";
      } else if (!mobPattern.test(AlternatePhoneNo)) {
        formIsValid = false;
        formErrors["AlternatePhoneNoerr"] = "Enter the valid Alternate Phone Number";
      }
    }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  };
  ContactValidation() {
    let formErrors = {};
    let formIsValid = true;
    // var mobPattern =
    //   /((\+*)((0[ -]*)*|((91 )*))((\d{10})+|(\d{10})+))|\d{5}([- ]*)\d{6}/;
    var mobPattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?(?!0+$)(?:[6-9]\d{9}|[6-9]\d{2}-\d{3}-\d{4})$/;
    var officialEmail = document.getElementById("OfficialEmail").value; //personal email
    var PhoneNumber = document.getElementById("PhoneNumber").value;
    var PersonalEmail = document.getElementById("PersonalEmail").value; //auto email
    var AlternatePhoneNo = document.getElementById("AlternatePhoneNo").value;
    var HomeNumber = document.getElementById("HomeNumber").value;

    var officePattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?(?!0+$)(?:[0-9]\d{9}|[0-9]\d{2}-\d{3}-\d{4})$/;
    // var Email = document.getElementById("Homeno").value
    // if (!PhoneNumber||PhoneNumber==='-') {
    //   formIsValid = false;
    //   formErrors["contactphnerr"] = "Office Number is required";
    // }
    // else if (!mobPattern.test(PhoneNumber)) {
    //   formIsValid = false;
    //   formErrors["contactphnerr"] = " Enter the valid Office Number";
    // }

    if (!HomeNumber || HomeNumber === "-") {
      formIsValid = false;

      formErrors["HomeErr"] = "Phone Number is required";
    } else if (!mobPattern.test(HomeNumber)) {
      formIsValid = false;
      formErrors["HomeErr"] = " Enter the valid Phone Number";
    } else if (officialEmail.trim()) {
      if (officialEmail === PersonalEmail) {
        formIsValid = false;
        formErrors["PersonalEmailerr"] =
          "Personal Email and Official Email should not be same";
      } else if (
        !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(officialEmail)
      ) {
        formIsValid = false;
        formErrors["PersonalEmailerr"] = "Enter valid Personal Email";
      }
    }

    if (!officialEmail.trim() || officialEmail === "-") {
      formIsValid = false;
      formErrors["officialEmailerr"] = " Enter the Personal Email";
    }



    if (HomeNumber === AlternatePhoneNo && HomeNumber != "-" && HomeNumber != "") {
      formIsValid = false;
      formErrors["AlternatePhoneNoerr"] = "Phone Number and Alternate Phone Number should not be same";

    }

    if (PhoneNumber === AlternatePhoneNo && AlternatePhoneNo !== '-' && PhoneNumber != "") {
      formIsValid = false;
      formErrors["AlternatePhoneNoerr"] = "Office Number and Alternate Phone Number should not be same";

    }

    if (PhoneNumber === HomeNumber && HomeNumber != "-" && HomeNumber != "") {
      formIsValid = false;
      formErrors["HomeErr"] = "Phone Number and Office Number should not be same";

    }

    if (AlternatePhoneNo === HomeNumber && HomeNumber != "-" && HomeNumber != "") {
      formIsValid = false;
      formErrors["AlternatePhoneNoerr"] = "Phone Number and Alternate Phone Number should not be same";

    }
    if (PhoneNumber.trim() !== '-' && PhoneNumber.trim() !== '') {
      if (!officePattern.test(PhoneNumber)) {
        formIsValid = false;
        formErrors["contactphnerr"] = " Enter the valid Office Number";
      }
    }
    if (AlternatePhoneNo.trim() !== '-' && AlternatePhoneNo.trim() !== '') {
      if (!mobPattern.test(AlternatePhoneNo)) {
        formIsValid = false;
        formErrors["AlternatePhoneNoerr"] = " Enter the valid Alternate Phone Number";
      }
    }

    //  else if (HomeNumber===AlternatePhoneNo) {
    //     formIsValid = false;
    //     formErrors["AlternatePhoneNoerr"] = "Home Number and AlternatePhoneNo should not be same";

    // }
    // else if (officialEmail.trim()) {
    //   if (officialEmail === PersonalEmail) {
    //     formIsValid = false;
    //     formErrors["PersonalEmailerr"] = "Personal Email and Official Email should not be same";
    //   }
    //   else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(officialEmail))) {
    //     formIsValid = false;
    //     formErrors["PersonalEmailerr"] = "Enter valid Personal Email";
    //   }
    // }
    // else if (AlternatePhoneNo.trim()) {
    // else if (PhoneNumber===AlternatePhoneNo) {
    //     formIsValid = false;
    //     formErrors["AlternatePhoneNoerr"] = "Office Number and AlternatePhoneNo should not be same";

    // }
    //  else if (HomeNumber===AlternatePhoneNo) {
    //     formIsValid = false;
    //     formErrors["AlternatePhoneNoerr"] = "Home Number and AlternatePhoneNo should not be same";

    // }
    // else if (!mobPattern.test(AlternatePhoneNo)) {
    //   formIsValid = false;
    //   formErrors["AlternatePhoneNoerr"] = "Enter valid Phone Number";
    // }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleChange = (e) => {
    var FirstName = document.getElementById("FirstNameFam").value;
    var LastName = document.getElementById("LastNameFam").value;
    var Contact = document.getElementById("ContactFam").value;
    var Email = document.getElementById("EmailFam").value;
    var Relationship = document.getElementById("RelationshipFam").value;
    var EmergencyContact = document.getElementById("EmergencyContact").checked;

    const { name, value } = e.target;
    this.setState({ [name]: value });
    let formErrors = {};
    let formIsValid = true;

    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
    //Email
    if (!FirstName) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "First Name is required";
    } else if (FirstName.length < 3) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "Minimum 3 Characters Required";
    } else if (!LastName) {
      formIsValid = false;
      formErrors["LastNameErr"] = "Last Name is required";
    } else if (EmergencyContact === true) {
      if (!Contact) {
        formIsValid = false;
        // formErrors["ContactErr"] = "Contact number is required";
      } else if (!mobPattern.test(Contact)) {
        formIsValid = false;
        // formErrors["ContactErr"] = " Enter the valid Contact Number";
      }

      // else if (!Email) {
      //   formIsValid = false;
      //   formErrors["EmailErr"] = "Email is required";
      // }

      else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
        formIsValid = false;
        // formErrors["EmailErr"] = "Enter valid Email";
      } else if (!Relationship) {
        formIsValid = false;
        formErrors["RelationshipErr"] = "Relationship is required";
      }

    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };
  handleSkillChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    const { SkillName, SkillDescription, Category } = this.state;
    let formErrors = {};
    let formIsValid = true;
    var uploadcertificate =
      document.getElementById("UploadCertificate").checked;

    var skillName = document.getElementById("skillname").value;
    var Experience = document.getElementById("exp").value;

    if (!skillName) {
      formIsValid = false;
      formErrors["SkillNameErr"] = "Name is required";
    }
    // else if (!SkillDescription) {
    //   formIsValid = false;
    //   // formErrors["SkillDescriptionErr"] = "Description is required";
    // }
    // else if (SkillDescription.length < 3) {
    //   formIsValid = false;
    //   // formErrors["SkillDescriptionErr"] = "A minimum 3 characters required";
    // }

    // else if (!Category) {
    //   formIsValid = false;
    //   // formErrors["CategoryErr"] = "Category is required";
    // }
    else if (!Experience) {
      formIsValid = false;
      formErrors["ExperienceErr"] = "Experience is required";
    }
    if (uploadcertificate) {
      var skillcoursename = document.getElementById("skillcoursename").value;
      var skillCoursestartdate = document.getElementById(
        "skillCoursestartdate"
      ).value;
      var skillCourseEnddate =
        document.getElementById("skillCourseEnddate").value;
      //Email
      if (!skillcoursename) {
        formIsValid = false;
        formErrors["skillcoursenameErr"] = " Course Name is required";
      } else if (!skillCoursestartdate) {
        formIsValid = false;
        formErrors["skillCoursestartdateErr"] = "Start Date is required";
      } else if (!skillCourseEnddate) {
        formIsValid = false;
        formErrors["skillCourseEnddateErr"] = "End Date is required";
      }
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };
  // checking
  handleEducationChange = (e) => {
    let formErrors = {};
    let formIsValid = true;
    const { name, value } = e.target;
    this.setState({ [name]: value });

    var Qualification = document.getElementById("Qualification").value;
    var St_date = document.getElementById("St_date").value;
    var Coursename = document.getElementById("Coursename").value;
    var End_date = document.getElementById("End_date").value;
    var Coursetype = document.getElementById("Coursetype").value;
    var clg_name = document.getElementById("clg_name").value;
    var stream = document.getElementById("stream").value;
    var unisty_name = document.getElementById("unisty_name").value;

    if (!Qualification) {
      formIsValid = false;
      formErrors["Qualification"] = "Qualification  is required";
    } else if (!Coursename) {
      formIsValid = false;
      formErrors["Coursename"] = "Course Name is required";
    }
    else if (Coursename.length < 2) {
      formIsValid = false;
      formErrors["Coursename"] = "A Minimum of 2 characters are required";
    }
    else if (!Coursetype) {
      formIsValid = false;
      formErrors["Coursetype"] = "Course Type is required";
    }
    // else if (!stream) {

    //   formIsValid = false;
    //   // formErrors["stream"] = "Specialization is required";
    // }
    // else if (stream.length < 3) {
    //   formIsValid = false;
    //   formErrors["stream"] = "A Minimum of 3 characters are required";
    // } 
    else if (!St_date) {
      formIsValid = false;
      formErrors["St_date"] = "Start From is required";
    }
    else if (!End_date) {
      formIsValid = false;
      formErrors["End_date"] = "End is required";

      if (St_date !== null && End_date !== null) {
        if (St_date === End_date) {
          formIsValid = false;
          formErrors["End_date"] = " Start From and End Date is From";
        }
        if (new Date(St_date).getTime() > new Date(End_date).getTime()) {
          formIsValid = false;

          formErrors["End_date"] = " Start From Should less then End From";
        }
      }
    }
    else if (!unisty_name) {
      formIsValid = false;
      formErrors["unisty_name"] = "University Name is required";
    } else if (unisty_name.length < 3) {
      formIsValid = false;
      formErrors["unisty_name"] = "A Minimum of 3 characters are required";
    }
    if (clg_name) {
      if (clg_name.length <= 2) {
        formIsValid = false;
        formErrors["clg_name"] = "A Minimum of 3 characters are required";
      }
    }
    // else if (!clg_name) {
    //   formIsValid = false;
    //   // formErrors["clg_name"] = "College Name  is required";
    // }
    // else if (clg_name.length < 3) {
    //   formIsValid = false;
    //   // formErrors["clg_name"] = "A Minimum of 3 characters are required";
    // }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };

  handlevalueChange = (e) => {
    e.preventDefault();
    var value = e.target.value;
    //e.target.value

    if (
      e.target.value === "Graduation" ||
      e.target.value === "Post Graduation" ||
      e.target.value === "Doctorate"
    ) {
      var flag =
        value === "Graduation"
          ? "1"
          : value === "Post Graduation"
            ? "2"
            : value === "Doctorate"
              ? "3"
              : null;

      const options = {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      };
      fetch(
        ` ${BASE_URLs}UserEducationDetails/GetUserEducationCourseName?flag=${flag}`,
        options
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({ EducationCourseName: data });
        });
    } else {
      this.setState({ EducationCourseName: [] });
    }
  };
  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });
      });
  }
  componentWillMount() {
    $(document).ready(function () {
      $("input[type='text']").on("click", function () {
        $(this).select();
      });
      $("input").focus(function () {
        $(this).select();
      });
      $("input").focusin(function () {
        $(this).select();
      });
    });
    $(document).ready(function () {
      $("#add-skillrow").hide();
    });
    $(document).ready(function () {
      $("#addBtn").click(function () {
        $("#add-skillrow").show();
      });
    });

    $(document).ready(function () {
      // $('#AddEDU').hide();
    });
    $(document).ready(function () {
      $("#addEduBtn").click(function () {
        $("#AddEDU").show();
      });
    });
    $(document).ready(function () {
      //$('#add-row').hide();
    });
    $(document).ready(function () {
      $("#addSkillBtn").click(function () {
        ///need
        $("#add-row").show();
      });
    });

    $(document).ready(function () {
      $("#saverow1").click(function () {
        //$("#add-row1").hide();
      });
      $("#addBtn1").click(function () {
        $("#add-row1").show();
      });
    });

    $(document).ready(function () {
      $("#add-row2Edu").hide();
    });
    $(document).ready(function () {
      /*   $("#saverow2").click(function(){
          $("#add-row2").hide();
        }); */
      $("#addBtn2").click(function () {
        $("#add-row2Edu").show();
      });
    });

    $(document).ready(function () {
      // $('#add-rowFamily').hide();
    });
    $(document).ready(function () {
      /*   $("#saverow2").click(function(){
          $("#add-row2").hide();
        }); */
      $("#addFmlyBtn").click(function () {
        $("#add-rowFamily").show();
      });
    });

    $(document).ready(function () {
      $("#add-rowEmergency").hide();
    });
    $(document).ready(function () {
      $("#addBtn4").click(function () {
        $("#add-rowEmergency").show();
      });
    });

    //Personal Profile
    $(document).ready(function () {
      $("#edit-info").hide();

      $("#edit-cancel").on("click", function () {
        var c = $("#edit-btn").find("i");
        c.removeClass("icofont-close");
        c.addClass("icofont-edit");
        $("#view-info").show();
        $("#edit-info").hide();
      });

      $("#edit-save").on("click", function () {
        var c = $("#edit-btn").find("i");
        c.removeClass("icofont-close");
        c.addClass("icofont-edit");
        $("#view-info").show();
        $("#edit-info").hide();
      });

      $("#edit-btn").on("click", function () {
        var b = $(this).find("i");
        var edit_class = b.attr("class");
        if (edit_class === "icofont icofont-edit") {
          b.removeClass("icofont-edit");
          b.addClass("icofont-close");
          $("#view-info").hide();
          $("#edit-info").show();
        } else {
          b.removeClass("icofont-close");
          b.addClass("icofont-edit");
          $("#view-info").show();
          $("#edit-info").hide();
          document.getElementById("PersonalInfo").reset();
        }
      });
      $("#edit-btn1").on("click", function () {
        var b = $(this).find("i");
        var edit_class = b.attr("class");
        if (edit_class === "icofont icofont-edit") {
          b.removeClass("icofont-edit");
          b.addClass("icofont-close");
          $("#view-info").hide();
          $("#edit-info").show();
        } else {
          b.removeClass("icofont-close");
          b.addClass("icofont-edit");
          $("#view-info").show();
          $("#edit-info").hide();
        }
      });
    });

    //Contact Info
    $(document).ready(function () {
      $("#edit-contact").hide();

      $("#edit-cancel").on("click", function () {
        var c = $("#edit-btn-contact").find("i");
        c.removeClass("icofont-close");
        c.addClass("icofont-edit");
        $("#view-contact").show();
        $("#edit-contact").hide();
      });

      $("#edit-save").on("click", function () {
        var c = $("#edit-btn-contact").find("i");
        c.removeClass("icofont-close");
        c.addClass("icofont-edit");
        $("#view-contact").show();
        $("#edit-contact").hide();
      });

      $("#edit-btn-contact").on("click", function () {
        var b = $(this).find("i");
        var edit_class = b.attr("class");
        if (edit_class === "icofont icofont-edit") {
          b.removeClass("icofont-edit");
          b.addClass("icofont-close");
          $("#view-contact").hide();
          $("#edit-contact").show();
        } else {
          b.removeClass("icofont-close");
          b.addClass("icofont-edit");
          $("#view-contact").show();
          $("#edit-contact").hide();
          document.getElementById("ContactInfo").reset();
        }
      });

      $("#edit-btn1").on("click", function () {
        var b = $(this).find("i");
        var edit_class = b.attr("class");
        if (edit_class === "icofont icofont-edit") {
          b.removeClass("icofont-edit");
          b.addClass("icofont-close");
          $("#view-contact").hide();
          $("#edit-contact").show();
        } else {
          b.removeClass("icofont-close");
          b.addClass("icofont-edit");
          $("#view-contact").show();
          $("#edit-contact").hide();
        }
      });
    });

    //Address Info
    $(document).ready(function () {
      $("#edit-Address").hide();

      $("#edit-cancel").on("click", function () {
        var c = $("#edit-btn-Address").find("i");
        c.removeClass("icofont-close");
        c.addClass("icofont-edit");
        $("#view-Address").show();
        $("#edit-Address").hide();
      });

      $("#edit-save").on("click", function () {
        var c = $("#edit-btn-Address").find("i");
        c.removeClass("icofont-close");
        c.addClass("icofont-edit");
        $("#view-Address").show();
        $("#edit-Address").hide();
      });

      $("#edit-btn-Address").on("click", function () {
        var b = $(this).find("i");
        var edit_class = b.attr("class");
        if (edit_class === "icofont icofont-edit") {
          b.removeClass("icofont-edit");
          b.addClass("icofont-close");
          $("#view-Address").hide();
          $("#edit-Address").show();
        } else {
          b.removeClass("icofont-close");
          b.addClass("icofont-edit");
          $("#view-Address").show();
          $("#edit-Address").hide();
          document.getElementById("AddressInfo").reset();
        }

      });
    });

    //Social media Info

    $(document).ready(function () {
      $("#edit-media").hide();

      $("#edit-cancel").on("click", function () {
        var c = $("#edit-btn-media").find("i");
        c.removeClass("icofont-close");
        c.addClass("icofont-edit");
        $("#view-media").show();
        $("#edit-media").hide();
      });

      $("#edit-save").on("click", function () {
        var c = $("#edit-btn-media").find("i");
        c.removeClass("icofont-close");
        c.addClass("icofont-edit");
        $("#view-media").show();
        $("#edit-media").hide();
      });

      $("#edit-btn-media").on("click", function () {
        var b = $(this).find("i");
        var edit_class = b.attr("class");
        if (edit_class === "icofont icofont-edit") {
          b.removeClass("icofont-edit");
          b.addClass("icofont-close");
          $("#view-media").hide();
          $("#edit-media").show();
        } else {
          b.removeClass("icofont-close");
          b.addClass("icofont-edit");
          $("#view-media").show();
          $("#edit-media").hide();
          document.getElementById("SocialInfo").reset();
        }
      });

      $("#edit-btn1").on("click", function () {
        var b = $(this).find("i");
        var edit_class = b.attr("class");
        if (edit_class === "icofont icofont-edit") {
          b.removeClass("icofont-edit");
          b.addClass("icofont-close");
          $("#view-media").hide();
          $("#edit-media").show();
        } else {
          b.removeClass("icofont-close");
          b.addClass("icofont-edit");
          $("#view-media").show();
          $("#edit-media").hide();
        }
      });
    });

    //Education info
    $(document).ready(function () {
      $("#Edit-EducationDetails").hide();

      $("#edit-cancel").on("click", function () {
        var c = $("#edit-btn-Education").find("i");
        c.removeClass("icofont-close");
        c.addClass("icofont-edit");
        $("#view-EducationDetails").show();
        $("#Edit-EducationDetails").hide();
      });

      $("#edit-save").on("click", function () {
        var c = $("#edit-btn-Education").find("i");
        c.removeClass("icofont-close");
        c.addClass("icofont-edit");
        $("#view-EducationDetails").show();
        $("#Edit-EducationDetails").hide();
      });

      $("#edit-btn-Education").on("click", function () {
        var b = $(this).find("i");
        var edit_class = b.attr("class");
        if (edit_class === "icofont icofont-edit") {
          b.removeClass("icofont-edit");
          b.addClass("icofont-close");
          $("#view-EducationDetails").hide();
          $("#Edit-EducationDetails").show();
        } else {
          b.removeClass("icofont-close");
          b.addClass("icofont-edit");
          $("#view-EducationDetails").show();
          $("#Edit-EducationDetails").hide();
        }
      });
      $("#edit-btn1").on("click", function () {
        var b = $(this).find("i");
        var edit_class = b.attr("class");
        if (edit_class === "icofont icofont-edit") {
          b.removeClass("icofont-edit");
          b.addClass("icofont-close");
          $("#view-EducationDetails").hide();
          $("#Edit-EducationDetails").show();
        } else {
          b.removeClass("icofont-close");
          b.addClass("icofont-edit");
          $("#view-EducationDetails").show();
          $("#Edit-EducationDetails").hide();
        }
      });
    });

    //Banking info
    $(document).ready(function () {
      $("#Edit-BankDetails").hide();

      $("#edit-cancel").on("click", function () {
        var c = $("#edit-btn-Bank").find("i");
        c.removeClass("icofont-close");
        c.addClass("icofont-edit");
        $("#view-BankDetails").show();
        $("#Edit-BankDetails").hide();
      });

      $("#edit-save").on("click", function () {
        var c = $("#edit-btn-Bank").find("i");
        c.removeClass("icofont-close");
        c.addClass("icofont-edit");
        $("#view-BankDetails").show();
        $("#Edit-BankDetails").hide();
      });

      $("#edit-btn-Bank").on("click", function () {
        var b = $(this).find("i");
        var edit_class = b.attr("class");
        if (edit_class === "icofont icofont-edit") {
          b.removeClass("icofont-edit");
          b.addClass("icofont-close");
          $("#view-BankDetails").hide();
          $("#Edit-BankDetails").show();
        } else {
          b.removeClass("icofont-close");
          b.addClass("icofont-edit");
          $("#view-BankDetails").show();
          $("#Edit-BankDetails").hide();
          document.getElementById("BankingForm").reset();
        }
      });
      $("#edit-btn1").on("click", function () {
        var b = $(this).find("i");
        var edit_class = b.attr("class");
        if (edit_class === "icofont icofont-edit") {
          b.removeClass("icofont-edit");
          b.addClass("icofont-close");
          $("#view-BankDetails").hide();
          $("#Edit-BankDetails").show();
        } else {
          b.removeClass("icofont-close");
          b.addClass("icofont-edit");
          $("#view-BankDetails").show();
          $("#Edit-BankDetails").hide();
        }
      });
    });
  }

  LogOut = (e) => {
    e.preventDefault();

    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    this.props.history.push("/Login_form");
  };

  profilelist() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Profile/Get`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ profile: data });
        // console.log("check", data);
      });
  }

  EducationDetailList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}UserEducationDetails/GetUserEducation`, options)
      .then((response) => response.json())
      .then((data) => {
        // console.log("EducationDetail", data)
        this.setState({ EducationDetail: data });
      });
  }

  BankdetailList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}UserBank/GetUserBankdetails`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Bankdetail: data });
        // console.log(data);
      });
  }

  SkillDetailList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}UserSkillDetails/GetUserSkill`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ SkillDetails: data });
        // console.log("skillsdetails", data)
      });
  }

  FamilyDetailList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Family/GetUserFamily`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ FamilyDetails: data });
        // console.log(data, "Familydetails");
      });
  }

  EmergencyDetailList() {
    // const options = {
    //   method: "GET",
    //       headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${JSON.parse(localStorage.user)}`
    //   },
    // };
    // fetch('h${BASE_URLs}Family/GetUserEmergency', options)
    //   .then(response => response.json())
    //   .then(data => {
    //     this.setState({ EmergencyDetails: data });
    //   }
    //   );
  }

  RelationshipDropList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Family/RelationshipDropdown`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ RelationshipDrop: data });
      });
  }

  GetUserSkillDrop() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}UserSkillDetails/GetUserSkillDrop`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ SKillAdminCategoryDrop: data });
        // console.log(data, "SKillAdminCategoryDrop");
      });
  }

  Skillsubmit = async (e) => {
    e.preventDefault();
    if (this.handleSkillValidation()) {
      this.setState({ isButtonDisabledB: true });
      const data = new FormData(e.target);
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      await fetch(`${BASE_URLs}UserSkillDetails/AddUserSkill`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Error") {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            if (this.state.SkillDetails.length === 0) {
              setTimeout(() => {
                window.location.reload(true);
              }, 3200);
            }
            this.setState({
              UploadCertificate: false,
            });
          }
        });
      this.setState({ isButtonDisabledB: false });
      document.getElementById("skillform").reset();
      // document.getElementById("add-row").style.display = "none";
      this.setState({ openskill: false, showing1: true, clickCount: 0, });
      this.setState({ SkillName: "" });
      this.setState({ SkillDescription: "" });
      this.setState({ Category: "" });
      this.setState({ Experience: "" });
    }
    this.SkillDetailList();
  };

  EditBankDetails = (e) => {
    e.preventDefault();
    if (this.BankValidation()) {
      const data = new FormData(e.target);
      this.setState({
        ButtonDisabled: true,
      });
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };

      fetch(`${BASE_URLs}UserBank/AddUserBank`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            this.BankdetailList();
            document.getElementById("edit-btn-Bank").click();
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({
              ButtonDisabled: false,
            });
            // setTimeout(() => {
            //   window.location.reload();
            // }, 3200);
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });

      this.BankdetailList();
    }
  };

  EditEducationDetails = async (e) => {
    e.preventDefault();
    if (this.EducationValidation()) {
      const data = new FormData(e.target);

      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      await fetch(
        `${BASE_URLs}UserEducationDetails/EditUserEducation`,
        options
      )
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Error") {
            alert(resp.message);
          } else {
            alert(resp.message);
            document.getElementById("Close_education").click();
          }
          this.EducationDetailList();
        });
    }
  };

  FamlySubmit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    if (this.DepentsformValidation()) {
      this.setState({ isButtonDisabled: true });
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      await fetch(`${BASE_URLs}Family/AddUserFamily`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Error") {
            this.setState({ openfam: true, showing2: false, clickCount: 1, });
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            this.setState({ openfam: false, showing2: true, clickCount: 0, });
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
      // document.getElementById("familyform").reset();
      // console.log("family", data);
      // document.getElementById("add-rowFamily").style.display = "none";
      // this.setState({ openfam: false , showing2: true, clickCount: 0, });
    }
    this.setState({ isButtonDisabled: false });
    this.FamilyDetailList();
  };

  EmergencySubmit = async (e) => {
    e.preventDefault();
    if (this.DepentsformValidation()) {
      const data = new FormData(e.target);

      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      await fetch(`${BASE_URLs}Family/AddUserEmergency`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Error") {
            alert(resp.message);
          } else {
            alert(resp.message);
          }
        });
      document.getElementById("Emergencyform").reset();
      document.getElementById("add-rowEmergency").style.display = "none";
    }
    this.EmergencyDetailList();
  };
  handleprofilechange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let formErrors = {};
    let formIsValid = true;

    var FirstNameinfo = document.getElementById("FirstNameinfo").value;
    var LastNameinfo = document.getElementById("LastNameinfo").value;
    var Genderinfo = document.getElementById("Genderinfo").value;
    var MaritalStatusinfo = document.getElementById("MaritalStatusinfo").value;
    var DOBinfo = document.getElementById("DOBinfo").value;
    var Bloodgroupinfo = document.getElementById("Bloodgroupinfo").value;
    var PersonalEmail = document.getElementById("PersonalEmail").value;
    var myDate = new Date(DOBinfo);

    var today = new Date();
    if (!FirstNameinfo) {
      formIsValid = false;
      formErrors["FirstNameinfo"] = "First Name  is required";
    } else if (!LastNameinfo) {
      formIsValid = false;
      formErrors["LastNameinfo"] = "Last Name is required";
    } else if (!Genderinfo) {
      formIsValid = false;
      formErrors["Genderinfo"] = "Gender is required";
    } else if (
      !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(PersonalEmail)
    ) {
      formIsValid = false;
      // formErrors["PersonalEmailerr"] = "Enter valid Email!.";
    } else if (!MaritalStatusinfo) {
      formIsValid = false;
      formErrors["MaritalStatusinfo"] = "Marital status is required";
    } else if (!DOBinfo) {
      formIsValid = false;
      formErrors["DOBinfo"] = "DOB is required";
    } else if (myDate > today) {
      formIsValid = false;
      formErrors["DOBinfo"] = "Valid DOB is required";
    }
    // else if (!Bloodgroupinfo) {
    //   formIsValid = false;
    //   formErrors["Bloodgroupinfo"] = "Blood Group is required";
    // }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  };
  ProfileValidation() {
    let formErrors = {};
    let formIsValid = true;

    var FirstNameinfo = document.getElementById("FirstNameinfo").value;
    var LastNameinfo = document.getElementById("LastNameinfo").value;
    var Genderinfo = document.getElementById("Genderinfo").value;
    var MaritalStatusinfo = document.getElementById("MaritalStatusinfo").value;
    var DOBinfo = document.getElementById("DOBinfo").value;
    var Bloodgroupinfo = document.getElementById("Bloodgroupinfo").value;
    var PersonalEmail = document.getElementById("PersonalEmail").value;
    var myDate = new Date(DOBinfo);

    var today = new Date();
    if (!FirstNameinfo) {
      formIsValid = false;
      formErrors["FirstNameinfo"] = "First Name  is required";
    }

    if (!LastNameinfo) {
      formIsValid = false;
      formErrors["LastNameinfo"] = "Last Name is required";
    }

    if (!Genderinfo) {
      formIsValid = false;
      formErrors["Genderinfo"] = "Gender is required";
    }
    //  else if (
    //   !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(PersonalEmail)
    // ) {
    //   formIsValid = false;
    //   formErrors["PersonalEmailerr"] = "Enter valid Email!.";
    // }
    if (!MaritalStatusinfo) {
      formIsValid = false;
      formErrors["MaritalStatusinfo"] = "Marital status is required";
    }
    if (!DOBinfo) {
      formIsValid = false;
      formErrors["DOBinfo"] = "DOB is required";
    }
    if (DOBinfo === "dd-mm-yyyy") {
      formIsValid = false;
      formErrors["DOBinfo"] = "DOB is required";
    }
    if (myDate > today) {
      formIsValid = false;
      formErrors["DOBinfo"] = "Valid DOB is required";
    }
    // if (!Bloodgroupinfo) {
    //   formIsValid = false;
    //   formErrors["Bloodgroupinfo"] = "Blood Group is required";
    // }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  EducationValidation() {
    let formErrors = {};
    let formIsValid = true;
    var Qualification = document.getElementById("Qualification").value;
    var St_date = document.getElementById("St_date").value;
    var Coursename = document.getElementById("Coursename").value;
    var End_date = document.getElementById("End_date").value;
    var Coursetype = document.getElementById("Coursetype").value;
    var clg_name = document.getElementById("clg_name").value;
    var stream = document.getElementById("stream").value;
    var unisty_name = document.getElementById("unisty_name").value;

    if (!Qualification) {
      formIsValid = false;
      formErrors["Qualification"] = "Qualification  is required";
    }

    if (!St_date) {
      formIsValid = false;
      formErrors["St_date"] = "Start From is required";
    }

    if (!Coursename) {
      formIsValid = false;
      formErrors["Coursename"] = "Coursename is required";
    }
    if (!End_date) {
      formIsValid = false;
      formErrors["End_date"] = "End is required";
    }
    if (St_date != null && End_date != null) {
      if (St_date === End_date) {
        formIsValid = false;
        formErrors["End_date"] = "Start From and  End From  is Same";
      }
      if (new Date(St_date).getTime() > new Date(End_date).getTime()) {
        formIsValid = false;

        formErrors["End_date"] = "Start From is less then  End From";
      }
    }

    if (!Coursetype) {
      formIsValid = false;
      formErrors["Coursetype"] = "Course Type is required";
    }
    if (!clg_name) {
      formIsValid = false;
      formErrors["clg_name"] = "College Name  is required";
    }
    if (!stream) {
      formIsValid = false;
      formErrors["stream"] = "Specialization is required";
    } else if (stream.length < 3) {
      formIsValid = false;
      formErrors["stream"] = "A Minimum of 3 characters are required";
    }
    if (!unisty_name) {
      formIsValid = false;
      formErrors["unisty_name"] = "University Name is required";
    } else if (unisty_name.length < 3) {
      formIsValid = false;
      formErrors["unisty_name"] = "A Minimum of 3 characters are required";
    } else if (unisty_name.length > 8) {
      formIsValid = false;
      formErrors["unisty_name"] = "A Maximum of 8 characters are required";
    }
    if (clg_name) {
      if (clg_name.length <= 2) {
        formIsValid = false;
        formErrors["clg_name"] = "A Minimum of 3 characters are required";
      }
    }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  handleAddresschange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let formErrors = {};
    let formIsValid = true;
    this.handlecheckbox();

    var CurrentAddress = document.getElementById("CurrentAddress").value;
    var CurrentAddress_Country = document.getElementById(
      "CurrentAddress_Country"
    ).value;
    var CurrentAddress_State = document.getElementById(
      "CurrentAddress_State"
    ).value;
    var CurrentAddress_City = document.getElementById(
      "CurrentAddress_City"
    ).value;
    var CurrentAddress_Pincode = document.getElementById(
      "CurrentAddress_Pincode"
    ).value;
    var PermanentAddress = document.getElementById("PermanentAddress").value;
    var PermanentAddress_Country = document.getElementById(
      "PermanentAddress_Country"
    ).value;
    var PermanentAddress_State = document.getElementById(
      "PermanentAddress_State"
    ).value;
    var PermanentAddress_City = document.getElementById(
      "PermanentAddress_City"
    ).value;
    var PermanentAddress_Pincode = document.getElementById(
      "PermanentAddress_Pincode"
    ).value;
    var OthersHouseType = document.getElementById("HouseText").value;
    var HouseType = document.getElementById("HouseType").value;
    var StayingSince = document.getElementById("StayingSince").value;
    //var CurrentCitySince = document.getElementById("CurrentCitySince").value
    var myDate = new Date(StayingSince);

    var today = new Date();
    const pincodeRegex = /^[1-9][0-9]{5}$/;
    if (CurrentAddress_Country == "India") {
      // alert(country);

      this.setState({
        isSelected: true,
      });
    } else if (CurrentAddress_Country != "India") {
      this.setState({
        isSelected: false,
      });
    }

    if (CurrentAddress === " " || !CurrentAddress || CurrentAddress === "-") {
      formIsValid = false;
      formErrors["CurrentAddress"] = "Current Address is required";
    } else if (CurrentAddress.length < 3) {
      formIsValid = false;
      formErrors["CurrentAddress"] = "A Minimum of 3 characters are required";
    } else if (
      CurrentAddress_City === "" ||
      !CurrentAddress_City ||
      CurrentAddress_City === "-"
    ) {
      formIsValid = false;
      formErrors["CurrentAddress_City"] = "City is required";
    } else if (CurrentAddress_City.length < 3) {
      formIsValid = false;
      formErrors["CurrentAddress_City"] = "A Minimum of 3 characters are required";
    } else if (
      !CurrentAddress_Country === "" ||
      !CurrentAddress_Country ||
      CurrentAddress_Country === "-"
    ) {
      formIsValid = false;
      formErrors["CurrentAddress_Country"] = "Country is required";
    } else if (
      CurrentAddress_State === "" ||
      !CurrentAddress_State ||
      CurrentAddress_State === "-"
    ) {
      formIsValid = false;
      formErrors["CurrentAddress_State"] = "State is required";
    } else if (
      CurrentAddress_Pincode === "" ||
      !CurrentAddress_Pincode ||
      CurrentAddress_Pincode === "-"
    ) {
      formIsValid = false;
      formErrors["CurrentAddress_Pincode"] = "Pincode is required";
    } else if (CurrentAddress_Pincode.length < 6) {
      formIsValid = false;
      formErrors["CurrentAddress_Pincode"] = "Maximum 6 Charatcers Required";
    } else if (!HouseType) {
      formIsValid = false;
      formErrors["HouseType"] = "House Type is required";
    } 
    else  if ( HouseType === 'others' && !OthersHouseType) {
      formIsValid = false;
      formErrors["HouseType"] = "House Type is required";
    }
   else if ( OthersHouseType.length < 3) {
      formIsValid = false;
      formErrors["HouseType"] = "A Minimum of 3 characters are required";
    } else if (!StayingSince) {
      formIsValid = false;
      formErrors["StayingSince"] = "Staying Since is required";
    } else if (myDate > today) {
      formIsValid = false;
      formErrors["StayingSince"] = "Valid Staying Since is required";
    }
    // if (!CurrentCitySince) {
    //   formIsValid = false;
    //   formErrors["CurrentCitySince"] = "Current City Since is required";
    // }
    if (PermanentAddress != '-') {
      if (PermanentAddress.length < 3) {
        formIsValid = false;
        formErrors["PermanentAddress"] = "A Minimum of 3 characters are required";
      }
    }

    if (
      PermanentAddress_City != '-'
    ) {
      if (PermanentAddress_City.length < 3) {
        formIsValid = false;
        formErrors["PermanentAddress_City"] =
          "A Minimum of 3 characters are required";
      }
    }

    if (PermanentAddress_Country === '-' || PermanentAddress_Country === "Select") {
      if (!PermanentAddress_Country) {
        formIsValid = false;
        formErrors["PermanentAddress_Country"] = "Country is required";
      }
    }

    if (PermanentAddress_State === 'Select state' || PermanentAddress_State === 'State') {
      if (PermanentAddress_State.length < 3) {
        formIsValid = false;
        formErrors["PermanentAddress_State"] = "A Minimum of 3 characters are required";
      }
    }


    if (PermanentAddress_Pincode != '-') {
      if (PermanentAddress_Pincode !== "-" && !pincodeRegex.test(PermanentAddress_Pincode)) {
        formIsValid = false;
        formErrors["PermanentAddress_Pincode"] = "Invalid Pincode";
      } else if (PermanentAddress_Pincode.length < 6) {
        formIsValid = false;
        formErrors["PermanentAddress_Pincode"] = "Minimum 6 Characters Required";
      }
    }




    this.setState({ formErrors: formErrors });

    return formIsValid;
  };

  GetUserSkillName() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}UserSkillDetails/GetUserSkillName`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ SKillAdminNameDrop: data });
        // console.log(data, "SKillName");
      });
  }
  AddressValidation() {
    let formErrors = {};
    let formIsValid = true;

    var CurrentAddress = document.getElementById("CurrentAddress").value;
    var CurrentAddress_Country = document.getElementById(
      "CurrentAddress_Country"
    ).value;
    var CurrentAddress_State = document.getElementById(
      "CurrentAddress_State"
    ).value;
    var CurrentAddress_City = document.getElementById(
      "CurrentAddress_City"
    ).value;
    var CurrentAddress_Pincode = document.getElementById(
      "CurrentAddress_Pincode"
    ).value;
    var PermanentAddress = document.getElementById("PermanentAddress").value;
    var PermanentAddress_Country = document.getElementById(
      "PermanentAddress_Country"
    ).value;
    var PermanentAddress_State = document.getElementById(
      "PermanentAddress_State"
    ).value;
    var PermanentAddress_City = document.getElementById(
      "PermanentAddress_City"
    ).value;
    var PermanentAddress_Pincode = document.getElementById(
      "PermanentAddress_Pincode"
    ).value;
    var HouseType = document.getElementById("HouseType").value;
    var OthersHouseType = document.getElementById("HouseText").value;
    var StayingSince = document.getElementById("StayingSince").value;
    //var CurrentCitySince = document.getElementById("CurrentCitySince").value
    var myDate = new Date(StayingSince);

    var today = new Date();
    const pincodeRegex = /^[1-9][0-9]{5}$/;
    if (CurrentAddress === " " || !CurrentAddress || CurrentAddress === "-") {
      formIsValid = false;
      formErrors["CurrentAddress"] = "Current Address is required";
    } else if (CurrentAddress.length < 3) {
      formIsValid = false;
      formErrors["CurrentAddress"] = "A Minimum of 3 characters are required";
    }
    if (
      CurrentAddress_City === "" ||
      !CurrentAddress_City ||
      CurrentAddress_City === "-"
    ) {
      formIsValid = false;
      formErrors["CurrentAddress_City"] = "City is required";
    } else if (CurrentAddress_City.length < 3) {
      formIsValid = false;
      formErrors["CurrentAddress_City"] =
        "A Minimum of 3 characters are required";
    }
    if (
      CurrentAddress_Country === "" ||
      !CurrentAddress_Country ||
      CurrentAddress_Country === "-"
    ) {
      formIsValid = false;
      formErrors["CurrentAddress_Country"] = "Country is required";
    }
    if (
      CurrentAddress_State === "" ||
      !CurrentAddress_State ||
      CurrentAddress_State === "-"
    ) {
      formIsValid = false;
      formErrors["CurrentAddress_State"] = "State is required";
    }

    if (
      CurrentAddress_Pincode === "" ||
      !CurrentAddress_Pincode ||
      CurrentAddress_Pincode === "-"
    ) {
      formIsValid = false;
      formErrors["CurrentAddress_Pincode"] = "Pincode is required";
    } else if (CurrentAddress_Pincode.length < 6) {
      formIsValid = false;
      formErrors["CurrentAddress_Pincode"] = "A Maximum of 6 characters are required";
    }
    if (!HouseType) {
      formIsValid = false;
      formErrors["HouseType"] = "House Type is required";
    }

    
    if ( HouseType === 'others' && !OthersHouseType) {
      formIsValid = false;
      formErrors["HouseType"] = "House Type is required";
    }
    if ( OthersHouseType !== "" && OthersHouseType.length < 3) {
      formIsValid = false;
      formErrors["HouseType"] = "A Minimum of 3 characters are required";
    }
    if (!StayingSince) {
      formIsValid = false;
      formErrors["StayingSince"] = "Staying Since is required";
    }
    if (StayingSince === "dd-mm-yyyy") {
      formIsValid = false;

      formErrors["StayingSince"] = "Staying Since is required";
    } else if (myDate > today) {
      formIsValid = false;
      formErrors["StayingSince"] = "Valid Staying Since is required";
    }

    if (PermanentAddress != "-") {
      if (!PermanentAddress) {
        formIsValid = false;
        formErrors["PermanentAddress"] = "Address is required";
      }
      else if (PermanentAddress.length < 3) {
        formIsValid = false;
        formErrors["PermanentAddress"] = "A Minimum of 3 characters are required";
      }
    }

    if (
      PermanentAddress_City != '-'
    ) {
      if (!PermanentAddress_City) {
        formIsValid = false;
        formErrors["PermanentAddress_City"] = "City is required";
      }
      else if (PermanentAddress_City.length < 3) {
        formIsValid = false;
        formErrors["PermanentAddress_City"] =
          "A Minimum of 3 characters are required";
      }
    }

    if (PermanentAddress_Country === '-' || PermanentAddress_Country === "Select") {
      if (!PermanentAddress_Country) {
        formIsValid = false;
        formErrors["PermanentAddress_Country"] = "Country is required";
      }
    }

    if (PermanentAddress_State === 'Select state' || PermanentAddress_State === 'State') {
      if (!PermanentAddress_State) {
        formIsValid = false;
        formErrors["PermanentAddress_State"] = "State is required";
      }
      else if (PermanentAddress_State.length < 3) {
        formIsValid = false;
        formErrors["PermanentAddress_State"] = "A Minimum of 3 characters are required";
      }
    }


    if (PermanentAddress_Pincode != '-') {
      if (!PermanentAddress_Pincode) {
        formIsValid = false;
        formErrors["PermanentAddress_Pincode"] = "Pincode is required";
      }
      else if (PermanentAddress_Pincode !== "-" && !pincodeRegex.test(PermanentAddress_Pincode)) {
        formIsValid = false;
        formErrors["PermanentAddress_Pincode"] = "Invalid Pincode";
      } else if (PermanentAddress_Pincode.length < 6) {
        formIsValid = false;
        formErrors["PermanentAddress_Pincode"] = "Minimum 6 Characters Required";
      }
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  handlebankchange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let formErrors = {};
    let formIsValid = true;
    //var Bankname = document.getElementById("Bankname").value
    var Branchname = document.getElementById("Branchname").value;
    var Branchaddress = document.getElementById("Branchaddress").value;
    var IFSC = document.getElementById("IFSC").value;
    var confirmAcNo = document.getElementById("confirmAcNo").value;
    var AcNo = document.getElementById("AcNo").value;
    var AcType = document.getElementById("AcType").value;
    var PhoneNo = document.getElementById("PhoneNo").value;
    var IFSCpattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
    if (!AcNo || AcNo === "-") {
      formIsValid = false;
      formErrors["AcNo"] = "Account Number is required";
    }
    else if (AcNo.length < 10 || !/^\d+$/.test(AcNo)) {
      formIsValid = false;
      formErrors["AcNo"] = "Invalid Account Number";
    }
    else if (!confirmAcNo || confirmAcNo === "-") {
      formIsValid = false;
      formErrors["AcName"] = "confirm Account Number is required";
    } else if (AcNo !== confirmAcNo) {
      formIsValid = false;
      formErrors["AcName"] = "Account Number and  confirm Account Number is not same";
    } else if (!IFSC || IFSC === "-") {
      formIsValid = false;
      formErrors["IFSC"] = "IFSC Code is required";
    } else if (!IFSCpattern.test(IFSC)) {
      formIsValid = false;
      formErrors["IFSC"] = "IFSC Code is Invalid";
    } else if (!AcType || AcType === "-") {
      formIsValid = false;
      formErrors["AcType"] = "Account Type is required";
    } else if (!Branchname || Branchname === "-") {
      formIsValid = false;
      formErrors["Branchname"] = "Branch Name is required";
    } else if (Branchname.length < 3) {
      formIsValid = false;
      formErrors["Branchname"] = "Minimum 3 Characters Required";
    }

    // else if (!PhoneNo || PhoneNo === '-') {
    //   formIsValid = false;
    //   // formErrors["PhoneNo"] = "Phone Number is required";
    // }
    else if (!mobPattern.test(PhoneNo)) {
      formIsValid = false;
      // formErrors["PhoneNo"] = " Enter the valid Phone Number.";
    }

    // else if (!Branchaddress || Branchaddress === '-') {
    //   formIsValid = false;
    //   // formErrors["Branchaddress"] = "Branch Address is required";
    // }

    // else if (Branchaddress.length < 3) {
    //   formIsValid = false;
    //   // formErrors["Branchaddress"] = "Minimum 3 Characters Required";
    // }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  };
  BankValidation() {
    let formErrors = {};
    let formIsValid = true;
    //var Bankname = document.getElementById("Bankname").value
    var Branchname = document.getElementById("Branchname").value;
    var Branchaddress = document.getElementById("Branchaddress").value;
    var IFSC = document.getElementById("IFSC").value;
    var confirmAcNo = document.getElementById("confirmAcNo").value;
    var AcNo = document.getElementById("AcNo").value;
    var AcType = document.getElementById("AcType").value;
    var PhoneNo = document.getElementById("PhoneNo").value;
    var IFSCpattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
    // if (!Bankname) {
    //   formIsValid = false;
    //   formErrors["Bankname"] = "Bank Name  is required";
    // }
    const re = /^[0-9\b]+$/;
    if (!re.test(AcNo) || AcNo === "-") {
      formIsValid = false;
      formErrors["AcNo"] = "Account Number  is required";
    } else if (AcNo.length < 9) {
      formIsValid = false;
      formErrors["AcNo"] = "Invalid Account Number ";
    }
    if (!confirmAcNo || confirmAcNo === "-") {
      formIsValid = false;
      formErrors["AcName"] = "confirm Account Number is required";
    } else if (AcNo !== confirmAcNo) {
      formIsValid = false;
      formErrors["AcName"] =
        "Account Number and  confirm Account Number is not same";
    }
    if (!IFSC || IFSC === "-") {
      formIsValid = false;
      formErrors["IFSC"] = "IFSC Code is required";
    } else if (!IFSCpattern.test(IFSC)) {
      formIsValid = false;
      formErrors["IFSC"] = "IFSC Code is Invalid";
    }
    if (!AcType || AcType === "-") {
      formIsValid = false;
      formErrors["AcType"] = "Account Type is required";
    }

    if (!Branchname || Branchname === "-") {
      formIsValid = false;
      formErrors["Branchname"] = "Branch Name is required";
    } else if (Branchname.length < 3) {
      formIsValid = false;
      formErrors["Branchname"] = "Minimum 3 Characters Required";
    }

    // if (!PhoneNo || PhoneNo === '-') {
    //   formIsValid = false;
    //   formErrors["PhoneNo"] = "Phone Number is required";
    // }

    // else if (!mobPattern.test(PhoneNo)) {

    //   formIsValid = false;
    //   formErrors["PhoneNo"] = " Enter the valid Phone Number.";
    // }

    // if (!Branchaddress || Branchaddress === '-') {
    //   formIsValid = false;
    //   formErrors["Branchaddress"] = "Branch Address is required";
    // }
    // else if (Branchaddress.length < 3) {
    //   formIsValid = false;
    //   formErrors["Branchaddress"] = "Minimum 3 Characters Required";
    // }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  submit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
  
    if (this.ProfileValidation()) {
      this.setState({
        ButtonDisabled: true,
      });
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };

      await fetch(`${BASE_URLs}Profile/Put`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            this.profilelist();

            document.getElementById("edit-btn").click();
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({
              ButtonDisabled: false,
            });
            // setTimeout(() => {
            //   window.location.reload(true);
            // }, 3200);
            // document.getElementById('edit-btn-contact').disabled = true
            // document.getElementById('edit-btn-Address').disabled = true
            // document.getElementById('edit-btn-media').disabled = true
            // document.getElementById('edit-btn').disabled = true
          }

          if (resp.status === "Error") {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          //resp.errors.MiddleName===null?document.getElementById('middlename').innerHTML="MiddleName":document.getElementById('middlename').innerHTML=resp.errors.MiddleName,document.getElementById('middlename').style.color="red"

          this.setState({ imagePreviewUrl: null });
          this.setState({ imageCoverPreviewUrl: null });
        });
    }
  };

  ContactEdit = async (e) => {
    e.preventDefault();
  
    if (this.ContactValidation()) {
      const data = new FormData(e.target);
      this.setState({
        ButtonDisabled: true,
      });
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // 'Accept':'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          // 'Content-Type':'application/json'
        },
        body: data,
      };

      await fetch(`${BASE_URLs}Profile/ContactEDit`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            this.profilelist();
            document.getElementById("edit-btn-contact").click();

            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({
              ButtonDisabled: true,
            });
            // setTimeout(() => {
            //   window.location.reload(true);
            // }, 3200);
            // document.getElementById('edit-btn-contact').disabled = true
            // document.getElementById('edit-btn-Address').disabled = true
            // document.getElementById('edit-btn-media').disabled = true
            // document.getElementById('edit-btn').disabled = true
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });

      this.setState({ imagePreviewUrl: null });
      this.setState({ imageCoverPreviewUrl: null });
    }
  };

  AddressEdit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    
    if (this.AddressValidation()) {
      this.setState({
        ButtonDisabled: true,
      });
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // 'Accept':'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          // 'Content-Type':'application/json'
        },
        body: data,
      };

      await fetch(`${BASE_URLs}Profile/AddressEdit`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            this.profilelist();
            document.getElementById("edit-btn-Address").click();

            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({
              ButtonDisabled: true,
            });
            // setTimeout(() => {
            //   window.location.reload(true);
            // }, 3200);
            // document.getElementById('edit-btn-contact').disabled = true
            // document.getElementById('edit-btn-Address').disabled = true
            // document.getElementById('edit-btn-media').disabled = true
            // document.getElementById('edit-btn').disabled = true
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });

      this.setState({ imagePreviewUrl: null });
      this.setState({ imageCoverPreviewUrl: null });
    }
  };

  SocailValidation() {
    let formErrors = {};
    let formIsValid = true;
    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
    var facebkPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    var Whatsapp = document.getElementById("Whatsapp").value;
    var facebook = document.getElementById("facebk").value;
    var LinkedIn = document.getElementById("linkedIn").value;
    var Skype = document.getElementById("skype").value;
    var Twitter = document.getElementById("twitter").value;


    if (Whatsapp != '-') {
      if (!mobPattern.test(Whatsapp)) {
        formIsValid = false;
        formErrors["WhatsappErr"] = " Enter the valid Whatsapp Number";
      }
    }

    // if (facebook != '-') {
    //   if (!facebkPattern.test(facebook) && !mobPattern.test(facebook)) {
    //     formIsValid = false;
    //     formErrors["FacebookErr"] = "Enter valid Facebook account ID";
    //   }
    // }
    // if (LinkedIn != '-') {
    //   if (!facebkPattern.test(LinkedIn)) {
    //     formIsValid = false;
    //     formErrors["linkedInErr"] = " Enter the valid Account";
    //   }
    // }
    // if (Skype != '-') {
    //   if (!facebkPattern.test(Skype)) {
    //     formIsValid = false;
    //     formErrors["SkypeErr"] = " Enter the valid Account";
    //   }
    // }
    // if (Twitter != '-') {
    //   if (!facebkPattern.test(Twitter)) {
    //     formIsValid = false;
    //     formErrors["TwitterErr"] = " Enter the valid Account";
    //   }
    // }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  handleSocailchange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    let formErrors = {};
    let formIsValid = true;
    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
    var facebkPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    var Whatsapp = document.getElementById("Whatsapp").value;
    var facebook = document.getElementById("facebk").value;
    var LinkedIn = document.getElementById("linkedIn").value;
    var Twitter = document.getElementById("twitter").value;
    var Skype = document.getElementById("skype").value;


    if (Whatsapp != '-') {
      if (!mobPattern.test(Whatsapp)) {
        formIsValid = false;
        formErrors["WhatsappErr"] = " Enter the valid Whatsapp Number";
      }
    }

    // if(facebook!='-'){
    //   if (!facebkPattern.test(facebook)&& !mobPattern.test(facebook)) {
    //     formIsValid = false;
    //     formErrors["FacebookErr"] = "Enter valid Facebook account ID";
    //   }
    // }

    // if(LinkedIn!='-'){
    //   if (!facebkPattern.test(LinkedIn)) {
    //     formIsValid = false;
    //     formErrors["linkedInErr"] = " Enter the valid Account";
    //   }
    // }
    // if(Twitter!='-'){
    //   if (!facebkPattern.test(Twitter)) {
    //     formIsValid = false;
    //     formErrors["TwitterErr"] = " Enter the valid Account";
    //   }
    // }
    // if(Skype!='-'){
    //   if (!facebkPattern.test(Skype)) {
    //     formIsValid = false;
    //     formErrors["SkypeErr"] = " Enter the valid Account";
    //   }
    // }



    this.setState({ formErrors: formErrors });

    return formIsValid;
  };
  SocialEdit = async (e) => {
    e.preventDefault();
    if (this.SocailValidation()) {
      const data = new FormData(e.target);
      this.setState({
        ButtonDisabled: true,
      });
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // 'Accept':'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          // 'Content-Type':'application/json'
        },
        body: data,
      };

      await fetch(`${BASE_URLs}Profile/SocialEdit`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            this.profilelist();

            document.getElementById("edit-btn-media").click();

            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({
              ButtonDisabled: true,
            });
            // setTimeout(() => {
            //   window.location.reload(true);
            // }, 3200);
            // document.getElementById('edit-btn-contact').disabled = true
            // document.getElementById('edit-btn-Address').disabled = true
            // document.getElementById('edit-btn-media').disabled = true
            // document.getElementById('edit-btn').disabled = true
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };

  handleFileSelected(event) {
    event.preventDefault();
    this.photofilename = event.target.files[0].name;
    const formData = new FormData();
    formData.append(
      "myFile",
      event.target.files[0],
      event.target.files[0].name
    );

    fetch("", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.imagesrc = process.env.REACT_APP_PHOTOPATH + result;
        },
        (error) => {
          alert("Failed");
        }
      );
    setTimeout(this.profilelist(), 400);
  }

  componentDidMount() {
    this.profilelist();
    this.EducationDetailList();
    this.BankdetailList();
    this.GetUserProfile();
    this.SkillDetailList();
    this.FamilyDetailList();
    this.EmergencyDetailList();
    this.RelationshipDropList();
    this.HouseTypeDropdownList();
    this.UserEducationDropdownList();
    this.GetUserSkillDrop();
    this.GetOrgLogo();
    this.UserEducationDropdownList();
    this.GetUserSkillName();

    const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
    this.setState({ isChrome });
  }
  GetUserProfile() {
    let url = `${BASE_URLs}Profile/Userprofile`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Profile_Pic: data,
        });

        //
      });
  }

  handleImageChange(e) {
    e.preventDefault();
    this.setState({ imagePreviewUrl: URL.createObjectURL(e.target.files[0]) });
  }

  handleCoverImageChange(e) {
    e.preventDefault();
    this.setState({
      imageCoverPreviewUrl: URL.createObjectURL(e.target.files[0]),
    });
  }
  FamModel = (e, fn, mn, ln, cn, em, relationship, emergencyContact, cdate) => {

    this.setState({ FamilyEditId: cdate });
    this.setState({
      EditFamValues: {
        id: e,
        FirstName: fn,
        MiddleName: mn,
        LastName: ln,
        Contact: cn,
        Email: em,
        Relationship: relationship,
        emergencyContact: emergencyContact,
        created_Date: cdate
      },
    });
    this.setState((prevState) => ({
      FamModel: !prevState.FamModel,
    }));

    this.FamilyDetailList();
  };

  EmergencyModel = (e, fn, ln, cn, em, relationship) => {
    this.setState({ EmergencyEditId: e });
    this.setState({
      EditECValues: {
        id: e,
        FirstName: fn,
        LastName: ln,
        Contact: cn,
        Email: em,
        Relationship: relationship,
      },
    });

    this.setState((prevState) => ({
      EmergencyModel: !prevState.EmergencyModel,
    }));

    this.EmergencyDetailList();
  };

  SkillModel = (
    id,
    categ,
    Skillname,
    Skilldescrp,
    exp,
    trainer,
    coursename,
    startdate,
    enddate,
    created_Date
  ) => {
    // this.setState({SkillEditId: id});
    this.setState({
      EditSkillValues: {
        id: id,
        categ: categ,
        Skillname: Skillname,
        Skilldescrp: Skilldescrp,
        exp: exp,
        trainer: trainer,
        coursename: coursename,
        startdate: startdate,
        enddate: enddate,
        created_Date: created_Date
      },
    });
    this.setState((prevState) => ({
      SkillModel: !prevState.SkillModel,
    }));
    this.SkillDetailList();
  };

  HouseTypeDropdownList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}profile/HouseTypeDropdown`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ HouseTypeDrop: data });
      });
  }
  UserEducationDropdownList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}UserEducationDetails/GetQualificationtype`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ EducationDrop: data });
      });
  }

  UserEduModel = (
    id,
    QualificationType,
    Coursename,
    Coursetype,
    Stream,
    CourseStartDate,
    CourseEndDate,
    UniversityName,
    CollegeName,
    Created_Date
  ) => {
    this.setState({
      EditSkillValues: {
        id: id,
        QualificationType: QualificationType,
        Coursename: Coursename,
        Coursetype: Coursetype,
        Stream: Stream,
        CourseStartDate: CourseStartDate,
        CourseEndDate: CourseEndDate,
        UniversityName: UniversityName,
        CollegeName: CollegeName,
        Created_Date: Created_Date
      },
    });
    this.setState((prevState) => ({
      UserEduModel: !prevState.UserEduModel,
    }));
    this.EducationDetailList();
  };

  HandleSkillCertificate = (e) => {
    e.preventDefault();
    var uploadcertificate =
      document.getElementById("UploadCertificate").checked;
    if (uploadcertificate === true) {
      this.setState({
        UploadCertificate: true,
      });
    } else {
      this.setState({
        UploadCertificate: false,
      });
    }
  };

  HandleHouse() {
    var HouseType = document.getElementById("HouseType").value;
    if (HouseType === "others") {
      document.getElementById("HouseText").value = "";
      document.getElementById("HouseText").type = "text";
    } else {
      document.getElementById("HouseText").value = "None";
      document.getElementById("HouseText").type = "hidden";
    }
  }

  reformatDate = (dateString) => {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    } else {
      return dateString;
    }
  };

  // formatDate = (inputDate) => {
  //   const date = new Date(inputDate);
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const year = date.getFullYear();
  //   if (day === 'NaN') {
  //     return `dd-mm-yyyy`;
  //   }
  //   else {
  //     return `${day}-${month}-${year}`;
  //   }
  // };



  DelUserSkill = (SName, UId) => {
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      const data = {
        SkillName: SName,
        UserId: UId
      };

      axios({
        method: "POST",
        url: `${BASE_URLs}UserSkillDetails/DelUserSkill`,
        data: data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "multipart/form-data",

          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      }).then((response) => {
        if (response.data.status === "Success") {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.SkillDetailList();
      });
    }
  };
  formatted(dateString) {
    const options = { year: "numeric", month: "short" };
    const date = new Date(`${dateString}-01`);
    return date.toLocaleDateString("en-US", options);
  }

  Getvalue() {
    var Pvalue = document.getElementById('PermanentAddress').value;
    var Cvalue = document.getElementById('CurrentAddress').value;
    var status = document.getElementById('SameAddress')
    if (Pvalue !== Cvalue || Pvalue === '-') {
      status.checked = false
    }
    else {
      status.checked = true

      this.setState({
        isReadOnly: true,
      });
    }
    var CurrentAddress_Country = document.getElementById("CurrentAddress_Country").value;
    if (CurrentAddress_Country == "India") {
      this.setState({
        isSelected: true,
      });
    } else if (CurrentAddress_Country != "India") {
      this.setState({
        isSelected: false,
      });
    }
  }

  handleInfoClick = () => {
    this.setState({
      clickCount: 0,

      openskill: false,
      openfam: false,
      openedu: false,
      showing: true,
      showing1: true,
      showing2: true,
    });
  };
  handleInfoClick1 = () => {
    this.setState({
      clickCount: 0,

      openskill: false,
      openfam: false,
      openedu: false,
      showing: true,
      showing1: true,
      showing2: true,

    });

  };
  handleInfoClick2 = () => {
    this.setState({
      clickCount: 0,

      openskill: false,
      openfam: false,
      openedu: false,
      showing: true,
      showing1: true,
      showing2: true,

    });

  };
  handleInfoClick3 = () => {
    this.setState({
      clickCount: 0,

      openskill: false,
      openfam: false,
      openedu: false,
      showing: true,
      showing1: true,
      showing2: true,

    });

  };
  handleInfoClick4 = () => {
    this.setState({
      clickCount: 0,

      openskill: false,
      openfam: false,
      openedu: false,
      showing: true,
      showing1: true,
      showing2: true,

    });

  };
  handleInfoClick5 = () => {
    this.setState({
      clickCount: 0,

      openskill: false,
      openfam: false,
      openedu: false,
      showing: true,
      showing1: true,
      showing2: true,

    });

  };

  handleIconClick = () => {

    this.fileInputRef.current.click();
  };
  render() {
    const {
      TwitterErr,
      SkypeErr,
      linkedInErr,
      contactphnerr,
      PersonalEmailerr,
      officialEmailerr,
      Qualification,
      St_date,
      Coursename,
      End_date,
      Coursetype,
      WhatsappErr,
      AlternatePhoneNoerr,
      HomeErr,
      FirstNameinfo,
      LastNameinfo,
      Genderinfo,
      MaritalStatusinfo,
      clg_name,
      stream,
      unisty_name,
      CurrentAddress,
      CurrentAddress_Country,
      CurrentAddress_State,
      CurrentAddress_City,
      CurrentAddress_Pincode,
      HouseType,
      StayingSince,
      PermanentAddress,
      PermanentAddress_Country,
      PermanentAddress_State,
      PermanentAddress_City,
      PermanentAddress_Pincode,
      CurrentCitySince,
      DOBinfo,
      Bloodgroupinfo,
      Profilepicinfo,
      SkillNameErr,
      SkillDescriptionErr,
      CategoryErr,
      ExperienceErr,
      FirstNameErr,
      LastNameErr,
      ContactErr,
      EmailErr,
      RelationshipErr,
      Bankname,
      Branchname,
      Branchaddress,
      IFSC,
      AcName,
      AcNo,
      AcType,
      PhoneNo,
      skillcoursenameErr,
      skillCoursestartdateErr,
      skillCourseEnddateErr,
      skillCertificateErr,
      FacebookErr
    } = this.state.formErrors;
    const { profile, isSelected } = this.state;
    const { EducationDetail } = this.state;
    const { SkillDetails } = this.state;
    const { FamilyDetails } = this.state;
    const { RelationshipDrop } = this.state;
    const { HouseTypeDrop } = this.state;
    const { EducationDrop } = this.state;
    const { openedu } = this.state;
    const { openfam } = this.state;
    const { openskill } = this.state;
    const { SKillAdminCategoryDrop, SKillAdminNameDrop } = this.state;
    const { EducationCourseName } = this.state;
    const { Bankdetail, UploadCertificate } = this.state;
    const { showing } = this.state;
    const { showing1 } = this.state;
    const { showing2 } = this.state;
    const { isChrome } = this.state;

    const now = 20;

    const now1 = 40;

    const now2 = 60;

    const now3 = 80;

    const now4 = 100;

    return (
      <div>
        <SideBar active={window.location.hash} />
        {this.state.FamModel ? (
          <EditFamilyModel
            id={this.state.FamilyEditId}
            value={this.state.EditFamValues}
            close={() => this.FamModel("")}
          />
        ) : null}
        {this.state.EmergencyModel ? (
          <EditEmergencyModel
            value={this.state.EditECValues}
            id={this.state.EmergencyEditId}
            close={() => this.EmergencyModel("")}
          />
        ) : null}
        {this.state.SkillModel ? (
          <EditSkillModel
            value={this.state.EditSkillValues}
            id={this.state.SkillEditId}
            close={() => this.SkillModel("")}
          />
        ) : null}
        {this.state.UserEduModel ? (
          <EditUserEduModel
            value={this.state.EditSkillValues}
            id={this.state.UserEduEditId}
            close={() => this.UserEduModel("")}
          />
        ) : null}
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body user-profile">
              <div className="page-wrapper">
                <div className="page-body">
                  <div className="j-tabs-container md-tabs">
                    <center>
                      {SkillDetails.length > 0 ? (
                        <div>
                          <h5 style={{ color: "green" }}>
                            <b>Your Profile Has Been Completed</b>
                          </h5>
                        </div>
                      ) : null}
                      <br />
                      <div id="ProgressBar">
                        {/* <div id="ProgressBar-Info"> </div>
                <div id="ProgressBar-Info-Done">
                  <i aria-hidden="true" style={{color:"#7666f0b8",marginTop:"5px"}}><b>1</b></i>
                  </div> */}
                        {/* <div id="ProgressBar-Info"> </div> */}
                        {profile.map((profile) => (
                          <>
                            {profile.percentageP != null &&
                              profile.percentageC == null &&
                              profile.percentageA == null ? (
                              <div title="Info">
                                <ProgressBar
                                  id="ProgressBar-Info"
                                  animated
                                  now={profile.percentageP}
                                />{" "}
                              </div>
                            ) : profile.percentageP == null &&
                              profile.percentageC != null &&
                              profile.percentageA == null ? (
                              <div title="Info">
                                <ProgressBar
                                  id="ProgressBar-Info"
                                  animated
                                  now={profile.percentageC}
                                />{" "}
                              </div>
                            ) : profile.percentageP == null &&
                              profile.percentageC == null &&
                              profile.percentageA != null ? (
                              <div title="Info">
                                <ProgressBar
                                  id="ProgressBar-Info"
                                  animated
                                  now={profile.percentageA}
                                />{" "}
                              </div>
                            ) : profile.percentageP == null &&
                              profile.percentageC == null &&
                              profile.percentageA == null ? (
                              <div title="Info">
                                <ProgressBar
                                  id="ProgressBar-Info"
                                  animated
                                  now={0}
                                />{" "}
                              </div>
                            ) : profile.percentageP != null &&
                              profile.percentageC != null &&
                              profile.percentageA != null ? (
                              <div title="Info">
                                <ProgressBar
                                  id="ProgressBar-Info"
                                  animated
                                  now={
                                    profile.percentageP +
                                    profile.percentageC +
                                    profile.percentageA
                                  }
                                  label={`${now}%`}
                                />{" "}
                              </div>
                            ) : profile.percentageP != null &&
                              profile.percentageC != null &&
                              profile.percentageA == null ? (
                              <div title="Info">
                                <ProgressBar
                                  id="ProgressBar-Info"
                                  animated
                                  now={
                                    profile.percentageP + profile.percentageC
                                  }
                                />{" "}
                              </div>
                            ) : profile.percentageP != null &&
                              profile.percentageC == null &&
                              profile.percentageA != null ? (
                              <div title="Info">
                                <ProgressBar
                                  id="ProgressBar-Info"
                                  animated
                                  now={
                                    profile.percentageP + profile.percentageA
                                  }
                                />{" "}
                              </div>
                            ) : profile.percentageP == null &&
                              profile.percentageC != null &&
                              profile.percentageA != null ? (
                              <div title="Info">
                                <ProgressBar
                                  id="ProgressBar-Info"
                                  animated
                                  now={
                                    profile.percentageA + profile.percentageC
                                  }
                                />{" "}
                              </div>
                            ) : profile.percentageP != null &&
                              profile.percentageC == null &&
                              profile.percentageA != null ? (
                              <div title="Info">
                                <ProgressBar
                                  id="ProgressBar-Info"
                                  animated
                                  now={
                                    profile.percentageA + profile.percentageP
                                  }
                                />{" "}
                              </div>
                            ) : null}

                            {profile.percentageP != null &&
                              profile.percentageC != null &&
                              profile.percentageA != null ? (
                              <div id="ProgressBar-Info-Done">
                                {" "}
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                  style={{
                                    color: "#7666f0b8",
                                    marginTop: "5px",
                                  }}
                                ></i>
                              </div>
                            ) : (
                              <div id="ProgressBar-Info-Done">
                                {" "}
                                <b
                                  style={{
                                    color: "#7666f0b8",
                                    marginTop: "5px",
                                  }}
                                >
                                  1
                                </b>
                              </div>
                            )}
                          </>
                        ))}

                        {/* <div id="ProgressBar-Family"> </div> */}
                        {Bankdetail.map((Bankdetail) => (
                          <>
                            <div title="Banking">
                              <ProgressBar
                                id="ProgressBar-Banking"
                                animated
                                now={Bankdetail.percentage}
                                label={
                                  Bankdetail.percentage == 100
                                    ? `${now1}%`
                                    : null
                                }
                              />{" "}
                            </div>
                            <div id="ProgressBar-Banking-Done">
                              {" "}
                              <b
                                style={{ color: "#7666f0b8", marginTop: "5px" }}
                              >
                                2
                              </b>
                            </div>
                            {Bankdetail.percentage == 100 ? (
                              <div id="ProgressBar-Banking-Done">
                                {" "}
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                  style={{
                                    color: "#7666f0b8",
                                    marginTop: "5px",
                                  }}
                                ></i>
                              </div>
                            ) : null}
                          </>
                        ))}
                        {/* <div id="ProgressBar-Family"> </div> */}
                        {FamilyDetails.length == 0 ? (
                          <>
                            <div>
                              <ProgressBar
                                id="ProgressBar-Family"
                                animated
                                now={0}
                              />{" "}
                            </div>
                            <div id="ProgressBar-Family-Done">
                              {" "}
                              <b
                                style={{ color: "#7666f0b8", marginTop: "5px" }}
                              >
                                3
                              </b>
                            </div>
                          </>
                        ) : (
                          <>
                            <div title="Contacts">
                              <ProgressBar
                                id="ProgressBar-Family"
                                animated
                                now={100}
                                label={`${now2}%`}
                              />{" "}
                            </div>
                            <div id="ProgressBar-Family-Done">
                              {" "}
                              <i
                                className="fa fa-check"
                                aria-hidden="true"
                                style={{ color: "#7666f0b8", marginTop: "5px" }}
                              ></i>
                            </div>
                          </>
                        )}
                        {/* <div id="ProgressBar-Family-Done"></div> */}
                        {EducationDetail.length == 0 ? (
                          <>
                            <div>
                              <ProgressBar
                                id="ProgressBar-Education"
                                animated
                                now={0}
                              />{" "}
                            </div>
                            <div id="ProgressBar-Education-Done">
                              {" "}
                              <b
                                aria-hidden="true"
                                style={{ color: "#7666f0b8", marginTop: "5px" }}
                              >
                                4
                              </b>
                            </div>
                          </>
                        ) : (
                          <>
                            <div title="Education">
                              <ProgressBar
                                id="ProgressBar-Education"
                                animated
                                now={100}
                                label={`${now3}%`}
                              />{" "}
                            </div>
                            <div id="ProgressBar-Education-Done">
                              {" "}
                              <i
                                className="fa fa-check"
                                aria-hidden="true"
                                style={{ color: "#7666f0b8", marginTop: "5px" }}
                              ></i>
                            </div>
                          </>
                        )}
                        {SkillDetails.length == 0 ? (
                          <>
                            <div>
                              <ProgressBar
                                id="ProgressBar-Skills"
                                animated
                                now={0}
                              />{" "}
                            </div>
                            <div id="ProgressBar-Skills-Done">
                              {" "}
                              <b
                                aria-hidden="true"
                                style={{ color: "#7666f0b8", marginTop: "5px" }}
                              >
                                5
                              </b>
                            </div>
                          </>
                        ) : (
                          <>
                            <div title="Skills">
                              <ProgressBar
                                id="ProgressBar-Skills"
                                animated
                                now={100}
                                label={`${now4}%`}
                              />{" "}
                            </div>
                            <div id="ProgressBar-Skills-Done">
                              {" "}
                              <i
                                className="fa fa-check"
                                aria-hidden="true"
                                style={{ color: "#7666f0b8", marginTop: "5px" }}
                              ></i>
                            </div>
                          </>
                        )}
                        {/* <div id="ProgressBar-Skills"> </div> */}
                        {/* <div id="ProgressBar-Skills-Done"></div> */}
                      </div>
                    </center>
                    <br />
                    <br />
                    <input id="tab1" type="radio" name="tabs" defaultChecked />
                    <label
                      id="supertab"
                      className="j-tabs-label"
                      htmlFor="tab1"
                      title="Info"
                      onClick={this.handleInfoClick}
                    >
                      <span>&nbsp;&nbsp;Info&nbsp;&nbsp;</span>
                    </label>
                    {profile.map((profile) => (
                      <>
                        <input
                          id="tab9"
                          type="radio"
                          name="tabs"
                          disabled={
                            profile.percentageP != null &&
                              profile.percentageC != null &&
                              profile.percentageA != null
                              ? null
                              : "disabled"
                          }
                        />
                        <label
                          id="pendingtab"
                          className="j-tabs-label"
                          htmlFor="tab9"
                          title="Banking"
                          style={{
                            opacity:
                              profile.percentageP != null &&
                                profile.percentageC != null &&
                                profile.percentageA != null
                                ? 1
                                : 0.7,
                          }}
                          onClick={this.handleInfoClick1}
                        >
                          <span>&nbsp;Banking &nbsp;&nbsp;</span>
                        </label>
                      </>
                    ))}
                    {Bankdetail.map((Bankdetail) => (
                      <>
                        <input
                          id="tab4"
                          type="radio"
                          name="tabs"
                          disabled={
                            Bankdetail.percentage == 100 ? null : "disabled"
                          }
                        />
                        <label
                          id="pendingtab"
                          className="j-tabs-label"
                          htmlFor="tab4"
                          title="Contacts"
                          style={{
                            opacity: Bankdetail.percentage == 100 ? 1 : 0.7,
                          }}
                          onClick={this.handleInfoClick3}
                        >
                          <span>&nbsp;Contacts&nbsp;&nbsp;</span>
                        </label>
                      </>
                    ))}
                    <input
                      id="tab2"
                      type="radio"
                      name="tabs"
                      disabled={FamilyDetails.length == 0 ? "disabled" : null}
                    />
                    <label
                      id="activetab"
                      className="j-tabs-label"
                      htmlFor="tab2"
                      title="Education"
                      style={{ opacity: FamilyDetails.length == 0 ? 0.7 : 1 }}
                      onClick={this.handleInfoClick4}
                    >
                      <span>&nbsp;&nbsp;Education&nbsp;&nbsp;</span>
                    </label>
                    <input
                      id="tab3"
                      type="radio"
                      name="tabs"
                      disabled={EducationDetail.length == 0 ? "disabled" : null}
                    />
                    <label
                      id="inactivetab"
                      className="j-tabs-label"
                      htmlFor="tab3"
                      title="Skills"
                      style={{ opacity: EducationDetail.length == 0 ? 0.7 : 1 }}
                      onClick={this.handleInfoClick5}
                    >
                      <span>&nbsp;Skills</span>&nbsp;&nbsp;
                    </label>
                    <Link
                      to="/UserRequest"
                      style={{
                        height: "41.2px",
                        float: "right",
                        position: "inherit",
                        background: "white",
                      }}
                      id="addRow"
                      className="btn"
                    >
                      {" "}
                      Request to Admin{" "}
                    </Link>

                    <br />
                    <br />
                    <br />
                    <br />

                    <div id="tabs-section-1" className="j-tabs-section">
                      <span>Please fill all the mandatory fields to follow the steps
                        <span style={{
                          marginTop: '-4px',
                          color: 'red',
                          fontSize: '25px',
                          position: 'absolute',
                          marginLeft: '4px',
                          fontWeight: 'bold'

                        }}>*</span> </span>



                      <div className="card">
                        <div
                          className="card-header"
                          style={{
                            backgroundColor: "darkslateblue",
                            padding: "5px",
                            color: "white",
                          }}
                        >
                          <h5
                            className="card-header-text"
                            style={{
                              marginTop: "10px",
                              marginLeft: "2%",
                              color: "white",
                            }}
                          >
                            PROFILE <span style={{ color: "red", marginLeft: "73px", marginTop: "-18px", fontSize: "22px" }}>
                              *
                            </span>
                          </h5>
                          <button
                            id="edit-btn"
                            type="button"
                            style={{
                              float: "right",
                              color: "#007bff",
                              fontSize: "medium",
                              fontWeight: "800",
                            }}
                            className="button-20 sk-edit"
                            onClick={() => this.setState({ formErrors: "", ButtonDisabled: false })}
                          >
                            <i className="icofont icofont-edit" title="Edit" />
                          </button>
                        </div>

                        {/* Personal Info*/}
                        <div className="card-block">
                          <div id="view-info">
                            <div className="col-lg-12">
                              <div className="row">
                                <div
                                  className="profile-bg-img"
                                  style={{ marginBottom: "6%" }}
                                >
                                  {profile.map((profile) => (
                                    <img
                                      className="user-img img-circle"
                                      src={profile.profile_Picture}
                                      style={{
                                        borderRadius: "50%",
                                        height: "100px",
                                        width: "100px",
                                      }}
                                      alt="user-img"
                                    />
                                  ))}
                                </div>
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Name </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>
                                        {profile.firstName +
                                          "   " +
                                          profile.middleName +
                                          "   " +
                                          profile.lastName +
                                          "   "}
                                      </lable>
                                    </>
                                  ))}
                                </div>

                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Birth Date </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      {profile.gender !== '-' ? <lable>{profile.dob}</lable> : '-'}{" "}
                                    </>
                                  ))}
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Gender </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>{profile.gender}</lable>
                                    </>
                                  ))}
                                </div>

                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Marital Status </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>
                                        {profile.maritalStatus}
                                      </lable>{" "}
                                    </>
                                  ))}
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Blood Group </span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>{profile.bloodGroup}</lable>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="edit-info">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="general-info">
                                  <form
                                    autoComplete="off"
                                    onSubmit={this.submit}
                                    id="PersonalInfo"
                                  >
                                    <div className="col-lg-12 ">
                                      <div className="row ">
                                        <div className="col-sm-3"></div>
                                        <div className="col-lg-12 ">
                                          {profile.map((profile) => (
                                            <center>
                                              <div
                                                className="col-sm-6 clogo"
                                                style={{
                                                  flexDirection: "column",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <label
                                                  style={{ height: "105px" }}
                                                >
                                                  <input
                                                    type="hidden"
                                                    name="Profile_Picture"
                                                    value={
                                                      profile.profile_Picture
                                                    }
                                                  />
                                                  <img
                                                    alt="preview"
                                                    src={
                                                      !this.state.Preview
                                                        ? profile.profile_Picture
                                                        : this.state.Preview
                                                    }
                                                    id="clogo"
                                                    style={{
                                                      height: "100px",
                                                      width: "100px",
                                                      // marginLeft: "170%",
                                                      borderRadius: "50px",
                                                    }}
                                                  />   </label>
                                                <center>
                                                  <i
                                                    className=" fa fa-pencil editbtn"
                                                    aria-hidden="true"
                                                    style={{
                                                      fontSize: "20px",
                                                      marginLeft: "-160%",
                                                      paddingTop: "8px",
                                                      paddingLeft: "4px",
                                                      marginTop: '-15px'
                                                    }}
                                                    onClick={this.handleIconClick}
                                                  ></i>
                                                </center>
                                                <input
                                                  accept="image/*"
                                                  htmlFor="clogo"
                                                  ref={this.fileInputRef}
                                                  onChange={this.HandleImage}
                                                  style={{ display: "none" }}
                                                  id="Profilepicinfo"
                                                  name="Profile_Picture"
                                                  type="file"
                                                />

                                                <center>
                                                  <span
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      // marginLeft: "83%",
                                                      color: "#a7a2a2",
                                                      marginTop: '-30px'
                                                    }}
                                                  >
                                                    Profile Logo
                                                  </span>
                                                </center>
                                                <input
                                                  accept="image/*"
                                                  type="hidden"
                                                  name="Old_Profile_Picture"
                                                  defaultValue={
                                                    profile.profile_Picture
                                                  }
                                                />
                                              </div>
                                            </center>
                                          ))}
                                        </div>
                                        <div className="col-lg-4 ">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              onChange={
                                                this.handleprofilechange
                                              }
                                              maxLength="20"
                                              defaultValue={profile.firstName}
                                              name="FirstName"
                                              type="text"
                                              id="FirstNameinfo"
                                              placeholder="First Name"
                                              readOnly
                                            />
                                          ))}

                                          {!FirstNameinfo ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              First Name{" "}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div
                                              style={{ color: "red" }}
                                              id="HideName"
                                            >
                                              {FirstNameinfo}
                                            </div>
                                          )}
                                        </div>

                                        <div className="col-lg-4 input-effect">
                                          {profile.map((profile) => (
                                            <div>
                                              <input
                                                className="effect-16"
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                onChange={
                                                  this.handleprofilechange
                                                }
                                                maxLength="20"
                                                defaultValue={
                                                  profile.middleName
                                                }
                                                name="MiddleName"
                                                type="text"
                                                placeholder="Middle Name"
                                              />
                                              <input
                                                type="hidden"
                                                name="Old_middleName"
                                                defaultValue={
                                                  profile.middleName
                                                }
                                              />
                                            </div>
                                          ))}
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Middle Name{" "}
                                          </span>
                                        </div>
                                        <div className="col-lg-4 input-effect">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              onChange={
                                                this.handleprofilechange
                                              }
                                              maxLength="20"
                                              defaultValue={profile.lastName}
                                              name="LastName"
                                              type="text"
                                              id="LastNameinfo"
                                              placeholder="Last Name"
                                            />
                                          ))}
                                          {!LastNameinfo ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Last Name{" "}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {LastNameinfo}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-lg-4 input-effect">
                                          {profile.map((profile) => (
                                            <select
                                              className="effect-16"
                                              defaultValue={profile.gender}
                                              name="Gender"
                                              onChange={
                                                this.handleprofilechange
                                              }
                                              id="Genderinfo"
                                              style={{
                                                // Apply the marginLeft style only if the browser is Chrome
                                                ...(isChrome && { marginLeft: '-3px' })
                                              }}
                                            >
                                              <option hidden>{profile.gender}</option>
                                              <option value="">
                                                Select your Gender
                                              </option>
                                              <option value="Male">Male</option>
                                              <option value="Female">
                                                Female
                                              </option>
                                              <option value="Transgender">
                                                Transgender
                                              </option>
                                            </select>
                                          ))}
                                          {!Genderinfo ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Gender{" "}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {Genderinfo}
                                            </div>
                                          )}
                                        </div>

                                        <div className="col-lg-4 input-effect">
                                          {profile.map((profile) => (
                                            <select
                                              className="effect-16"
                                              defaultValue={
                                                profile.maritalStatus
                                              }
                                              name="MaritalStatus"
                                              onChange={
                                                this.handleprofilechange
                                              }
                                              id="MaritalStatusinfo"
                                              style={{
                                                // Apply the marginLeft style only if the browser is Chrome
                                                ...(isChrome && { marginLeft: '-3px' })
                                              }}
                                            >
                                              <option hidden>{profile.maritalStatus}</option>
                                              <option value="">
                                                Marital status
                                              </option>
                                              <option value="Single">
                                                Single
                                              </option>
                                              <option value="Married">
                                                Married
                                              </option>
                                              <option value="divorced">
                                                Divorced
                                              </option>
                                              <option value="widowed">
                                                Widowed
                                              </option>
                                            </select>
                                          ))}{" "}
                                          {!MaritalStatusinfo ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Marital status{" "}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>{" "}
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {MaritalStatusinfo}
                                            </div>
                                          )}
                                        </div>

                                        <div className="col-lg-4 input-effect">
                                          {profile.map((profile) => (
                                            // <input className="effect-16" onFocus={(e) => e.target.select()} defaultValue={profile.dob} max={new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate()).toJSON().slice(0, 10)} min={new Date(new Date().getFullYear() - 80, new Date().getMonth(), new Date().getDate()).toJSON().slice(0, 10)} onFocus={(e) => e.target.type = 'date'} onBlur={(e) => { e.target.type = 'text'; e.target.value = this.formatDate(e.target.value) }} id="DOBinfo" name="DOB" onChange={this.handleprofilechange} placeholder="Dob" />
                                            <input
                                              className="effect-16"
                                              type="date"
                                              max={new Date(
                                                new Date().getFullYear() - 18,
                                                new Date().getMonth(),
                                                new Date().getDate()
                                              )
                                                .toJSON()
                                                .slice(0, 10)}
                                              min={new Date(
                                                new Date().getFullYear() - 80,
                                                new Date().getMonth(),
                                                new Date().getDate()
                                              )
                                                .toJSON()
                                                .slice(0, 10)}
                                              defaultValue={profile.gender !== '-' ? this.reformatDate(profile.dob) : 'dd-mm-yyyy'}
                                              id="DOBinfo"
                                              name="DOB"
                                              onChange={
                                                this.handleprofilechange
                                              }
                                            />
                                          ))}
                                          {!DOBinfo ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              DOB
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {DOBinfo}
                                            </div>
                                          )}
                                        </div>

                                        <div className="col-lg-4 input-effect">
                                          {profile.map((profile) => (
                                            <select
                                              className="effect-16"
                                              defaultValue={profile.bloodGroup}
                                              name="Bloodgroup"
                                              id="Bloodgroupinfo"
                                              onChange={
                                                this.handleprofilechange
                                              }
                                              type="text"
                                              style={{
                                                // Apply the marginLeft style only if the browser is Chrome
                                                ...(isChrome && { marginLeft: '-3px' })
                                              }}
                                            >
                                              <option hidden>{profile.bloodGroup}</option>
                                              <option value="">
                                                Blood Group
                                              </option>
                                              <option value="A+">A+</option>
                                              <option value="A-">A-</option>
                                              <option value="B+">B+</option>
                                              <option value="B-">B-</option>
                                              <option value="O+">O+</option>
                                              <option value="O-">O-</option>
                                              <option value="AB+">AB+</option>
                                              <option value="AB-">AB-</option>
                                            </select>
                                          ))}
                                          {!Bloodgroupinfo ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Blood Group
                                              {/* <span style={{ color: "red" }}>*</span> */}
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {Bloodgroupinfo}
                                            </div>
                                          )}
                                        </div>

                                        {/* <div className="col-lg-4 input-effect" >
                                          {profile.map(profile => <div>
                                            <input className="effect-16" style={{ width: "100%" }} name="Profile_Picture" onFocus={(e) => e.target.select()} defaultValue={profile.profile_Picture} onFocus={(e) => e.target.type = 'file'} onChange={this.handleImageChange} type="text" id="Profilepicinfo" placeholder="profile picture" />
                                            {this.state.imagePreviewUrl != null ? <img alt="preview" src={this.state.imagePreviewUrl} style={{ height: "70px" }} /> : null}
                                            <input type="hidden" name="Old_Profile_Picture" defaultValue={profile.profile_Picture} />
                                          </div>)}{!Profilepicinfo ? <span style={{ color: '#a29d9d', float: "left" }}>Profile Picture</span> : <div style={{ color: "red" }}>{Profilepicinfo}</div>}

                                        </div> */}
                                      </div>
                                    </div>

                                    <div className="col-lg-12">
                                      <div className="row  justify-content-center">
                                        <button
                                          type="submit"
                                          disabled={this.state.ButtonDisabled}
                                          className="but"
                                          style={{
                                            height: "39.5px",
                                            width: "83.36px",
                                          }}
                                        >
                                          Update
                                        </button>
                                        <button
                                          type="reset"
                                          className=" btn-Secondary"
                                          onClickCapture={this.resetImage}
                                          style={{
                                            marginLeft: "1%",
                                            height: "39.5px",
                                          }}
                                          onClick={() =>
                                            this.setState({ formErrors: "" })
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Contact Info  */}
                      <div className="card">
                        <div
                          className="card-header"
                          style={{
                            backgroundColor: "darkslateblue",
                            padding: "5px",
                            color: "white",
                          }}
                        >
                          <h5
                            className="card-header-text"
                            style={{
                              marginTop: "10px",
                              marginLeft: "2%",
                              color: "white",
                            }}
                          >
                            {" "}
                            Contact <span style={{ color: "red", marginLeft: "83px", marginTop: "-18px", fontSize: "22px" }}>
                              *
                            </span>
                          </h5>
                          <button
                            id="edit-btn-contact"
                            type="button"
                            style={{
                              float: "right",
                              color: "#007bff",
                              fontSize: "medium",
                              fontWeight: "800",
                            }}
                            className="button-20 sk-edit"
                            onClick={() => this.setState({ formErrors: "", ButtonDisabled: false })}
                          >
                            <i className="icofont icofont-edit" title="Edit" />
                          </button>
                        </div>

                        <div className="card-block">
                          <div id="view-contact">
                            <div className="col-lg-12">
                              {/* vfv */}
                              <div className="row">
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Official Email </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable
                                        style={{ overflowWrap: "break-word" }}
                                      >
                                        {profile.email}
                                      </lable>
                                    </>
                                  ))}
                                </div>
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Personal Email </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable
                                        style={{ overflowWrap: "break-word" }}
                                      >
                                        {profile.officialEmail}{" "}
                                      </lable>{" "}
                                    </>
                                  ))}
                                </div>
                              </div>
                              <br />

                              <div className="row">
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Phone Number </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>{profile.homeNumber}</lable>
                                    </>
                                  ))}
                                </div>
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Office Number </span>
                                  </b>
                                </div>
                                <div className="col-lg-3">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>{profile.phoneNumber}</lable>{" "}
                                    </>
                                  ))}
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-lg-3 ">
                                  <b>
                                    <span>Alternate Phone Number</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>{profile.alternatePhoneNo}</lable>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="edit-contact">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="general-info">
                                <form
                                  autoComplete="off"
                                  onSubmit={this.ContactEdit}
                                  id="ContactInfo"
                                >
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="row">
                                        <div className="col-lg-4">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              style={{
                                                width: "82%",
                                                pointerEvents: "none",
                                              }}
                                              type="text"
                                              id="PersonalEmail"
                                              name="Email"
                                              value={profile.email}
                                              onChange={
                                                this.handlecontactChange
                                              }
                                              placeholder="Personal Email  "
                                            />
                                          ))}
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Official Email
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </span>
                                          <br />
                                        </div>
                                        <br />
                                        <div className="col-lg-4">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              maxLength="10"
                                              style={{ width: "82%" }}
                                              type="text"
                                              id="PhoneNumber"
                                              name="PhoneNumber"
                                              onKeyPress={(event) => {
                                                const isLetter = /[a-zA-Z]/i.test(event.key);
                                                if (isLetter) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onInputCapture={(event) => {
                                                if (!/^[0-9]*$/.test(event.target.value)) {
                                                  event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                                }
                                              }}
                                              defaultValue={profile.phoneNumber}
                                              onChange={
                                                this.handlecontactChange
                                              }
                                              placeholder="Office Number "
                                            />
                                          ))}
                                          <br />{" "}
                                          {!contactphnerr ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Office Number
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {contactphnerr}
                                            </div>
                                          )}
                                        </div>{" "}
                                        <br />
                                        <div className="col-lg-4">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              maxLength="10"
                                              style={{ width: "82%" }}
                                              type="text"
                                              id="HomeNumber"
                                              name="HomeNumber"
                                              onKeyPress={(event) => {
                                                const isLetter = /[a-zA-Z]/i.test(event.key);
                                                if (isLetter) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onInputCapture={(event) => {
                                                if (!/^[0-9]*$/.test(event.target.value)) {
                                                  event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                                }
                                              }}
                                              defaultValue={profile.homeNumber}
                                              onChange={
                                                this.handlecontactChange
                                              }
                                              placeholder="Phone Number "
                                            />
                                          ))}
                                          <br />{" "}
                                          {!HomeErr ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Phone Number
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {HomeErr}
                                            </div>
                                          )}
                                        </div>{" "}
                                        <br />
                                      </div>

                                      <div className="row">
                                        <div className="col-lg-4">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              style={{ width: "82%" }}
                                              type="text"
                                              id="OfficialEmail"
                                              name="OfficialEmail"
                                              defaultValue={
                                                profile.officialEmail
                                              }
                                              placeholder="-"
                                              onChange={
                                                this.handlecontactChange
                                              }
                                            />
                                          ))}
                                          <br />

                                          {!officialEmailerr ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Personal Email
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {officialEmailerr}
                                            </div>
                                          )}
                                        </div>
                                        <br />
                                        <div className="col-lg-4">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              maxLength="10"
                                              style={{ width: "82%" }}
                                              type="text"
                                              id="AlternatePhoneNo"
                                              onKeyPress={(event) => {
                                                const isLetter = /[a-zA-Z]/i.test(event.key);
                                                if (isLetter) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onInputCapture={(event) => {
                                                if (!/^[0-9]*$/.test(event.target.value)) {
                                                  event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                                }
                                              }}
                                              defaultValue={
                                                profile.alternatePhoneNo
                                              }
                                              onChange={
                                                this.handlecontactChange
                                              }
                                              name="AlternatePhoneNo"

                                              placeholder="-"
                                            />
                                          ))}
                                          <br />{" "}
                                          {!AlternatePhoneNoerr ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Alternate Phone Number
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {AlternatePhoneNoerr}
                                            </div>
                                          )}
                                        </div>
                                        <br />
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <br /> <br />
                                    <div className="col-lg-12">
                                      <div className="row  justify-content-center">
                                        <button
                                          type="submit"
                                          disabled={this.state.ButtonDisabled}
                                          className="but"
                                          style={{
                                            height: "39.5px",
                                            width: "83.36px",
                                          }}
                                        >
                                          Update
                                        </button>
                                        <button
                                          type="reset"
                                          className=" btn-Secondary"
                                          style={{
                                            marginLeft: "1%",
                                            height: "39.5px",
                                          }}
                                          onClick={() =>
                                            this.setState({ formErrors: "" })
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                    <br />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Address  */}
                      <div className="card">
                        <div
                          className="card-header"
                          style={{
                            backgroundColor: "darkslateblue",
                            padding: "5px",
                            color: "white",
                          }}
                        >
                          <h5
                            className="card-header-text"
                            style={{
                              marginTop: "10px",
                              marginLeft: "2%",
                              color: "white",
                            }}
                          >
                            {" "}
                            Address  <span style={{ color: "red", marginLeft: "83px", marginTop: "-18px", fontSize: "22px" }}>
                              *
                            </span>
                          </h5>
                          <button
                            id="edit-btn-Address"
                            type="button"
                            style={{
                              float: "right",
                              color: "#007bff",
                              fontSize: "medium",
                              fontWeight: "800",
                            }}
                            className="button-20 sk-edit"
                            onClick={() => {
                              this.setState({ formErrors: "", ButtonDisabled: false })
                              this.HandleHouse();
                              this.Getvalue();
                            }}
                          >
                            <i className="icofont icofont-edit" title="Edit" />
                          </button>
                        </div>

                        <div className="card-block">
                          <div id="view-Address">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Current Address </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable style={{ wordWrap: "break-word" }}>
                                        {profile.currentAddress}
                                      </lable>
                                    </>
                                  ))}
                                </div>

                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Permanent Address </span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable style={{ wordWrap: "break-word" }}>
                                        {profile.permanentAddress}
                                      </lable>
                                    </>
                                  ))}
                                </div>
                              </div>
                              <br />
                              {/* City */}
                              <div className="row">
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> City </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>
                                        {profile.currentAddress_City}
                                      </lable>
                                    </>
                                  ))}
                                </div>

                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span>City</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>
                                        {profile.permanentAddress_City}
                                      </lable>
                                    </>
                                  ))}
                                </div>
                              </div>
                              <br />
                              {/* Country */}
                              <div className="row">
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> State </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>
                                        {profile.currentAddress_State}
                                      </lable>
                                    </>
                                  ))}
                                </div>

                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span>State</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>
                                        {profile.permanentAddress_State}
                                      </lable>
                                    </>
                                  ))}
                                </div>
                              </div>
                              <br />
                              {/* Country */}
                              <div className="row">
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Country </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>
                                        {profile.currentAddress_Country}
                                      </lable>
                                    </>
                                  ))}
                                </div>

                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span>Country</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>
                                        {profile.permanentAddress_Country}
                                      </lable>
                                    </>
                                  ))}
                                </div>
                              </div>
                              <br />{" "}
                              <div className="row">
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Pincode </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>
                                        {profile.currentAddress_Pincode}
                                      </lable>
                                    </>
                                  ))}
                                </div>

                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span>Pincode</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>
                                        {profile.permanentAddress_Pincode}
                                      </lable>
                                    </>
                                  ))}
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> HouseType </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>{profile.houseType}</lable>
                                    </>
                                  ))}
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-lg-3 ">
                                  <b>
                                    {" "}
                                    <span> Staying Since </span>
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </div>
                                <div className="col-lg-3 ">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>{profile.stayingSince}</lable>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div id="edit-Address">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="general-info">
                                <form
                                  autoComplete="off"
                                  onSubmit={this.AddressEdit}
                                  id="AddressInfo"
                                >
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="row">
                                        <h5 style={{ marginLeft: "13px" }}>
                                          Current Address
                                        </h5>
                                        <div className="col-12 input-effect">
                                          {profile.map((profile) => (
                                            <textarea
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              onChange={
                                                this.handleAddresschange
                                              }
                                              defaultValue={
                                                profile.currentAddress
                                              }
                                              name="CurrentAddress"
                                              id="CurrentAddress"
                                              type="text"
                                              placeholder="Current Address"
                                            />
                                          ))}
                                          {!CurrentAddress ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Current Address{" "}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {CurrentAddress}
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-xl-6">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              onInputCapture={(event) => {
                                                if (
                                                  !/[a-zA-Z\s ]+/.test(
                                                    event.key
                                                  )
                                                ) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              type="text"
                                              id="CurrentAddress_City"
                                              name="CurrentAddress_City"
                                              defaultValue={
                                                profile.currentAddress_City
                                              }
                                              placeholder="City "
                                              onChange={
                                                this.handleAddresschange
                                              }
                                            />
                                          ))}
                                          <br />{" "}
                                          {!CurrentAddress_City ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              City
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {CurrentAddress_City}
                                            </div>
                                          )}
                                        </div>

                                        <div className="col-xl-6">
                                          {profile.map((profile) => (
                                            <select
                                              className="effect-16"
                                              id="CurrentAddress_Country"
                                              name="CurrentAddress_Country"
                                              defaultValue={
                                                profile.currentAddress_Country
                                              }
                                              data-default-value={profile.currentAddress_Country || ""}
                                              type="text"
                                              onChange={
                                                this.handleAddresschange
                                              }
                                              style={{
                                                // Apply the marginLeft style only if the browser is Chrome
                                                ...(isChrome && { marginLeft: '-3px' })
                                              }}
                                            >
                                              <option hidden>{profile.currentAddress_Country}</option>
                                              <option value="">Select</option>
                                              <option value="Afghanistan">
                                                Afghanistan
                                              </option>
                                              <option value="Åland Islands">
                                                Åland Islands
                                              </option>
                                              <option value="Albania">
                                                Albania
                                              </option>
                                              <option value="Algeria">
                                                Algeria
                                              </option>
                                              <option value="American Samoa">
                                                American Samoa
                                              </option>
                                              <option value="Andorra">
                                                Andorra
                                              </option>
                                              <option value="Angola">
                                                Angola
                                              </option>
                                              <option value="Anguilla">
                                                Anguilla
                                              </option>
                                              <option value="Antarctica">
                                                Antarctica
                                              </option>
                                              <option value="Antigua and Barbuda">
                                                Antigua and Barbuda
                                              </option>
                                              <option value="Argentina">
                                                Argentina
                                              </option>
                                              <option value="Armenia">
                                                Armenia
                                              </option>
                                              <option value="Aruba">
                                                Aruba
                                              </option>
                                              <option value="Australia">
                                                Australia
                                              </option>
                                              <option value="Austria">
                                                Austria
                                              </option>
                                              <option value="Azerbaijan">
                                                Azerbaijan
                                              </option>
                                              <option value="Bahamas">
                                                Bahamas
                                              </option>
                                              <option value="Bahrain">
                                                Bahrain
                                              </option>
                                              <option value="Bangladesh">
                                                Bangladesh
                                              </option>
                                              <option value="Barbados">
                                                Barbados
                                              </option>
                                              <option value="Belarus">
                                                Belarus
                                              </option>
                                              <option value="Belgium">
                                                Belgium
                                              </option>
                                              <option value="Belize">
                                                Belize
                                              </option>
                                              <option value="Benin">
                                                Benin
                                              </option>
                                              <option value="Bermuda">
                                                Bermuda
                                              </option>
                                              <option value="Bhutan">
                                                Bhutan
                                              </option>
                                              <option value="Bolivia">
                                                Bolivia
                                              </option>
                                              <option value="Bosnia and Herzegovina">
                                                Bosnia and Herzegovina
                                              </option>
                                              <option value="Botswana">
                                                Botswana
                                              </option>
                                              <option value="Bouvet Island">
                                                Bouvet Island
                                              </option>
                                              <option value="Brazil">
                                                Brazil
                                              </option>
                                              <option value="British Indian Ocean Territory">
                                                British Indian Ocean Territory
                                              </option>
                                              <option value="Brunei Darussalam">
                                                Brunei Darussalam
                                              </option>
                                              <option value="Bulgaria">
                                                Bulgaria
                                              </option>
                                              <option value="Burkina Faso">
                                                Burkina Faso
                                              </option>
                                              <option value="Burundi">
                                                Burundi
                                              </option>
                                              <option value="Cambodia">
                                                Cambodia
                                              </option>
                                              <option value="Cameroon">
                                                Cameroon
                                              </option>
                                              <option value="Canada">
                                                Canada
                                              </option>
                                              <option value="Cape Verde">
                                                Cape Verde
                                              </option>
                                              <option value="Cayman Islands">
                                                Cayman Islands
                                              </option>
                                              <option value="Central African Republic">
                                                Central African Republic
                                              </option>
                                              <option value="Chad">Chad</option>
                                              <option value="Chile">
                                                Chile
                                              </option>
                                              <option value="China">
                                                China
                                              </option>
                                              <option value="Christmas Island">
                                                Christmas Island
                                              </option>
                                              <option value="Cocos (Keeling) Islands">
                                                Cocos (Keeling) Islands
                                              </option>
                                              <option value="Colombia">
                                                Colombia
                                              </option>
                                              <option value="Comoros">
                                                Comoros
                                              </option>
                                              <option value="Congo">
                                                Congo
                                              </option>
                                              <option value="Congo, The Democratic Republic of The">
                                                Congo, The Democratic Republic
                                                of The
                                              </option>
                                              <option value="Cook Islands">
                                                Cook Islands
                                              </option>
                                              <option value="Costa Rica">
                                                Costa Rica
                                              </option>
                                              <option value="Cote D'ivoire">
                                                Cote D'ivoire
                                              </option>
                                              <option value="Croatia">
                                                Croatia
                                              </option>
                                              <option value="Cuba">Cuba</option>
                                              <option value="Cyprus">
                                                Cyprus
                                              </option>
                                              <option value="Czech Republic">
                                                Czech Republic
                                              </option>
                                              <option value="Denmark">
                                                Denmark
                                              </option>
                                              <option value="Djibouti">
                                                Djibouti
                                              </option>
                                              <option value="Dominica">
                                                Dominica
                                              </option>
                                              <option value="Dominican Republic">
                                                Dominican Republic
                                              </option>
                                              <option value="Ecuador">
                                                Ecuador
                                              </option>
                                              <option value="Egypt">
                                                Egypt
                                              </option>
                                              <option value="El Salvador">
                                                El Salvador
                                              </option>
                                              <option value="Equatorial Guinea">
                                                Equatorial Guinea
                                              </option>
                                              <option value="Eritrea">
                                                Eritrea
                                              </option>
                                              <option value="Estonia">
                                                Estonia
                                              </option>
                                              <option value="Ethiopia">
                                                Ethiopia
                                              </option>
                                              <option value="Falkland Islands (Malvinas)">
                                                Falkland Islands (Malvinas)
                                              </option>
                                              <option value="Faroe Islands">
                                                Faroe Islands
                                              </option>
                                              <option value="Fiji">Fiji</option>
                                              <option value="Finland">
                                                Finland
                                              </option>
                                              <option value="France">
                                                France
                                              </option>
                                              <option value="French Guiana">
                                                French Guiana
                                              </option>
                                              <option value="French Polynesia">
                                                French Polynesia
                                              </option>
                                              <option value="French Southern Territories">
                                                French Southern Territories
                                              </option>
                                              <option value="Gabon">
                                                Gabon
                                              </option>
                                              <option value="Gambia">
                                                Gambia
                                              </option>
                                              <option value="Georgia">
                                                Georgia
                                              </option>
                                              <option value="Germany">
                                                Germany
                                              </option>
                                              <option value="Ghana">
                                                Ghana
                                              </option>
                                              <option value="Gibraltar">
                                                Gibraltar
                                              </option>
                                              <option value="Greece">
                                                Greece
                                              </option>
                                              <option value="Greenland">
                                                Greenland
                                              </option>
                                              <option value="Grenada">
                                                Grenada
                                              </option>
                                              <option value="Guadeloupe">
                                                Guadeloupe
                                              </option>
                                              <option value="Guam">Guam</option>
                                              <option value="Guatemala">
                                                Guatemala
                                              </option>
                                              <option value="Guernsey">
                                                Guernsey
                                              </option>
                                              <option value="Guinea">
                                                Guinea
                                              </option>
                                              <option value="Guinea-bissau">
                                                Guinea-bissau
                                              </option>
                                              <option value="Guyana">
                                                Guyana
                                              </option>
                                              <option value="Haiti">
                                                Haiti
                                              </option>
                                              <option value="Heard Island and Mcdonald Islands">
                                                Heard Island and Mcdonald
                                                Islands
                                              </option>
                                              <option value="Holy See (Vatican City State)">
                                                Holy See (Vatican City State)
                                              </option>
                                              <option value="Honduras">
                                                Honduras
                                              </option>
                                              <option value="Hong Kong">
                                                Hong Kong
                                              </option>
                                              <option value="Hungary">
                                                Hungary
                                              </option>
                                              <option value="Iceland">
                                                Iceland
                                              </option>
                                              <option value="India">
                                                India
                                              </option>
                                              <option value="Indonesia">
                                                Indonesia
                                              </option>
                                              <option value="Iran, Islamic Republic of">
                                                Iran, Islamic Republic of
                                              </option>
                                              <option value="Iraq">Iraq</option>
                                              <option value="Ireland">
                                                Ireland
                                              </option>
                                              <option value="Isle of Man">
                                                Isle of Man
                                              </option>
                                              <option value="Israel">
                                                Israel
                                              </option>
                                              <option value="Italy">
                                                Italy
                                              </option>
                                              <option value="Jamaica">
                                                Jamaica
                                              </option>
                                              <option value="Japan">
                                                Japan
                                              </option>
                                              <option value="Jersey">
                                                Jersey
                                              </option>
                                              <option value="Jordan">
                                                Jordan
                                              </option>
                                              <option value="Kazakhstan">
                                                Kazakhstan
                                              </option>
                                              <option value="Kenya">
                                                Kenya
                                              </option>
                                              <option value="Kiribati">
                                                Kiribati
                                              </option>
                                              <option value="Korea, Democratic People's Republic of">
                                                Korea, Democratic People's
                                                Republic of
                                              </option>
                                              <option value="Korea, Republic of">
                                                Korea, Republic of
                                              </option>
                                              <option value="Kuwait">
                                                Kuwait
                                              </option>
                                              <option value="Kyrgyzstan">
                                                Kyrgyzstan
                                              </option>
                                              <option value="Lao People's Democratic Republic">
                                                Lao People's Democratic Republic
                                              </option>
                                              <option value="Latvia">
                                                Latvia
                                              </option>
                                              <option value="Lebanon">
                                                Lebanon
                                              </option>
                                              <option value="Lesotho">
                                                Lesotho
                                              </option>
                                              <option value="Liberia">
                                                Liberia
                                              </option>
                                              <option value="Libyan Arab Jamahiriya">
                                                Libyan Arab Jamahiriya
                                              </option>
                                              <option value="Liechtenstein">
                                                Liechtenstein
                                              </option>
                                              <option value="Lithuania">
                                                Lithuania
                                              </option>
                                              <option value="Luxembourg">
                                                Luxembourg
                                              </option>
                                              <option value="Macao">
                                                Macao
                                              </option>
                                              <option value="Macedonia, The Former Yugoslav Republic of">
                                                Macedonia, The Former Yugoslav
                                                Republic of
                                              </option>
                                              <option value="Madagascar">
                                                Madagascar
                                              </option>
                                              <option value="Malawi">
                                                Malawi
                                              </option>
                                              <option value="Malaysia">
                                                Malaysia
                                              </option>
                                              <option value="Maldives">
                                                Maldives
                                              </option>
                                              <option value="Mali">Mali</option>
                                              <option value="Malta">
                                                Malta
                                              </option>
                                              <option value="Marshall Islands">
                                                Marshall Islands
                                              </option>
                                              <option value="Martinique">
                                                Martinique
                                              </option>
                                              <option value="Mauritania">
                                                Mauritania
                                              </option>
                                              <option value="Mauritius">
                                                Mauritius
                                              </option>
                                              <option value="Mayotte">
                                                Mayotte
                                              </option>
                                              <option value="Mexico">
                                                Mexico
                                              </option>
                                              <option value="Micronesia, Federated States of">
                                                Micronesia, Federated States of
                                              </option>
                                              <option value="Moldova, Republic of">
                                                Moldova, Republic of
                                              </option>
                                              <option value="Monaco">
                                                Monaco
                                              </option>
                                              <option value="Mongolia">
                                                Mongolia
                                              </option>
                                              <option value="Montenegro">
                                                Montenegro
                                              </option>
                                              <option value="Montserrat">
                                                Montserrat
                                              </option>
                                              <option value="Morocco">
                                                Morocco
                                              </option>
                                              <option value="Mozambique">
                                                Mozambique
                                              </option>
                                              <option value="Myanmar">
                                                Myanmar
                                              </option>
                                              <option value="Namibia">
                                                Namibia
                                              </option>
                                              <option value="Nauru">
                                                Nauru
                                              </option>
                                              <option value="Nepal">
                                                Nepal
                                              </option>
                                              <option value="Netherlands">
                                                Netherlands
                                              </option>
                                              <option value="Netherlands Antilles">
                                                Netherlands Antilles
                                              </option>
                                              <option value="New Caledonia">
                                                New Caledonia
                                              </option>
                                              <option value="New Zealand">
                                                New Zealand
                                              </option>
                                              <option value="Nicaragua">
                                                Nicaragua
                                              </option>
                                              <option value="Niger">
                                                Niger
                                              </option>
                                              <option value="Nigeria">
                                                Nigeria
                                              </option>
                                              <option value="Niue">Niue</option>
                                              <option value="Norfolk Island">
                                                Norfolk Island
                                              </option>
                                              <option value="Northern Mariana Islands">
                                                Northern Mariana Islands
                                              </option>
                                              <option value="Norway">
                                                Norway
                                              </option>
                                              <option value="Oman">Oman</option>
                                              <option value="Pakistan">
                                                Pakistan
                                              </option>
                                              <option value="Palau">
                                                Palau
                                              </option>
                                              <option value="Palestinian Territory, Occupied">
                                                Palestinian Territory, Occupied
                                              </option>
                                              <option value="Panama">
                                                Panama
                                              </option>
                                              <option value="Papua New Guinea">
                                                Papua New Guinea
                                              </option>
                                              <option value="Paraguay">
                                                Paraguay
                                              </option>
                                              <option value="Peru">Peru</option>
                                              <option value="Philippines">
                                                Philippines
                                              </option>
                                              <option value="Pitcairn">
                                                Pitcairn
                                              </option>
                                              <option value="Poland">
                                                Poland
                                              </option>
                                              <option value="Portugal">
                                                Portugal
                                              </option>
                                              <option value="Puerto Rico">
                                                Puerto Rico
                                              </option>
                                              <option value="Qatar">
                                                Qatar
                                              </option>
                                              <option value="Reunion">
                                                Reunion
                                              </option>
                                              <option value="Romania">
                                                Romania
                                              </option>
                                              <option value="Russian Federation">
                                                Russian Federation
                                              </option>
                                              <option value="Rwanda">
                                                Rwanda
                                              </option>
                                              <option value="Saint Helena">
                                                Saint Helena
                                              </option>
                                              <option value="Saint Kitts and Nevis">
                                                Saint Kitts and Nevis
                                              </option>
                                              <option value="Saint Lucia">
                                                Saint Lucia
                                              </option>
                                              <option value="Saint Pierre and Miquelon">
                                                Saint Pierre and Miquelon
                                              </option>
                                              <option value="Saint Vincent and The Grenadines">
                                                Saint Vincent and The Grenadines
                                              </option>
                                              <option value="Samoa">
                                                Samoa
                                              </option>
                                              <option value="San Marino">
                                                San Marino
                                              </option>
                                              <option value="Sao Tome and Principe">
                                                Sao Tome and Principe
                                              </option>
                                              <option value="Saudi Arabia">
                                                Saudi Arabia
                                              </option>
                                              <option value="Senegal">
                                                Senegal
                                              </option>
                                              <option value="Serbia">
                                                Serbia
                                              </option>
                                              <option value="Seychelles">
                                                Seychelles
                                              </option>
                                              <option value="Sierra Leone">
                                                Sierra Leone
                                              </option>
                                              <option value="Singapore">
                                                Singapore
                                              </option>
                                              <option value="Slovakia">
                                                Slovakia
                                              </option>
                                              <option value="Slovenia">
                                                Slovenia
                                              </option>
                                              <option value="Solomon Islands">
                                                Solomon Islands
                                              </option>
                                              <option value="Somalia">
                                                Somalia
                                              </option>
                                              <option value="South Africa">
                                                South Africa
                                              </option>
                                              <option value="South Georgia and The South Sandwich Islands">
                                                South Georgia and The South
                                                Sandwich Islands
                                              </option>
                                              <option value="Spain">
                                                Spain
                                              </option>
                                              <option value="Sri Lanka">
                                                Sri Lanka
                                              </option>
                                              <option value="Sudan">
                                                Sudan
                                              </option>
                                              <option value="Suriname">
                                                Suriname
                                              </option>
                                              <option value="Svalbard and Jan Mayen">
                                                Svalbard and Jan Mayen
                                              </option>
                                              <option value="Swaziland">
                                                Swaziland
                                              </option>
                                              <option value="Sweden">
                                                Sweden
                                              </option>
                                              <option value="Switzerland">
                                                Switzerland
                                              </option>
                                              <option value="Syrian Arab Republic">
                                                Syrian Arab Republic
                                              </option>
                                              <option value="Taiwan, Province of China">
                                                Taiwan, Province of China
                                              </option>
                                              <option value="Tajikistan">
                                                Tajikistan
                                              </option>
                                              <option value="Tanzania, United Republic of">
                                                Tanzania, United Republic of
                                              </option>
                                              <option value="Thailand">
                                                Thailand
                                              </option>
                                              <option value="Timor-leste">
                                                Timor-leste
                                              </option>
                                              <option value="Togo">Togo</option>
                                              <option value="Tokelau">
                                                Tokelau
                                              </option>
                                              <option value="Tonga">
                                                Tonga
                                              </option>
                                              <option value="Trinidad and Tobago">
                                                Trinidad and Tobago
                                              </option>
                                              <option value="Tunisia">
                                                Tunisia
                                              </option>
                                              <option value="Turkey">
                                                Turkey
                                              </option>
                                              <option value="Turkmenistan">
                                                Turkmenistan
                                              </option>
                                              <option value="Turks and Caicos Islands">
                                                Turks and Caicos Islands
                                              </option>
                                              <option value="Tuvalu">
                                                Tuvalu
                                              </option>
                                              <option value="Uganda">
                                                Uganda
                                              </option>
                                              <option value="Ukraine">
                                                Ukraine
                                              </option>
                                              <option value="United Arab Emirates">
                                                United Arab Emirates
                                              </option>
                                              <option value="United Kingdom">
                                                United Kingdom
                                              </option>
                                              <option value="United States">
                                                United States
                                              </option>
                                              <option value="United States Minor Outlying Islands">
                                                United States Minor Outlying
                                                Islands
                                              </option>
                                              <option value="Uruguay">
                                                Uruguay
                                              </option>
                                              <option value="Uzbekistan">
                                                Uzbekistan
                                              </option>
                                              <option value="Vanuatu">
                                                Vanuatu
                                              </option>
                                              <option value="Venezuela">
                                                Venezuela
                                              </option>
                                              <option value="Viet Nam">
                                                Viet Nam
                                              </option>
                                              <option value="Virgin Islands, British">
                                                Virgin Islands, British
                                              </option>
                                              <option value="Virgin Islands, U.S.">
                                                Virgin Islands, U.S.
                                              </option>
                                              <option value="Wallis and Futuna">
                                                Wallis and Futuna
                                              </option>
                                              <option value="Western Sahara">
                                                Western Sahara
                                              </option>
                                              <option value="Yemen">
                                                Yemen
                                              </option>
                                              <option value="Zambia">
                                                Zambia
                                              </option>
                                              <option value="Zimbabwe">
                                                Zimbabwe
                                              </option>
                                            </select>
                                          ))}
                                          <br />
                                          {!CurrentAddress_Country ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Country
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {CurrentAddress_Country}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-xl-6">
                                          {profile.map((profile) => (
                                            <>
                                              {this.state.isSelected ? (
                                                <select
                                                  id="CurrentAddress_State"
                                                  name="CurrentAddress_State"
                                                  className="effect-16"
                                                  onChange={(e) => {
                                                    this.handleAddresschange(e);
                                                    // this.AutoCaps(e);
                                                  }}
                                                  // onFocus={this.handleFocus}
                                                  defaultValue={
                                                    profile.currentAddress_State
                                                  }
                                                  data-default-value={profile.currentAddress_State || ""}
                                                  onKeyPress={(event) => {
                                                    if (
                                                      !/[a-zA-Z\s ]+/.test(
                                                        event.key
                                                      )
                                                    ) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  style={{
                                                    // Apply the marginLeft style only if the browser is Chrome
                                                    ...(isChrome && { marginLeft: '-3px' })
                                                  }}
                                                >
                                                  <option hidden>{profile.currentAddress_State}</option>
                                                  <option value="">
                                                    Select state
                                                  </option>
                                                  <option value="Andaman and Nicobar Islands">
                                                    Andaman and Nicobar Islands
                                                  </option>
                                                  <option value="Andhra Pradesh">
                                                    Andhra Pradesh
                                                  </option>
                                                  <option value="Arunachal Pradesh">
                                                    Arunachal Pradesh
                                                  </option>
                                                  <option value="Assam">
                                                    Assam
                                                  </option>
                                                  <option value="Bihar">
                                                    Bihar
                                                  </option>
                                                  <option value="Chandigarh">
                                                    Chandigarh
                                                  </option>
                                                  <option value="Chhattisgarh">
                                                    Chhattisgarh
                                                  </option>
                                                  <option value="Dadra and Nagar Haveli">
                                                    Dadra and Nagar Haveli
                                                  </option>
                                                  <option value="Daman and Diu">
                                                    Daman and Diu
                                                  </option>
                                                  <option value="Delhi">
                                                    Delhi
                                                  </option>
                                                  <option value="Goa">
                                                    Goa
                                                  </option>
                                                  <option value="Gujarat">
                                                    Gujarat
                                                  </option>
                                                  <option value="Haryana">
                                                    Haryana
                                                  </option>
                                                  <option value="Himachal Pradesh">
                                                    Himachal Pradesh
                                                  </option>
                                                  <option value="Jammu and Kashmir">
                                                    Jammu and Kashmir
                                                  </option>
                                                  <option value="Jharkhand">
                                                    Jharkhand
                                                  </option>
                                                  <option value="Karnataka">
                                                    Karnataka
                                                  </option>
                                                  <option value="Kerala">
                                                    Kerala
                                                  </option>
                                                  <option value="Ladakh">
                                                    Ladakh
                                                  </option>
                                                  <option value="Lakshadweep">
                                                    Lakshadweep
                                                  </option>
                                                  <option value="Madhya Pradesh">
                                                    Madhya Pradesh
                                                  </option>
                                                  <option value="Maharashtra">
                                                    Maharashtra
                                                  </option>
                                                  <option value="Manipur">
                                                    Manipur
                                                  </option>
                                                  <option value="Meghalaya">
                                                    Meghalaya
                                                  </option>
                                                  <option value="Mizoram">
                                                    Mizoram
                                                  </option>
                                                  <option value="Nagaland">
                                                    Nagaland
                                                  </option>
                                                  <option value="Odisha">
                                                    Odisha
                                                  </option>
                                                  <option value="Puducherry">
                                                    Puducherry
                                                  </option>
                                                  <option value="Punjab">
                                                    Punjab
                                                  </option>
                                                  <option value="Rajasthan">
                                                    Rajasthan
                                                  </option>
                                                  <option value="Sikkim">
                                                    Sikkim
                                                  </option>
                                                  <option value="Tamil Nadu">
                                                    Tamil Nadu
                                                  </option>
                                                  <option value="Telangana">
                                                    Telangana
                                                  </option>
                                                  <option value="Tripura">
                                                    Tripura
                                                  </option>
                                                  <option value="Uttar Pradesh">
                                                    Uttar Pradesh
                                                  </option>
                                                  <option value="Uttarakhand">
                                                    Uttarakhand
                                                  </option>
                                                  <option value="West Bengal">
                                                    West Bengal
                                                  </option>
                                                </select>
                                              ) : (
                                                <input
                                                  className="effect-16"
                                                  onFocus={(e) =>
                                                    e.target.select()
                                                  }
                                                  onInputCapture={(event) => {
                                                    if (
                                                      !/[a-zA-Z\s ]+/.test(
                                                        event.key
                                                      )
                                                    ) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  type="text"
                                                  id="CurrentAddress_State"
                                                  name="CurrentAddress_State"
                                                  defaultValue={
                                                    profile.currentAddress_State
                                                  }
                                                  placeholder="State "
                                                  onChange={
                                                    this.handleAddresschange
                                                  }
                                                />
                                              )}
                                            </>
                                          ))}
                                          {!CurrentAddress_State ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              State
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {CurrentAddress_State}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-xl-6">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              type="text"
                                              id="CurrentAddress_Pincode"
                                              name="CurrentAddress_Pincode"
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxLength="6"
                                              defaultValue={
                                                profile.currentAddress_Pincode
                                              }
                                              placeholder="Pincode "
                                              onInputCapture={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onChange={
                                                this.handleAddresschange
                                              }
                                            />
                                          ))}
                                          <br />{" "}
                                          {!CurrentAddress_Pincode ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Pincode
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {CurrentAddress_Pincode}
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-xl-6">
                                          {profile.map((profile) => (
                                            <select
                                              className="effect-16"
                                              defaultValue={profile.houseType}
                                              onInputCapture={this.HandleHouse}
                                              data-default-value={profile.houseType || ""}
                                              id="HouseType"
                                              name="HouseType"
                                              onChange={
                                                this.handleAddresschange
                                              }
                                              style={{
                                                // Apply the marginLeft style only if the browser is Chrome
                                                ...(isChrome && { marginLeft: '-3px' })
                                              }}
                                            >
                                              <option value={profile.houseType} hidden>{profile.houseType}</option>
                                              <option value="">Select</option>

                                              {HouseTypeDrop.map(
                                                (HouseTypeDrop) => (
                                                  <option
                                                    value={
                                                      HouseTypeDrop.housetype
                                                    }
                                                    selected = {
                                                      HouseTypeDrop.housetype === 
                                                      profile.houseType
                                                    }
                                                  >
                                                    {HouseTypeDrop.housetype}
                                                  </option>
                                                )
                                              )}
                                              <option value="others">
                                                Others
                                              </option>
                                            </select>
                                          ))}
                                          <input
                                            type="hidden"
                                            Name="otherType"
                                            placeholder="House Type"
                                            className="form"
                                            id="HouseText"
                                            maxLength={30}
                                            onChange={this.handleAddresschange}
                                          />
                                          <br />{" "}
                                          {!HouseType ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              House Type
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {HouseType}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-xl-6">
                                          {profile.map((profile) => (
                                            // <input className="effect-16" max={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1).toJSON().slice(0, 10)} id="StayingSince" type="text" name="StayingSince" onFocus={(e) => e.target.type = 'date'} onBlur={(e) => { e.target.type = 'text'; e.target.value = this.formatDate(e.target.value) }} defaultValue={profile.stayingSince} placeholder="Staying Since  " onChange={this.handleAddresschange} />
                                            <input
                                              className="effect-16"
                                              type="date"
                                              name="StayingSince"
                                              max={new Date(
                                                new Date().getFullYear(),
                                                new Date().getMonth(),
                                                new Date().getDate()
                                              )
                                                .toJSON()
                                                .slice(0, 10)}
                                              defaultValue={this.reformatDate(
                                                profile.stayingSince
                                              )}
                                              id="StayingSince"
                                              placeholder="Staying Since"
                                              onChange={
                                                this.handleAddresschange
                                              }
                                            />
                                          ))}
                                          <br />{" "}
                                          {!StayingSince ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Staying since
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {StayingSince}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <br />

                                      <div
                                        className="checkbox-fade fade-in-primary"
                                        style={{ marginLeft: "10px" }}
                                      >
                                        <label>
                                          <input
                                            type="checkbox"
                                            onClick={this.handlecheckbox}
                                            onClickCapture={
                                              this.handleTextareaClick
                                            }

                                            name="SameAddress"
                                            id="SameAddress"
                                          />
                                          <span className="cr">
                                            <i className="cr-icon icofont icofont-ui-check txt-primary" />
                                          </span>{" "}
                                          <span>
                                            Permanent address is the same as
                                            current address
                                          </span>
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-lg-6">
                                      <h5 style={{ marginLeft: "9px" }}>
                                        Permanent Address
                                      </h5>
                                      <div className="row">
                                        <div className="col-12 input-effect">
                                          {profile.map((profile) => (
                                            <textarea
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              readOnly={this.state.isReadOnly}
                                              onChange={this.handlePermanentAddress}
                                              defaultValue={
                                                profile.permanentAddress
                                              }
                                              id="PermanentAddress"
                                              name="PermanentAddress"
                                              type="text"
                                            />
                                          ))}
                                          {/* <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Permanent Address{" "}
                                        </span> */}
                                          {!PermanentAddress ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Permanent Address

                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {PermanentAddress}
                                            </div>
                                          )}
                                        </div>
                                      </div>


                                      <div className="row">
                                        <div className="col-xl-6">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              disabled={this.state.isReadOnly}
                                              onInputCapture={(event) => {
                                                if (
                                                  !/[a-zA-Z\s ]+/.test(
                                                    event.key
                                                  )
                                                ) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onChange={this.handlePermanentAddress}
                                              type="text"
                                              id="PermanentAddress_City"
                                              name="PermanentAddress_City"
                                              defaultValue={
                                                profile.permanentAddress_City
                                              }
                                            />
                                          ))}{" "}
                                          {/* <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            City{" "}
                                          </span> */}

                                          {!PermanentAddress_City ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              City

                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {PermanentAddress_City}
                                            </div>
                                          )}
                                        </div>

                                        <div className="col-xl-6">
                                          {profile.map((profile) => (
                                            <select
                                              className="effect-16"
                                              id="PermanentAddress_Country"
                                              disabled={this.state.isReadOnly}
                                              name="PermanentAddress_Country"
                                              defaultValue={
                                                profile.permanentAddress_Country
                                              }
                                              data-default-value={profile.permanentAddress_Country || ""}
                                              onChange={
                                                this.handlePermanentAddress
                                              }
                                              type="text"
                                              style={{
                                                // Apply the marginLeft style only if the browser is Chrome
                                                ...(isChrome && { marginLeft: '-3px' })
                                              }}
                                            >
                                              <option hidden>{profile.permanentAddress_Country}</option>
                                              <option value="">Select</option>
                                              <option value="Afghanistan">
                                                Afghanistan
                                              </option>
                                              <option value="Åland Islands">
                                                Åland Islands
                                              </option>
                                              <option value="Albania">
                                                Albania
                                              </option>
                                              <option value="Algeria">
                                                Algeria
                                              </option>
                                              <option value="American Samoa">
                                                American Samoa
                                              </option>
                                              <option value="Andorra">
                                                Andorra
                                              </option>
                                              <option value="Angola">
                                                Angola
                                              </option>
                                              <option value="Anguilla">
                                                Anguilla
                                              </option>
                                              <option value="Antarctica">
                                                Antarctica
                                              </option>
                                              <option value="Antigua and Barbuda">
                                                Antigua and Barbuda
                                              </option>
                                              <option value="Argentina">
                                                Argentina
                                              </option>
                                              <option value="Armenia">
                                                Armenia
                                              </option>
                                              <option value="Aruba">
                                                Aruba
                                              </option>
                                              <option value="Australia">
                                                Australia
                                              </option>
                                              <option value="Austria">
                                                Austria
                                              </option>
                                              <option value="Azerbaijan">
                                                Azerbaijan
                                              </option>
                                              <option value="Bahamas">
                                                Bahamas
                                              </option>
                                              <option value="Bahrain">
                                                Bahrain
                                              </option>
                                              <option value="Bangladesh">
                                                Bangladesh
                                              </option>
                                              <option value="Barbados">
                                                Barbados
                                              </option>
                                              <option value="Belarus">
                                                Belarus
                                              </option>
                                              <option value="Belgium">
                                                Belgium
                                              </option>
                                              <option value="Belize">
                                                Belize
                                              </option>
                                              <option value="Benin">
                                                Benin
                                              </option>
                                              <option value="Bermuda">
                                                Bermuda
                                              </option>
                                              <option value="Bhutan">
                                                Bhutan
                                              </option>
                                              <option value="Bolivia">
                                                Bolivia
                                              </option>
                                              <option value="Bosnia and Herzegovina">
                                                Bosnia and Herzegovina
                                              </option>
                                              <option value="Botswana">
                                                Botswana
                                              </option>
                                              <option value="Bouvet Island">
                                                Bouvet Island
                                              </option>
                                              <option value="Brazil">
                                                Brazil
                                              </option>
                                              <option value="British Indian Ocean Territory">
                                                British Indian Ocean Territory
                                              </option>
                                              <option value="Brunei Darussalam">
                                                Brunei Darussalam
                                              </option>
                                              <option value="Bulgaria">
                                                Bulgaria
                                              </option>
                                              <option value="Burkina Faso">
                                                Burkina Faso
                                              </option>
                                              <option value="Burundi">
                                                Burundi
                                              </option>
                                              <option value="Cambodia">
                                                Cambodia
                                              </option>
                                              <option value="Cameroon">
                                                Cameroon
                                              </option>
                                              <option value="Canada">
                                                Canada
                                              </option>
                                              <option value="Cape Verde">
                                                Cape Verde
                                              </option>
                                              <option value="Cayman Islands">
                                                Cayman Islands
                                              </option>
                                              <option value="Central African Republic">
                                                Central African Republic
                                              </option>
                                              <option value="Chad">Chad</option>
                                              <option value="Chile">
                                                Chile
                                              </option>
                                              <option value="China">
                                                China
                                              </option>
                                              <option value="Christmas Island">
                                                Christmas Island
                                              </option>
                                              <option value="Cocos (Keeling) Islands">
                                                Cocos (Keeling) Islands
                                              </option>
                                              <option value="Colombia">
                                                Colombia
                                              </option>
                                              <option value="Comoros">
                                                Comoros
                                              </option>
                                              <option value="Congo">
                                                Congo
                                              </option>
                                              <option value="Congo, The Democratic Republic of The">
                                                Congo, The Democratic Republic
                                                of The
                                              </option>
                                              <option value="Cook Islands">
                                                Cook Islands
                                              </option>
                                              <option value="Costa Rica">
                                                Costa Rica
                                              </option>
                                              <option value="Cote D'ivoire">
                                                Cote D'ivoire
                                              </option>
                                              <option value="Croatia">
                                                Croatia
                                              </option>
                                              <option value="Cuba">Cuba</option>
                                              <option value="Cyprus">
                                                Cyprus
                                              </option>
                                              <option value="Czech Republic">
                                                Czech Republic
                                              </option>
                                              <option value="Denmark">
                                                Denmark
                                              </option>
                                              <option value="Djibouti">
                                                Djibouti
                                              </option>
                                              <option value="Dominica">
                                                Dominica
                                              </option>
                                              <option value="Dominican Republic">
                                                Dominican Republic
                                              </option>
                                              <option value="Ecuador">
                                                Ecuador
                                              </option>
                                              <option value="Egypt">
                                                Egypt
                                              </option>
                                              <option value="El Salvador">
                                                El Salvador
                                              </option>
                                              <option value="Equatorial Guinea">
                                                Equatorial Guinea
                                              </option>
                                              <option value="Eritrea">
                                                Eritrea
                                              </option>
                                              <option value="Estonia">
                                                Estonia
                                              </option>
                                              <option value="Ethiopia">
                                                Ethiopia
                                              </option>
                                              <option value="Falkland Islands (Malvinas)">
                                                Falkland Islands (Malvinas)
                                              </option>
                                              <option value="Faroe Islands">
                                                Faroe Islands
                                              </option>
                                              <option value="Fiji">Fiji</option>
                                              <option value="Finland">
                                                Finland
                                              </option>
                                              <option value="France">
                                                France
                                              </option>
                                              <option value="French Guiana">
                                                French Guiana
                                              </option>
                                              <option value="French Polynesia">
                                                French Polynesia
                                              </option>
                                              <option value="French Southern Territories">
                                                French Southern Territories
                                              </option>
                                              <option value="Gabon">
                                                Gabon
                                              </option>
                                              <option value="Gambia">
                                                Gambia
                                              </option>
                                              <option value="Georgia">
                                                Georgia
                                              </option>
                                              <option value="Germany">
                                                Germany
                                              </option>
                                              <option value="Ghana">
                                                Ghana
                                              </option>
                                              <option value="Gibraltar">
                                                Gibraltar
                                              </option>
                                              <option value="Greece">
                                                Greece
                                              </option>
                                              <option value="Greenland">
                                                Greenland
                                              </option>
                                              <option value="Grenada">
                                                Grenada
                                              </option>
                                              <option value="Guadeloupe">
                                                Guadeloupe
                                              </option>
                                              <option value="Guam">Guam</option>
                                              <option value="Guatemala">
                                                Guatemala
                                              </option>
                                              <option value="Guernsey">
                                                Guernsey
                                              </option>
                                              <option value="Guinea">
                                                Guinea
                                              </option>
                                              <option value="Guinea-bissau">
                                                Guinea-bissau
                                              </option>
                                              <option value="Guyana">
                                                Guyana
                                              </option>
                                              <option value="Haiti">
                                                Haiti
                                              </option>
                                              <option value="Heard Island and Mcdonald Islands">
                                                Heard Island and Mcdonald
                                                Islands
                                              </option>
                                              <option value="Holy See (Vatican City State)">
                                                Holy See (Vatican City State)
                                              </option>
                                              <option value="Honduras">
                                                Honduras
                                              </option>
                                              <option value="Hong Kong">
                                                Hong Kong
                                              </option>
                                              <option value="Hungary">
                                                Hungary
                                              </option>
                                              <option value="Iceland">
                                                Iceland
                                              </option>
                                              <option value="India">
                                                India
                                              </option>
                                              <option value="Indonesia">
                                                Indonesia
                                              </option>
                                              <option value="Iran, Islamic Republic of">
                                                Iran, Islamic Republic of
                                              </option>
                                              <option value="Iraq">Iraq</option>
                                              <option value="Ireland">
                                                Ireland
                                              </option>
                                              <option value="Isle of Man">
                                                Isle of Man
                                              </option>
                                              <option value="Israel">
                                                Israel
                                              </option>
                                              <option value="Italy">
                                                Italy
                                              </option>
                                              <option value="Jamaica">
                                                Jamaica
                                              </option>
                                              <option value="Japan">
                                                Japan
                                              </option>
                                              <option value="Jersey">
                                                Jersey
                                              </option>
                                              <option value="Jordan">
                                                Jordan
                                              </option>
                                              <option value="Kazakhstan">
                                                Kazakhstan
                                              </option>
                                              <option value="Kenya">
                                                Kenya
                                              </option>
                                              <option value="Kiribati">
                                                Kiribati
                                              </option>
                                              <option value="Korea, Democratic People's Republic of">
                                                Korea, Democratic People's
                                                Republic of
                                              </option>
                                              <option value="Korea, Republic of">
                                                Korea, Republic of
                                              </option>
                                              <option value="Kuwait">
                                                Kuwait
                                              </option>
                                              <option value="Kyrgyzstan">
                                                Kyrgyzstan
                                              </option>
                                              <option value="Lao People's Democratic Republic">
                                                Lao People's Democratic Republic
                                              </option>
                                              <option value="Latvia">
                                                Latvia
                                              </option>
                                              <option value="Lebanon">
                                                Lebanon
                                              </option>
                                              <option value="Lesotho">
                                                Lesotho
                                              </option>
                                              <option value="Liberia">
                                                Liberia
                                              </option>
                                              <option value="Libyan Arab Jamahiriya">
                                                Libyan Arab Jamahiriya
                                              </option>
                                              <option value="Liechtenstein">
                                                Liechtenstein
                                              </option>
                                              <option value="Lithuania">
                                                Lithuania
                                              </option>
                                              <option value="Luxembourg">
                                                Luxembourg
                                              </option>
                                              <option value="Macao">
                                                Macao
                                              </option>
                                              <option value="Macedonia, The Former Yugoslav Republic of">
                                                Macedonia, The Former Yugoslav
                                                Republic of
                                              </option>
                                              <option value="Madagascar">
                                                Madagascar
                                              </option>
                                              <option value="Malawi">
                                                Malawi
                                              </option>
                                              <option value="Malaysia">
                                                Malaysia
                                              </option>
                                              <option value="Maldives">
                                                Maldives
                                              </option>
                                              <option value="Mali">Mali</option>
                                              <option value="Malta">
                                                Malta
                                              </option>
                                              <option value="Marshall Islands">
                                                Marshall Islands
                                              </option>
                                              <option value="Martinique">
                                                Martinique
                                              </option>
                                              <option value="Mauritania">
                                                Mauritania
                                              </option>
                                              <option value="Mauritius">
                                                Mauritius
                                              </option>
                                              <option value="Mayotte">
                                                Mayotte
                                              </option>
                                              <option value="Mexico">
                                                Mexico
                                              </option>
                                              <option value="Micronesia, Federated States of">
                                                Micronesia, Federated States of
                                              </option>
                                              <option value="Moldova, Republic of">
                                                Moldova, Republic of
                                              </option>
                                              <option value="Monaco">
                                                Monaco
                                              </option>
                                              <option value="Mongolia">
                                                Mongolia
                                              </option>
                                              <option value="Montenegro">
                                                Montenegro
                                              </option>
                                              <option value="Montserrat">
                                                Montserrat
                                              </option>
                                              <option value="Morocco">
                                                Morocco
                                              </option>
                                              <option value="Mozambique">
                                                Mozambique
                                              </option>
                                              <option value="Myanmar">
                                                Myanmar
                                              </option>
                                              <option value="Namibia">
                                                Namibia
                                              </option>
                                              <option value="Nauru">
                                                Nauru
                                              </option>
                                              <option value="Nepal">
                                                Nepal
                                              </option>
                                              <option value="Netherlands">
                                                Netherlands
                                              </option>
                                              <option value="Netherlands Antilles">
                                                Netherlands Antilles
                                              </option>
                                              <option value="New Caledonia">
                                                New Caledonia
                                              </option>
                                              <option value="New Zealand">
                                                New Zealand
                                              </option>
                                              <option value="Nicaragua">
                                                Nicaragua
                                              </option>
                                              <option value="Niger">
                                                Niger
                                              </option>
                                              <option value="Nigeria">
                                                Nigeria
                                              </option>
                                              <option value="Niue">Niue</option>
                                              <option value="Norfolk Island">
                                                Norfolk Island
                                              </option>
                                              <option value="Northern Mariana Islands">
                                                Northern Mariana Islands
                                              </option>
                                              <option value="Norway">
                                                Norway
                                              </option>
                                              <option value="Oman">Oman</option>
                                              <option value="Pakistan">
                                                Pakistan
                                              </option>
                                              <option value="Palau">
                                                Palau
                                              </option>
                                              <option value="Palestinian Territory, Occupied">
                                                Palestinian Territory, Occupied
                                              </option>
                                              <option value="Panama">
                                                Panama
                                              </option>
                                              <option value="Papua New Guinea">
                                                Papua New Guinea
                                              </option>
                                              <option value="Paraguay">
                                                Paraguay
                                              </option>
                                              <option value="Peru">Peru</option>
                                              <option value="Philippines">
                                                Philippines
                                              </option>
                                              <option value="Pitcairn">
                                                Pitcairn
                                              </option>
                                              <option value="Poland">
                                                Poland
                                              </option>
                                              <option value="Portugal">
                                                Portugal
                                              </option>
                                              <option value="Puerto Rico">
                                                Puerto Rico
                                              </option>
                                              <option value="Qatar">
                                                Qatar
                                              </option>
                                              <option value="Reunion">
                                                Reunion
                                              </option>
                                              <option value="Romania">
                                                Romania
                                              </option>
                                              <option value="Russian Federation">
                                                Russian Federation
                                              </option>
                                              <option value="Rwanda">
                                                Rwanda
                                              </option>
                                              <option value="Saint Helena">
                                                Saint Helena
                                              </option>
                                              <option value="Saint Kitts and Nevis">
                                                Saint Kitts and Nevis
                                              </option>
                                              <option value="Saint Lucia">
                                                Saint Lucia
                                              </option>
                                              <option value="Saint Pierre and Miquelon">
                                                Saint Pierre and Miquelon
                                              </option>
                                              <option value="Saint Vincent and The Grenadines">
                                                Saint Vincent and The Grenadines
                                              </option>
                                              <option value="Samoa">
                                                Samoa
                                              </option>
                                              <option value="San Marino">
                                                San Marino
                                              </option>
                                              <option value="Sao Tome and Principe">
                                                Sao Tome and Principe
                                              </option>
                                              <option value="Saudi Arabia">
                                                Saudi Arabia
                                              </option>
                                              <option value="Senegal">
                                                Senegal
                                              </option>
                                              <option value="Serbia">
                                                Serbia
                                              </option>
                                              <option value="Seychelles">
                                                Seychelles
                                              </option>
                                              <option value="Sierra Leone">
                                                Sierra Leone
                                              </option>
                                              <option value="Singapore">
                                                Singapore
                                              </option>
                                              <option value="Slovakia">
                                                Slovakia
                                              </option>
                                              <option value="Slovenia">
                                                Slovenia
                                              </option>
                                              <option value="Solomon Islands">
                                                Solomon Islands
                                              </option>
                                              <option value="Somalia">
                                                Somalia
                                              </option>
                                              <option value="South Africa">
                                                South Africa
                                              </option>
                                              <option value="South Georgia and The South Sandwich Islands">
                                                South Georgia and The South
                                                Sandwich Islands
                                              </option>
                                              <option value="Spain">
                                                Spain
                                              </option>
                                              <option value="Sri Lanka">
                                                Sri Lanka
                                              </option>
                                              <option value="Sudan">
                                                Sudan
                                              </option>
                                              <option value="Suriname">
                                                Suriname
                                              </option>
                                              <option value="Svalbard and Jan Mayen">
                                                Svalbard and Jan Mayen
                                              </option>
                                              <option value="Swaziland">
                                                Swaziland
                                              </option>
                                              <option value="Sweden">
                                                Sweden
                                              </option>
                                              <option value="Switzerland">
                                                Switzerland
                                              </option>
                                              <option value="Syrian Arab Republic">
                                                Syrian Arab Republic
                                              </option>
                                              <option value="Taiwan, Province of China">
                                                Taiwan, Province of China
                                              </option>
                                              <option value="Tajikistan">
                                                Tajikistan
                                              </option>
                                              <option value="Tanzania, United Republic of">
                                                Tanzania, United Republic of
                                              </option>
                                              <option value="Thailand">
                                                Thailand
                                              </option>
                                              <option value="Timor-leste">
                                                Timor-leste
                                              </option>
                                              <option value="Togo">Togo</option>
                                              <option value="Tokelau">
                                                Tokelau
                                              </option>
                                              <option value="Tonga">
                                                Tonga
                                              </option>
                                              <option value="Trinidad and Tobago">
                                                Trinidad and Tobago
                                              </option>
                                              <option value="Tunisia">
                                                Tunisia
                                              </option>
                                              <option value="Turkey">
                                                Turkey
                                              </option>
                                              <option value="Turkmenistan">
                                                Turkmenistan
                                              </option>
                                              <option value="Turks and Caicos Islands">
                                                Turks and Caicos Islands
                                              </option>
                                              <option value="Tuvalu">
                                                Tuvalu
                                              </option>
                                              <option value="Uganda">
                                                Uganda
                                              </option>
                                              <option value="Ukraine">
                                                Ukraine
                                              </option>
                                              <option value="United Arab Emirates">
                                                United Arab Emirates
                                              </option>
                                              <option value="United Kingdom">
                                                United Kingdom
                                              </option>
                                              <option value="United States">
                                                United States
                                              </option>
                                              <option value="United States Minor Outlying Islands">
                                                United States Minor Outlying
                                                Islands
                                              </option>
                                              <option value="Uruguay">
                                                Uruguay
                                              </option>
                                              <option value="Uzbekistan">
                                                Uzbekistan
                                              </option>
                                              <option value="Vanuatu">
                                                Vanuatu
                                              </option>
                                              <option value="Venezuela">
                                                Venezuela
                                              </option>
                                              <option value="Viet Nam">
                                                Viet Nam
                                              </option>
                                              <option value="Virgin Islands, British">
                                                Virgin Islands, British
                                              </option>
                                              <option value="Virgin Islands, U.S.">
                                                Virgin Islands, U.S.
                                              </option>
                                              <option value="Wallis and Futuna">
                                                Wallis and Futuna
                                              </option>
                                              <option value="Western Sahara">
                                                Western Sahara
                                              </option>
                                              <option value="Yemen">
                                                Yemen
                                              </option>
                                              <option value="Zambia">
                                                Zambia
                                              </option>
                                              <option value="Zimbabwe">
                                                Zimbabwe
                                              </option>
                                            </select>
                                          ))}

                                          {/* <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Country{" "}
                                          </span> */}
                                          {!PermanentAddress_Country ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Country

                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {PermanentAddress_Country}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-xl-6">
                                          {profile.map((profile) => (
                                            <>
                                              {this.state.isSelected1 ? (
                                                <select
                                                  id="PermanentAddress_State"
                                                  name="PermanentAddress_State"
                                                  disabled={this.state.isReadOnly}
                                                  className="effect-16"
                                                  onChange={(e) => {
                                                    this.handlePermanentAddress(
                                                      e
                                                    );
                                                    // this.AutoCaps(e);
                                                  }}
                                                  // onFocus={this.handleFocus}
                                                  defaultValue={
                                                    profile.permanentAddress_State
                                                  }
                                                  data-default-value={profile.permanentAddress_State || ""}
                                                  onKeyPress={(event) => {
                                                    if (
                                                      !/[a-zA-Z\s ]+/.test(
                                                        event.key
                                                      )
                                                    ) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  style={{
                                                    // Apply the marginLeft style only if the browser is Chrome
                                                    ...(isChrome && { marginLeft: '-3px' })
                                                  }}
                                                >
                                                  <option hidden>{profile.permanentAddress_State}</option>
                                                  <option value="">
                                                    Select state
                                                  </option>
                                                  <option value="Andaman and Nicobar Islands">
                                                    Andaman and Nicobar Islands
                                                  </option>
                                                  <option value="Andhra Pradesh">
                                                    Andhra Pradesh
                                                  </option>
                                                  <option value="Arunachal Pradesh">
                                                    Arunachal Pradesh
                                                  </option>
                                                  <option value="Assam">
                                                    Assam
                                                  </option>
                                                  <option value="Bihar">
                                                    Bihar
                                                  </option>
                                                  <option value="Chandigarh">
                                                    Chandigarh
                                                  </option>
                                                  <option value="Chhattisgarh">
                                                    Chhattisgarh
                                                  </option>
                                                  <option value="Dadra and Nagar Haveli">
                                                    Dadra and Nagar Haveli
                                                  </option>
                                                  <option value="Daman and Diu">
                                                    Daman and Diu
                                                  </option>
                                                  <option value="Delhi">
                                                    Delhi
                                                  </option>
                                                  <option value="Goa">
                                                    Goa
                                                  </option>
                                                  <option value="Gujarat">
                                                    Gujarat
                                                  </option>
                                                  <option value="Haryana">
                                                    Haryana
                                                  </option>
                                                  <option value="Himachal Pradesh">
                                                    Himachal Pradesh
                                                  </option>
                                                  <option value="Jammu and Kashmir">
                                                    Jammu and Kashmir
                                                  </option>
                                                  <option value="Jharkhand">
                                                    Jharkhand
                                                  </option>
                                                  <option value="Karnataka">
                                                    Karnataka
                                                  </option>
                                                  <option value="Kerala">
                                                    Kerala
                                                  </option>
                                                  <option value="Ladakh">
                                                    Ladakh
                                                  </option>
                                                  <option value="Lakshadweep">
                                                    Lakshadweep
                                                  </option>
                                                  <option value="Madhya Pradesh">
                                                    Madhya Pradesh
                                                  </option>
                                                  <option value="Maharashtra">
                                                    Maharashtra
                                                  </option>
                                                  <option value="Manipur">
                                                    Manipur
                                                  </option>
                                                  <option value="Meghalaya">
                                                    Meghalaya
                                                  </option>
                                                  <option value="Mizoram">
                                                    Mizoram
                                                  </option>
                                                  <option value="Nagaland">
                                                    Nagaland
                                                  </option>
                                                  <option value="Odisha">
                                                    Odisha
                                                  </option>
                                                  <option value="Puducherry">
                                                    Puducherry
                                                  </option>
                                                  <option value="Punjab">
                                                    Punjab
                                                  </option>
                                                  <option value="Rajasthan">
                                                    Rajasthan
                                                  </option>
                                                  <option value="Sikkim">
                                                    Sikkim
                                                  </option>
                                                  <option value="Tamil Nadu">
                                                    Tamil Nadu
                                                  </option>
                                                  <option value="Telangana">
                                                    Telangana
                                                  </option>
                                                  <option value="Tripura">
                                                    Tripura
                                                  </option>
                                                  <option value="Uttar Pradesh">
                                                    Uttar Pradesh
                                                  </option>
                                                  <option value="Uttarakhand">
                                                    Uttarakhand
                                                  </option>
                                                  <option value="West Bengal">
                                                    West Bengal
                                                  </option>
                                                </select>
                                              ) : (
                                                <input
                                                  className="effect-16"
                                                  onFocus={(e) =>
                                                    e.target.select()
                                                  }
                                                  onInputCapture={(event) => {
                                                    if (
                                                      !/[a-zA-Z\s ]+/.test(
                                                        event.key
                                                      )
                                                    ) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  type="text"
                                                  id="PermanentAddress_State"
                                                  name="PermanentAddress_State"
                                                  defaultValue={
                                                    profile.permanentAddress_State
                                                  }
                                                  placeholder="State"
                                                  onChange={
                                                    this.handlePermanentAddress
                                                  }
                                                />
                                              )}
                                            </>
                                          ))}
                                          {/* <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            State{" "}
                                          </span> */}

                                          {!PermanentAddress_State ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              State

                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {PermanentAddress_State}
                                            </div>
                                          )}
                                          {/* {!CurrentAddress_State ? <span style={{ color: '#a29d9d', float: "left" }}>State<span style={{ color: "red" }}>*</span></span> : <div style={{ color: "red" }}>{CurrentAddress_State}</div>} */}
                                        </div>
                                        {/* <div className="col-xl-6">
                                          {profile.map(profile =>
                                            <input className="effect-16" onFocus={(e) => e.target.select()} disabled={this.state.isReadOnly} onInputCapture={(event) => { if (!/[a-zA-Z\s ]+/.test(event.key)) { event.preventDefault(); } }} type="text" name="PermanentAddress_State" id="PermanentAddress_State" defaultValue={profile.permanentAddress_State} />
                                          )} <span style={{ color: '#a29d9d', float: "left" }}>State </span>
                                        </div> */}

                                        <div className="col-xl-6">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              type="text"
                                              disabled={this.state.isReadOnly}
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxLength="6"
                                              id="PermanentAddress_Pincode"
                                              name="PermanentAddress_Pincode"
                                              defaultValue={
                                                profile.permanentAddress_Pincode
                                              }
                                              onChange={
                                                this.handlePermanentAddress
                                              }
                                            />
                                          ))}{" "}
                                          {/* <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Pincode{" "}
                                          </span> */}
                                          {!PermanentAddress_Pincode ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Pincode

                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {PermanentAddress_Pincode}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="center">
                                      <button
                                        type="submit"
                                        disabled={this.state.ButtonDisabled}
                                        className="but"
                                        style={{
                                          height: "39.5px",
                                          width: "83.36px",
                                        }}
                                      >
                                        Update
                                      </button>
                                      <button
                                        className="btn-Secondary"
                                        style={{ marginLeft: "1%", height: "39.5px" }}
                                        onClick={(event) => {
                                          event.preventDefault();
                                          this.setState({ formErrors: {} });
                                          var status = document.getElementById('SameAddress');
                                          document.getElementById("HouseText").type = "hidden";
                                          document.getElementById("AddressInfo").reset();
                                          setTimeout(() => {
                                            var Pvalue = document.getElementById('PermanentAddress').value;
                                            var Cvalue = document.getElementById('CurrentAddress').value;

                                            if (Pvalue === Cvalue
                                            ) {


                                              const form1 = event.target.form;
                                              if (form1) {
                                                const formElements = form1.elements;
                                                for (let i = 0; i < formElements.length; i++) {
                                                  const element = formElements[i];
                                                  if (element.type === 'checkbox') {
                                                    element.checked = true;
                                                  } else {
                                                    if (element.type !== 'checkbox') {
                                                      element.value = element.defaultValue;
                                                    }
                                                    element.checked = true;
                                                    this.setState({
                                                      isReadOnly: true,
                                                    });
                                                  }
                                                }
                                                const selects = form1.querySelectorAll('select');
                                                selects.forEach(select => {
                                                  const defaultValue = select.getAttribute('data-default-value');
                                                  select.value = defaultValue;
                                                });
                                              }
                                            }

                                            else {

                                              const form = event.target.form;
                                              if (form) {
                                                const formElements = form.elements;
                                                for (let i = 0; i < formElements.length; i++) {
                                                  const element = formElements[i];
                                                  if (element.type === 'checkbox') {
                                                    element.checked = false;
                                                  } else {
                                                    if (element.type !== 'checkbox') {
                                                      element.value = element.defaultValue;
                                                    }
                                                    element.checked = false;
                                                    this.setState({
                                                      isReadOnly: false,
                                                    });
                                                  }
                                                }
                                                const selects = form.querySelectorAll('select');
                                                selects.forEach(select => {
                                                  const defaultValue = select.getAttribute('data-default-value');
                                                  select.value = defaultValue;
                                                });
                                              }
                                            }
                                          }, 10);





                                        }
                                        }
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                    <br />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <br /><br /> */}
                      </div>
                      {/* Social Media  */}
                      <div className="card">
                        <div
                          className="card-header"
                          style={{
                            backgroundColor: "darkslateblue",
                            padding: "5px",
                            color: "white",
                          }}
                        >
                          <h5
                            className="card-header-text"
                            style={{
                              marginTop: "10px",
                              marginLeft: "2%",
                              color: "white",
                            }}
                          >
                            {" "}
                            Social Media
                          </h5>
                          <button
                            id="edit-btn-media"
                            type="button"
                            style={{
                              float: "right",
                              color: "#007bff",
                              fontSize: "medium",
                              fontWeight: "800",
                            }}
                            className="button-20 sk-edit"
                            onClick={() => this.setState({ formErrors: "",ButtonDisabled: false })}
                          >
                            <i className="icofont icofont-edit" title="Edit" />
                          </button>
                        </div>
                        <div className="card-block">
                          <div id="view-media">
                            <div className="col-lg-12">
                              <div className="row">
                                <div className="col-lg-3">
                                  <b>
                                    {" "}
                                    <span> LinkedIn </span>
                                  </b>
                                </div>

                                <div className="col-lg-3">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable style={{ wordWrap: "break-word" }}>
                                        {profile.linkedIn}{" "}
                                      </lable>{" "}
                                    </>
                                  ))}
                                </div>

                                <div className="col-lg-3">
                                  <b>
                                    {" "}
                                    <span> Skype </span>
                                  </b>
                                </div>

                                <div className="col-lg-3">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable style={{ wordWrap: "break-word" }}>
                                        {profile.skype}{" "}
                                      </lable>{" "}
                                    </>
                                  ))}
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-lg-3">
                                  <b>
                                    {" "}
                                    <span> WhatsApp </span>
                                  </b>
                                </div>
                                <div className="col-lg-3">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable>
                                        {profile.whatsappNumber}
                                      </lable>{" "}
                                    </>
                                  ))}
                                </div>

                                <div className="col-lg-3">
                                  <b>
                                    {" "}
                                    <span> Facebook </span>
                                  </b>
                                </div>
                                <div className="col-lg-3">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable style={{ wordWrap: "break-word" }}>
                                        {profile.faceBook}{" "}
                                      </lable>{" "}
                                    </>
                                  ))}
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-lg-3">
                                  <b>
                                    {" "}
                                    <span> Twitter </span>
                                  </b>
                                </div>
                                <div className="col-lg-3">
                                  {profile.map((profile) => (
                                    <>
                                      {" "}
                                      <lable style={{ wordWrap: "break-word" }}>
                                        {profile.twitter}
                                      </lable>{" "}
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="edit-media">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="general-info">
                                <form
                                  autoComplete="off"
                                  onSubmit={this.SocialEdit}
                                  id="SocialInfo"
                                >
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <div className="row">
                                        <div className="col-lg-4">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              type="text"
                                              name="LinkedIn"
                                              defaultValue={profile.linkedIn}
                                              // onChange={this.handleSocailchange}
                                              placeholder="LinkedIn "
                                              id="linkedIn"
                                            />
                                          ))}
                                          {/* <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            LinkedIn
                                          </span> */}
                                          {!linkedInErr ? <span style={{ color: '#a29d9d', float: "left" }}>LinkedIn</span> :
                                            <div style={{ color: "red" }}>{linkedInErr}</div>}
                                        </div>
                                        <br />
                                        <div className="col-lg-4">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              type="text"
                                              name="Skype"
                                              defaultValue={profile.skype}
                                              // onChange={this.handleSocailchange}
                                              placeholder="Skype "
                                              id="skype"
                                            />
                                          ))}
                                          {/* <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Skype
                                          </span> */}
                                          {!SkypeErr ? <span style={{ color: '#a29d9d', float: "left" }}>Skype</span> :
                                            <div style={{ color: "red" }}>{SkypeErr}</div>}
                                        </div>{" "}
                                        <br />
                                        <div className="col-lg-4">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              type="text"
                                              name="Whatsapp"
                                              onFocus={(e) => e.target.select()}
                                              maxLength="10"
                                              defaultValue={
                                                profile.whatsappNumber
                                              }
                                              onKeyPress={(event) => {
                                                const isLetter = /[a-zA-Z]/i.test(event.key);
                                                if (isLetter) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onInputCapture={(event) => {
                                                if (!/^[0-9]*$/.test(event.target.value)) {
                                                  event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                                }
                                              }}
                                              placeholder="-"
                                              onChange={this.handleSocailchange}
                                              id="Whatsapp"
                                            />
                                          ))}
                                          {/* <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Whatsapp{" "}
                                          </span>
                                          <br /> */}
                                          {!WhatsappErr ? <span style={{ color: '#a29d9d', float: "left" }}>WhatsApp </span> :
                                            <div style={{ color: "red" }}>{WhatsappErr}</div>}
                                        </div>
                                      </div>

                                      <div className="row">
                                        <div className="col-lg-4">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              type="text"
                                              name="facebook"
                                              onFocus={(e) => e.target.select()}
                                              defaultValue={profile.faceBook}
                                              // onChange={this.handleSocailchange}
                                              placeholder="FaceBook"
                                              id='facebk'
                                            />
                                          ))}
                                          {/* <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Facebook
                                          </span>
                                        </div>
                                        <br /> */}

                                          {!FacebookErr ? <span style={{ color: '#a29d9d', float: "left" }}>Facebook</span> :
                                            <div style={{ color: "red" }}>{FacebookErr}</div>}
                                        </div>
                                        <div className="col-lg-4">
                                          {profile.map((profile) => (
                                            <input
                                              className="effect-16"
                                              onFocus={(e) => e.target.select()}
                                              type="text"
                                              // onChange={this.handleSocailchange}
                                              name="Twitter"
                                              defaultValue={profile.twitter}
                                              placeholder="Twitter"
                                              id="twitter"
                                            />
                                          ))}
                                          {/* <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Twitter
                                          </span> */}
                                          {!TwitterErr ? <span style={{ color: '#a29d9d', float: "left" }}>Twitter</span> :
                                            <div style={{ color: "red" }}>{TwitterErr}</div>}
                                        </div>
                                        <br />
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <br />
                                    <div className="center">
                                      <button
                                        type="submit"
                                        disabled={this.state.ButtonDisabled}
                                        className="but"
                                        style={{
                                          marginTop: "-0.1%",
                                          height: "38.5px",
                                          width: "83.36px",
                                        }}
                                      >
                                        Update
                                      </button>
                                      <button
                                        className="btn-Secondary"
                                        style={{ marginLeft: "1%", height: "39.5px" }}
                                        onClick={(event) => {
                                          event.preventDefault();
                                          this.Getvalue();
                                          const status = document.getElementById('SameAddress');
                                          const form = event.target.form;
                                          if (form) {
                                            const formElements = form.elements;
                                            for (let i = 0; i < formElements.length; i++) {
                                              const element = formElements[i];
                                              if (element.type !== 'checkbox') {
                                                element.value = element.defaultValue;
                                              }
                                            }
                                            const selects = form.querySelectorAll('select');
                                            selects.forEach(select => {
                                              const defaultValue = select.getAttribute('data-default-value');
                                              select.value = defaultValue;
                                            });
                                          }
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                  <br />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="tabs-section-9" className="j-tabs-section">
                      <span>Please fill all the mandatory fields to follow the steps
                        <span style={{
                          marginTop: '-4px',
                          color: 'red',
                          fontSize: '25px',
                          position: 'absolute',
                          marginLeft: '4px',
                          fontWeight: 'bold'

                        }}>*</span> </span>
                      <div className="tab-pane" id="notes" role="tabpanel">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{
                              backgroundColor: "darkslateblue",
                              padding: "5px",
                              color: "white",
                            }}
                          >
                            <h5
                              className="card-header-text"
                              style={{
                                marginTop: "10px",
                                marginLeft: "2%",
                                color: "white",
                              }}
                            >
                              {" "}
                              Banking <span style={{ color: "red", marginLeft: "83px", marginTop: "-18px", fontSize: "22px" }}>
                                *
                              </span>
                            </h5>
                            <button
                              id="edit-btn-Bank"
                              type="button"
                              style={{
                                float: "right",
                                color: "#007bff",
                                fontSize: "medium",
                                fontWeight: "800",
                              }}
                              className="button-20 sk-edit"
                              onClick={() => this.setState({ formErrors: "", ButtonDisabled: false })}
                            >
                              <i
                                className="icofont icofont-edit"
                                title="Edit"
                              />
                            </button>
                          </div>

                          <div className="card-block">
                            <div id="view-BankDetails">
                              <div className="col-lg-12">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b>
                                      {" "}
                                      <span> Account Number </span>
                                      <span style={{ color: "red" }}>*</span>
                                    </b>
                                  </div>
                                  <div className="col-lg-3">
                                    {Bankdetail.map((Bankdetail) => (
                                      <>
                                        {" "}
                                        <lable>{Bankdetail.acNo}</lable>{" "}
                                      </>
                                    ))}
                                  </div>
                                  <div className="col-lg-3">
                                    <b>
                                      {" "}
                                      <span> IFSC Code </span>
                                      <span style={{ color: "red" }}>*</span>
                                    </b>
                                  </div>
                                  <div className="col-lg-3">
                                    {Bankdetail.map((Bankdetail) => (
                                      <>
                                        {" "}
                                        <lable>{Bankdetail.ifsc} </lable>{" "}
                                      </>
                                    ))}
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b>
                                      {" "}
                                      <span> Account Type </span>
                                      <span style={{ color: "red" }}>*</span>
                                    </b>
                                  </div>
                                  <div className="col-lg-3">
                                    {Bankdetail.map((Bankdetail) => (
                                      <>
                                        {" "}
                                        <lable>{Bankdetail.acType}</lable>{" "}
                                      </>
                                    ))}
                                  </div>
                                  <div className="col-lg-3">
                                    <b>
                                      {" "}
                                      <span> Branch Name </span>
                                      <span style={{ color: "red" }}>*</span>
                                    </b>
                                  </div>
                                  <div className="col-lg-3">
                                    {Bankdetail.map((Bankdetail) => (
                                      <>
                                        {" "}
                                        <lable>
                                          {Bankdetail.branchname}
                                        </lable>{" "}
                                      </>
                                    ))}
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-lg-3">
                                    <b>
                                      {" "}
                                      <span>Phone Number</span>
                                    </b>
                                  </div>
                                  <div className="col-lg-3">
                                    {Bankdetail.map((Bankdetail) => (
                                      <>
                                        {" "}
                                        <lable>{Bankdetail.phoneNo}</lable>{" "}
                                      </>
                                    ))}
                                  </div>
                                  <div className="col-lg-3">
                                    <b>
                                      {" "}
                                      <span>Address</span>
                                    </b>
                                  </div>
                                  <div className="col-lg-3">
                                    {Bankdetail.map((Bankdetail) => (
                                      <>
                                        {" "}
                                        <lable
                                          style={{ wordWrap: "break-word" }}
                                        >
                                          {Bankdetail.branchaddress}{" "}
                                        </lable>{" "}
                                      </>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              {/* <br/> */}
                            </div>
                          </div>
                          <div id="Edit-BankDetails">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-12">
                                  <form
                                    autoComplete="off"
                                    onSubmit={this.EditBankDetails}
                                    id="BankingForm"
                                  >
                                    <div
                                      className="container"
                                      style={{ maxWidth: "765px" }}
                                    >
                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        <div className="col-sm-6">
                                          {Bankdetail.map((Bankdetail) => (
                                            <input
                                              className="form"
                                              maxLength="20"
                                              onFocus={(e) => e.target.select()}
                                              defaultValue={Bankdetail.acNo}
                                              id="AcNo"
                                              name="AcNo"
                                              type="text"
                                              onChange={this.handlebankchange}
                                              placeholder="Account Number"
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                          ))}
                                          {/* <span style={{ color: '#a29d9d', float: "left" }}>Account Number</span> */}
                                          {!AcNo ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Account Number
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {AcNo}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>
                                        <div className="col-sm-6">
                                          {Bankdetail.map((Bankdetail) => (
                                            <input
                                              className="form"
                                              maxLength="20"
                                              onFocus={(e) => e.target.select()}
                                              defaultValue={Bankdetail.acName}
                                              id="confirmAcNo"
                                              name="AcName"
                                              onCopy={(e) => e.preventDefault()}
                                              onPaste={(e) => e.preventDefault()}
                                              type="text"
                                              onChange={this.handlebankchange}
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                          ))}
                                          {/* <span style={{ color: '#a29d9d', float: "left" }}>Confirm Account Number</span> */}
                                          {!AcName ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Confirm Account Number
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {AcName}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>
                                      </div>
                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        <div className="col-sm-6">
                                          {Bankdetail.map((Bankdetail) => (
                                            <input
                                              className="form"
                                              maxLength="20"
                                              onFocus={(e) => e.target.select()}
                                              defaultValue={Bankdetail.ifsc}
                                              id="IFSC"
                                              name="IFSC"
                                              type="text"
                                              onChange={this.handlebankchange}
                                              placeholder="IFSC Code"
                                            />
                                          ))}
                                          {/* <span style={{ color: '#a29d9d', float: "left" }}>IFSC Code</span> */}
                                          {!IFSC ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              IFSC Code
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {IFSC}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          {Bankdetail.map((Bankdetail) => (
                                            <select
                                              className="effect-16"
                                              id="AcType"
                                              defaultValue={Bankdetail.acType}
                                              name="AcType"
                                              onChange={this.handlebankchange}
                                              type="text"
                                              style={{
                                                // Apply the marginLeft style only if the browser is Chrome
                                                ...(isChrome && { marginLeft: '-3px' })
                                              }}
                                            >
                                              <option hidden>{Bankdetail.acType}</option>
                                              <option Value="">Select</option>
                                              <option value="Savings Account">
                                                {" "}
                                                Savings Account
                                              </option>
                                              <option value="Current Account">
                                                {" "}
                                                Current Account
                                              </option>
                                            </select>
                                          ))}
                                          {/* <span style={{ color: '#a29d9d', float: "left" }}>Account Type</span> */}
                                          {!AcType ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Account Type
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {AcType}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>
                                      </div>

                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        <div className="col-sm-6">
                                          {Bankdetail.map((Bankdetail) => (
                                            <input
                                              className="form"
                                              maxLength="20"
                                              onFocus={(e) => e.target.select()}
                                              defaultValue={
                                                Bankdetail.branchname
                                              }
                                              id="Branchname"
                                              name="Branchname"
                                              type="text"
                                              onChange={this.handlebankchange}
                                              placeholder="Bank Branch Name"
                                            />
                                          ))}
                                          {/* <span style={{ color: '#a29d9d', float: "left" }}>Branch Name</span> */}
                                          {!Branchname ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Branch Name
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {Branchname}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>

                                        <div className="col-sm-6">
                                          {Bankdetail.map((Bankdetail) => (
                                            <input
                                              className="form"
                                              maxLength="10"
                                              onFocus={(e) => e.target.select()}
                                              onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              defaultValue={Bankdetail.phoneNo}
                                              id="PhoneNo"
                                              name="PhoneNo"
                                              type="text"
                                              onChange={this.handlebankchange}
                                              placeholder="Phone Number"
                                            />
                                          ))}
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Phone Number
                                          </span>
                                          {/* {!PhoneNo ? <span style={{ color: '#a29d9d', float: "left" }}>Phone Number<span style={{ color: "red" }}>*</span></span> : <div style={{ color: "red" }}>{PhoneNo}</div>} */}
                                          <span className="underline" />
                                        </div>
                                      </div>
                                      <div className="col-sm-6">
                                        {Bankdetail.map((Bankdetail) => (
                                          <textarea
                                            className="form"
                                            onFocus={(e) => e.target.select()}
                                            defaultValue={
                                              Bankdetail.branchaddress
                                            }
                                            id="Branchaddress"
                                            name="Branchaddress"
                                            type="text"
                                            onChange={this.handlebankchange}
                                            placeholder="Branch Address"
                                          />
                                        ))}
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          {" "}
                                          Address
                                        </span>
                                        {/* {!Branchaddress ? <span style={{ color: '#a29d9d', float: "left" }}> Address<span style={{ color: "red" }}>*</span></span> : <div style={{ color: "red" }}>{Branchaddress}</div>} */}
                                        <span className="underline" />
                                      </div>

                                      <div
                                        className="col-sm-6"
                                        style={{ display: "none" }}
                                      >
                                        {Bankdetail.map((Bankdetail) => (
                                          <input
                                            className="form"
                                            onFocus={(e) => e.target.select()}
                                            maxLength="20"
                                            defaultValue={Bankdetail.bankname}
                                            id="Bankname"
                                            name="Bankname"
                                            type="text"
                                            onChange={this.handlebankchange}
                                            placeholder="Bank Name"
                                          />
                                        ))}
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Bank Name
                                        </span>
                                        {/* {!Bankname ? <span style={{ color: '#a29d9d', float: "left" }}>Bank Name<span style={{ color: "red" }}>*</span></span> : <div style={{ color: "red" }}>{Bankname}</div>} */}
                                        <span className="underline" />
                                      </div>

                                      <div className="col-lg-12 center">
                                        <button
                                          type="submit"
                                          disabled={this.state.ButtonDisabled}
                                          className="but"
                                          style={{
                                            height: "39.5px",
                                            width: "83.36px",
                                          }}
                                        >
                                          Update
                                        </button>
                                        <button
                                          type="reset"
                                          id="Close_education"
                                          className=" btn-Secondary"
                                          style={{
                                            marginLeft: "1%",
                                            height: "39.5px",
                                          }}
                                          onClick={() =>
                                            this.setState({ formErrors: "" })
                                          }
                                        >
                                          Cancel
                                        </button>
                                      </div>

                                      <br />
                                    </div>
                                  </form>
                                </div>
                              </div>

                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id="tabs-section-2" className="j-tabs-section">
                      <span>Please fill all the mandatory fields to follow the steps
                        <span style={{
                          marginTop: '-4px',
                          color: 'red',
                          fontSize: '25px',
                          position: 'absolute',
                          marginLeft: '4px',
                          fontWeight: 'bold'

                        }}>*</span> </span>
                      <div className="card">
                        <div
                          className="card-header"
                          style={{
                            backgroundColor: "darkslateblue",
                            padding: "5px",
                            color: "white",
                          }}
                        >
                          <h5
                            className="card-header-text"
                            style={{
                              marginTop: "10px",
                              marginLeft: "2%",
                              color: "white",
                            }}
                          >
                            {" "}
                            Education <span style={{ color: "red", marginLeft: "98px", marginTop: "-18px", fontSize: "22px" }}>
                              *
                            </span>
                          </h5>
                          <button
                            style={{
                              float: "right",
                              color: "#007bff",
                              fontSize: "medium",
                              fontWeight: "800",
                            }}
                            className="button-20 sk-edit"
                            id="addEduBtn"
                            title="Add"
                            type="button"
                            // style={{ fontSize: "large" }}

                            // onClick={() => {
                            //   this.setState((prevState) => ({
                            //     openedu: !prevState.openedu,
                            //   }));
                            //   this.formreset();
                            // }}

                            onClick={() => {
                              this.setState((prevState) => ({
                                openedu: !prevState.openedu,
                                showing: prevState.clickCount % 2 === 0 ? false : true, // Toggle showing based on the number of clicks
                                clickCount: prevState.clickCount + 1, // Increment the click count
                              }), () => {
                                if (this.state.clickCount % 2 === 1) {
                                  this.formreset();
                                }
                              });
                            }}
                          >
                            {" "}
                            {openedu ? "×" : "+"}{" "}
                          </button>
                        </div>
                        {
                          openedu ? (
                            <div id="AddEDU">
                              <form id="EduForm" onSubmit={this.EduSubmit}>
                                <div className="card-block">
                                  <div className="row">
                                    <div className="col-xl-4">
                                      <select
                                        className="effect-16"
                                        id="Qualification"
                                        name="QualificationType"
                                        onChange={(e) => {
                                          this.handleEducationChange(e);
                                          this.handlevalueChange(e);
                                        }}
                                        style={{
                                          // Apply the marginLeft style only if the browser is Chrome
                                          ...(isChrome && { marginLeft: '-3px' })
                                        }}
                                      >
                                        <option value="">Select</option>
                                        {EducationDrop.map((EducationDrop) => (
                                          <option
                                            value={
                                              EducationDrop.qualificationType
                                            }
                                          >
                                            {EducationDrop.qualificationType}
                                          </option>
                                        ))}
                                        <option value="Others">Others</option>
                                      </select>
                                      <br />
                                      {!Qualification ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Qualification Type
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {Qualification}
                                        </div>
                                      )}
                                    </div>

                                    <div className="col-xl-4">
                                      <input
                                        className="effect-16"
                                        onFocus={(e) => e.target.select()}
                                        list="browsers"
                                        onChange={this.handleEducationChange}
                                        maxLength="40"
                                        type="text"
                                        id="Coursename"
                                        name="Coursename"
                                      />
                                      <br />
                                      {!Coursename ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Course Name
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {Coursename}
                                        </div>
                                      )}
                                      <datalist id="browsers">
                                        {EducationCourseName.map(
                                          (EducationCourseName) => (
                                            <option
                                              value={
                                                EducationCourseName.coursename
                                              }
                                            ></option>
                                          )
                                        )}
                                      </datalist>
                                    </div>
                                    <div className="col-xl-4">
                                      <input
                                        className="effect-16"
                                        onFocus={(e) => e.target.select()}
                                        onChange={this.handleEducationChange}
                                        maxLength="100"
                                        type="text"
                                        id="stream"
                                        name="Stream"
                                      />
                                      <br />{" "}
                                     
                                      {!stream ? (
                                      <span
                                        style={{
                                          color: "#a29d9d",
                                          float: "left",
                                        }}
                                      >
                                        Specialization
                                      </span>
                                    ) : (
                                      <div style={{ color: "red" }}>
                                        {stream}
                                      </div>
                                    )}
                                    </div>

                                    <div className="col-xl-4">
                                      <select
                                        className="effect-16"
                                        onChange={this.handleEducationChange}
                                        type="text"
                                        id="Coursetype"
                                        name="Coursetype"
                                        style={{
                                          // Apply the marginLeft style only if the browser is Chrome
                                          ...(isChrome && { marginLeft: '-3px' })
                                        }}
                                      >
                                        <option value=""> Select</option>
                                        <option value="Part Time">
                                          Part Time
                                        </option>
                                        <option value="Full Time">
                                          Full Time
                                        </option>
                                        <option value="Full Time">
                                          Distance Education
                                        </option>
                                      </select>
                                      <br />{" "}
                                      {!Coursetype ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Type
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {Coursetype}
                                        </div>
                                      )}
                                    </div>

                                    <div className="col-xl-4">
                                      <input
                                        className="effect-16"
                                        onFocus={(e) =>
                                          (e.target.type = "month")
                                        }
                                        onChange={this.handleEducationChange}
                                        max={new Date(
                                          new Date().getFullYear(),
                                          new Date().getMonth() + 1
                                        )
                                          .toJSON()
                                          .slice(0, 7)}
                                        min={new Date(
                                          new Date().getFullYear() - 60,
                                          new Date().getMonth() + 1
                                        )
                                          .toJSON()
                                          .slice(0, 7)}
                                        type="text"
                                        id="St_date"
                                        name="CourseStartDate"
                                      />
                                      <br />
                                      {!St_date ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Start From
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {St_date}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-xl-4">
                                      <input
                                        className="effect-16"
                                        max={new Date(
                                          new Date().getFullYear(),
                                          new Date().getMonth() + 1
                                        )
                                          .toJSON()
                                          .slice(0, 7)}
                                        min={new Date(
                                          new Date().getFullYear() - 60,
                                          new Date().getMonth() + 1
                                        )
                                          .toJSON()
                                          .slice(0, 7)}
                                        onFocus={(e) =>
                                          (e.target.type = "month")
                                        }
                                        onChange={this.handleEducationChange}
                                        type="text"
                                        id="End_date"
                                        name="CourseEndDate"
                                      />
                                      <br />{" "}
                                      {!End_date ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          End
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {End_date}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-xl-4">
                                      <input
                                        className="effect-16"
                                        onFocus={(e) => e.target.select()}
                                        onChange={this.handleEducationChange}
                                        maxLength="80"
                                        type="text"
                                        id="unisty_name"
                                        name="UniversityName"
                                      />
                                      <br />{" "}
                                      {!unisty_name ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          University Name
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {unisty_name}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-xl-4">
                                      <input
                                        className="effect-16"
                                        onFocus={(e) => e.target.select()}
                                        onChange={this.handleEducationChange}
                                        maxLength="80"
                                        type="text"
                                        id="clg_name"
                                        name="CollegeName"
                                      />
                                      <br />
                                      {!clg_name ? <span style={{ color: '#a29d9d', float: "left" }}>College Name</span> : <div style={{ color: "red" }}>{clg_name}</div>}

                                    </div>
                                    <br /> <br /> <br /> <br />
                                    <div className="col-lg-12 center">
                                      <button
                                        type="submit"
                                        className="but"
                                        style={{
                                          height: "39.5px",
                                          width: "83.36px",
                                        }}
                                        disabled={this.state.isButtonDisabledA}
                                      >
                                        Save
                                      </button >
                                      <button
                                        type="reset"
                                        id="Close_education"
                                        className=" btn-Secondary"
                                        style={{
                                          marginLeft: "1%",
                                          height: "39.5px",
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </form>
                            </div>
                          ) : (
                            <div id="AddEDU" style={{ display: "none" }}>
                              <form id="EduForm" onSubmit={this.EduSubmit}>
                                <div className="card-block">
                                  <div className="row">
                                    <div className="col-xl-4">
                                      <select
                                        className="effect-16"
                                        id="Qualification"
                                        name="QualificationType"
                                        onChange={(e) => {
                                          this.handleEducationChange(e);
                                          this.handlevalueChange(e);
                                        }}
                                        style={{
                                          // Apply the marginLeft style only if the browser is Chrome
                                          ...(isChrome && { marginLeft: '-3px' })
                                        }}
                                      >
                                        <option value="">Select</option>
                                        {EducationDrop.map((EducationDrop) => (
                                          <option
                                            value={
                                              EducationDrop.qualificationType
                                            }
                                          >
                                            {EducationDrop.qualificationType}
                                          </option>
                                        ))}
                                        <option value="">Others</option>
                                      </select>
                                      <br />
                                      {!Qualification ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Qualification Type
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {Qualification}
                                        </div>
                                      )}
                                    </div>

                                    <div className="col-xl-4">
                                      <input
                                        className="effect-16"
                                        onFocus={(e) => e.target.select()}
                                        list="browsers"
                                        onChange={this.handleEducationChange}
                                        maxLength="40"
                                        type="text"
                                        id="Coursename"
                                        name="Coursename"
                                      />
                                      <br />
                                      {!Coursename ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Course Name
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {Coursename}
                                        </div>
                                      )}
                                      <datalist id="browsers">
                                        {EducationCourseName.map(
                                          (EducationCourseName) => (
                                            <option
                                              value={
                                                EducationCourseName.coursename
                                              }
                                            ></option>
                                          )
                                        )}
                                      </datalist>
                                    </div>
                                    <div className="col-xl-4">
                                      <input
                                        className="effect-16"
                                        onFocus={(e) => e.target.select()}
                                        onChange={this.handleEducationChange}
                                        maxLength="100"
                                        type="text"
                                        id="stream"
                                        name="Stream"
                                      />
                                      <br />{" "}
                                      <span
                                        style={{
                                          color: "#a29d9d",
                                          float: "left",
                                        }}
                                      >
                                        Specialization
                                      </span>
                                      {/* {!stream ? (
                                      <span
                                        style={{
                                          color: "#a29d9d",
                                          float: "left",
                                        }}
                                      >
                                        Specialization
                                        <span style={{ color: "red" }}>
                                          *
                                        </span>
                                      </span>
                                    ) : (
                                      <div style={{ color: "red" }}>
                                        {stream}
                                      </div>
                                    )} */}
                                    </div>

                                    <div className="col-xl-4">
                                      <select
                                        className="effect-16"
                                        onChange={this.handleEducationChange}
                                        type="text"
                                        id="Coursetype"
                                        name="Coursetype"
                                        style={{
                                          // Apply the marginLeft style only if the browser is Chrome
                                          ...(isChrome && { marginLeft: '-3px' })
                                        }}
                                      >
                                        <option value=""> Select</option>
                                        <option value="Part Time">
                                          Part Time
                                        </option>
                                        <option value="Full Time">
                                          Full Time
                                        </option>
                                        <option value="Full Time">
                                          Distance Education
                                        </option>
                                      </select>
                                      <br />{" "}
                                      {!Coursetype ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Type
                                          <span style={{ color: "red" }}>
                                            *
                                          </span>
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {Coursetype}
                                        </div>
                                      )}
                                    </div>

                                    <div className="col-xl-4">
                                      <input
                                        className="effect-16"
                                        onFocus={(e) =>
                                          (e.target.type = "month")
                                        }
                                        onChange={this.handleEducationChange}
                                        max={new Date(
                                          new Date().getFullYear(),
                                          new Date().getMonth() + 1
                                        )
                                          .toJSON()
                                          .slice(0, 7)}
                                        min={new Date(
                                          new Date().getFullYear() - 60,
                                          new Date().getMonth() + 1
                                        )
                                          .toJSON()
                                          .slice(0, 7)}
                                        type="text"
                                        id="St_date"
                                        name="CourseStartDate"
                                      />
                                      <br />
                                      {!St_date ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Start From
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {St_date}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-xl-4">
                                      <input
                                        className="effect-16"
                                        max={new Date(
                                          new Date().getFullYear(),
                                          new Date().getMonth() + 1
                                        )
                                          .toJSON()
                                          .slice(0, 7)}
                                        min={new Date(
                                          new Date().getFullYear() - 60,
                                          new Date().getMonth() + 1
                                        )
                                          .toJSON()
                                          .slice(0, 7)}
                                        onFocus={(e) =>
                                          (e.target.type = "month")
                                        }
                                        onChange={this.handleEducationChange}
                                        type="text"
                                        id="End_date"
                                        name="CourseEndDate"
                                      />
                                      <br />{" "}
                                      {!End_date ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          End From
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {End_date}
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-xl-4">
                                      <input
                                        className="effect-16"
                                        onFocus={(e) => e.target.select()}
                                        onChange={this.handleEducationChange}
                                        maxLength="40"
                                        type="text"
                                        id="unisty_name"
                                        name="UniversityName"
                                      />
                                      <br />{" "}
                                      {!unisty_name ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          University Name
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {unisty_name}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-xl-4">
                                      <input
                                        className="effect-16"
                                        onFocus={(e) => e.target.select()}
                                        onChange={this.handleEducationChange}
                                        maxLength="40"
                                        type="text"
                                        id="clg_name"
                                        name="CollegeName"
                                      />
                                      <br />
                                      <span
                                        style={{
                                          color: "#a29d9d",
                                          float: "left",
                                        }}
                                      >
                                        College Name
                                      </span>
                                      {/* {!clg_name ? <span style={{ color: '#a29d9d', float: "left" }}>College Name</span> : <div style={{ color: "red" }}>{clg_name}</div>} */}
                                    </div>
                                    <br /> <br />
                                    <div className="col-lg-12 center">
                                      <br /> <br />
                                      <button
                                        type="submit"
                                        className="but"
                                        style={{
                                          height: "39.5px",
                                          width: "83.36px",
                                        }}

                                      >
                                        Save
                                      </button>
                                      <button
                                        type="reset"
                                        id="Close_education"
                                        className=" btn-Secondary"
                                        style={{
                                          marginLeft: "1%",
                                          height: "39.5px",
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                    <br />
                                    <br />
                                  </div>
                                </div>
                              </form>
                            </div>
                          )

                          // cdcdcdcdcdc
                        }
                      </div>
                      {showing ?
                        <div className="card">
                          <div className="dt-responsive table-responsive">
                            {EducationDetail.length !== 0 ? (
                              <table
                                id="add-row-table"
                                className="table  nowrap "
                              >
                                <thead>
                                  <tr>
                                    <th>Qualification Type</th>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Stream</th>
                                    <th>Start From</th>
                                    <th>End From</th>
                                    <th>College Name</th>
                                    <th>University Name</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {EducationDetail.map((EducationDetail) => (
                                    <tr>
                                      <td>{EducationDetail.qualificationType}</td>
                                      <td>{EducationDetail.courseName}</td>
                                      <td>{EducationDetail.courseType} </td>
                                      <td>{EducationDetail.stream} </td>
                                      <td>
                                        {this.formatted(
                                          EducationDetail.courseStartDate
                                        )}
                                      </td>
                                      <td>
                                        {this.formatted(
                                          EducationDetail.courseEndDate
                                        )}
                                      </td>
                                      <td>{EducationDetail.collegeName}</td>
                                      <td>{EducationDetail.universityName}</td>
                                      <td>
                                        {/* <img alt="edit" src="https://img.icons8.com/android/22/000000/pencil.png" onClick={() => this.UserEduModel(EducationDetail.id, EducationDetail.qualificationType,
                                      EducationDetail.courseName, EducationDetail.courseType, EducationDetail.stream, EducationDetail.courseStartDate, EducationDetail.courseEndDate
                                      , EducationDetail.collegeName, EducationDetail.universityName)} />    &nbsp;&nbsp;&nbsp; */}
                                        <button
                                          className="button-30 sk-edit"
                                          name="Edit"
                                          title="Delete"
                                          onClick={() =>
                                            this.DelUserEducation(
                                              EducationDetail.created_Date,
                                              EducationDetail.user_Id
                                            )
                                          }
                                          style={{ marginLeft: "-19px" }}
                                        >
                                          <i
                                            className="fa fa-minus"
                                            style={{ color: "red" }}
                                          ></i>
                                        </button>{" "}
                                        &nbsp;&nbsp;
                                        <button
                                          className="button-30 sk-edit"
                                          name="Edit"
                                          title="Edit"
                                          onClick={() =>
                                            this.UserEduModel(
                                              EducationDetail.id,
                                              EducationDetail.qualificationType,
                                              EducationDetail.courseName,
                                              EducationDetail.courseType,
                                              EducationDetail.stream,
                                              EducationDetail.courseStartDate,
                                              EducationDetail.courseEndDate,
                                              EducationDetail.universityName,
                                              EducationDetail.collegeName,
                                              EducationDetail.created_Date
                                            )
                                          }
                                        >

                                          <i
                                            className="fa fa-edit"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : null}{" "}
                          </div>
                        </div> : null}
                    </div>

                    <div id="tabs-section-3" className="j-tabs-section">
                      <span>Please fill all the mandatory fields to follow the steps
                        <span style={{
                          marginTop: '-4px',
                          color: 'red',
                          fontSize: '25px',
                          position: 'absolute',
                          marginLeft: '4px',
                          fontWeight: 'bold'

                        }}>*</span> </span>
                      <div className="tab-pane" id="contacts" role="tabpanel">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{
                              backgroundColor: "darkslateblue",
                              padding: "5px",
                              color: "white",
                            }}
                          >
                            <h5
                              className="card-header-text"
                              style={{
                                marginTop: "10px",
                                marginLeft: "2%",
                                color: "white",
                              }}
                            >
                              {" "}
                              Skills <span style={{ color: "red", marginLeft: "61px", marginTop: "-18px", fontSize: "22px" }}>
                                *
                              </span>
                            </h5>
                            <button
                              style={{
                                float: "right",
                                color: "#007bff",
                                fontSize: "medium",
                                fontWeight: "800",
                              }}
                              className="button-20 sk-edit"
                              id="addSkillBtn"
                              title="Add"
                              type="button"
                              // onClick={() => {
                              //   this.setState((prevState) => ({
                              //     openskill: !prevState.openskill,
                              //   }));
                              //   this.skillformreset();
                              // }}
                              onClick={() => {
                                this.setState((prevState) => ({
                                  openskill: !prevState.openskill,
                                  showing1: prevState.clickCount % 2 === 0 ? false : true, // Toggle showing based on the number of clicks
                                  clickCount: prevState.clickCount + 1, // Increment the click count
                                }), () => {
                                  if (this.state.clickCount % 2 === 1) {
                                    this.skillformreset();
                                  }
                                });
                              }}
                            >
                              {" "}
                              {openskill ? "×" : "+"}{" "}
                            </button>
                          </div>
                          {this.state.openskill ? (


                            <div className="container">
                              <div className="row">
                                <div className="col-lg-12">
                                  <form
                                    autoComplete="off"
                                    onSubmit={this.Skillsubmit}
                                    id="skillform"
                                  >
                                    <div
                                      className="container"
                                      style={{ maxWidth: "765px" }}
                                    >
                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        <div className="col-sm-6">
                                          <select
                                            className="effect-16"
                                            name="SkillName"
                                            onChange={this.handleSkillChange}
                                            type="text"
                                            placeholder="SkillName"
                                            id="skillname"
                                            style={{
                                              // Apply the marginLeft style only if the browser is Chrome
                                              ...(isChrome && { marginLeft: '-3px' })
                                            }}

                                          >
                                            <option value="">Name</option>
                                            {SKillAdminNameDrop.map(
                                              (SKillAdminNameDrop) => (
                                                <option
                                                  value={
                                                    SKillAdminNameDrop.skillName
                                                  }
                                                >
                                                  {SKillAdminNameDrop.skillName}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          {!SkillNameErr ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",

                                              }}
                                            >
                                              Name{" "}
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {SkillNameErr}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>
                                        <div className="col-sm-6">
                                          <input
                                            className="form"
                                            onFocus={(e) => e.target.select()}
                                            id="exp"
                                            name="Experience"
                                            type="text"
                                            onFocus={(e) =>
                                              (e.target.type = "date")
                                            }
                                            onChange={this.handleSkillChange}
                                            max={this.state.maxDate}
                                          />
                                          {!ExperienceErr ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Acquired From
                                              <span style={{ color: "red" }}>
                                                *
                                              </span>
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {ExperienceErr}
                                            </div>
                                          )}
                                          <span className="underline" />
                                        </div>
                                      </div>
                                      <div
                                        className="row"
                                        style={{ padding: "8px" }}
                                      >
                                        <div className="col-sm-6">
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                            }}
                                          >
                                            I want to be a Trainer
                                          </span>
                                          {""}&nbsp;&nbsp;&nbsp;

                                          <label>
                                            <label className="radio1">
                                              <input
                                                name="Trainer"
                                                id="yes"
                                                type="radio"
                                                checked={
                                                  this.state.checkbox1Checked
                                                }
                                                onChange={() =>
                                                  this.handleCheckboxChange(1)
                                                }
                                              />
                                              <span>Yes</span>
                                              &nbsp;&nbsp;&nbsp;
                                              <input
                                                id="no"
                                                type="radio"
                                                checked={
                                                  this.state.checkbox2Checked
                                                }
                                                onChange={() =>
                                                  this.handleCheckboxChange(2)
                                                }
                                              />
                                              <span>No</span>
                                            </label>
                                          </label>
                                          <span className="underline" />
                                        </div>



                                        <div className="col-sm-6">
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              // marginLeft: "26%",
                                              // marginTop: "52px",
                                            }}
                                          >
                                            {" "}
                                            Certificate Details
                                          </span>{" "}
                                          &nbsp;&nbsp;&nbsp;
                                          <label className="radio1">
                                            <input
                                              type="radio"
                                              onFocus={(e) => e.target.select()}
                                              id="UploadCertificate"
                                              onInputCapture={
                                                this.HandleSkillCertificate
                                              }
                                              name="UploadCertificate"
                                            />
                                            <span>Yes</span>
                                            &nbsp;&nbsp;&nbsp;
                                            <input
                                              type="radio"
                                              onFocus={(e) => e.target.select()}
                                              id="UploadCertificate"
                                              onInputCapture={
                                                this.HandleSkillCertificate
                                              }
                                              name="UploadCertificate"
                                            />
                                            <span>No</span>
                                          </label>

                                          <span className="underline" />
                                        </div>
                                      </div>


                                      {UploadCertificate === true ? (
                                        <>
                                          <div
                                            className="row"
                                            style={{ padding: "8px" }}
                                          >

                                            <div className="col-sm-6">
                                              <input
                                                className="form"
                                                onFocus={(e) => e.target.select()}
                                                name="SkillCoursename"
                                                type="text"
                                                id="skillcoursename"
                                                onChange={
                                                  this.hanhandleSkillChangedleChange
                                                }
                                              />
                                              {!skillcoursenameErr ? (
                                                <span
                                                  style={{
                                                    color: "#a29d9d",
                                                    float: "left",
                                                  }}
                                                >
                                                  Course Name
                                                  <span style={{ color: "red" }}>
                                                    *
                                                  </span>
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {skillcoursenameErr}
                                                </div>
                                              )}
                                              <span className="underline" />
                                            </div>

                                            <div className="col-sm-6">
                                              <input
                                                className="form"
                                                onFocus={(e) => e.target.select()}
                                                max={new Date(
                                                  new Date().getFullYear(),
                                                  new Date().getMonth() + 1
                                                )
                                                  .toJSON()
                                                  .slice(0, 7)}
                                                min={new Date(
                                                  new Date().getFullYear() - 60,
                                                  new Date().getMonth() + 1
                                                )
                                                  .toJSON()
                                                  .slice(0, 7)}
                                                name="SkillStartdate"
                                                id="skillCoursestartdate"
                                                type="text"
                                                onFocus={(e) =>
                                                  (e.target.type = "month")
                                                }
                                                onChange={this.handleSkillChange}
                                              />
                                              {!skillCoursestartdateErr ? (
                                                <span
                                                  style={{
                                                    color: "#a29d9d",
                                                    float: "left",
                                                  }}
                                                >
                                                  Start From
                                                  <span style={{ color: "red" }}>
                                                    *
                                                  </span>
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {skillCoursestartdateErr}
                                                </div>
                                              )}

                                              <span className="underline" />
                                            </div>
                                          </div>

                                          <div
                                            className="row"
                                            style={{ padding: "8px" }}
                                          >

                                            <div className="col-sm-6">
                                              <input
                                                className="form"
                                                onFocus={(e) => e.target.select()}
                                                name="SkillEnddate"
                                                id="skillCourseEnddate"
                                                type="text"
                                                max={new Date(
                                                  new Date().getFullYear(),
                                                  new Date().getMonth() + 1
                                                )
                                                  .toJSON()
                                                  .slice(0, 7)}
                                                min={new Date(
                                                  new Date().getFullYear() - 60,
                                                  new Date().getMonth() + 1
                                                )
                                                  .toJSON()
                                                  .slice(0, 7)}
                                                onFocus={(e) =>
                                                  (e.target.type = "month")
                                                }
                                                onChange={this.handleSkillChange}
                                              />
                                              {!skillCourseEnddateErr ? (
                                                <span
                                                  style={{
                                                    color: "#a29d9d",
                                                    float: "left",
                                                  }}
                                                >
                                                  End
                                                  <span style={{ color: "red" }}>
                                                    *
                                                  </span>
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {skillCourseEnddateErr}
                                                </div>
                                              )}

                                              <span className="underline" />
                                            </div>

                                            <div
                                              className="col-sm-6"

                                            >
                                              <input
                                                className="effect-16"
                                                name="SkillCertificate"
                                                type="file"
                                                id="SkillCertificate"
                                                onChange={
                                                  this.handleSkillCertificate
                                                }
                                              />
                                              {!skillCertificateErr ? (
                                                <span
                                                  style={{
                                                    color: "#a29d9d",
                                                    float: "left",
                                                  }}
                                                >
                                                  Certificate upload
                                                  <span style={{ color: "red" }}>
                                                    *
                                                  </span>
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {skillCertificateErr}
                                                </div>
                                              )}

                                              <span className="underline" />
                                            </div>
                                          </div>
                                        </>
                                      ) : null}

                                      <div className="col-sm-12 ">
                                        <br />
                                        <div className="center">
                                          <button
                                            type="submit"
                                            className="but"
                                            style={{
                                              height: "39.5px",
                                              width: "83.36px",
                                            }}

                                          >
                                            Save
                                          </button>
                                          <button
                                            type="reset"
                                            className=" btn-Secondary"
                                            style={{
                                              marginLeft: "1%",
                                              height: "39.5px",
                                            }}
                                            onClick={this.handleTextarea}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>

                                      <br />
                                    </div>
                                  </form>
                                </div>
                              </div>

                              <br />
                            </div>

                          ) : (
                            <div
                              className="card-block contact-details"
                              style={{ display: "none" }}
                              id="add-row"
                            >
                              <div>
                                <form
                                  id="skillform"
                                  onSubmit={this.Skillsubmit}
                                >
                                  <div className="row justify-content-center ">
                                    <div className="col-lg-4">
                                      <select
                                        className="effect-16"
                                        name="Category"
                                        onChange={this.handleSkillChange}
                                        type="text"
                                        placeholder="City"
                                      >
                                        <option value=""> Category</option>
                                        {SKillAdminCategoryDrop.map(
                                          (SKillAdminCategoryDrop) => (
                                            <option
                                              value={
                                                SKillAdminCategoryDrop.category
                                              }
                                            >
                                              {SKillAdminCategoryDrop.category}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      {!CategoryErr ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Category{" "}
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {CategoryErr}
                                        </div>
                                      )}
                                    </div>
                                    <div className="col-lg-4">
                                      <input
                                        className="form"
                                        onFocus={(e) => e.target.select()}
                                        name="SkillName"
                                        type="text"
                                        onChange={this.handleSkillChange}
                                      />
                                      {!SkillNameErr ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Name{" "}
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {SkillNameErr}
                                        </div>
                                      )}
                                    </div>

                                    <div className="col-lg-4">
                                      <input
                                        className="form"
                                        onFocus={(e) => e.target.select()}
                                        name="SkillDescription"
                                        type="text"
                                        onChange={this.handleSkillChange}
                                      />
                                      {!SkillDescriptionErr ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Description{" "}
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {SkillDescriptionErr}
                                        </div>
                                      )}
                                    </div>

                                    <div className="col-lg-4">
                                      <input
                                        className="form"
                                        onFocus={(e) => e.target.select()}
                                        name="Experience"
                                        type="text"
                                        onFocus={(e) =>
                                          (e.target.type = "date")
                                        }
                                        onChange={this.handleSkillChange}
                                      />
                                      {!ExperienceErr ? (
                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Experience in hand
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {ExperienceErr}
                                        </div>
                                      )}
                                    </div>

                                    {/* <div className="col-lg-4" style={{ marginTop: "25px", color: "#495057" }}>
                                      <span style={{ color: '#a29d9d' }}>Intrested as a Trainer</span>   &nbsp;&nbsp;&nbsp;
                                      <label className="switch">
                                        <input type="checkbox" name="Trainer" />
                                        <span className="slider round" />
                                      </label>
                                    </div> */}

                                    <div
                                      className="col-lg-4 style-checkboxes"
                                      style={{
                                        marginTop: "25px",
                                        color: "#495057",
                                      }}
                                    >
                                      <span style={{ color: "#a29d9d" }}>
                                        I want to be a Trainer
                                      </span>
                                      {""}&nbsp;&nbsp;&nbsp;
                                      {/* <label className="switch">
                                        <input type="checkbox" name="Trainer" />
                                        <span className="slider round" />
                                      </label> */}
                                      {/* <input type="checkbox" id="yes" name="Trainer" />
                                        <label for="yes">Yes</label>
                                        
                                        <input type="checkbox"  id="no"/>
                                        <label for="no">No</label> */}
                                      <div className="checkbox-yes">
                                        <div>
                                          <label for="yes">
                                            Yes&nbsp;
                                            <input
                                              name="Trainer"
                                              id="yes"
                                              type="checkbox"
                                              checked={
                                                this.state.checkbox1Checked
                                              }
                                              onChange={() =>
                                                this.handleCheckboxChange(1)
                                              }
                                            />
                                          </label>
                                        </div>
                                        <div>
                                          <label for="no">
                                            No&nbsp;
                                            <input
                                              id="no"
                                              type="checkbox"
                                              checked={
                                                this.state.checkbox2Checked
                                              }
                                              onChange={() =>
                                                this.handleCheckboxChange(2)
                                              }
                                            />
                                          </label>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="col-lg-4"
                                      style={{
                                        marginTop: "25px",
                                        color: "#495057",
                                      }}
                                    >
                                      <span style={{ color: "#a29d9d" }}>
                                        {" "}
                                        Certificate Details
                                      </span>{" "}
                                      &nbsp;&nbsp;&nbsp;
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          onFocus={(e) => e.target.select()}
                                          id="UploadCertificate"
                                          onInputCapture={
                                            this.HandleSkillCertificate
                                          }
                                          name="UploadCertificate"
                                        />
                                        <span className="slider round" />
                                      </label>
                                    </div>
                                    {UploadCertificate === true ? (
                                      <>
                                        <div className="col-lg-4">
                                          <input
                                            className="form"
                                            onFocus={(e) => e.target.select()}
                                            name="SkillCoursename"
                                            type="text"
                                            id="skillcoursename"
                                            onChange={
                                              this.hanhandleSkillChangedleChange
                                            }
                                          />
                                          {!skillcoursenameErr ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Course Name
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {skillcoursenameErr}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-lg-4">
                                          <input
                                            className="form"
                                            onFocus={(e) => e.target.select()}
                                            name="SkillStartdate"
                                            id="skillCoursestartdate"
                                            type="text"
                                            onFocus={(e) =>
                                              (e.target.type = "month")
                                            }
                                            onChange={this.handleSkillChange}
                                          />
                                          {!skillCoursestartdateErr ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Start From
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {skillCoursestartdateErr}
                                            </div>
                                          )}
                                        </div>
                                        <div className="col-lg-4">
                                          <input
                                            className="form"
                                            onFocus={(e) => e.target.select()}
                                            name="SkillEnddate"
                                            id="skillCourseEnddate"
                                            type="text"
                                            onFocus={(e) =>
                                              (e.target.type = "month")
                                            }
                                            onChange={this.handleSkillChange}
                                          />
                                          {!skillCourseEnddateErr ? (
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              End From
                                            </span>
                                          ) : (
                                            <div style={{ color: "red" }}>
                                              {skillCourseEnddateErr}
                                            </div>
                                          )}
                                        </div>
                                      </>
                                    ) : null}
                                    <div className="col-lg-12">
                                      <button
                                        type="submit"
                                        id="Skilledu"
                                        className="but"
                                        style={{
                                          height: "40.4px",
                                          width: "83.36px",
                                          float: "right",
                                        }}
                                        disabled={this.state.isButtonDisabledB}
                                      >
                                        Save
                                      </button>
                                    </div>
                                    <br />
                                    <br />
                                  </div>
                                </form>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {showing1 ?
                        <div className="card">
                          <div className="dt-responsive table-responsive">
                            {SkillDetails.length !== 0 ? (
                              <table
                                id="add-row-table"
                                className="table   nowrap"
                              >
                                <thead>
                                  <tr>
                                    {/* <th>Category</th> */}
                                    <th>Skill Name</th>
                                    {/* <th>Description</th> */}
                                    <th>Acquired From</th>
                                    <th>Trainer</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {SkillDetails.map((SkillDetails) => (
                                    <tr>
                                      {/* <td >{SkillDetails.category}</td> */}
                                      <td>{SkillDetails.skillName}</td>
                                      {/* <td >{SkillDetails.skillDescription}</td> */}
                                      <td>{SkillDetails.experience}</td>
                                      <td>{SkillDetails.trainer}</td>
                                      <td>
                                        {/* <img alt="edit" src="https://img.icons8.com/android/22/000000/pencil.png" 
                                  onClick={() => this.SkillModel(SkillDetails.id, SkillDetails.category, SkillDetails.skillName, 
                                  SkillDetails.skillDescription, SkillDetails.experiencedate, SkillDetails.trainer,
                                   SkillDetails.skillCoursename, SkillDetails.skillStartdate, SkillDetails.skillEnddate)} /> */}
                                        <button
                                          className="button-30 sk-edit"
                                          name="Edit"
                                          title="Delete"
                                          onClick={() =>
                                            this.DelUserSkill(SkillDetails.skillName, SkillDetails.userId)
                                          }
                                          style={{ marginLeft: "-19px" }}
                                        >
                                          <i
                                            className="fa fa-minus"
                                            style={{ color: "red" }}
                                          ></i>
                                        </button>{" "}
                                        &nbsp;&nbsp;
                                        <button
                                          className="button-30 sk-edit"
                                          name="Edit"
                                          title="Edit"
                                          onClick={() =>
                                            this.SkillModel(
                                              SkillDetails.id,
                                              SkillDetails.category,
                                              SkillDetails.skillName,
                                              SkillDetails.skillDescription,
                                              SkillDetails.experiencedate,
                                              SkillDetails.trainer,
                                              SkillDetails.skillCoursename,
                                              SkillDetails.skillStartdate,
                                              SkillDetails.skillEnddate,
                                              SkillDetails.created_Date
                                            )
                                          }
                                        >

                                          <i
                                            className="fa fa-edit"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : null}
                          </div>
                        </div> : null}
                    </div>

                    <div id="tabs-section-4" className="j-tabs-section">
                      <span>Please fill all the mandatory fields to follow the steps
                        <span style={{
                          marginTop: '-4px',
                          color: 'red',
                          fontSize: '25px',
                          position: 'absolute',
                          marginLeft: '4px',
                          fontWeight: 'bold'

                        }}>*</span> </span>
                      <div className="tab-pane" id="review" role="tabpanel">
                        <div className="card">
                          <div
                            className="card-header"
                            style={{
                              backgroundColor: "darkslateblue",
                              padding: "5px",
                              color: "white",
                            }}
                          >
                            <h5
                              className="card-header-text"
                              style={{
                                marginTop: "10px",
                                marginLeft: "2%",
                                color: "white",
                              }}
                            >
                              {" "}
                              Add Dependencies <span style={{ color: "red", marginLeft: "171px", marginTop: "-18px", fontSize: "22px" }}>
                                *
                              </span>
                            </h5>
                            <button
                              style={{
                                float: "right",
                                color: "#007bff",
                                fontSize: "medium",
                                fontWeight: "800",
                              }}
                              className="button-20 sk-edit"
                              id="addFmlyBtn"
                              title="Add"
                              type="button"
                              // onClick={() => {
                              //   this.setState((prevState) => ({
                              //     openfam: !prevState.openfam,
                              //   }));
                              //   this.familyformreset();
                              // }}
                              onClick={() => {
                                this.setState((prevState) => ({
                                  openfam: !prevState.openfam,
                                  showing2: prevState.clickCount % 2 === 0 ? false : true, // Toggle showing based on the number of clicks
                                  clickCount: prevState.clickCount + 1, // Increment the click count
                                }), () => {
                                  if (this.state.clickCount % 2 === 1) {
                                    this.familyformreset();
                                  }
                                });
                              }}
                            >
                              {openfam ? "×" : "+"}{" "}
                            </button>
                          </div>

                          {
                            openfam ? (
                              <div
                                className="card-block contact-details"
                                id="add-rowFamily"
                              >
                                <div>
                                  <form
                                    id="familyform"
                                    onSubmit={this.FamlySubmit}
                                  >
                                    <div className="row ">
                                      <div className="col-lg-3">
                                        <select
                                          className="effect-16"
                                          id="RelationshipFam"
                                          name="Relationship"
                                          onChange={this.handleChange}
                                          type="text"
                                          style={{
                                            // Apply the marginLeft style only if the browser is Chrome
                                            ...(isChrome && { marginLeft: '-3px' })
                                          }}
                                        >
                                          <option value="">
                                            {" "}
                                            Relationship
                                          </option>
                                          {RelationshipDrop.map(
                                            (RelationshipDrop) => (
                                              <option
                                                value={
                                                  RelationshipDrop.relationship
                                                }
                                              >
                                                {RelationshipDrop.relationship}
                                              </option>
                                            )
                                          )}
                                        </select>

                                        {!RelationshipErr ? (
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Relationship
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {RelationshipErr}
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-lg-3 ">
                                        <input
                                          className="form"
                                          onFocus={(e) => e.target.select()}
                                          name="FirstName"
                                          id="FirstNameFam"
                                          type="text"
                                          onChange={this.handleChange}
                                        />

                                        {!FirstNameErr ? (
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            First Name
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {FirstNameErr}
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-lg-3 ">
                                        <input
                                          className="form"
                                          onFocus={(e) => e.target.select()}
                                          name="MiddleName"
                                          id="MiddleNameFam"
                                          type="text"
                                          onChange={this.handleChange}
                                        />

                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Middle Name
                                        </span>
                                      </div>
                                      <div className="col-lg-3 ">
                                        <input
                                          className="form"
                                          onFocus={(e) => e.target.select()}
                                          name="LastName"
                                          id="LastNameFam"
                                          type="text"
                                          onChange={this.handleChange}
                                        />
                                        <span
                                          className={
                                            LastNameErr ? " showError" : ""
                                          }
                                        ></span>

                                        {!LastNameErr ? (
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Last Name
                                            <span style={{ color: "red" }}>
                                              *
                                            </span>
                                          </span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {LastNameErr}
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      className="row "
                                      style={{ marginTop: "2%" }}
                                    >
                                      <div className="col-lg-3 ">
                                        <input
                                          className="form"
                                          onFocus={(e) => e.target.select()}
                                          name="Contact"
                                          id="ContactFam"
                                          type="text"
                                          maxLength="10"
                                          onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={this.handleChange}
                                        />

                                        {!ContactErr ? (
                                          <>
                                            <span
                                              style={{
                                                color: "#a29d9d",
                                                float: "left",
                                              }}
                                            >
                                              Contact Number
                                              {/* <span style={{ color: "red" }} id='contacstar'>
                                              *
                                            </span> */}
                                            </span>
                                            <p>{this.state.FamilyDetails.length === 0
                                              ? <span style={{ color: "red" }}>
                                                *
                                              </span>
                                              : null}</p>
                                          </>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {ContactErr}
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-lg-3">
                                        <input
                                          className="form"
                                          onFocus={(e) => e.target.select()}
                                          name="Email"
                                          id="EmailFam"
                                          type="text"
                                          onChange={this.handleChange}
                                        />
                                        {/* <span style={{ color: '#a29d9d', float: "left" }}>Email</span> */}
                                        {!EmailErr ? (
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Email
                                          </span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {EmailErr}
                                          </div>
                                        )}
                                      </div>
                                      {/* <div className="col-lg-3">

                                      <select className="effect-16" id="RelationshipFam" name="Relationship" onChange={this.handleChange} type="text"    >
                                        <option value="" > Relationship</option>
                                        {RelationshipDrop.map(RelationshipDrop => <option value={RelationshipDrop.relationship}>{RelationshipDrop.relationship}</option>)}

                                      </select>


                                      {!RelationshipErr ? <span style={{ color: '#a29d9d', float: "left" }}>Relationship<span style={{ color: "red" }}>*</span></span> : <div style={{ color: "red" }}>{RelationshipErr}</div>}


                                    </div> */}

                                      <div
                                        className="col-lg-3"
                                        style={{ marginTop: "21px" }}
                                      >
                                        <span style={{ color: "#7c7c7c" }}>
                                          Emergency Contact
                                        </span>
                                        &nbsp;&nbsp;
                                        {this.state.FamilyDetails.length === 0
                                          ? true
                                          : false}
                                        <label
                                          style={
                                            this.state.FamilyDetails.length ===
                                              0
                                              ? { pointerEvents: "none" }
                                              : { pointerEvents: "all" }
                                          }
                                          className="switch center"
                                        >
                                          <input
                                            type="checkbox"
                                            id="EmergencyContact"
                                            defaultChecked={
                                              this.state.FamilyDetails
                                                .length === 0
                                                ? true
                                                : false
                                            }
                                            name="EmergencyContact"
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                      <br />

                                      {/* <div className="col-lg-12 ">

                                      <button type="submit" id="saverow3" className="but" style={{ height: '40.4px', width: '83.36px', float: "right" }} disabled={this.state.isButtonDisabled}>Save</button>
                                    </div> */}

                                      <div className="col-lg-12 ">
                                        <br />
                                        <div className="center">
                                          <button
                                            type="submit"
                                            className="but"
                                            style={{
                                              height: "39.5px",
                                              width: "83.36px",
                                            }}
                                            disabled={
                                              this.state.isButtonDisabled
                                            }
                                          >
                                            Save
                                          </button>
                                          <button
                                            type="reset"
                                            className=" btn-Secondary"
                                            style={{
                                              marginLeft: "1%",
                                              height: "39.5px",
                                            }}
                                            onClick={this.handleTextarea}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="card-block contact-details"
                                style={{ display: "none" }}
                                id="add-rowFamily"
                              >
                                <div>
                                  <form
                                    id="familyform"
                                    onSubmit={this.FamlySubmit}
                                  >
                                    <div className="row ">
                                      <div className="col-lg-3 ">
                                        <input
                                          className="form"
                                          onFocus={(e) => e.target.select()}
                                          name="FirstName"
                                          id="FirstNameFam"
                                          type="text"
                                          onChange={this.handleChange}
                                        />

                                        {!FirstNameErr ? (
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            First Name
                                          </span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {FirstNameErr}
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-lg-3 ">
                                        <input
                                          className="form"
                                          onFocus={(e) => e.target.select()}
                                          name="MiddleName"
                                          id="MiddleNameFam"
                                          type="text"
                                          onChange={this.handleChange}
                                        />

                                        <span
                                          style={{
                                            color: "#a29d9d",
                                            float: "left",
                                          }}
                                        >
                                          Middle Name
                                        </span>
                                      </div>
                                      <div className="col-lg-3 ">
                                        <input
                                          className="form"
                                          onFocus={(e) => e.target.select()}
                                          name="LastName"
                                          id="LastNameFam"
                                          type="text"
                                          onChange={this.handleChange}
                                        />
                                        <span
                                          className={
                                            LastNameErr ? " showError" : ""
                                          }
                                        ></span>

                                        {!LastNameErr ? (
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Last Name
                                          </span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {LastNameErr}
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-lg-3 ">
                                        <input
                                          className="form"
                                          onFocus={(e) => e.target.select()}
                                          name="Contact"
                                          id="ContactFam"
                                          type="text"
                                          maxLength="10"
                                          onKeyPress={(event) => {
                                            const isLetter = /[a-zA-Z]/i.test(event.key);
                                            if (isLetter) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onInputCapture={(event) => {
                                            if (!/^[0-9]*$/.test(event.target.value)) {
                                              event.target.value = event.target.value.replace(/[^0-9]/g, '');
                                            }
                                          }}
                                          onChange={this.handleChange}
                                        />

                                        {!ContactErr ? (
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Contact Number
                                          </span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {ContactErr}
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      className="row "
                                      style={{ marginTop: "2%" }}
                                    >
                                      <div className="col-lg-3">
                                        <input
                                          className="form"
                                          onFocus={(e) => e.target.select()}
                                          name="Email"
                                          id="EmailFam"
                                          type="email"
                                          onChange={this.handleChange}
                                        />

                                        {!EmailErr ? (
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Email
                                          </span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {EmailErr}
                                          </div>
                                        )}
                                      </div>
                                      <div className="col-lg-3">
                                        <select
                                          className="effect-16"
                                          id="RelationshipFam"
                                          name="Relationship"
                                          onChange={this.handleChange}
                                          type="text"
                                        >
                                          <option value="">
                                            {" "}
                                            Relationship
                                          </option>
                                          {RelationshipDrop.map(
                                            (RelationshipDrop) => (
                                              <option
                                                value={
                                                  RelationshipDrop.relationship
                                                }
                                              >
                                                {RelationshipDrop.relationship}
                                              </option>
                                            )
                                          )}
                                        </select>

                                        {!RelationshipErr ? (
                                          <span
                                            style={{
                                              color: "#a29d9d",
                                              float: "left",
                                            }}
                                          >
                                            Relationship
                                          </span>
                                        ) : (
                                          <div style={{ color: "red" }}>
                                            {RelationshipErr}
                                          </div>
                                        )}
                                      </div>

                                      <div
                                        className="col-lg-3"
                                        style={{ marginTop: "21px" }}
                                      >
                                        <span style={{ color: "#7c7c7c" }}>
                                          Emergency Contact1
                                        </span>
                                        &nbsp;&nbsp;
                                        <label
                                          style={{ pointerEvents: "none" }}
                                          className="switch center"
                                        >
                                          <input
                                            type="checkbox"
                                            id="EmergencyContact"
                                            defaultChecked={
                                              this.state.FamilyDetails
                                                .length === 0
                                                ? true
                                                : false
                                            }
                                            name="EmergencyContact"
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </div>
                                      <br />

                                      <div className="col-lg-12 ">
                                        <button
                                          type="submit"
                                          id="saverow3"
                                          disabled={this.state.isButtonDisabled}
                                          className="but"
                                          style={{
                                            height: "40.4px",
                                            width: "83.36px",
                                            float: "right",
                                          }}
                                        >
                                          Save
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            )

                            // vbfbgf
                          }
                        </div>
                      </div>
                      {showing2 ?
                        <div className="card">
                          <div className="dt-responsive table-responsive">
                            {FamilyDetails.length !== 0 ? (
                              <table
                                id="add-row-table"
                                className="table  nowrap "
                              >
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Contact Number </th>
                                    <th>Email</th>
                                    <th>Relationship</th>
                                    <th>Emergency Contact</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {FamilyDetails.map((FamilyDetails) => (
                                    <tr
                                      id={
                                        FamilyDetails.id + FamilyDetails.firstName
                                      }
                                    >
                                      <td>
                                        {FamilyDetails.firstName}{" "}
                                        {FamilyDetails.middleName}{" "}
                                        {FamilyDetails.lastName}
                                      </td>
                                      <td>{FamilyDetails.contact}</td>
                                      <td>{FamilyDetails.email}</td>
                                      <td>{FamilyDetails.relationship}</td>
                                      <td>{FamilyDetails.emergencyContact}</td>
                                      <td>
                                        {/* <img alt="edit" src="https://img.icons8.com/android/22/000000/pencil.png" 
                                    onClick={() => this.FamModel(FamilyDetails.id, FamilyDetails.firstName, FamilyDetails.middleName, FamilyDetails.lastName, 
                                    FamilyDetails.contact, FamilyDetails.email, FamilyDetails.relationship, FamilyDetails.emergencyContact)} />    
                                    &nbsp;&nbsp;&nbsp; */}
                                        <button
                                          className="button-30 sk-edit"
                                          name="Edit"
                                          title="Delete"
                                          onClick={() =>
                                            this.DelUserFamily(FamilyDetails.created_Date, FamilyDetails.userId)
                                          }
                                          style={{ marginLeft: "-19px" }}
                                        >
                                          <i
                                            className="fa fa-minus"
                                            style={{ color: "red" }}
                                          ></i>
                                        </button>{" "}
                                        &nbsp;&nbsp;
                                        <button
                                          className="button-30 sk-edit"
                                          type="button"
                                          title="Edit"
                                          onClick={() =>
                                            this.FamModel(
                                              FamilyDetails.id,
                                              FamilyDetails.firstName,
                                              FamilyDetails.middleName,
                                              FamilyDetails.lastName,
                                              FamilyDetails.contact,
                                              FamilyDetails.email,
                                              FamilyDetails.relationship,
                                              FamilyDetails.emergencyContact,
                                              FamilyDetails.created_Date
                                            )
                                          }
                                        >

                                          <i
                                            className="fa fa-edit"
                                            aria-hidden="true"
                                          ></i>{" "}
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : null}{" "}
                          </div>
                        </div> : null}
                    </div>

                    <div id="tabs-section-8" className="j-tabs-section">
                      <span>Please fill all the mandatory fields to follow the steps
                        <span style={{
                          marginTop: '-4px',
                          color: 'red',
                          fontSize: '25px',
                          position: 'absolute',
                          marginLeft: '4px',
                          fontWeight: 'bold'

                        }}>*</span> </span>
                      <div className="tab-pane" id="notes" role="tabpanel">
                        <div className="card">
                          <div className="card-header">
                            <h5 className="card-header-text"> </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
