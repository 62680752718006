import { React, useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';

const DisplayTrainerScheduleData = ({ close, date, Data }) => {
  const [ScheduleData, setScheduleData] = useState([]);
  const [LoadStatus, setLoadStatus] = useState('Loading....');

  useEffect(() => {
    console.log('v', Data);
    GetDepartment();
  }, []);

  const GetDepartment = () => {
    // {
    //     "trainerName": "Arun",
    //     "department": "Planning",
    //     "trainer_UserId": "3a0aa00d-b7c6-4eaa-8fc5-71dfbd8ff177",
    //     "startdate": [
    //         "2025-05-23",
    //         " 2024-04-17"
    //     ],
    //     "check": false,
    //     "available": true,
    //     "closebtn": false
    // }
    let url = `https://trainingapi.oneawesometeam.com/api/Learning/GetScheduleData?UserId=${Data.trainer_UserId}&Date=${date}`;
    console.log('||||||||||||', url);
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        setScheduleData(data);
        setLoadStatus('No Scheduled Trainings');
        console.log('Training', data);

        //
      });
  };
  function TimeMod(time) {
    console.log('timetimetimetimetime', time);
    const Hour = time.split(':')[0];
    const minutes = time.split(':')[1];
    if (Hour > 12) {
      time = Hour - 12;
      time = time < 10 ? '0' + time : time;

      return time + ':' + minutes + ' PM';
    } else if (Hour == 0) {
      return 12 + ':' + minutes + ' AM';
    } else if (Hour == 12) {
      return 12 + ':' + minutes + ' PM';
    } else {
      return time + ' AM';
    }
  }

  function Duration(duration) {
    console.log('duration------------------', duration);
    let hour = duration.split(':')[0];
    let minute = duration.split(':')[1];
    let m = String(minute);
    m === '0' ? (m = '00') : (m = minute);
    hour = parseInt(hour) < 0 ? parseInt(hour) + 24 : hour;

    if (hour == 1 && minute == '00') {
      return Number(hour) + ' Hour';
    } else if (hour == 1 && minute != '00') {
      return Number(hour) + ':' + m + ' Hours';
    } else if (hour > 1 && minute != '00') {
      return Number(hour) + ':' + m + ' Hours';
    } else if (hour > 1 && minute == '00') {
      return Number(hour) + ' Hours';
    } else if (hour == 0 && m != '00') {
      return m + ' Minutes';
    }
    //  else if (hour == 0 && m == '00') {
    //   return 'Whole day';
    // } else {
    //   return 'Whole day';
    // }
  }

  return (
    <div className="pop">
      <div
        className="popinnernormal"
        style={{
          width: '52%',
          height: '32vh',
          overflowY: 'auto',
          padding: '6px',
        }}
      >
        <div className="row">
          <div className="col-lg-5"></div>
          <div className="col-lg-1 " style={{ fontSize: '20px' }}>
            <b>{Data.trainerName}</b>
          </div>
          <div className="col-lg-5"></div>

          <div className="col-lg-1 ">
            <button
              style={{
                backgroundColor: 'rgba(160, 163, 163, 0.02)',
                color: 'rgb(121, 112, 112)',
                border: '1px solid rgba(163, 165, 165, 0.02)',
                float: 'right',
              }}
              onClick={(e) => close(e)}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
        <hr style={{ margin: '0' }} />
      </div>

      <div className="row">
        <div
          className="col-lg-12"
          style={{
            height: '21vh',
            overflowY: 'auto',
          }}
        >
          <div>
            {ScheduleData.length == 0 ? (
              <p style={{ marginTop: '50px' }}>{LoadStatus}</p>
            ) : (
              <table
                id="add-row-table3"
                className="table nowrap"
                style={{
                  height: 'auto',
                  overflow: 'auto',
                }}
              >
                <thead>
                  <tr>
                    {/* <th><input type="checkbox" id="selectall" /> </th> */}
                    <th>Date</th>
                    <th>Time</th>
                    {/* <th>Training Topic</th>
                  <th>Description</th>
                  <th>Department</th> */}
                    <th>Duration</th>
                    {/* <th>End Date</th> */}
                    {/* <th>Trainers</th> */}
                    {/* <th>Attendies</th> */}
                    {/* <th>Invite</th> */}
                  </tr>
                </thead>
                {ScheduleData.map((Learning, time24) => (
                  <tbody>
                    {/* {this.state.completeddatevalue} */}

                    <tr>
                      {/* <td><input type="checkbox" id="selectall" /></td> */}

                      <td>
                        {Learning.startDate
                          ? Learning.startDate.slice(0, 10)
                          : null}
                      </td>
                      <td>
                        {TimeMod(Learning.scheduleTime)} to{' '}
                        {TimeMod(Learning.scheduleEndTime)}
                      </td>

                      {/* <td>{Learning.trainingitem}</td>
                    <td>
                      {Learning.trainingDescription.length > 10 ? (
                        <div>
                          {Learning.trainingDescription.substring(0, 15)}
                          ....
                        </div>
                      ) : (
                        Learning.trainingDescription
                      )}
                    </td>
                    <td>{Learning.department}</td> */}
                      <td>{Duration(Learning.schedulingDuration)}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DisplayTrainerScheduleData;
