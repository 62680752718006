import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class EditAdminLocationModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      ButtonDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };

  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var Address = document.getElementById("Address").value;
    var Branch = document.getElementById("Branch").value;
    var LocationType = document.getElementById("LocationType").value;

    if (!Address) {
      formIsValid = false;
      formErrors["Address"] = "Address  is required";
    }
    if (!Branch) {
      formIsValid = false;
      formErrors["Branch"] = "Branch  is required";
    }

    if (!LocationType) {
      formIsValid = false;
      formErrors["Address"] = "Location Type  is required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }
  handleSubmit(event) {
    var Address = document.getElementById("Address").value;
    var Branch = document.getElementById("Branch").value;
    var LocationType = document.getElementById("LocationType").value;
    var Id = document.getElementById("Id").value;

    event.preventDefault();
    if (this.Validation()) {
      this.setState({
        ButtonDisabled: true,
      });
      const data = new FormData();
      data.append("Address", Address);
      data.append("Branch", Branch);
      data.append("LocationType", LocationType);
      data.append("Id", Id);

      fetch(`${BASE_URLs}AdminLocation/EditLocation`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            document.getElementById("Editpop").style.display = "none";
            setTimeout(function () {
              document.getElementById("close").click();
            }, 4000);
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            document.getElementById("Editpop").style.display = "none";
            setTimeout(function () {
              document.getElementById("close").click();
            }, 2000);
          }
        });
    }
  }
  render() {
    var w = window.innerWidth;

    const { Address, LocationType, Branch } = this.state.formErrors;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal threeFieldPop"
                : "popinnernormal threeFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="Editpop"
          >
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>
            <br />
            <form autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
              <div className="row">
                <div className="col-sm-4">
                  <input
                    type="hidden"
                    id="Id"
                    name="Id"
                    onKeyDown={this._handleKeyDown}
                    defaultValue={this.props.values.id}
                  />
                  <textarea
                    className="form"
                    defaultValue={this.props.values.address}
                    onKeyDown={this._handleKeyDown}
                    id="Address"
                    name="Address"
                    style={{ height: "36px" }}
                  />
                  {Address == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Address
                      <span style={{ color: "red", float: "left" }}></span>
                    </span>
                  ) : (
                    <span
                      style={{ color: "red", float: "left" }}
                      className={Address ? " showError" : ""}
                    >
                      {Address}
                    </span>
                  )}
                </div>
                <div className="col-sm-4">
                  <input
                    type="hidden"
                    id="Id"
                    name="Id"
                    onKeyDown={this._handleKeyDown}
                    defaultValue={this.props.values.id}
                  />
                  <input
                    className="form"
                    maxLength="20"
                    defaultValue={this.props.values.Branch}
                    id="Branch"
                    name="Branch"
                    type="text"
                  />
                  {Branch == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Branch
                      <span style={{ color: "red", float: "left" }}></span>
                    </span>
                  ) : (
                    <span
                      style={{ color: "red", float: "left" }}
                      className={Address ? " showError" : ""}
                    >
                      {Branch}
                    </span>
                  )}
                </div>
                <div className="col-sm-4">
                  <select
                    className="form"
                    maxLength="20"
                    onKeyDown={this._handleKeyDown}
                    defaultValue={this.props.values.addresstype}
                    id="LocationType"
                    name="LocationType"
                    type="text"
                  >
                  <option value="Primary Location">Primary Location</option>
                  <option value="Secondary Location">Secondary Location</option>
                  </select>
                  {LocationType == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      {" "}
                      Type<span style={{ color: "red", float: "left" }}></span>
                    </span>
                  ) : (
                    <span
                      style={{ color: "red", float: "left" }}
                      className={LocationType ? " showError" : ""}
                    >
                      {LocationType}
                    </span>
                  )}
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
                <br />
              </div>
            </form>
            <br />
          </div>
        </div>
      </>
    );
  }
}
