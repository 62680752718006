import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import AssignedTraineePop from "../../Common/AssignedTraineePop";
export default class TrainerAssign1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      AssignedTraineePopModel: false,
    };
  }
  AssignedTraineePopModel = () => {
    this.setState((prevState) => ({
      AssignedTraineePopModel: !prevState.AssignedTraineePopModel,
    }));
  };
  render() {
    return (
      <div>
        <SideBar active={window.location.hash} />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">
                            Assigned Training
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        {this.state.AssignedTraineePopModel ? (
                          <AssignedTraineePop
                            id="Success"
                            close={() => this.AssignedTraineePopModel()}
                          />
                        ) : null}
                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                          >
                            <div className="row" style={{ marginTop: "3%" }}>
                              <div className="container">
                                <div className="container">
                                  {/* <input type="text" defaultValue={this.props.location.state.training_Id} /> */}

                                  <div
                                    className="row"
                                    style={{ padding: "8px" }}
                                  >
                                    <div className="col-sm-6">
                                      <input
                                        className="effect-16"
                                        name="TrainingItem"
                                        id="trainingitem"
                                        value="Quality Management"
                                        readOnly
                                        type="text"
                                        maxLength="50"
                                        placeholder="Training Item"
                                      />
                                      <span style={{ color: "#a29d9d" }}>
                                        Training Item
                                      </span>{" "}
                                      <span style={{ color: "red" }}></span>
                                    </div>
                                    <div className="col-sm-6">
                                      <input
                                        className="effect-16"
                                        name="Department"
                                        id="department"
                                        value="Quality"
                                        readOnly
                                        type="text"
                                        maxLength="50"
                                        placeholder="Department"
                                      />
                                      <span style={{ color: "#a29d9d" }}>
                                        Department
                                      </span>{" "}
                                      <span style={{ color: "red" }}></span>
                                    </div>

                                    <div className="col-sm-6">
                                      <input
                                        className="effect-16"
                                        name="TrainerName"
                                        id="trainername"
                                        value="Sushma Sudha"
                                        readOnly
                                        type="text"
                                        maxLength="50"
                                        placeholder="Trainer Name"
                                      />
                                      <span style={{ color: "#a29d9d" }}>
                                        Trainers
                                      </span>{" "}
                                      <span style={{ color: "red" }}></span>
                                    </div>
                                    <div className="col-sm-6">
                                      <input
                                        className="effect-16"
                                        name="EndDate"
                                        id="end"
                                        value="2"
                                        readOnly
                                        type="text"
                                        onFocus={this.myDate}
                                        maxLength="50"
                                      />
                                      <span style={{ color: "#a29d9d" }}>
                                        No of Documents
                                      </span>{" "}
                                      <span style={{ color: "red" }}></span>
                                    </div>
                                    <div className="col-sm-6">
                                      <input
                                        className="effect-16"
                                        name="StartDate"
                                        id="start"
                                        value="A crucial concept in total quality management"
                                        readOnly
                                        type="text"
                                        maxLength="50"
                                      />
                                      <span style={{ color: "#a29d9d" }}>
                                        Description
                                      </span>{" "}
                                      <span style={{ color: "red" }}></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br />
                            <div className="row">
                              <div
                                className="col-sm-6"
                                style={{ textAlign: "center" }}
                              >
                                <button
                                  type="submit"
                                  className="but "
                                  style={{
                                    height: "40.5px",
                                    width: "90.36px",
                                    marginTop: "-0.3%",
                                  }}
                                >
                                  Accept
                                </button>
                              </div>

                              <div
                                className="col-sm-6"
                                style={{ textAlign: "center" }}
                              >
                                <Link
                                  onClick={() => this.AssignedTraineePopModel()}
                                >
                                  <button
                                    type="submit"
                                    className="but "
                                    style={{
                                      height: "40.5px",
                                      width: "150px",
                                      marginTop: "-0.3%",
                                    }}
                                  >
                                    Not Interested
                                  </button>
                                </Link>
                                {/* <Link to='/AddTraineePop'  type="button" style={{ width: "150px", background: "white" }} className="btn ">Add Trainees  </Link> */}
                              </div>
                            </div>
                            <br /> <br />
                            <br /> <br />
                          </form>
                        </div>
                        {/* <div className="dt-responsive table-responsive table-res">

                                            <table id="DepartSheet" className="table nowrap" style={{ maxWidth: "70%" }}>
                                                <thead>
                                                    <tr>
                                                        <th>Trainer Name</th>
                                                        <th>Department</th>
                                                        <th>Session</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {Trainername.map(Department => <tr>
                                                        <td>{Department.trainerName}</td>
                                                        <td>{Department.department}</td>
                                                        <td>{Department.trainingItem}</td>
                                                        <td >{Department.startDate}</td>
                                                        <td>{Department.endDate}</td>
                                                    </tr>)}
                                                </tbody>
                                            </table>

                                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
