import React, { Component } from "react";
import $ from "jquery";
import SideBar from "../../Common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../../Config/Base";
import { Image_URLs } from "../../Config/Base";

$(document).ready(function () {
  $(".notfound").hide();

  // Search User columns

  $("#searchorg").keyup(function () {
    // Search Text
    var search = $(this).val();

    // Hide all table tbody rows
    $("table tbody tr").hide();

    // Count total search result
    var len = $(
      'table tbody tr:not(.notfound) td:contains("' + search + '")'
    ).length;

    if (len > 0) {
      // Searching text in columns and show match row
      $('table tbody tr:not(.notfound) td:contains("' + search + '")').each(
        function () {
          $(this).closest("tr").show();
        }
      );
    } else {
      $(".notfound").show();
    }
  });

  $.expr[":"].contains = $.expr.createPseudo(function (arg) {
    return function (elem) {
      return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
  });
});
export default class Organization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      User: [],
      CName: "",
      CEmail: "",
      CSubject: "",
      CMsg: "",
      formErrors: {},
      Notify: [],
    };

    this.initialState = this.state;
  }
  GetContactmsg() {
    var Contact_Id = this.props.location.state.Id;

    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(
      `${BASE_URLs}Contact/GetContactmsg?Contact_Id=` + Contact_Id,
      options
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Notify: data });
        if (data.length !== 0) {
          this.setState({ Unreadcount: data[0].count });
        }
      });
  }

  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push("/Notifications");
    } else {
      this.GetContactmsg();
    }
  }

  render() {
    const { Notify } = this.state;
    const { CSubjectErr, CMsgErr } = this.state.formErrors;
    return (
      <div>
        <SideBar active={window.location.hash} />
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header"></div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="glowBox">
                          <div>
                            <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                          </div>
                          <h4 className="title">Message Details</h4>
                        </div>
                        <div className="card-block">
                          <form
                            id="contactForm"
                            className="contactForm"
                            autoComplete="on"
                            encType="multipart/form-data"
                          >
                            <div className="container">
                              {/* <div id="snackbarSuccess">Some text some message..</div> */}
                              {/* {Notify.map(Notify=><>

            <h4 style={{textAlign:"center"}}>{Notify.cSubject}</h4>
            
            
            <h6 style={{textAlign:"center"}}>{Notify.cMsg}</h6>
            
            </> 
            )} */}
                              {/* {Notify.map(Notify=><>
   <div className="flex-container">
   <div className="flex-item-left"></div>
  <div className="flex-item-left">Title</div>
  <div className="flex-item-right">:</div>
  <div className="flex-item-right">{Notify.cSubject}</div>
</div>
<div className="flex-container">
<div className="flex-item-left"></div>
<div className="flex-item-left">Subject</div>
  <div className="flex-item-right">{Notify.cMsg}</div>
  <div className="flex-item-right"></div>
  </div>
  <div className="flex-container">
  <div className="flex-item-left"></div>
<div className="flex-item-left">Attachements</div>
  <div className="flex-item-right">{Notify.cMsg}</div>
  <div className="flex-item-right"></div>
  </div>
</>  )} */}

                              {Notify.map((Notify) => (
                                <>
                                  <div>
                                    <div className="row">
                                      <div className="col-lg-1"></div>
                                      <div
                                        className="col-lg-3"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Title
                                      </div>

                                      <div
                                        className="col-lg-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        :
                                      </div>
                                      <div className="col-lg-7">
                                        {Notify.cSubject}
                                      </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                      <div className="col-lg-1"></div>
                                      <div
                                        className="col-lg-3"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Subject{" "}
                                      </div>

                                      <div
                                        className="col-lg-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {" "}
                                        :
                                      </div>
                                      <div
                                        className="col-lg-7"
                                        style={{ wordBreak: "break-all" }}
                                      >
                                        {Notify.cMsg}
                                      </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                      <div className="col-lg-1"></div>
                                      <div
                                        className="col-lg-3"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Attachements{" "}
                                      </div>
                                      <div
                                        className="col-lg-1"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        :
                                      </div>

                                      <div className="col-lg-7">
                                        <a
                                          style={{ color: "#11439b" }}
                                          target="_blank"
                                          href={Notify.image}
                                        >
                                          {Notify.image.replace(
                                            `${Image_URLs}/Images/`,
                                            " "
                                          )}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
