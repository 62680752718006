import React, { Component } from "react";
import Piechart from "./Piechart";
import Pie from "../Common/Pie";
import DashPie from "../Common/DashPie";
import "../Css/Sign-Up.css";
import SideBar from "../Common/Sidebar";
import { Link } from "react-router-dom";
import { typeOf } from "@ramonak/react-excel";
import ReactTimeAgo from "react-time-ago";
import { BASE_URLs } from "../Config/Base";
export default class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comlogo: [],
      User: [],
      Tasks: [],
      Unreadcount: 0,
      pendingTask: [],
      FinishedTask: [],
      Organization: this.getCookie(
        "" + JSON.parse(localStorage.uname) + "organization"
      ),
      role: this.getCookie("" + JSON.parse(localStorage.uname) + "role"),
      Reason: "",
      Status: "",
      SaveOrg: [],
      currentPage: 1,
      firstIndex: 0,
      lastIndex: 0,
    };

    this.GetSavedDeactive = this.GetSavedDeactive.bind(this);
    this.prePage = this.prePage.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.changeCPage = this.changeCPage.bind(this);
  }

  getCookie(cName) {
    const name = cName + "=";
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split("; ");
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }

  GetSavedDeactive() {
    // if(this.state.Reason===undefined){return};
    let url = `${BASE_URLs}AddOrganization/GetSavedDeactive`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ SaveOrg: data });
        this.setState({ Reason: data[0]?.reason });
        this.setState({ Status: data[0]?.status });
      });
  }
  GetTask() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Notification/GetTask`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.length !== 0) {
          this.setState({ Tasks: data });
          this.setState({
            FinishedTask: data.filter((Tasks) => Tasks.seen === true),
          });
          this.setState({
            pendingTask: data.filter((Tasks) => Tasks.seen === false),
          });
        }
        // console.log(data);
      });
  }

  GetSPNotification() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Notification/GetNotification`, options)
      .then((response) => response.json())
      .then((data) => {
        if (data.length !== 0) {
          this.setState({ Unreadcount: data[0].count });

          //  this.setState({ Tasks: data});
          //  this.setState({ FinishedTask: data.filter(Tasks => (Tasks.status === 1) || (Tasks.status === 0)   )});
          //   this.setState({ pendingTask: data.filter(Tasks => ((Tasks.status === 2) || (Tasks.status === 4) )   )});
        }
      });
  }

  Noticount = (notify_Id, company_Id) => {
    fetch(`${BASE_URLs}Notification/ChangeSeen`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Seen: 1,
        notify_Id: notify_Id,
      }),
    });

    if (company_Id !== null) {
      var page = {
        pathname: "/OrganizationDetail",
        state: {
          Id: company_Id,
        },
      };
      this.props.history.push(page);
    }
  };

  GetUserInfo() {
    if (
      this.getCookie("" + JSON.parse(localStorage.uname) + "role") ===
      undefined ||
      this.getCookie("" + JSON.parse(localStorage.uname) + "organization") ===
      undefined
    ) {
      let url = `${BASE_URLs}Authenticate/GetValidation`;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      fetch(url, {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: "Bearer " + accessToken,
          "Content-type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            User: data,
            Organization: data[0].organization,
            sidebar: data,
          });

          document.cookie =
            "" +
            JSON.parse(localStorage.uname) +
            "role = " +
            data[0].role +
            ";expires=" +
            new Date(new Date().getTime() + 1 * 60000).toUTCString() +
            " ";
          document.cookie =
            "" +
            JSON.parse(localStorage.uname) +
            "organization = " +
            parseInt(data[0].organization) +
            ";expires=" +
            new Date(new Date().getTime() + 1 * 60000).toUTCString() +
            "";
        });
    } else {
      var crole = this.getCookie("" + JSON.parse(localStorage.uname) + "role");

      var corg = this.getCookie(
        "" + JSON.parse(localStorage.uname) + "organization"
      );
      localStorage.setItem("Role", JSON.stringify(crole));
      localStorage.setItem("org", JSON.stringify(corg));
      this.setState({
        User: [{ role: crole, organization: parseInt(corg) }],
        Organization: corg,
      });
    }
  }

  componentDidMount() {
    this.GetUserInfo();
    this.GetSavedDeactive();
    var crole = this.getCookie("" + JSON.parse(localStorage.uname) + "role");
    var corg = this.getCookie(
      "" + JSON.parse(localStorage.uname) + "organization"
    );
    this.setState({
      role: crole,
      Organization: corg,
    });
    this.GetSPNotification();
    this.GetTask();
  }

  prePage() {
    const { currentPage, firstIndex } = this.state;
    if (currentPage !== firstIndex) {
      this.setState({
        currentPage: currentPage - 1,
      });
    }
  }
  changeCPage(id) {
    this.setState({
      currentPage: id,
    });
  }
  nextPage() {
    const { currentPage, lastIndex } = this.state;
    if (currentPage !== lastIndex) {
      this.setState({
        currentPage: currentPage + 1,
      });
    }
  }

  render() {
    const { FinishedTask, pendingTask, Reason, Status, currentPage, Tasks } =
      this.state;
    const { User } = this.state;
    var username = JSON.parse(localStorage.uname);

    const recordsPerPage = 5;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = FinishedTask.slice(firstIndex, lastIndex);
    const npage = Math.ceil(FinishedTask.length / recordsPerPage);
    // const numbers = [...Array(npage + 1).keys()].slice(1);
    let numbers = [];
    if (npage <= 4) {
      numbers = [...Array(npage + 1).keys()].slice(1);
    } else {
      if (currentPage <= 3) {
        numbers = [...Array(5).keys()].map((i) => i + 1).concat(["..."]);
      } else if (currentPage >= npage - 2) {
        numbers = [1, "...", npage - 3, npage - 2, npage - 1, npage];
      } else {
        numbers = [
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          npage,
        ];
      }
    }

    numbers = numbers.filter((item) => item !== "...");

    return (
      <div>
        <SideBar default active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                {User &&
                  User.map((item) =>
                    item.role === "Platform Admin" ? (
                      <div id="allcards" className="row">
                        <div className="column">
                          <div className="carddash">
                            <h1 className="icofont icofont-bank-alt card1"></h1>
                            <Link to="/Organization">
                              <p className="cardcontent">
                                <h5 id="carddashboard">Entities</h5>
                              </p>
                            </Link>
                          </div>
                        </div>
                        <div className="column">
                          <div className="carddash">
                            <h1 className="icofont icofont-notification card2"></h1>
                            <Link to="/Notifications">
                              <p className="cardcontent">
                                <h5
                                  id="carddashboard"
                                  style={{ marginLeft: "-7px" }}
                                >
                                  {this.state.Unreadcount === 0 ? (
                                    "Notifications"
                                  ) : (
                                    <>
                                      {" "}
                                      Notifications ({this.state.Unreadcount})
                                    </>
                                  )}{" "}
                                </h5>
                              </p>
                            </Link>
                          </div>
                        </div>
                        <div className="column">
                          <div className="carddash">
                            <h1 className="icofont icofont-ui-alarm card3  "></h1>

                            <p className="cardcontent">
                              <h5 id="carddashboard">Reminders</h5>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="card">
                            <div className="row">
                              <div
                                className="col-lg-6"
                                style={{
                                  boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%)",
                                  background: "#f1f1f1",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "25px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  {" "}
                                  {pendingTask.length === 0
                                    ? "No Assigned Tasks "
                                    : "  Assigned  to Me"}
                                </span>
                                <div className="row">
                                  {pendingTask.map((f) => (
                                    <div
                                      className="col-lg-12"
                                      style={{ paddingBottom: "16px" }}
                                    >
                                      <span style={{ paddingTop: "6px" }}>
                                        {f.description}{" "}
                                      </span>
                                      &nbsp;&nbsp;
                                      <button
                                        className="but"
                                        style={{ padding: "5px" }}
                                        onClick={() =>
                                          this.Noticount(
                                            f.notify_Id,
                                            f.company_Id
                                          )
                                        }
                                      >
                                        Verify
                                      </button>{" "}
                                      <br />
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <div
                                className="col-lg-6"
                                style={{
                                  boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%)",
                                  background: "#f1f1f1",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "25px",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  Completed
                                </span>
                                <div className="row">
                                  {records.map((f) => (
                                    <>
                                      {f.state === 3 ? null : (
                                        <div
                                          className="col-lg-12"
                                          style={{ paddingBottom: "16px" }}
                                        >
                                          <span style={{ paddingTop: "6px" }}>
                                            {f.description}
                                          </span>{" "}
                                          &nbsp;&nbsp;
                                          <span
                                            style={{
                                              color: "green",
                                              paddingTop: "6px",
                                            }}
                                          >
                                            {" "}
                                            <i
                                              style={{ fontSize: "20px" }}
                                              className="icofont icofont-tick-boxed"
                                            ></i>
                                          </span>
                                          <span>
                                            {" "}
                                            <ReactTimeAgo
                                              date={f.created_Date}
                                              locale="en-US"
                                              style={{ color: "gray" }}
                                            />
                                          </span>
                                        </div>
                                      )}
                                    </>
                                  ))}
                                  <div className="row">
                                    <div className="col-lg-4"></div>
                                    <div
                                      className="col-lg-4"
                                      style={{
                                        maxWidth: "100%",
                                      }}
                                    >
                                      <nav>
                                        <ul className="pagination pagination-sm">
                                          {currentPage > 1 ? (
                                            <li className="page-item">
                                              <a
                                                className="page-link"
                                                onClick={this.prePage}
                                              >
                                                Prev
                                              </a>
                                            </li>
                                          ) : null}
                                          {numbers.map((n, i) => (
                                            <li
                                              className={`page-item ${currentPage === n
                                                ? "active"
                                                : ""
                                                }`}
                                              key={i}
                                            >
                                              <a
                                                className="page-link"
                                                onClick={() =>
                                                  this.changeCPage(n)
                                                }
                                              >
                                                {n}
                                              </a>
                                            </li>
                                          ))}
                                          {currentPage < npage ? (
                                            <li className="page-item">
                                              <a
                                                className="page-link"
                                                onClick={this.nextPage}
                                              >
                                                Next
                                              </a>
                                            </li>
                                          ) : null}
                                        </ul>
                                      </nav>
                                    </div>

                                    <div className="col-lg-4"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}

                {User &&
                  User.map((item) =>
                    item.role === "Admin" ? (
                      <>
                        {this.state.Organization === "2" ||
                          this.state.Organization === "0" ||
                          this.state.Organization === "3" ||
                          this.state.Organization === "4" ? (
                          <div className="main-body">
                            <div className="page-wrapper">
                              <div className="page-header"></div>
                              <div className="container">
                                <div className="pcoded-wrapper">
                                  {this.state.Organization === "2" ? (
                                    <h1
                                      style={{
                                        textAlign: "center",
                                        color: "#421769",
                                        textShadow: "0 0 1px black",
                                        fontSize: "33px",
                                      }}
                                    >
                                      Dear {username},Your profile has been
                                      completed and waiting for verification{" "}
                                    </h1>
                                  ) : this.state.Organization === "4" ? (
                                    <h1
                                      style={{
                                        textAlign: "center",
                                        color: "#421769",
                                        textShadow: "0 0 1px black",
                                        fontSize: "33px",
                                      }}
                                    >
                                      Dear {username},Your Entity is still
                                      Pending{" "}
                                    </h1>
                                  ) : this.state.Organization === "0" ?
                                    <h1
                                      style={{
                                        textAlign: "center",
                                        color: "#421769",
                                        textShadow: "0 0 1px black",
                                        fontSize: "33px",
                                      }}
                                    >
                                      Dear {username}, Your Entity profile
                                      Deactivated{" "}
                                    </h1>
                                    : (
                                      <>
                                    <h1
                      style={{
                        textAlign: "center",
                        color: "#421769",
                        textShadow: "0 0 1px black",
                        fontSize: "33px",
                      }}
                    >
                      Dear {username},{" "}
                      {this.state.Reason != null && this.state.Status === 4
                        ? "Your Entity is still pending"
                        : "Welcome To One Awesome Team!!"}{" "}
                    </h1>
                    <br />{" "}
                                  <h4 style={{ textAlign: "center", color: "#421769" }}>
                                    {this.state.Reason != null && this.state.Status === 4
                                      ? ""
                                      : "To use our awesome features please complete your entity profile"}{" "}
                                  </h4>
                                  </>
                                  )}
                                  <br />{" "}
                                  {this.state.Reason === null ? (
                                    <h4
                                      style={{
                                        textAlign: "center",
                                        color: "#421769",
                                      }}
                                    >
                                      Due to + {this.state.Reason} + ""
                                    </h4>
                                  ) : null}
                                  <div className="row">
                                    <div
                                      className="col-sm-11"
                                      style={{
                                        textAlign: "center",
                                        marginLeft: "43px",
                                        marginTop: "3%",
                                      }}
                                    >
                                      {this.state.Organization === "2" || this.state.Organization === "2" 
                                      || this.state.Organization === "0" ? 
                                      <Link to="/Organization">
                                        <span>
                                          <input
                                            type="button"
                                            className="but"
                                            value="Entity Profile"
                                            style={{
                                              height: "41.5px",
                                              width: "104.36px",
                                            }}
                                          />
                                        </span>
                                      </Link>:<Link to="/AddOrganization">
                                        <span>
                                          <input
                                            type="button"
                                            className="but"
                                            value="Entity Profile"
                                            style={{
                                              height: "41.5px",
                                              width: "104.36px",
                                            }}
                                          />
                                        </span>
                                      </Link>}{" "}
                                      &nbsp; &nbsp; &nbsp;
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div id="admindash">
                            <div className="main-body">
                              <div className="page-body">
                                <div className="row">
                                  <div className="col-md-12 col-xl-4">
                                    <div className="card table-card">
                                      <div>
                                        <div className="row-table">
                                          <div className="col-sm-6 card-block-big br">
                                            <div className="row">
                                              <div className="col-sm-4">
                                                <i className="icofont icofont-eye-alt text-success" />
                                              </div>
                                              <div className="col-sm-8 text-center">
                                                {/* <h5></h5> */}
                                                <span></span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-6 card-block-big">
                                            <div className="row">
                                              <div className="col-sm-4">
                                                <i className="icofont icofont-ui-music text-danger" />
                                              </div>
                                              <div className="col-sm-8 text-center">
                                                {/* <h5></h5> */}
                                                <span></span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className>
                                        <div className="row-table">
                                          <div className="col-sm-6 card-block-big br">
                                            <div className="row">
                                              <div className="col-sm-4">
                                                <i className="icofont icofont-files text-info" />
                                              </div>
                                              <div className="col-sm-8 text-center">
                                                <h5> </h5>
                                                <span></span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-6 card-block-big">
                                            <div className="row">
                                              <div className="col-sm-4">
                                                <i className="icofont icofont-envelope-open text-warning" />
                                              </div>
                                              <div className="col-sm-8 text-center">
                                                {/* <h5></h5> */}
                                                <span></span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-xl-4">
                                    <div className="card table-card">
                                      <div className>
                                        <div className="row-table">
                                          <div className="col-sm-6 card-block-big br">
                                            <div className="row">
                                              <div className="col-sm-4">
                                                <div
                                                  id="barchart"
                                                  style={{
                                                    height: 40,
                                                    width: 40,
                                                  }}
                                                  className="rickshaw_graph"
                                                >
                                                  <svg width={40} height={40}>
                                                    <rect
                                                      x={0}
                                                      y="7.670236411396245"
                                                      width="6.333333333333332"
                                                      height="32.329763588603754"
                                                      transform="matrix(1,0,0,1,0,0)"
                                                      fill="#1abc9c"
                                                    />
                                                    <rect
                                                      x="13.333333333333332"
                                                      y="0.3960396039603964"
                                                      width="6.333333333333332"
                                                      height="39.603960396039604"
                                                      transform="matrix(1,0,0,1,0,0)"
                                                      fill="#1abc9c"
                                                    />
                                                    <rect
                                                      x="26.666666666666664"
                                                      y="15.752677308547183"
                                                      width="6.333333333333332"
                                                      height="24.247322691452815"
                                                      transform="matrix(1,0,0,1,0,0)"
                                                      fill="#1abc9c"
                                                    />
                                                    <rect
                                                      x="6.333333333333332"
                                                      y="31.91755910284906"
                                                      width="6.333333333333332"
                                                      height="8.082440897150938"
                                                      transform="matrix(1,0,0,1,0,0)"
                                                      fill="#bdc3c7"
                                                    />
                                                    <rect
                                                      x="19.666666666666664"
                                                      y="20.602141846837743"
                                                      width="6.333333333333332"
                                                      height="19.397858153162254"
                                                      transform="matrix(1,0,0,1,0,0)"
                                                      fill="#bdc3c7"
                                                    />
                                                    <rect
                                                      x={33}
                                                      y="27.876338654273592"
                                                      width="6.333333333333332"
                                                      height="12.123661345726408"
                                                      transform="matrix(1,0,0,1,0,0)"
                                                      fill="#bdc3c7"
                                                    />
                                                  </svg>
                                                </div>
                                              </div>
                                              <div className="col-sm-8 text-center">
                                                {/* <h5></h5> */}
                                                <span></span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-6 card-block-big">
                                            <div className="row ">
                                              <div className="col-sm-4">
                                                <i className="icofont icofont-network text-primary" />
                                              </div>
                                              <div className="col-sm-8 text-center">
                                                {/* <h5></h5> */}
                                                <span></span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className>
                                        <div className="row-table">
                                          <div className="col-sm-6 card-block-big br">
                                            <div className="row ">
                                              <div className="col-sm-4">
                                                <div
                                                  id="barchart2"
                                                  style={{
                                                    height: 40,
                                                    width: 40,
                                                  }}
                                                  className="rickshaw_graph"
                                                >
                                                  <svg width={40} height={40}>
                                                    <rect
                                                      x={0}
                                                      y="28.684582743988685"
                                                      width="6.333333333333332"
                                                      height="11.315417256011315"
                                                      transform="matrix(1,0,0,1,0,0)"
                                                      fill="#1abc9c"
                                                    />
                                                    <rect
                                                      x="13.333333333333332"
                                                      y="20.602141846837743"
                                                      width="6.333333333333332"
                                                      height="19.397858153162254"
                                                      transform="matrix(1,0,0,1,0,0)"
                                                      fill="#1abc9c"
                                                    />
                                                    <rect
                                                      x="26.666666666666664"
                                                      y="8.478480501111338"
                                                      width="6.333333333333332"
                                                      height="31.52151949888866"
                                                      transform="matrix(1,0,0,1,0,0)"
                                                      fill="#1abc9c"
                                                    />
                                                    <rect
                                                      x="6.333333333333332"
                                                      y="8.478480501111338"
                                                      width="6.333333333333332"
                                                      height="31.52151949888866"
                                                      transform="matrix(1,0,0,1,0,0)"
                                                      fill="#bdc3c7"
                                                    />
                                                    <rect
                                                      x="19.666666666666664"
                                                      y="4.437260052535867"
                                                      width="6.333333333333332"
                                                      height="35.56273994746413"
                                                      transform="matrix(1,0,0,1,0,0)"
                                                      fill="#bdc3c7"
                                                    />
                                                    <rect
                                                      x={33}
                                                      y="0.3960396039603964"
                                                      width="6.333333333333332"
                                                      height="39.603960396039604"
                                                      transform="matrix(1,0,0,1,0,0)"
                                                      fill="#bdc3c7"
                                                    />
                                                  </svg>
                                                </div>
                                              </div>
                                              <div className="col-sm-8 text-center">
                                                {/* <h5></h5> */}
                                                <span></span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-sm-6 card-block-big">
                                            <div className="row ">
                                              <div className="col-sm-4">
                                                <i className="icofont icofont-network-tower text-primary" />
                                              </div>
                                              <div className="col-sm-8 text-center">
                                                {/* <h5></h5> */}
                                                <span></span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-xl-4">
                                    <div className="card table-card widget-primary-card">
                                      <div className>
                                        <div className="row-table">
                                          <div className="col-sm-3 card-block-big">
                                            <i className="icofont icofont-star" />
                                          </div>
                                          <div className="col-sm-9">
                                            {/* <h4></h4> */}
                                            <h6> </h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card table-card widget-success-card">
                                      <div className>
                                        <div className="row-table">
                                          <div className="col-sm-3 card-block-big">
                                            <i className="icofont icofont-trophy-alt" />
                                          </div>
                                          <div className="col-sm-9">
                                            {/* <h4></h4>
                          <h6></h6> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="col-lg-6"
                                    style={{ width: "104%" }}
                                  >
                                    <div className="card-block">
                                      <DashPie style={{ width: "104%" }} />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div
                                      className="card"
                                      style={{
                                        height: "93%",
                                        marginLeft: "15%",
                                      }}
                                    >
                                      <div className="card-block">
                                        <figure className="pie-chart">
                                          <figcaption>
                                            Trainee{" "}
                                            <span
                                              style={{ color: "#4e79a7" }}
                                            />
                                            <br />
                                            Intern{" "}
                                            <span
                                              style={{ color: "#f28e2c" }}
                                            />
                                            <br />
                                            Junior software Engineers
                                            <span
                                              style={{ color: "#e15759" }}
                                            />
                                            <br />
                                            senior software Engineers
                                            <span
                                              style={{ color: "#76b7b2" }}
                                            />
                                            <br />
                                            HR
                                            <span
                                              style={{ color: "#59a14f" }}
                                            />
                                            <br />
                                            Team Leader
                                            <span
                                              style={{ color: "#edc949" }}
                                            />
                                          </figcaption>
                                        </figure>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-12">
                                    <Pie />
                                  </div>

                                  <div className="col-lg-12">
                                    <div className="row">
                                      <div className="col-md-3">
                                        <div className="card client-blocks warning-border">
                                          <div className="card-block">
                                            <h5>New Clients</h5>
                                            <ul>
                                              <li>
                                                <i className="icofont icofont-ui-user-group text-warning" />
                                              </li>
                                              <li className="text-right text-warning">
                                                23
                                              </li>
                                            </ul>
                                          </div>
                                        </div>{" "}
                                      </div>
                                      <div className="col-md-3">
                                        <div className="card client-blocks success-border">
                                          <div className="card-block">
                                            <h5>New Files</h5>
                                            <ul>
                                              <li>
                                                <i className="icofont icofont-files text-success" />
                                              </li>
                                              <li className="text-right text-success">
                                                240
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="card client-blocks dark-primary-border">
                                          <div className="card-block">
                                            <h5>New Documents</h5>
                                            <ul>
                                              <li>
                                                <i className="icofont icofont-document-folder" />
                                              </li>
                                              <li className="text-right">
                                                133
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="card client-blocks dark-primary-border">
                                          <div className="card-block">
                                            <h5>Open Projects</h5>
                                            <ul>
                                              <li>
                                                <i className="icofont icofont-ui-folder text-primary" />
                                              </li>
                                              <li className="text-right text-primary">
                                                169
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-8">
                                    <div className="row">
                                      <div className="col-lg-12">
                                        <div className="card table-1-card">
                                          <div className="card-block">
                                            <div className="table-responsive">
                                              <table className="table">
                                                <thead>
                                                  <tr className="text-capitalize">
                                                    <th>Type</th>
                                                    <th>Lead Name</th>
                                                    <th>Views</th>
                                                    <th>Favourites</th>
                                                    <th>Last Visit</th>
                                                    <th>Last Action</th>
                                                    <th>Last Date</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <a href>Buyer</a>
                                                    </td>
                                                    <td>Denish Ann</td>
                                                    <td>153</td>
                                                    <td>100</td>
                                                    <td>20</td>
                                                    <td>9.23 A.M.</td>
                                                    <td>9/27/2015</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <a
                                                        className="text-danger"
                                                        href
                                                      >
                                                        Lanload
                                                      </a>
                                                    </td>
                                                    <td>John Doe</td>
                                                    <td>121</td>
                                                    <td>100</td>
                                                    <td>20</td>
                                                    <td>6.23 A.M.</td>
                                                    <td>8/27/2015</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <a href>Buyer</a>
                                                    </td>
                                                    <td>Henry Joe</td>
                                                    <td>154</td>
                                                    <td>140</td>
                                                    <td>30</td>
                                                    <td>7.54 P.M.</td>
                                                    <td>4/30/2015</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <a
                                                        className="text-danger"
                                                        href
                                                      >
                                                        Lanload
                                                      </a>
                                                    </td>
                                                    <td>Sara Soudein</td>
                                                    <td>153</td>
                                                    <td>100</td>
                                                    <td>20</td>
                                                    <td>9.23 A.M.</td>
                                                    <td>5/20/2016</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <a href>Buyer</a>
                                                    </td>
                                                    <td>Denish Ann</td>
                                                    <td>153</td>
                                                    <td>100</td>
                                                    <td>20</td>
                                                    <td>9.23 A.M.</td>
                                                    <td>3/26/2015</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <a
                                                        className="text-info"
                                                        href
                                                      >
                                                        Lanload
                                                      </a>
                                                    </td>
                                                    <td>Stefen Joe</td>
                                                    <td>153</td>
                                                    <td>100</td>
                                                    <td>20</td>
                                                    <td>11.45 A.M.</td>
                                                    <td>5/20/2017</td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <a href>Buyer</a>
                                                    </td>
                                                    <td>Mark Backlus</td>
                                                    <td>153</td>
                                                    <td>100</td>
                                                    <td>20</td>
                                                    <td>12.40 A.M.</td>
                                                    <td>3/27/2017</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-12">
                                        <div className="card table-card group-widget">
                                          <div className>
                                            <div className="row-table">
                                              <div className="col-sm-4 bg-primary card-block-big">
                                                <i className="icofont icofont-music" />
                                                <p>1,586</p>
                                              </div>
                                              <div className="col-sm-4 bg-dark-primary card-block-big">
                                                <i className="icofont icofont-video-clapper" />
                                                <p>1,743</p>
                                              </div>
                                              <div className="col-sm-4 bg-darkest-primary card-block-big">
                                                <i className="icofont icofont-email" />
                                                <p>1,096</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-4">
                                    <div className="card">
                                      <div className="card-header">
                                        <h5>Create Your Daily Task</h5>
                                        <label className="label label-success">
                                          Today
                                        </label>
                                      </div>
                                      <div className="card-block">
                                        <div className="input-group input-group-button">
                                          <input
                                            type="text"
                                            className="form-control add_task_todo"
                                            placeholder="Create your task list"
                                            name="task-insert"
                                          />
                                          <span
                                            className="input-group-addon"
                                            id="basic-addon1"
                                          >
                                            <button
                                              id="add-btn"
                                              className=" btn-primary"
                                            >
                                              Add Task
                                            </button>
                                          </span>
                                        </div>
                                        <div className="new-task">
                                          <div className="to-do-list">
                                            <div className="checkbox-fade fade-in-primary">
                                              <label className="check-task">
                                                <input
                                                  type="checkbox"
                                                  defaultValue
                                                />
                                                <span className="cr">
                                                  <i className="cr-icon icofont icofont-ui-check txt-primary" />
                                                </span>
                                                <span>
                                                  Hey.. Attach your new file
                                                </span>
                                              </label>
                                            </div>
                                            <div className="f-right">
                                              <a
                                                href
                                                className="delete_todolist"
                                              >
                                                <i className="icofont icofont-ui-delete" />
                                              </a>
                                            </div>
                                          </div>
                                          <div className="to-do-list">
                                            <div className="checkbox-fade fade-in-primary">
                                              <label className="check-task">
                                                <input
                                                  type="checkbox"
                                                  defaultValue
                                                />
                                                <span className="cr">
                                                  <i className="cr-icon icofont icofont-ui-check txt-primary" />
                                                </span>
                                                <span>
                                                  New Attachment has error
                                                </span>
                                              </label>
                                            </div>
                                            <div className="f-right">
                                              <a
                                                href
                                                className="delete_todolist"
                                              >
                                                <i className="icofont icofont-ui-delete" />
                                              </a>
                                            </div>
                                          </div>
                                          <div className="to-do-list">
                                            <div className="checkbox-fade fade-in-primary">
                                              <label className="check-task">
                                                <input
                                                  type="checkbox"
                                                  defaultValue
                                                />
                                                <span className="cr">
                                                  <i className="cr-icon icofont icofont-ui-check txt-primary" />
                                                </span>
                                                <span>
                                                  Have to submit early
                                                </span>
                                              </label>
                                            </div>
                                            <div className="f-right">
                                              <a
                                                href
                                                className="delete_todolist"
                                              >
                                                <i className="icofont icofont-ui-delete" />
                                              </a>
                                            </div>
                                          </div>
                                          <div className="to-do-list">
                                            <div className="checkbox-fade fade-in-primary">
                                              <label className="check-task">
                                                <input
                                                  type="checkbox"
                                                  defaultValue
                                                />
                                                <span className="cr">
                                                  <i className="cr-icon icofont icofont-ui-check txt-primary" />
                                                </span>
                                                <span>
                                                  10 pages has to be completed
                                                </span>
                                              </label>
                                            </div>
                                            <div className="f-right">
                                              <a
                                                href
                                                className="delete_todolist"
                                              >
                                                <i className="icofont icofont-ui-delete" />
                                              </a>
                                            </div>
                                          </div>
                                          <div className="to-do-list">
                                            <div className="checkbox-fade fade-in-primary">
                                              <label className="check-task">
                                                <input
                                                  type="checkbox"
                                                  defaultValue
                                                />
                                                <span className="cr">
                                                  <i className="cr-icon icofont icofont-ui-check txt-primary" />
                                                </span>
                                                <span>Navigation working</span>
                                              </label>
                                            </div>
                                            <div className="f-right">
                                              <a
                                                href
                                                className="delete_todolist"
                                              >
                                                <i className="icofont icofont-ui-delete" />
                                              </a>
                                            </div>
                                          </div>
                                          <div className="to-do-list">
                                            <div className="checkbox-fade fade-in-primary">
                                              <label className="check-task">
                                                <input
                                                  type="checkbox"
                                                  defaultValue
                                                />
                                                <span className="cr">
                                                  <i className="cr-icon icofont icofont-ui-check txt-primary" />
                                                </span>
                                                <span>
                                                  Files submited successfully
                                                </span>
                                              </label>
                                            </div>
                                            <div className="f-right">
                                              <a
                                                href
                                                className="delete_todolist"
                                              >
                                                <i className="icofont icofont-ui-delete" />
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-xl-3">
                                    <div className="card social-widget-card">
                                      <div className="card-block-big bg-facebook">
                                        <h3>1165 +</h3>
                                        <span className="m-t-10">
                                          Facebook Users
                                        </span>
                                        <i className="icofont icofont-social-facebook" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-xl-3">
                                    <div className="card social-widget-card">
                                      <div className="card-block-big bg-twitter">
                                        <h3>780 +</h3>
                                        <span className="m-t-10">
                                          Twitter Users
                                        </span>
                                        <i className="icofont icofont-social-twitter" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-xl-3">
                                    <div className="card social-widget-card">
                                      <div className="card-block-big bg-linkein">
                                        <h3>998 +</h3>
                                        <span className="m-t-10">
                                          Linked In Users
                                        </span>
                                        <i className="icofont icofont-brand-linkedin" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-xl-3">
                                    <div className="card social-widget-card">
                                      <div className="card-block-big bg-google-plus">
                                        <h3>650 +</h3>
                                        <span className="m-t-10">
                                          Google Plus Users
                                        </span>
                                        <i className="icofont icofont-social-google-plus" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : null
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
