import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base";
export default class OfficialHolidayDash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      Pending: [],
      comlogo: [],
      Notification: [],
    };
  }
  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }
  componentDidMount() {
    this.GetOrgLogo();

    this.Getpending();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }
  Getall() {
    fetch(`${BASE_URLs}AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }
  Getpending() {
    fetch(`${BASE_URLs}AddOrganization/pendingcount`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Pending: data });
      });
  }

  render() {
    return (
      <div>
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                {/*  Start */}
                <div className="page-body">
                  <div className="row">
                    <div className="col-md-12 col-xl-4">
                      <div className="card counter-card-1">
                        <div className="card-block-big">
                          <div>
                            {/* <h3></h3> */}
                            <p></p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-pink"
                                role="progressbar"
                                style={{ width: "70%" }}
                                aria-valuenow={70}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-comment" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4">
                      <div className="card counter-card-2">
                        <div className="card-block-big">
                          <div>
                            {/* <h3></h3> */}
                            <p></p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-success"
                                role="progressbar"
                                style={{ width: "50%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-coffee-mug" />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4">
                      <div className="card counter-card-3">
                        <div className="card-block-big">
                          <div>
                            {/* <h3></h3> */}
                            <p></p>
                            <div className="progress ">
                              <div
                                className="progress-bar progress-bar-striped progress-xs progress-bar-default"
                                role="progressbar"
                                style={{ width: "90%" }}
                                aria-valuenow={90}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          <i className="icofont icofont-upload" />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-xl-8">
                      <div className="card">
                        <div className="card-header">
                          <button className="but btn-sm">Week</button>
                          <button className="but btn-sm">Month</button>
                          <button className="but btn-sm">Year</button>
                        </div>
                        <div className="card-block">
                          <div
                            id="morris-extra-area"
                            style={{ height: 470, position: "relative" }}
                          >
                            <svg
                              height={470}
                              version="1.1"
                              width="754.388"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              style={{
                                overflow: "hidden",
                                position: "relative",
                                left: "-0.125px",
                                top: "-0.2px",
                              }}
                            >
                              <desc
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              >
                                Created with Raphaël 2.2.0
                              </desc>
                              <defs
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <text
                                x="32.53125"
                                y="431.375"
                                textAnchor="end"
                                fontFamily="sans-serif"
                                fontSize="12px"
                                stroke="none"
                                fill="#888888"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  textAnchor: "end",
                                  fontFamily: "sans-serif",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                                fontWeight="normal"
                              >
                                <tspan
                                  dy="4.3984375"
                                  style={{
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  }}
                                >
                                  0
                                </tspan>
                              </text>
                              <path
                                fill="none"
                                stroke="#5fbeaa"
                                d="M45.03125,431.375H729.388"
                                strokeWidth="0.5"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <text
                                x="32.53125"
                                y="329.78125"
                                textAnchor="end"
                                fontFamily="sans-serif"
                                fontSize="12px"
                                stroke="none"
                                fill="#888888"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  textAnchor: "end",
                                  fontFamily: "sans-serif",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                                fontWeight="normal"
                              >
                                <tspan
                                  dy="4.3984375"
                                  style={{
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  }}
                                >
                                  50
                                </tspan>
                              </text>
                              <path
                                fill="none"
                                stroke="#5fbeaa"
                                d="M45.03125,329.78125H729.388"
                                strokeWidth="0.5"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <text
                                x="32.53125"
                                y="228.1875"
                                textAnchor="end"
                                fontFamily="sans-serif"
                                fontSize="12px"
                                stroke="none"
                                fill="#888888"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  textAnchor: "end",
                                  fontFamily: "sans-serif",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                                fontWeight="normal"
                              >
                                <tspan
                                  dy="4.3984375"
                                  style={{
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  }}
                                >
                                  100
                                </tspan>
                              </text>
                              <path
                                fill="none"
                                stroke="#5fbeaa"
                                d="M45.03125,228.1875H729.388"
                                strokeWidth="0.5"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <text
                                x="32.53125"
                                y="126.59375"
                                textAnchor="end"
                                fontFamily="sans-serif"
                                fontSize="12px"
                                stroke="none"
                                fill="#888888"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  textAnchor: "end",
                                  fontFamily: "sans-serif",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                                fontWeight="normal"
                              >
                                <tspan
                                  dy="4.3984375"
                                  style={{
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  }}
                                >
                                  150
                                </tspan>
                              </text>
                              <path
                                fill="none"
                                stroke="#5fbeaa"
                                d="M45.03125,126.59375H729.388"
                                strokeWidth="0.5"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <text
                                x="32.53125"
                                y={25}
                                textAnchor="end"
                                fontFamily="sans-serif"
                                fontSize="12px"
                                stroke="none"
                                fill="#888888"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  textAnchor: "end",
                                  fontFamily: "sans-serif",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                                fontWeight="normal"
                              >
                                <tspan
                                  dy="4.3984375"
                                  style={{
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  }}
                                >
                                  200
                                </tspan>
                              </text>
                              <path
                                fill="none"
                                stroke="#5fbeaa"
                                d="M45.03125,25H729.388"
                                strokeWidth="0.5"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <text
                                x="729.388"
                                y="443.875"
                                textAnchor="middle"
                                fontFamily="sans-serif"
                                fontSize="12px"
                                stroke="none"
                                fill="#888888"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  textAnchor: "middle",
                                  fontFamily: "sans-serif",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                                fontWeight="normal"
                                transform="matrix(1,0,0,1,0,6.8125)"
                              >
                                <tspan
                                  dy="4.3984375"
                                  style={{
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  }}
                                >
                                  2016
                                </tspan>
                              </text>
                              <text
                                x="615.3805998402556"
                                y="443.875"
                                textAnchor="middle"
                                fontFamily="sans-serif"
                                fontSize="12px"
                                stroke="none"
                                fill="#888888"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  textAnchor: "middle",
                                  fontFamily: "sans-serif",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                                fontWeight="normal"
                                transform="matrix(1,0,0,1,0,6.8125)"
                              >
                                <tspan
                                  dy="4.3984375"
                                  style={{
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  }}
                                >
                                  2015
                                </tspan>
                              </text>
                              <text
                                x="501.3731996805112"
                                y="443.875"
                                textAnchor="middle"
                                fontFamily="sans-serif"
                                fontSize="12px"
                                stroke="none"
                                fill="#888888"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  textAnchor: "middle",
                                  fontFamily: "sans-serif",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                                fontWeight="normal"
                                transform="matrix(1,0,0,1,0,6.8125)"
                              >
                                <tspan
                                  dy="4.3984375"
                                  style={{
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  }}
                                >
                                  2014
                                </tspan>
                              </text>
                              <text
                                x="387.3657995207668"
                                y="443.875"
                                textAnchor="middle"
                                fontFamily="sans-serif"
                                fontSize="12px"
                                stroke="none"
                                fill="#888888"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  textAnchor: "middle",
                                  fontFamily: "sans-serif",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                                fontWeight="normal"
                                transform="matrix(1,0,0,1,0,6.8125)"
                              >
                                <tspan
                                  dy="4.3984375"
                                  style={{
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  }}
                                >
                                  2013
                                </tspan>
                              </text>
                              <text
                                x="273.0460503194888"
                                y="443.875"
                                textAnchor="middle"
                                fontFamily="sans-serif"
                                fontSize="12px"
                                stroke="none"
                                fill="#888888"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  textAnchor: "middle",
                                  fontFamily: "sans-serif",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                                fontWeight="normal"
                                transform="matrix(1,0,0,1,0,6.8125)"
                              >
                                <tspan
                                  dy="4.3984375"
                                  style={{
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  }}
                                >
                                  2012
                                </tspan>
                              </text>
                              <text
                                x="159.0386501597444"
                                y="443.875"
                                textAnchor="middle"
                                fontFamily="sans-serif"
                                fontSize="12px"
                                stroke="none"
                                fill="#888888"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  textAnchor: "middle",
                                  fontFamily: "sans-serif",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                                fontWeight="normal"
                                transform="matrix(1,0,0,1,0,6.8125)"
                              >
                                <tspan
                                  dy="4.3984375"
                                  style={{
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  }}
                                >
                                  2011
                                </tspan>
                              </text>
                              <text
                                x="45.03125"
                                y="443.875"
                                textAnchor="middle"
                                fontFamily="sans-serif"
                                fontSize="12px"
                                stroke="none"
                                fill="#888888"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  textAnchor: "middle",
                                  fontFamily: "sans-serif",
                                  fontSize: 12,
                                  fontWeight: "normal",
                                }}
                                fontWeight="normal"
                                transform="matrix(1,0,0,1,0,6.8125)"
                              >
                                <tspan
                                  dy="4.3984375"
                                  style={{
                                    WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  }}
                                >
                                  2010
                                </tspan>
                              </text>
                              <path
                                fill="#fad0c3"
                                stroke="none"
                                d="M45.03125,431.375C73.5331000399361,405.9765625,130.5368001198083,334.8609375,159.0386501597444,329.78125C187.5405001996805,324.7015625,244.5442002795527,393.27386927154583,273.0460503194888,390.7375C301.6259876198083,388.19418177154586,358.7858622204473,312.00581822845413,387.3657995207668,309.4625C415.86764956070294,306.92613072845415,472.8713496405751,361.529296875,501.3731996805112,370.41875C529.8750497204474,379.308203125,586.8787498003195,375.4984375,615.3805998402556,380.578125C643.8824498801918,385.6578125,700.8861499600639,403.43671874999995,729.388,411.05625L729.388,431.375L45.03125,431.375Z"
                                fillOpacity="0.8"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  fillOpacity: "0.8",
                                }}
                              />
                              <path
                                fill="none"
                                stroke="#fb9678"
                                d="M45.03125,431.375C73.5331000399361,405.9765625,130.5368001198083,334.8609375,159.0386501597444,329.78125C187.5405001996805,324.7015625,244.5442002795527,393.27386927154583,273.0460503194888,390.7375C301.6259876198083,388.19418177154586,358.7858622204473,312.00581822845413,387.3657995207668,309.4625C415.86764956070294,306.92613072845415,472.8713496405751,361.529296875,501.3731996805112,370.41875C529.8750497204474,379.308203125,586.8787498003195,375.4984375,615.3805998402556,380.578125C643.8824498801918,385.6578125,700.8861499600639,403.43671874999995,729.388,411.05625"
                                strokeWidth={0}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="45.03125"
                                cy="431.375"
                                r={0}
                                fill="#fb9678"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="159.0386501597444"
                                cy="329.78125"
                                r={0}
                                fill="#fb9678"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="273.0460503194888"
                                cy="390.7375"
                                r={0}
                                fill="#fb9678"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="387.3657995207668"
                                cy="309.4625"
                                r={0}
                                fill="#fb9678"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="501.3731996805112"
                                cy="370.41875"
                                r={0}
                                fill="#fb9678"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="615.3805998402556"
                                cy="380.578125"
                                r={0}
                                fill="#fb9678"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="729.388"
                                cy="411.05625"
                                r={0}
                                fill="#fb9678"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <path
                                fill="#afb1db"
                                stroke="none"
                                d="M45.03125,431.375C73.5331000399361,423.75546875,130.5368001198083,413.59609375,159.0386501597444,400.896875C187.5405001996805,388.19765625,244.5442002795527,329.02033921853626,273.0460503194888,329.78125C301.6259876198083,330.54424546853625,358.7858622204473,399.3625453146375,387.3657995207668,406.9925C415.86764956070294,414.6016078146375,472.8713496405751,408.0084375,501.3731996805112,390.7375C529.8750497204474,373.4665625,586.8787498003195,266.28515625000006,615.3805998402556,268.82500000000005C643.8824498801918,271.36484375000003,700.8861499600639,375.4984375,729.388,411.05625L729.388,431.375L45.03125,431.375Z"
                                fillOpacity="0.8"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  fillOpacity: "0.8",
                                }}
                              />
                              <path
                                fill="none"
                                stroke="#7e81cb"
                                d="M45.03125,431.375C73.5331000399361,423.75546875,130.5368001198083,413.59609375,159.0386501597444,400.896875C187.5405001996805,388.19765625,244.5442002795527,329.02033921853626,273.0460503194888,329.78125C301.6259876198083,330.54424546853625,358.7858622204473,399.3625453146375,387.3657995207668,406.9925C415.86764956070294,414.6016078146375,472.8713496405751,408.0084375,501.3731996805112,390.7375C529.8750497204474,373.4665625,586.8787498003195,266.28515625000006,615.3805998402556,268.82500000000005C643.8824498801918,271.36484375000003,700.8861499600639,375.4984375,729.388,411.05625"
                                strokeWidth={0}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="45.03125"
                                cy="431.375"
                                r={0}
                                fill="#7e81cb"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="159.0386501597444"
                                cy="400.896875"
                                r={0}
                                fill="#7e81cb"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="273.0460503194888"
                                cy="329.78125"
                                r={0}
                                fill="#7e81cb"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="387.3657995207668"
                                cy="406.9925"
                                r={0}
                                fill="#7e81cb"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="501.3731996805112"
                                cy="390.7375"
                                r={0}
                                fill="#7e81cb"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="615.3805998402556"
                                cy="268.82500000000005"
                                r={0}
                                fill="#7e81cb"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="729.388"
                                cy="411.05625"
                                r={0}
                                fill="#7e81cb"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <path
                                fill="#0ddbe4"
                                stroke="none"
                                d="M45.03125,431.375C73.5331000399361,428.83515625,130.5368001198083,431.375,159.0386501597444,421.215625C187.5405001996805,404.706640625,244.5442002795527,299.8103988543092,273.0460503194888,299.303125C301.6259876198083,298.7944613543092,358.7858622204473,431.140125256498,387.3657995207668,417.151875C415.86764956070294,403.201844006498,472.8713496405751,195.93148437500003,501.3731996805112,187.55C529.8750497204474,179.168515625,586.8787498003195,322.16171875000003,615.3805998402556,350.1C643.8824498801918,378.03828125,700.8861499600639,395.8171875,729.388,411.05625L729.388,431.375L45.03125,431.375Z"
                                fillOpacity="0.8"
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                  fillOpacity: "0.8",
                                }}
                              />
                              <path
                                fill="none"
                                stroke="#01c0c8"
                                d="M45.03125,431.375C73.5331000399361,428.83515625,130.5368001198083,431.375,159.0386501597444,421.215625C187.5405001996805,404.706640625,244.5442002795527,299.8103988543092,273.0460503194888,299.303125C301.6259876198083,298.7944613543092,358.7858622204473,431.140125256498,387.3657995207668,417.151875C415.86764956070294,403.201844006498,472.8713496405751,195.93148437500003,501.3731996805112,187.55C529.8750497204474,179.168515625,586.8787498003195,322.16171875000003,615.3805998402556,350.1C643.8824498801918,378.03828125,700.8861499600639,395.8171875,729.388,411.05625"
                                strokeWidth={0}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="45.03125"
                                cy="431.375"
                                r={0}
                                fill="#01c0c8"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="159.0386501597444"
                                cy="421.215625"
                                r={0}
                                fill="#01c0c8"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="273.0460503194888"
                                cy="299.303125"
                                r={0}
                                fill="#01c0c8"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="387.3657995207668"
                                cy="417.151875"
                                r={0}
                                fill="#01c0c8"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="501.3731996805112"
                                cy="187.55"
                                r={0}
                                fill="#01c0c8"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="615.3805998402556"
                                cy="350.1"
                                r={0}
                                fill="#01c0c8"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                              <circle
                                cx="729.388"
                                cy="411.05625"
                                r={0}
                                fill="#01c0c8"
                                stroke="#ffffff"
                                strokeWidth={1}
                                style={{
                                  WebkitTapHighlightColor: "rgba(0, 0, 0, 0)",
                                }}
                              />
                            </svg>
                            <div
                              className="morris-hover morris-default-style"
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-xl-4">
                      <div className="card table-card widget-primary-card">
                        <div className="row-table">
                          <div className="col-sm-3 card-block-big">
                            <i className="icofont icofont-star" />
                          </div>
                          <div className="col-sm-9">
                            {/* <h4> </h4>
            <h6></h6> */}
                          </div>
                        </div>
                      </div>
                      <div className="card table-card widget-success-card">
                        <div className="row-table">
                          <div className="col-sm-3 card-block-big">
                            <i className="icofont icofont-trophy-alt" />
                          </div>
                          <div className="col-sm-9">
                            {/* <h4></h4>
            <h6></h6> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}
