import React, { Component } from "react";
import { BASE_URLs } from "../Config/Base";
export default class Export extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Addskills: [],
      SkillName: "",
      SkillDescription: "",
      Category: "",
      Notification: [],
      skillsgun: "",
    };
  }
  exportTableToExcel = (tableID, filename = "") => {
    var downloadLink;
    var dataType = "application/vnd.ms-excel";
    //var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

    // Specify file name
    filename = filename ? filename + ".xls" : "excel_data.xls";

    // Create download link element
    downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      var blob = new Blob(["\ufeff"], {
        type: dataType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = "data:" + dataType;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }
  };

  GetAddSkill() {
    let url = `${BASE_URLs}Skill/MasterGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Addskills: data,
        });
      });
  }

  componentDidMount() {
    this.GetAddSkill();
  }
  render() {
    const { Addskills } = this.state;
    return (
      <div>
        <form autoComplete="on" encType="multipart/form-data">
          <table
            id="tblData"
            className="table  table-striped table-bordered nowrap"
          >
            <thead>
              <tr>
                <th>Category</th>
                <th>Skill Name</th>
                <th>Skill Description </th>
              </tr>
            </thead>
            <tbody>
              {Addskills.map((skill) => (
                <tr>
                  <td>{skill.category}</td>
                  <td>{skill.skillName}</td>
                  <td>{skill.skillDescription}</td>
                  <td>
                    <img
                      alt="invite"
                      src="../Assets/images/invite.png"
                      tyle={{ height: "30px" }}
                    />
                  </td>
                  <td>
                    <div className="dropdown">
                      <div className="dropdown-content">
                        <a href>Edit </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onclick={this.exportTableToExcel()}>
            Export Table Data To Excel File
          </button>
          <br />

          <br />
          <br />
          <br />
          <br />
        </form>
      </div>
    );
  }
}
