import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class AddDepartmentpop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RelationshipDrop: [],
      formErrors: {},
      Department: [],
      isButtonDisabled: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };
  handleSubmit = async (e) => {
    var DepartmentName = document.getElementById("DepartmentName").value;

    e.preventDefault();
    //  const data = new FormData(e.target);
    if (this.Validation()) {
      this.setState({ isButtonDisabled: true });
      // alert(DepartmentName)

      const data = new FormData();

      data.append("AddDepartment", DepartmentName);

      var role = JSON.parse(localStorage.Role);
      const url =
        role.toString() === "Platform Admin"
          ? `${BASE_URLs}SuperAdmin/SADepartment`
          : `${BASE_URLs}Department/AddDepartment`;

      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({ isButtonDisabled: false });
        document.getElementById("Addpop").style.display = "none";
        setTimeout(function () {
          if (document.getElementById("close")) {
            document.getElementById("close").click();
          }
        }, 4000);
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 2200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        document.getElementById("Addpop").style.display = "none";
        setTimeout(function () {
          if (document.getElementById("close")) {
            document.getElementById("close").click();
          }
        }, 3000);
      }
    }
    this.GetDepartment();
  };
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;

    let formErrors = {};
    let formIsValid = true;
    var DepartmentName = document.getElementsByName("AddDepartment");

    // var dept = document.getElementById('DepartmentName').value
    var otherdepartment = document.getElementById("departmenttext").value;

    if (!DepartmentName[0].value) {
      formIsValid = false;
      formErrors["DepartmentName"] = "Department Name  is required";
    }
    // else if (DepartmentName.length < 3){
    //   formIsValid = false;
    //   formErrors["DepartmentName"] = "A Minimum of 3 characters are required" ;
    // }
    else if (DepartmentName[0].value === "Others") {
      if (!otherdepartment) {
        formIsValid = false;
        formErrors["otherdepartment"] = "Department Name is required";
      }
    }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var DepartmentName = document.getElementsByName("AddDepartment");

    var otherdepartment = document.getElementById("departmenttext").value;

    if (!DepartmentName[0].value) {
      formIsValid = false;
      formErrors["DepartmentName"] = "Department Name  is required";
    } else if (DepartmentName[0].value === "Others") {
      if (!otherdepartment) {
        formIsValid = false;
        formErrors["otherdepartment"] = "Department Name is required";
      }
    }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  GetDepartment() {
    let url = `${BASE_URLs}Department/GetMasterDepartment`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Department: data,
        });
      });
  }

  componentDidMount() {
    this.GetDepartment();
  }
  render() {
    var w = window.innerWidth;

    const { otherdepartment, DepartmentName } = this.state.formErrors;
    const { Department } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="Addpop"
          >
            <form autoComplete="off" encType="multipart/form-data">
              <button
                id="close"
                style={{
                  float: "right",
                  backgroundColor: "#a0a3a305",
                  color: "#797070",
                  border: "1px solid #a3a5a505",
                }}
                onClick={this.props.close}
              >
                <CloseIcon />
              </button>

              <div className="row" style={{ padding: "8px" }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <input
                    className="form"
                    maxLength="50"
                    id="DepartmentName"
                    onChange={(e) => {
                      this.AutoCaps(e);
                    }}
                    onKeyDown={this._handleKeyDown}
                    name="AddDepartment"
                    type="text"
                  />

                  {DepartmentName == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Add Department
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {DepartmentName}
                    </div>
                  )}
                  <input
                    type="hidden"
                    Name="otherdepartment"
                    className="form"
                    id="departmenttext"
                  />
                  {!otherdepartment == null ? null : (
                    <div style={{ color: "red", float: "left" }}>
                      {otherdepartment}
                    </div>
                  )}
                  <span className="underline" />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    onClick={this.handleSubmit}
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                    disabled={this.state.isButtonDisabled}
                  >
                    Save
                  </button>
                </div>
              </div>
              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
