import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import SideBar from "../../Common/Sidebar";
import img from "../../Assets/images/3.png";
import excel from "../../Assets/Files/Training.xlsx";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { BASE_URLs } from "../../Config/Base";
import { Image_URLs } from "../../Config/Base";

const TrainerTrainingDocuments = (props) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [data, setData] = useState([]);
  const [department, setDepartment] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const openPopup = () => {
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  function fetchItemsbyId() {
    const { trainerid } = props.location.state;
    // console.log("TrainingId", trainerid);

    let url = `${BASE_URLs}Learning/GetDocumentsUsingTrainingItems?id=${trainerid}`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        // console.log("PropsValue", trainerid);
        // console.log("DataFetchedById", data);
      })
      .catch((error) => console.error(error));
  }

  // const localhost = `https://localhost:44369/Images/${item.file_name}`; // Replace with your API localhost URL
  // const databaseName = 'Aktec'; // Replace with your database name

  // const apiEndpoint = `${localhost}/${databaseName}`;

  useEffect(() => {
    fetchData();
    // GetDocument();
    fetchItemsbyId();
  }, []);

  const fetchData = () => {
    let url = `${BASE_URLs}Learning/GetLearningTable`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOptions(data);
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex((item) => item.department === value.department) ===
            index
          );
        });
        setDepartment(filteredData);
        // console.log("check", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedOption(selectedOption);
    // console.log("Selected option:", selectedOption);
  };

  const handleClick = () => {
    const excelFilePath = img;
    const newTab = window.open(excelFilePath, "_blank");
    newTab.focus();
  };

  const handleClick1 = () => {
    const FilePath = excel;
    const newTab = window.open(FilePath, "_child");
    newTab.focus();
  };

  const GetDocument = () => {
    fetch(`${BASE_URLs}Learning/GetDocuments`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        // console.log("doc", data);
        // console.log("id", data[0].upload_id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handledownload = (data) => {
    const fileUrl = `${Image_URLs}/Images/` + data;
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", data);
        link.click();

        URL.revokeObjectURL(url);
      });
  };

  const handleOptionChange = (event) => {
    const selectedOption = event.target.value;
    // console.log("Selected option:", selectedOption);
  };

  return (
    <div>
      <SideBar active={window.location.hash} />
      <div className="pcoded-content">
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-header">
                <div className="page-header-title"></div>
              </div>
              <div className="page-body">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="glowBox">
                        <div style={{ float: "left" }}>
                          <a href onClick={() => window.history.back()}>
                            <i
                              className="icofont icofont-arrow-left"
                              style={{ fontSize: "45px", color: "white" }}
                            />
                          </a>
                        </div>
                        <h4 style={{ marginRight: "4%" }} className="title">
                          Training Documents
                        </h4>
                      </div>
                      {/* <div className="card-header-right">
                        <div className="col btnalign">
                          <select
                            className="effect-16 col-lg-2 col-sm-2 col-md-2"
                            value={selectedOption}
                            onChange={handleSelectChange}
                          >
                            <option value="">Select an option</option>
                            {options.map(option => (
                              <option key={option.department} value={option.upload_id}>
                                {option.department}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div> */}
                      <div className="card-block2">
                        <form autoComplete="off" encType="multipart/form-data">
                          <br />
                          <br />
                          <div className="container">
                            <div className="col-lg-12">
                              <div className="dt-responsive table-responsive">
                                <button
                                  type="button"
                                  className="but "
                                  onClick={openPopup}
                                  style={{
                                    height: "39.5px",
                                    width: "150px",
                                    marginTop: "-0.3%",
                                  }}
                                >
                                  Request Document
                                </button>
                                &nbsp; &nbsp; &nbsp;
                                <table
                                  id="add-row-table3"
                                  className="table nowrap"
                                >
                                  <thead>
                                    <tr>
                                      <th>Documents</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data.map((item, index) => (
                                      <tr key={index}>
                                        <td>
                                          <a
                                            href={`${Image_URLs}/Images/${item.file_name}`}
                                            id="if"
                                            value={item.file_name}
                                            title="Iframe Example"
                                            target="_blank"
                                            style={{
                                              width: "200px",
                                              height: "200px",
                                            }}
                                          >
                                            {item.file_name}
                                          </a>
                                        </td>
                                        <td
                                          onClick={() =>
                                            handledownload(item.file_name)
                                          }
                                        >
                                          <a
                                            className="download"
                                            style={{
                                              padding: "2px 15px",
                                              boxShadow:
                                                " -2px -2px 16px -2px #5f7ae14a",
                                            }}
                                          >
                                            <i
                                              title="Download Template"
                                              className="icofont icofont-download"
                                            ></i>
                                          </a>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              <br />
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="styleSelector"></div>
      </div>

      {isOpen && (
        <div className="pop">
          <div className="popinnernormal">
            <form autoComplete="off" encType="multipart/form-data">
              <button
                style={{
                  float: "right",
                  backgroundColor: "#a0a3a305",
                  color: "#797070",
                  border: "1px solid #a3a5a505",
                }}
                onClick={closePopup}
              >
                <CloseIcon />
              </button>

              <div className="row" style={{ padding: "8px" }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-8">
                  <input
                    className="form"
                    maxLength="50"
                    id="CourseName"
                    name="CourseName"
                    type="text"
                  />
                  <span style={{ color: "#a29d9d", float: "left" }}>
                    Requesting Document
                  </span>
                  <div style={{ color: "red", float: "left" }}> </div>
                  {/* <input type="hidden" name="otherdepartment" className="form" id="departmenttext" /> */}
                  <div style={{ color: "red", float: "left" }}></div>
                  <span className="underline" />
                </div>
                <div className="col-sm-4"></div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "130px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Request to Admin
                  </button>
                </div>
              </div>
            </form>
            <br />
          </div>
        </div>
      )}
    </div>
  );
};
export default TrainerTrainingDocuments;
