import { React, useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PlusOne from '@material-ui/icons/Add';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AddTrainingAddTrainer = ({ closeThePopup, array, state }) => {
  const [Trainers, setTrainers] = useState(array);
  const [filteredTrainers, setFilteredTrainers] = useState(array);

  const [search, setSearch] = useState('');

  useEffect(() => {
    console.log('array', array);
  }, []);

  // const handleSearchChange = (e) => {
  //   console.log("called");
  //   setSearch(e.target.value);
  // };

  // const filteredTrainers = Trainers.filter(
  //   (trainer) =>
  //     trainer.trainerName.toLowerCase().includes(search.toLowerCase()) ||
  //     trainer.email.toLowerCase().includes(search.toLowerCase())
  // );

  const handleSearchChange = (e) => {
    setSearch(e.target.value);

    const data = searchJobs(e.target.value);
    setFilteredTrainers(data);
    console.log('Hello World', data);
  };

  const searchJobs = (keyword) => {
    const results = [];

    // First, search for an exact match and push it to results
    const exactMatch = Trainers.find(
      (job) =>
        job.trainerName.toLowerCase().startsWith(keyword.toLowerCase()) ||
        job.email.toLowerCase().startsWith(keyword.toLowerCase())
    );
    if (exactMatch) {
      results.push(exactMatch);
    }

    // Then, search for partial matches and push them to results
    // const exactMatch = jobList.find(job => job.designation.toLowerCase().startsWith(keyword.toLowerCase()));

    const partialMatches = Trainers.filter(
      (job) =>
        (job.trainerName.toLowerCase().includes(keyword.toLowerCase()) &&
          job !== exactMatch) ||
        (job.email.toLowerCase().includes(keyword.toLowerCase()) &&
          job !== exactMatch)
    );
    results.push(...partialMatches);
    console.log('999999999999', results);
    return results;
  };

  const handleChange = (Id, i, event) => {
    let change = filteredTrainers.map((e, index) => {
      if (Id == e.trainer_UserId) {
        return { ...e, ...{ check: event.target.checked } };
      }

      return { ...e };
    });
    let change1 = Trainers.map((e, index) => {
      if (Id == e.trainer_UserId) {
        return { ...e, ...{ check: event.target.checked } };
      }

      return { ...e };
    });

    setFilteredTrainers(change);
    setTrainers(change1);
  };

  function validateCheckbox() {
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    var isChecked = false;

    checkboxes.forEach(function (checkbox) {
      if (checkbox.checked) {
        isChecked = true;
        closeThePopup();
      }
    });

    if (!isChecked) {
      toast.error('Please select at least one Trainer', {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      state(Trainers);
    }
  }

  return (
    //   <div className="pop">
    //   <div className="popinnernormal">
    //     <form autoComplete="off" encType="multipart/form-data">

    //       <button
    //         style={{
    //           float: "right",
    //           backgroundColor: "#a0a3a305",
    //           color: "#797070",
    //           border: "1px solid #a3a5a505",
    //         }}
    //         onClick={closeThePopup}
    //       >
    //         <CloseIcon />
    //       </button>
    //       <div className="TrainerPophHeader">Trainers</div>
    //       <div className="search-box">
    //             <input
    //               className="form"
    //               type="text"
    //               placeholder="Search..."
    //               onChange={handleSearchChange}
    //             />
    //           </div>
    //       <div className="row" style={{ padding: "8px" }}>
    //         {/* <div className="col-sm-8"> */}
    //           {/* <div className="TrainerPophHeader">
    //             Trainers
    //           </div> */}

    //           <div   id="TrainerPop">
    //             {filteredTrainers.length === 0 ? (
    //               "There are no ' Trainers '"
    //             ) : (
    //               <table
    //                 id="DepSheet"
    //                 className="table nowrap"
    //                 style={{ maxWidth: "100%" }}
    //               >
    //                 <thead>
    //                 </thead>
    //                 <tbody>
    //                   {filteredTrainers.map((Trainers, i) => (
    //                     <tr key={i}>
    //                       <td>
    //                         <input
    //                           type="checkbox"
    //                           value={
    //                             Trainers.trainerName +
    //                             "$$$" +
    //                             Trainers.trainer_UserId
    //                           }
    //                           checked={Trainers.check}
    //                           onChange={(e) => handleChange(Trainers, i, e)}
    //                         />
    //                       </td>
    //                       <td>
    //                         {Trainers.trainerName + " (" + Trainers.email + ") "}
    //                       </td>
    //                     </tr>
    //                   ))}
    //                 </tbody>
    //               </table>
    //             )}
    //           </div>

    //         {/* </div> */}
    //         <div className="col-sm-4"></div>

    //       </div>
    //       <div className="TrainerPopFooter">
    //             <button
    //               className="but"
    //               style={{
    //                 height: "39.5px",
    //                 width: "86.36px",
    //                 marginTop: "-0.3%",
    //               }}
    //               onClick={closeThePopup}
    //             >
    //               Add
    //             </button>
    //           </div>

    //       <div className="row">
    //         <div className="col-sm-12" style={{ textAlign: "center" }}></div>
    //       </div>
    //     </form>
    //     <br />
    //   </div>
    // </div>
    <>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div class="popup" id="myPopup">
        <div class="popup-contents">
          <div class="popup-headers">
            <b>Trainers</b>
            <br />
            <br />
            <input
              className="form"
              type="text"
              placeholder="Search..."
              onChange={handleSearchChange}
              style={{ width: '76%', marginLeft: 'auto', marginRight: 'auto' }}
            />
            <div style={{ marginTop: '5px' }}></div>
            <div class="popup-close" onClick={closeThePopup}>
              {' '}
              <CloseIcon />
            </div>
          </div>
          <div class="popup-bodyss">
            {filteredTrainers.length === 0 ? (
              <p style={{ marginTop: '23%' }}> "There are no ' Trainers '"</p>
            ) : (
              <table
                id="DepSheet"
                className="table nowrap mx-auto"
                style={{ maxWidth: '100%' }}
              >
                <thead></thead>
                <tbody>
                  {filteredTrainers.map((Trainers, i) => (
                    <tr key={i}>
                      <td>
                        <input
                          type="checkbox"
                          value={
                            Trainers.trainerName +
                            '$$$' +
                            Trainers.trainer_UserId
                          }
                          checked={Trainers.check}
                          onChange={(e) =>
                            handleChange(Trainers.trainer_UserId, i, e)
                          }
                        />
                      </td>
                      <td>
                        {Trainers.trainerName + ' (' + Trainers.email + ') '}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          <div class="popup-footers">
            <button
              className="but"
              type="button"
              id="Savebut"
              onClick={validateCheckbox}
            >
              Assign
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTrainingAddTrainer;
