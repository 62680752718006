import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import CommonSuccess from "../../Common/CommonSuccess";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../../Config/Base"
export default class SkillMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      Addskills: [],
      Id: "",
      SkillName: "",
      SkillDescription: "",
      Category: "",
      comlogo: [],
      formErrors: {},
      Notification: [],
      PopMessage: "",
      categ: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.state;
  }

  handleFormValidation() {
    //const {SkillName,SkillDescription,Category}= this.state;
    var SkillName = document.getElementById("SkillName").value;
    var SkillDescription = document.getElementById("SkillDescription").value;
    var Category = document.getElementById("Category").value;
    let formErrors = {};
    let formIsValid = true;

    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    }
    //Email
    if (!SkillName || SkillName === "") {
      formIsValid = false;
      formErrors["SkillNameErr"] = "Name is required";
    }
    if (!SkillDescription) {
      formIsValid = false;
      formErrors["SkillDescriptionErr"] = "Description is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  Getcategory() {
    let url = `${BASE_URLs}Skill/GetAdminCategory`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          categ: data,
        });
      });
  }
  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.handleFormValidation()) {
      const data = new FormData(event.target);
      let user = JSON.parse(localStorage.getItem("user"));

      const accessToken = user;
      const url = `${BASE_URLs}Skill/post`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      };

      const response = await fetch(url, options);
      const result = await response.json();

      this.setState({ PopMessage: result.message });

      if (result.status === "Success") {
        this.SuccessModel();
        setTimeout(() => {
          this.props.history.push("/MasterSkill");
        }, 2000);
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));
  };
  HandlePg() {
    var UG = document.getElementById("Category").value;

    if (UG === "others") {
      document.getElementById("pgtext").value = "";
      document.getElementById("pgtext").type = "text";
    } else {
      document.getElementById("pgtext").value = "None";
      document.getElementById("pgtext").type = "hidden";
    }
  }

  deleteSkill(id) {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}Delete?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          alert(resp);
        });
      });
    }
  }

  handleChange = (e) => {
    //const {SkillName,SkillDescription,Category}= this.state;
    var SkillName = document.getElementById("SkillName").value;
    var SkillDescription = document.getElementById("SkillDescription").value;
    var Category = document.getElementById("Category").value;
    let formErrors = {};
    let formIsValid = true;

    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    }
    //Email
    else if (!SkillName || SkillName === " ") {
      formIsValid = false;
      formErrors["SkillNameErr"] = "Name is required";
    } else if (!SkillDescription) {
      formIsValid = false;
      formErrors["SkillDescriptionErr"] = "Description is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  };
  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };
  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });
      });
  }
  componentDidMount() {
    this.GetOrgLogo();
    this.Getcategory();

    var role = JSON.parse(localStorage.Role);

    if (role === "Platform Admin") {
      document
        .getElementById("navright")
        .setAttribute("style", " display:none;");
      document
        .getElementById("mainorg")
        .setAttribute("style", "  display:none;");
    }
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }
  Getall() {
    fetch(`${BASE_URLs}AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }

  render() {
    const { Addskills } = this.state;
    const { categ } = this.state;
    const { SkillNameErr, SkillDescriptionErr, CategoryErr } =
      this.state.formErrors;
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title" style={{ marginRight: "5%" }}>
                            Add
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        {this.state.SuccessModel ? (
                          <CommonSuccess
                            id="Success"
                            message={this.state.PopMessage}
                            close={() => this.SuccessModel()}
                          />
                        ) : null}
                        <div className="card-block2">
                          {/*         <div className="j-wrapper ">
                           */}
                          <form
                            autoComplete="on"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="container">
                              <div
                                className="row"
                                id="skill-form"
                                style={{ marginTop: "41px" }}
                              >
                                <div className="col-lg-4">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <select
                                        className="form"
                                        id="Category"
                                        name="Category"
                                        onInputCapture={this.HandlePg}
                                        onChange={this.handleChange}
                                        type="text"
                                      >
                                        <option value="">Select</option>
                                        {categ.map((categ) => (
                                          <option value={categ.category}>
                                            {categ.category}
                                          </option>
                                        ))}
                                      </select>
                                      {!CategoryErr ? (
                                        <span style={{ color: "#a29d9d" }}>
                                          Category
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {CategoryErr}
                                        </div>
                                      )}
                                      <span className="underline" />
                                      <input
                                        type="hidden"
                                        className="form"
                                        id="pgtext"
                                        name="OtherCategory"
                                        onChange={this.handleChange}
                                        placeholder="Category"
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      <input
                                        className="form"
                                        id="SkillName"
                                        name="SkillName"
                                        type="text"
                                        onChange={this.handleChange}
                                      />

                                      {!SkillNameErr ? (
                                        <span style={{ color: "#a29d9d" }}>
                                          {" "}
                                          Name{" "}
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {SkillNameErr}
                                        </div>
                                      )}
                                      <span className="underline" />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      <input
                                        className="form"
                                        id="SkillDescription"
                                        name="SkillDescription"
                                        type="text"
                                        onChange={this.handleChange}
                                      />

                                      {!SkillDescriptionErr ? (
                                        <span style={{ color: "#a29d9d" }}>
                                          {" "}
                                          Description{" "}
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {SkillDescriptionErr}
                                        </div>
                                      )}
                                      <span className="underline" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div
                                  className="col-sm-12"
                                  style={{ textAlign: "center" }}
                                >
                                  <button
                                    type="submit"
                                    className="but"
                                    style={{
                                      height: "39.5px",
                                      width: "86.36px",
                                      marginTop: "-0.3%",
                                    }}
                                  >
                                    Save
                                  </button>
                                  &nbsp; &nbsp; &nbsp;
                                  <button
                                    type="reset"
                                    className=" btn-Secondary"
                                    style={{ height: "39.5px" }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                          <br /> <br /> <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
