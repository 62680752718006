import { default as Add, default as PlusOne } from '@material-ui/icons/Add';
import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from '../Common/Sidebar';
import { BASE_URLs } from '../Config/Base';
import AddTrainingAddDesignation from './AddTrainingAddDesignation';
import AddTrainingAddTrainer from './AddTrainingAddTrainer';
export default class AddTrainingpop extends Component {
  fileInputRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      RelationshipDrop: [],
      formErrors: {},
      Training: [],
      Departmentdrop: [],
      Jobdrop: [],
      department: [],
      selectedDepartment: '',
      DeptSelected: null,
      rolevalue: [],
      Designation: [],
      JobRole: [],
      AllRoles: [],
      selectedOption: '',
      selectedDuration: '',
      trainingItem: '',
      description: '',
      selectedFile: [],

      errors: {
        selectedOption: '',
        selectedDesignation: '',
        selectedDuration: '',
        trainingItem: '',
        description: '',
        selectedFile: [],
        selectedTrainer: '',
      },
      filteredDesignation: [],
      TrainerDept: [],
      TrainerUserID: [],
      AllDept: [],
      resDataValue: [],
      selectedOptions: [],
      selectedValue: [],
      TrainDesignation: [],
      selectedTrainerID: [],
      isAllSelected: false,
      isAddTrainerOpen: false,
      isAddDesignationOpen: false,
      Trainers: [],
      selectedTrainer: [],
      selectedDesignation: [],
      addButtonDisabled: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // Code By Arun
  handleButtonsClick = () => {
    // Trigger the click event of the file input element
    this.fileInputRef.current.click();
  };
  // Code By Arun

  // handleDropdownChange = (event) => {
  //   this.GetTrainers(event.target.value);
  //   const selectedOption = event.target.value;
  //   this.setState({ selectedOption: selectedOption });
  //   const { errors } = this.state;
  //   errors.selectedOption = '';
  //   console.log('selectedOption', selectedOption);
  // };
  handleDropdownChange = (event) => {
    this.GetTrainers(event.target.value);
    const selectedOption = event.target.value;
    this.setState({ selectedOption: selectedOption }, () => {
      this.setAddButtonDisabled();
    });
    const { errors } = this.state;
    errors.selectedOption = '';
    console.log('selectedOption', selectedOption);
  };

  setAddButtonDisabled = () => {
    const addButtonDisabled =
      this.state.selectedOption === null || this.state.selectedOption === '';
    this.setState({ addButtonDisabled: addButtonDisabled });
  };

  handleDesignationdropdownChange = (event) => {
    const selectedDesignation = event;
    this.setState({ selectedDesignation });
    const { errors } = this.state;
    errors.selectedDesignation = '';
  };

  handleDateChange = (event) => {
    const selectedDuration = event.target.value;
    this.setState({ selectedDuration });
    const { errors } = this.state;
    errors.selectedDuration = '';
  };
  handleInputChange = (event) => {
    const trainingItem = event.target.value;
    this.setState({ trainingItem });
    const { errors } = this.state;
    errors.trainingItem = '';
  };
  handleInputChange1 = (event) => {
    const description = event.target;
    this.setState({ description });
    const { errors } = this.state;
    errors.description = '';
  };

  // Code By Arun
  // handleFileChange = (event) => {
  //   const selectedFile = Array.from(event.target.files);
  //   this.setState({ selectedFile });
  //   const { errors } = this.state;
  //   errors.selectedFile = [];
  // };

  // handleFileChange = (event) => {
  //   const selectedFiles = this.state.selectedFile ? [...this.state.selectedFile] : [];
  //   const newFiles = Array.from(event.target.files);

  //   const updatedFiles = selectedFiles.concat(newFiles);

  //   this.setState({ selectedFile: updatedFiles });

  //   const { errors } = this.state;
  //   errors.selectedFile = [];
  // };
  //   handleFileChange = (event) => {
  //     const selectedFiles = this.state.selectedFile ? [...this.state.selectedFile] : [];
  //     const newFiles = Array.from(event.target.files);
  //     const updatedFiles = newFiles.filter(file => file.name.length <= 25);
  //     newFiles.forEach(file => {
  //         if (file.name.length > 25) {
  //             alert(`Filename "${file.name}" is too long. Maximum 15 characters allowed.`);
  //         }
  //        if(file.name === finalFiles)
  //         {
  //           alert(`Already "${file.name}" was selected.`)
  //         }
  //     });

  //     const finalFiles = selectedFiles.concat(updatedFiles);

  //     this.setState({ selectedFile: finalFiles });

  //     const { errors } = this.state;
  //     errors.selectedFile = [];
  // };

  handleFileChange = (event) => {
    const selectedFiles = this.state.selectedFile ? [...this.state.selectedFile] : [];
    const newFiles = Array.from(event.target.files);
  
    // Filter new files to check if the names are more than 25 characters
    const longFileNames = newFiles.filter((file) => file.name.length > 25);
    const shortFileNames = newFiles.filter((file) => file.name.length <= 25);
  
    let name = longFileNames.map((file) => file.name.slice(0, 10) + '...');
  
    let sts = name.length === 1 ? 'file' : 'files';
    if (name.length > 0) {
      alert(
        'These ' +
        name.length +
        ' ' +
        sts +
        ' ( ' +
        name.toString() +
        ' ) are above 25 characters'
      );
    }
  
    // Check for duplicate files in the shortFileNames and filter them out
    const duplicateFiles = shortFileNames.filter((file) =>
      selectedFiles.some((selectedFile) => selectedFile.name === file.name)
    );
  
    if (duplicateFiles.length == 1) {
      const duplicateNames = duplicateFiles.map((file) => file.name);
      alert(
        'You have already selected the following file: ' +
        duplicateNames.join(', ')
      );
    } else if (duplicateFiles.length > 1) {
      const duplicateNames = duplicateFiles.map((file) => file.name);
      alert(
        'You have already selected the following files: ' +
        duplicateNames.join(', ')
      );
    }
  
    // Filter out the duplicate files from the shortFileNames array
    const nonDuplicateFiles = shortFileNames.filter(
      (file) =>
        !selectedFiles.some((selectedFile) => selectedFile.name === file.name)
    );
  
    // Add only non-duplicate files to the state
    if (nonDuplicateFiles.length > 0) {
      this.setState((prevState) => ({
        selectedFile: [...prevState.selectedFile, ...nonDuplicateFiles],
      }));
    }
  
    const { errors } = this.state;
    errors.selectedFile = [];
  
    event.target.value = null;
  };
  

  // Code By Arun

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      selectedOption,
      selectedDesignation,
      selectedDuration,
      trainingItem,
      description,
      selectedFile,
      selectedTrainer,
      selectedTrainerID,
    } = this.state;
    const errors = {};
    console.log('selectedOptionselectedOption', this.state.errors);

    if (!selectedOption) {
      errors.selectedOption = 'Department is required';
    } else {
      // errors.selectedOption = '';
    }
    console.log('selectedDuration', selectedDuration);
    if (!selectedDuration) {
      errors.selectedDuration = 'Duration of Training is required';
    } else {
      // errors.selectedDuration = '';
    }
    console.log('selectedOption', selectedOption);
    if (!trainingItem.trim()) {
      errors.trainingItem = 'Training Topic is required';
    } else {
      // errors.trainingItem = '';
    }
    if (!description) {
      errors.description = 'Description is required';
    } else {
      // errors.description = '';
    }
    if (!selectedFile) {
      errors.selectedFile = 'Document is required';
    } else {
      // errors.selectedFile = '';
    }
    console.log('errors', errors);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      var dept = document.getElementById('Department').value;
      const data = new FormData(e.target);
      data.append('Department', this.state.selectedDepartment);

      selectedDesignation.forEach((value) => {
        data.append('Designation', value.designation);
      });
      if (selectedTrainer.length > 0) {
        selectedTrainer
          .filter((e) => e.check === true)
          .forEach((ee) => {
            data.append('Trainer', ee.trainerName);
            data.append('TrainerId', ee.trainer_UserId);
          });
      }
      if (selectedFile.length > 0) {
        for (let i = 0; i < this.state.selectedFile.length; i++) {
          data.append('Ref_Docs', this.state.selectedFile[i]);
        }
      }
      for (var pair of data.entries()) {
      }
      var role = JSON.parse(localStorage.Role);
      const url =
        role.toString() === 'Platform Admin'
          ? `${BASE_URLs}Training/AddTraining`
          : `${BASE_URLs}Training/AdminAddTraining`;
      const options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === 'Success') {
        this.SuccessModel();
      } else {
        toast.error(result.message, {
          position: 'top-center',
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));

    setTimeout(() => {
      this.props.history.push('/TrainingSchedule');
    }, 3500);
    toast.success('Topic Added Successfully', {
      position: 'top-center',
      autoClose: 3200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    this.GetTraining();
  };

  handleChange = (event) => {
    const dept = event;

    this.handleDesignationdropdownChange(dept);

    this.handleDesignationChange(dept);
  };

  handleDesignationChange = (event) => {
    const { filteredDesignation, selectedValue } = this.state;

    const isAllSelected =
      filteredDesignation.length > 0 &&
      selectedValue.length === filteredDesignation.length;

    this.setState({ isAllSelected });

    this.setState({ ...(this.state.selectedDesignation = event) });
  };

  handleValue = (e) => {
    const { filteredDesignation, selectedValue } = this.state;
    const value = e.target.value;

    if (value.includes('all')) {
      this.setState({ isAllSelected: !this.state.isAllSelected });
      const newState =
        selectedValue && selectedValue.length === filteredDesignation.length
          ? []
          : filteredDesignation;
      this.setState({ selectedValue: newState });
    } else {
      this.setState({ selectedValue: value });
      const newState =
        selectedValue.length === filteredDesignation.length ? true : false;
      if (newState) {
        this.setState({ isAllSelected: !this.state.isAllSelected });
      } else {
        this.setState({ isAllSelected: false });
      }
    }
  };
  handleTrainerChange = (event, s) => {
    let selectedID = s
      .map((d) => {
        if (event.includes(d.trainerName)) {
          return d.trainer_UserId;
        }
      })
      .filter(function (x) {
        return x !== undefined;
      });
    this.setState({ ...(this.state.selectedTrainer = event) });
    this.setState({ ...(this.state.selectedTrainerID = selectedID) });
  };

  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }

  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var TrainingName = document.getElementsByName('CategoryMaster');
    var ReferenceDocument = document.getElementsByName('Ref_Doc');

    if (!TrainingName[0].value) {
      formIsValid = false;
      formErrors['TrainingName'] = 'Training Name is required';
    }

    if (!ReferenceDocument[0].value) {
      formIsValid = false;
      formErrors['ReferenceDocument'] = 'Document Required';
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  GetTraining() {
    let url = `${BASE_URLs}Training/SAGetTraining`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;

    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Training: data,
        });
      });
  }

  GetDepartment() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Department/DepartmentGet`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex((item) => item.department === value.department) ===
            index
          );
        });

        this.setState({
          department: filteredData,
        });
      });
  }

  GetAllRole() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Team/GetAllJRole`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          AllRoles: data,
        });
      });
  }

  GetTrainers = async (selectedOption) => {
    let trainerName1 = [];
    let trainerUserID = [];
    const newTrainerDept = [];

    var dept = document.getElementById('Department').value;
    if (dept != '') {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          Accept: 'application/json',
        },
      };
      let url =
        `${BASE_URLs}Training/TrainerBasedDepartment?Department=` +
        selectedOption;

      const reqData = await fetch(url, options);
      const resData = await reqData.json();

      this.setState({ resDataValue: resData });
      resData.forEach((e) => {
        if (e.trainerName) {
          trainerName1.push(e.trainerName);
        }
        if (e.trainer_UserId) {
          trainerUserID.push(e.trainer_UserId);
        }
      });
      let final = resData.map((e) => {
        return { ...e, ...{ check: false } };
      });

      this.setState({ Trainers: final });
      this.setState({ TrainerDept: trainerName1 });
      this.setState({ TrainerUserID: trainerUserID });
    }
  };

  GetDesignation = async () => {
    let filteredDesignation1 = [];

    let url = `${BASE_URLs}Designation/GetMasterDesignation`;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    const reqData = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
    });

    const resData = await reqData.json();
    let final = resData.map((e) => {
      return { ...e, ...{ check: false } };
    });
    this.setState({ TrainDesignation: final });

    // filteredDesignation1.push("Select All")

    resData.forEach((e) => {
      if (e.designation) {
        filteredDesignation1.push(e.designation);
      }
    });

    this.setState({ filteredDesignation: filteredDesignation1 });
  };

  GetJobRole() {
    var dept = document.getElementById('Department').value;
    if (dept != '') {
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      };
      fetch(`${BASE_URLs}Team/GetJobRole?Department=` + dept, options)
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            JobRole: data,
          });
        });
    }
  }

  handleDeleteClicks = (index) => {
    // const selectedFile = [...this.state.selectedFile];
    // selectedFile.splice(index, 1);

    const [...remainingValues] = this.state.selectedFile;

    // Update the state with the remaining values











    
    remainingValues.splice(index, 1);
    this.setState({ selectedFile: remainingValues });
    console.log('remainingValuesremainingValues', remainingValues);
  };

  componentDidMount() {
    this.GetTraining();
    this.GetDepartment();
    this.GetDesignation();
    this.GetAllRole();
  }

  openAddTrainerPopup = () => {
    this.setState({ isAddTrainerOpen: !this.state.isAddTrainerOpen });
  };
  openAddDesignationPopup = () => {
    this.setState({ isAddDesignationOpen: !this.state.isAddDesignationOpen });
  };
  DeleteTrainer = (array, id) => {
    let changes = this.state.Trainers.map((e, index) => {
      if (e.trainer_UserId === id) return { ...e, ...{ check: false } };
      return { ...e };
    });
    this.setState({ selectedTrainer: changes.filter((e) => e.check === true) });
    this.setState({ Trainers: changes });
  };
  DeleteDesignation = (array, id) => {
    let changes = this.state.TrainDesignation.map((e) => {
      if (e.id === id) {
        return { ...e, ...{ check: false } };
      }
      return { ...e };
    });
    this.setState({
      selectedDesignation: changes.filter((e) => e.check === true),
    });

    let arr = changes.filter((e) => e.check === true).map((e) => e.designation);
    let final = changes.map((obj) => {
      if (!arr.includes(obj.designation)) {
        return { ...obj, ...{ check: false } };
      }
      return { ...obj };
    });
    this.setState({ TrainDesignation: final });
  };

  clearInputFields = () => {
    console.log('errors', this.state.errors);
    const updatedState = this.state.Trainers.map((e) => ({
      ...e,
      check: false,
    }));
    this.setState({ Trainers: updatedState });
    this.setState({
      selectedTrainer: updatedState.filter((e) => e.check === false),
    });

    const updatedStates = this.state.TrainDesignation.map((e) => ({
      ...e,
      check: false,
    }));
    this.setState({ TrainDesignation: updatedStates });
    this.setState({
      selectedDesignation: updatedState.filter((e) => e.check === false),
    });

    var input1 = document.getElementById('Department');
    var input2 = document.getElementById('Trainingitem');
    var input3 = document.getElementById('duration');
    var input4 = document.getElementById('description');

    input1.value = '';
    input2.value = '';
    input3.value = '';
    input4.value = '';

    this.setState({
      selectedDuration: '',
      trainingItem: '',
      description: '',
      selectedOption: '',
    });
    this.setState({
      selectedFile: [],
    });

    this.setState({
      selectedTrainer: [],
    });

    this.setState({
      selectedDesignation: [],
    });
    console.log('vvvvvvvvvvvvv', this.state.errors);

    const err = {
      selectedOption: '',
      selectedDesignation: '',
      selectedDuration: '',
      trainingItem: '',
      description: '',
    };

    this.setState({ errors: err });
  };

  render() {
    var w = window.innerWidth;

    const { ReferenceDocument, TrainingName } = this.state.formErrors;
    const {
      Training,
      Departmentdrop,
      Jobdrop,
      filteredDesignation,
      TrainerDept,
      TrainerUserID,
      TrainDesignation,
      Trainers,
      selectedTrainer,
      selectedDesignation,
    } = this.state;
    const {
      selectedOption,
      errors,
      resDataValue,
      selectedValue,
      isAllSelected,
      isAddTrainerOpen,
      isAddDesignationOpen,
    } = this.state;

    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: 'left' }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                class="icofont icofont-arrow-left"
                                style={{ fontSize: '45px', color: 'white' }}
                              />
                            </a>{' '}
                          </div>
                          <h4 style={{ marginRight: '3%' }} className="title">
                            Add Training Topic
                          </h4>
                        </div>

                        <form
                          autoComplete="off"
                          onSubmit={this.handleSubmit}
                          style={{ padding: '8px' }}
                        >
                          <div className="col-lg-12">
                            <div className="container">
                              <div className="row" style={{ padding: '8px' }}>
                                <div className="col-lg-6">
                                  <select
                                    className="effect-16"
                                    type="text"
                                    onChange={this.handleDropdownChange}
                                    name="Department"
                                    id="Department"
                                  >
                                    <option value="">Select</option>
                                    {this.state.department.map(
                                      (Departmentdrop) => (
                                        <option
                                          value={Departmentdrop.department}
                                        >
                                          {Departmentdrop.department}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  {errors.selectedOption !== undefined &&errors.selectedOption !== ''? (
                                    <span
                                      style={{ color: 'red', float: 'left' }}
                                    >
                                      {errors.selectedOption}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: '#a29d9d',
                                        float: 'left',
                                      }}
                                    >
                                      Department{' '}
                                      <span style={{ color: 'red' }}>
                                        &nbsp;*
                                      </span>
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <input
                                    className="form"
                                    maxLength="50"
                                    id="Trainingitem"
                                    name="Trainingitem"
                                    type="text"
                                    onChange={this.handleInputChange}
                                  />
                                  {errors.trainingItem !== undefined &&errors.trainingItem !== ''? (
                                    <span
                                      style={{ color: 'red', float: 'left' }}
                                    >
                                      {errors.trainingItem}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: '#a29d9d',
                                        float: 'left',
                                      }}
                                    >
                                      Training Topic
                                      <span style={{ color: 'red' }}>
                                        &nbsp;*
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="container">
                              <div className="row" style={{ padding: '8px' }}>
                                <div className="col-lg-6">
                                  <select
                                    className="effect-16"
                                    name="Duration"
                                    id="duration"
                                    type="text"
                                    onChange={this.handleDateChange}
                                  >
                                    <option value="">Select</option>
                                    <option value="1 Hours" id="duration">
                                      1 Hour
                                    </option>
                                    <option value="1.5 Hours" id="duration">
                                      1.5 Hours
                                    </option>
                                    <option value="2 Hours" id="duration">
                                      2 Hours
                                    </option>
                                    <option value="2.5 Hours" id="duration">
                                      2.5 Hours
                                    </option>
                                    <option value="3 Hours" id="duration">
                                      3 Hours
                                    </option>
                                    <option value="3.5 Hours" id="duration">
                                      3.5 Hour
                                    </option>
                                    <option value="4 Hours" id="duration">
                                      4 Hours
                                    </option>
                                    <option value="4.5 Hours" id="duration">
                                      4.5 Hours
                                    </option>
                                    <option value="5 Hours" id="duration">
                                      5 Hours
                                    </option>
                                    <option value="5.5 Hours" id="duration">
                                      5.5 Hours
                                    </option>
                                    <option value="6 Hours" id="duration">
                                      6 Hours
                                    </option>
                                    <option value="6.5 Hours" id="duration">
                                      6.5 Hours
                                    </option>
                                    <option value="7 Hours" id="duration">
                                      7 Hours
                                    </option>
                                    <option value="7.5 Hours" id="duration">
                                      7.5 Hours
                                    </option>
                                    <option value="8 Hours" id="duration">
                                      8 Hours
                                    </option>
                                    <option value="8.5 Hours" id="duration">
                                      8.5 Hours
                                    </option>
                                    <option value="9 Hours" id="duration">
                                      9 Hours
                                    </option>
                                    <option value="9.5 Hours" id="duration">
                                      9.5 Hours
                                    </option>
                                  </select>
                                  {errors.selectedDuration !== undefined && errors.selectedDuration!='' ? (
                                    <span
                                      style={{ color: 'red', float: 'left' }}
                                    >
                                      {errors.selectedDuration}
                                    </span>
                                  ) : (
                                    <span style={{ color: '#a29d9d' }}>
                                      Duration of Training{' '}
                                      <span style={{ color: 'red' }}>
                                        &nbsp;*
                                      </span>
                                    </span>
                                  )}
                                </div>

                                <div
                                  className="col-lg-6"
                                  style={{ marginTop: '6px' }}
                                >
                                  <textarea
                                    className="form"
                                    id="description"
                                    name="description"
                                    type="text"
                                    style={{ height: '33px' }}
                                    onChange={this.handleInputChange1}
                                  ></textarea>
                                  {errors.description !== undefined &&errors.description !== '' ? (
                                    <span
                                      style={{ color: 'red', float: 'left' }}
                                    >
                                      {errors.description}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        color: '#a29d9d',
                                        float: 'left',
                                      }}
                                    >
                                      Training Description{' '}
                                      <span style={{ color: 'red' }}>
                                        &nbsp;*
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Code By Arun */}

                          <div className="container">
                            <div className="row">
                              {/* Documents */}
                              <div className="col-lg-4">
                                <div
                                  style={{
                                    margin: '10px',
                                    padding: '20px',
                                    overflow: 'auto',
                                    height: '250px',
                                    backgroundColor: 'white',
                                    borderTop: '5px solid whitesmoke',
                                    borderBottom: '5px solid whitesmoke',
                                    borderLeft: '5px solid whitesmoke',
                                    borderRight: '5px solid whitesmoke',
                                    boxShadow: '10px 10px 5px lightblue',
                                  }}
                                >
                                  {/* Rest of the code */}

                                  {/* <label
                                    for="Ref_Docs"
                                    style={{
                                      height: "30.5px",
                                      width: "10%",
                                      padding: "4px 12px",
                                      marginRight: "400px",
                                    }}
                                    className=" btn-Secondary"
                                  >
                                    <PlusOne
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    />
                                  </label> */}
                                  <button
                                    onClick={this.handleButtonsClick}
                                    style={{ width: '40px', height: '30px' }}
                                    type="button"
                                  >
                                    <PlusOne />
                                  </button>
                                  <input
                                    className="form"
                                    id="Ref_Doc"
                                    onFocus={(e) => (e.target.type = 'file')}
                                    hidden
                                    onChange={this.handleFileChange}
                                    multiple
                                    type="file"
                                    ref={this.fileInputRef}
                                  />
                                  {/* <button type="submit" onClick={()=>this.DeleteMutipleFiles} className="multitrash" id="deletecount" style={{float:"right",visibility:"hidden",fontSize: "17px",}}><i class="icofont icofont-delete-alt" style={{ color: "darkgray" }}></i></button>  */}

                                  <span
                                    id="deletecount"
                                    className="multitrash"
                                    style={{
                                      visibility: 'hidden',
                                      fontSize: '17px',
                                    }}
                                  ></span>
                                  <span>
                                    &nbsp;Note: Filename max 25 characters.
                                  </span>
                                  <div
                                    id="selectalldiv"
                                    style={{ visibility: 'hidden' }}
                                  >
                                    &nbsp;&nbsp;
                                    <button
                                      type="submit"
                                      className="multitrash"
                                      id="delete"
                                      onClick={this.DeleteMutipleFiles}
                                      style={{
                                        float: 'right',
                                        visibility: 'hidden',
                                      }}
                                    >
                                      <i
                                        class="icofont icofont-delete-alt"
                                        style={{ color: 'darkgray' }}
                                      ></i>
                                    </button>
                                  </div>

                                  <table
                                    id="skillsheet"
                                    className="table nowrap SkillGrid"
                                  >
                                    <thead style={{ textAlign: 'left' }}>
                                      <tr>
                                        <th style={{ position: 'relative' }}>
                                          Add Documents &nbsp;&nbsp;
                                        </th>

                                        {/* <th style={{ textAlign: "center" }}>
                                          Actions
                                        </th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.selectedFile.map(
                                        (value, index) => (
                                          // <tr>

                                          //   <td>
                                          //     {" "}
                                          //     <button
                                          //       onClick={() =>
                                          //         this.handleDeleteClicks(index)
                                          //       }
                                          //       type="submit"
                                          //       className="button-30 sk-del"
                                          //       id="deletes"

                                          //     >
                                          //       <i
                                          //         class="fa fa-trash-o"
                                          //         style={{ color: "red" }}
                                          //       ></i>
                                          //     </button>
                                          //   </td>

                                          //   <td>{value.name}</td>

                                          // </tr>

                                          <tr
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                            }}
                                          >
                                            <td>
                                              <button
                                                onClick={() =>
                                                  this.handleDeleteClicks(index)
                                                }
                                                type="button"
                                                className="button-30 sk-del"
                                              >
                                                <i class="fa fa-trash-o"></i>
                                              </button>
                                            </td>
                                            <td>{value.name}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              {/* Trainers */}
                              <div className="col-lg-4">
                                <div
                                  style={{
                                    margin: '10px',
                                    padding: '20px',
                                    overflow: 'auto',
                                    height: '250px',
                                    backgroundColor: 'white',
                                    borderTop: '5px solid whitesmoke',
                                    borderBottom: '5px solid whitesmoke',
                                    borderLeft: '5px solid whitesmoke',
                                    borderRight: '5px solid whitesmoke',
                                    boxShadow: '10px 10px 5px lightblue',
                                  }}
                                >
                                  {/* Rest of the code */}
                                  <button
                                    style={{ width: '40px', height: '30px' }}
                                    disabled={this.state.addButtonDisabled}
                                    onClick={(e) => this.openAddTrainerPopup(e)}
                                    type="button"
                                  >
                                    {' '}
                                    <Add />
                                  </button>
                                  <span
                                    id="deletecounts"
                                    className="multitrash"
                                    style={{
                                      visibility: 'hidden',
                                      fontSize: '17px',
                                    }}
                                  ></span>
                                  <div
                                    id="selectalldivs"
                                    style={{ visibility: 'hidden' }}
                                  >
                                    &nbsp;&nbsp;
                                    <button
                                      className="multitrash"
                                      id="deletes"
                                      onClick={this.DeleteMutipleTrainers}
                                      style={{
                                        float: 'right',
                                        visibility: 'hidden',
                                      }}
                                    >
                                      <i
                                        class="icofont icofont-delete-alt"
                                        style={{ color: 'darkgray' }}
                                      ></i>
                                    </button>
                                  </div>
                                  <table
                                    id="skillsheet"
                                    className="table   nowrap SkillGrid"
                                  >
                                    <thead style={{ textAlign: 'left' }}>
                                      <tr>
                                        <th style={{ position: 'relative' }}>
                                          Assign Trainers
                                        </th>
                                        <th></th>

                                        {/* <th style={{ textAlign: "center" }}>
                                          Actions
                                        </th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {selectedTrainer.map((Trainers) => (
                                        <tr
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                          }}
                                        >
                                          <td style={{ textAlign: 'center' }}>
                                            <button
                                              name="delete"
                                              className="button-30 sk-del"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.DeleteTrainer(
                                                  Trainers,
                                                  Trainers.trainer_UserId
                                                );
                                              }}
                                            >
                                              <i
                                                class="fa fa-trash-o"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </td>
                                          <td> {Trainers.trainerName}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              {/* Designation */}

                              <div className="col-lg-4">
                                <div
                                  style={{
                                    margin: '10px',
                                    padding: '20px',
                                    overflow: 'auto',
                                    height: '250px',
                                    backgroundColor: 'white',
                                    borderTop: '5px solid whitesmoke',
                                    borderBottom: '5px solid whitesmoke',
                                    borderLeft: '5px solid whitesmoke',
                                    borderRight: '5px solid whitesmoke',
                                    boxShadow: '10px 10px 5px lightblue',
                                  }}
                                >
                                  {/* Rest of the code */}
                                  <button
                                    style={{ width: '40px', height: '30px' }}
                                    onClick={(e) =>
                                      this.openAddDesignationPopup(e)
                                    }
                                    type="button"
                                  >
                                    {' '}
                                    <Add />
                                  </button>
                                  <span
                                    id="deletecountsss"
                                    className="multitrash"
                                    style={{
                                      visibility: 'hidden',
                                      fontSize: '17px',
                                    }}
                                  ></span>
                                  <div
                                    id="selectalldivsss"
                                    style={{ visibility: 'hidden' }}
                                  >
                                    &nbsp;&nbsp;
                                    <button
                                      className="multitrash"
                                      id="deletesss"
                                      style={{
                                        float: 'right',
                                        visibility: 'hidden',
                                      }}
                                    >
                                      <i
                                        class="icofont icofont-delete-alt"
                                        style={{ color: 'darkgray' }}
                                      ></i>
                                    </button>
                                  </div>
                                  <table
                                    id="skillsheet"
                                    className="table   nowrap SkillGrid"
                                  >
                                    <thead style={{ textAlign: 'left' }}>
                                      <tr>
                                        <th style={{ position: 'relative' }}>
                                          Assign to Designation &nbsp;&nbsp;
                                        </th>

                                        {/* <th style={{ textAlign: "center" }}>
                                          Actions
                                        </th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {selectedDesignation.map(
                                        (Designation) => (
                                          <tr
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                            }}
                                          >
                                            {/* <td>
                                          {" "}
                                          <input
                                            type="checkbox"
                                            //className="deletcategsss"
                                            name="deletcategsss"
                                            id="desigidcheck"
                                          />
                                        </td> */}
                                            <td style={{ textAlign: 'center' }}>
                                              <button
                                                name="delete"
                                                className="button-30 sk-del"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.DeleteDesignation(
                                                    Designation,
                                                    Designation.id
                                                  );
                                                }}
                                              >
                                                <i
                                                  class="fa fa-trash-o"
                                                  aria-hidden="true"
                                                ></i>
                                              </button>
                                            </td>
                                            <td> {Designation.designation}</td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                          {/* Code By Arun */}

                          <div className="row" style={{ padding: '12px' }}>
                            <div
                              className="col-sm-12"
                              style={{ textAlign: 'center', height: '70px' }}
                            >
                              <button
                                type="submit"
                                className="but"
                                style={{
                                  height: '39.5px',
                                  width: '86.36px',
                                  marginTop: '-0.3%',
                                }}
                              >
                                Save
                              </button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <button
                                type="button"
                                className=" but"
                                style={{ height: '39.5px' }}
                                onClick={this.clearInputFields}
                              >
                                Cancel
                              </button>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isAddTrainerOpen ? (
            <AddTrainingAddTrainer
              closeThePopup={(e) => this.openAddTrainerPopup(e)}
              array={this.state.Trainers}
              state={(state) => {
                this.setState({ Trainers: state });
                this.setState({
                  selectedTrainer: state.filter((e) => e.check === true),
                });
              }}
            />
          ) : null}
          {isAddDesignationOpen ? (
            <AddTrainingAddDesignation
              closeThePopup={(e) => this.openAddDesignationPopup(e)}
              array={this.state.TrainDesignation}
              state={(state) => {
                this.setState({ TrainDesignation: state });
                this.setState({
                  selectedDesignation: state.filter((e) => e.check === true),
                });
              }}
            />
          ) : null}
        </div>
      </>
    );
  }
}
