import CloseIcon from '@material-ui/icons/Close';
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-react-calendars/styles/material.css';
import { React, useEffect, useState } from 'react';
import DisplayTrainerScheduleData from './DisplayTrainerScheduleData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ScheduleEditTrainerpop = ({
  closeThePopup,
  array,
  SelectedtrainerArray,
  state,
  third,
  selectedTrainingTopicId,
  WholeDates,
  FinalSubmit,
  closeThePopupNoSave,
  TrainingStartDate,
  selectedDate,
  selectedEndDate,
}) => {
  const [Trainers, setTrainers] = useState([]);
  const [Train, setTrain] = useState([]);
  const [WholeTrainers, setWholeTrainers] = useState([]);
  const [AvailableTrainers, setAvailableTrainers] = useState([]);
  const [NonAvailableTrainers, setNonAvailableTrainers] = useState([]);
  const [BaseAvailableTrainers, setBaseAvailableTrainers] = useState([]);
  const [BaseNonAvailableTrainers, setNonBaseAvailableTrainers] = useState([]);

  const [WholeTrain, setWholeTrain] = useState([]);
  const [Selectall, setSelectall] = useState(false);
  const [toggleState, setToggleState] = useState(1);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [search, setSearch] = useState('');
  const [isInfoIconVisible, setInfoIconVisible] = useState(true);
  const [closeVisible, setcloseVisible] = useState(false);
  const [infoVisible, setinfoVisible] = useState(false);
  const [filterDate, setfilterDate] = useState([]);
  const [Data, setData] = useState([]);

  let SelectedDateArr = [
    {
      selectedDate: selectedDate,
      selectedEndDate: selectedEndDate,
    },
  ];
  let StartDte = [];
  let EndDte = [];

  SelectedDateArr.map((ar, index) => {
    StartDte.push(ar.selectedDate);
    EndDte.push(ar.selectedEndDate);
  });

  useEffect(() => {
    let StartTime = new Date(WholeDates[0]).getTime(); //- 5.5 * 60 * 60 * 1000
    StartTime = new Date(StartTime).toISOString();

    console.log('array------------', array);
  }, []);

  const isDateDisabled = (args) => {
    // Add your logic to determine if the date should be disabled
    // For example, disable past dates
    const currentDate = new Date(args.date);
    const today = new Date();
    return currentDate < today;
  };

  const renderDayCell = (args) => {
    if (isDateDisabled(args)) {
      args.isDisabled = true;
    }
  };

  const handleIconClick = () => {
    setInfoIconVisible((prevIsInfoIconVisible) => !prevIsInfoIconVisible);
  };

  const handleSearchChange = (e) => {
    let state = filterDate.filter(
      (trainer) =>
        trainer.trainerName
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        trainer.email.toLowerCase().includes(e.target.value.toLowerCase())
    );

    if (toggleState === 1) {
      const results = [];
      for (const day of BaseAvailableTrainers) {
        const filteredAvailableArray = [];

        for (const trainer of day.filteredAvailableArray.filter(
          (trainer) => trainer.available === true
        )) {
          if (
            trainer.trainerName
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            trainer.email.toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            filteredAvailableArray.push(trainer);
          }
        }
        if (filteredAvailableArray.length > 0) {
          const resultObject = {
            filteredAvailableArray,
            date: day.date,
            StartTime: day.StartTime,
            EndTime: day.EndTime,
          };
          results.push(resultObject);
        }
      }
      setAvailableTrainers(results);
    } else {
      const results = [];
      for (const day of BaseNonAvailableTrainers) {
        const filteredAvailableArray = [];

        for (const trainer of day.filteredAvailableArray.filter(
          (trainer) => trainer.available === false
        )) {
          if (
            trainer.trainerName
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            trainer.email.toLowerCase().includes(e.target.value.toLowerCase())
          ) {
            filteredAvailableArray.push(trainer);
          }
        }
        if (filteredAvailableArray.length > 0) {
          const resultObject = {
            filteredAvailableArray,
            date: day.date,
            StartTime: day.StartTime,
            EndTime: day.EndTime,
          };
          results.push(resultObject);
        }
      }
      setNonAvailableTrainers(results);
    }

    setSearch(e.target.value);
  };

  // .filter(
  //   (trainer) =>
  //     trainer.trainerName.toLowerCase().includes(search.toLowerCase())
  //   // trainer.email.toLowerCase().includes(search.toLowerCase())
  // );

  const handleMonthClick = (value, startdate) => {
    const selectedMonthNumber = value.getMonth() + 1;

    setSelectedMonth(value);
    setHoveredDate(new Date(startdate));
  };

  const handleRowHover = (startdate, index, trainerIndex, data) => {
    let state = AvailableTrainers.map((e, i) => {
      if (i === index) {
        let datemod = AvailableTrainers[index].filteredAvailableArray.map(
          (arr, dateind) => {
            if (dateind === trainerIndex) {
              return { ...arr, closebtn: !arr.closebtn };
            } else {
              return arr;
            }
          }
        );
        return { ...e, filteredAvailableArray: datemod };
      }
      return e;
    });

    setAvailableTrainers(state);

    setHoveredDate((hoveredDate) => !hoveredDate);
    setHoveredDate(startdate);
    setData(data);

    setinfoVisible(true);
    setcloseVisible(false);
  };

  const handleRowLeave = () => {
    setHoveredDate(null);
  };

  const toggleTab = (index) => {
    setToggleState(index);
    setHoveredDate(null);
  };

  const GetTrainer = () => {
    let url = `https://trainingapi.oneawesometeam.com/api/Training/TrainingAdd_GetTrainer?TrainingTopicId=${selectedTrainingTopicId}`;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let realfinal = data.map((e) => {
          let date = [];

          e.assigned_date != null &&
            e.assigned_date
              .split(',')
              .map((ee) => (new Date(ee) > new Date() ? date.push(ee) : null));

          return {
            ...e,
            Filtdate: date.toString(),
          };
        });

        let final = realfinal.map((e) => {
          return { ...e, check: false, closebtn: false };
        });

        setTrainers(final);
        setTrain(final);
      });
  };
  const GetTrainerMain = () => {
    let url = `https://trainingapi.oneawesometeam.com/api/Training/GetTrainersPopup?TopicId=${selectedTrainingTopicId}`;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let AvailableTrainer = [];
        let NonAvailableTrainer = [];
        let ff = null;

        data = data.map((e) => {
          if (e.startdate == null) {
            return { ...e, startdate: [], endDate: [] };
          }

          return {
            ...e,
            startdate: e.startdate.split(','),
            endDate: e.enddate.split(','),
          };
        });

        setWholeTrainers(data);
        let Assigned_TrainerId = SelectedtrainerArray.map(
          (e) => e.trainer_UserID
        );
        console.log('trainerName');
        // let IdArr = []
        // if(array[0].trainerName.split(",").length != 0 ){
        //  IdArr = array[0].trainerName.split(",").map(e=>{
        // return e.split("$$")[1]

        // })

        // }
        // console.log("IdArr",IdArr);

        let startTime =
          array[0].startDate.split('T')[0] + 'T' + array[0].startTime + ':00';
        let endTime =
          array[0].endDate.split('T')[0] + 'T' + array[0].endTime + ':00';
        console.log('data===========', data);
        data = data.map((e) => {
          let startdate = e.startdate.filter((date) => date !== startTime);
          let endDate = e.endDate.filter((date) => date !== endTime);

          if (Assigned_TrainerId.indexOf(e.trainer_UserId) !== -1) {
            return { ...e, startdate, endDate };
          }
          return e;
        });

        console.log('data', data);

        // let SelectedDateArr = ClassObj.map((ee) => {
        //     if (ee.StartTime === null) {
        //       return {
        //         selectedDate: [],
        //         selectedEndDate: [],
        //       };
        //     }
        //     return {
        //       selectedDate: AddHours(new Date(ee.StartTime)),
        //       selectedEndDate: AddHours(new Date(ee.EndTime)),
        //     };
        //   });
        console.log('SelectedDateArr', SelectedDateArr);
        // SelectedDateArr.map((ar, index) => {
        //   // let selectedDate = new Date(ar.selectedDate).toISOString();
        //   // let selectedEndDate = new Date(ar.selectedEndDate).toISOString();

        //   StartDte.push(ar.selectedDate);
        //   EndDte.push(ar.selectedEndDate);
        // });
        function AddHours(dateString) {
          const originalDate = new Date(dateString);
          const newDate = new Date(
            originalDate.getTime() + 5.5 * 60 * 60 * 1000
          );
          return newDate.toISOString();
        }
        let rr = SelectedDateArr.map((ar, index) => {
          ff = data.map((data, ind) => {
            if (data.startdate.length !== 0) {
              let Status = [];

              let date = data.startdate.map((d, i) => {
                let AStartDate = new Date(d);
                let AEndDate = new Date(data.endDate[i]);
                AStartDate = AStartDate.toISOString();
                AEndDate = AEndDate.toISOString();
                AStartDate = AddHours(AStartDate);
                AEndDate = AddHours(AEndDate);
                let selectedDate = new Date(ar.selectedDate).toISOString();
                let selectedEndDate = new Date(
                  ar.selectedEndDate
                ).toISOString();

                if (
                  AStartDate === selectedDate &&
                  AEndDate === selectedEndDate
                ) {
                  Status.push(false);
                } else if (
                  AStartDate === selectedDate ||
                  AEndDate === selectedEndDate
                ) {
                  Status.push(false);
                } else if (
                  selectedDate < AStartDate &&
                  AEndDate < selectedEndDate
                ) {
                  Status.push(false);
                } else if (
                  selectedDate === AStartDate &&
                  AEndDate < selectedEndDate
                ) {
                  Status.push(false);
                } else if (
                  selectedDate > AStartDate &&
                  AEndDate === selectedEndDate
                ) {
                  Status.push(false);
                } else if (
                  selectedDate > AStartDate &&
                  AEndDate > selectedEndDate
                ) {
                  Status.push(false);
                } else if (
                  selectedDate > AStartDate &&
                  AEndDate < selectedEndDate &&
                  selectedDate < AEndDate
                ) {
                  Status.push(false);
                } else if (
                  selectedDate < AStartDate &&
                  selectedEndDate > AStartDate &&
                  AEndDate > selectedEndDate &&
                  selectedDate < AEndDate
                ) {
                  Status.push(false);
                } else {
                  Status.push(true);
                }
              });

              return {
                ...data,
                check: false,
                available: Status.indexOf(false) === -1,
                closebtn: false,
              };
            } else {
              return {
                ...data,
                check: false,
                available: true,
                closebtn: false,
              };
            }
          });
          //selectedDate  ,selectedEndDate

          // return ff;
          if (ff.filter((e) => e.available === true).length !== 0) {
            AvailableTrainer.push({
              filteredAvailableArray: ff.filter((e) => e.available === true),
              date: selectedDate.split('T')[0],
              StartTime: TimeMod(StartDte[index].split('T')[1].slice(0, 5)),
              EndTime: TimeMod(EndDte[index].split('T')[1].slice(0, 5)),
            });
          }
          if (ff.filter((e) => e.available === false).length !== 0) {
            NonAvailableTrainer.push({
              filteredAvailableArray: ff.filter((e) => e.available === false),
              date: selectedDate.split('T')[0],
              StartTime: TimeMod(StartDte[index].split('T')[1].slice(0, 5)),
              EndTime: TimeMod(EndDte[index].split('T')[1].slice(0, 5)),
            });
          }

          let value = {
            Available: AvailableTrainer,
            NonAvailable: NonAvailableTrainer,
          };
          console.log('value////////', value);
          return value;
        });

        function TimeMod(time) {
          const Hour = time.split(':')[0];
          const minutes = time.split(':')[1];
          if (Hour > 12) {
            time = Hour - 12;
            time = time < 10 ? '0' + time : time;

            return time + ':' + minutes + ' PM';
          } else if (Hour == 0) {
            return 12 + ':' + minutes + ' AM';
          } else if (Hour == 12) {
            return 12 + ':' + minutes + ' PM';
          } else {
            return time + ' AM';
          }
        }

        // return

        // return true;

        const filteredAvailableArray = ff.filter((e) => e.available === true);
        setfilterDate(ff);
        let ddd = [];
        if (filteredAvailableArray.length !== 0) {
          ddd = WholeDates.map((e, i) => {
            return {
              filteredAvailableArray,
              date: e.split('T')[0],
              StartTime: StartDte,
              EndTime: EndDte,
            };
          });
        }
        setNonAvailableTrainers(rr[0].NonAvailable);

        let AvailState = ArrayModifier(filteredAvailableArray);
        AvailState = AvailState.map((e) => {
          return { ...e, check: false };
        });
        setAvailableTrainers(rr[0].Available);
        setBaseAvailableTrainers(rr[0].Available);
        setNonBaseAvailableTrainers(rr[0].NonAvailable);

        setWholeTrain(data);

        // setAvailableTrainers(ff);

        setWholeTrain(data);
      });
  };

  const ArrayModifier = (Array) => {
    // Filtering array1 based on the date array

    // Displaying the filtered results
    let trainerUserId = [];

    let dateee = Array.map((array) => {
      let dateArray = [];
      if (!trainerUserId.includes(array.trainer_UserId)) {
        trainerUserId.push(array.trainer_UserId);
      }

      Array.filter((e) => e.trainer_UserId === array.trainer_UserId).forEach(
        (date) => {
          dateArray.push(date.startdate);
        }
      );
      return { ...array, startdate: dateArray };
    });

    return filter(trainerUserId, dateee);
  };

  const filter = (UserIdArray, ArrayObject) => {
    let data = UserIdArray.map((final) => {
      let value = ArrayObject.filter((date1) => date1.trainer_UserId == final);

      return value[0];
    });
    return data;
  };

  useEffect(() => {
    GetTrainer();
    GetTrainerMain();
  }, []);

  const handleChange = (arr, index, event, trainerIndex, email) => {
    // let change = AvailableTrainers.map((e, index) => {
    //   if (i === index) return { ...e, ...{ check: event.target.checked } };
    //   return { ...e };
    // });

    let state = AvailableTrainers.map((e, i) => {
      if (i === index) {
        let datemod = AvailableTrainers[index].filteredAvailableArray.map(
          (arr, dateind) => {
            if (arr.email === email) {
              return { ...arr, check: !arr.check };
            } else {
              return arr;
            }
          }
        );
        return { ...e, filteredAvailableArray: datemod };
      }
      return e;
    });

    let state1 = BaseAvailableTrainers.map((e, i) => {
      if (i === index) {
        let datemod = BaseAvailableTrainers[index].filteredAvailableArray.map(
          (arr, dateind) => {
            if (arr.email === email) {
              return { ...arr, check: !arr.check };
            } else {
              return arr;
            }
          }
        );
        return { ...e, filteredAvailableArray: datemod };
      }
      return e;
    });

    // state(change);
    // state.length === state.filter((e) => e.check === true).length
    //   ? setSelectall(true)
    //   : setSelectall(false);
    setAvailableTrainers(state);
    setBaseAvailableTrainers(state1);
  };

  const handleSelectAll = (event) => {
    setSelectall(!Selectall);
    let change = Trainers.map((e, index) => {
      return { ...e, ...{ check: event.target.checked } };
    });
    change.length === change.filter((e) => e.check === true).length
      ? setSelectall(true)
      : setSelectall(false);
    setTrainers(change);
  };
  const add = (event) => {
    event.preventDefault();
    state(Trainers.filter((e) => e.check));
    third();
  };
  const validateCheckbox = (event) => {
    event.preventDefault();

    let array = [];

    let f = AvailableTrainers.map((e) => {
      let sts = false;

      if (e.filteredAvailableArray) {
        let ff = e.filteredAvailableArray.filter((e) => e.check === true);
        if (ff.length >= 1) {
          sts = true;
        }
      }
      //push false if any date has no selected trainer
      array.push(sts);
    });

    if (array.indexOf(false) !== -1) {
      toast.error('select atleast one trainer in all classes', {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      closeThePopup(AvailableTrainers, event);
    }
  };
  return (
    <div class="trainerpopup-container">
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div class="trainerpopup-content">
        <div class="trainer-popup-header">
          <span class="close-btn" onClick={closeThePopupNoSave}>
            {' '}
            <CloseIcon />
          </span>
          <div className="j-tabs-container md-tabs">
            <input id="tab1" type="radio" name="tabs" defaultChecked />
            <label
              id="supertab"
              onClick={() => toggleTab(1)}
              style={{ border: '1px solid lightgray', width: '40%' }}
              className="j-tabs-label"
              htmlFor="tab1"
              title="Available Trainers"
            >
              <span>&nbsp;&nbsp;Available Trainers&nbsp;&nbsp;</span>
            </label>

            <input id="tab9" type="radio" name="tabs" />
            <label
              id="pendingtab"
              onClick={() => toggleTab(2)}
              style={{ border: '1px solid lightgray', width: '40%' }}
              className="j-tabs-label"
              htmlFor="tab9"
              title="Non Available Trainers"
            >
              <span>&nbsp;Non Available Trainers &nbsp;&nbsp;</span>
            </label>
          </div>
        </div>

        <div class="trainerpopup-body">
          <div className="contentss-tabs">
            <div
              className={
                toggleState === 1 ? 'contentss  active-contentss' : 'contentss'
              }
              id="trainercontenttab"
              style={{ height: '365px' }}
            >
              {WholeTrainers.length === 0 ? null : (
                <div>
                  <input
                    className="form"
                    type="text"
                    placeholder="Search..."
                    onChange={handleSearchChange}
                    style={{
                      marginTop: '20px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: '80%',
                    }}
                  />
                  {AvailableTrainers.length === 0 ? (
                    "There are no ' Trainers'"
                  ) : (
                    <table id="DepSheet" className="table nowrap ms-auto">
                      <tbody>
                        {AvailableTrainers.map((Wholedata, i) => (
                          <tr key={Wholedata.trainer_UserId}>
                            <tr>
                              <td>
                                {' '}
                                <b style={{ marginLeft: '10px' }}>
                                  {' '}
                                  {Wholedata.date}
                                </b>
                                {' ( ' + Wholedata.StartTime} to{' '}
                                {Wholedata.EndTime + ' ) '}
                              </td>
                            </tr>

                            {Wholedata.filteredAvailableArray.map(
                              (e, trainIndex) => (
                                <tr>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={e.check}
                                      onChange={(event) =>
                                        handleChange(
                                          e,
                                          i,
                                          event,
                                          trainIndex,
                                          e.email
                                        )
                                      }
                                      style={{ marginLeft: '10px' }}
                                    />
                                    &nbsp; &nbsp;
                                    {e.trainerName} {' ( ' + e.email + ' ) '}
                                    &nbsp;&nbsp;
                                    <button
                                      key={e.trainer_UserId}
                                      onClick={() =>
                                        handleRowHover(
                                          e.startdate,
                                          i,
                                          trainIndex,
                                          e
                                        )
                                      }
                                      type="button"
                                      className="button-30 sk-del"
                                    >
                                      <i class="fa fa-info"></i>
                                    </button>
                                  </td>

                                  <td> </td>
                                </tr>
                              )
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
              {WholeTrainers.length === 0 || AvailableTrainers === 0 ? null : (
                <div class="trainerpopup-footer">
                  <button
                    className="but"
                    // onClick={(event) => closeThePopup(AvailableTrainers, event)}
                    onClick={(event) => validateCheckbox(event)}
                  >
                    Assign
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="contentss-tabs">
            <div
              className={
                toggleState === 2 ? 'contentss  active-contentss' : 'contentss'
              }
              style={{ height: '365px' }}
            >
              <div>
                <input
                  className="form"
                  type="text"
                  placeholder="Search..."
                  onChange={handleSearchChange}
                  style={{
                    marginTop: '20px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '80%',
                  }}
                />
                {NonAvailableTrainers.length === 0 ? (
                  'There are no  Trainers '
                ) : (
                  <table
                    id="DepSheet"
                    className="table nowrap"
                    style={{ maxWidth: '90%' }}
                  >
                    <tbody>
                      {NonAvailableTrainers.map((Wholedata, i) => (
                        <tr key={Wholedata.trainer_UserId}>
                          <tr>
                            <td>
                              {' '}
                              <b style={{ marginLeft: '10px' }}>
                                {' '}
                                {Wholedata.date}
                              </b>
                              {' ( ' + Wholedata.StartTime} to{' '}
                              {Wholedata.EndTime + ' ) '}
                            </td>
                          </tr>

                          {Wholedata.filteredAvailableArray.map(
                            (e, trainIndex) => (
                              <tr>
                                <td>
                                  &nbsp; &nbsp;
                                  {e.trainerName} {' ( ' + e.email + ' ) '}
                                  &nbsp;&nbsp;
                                  <button
                                    key={e.trainer_UserId}
                                    onClick={() =>
                                      handleRowHover(
                                        e.startdate,
                                        i,
                                        trainIndex,
                                        e
                                      )
                                    }
                                    type="button"
                                    className="button-30 sk-del"
                                  >
                                    <i class="fa fa-info"></i>
                                  </button>
                                </td>

                                <td> </td>
                              </tr>
                            )
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>

              {NonAvailableTrainers.length === 0 ? null : (
                <div class="trainerpopup-footer">
                  {toggleState === 2 ? null : (
                    <button
                      className="but"
                      onClick={(event) =>
                        closeThePopup(AvailableTrainers, event)
                      }
                    >
                      Assign
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {hoveredDate && (
          <div
            className="custom-calendar"
            style={{ position: 'absolute', top: '250px', right: '350px' }}
          >
            {' '}
            <DisplayTrainerScheduleData
              close={(e) => {
                e.preventDefault();

                setHoveredDate(false);
              }}
              date={WholeDates[0]}
              Data={Data}
            />
          </div>
        )}
      </div>

      {/* {hoveredDate && (
        <div
          className="custom-calendar"
          style={{ position: "absolute", top: "250px", right: "350px" }}
        >
          {" "}
          <DisplayTrainerScheduleData
            close={(e) => {
              e.preventDefault();

              setHoveredDate(false);
            }}
            date={WholeDates[0]}
            Data={Data}
          />
        </div>
      )} */}
    </div>
  );
};

export default ScheduleEditTrainerpop;
