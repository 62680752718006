import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReactTimeAgo from "react-time-ago";
import EditAnnouncementpop from "../../Common/EditAnnouncementpop";
import { BASE_URLs } from "../../Config/Base"
import fileImg from "../../Assets/images/document.png";
import CloseIcon from "@material-ui/icons/Close";
import onlinedot from "../../Assets/images/online-dot.png";
import { Image_URLs } from "../../Config/Base";

export default class AnnouncementPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Announcement: [],
      AllAnnouncement: [],
      EditBusinessValues: [],
      Announcementfilter: "",
      AddAnnounce: [],
      SaAnnouncement: [],
      EditModel: false,
      status: false,
      isOpen: false,
      delay: false,
      Filterpop: false,
      letters: [],
      FilterData: "",
      SeenCount:0,
      Active:[],
      Activestate:null,
      AnnouncementSeen:[],
      clickedContents:
        JSON.parse(localStorage.getItem("clickedContents")) || {},
    };
  }

  GetAnnouncement() {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(`${BASE_URLs}Announcement/GetAdminPreview`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Announcement: data,
          AllAnnouncement: data,
          // Announcementfilter: data
        });
      // console.log("Annnn",data)
      });
  }

  GetEntityActive() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Announcement/GetEActivestatus`, options)
      .then((response) => response.json())
      .then((data) => {
      
       this.setState({ Active: data });
       if (this.state.Active.length !== 0) {
        this.setState({
          Activestate: this.state.Active[0].status,
        }, () => {
          // // console.log(this.state.Activestate,'xx');
        });
      }
      });
  }
  filterfunction = (e) => {
    const Announcement = this.state.AllAnnouncement.filter(
      (item) =>
        item.title.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.category.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ Announcement, showResults: true, Announcementfilter: Announcement });
  }

  handleFilter = (letter, event) => {
    event.preventDefault();
    const Announcement = this.state.AllAnnouncement.filter((item) => {
      return (
        item.title.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.category.toLowerCase().includes(letter.toLowerCase())
      );
    });
    this.setState({ Announcement, showResults: true, FilterData: Announcement });
  };

  EditModel = (e, fn, desc, catg, img,date,uid) => {
    this.setState({
      EditBusinessValues: {
        id: e,
        title: fn,
        message: desc,
        category: catg,
        image: img,
        date:date,
        userid:uid
      },
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));

    this.GetAnnouncement();
  };

  GetSAdminAnnouncement(Id) {
    this.handleContentClick(Id)
    this.setState({
      isOpen: true
    })
    // var Id = this.props.location.state.Id;
    let url = `${BASE_URLs}Announcement/GetUserAnnouncementID?Id=` + Id;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          AddAnnounce: data,
          Sort: data,
          SaAnnouncement: data,
        });
        // console.log("user", data);
      });
  }

  Details(Id) {
    var page = {
      pathname: "/UserAnnouncementpopup",
      state: {
        Id: Id,
      },
    };
    this.props.history.push(page);
  }

  GetUserProfile() {
    let url = `${BASE_URLs}Profile/Userprofile`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Profile_Pic: data,
        });

        // //
      });
  }

  getAnnouncement = () => {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}AddOrganization/GetAdminAnnSeenCount`, options)
      .then((response) => response.json())
      .then((data) => {
       
       this.setState({ AnnouncementSeen: data });
       if (this.state.AnnouncementSeen.length !== 0) {
         this.setState({
          SeenCount: this.state.AnnouncementSeen[0].anncount,
         });
        }
      });
    
  };
  componentDidMount() {
    this.GetAnnouncement();
    this.GetUserProfile();
    this.GetEntityActive();
    setTimeout(() => {
      this.setState({
        delay: true
      })
    }, 500);

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });

    this.interval = setInterval(() => {
     this.getAnnouncement()
    }, 1000);
    //  var role =JSON.parse(localStorage.Role);
  }

  componentWillUnmount() {

    clearInterval(this.interval);
  }
  //   DeleteButtons=(id)=>{

  //     const data = new FormData();

  //      if (window.confirm("Are you sure you want to delete this record permenently?")){

  //       var options = {

  //       method: 'POST',

  //       headers:{

  //           Authorization: `Bearer ${JSON.parse(localStorage.user)}`,

  //       },
  //       body: data

  //   };
  //   fetch(`${BASE_URLs}Announcement/DeletePreviewannounce?Id="+id,options)

  //   .then(response => response.json())
  //   .then(data => {

  //     toast.success(
  //       data.message,{
  //       position: "top-center",
  //       autoClose: 3200,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       })

  //   }
  //   );
  //   this.GetAnnouncement();
  // }

  //   }

  // DeleteGroup= (Id) =>{
  //   var x = window.confirm('Are you sure you want to delete this record permenently?');
  //   if (x){
  //   fetch('${BASE_URLs}Announcement/DeletePreviewannounce?Id='+Id,
  //    { method: 'POST' })

  //   .then(() => this.setState({ status: 'Delete successful' }));

  //   window.location.reload();
  // }
  // else{
  //   return false;
  // }
  // // this.GetAnnouncement();
  // }

  DeleteButton = (created_Date) => {
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        // body: data
      };
      fetch(`${BASE_URLs}Announcement/DeletePreviewannounce?CreatedDate=` + created_Date, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetAnnouncement();
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };
  handleContentClick(id) {
    const clickedContents = { ...this.state.clickedContents };
    clickedContents[id] = true;
    localStorage.setItem("clickedContents", JSON.stringify(clickedContents));
    this.setState({ clickedContents });
  }
  bothonclick(id) {
    this.handleContentClick(id);
    this.Details(id);
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  truncateTitle(title, maxLength) {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "...";
    }
    return title;
  }
  popupopen = () => {
    this.setState({
      isOpen: true
    })
  }
  popupclose = () => {
    this.setState({
      isOpen: false
    })
  }
  getFileType(path) {
    const extension = path.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) {
      return 'image';
    } else if (['mp4', 'avi', 'mov', 'wmv', 'mkv'].includes(extension)) {
      return 'video';
    } else if (['pdf', 'xls', 'xlsx'].includes(extension)) {
      return 'document';
    } else {
      return 'file';
    }
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  handleImageClick = (url) => {
    window.open(url, '_blank');
     };

  render() {
    const { delay, Announcement, Announcementfilter, status, isOpen, SaAnnouncement, Filterpop, FilterData, } = this.state;

    const alldata = localStorage.getItem('AllAnnData')
    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body" style={{ width: '97.9%', marginLeft: '12px' }}>
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">

                  <div className="card" >
                    <div className="glowBox">
                      <div>
                        <div style={{ float: "left" }}>
                          <a
                            href
                            onClick={() => this.props.history.goBack()}
                          >
                            <i
                              className="icofont icofont-arrow-left"
                              style={{ fontSize: "45px", color: "white" }}
                            />
                          </a>{" "}
                        </div>
                      </div>
                      <h4 className="title" style={{ marginRight: "64px" }}>
                        Announcements
                      </h4>
                      {this.state.EditModel ? (
                        <EditAnnouncementpop
                          values={this.state.EditBusinessValues}
                          close={() => this.EditModel()}
                        />
                      ) : null}
                    </div>

                    {isOpen ?
                      <div className="col-lg-12">
                        <div className="popup" style={{ textAlign: 'left' }}>
                          <div className="popup-content">
                            <div className="Announcedetailspop" >
                              <i
                                className="fa fa-times"
                                aria-hidden="true"
                                style={{
                                  color: "gray",
                                  marginLeft: "90%",
                                  fontSize: "17px",
                                  cursor: 'pointer'
                                }}
                                onClick={this.popupclose}
                              ></i>
                              <br />
                              <center><h6><b>Announcement Details</b></h6></center>
                              <div className="card-block">
                                <div>
                                  {SaAnnouncement &&
                                    SaAnnouncement.map &&
                                    SaAnnouncement.map((Announcement) => (
                                      <>
                                        {Announcement.image === "-" &&
                                          Announcement.video === "-" ? (
                                          <div >
                                            <div
                                            >
                                              <span
                                                key={Announcement.id}
                                                style={{ margin: "0%" }}
                                              >

                                                <b>Subject:</b> &nbsp; <span
                                                  style={{ overflowWrap: "break-word", width: "50%", }}
                                                > {Announcement.title}</span><br /><br />
                                                <b>Description:</b> &nbsp; <span
                                                  style={{ overflowWrap: "break-word", width: "50%", }}> {Announcement.message}</span>

                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          <>
                                          <div style={{ width:"97%",height: "237px", overflow: "scroll"}}>
                                            {Announcement.image.split(',').map((imageUrl, index) => (
                                              <div style={{ display: "inline-block", marign: "10px" }} key={index}>
                                                {index === 0 && (
    <>
    <span style={{ margin: "38px" }}>
      <b>Subject:</b> &nbsp;
      <span style={{ overflowWrap: "break-word", width: "50%" }}>
        {Announcement.title}
      </span>
    </span>
    <br /><br />
    <span style={{ margin: "38px" }}>
      <b>Description:</b> &nbsp;
      <span style={{ overflowWrap: "break-word", width: "50%" }}>
        {Announcement.message}
      </span>
    </span>
    <br /><br />
    </>
)}
                                                <div style={{margin:"10px"}}>
                                                {this.getFileType(imageUrl) === 'image' ? (
                                                  
                                                  <span style={{ margin:"30px" }}>
                                                  <b>Image:&nbsp;</b>

{imageUrl.split(',').map((Url, index) => (
<img
key={index}
alt={`Image ${index}`}
src={imageUrl.trim()} 
style={{ width: "100px", height: "100px" }}
onClick={() => this.handleImageClick(Url.trim())}
/>
))}
                                                  </span>
                                                ) : this.getFileType(imageUrl) === 'video' ? (
                                                  <span style={{ display: "flex", margin:"30px" }}>
                                                    <span><b>video:</b></span>
                                                    <video
                                                      controls
                                                      style={{ width: "125px", height: '118px', marginTop: '-62px', marginLeft:"8px" }}
                                                    >
                                                      <source src={imageUrl} type="video/mp4" />
                                                      <source src={imageUrl} type="video/ogg" />
                                                    </video>
                                                  </span>
                                                ) : (
                                                  <span style={{margin:"30px"}}>
                                                    <b>File:</b> 
                                                    <img
                                                      src={fileImg}
                                                      style={{ height: "30px", width: "30px" }}
                                                    />
                                                    &nbsp;
                                                    <a
                                                      href={imageUrl}
                                                      name="picture"
                                                      readOnly
                                                      value={imageUrl}
                                                      title=""
                                                      target="_blank"
                                                    >
                                                      <span>
                                                      {imageUrl.replace(
                                                    `${Image_URLs}/Images/`,
                                                    " "
                                                  )}
                                                      </span>
                                                    </a>
                                                  </span>
                                                )}
                                                </div>
                                                </div>
                                            ))}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    ))}
                                </div>
                              </div>
                            </div> </div> </div></div> : null}

                    <div className="card-block">
                    <div
                      className="row"

                      // style={{
                      //   display: "flex",
                      //   justifyContent: "flex-start",
                      //   alignItems: "baseline",
                      //   float: "left",
                      //   marginLeft: "30px",
                      //   gap: '20px'
                      // }}
                    >
                    {this.state.Activestate===1?
                    <>
                    <div className="col btnalign">
                      <Link
                        to="/AdminAnnouncement"
                        style={{ width: "inherit", background: "white" }}
                        // id="addRow"
                        className="btn"
                      >
                        {" "}
                        <i className="fa-solid fa-plus"></i>Create New Announcements
                      </Link>
                      </div>
                      <div className="col btnalign">
                      <Link
                        to="/AllAnnouncement"
                        style={{ width: "inherit", background: "white" }}
                        // id="addRow"
                        className="btn"
                      >
                        {" "}
                        Announcements From OAT  &nbsp;
                        {this.state.SeenCount !== 0 ?  <img
                              src={onlinedot}
                              className="media-object img-circle"
                              style={{
                                marginTop: "0px",
                                width: "10px",
                              }}
                              title="Unread"
                            /> : null}
                      </Link>
                      </div>
                      </>
                      : <>
                      <div className="col btnalign">
                      <Link
                        to="/AllAnnouncement"
                        style={{ width: "inherit", background: "white" }}
                        // id="addRow"
                        className="btn"
                      >
                        {" "}
                        Announcements From OAT  &nbsp;
                        {this.state.SeenCount !== 0 ?  <img
                              src={onlinedot}
                              className="media-object img-circle"
                              style={{
                                marginTop: "0px",
                                width: "10px",
                              }}
                              title="Online"
                            /> : null}
                      </Link>
                      </div>
                      <div className="col btnalign">
                      <Link
                        to="/AdminAnnouncement"
                        style={{ width: "inherit", background: "white",visibility: 'hidden' }}
                        // id="addRow"
                        className="btn"
                      
                      >
                        {" "}
                        <i className="fa-solid fa-plus"></i>Create New Announcements
                      </Link>
                      </div>
                      </>}
                     
                      <div
                        className="col-lg-3"
                      // style={{
                      //   overflow: "scroll",
                      //   height: "73px",
                      //   textAlign: "center",
                      //   cursor: "pointer",
                      // }}
                      >
                        {/* Just for design */}
                      </div>


                      <div

                      // style={{ height: "60px" }}
                      >
                        <input
                          type="text"
                          className="Searchbox"
                          id="CNSearch"
                          placeholder="Search"
                          //  style={{marginLeft:'75px'}}
                          onKeyPress={this.handleKeyPress}
                          onClick={() => {
                            this.setState({
                              Filterpop: true,
                              Filterpop: !this.state.Filterpop,
                            });
                          }}
                          readOnly
                        />
                      </div>

                    </div>
                    <div
                                
                                style={{ height: "60px" }}
                              >
                                <input
                                  className="Searchbox"
                                  id="CNSearch1"
                                  placeholder="Search"
                                  onKeyPress={this.handleKeyPress}
                                  onKeyUp={this.myFunction}
                                  // value={filterValue}
                                  onClick={() => {
                                    this.setState({
                                      Filterpop: !this.state.Filterpop,
                                    });
                                  }}
                                  readOnly
                                />
                              </div>
                    </div>
                      {/* {alldata.length === 0 && delay ? <center>
                            <p>No Announcements</p>
                          </center> : null} */}
                      <div className="dt-responsive table-responsive"
                        id={Announcement.length <= 6 ? "UserNoAnnLength" : "Above10"}>
                        {Announcement.length === 0 && delay ?
                          <center>

                            <p>No Record</p>
                          </center>
                          : null}
                        {Announcement.length !== 0 ? (<table
                          id="add-row-table3"
                          className="table nowrap"
                          style={Filterpop?{ marginTop: "48px" }:{marginTop:'20px'}}
                        >
                          <thead id='tablepadding'><tr>
                            <th></th>
                            <th>Announcement Details</th>
                            <th>Category</th>
                            <th><span style={{ marginLeft: "12px" }}>Actions</span></th></tr>
                          </thead>

                          <tbody id='tablepadding'>

                            <>
                              {Announcement.map((Announcement) => (
                                <>

                                  <tr>
                                    <td></td>

                                    <td>
                                      <span>Subject:&nbsp;</span>
                                      <a href="#" onClick={(event) => {
                                        event.preventDefault();
                                        this.GetSAdminAnnouncement(Announcement.id);
                                      }}>{this.truncateTitle(Announcement.title, 85)}</a>
                                      {/* <a>Title:&nbsp;{this.truncateTitle(Announcement.title, 85)}</a> */}

                                      &nbsp;<span
                                        style={{ cursor: 'pointer',fontSize: "12px"  }}
                                        onClick={() =>

                                          this.GetSAdminAnnouncement(Announcement.id)
                                        }>See More</span>
                                      &nbsp;&nbsp;&nbsp;
                                      <ReactTimeAgo
                                        date={Announcement.created_Date}
                                        locale="en-US"
                                        style={{ color: "gray", fontSize: "12px" }}
                                      />

                                    </td>
                                    <td>{Announcement.category}</td>
                                    <td>
                                      {/* <button
                                                  className="button-30"
                                                  type="button"
                                                  style={{
                                                    color: "rgb(94, 217, 135)",
                                                  }}
                                                  onClick={() =>
                                          // this.bothonclick(Announcement.id)
                                        
                                          this.GetSAdminAnnouncement(Announcement.id)
                                        }
                                                  title="Details"
                                                >
                                               
                                                  <i className="fa fa-info-circle"></i>
                                                </button>
                                     
                                      &nbsp;&nbsp;&nbsp;{" "} */}


                                      <button
                                        className="Annbuttons sk-del"
                                        title="Delete"
                                        type="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.DeleteButton(Announcement.created_Date);
                                        }}
                                      >
                                        <i
                                          className="fa fa-minus"
                                          aria-hidden="true"
                                        ></i>
                                      </button> &nbsp;&nbsp;
                                      <button
                                        className="Annbuttons sk-edit"
                                        title="Edit"
                                        type="button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.EditModel(
                                            Announcement.id,
                                            Announcement.title,
                                            Announcement.message,
                                            Announcement.category,
                                            Announcement.image,
                                            Announcement.created_Date,
                                            Announcement.userId
                                          );
                                        }}
                                      >
                                        <i
                                          className="fa fa-edit"
                                          aria-hidden="true"
                                        ></i>{" "}
                                      </button>
                                    </td>

                                  </tr>
                                </>
                              ))}
                            </>
                          </tbody>
                        </table>) : null}
                      </div> 


                    {/* <div style={{ textAlign: "center" }}>
                            <button
                              href
                              onClick={() => this.props.history.goBack()}
                              type="button"
                              style={{
                                width: "130px",
                                backgroundColor: "#7f63efde",
                                color: "white",
                              }}
                              className="dropdown_item-2"
                              className="btn "
                            >
                              Back
                            </button>{" "}
                          </div>  */}

                    <br />
                  </div>
                </div>


              </div>
              {Filterpop && (
                <div className="popSearchTeam">
                  <div className="popSearchinnernormalTeam">
                    <div
                      className="popSearchinnertitle"
                      id="popSearchinnernormal"
                    >
                      <button
                        id="close"
                        style={{
                          float: "right",
                          backgroundColor: "#a0a3a305",
                          color: "#797070",
                          border: "1px solid #a3a5a505",
                          marginTop: "-20px",
                          marginRight: "-20px",
                        }}
                        onClick={() => {
                          this.setState({ Filterpop: false });
                          this.GetAnnouncement();
                        }}
                      >
                        <CloseIcon />
                      </button>

                      <div className="checkboxes">
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{
                            fontSize: "20px",
                            color: "gray",
                            marginLeft: "-1%",
                            position: "absolute",
                            marginTop: "0.5%",
                          }}
                        ></i>
                        <input
                          className="Searchbox"
                          placeholder="Search"
                          onChange={this.filterfunction}
                          style={{ float: "right", width: "80%" }}
                        />
                        <br></br>
                        {this.state.letters.map(
                          (letter, index) => (
                            <button
                              className="filter"
                              key={index}
                              onClick={(event) =>
                                this.handleFilter(
                                  letter,
                                  event
                                )
                              }
                            >
                              {letter}
                            </button>
                          )
                        )}
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
