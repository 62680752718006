import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessPopup1 from "./SuccessPopup1";
import { Button } from "@material-ui/core";
import $ from "jquery";
import GroupPop from "./GroupPop";
import { Announcement } from "@material-ui/icons";
import { Link } from "react-router-dom";
import SearchPopup from "./SearchPopup";
import msg from "../Assets/images/msg.png";
import Profile from "../Assets/images/profile.png";
import ReactTimeAgo from "react-time-ago";
import Picker from "emoji-picker-react";
import onlineimg from "../Assets/images/online.png";
import offlineimg from "../Assets/images/offlineimg.png";
import doubletick from "../Assets/images/double-check.webp";
import singletick from "../Assets/images/single-check.png";
import onlinedot from "../Assets/images/online-dot.png";
import offlinedot from "../Assets/images/offline-dot.png";
import verified from "../Assets/images/Verified.png";
import publicpng from "../Assets/images/public.png";
import lockpng from "../Assets/images/lockpng.png";
import userpng from "../Assets/images/userpng.png";
import closepng from "../Assets/images/closepng.png";
import DragDrop from "./DragDrop";
import Picturepop from "./Picturepop";
import OneonOneDragDrop from "./OneonOneDragDrop";
import File1 from "../Assets/images/document.png";
import OneonOnePicturepop from "./OneonOnePicturepop";
import UserPicturepop from "./UserPicturepop";
import UserFilepop from "./UserFilepop";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URLs } from "../Config/Base";
import { Image_URLs } from "../Config/Base";
import video from "../Assets/images/videoplay.png";
export default class LastGroup extends Component {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();

    this.state = {
      Announcements: [],
      AllAnnouncement: [],
      showing: true,
      attachMenu: false,
      profile: [],
      EditSkillModel: false,
      EditSkillValues: [],
      SearchModel: false,
      SearchValues: [],
      Id: "",
      group_Name: "",
      isButton1Visible: true,
      isButton2Visible: true,
      allmessage: "",
      usermsgId: "",
      receivemsg: "",
      Skillname: "",
      User: [],
      Alluser: [],
      User1: [],
      Alluser1: [],
      NonUsers: [],
      NonUsers1: [],
      Deleted: [],
      childVisible: false,
      SideBarOpen: true,
      EntityMembers: [],
      EntityMemberUsers: [],
      AllEntityMemberUsers: [],
      AllEntityMembers: [],
      Chatpop: false,
      Minimized: false,
      Name: "",
      Email: "",
      message: [],
      message: "",
      picture_Path: null,
      document_Path: null,
      ReceivepopMsg: [],
      Popmsg: [],
      showEmoji: false,
      RoleA: "",
      UserMail: "",
      UserId: "",
      OneonOne: [],
      OneonOneCount: [],
      ReceivepopMsgUser: [],
      loginUser: "",
      USERNAME: "",
      AdminName: [],
      AdminNameA: [],
      notificationCount: 0,
      notificationClosed: 0,
      individualCount: 0,
      groupCount: 0,
      publicCount: 0,
      checkPrivacyandCreated: [],
      checkPrivacyandCreatedPublic: [],
      CheckCreatedName: [],
      checkFilter: false,
      trendList: [],
      MutedDisc: [],
      AllMutedDisc: [],
      MuteMessage: [],
      Filterpop: false,
      letters: [],
      isdocumentpopOpen: false,
      Documentpopup: false,
      ispicturepopOpen: false,
      Picturepopup: false,
      OneonOneAdmin: [],
      DName: false,
      UserProfilePic: "",
      activeTab: "tab1",

      MsgMenu: false,
      deleteId: "",
      Forwardmembers: [],
      AllForwardmembers: [],
      forwardpopup: false,
      selectedUserIds: [],
      selectedUsers: [],
      selectedUserName: [],
      Addmembers: [],
      AllAddmembers: [],
      selectedUsersA: [],
      deletemsg: "",
      Forwardpic: "",
      Forwarddoc: "",
      Deletepop: false,
      deletesender: "",
      delparticularId: "",
      deletedate: "",
      deleteEvery: "",
      ICMuteDisc: false,
      deletemsg: "",
      Popmsguserside: [],
      ReceivepopMsgUserSide: [],
      ReceivepopMsgUsersideA: [],
      Time: "",
      MailId: "",
      createdby: "",
      MemberAccess: [],
      AllMemberAccess: [],
      NonAccess: [],
      AllNonAccess: [],
      EditInput: false,
      ICcountinterval: null,
      ICcountTabinterval: null,
    };
    this.initialState = this.state;

    this.Chatpop = this.Chatpop.bind(this);
    this.hideChatpop = this.hideChatpop.bind(this);
    this.Hidechatheader = this.Hidechatheader.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.check = this.check.bind(this);
    this.formRef = React.createRef();
    this.chatBoxRef = React.createRef();
    this.firstInputRef = React.createRef();
    this.searchForDiscRef = React.createRef();
    this.attachMenu = this.attachMenu.bind(this);
    this.Documentpop = this.Documentpop.bind(this);
    this.Picturepop = this.Picturepop.bind(this);
    this.deleteMenu = this.deleteMenu.bind(this);
    this.EditMessage = this.EditMessage.bind(this);
  }

  dot = () => {
  };
  toggleMinimize = () => {
    this.setState((prevState) => ({
      Minimized: !prevState.Minimized,
      Chatpop: false,
    }), () => {
      this.updateCheckedState();
    });
  };
  toggleMinimizeA = () => {
    this.setState({
      Chatpop: true,
      Minimized: false,
    });
  };
  onClick() {
    this.setState({ childVisible: !this.state.childVisible });
  }

  StateCheck = () => {
    clearInterval(this.ICcountTabinterval);
    this.setState({
      activeTab: "",
      Filterpop: false
    })
  }

  updateCheckedState = () => {
    const { activeTab } = this.state;
    const oneonone = document.getElementById("tab5");
    const tabA = document.getElementById("tab1");

    if (activeTab === "tab5" && oneonone) {
      oneonone.checked = true;
      if (this.ICcountTabinterval) {
        clearInterval(this.ICcountTabinterval);
      }
      this.ICcountTabinterval = setInterval(() => {
        this.OneononeUser();

        this.fetchNotificationCount();
        this.fetchclosedCount();
        this.fetchindividualCount();
        this.fetchgroupCount();
        this.fetchpublicCount();

      }, 1000);
    } else if (tabA) {
      tabA.checked = true;
      oneonone.checked = false;
      this.setState({ OneonOne: [] })
      if (this.ICcountTabinterval) {
        clearInterval(this.ICcountTabinterval);
      }
      this.ICcountTabinterval = setInterval(() => {
        this.getUserData();
        this.setState({ OneonOne: [] })
        this.fetchNotificationCount();
        this.fetchclosedCount();
        this.fetchindividualCount();
        this.fetchgroupCount();
        this.fetchpublicCount();
      }, 1000);
    }
  }

  handleTabChange = (tabId) => {
    this.setState({ activeTab: tabId });
  };

  fetchpublicCount = async () => {
    var UserEmail = JSON.parse(localStorage.getItem("email"));
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    try {
      const response = await axios.post(
        `${BASE_URLs}Notification/NotificationPublic?Email=`,
        { email: UserEmail },
        options
      );
      const data = response.data;
      this.setState({ publicCount: data.count });
      if (this.state.publicCount.length !== 0) {
        this.setState({
          publicCounts: this.state.publicCount[0].noticount,
        });
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
    }
    setInterval(1000);
  };

  documentName = (dn) => {
    this.setState({
      DName: true,
      documentname: dn,
    });
  };
  documentNameL = () => {
    setTimeout(() => {
      this.setState({
        DName: false,
      });
    }, 500);
  };

  closefuncdisc = () => {
    document.getElementById("FilterErr1").innerHTML = "";
    this.setState({ Filterpop: false });
    var tabA = document.getElementById("tab1");
    var tabB = document.getElementById("tab2");
    var tabC = document.getElementById("tab3");
    var tabE = document.getElementById("tab4");
    var tabD = document.getElementById("tab5");
    const tt6 = document.getElementById("tab6");
    var tabG = document.getElementById("tab7");

    if (tabA.checked) {
      var Alluser1 = this.state.AlluserFilter;
      this.setState({ Alluser1: Alluser1, showResults: true });
    } else if (tabB.checked) {
      var Alluser2 = this.state.Deleteduser;
      this.setState({ Deleted: Alluser2, showResults: true });
    } else if (tabC.checked) {
      var Alluser3 = this.state.Alluser1;
      this.setState({ Alluser1: Alluser3, showResults: true });
    } else if (tabE.checked) {
      var Alluser4 = this.state.Groupuser;
      this.setState({ User: Alluser4, showResults: true });
    } else if (tabD.checked) {
      var Alluser5 = this.state.OneonOneCount;
      this.setState({ OneonOne: Alluser5, showResults: true });
    } else if (tt6) {
    } else if (tabG.checked) {
      var Alluser6 = this.state.MuteMessage;
      this.setState({ MutedDisc: Alluser6, showResults: true });
    }
  };
  setFocus = () => {
    this.setState(
      (prevState) => ({
        Filterpop: !prevState.Filterpop,
      }),
      () => {
        if (this.state.Filterpop) {
          this.searchForDiscRef.current.focus();
        }
      }
    );
  };
  fetchgroupCount = async () => {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    try {
      const response = await axios.get(
        `${BASE_URLs}Notification/NotificationGroup`,
        options
      );
      const data = response.data;
  
      await this.setState({ groupCount: data.count });
  
      if (this.state.groupCount.length !== 0) {
        this.setState({
          Noticount: this.state.groupCount[0].noticount,
        });
      }
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };
  

  fetchclosedCount = async () => {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    try {
      const response = await axios.get(
        `${BASE_URLs}Notification/NotificationClosed`,
        options
      );
      const data = response.data;
  
      await this.setState({ notificationClosed: data.totalCountLeft });
  
      if (this.state.notificationClosed.length !== 0) {
        this.setState({
          NotiClosedcount: this.state.notificationClosed[0].noticount,
        });
      }
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };
  
  fetchPrivacyandCreatedby = async () => {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    try {
      const response = await axios.get(
        `${BASE_URLs}Chat/CheckPrivacyandCreatedby`,
        options
      );
      const data = response.data;
  
      await this.setState({ checkPrivacyandCreated: data });
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };
  
  fetchPrivacyandCreatedbyPublic = async () => {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    try {
      const response = await axios.get(
        `${BASE_URLs}Chat/CheckPrivacyandCreatedbyPublic`,
        options
      );
      const data = response.data;
  
      await this.setState({ checkPrivacyandCreatedPublic: data });
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };
  
  fetchCreatedName = async () => {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    try {
      const response = await axios.get(
        `${BASE_URLs}Chat/CheckCreatedName`,
        options
      );
      const data = response.data;
  
      await this.setState({ CheckCreatedName: data });
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };

  fetchNotificationCount = async () => {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    try {
      const response = await axios.get(
        `${BASE_URLs}Notification/NotificationCurrent`,
        options
      );
      const data = response.data;
  
      await this.setState({ notificationCount: data.totalCount });
  
      if (this.state.notificationCount.length !== 0) {
        this.setState({
          Noticount: this.state.notificationCount[0].noticount,
        });
      }
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };
  

  getUserData() {
    const oneononeOptions = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    const userOrgOptions = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    Promise.all([
      axios.get(`${BASE_URLs}UserBank/GetAllUserPop`, oneononeOptions),
      axios.get(`${BASE_URLs}UserBank/GetLastGroupMsg`, userOrgOptions),
      axios.get(`${BASE_URLs}UserBank/GetLastGroupNonusers`, userOrgOptions),
      axios.get(`${BASE_URLs}UserBank/GetLastGroupMsgLeft`, userOrgOptions),
    ])
      .then(([oneononeResponse, userOrgResponse, userOrgNonUserResponse, userOrgLeftResponse]) => {
        const oneononeData = oneononeResponse.data;
        const userOrgData = userOrgResponse.data;
        const userOrgNonUserData = userOrgNonUserResponse.data;
        const userOrgLeftData = userOrgLeftResponse.data;
  
        const mergedData = [
          ...oneononeData,
          ...userOrgData,
          ...userOrgNonUserData,
          ...userOrgLeftData,
        ];
  
        const sortedMergedData = mergedData.sort(
          (a, b) => new Date(b.createDateTime) - new Date(a.createDateTime)
        );
  
        this.setState({
          OneonOne: oneononeData,
          OneonOneCount: oneononeData,
          Alluser1: sortedMergedData,
          AlluserFilter: sortedMergedData,
          OneonOne: [],
          Deleted: [],
          User: [],
          MutedDisc: [],
          NonUsers: [],
          Groupuser: userOrgNonUserData,
          Deleteduser: userOrgLeftData,
        });
  
      })
      .catch((error) => {
      });
  }
  fetchindividualCount = async () => {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    try {
      const response = await axios.get(
        `${BASE_URLs}Notification/NotificationOneonone`,
        options
      );
      const data = response.data;
      this.setState({ individualCount: data.individualCount });
      if (this.state.individualCount.length !== 0) {
        this.setState({
          Noticount: this.state.individualCount[0].noticount,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setInterval(1000);
  };

  OneononeUser() {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    const fetchOneonOne = axios.get(`${BASE_URLs}UserBank/GetAllUserPop`, options);
  
    fetchOneonOne
      .then((response) => {
        const oneonOneData = response.data;
        this.setState({
          OneonOne: oneonOneData,
          OneonOneCount: oneonOneData,
          User: [],
          Alluser1: [],
          Deleted: [],
          trendList: [],
          MutedDisc: [],
          NonUsers: [],
        });
        // // console.log("oneonone", oneonOneData);
      })
      .catch((error) => {
        // Handle error if any of the promises fail
        console.error("Error fetching data:", error);
      });
  } 

  CombainedData() {
    const combineddata = [...this.state.OneonOne, ...this.state.User];

    const sortCombinedData = combineddata.sort(
      (a, b) => new Date(b.createDateTime) - new Date(a.createDateTime)
    );

  }

  UserOrg() {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    const fetchLastGroupMsg = axios.get(`${BASE_URLs}UserBank/GetLastGroupMsg`, options);
  
    fetchLastGroupMsg
      .then((response) => {
        const data = response.data;
        this.setState({
          User: data,
          Groupuser: data,
          Alluser1: [],
          OneonOne: [],
          trendList: [],
          Deleted: [],
          MutedDisc: [],
          NonUsers: [],
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  
  UserOrg1() {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    const fetchLastGroupNonusers = axios.get(`${BASE_URLs}UserBank/GetLastGroupNonusers`, options);
  
    fetchLastGroupNonusers
      .then((response) => {
        const data = response.data;
        this.setState({
          NonUsers: data,
          NonUsers1: data,
          OneonOne: [],
          Deleted: [],
          trendList: [],
          MutedDisc: [],
          User: [],
          Groupuser: [],
          Alluser1: [],
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  UserOrgTrend() {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    const fetchTrendLastGroupMsg = axios.get(`${BASE_URLs}UserBank/GetTrendLastGroupMsg`, options);
  
    fetchTrendLastGroupMsg
      .then((response) => {
        const data = response.data;
        this.setState({
          trendList: data,
          User1: [],
          Alluser1: [],
          OneonOne: [],
          User: [],
          MutedDisc: [],
          Deleted: [],
          NonUsers: [],
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  UserOrgLeft() {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    const fetchLastGroupMsgLeft = axios.get(`${BASE_URLs}UserBank/GetLastGroupMsgLeft`, options);
  
    fetchLastGroupMsgLeft
      .then((response) => {
        const data = response.data;
        this.setState({
          Deleted: data,
          Deleteduser: data,
          User1: [],
          Alluser1: [],
          OneonOne: [],
          User: [],
          trendList: [],
          MutedDisc: [],
          NonUsers: [],
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  handleButtonClick1 = () => {
    this.setState((prevState) => ({
      isButton2Visible: !prevState.isButton2Visible,
      isButton1Visible: true,
    }));
  };
  handlehide = () => {
    document.getElementById("Searchbox2").style.display = "none";
    document.getElementById("Searchbox1").style.display = "none";
  };
  EditSkillModel = (e, fn, al, uid, rmsg, em, mb, userName) => {
    //  document.getElementById("EntityList").style.display = "none";

    this.setState({
      EditSkillValues: {
        id: e,
        firstName: fn,
        allmessage: al,
        usermsgId: uid,
        receivemsg: rmsg,
        email: em,
        displayName: mb,
        userName: userName,
      },
    });

    this.setState((prevState) => ({
      EditSkillModel: !prevState.EditSkillModel,
      Chatpop: true,
    }));
  };

  EditSkillModels = (id, fn, cb, pr) => {

    this.setState({
      EditSkillValues: {
        groupId: id,
        groupName: fn,
        createdby: cb,
        privacy: pr,
      },
    });
    this.setState((prevState) => ({
      EditSkillModels: !prevState.EditSkillModels,
      Chatpop: false,
      Filterpop: false,
    }));
  };
  SearchModel = (e, fn) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      SearchValues: {
        id: e,
        Skillname: fn,
      },
    });
    this.setState((prevState) => ({
      SearchModel: !prevState.SearchModel,
    }));
  };
  GetAnnouncement(Id) {
    const options = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}UserBank/GetAdminGroup`, options)
      .then((response) => {
        const data = response.data;
        this.setState({
          Announcements: data,
          AllAnnouncement: data,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }
  myFunction = (e) => {
    const Announcements = this.state.AllAnnouncement.filter((item) =>
      item.group_Name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Announcements });
    const User = this.state.Alluser.filter((item) =>
      item.group_Name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ User });
  };

  scrollIntoView() {
    this.myRef.current?.scrollIntoView();
  }

  scrollToBottom = () => {
    if (this.chatBoxRef.current) {
      this.chatBoxRef.current.scrollTop = this.chatBoxRef.current.scrollHeight;
    }
  };

  ActiveMemberFilter = (e) => {
    const EntityMembers = this.state.AllEntityMembers.filter(
      (item) =>
        item.displayName
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ EntityMembers, showResults: true });
    if (EntityMembers.length === 0) {
      document.getElementById("FilterErr").innerHTML = "Member Not Found";
    } else {
      document.getElementById("FilterErr").innerHTML = "";
    }
  };

  LetterDiscussionFilter = (letter, e) => {
    e.preventDefault();
    var tabA = document.getElementById("tab1");
    if (this.state.RoleA === "Admin") {
      if (tabA.checked) {
        const Alluser1 = this.state.AlluserFilter.filter((item) => {
          const receiver = item.receiver || "";
          const group_Name = item.group_Name || "";

          return (
            receiver.toLowerCase().startsWith(letter.toLowerCase()) ||
            group_Name.toLowerCase().startsWith(letter.toLowerCase())
          );
        });

        this.setState({ Alluser1: Alluser1, showResults: true });

        if (Alluser1.length === 0) {
          document.getElementById("FilterErr1").innerHTML =
            "Discussion Not Found";
        } else {
          document.getElementById("FilterErr1").innerHTML = "";
        }
      }
    } else if (this.state.RoleA === "User") {
      if (tabA.checked) {
        const Alluser1 = this.state.AlluserFilter.filter((item) => {
          const receiver = item.receiver || "";
          const group_Name = item.group_Name || "";
          const firstName = item.firstName || "";
          const displayName = item.displayName || "";

          return (
            receiver.toLowerCase().startsWith(letter.toLowerCase()) ||
            group_Name.toLowerCase().startsWith(letter.toLowerCase()) ||
            firstName.toLowerCase().startsWith(letter.toLowerCase()) ||
            displayName.toLowerCase().startsWith(letter.toLowerCase())
          );
        });

        this.setState({ Alluser1: Alluser1, showResults: true });
        // // console.log("discussionss", Alluser1);

        if (Alluser1.length === 0) {
          document.getElementById("FilterErr1").innerHTML =
            "Discussion Not Found";
        } else {
          document.getElementById("FilterErr1").innerHTML = "";
        }
      }
    }

    var tabD = document.getElementById("tab5");
    if (tabD.checked) {
      const OneonOne = this.state.OneonOneCount.filter((item) => {
        const receiver = item.receiver || "";
        const displayName = item.displayName || "";
        const firstName = item.firstName || "";

        return (
          receiver.toLowerCase().startsWith(letter.toLowerCase()) ||
          displayName.toLowerCase().startsWith(letter.toLowerCase()) ||
          firstName.toLowerCase().startsWith(letter.toLowerCase())
        );
      });

      this.setState({ OneonOne, showResults: true });
      // // console.log("discussionss", OneonOne);

      if (OneonOne.length === 0) {
        document.getElementById("FilterErr1").innerHTML =
          "Discussion Not Found";
      } else {
        document.getElementById("FilterErr1").innerHTML = "";
      }
    }

    var tabE = document.getElementById("tab4");
    if (tabE.checked) {
      const User = this.state.Groupuser.filter((item) => {
        const group_Name = item.group_Name || "";

        return group_Name.toLowerCase().startsWith(letter.toLowerCase());
      });

      this.setState({ User, showResults: true });
      // // console.log("discussions1", User);

      if (User.length === 0) {
        document.getElementById("FilterErr1").innerHTML =
          "Discussion Not Found";
      } else {
        document.getElementById("FilterErr1").innerHTML = "";
      }
    }
    var tabB = document.getElementById("tab2");
    if (tabB.checked) {
      const Deleted = this.state.Deleteduser.filter((item) => {
        const group_Name = item.group_Name || "";

        return group_Name.toLowerCase().startsWith(letter.toLowerCase());
      });

      this.setState({ Deleted, showResults: true });
      // // console.log("discussionss", Deleted);

      if (Deleted.length === 0) {
        document.getElementById("FilterErr1").innerHTML =
          "Discussion Not Found";
      } else {
        document.getElementById("FilterErr1").innerHTML = "";
      }
    }
    var tabC = document.getElementById("tab3");
    if (tabC.checked) {
      const Alluser1 = this.state.Alluser1.filter((item) => {
        const group_Name = item.group_Name || "";

        return group_Name.toLowerCase().startsWith(letter.toLowerCase());
      });

      this.setState({ Alluser1, showResults: true });
      // // console.log("discussionss", Alluser1);

      if (Alluser1.length === 0) {
        document.getElementById("FilterErr1").innerHTML =
          "Discussion Not Found";
      } else {
        document.getElementById("FilterErr1").innerHTML = "";
      }
    }

    var tabG = document.getElementById("tab7");
    if (tabG.checked) {
      const MutedDisc = this.state.MuteMessage.filter((item) => {
        const group_Name = item.group_Name || "";

        return group_Name.toLowerCase().startsWith(letter.toLowerCase());
      });

      this.setState({ MutedDisc, showResults: true });
      // // console.log("discussionss", MutedDisc);

      if (MutedDisc.length === 0) {
        document.getElementById("FilterErr1").innerHTML =
          "Discussion Not Found";
      } else {
        document.getElementById("FilterErr1").innerHTML = "";
      }
    }
  };

  DiscussionFilter = (e) => {
    clearInterval(this.interval);
    var tabA = document.getElementById("tab1");
    if (this.state.RoleA === "Admin") {
      if (tabA.checked) {
        const Alluser1 = this.state.AlluserFilter.filter((item) => {
          const receiver = item.receiver || "";
          const group_Name = item.group_Name || "";
          return (
            receiver.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
            group_Name.toLowerCase().startsWith(e.target.value.toLowerCase())
          );
        });

        this.setState({ Alluser1: Alluser1, showResults: true });
        // // console.log("discussionss", Alluser1);

        if (Alluser1.length === 0) {
          document.getElementById("FilterErr1").innerHTML =
            "Discussion Not Found";
        } else {
          document.getElementById("FilterErr1").innerHTML = "";
        }
      }
    } else if (this.state.RoleA === "User") {
      if (tabA.checked) {
        const Alluser1 = this.state.AlluserFilter.filter((item) => {
          const receiver = item.receiver || "";
          const group_Name = item.group_Name || "";
          const firstName = item.firstName || "";
          const displayName = item.displayName || "";

          return (
            receiver.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
            group_Name.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
            firstName.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
            displayName.toLowerCase().startsWith(e.target.value.toLowerCase())
          );
        });

        this.setState({ Alluser1: Alluser1, showResults: true });
        // // console.log("discussionss", Alluser1);

        if (Alluser1.length === 0) {
          document.getElementById("FilterErr1").innerHTML =
            "Discussion Not Found";
        } else {
          document.getElementById("FilterErr1").innerHTML = "";
        }
      }
    }

    var tabD = document.getElementById("tab5");
    if (tabD.checked) {
      const OneonOne = this.state.OneonOneCount.filter((item) => {
        const receiver = item.receiver || "";
        const displayName = item.displayName || "";
        const firstName = item.firstName || "";

        return (
          receiver.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
          displayName.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
          firstName.toLowerCase().startsWith(e.target.value.toLowerCase())
        );
      });

      this.setState({ OneonOne, showResults: true });
      // // console.log("discussionss", OneonOne);

      if (OneonOne.length === 0) {
        document.getElementById("FilterErr1").innerHTML =
          "Discussion Not Found";
      } else {
        document.getElementById("FilterErr1").innerHTML = "";
      }
    }

    var tabE = document.getElementById("tab4");
    if (tabE.checked) {
      const User = this.state.Groupuser.filter((item) => {
        const group_Name = item.group_Name || "";

        return group_Name
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase());
      });

      this.setState({ User, showResults: true });
      // // console.log("discussions1", User);

      if (User.length === 0) {
        document.getElementById("FilterErr1").innerHTML =
          "Discussion Not Found";
      } else {
        document.getElementById("FilterErr1").innerHTML = "";
      }
    }
    var tabB = document.getElementById("tab2");
    if (tabB.checked) {
      const Deleted = this.state.Deleteduser.filter((item) => {
        const group_Name = item.group_Name || "";

        return group_Name
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase());
      });

      this.setState({ Deleted, showResults: true });
      // // console.log("discussionss", Deleted);

      if (Deleted.length === 0) {
        document.getElementById("FilterErr1").innerHTML =
          "Discussion Not Found";
      } else {
        document.getElementById("FilterErr1").innerHTML = "";
      }
    }
    var tabC = document.getElementById("tab3");
    if (tabC.checked) {
      const Alluser1 = this.state.Alluser1.filter((item) => {
        const group_Name = item.group_Name || "";

        return group_Name
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase());
      });

      this.setState({ Alluser1, showResults: true });
      // // console.log("discussionss", Alluser1);

      if (Alluser1.length === 0) {
        document.getElementById("FilterErr1").innerHTML =
          "Discussion Not Found";
      } else {
        document.getElementById("FilterErr1").innerHTML = "";
      }
    }

    var tabG = document.getElementById("tab7");
    if (tabG.checked) {
      const MutedDisc = this.state.MuteMessage.filter((item) => {
        const group_Name = item.group_Name || "";

        return group_Name
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase());
      });

      this.setState({ MutedDisc, showResults: true });
      // // console.log("discussionss", MutedDisc);

      if (MutedDisc.length === 0) {
        document.getElementById("FilterErr1").innerHTML =
          "Discussion Not Found";
      } else {
        document.getElementById("FilterErr1").innerHTML = "";
      }
    }

    var tabMute = document.getElementById("tab7");
    if (tabMute.checked) {
      const MutedDisc = this.state.AllMutedDisc.filter((item) => {
        const group_Name = item.group_Name || "";

        return group_Name
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase());
      });

      this.setState({ MutedDisc, showResults: true });
      // // console.log("discussionss", MutedDisc);

      if (MutedDisc.length === 0) {
        document.getElementById("FilterErr1").innerHTML =
          "Discussion Not Found";
      } else {
        document.getElementById("FilterErr1").innerHTML = "";
      }
    }
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  
    if (event.charCode === 13 && !this.state.EditInput) {
      const { message, picture_Path, document_Path } = this.state;
      if (!message.toString().trim() && !picture_Path && !document_Path) {
        alert("Please enter a message, select an image, or choose a document.");
      } else {
        event.preventDefault();
  
        const formData = new FormData(this.formRef.current);
  
        for (const [key, value] of formData.entries()) {
        }
        axios.post(`${BASE_URLs}Contact/OneOnOne`, formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            // // console.log(response.data);
            var FirstName = this.state.Name;
            var UserName = this.state.Email;
            this.GetReceivemsgPop(FirstName, UserName);
            this.GetmsgPop(UserName);
            this.setState({
              message: "",
              picture_Path: null,
              document_Path: null,
            });
          })
          .catch((error) => {
            // // console.log(error);
          });
      }
    }
  
    if (event.charCode === 13 && this.state.EditInput) {
      document.getElementById("input-groupEdit").style.display = "none";
      document.getElementById("input-group write-msg").style.display = "flex";
      // let Id = document.getElementById('groupid').value
      const data = {
        ParticularId: this.state.delparticularId,
        Message: this.state.deletemsg,
        SenderId: this.state.deletesender,
        SysDateTimeWithSeconds: this.state.Time,
      };
      // // console.log("Edits", data);
      axios.post(`${BASE_URLs}Chat/EditOneonOneMessage`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      })
        .then((response) => {
          // // console.log(response.data);
          this.setState({ message: "", MsgMenu: false, deletemsg: "" });
          var FirstName = this.state.Name;
          var UserName = this.state.Email;
          var Email = this.state.MailId;
          this.GetReceivemsgPopUser(FirstName, Email);
          this.GetmsgPop(UserName);
  
          this.setState({ EditInput: false });
        })
        .catch((error) => {
          // // console.log(error);
        });
    }
  };
  

  handleKeyPressUser = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  
    if (event.charCode === 13 && !this.state.EditInput) {
      const { message, picture_Path, document_Path } = this.state;
      if (!message.toString().trim() && !picture_Path && !document_Path) {
        alert("Please enter a message, select an image, or choose a document.");
      } else {
        event.preventDefault();
  
        const formData = new FormData(this.formRef.current);
  
        for (const [key, value] of formData.entries()) {
        }
  
        axios.post(`${BASE_URLs}Contact/Receiversend`, formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        })
          .then((response) => {
            var FirstName = this.state.Name;
            var UserName = this.state.Email;
            this.GetReceivemsgPopUser(FirstName, UserName);
            this.GetmsgPop(UserName);
            this.setState({
              message: "",
              picture_Path: null,
              document_Path: null,
            });
          })
          .catch((error) => {
            // Handle error
            console.error("Error sending message:", error);
          });
      }
    }
  
    if (event.charCode === 13 && this.state.EditInput) {
      document.getElementById("input-groupEdit").style.display = "none";
      document.getElementById("input-group write-msg").style.display = "flex";
      const data = {
        ParticularId: this.state.delparticularId,
        Message: this.state.deletemsg,
        SenderId: this.state.deletesender,
        SysDateTimeWithSeconds: this.state.Time,
      };
  
      axios.post(`${BASE_URLs}Chat/EditOneonOneMessage`, data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      })
        .then((response) => {
          // Handle success
          this.setState({ message: "", MsgMenu: false, deletemsg: "" });
          var FirstName = this.state.Name;
          var UserName = this.state.Email;
          var Email = this.state.MailId;
          this.GetReceivemsgPopUser(FirstName, Email);
          this.GetmsgPop(UserName);
          this.setState({ EditInput: false });
        })
        .catch((error) => {
          // Handle error
          console.error("Error editing message:", error);
        });
    }
  };
  

  ActiveMemberUserFilter = (e) => {

    const EntityMemberUsers = this.state.AllEntityMemberUsers.filter(
      (item) =>
        item.displayName
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );

    const AdminName = this.state.AdminNameA.filter(
      (item) =>
        item.name.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ EntityMemberUsers, showResults: true, AdminName });

    if (EntityMemberUsers.length === 0) {
      if (AdminName.length === 0) {
        document.getElementById("FilterErr").innerHTML = "User Not Found";
      } else {
        document.getElementById("FilterErr").innerHTML = "";
      }
    } else {
      if (EntityMemberUsers.length === 0 && AdminName.length === 0) {
        document.getElementById("FilterErr").innerHTML = "User Not Found";
      } else {
        document.getElementById("FilterErr").innerHTML = "";
      }
    }
  };

  OATSearch = (e) => {
    const User = this.state.Alluser.filter((item) =>
      item.company_Name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ User });
  };
  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }

  markMessageAsSeen = (group_id) => {
    const data = {
      id: group_id,
    };
  
    const config = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      data: JSON.stringify(data),
      url: `${BASE_URLs}Notification/Msgcount`,
    };
  
    axios(config)
      .then((response) => {
      })
      .catch((error) => {
        console.error("Error updating Seen field:", error);
      });
  };
  

  markMessageAsSeenUser = (senderId, particularId) => {
    const data = {
      senderId: senderId,
      particularId: particularId,
    };
  
    const config = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      data: JSON.stringify(data),
      url: `${BASE_URLs}Notification/MsgSeenUser`,
    };
  
    axios(config)
      .then((response) => {
      })
      .catch((error) => {
        console.error("Error updating Seen field:", error);
      });
  };
  

  markMessageAsSeenAdmin = (senderId, particularId) => {
    const data = {
      senderId: senderId,
      particularId: particularId,
    };
  
    const config = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      data: JSON.stringify(data),
      url: `${BASE_URLs}Notification/MsgcountSeenAdmin`,
    };
  
    axios(config)
      .then((response) => {
      })
      .catch((error) => {
        console.error("Error updating Seen field:", error);
      });
  };
  

  GetMemberAccess() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}Chat/GetAccessMemberUsers`, options)
      .then((response) => {
        this.setState({
          MemberAccess: response.data,
          AllMemberAccess: response.data,
        });
      })
      .catch((error) => {
        console.error("Error fetching member access:", error);
      });
  
      setInterval(1000);
  }

  GetNonAccess() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}Team/GetAccessMembers`, options)
      .then((response) => {
        this.setState({
          NonAccess: response.data,
          AllNonAccess: response.data,
        });
      })
      .catch((error) => {
        console.error("Error fetching non-access members:", error);
      });
  }
  
  componentDidMount() {
    // document.removeEventListener("keydown", this.handleKeyDown);
    // document.addEventListener("keydown", this.handleKeyDown);
    this.GetAnnouncement();
    this.fetchCreatedName();
    this.GetMemberAccess();
    this.GetNonAccess();
    // this.OneononeUser();
    // this.UserOrg();
    const tt = document.getElementById("tab1");
    const tt2 = document.getElementById("tab2");
    const tt3 = document.getElementById("tab3");
    const tt4 = document.getElementById("tab4");
    const tt5 = document.getElementById("tab5");
    const tt6 = document.getElementById("tab6");
    const tt7 = document.getElementById("tab7");
    const searchtabs = document.getElementById("searchfordisc");
    window.onload = function () {
      document.getElementById("searchfordisc").blur();
    };

    if (tt) {
      tt.checked = true;
    }
    function delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    
    function getUserDataAsync() {
      return new Promise(resolve => {
        this.getUserData();
        resolve();
      });
    }
    
    function fetchData() {
      const fetchPromises = [
        this.fetchNotificationCount(),
        this.fetchclosedCount(),
        this.fetchindividualCount(),
        this.fetchgroupCount(),
        this.fetchpublicCount(),
        this.fetchPrivacyandCreatedby(),
        this.fetchPrivacyandCreatedbyPublic()
      ];
      return Promise.all(fetchPromises);
    }
    
    async function fetchDataWithDelay() {
      await getUserDataAsync.call(this);
      await fetchData.call(this);
    }
    
    async function fetchDataWithDelayAndCheck() {
      if (tt.checked && !this.state.Filterpop) {
        // Check if searchtabs is not in focus
        if (!searchtabs || (searchtabs && !this.state.Filterpop)) {
          if (
            !tt2.checked &&
            !tt3.checked &&
            !tt4.checked &&
            !tt5.checked &&
            !tt6.checked &&
            !tt7.checked
          ) {
            await fetchDataWithDelay.call(this);
          }
        }
      }
    }
    
    // Call fetchDataWithDelayAndCheck
    fetchDataWithDelayAndCheck.call(this);
    
    // this.UserOrg1();
    // this.UserOrgLeft();
    this.Team();
    this.TeamUsers();
    // this.check();
    this.GetAdminDetails();

    const tab1Radio = document.getElementById("tab1");

    if (tab1Radio) {
      tab1Radio.checked = true;
    }

    var role = JSON.parse(localStorage.getItem("Role"));
    this.setState({ RoleA: role });

    var mail = JSON.parse(localStorage.getItem("email"));
    this.setState({ UserMail: mail });

    var Id = JSON.parse(localStorage.getItem("userId"));
    this.setState({ UserId: Id });

    var namee = JSON.parse(localStorage.getItem("uname"));
    this.setState({ USERNAME: namee });

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to avoid memory leaks
    clearInterval(this.interval);
    clearInterval(this.ICcountinterval);
    clearInterval(this.ICcountTabinterval)
  }

  handleClickOutside = (event) => {
    if (
      this.containerRef.current &&
      !this.containerRef.current.contains(event.target)
    ) {
      this.setState({
        Deletepop: false,

        showEmoji: false,
        MsgMenu: false,
        attachMenu: false,
      });
    }
  };

  Team() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}Team/GetActiveMembers`, options)
      .then((response) => {
        this.setState({
          EntityMembers: response.data,
          AllEntityMembers: response.data,
        });
      })
      .catch((error) => {
        console.error("Error fetching active team members:", error);
      });
  }
  

  TeamUsers() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}Team/GetActiveMemberUsers`, options)
      .then((response) => {
        this.setState({
          EntityMemberUsers: response.data,
          AllEntityMemberUsers: response.data,
        });
      })
      .catch((error) => {
        console.error("Error fetching active team member users:", error);
      });
  }
  
  navigate = () => {
    this.props.history.push("/NewDiscussion");
  };

  GetmsgPop(UserName) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };
  
    axios.get(`${BASE_URLs}Contact/GetmsgPop?UserName=${UserName}`, options)
      .then((response) => {
        const DataLength = this.state.Popmsg.length;
        this.setState(
          {
            Popmsg: response.data,
          },
          () => {
            if (response.data.length > DataLength) {
              this.scrollToBottom();
            }
          }
        );
        this.setState({
          Popmsg: response.data,
          OneonOneAdmin: UserName,
        });
      })
      .catch((error) => {
        console.error("Error fetching user messages:", error);
      });
  }
  

  GetAdminDetails() {
    var UserEmail = JSON.parse(localStorage.getItem("email"));
  
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };
  
    axios.get(`${BASE_URLs}UserBank/GetAdminOnly?Email=${UserEmail}`, options)
      .then((response) => {
        this.setState({
          AdminName: response.data,
          AdminNameA: response.data,
        });
      })
      .catch((error) => {
      });
  }
  

  GetReceivemsgPop(FirstName, Email) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };
  
    axios.get(`${BASE_URLs}Contact/GetReceivemsgPop?FirstName=${FirstName}&Email=${Email}`, options)
      .then((response) => {
        const DataLength = this.state.ReceivepopMsg.length;
        this.setState(
          {
            ReceivepopMsg: response.data,
          },
          () => {
            if (response.data.length > DataLength) {
              this.scrollToBottom();
            }
          }
        );
        this.setState({
          ReceivepopMsg: response.data,
        });
      })
      .catch((error) => {
        console.error("Error fetching received messages:", error);
      });
  }
  

  GetReceivemsgPopUser(FirstName, Email) {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };
  
    axios.get(`${BASE_URLs}Contact/GetReceivemsgPopUser?FirstName=${FirstName}&Email=${Email}`, options)
      .then((response) => {
        const DataLength = this.state.ReceivepopMsgUser.length;
        this.setState(
          {
            ReceivepopMsgUser: response.data,
          },
          () => {
            if (response.data.length > DataLength) {
              this.scrollToBottom();
            }
          }
        );
        this.setState({
          ReceivepopMsgUser: response.data,
        });
      })
      .catch((error) => {
        console.error("Error fetching received messages for user:", error);
      });
  }
  

  handleSubmit = (event) => {
    const { message, picture_Path, document_Path } = this.state;
    if (!message.toString().trim() && !picture_Path && !document_Path) {
      event.preventDefault();
      alert("Please enter a message, select an image, or choose a document.");
    } else {
      event.preventDefault();
  
      const formData = new FormData(this.formRef.current);
  
      axios.post(`${BASE_URLs}Contact/OneOnOne`, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          var FirstName = this.state.Name;
          var UserName = this.state.Email;
          this.GetReceivemsgPop(FirstName, UserName);
          this.GetmsgPop(UserName);
          this.setState({
            message: "",
            picture_Path: null,
            document_Path: null,
          });
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    }
  };
  

  handleSubmitUser = (event) => {
    const { message, picture_Path, document_Path } = this.state;
    if (!message.toString().trim() && !picture_Path && !document_Path) {
      event.preventDefault();
      alert("Please enter a message, select an image, or choose a document.");
    } else {
      event.preventDefault();
  
      const formData = new FormData(this.formRef.current);
  
      axios.post(`${BASE_URLs}Contact/Receiversend`, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          var FirstName = this.state.Name;
          var UserName = this.state.Email;
          this.GetReceivemsgPopUser(FirstName, UserName);
          this.GetmsgPop(UserName);
          this.setState({
            message: "",
            picture_Path: null,
            document_Path: null,
          });
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
        });
    }
  };
  

  handleTextChange = (event) => {
    this.setState({
      message: event.target.value,
      deletemsg: event.target.value,
    });
  };


  Chatpop = (FirstName, UserName, pic, Email, senderId, particularId) => {

    this.fetchNotificationCount();
    this.fetchindividualCount();

    this.setState({
      Chatpop: true,
      Name: FirstName,
      Email: UserName,
      Minimized: false,
      Filterpop: false,
      UserProfilePic: pic,
      MailId: Email,
    }, () => {
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    });



    this.GetReceivemsgPop(FirstName, Email);
    this.GetReceivemsgPopUser(FirstName, Email);
    this.GetmsgPop(UserName);
    this.OneononeUser();
    this.GetForwardmembers();

    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.GetReceivemsgPop(FirstName, Email);
      this.GetReceivemsgPopUser(FirstName, Email);
      this.GetmsgPop(UserName);

    }, 1000);



    if (this.state.UserId !== senderId) {
      this.ICcountinterval = setInterval(() => {
        // // console.log('aarthiii')
        this.markMessageAsSeenAdmin(
          senderId, particularId
        );
        this.markMessageAsSeenUser(senderId, particularId
        );
      }, 1000);
    }
  };

  hideChatpop = () => {
    this.setState({
      Chatpop: false,
      attachMenu: false,
      showEmoji: false,
      MsgMenu: false,
      Deletepop: false,
    }, () => {
      this.updateCheckedState();

    });
    clearInterval(this.ICcountinterval);
  };

  Hidechatheader = () => {
    var tabA = document.getElementById("tab1");
    // alert("chat")
    this.setState({
      Chatpop: false,
      attachMenu: false,
      showEmoji: false,
      MsgMenu: false,
      Deletepop: false,
      Minimized: false,
    });
    if (tabA.checked) {
      this.setState({
        OneonOne: [],
      });
      this.getUserData();
    }

  };

  handleEmojiClick = (emojiObject) => {
    this.setState((prevState) => ({
      message: prevState.message + emojiObject.emoji,
      showEmoji: false,
      deletemsg: prevState.deletemsg + emojiObject.emoji,
    }));
  };

  myEmoji = () => {
    const changeState = this.state.showEmoji;
    if (changeState) {
      this.setState({
        showEmoji: false,
      });
    } else {
      this.setState({
        showEmoji: true,
      });
    }
  };

  check = () => { };

  formatDateAA = (dateString) => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const oneWeekAgo = new Date(currentDate.getTime() - 7 * oneDay);

    const isToday =
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    const isYesterday =
      inputDate.getDate() === currentDate.getDate() - 1 &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    const isBeforeYesterday =
      inputDate.getTime() > oneWeekAgo.getTime() && !isYesterday;

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    if (isToday) {
      const timeOnly = inputDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return `${timeOnly}`;
    } else if (isYesterday) {
      return `${inputDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })}`;
    } else if (isBeforeYesterday) {
      // const formattedDate = inputDate.toLocaleDateString("en-US", { weekday: "long" });
      const formattedTime = inputDate.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return `${formattedTime}`;
    } else {
      return inputDate.toLocaleString("en-US", options);
    }
  };

  formatDateAA1 = (dateString) => {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const oneWeekAgo = new Date(currentDate.getTime() - 7 * oneDay);

    const isToday =
      inputDate.getDate() === currentDate.getDate() &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    const isYesterday =
      inputDate.getDate() === currentDate.getDate() - 1 &&
      inputDate.getMonth() === currentDate.getMonth() &&
      inputDate.getFullYear() === currentDate.getFullYear();

    const isBeforeYesterday =
      inputDate.getTime() > oneWeekAgo.getTime() && !isYesterday;

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const options1 = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    if (isToday) {
      return "Today";
    } else if (isYesterday) {
      return "Yesterday";
    } else if (isBeforeYesterday) {
      const formattedDate = inputDate.toLocaleDateString("en-US", {
        weekday: "long",
      });
      // const formattedTime = inputDate.toLocaleTimeString("en-US", { hour: "numeric", minute: "numeric", hour12: true });
      return `${formattedDate}`;
    } else {
      return inputDate.toLocaleString("en-US", options1);
    }
  };

  formatDateAA2 = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return new Date(dateString).toLocaleString("en-US", options);
  };

  Mute() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}Chat/GetMuteChat`, options)
      .then((response) => {
        this.setState({
          MutedDisc: response.data,
          AllMutedDisc: response.data,
          OneonOne: [],
          User: [],
          Alluser1: [],
          Deleted: [],
          trendList: [],
          NonUsers: [],
        });
      })
      .catch((error) => {
        console.error("Error fetching muted chats:", error);
      });
  }
  

  attachMenu() {
    this.setState({
      attachMenu: !this.state.attachMenu,
      showEmoji: false,
      Addpopup: false,
      showMenu: false,
      Deletepop: false,
      MsgMenu: false,
    });
  }
  Picturepop() {
    this.setState({
      Picturepopup: !this.state.Picturepopup,
      ispicturepopOpen: true,
      attachMenu: false,
    });
    localStorage.setItem("fileee", JSON.stringify(this.state.OneonOneAdmin));
  }
  Documentpop() {
    this.setState({
      Documentpopup: !this.state.Documentpopup,
      isdocumentpopOpen: true,

      attachMenu: false,
    });

    // // console.log(this.state.OneonOneAdmin, "droppppppp");
    localStorage.setItem("fileee", JSON.stringify(this.state.OneonOneAdmin));
    localStorage.setItem("UNamee", JSON.stringify(this.state.Name));
  }

  documentPopup = () => {
    this.setState({
      isdocumentpopOpen: false,
      Documentpopup: false,
    });
    var FirstName = this.state.Name;
    var UserName = this.state.Email;
    var Email = this.state.MailId;
    this.GetmsgPop(UserName);
    this.GetReceivemsgPop(FirstName, Email);
    this.GetReceivemsgPopUser(FirstName, Email);
  };
  picturePopup = () => {
    this.setState({
      ispicturepopOpen: false,
      Picturepopup: false,
    });
    var FirstName = this.state.Name;
    var UserName = this.state.Email;
    var Email = this.state.MailId;
    this.GetmsgPop(UserName);
    this.GetReceivemsgPop(FirstName, Email);
    this.GetReceivemsgPopUser(FirstName, Email);
  };

  documentpop() {
    this.setState({ Documentpopup: !this.state.Documentpopup });
  }

  picturepop() {
    this.setState({ Picturepopup: !this.state.Picturepopup });
  }

  hidedocumentpop = () => {
    this.setState({
      Documentpopup: false,
    });
  };
  hidepicturepop = () => {
    this.setState({
      Picturepopup: false,
    });
  };
  EditOneonOneMessage = () => {
    document.getElementById("input-groupEdit").style.display = "none";
    document.getElementById("input-group write-msg").style.display = "flex";
    // let Id = document.getElementById('groupid').value
    const data = {
      ParticularId: this.state.delparticularId,
      Message: this.state.deletemsg,
      SenderId: this.state.deletesender,
      SysDateTimeWithSeconds: this.state.Time,
    };
    // // console.log("Edits", data);
    axios({
      method: "POST",
      url: `${BASE_URLs}Chat/EditOneonOneMessage`,
      data: data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "multipart/form-data",

        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    })
      .then((response) => {
        // // console.log(response.data);
        this.setState({ message: "", MsgMenu: false, deletemsg: "" });
        var FirstName = this.state.Name;
        var UserName = this.state.Email;
        var Email = this.state.MailId;
        this.GetReceivemsgPopUser(FirstName, Email);
        this.GetmsgPop(UserName);

        this.setState({ EditInput: false });
      })

      .catch((error) => {
        // // console.log(error);
      });
  };

  DeleteMessageOne = () => {
    const data = {
      Id: this.state.deleteId,
      UserName: this.state.Email,
    };

    // // console.log(data, "delete message");

    Swal.fire({
      // title: "Are you sure",

      text: "Are you sure want to delete message?",

      icon: "warning",

      showCancelButton: true,

      confirmButtonColor: "#3085d6",

      cancelButtonColor: "#d33",

      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "POST",

          url: `${BASE_URLs}Chat/DeleteMessageOne`,

          data: data,

          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-Type": "multipart/form-data",

            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        })
          .then((response) => {
            // // console.log(response.data);

            var FirstName = this.state.Name;
            var UserName = this.state.Email;
            var Email = this.state.MailId;
            this.GetmsgPop(UserName);
            this.GetReceivemsgPop(FirstName, Email);
            this.GetReceivemsgPopUser(FirstName, Email);

            this.setState({
              Deletepop: false,
            });
          })

          .catch((error) => {
            // // console.log(error);
          });
      }

      if (result.isDismissed) {
        this.setState({
          Deletepop: false,
        });
      }
    });
  };

  DisplayMenu(id, Imsg, Ipic, Idoc, Isd, Icd, ParticularId, Ist) {
    document.getElementById("input-groupEdit").style.display = "none";
    document.getElementById("input-group write-msg").style.display = "flex";
    this.setState({
      MsgMenu: !this.state.MsgMenu,
      deleteId: id,
      // deletemsg: Imsg,
      Forwardpic: Ipic,
      Forwarddoc: Idoc,
      deletesender: Isd,
      deletedate: Icd,
      Deletepop: false,
      attachMenu: false,
      deleteEvery: id,
      deletemsg: Imsg,
      delparticularId: ParticularId,
      //  Time:Ist,
      Time: Ist,
    });
  }

  GetForwardmembers() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}Chat/GetallMembers`, options)
      .then((response) => {
        if (Array.isArray(response.data)) {
          this.setState({
            Forwardmembers: response.data,
            AllForwardmembers: response.data,
            Addmembers: response.data,
            AllAddmembers: response.data,
          });
        } else {
        }
      })
      .catch((error) => {
        console.error("Error fetching forward members:", error);
      });
  }
  
  forwardopenpop = () => {
    this.setState({
      forwardpopup: !this.state.forwardpopup,

      MsgMenu: false,
    });
    this.GetMemberAccess();
    this.GetNonAccess();
  };

  hideForwardpop = () => {
    this.setState({
      forwardpopup: false,
      selectedUserIds: []
    });
    this.GetMemberAccess();
    this.GetNonAccess();
  };

  handleUserSelectionChange = (event) => {
    //document.getElementById("Searchbox2").style.display = "block";
    const { value, checked } = event.target;
    this.setState((prevState) => {
      let selectedUsers = [...prevState.selectedUsers];
      let selectedUserIds = [...prevState.selectedUserIds];
      let selectedUsersA = [...prevState.selectedUsersA];
      let selectedUserName = [...prevState.selectedUserName];
      if (checked) {
        const userToAdd = prevState.Addmembers.find(
          (user) => user.email === value
        );
        if (userToAdd) {
          selectedUsers.push(userToAdd);
          selectedUserIds.push(userToAdd.email);
        }

        const userNameToAdd = prevState.Addmembers.find(
          (user) => user.email === value
        );
        if (userNameToAdd) {
          // let Both = userNameToAdd.name + " [ " + userNameToAdd.email + " ] ";
          let Both = userNameToAdd.name;
          selectedUsersA.push(userNameToAdd);
          selectedUserName.push(Both);
        }
      } else {
        const userNameToAdd = prevState.Addmembers.find(
          (user) => user.email === value
        );

        selectedUsers = selectedUsers.filter((user) => user.email !== value);
        selectedUserIds = selectedUserIds.filter((email) => email !== value);

        selectedUsersA = selectedUsersA.filter((user) => user.email !== value);
        selectedUserName = selectedUserName.filter(
          (name) => name !== userNameToAdd.name
        );
      }

      return { selectedUsers, selectedUserIds, selectedUserName };
    });
    // this.handleUserRemove()
  };

  handleUserRemove = (email, name) => {
    this.setState((prevState) => {
      const updatedSelectedUsers = prevState.selectedUsers.filter(
        (user) => user.email !== email
      );
      const updatedSelectedUserIds = prevState.selectedUserIds.filter(
        (userId) => userId !== email
      );
      const updatedSelectedUserName = prevState.selectedUserName.filter(
        (userName) => userName !== name
      );

      //const updatedSelectedUsersA = prevState.selectedUsersA.filter((usersA) => usersA.email !== email);

      return {
        selectedUsers: updatedSelectedUsers,
        selectedUserIds: updatedSelectedUserIds,
        selectedUserName: updatedSelectedUserName,

        //selectedUsersA: updatedSelectedUsersA
      };
    });
  };

  Addmemberfilter = (e) => {
    const MemberAccess = this.state.AllMemberAccess.filter(
      (item) =>
        item.firstName.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    this.setState({ MemberAccess, showResults: true });
    const NonAccess = this.state.AllNonAccess.filter(
      (item) =>
        item.firstName.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.email.toLowerCase().startsWith(e.target.value.toLowerCase())
    );

    // // console.log("NonAccess", NonAccess);
    this.setState({ NonAccess, showResults: true });

  };

  ForwardIChatMsg = (event) => {
    event.preventDefault();

    let ParticularId = this.state.selectedUserIds.toString();

    if (!ParticularId) {
      alert("Choose Any Member");
    } else {
      const data = {
        Message: this.state.deletemsg,

        Picture_Path: this.state.Forwardpic,

        Document_Path: this.state.Forwarddoc,

        UserName: ParticularId,
      };

      // // console.log(data, "ICupdatename check");

      axios({
        method: "POST",

        url: `${BASE_URLs}contact/OneonOneFwdMessage`,

        data: data,

        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Content-Type": "multipart/form-data",

          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
      })
        .then((response) => {
          // // console.log(response.data);

          this.setState({
            //   selectedOptionsFwd: [],
            forwardpopup: false,
            selectedUserIds: [],
          });
          Swal.fire({
            position: "center",
            icon: "success",
            text: "Forwarded",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            customClass: "IC-swal-container",
          });
          var FirstName = this.state.Name;
          var UserName = this.state.Email;
          var Email = this.state.MailId;
          this.GetmsgPop(UserName);
          this.GetReceivemsgPop(FirstName, Email);
          this.GetReceivemsgPopUser(FirstName, Email);
          this.GetMemberAccess();
          this.GetNonAccess();
        })

        .catch((error) => {
          // // console.log(error);

          alert("failed !");
        });
    }
  };
  deleteMenu() {
    this.setState({
      Deletepop: !this.state.Deletepop,
      MsgMenu: false,
    });
  }
  handleClose = () => {
    this.setState({
      Deletepop: false,
    });
  };
  ICDeleteMessageEveryone = () => {
    const data = {
      UserName: this.state.Email,

      SenderId: this.state.deletesender,

      DateTimeSec: this.state.Time,

      Message: this.state.deletemsg,

      PicturePath: this.state.Forwardpic,

      DocumentPath: this.state.Forwarddoc,
      // SysDateTimeWithSeconds: this.state.Time,
    };

    // // console.log(data, "delete message");

    Swal.fire({
      // title: "Are you sure",

      text: "Are you sure want to delete message?",

      icon: "warning",

      showCancelButton: true,

      confirmButtonColor: "#3085d6",

      cancelButtonColor: "#d33",

      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "POST",

          url: `${BASE_URLs}contact/ICDelMsgEveryone`,

          data: data,

          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-Type": "multipart/form-data",

            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        })
          .then((response) => {
            // // console.log(response.data);

            var FirstName = this.state.Name;
            var UserName = this.state.Email;
            var Email = this.state.MailId;
            this.GetmsgPop(UserName);
            this.GetReceivemsgPop(FirstName, Email);
            this.GetReceivemsgPopUser(FirstName, Email);

            this.setState({
              Deletepop: false,
            });
          })

          .catch((error) => {
            // // console.log(error);
          });
      }

      if (result.isDismissed) {
        this.setState({
          Deletepop: false,
        });
      }
    });
  };

  ICMuteDiscpop(e) {
    e.preventDefault();
    this.setState({
      ICMuteDisc: !this.state.MuteDisc,
    });
  }

  EditMessage = () => {
    this.setState({ EditInput: true });
    document.getElementById("input-groupEdit").style.display = "flex";
    document.getElementById("input-group write-msg").style.display = "none";
  };

  CancelEdit() {
    document.getElementById("input-groupEdit").style.display = "none";
    document.getElementById("input-group write-msg").style.display = "flex";
  }

  render() {
    var w = window.innerWidth;

    const {
      EntityMembers,
      EntityMemberUsers,
      UserId,
      RoleA,
      UserMail,
      USERNAME,
      showEmoji,
      ReceivepopMsg,
      ReceivepopMsgUser,
      Popmsg,
      User,
      Name,
      Email,
      Chatpop,
      Minimized,
      isButton1Visible,
      isButton2Visible,
      Announcements,
      SideBarOpen,
      User1,
      Alluser,
      Alluser1,
      NonUsers,
      OneonOne,
      AdminName,
      OneonOneCount,
      Deleted,
      notificationCount,
      notificationClosed,
      individualCount,
      groupCount,
      publicCount,
      checkPrivacyandCreated,
      checkPrivacyandCreatedPublic,
      CheckCreatedName,
      trendList,
      MutedDisc,
      Filterpop,
      isdocumentpopOpen,
      ispicturepopOpen,
      DName,
      documentname,
      UserProfilePic,

      MsgMenu,
      Forwardmembers,
      selectedUserIds,
      selectedUsers,
      Deletepop,
      Popmsguserside,
      ReceivepopMsgUserSide,
      ReceivepopMsgUsersideA,
      createdby,
      MemberAccess,
      NonAccess,
      activeTab
    } = this.state;
    const messages = [...Popmsg, ...ReceivepopMsg, ...ReceivepopMsgUser];

    messages.sort(
      (a, b) => new Date(a.createDateTime) - new Date(b.createDateTime)
    );
    messages.sort((a, b) => a.id - b.id);

    let seenCreateDateTime = {}; // to keep track of createDateTime

    for (let i = 0; i < messages.length; i++) {
      const currentObject = messages[i];

      // Check if createDateTime is already seen
      if (seenCreateDateTime[currentObject.createDateTime.split("T")[0]]) {
        // Check if displayName is different
        if (
          seenCreateDateTime[currentObject.createDateTime.split("T")[0]] !==
          currentObject.displayName
        ) {
          // Update rowNum to 2
          messages[i].rowNum = 2;
        }
      } else {
        // Store the displayName for the createDateTime
        seenCreateDateTime[currentObject.createDateTime.split("T")[0]] =
          currentObject.displayName;
      }
    }

    messages.sort(
      (a, b) => new Date(a.createDateTime) - new Date(b.createDateTime)
    );
    const merge = [...User, ...User1];
    merge.sort(
      (a, b) => new Date(b.createDateTime) - new Date(a.createDateTime)
    );

    var Accessed = JSON.parse(localStorage.access);
    var Accessed_to = JSON.parse(localStorage.accessed_to);
    var MailIds = JSON.parse(localStorage.email);

    const deleteStyles = {
      position: "absolute",
      // top: "350px",
      alignItems: "center",
      zIndex: "10",

      // width: "300px",
      backgroundColor: "white",
      padding: "10px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      display: this.state.Deletepop ? "block" : "none",
      cursor: "pointer",
      borderRadius: "9px",
      top: "50%",
      // left: "57%",
      transform: "translate(-50%, -50%)",
    };

    const MessageMenu = {
      position: "absolute",
      top: "-10px",
      alignItems: "center",
      zIndex: "100",
      // left: "19.5%",
      width: "140px",
      backgroundColor: "white",
      padding: "10px",
      boxShadow: "rgb(0 0 0 / 20%) 0px 2px 5px",
      display: this.state.MsgMenu ? "block" : "none",
      cursor: "pointer",
      borderRadius: "none",

    };

    const chatpop = {
      zIndex: Minimized ? "0" : "0",
      display: this.state.Chatpop ? "block" : "none",
      cursor: "pointer",
    };

    const chatpopA = {
      position: "absolute",
      top: "46px",
      left: "-0%",
      backgroundColor: "white",
      // display: this.state.Chatpop ? "block" : "none",
      cursor: "pointer",
      height: "auto",
    };

    const attachStyles = {
      position: "absolute",
      //  top: "400px",

      marginTop: "-122px",
      alignItems: "center",
      zIndex: "100",
      // left: "89%",
      backgroundColor: "white",
      padding: "10px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      display: this.state.attachMenu ? "block" : "none",
      cursor: "pointer",
      borderRadius: "9px",
      // left: "89%",
      // left: "43%",
    };

    const documentpop = {
      position: "absolute",
      zIndex: "100",
      backgroundColor: "white",
      cursor: "pointer",
      border: "3px solid rgb(156, 145, 244)",
      display: this.state.Documentpopup ? "block" : "none",
    };

    const picturepop = {
      position: "absolute",
      zIndex: "100",
      backgroundColor: "white",
      display: this.state.Picturepopup ? "block" : "none",

      cursor: "pointer",

      border: "3px solid rgb(156 145 244)",
    };
    const regexp =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

      const LoginUserName = localStorage.getItem("uname");
      
      const name = LoginUserName.replace(/"/g, "");

    return (
      <>
        <div>
          {this.state.EditSkillModels ? (
            <div className="row">
              {/* <div className="col-lg-6"> */}
              <GroupPop
                id=""
                values={this.state.EditSkillValues}
                close={() => {
                  var tabA = document.getElementById("tab1");
                  if (tabA.checked) {
                    this.EditSkillModels();
                    this.getUserData();
                  }

                  var tabB = document.getElementById("tab2");
                  if (tabB.checked) {
                    this.EditSkillModels();
                    this.UserOrgLeft();
                  }
                  var tabC = document.getElementById("tab3");
                  if (tabC.checked) {
                    this.EditSkillModels();
                    this.UserOrg1();
                  }

                  var tabD = document.getElementById("tab5");
                  if (tabD.checked) {
                    this.EditSkillModels();
                    this.OneononeUser();
                  }

                  var tabE = document.getElementById("tab4");
                  if (tabE.checked) {
                    this.EditSkillModels();
                    this.UserOrg();
                  }

                  var tabF = document.getElementById("tab6");
                  if (tabF.checked) {
                    this.EditSkillModels();
                    this.UserOrgTrend();
                  }

                  var tabG = document.getElementById("tab7");
                  if (tabG.checked) {
                    this.EditSkillModels();
                    this.Mute();
                  }
                }}
              />
              {/* </div> */}
            </div>
          ) : null}
          {this.state.SearchModel ? (
            <SearchPopup id="" close={() => this.SearchModel()} />
          ) : null}
          <div>
            <div className="pcoded-content" id="lastgroup">
              <div className="pcoded-inner-content">
                {!Chatpop && (
                  <div className="main-body" id="Ndbtnad">
                    <div
                    >

                      <div className="DiscussionTab" id="DiscussionTab">
                        <input
                          id="tab1"
                          type="radio"
                          name="tabs"
                          className="DInput"
                          checked={activeTab === 'tab1' ? "checked" : ''}
                          onChange={() => {
                            this.handleTabChange('tab1');
                          }}
                        />
                        <label
                          for="tab1"
                          id="Dlabel"
                          onClick={() => {
                            this.StateCheck();
                            this.getUserData();
                            document.getElementById("FilterErr1").innerHTML = "";
                          }}
                        >
                          <span className="hover-zoom">
                            <i className="fa fa-comments-o " aria-hidden="true"></i>
                            &nbsp;&nbsp;
                            <span id="DText">Current </span>&nbsp;{" "}
                          </span>
                          <span>
                            {notificationCount === 0 ? (
                              <span
                                className="badge"
                                id="size"
                                style={{
                                  fontSize: "13px",
                                  backgroundColor: "none",
                                  marginLeft: "-10%",
                                }}
                              ></span>
                            ) : (
                              <img
                                src={onlinedot}
                                className="media-object img-circle"
                                style={{
                                  marginTop: "0px",
                                  width: "10px",
                                }}
                                title="Online"
                              />
                            )}{" "}
                          </span>
                        </label>

                        <input id="tab5" type="radio" name="tabs" hidden
                          checked={activeTab === 'tab5' ? "checked" : ''}
                          onChange={() => {
                            this.handleTabChange('tab5');
                          }}
                        />
                        <label
                          for="tab5"
                          id="Dlabel"
                          onClick={() => {
                            this.StateCheck();
                            this.OneononeUser();
                            document.getElementById("FilterErr1").innerHTML = "";
                          }}
                        >
                          <span className="hover-zoom">
                            <i
                              className="fa fa-user"
                              aria-hidden="true"
                              style={{ fontSize: "13px" }}
                            ></i>
                            &nbsp;&nbsp;
                            <span id="DText" style={{ marginRight: "30px" }}>
                              1:1{" "}
                            </span>
                            &nbsp;{" "}
                          </span>
                          <span>
                            {individualCount === 0 ? (
                              <span
                                className="badge"
                                id="size"
                                style={{
                                  fontSize: "12px",
                                  backgroundColor: "none",
                                  marginLeft: "-46%",
                                  color: "transparent",
                                  visibility: "hidden",
                                }}
                              ></span>
                            ) : (
                              <img
                                src={onlinedot}
                                className="media-object img-circle"
                                style={{
                                  marginTop: "0px",
                                  width: "10px",
                                }}
                                title="Online"
                              />
                            )}
                          </span>
                        </label>
                        <input id="tab4" type="radio" name="tabs" hidden />
                        <label
                          for="tab4"
                          id="Dlabel"
                          onClick={() => {
                            this.StateCheck();
                            this.UserOrg();
                            document.getElementById("FilterErr1").innerHTML = "";
                          }}
                        >
                          <span className="hover-zoom">
                            <i
                              className="fa fa-users"
                              aria-hidden="true"
                              style={{ fontSize: "13px" }}
                            ></i>
                            &nbsp;&nbsp;
                            <span id="DText">Group </span>&nbsp;{" "}
                          </span>
                          <span>
                            {groupCount === 0 ? (
                              <span
                                className="badge"
                                style={{
                                  fontSize: "13px",
                                  backgroundColor: "none",
                                  marginLeft: "-40%",
                                  color: "transparent",
                                  visibility: "hidden",
                                }}
                              ></span>
                            ) : (
                              <img
                                src={onlinedot}
                                className="media-object img-circle"
                                style={{
                                  marginTop: "0px",
                                  width: "10px",
                                }}
                                title="Online"
                              />
                            )}
                          </span>
                        </label>
                        <input id="tab2" type="radio" name="tabs" />
                        <label
                          for="tab2"
                          id="Dlabel"
                          onClick={() => {
                            this.StateCheck();
                            this.UserOrgLeft();
                            document.getElementById("FilterErr1").innerHTML = "";
                          }}
                        >
                          <span className="hover-zoom">
                            <i className="fa fa-times" aria-hidden="true"></i>
                            &nbsp;&nbsp;
                            <span id="DText">Closed </span>&nbsp;{" "}
                          </span>
                          <span>
                            {notificationClosed === 0 ? (
                              <span
                                className="badge"
                                style={{
                                  fontSize: "13px",
                                  backgroundColor: "none",
                                  marginLeft: "-18%",
                                  color: "transparent",
                                  visibility: "hidden",
                                }}
                              ></span>
                            ) : (
                              <img
                                src={onlinedot}
                                className="media-object img-circle"
                                style={{
                                  marginTop: "0px",
                                  width: "10px",
                                }}
                                title="Online"
                              />
                            )}
                            {" "}
                          </span>
                        </label>

                        <input id="tab3" type="radio" name="tabs" />
                        <label
                          for="tab3"
                          id="Dlabel"
                          onClick={() => {
                            this.StateCheck();
                            this.UserOrg1();
                            document.getElementById("FilterErr1").innerHTML = "";
                          }}
                        >
                          <span className="hover-zoom">
                            <i
                              className="icofont icofont-people"
                              aria-hidden="true"
                            ></i>
                            &nbsp;&nbsp;
                            <span id="DText">Public </span>&nbsp;{" "}
                          </span>
                          <span>
                            {publicCount === 0 ? (
                              <span
                                className="badge"
                                style={{
                                  fontSize: "13px",
                                  backgroundColor: "none",
                                  marginLeft: "-18%",
                                  color: "transparent",
                                  visibility: "hidden",
                                }}
                              ></span>
                            ) : (
                              <img
                                src={onlinedot}
                                className="media-object img-circle"
                                style={{
                                  marginTop: "0px",
                                  width: "10px",
                                }}
                                title="Online"
                              />
                            )}
                          </span>
                        </label>
                        <input id="tab7" type="radio" name="tabs" hidden />
                        <label
                          for="tab7"
                          id="Dlabel"
                          onClick={() => {
                            this.StateCheck();
                            this.Mute();
                            document.getElementById("FilterErr1").innerHTML = "";
                          }}
                        >
                          <span className="hover-zoom">
                            <i className="fa-solid fa-bell-slash" id="mute"></i>
                            &nbsp;&nbsp;
                            <span id="DText">Mute</span>&nbsp;{" "}
                          </span>
                          <span>
                            {publicCount === 0 &&
                              groupCount === 0 &&
                              individualCount === 0 &&
                              notificationCount === 0 ? (
                              <span
                                className="badge"
                                style={{
                                  fontSize: "13px",
                                  backgroundColor: "none",
                                  marginLeft: "-40%",
                                  color: "transparent",
                                  visibility: "hidden",
                                }}
                              >
                                weee
                              </span>
                            ) : (
                              <span
                                className="badge"
                                style={{
                                  fontSize: "13px",
                                  backgroundColor: "none",
                                  marginLeft: "-14%",
                                  color: "transparent",
                                  visibility: "hidden",
                                }}
                              ></span>
                            )}{" "}
                          </span>
                        </label>
                        {/* <input id="tab6" type="radio" name="tabs" hidden />
                            <label
                              for="tab6"
                              id="Dlabel"
                               onClick={() => {
                                 this.UserOrgLeft();
                               }}
                            ></label> */}
                        <input id="tab6" type="radio" name="tabs" hidden />
                        <label
                          for="tab6"
                          id="Dlabel"
                          onClick={() => {
                            this.StateCheck();
                            this.UserOrgTrend();
                            document.getElementById("FilterErr1").innerHTML = "";
                          }}
                        >
                          <span className="hover-zoom">
                            <i
                              className="fa-solid fa-arrow-trend-up"
                              id="trend"
                            ></i>
                            &nbsp;&nbsp;
                            <span id="DText">Trending </span>&nbsp;{" "}
                          </span>
                          <span>
                            {publicCount === 0 &&
                              groupCount === 0 &&
                              individualCount === 0 &&
                              notificationCount === 0 ? (
                              <span
                                className="badge"
                                style={{
                                  fontSize: "13px",
                                  backgroundColor: "none",
                                  marginLeft: "-35%",
                                  color: "transparent",
                                  visibility: "hidden",
                                }}
                              ></span>
                            ) : (
                              <span
                                className="badge"
                                style={{
                                  fontSize: "13px",
                                  backgroundColor: "none",
                                  marginLeft: "-21%",
                                  color: "transparent",
                                  visibility: "hidden",
                                }}
                              ></span>
                            )}{" "}
                          </span>
                        </label>
                      </div>

                      <div style={{ marginTop: "20px" }}>
                        <div>
                          <main
                            id="maintabA"
                          >
                            <div>
                              <input
                                id="searchfordisc"
                                className="Searchbox"
                                placeholder="Search"
                                style={{ float: "right", marginRight: "20px" }}
                                // onKeyPress={this.handleKeyPress}
                                ref={this.firstInputRef}
                                onClick={this.setFocus}
                                onChange={this.DiscussionFilter}
                              />
                              <br />
                              <br />
                              <br />

                              <center>
                                <span
                                  id="FilterErr1"
                                  style={{ color: "red" }}
                                ></span>
                              </center>
                            </div>
                            <div>
                              <div className="col-lg-12">
                                <input
                                  id="tab1"
                                  type="radio"
                                  name="tabs"
                                  defaultChecked
                                  style={{ display: "none" }}
                                />
                                <input id="tab2" type="radio" name="tabs" />

                                {Filterpop && (
                                  <div className="AccPop">
                                    <div className="accesspopSearchinnernormal1">
                                      <div
                                        className="accesspopSearchinnertitle"
                                        id="accesspopSearchinnernormal"
                                      >
                                        <button
                                          id="close"
                                          style={{
                                            float: "right",
                                            backgroundColor: "#a0a3a305",
                                            color: "#797070",
                                            border: "1px solid #a3a5a505",
                                            marginTop: "-20px",
                                            marginRight: "-20px",
                                          }}
                                          onClick={this.closefuncdisc}
                                        >
                                          <CloseIcon />
                                        </button>

                                        <div className="checkboxes">
                                          <i
                                            className="fa fa-search"
                                            aria-hidden="true"
                                            style={{
                                              fontSize: "20px",
                                              color: "gray",
                                              // marginLeft: "-1%",
                                              position: "absolute",
                                              marginTop: "0.5%",
                                            }}
                                          ></i>
                                          <input
                                            id="searchfordisc"
                                            className="Searchbox"
                                            placeholder="Search"
                                            onKeyUp={this.DiscussionFilter}
                                            style={{
                                              float: "right",
                                              width: "80%",
                                            }}
                                            ref={this.searchForDiscRef}
                                          />
                                          <br></br>
                                          {this.state.letters.map(
                                            (letter, index) => (
                                              <button
                                                id="searchfordisc"
                                                className="filter"
                                                key={index}
                                                onClick={(event) =>
                                                  this.LetterDiscussionFilter(
                                                    letter,
                                                    event
                                                  )
                                                }
                                              >
                                                {letter}
                                              </button>
                                            )
                                          )}
                                          <br></br>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                              {/* {Announcement.length > 0 && ( */}

                              {/* Current Discussion */}

                              <>
                                <>
                                  {RoleA === "Admin" ?
                                    (
                                      <>
                                        {Alluser1.map((Announcement) => {
                                          let Message = Announcement.message;
                                          let Messageresult = Message.slice(
                                            0,
                                            55
                                          );
                                          let Sender = Announcement.senderId;
                                          const isSender =
                                            Sender.includes(UserId);

                                          return (
                                            <>
                                              {UserMail ===
                                                Announcement.userName ||
                                                UserMail === Announcement.email ? (
                                                <div
                                                  className="media userlist-box"
                                                  data-id="1"
                                                  data-status="online"
                                                  data-toggle="tooltip"
                                                  data-placement="left"
                                                  // key={Announcement.id,Announcement.usermsgId}
                                                  key={[
                                                    Announcement.id,
                                                    Announcement.usermsgId,
                                                  ].join("-")}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.Chatpop(
                                                      Announcement.displayName ===
                                                        null ||
                                                        Announcement.displayName ===
                                                        USERNAME
                                                        ? Announcement.receiver ===
                                                          USERNAME
                                                          ? Announcement.firstName
                                                          : Announcement.receiver
                                                        : Announcement.displayName,
                                                      Announcement.userName ===
                                                        UserMail
                                                        ? Announcement.email
                                                        : Announcement.userName,
                                                      UserId ===
                                                        Announcement.senderId
                                                        ? Announcement.particularProfile_Picture
                                                        : Announcement.senderProfile_Picture,
                                                      Announcement.email ===
                                                        UserMail
                                                        ? Announcement.userName
                                                        : Announcement.email,
                                                      Announcement.senderId,
                                                      Announcement.particularId
                                                    );
                                                    this.markMessageAsSeenUser(
                                                      Announcement.senderId,
                                                      Announcement.particularId
                                                    );
                                                  }}
                                                  style={{
                                                    padding: "7px",
                                                    height: "65px",
                                                  }}
                                                >
                                                  <a className="media-left">
                                                    {UserId ===
                                                      Announcement.senderId ? (
                                                      <img
                                                        className="media-object img-circle"
                                                        src={
                                                          Announcement.particularProfile_Picture
                                                        }
                                                        alt="Generic placeholder image"
                                                        style={{
                                                          height: "50px",
                                                          width: "50px",
                                                          padding: "1px 1px",
                                                          marginLeft: "20px",
                                                        }}
                                                      />
                                                    ) : (
                                                      <img
                                                        className="media-object img-circle"
                                                        src={
                                                          Announcement.senderProfile_Picture
                                                        }
                                                        alt="Generic placeholder image"
                                                        style={{
                                                          height: "50px",
                                                          width: "50px",
                                                          padding: "1px 1px",
                                                          marginLeft: "20px",
                                                        }}
                                                      />
                                                    )}
                                                  </a>
                                                  <div
                                                    className="media-body"
                                                    style={{
                                                      textAlign: "initial",
                                                      // padding: "2px 2px",
                                                    }}
                                                  >
                                                    <div className="f-13 chat-header">
                                                      {" "}
                                                      <span
                                                        style={{
                                                          color: "black",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        <b>
                                                          {Announcement.displayName ===
                                                            null ||
                                                            Announcement.displayName ===
                                                            USERNAME
                                                            ? Announcement.receiver ===
                                                              USERNAME
                                                              ? Announcement.firstName
                                                              : Announcement.receiver
                                                            : Announcement.displayName}{" "}
                                                          <img
                                                            src={userpng}
                                                            style={{
                                                              width: "15px",
                                                              background: "white",
                                                            }}
                                                          />
                                                        </b>
                                                        <ReactTimeAgo
                                                          date={
                                                            Announcement.createDateTime
                                                          }
                                                          locale="en-US"
                                                          timeStyle="round-minute"
                                                          style={{
                                                            color: "gray",
                                                            float: "right",
                                                            fontSize: "10px",
                                                            marginTop: "10px",
                                                            marginRight: "10px",
                                                            fontWeight: "bold",
                                                          }}
                                                        />
                                                      </span>
                                                      <br />
                                                      {Announcement.senderId ==
                                                        JSON.parse(
                                                          localStorage.userId
                                                        ) ? (
                                                        <span
                                                          style={{
                                                            color: "black",
                                                            whiteSpace: "nowrap",
                                                          }}
                                                        >
                                                          <div
                                                            onClick={() =>
                                                              this.markMessageAsSeenUser(
                                                                Announcement.senderId,
                                                                Announcement.particularId
                                                              )
                                                            }
                                                          >
                                                            You:{" "}
                                                            {Announcement.seen ==
                                                              1 ? (
                                                              <img
                                                                src={doubletick}
                                                                style={{
                                                                  // marginTop: "50px",
                                                                  marginLeft:
                                                                    "-2px",
                                                                  width: "20px",
                                                                  backgroundImage:
                                                                    "green",
                                                                }}
                                                                title="Seen"
                                                              />
                                                            ) : null}{" "}
                                                            {Announcement.seen ==
                                                              0 ? (
                                                              <img
                                                                src={singletick}
                                                                style={{
                                                                  // marginTop: "50px",
                                                                  marginLeft:
                                                                    "-2px",
                                                                  width: "20px",
                                                                  backgroundImage:
                                                                    "green",
                                                                }}
                                                                title="Unread"
                                                              />
                                                            ) : null}{" "}
                                                            {
                                                              Announcement.message
                                                                === "-"
                                                                ? "Sent a File"
                                                                : <>
                                                                  {Messageresult}
                                                                  {Announcement
                                                                    .message.length >
                                                                    85
                                                                    ? "..."
                                                                    : null}{" "}
                                                                </>}{" "}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {Announcement.senderId !==
                                                              UserId ? (
                                                              <>
                                                                {Announcement.counts >
                                                                  0 ? (
                                                                  <p
                                                                    className="badge badge-primary"
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      backgroundColor:
                                                                        "#563ccf",
                                                                    }}
                                                                  >
                                                                    {
                                                                      Announcement.counts
                                                                    }
                                                                  </p>
                                                                ) : null}
                                                              </>
                                                            ) : null}
                                                            {/* <ReactTimeAgo
                                                              date={
                                                                Announcement.createDateTime
                                                              }
                                                              locale="en-US"
                                                              timeStyle="round-minute"
                                                              style={{
                                                                color: "gray",
                                                                float: "right",
                                                                fontSize:
                                                                  "11px",
                                                                fontWeight: 100,
                                                              }}
                                                            /> */}
                                                          </div>
                                                        </span>
                                                      ) : (
                                                        <span
                                                          style={{
                                                            color: "black",
                                                            whiteSpace: "nowrap",
                                                          }}
                                                        >
                                                          <div
                                                            onClick={() =>
                                                              this.markMessageAsSeenUser(
                                                                Announcement.senderId,
                                                                Announcement.particularId
                                                              ) ||
                                                              this.markMessageAsSeenAdmin(
                                                                Announcement.senderId,
                                                                Announcement.particularId
                                                              )
                                                            }
                                                          >
                                                            {Announcement.displayName ===
                                                              null ||
                                                              Announcement.displayName ===
                                                              USERNAME
                                                              ? Announcement.receiver ===
                                                                USERNAME
                                                                ? Announcement.firstName
                                                                : Announcement.receiver
                                                              : Announcement.displayName}
                                                            :{" "}
                                                            {
                                                              Announcement.message
                                                                === "-"
                                                                ? "Sent a File"
                                                                : <>
                                                                  {Messageresult}
                                                                  {Announcement
                                                                    .message.length >
                                                                    85
                                                                    ? "..."
                                                                    : null}{" "}
                                                                </>}{" "}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {Announcement.senderId !==
                                                              UserId ? (
                                                              <>
                                                                {Announcement.counts >
                                                                  0 ? (
                                                                  <p
                                                                    className="badge badge-primary"
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      backgroundColor:
                                                                        "#563ccf",
                                                                    }}
                                                                  >
                                                                    {
                                                                      Announcement.counts
                                                                    }
                                                                  </p>
                                                                ) : null}
                                                              </>
                                                            ) : null}
                                                            {/* <ReactTimeAgo
                                                              date={
                                                                Announcement.createDateTime
                                                              }
                                                              locale="en-US"
                                                              timeStyle="twitter"
                                                              style={{
                                                                color: "gray",
                                                                float: "right",
                                                              }}
                                                            /> */}
                                                          </div>
                                                        </span>
                                                      )}
                                                      <br />
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <>
                                                  {/* let Message = User.message;
                                            let Messageresult = Message.slice(0, 55); */}
                                                  {UserMail ===
                                                    Announcement.userName ||
                                                    UserMail ===
                                                    Announcement.email ||
                                                    UserMail ===
                                                    Announcement.group_Member ? (
                                                    <div
                                                      className="media userlist-box"
                                                      data-id="1"
                                                      data-status="online"
                                                      data-toggle="tooltip"
                                                      data-placement="left"
                                                      // key={Announcement.id,Announcement.usermsgId}
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.EditSkillModels(
                                                          Announcement.group_id,
                                                          Announcement.group_Name,
                                                          Announcement.created_by,
                                                          Announcement.privacy
                                                        );

                                                        this.markMessageAsSeen(
                                                          Announcement.group_id
                                                        );
                                                        //this.handleButtonClick1();
                                                        // this.handlehide();
                                                      }}
                                                      style={{ padding: "7px" }}
                                                    >
                                                      <a className="media-left">
                                                        {Announcement.profile ==
                                                          `${Image_URLs}Images/profile.png` ? (
                                                          <img
                                                            className="media-object img-circle"
                                                            src={Profile}
                                                            alt="1Generic placeholder image"
                                                            style={{
                                                              height: "50px",
                                                              width: "50px",
                                                              padding: "1px 1px",
                                                              marginLeft: "20px",
                                                            }}
                                                          />
                                                        ) : (
                                                          <img
                                                            className="media-object img-circle"
                                                            src={
                                                              Announcement.profile
                                                            }
                                                            alt="Generic placeholder image"
                                                            style={{
                                                              height: "50px",
                                                              width: "50px",
                                                              padding: "1px 1px",
                                                              marginLeft: "20px",
                                                            }}
                                                          />
                                                        )}
                                                      </a>
                                                      <div
                                                        className="media-body"
                                                        style={{
                                                          textAlign: "initial",
                                                        }}
                                                      >
                                                        <div className="f-13 chat-header">
                                                          {" "}
                                                          {/* <span style={{ color: "Gray" }}>{Announcement.teamId}</span>&nbsp; */}
                                                          <span
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              this.EditSkillModels(
                                                                Announcement.group_id,
                                                                Announcement.group_Name,
                                                                Announcement.created_by,
                                                                Announcement.privacy
                                                              );
                                                              //this.handleButtonClick1();
                                                            }}
                                                            style={{
                                                              color: "black",
                                                              whiteSpace:
                                                                "nowrap",
                                                              fontWeight: "600",
                                                            }}
                                                          >
                                                            {
                                                              Announcement.group_Name
                                                            }{" "}
                                                            {checkPrivacyandCreated.map(
                                                              (d) => {
                                                                if (
                                                                  d.group_Name ===
                                                                  Announcement.group_Name &&
                                                                  d.user_id ===
                                                                  MailIds
                                                                ) {
                                                                  return (
                                                                    <span>
                                                                      {d.privacy ===
                                                                        0 ? (
                                                                        <>
                                                                          <img
                                                                            src={
                                                                              lockpng
                                                                            }
                                                                            style={{
                                                                              width:
                                                                                "12px",
                                                                              background:
                                                                                "white",
                                                                            }}
                                                                          />
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          <img
                                                                            src={
                                                                              publicpng
                                                                            }
                                                                            style={{
                                                                              width:
                                                                                "14px",
                                                                              background:
                                                                                "white",
                                                                            }}
                                                                          />
                                                                        </>
                                                                      )}
                                                                    </span>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                            &nbsp;
                                                            <ReactTimeAgo
                                                              date={
                                                                Announcement.createDateTime
                                                              }
                                                              locale="en-US"
                                                              timeStyle="round-minute"
                                                              style={{
                                                                color: "gray",
                                                                float: "right",
                                                                fontSize: "10px",
                                                                marginTop: "10px",
                                                                marginRight:
                                                                  "10px",
                                                              }}
                                                            />
                                                            {checkPrivacyandCreated.map(
                                                              (d) => {
                                                                if (
                                                                  d.group_Name ===
                                                                  Announcement.group_Name &&
                                                                  d.user_id ===
                                                                  MailIds
                                                                ) {
                                                                  return (
                                                                    <span>
                                                                      {d.privacy ===
                                                                        0 &&
                                                                        d.created_by ===
                                                                        JSON.parse(
                                                                          localStorage.userId
                                                                        ) ? (
                                                                        <>
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "10px",
                                                                            }}
                                                                          >
                                                                            by You{" "}
                                                                            on{" "}
                                                                            {this.formatDateAA2(
                                                                              d.created_Date
                                                                            )}
                                                                          </span>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "10px",
                                                                            }}
                                                                          >
                                                                            by{" "}
                                                                            {
                                                                              d.created_Name
                                                                            }{" "}
                                                                            on{" "}
                                                                            {this.formatDateAA2(
                                                                              d.created_Date
                                                                            )}
                                                                          </span>
                                                                        </>
                                                                      )}
                                                                    </span>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </span>
                                                          <br />
                                                          {Announcement.senderId ==
                                                            JSON.parse(
                                                              localStorage.userId
                                                            ) ? (
                                                            <span
                                                              style={{
                                                                color: "black",
                                                                whiteSpace:
                                                                  "nowrap",
                                                              }}
                                                              onClick={() =>
                                                                this.markMessageAsSeen(
                                                                  Announcement.group_id
                                                                )
                                                              }
                                                            >
                                                              {/* {User.senderId == JSON.parse(localStorage.userId) */}

                                                              {Announcement.type ==
                                                                null ? (
                                                                <>
                                                                  You{" "}
                                                                  {Messageresult}
                                                                  {Announcement
                                                                    .message
                                                                    .length > 85
                                                                    ? "..."
                                                                    : null}{" "}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  {/* You:{User.message} */}
                                                                  {Announcement.message ==
                                                                    "-" ? (
                                                                    <>
                                                                      {" "}
                                                                      You: Sent a
                                                                      File
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      {" "}
                                                                      You:{" "}{(isSender &&
                                                                        Announcement.displayStatus == 1) && !Announcement.message.includes("Ended Discussion On")
                                                                        && !Announcement.message.includes("Added") ? (
                                                                        <img
                                                                          src={doubletick}
                                                                          style={{
                                                                            // marginTop: "50px",
                                                                            marginLeft: "-2px",
                                                                            width: "20px",
                                                                          }}
                                                                          title="Seen"
                                                                        />
                                                                      ) : isSender && !Announcement.message.includes("Ended Discussion On")
                                                                        && !Announcement.message.includes("Added") ? <img
                                                                        src={singletick}
                                                                        style={{
                                                                          // marginTop: "50px",
                                                                          marginLeft: "-2px",
                                                                          width: "20px",

                                                                        }}
                                                                        title="Unread"
                                                                      /> : null}{" "}
                                                                      {
                                                                        Messageresult
                                                                      }
                                                                      {Announcement
                                                                        .message
                                                                        .length >
                                                                        85
                                                                        ? "..."
                                                                        : null}
                                                                    </>
                                                                  )}
                                                                </>
                                                              )}
                                                            </span>
                                                          ) : (
                                                            <span
                                                              style={{
                                                                color: "black",
                                                                whiteSpace:
                                                                  "nowrap",
                                                              }}
                                                            >
                                                              {/* {User.displayName}:{User.message} */}
                                                              {Announcement.type ==
                                                                null ? (
                                                                <>
                                                                  {
                                                                    Announcement.displayName
                                                                  }{" "}
                                                                  {/* {Messageresult} */}
                                                                  {Messageresult.includes(name)?Messageresult.replace(name, 'You'):Messageresult}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  {Announcement.message ==
                                                                    "-" ? (
                                                                    <>
                                                                      {
                                                                        Announcement.displayName
                                                                      }
                                                                      :
                                                                      {Announcement.seen ==
                                                                        0 ? (
                                                                        <b>
                                                                          Sent a
                                                                          File
                                                                        </b>
                                                                      ) : (
                                                                        Announcement.seen ==
                                                                        1 &&
                                                                        "Sent a File"
                                                                      )}
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      {
                                                                        Announcement.displayName
                                                                      }
                                                                      :
                                                                      {Announcement.seen ==
                                                                        0 ? (
                                                                        <b>
                                                                          {" "}
                                                                          {
                                                                            Messageresult
                                                                          }
                                                                          {Announcement
                                                                            .message
                                                                            .length >
                                                                            85
                                                                            ? "..."
                                                                            : null}
                                                                        </b>
                                                                      ) : (
                                                                        Announcement.seen ==
                                                                        1 &&
                                                                        `${" "}${Messageresult}${Announcement
                                                                          .message
                                                                          .length >
                                                                          85
                                                                          ? "..."
                                                                          : " "
                                                                        }`
                                                                      )}
                                                                    </>
                                                                  )}
                                                                </>
                                                              )}
                                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                                              {Announcement.counts >
                                                                0 ? (
                                                                <p
                                                                  className="badge badge-primary"
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    backgroundColor:
                                                                      "#563ccf",
                                                                  }}
                                                                >
                                                                  {
                                                                    Announcement.counts
                                                                  }
                                                                </p>
                                                              ) : null}
                                                            </span>
                                                          )}
                                                          {/* {username} */}
                                                          <br />
                                                          {/* <span
                                                            style={{
                                                              color: "Gray",
                                                              whiteSpace: "nowrap",
                                                            }}
                                                          >
                                                            {Announcement.email}
                                                          </span> */}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </>
                                              )}
                                            </>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <>
                                        {Alluser1.map((Announcement) => {
                                          let Message = Announcement.message;
                                          let Messageresult = Message.slice(
                                            0,
                                            55
                                          );
                                          let Sender = Announcement.senderId;
                                          const isSender =
                                            Sender.includes(UserId);

                                          return (
                                            <>
                                              {UserMail ===
                                                Announcement.userName ||
                                                UserMail === Announcement.email ? (
                                                <div
                                                  className="media userlist-box"
                                                  data-id="1"
                                                  data-status="online"
                                                  data-toggle="tooltip"
                                                  data-placement="left"
                                                  // key={Announcement.id,Announcement.usermsgId}
                                                  key={[
                                                    Announcement.id,
                                                    Announcement.usermsgId,
                                                  ].join("-")}
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.Chatpop(
                                                      Announcement.displayName ===
                                                        null ||
                                                        Announcement.displayName ===
                                                        USERNAME
                                                        ? Announcement.receiver ===
                                                          USERNAME
                                                          ? Announcement.firstName
                                                          : Announcement.receiver
                                                        : Announcement.displayName,
                                                      Announcement.userName ===
                                                        UserMail
                                                        ? Announcement.email
                                                        : Announcement.userName,
                                                      UserId ===
                                                        Announcement.senderId
                                                        ? Announcement.particularProfile_Picture
                                                        : Announcement.senderProfile_Picture,
                                                      Announcement.email ===
                                                        UserMail
                                                        ? Announcement.userName
                                                        : Announcement.email,
                                                      Announcement.senderId,
                                                      Announcement.particularId

                                                    );

                                                    this.markMessageAsSeenAdmin(
                                                      Announcement.senderId,
                                                      Announcement.particularId
                                                    );
                                                  }}
                                                  style={{
                                                    padding: "7px",
                                                    height: "65px",
                                                  }}
                                                >
                                                  <a className="media-left">
                                                    {UserId ===
                                                      Announcement.senderId ? (
                                                      <img
                                                        className="media-object img-circle"
                                                        src={
                                                          Announcement.particularProfile_Picture
                                                        }
                                                        alt="Generic placeholder image"
                                                        style={{
                                                          height: "50px",
                                                          width: "50px",
                                                          padding: "1px 1px",
                                                          marginLeft: "20px",
                                                        }}
                                                      />
                                                    ) : (
                                                      <img
                                                        className="media-object img-circle"
                                                        src={
                                                          Announcement.senderProfile_Picture
                                                        }
                                                        alt="Generic placeholder image"
                                                        style={{
                                                          height: "50px",
                                                          width: "50px",
                                                          padding: "1px 1px",
                                                          marginLeft: "20px",
                                                        }}
                                                      />
                                                    )}
                                                  </a>
                                                  <div
                                                    className="media-body"
                                                    style={{
                                                      textAlign: "initial",
                                                      // padding: "2px 2px",
                                                    }}
                                                  >
                                                    <div className="f-13 chat-header">
                                                      {" "}
                                                      <span
                                                        style={{
                                                          color: "black",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        <b>
                                                          {Announcement.displayName ===
                                                            null ||
                                                            Announcement.displayName ===
                                                            USERNAME
                                                            ? Announcement.receiver ===
                                                              USERNAME
                                                              ? Announcement.firstName
                                                              : Announcement.receiver
                                                            : Announcement.displayName}
                                                        </b>
                                                        &nbsp;
                                                        <span>
                                                          <img
                                                            src={userpng}
                                                            style={{
                                                              width: "15px",
                                                              background: "white",
                                                            }}
                                                          />
                                                        </span>
                                                        <ReactTimeAgo
                                                          date={
                                                            Announcement.createDateTime
                                                          }
                                                          locale="en-US"
                                                          timeStyle="round-minute"
                                                          style={{
                                                            color: "gray",
                                                            float: "right",
                                                            fontSize: "10px",
                                                            marginTop: "10px",
                                                            marginRight: "10px",
                                                            fontWeight: "bold",
                                                          }}
                                                        />
                                                      </span>
                                                      <br />
                                                      {Announcement.senderId ==
                                                        JSON.parse(
                                                          localStorage.userId
                                                        ) ? (
                                                        <span
                                                          style={{
                                                            color: "black",
                                                            whiteSpace: "nowrap",
                                                          }}
                                                        >
                                                          <div
                                                          >
                                                            You:{" "}
                                                            {Announcement.seen ==
                                                              1 ? (
                                                              <img
                                                                src={doubletick}
                                                                style={{
                                                                  // marginTop: "50px",
                                                                  marginLeft:
                                                                    "-2px",
                                                                  width: "20px",
                                                                  backgroundImage:
                                                                    "green",
                                                                }}
                                                                title="Seen"
                                                              />
                                                            ) : null}{" "}
                                                            {Announcement.seen ==
                                                              0 ? (
                                                              <img
                                                                src={singletick}
                                                                style={{
                                                                  // marginTop: "50px",
                                                                  marginLeft:
                                                                    "-2px",
                                                                  width: "20px",
                                                                  backgroundImage:
                                                                    "green",
                                                                }}
                                                                title="Unread"
                                                              />
                                                            ) : null}{" "}
                                                            {
                                                              Announcement.message
                                                                === "-"
                                                                ? "Sent a File"
                                                                : <>
                                                                  {Messageresult}
                                                                  {Announcement
                                                                    .message.length >
                                                                    85
                                                                    ? "..."
                                                                    : null}{" "}
                                                                </>}{" "}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {Announcement.senderId !==
                                                              UserId ? (
                                                              <>
                                                                {Announcement.counts >
                                                                  0 ? (
                                                                  <p
                                                                    className="badge badge-primary"
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      backgroundColor:
                                                                        "#563ccf",
                                                                    }}
                                                                  >
                                                                    {
                                                                      Announcement.counts
                                                                    }
                                                                  </p>
                                                                ) : null}
                                                              </>
                                                            ) : null}
                                                          </div>
                                                        </span>
                                                      ) : (
                                                        <span
                                                          style={{
                                                            color: "black",
                                                            whiteSpace: "nowrap",
                                                          }}
                                                        >
                                                          <div
                                                            onClick={() => {
                                                              this.markMessageAsSeenAdmin(
                                                                Announcement.senderId,
                                                                Announcement.particularId
                                                              );
                                                              this.markMessageAsSeenUser(
                                                                Announcement.senderId,
                                                                Announcement.particularId
                                                              );
                                                            }}
                                                          >
                                                            {Announcement.displayName ===
                                                              null ||
                                                              Announcement.displayName ===
                                                              USERNAME
                                                              ? Announcement.receiver ===
                                                                USERNAME
                                                                ? Announcement.firstName
                                                                : Announcement.receiver
                                                              : Announcement.displayName}
                                                            :{" "}
                                                            {
                                                              Announcement.message
                                                                === "-"
                                                                ? "Sent a File"
                                                                : <>
                                                                  {Messageresult}
                                                                  {Announcement
                                                                    .message.length >
                                                                    85
                                                                    ? "..."
                                                                    : null}{" "}
                                                                </>}{" "}
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {Announcement.senderId !==
                                                              UserId ? (
                                                              <>
                                                                {Announcement.counts >
                                                                  0 ? (
                                                                  <p
                                                                    className="badge badge-primary"
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      backgroundColor:
                                                                        "#563ccf",
                                                                    }}
                                                                  >
                                                                    {
                                                                      Announcement.counts
                                                                    }
                                                                  </p>
                                                                ) : null}
                                                              </>
                                                            ) : null}
                                                            {/* <ReactTimeAgo
                                                              date={
                                                                Announcement.createDateTime
                                                              }
                                                              locale="en-US"
                                                              timeStyle="round-minute"
                                                              style={{
                                                                color: "gray",
                                                                float: "right",
                                                              }}
                                                            /> */}
                                                          </div>
                                                        </span>
                                                      )}
                                                      <br />
                                                    </div>
                                                  </div>
                                                </div>
                                              ) : (
                                                <>
                                                  {/* let Message = User.message;
                                            let Messageresult = Message.slice(0, 55); */}
                                                  {UserMail ===
                                                    Announcement.userName ||
                                                    UserMail ===
                                                    Announcement.email ||
                                                    UserMail ===
                                                    Announcement.group_Member ? (
                                                    <div
                                                      className="media userlist-box"
                                                      data-id="1"
                                                      data-status="online"
                                                      data-toggle="tooltip"
                                                      data-placement="left"
                                                      // key={Announcement.id,Announcement.usermsgId}
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        this.EditSkillModels(
                                                          Announcement.group_id,
                                                          Announcement.group_Name,
                                                          Announcement.created_by,
                                                          Announcement.privacy
                                                        );

                                                        this.markMessageAsSeen(
                                                          Announcement.group_id
                                                        );
                                                        //this.handleButtonClick1();
                                                        // this.handlehide();
                                                      }}
                                                      style={{ padding: "7px" }}
                                                    >
                                                      <a className="media-left">
                                                        {Announcement.profile ==
                                                          `${Image_URLs}Images/profile.png` ? (
                                                          <img
                                                            className="media-object img-circle"
                                                            src={Profile}
                                                            alt="1Generic placeholder image"
                                                            style={{
                                                              height: "50px",
                                                              width: "50px",
                                                              padding: "1px 1px",
                                                              marginLeft: "20px",
                                                            }}
                                                          />
                                                        ) : (
                                                          <img
                                                            className="media-object img-circle"
                                                            src={
                                                              Announcement.profile
                                                            }
                                                            alt="Generic placeholder image"
                                                            style={{
                                                              height: "50px",
                                                              width: "50px",
                                                              padding: "1px 1px",
                                                              marginLeft: "20px",
                                                            }}
                                                          />
                                                        )}
                                                      </a>
                                                      <div
                                                        className="media-body"
                                                        style={{
                                                          textAlign: "initial",
                                                        }}
                                                      >
                                                        <div className="f-13 chat-header">
                                                          {" "}
                                                          {/* <span style={{ color: "Gray" }}>{Announcement.teamId}</span>&nbsp; */}
                                                          <span
                                                            onClick={(e) => {
                                                              e.preventDefault();
                                                              this.EditSkillModels(
                                                                Announcement.group_id,
                                                                Announcement.group_Name,
                                                                Announcement.created_by,
                                                                Announcement.privacy
                                                            
                                                              );
                                                              //this.handleButtonClick1();
                                                            }}
                                                            style={{
                                                              color: "black",
                                                              whiteSpace:
                                                                "nowrap",
                                                              fontWeight: "600",
                                                            }}
                                                          >
                                                            {
                                                              Announcement.group_Name
                                                            }
                                                            &nbsp;
                                                            {checkPrivacyandCreated.map(
                                                              (d) => {
                                                                if (
                                                                  d.group_Name ===
                                                                  Announcement.group_Name &&
                                                                  d.user_id ===
                                                                  MailIds
                                                                ) {
                                                                  return (
                                                                    <span>
                                                                      {d.privacy ===
                                                                        0 ? (
                                                                        <>
                                                                          <img
                                                                            src={
                                                                              lockpng
                                                                            }
                                                                            style={{
                                                                              width:
                                                                                "12px",
                                                                              background:
                                                                                "white",
                                                                            }}
                                                                          />
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          <img
                                                                            src={
                                                                              publicpng
                                                                            }
                                                                            style={{
                                                                              width:
                                                                                "14px",
                                                                              background:
                                                                                "white",
                                                                            }}
                                                                          />
                                                                        </>
                                                                      )}
                                                                    </span>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                            &nbsp;
                                                            <ReactTimeAgo
                                                              date={
                                                                Announcement.createDateTime
                                                              }
                                                              locale="en-US"
                                                              timeStyle="round-minute"
                                                              style={{
                                                                color: "gray",
                                                                float: "right",
                                                                fontSize: "10px",
                                                                marginTop: "10px",
                                                                marginRight:
                                                                  "10px",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            />
                                                            {checkPrivacyandCreated.map(
                                                              (d) => {
                                                                if (
                                                                  d.group_Name ===
                                                                  Announcement.group_Name &&
                                                                  d.user_id ===
                                                                  MailIds
                                                                ) {
                                                                  return (
                                                                    <span>
                                                                      {d.privacy ===
                                                                        0 &&
                                                                        d.created_by ===
                                                                        JSON.parse(
                                                                          localStorage.userId
                                                                        ) ? (
                                                                        <>
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "10px",
                                                                            }}
                                                                          >
                                                                            by You{" "}
                                                                            on{" "}
                                                                            {this.formatDateAA2(
                                                                              d.created_Date
                                                                            )}
                                                                          </span>
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          <span
                                                                            style={{
                                                                              fontSize:
                                                                                "10px",
                                                                            }}
                                                                          >
                                                                            by{" "}
                                                                            {
                                                                              d.created_Name
                                                                            }{" "}
                                                                            on{" "}
                                                                            {this.formatDateAA2(
                                                                              d.created_Date
                                                                            )}
                                                                          </span>
                                                                        </>
                                                                      )}
                                                                    </span>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </span>
                                                          <br />
                                                          {Announcement.senderId ==
                                                            JSON.parse(
                                                              localStorage.userId
                                                            ) ? (
                                                            <span
                                                              style={{
                                                                color: "black",
                                                                whiteSpace:
                                                                  "nowrap",
                                                              }}
                                                              onClick={() =>
                                                                this.markMessageAsSeen(
                                                                  Announcement.group_id
                                                                )
                                                              }
                                                            >
                                                              {/* {User.senderId == JSON.parse(localStorage.userId) */}

                                                              {Announcement.type ==
                                                                null ? (
                                                                <>
                                                                  You{" "}
                                                                  {Messageresult}
                                                                  {Announcement
                                                                    .message
                                                                    .length > 85
                                                                    ? "..."
                                                                    : null}{" "}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  {/* You:{User.message} */}
                                                                  {Announcement.message ==
                                                                    "-" ? (
                                                                    <>
                                                                      {" "}
                                                                      You: Sent a
                                                                      File
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      {" "}
                                                                      You:{" "}
                                                                      {(isSender &&
                                                                        Announcement.displayStatus == 1) && !Announcement.message.includes("Ended Discussion On")
                                                                        && !Announcement.message.includes("Added") ? (
                                                                        <img
                                                                          src={doubletick}
                                                                          style={{
                                                                            // marginTop: "50px",
                                                                            marginLeft: "-2px",
                                                                            width: "20px",
                                                                          }}
                                                                          title="Seen"
                                                                        />
                                                                      ) : isSender && !Announcement.message.includes("Ended Discussion On")
                                                                        && !Announcement.message.includes("Added") ? <img
                                                                        src={singletick}
                                                                        style={{
                                                                          // marginTop: "50px",
                                                                          marginLeft: "-2px",
                                                                          width: "20px",

                                                                        }}
                                                                        title="Unread"
                                                                      /> : null}{" "}
                                                                      {
                                                                        Messageresult
                                                                      }
                                                                      {Announcement
                                                                        .message
                                                                        .length >
                                                                        85
                                                                        ? "..."
                                                                        : null}
                                                                    </>
                                                                  )}
                                                                </>
                                                              )}
                                                            </span>
                                                          ) : (
                                                            <span
                                                              style={{
                                                                color: "black",
                                                                whiteSpace:
                                                                  "nowrap",
                                                              }}
                                                            >
                                                              {/* {User.displayName}:{User.message} */}
                                                              {Announcement.type ==
                                                                null ? (
                                                                <>
                                                                  {
                                                                    Announcement.displayName
                                                                  }{" "}
                                                                  {/* {Messageresult} */}
                                                                   {Messageresult.includes(name)?Messageresult.replace(name, 'You'):Messageresult}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  {Announcement.message ==
                                                                    "-" ? (
                                                                    <>
                                                                      {
                                                                        Announcement.displayName
                                                                      }
                                                                      :
                                                                      {Announcement.seen ==
                                                                        0 ? (
                                                                        <b>
                                                                          Sent a
                                                                          File
                                                                        </b>
                                                                      ) : (
                                                                        Announcement.seen ==
                                                                        1 &&
                                                                        "Sent a File"
                                                                      )}
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      {
                                                                        Announcement.displayName
                                                                      }
                                                                      :
                                                                      {Announcement.seen ==
                                                                        0 ? (
                                                                        <b>
                                                                          {" "}
                                                                          {
                                                                            Messageresult
                                                                          }
                                                                          {Announcement
                                                                            .message
                                                                            .length >
                                                                            85
                                                                            ? "..."
                                                                            : null}
                                                                        </b>
                                                                      ) : (
                                                                        Announcement.seen ==
                                                                        1 &&
                                                                        `${" "}${Messageresult}${Announcement
                                                                          .message
                                                                          .length >
                                                                          85
                                                                          ? "..."
                                                                          : " "
                                                                        }`
                                                                      )}
                                                                    </>
                                                                  )}
                                                                </>
                                                              )}
                                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                                              {Announcement.counts >
                                                                0 ? (
                                                                <p
                                                                  className="badge badge-primary"
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    backgroundColor:
                                                                      "#563ccf",
                                                                  }}
                                                                >
                                                                  {
                                                                    Announcement.counts
                                                                  }
                                                                </p>
                                                              ) : null}
                                                            </span>
                                                          )}
                                                          {/* {username} */}
                                                          <br />
                                                          {/* <span
                                                            style={{
                                                              color: "Gray",
                                                              whiteSpace: "nowrap",
                                                            }}
                                                          >
                                                            {Announcement.email}
                                                          </span> */}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </>
                                              )}
                                            </>
                                          );
                                        })}
                                      </>
                                    )}

                                  {/* 1:1 Discussion */}

                                  {RoleA === "Admin" ? (
                                    <>
                                      {OneonOne.map((Announcement) => {
                                        let Message = Announcement.message;
                                        let Messageresult = Message.slice(
                                          0,
                                          55
                                        );

                                        return (
                                          <>
                                            {UserMail ===
                                              Announcement.userName ||
                                              UserMail === Announcement.email ? (
                                              <div
                                                className="media userlist-box"
                                                data-id="1"
                                                data-status="online"
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                // key={Announcement.id,Announcement.usermsgId}
                                                key={[
                                                  Announcement.id,
                                                  Announcement.usermsgId,
                                                ].join("-")}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.Chatpop(
                                                    Announcement.displayName ===
                                                      null ||
                                                      Announcement.displayName ===
                                                      USERNAME
                                                      ? Announcement.receiver ===
                                                        USERNAME
                                                        ? Announcement.firstName
                                                        : Announcement.receiver
                                                      : Announcement.displayName,
                                                    Announcement.userName ===
                                                      UserMail
                                                      ? Announcement.email
                                                      : Announcement.userName,
                                                    UserId ===
                                                      Announcement.senderId
                                                      ? Announcement.particularProfile_Picture
                                                      : Announcement.senderProfile_Picture,
                                                    Announcement.email ===
                                                      UserMail
                                                      ? Announcement.userName
                                                      : Announcement.email,
                                                    Announcement.senderId,
                                                    Announcement.particularId
                                                  );
                                                  this.markMessageAsSeenUser(
                                                    Announcement.senderId,
                                                    Announcement.particularId
                                                  );
                                                }}
                                                style={{
                                                  padding: "7px",
                                                  height: "65px",
                                                }}
                                              >
                                                <a className="media-left">
                                                  {UserId ===
                                                    Announcement.senderId ? (
                                                    <img
                                                      className="media-object img-circle"
                                                      src={
                                                        Announcement.particularProfile_Picture
                                                      }
                                                      alt="Generic placeholder image"
                                                      style={{
                                                        height: "50px",
                                                        width: "50px",
                                                        padding: "1px 1px",
                                                        marginLeft: "20px",
                                                      }}
                                                    />
                                                  ) : (
                                                    <img
                                                      className="media-object img-circle"
                                                      src={
                                                        Announcement.senderProfile_Picture
                                                      }
                                                      alt="Generic placeholder image"
                                                      style={{
                                                        height: "50px",
                                                        width: "50px",
                                                        padding: "1px 1px",
                                                        marginLeft: "20px",
                                                      }}
                                                    />
                                                  )}
                                                </a>
                                                <div
                                                  className="media-body"
                                                  style={{
                                                    textAlign: "initial",
                                                    // padding: "2px 2px",
                                                  }}
                                                >
                                                  <div className="f-13 chat-header">
                                                    {" "}
                                                    <span
                                                      style={{
                                                        color: "black",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      <b>
                                                        {Announcement.displayName ===
                                                          null ||
                                                          Announcement.displayName ===
                                                          USERNAME
                                                          ? Announcement.receiver ===
                                                            USERNAME
                                                            ? Announcement.firstName
                                                            : Announcement.receiver
                                                          : Announcement.displayName}
                                                      </b>{" "}
                                                      <span>
                                                        <img
                                                          src={userpng}
                                                          style={{
                                                            width: "12px",
                                                            background: "white",
                                                          }}
                                                        />
                                                      </span>
                                                      <ReactTimeAgo
                                                        date={
                                                          Announcement.createDateTime
                                                        }
                                                        locale="en-US"
                                                        timeStyle="round-minute"
                                                        style={{
                                                          color: "gray",
                                                          float: "right",
                                                          fontSize: "10px",
                                                          marginTop: "10px",
                                                          marginRight: "10px",
                                                          fontWeight: "bold",
                                                        }}
                                                      />
                                                    </span>
                                                    <br />
                                                    {Announcement.senderId ==
                                                      JSON.parse(
                                                        localStorage.userId
                                                      ) ? (
                                                      <span
                                                        style={{
                                                          color: "black",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        <div
                                                          onClick={() =>
                                                            this.markMessageAsSeenUser(
                                                              Announcement.senderId,
                                                              Announcement.particularId
                                                            )
                                                          }
                                                        >
                                                          You:{" "}
                                                          {Announcement.seen ==
                                                            1 ? (
                                                            <img
                                                              src={doubletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft:
                                                                  "-2px",
                                                                width: "20px",
                                                                backgroundImage:
                                                                  "green",
                                                              }}
                                                              title="Seen"
                                                            />
                                                          ) : null}{" "}
                                                          {Announcement.seen ==
                                                            0 ? (
                                                            <img
                                                              src={singletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft:
                                                                  "-2px",
                                                                width: "20px",
                                                                backgroundImage:
                                                                  "green",
                                                              }}
                                                              title="Unread"
                                                            />
                                                          ) : null}{" "}
                                                          {
                                                            Announcement.message
                                                              === "-"
                                                              ? "Sent a File"
                                                              : <>
                                                                {Messageresult}
                                                                {Announcement
                                                                  .message.length >
                                                                  85
                                                                  ? "..."
                                                                  : null}{" "}
                                                              </>}{" "}
                                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                          {Announcement.senderId !==
                                                            UserId ? (
                                                            <>
                                                              {Announcement.counts >
                                                                0 ? (
                                                                <p
                                                                  className="badge badge-primary"
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    backgroundColor:
                                                                      "#563ccf",
                                                                  }}
                                                                >
                                                                  {
                                                                    Announcement.counts
                                                                  }
                                                                </p>
                                                              ) : null}
                                                            </>
                                                          ) : null}
                                                          {/* <ReactTimeAgo
                                                              date={
                                                                Announcement.createDateTime
                                                              }
                                                              locale="en-US"
                                                              timeStyle="round-minute"
                                                              style={{
                                                                color: "gray",
                                                                float: "right",
                                                                fontSize:
                                                                  "11px",
                                                                fontWeight: 100,
                                                              }}
                                                            /> */}
                                                        </div>
                                                      </span>
                                                    ) : (
                                                      <span
                                                        style={{
                                                          color: "black",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        <div
                                                          onClick={() =>
                                                            this.markMessageAsSeenUser(
                                                              Announcement.senderId,
                                                              Announcement.particularId
                                                            )
                                                          }
                                                        >
                                                          {Announcement.displayName ===
                                                            null ||
                                                            Announcement.displayName ===
                                                            USERNAME
                                                            ? Announcement.receiver ===
                                                              USERNAME
                                                              ? Announcement.firstName
                                                              : Announcement.receiver
                                                            : Announcement.displayName}
                                                          :{" "}
                                                          {
                                                            Announcement.message
                                                              === "-"
                                                              ? "Sent a File"
                                                              : <>
                                                                {Messageresult}
                                                                {Announcement
                                                                  .message.length >
                                                                  85
                                                                  ? "..."
                                                                  : null}{" "}
                                                              </>}{" "}
                                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                          {Announcement.senderId !==
                                                            UserId ? (
                                                            <>
                                                              {Announcement.counts >
                                                                0 ? (
                                                                <p
                                                                  className="badge badge-primary"
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    backgroundColor:
                                                                      "#563ccf",
                                                                  }}
                                                                >
                                                                  {
                                                                    Announcement.counts
                                                                  }
                                                                </p>
                                                              ) : null}
                                                            </>
                                                          ) : null}
                                                          {/* <ReactTimeAgo
                                                          date={
                                                            Announcement.createDateTime
                                                          }
                                                          locale="en-US"
                                                          timeStyle="round-minute"
                                                          style={{
                                                            color: "gray",
                                                            float: "right",
                                                          }}
                                                        /> */}
                                                        </div>
                                                      </span>
                                                    )}
                                                    <br />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <>
                                      {OneonOne.map((Announcement) => {
                                        let Message = Announcement.message;
                                        let Messageresult = Message.slice(
                                          0,
                                          55
                                        );

                                        return (
                                          <>
                                            {UserMail ===
                                              Announcement.userName ||
                                              UserMail === Announcement.email ? (
                                              <div
                                                className="media userlist-box"
                                                data-id="1"
                                                data-status="online"
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                // key={Announcement.id,Announcement.usermsgId}
                                                key={[
                                                  Announcement.id,
                                                  Announcement.usermsgId,
                                                ].join("-")}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.Chatpop(
                                                    Announcement.displayName ===
                                                      null ||
                                                      Announcement.displayName ===
                                                      USERNAME
                                                      ? Announcement.receiver ===
                                                        USERNAME
                                                        ? Announcement.firstName
                                                        : Announcement.receiver
                                                      : Announcement.displayName,
                                                    Announcement.userName ===
                                                      UserMail
                                                      ? Announcement.email
                                                      : Announcement.userName,
                                                    UserId ===
                                                      Announcement.senderId
                                                      ? Announcement.particularProfile_Picture
                                                      : Announcement.senderProfile_Picture,
                                                    Announcement.email ===
                                                      UserMail
                                                      ? Announcement.userName
                                                      : Announcement.email,
                                                    Announcement.senderId,
                                                    Announcement.particularId
                                                  );

                                                  if (
                                                    Announcement.senderId !==
                                                    UserId
                                                  ) {
                                                    this.markMessageAsSeenAdmin(
                                                      Announcement.senderId,
                                                      Announcement.particularId
                                                    );
                                                    this.markMessageAsSeenUser(
                                                      Announcement.senderId,
                                                      Announcement.particularId
                                                    );
                                                  }
                                                }}
                                                style={{
                                                  padding: "7px",
                                                  height: "65px",
                                                }}
                                              >
                                                <a className="media-left">
                                                  {UserId ===
                                                    Announcement.senderId ? (
                                                    <img
                                                      className="media-object img-circle"
                                                      src={
                                                        Announcement.particularProfile_Picture
                                                      }
                                                      alt="Generic placeholder image"
                                                      style={{
                                                        height: "50px",
                                                        width: "50px",
                                                        padding: "1px 1px",
                                                        marginLeft: "20px",
                                                      }}
                                                    />
                                                  ) : (
                                                    <img
                                                      className="media-object img-circle"
                                                      src={
                                                        Announcement.senderProfile_Picture
                                                      }
                                                      alt="Generic placeholder image"
                                                      style={{
                                                        height: "50px",
                                                        width: "50px",
                                                        padding: "1px 1px",
                                                        marginLeft: "20px",
                                                      }}
                                                    />
                                                  )}
                                                </a>
                                                <div
                                                  className="media-body"
                                                  style={{
                                                    textAlign: "initial",
                                                    // padding: "2px 2px",
                                                  }}
                                                >
                                                  <div className="f-13 chat-header">
                                                    {" "}
                                                    <span
                                                      style={{
                                                        color: "black",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      <b>
                                                        {Announcement.displayName ===
                                                          null ||
                                                          Announcement.displayName ===
                                                          USERNAME
                                                          ? Announcement.receiver ===
                                                            USERNAME
                                                            ? Announcement.firstName
                                                            : Announcement.receiver
                                                          : Announcement.displayName}
                                                      </b>
                                                      &nbsp;
                                                      <span>
                                                        <img
                                                          src={userpng}
                                                          style={{
                                                            width: "12px",
                                                            background: "white",
                                                          }}
                                                        />
                                                      </span>
                                                      <ReactTimeAgo
                                                        date={
                                                          Announcement.createDateTime
                                                        }
                                                        locale="en-US"
                                                        timeStyle="round-minute"
                                                        style={{
                                                          color: "gray",
                                                          float: "right",
                                                          fontSize: "10px",
                                                          marginTop: "10px",
                                                          marginRight: "10px",
                                                          fontWeight: "bold",
                                                        }}
                                                      />
                                                    </span>
                                                    <br />
                                                    {Announcement.senderId ==
                                                      JSON.parse(
                                                        localStorage.userId
                                                      ) ? (
                                                      <span
                                                        style={{
                                                          color: "black",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        <div
                                                          onClick={() =>
                                                            Announcement.senderId !==
                                                              UserId
                                                              ? this.markMessageAsSeenUser(
                                                                Announcement.senderId,
                                                                Announcement.particularId
                                                              )
                                                              : null
                                                          }
                                                        >
                                                          You:{" "}
                                                          {Announcement.seen ==
                                                            1 ? (
                                                            <img
                                                              src={doubletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft:
                                                                  "-2px",
                                                                width: "20px",
                                                                backgroundImage:
                                                                  "green",
                                                              }}
                                                              title="Seen"
                                                            />
                                                          ) : null}{" "}
                                                          {Announcement.seen ==
                                                            0 ? (
                                                            <img
                                                              src={singletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft:
                                                                  "-2px",
                                                                width: "20px",
                                                                backgroundImage:
                                                                  "green",
                                                              }}
                                                              title="Unread"
                                                            />
                                                          ) : null}{" "}
                                                          {
                                                            Announcement.message
                                                              === "-"
                                                              ? "Sent a File"
                                                              : <>
                                                                {Messageresult}
                                                                {Announcement
                                                                  .message.length >
                                                                  85
                                                                  ? "..."
                                                                  : null}{" "}
                                                              </>}{" "}
                                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                          {Announcement.senderId !==
                                                            UserId ? (
                                                            <>
                                                              {Announcement.counts >
                                                                0 ? (
                                                                <p
                                                                  className="badge badge-primary"
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    backgroundColor:
                                                                      "#563ccf",
                                                                  }}
                                                                >
                                                                  {
                                                                    Announcement.counts
                                                                  }
                                                                </p>
                                                              ) : null}
                                                            </>
                                                          ) : null}
                                                        </div>
                                                      </span>
                                                    ) : (
                                                      <span
                                                        style={{
                                                          color: "black",
                                                          whiteSpace: "nowrap",
                                                        }}
                                                      >
                                                        <div
                                                          onClick={() => {
                                                            this.markMessageAsSeenAdmin(
                                                              Announcement.senderId,
                                                              Announcement.particularId
                                                            );
                                                            this.markMessageAsSeenUser(
                                                              Announcement.senderId,
                                                              Announcement.particularId
                                                            );
                                                          }}
                                                        >
                                                          {Announcement.displayName ===
                                                            null ||
                                                            Announcement.displayName ===
                                                            USERNAME
                                                            ? Announcement.receiver ===
                                                              USERNAME
                                                              ? Announcement.firstName
                                                              : Announcement.receiver
                                                            : Announcement.displayName}
                                                          :{" "}
                                                          {
                                                            Announcement.message
                                                              === "-"
                                                              ? "Sent a File"
                                                              : <>
                                                                {Messageresult}
                                                                {Announcement
                                                                  .message.length >
                                                                  85
                                                                  ? "..."
                                                                  : null}{" "}
                                                              </>}{" "}
                                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                          {Announcement.senderId !==
                                                            UserId ? (
                                                            <>
                                                              {Announcement.counts >
                                                                0 ? (
                                                                <p
                                                                  className="badge badge-primary"
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                    backgroundColor:
                                                                      "#563ccf",
                                                                  }}
                                                                >
                                                                  {
                                                                    Announcement.counts
                                                                  }
                                                                </p>
                                                              ) : null}
                                                            </>
                                                          ) : null}
                                                          {/* <ReactTimeAgo
                                                              date={
                                                                Announcement.createDateTime
                                                              }
                                                              locale="en-US"
                                                              timeStyle="round-minute"
                                                              style={{
                                                                color: "gray",
                                                                float: "right",
                                                              }}
                                                            /> */}
                                                        </div>
                                                      </span>
                                                    )}
                                                    <br />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </>
                                  )}

                                  {/* Group Discussion */}

                                  {User.map((User) => {
                                    let Message = User.message;
                                    let Messageresult = Message.slice(0, 55);
                                    let Sender = User.senderId;
                                    const isSender =
                                      Sender.includes(UserId);
                                    return (
                                      <div
                                        className="media userlist-box"
                                        data-id="1"
                                        data-status="online"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        // key={Announcement.id,Announcement.usermsgId}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.EditSkillModels(
                                            
                                            User.group_id,
                                            User.group_Name,
                                            User.created_by,
                                            User.privacy
                                          );

                                          this.markMessageAsSeen(User.group_id);
                                         
                                        }}
                                        style={{ padding: "7px" }}
                                      >
                                        <a className="media-left">
                                          {User.profile ==
                                            `${Image_URLs}Images/profile.png` ? (
                                            <img
                                              className="media-object img-circle"
                                              src={Profile}
                                              alt="1Generic placeholder image"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                                padding: "1px 1px",
                                                marginLeft: "20px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              className="media-object img-circle"
                                              src={User.profile}
                                              alt="Generic placeholder image"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                                padding: "1px 1px",
                                                marginLeft: "20px",
                                              }}
                                            />
                                          )}
                                        </a>
                                        <div
                                          className="media-body"
                                          style={{
                                            textAlign: "initial",
                                          }}
                                        >
                                          <div className="f-13 chat-header">
                                            {" "}
                                            <span
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.EditSkillModels(
                                                  User.group_id,
                                                  User.group_Name,
                                                  User.created_by,
                                                  User.privacy
                                                );
                                                //this.handleButtonClick1();
                                              }}
                                              style={{
                                                color: "black",
                                                whiteSpace: "nowrap",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {User.group_Name}{" "}
                                              {checkPrivacyandCreated.map(
                                                (d) => {
                                                  if (
                                                    d.group_Name ===
                                                    User.group_Name &&
                                                    d.user_id === MailIds
                                                  ) {
                                                    return (
                                                      <span>
                                                        {d.privacy === 0 ? (
                                                          <img
                                                            src={lockpng}
                                                            style={{
                                                              width: "12px",
                                                              background:
                                                                "white",
                                                            }}
                                                          />
                                                        ) : (
                                                          <img
                                                            src={publicpng}
                                                            style={{
                                                              width: "14px",
                                                              background:
                                                                "white",
                                                            }}
                                                          />
                                                        )}
                                                      </span>
                                                    );
                                                  }
                                                }
                                              )}
                                              &nbsp;
                                              <ReactTimeAgo
                                                date={User.createDateTime}
                                                locale="en-US"
                                                timeStyle="round-minute"
                                                style={{
                                                  color: "gray",
                                                  float: "right",
                                                  fontSize: "10px",
                                                  marginTop: "10px",
                                                  marginRight: "10px",
                                                }}
                                              />
                                              {checkPrivacyandCreated.map(
                                                (d) => {
                                                  if (
                                                    d.group_Name ===
                                                    User.group_Name &&
                                                    d.user_id === MailIds
                                                  ) {
                                                    return (
                                                      <span>
                                                        {d.privacy === 0 &&
                                                          d.created_by ===
                                                          JSON.parse(
                                                            localStorage.userId
                                                          ) ? (
                                                          <>
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "10px",
                                                              }}
                                                            >
                                                              by{""} You{""} on{" "}
                                                              {this.formatDateAA2(
                                                                d.created_Date
                                                              )}
                                                            </span>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "10px",
                                                              }}
                                                            >
                                                              by{" "}
                                                              {d.created_Name}{" "}
                                                              on{" "}
                                                              {this.formatDateAA2(
                                                                d.created_Date
                                                              )}
                                                            </span>
                                                          </>
                                                        )}
                                                      </span>
                                                    );
                                                  }
                                                }
                                              )}
                                            </span>
                                            <br />
                                            {User.senderId ==
                                              JSON.parse(localStorage.userId) ? (
                                              <span
                                                style={{
                                                  color: "black",
                                                  whiteSpace: "nowrap",
                                                }}
                                                onClick={() =>
                                                  this.markMessageAsSeen(
                                                    User.group_id
                                                  )
                                                }
                                              >
                                                {User.type == null ? (
                                                  <>
                                                    You {Messageresult}
                                                    {User.message.length > 85
                                                      ? "..."
                                                      : null}{" "}
                                                  </>
                                                ) : (
                                                  <>
                                                    {User.message == "-" ? (
                                                      <> You: Sent a File</>
                                                    ) : (
                                                      <>
                                                        {" "}

                                                        You:{" "}{(isSender &&
                                                          User.displayStatus == 1) && !User.message.includes("Ended Discussion On")
                                                          && !User.message.includes("Added") ? (
                                                          <img
                                                            src={doubletick}
                                                            style={{
                                                              // marginTop: "50px",
                                                              marginLeft: "-2px",
                                                              width: "20px",
                                                            }}
                                                            title="Seen"
                                                          />
                                                        ) : isSender && !User.message.includes("Ended Discussion On")
                                                          && !User.message.includes("Added") ? <img
                                                          src={singletick}
                                                          style={{
                                                            // marginTop: "50px",
                                                            marginLeft: "-2px",
                                                            width: "20px",

                                                          }}
                                                          title="Unread"
                                                        /> : null}{" "}{Messageresult}
                                                        {User.message.length >
                                                          85
                                                          ? "..."
                                                          : null}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </span>
                                            ) : (
                                              <span
                                                style={{
                                                  color: "black",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {User.type == null ? (
                                                  <>
                                                    {User.displayName}{" "}
                                                    {User.message.includes(name)?User.message.replace(name, 'You'):User.message}
                                                  </>
                                                ) : (
                                                  <>
                                                    {User.message == "-" ? (
                                                      <>
                                                        {User.displayName}:
                                                        {User.seen == 0 ? (
                                                          <b>Sent a File</b>
                                                        ) : (
                                                          User.seen == 1 &&
                                                          "Sent a File"
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {User.displayName}:
                                                        {User.seen == 0 ? (
                                                          <b>
                                                            {" "}
                                                            {Messageresult}
                                                            {User.message
                                                              .length > 85
                                                              ? "..."
                                                              : null}
                                                          </b>
                                                        ) : (
                                                          User.seen == 1 &&
                                                          `${" "}${Messageresult}${User.message
                                                            .length > 85
                                                            ? "..."
                                                            : " "
                                                          }`
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {User.counts > 0 ? (
                                                  <p
                                                    className="badge badge-primary"
                                                    style={{
                                                      fontSize: "15px",
                                                      backgroundColor:
                                                        "#563ccf",
                                                    }}
                                                  >
                                                    {User.counts}
                                                  </p>
                                                ) : null}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}

                                  {/* Public Discussion */}

                                  {NonUsers.map((User) => {
                                    let Message = User.message;
                                    let Messageresult = Message.slice(0, 55);

                                    return (
                                      <div
                                        className="media userlist-box"
                                        data-id="1"
                                        data-status="online"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        // key={Announcement.id,Announcement.usermsgId}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.EditSkillModels(
                                           
                                            User.group_id,
                                            User.group_Name,
                                            User.created_by,
                                            User.privacy
                                          );

                                          this.markMessageAsSeen(User.group_id);
                                          //this.handleButtonClick1();
                                          // this.handlehide();
                                        }}
                                        style={{ padding: "7px" }}
                                      >
                                        <a className="media-left">
                                          {User.profile ==
                                            `${Image_URLs}Images/profile.png` ? (
                                            <img
                                              className="media-object img-circle"
                                              src={Profile}
                                              alt="1Generic placeholder image"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                                padding: "1px 1px",
                                                marginLeft: "20px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              className="media-object img-circle"
                                              src={User.profile}
                                              alt="Generic placeholder image"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                                padding: "1px 1px",
                                                marginLeft: "20px",
                                              }}
                                            />
                                          )}
                                        </a>
                                        <div
                                          className="media-body"
                                          style={{
                                            textAlign: "initial",
                                          }}
                                        >
                                          <div className="f-13 chat-header">
                                            {" "}
                                            <span
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.EditSkillModels(
                                                  User.group_id,
                                                  User.group_Name,
                                                  User.created_by,
                                                  User.privacy
                                                  
                                                );
                                                //this.handleButtonClick1();
                                              }}
                                              style={{
                                                color: "black",
                                                whiteSpace: "nowrap",
                                                fontWeight: "600",
                                              }}
                                            >
                                              {User.group_Name}{" "}
                                              {checkPrivacyandCreatedPublic.map(
                                                (d) => {
                                                  if (
                                                    d.group_Name ===
                                                    User.group_Name
                                                  ) {
                                                    return (
                                                      <span>
                                                        {d.privacy === 0 ? (
                                                          <img
                                                            src={lockpng}
                                                            style={{
                                                              width: "12px",
                                                              background:
                                                                "white",
                                                            }}
                                                          />
                                                        ) : (
                                                          <img
                                                            src={publicpng}
                                                            style={{
                                                              width: "14px",
                                                              background:
                                                                "white",
                                                            }}
                                                          />
                                                        )}
                                                      </span>
                                                    );
                                                  }
                                                }
                                              )}
                                              &nbsp;
                                              <ReactTimeAgo
                                                date={User.createDateTime}
                                                locale="en-US"
                                                timeStyle="round-minute"
                                                style={{
                                                  color: "gray",
                                                  float: "right",
                                                  fontSize: "10px",
                                                  marginTop: "10px",
                                                  marginRight: "10px",
                                                }}
                                              />
                                              {checkPrivacyandCreatedPublic.map(
                                                (d) => {
                                                  if (
                                                    d.group_Name ===
                                                    User.group_Name
                                                  ) {
                                                    return (
                                                      <span>
                                                        {d.privacy === 0 &&
                                                          d.created_by ===
                                                          JSON.parse(
                                                            localStorage.userId
                                                          ) ? (
                                                          <>
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "10px",
                                                              }}
                                                            >
                                                              by{""} You{""} on{" "}
                                                              {this.formatDateAA2(
                                                                d.created_Date
                                                              )}
                                                            </span>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "10px",
                                                              }}
                                                            >
                                                              by{" "}
                                                              {d.created_Name}{" "}
                                                              on{" "}
                                                              {this.formatDateAA2(
                                                                d.created_Date
                                                              )}
                                                            </span>
                                                          </>
                                                        )}
                                                      </span>
                                                    );
                                                  }
                                                }
                                              )}
                                            </span>
                                            <br />
                                            {User.senderId ==
                                              JSON.parse(localStorage.userId) ? (
                                              <span
                                                style={{
                                                  color: "black",
                                                  whiteSpace: "nowrap",
                                                }}
                                                onClick={() =>
                                                  this.markMessageAsSeen(
                                                    User.group_id
                                                  )
                                                }
                                              >
                                                {User.type == null ? (
                                                  <>
                                                    You {Messageresult}
                                                    {User.message.length > 85
                                                      ? "..."
                                                      : null}{" "}
                                                  </>
                                                ) : (
                                                  <>
                                                    {User.message == "-" ? (
                                                      <> You: Sent a File</>
                                                    ) : (
                                                      <>
                                                        {" "}
                                                        You: {Messageresult}
                                                        {User.message.length >
                                                          85
                                                          ? "..."
                                                          : null}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </span>
                                            ) : (
                                              <span
                                                style={{
                                                  color: "black",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {User.type == null ? (
                                                  <>
                                                    {User.displayName}{" "}
                                                    {User.message.includes(name)?User.message.replace(name, 'You'):User.message}
                                                  </>
                                                ) : (
                                                  <>
                                                    {User.message == "-" ? (
                                                      <>
                                                        {User.displayName}:
                                                        {User.seen == 0 ? (
                                                          <b>Sent a File</b>
                                                        ) : (
                                                          User.seen == 1 &&
                                                          "Sent a File"
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {User.displayName}:
                                                        {User.seen == 0 ? (
                                                          <b>
                                                            {" "}
                                                            {Messageresult}
                                                            {User.message
                                                              .length > 85
                                                              ? "..."
                                                              : null}
                                                          </b>
                                                        ) : (
                                                          User.seen == 1 &&
                                                          `${" "}${Messageresult}${User.message
                                                            .length > 85
                                                            ? "..."
                                                            : " "
                                                          }`
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {User.counts > 0 ? (
                                                  <p
                                                    className="badge badge-primary"
                                                    style={{
                                                      fontSize: "15px",
                                                      backgroundColor:
                                                        "#563ccf",
                                                    }}
                                                  >
                                                    {User.counts}
                                                  </p>
                                                ) : null}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}

                                  {/* Trend List */}

                                  <>
                                    <>
                                      {RoleA === "Admin" ? (
                                        <>
                                          {trendList.map((Announcement) => {
                                            let Message = Announcement.message;
                                            let Messageresult = Message.slice(
                                              0,
                                              55
                                            );

                                            return (
                                              <>
                                                {UserMail ===
                                                  Announcement.userName ||
                                                  UserMail ===
                                                  Announcement.email ? (
                                                  <div
                                                    className="media userlist-box"
                                                    data-id="1"
                                                    data-status="online"
                                                    data-toggle="tooltip"
                                                    data-placement="left"
                                                    // key={Announcement.id,Announcement.usermsgId}
                                                    key={[
                                                      Announcement.id,
                                                      Announcement.usermsgId,
                                                    ].join("-")}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.Chatpop(
                                                        Announcement.displayName ===
                                                          null ||
                                                          Announcement.displayName ===
                                                          USERNAME
                                                          ? Announcement.receiver ===
                                                            USERNAME
                                                            ? Announcement.firstName
                                                            : Announcement.receiver
                                                          : Announcement.displayName,
                                                        Announcement.userName ===
                                                          UserMail
                                                          ? Announcement.email
                                                          : Announcement.userName,
                                                        UserId ===
                                                          Announcement.senderId
                                                          ? Announcement.particularProfile_Picture
                                                          : Announcement.senderProfile_Picture,
                                                        Announcement.email ===
                                                          UserMail
                                                          ? Announcement.userName
                                                          : Announcement.email,
                                                        Announcement.senderId,
                                                        Announcement.particularId
                                                      );
                                                      this.markMessageAsSeenUser(
                                                        Announcement.senderId,
                                                        Announcement.particularId
                                                      );
                                                    }}
                                                    style={{
                                                      padding: "7px",
                                                      height: "65px",
                                                    }}
                                                  >
                                                    <a className="media-left">
                                                      {UserId ===
                                                        Announcement.senderId ? (
                                                        <img
                                                          className="media-object img-circle"
                                                          src={
                                                            Announcement.particularProfile_Picture
                                                          }
                                                          alt="Generic placeholder image"
                                                          style={{
                                                            height: "50px",
                                                            width: "50px",
                                                            padding: "1px 1px",
                                                            marginLeft: "20px",
                                                          }}
                                                        />
                                                      ) : (
                                                        <img
                                                          className="media-object img-circle"
                                                          src={
                                                            Announcement.senderProfile_Picture
                                                          }
                                                          alt="Generic placeholder image"
                                                          style={{
                                                            height: "50px",
                                                            width: "50px",
                                                            padding: "1px 1px",
                                                            marginLeft: "20px",
                                                          }}
                                                        />
                                                      )}
                                                    </a>
                                                    <div
                                                      className="media-body"
                                                      style={{
                                                        textAlign: "initial",
                                                        // padding: "2px 2px",
                                                      }}
                                                    >
                                                      <div className="f-13 chat-header">
                                                        {" "}
                                                        <span
                                                          style={{
                                                            color: "black",
                                                            whiteSpace:
                                                              "nowrap",
                                                          }}
                                                        >
                                                          <b>
                                                            {Announcement.displayName ===
                                                              null ||
                                                              Announcement.displayName ===
                                                              USERNAME
                                                              ? Announcement.receiver ===
                                                                USERNAME
                                                                ? Announcement.firstName
                                                                : Announcement.receiver
                                                              : Announcement.displayName}{" "}
                                                            <img
                                                              src={userpng}
                                                              style={{
                                                                width: "15px",
                                                                background:
                                                                  "white",
                                                              }}
                                                            />
                                                          </b>
                                                          <ReactTimeAgo
                                                            date={
                                                              Announcement.createDateTime
                                                            }
                                                            locale="en-US"
                                                            timeStyle="round-minute"
                                                            style={{
                                                              color: "gray",
                                                              float: "right",
                                                              fontSize: "10px",
                                                            }}
                                                          />
                                                        </span>
                                                        <br />
                                                        {Announcement.senderId ==
                                                          JSON.parse(
                                                            localStorage.userId
                                                          ) ? (
                                                          <span
                                                            style={{
                                                              color: "black",
                                                              whiteSpace:
                                                                "nowrap",
                                                            }}
                                                          >
                                                            <div
                                                              onClick={() =>
                                                                this.markMessageAsSeenUser(
                                                                  Announcement.senderId,
                                                                  Announcement.particularId
                                                                )
                                                              }
                                                            >
                                                              You:{" "}
                                                              {Announcement.seen ==
                                                                1 ? (
                                                                <img
                                                                  src={
                                                                    doubletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    backgroundImage:
                                                                      "green",
                                                                  }}
                                                                  title="Seen"
                                                                />
                                                              ) : null}{" "}
                                                              {Announcement.seen ==
                                                                0 ? (
                                                                <img
                                                                  src={
                                                                    singletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    backgroundImage:
                                                                      "green",
                                                                  }}
                                                                  title="Unread"
                                                                />
                                                              ) : null}{" "}
                                                              {
                                                                Announcement.message
                                                              }{" "}
                                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                              {Announcement.senderId !==
                                                                UserId ? (
                                                                <>
                                                                  {Announcement.counts >
                                                                    0 ? (
                                                                    <p
                                                                      className="badge badge-primary"
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        backgroundColor:
                                                                          "#563ccf",
                                                                      }}
                                                                    >
                                                                      {
                                                                        Announcement.counts
                                                                      }
                                                                    </p>
                                                                  ) : null}
                                                                </>
                                                              ) : null}
                                                            
                                                            </div>
                                                          </span>
                                                        ) : (
                                                          <span
                                                            style={{
                                                              color: "black",
                                                              whiteSpace:
                                                                "nowrap",
                                                            }}
                                                          >
                                                            <div
                                                              onClick={() =>
                                                                this.markMessageAsSeenUser(
                                                                  Announcement.senderId,
                                                                  Announcement.particularId
                                                                ) ||
                                                                this.markMessageAsSeenAdmin(
                                                                  Announcement.senderId,
                                                                  Announcement.particularId
                                                                )
                                                              }
                                                            >
                                                              {Announcement.displayName ===
                                                                null ||
                                                                Announcement.displayName ===
                                                                USERNAME
                                                                ? Announcement.receiver ===
                                                                  USERNAME
                                                                  ? Announcement.firstName
                                                                  : Announcement.receiver
                                                                : Announcement.displayName}
                                                              :{" "}
                                                              {
                                                                Announcement.message
                                                              }{" "}
                                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                              {Announcement.senderId !==
                                                                UserId ? (
                                                                <>
                                                                  {Announcement.counts >
                                                                    0 ? (
                                                                    <p
                                                                      className="badge badge-primary"
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        backgroundColor:
                                                                          "#563ccf",
                                                                      }}
                                                                    >
                                                                      {
                                                                        Announcement.counts
                                                                      }
                                                                    </p>
                                                                  ) : null}
                                                                </>
                                                              ) : null}
                                                              {/* <ReactTimeAgo
                                                              date={
                                                                Announcement.createDateTime
                                                              }
                                                              locale="en-US"
                                                              timeStyle="twitter"
                                                              style={{
                                                                color: "gray",
                                                                float: "right",
                                                              }}
                                                            /> */}
                                                            </div>
                                                          </span>
                                                        )}
                                                        <br />
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <>
                                                    {/* let Message = User.message;
                                            let Messageresult = Message.slice(0, 55); */}
                                                    {UserMail ===
                                                      Announcement.userName ||
                                                      UserMail ===
                                                      Announcement.email ||
                                                      UserMail ===
                                                      Announcement.group_Member ? (
                                                      <div
                                                        className="media userlist-box"
                                                        data-id="1"
                                                        data-status="online"
                                                        data-toggle="tooltip"
                                                        data-placement="left"
                                                        // key={Announcement.id,Announcement.usermsgId}
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.EditSkillModels(
                                                          
                                                            Announcement.group_id,
                                                            Announcement.group_Name,
                                                            Announcement.created_by,
                                                            Announcement.privacy
                                                          );

                                                          this.markMessageAsSeen(
                                                            Announcement.group_id
                                                          );
                                                          //this.handleButtonClick1();
                                                          // this.handlehide();
                                                        }}
                                                        style={{
                                                          padding: "7px",
                                                        }}
                                                      >
                                                        <a className="media-left">
                                                          {Announcement.profile ==
                                                            "-" ? (
                                                            <img
                                                              className="media-object img-circle"
                                                              src={Profile}
                                                              alt="1Generic placeholder image"
                                                              style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                padding:
                                                                  "1px 1px",
                                                                marginLeft:
                                                                  "20px",
                                                              }}
                                                            />
                                                          ) : (
                                                            <img
                                                              className="media-object img-circle"
                                                              src={
                                                                Announcement.profile
                                                              }
                                                              alt="Generic placeholder image"
                                                              style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                padding:
                                                                  "1px 1px",
                                                                marginLeft:
                                                                  "20px",
                                                              }}
                                                            />
                                                          )}
                                                        </a>
                                                        <div
                                                          className="media-body"
                                                          style={{
                                                            textAlign:
                                                              "initial",
                                                          }}
                                                        >
                                                          <div className="f-13 chat-header">
                                                            {" "}
                                                            {/* <span style={{ color: "Gray" }}>{Announcement.teamId}</span>&nbsp; */}
                                                            <span
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                this.EditSkillModels(
                                                                  Announcement.group_id,
                                                                  Announcement.group_Name,
                                                                  Announcement.created_by,
                                                                  Announcement.privacy
                                                                );
                                                                //this.handleButtonClick1();
                                                              }}
                                                              style={{
                                                                color: "black",
                                                                whiteSpace:
                                                                  "nowrap",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              {
                                                                Announcement.group_Name
                                                              }{" "}
                                                              {checkPrivacyandCreated.map(
                                                                (d) => {
                                                                  if (
                                                                    d.group_Name ===
                                                                    Announcement.group_Name &&
                                                                    d.user_id ===
                                                                    MailIds
                                                                  ) {
                                                                    return (
                                                                      <span>
                                                                        {d.privacy ===
                                                                          0 ? (
                                                                          <>
                                                                            <img
                                                                              src={
                                                                                lockpng
                                                                              }
                                                                              style={{
                                                                                width:
                                                                                  "12px",
                                                                                background:
                                                                                  "white",
                                                                              }}
                                                                            />
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            <img
                                                                              src={
                                                                                publicpng
                                                                              }
                                                                              style={{
                                                                                width:
                                                                                  "14px",
                                                                                background:
                                                                                  "white",
                                                                              }}
                                                                            />
                                                                          </>
                                                                        )}
                                                                      </span>
                                                                    );
                                                                  }
                                                                }
                                                              )}
                                                              &nbsp;
                                                              <ReactTimeAgo
                                                                date={
                                                                  Announcement.createDateTime
                                                                }
                                                                locale="en-US"
                                                                timeStyle="round-minute"
                                                                style={{
                                                                  color: "gray",
                                                                  float:
                                                                    "right",
                                                                  fontSize:
                                                                    "11px",
                                                                  marginTop:
                                                                    "10px",
                                                                  marginRight:
                                                                    "10px",
                                                                  fontSize:
                                                                    "10px",
                                                                }}
                                                              />
                                                              {checkPrivacyandCreated.map(
                                                                (d) => {
                                                                  if (
                                                                    d.group_Name ===
                                                                    Announcement.group_Name &&
                                                                    d.user_id ===
                                                                    MailIds
                                                                  ) {
                                                                    return (
                                                                      <span>
                                                                        {d.privacy ===
                                                                          0 ? (
                                                                          <>
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "10px",
                                                                              }}
                                                                            >
                                                                              by{" "}
                                                                              {
                                                                                d.created_Name
                                                                              }{" "}
                                                                              on{" "}
                                                                              {this.formatDateAA2(
                                                                                d.created_Date
                                                                              )}
                                                                            </span>
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "10px",
                                                                              }}
                                                                            >
                                                                              by{" "}
                                                                              {
                                                                                d.created_Name
                                                                              }{" "}
                                                                              on{" "}
                                                                              {this.formatDateAA2(
                                                                                d.created_Date
                                                                              )}
                                                                            </span>
                                                                          </>
                                                                        )}
                                                                      </span>
                                                                    );
                                                                  }
                                                                }
                                                              )}
                                                            </span>
                                                            <br />
                                                            {Announcement.senderId ==
                                                              JSON.parse(
                                                                localStorage.userId
                                                              ) ? (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                }}
                                                                onClick={() =>
                                                                  this.markMessageAsSeen(
                                                                    Announcement.group_id
                                                                  )
                                                                }
                                                              >
                                                                {/* {User.senderId == JSON.parse(localStorage.userId) */}

                                                                {Announcement.type ==
                                                                  null ? (
                                                                  <>
                                                                    You{" "}
                                                                    {
                                                                      Messageresult
                                                                    }
                                                                    {Announcement
                                                                      .message
                                                                      .length >
                                                                      85
                                                                      ? "..."
                                                                      : null}{" "}
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {/* You:{User.message} */}
                                                                    {Announcement.message ==
                                                                      "-" ? (
                                                                      <>
                                                                        {" "}
                                                                        You:
                                                                        Sent a
                                                                        File
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {" "}
                                                                        You:{" "}
                                                                        {
                                                                          Messageresult
                                                                        }
                                                                        {Announcement
                                                                          .message
                                                                          .length >
                                                                          85
                                                                          ? "..."
                                                                          : null}
                                                                      </>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </span>
                                                            ) : (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                }}
                                                              >
                                                                {/* {User.displayName}:{User.message} */}
                                                                {Announcement.type ==
                                                                  null ? (
                                                                  <>
                                                                    {
                                                                      Announcement.displayName
                                                                    }{" "}
                                                                    {
                                                                      Messageresult
                                                                    }
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {Announcement.message ==
                                                                      "-" ? (
                                                                      <>
                                                                        {
                                                                          Announcement.displayName
                                                                        }
                                                                        :
                                                                        {Announcement.seen ==
                                                                          0 ? (
                                                                          <b>
                                                                            Sent
                                                                            a
                                                                            File
                                                                          </b>
                                                                        ) : (
                                                                          Announcement.seen ==
                                                                          1 &&
                                                                          "Sent a File"
                                                                        )}
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {
                                                                          Announcement.displayName
                                                                        }
                                                                        :
                                                                        {Announcement.seen ==
                                                                          0 ? (
                                                                          <b>
                                                                            {" "}
                                                                            {
                                                                              Messageresult
                                                                            }
                                                                            {Announcement
                                                                              .message
                                                                              .length >
                                                                              85
                                                                              ? "..."
                                                                              : null}
                                                                          </b>
                                                                        ) : (
                                                                          Announcement.seen ==
                                                                          1 &&
                                                                          `${" "}${Messageresult}${Announcement
                                                                            .message
                                                                            .length >
                                                                            85
                                                                            ? "..."
                                                                            : " "
                                                                          }`
                                                                        )}
                                                                      </>
                                                                    )}
                                                                  </>
                                                                )}
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                {Announcement.counts >
                                                                  0 ? (
                                                                  <p
                                                                    className="badge badge-primary"
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      backgroundColor:
                                                                        "#563ccf",
                                                                    }}
                                                                  >
                                                                    {
                                                                      Announcement.counts
                                                                    }
                                                                  </p>
                                                                ) : null}
                                                              </span>
                                                            )}
                                                            {/* {username} */}
                                                            <br />
                                                            {/* <span
                                                            style={{
                                                              color: "Gray",
                                                              whiteSpace: "nowrap",
                                                            }}
                                                          >
                                                            {Announcement.email}
                                                          </span> */}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : null}
                                                  </>
                                                )}
                                              </>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <>
                                          {trendList.map((Announcement) => {
                                            let Message = Announcement.message;
                                            let Messageresult = Message.slice(
                                              0,
                                              55
                                            );

                                            return (
                                              <>
                                                {UserMail ===
                                                  Announcement.userName ||
                                                  UserMail ===
                                                  Announcement.email ? (
                                                  <div
                                                    className="media userlist-box"
                                                    data-id="1"
                                                    data-status="online"
                                                    data-toggle="tooltip"
                                                    data-placement="left"
                                                    // key={Announcement.id,Announcement.usermsgId}
                                                    key={[
                                                      Announcement.id,
                                                      Announcement.usermsgId,
                                                    ].join("-")}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.Chatpop(
                                                        Announcement.displayName ===
                                                          null ||
                                                          Announcement.displayName ===
                                                          USERNAME
                                                          ? Announcement.receiver ===
                                                            USERNAME
                                                            ? Announcement.firstName
                                                            : Announcement.receiver
                                                          : Announcement.displayName,
                                                        Announcement.userName ===
                                                          UserMail
                                                          ? Announcement.email
                                                          : Announcement.userName,
                                                        UserId ===
                                                          Announcement.senderId
                                                          ? Announcement.particularProfile_Picture
                                                          : Announcement.senderProfile_Picture,
                                                        Announcement.email ===
                                                          UserMail
                                                          ? Announcement.userName
                                                          : Announcement.email,
                                                        Announcement.senderId,
                                                        Announcement.particularId
                                                      );

                                                      this.markMessageAsSeenAdmin(
                                                        Announcement.senderId,
                                                        Announcement.particularId
                                                      );
                                                      
                                                    }}
                                                    style={{
                                                      padding: "7px",
                                                      height: "65px",
                                                    }}
                                                  >
                                                    <a className="media-left">
                                                      {UserId ===
                                                        Announcement.senderId ? (
                                                        <img
                                                          className="media-object img-circle"
                                                          src={
                                                            Announcement.particularProfile_Picture
                                                          }
                                                          alt="Generic placeholder image"
                                                          style={{
                                                            height: "50px",
                                                            width: "50px",
                                                            padding: "1px 1px",
                                                            marginLeft: "20px",
                                                          }}
                                                        />
                                                      ) : (
                                                        <img
                                                          className="media-object img-circle"
                                                          src={
                                                            Announcement.senderProfile_Picture
                                                          }
                                                          alt="Generic placeholder image"
                                                          style={{
                                                            height: "50px",
                                                            width: "50px",
                                                            padding: "1px 1px",
                                                            marginLeft: "20px",
                                                          }}
                                                        />
                                                      )}
                                                    </a>
                                                    <div
                                                      className="media-body"
                                                      style={{
                                                        textAlign: "initial",
                                                        // padding: "2px 2px",
                                                      }}
                                                    >
                                                      <div className="f-13 chat-header">
                                                        {" "}
                                                        <span
                                                          style={{
                                                            color: "black",
                                                            whiteSpace:
                                                              "nowrap",
                                                          }}
                                                        >
                                                          <b>
                                                            {Announcement.displayName ===
                                                              null ||
                                                              Announcement.displayName ===
                                                              USERNAME
                                                              ? Announcement.receiver ===
                                                                USERNAME
                                                                ? Announcement.firstName
                                                                : Announcement.receiver
                                                              : Announcement.displayName}
                                                          </b>
                                                          &nbsp;
                                                          <span>
                                                            <img
                                                              src={userpng}
                                                              style={{
                                                                width: "12px",
                                                                background:
                                                                  "white",
                                                              }}
                                                            />
                                                          </span>
                                                          <ReactTimeAgo
                                                            date={
                                                              Announcement.createDateTime
                                                            }
                                                            locale="en-US"
                                                            timeStyle="round-minute"
                                                            style={{
                                                              color: "gray",
                                                              float: "right",
                                                              fontSize: "10px",
                                                            }}
                                                          />
                                                        </span>
                                                        <br />
                                                        {Announcement.senderId ==
                                                          JSON.parse(
                                                            localStorage.userId
                                                          ) ? (
                                                          <span
                                                            style={{
                                                              color: "black",
                                                              whiteSpace:
                                                                "nowrap",
                                                            }}
                                                          >
                                                            <div
                                                           
                                                            >
                                                              You:{" "}
                                                              {Announcement.seen ==
                                                                1 ? (
                                                                <img
                                                                  src={
                                                                    doubletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    backgroundImage:
                                                                      "green",
                                                                  }}
                                                                  title="Seen"
                                                                />
                                                              ) : null}{" "}
                                                              {Announcement.seen ==
                                                                0 ? (
                                                                <img
                                                                  src={
                                                                    singletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    backgroundImage:
                                                                      "green",
                                                                  }}
                                                                  title="Unread"
                                                                />
                                                              ) : null}{" "}
                                                              {
                                                                Announcement.message
                                                              }{" "}
                                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                              {Announcement.senderId !==
                                                                UserId ? (
                                                                <>
                                                                  {Announcement.counts >
                                                                    0 ? (
                                                                    <p
                                                                      className="badge badge-primary"
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        backgroundColor:
                                                                          "#563ccf",
                                                                      }}
                                                                    >
                                                                      {
                                                                        Announcement.counts
                                                                      }
                                                                    </p>
                                                                  ) : null}
                                                                </>
                                                              ) : null}
                                                            </div>
                                                          </span>
                                                        ) : (
                                                          <span
                                                            style={{
                                                              color: "black",
                                                              whiteSpace:
                                                                "nowrap",
                                                            }}
                                                          >
                                                            <div
                                                              onClick={() => {
                                                                this.markMessageAsSeenAdmin(
                                                                  Announcement.senderId,
                                                                  Announcement.particularId
                                                                );
                                                                this.markMessageAsSeenUser(
                                                                  Announcement.senderId,
                                                                  Announcement.particularId
                                                                );
                                                              }}
                                                            >
                                                              {Announcement.displayName ===
                                                                null ||
                                                                Announcement.displayName ===
                                                                USERNAME
                                                                ? Announcement.receiver ===
                                                                  USERNAME
                                                                  ? Announcement.firstName
                                                                  : Announcement.receiver
                                                                : Announcement.displayName}
                                                              :{" "}
                                                              {
                                                                Announcement.message
                                                              }{" "}
                                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                              {Announcement.senderId !==
                                                                UserId ? (
                                                                <>
                                                                  {Announcement.counts >
                                                                    0 ? (
                                                                    <p
                                                                      className="badge badge-primary"
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                        backgroundColor:
                                                                          "#563ccf",
                                                                      }}
                                                                    >
                                                                      {
                                                                        Announcement.counts
                                                                      }
                                                                    </p>
                                                                  ) : null}
                                                                </>
                                                              ) : null}
                                                              {/* <ReactTimeAgo
                                                              date={
                                                                Announcement.createDateTime
                                                              }
                                                              locale="en-US"
                                                              timeStyle="round-minute"
                                                              style={{
                                                                color: "gray",
                                                                float: "right",
                                                              }}
                                                            /> */}
                                                            </div>
                                                          </span>
                                                        )}
                                                        <br />
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <>
                                                    {/* let Message = User.message;
                                            let Messageresult = Message.slice(0, 55); */}
                                                    {UserMail ===
                                                      Announcement.userName ||
                                                      UserMail ===
                                                      Announcement.email ||
                                                      UserMail ===
                                                      Announcement.group_Member ? (
                                                      <div
                                                        className="media userlist-box"
                                                        data-id="1"
                                                        data-status="online"
                                                        data-toggle="tooltip"
                                                        data-placement="left"
                                                        // key={Announcement.id,Announcement.usermsgId}
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                          this.EditSkillModels(
                                                        
                                                            Announcement.group_id,
                                                            Announcement.group_Name,
                                                            Announcement.created_by,
                                                            Announcement.privacy
                                                          );

                                                          this.markMessageAsSeen(
                                                            Announcement.group_id
                                                          );
                                                          //this.handleButtonClick1();
                                                          // this.handlehide();
                                                        }}
                                                        style={{
                                                          padding: "7px",
                                                        }}
                                                      >
                                                        <a className="media-left">
                                                          {Announcement.profile ==
                                                            "-" ? (
                                                            <img
                                                              className="media-object img-circle"
                                                              src={Profile}
                                                              alt="1Generic placeholder image"
                                                              style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                padding:
                                                                  "1px 1px",
                                                                marginLeft:
                                                                  "20px",
                                                              }}
                                                            />
                                                          ) : (
                                                            <img
                                                              className="media-object img-circle"
                                                              src={
                                                                Announcement.profile
                                                              }
                                                              alt="Generic placeholder image"
                                                              style={{
                                                                height: "50px",
                                                                width: "50px",
                                                                padding:
                                                                  "1px 1px",
                                                                marginLeft:
                                                                  "20px",
                                                              }}
                                                            />
                                                          )}
                                                        </a>
                                                        <div
                                                          className="media-body"
                                                          style={{
                                                            textAlign:
                                                              "initial",
                                                          }}
                                                        >
                                                          <div className="f-13 chat-header">
                                                            {" "}
                                                            {/* <span style={{ color: "Gray" }}>{Announcement.teamId}</span>&nbsp; */}
                                                            <span
                                                              onClick={(e) => {
                                                                e.preventDefault();
                                                                this.EditSkillModels(
                                                                  Announcement.group_id,
                                                                  Announcement.group_Name,
                                                                  Announcement.created_by,
                                                                  Announcement.privacy
                                                                
                                                                );
                                                                //this.handleButtonClick1();
                                                              }}
                                                              style={{
                                                                color: "black",
                                                                whiteSpace:
                                                                  "nowrap",
                                                                fontWeight:
                                                                  "600",
                                                              }}
                                                            >
                                                              {
                                                                Announcement.group_Name
                                                              }
                                                              &nbsp;
                                                              {checkPrivacyandCreated.map(
                                                                (d) => {
                                                                  if (
                                                                    d.group_Name ===
                                                                    Announcement.group_Name &&
                                                                    d.user_id ===
                                                                    MailIds
                                                                  ) {
                                                                    return (
                                                                      <span>
                                                                        {d.privacy ===
                                                                          0 ? (
                                                                          <>
                                                                            <img
                                                                              src={
                                                                                lockpng
                                                                              }
                                                                              style={{
                                                                                width:
                                                                                  "12px",
                                                                                background:
                                                                                  "white",
                                                                              }}
                                                                            />
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            <img
                                                                              src={
                                                                                publicpng
                                                                              }
                                                                              style={{
                                                                                width:
                                                                                  "14px",
                                                                                background:
                                                                                  "white",
                                                                              }}
                                                                            />
                                                                          </>
                                                                        )}
                                                                      </span>
                                                                    );
                                                                  }
                                                                }
                                                              )}
                                                              &nbsp;
                                                              <ReactTimeAgo
                                                                date={
                                                                  Announcement.createDateTime
                                                                }
                                                                locale="en-US"
                                                                timeStyle="round-minute"
                                                                style={{
                                                                  color: "gray",
                                                                  float:
                                                                    "right",
                                                                  fontSize:
                                                                    "10px",
                                                                }}
                                                              />
                                                              {checkPrivacyandCreated.map(
                                                                (d) => {
                                                                  if (
                                                                    d.group_Name ===
                                                                    Announcement.group_Name &&
                                                                    d.user_id ===
                                                                    MailIds
                                                                  ) {
                                                                    return (
                                                                      <span>
                                                                        {d.privacy ===
                                                                          0 ? (
                                                                          <>
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "10px",
                                                                              }}
                                                                            >
                                                                              by{" "}
                                                                              {
                                                                                d.created_Name
                                                                              }{" "}
                                                                              on{" "}
                                                                              {this.formatDateAA2(
                                                                                d.created_Date
                                                                              )}
                                                                            </span>
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            <span
                                                                              style={{
                                                                                fontSize:
                                                                                  "10px",
                                                                              }}
                                                                            >
                                                                              by{" "}
                                                                              {
                                                                                d.created_Name
                                                                              }{" "}
                                                                              on{" "}
                                                                              {this.formatDateAA2(
                                                                                d.created_Date
                                                                              )}
                                                                            </span>
                                                                          </>
                                                                        )}
                                                                      </span>
                                                                    );
                                                                  }
                                                                }
                                                              )}
                                                            </span>
                                                            <br />
                                                            {Announcement.senderId ==
                                                              JSON.parse(
                                                                localStorage.userId
                                                              ) ? (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                }}
                                                                onClick={() =>
                                                                  this.markMessageAsSeen(
                                                                    Announcement.group_id
                                                                  )
                                                                }
                                                              >
                                                                {/* {User.senderId == JSON.parse(localStorage.userId) */}

                                                                {Announcement.type ==
                                                                  null ? (
                                                                  <>
                                                                    You{" "}
                                                                    {
                                                                      Messageresult
                                                                    }
                                                                    {Announcement
                                                                      .message
                                                                      .length >
                                                                      85
                                                                      ? "..."
                                                                      : null}{" "}
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {/* You:{User.message} */}
                                                                    {Announcement.message ==
                                                                      "-" ? (
                                                                      <>
                                                                        {" "}
                                                                        You:
                                                                        Sent a
                                                                        File
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {" "}
                                                                        You:{" "}
                                                                        {
                                                                          Messageresult
                                                                        }
                                                                        {Announcement
                                                                          .message
                                                                          .length >
                                                                          85
                                                                          ? "..."
                                                                          : null}
                                                                      </>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </span>
                                                            ) : (
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "black",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                }}
                                                              >
                                                                {/* {User.displayName}:{User.message} */}
                                                                {Announcement.type ==
                                                                  null ? (
                                                                  <>
                                                                    {
                                                                      Announcement.displayName
                                                                    }{" "}
                                                                    {
                                                                      Messageresult
                                                                    }
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {Announcement.message ==
                                                                      "-" ? (
                                                                      <>
                                                                        {
                                                                          Announcement.displayName
                                                                        }
                                                                        :
                                                                        {Announcement.seen ==
                                                                          0 ? (
                                                                          <b>
                                                                            Sent
                                                                            a
                                                                            File
                                                                          </b>
                                                                        ) : (
                                                                          Announcement.seen ==
                                                                          1 &&
                                                                          "Sent a File"
                                                                        )}
                                                                      </>
                                                                    ) : (
                                                                      <>
                                                                        {
                                                                          Announcement.displayName
                                                                        }
                                                                        :
                                                                        {Announcement.seen ==
                                                                          0 ? (
                                                                          <b>
                                                                            {" "}
                                                                            {
                                                                              Messageresult
                                                                            }
                                                                            {Announcement
                                                                              .message
                                                                              .length >
                                                                              85
                                                                              ? "..."
                                                                              : null}
                                                                          </b>
                                                                        ) : (
                                                                          Announcement.seen ==
                                                                          1 &&
                                                                          `${" "}${Messageresult}${Announcement
                                                                            .message
                                                                            .length >
                                                                            85
                                                                            ? "..."
                                                                            : " "
                                                                          }`
                                                                        )}
                                                                      </>
                                                                    )}
                                                                  </>
                                                                )}
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                {Announcement.counts >
                                                                  0 ? (
                                                                  <p
                                                                    className="badge badge-primary"
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                      backgroundColor:
                                                                        "#563ccf",
                                                                    }}
                                                                  >
                                                                    {
                                                                      Announcement.counts
                                                                    }
                                                                  </p>
                                                                ) : null}
                                                              </span>
                                                            )}
                                                            {/* {username} */}
                                                            <br />
                                                            {/* <span
                                                            style={{
                                                              color: "Gray",
                                                              whiteSpace: "nowrap",
                                                            }}
                                                          >
                                                            {Announcement.email}
                                                          </span> */}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : null}
                                                  </>
                                                )}
                                              </>
                                            );
                                          })}
                                        </>
                                      )}

                                      {/* Trend List */}

                                      {/* )} */}

                                      {/* Deleted Discussion */}

                                      {Deleted.map((User) => {
                                        let Message = User.message;
                                        let Messageresult = Message.slice(
                                          0,
                                          55
                                        );

                                        return (
                                          <div
                                            className="media userlist-box"
                                            data-id="1"
                                            data-status="online"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            // key={Announcement.id,Announcement.usermsgId}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.EditSkillModels(
                                                
                                                User.group_id,
                                                User.group_Name,
                                                User.created_by,
                                                User.privacy
                                              );

                                              this.markMessageAsSeen(
                                                User.group_id
                                              );
                                              //this.handleButtonClick1();
                                              // this.handlehide();
                                            }}
                                            style={{ padding: "7px" }}
                                          >
                                            <a className="media-left">
                                              {User.profile ==
                                                `${Image_URLs}Images/profile.png` ? (
                                                <img
                                                  className="media-object img-circle"
                                                  src={Profile}
                                                  alt="1Generic placeholder image"
                                                  style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    padding: "1px 1px",
                                                    marginLeft: "20px",
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  className="media-object img-circle"
                                                  src={User.profile}
                                                  alt="Generic placeholder image"
                                                  style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    padding: "1px 1px",
                                                    marginLeft: "20px",
                                                  }}
                                                />
                                              )}
                                            </a>
                                            <div
                                              className="media-body"
                                              style={{
                                                textAlign: "initial",
                                              }}
                                            >
                                              <div className="f-13 chat-header">
                                                {" "}
                                                <span
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.EditSkillModels(
                                                      User.group_id,
                                                      User.group_Name,
                                                      User.created_by,
                                                      User.privacy
                                                    );
                                                    //this.handleButtonClick1();
                                                  }}
                                                  style={{
                                                    color: "black",
                                                    whiteSpace: "nowrap",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  {User.group_Name}&nbsp;
                                                  {checkPrivacyandCreated.map(
                                                    (d) => {
                                                      if (
                                                        d.group_Name ===
                                                        User.group_Name &&
                                                        d.user_id ===
                                                        MailIds
                                                      ) {
                                                        return (
                                                          <span>
                                                            {d.privacy ===
                                                              0 ? (
                                                              <>
                                                                <img
                                                                  src={
                                                                    lockpng
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "12px",
                                                                    background:
                                                                      "white",
                                                                  }}
                                                                />
                                                              </>
                                                            ) : (
                                                              <>
                                                                <img
                                                                  src={
                                                                    publicpng
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "14px",
                                                                    background:
                                                                      "white",
                                                                  }}
                                                                />
                                                              </>
                                                            )}
                                                          </span>
                                                        );
                                                      }
                                                    }
                                                  )}
                                                  &nbsp;
                                                  {checkPrivacyandCreated.map(
                                                    (d) => {
                                                      if (
                                                        d.group_Name ===
                                                        User.group_Name &&
                                                        d.user_id === MailIds
                                                      ) {
                                                        return (
                                                          <span>
                                                            {d.closed_Name ===
                                                              null &&
                                                              d.remover_Name !==
                                                              null &&
                                                              d.leaving == 0 ? (
                                                              <>
                                                                <span>
                                                                  {d.privacy ===
                                                                    0 &&
                                                                    d.created_by ===
                                                                    JSON.parse(
                                                                      localStorage.userId
                                                                    ) ? (
                                                                    <>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "10px",
                                                                        }}
                                                                      >
                                                                        by You{" "}
                                                                        on{" "}
                                                                        {this.formatDateAA2(
                                                                          d.created_Date
                                                                        )}
                                                                      </span>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "10px",
                                                                        }}
                                                                      >
                                                                        by{" "}
                                                                        {
                                                                          d.created_Name
                                                                        }{" "}
                                                                        on{" "}
                                                                        {this.formatDateAA2(
                                                                          d.created_Date
                                                                        )}
                                                                      </span>
                                                                    </>
                                                                  )}
                                                                </span>
                                                              </>
                                                            ) : d.closed_Name !==
                                                              null &&
                                                              d.leaving == 0 ? (
                                                              <>
                                                                <span>
                                                                  {d.privacy ===
                                                                    0 &&
                                                                    d.created_by ===
                                                                    JSON.parse(
                                                                      localStorage.userId
                                                                    ) ? (
                                                                    <>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "10px",
                                                                        }}
                                                                      >
                                                                        by You{" "}
                                                                        on{" "}
                                                                        {this.formatDateAA2(
                                                                          d.created_Date
                                                                        )}
                                                                      </span>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "10px",
                                                                        }}
                                                                      >
                                                                        by{" "}
                                                                        {
                                                                          d.created_Name
                                                                        }{" "}
                                                                        on{" "}
                                                                        {this.formatDateAA2(
                                                                          d.created_Date
                                                                        )}
                                                                      </span>
                                                                    </>
                                                                  )}
                                                                </span>
                                                              </>
                                                            ) : d.user_id ==
                                                              MailIds &&
                                                              d.leaving == 1 ? (
                                                              <>
                                                                <span>
                                                                  {d.privacy ===
                                                                    0 &&
                                                                    d.created_by ===
                                                                    JSON.parse(
                                                                      localStorage.userId
                                                                    ) ? (
                                                                    <>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "10px",
                                                                        }}
                                                                      >
                                                                        by You{" "}
                                                                        on{" "}
                                                                        {this.formatDateAA2(
                                                                          d.created_Date
                                                                        )}
                                                                      </span>
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "10px",
                                                                        }}
                                                                      >
                                                                        by{" "}
                                                                        {
                                                                          d.created_Name
                                                                        }{" "}
                                                                        on{" "}
                                                                        {this.formatDateAA2(
                                                                          d.created_Date
                                                                        )}
                                                                      </span>
                                                                    </>
                                                                  )}
                                                                </span>
                                                              </>
                                                            ) : null}
                                                          </span>
                                                        );
                                                      }
                                                    }
                                                  )}
                                                  <ReactTimeAgo
                                                    date={User.createDateTime}
                                                    locale="en-US"
                                                    timeStyle="twitter"
                                                    style={{
                                                      color: "gray",
                                                      float: "right",
                                                      fontSize: "10px",
                                                      marginTop: "10px",
                                                      marginRight: "10px",
                                                    }}
                                                  />
                                                </span>
                                                <br />
                                                {User.senderId ==
                                                  JSON.parse(
                                                    localStorage.userId
                                                  ) ? (
                                                  <span
                                                    style={{
                                                      color: "black",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                    onClick={() =>
                                                      this.markMessageAsSeen(
                                                        User.group_id
                                                      )
                                                    }
                                                  >
                                                    {User.type == null ? (
                                                      <>
                                                        You {Messageresult}
                                                        {User.message.length >
                                                          85
                                                          ? "..."
                                                          : null}{" "}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {User.message == "-" ? (
                                                          <> You: Sent a File</>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            You: {Messageresult}
                                                            {User.message
                                                              .length > 85
                                                              ? "..."
                                                              : null}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </span>
                                                ) : (
                                                  <span
                                                    style={{
                                                      color: "black",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {User.type == null ? (
                                                      <>
                                                        {User.displayName}{" "}
                                                        {User.message.includes(name)?User.message.replace(name, 'You'):User.message}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {User.message == "-" ? (
                                                          <>
                                                            {User.displayName}:
                                                            {User.seen == 0 ? (
                                                              <b>Sent a File</b>
                                                            ) : (
                                                              User.seen == 1 &&
                                                              "Sent a File"
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {User.displayName}:
                                                            {User.seen == 0 ? (
                                                              <b>
                                                                {" "}
                                                                {Messageresult}
                                                                {User.message
                                                                  .length > 85
                                                                  ? "..."
                                                                  : null}
                                                              </b>
                                                            ) : (
                                                              User.seen == 1 &&
                                                              `${" "}${Messageresult}${User.message
                                                                .length > 85
                                                                ? "..."
                                                                : " "
                                                              }`
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    {User.counts > 0 ? (
                                                      <p
                                                        className="badge badge-primary"
                                                        style={{
                                                          fontSize: "15px",
                                                          backgroundColor:
                                                            "#563ccf",
                                                        }}
                                                      >
                                                        {User.counts}
                                                      </p>
                                                    ) : null}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}

                                      {/* Muted Discussion */}

                                      {MutedDisc.map((User) => {
                                        let Message = User.message;
                                        let Messageresult = Message.slice(
                                          0,
                                          55
                                        );
                                        let Sender = User.senderId;
                                        const isSender =
                                          Sender.includes(UserId);

                                        return (
                                          <div
                                            className="media userlist-box"
                                            data-id="1"
                                            data-status="online"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            // key={Announcement.id,Announcement.usermsgId}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.EditSkillModels(
                                              
                                                User.group_id,
                                                User.group_Name,
                                                User.created_by,
                                                User.privacy
                                              );

                                              this.markMessageAsSeen(
                                                User.group_id
                                              );
                                              //this.handleButtonClick1();
                                              // this.handlehide();
                                            }}
                                            style={{ padding: "7px" }}
                                          >
                                            <a className="media-left">
                                              {User.profile ==
                                                `${Image_URLs}Images/profile.png` ? (
                                                <img
                                                  className="media-object img-circle"
                                                  src={Profile}
                                                  alt="1Generic placeholder image"
                                                  style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    padding: "1px 1px",
                                                    marginLeft: "20px",
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  className="media-object img-circle"
                                                  src={User.profile}
                                                  alt="Generic placeholder image"
                                                  style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    padding: "1px 1px",
                                                    marginLeft: "20px",
                                                  }}
                                                />
                                              )}
                                            </a>
                                            <div
                                              className="media-body"
                                              style={{
                                                textAlign: "initial",
                                              }}
                                            >
                                              <div className="f-13 chat-header">
                                                {" "}
                                                <span
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.EditSkillModels(
                                                      User.group_id,
                                                      User.group_Name,
                                                      User.created_by,
                                                      User.privacy
                                                    
                                                    );
                                                    //this.handleButtonClick1();
                                                  }}
                                                  style={{
                                                    color: "black",
                                                    whiteSpace: "nowrap",
                                                    fontWeight: "600",
                                                  }}
                                                >
                                                  {User.group_Name}
                                                  &nbsp;
                                                  {checkPrivacyandCreated.map(
                                                    (d) => {
                                                      if (
                                                        d.group_Name ===
                                                        User.group_Name &&
                                                        d.user_id === MailIds
                                                      ) {
                                                        return (
                                                          <span>
                                                            {d.privacy === 0 ? (
                                                              <>
                                                                <img
                                                                  src={lockpng}
                                                                  style={{
                                                                    width:
                                                                      "12px",
                                                                    background:
                                                                      "white",
                                                                  }}
                                                                />
                                                              </>
                                                            ) : (
                                                              <>
                                                                <img
                                                                  src={
                                                                    publicpng
                                                                  }
                                                                  style={{
                                                                    width:
                                                                      "14px",
                                                                    background:
                                                                      "white",
                                                                  }}
                                                                />
                                                              </>
                                                            )}
                                                          </span>
                                                        );
                                                      }
                                                    }
                                                  )}
                                                  &nbsp;
                                                  <ReactTimeAgo
                                                    date={User.createDateTime}
                                                    locale="en-US"
                                                    timeStyle="round-minute"
                                                    style={{
                                                      color: "gray",
                                                      float: "right",
                                                      fontSize: "10px",
                                                    }}
                                                  />
                                                  {checkPrivacyandCreated.map(
                                                    (d) => {
                                                      if (
                                                        d.group_Name ===
                                                        User.group_Name &&
                                                        d.user_id === MailIds
                                                      ) {
                                                        return (
                                                          <span>
                                                            {d.privacy === 0 &&
                                                              d.created_by ===
                                                              JSON.parse(
                                                                localStorage.userId
                                                              ) ? (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "10px",
                                                                  }}
                                                                >
                                                                  by{" "}
                                                                  {
                                                                    d.created_Name
                                                                  }{" "}
                                                                  on{" "}
                                                                  {this.formatDateAA2(
                                                                    d.created_Date
                                                                  )}
                                                                </span>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "10px",
                                                                  }}
                                                                >
                                                                  by{" "}
                                                                  {
                                                                    d.created_Name
                                                                  }{" "}
                                                                  on{" "}
                                                                  {this.formatDateAA2(
                                                                    d.created_Date
                                                                  )}
                                                                </span>
                                                              </>
                                                            )}
                                                          </span>
                                                        );
                                                      }
                                                    }
                                                  )}
                                                </span>
                                                <br />
                                                {User.senderId ==
                                                  JSON.parse(
                                                    localStorage.userId
                                                  ) ? (
                                                  <span
                                                    style={{
                                                      color: "black",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                    onClick={() =>
                                                      this.markMessageAsSeen(
                                                        User.group_id
                                                      )
                                                    }
                                                  >
                                                    {User.type == null ? (
                                                      <>
                                                        You {Messageresult}
                                                        {User.message.length >
                                                          85
                                                          ? "..."
                                                          : null}{" "}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {User.message == "-" ? (
                                                          <> You: Sent a File</>
                                                        ) : (
                                                          <>
                                                            {" "}
                                                            You:{" "}{(isSender &&
                                                              User.displayStatus == 1) && !User.message.includes("Ended Discussion On")
                                                              && !User.message.includes("Added") ? (
                                                              <img
                                                                src={doubletick}
                                                                style={{
                                                                  // marginTop: "50px",
                                                                  marginLeft: "-2px",
                                                                  width: "20px",
                                                                }}
                                                                title="Seen"
                                                              />
                                                            ) : isSender && !User.message.includes("Ended Discussion On")
                                                              && !User.message.includes("Added") ? <img
                                                              src={singletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft: "-2px",
                                                                width: "20px",

                                                              }}
                                                              title="Unread"
                                                            /> : null}{" "}{Messageresult}
                                                            {User.message
                                                              .length > 85
                                                              ? "..."
                                                              : null}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </span>
                                                ) : (
                                                  <span
                                                    style={{
                                                      color: "black",
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  >
                                                    {User.type == null ? (
                                                      <>
                                                        {User.displayName}{" "}
                                                        {User.message.includes(name)?User.message.replace(name, 'You'):User.message}
                                                      </>
                                                    ) : (
                                                      <>
                                                        {User.message == "-" ? (
                                                          <>
                                                            {User.displayName}:
                                                            {User.seen == 0 ? (
                                                              <b>Sent a File</b>
                                                            ) : (
                                                              User.seen == 1 &&
                                                              "Sent a File"
                                                            )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {User.displayName}:
                                                            {User.seen == 0 ? (
                                                              <b>
                                                                {" "}
                                                                {Messageresult}
                                                                {User.message
                                                                  .length > 85
                                                                  ? "..."
                                                                  : null}
                                                              </b>
                                                            ) : (
                                                              User.seen == 1 &&
                                                              `${" "}${Messageresult}${User.message
                                                                .length > 85
                                                                ? "..."
                                                                : " "
                                                              }`
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    {User.counts > 0 ? (
                                                      <p
                                                        className="badge badge-primary"
                                                        style={{
                                                          fontSize: "15px",
                                                          backgroundColor:
                                                            "#563ccf",
                                                        }}
                                                      >
                                                        {User.counts}
                                                      </p>
                                                    ) : null}
                                                  </span>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}

                                      {Announcements.map((User) => (
                                        <div
                                          className="media userlist-box"
                                          data-id="1"
                                          data-status="online"
                                          data-toggle="tooltip"
                                          data-placement="left"
                                          // key={Announcement.id,Announcement.usermsgId}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.EditSkillModels(
                                             
                                              User.group_id,
                                              User.group_Name,
                                              User.created_by,
                                              User.privacy
                                            );
                                            //this.handleButtonClick1();
                                            // this.handlehide();
                                          }}
                                          style={{ padding: "7px" }}
                                        >
                                          <a className="media-left">
                                            {
                                              (User.profile = "-" ? (
                                                <img
                                                  className="media-object img-circle"
                                                  src={Profile}
                                                  alt="Generic placeholder image"
                                                  style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    padding: "1px 1px",
                                                    marginLeft: "20px",
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  className="media-object img-circle"
                                                  src={User.profile}
                                                  alt="Generic placeholder image"
                                                  style={{
                                                    height: "50px",
                                                    width: "50px",
                                                    padding: "1px 1px",
                                                    marginLeft: "20px",
                                                  }}
                                                />
                                              ))
                                            }
                                          </a>
                                          <div
                                            className="media-body"
                                            style={{
                                              textAlign: "initial",
                                            }}
                                          >
                                            <div className="f-13 chat-header">
                                              {" "}
                                              {/* <span style={{ color: "Gray" }}>{Announcement.teamId}</span>&nbsp; */}
                                              <span
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.EditSkillModels(
                                                    User.group_id,
                                                    User.group_Name,
                                                    User.created_by,
                                                    User.privacy
                                                  );
                                                  //this.handleButtonClick1();
                                                }}
                                                style={{
                                                  color: "black",
                                                  whiteSpace: "nowrap",
                                                  fontWeight: "600",
                                                }}
                                              >
                                                {User.group_Name}
                                              </span>
                                              <br />
                                              <span
                                                style={{
                                                  color: "black",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                Last Message: ...
                                              </span>
                                              {/* {username} */}
                                              <br />
                                              {/* <span
                                                      style={{
                                                        color: "Gray",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      {Announcement.email}
                                                    </span> */}
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </>
                                  </>

                                  {/* )} */}

                                  {Announcements.map((User) => (
                                    <div
                                      className="media userlist-box"
                                      data-id="1"
                                      data-status="online"
                                      data-toggle="tooltip"
                                      data-placement="left"
                                      // key={Announcement.id,Announcement.usermsgId}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.EditSkillModels(
                                        
                                          User.group_id,
                                          User.group_Name,
                                          User.created_by,
                                          User.privacy
                                        );
                                        //this.handleButtonClick1();
                                        // this.handlehide();
                                      }}
                                      style={{ padding: "7px" }}
                                    >
                                      <a className="media-left">
                                        {
                                          (User.profile = "-" ? (
                                            <img
                                              className="media-object img-circle"
                                              src={Profile}
                                              alt="Generic placeholder image"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                                padding: "1px 1px",
                                                marginLeft: "20px",
                                              }}
                                            />
                                          ) : (
                                            <img
                                              className="media-object img-circle"
                                              src={User.profile}
                                              alt="Generic placeholder image"
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                                padding: "1px 1px",
                                                marginLeft: "20px",
                                              }}
                                            />
                                          ))
                                        }
                                      </a>
                                      <div
                                        className="media-body"
                                        style={{
                                          textAlign: "initial",
                                        }}
                                      >
                                        <div className="f-13 chat-header">
                                          {" "}
                                          {/* <span style={{ color: "Gray" }}>{Announcement.teamId}</span>&nbsp; */}
                                          <span
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.EditSkillModels(
                                                User.group_id,
                                                User.group_Name,
                                                User.created_by,
                                                User.privacy
                                              );
                                              //this.handleButtonClick1();
                                            }}
                                            style={{
                                              color: "black",
                                              whiteSpace: "nowrap",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {User.group_Name}
                                          </span>
                                          <br />
                                          <span
                                            style={{
                                              color: "black",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            Last Message: ...
                                          </span>
                                          {/* {username} */}
                                          <br />
                                         
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </>
                              </>
                            </div>

                           
                          </main>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                )}

                {RoleA === "Admin" ? (
                  <>
                    {Minimized ? (
                      <div className="ICheaderMinimize">
                        <span
                          style={{
                            float: "left",
                            // marginTop: "-5px",
                            color: "white",
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          {EntityMemberUsers.map((member) =>
                            member.firstName === Name &&
                              member.email === Email ? (
                              <div key={member.id}>
                                {member.userActiveStatus === 1 ? (
                                  <>
                                    <i
                                      className="icofont icofont-arrow-left"
                                      id="ICbackgroup"
                                      onClick={this.Hidechatheader}
                                    ></i>
                                    &nbsp;
                                    <img
                                      src={onlinedot}
                                      className="media-object img-circle"
                                      style={{
                                        marginTop: "0px",
                                        width: "15px",
                                        // position: "absolute",
                                        animation: "greenonline 2s infinite",
                                      }}
                                      title="Online"
                                    />
                                    &nbsp;
                                    <img
                                      className="media-object img-circle"
                                      src={UserProfilePic}
                                      alt="img"
                                      style={{
                                        height: "35px",
                                        width: "35px",
                                        // padding: "1px 1px",
                                        // marginLeft: "20px",
                                      }}
                                    />
                                    &nbsp;&nbsp;
                                    {Name} {""}
                                    <p
                                      style={{
                                        color: "#fff",
                                        fontSize: "10px",
                                        //  marginLeft: "52%",
                                        marginLeft: "65px",
                                        marginTop: "-4%",
                                      }}
                                    >
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      Online
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    <i
                                      className="icofont icofont-arrow-left"
                                      id="ICbackgroup"
                                      onClick={this.Hidechatheader}
                                    ></i>
                                    &nbsp;
                                    <img
                                      src={offlinedot}
                                      className="media-object img-circle"
                                      style={{
                                        marginTop: "0px",
                                        width: "15px",
                                        // position: "absolute",
                                        animation: "offlinego 2s infinite",
                                      }}
                                      title="Offline"
                                    />
                                    &nbsp;
                                    <img
                                      className="media-object img-circle"
                                      src={UserProfilePic}
                                      alt="img"
                                      style={{
                                        height: "35px",
                                        width: "35px",
                                        // padding: "1px 1px",
                                        // marginLeft: "20px",
                                      }}
                                    />
                                    &nbsp;&nbsp;
                                    {Name}
                                    <p
                                      style={{
                                        color: "#d4d4d4",
                                        width: "100%",
                                        fontSize: "12px",
                                        marginLeft: "88px",
                                        marginTop: "-5%",
                                      }}
                                    >
                                      last seen at {member.logout}
                                    </p>
                                  </>
                                )}
                              </div>
                            ) : null
                          )}
                        </span>
                        <div
                          className="box-tools d-inline-block"
                          style={{
                            float: "right",
                            marginTop: "0px",
                            color: "white",
                            fontSize: "20px",
                          }}
                        >
                          <span>
                            {Minimized ? (
                              <span
                                className="fa fa-window-maximize"
                                onClick={this.toggleMinimizeA}
                              ></span>
                            ) : (
                              <span className="fa fa-minus"></span>
                            )}
                          </span>{" "}
                          &nbsp;&nbsp;
                          <span>
                            {" "}
                            <i
                              className="fa fa-times"
                              aria-hidden="true"
                              onClick={this.Hidechatheader}
                            ></i>
                          </span>
                        </div>
                      </div>
                    ) : null}
                    {Chatpop && (
                      <div className="ICchat-container">
                        {Minimized ? null : (
                          <div className="ICheader">
                            <span
                              style={{
                                float: "left",
                                // marginTop: "-5px",
                                color: "white",
                                fontSize: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              {EntityMemberUsers.map((member) =>
                                member.firstName === Name &&
                                  member.email === Email ? (
                                  <div
                                    key={member.id}
                                  
                                  >
                                    {member.userActiveStatus === 1 ? (
                                      <>
                                        <i
                                          className="icofont icofont-arrow-left"
                                          id="ICbackgroup"
                                          onClick={() => {
                                            this.setState({
                                              Chatpop: false,
                                            }, () => {
                                              this.updateCheckedState();
                                            });
                                          }}
                                        ></i>
                                        &nbsp;
                                        <img
                                          src={onlinedot}
                                          className="media-object img-circle"
                                          style={{
                                            marginTop: "0px",
                                            width: "15px",
                                            // position: "absolute",
                                            animation:
                                              "greenonline 2s infinite",
                                          }}
                                          title="Online"
                                        />
                                        &nbsp;
                                        <img
                                          className="media-object img-circle"
                                          src={UserProfilePic}
                                          alt="img"
                                          style={{
                                            height: "35px",
                                            width: "35px",
                                            // padding: "1px 1px",
                                            // marginLeft: "20px",
                                          }}
                                        />
                                        &nbsp;&nbsp;
                                        {Name} {""}
                                        <p
                                          style={{
                                            color: "#fff",
                                            fontSize: "10px",
                                            //  marginLeft: "52%",
                                            marginLeft: "65px",
                                            marginTop: "-4%",
                                          }}
                                        >
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          Online
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <i
                                          className="icofont icofont-arrow-left"
                                          id="ICbackgroup"
                                          onClick={() => {
                                            this.setState({
                                              Chatpop: false,
                                            }, () => {
                                              this.updateCheckedState();
                                            });
                                          }}
                                        ></i>
                                        &nbsp;
                                        <img
                                          src={offlinedot}
                                          className="media-object img-circle"
                                          style={{
                                            marginTop: "0px",
                                            width: "15px",
                                            // position: "absolute",
                                            animation: "offlinego 2s infinite",
                                          }}
                                          title="Offline"
                                        />
                                        &nbsp;
                                        <img
                                          className="media-object img-circle"
                                          src={UserProfilePic}
                                          alt="img"
                                          style={{
                                            height: "35px",
                                            width: "35px",
                                            // padding: "1px 1px",
                                            // marginLeft: "20px",
                                          }}
                                        />
                                        &nbsp;&nbsp;
                                        {Name}
                                        <p
                                          style={{
                                            color: "#d4d4d4",
                                            width: "100%",
                                            fontSize: "12px",
                                            marginLeft: "88px",
                                            marginTop: "-5%",
                                          }}
                                        >
                                          last seen at {member.logout}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                ) : null
                              )}
                            </span>
                            <div
                              className="box-tools d-inline-block"
                              style={{
                                float: "right",
                                marginTop: "0px",
                                color: "white",
                                fontSize: "20px",
                              }}
                            >
                              <span onClick={this.toggleMinimize}>
                                {Minimized ? (
                                  <span className="fa fa-window-maximize"></span>
                                ) : (
                                  <span className="fa fa-minus"></span>
                                )}
                              </span>{" "}
                              &nbsp;&nbsp;
                              <span>
                                {" "}
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                  onClick={this.hideChatpop}
                                ></i>
                              </span>
                            </div>
                          </div>
                        )}

                        {Minimized ? null : (
                          <div className="ICchat-messages" ref={this.chatBoxRef}>
                            <form
                              autoComplete="off"
                              ref={this.formRef}
                              // onSubmit={this.handleSubmit}
                              encType="multipart/form-data"
                            >
                              <input
                                type="hidden"
                                name="UserName"
                                defaultValue={this.state.Email}
                              />
                              <div style={documentpop} id="InDocPop">
                                <span style={{ textAlign: "center" }}>
                                  {isdocumentpopOpen && (
                                    <OneonOneDragDrop
                                      values={this.state.Email}
                                      handleClose={() => this.documentPopup()}
                                    // AllMembers={this.state.mergedUsers}
                                    />
                                  )}
                                </span>
                              </div>

                              <div style={picturepop} id="InDocPop">
                                <span style={{ textAlign: "center" }}>
                                  {ispicturepopOpen && (
                                    <OneonOnePicturepop
                                      values={this.state.Email}
                                      // values={this.state.Group}
                                      handleClose={() => this.picturePopup()}
                                    // AllMembers={this.state.mergedUsers}
                                    />
                                  )}
                                </span>
                              </div>
                              <div className="message-scrooler">
                                <div
                                  className="messages"
                                  style={{ marginLeft: "2%" }}
                                >
                                  <div>
                                    {messages.map((message, index) => {
                                      const isSender = Popmsg.includes(message);
                                      let text = message.document_Path;
                                      let result = text.slice(45, 85);
                                      let result1 = text.slice(45);
                                      return (
                                        <>
                                          {message.sysDateTimeWithSeconds &&
                                            message.rowNum === 1 ? (
                                            <>
                                              <center
                                                style={{ marginTop: "1%" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "black",
                                                    backgroundColor:
                                                      "rgb(221, 221, 221)",
                                                    height: "35px",
                                                    padding: "8px 8px 8px 6px",
                                                    borderRadius: "7px",
                                                  }}
                                                >
                                                  &nbsp;
                                                  {this.formatDateAA1(
                                                    message.createDateTime
                                                  )}
                                                </span>
                                              </center>
                                              <br />
                                            </>
                                          ) : null}
                                          {message.particularId === UserId ? (
                                            <>
                                              {message.displayStatus === 2 ? (
                                                <div
                                                  className={`message-${isSender ? "orange" : "blue"
                                                    }`}
                                                  key={index}
                                                  style={{
                                                    height: "auto",
                                                    overflowWrap: "break-word",
                                                  }}
                                                >
                                                  {MsgMenu &&
                                                    this.state.deleteId ==
                                                    message.id ? (
                                                    <div
                                                      style={MessageMenu}
                                                      ref={this.containerRef}
                                                    >
                                                      <center>
                                                        <ul>
                                                          {isSender ? (
                                                            <li style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>

                                                              <span
                                                                onClick={this.EditMessage}
                                                                id="MsgOption"
                                                                style={{ marginLeft: '-26px' }}
                                                              >
                                                                <i
                                                                  className="fa fa-pencil-square-o"
                                                                  aria-hidden="true"
                                                                ></i>&nbsp;&nbsp;
                                                                <b>
                                                                  Edit
                                                                </b>
                                                              </span>
                                                            </li>) : null}

                                                          <li style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                            <span
                                                              onClick={this.deleteMenu}
                                                              id="MsgOption"
                                                              style={{ marginLeft: '-14px' }}
                                                            >
                                                              <i
                                                                className="fa fa-trash"
                                                                aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;

                                                              <b>
                                                                Delete
                                                              </b>
                                                            </span>
                                                          </li>
                                                          <li style={{ padding: '8px' }}>
                                                            <span
                                                              onClick={this.forwardopenpop}
                                                              id="MsgOption"
                                                            >
                                                              <i
                                                                className="fa fa-share"
                                                                aria-hidden="true"></i>

                                                              <b>&nbsp;
                                                                Forward
                                                              </b>
                                                            </span>
                                                          </li>

                                                        </ul>
                                                      </center>
                                                    </div>
                                                  ) : null}
                                                  <i
                                                    className="fa fa-ellipsis-h"
                                                    style={{
                                                      float: "Right",
                                                      cursor: "pointer",
                                                    }}
                                                    aria-hidden="true"
                                                    onClick={() =>
                                                      this.DisplayMenu(
                                                        message.id,
                                                        message.message,
                                                        message.picture_Path,
                                                        message.document_Path,
                                                        message.senderId,
                                                        message.createDateTime,
                                                        message.particularId,
                                                        message.sysDateTimeWithSeconds
                                                      )
                                                    }
                                                  ></i>

                                                  {!isSender ? (
                                                    <h6
                                                      style={{
                                                        fontWeight: "600",
                                                        color: "#09679f",
                                                      }}
                                                    >
                                                      {message.editStatus ===
                                                        1 ? (
                                                        <span
                                                          style={{
                                                            fontWeight: "200",
                                                            color: "grey",
                                                            fontSize: "10px",
                                                          }}
                                                        >
                                                          Edited
                                                        </span>
                                                      ) : null}
                                                    </h6>
                                                  ) : (
                                                    <>
                                                      {message.editStatus ===
                                                        1 ? (
                                                        <h6
                                                          style={{
                                                            fontWeight: "200",
                                                            color: "grey",
                                                            fontSize: "10px",
                                                          }}
                                                        >
                                                          Edited
                                                        </h6>
                                                      ) : null}
                                                    </>
                                                  )}
                                                  {/* {!isSender ? (
                                                    <> */}
                                                  {message.frwdStatus ===
                                                    1 ? (
                                                    <h6
                                                      style={{
                                                        fontWeight: "200",
                                                        color: "grey",
                                                        fontSize: "10px",
                                                      }}
                                                    >
                                                      <i
                                                        className="fa fa-share"
                                                        aria-hidden="true"
                                                      ></i>
                                                      &nbsp;&nbsp;Forwarded
                                                    </h6>
                                                  ) : null}
                                                  {/* </>
                                                  ) : null} */}
                                                  {message.picture_Path ===
                                                    "-" &&
                                                    message.document_Path ===
                                                    "-" ? (
                                                    <p
                                                      className="message-content"
                                                      name="Popmsg"
                                                    // style={{ wordWrap: "break-word" }}
                                                    >
                                                      {regexp.test(
                                                        message.message
                                                      ) == true ? (
                                                        <>
                                                          <a
                                                            style={{
                                                              color: "blue",
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={
                                                              message.message
                                                            }
                                                          >
                                                            {message.message}
                                                          </a>
                                                          <br />
                                                        </>
                                                      ) : (
                                                        <div>
                                                          <p>
                                                            {message.message}
                                                          </p>
                                                        </div>
                                                      )}
                                                      {isSender &&
                                                        message.seen == 1 ? (
                                                        <img
                                                          src={doubletick}
                                                          style={{
                                                            // marginTop: "50px",
                                                            marginLeft: "-2px",
                                                            width: "20px",
                                                            float: "right",
                                                          }}
                                                          title="Seen"
                                                        />
                                                      ) : null}
                                                      {isSender &&
                                                        message.seen == 0 ? (
                                                        <img
                                                          src={singletick}
                                                          style={{
                                                            // marginTop: "50px",
                                                            marginLeft: "-2px",
                                                            width: "20px",
                                                            float: "right",
                                                          }}
                                                          title="Unread"
                                                        />
                                                      ) : null}
                                                      <br />
                                                    </p>
                                                  ) : (
                                                    <>
                                                      {message.picture_Path !==
                                                        "-" &&
                                                        message.document_Path !==
                                                        "-" ? (
                                                        <div>
                                                          <div>
                                                            <p
                                                              className="message-content"
                                                              name="Popmsg"
                                                            // style={{ wordWrap: "break-word" }}
                                                            >
                                                              {message.message !=
                                                                "-" ? (
                                                                <>
                                                                  {
                                                                    message.message
                                                                  }
                                                                </>
                                                              ) : null}
                                                            </p>

                                                            <br />
                                                            <a
                                                              href={
                                                                message.picture_Path
                                                              }
                                                              name="picture"
                                                              readOnly
                                                              value={
                                                                message.picture_Path
                                                              }
                                                              title=""
                                                              target="_blank"
                                                              style={{
                                                                width: "120px",
                                                                height: "200px",
                                                              }}
                                                            ></a>
                                                            <div>
                                                              <img
                                                                alt="chatimg"
                                                                width="80"
                                                                height="80"
                                                                src={
                                                                  message.picture_Path
                                                                }
                                                             
                                                              />
                                                            </div>
                                                            <div>
                                                              <embed
                                                                src={
                                                                  message.document_Path
                                                                }
                                                                id="Gstiframe"
                                                                title=""
                                                                scrolling="no"
                                                                target="_blank"
                                                                style={{
                                                                  width:
                                                                    "100px",
                                                                  height:
                                                                    "100px",
                                                                  pointerEvents:
                                                                    "none",
                                                                }}
                                                              ></embed>
                                                            </div>
                                                          </div>
                                                          {isSender &&
                                                            message.seen == 1 ? (
                                                            <img
                                                              src={doubletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft:
                                                                  "-2px",
                                                                width: "20px",
                                                                float: "right",
                                                              }}
                                                              title="Seen"
                                                            />
                                                          ) : null}
                                                          {isSender &&
                                                            message.seen == 0 ? (
                                                            <img
                                                              src={singletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft:
                                                                  "-2px",
                                                                width: "20px",
                                                                float: "right",
                                                              }}
                                                              title="Unread"
                                                            />
                                                          ) : null}
                                                          <br />
                                                        </div>
                                                      ) : (
                                                        <>
                                                          {message.picture_Path ===
                                                            "-" &&
                                                            message.document_Path !==
                                                            "-" ? (
                                                            <div>
                                                              <p
                                                                className="message-content"
                                                                name="Popmsg"
                                                              
                                                              >
                                                                {message.message !=
                                                                  "-" ? (
                                                                  <>
                                                                    {
                                                                      message.message
                                                                    }
                                                                  </>
                                                                ) : null}
                                                              </p>
                                                              <br />
                                                              <a
                                                                href={
                                                                  message.document_Path
                                                                }
                                                                name="picture"
                                                                readOnly
                                                                value={
                                                                  message.document_Path
                                                                }
                                                                title=""
                                                                scrolling="no"
                                                                target="_blank"
                                                                style={{
                                                                  width:
                                                                    "120px",
                                                                  height:
                                                                    "200px",
                                                                }}
                                                              >
                                                                <div
                                                                  className="row"
                                                                  style={{
                                                                    width:
                                                                      "180px",
                                                                    height:
                                                                      "60px",
                                                                    borderRadius:
                                                                      "5px",
                                                                    boxShadow:
                                                                      "rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px",
                                                                    background:
                                                                      "#e8e8e8",
                                                                    alignItems:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <div className="col-lg-3">
                                                                    <img
                                                                      src={
                                                                        File1
                                                                      }
                                                                      style={{
                                                                        height:
                                                                          "30px",
                                                                        width:
                                                                          "30px",
                                                                      }}
                                                                    ></img>
                                                                  </div>
                                                                  <div className="col-lg-9">
                                                                    {message
                                                                      .document_Path
                                                                      .length >
                                                                      85 ? (
                                                                      <>
                                                                        <div
                                                                          style={{
                                                                            opacity:
                                                                              DName &&
                                                                                documentname ==
                                                                                message.id
                                                                                ? 1
                                                                                : 0,
                                                                            width:
                                                                              "150px",
                                                                            height:
                                                                              "auto",
                                                                            overflowWrap:
                                                                              "break-word",
                                                                            zIndex:
                                                                              "109",
                                                                            border:
                                                                              "1px solid black",

                                                                            background:
                                                                              "#0000007d",
                                                                            color:
                                                                              "white",
                                                                            alignItems:
                                                                              "center",
                                                                            position:
                                                                              "absolute",
                                                                            top: "-50px",
                                                                            left: "-50px",
                                                                          }}
                                                                        >
                                                                          <p
                                                                            style={{
                                                                              fontSize:
                                                                                "10px",
                                                                            }}
                                                                          >
                                                                            {
                                                                              result1
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                        <p
                                                                          style={{
                                                                            marginTop:
                                                                              "10px",
                                                                            textAlign:
                                                                              "center",
                                                                            fontSize:
                                                                              "10px",
                                                                            fontWeight:
                                                                              "600",
                                                                            color:
                                                                              "#19578b",
                                                                          }}
                                                                          onMouseOver={(
                                                                            e
                                                                          ) => {
                                                                            e.preventDefault();
                                                                            this.documentName(
                                                                              message.id
                                                                            );
                                                                          }}
                                                                          onMouseLeave={
                                                                            this
                                                                              .documentNameL
                                                                          }
                                                                        >
                                                                          {
                                                                            result
                                                                          }
                                                                          ...
                                                                        </p>
                                                                      </>
                                                                    ) : (
                                                                      <p
                                                                        style={{
                                                                          marginTop:
                                                                            "10px",
                                                                          textAlign:
                                                                            "center",
                                                                          fontSize:
                                                                            "10px",
                                                                          fontWeight:
                                                                            "600",
                                                                          color:
                                                                            "#014c8c",
                                                                        }}
                                                                      >
                                                                        {result}
                                                                      </p>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </a>
                                                              {isSender &&
                                                                message.seen ==
                                                                1 ? (
                                                                <img
                                                                  src={
                                                                    doubletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  title="Seen"
                                                                />
                                                              ) : null}
                                                              {isSender &&
                                                                message.seen ==
                                                                0 ? (
                                                                <img
                                                                  src={
                                                                    singletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  title="Unread"
                                                                />
                                                              ) : null}
                                                              <br />
                                                            </div>
                                                          ) : (
                                                            <div>
                                                              <p
                                                                className="message-content"
                                                                name="Popmsg"
                                                              
                                                              >
                                                                {message.message !=
                                                                  "-" ? (
                                                                  <>
                                                                    {
                                                                      message.message
                                                                    }
                                                                  </>
                                                                ) : null}
                                                              </p>
                                                              <br />
                                                              <a
                                                                href={
                                                                  message.picture_Path
                                                                }
                                                                name="picture"
                                                                readOnly
                                                                value={
                                                                  message.picture_Path
                                                                }
                                                                title=""
                                                                target="_blank"
                                                                style={{
                                                                  width:
                                                                    "120px",
                                                                  height:
                                                                    "200px",
                                                                }}
                                                              >
                                                                <div>
                                                                  {message.picture_Path.includes(
                                                                    ".mp4"
                                                                  ) ? (
                                                                    <>
                                                                      {" "}
                                                                      <img
                                                                        alt="Video"
                                                                        width="30"
                                                                        height="30"
                                                                        src={
                                                                          video
                                                                        }
                                                                        title="Click to play video"
                                                                     
                                                                      />{" "}
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "#000",
                                                                        }}
                                                                      >
                                                                        Video
                                                                        File
                                                                      </span>{" "}
                                                                    </>
                                                                  ) : (
                                                                    <img
                                                                      alt="chatimg"
                                                                      width="80"
                                                                      height="80"
                                                                      src={
                                                                        message.picture_Path
                                                                      }
                                                                  
                                                                    />
                                                                  )}
                                                                </div>
                                                              </a>
                                                              {isSender &&
                                                                message.seen ==
                                                                1 ? (
                                                                <img
                                                                  src={
                                                                    doubletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  title="Seen"
                                                                />
                                                              ) : null}
                                                              {isSender &&
                                                                message.seen ==
                                                                0 ? (
                                                                <img
                                                                  src={
                                                                    singletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  title="Unread"
                                                                />
                                                              ) : null}
                                                              <br />
                                                            </div>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                  <br />
                                                  <div className="message-timestamp-right">
                                                   
                                                    <span
                                                      style={{
                                                        color: "gray",
                                                      }}
                                                    >
                                                      <i className="fa fa-clock-o"></i>
                                                      &nbsp;
                                                      {this.formatDateAA(
                                                        message.createDateTime
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </>
                                          ) : (
                                            <>
                                              {message.displayStatus === 1 ? (
                                                <div
                                                  className={`message-${isSender ? "orange" : "blue"
                                                    }`}
                                                  key={index}
                                                  style={{
                                                    height: "auto",
                                                    overflowWrap: "break-word",
                                                  }}
                                                >
                                                  {MsgMenu &&
                                                    this.state.deleteId ==
                                                    message.id ? (
                                                    <div
                                                      style={MessageMenu}
                                                      ref={this.containerRef}
                                                    >
                                                      <center>
                                                        <ul>
                                                          {isSender ? (
                                                            <li style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>

                                                              <span
                                                                onClick={this.EditMessage}
                                                                id="MsgOption"
                                                                style={{ marginLeft: '-26px' }}
                                                              >
                                                                <i
                                                                  className="fa fa-pencil-square-o"
                                                                  aria-hidden="true"
                                                                ></i>&nbsp;&nbsp;
                                                                <b>
                                                                  Edit
                                                                </b>
                                                              </span>
                                                            </li>) : null}

                                                          <li style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                            <span
                                                              onClick={this.deleteMenu}
                                                              id="MsgOption"
                                                              style={{ marginLeft: '-14px' }}
                                                            >
                                                              <i
                                                                className="fa fa-trash"
                                                                aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;

                                                              <b>
                                                                Delete
                                                              </b>
                                                            </span>
                                                          </li>
                                                          <li style={{ padding: '8px' }}>
                                                            <span
                                                              onClick={this.forwardopenpop}
                                                              id="MsgOption"
                                                            >
                                                              <i
                                                                className="fa fa-share"
                                                                aria-hidden="true"></i>

                                                              <b>&nbsp;
                                                                Forward
                                                              </b>
                                                            </span>
                                                          </li>

                                                        </ul>
                                                      </center>
                                                    </div>
                                                  ) : null}
                                                  <i
                                                    className="fa fa-ellipsis-h"
                                                    style={{
                                                      float: "Right",
                                                      cursor: "pointer",
                                                    }}
                                                    aria-hidden="true"
                                                    onClick={() =>
                                                      this.DisplayMenu(
                                                        message.id,
                                                        message.message,
                                                        message.picture_Path,
                                                        message.document_Path,
                                                        message.senderId,
                                                        message.createDateTime,
                                                        message.particularId,
                                                        message.sysDateTimeWithSeconds
                                                      )
                                                    }
                                                  ></i>

                                                  {!isSender ? (
                                                    <h6
                                                      style={{
                                                        fontWeight: "600",
                                                        color: "#09679f",
                                                      }}
                                                    >
                                                      {message.editStatus ===
                                                        1 ? (
                                                        <span
                                                          style={{
                                                            fontWeight: "200",
                                                            color: "grey",
                                                            fontSize: "10px",
                                                          }}
                                                        >
                                                          Edited
                                                        </span>
                                                      ) : null}
                                                    </h6>
                                                  ) : (
                                                    <>
                                                      {message.editStatus ===
                                                        1 ? (
                                                        <h6
                                                          style={{
                                                            fontWeight: "200",
                                                            color: "grey",
                                                            fontSize: "10px",
                                                          }}
                                                        >
                                                          Edited
                                                        </h6>
                                                      ) : null}
                                                    </>
                                                  )}
                                                  {/* {!isSender ? (
                                                    <> */}
                                                  {message.frwdStatus ===
                                                    1 ? (
                                                    <h6
                                                      style={{
                                                        fontWeight: "200",
                                                        color: "grey",
                                                        fontSize: "10px",
                                                      }}
                                                    >
                                                      <i
                                                        className="fa fa-share"
                                                        aria-hidden="true"
                                                      ></i>
                                                      &nbsp;&nbsp;Forwarded
                                                    </h6>
                                                  ) : null}
                                                  {/* </>
                                                  ) : null} */}
                                                  {message.picture_Path ===
                                                    "-" &&
                                                    message.document_Path ===
                                                    "-" ? (
                                                    <p
                                                      className="message-content"
                                                      name="Popmsg"
                                                    // style={{ wordWrap: "break-word" }}
                                                    >
                                                      {regexp.test(
                                                        message.message
                                                      ) == true ? (
                                                        <>
                                                          <a
                                                            style={{
                                                              color: "blue",
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={
                                                              message.message
                                                            }
                                                          >
                                                            {message.message}
                                                          </a>
                                                          <br />
                                                        </>
                                                      ) : (
                                                        <div>
                                                          <p>
                                                            {message.message}
                                                          </p>
                                                        </div>
                                                      )}
                                                      {isSender &&
                                                        message.seen == 1 ? (
                                                        <img
                                                          src={doubletick}
                                                          style={{
                                                            // marginTop: "50px",
                                                            marginLeft: "-2px",
                                                            width: "20px",
                                                            float: "right",
                                                          }}
                                                          title="Seen"
                                                        />
                                                      ) : null}
                                                      {isSender &&
                                                        message.seen == 0 ? (
                                                        <img
                                                          src={singletick}
                                                          style={{
                                                            // marginTop: "50px",
                                                            marginLeft: "-2px",
                                                            width: "20px",
                                                            float: "right",
                                                          }}
                                                          title="Unread"
                                                        />
                                                      ) : null}
                                                      <br />
                                                    </p>
                                                  ) : (
                                                    <>
                                                      {message.picture_Path !==
                                                        "-" &&
                                                        message.document_Path !==
                                                        "-" ? (
                                                        <div>
                                                          <div>
                                                            <p
                                                              className="message-content"
                                                              name="Popmsg"
                                                            // style={{ wordWrap: "break-word" }}
                                                            >
                                                              {message.message !=
                                                                "-" ? (
                                                                <>
                                                                  {
                                                                    message.message
                                                                  }
                                                                </>
                                                              ) : null}
                                                            </p>

                                                            <br />
                                                            <a
                                                              href={
                                                                message.picture_Path
                                                              }
                                                              name="picture"
                                                              readOnly
                                                              value={
                                                                message.picture_Path
                                                              }
                                                              title=""
                                                              target="_blank"
                                                              style={{
                                                                width: "120px",
                                                                height: "200px",
                                                              }}
                                                            ></a>
                                                            <div>
                                                              <img
                                                                alt="chatimg"
                                                                width="80"
                                                                height="80"
                                                                src={
                                                                  message.picture_Path
                                                                }
                                                             
                                                              />
                                                            </div>
                                                            <div>
                                                              <embed
                                                                src={
                                                                  message.document_Path
                                                                }
                                                                id="Gstiframe"
                                                                title=""
                                                                scrolling="no"
                                                                target="_blank"
                                                                style={{
                                                                  width:
                                                                    "100px",
                                                                  height:
                                                                    "100px",
                                                                  pointerEvents:
                                                                    "none",
                                                                }}
                                                              ></embed>
                                                            </div>
                                                          </div>
                                                          {isSender &&
                                                            message.seen == 1 ? (
                                                            <img
                                                              src={doubletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft:
                                                                  "-2px",
                                                                width: "20px",
                                                                float: "right",
                                                              }}
                                                              title="Seen"
                                                            />
                                                          ) : null}
                                                          {isSender &&
                                                            message.seen == 0 ? (
                                                            <img
                                                              src={singletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft:
                                                                  "-2px",
                                                                width: "20px",
                                                                float: "right",
                                                              }}
                                                              title="Unread"
                                                            />
                                                          ) : null}
                                                          <br />
                                                        </div>
                                                      ) : (
                                                        <>
                                                          {message.picture_Path ===
                                                            "-" &&
                                                            message.document_Path !==
                                                            "-" ? (
                                                            <div>
                                                              <p
                                                                className="message-content"
                                                                name="Popmsg"
                                                             
                                                              >
                                                                {message.message !=
                                                                  "-" ? (
                                                                  <>
                                                                    {
                                                                      message.message
                                                                    }
                                                                  </>
                                                                ) : null}
                                                              </p>
                                                              <br />
                                                              <a
                                                                href={
                                                                  message.document_Path
                                                                }
                                                                name="picture"
                                                                readOnly
                                                                value={
                                                                  message.document_Path
                                                                }
                                                                title=""
                                                                scrolling="no"
                                                                target="_blank"
                                                                style={{
                                                                  width:
                                                                    "120px",
                                                                  height:
                                                                    "200px",
                                                                }}
                                                              >
                                                                <div
                                                                  className="row"
                                                                  style={{
                                                                    width:
                                                                      "180px",
                                                                    height:
                                                                      "60px",
                                                                    borderRadius:
                                                                      "5px",
                                                                    boxShadow:
                                                                      "rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px",
                                                                    background:
                                                                      "#e8e8e8",
                                                                    alignItems:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <div className="col-lg-3">
                                                                    <img
                                                                      src={
                                                                        File1
                                                                      }
                                                                      style={{
                                                                        height:
                                                                          "30px",
                                                                        width:
                                                                          "30px",
                                                                      }}
                                                                    ></img>
                                                                  </div>
                                                                  <div className="col-lg-9">
                                                                    {message
                                                                      .document_Path
                                                                      .length >
                                                                      85 ? (
                                                                      <>
                                                                        <div
                                                                          style={{
                                                                            opacity:
                                                                              DName &&
                                                                                documentname ==
                                                                                message.id
                                                                                ? 1
                                                                                : 0,
                                                                            width:
                                                                              "150px",
                                                                            height:
                                                                              "auto",
                                                                            overflowWrap:
                                                                              "break-word",
                                                                            zIndex:
                                                                              "109",
                                                                            border:
                                                                              "1px solid black",

                                                                            background:
                                                                              "#0000007d",
                                                                            color:
                                                                              "white",
                                                                            alignItems:
                                                                              "center",
                                                                            position:
                                                                              "absolute",
                                                                            top: "-50px",
                                                                            left: "-50px",
                                                                          }}
                                                                        >
                                                                          <p
                                                                            style={{
                                                                              fontSize:
                                                                                "10px",
                                                                            }}
                                                                          >
                                                                            {
                                                                              result1
                                                                            }
                                                                          </p>
                                                                        </div>
                                                                        <p
                                                                          style={{
                                                                            marginTop:
                                                                              "10px",
                                                                            textAlign:
                                                                              "center",
                                                                            fontSize:
                                                                              "10px",
                                                                            fontWeight:
                                                                              "600",
                                                                            color:
                                                                              "#19578b",
                                                                          }}
                                                                          onMouseOver={(
                                                                            e
                                                                          ) => {
                                                                            e.preventDefault();
                                                                            this.documentName(
                                                                              message.id
                                                                            );
                                                                          }}
                                                                          onMouseLeave={
                                                                            this
                                                                              .documentNameL
                                                                          }
                                                                        >
                                                                          {
                                                                            result
                                                                          }
                                                                          ...
                                                                        </p>
                                                                      </>
                                                                    ) : (
                                                                      <p
                                                                        style={{
                                                                          marginTop:
                                                                            "10px",
                                                                          textAlign:
                                                                            "center",
                                                                          fontSize:
                                                                            "10px",
                                                                          fontWeight:
                                                                            "600",
                                                                          color:
                                                                            "#014c8c",
                                                                        }}
                                                                      >
                                                                        {result}
                                                                      </p>
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </a>
                                                              {isSender &&
                                                                message.seen ==
                                                                1 ? (
                                                                <img
                                                                  src={
                                                                    doubletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  title="Seen"
                                                                />
                                                              ) : null}
                                                              {isSender &&
                                                                message.seen ==
                                                                0 ? (
                                                                <img
                                                                  src={
                                                                    singletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  title="Unread"
                                                                />
                                                              ) : null}
                                                              <br />
                                                            </div>
                                                          ) : (
                                                            <div>
                                                              <p
                                                                className="message-content"
                                                                name="Popmsg"
                                                            
                                                              >
                                                                {message.message !=
                                                                  "-" ? (
                                                                  <>
                                                                    {
                                                                      message.message
                                                                    }
                                                                  </>
                                                                ) : null}
                                                              </p>
                                                              <br />
                                                              <a
                                                                href={
                                                                  message.picture_Path
                                                                }
                                                                name="picture"
                                                                readOnly
                                                                value={
                                                                  message.picture_Path
                                                                }
                                                                title=""
                                                                target="_blank"
                                                                style={{
                                                                  width:
                                                                    "120px",
                                                                  height:
                                                                    "200px",
                                                                }}
                                                              >
                                                                <div>
                                                                  {message.picture_Path.includes(
                                                                    ".mp4"
                                                                  ) ? (
                                                                    <>
                                                                      {" "}
                                                                      <img
                                                                        alt="Video"
                                                                        width="30"
                                                                        height="30"
                                                                        src={
                                                                          video
                                                                        }
                                                                        title="Click to play video"
                                                                      
                                                                      />{" "}
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "#000",
                                                                        }}
                                                                      >
                                                                        Video
                                                                        File
                                                                      </span>{" "}
                                                                    </>
                                                                  ) : (
                                                                    <img
                                                                      alt="chatimg"
                                                                      width="80"
                                                                      height="80"
                                                                      src={
                                                                        message.picture_Path
                                                                      }
                                                                 
                                                                    />
                                                                  )}
                                                                </div>
                                                              </a>
                                                              {isSender &&
                                                                message.seen ==
                                                                1 ? (
                                                                <img
                                                                  src={
                                                                    doubletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  title="Seen"
                                                                />
                                                              ) : null}
                                                              {isSender &&
                                                                message.seen ==
                                                                0 ? (
                                                                <img
                                                                  src={
                                                                    singletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  title="Unread"
                                                                />
                                                              ) : null}
                                                              <br />
                                                            </div>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                  <br />
                                                  <div className="message-timestamp-right">
                                                    
                                                    <span
                                                      style={{
                                                        color: "gray",
                                                      }}
                                                    >
                                                      <i className="fa fa-clock-o"></i>
                                                      &nbsp;
                                                      {this.formatDateAA(
                                                        message.createDateTime
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                              ) : null}
                                            </>
                                          )}
                                        </>
                                      );
                                    })}
                                    <div ref={this.myRef} />
                                  </div>
                                </div>
                              </div>

                              <input
                                type="text"
                                className="form-control input-value"
                                placeholder="Type a Message"
                                value={this.state.message}
                                onChange={this.handleTextChange}
                                name="Message"
                                style={{ border: "none", display: "none" }}
                                autocomplete="off"
                              />
                            </form>
                          </div>
                        )}
                        {Minimized ? null : (
                          <div className="ICfooter">
                            <div
                              className="input-group write-msg"
                              id="input-group write-msg"
                              style={{
                                border: "1px solid #bfbfbe",
                                padding: "2px 2px",
                                // marginTop: "379px",
                                // marginTop: "503px",
                                width: "101.5%",
                                // marginLeft: "-10px",
                                backgroundColor: "white",
                                marginBottom: "-8px",
                              }}
                            >
                              <div style={attachStyles} id="IndividualAttach">
                                <ul id="listmenu">
                                  <label style={{ marginTop: "8px" }}>
                                    <span
                                      className="fa fa-picture-o"
                                      aria-hidden="true"
                                      style={{
                                        color: "#5d4fd0",
                                        marginLeft: "7px",
                                        cursor: "pointer",
                                      }}
                                      onClick={this.Picturepop}
                                    ></span>
                                  </label>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <br />
                                  <label
                                    style={{
                                      marginTop: "8px",
                                      marginLeft: "23%",
                                    }}
                                  >
                                    <span
                                      className="fa fa-file"
                                      aria-hidden="true"
                                      style={{
                                        color: "#5d4fd0",
                                        cursor: "pointer",
                                      }}
                                      onClick={this.Documentpop}
                                    ></span>
                                  </label>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                </ul>
                              </div>
                              <input
                                type="text"
                                className="form-control input-value"
                                placeholder="Type a Message"
                                value={this.state.message}
                                onChange={this.handleTextChange}
                                name="Message"
                                style={{ border: "none" }}
                                onKeyPress={this.handleKeyPress}
                                autocomplete="off"
                              />
                              <input
                                type="file"
                                id="file"
                                name="Picture_Path"
                                accept="image/*"
                                // onChange={this.handleInputChange}
                                // onChange={this.handleImageUpload}
                                onChange={this.handleFileChange}
                                onFocus={(e) => (e.target.type = "file")}
                                style={{ display: "none" }}
                              />
                              <span>
                                <i
                                  className="fa fa-smile-o"
                                  aria-hidden="true"
                                  style={{
                                    marginTop: "10px",
                                    fontSize: "18px",
                                  }}
                                  onClick={this.myEmoji}
                                ></i>
                              </span>
                              {showEmoji && (
                                <div
                                  style={{
                                    position: "absolute",
                                    // left:'50%',
                                    top: "-200px",
                                    transform: "translate(-50%, -50%)",
                                    maxWidth: "90vw",
                                    maxHeight: "90vh",
                                  }}
                                  //  id="indiEmojiAdmin"
                                  id="Pickeroneoneone"
                                >
                                  <Picker
                                    onEmojiClick={this.handleEmojiClick}
                                    height={380}
                                    width={"100%"}
                                  />
                                </div>
                              )}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <label style={{ marginTop: "8px" }}>
                                <i
                                  className="fa fa-paperclip"
                                  style={{ fontSize: "20px" }}
                                  aria-hidden="true"
                                  onClick={this.attachMenu}
                                  id="AttachIcon"
                                ></i>
                              </label>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <span className="input-group-btn">
                                <button
                                  type="submit"
                                  className="btn btn-secondary"
                                  onClick={this.handleSubmit}
                                >
                                  <i className="icofont icofont-paper-plane"></i>
                                </button>
                              </span>
                            </div>
                            <div
                              className="input-group write-msg"
                              id="input-groupEdit"
                              style={{
                                border: "1px solid #bfbfbe",
                                padding: "2px 2px",
                                // marginTop: "503px",
                                width: "101.5%",

                                backgroundColor: "white",
                                marginBottom: "-8px",
                              }}
                            >
                              <input
                                type="text"
                                className="form-control input-value"
                                placeholder="Type a Message"
                                value={this.state.deletemsg}
                                onChange={this.handleTextChange}
                                name="Message"
                                style={{ border: "none" }}
                                onKeyPress={this.handleKeyPress}
                                autocomplete="off"
                              />
                              <input
                                type="file"
                                id="file"
                                name="Picture_Path"
                                accept="image/*"
                                // onChange={this.handleInputChange}
                                // onChange={this.handleImageUpload}
                                onChange={this.handleFileChange}
                                onFocus={(e) => (e.target.type = "file")}
                                style={{ display: "none" }}
                              />
                              <span
                                className="fa fa-smile-o"
                                aria-hidden="true"
                                style={{
                                  marginTop: "10px",
                                  fontSize: "18px",
                                }}
                                onClick={this.myEmoji}
                              ></span>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              
                              {showEmoji && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-395px",
                                    marginLeft: "830px",
                                  }}
                                >
                                  <Picker
                                    onEmojiClick={this.handleEmojiClick}
                                  />
                                </div>
                              )}
                              <span className="input-group-btn">
                                <span
                                  id="paper-btn"
                                  className="btn btn-secondary"
                                // type="submit"
                                >
                                  <i
                                    className="fa fa-check"
                                    onClick={this.EditOneonOneMessage}
                                  ></i>{" "}
                                  &nbsp;&nbsp;{" "}
                                  <i
                                    className="fa fa-times"
                                    onClick={this.CancelEdit}
                                  ></i>
                                </span>
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    {RoleA === "User" ? (
                      <>
                        {Minimized ? (
                          <div className="ICheaderMinimize">
                            <span
                              style={{
                                float: "left",
                                // marginTop: "-5px",
                                color: "white",
                                fontSize: "15px",
                                fontWeight: "bold",
                              }}
                            >
                              {EntityMemberUsers.map((member) =>
                                member.firstName === Name &&
                                  member.email === Email ? (
                                  <div key={member.id}>
                                    {member.userActiveStatus === 1 ? (
                                      <>
                                        <i
                                          className="icofont icofont-arrow-left"
                                          id="ICbackgroup"
                                          onClick={this.Hidechatheader}
                                        ></i>
                                        &nbsp;
                                        <img
                                          src={onlinedot}
                                          className="media-object img-circle"
                                          style={{
                                            marginTop: "0px",
                                            width: "15px",
                                            // position: "absolute",
                                            animation:
                                              "greenonline 2s infinite",
                                          }}
                                          title="Online"
                                        />
                                        &nbsp;
                                        <img
                                          className="media-object img-circle"
                                          src={UserProfilePic}
                                          alt="img"
                                          style={{
                                            height: "35px",
                                            width: "35px",
                                            // padding: "1px 1px",
                                            // marginLeft: "20px",
                                          }}
                                        />
                                        &nbsp;&nbsp;
                                        {Name} {""}
                                        <p
                                          style={{
                                            color: "#fff",
                                            fontSize: "10px",
                                            //  marginLeft: "52%",
                                            marginLeft: "65px",
                                            marginTop: "-4%",
                                          }}
                                        >
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          Online
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <i
                                          className="icofont icofont-arrow-left"
                                          id="ICbackgroup"
                                          onClick={this.Hidechatheader}
                                        ></i>
                                        &nbsp;
                                        <img
                                          src={offlinedot}
                                          className="media-object img-circle"
                                          style={{
                                            marginTop: "0px",
                                            width: "15px",
                                            // position: "absolute",
                                            animation: "offlinego 2s infinite",
                                          }}
                                          title="Offline"
                                        />
                                        &nbsp;
                                        <img
                                          className="media-object img-circle"
                                          src={UserProfilePic}
                                          alt="img"
                                          style={{
                                            height: "35px",
                                            width: "35px",
                                            // padding: "1px 1px",
                                            // marginLeft: "20px",
                                          }}
                                        />
                                        &nbsp;&nbsp;
                                        {Name}
                                        <p
                                          style={{
                                            color: "#d4d4d4",
                                            width: "100%",
                                            fontSize: "12px",
                                            marginLeft: "88px",
                                            marginTop: "-5%",
                                          }}
                                        >
                                          last seen at {member.logout}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                ) : null
                              )}
                            </span>
                            <div
                              className="box-tools d-inline-block"
                              style={{
                                float: "right",
                                marginTop: "0px",
                                color: "white",
                                fontSize: "20px",
                              }}
                            >
                              <span>
                                {Minimized ? (
                                  <span
                                    className="fa fa-window-maximize"
                                    onClick={this.toggleMinimizeA}
                                  ></span>
                                ) : (
                                  <span className="fa fa-minus"></span>
                                )}
                              </span>{" "}
                              &nbsp;&nbsp;
                              <span>
                                {" "}
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                  onClick={this.Hidechatheader}
                                ></i>
                              </span>
                            </div>
                          </div>
                        ) : null}

                        {Chatpop && (
                          <div className="ICchat-container">
                            {Minimized ? null : (
                              <div className="ICheader">
                                <span
                                  style={{
                                    float: "left",
                                    // marginTop: "-5px",
                                    color: "white",
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {EntityMemberUsers.map((member) =>
                                    member.firstName === Name &&
                                      member.email === Email ? (
                                      <div
                                        key={member.id}
                                      
                                      >
                                        {member.userActiveStatus === 1 ? (
                                          <>
                                            <i
                                              className="icofont icofont-arrow-left"
                                              id="ICbackgroup"
                                              onClick={() => {
                                                this.setState({
                                                  Chatpop: false,
                                                }, () => {
                                                  this.updateCheckedState();
                                                });
                                              }}
                                            ></i>
                                            &nbsp;
                                            <img
                                              src={onlinedot}
                                              className="media-object img-circle"
                                              style={{
                                                marginTop: "0px",
                                                width: "15px",
                                                // position: "absolute",
                                                animation:
                                                  "greenonline 2s infinite",
                                              }}
                                              title="Online"
                                            />
                                            &nbsp;
                                            <img
                                              className="media-object img-circle"
                                              src={UserProfilePic}
                                              alt="img"
                                              style={{
                                                height: "35px",
                                                width: "35px",
                                                // padding: "1px 1px",
                                                // marginLeft: "20px",
                                              }}
                                            />
                                            &nbsp;&nbsp;
                                            {Name} {""}
                                            <p
                                              style={{
                                                color: "#fff",
                                                fontSize: "10px",
                                                //  marginLeft: "52%",
                                                marginLeft: "65px",
                                                marginTop: "-4%",
                                              }}
                                            >
                                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              Online
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <i
                                              className="icofont icofont-arrow-left"
                                              id="ICbackgroup"
                                              onClick={() => {
                                                this.setState({
                                                  Chatpop: false,
                                                }, () => {
                                                  this.updateCheckedState();
                                                });
                                              }}
                                            ></i>
                                            &nbsp;
                                            <img
                                              src={offlinedot}
                                              className="media-object img-circle"
                                              style={{
                                                marginTop: "0px",
                                                width: "15px",
                                                // position: "absolute",
                                                animation:
                                                  "offlinego 2s infinite",
                                              }}
                                              title="Offline"
                                            />
                                            &nbsp;
                                            <img
                                              className="media-object img-circle"
                                              src={UserProfilePic}
                                              alt="img"
                                              style={{
                                                height: "35px",
                                                width: "35px",
                                                // padding: "1px 1px",
                                                // marginLeft: "20px",
                                              }}
                                            />
                                            &nbsp;&nbsp;
                                            {Name}
                                            <p
                                              style={{
                                                color: "#d4d4d4",
                                                width: "100%",
                                                fontSize: "12px",
                                                marginLeft: "88px",
                                                marginTop: "-5%",
                                              }}
                                            >
                                              last seen at {member.logout}
                                            </p>
                                          </>
                                        )}
                                      </div>
                                    ) : null
                                  )}
                                </span>
                                <div
                                  className="box-tools d-inline-block"
                                  style={{
                                    float: "right",
                                    marginTop: "0px",
                                    color: "white",
                                    fontSize: "20px",
                                  }}
                                >
                                  <span onClick={this.toggleMinimize}>
                                    {Minimized ? (
                                      <span className="fa fa-window-maximize"></span>
                                    ) : (
                                      <span className="fa fa-minus"></span>
                                    )}
                                  </span>{" "}
                                  &nbsp;&nbsp;
                                  <span>
                                    {" "}
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                      onClick={this.hideChatpop}
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            )}
                            {Minimized ? null : (
                              <div
                                className="ICchat-messages"
                                ref={this.chatBoxRef}
                              >
                                <form
                                  autoComplete="off"
                                  ref={this.formRef}
                                  // onSubmit={this.handleSubmit}
                                  encType="multipart/form-data"
                                >
                                  <input
                                    type="hidden"
                                    name="UserName"
                                    defaultValue={this.state.Email}
                                  />
                                  <div style={documentpop} id="UserInDocPop">
                                    <span style={{ textAlign: "center" }}>
                                      {isdocumentpopOpen && (
                                        <UserFilepop
                                          values={this.state.Email}
                                          handleClose={() =>
                                            this.documentPopup()
                                          }
                                        // AllMembers={this.state.mergedUsers}
                                        />
                                      )}
                                    </span>
                                  </div>

                                  <div style={picturepop} id="UserInDocPop">
                                    <span style={{ textAlign: "center" }}>
                                      {ispicturepopOpen && (
                                        <UserPicturepop
                                          values={this.state.Email}

                                          handleClose={() => this.picturePopup()}

                                        />
                                      )}
                                    </span></div>

                                  <div className="message-scrooler">
                                    <div
                                      style={{ marginLeft: "2%" }}
                                      className="messages"
                                    >
                                      <div
                                      >
                                        {messages.map((message, index) => {
                                          const isSender =
                                            Popmsg.includes(message);
                                          const isReciever =
                                            ReceivepopMsg.includes(message);
                                          let text = message.document_Path;
                                          let result = text.slice(45, 85);
                                          let result1 = text.slice(45);
                                          return (
                                            <>
                                              {message.sysDateTimeWithSeconds &&
                                                message.rowNum === 1 ? (
                                                <>
                                                  <center
                                                    style={{ marginTop: "1%" }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: "black",
                                                    backgroundColor:
                                                      "rgb(221, 221, 221)",
                                                        height: "35px",
                                                        padding:
                                                          "8px 8px 8px 6px",
                                                        borderRadius: "7px",
                                                      }}
                                                    >
                                                      &nbsp;
                                                      {this.formatDateAA1(
                                                        message.createDateTime
                                                      )}
                                                    </span>
                                                  </center>
                                                  <br />
                                                </>
                                              ) : null}
                                              {message.particularId ===
                                                UserId ? (
                                                <>
                                                  {message.displayStatus ===
                                                    2 ? (
                                                    <div
                                                      className={`message-${isSender
                                                        ? "orange"
                                                        : "blue"
                                                        }`}
                                                      key={index}
                                                      style={{
                                                        height: "auto",
                                                        overflowWrap:
                                                          "break-word",
                                                      }}
                                                    >
                                                      {MsgMenu &&
                                                        this.state.deleteId ==
                                                        message.id ? (
                                                        <div
                                                          style={MessageMenu}
                                                          ref={
                                                            this.containerRef
                                                          }
                                                        >
                                                          <center>
                                                            <ul>
                                                              {isSender ? (
                                                                <li style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>

                                                                  <span
                                                                    onClick={this.EditMessage}
                                                                    id="MsgOption"
                                                                    style={{ marginLeft: '-26px' }}
                                                                  >
                                                                    <i
                                                                      className="fa fa-pencil-square-o"
                                                                      aria-hidden="true"
                                                                    ></i>&nbsp;&nbsp;
                                                                    <b>
                                                                      Edit
                                                                    </b>
                                                                  </span>
                                                                </li>) : null}

                                                              <li style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                                <span
                                                                  onClick={this.deleteMenu}
                                                                  id="MsgOption"
                                                                  style={{ marginLeft: '-14px' }}
                                                                >
                                                                  <i
                                                                    className="fa fa-trash"
                                                                    aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;

                                                                  <b>
                                                                    Delete
                                                                  </b>
                                                                </span>
                                                              </li>
                                                              <li style={{ padding: '8px' }}>
                                                                <span
                                                                  onClick={this.forwardopenpop}
                                                                  id="MsgOption"
                                                                >
                                                                  <i
                                                                    className="fa fa-share"
                                                                    aria-hidden="true"></i>

                                                                  <b>&nbsp;
                                                                    Forward
                                                                  </b>
                                                                </span>
                                                              </li>

                                                            </ul>
                                                          </center>
                                                        </div>
                                                      ) : null}
                                                      <i
                                                        className="fa fa-ellipsis-h"
                                                        style={{
                                                          float: "Right",
                                                          cursor: "pointer",
                                                        }}
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                          this.DisplayMenu(
                                                            message.id,
                                                            message.message,
                                                            message.picture_Path,
                                                            message.document_Path,
                                                            message.senderId,
                                                            message.createDateTime,
                                                            message.particularId,
                                                            message.sysDateTimeWithSeconds
                                                          )
                                                        }
                                                      ></i>

                                                      {!isSender ? (
                                                        <h6
                                                          style={{
                                                            fontWeight: "600",
                                                            color: "#09679f",
                                                          }}
                                                        >
                                                          {message.editStatus ===
                                                            1 ? (
                                                            <span
                                                              style={{
                                                                fontWeight:
                                                                  "200",
                                                                color: "grey",
                                                                fontSize:
                                                                  "10px",
                                                              }}
                                                            >
                                                              Edited
                                                            </span>
                                                          ) : null}
                                                        </h6>
                                                      ) : (
                                                        <>
                                                          {message.editStatus ===
                                                            1 ? (
                                                            <h6
                                                              style={{
                                                                fontWeight:
                                                                  "200",
                                                                color: "grey",
                                                                fontSize:
                                                                  "10px",
                                                              }}
                                                            >
                                                              Edited
                                                            </h6>
                                                          ) : null}
                                                        </>
                                                      )}
                                                      {/* {!isSender ? (
                                                        <> */}
                                                      {message.frwdStatus ===
                                                        1 ? (
                                                        <h6
                                                          style={{
                                                            fontWeight:
                                                              "200",
                                                            color: "grey",
                                                            fontSize:
                                                              "10px",
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-share"
                                                            aria-hidden="true"
                                                          ></i>
                                                          &nbsp;&nbsp;Forwarded
                                                        </h6>
                                                      ) : null}
                                                      {/* </>
                                                      ) : null} */}
                                                      {message.picture_Path ===
                                                        "-" &&
                                                        message.document_Path ===
                                                        "-" ? (
                                                        <p
                                                          className="message-content"
                                                          name="Popmsg"
                                                        // style={{ wordWrap: "break-word" }}
                                                        >
                                                          {regexp.test(
                                                            message.message
                                                          ) == true ? (
                                                            <>
                                                              <a
                                                                style={{
                                                                  color: "blue",
                                                                  textDecoration:
                                                                    "underline",
                                                                }}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href={
                                                                  message.message
                                                                }
                                                              >
                                                                {
                                                                  message.message
                                                                }
                                                              </a>
                                                              <br />
                                                            </>
                                                          ) : (
                                                            <div>
                                                              <p>
                                                                {
                                                                  message.message
                                                                }
                                                              </p>
                                                            </div>
                                                          )}
                                                          {isSender &&
                                                            message.seen == 1 ? (
                                                            <img
                                                              src={doubletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft:
                                                                  "-2px",
                                                                width: "20px",
                                                                float: "right",
                                                              }}
                                                              title="Seen"
                                                            />
                                                          ) : null}
                                                          {isSender &&
                                                            message.seen == 0 ? (
                                                            <img
                                                              src={singletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft:
                                                                  "-2px",
                                                                width: "20px",
                                                                float: "right",
                                                              }}
                                                              title="Unread"
                                                            />
                                                          ) : null}
                                                          <br />
                                                        </p>
                                                      ) : (
                                                        <>
                                                          {message.picture_Path !==
                                                            "-" &&
                                                            message.document_Path !==
                                                            "-" ? (
                                                            <div>
                                                              <div>
                                                                <p
                                                                  className="message-content"
                                                                  name="Popmsg"
                                                                // style={{ wordWrap: "break-word" }}
                                                                >
                                                                  {message.message !=
                                                                    "-" ? (
                                                                    <>
                                                                      {
                                                                        message.message
                                                                      }
                                                                    </>
                                                                  ) : null}
                                                                </p>

                                                                <br />
                                                                <a
                                                                  href={
                                                                    message.picture_Path
                                                                  }
                                                                  name="picture"
                                                                  readOnly
                                                                  value={
                                                                    message.picture_Path
                                                                  }
                                                                  title=""
                                                                  target="_blank"
                                                                  style={{
                                                                    width:
                                                                      "120px",
                                                                    height:
                                                                      "200px",
                                                                  }}
                                                                ></a>
                                                                <div>
                                                                  <img
                                                                    alt="chatimg"
                                                                    width="80"
                                                                    height="80"
                                                                    src={
                                                                      message.picture_Path
                                                                    }
                                                                  // style={{
                                                                  //   width:
                                                                  //     "inherit",
                                                                  // }}
                                                                  />
                                                                </div>
                                                                <div>
                                                                  <embed
                                                                    src={
                                                                      message.document_Path
                                                                    }
                                                                    id="Gstiframe"
                                                                    title=""
                                                                    scrolling="no"
                                                                    target="_blank"
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      height:
                                                                        "100px",
                                                                      pointerEvents:
                                                                        "none",
                                                                    }}
                                                                  ></embed>
                                                                </div>
                                                              </div>
                                                              {isSender &&
                                                                message.seen ==
                                                                1 ? (
                                                                <img
                                                                  src={
                                                                    doubletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  title="Seen"
                                                                />
                                                              ) : null}
                                                              {isSender &&
                                                                message.seen ==
                                                                0 ? (
                                                                <img
                                                                  src={
                                                                    singletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  title="Unread"
                                                                />
                                                              ) : null}
                                                              <br />
                                                            </div>
                                                          ) : (
                                                            <>
                                                              {message.picture_Path ===
                                                                "-" &&
                                                                message.document_Path !==
                                                                "-" ? (
                                                                <div>
                                                                  <p
                                                                    className="message-content"
                                                                    name="Popmsg"
                                                                  // style={{
                                                                  //   wordWrap: "break-word",
                                                                  // }}
                                                                  >
                                                                    {message.message !=
                                                                      "-" ? (
                                                                      <>
                                                                        {
                                                                          message.message
                                                                        }
                                                                      </>
                                                                    ) : null}
                                                                  </p>
                                                                  <br />
                                                                  <a
                                                                    href={
                                                                      message.document_Path
                                                                    }
                                                                    name="picture"
                                                                    readOnly
                                                                    value={
                                                                      message.document_Path
                                                                    }
                                                                    title=""
                                                                    scrolling="no"
                                                                    target="_blank"
                                                                    style={{
                                                                      width:
                                                                        "120px",
                                                                      height:
                                                                        "200px",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      className="row"
                                                                      style={{
                                                                        width:
                                                                          "180px",
                                                                        height:
                                                                          "60px",
                                                                        borderRadius:
                                                                          "5px",
                                                                        boxShadow:
                                                                          "rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px",
                                                                        background:
                                                                          "#e8e8e8",
                                                                        alignItems:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      <div className="col-lg-3">
                                                                        <img
                                                                          src={
                                                                            File1
                                                                          }
                                                                          style={{
                                                                            height:
                                                                              "30px",
                                                                            width:
                                                                              "30px",
                                                                          }}
                                                                        ></img>
                                                                      </div>
                                                                      <div className="col-lg-9">
                                                                        {message
                                                                          .document_Path
                                                                          .length >
                                                                          85 ? (
                                                                          <>
                                                                            <div
                                                                              style={{
                                                                                opacity:
                                                                                  DName &&
                                                                                    documentname ==
                                                                                    message.id
                                                                                    ? 1
                                                                                    : 0,
                                                                                width:
                                                                                  "150px",
                                                                                height:
                                                                                  "auto",
                                                                                overflowWrap:
                                                                                  "break-word",
                                                                                zIndex:
                                                                                  "109",
                                                                                border:
                                                                                  "1px solid black",

                                                                                background:
                                                                                  "#0000007d",
                                                                                color:
                                                                                  "white",
                                                                                alignItems:
                                                                                  "center",
                                                                                position:
                                                                                  "absolute",
                                                                                top: "-50px",
                                                                                left: "-50px",
                                                                              }}
                                                                            >
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "10px",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  result1
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                            <p
                                                                              style={{
                                                                                marginTop:
                                                                                  "10px",
                                                                                textAlign:
                                                                                  "center",
                                                                                fontSize:
                                                                                  "10px",
                                                                                fontWeight:
                                                                                  "600",
                                                                                color:
                                                                                  "#19578b",
                                                                              }}
                                                                              onMouseOver={(
                                                                                e
                                                                              ) => {
                                                                                e.preventDefault();
                                                                                this.documentName(
                                                                                  message.id
                                                                                );
                                                                              }}
                                                                              onMouseLeave={
                                                                                this
                                                                                  .documentNameL
                                                                              }
                                                                            >
                                                                              {
                                                                                result
                                                                              }
                                                                              ...
                                                                            </p>
                                                                          </>
                                                                        ) : (
                                                                          <p
                                                                            style={{
                                                                              marginTop:
                                                                                "10px",
                                                                              textAlign:
                                                                                "center",
                                                                              fontSize:
                                                                                "10px",
                                                                              fontWeight:
                                                                                "600",
                                                                              color:
                                                                                "#014c8c",
                                                                            }}
                                                                          >
                                                                            {
                                                                              result
                                                                            }
                                                                          </p>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  </a>
                                                                  {isSender &&
                                                                    message.seen ==
                                                                    1 ? (
                                                                    <img
                                                                      src={
                                                                        doubletick
                                                                      }
                                                                      style={{
                                                                        // marginTop: "50px",
                                                                        marginLeft:
                                                                          "-2px",
                                                                        width:
                                                                          "20px",
                                                                        float:
                                                                          "right",
                                                                      }}
                                                                      title="Seen"
                                                                    />
                                                                  ) : null}
                                                                  {isSender &&
                                                                    message.seen ==
                                                                    0 ? (
                                                                    <img
                                                                      src={
                                                                        singletick
                                                                      }
                                                                      style={{
                                                                        // marginTop: "50px",
                                                                        marginLeft:
                                                                          "-2px",
                                                                        width:
                                                                          "20px",
                                                                        float:
                                                                          "right",
                                                                      }}
                                                                      title="Unread"
                                                                    />
                                                                  ) : null}
                                                                  <br />
                                                                </div>
                                                              ) : (
                                                                <div>
                                                                  <p
                                                                    className="message-content"
                                                                    name="Popmsg"
                                                                
                                                                  >
                                                                    {message.message !=
                                                                      "-" ? (
                                                                      <>
                                                                        {
                                                                          message.message
                                                                        }
                                                                      </>
                                                                    ) : null}
                                                                  </p>
                                                                  <br />
                                                                  <a
                                                                    href={
                                                                      message.picture_Path
                                                                    }
                                                                    name="picture"
                                                                    readOnly
                                                                    value={
                                                                      message.picture_Path
                                                                    }
                                                                    title=""
                                                                    target="_blank"
                                                                    style={{
                                                                      width:
                                                                        "120px",
                                                                      height:
                                                                        "200px",
                                                                    }}
                                                                  >
                                                                    <div>
                                                                      {message.picture_Path.includes(
                                                                        ".mp4"
                                                                      ) ? (
                                                                        <>
                                                                          {" "}
                                                                          <img
                                                                            alt="Video"
                                                                            width="30"
                                                                            height="30"
                                                                            src={
                                                                              video
                                                                            }
                                                                            title="Click to play video"
                                                                         
                                                                          />{" "}
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "#000",
                                                                            }}
                                                                          >
                                                                            Video
                                                                            File
                                                                          </span>{" "}
                                                                        </>
                                                                      ) : (
                                                                        <img
                                                                          alt="chatimg"
                                                                          width="80"
                                                                          height="80"
                                                                          src={
                                                                            message.picture_Path
                                                                          }
                                                                        
                                                                        />
                                                                      )}
                                                                    </div>
                                                                  </a>
                                                                  {isSender &&
                                                                    message.seen ==
                                                                    1 ? (
                                                                    <img
                                                                      src={
                                                                        doubletick
                                                                      }
                                                                      style={{
                                                                        // marginTop: "50px",
                                                                        marginLeft:
                                                                          "-2px",
                                                                        width:
                                                                          "20px",
                                                                        float:
                                                                          "right",
                                                                      }}
                                                                      title="Seen"
                                                                    />
                                                                  ) : null}
                                                                  {isSender &&
                                                                    message.seen ==
                                                                    0 ? (
                                                                    <img
                                                                      src={
                                                                        singletick
                                                                      }
                                                                      style={{
                                                                        // marginTop: "50px",
                                                                        marginLeft:
                                                                          "-2px",
                                                                        width:
                                                                          "20px",
                                                                        float:
                                                                          "right",
                                                                      }}
                                                                      title="Unread"
                                                                    />
                                                                  ) : null}
                                                                  <br />
                                                                </div>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                      <br />
                                                      <div className="message-timestamp-right">
                                                        
                                                        <span
                                                          style={{
                                                            color: "gray",
                                                          }}
                                                        >
                                                          <i className="fa fa-clock-o"></i>
                                                          &nbsp;
                                                          {this.formatDateAA(
                                                            message.createDateTime
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </>
                                              ) : (
                                                <>
                                                  {message.displayStatus ===
                                                    1 ? (
                                                    <div
                                                      className={`message-${isSender
                                                        ? "orange"
                                                        : "blue"
                                                        }`}
                                                      key={index}
                                                      style={{
                                                        height: "auto",
                                                        overflowWrap:
                                                          "break-word",
                                                      }}
                                                    >
                                                      {MsgMenu &&
                                                        this.state.deleteId ==
                                                        message.id ? (
                                                        <div
                                                          style={MessageMenu}
                                                          ref={
                                                            this.containerRef
                                                          }
                                                        >
                                                          <center>
                                                            <ul>
                                                              {isSender ? (
                                                                <li style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>

                                                                  <span
                                                                    onClick={this.EditMessage}
                                                                    id="MsgOption"
                                                                    style={{ marginLeft: '-26px' }}
                                                                  >
                                                                    <i
                                                                      className="fa fa-pencil-square-o"
                                                                      aria-hidden="true"
                                                                    ></i>&nbsp;&nbsp;
                                                                    <b>
                                                                      Edit
                                                                    </b>
                                                                  </span>
                                                                </li>) : null}

                                                              <li style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>
                                                                <span
                                                                  onClick={this.deleteMenu}
                                                                  id="MsgOption"
                                                                  style={{ marginLeft: '-14px' }}
                                                                >
                                                                  <i
                                                                    className="fa fa-trash"
                                                                    aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;

                                                                  <b>
                                                                    Delete
                                                                  </b>
                                                                </span>
                                                              </li>
                                                              <li style={{ padding: '8px' }}>
                                                                <span
                                                                  onClick={this.forwardopenpop}
                                                                  id="MsgOption"
                                                                >
                                                                  <i
                                                                    className="fa fa-share"
                                                                    aria-hidden="true"></i>

                                                                  <b>&nbsp;
                                                                    Forward
                                                                  </b>
                                                                </span>
                                                              </li>

                                                            </ul>
                                                          </center>
                                                        </div>
                                                      ) : null}
                                                      <i
                                                        className="fa fa-ellipsis-h"
                                                        style={{
                                                          float: "Right",
                                                          cursor: "pointer",
                                                        }}
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                          this.DisplayMenu(
                                                            message.id,
                                                            message.message,
                                                            message.picture_Path,
                                                            message.document_Path,
                                                            message.senderId,
                                                            message.createDateTime,
                                                            message.particularId,
                                                            message.sysDateTimeWithSeconds
                                                          )
                                                        }
                                                      ></i>

                                                      {!isSender ? (
                                                        <h6
                                                          style={{
                                                            fontWeight: "600",
                                                            color: "#09679f",
                                                          }}
                                                        >
                                                          {message.editStatus ===
                                                            1 ? (
                                                            <span
                                                              style={{
                                                                fontWeight:
                                                                  "200",
                                                                color: "grey",
                                                                fontSize:
                                                                  "10px",
                                                              }}
                                                            >
                                                              Edited
                                                            </span>
                                                          ) : null}
                                                        </h6>
                                                      ) : (
                                                        <>
                                                          {message.editStatus ===
                                                            1 ? (
                                                            <h6
                                                              style={{
                                                                fontWeight:
                                                                  "200",
                                                                color: "grey",
                                                                fontSize:
                                                                  "10px",
                                                              }}
                                                            >
                                                              Edited
                                                            </h6>
                                                          ) : null}
                                                        </>
                                                      )}
                                                      {/* {!isSender ? (
                                                        <> */}
                                                      {message.frwdStatus ===
                                                        1 ? (
                                                        <h6
                                                          style={{
                                                            fontWeight:
                                                              "200",
                                                            color: "grey",
                                                            fontSize:
                                                              "10px",
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-share"
                                                            aria-hidden="true"
                                                          ></i>
                                                          &nbsp;&nbsp;Forwarded
                                                        </h6>
                                                      ) : null}
                                                      {/* </>
                                                      ) : null} */}
                                                      {message.picture_Path ===
                                                        "-" &&
                                                        message.document_Path ===
                                                        "-" ? (
                                                        <p
                                                          className="message-content"
                                                          name="Popmsg"
                                                        // style={{ wordWrap: "break-word" }}
                                                        >
                                                          {regexp.test(
                                                            message.message
                                                          ) == true ? (
                                                            <>
                                                              <a
                                                                style={{
                                                                  color: "blue",
                                                                  textDecoration:
                                                                    "underline",
                                                                }}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                href={
                                                                  message.message
                                                                }
                                                              >
                                                                {
                                                                  message.message
                                                                }
                                                              </a>
                                                              <br />
                                                            </>
                                                          ) : (
                                                            <div>
                                                              <p>
                                                                {
                                                                  message.message
                                                                }
                                                              </p>
                                                            </div>
                                                          )}
                                                          {isSender &&
                                                            message.seen == 1 ? (
                                                            <img
                                                              src={doubletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft:
                                                                  "-2px",
                                                                width: "20px",
                                                                float: "right",
                                                              }}
                                                              title="Seen"
                                                            />
                                                          ) : null}
                                                          {isSender &&
                                                            message.seen == 0 ? (
                                                            <img
                                                              src={singletick}
                                                              style={{
                                                                // marginTop: "50px",
                                                                marginLeft:
                                                                  "-2px",
                                                                width: "20px",
                                                                float: "right",
                                                              }}
                                                              title="Unread"
                                                            />
                                                          ) : null}
                                                          <br />
                                                        </p>
                                                      ) : (
                                                        <>
                                                          {message.picture_Path !==
                                                            "-" &&
                                                            message.document_Path !==
                                                            "-" ? (
                                                            <div>
                                                              <div>
                                                                <p
                                                                  className="message-content"
                                                                  name="Popmsg"
                                                                // style={{ wordWrap: "break-word" }}
                                                                >
                                                                  {message.message !=
                                                                    "-" ? (
                                                                    <>
                                                                      {
                                                                        message.message
                                                                      }
                                                                    </>
                                                                  ) : null}
                                                                </p>

                                                                <br />
                                                                <a
                                                                  href={
                                                                    message.picture_Path
                                                                  }
                                                                  name="picture"
                                                                  readOnly
                                                                  value={
                                                                    message.picture_Path
                                                                  }
                                                                  title=""
                                                                  target="_blank"
                                                                  style={{
                                                                    width:
                                                                      "120px",
                                                                    height:
                                                                      "200px",
                                                                  }}
                                                                ></a>
                                                                <div>
                                                                  <img
                                                                    alt="chatimg"
                                                                    width="80"
                                                                    height="80"
                                                                    src={
                                                                      message.picture_Path
                                                                    }
                                                                  
                                                                  />
                                                                </div>
                                                                <div>
                                                                  <embed
                                                                    src={
                                                                      message.document_Path
                                                                    }
                                                                    id="Gstiframe"
                                                                    title=""
                                                                    scrolling="no"
                                                                    target="_blank"
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      height:
                                                                        "100px",
                                                                      pointerEvents:
                                                                        "none",
                                                                    }}
                                                                  ></embed>
                                                                </div>
                                                              </div>
                                                              {isSender &&
                                                                message.seen ==
                                                                1 ? (
                                                                <img
                                                                  src={
                                                                    doubletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  title="Seen"
                                                                />
                                                              ) : null}
                                                              {isSender &&
                                                                message.seen ==
                                                                0 ? (
                                                                <img
                                                                  src={
                                                                    singletick
                                                                  }
                                                                  style={{
                                                                    // marginTop: "50px",
                                                                    marginLeft:
                                                                      "-2px",
                                                                    width:
                                                                      "20px",
                                                                    float:
                                                                      "right",
                                                                  }}
                                                                  title="Unread"
                                                                />
                                                              ) : null}
                                                              <br />
                                                            </div>
                                                          ) : (
                                                            <>
                                                              {message.picture_Path ===
                                                                "-" &&
                                                                message.document_Path !==
                                                                "-" ? (
                                                                <div>
                                                                  <p
                                                                    className="message-content"
                                                                    name="Popmsg"
                                                                  // style={{
                                                                  //   wordWrap: "break-word",
                                                                  // }}
                                                                  >
                                                                    {message.message !=
                                                                      "-" ? (
                                                                      <>
                                                                        {
                                                                          message.message
                                                                        }
                                                                      </>
                                                                    ) : null}
                                                                  </p>
                                                                  <br />
                                                                  <a
                                                                    href={
                                                                      message.document_Path
                                                                    }
                                                                    name="picture"
                                                                    readOnly
                                                                    value={
                                                                      message.document_Path
                                                                    }
                                                                    title=""
                                                                    scrolling="no"
                                                                    target="_blank"
                                                                    style={{
                                                                      width:
                                                                        "120px",
                                                                      height:
                                                                        "200px",
                                                                    }}
                                                                  >
                                                                    <div
                                                                      className="row"
                                                                      style={{
                                                                        width:
                                                                          "180px",
                                                                        height:
                                                                          "60px",
                                                                        borderRadius:
                                                                          "5px",
                                                                        boxShadow:
                                                                          "rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px",
                                                                        background:
                                                                          "#e8e8e8",
                                                                        alignItems:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      <div className="col-lg-3">
                                                                        <img
                                                                          src={
                                                                            File1
                                                                          }
                                                                          style={{
                                                                            height:
                                                                              "30px",
                                                                            width:
                                                                              "30px",
                                                                          }}
                                                                        ></img>
                                                                      </div>
                                                                      <div className="col-lg-9">
                                                                        {message
                                                                          .document_Path
                                                                          .length >
                                                                          85 ? (
                                                                          <>
                                                                            <div
                                                                              style={{
                                                                                opacity:
                                                                                  DName &&
                                                                                    documentname ==
                                                                                    message.id
                                                                                    ? 1
                                                                                    : 0,
                                                                                width:
                                                                                  "150px",
                                                                                height:
                                                                                  "auto",
                                                                                overflowWrap:
                                                                                  "break-word",
                                                                                zIndex:
                                                                                  "109",
                                                                                border:
                                                                                  "1px solid black",

                                                                                background:
                                                                                  "#0000007d",
                                                                                color:
                                                                                  "white",
                                                                                alignItems:
                                                                                  "center",
                                                                                position:
                                                                                  "absolute",
                                                                                top: "-50px",
                                                                                left: "-50px",
                                                                              }}
                                                                            >
                                                                              <p
                                                                                style={{
                                                                                  fontSize:
                                                                                    "10px",
                                                                                }}
                                                                              >
                                                                                {
                                                                                  result1
                                                                                }
                                                                              </p>
                                                                            </div>
                                                                            <p
                                                                              style={{
                                                                                marginTop:
                                                                                  "10px",
                                                                                textAlign:
                                                                                  "center",
                                                                                fontSize:
                                                                                  "10px",
                                                                                fontWeight:
                                                                                  "600",
                                                                                color:
                                                                                  "#19578b",
                                                                              }}
                                                                              onMouseOver={(
                                                                                e
                                                                              ) => {
                                                                                e.preventDefault();
                                                                                this.documentName(
                                                                                  message.id
                                                                                );
                                                                              }}
                                                                              onMouseLeave={
                                                                                this
                                                                                  .documentNameL
                                                                              }
                                                                            >
                                                                              {
                                                                                result
                                                                              }
                                                                              ...
                                                                            </p>
                                                                          </>
                                                                        ) : (
                                                                          <p
                                                                            style={{
                                                                              marginTop:
                                                                                "10px",
                                                                              textAlign:
                                                                                "center",
                                                                              fontSize:
                                                                                "10px",
                                                                              fontWeight:
                                                                                "600",
                                                                              color:
                                                                                "#014c8c",
                                                                            }}
                                                                          >
                                                                            {
                                                                              result
                                                                            }
                                                                          </p>
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  </a>
                                                                  {isSender &&
                                                                    message.seen ==
                                                                    1 ? (
                                                                    <img
                                                                      src={
                                                                        doubletick
                                                                      }
                                                                      style={{
                                                                        // marginTop: "50px",
                                                                        marginLeft:
                                                                          "-2px",
                                                                        width:
                                                                          "20px",
                                                                        float:
                                                                          "right",
                                                                      }}
                                                                      title="Seen"
                                                                    />
                                                                  ) : null}
                                                                  {isSender &&
                                                                    message.seen ==
                                                                    0 ? (
                                                                    <img
                                                                      src={
                                                                        singletick
                                                                      }
                                                                      style={{
                                                                        // marginTop: "50px",
                                                                        marginLeft:
                                                                          "-2px",
                                                                        width:
                                                                          "20px",
                                                                        float:
                                                                          "right",
                                                                      }}
                                                                      title="Unread"
                                                                    />
                                                                  ) : null}
                                                                  <br />
                                                                </div>
                                                              ) : (
                                                                <div>
                                                                  <p
                                                                    className="message-content"
                                                                    name="Popmsg"
                                                                  
                                                                  >
                                                                    {message.message !=
                                                                      "-" ? (
                                                                      <>
                                                                        {
                                                                          message.message
                                                                        }
                                                                      </>
                                                                    ) : null}
                                                                  </p>
                                                                  <br />
                                                                  <a
                                                                    href={
                                                                      message.picture_Path
                                                                    }
                                                                    name="picture"
                                                                    readOnly
                                                                    value={
                                                                      message.picture_Path
                                                                    }
                                                                    title=""
                                                                    target="_blank"
                                                                    style={{
                                                                      width:
                                                                        "120px",
                                                                      height:
                                                                        "200px",
                                                                    }}
                                                                  >
                                                                    <div>
                                                                      {message.picture_Path.includes(
                                                                        ".mp4"
                                                                      ) ? (
                                                                        <>
                                                                          {" "}
                                                                          <img
                                                                            alt="Video"
                                                                            width="30"
                                                                            height="30"
                                                                            src={
                                                                              video
                                                                            }
                                                                            title="Click to play video"
                                                                          
                                                                          />{" "}
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "#000",
                                                                            }}
                                                                          >
                                                                            Video
                                                                            File
                                                                          </span>{" "}
                                                                        </>
                                                                      ) : (
                                                                        <img
                                                                          alt="chatimg"
                                                                          width="80"
                                                                          height="80"
                                                                          src={
                                                                            message.picture_Path
                                                                          }
                                                                      
                                                                        />
                                                                      )}
                                                                    </div>
                                                                  </a>
                                                                  {isSender &&
                                                                    message.seen ==
                                                                    1 ? (
                                                                    <img
                                                                      src={
                                                                        doubletick
                                                                      }
                                                                      style={{
                                                                        // marginTop: "50px",
                                                                        marginLeft:
                                                                          "-2px",
                                                                        width:
                                                                          "20px",
                                                                        float:
                                                                          "right",
                                                                      }}
                                                                      title="Seen"
                                                                    />
                                                                  ) : null}
                                                                  {isSender &&
                                                                    message.seen ==
                                                                    0 ? (
                                                                    <img
                                                                      src={
                                                                        singletick
                                                                      }
                                                                      style={{
                                                                        // marginTop: "50px",
                                                                        marginLeft:
                                                                          "-2px",
                                                                        width:
                                                                          "20px",
                                                                        float:
                                                                          "right",
                                                                      }}
                                                                      title="Unread"
                                                                    />
                                                                  ) : null}
                                                                  <br />
                                                                </div>
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                      <br />
                                                      <div className="message-timestamp-right">
                                                       
                                                        <span
                                                          style={{
                                                            color: "gray",
                                                          }}
                                                        >
                                                          <i className="fa fa-clock-o"></i>
                                                          &nbsp;
                                                          {this.formatDateAA(
                                                            message.createDateTime
                                                          )}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </>
                                              )}
                                            </>
                                          );
                                        })}
                                        <div ref={this.myRef} />
                                      </div>
                                    </div>
                                  </div>

                                  <input
                                    type="text"
                                    className="form-control input-value"
                                    placeholder="Type a Message"
                                    value={this.state.message}
                                    onChange={this.handleTextChange}
                                    name="Message"
                                    style={{ border: "none", display: "none" }}
                                    autocomplete="off"
                                  />
                                </form>
                              </div>
                            )}
                            {Minimized ? null : (
                              <div className="ICfooter">
                                <div
                                  className="input-group write-msg"
                                  id="input-group write-msg"
                                  style={{
                                    border: "1px solid #bfbfbe",
                                    padding: "2px 2px",

                                    backgroundColor: "white",
                                    width: "101.5%",
                                    marginBottom: "-8px",
                                  }}
                                >
                                  <div
                                    style={attachStyles}
                                    id="IndividualAttach"
                                  >
                                    <ul id="listmenu">
                                      <label style={{ marginTop: "8px" }}>
                                        <span
                                          className="fa fa-picture-o"
                                          aria-hidden="true"
                                          style={{
                                            color: "#5d4fd0",
                                            marginLeft: "7px",
                                            cursor: "pointer",
                                          }}
                                          onClick={this.Picturepop}
                                        ></span>
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <br />
                                      <label
                                        style={{
                                          marginTop: "8px",
                                          marginLeft: "23%",
                                        }}
                                      >
                                        <span
                                          className="fa fa-file"
                                          aria-hidden="true"
                                          style={{
                                            color: "#5d4fd0",
                                            cursor: "pointer",
                                          }}
                                          onClick={this.Documentpop}
                                        ></span>
                                      </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                    </ul>
                                  </div>
                                  <input
                                    type="text"
                                    className="form-control input-value"
                                    placeholder="Type a Message"
                                    value={this.state.message}
                                    onChange={this.handleTextChange}
                                    name="Message"
                                    style={{ border: "none" }}
                                    onKeyPress={this.handleKeyPressUser}
                                    autocomplete="off"
                                  />
                                  <input
                                    type="file"
                                    id="file"
                                    name="Picture_Path"
                                    accept="image/*"
                                    // onChange={this.handleInputChange}
                                    // onChange={this.handleImageUpload}
                                    onChange={this.handleFileChange}
                                    onFocus={(e) => (e.target.type = "file")}
                                    style={{ display: "none" }}
                                  />
                                  <span
                                    className="fa fa-smile-o"
                                    aria-hidden="true"
                                    style={{
                                      marginTop: "10px",
                                      fontSize: "18px",
                                    }}
                                    onClick={this.myEmoji}
                                  ></span>
                                  &nbsp;&nbsp;
                                  {showEmoji && (
                                    <div
                                     
                                      id="indiEmoji"
                                    >
                                      <Picker
                                        onEmojiClick={this.handleEmojiClick}
                                        height={380}
                                        width={300}
                                      />
                                    </div>
                                  )}
                                  &nbsp;&nbsp;
                                  <span style={{ backgroundColor: "white" }}>
                                    <label style={{ marginTop: "8px" }}>
                                      <i
                                        className="fa fa-paperclip"
                                        style={{ fontSize: "20px" }}
                                        aria-hidden="true"
                                        onClick={this.attachMenu}
                                        id="AttachIcon"
                                      ></i>
                                    </label>
                                  </span>
                                  &nbsp;&nbsp;
                                  <span className="input-group-btn">
                                    <button
                                      type="submit"
                                      className="btn btn-secondary"
                                      onClick={this.handleSubmitUser}
                                    >
                                      <i className="icofont icofont-paper-plane"></i>
                                    </button>
                                  </span>
                                </div>

                                <div
                                  className="input-group write-msg"
                                  id="input-groupEdit"
                                  style={{
                                    border: "1px solid #bfbfbe",
                                    padding: "2px 2px",

                                    width: "101.5%",
                                    marginBottom: "-8px",
                                    backgroundColor: "white",
                                  }}
                                >
                                  <input
                                    type="text"
                                    className="form-control input-value"
                                    placeholder="Type a Message"
                                    value={this.state.deletemsg}
                                    onChange={this.handleTextChange}
                                    name="Message"
                                    style={{ border: "none" }}
                                    onKeyPress={this.handleKeyPressUser}
                                    autocomplete="off"
                                  />
                                  <input
                                    type="file"
                                    id="file"
                                    name="Picture_Path"
                                    accept="image/*"
                                    onChange={this.handleFileChange}
                                    onFocus={(e) => (e.target.type = "file")}
                                    style={{ display: "none" }}
                                  />
                                  <span
                                    className="fa fa-smile-o"
                                    aria-hidden="true"
                                    style={{
                                      marginTop: "10px",
                                      fontSize: "18px",
                                    }}
                                    onClick={this.myEmoji}
                                  ></span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  
                                  {showEmoji && (
                                    <div
                                      style={{
                                        position: "absolute",

                                        top: "-200px",
                                        transform: "translate(-50%, -50%)",
                                        maxWidth: "90vw",
                                        maxHeight: "90vh",
                                      }}
                                      id="Pickeroneoneone"
                                    >
                                      <Picker
                                        onEmojiClick={this.handleEmojiClick}
                                        height={380}
                                        width={"100%"}
                                      />
                                    </div>
                                  )}
                                  <span className="input-group-btn">
                                    <span
                                      id="paper-btn"
                                      className="btn btn-secondary"
                                    // type="submit"
                                    >
                                      <i
                                        className="fa fa-check"
                                        onClick={this.EditOneonOneMessage}
                                      ></i>{" "}
                                      &nbsp;&nbsp;{" "}
                                      <i
                                        className="fa fa-times"
                                        onClick={this.CancelEdit}
                                      ></i>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                )}

                <div id="styleSelector"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Deletepop */}

        {Deletepop ? (
          <div id="oneononeDelpop" style={deleteStyles} ref={this.containerRef}>

            <i
              className="fa fa-times"
              aria-hidden="true"
              style={{
                color: "gray",
                marginLeft: "90%",
                fontSize: "17px",
              }}
              onClick={this.handleClose}
            ></i>
            <center>
              <span>
                <img
                  src="https://static.thenounproject.com/png/659539-200.png"
                  style={{ height: '25px', width: '25px' }}></img>&nbsp;
                <span >Delete Message?</span>
                <br />
              </span>
              <div style={{ display: 'inline-flex', gap: '15px', padding: '20px' }} id="spandiV">
                <span
                  id="Deletelistbtns"
                  onClick={this.DeleteMessageOne}
                  style={{ float: "right" }}
                >
                  Delete for me
                </span>


                {this.state.deletesender == UserId ? (
                  <>
                    <span
                      id="Deletelistbtns"
                      onClick={this.ICDeleteMessageEveryone}
                      style={{ float: "right" }}
                    >
                      Delete for Everyone
                    </span>
                    <br />
                  </>
                ) : null}


              </div></center>
          </div>
        ) : null}

        {/* Forwardpop */}

        {this.state.forwardpopup && (
          <>
            {Minimized ? null : (
              <div className="popup">
                <div className="popup-content">
                  <center id="DiscAddPop">
                    <div>
                      <center style={{ marginTop: '-2%' }}>
                        <h6>
                          <b>Forward To</b>
                        </h6>
                      </center>
                      <center>
                        <input
                          className="DiscSearchbox"
                          placeholder="Search"
                          onChange={this.Addmemberfilter}
                        />
                      </center>
                      <br />
                      <center>

                        <form id="ForwardMemberPopA">

                          {MemberAccess.length === 0 && NonAccess.length === 0 ?
                            <center>

                              <span
                                id="ForwardFilterErr"
                                style={{ color: "red" }}
                              >  Member Not Found</span>
                            </center> :
                            <>

                              {MemberAccess.map((Announcement) => (
                                <>
                                  {Accessed === 1 && Accessed_to === 1 ? (
                                    <div
                                      className="media userlist-box"
                                      data-id="1"
                                      data-status="online"
                                      data-username="Josephin Doe"
                                      data-toggle="tooltip"
                                      data-placement="left"
                                      style={{
                                        // padding: "5px",
                                        height: "55px",
                                      }}
                                    >
                                      <div
                                        className="checkbox-wrapper-12"
                                        id="checkboxTop"
                                      >
                                        <div className="cbx">
                                          <input
                                            type="checkbox"
                                            title="Add"
                                            className="check"
                                            id="Checkboxchat"
                                            value={Announcement.email}
                                            checked={selectedUserIds.includes(
                                              Announcement.email
                                            )}
                                            onChange={
                                              this.handleUserSelectionChange
                                            }
                                          />
                                          <label for="cbx-12"></label>
                                          <svg
                                            width="15"
                                            height="14"
                                            viewbox="0 0 15 14"
                                            fill="none"
                                          >
                                            <path d="M2 8.36364L6.23077 12L13 2"></path>
                                          </svg>
                                        </div>

                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          version="1.1"
                                        >
                                          <defs>
                                            <filter id="goo-12">
                                              <fegaussianblur
                                                in="SourceGraphic"
                                                stddeviation="4"
                                                result="blur"
                                              ></fegaussianblur>
                                              <fecolormatrix
                                                in="blur"
                                                mode="matrix"
                                                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                                                result="goo-12"
                                              ></fecolormatrix>
                                              <feblend
                                                in="SourceGraphic"
                                                in2="goo-12"
                                              ></feblend>
                                            </filter>
                                          </defs>
                                        </svg>
                                      </div>
                                      &nbsp;&nbsp;&nbsp;
                                      <a
                                        className="media-left"
                                        style={{ marginTop: "-4%" }}
                                      >
                                        <img
                                          className="media-object img-circle"
                                          src={Announcement.profile_Picture}
                                          alt="Generic placeholder image"
                                          style={{
                                            marginTop: "20px",
                                            width: "35px",
                                            height: "35px",
                                          }}
                                        />
                                        {Announcement.userActiveStatus === 1 ? (
                                          <img
                                            src={onlineimg}
                                            className="media-object img-circle"
                                            style={{
                                              marginTop: "50px",
                                              marginLeft: "-12px",
                                              width: "12px",
                                              // position: "absolute",
                                              background: "white",
                                            }}
                                            title="Online"
                                          />
                                        ) : (
                                          <img
                                            src={offlineimg}
                                            className="media-object img-circle"
                                            style={{
                                              marginTop: "50px",
                                              marginLeft: "-12px",
                                              width: "12px",
                                              // position: "absolute",
                                              background: "white",
                                            }}
                                            title={`last seen at ${Announcement.logout}`}
                                          />
                                        )}
                                      </a>
                                      <div
                                        className="media-body"
                                        style={{
                                          textAlign: "initial",
                                        }}
                                      >
                                        <div
                                          className="f-13 chat-header"
                                          style={{
                                            color: "black",
                                          }}
                                        >
                                          {" "}
                                          <li
                                            key={Announcement.id}
                                            value={Announcement.id}
                                            style={{ listStyleType: "none" }}
                                          >
                                            <label style={{ marginTop: '-2%' }}>
                                              <span
                                                className="Fontsize_In_chat"
                                                style={{
                                                  color: "black",
                                                  whiteSpace: "nowrap",
                                                  fontWeight: "bold",
                                                }}
                                              ></span>
                                              <br />
                                              <span
                                                className="Fontsize_In_chat"
                                                style={{
                                                  color: "black",
                                                  whiteSpace: "nowrap",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {Announcement.firstName}
                                              </span>
                                              &nbsp;
                                              <span
                                                className="Fontsize_In_chat"
                                                style={{
                                                  color: "Gray",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                ({Announcement.email})
                                              </span>
                                            </label>
                                          </li>
                                        </div>
                                      </div>
                                      <input hidden type="text" name="User_Id" />
                                    </div>
                                  ) : null}
                                </>
                              ))}
                              {NonAccess.map((Announcement) => (
                                <>
                                  {(Accessed === 1 && Accessed_to === 0) ||
                                    (Accessed === 0 && Accessed_to === 0) ||
                                    (Accessed === 0 && Accessed_to === 1) ? (
                                    <div
                                      className="media userlist-box"
                                      data-id="1"
                                      data-status="online"
                                      data-username="Josephin Doe"
                                      data-toggle="tooltip"
                                      data-placement="left"
                                      style={{
                                        // padding: "5px",
                                        height: "55px",
                                      }}
                                    >
                                      <div
                                        className="checkbox-wrapper-12"
                                        // style={{ marginTop: "3%" }}
                                        id="checkboxTop"
                                      >
                                        <div className="cbx">
                                          <input
                                            type="checkbox"
                                            title="Add"
                                            className="check"
                                            id="Checkboxchat"
                                            value={Announcement.email}
                                            checked={selectedUserIds.includes(
                                              Announcement.email
                                            )}
                                            onChange={
                                              this.handleUserSelectionChange
                                            }
                                          />
                                          <label for="cbx-12"></label>
                                          <svg
                                            width="15"
                                            height="14"
                                            viewbox="0 0 15 14"
                                            fill="none"
                                          >
                                            <path d="M2 8.36364L6.23077 12L13 2"></path>
                                          </svg>
                                        </div>

                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          version="1.1"
                                        >
                                          <defs>
                                            <filter id="goo-12">
                                              <fegaussianblur
                                                in="SourceGraphic"
                                                stddeviation="4"
                                                result="blur"
                                              ></fegaussianblur>
                                              <fecolormatrix
                                                in="blur"
                                                mode="matrix"
                                                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 22 -7"
                                                result="goo-12"
                                              ></fecolormatrix>
                                              <feblend
                                                in="SourceGraphic"
                                                in2="goo-12"
                                              ></feblend>
                                            </filter>
                                          </defs>
                                        </svg>
                                      </div>
                                      &nbsp;&nbsp;&nbsp;
                                      <a
                                        className="media-left"
                                        style={{ marginTop: "-4%" }}
                                      >
                                        <img
                                          className="media-object img-circle"
                                          src={Announcement.profile_Picture}
                                          alt="Generic placeholder image"
                                          style={{
                                            marginTop: "20px",
                                            width: "35px",
                                            height: "35px",
                                          }}
                                        />
                                        {Announcement.userActiveStatus === 1 ? (
                                          <img
                                            src={onlineimg}
                                            className="media-object img-circle"
                                            style={{
                                              marginTop: "50px",
                                              marginLeft: "-12px",
                                              width: "12px",
                                              // position: "absolute",
                                              background: "white",
                                            }}
                                            title="Online"
                                          />
                                        ) : (
                                          <img
                                            src={offlineimg}
                                            className="media-object img-circle"
                                            style={{
                                              marginTop: "50px",
                                              marginLeft: "-12px",
                                              width: "12px",
                                              // position: "absolute",
                                              background: "white",
                                            }}
                                            title={`last seen at ${Announcement.logout}`}
                                          />
                                        )}
                                      </a>
                                      <div
                                        className="media-body"
                                        style={{
                                          textAlign: "initial",
                                        }}
                                      >
                                        <div
                                          className="f-13 chat-header"
                                          style={{
                                            color: "black",
                                          }}
                                        >
                                          {" "}
                                          <li
                                            key={Announcement.id}
                                            value={Announcement.id}
                                            style={{ listStyleType: "none" }}
                                          >
                                            <label style={{ marginTop: '-2%' }}>
                                              <span
                                                className="Fontsize_In_chat"
                                                style={{
                                                  color: "black",
                                                  whiteSpace: "nowrap",
                                                  fontWeight: "bold",
                                                }}
                                              ></span>
                                              <br />
                                              <span
                                                className="Fontsize_In_chat"
                                                style={{
                                                  color: "black",
                                                  whiteSpace: "nowrap",
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {Announcement.firstName}
                                              </span>
                                              &nbsp;
                                              <span
                                                className="Fontsize_In_chat"
                                                style={{
                                                  color: "Gray",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                ({Announcement.email})
                                              </span>
                                            </label>
                                          </li>
                                        </div>
                                      </div>
                                      <input hidden type="text" name="User_Id" />
                                    </div>
                                  ) : null}
                                </>
                              ))}
                            </>}
                        </form>
                      </center>  <br />
                      <center>

                        <span
                          className="AddmemberpopFrdbtn"
                          id="addbutton"
                          disabled="disabled"
                          onClick={this.ForwardIChatMsg}
                        >
                          Forward
                        </span>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                        <span
                          className="Addmemberpopbtns"
                          id="Cancelbutton"
                          onClick={this.hideForwardpop}
                        >
                          Cancel
                        </span>
                      </center>
                    </div>
                  </center>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}
