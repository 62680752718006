import React, { Component } from "react";
import AddLocationModel from "../../Common/AddLocationModel";
import EditAdminLocationModel from "../../Common/EditAdminLocationModel";
import SideBar from "../../Common/Sidebar";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Officelocationxl from "../../Assets/Files/OfficeLocationxl.xlsx";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import CloseIcon from "@material-ui/icons/Close";
import { OutTable } from "react-excel-renderer";
import { BASE_URLs } from "../../Config/Base"
import DataTable from 'react-data-table-component';
import "../../Css/excel.css";
var XLSX = require("xlsx");
export default class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      DepartmentName: "",
      SkillModel: false,
      EditModel: false,
      comlogo: [],
      Location: [],
      formErrors: {},
      Notification: [],
      letters: [],
      Filterpop: false,
      EditLocationValues: [],
      Loc: [],
      rows: [],
      cols: [],
      FilterData:"",
      showExcel: false,
      showSelectAll: false,
      showDelete: false,
      showDeleteCount: false,
      selectAllChecked: false,
    };
    // this.handleSubmit=this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  exportXlsx(filename) {
   
    var table_elt = document.getElementById("LocationExport");
    
    var workbook = XLSX.utils.table_to_book(table_elt);
  
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }

  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      // for (const [key, value] of data.entries()) {
      //   // console.log(`Field name: ${key}, Field value: ${value}`);
      // }
      fetch(`${BASE_URLs}AdminLocation/LocationExcel`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp === undefined) {
            alert("0  Records  inserted ");
          }
          if (resp === 0) {
            alert("Records added Successfully");
          } else {
            alert(+resp + " Records not inserted ");
          }
          this.GetDesignation();
          this.setState({ initialData: undefined });
        });
      var file = document.getElementById("files");
      var emptyFile = document.createElement("input");
      emptyFile.type = "file";
      file.files = emptyFile.files;
    } catch (error) {}
  };

  handleUpload = (event) => {
    this.setState({ showExcel: true });

    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document.getElementById("delete").setAttribute("style", "visibility: hidden;");
        document.getElementById("deletecount").setAttribute("style", "visibility: hidden;");
      }
    }

    // Get the uploaded file
    var inputElement = document.getElementById("files");
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf("."));
    var allowedExtensionsRegx = /(\.xlsx|\.xlsm)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);

    // Check if the file is allowed
    if (isAllowed) {
      // Read the file using FileReader
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        // Check if the parsed data is empty
        if (parsedData.length === 0) {
          toast.error("The uploaded Excel file is empty.",{ autoClose: 3200 });
          this.setState({ initialData: [], showExcel: false });
        } else {
          this.setState({ initialData: parsedData });
        }
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsBinaryString(files[0]);

      return true; // Indicate successful file upload
    } else {
      alert("Invalid File Type.");
      return false; // Indicate failed file upload
    }
  };

  cancel = () => {
    var inputElement = document.getElementById("files");
    inputElement.value = "";

    this.setState({ showExcel: false });
  };


  GetDesignation() {
    let url = `${BASE_URLs}AdminLocation/GetLocation`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("LocationView",data)
        this.setState({
          Location: data,
          Loc: data,
        });
      });
  }

  onKeyUp = (e) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
    const Location = this.state.Loc.filter(
      (item) =>
        item.address.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.branch.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.locationType.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Location });
  };

  handleDesignationFilter = (letter, event) => {
    event.preventDefault();
    const Location = this.state.Loc.filter((item) => {
      return (
        item.address.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.branch.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.locationType.toLowerCase().includes(letter.toLowerCase())
      );
    });
    this.setState({ Location, showResults: true, FilterData: Location });
  };

  SkillModel = () => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState((prevState) => ({
      SkillModel: !prevState.SkillModel,
    }));

    this.GetDesignation();
  };

  EditModel = (id, address, type, branch) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditLocationValues: {
        id: id,
        address: address,
        addresstype: type,
        Branch: branch,
      },
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));
    this.GetDesignation();
  };

  checkAll(e) {
    var checkboxes = document.getElementsByName("Id_delete");

    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;margin-top: 30px;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top: 30px;"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + "  selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  handlecheckbox = (state) => {
    const selectedRows = state.selectedRows;

    this.setState({ SelectedNames: selectedRows });
    this.setState({
      showSelectAll: selectedRows.length >= 2,
      showDelete: selectedRows.length >= 1,
      showDeleteCount: selectedRows.length > 0,
    });
  };

  // handlecheckbox() {
  //   let checkboxes = document.querySelectorAll('#tablepadding input[type="checkbox"]:checked');
  //   let Total = document.querySelectorAll('input[type="checkbox"]');
    
  //   // selectalldiv
  //   if (checkboxes.length >= 2) {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:visible ;");
  //   } else {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:hidden ;");
  //   }
  //   if (checkboxes.length >= 1) {
  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility:visible ;margin-top: 30px;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute(
  //         "style",
  //         "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top: 30px;"
  //       );
  //     var msg = document.getElementById("deletecount");
  //     let count = checkboxes.length - 1;

  //     if (document.getElementById("tablepadding").checked) {
  //       msg.innerHTML = "<span> " + count + " selected </span> ";
  //     } else {
  //       msg.innerHTML = "<span> " + checkboxes.length + " selected </span> ";
  //     }
  //   } else {
  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility: hidden;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute("style", "visibility: hidden;");
  //   }
  //   if (document.getElementById("selectall").checked === false) {
  //     if (checkboxes.length - 1 === Total.length ) {
  //       //
  //       document.getElementById("selectall").checked = true;
  //     }
  //   } else {
  //     if (document.getElementById("selectall").checked) {
  //       if (checkboxes.length - 2 !== Total.length) {
  //         document.getElementById("selectall").checked = false;
  //       }
  //     }
  //   }
  // }

  componentDidMount() {
    this.GetOrgLogo();
    this.GetDesignation();
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleFormValidation() {
    const {
      FirstName,
      LastName,
      Email,
      Mobile,
      Department,
      Designation,
      RoleName,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!FirstName) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "First Name is required";
    }
    if (!LastName) {
      formIsValid = false;
      formErrors["LastNameErr"] = "Last Name is required";
    }
    if (!Email) {
      formIsValid = false;
      formErrors["EmailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
      formErrors["EmailErr"] = "Invalid Email id";
    }
    if (!Mobile) {
      formIsValid = false;
      formErrors["MobileErr"] = "Mobile is required";
    }
    // else if (!Mobile >= 10) {
    //   formIsValid = false;
    //   formErrors["MobileErr"] = "Invalid Mobile number";
    // }
    else {
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
      if (!mobPattern.test(Mobile)) {
        formIsValid = false;
        formErrors["MobileErr"] = "Invalid phone number";
      }
    }
    if (!Department) {
      formIsValid = false;
      formErrors["departmentErr"] = "department is required";
    }
    if (!Designation) {
      formIsValid = false;
      formErrors["designationErr"] = "designation is required";
    }
    if (!RoleName) {
      formIsValid = false;
      formErrors["rolesErr"] = "Role is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  Multidelete = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    this.state.SelectedNames.forEach((row) => {
      data.append("Id_delete", row.id);
    });

    // console.log("FormData:", data);
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}AdminLocation/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.setState({ initialData: undefined });
          this.GetDesignation();
          alert(resp.message);
        });

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
    this.GetDesignation();
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  DeleteButton = (id) => {
    const data = new FormData();
    data.append("Id_delete", id);
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}AdminLocation/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetDesignation();
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      //check.checked = false;
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
  };

  handleSelectAllChange = (event) => {
    const { checked } = event.target;
    this.setState({ selectAllChecked: checked });
  };
  render() {
    var username = JSON.parse(localStorage.uname);
    const { Location, Filterpop, FilterData,showExcel, filterValue } = this.state;
    var filename = `O_${username}_Location`;
    const customStyles = {
      header: {
        style: {
          fontWeight: 'bold',
          fontSize: "14px",
          flex: "0"  
        },
      },
      headCells: {
        style: {
          fontWeight: 'bold',
          fontSize: "14px",
          flex: "0" ,
          borderBottom: "1px solid #ccc",
          
        },
      },
   
      columns: {
        idHeader: {
          style: {
            fontWeight: 'bold',
            fontSize: "14px",
            flex: "0" 
          },
        },
      },
    };
    const columns = [
      {
        name:'Address',
        selector: row => row.address,
        id: "isheader",
        grow: 3.5,
        sortable: false, 
      },
      {
        name:'Branch',
        selector: row => row.branch,
        id: "isheader",
        grow: 3.5,
        sortable: false, 
      },
      {
        name:'Location',
        selector: row => row.locationType,
        id: "isheader",
        grow: 3.5,
        sortable: false, 
      },
     
      {
        name: 'Actions',
        grow: 0,
        cell: row => (
          <div>
            <button
              className="button-30 sk-del"
              onClick={(e) => {
                e.preventDefault();
                this.DeleteButton(row.id);
              }}
              title="Delete"
              style={{ marginLeft: "-10px" }}
            >
              <i className="fa fa-minus" aria-hidden="true"></i>
            </button>
            &nbsp;&nbsp;
            {" "}
            <button
              className="Annbuttons sk-edit"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.EditModel(
                  row.id,
                  row.address,
                  row.type,
                  row.branch,
                  
                );
              }}
              title="Edit"
            >
              <i className="fa fa-edit" aria-hidden="true"></i>
            </button>
          </div>
        )
      }
    ];
    return (
      <div>
        <SideBar active={window.location.hash} />
        {this.state.SkillModel ? (
          <AddLocationModel close={() => this.SkillModel()} />
        ) : null}
        {this.state.EditModel ? (
          <EditAdminLocationModel
            values={this.state.EditLocationValues}
            close={() => this.EditModel("")}
          />
        ) : null}

        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <Link
                              to
                              onClick={() => this.props.history.goBack()}
                            >
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </Link>{" "}
                          </div>
                          <h4 style={{ marginRight: "4%" }} className="title">
                            Office Locations{" "}
                          </h4>
                        </div>

                        <div className="card-block">
                        <form onSubmit={this.handleSubmit}>
                          <div className="row">
                            <div className="col btnalign">
                              <h5 className="card-header-text">
                                <Link
                                  onClick={this.SkillModel}
                                  className="btn"
                                  style={{ width: "150px" }}
                                  onKeyPress={this.handleKeyPress}
                                >
                                  Add
                                </Link>
                              </h5>{" "}
                            </div>
                            <div className="col btnalign">
                                <label
                                  htmlFor="files"
                                  className="btn"
                                  id="addBtn"
                                  name="Locations"
                                >
                                  Import From Excel
                                </label>
                                <input
                                  id="files"
                                  type="file"
                                  accept=".xlsx"
                                  style={{ display: "none" }}
                                  onChange={this.handleUpload}
                                  name="Locations"
                                />
                              </div>
                              <div style={{padding: "7px"}} ></div>
                            <div className="col btnalign">
                              <button
                                className="btn"
                                type="button"
                                onClick={() => this.exportXlsx(filename)}
                                onKeyPress={this.handleKeyPress}
                              >
                                Export to excel
                              </button>
                              {/* <ReactHTMLTableToExcel                  
           className="btn"  
           table="LocSheet"  
           filename={filename}
           sheet="Sheet"  
           buttonText="Export to Excel " id="exportxlUG" />  */}
                            </div>
                            <div
                              className="col btnalign"
                              onKeyDown={this.handleKeyPress}
                            >
                              <a
                                className="btn"
                                href={Officelocationxl}
                                type="button"
                                style={{ color: "#5035ce" }}
                              >
                                <i
                                  title="Download Template"
                                  className="icofont icofont-download"
                                ></i>{" "}
                                Download a template
                              </a>
                            </div>
                              <OutTable
                              data={this.state.rows}
                              columns={this.state.cols}
                              tableClassName="ExcelTable2007"
                              tableHeaderRowclassName="heading"
                            />
                            <div
                              className="col-lg-1"
                              style={{
                                height: "73px",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              {/* Just for design */}
                            </div>
                           

                            {/* <div className="col-lg-2"></div> */}
                            {/*<div className="col-lg-2"></div> */}
                            <div >
                              {/* {this.state.Loc.length === 0 ? null : ( */}
                                <input
                                  onKeyUp={this.onKeyUp}
                                  className="Searchbox"
                                  id="CNSearch"
                                  placeholder="Search"
                                  onClick={() => {
                                    this.setState({
                                      // Filterpop: true,
                                      Filterpop: !this.state.Filterpop,
                                    });
                                  }}
                                  readOnly
                                />
                              {/* )} */}
                            </div>
                          </div>
                          <div
                              >
                                <input
                                  className="Searchbox"
                                  id="CNSearch1"
                                  placeholder="Search"
                                  onKeyPress={this.handleKeyPress}
                                  onKeyUp={this.myFunction}
                                  value={filterValue}
                                  onClick={() => {
                                    this.setState({
                                      Filterpop: !this.state.Filterpop,
                                    });
                                  }}
                                  readOnly
                                />
                              </div>
                          <center>
                          {showExcel && this.state.initialData !== undefined && (
                              <table className="table" id="tableDesign">
                              <thead >
                                <tr>
                                  {Object.keys(this.state.initialData[0]).map((key) => (
                                    <th key={key} id="team1design">{key}</th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.initialData.map((row, index) => (
                                  <tr key={index}>
                                    {Object.values(row).map((value, index) => (
                                      <td key={index} id="teamdesign">{value}</td>
                                    ))}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            )}
                            </center>
                            
                            {showExcel && this.state.initialData !== undefined && (
                              <div className="savebtndiv">
                                <button
                                  className="btn"
                                  id=""
                                  type="submit"
                                  style={{ width: "150px" }}
                                >
                                  Save
                                </button>
                                <span
                                  className="btn"
                                  id="add-row"
                                  style={{ width: "150px" }}
                                  onClick={this.cancel}
                                >
                                  Cancel
                                </span>
                              
                              </div> )}
                              <br/>
                              </form>
                              </div>
                             
                              <div className="card-block2">
                              <form
                            onSubmit={this.Multidelete}
                            onKeyPress={this.handleKeyPress}
                            style={{ marginTop: "-28px" }}
                          >
                           <button
                              type="submit"
                              className="multitrash"
                              id="delete"
                              style={{ float: "right", visibility: this.state.showDelete ? 'visible' : 'hidden',marginTop:"30px" }}
                            >
                              <i
                                className="icofont icofont-delete-alt"
                                style={{ color: "darkgray" }}
                              ></i>
                            </button>
                            <span
                              id="deletecount"
                              className="multitrash"
                              style={{ visibility: this.state.showDeleteCount ? 'visible' : 'hidden', fontSize: "17px",marginTop:"30px" }}
                            >
                              {this.state.showDeleteCount && (
                              <span style={{backgroundColor: "#007bff",background: "linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)"}}>{this.state.SelectedNames.length} selected</span>
                               )}
                            </span>
                            <div id="selectalldiv" style={{ visibility: this.state.showSelectAll ? 'hidden' : 'hidden' }}>
                            <input type="checkbox" id="selectall" checked={this.state.selectAllChecked} onChange={this.handleSelectAllChange} />&nbsp;&nbsp;
                            </div>
                            <center>
                            <div className="dt-responsive table-responsive" style={{maxWidth:"60%"}}>
        <DataTable
          columns={columns}
          data={Location}
          selectableRows
          onSelectedRowsChange={this.handlecheckbox} // Assuming handlecheckbox is a method to handle row selection
          pagination
          customStyles={customStyles}
        />
      </div>
      </center>
                            
                            {/* <div
                              className="dt-responsive table-responsive table-res"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {this.state.Loc.length === 0 ? null : (
                                <table
                                  id="LocSheet"
                                  className="table nowrap"
                                  style={{ maxWidth: "85%" }}
                                >
                                  <thead id="tablepaddingA">
                                    <tr>
                                      <th>
                                        <input
                                          type="checkbox"
                                          id="selectall"
                                          onClick={() => {
                                            this.checkAll(this);
                                          }}
                                        />{" "}
                                      </th>
                                      <th>Address</th>
                                      <th>Branch </th>
                                      <th>Location </th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody id="tablepadding">
                                  {FilterData.length === 0 && Location.length === 0 ? (
                                      <tr>
                                        <th
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Record
                                        </th>
                                      </tr>
                                    ) : null}
                                    {Location.map((Location) => (
                                      <tr>
                                        <td>
                                          <input
                                            type="checkbox"
                                            name="Id_delete"
                                            onChange={this.handlecheckbox}
                                            defaultValue={Location.id}
                                          />
                                        </td>
                                        <td>{Location.address}</td>
                                        <td>{Location.branch}</td>
                                        <td>{Location.locationType}</td>
                                        <td>
                                        <button
                                            className="button-30 sk-del"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.DeleteButton(Location.id);
                                            }}
                                            title="Delete"
                                            style={{ marginLeft:"-15px" }}
                                          >
                                            {/* Delete&nbsp; */}
                                            {/* <i
                                              className="fa fa-minus"
                                              aria-hidden="true"
                                            ></i>
                                          </button>{" "}
                                          &nbsp;&nbsp;&nbsp;
                                          <button
                                            className="button-30 sk-edit"
                                            title="Edit"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.EditModel(
                                                Location.id,
                                                Location.address,
                                                Location.locationType,
                                                Location.branch
                                              );
                                            }}
                                          >
                                           
                                            <i
                                              className="fa fa-edit"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody> */}
                          </form>
                          </div>
                          <br/>
                          <br/>
                          <table
                              id="LocationExport"
                              className="LocationExport"
                              hidden
                            >
                              <thead>
                                <tr>
                                  <th>Address</th>
                                  <th>Branch</th>
                                  <th>Location</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Location.map((Location) => (
                                  <tr>
                                    <td>{Location.address}</td>
                                  
                                    <td>{Location.branch}</td>
                                    <td>{Location.locationType}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              {Filterpop && (
                              <div className="popSearchTeam">
                                <div className="popSearchinnernormalTeam">
                                  <div
                                    className="popSearchinnertitle"
                                    id="popSearchinnernormal"
                                  >
                                    <button
                                      id="close"
                                      style={{
                                        float: "right",
                                        backgroundColor: "#a0a3a305",
                                        color: "#797070",
                                        border: "1px solid #a3a5a505",
                                        marginTop: "-20px",
                                        marginRight: "-20px",
                                      }}
                                      onClick={() => {
                                        this.setState({ Filterpop: false });
                                        this.GetDesignation();
                                      }}
                                    >
                                      <CloseIcon />
                                    </button>

                                    <div className="checkboxes">
                                      <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "20px",
                                          color: "gray",
                                          marginLeft: "-1%",
                                          position: "absolute",
                                          marginTop: "0.5%",
                                        }}
                                      ></i>
                                      <input
                                        className="Searchbox"
                                        placeholder="Search"
                                        onKeyUp={this.onKeyUp}
                                        style={{ float: "right", width: "80%" }}
                                      />
                                      <br></br>
                                      {this.state.letters.map(
                                        (letter, index) => (
                                          <button
                                            className="filter"
                                            key={index}
                                            onClick={(event) =>
                                              this.handleDesignationFilter(
                                                letter,
                                                event
                                              )
                                            }
                                          >
                                            {letter}
                                          </button>
                                        )
                                      )}
                                      <br></br>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
              {/* End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
