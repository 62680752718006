import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddLocationModel from "../../Common/AddLocationModel";
import AddManagerPop from "../../Common/AddManagerPop";
import { MoonLoader } from "react-spinners";
import { BASE_URLs } from "../../Config/Base";
export default class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      FirstName: "",
      LastName: "",
      Email: "",
      Mobile: "",
      Department: "",
      Designation: "",
      RoleName: "",
      formErrors: {},
      Notification: [],
      Departmentdrop: [],
      Designationdrop: [],
      LocationDrop: [],
      ManagerDrop: [],
      comlogo: [],
      value: this.props.location.state,
      Details: [],
      TeamSts: "",
      BoxSts: "",
      TrainerSts1: "",
      TrainerSts2: "",
      LocationModel: false,
      ButtonDisabled: false,
      checkBox1: false,
      checkBox2: false,
      ManagerModel: false,
      Loading: false,
      ManagerNamedrop: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }

  GetMangerNameDrop(Department) {
    let url = `${BASE_URLs}Manager/GetManagerName?Department=` + Department;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          ManagerNamedrop: data,
        });
        // console.log('nameeeee', data)
      });
  }
  componentDidMount() {
    this.GetLocation();
    this.GetOrgLogo();
    this.GetDepartment();
    this.GetDesignation();
    this.GetManager();
    this.selectExperience();
    if (this.props.location.state === undefined) {
      this.props.history.push("./Team");
    } else {
      this.Getdetail();
    }
    document.getElementById("maindiv").style.display = "none";
    setTimeout(() => {
      this.setState({ isLoading: false });
      this.Getdetail();
      document.getElementById("teamloader").style.display = "none";
      document.getElementById("maindiv").style.display = "block";
    }, 200);
  }

  reformatDate = (dateString) => {
    const parts = dateString.split("-");
    if (parts.length === 3) {
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    } else {
      return dateString;
    }
  };

  formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    if (day === "NAN") {
      return `dd-mm-yyyy`;
    } else {
      return `${day}-${month}-${year}`;
    }
  };

  GetDesignation() {
    let url = `${BASE_URLs}Designation/GetMasterDesignation`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Designationdrop: data,
        });
      });
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleFormValidation() {
    //const {FirstName,LastName,Email,Mobile,Department,Designation,RoleName}= this.state;
    var TeamId = document.getElementById("TeamId").value;

    var FirstName = document.getElementById("FirstNameedit").value;
    var LastName = document.getElementById("LastNameedit").value;
    var Email = document.getElementById("Emailedit").value;
    var Mobile = document.getElementById("Mobileedit").value;
    var Department = document.getElementById("Departmentedit").value;
    var DisplayName = document.getElementById("DisplayName").value;
    var Designation = document.getElementById("Designationedit").value;
    var RoleName = document.getElementById("RoleNameedit").value;
    var Report_Manager = document.getElementById("reportedit").value;
    // var Primary_Ofc = document.getElementById("primaryedit").value;
    var Join_Date = document.getElementById("JoiningDate").value;
    // var Experience = document.getElementById("Experience").value;

    let formErrors = {};
    let formIsValid = true;

    if (!TeamId) {
      formIsValid = false;
      formErrors["TeamIdErr"] = "User Id is required";
    } else if (TeamId.length < 3) {
      formIsValid = false;
      formErrors["TeamIdErr"] = " Minimum 3  characters is required";
    }
    if (!FirstName) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "First Name is required";
    } else if (FirstName.length < 3) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "Minimum 3  characters required";
    }
    if (!DisplayName) {
      formIsValid = false;
      formErrors["DisplayNameErr"] = "Display Name is required";
    } else if (DisplayName.length < 2) {
      formIsValid = false;
      formErrors["DisplayNameErr"] = "Minimum 2  characters required";
    }
    // if (!LastName) {
    //   formIsValid = false;
    //   formErrors["LastNameErr"] = "Last Name is required" ;
    // }
    if (!Email) {
      formIsValid = false;
      formErrors["EmailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
      formErrors["EmailErr"] = "Invalid Email id.";
    }
    if (!Mobile) {
      formIsValid = false;
      formErrors["MobileErr"] = "Mobile is required";
    } else {
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
      if (!mobPattern.test(Mobile)) {
        formIsValid = false;
        formErrors["MobileErr"] = "Invalid Mobile Number.";
      }
    }
    if (!Department) {
      formIsValid = false;
      formErrors["departmentErr"] = "Department is required";
    }
    if (!Designation) {
      formIsValid = false;
      formErrors["designationErr"] = "Designation is required";
    }
    if (!RoleName) {
      formIsValid = false;
      formErrors["rolesErr"] = "Role is required";
    }
    if (document.getElementById("deactivereason")) {
      if (!document.getElementById("deactivereason").value) {
        formIsValid = false;
        formErrors["DeactivesErr"] = "Deactivate Reason is required";
      }
    }

    if (document.getElementById("terminateReason")) {
      if (!document.getElementById("terminateReason").value) {
        formIsValid = false;
        formErrors["TerminateErr"] = "Terminate Reason is required";
      }
    }
    if (document.getElementById("TerminateDate")) {
      if (!document.getElementById("TerminateDate").value) {
        formIsValid = false;
        formErrors["TerminateDateErr"] = "Terminate Date is required";
      }
    }

    if (!Report_Manager) {
      formIsValid = false;
      formErrors["ReportErr"] = "Reporting Manager  is required";
    }
    if (!Join_Date) {
      formIsValid = false;
      formErrors["JoinErr"] = "Join Date is required";
    }
    // if (!Experience) {
    //   formIsValid = false;
    //   formErrors["ExpErr"] = "Experience is required";
    // }
    // if(!Primary_Ofc) {
    //   formIsValid = false;
    //   formErrors["PrimaryErr"] = "Primary Office is required" ;
    //   }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    if (this.handleFormValidation()) {
      this.setState({
        ButtonDisabled: true,
      });
      fetch(`${BASE_URLs}Team/EditTeam`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // 'Accept': 'application/json',
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              // pauseOnHover: true,
              // draggable: true,
              // progress: undefined,
            });
            this.setState({
              ButtonDisabled: true,
            });
            setTimeout(() => {
              this.props.history.push("/Team");
            }, 4000);
            this.selectExperience();
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  }

  Getdetail() {
    var Id = this.props.location.state.Id;
    let url = `${BASE_URLs}Team/GetTeamEdit?Id=` + Id;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Details: data,
          TeamSts: data[0].status,
          TrainerSts1: data[0].yes,
          TrainerSts2: data[0].no,
        });

        // console.log("checking", data);

        if (data[0].status !== 2) {
          {
            data[0].status === 0 ? (
              this.setState({ BoxSts: 0 })
            ) : (
              <>
                {data[0].status === 3
                  ? this.setState({
                    BoxSts: 3,
                  })
                  : null}
              </>
            );
          }

          {
            data[0].status === 0
              ? (document.getElementById("Deactive").checked = true)
              : data[0].status === 1
                ? (document.getElementById("Active").checked = true)
                : (document.getElementById("Terminate").checked = true);
          }
        }
      });
  }
  GetDepartment() {
    let url = `${BASE_URLs}Department/DepartmentGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Departmentdrop: data,
        });
      });
  }
  GetManager() {
    let url = `${BASE_URLs}Manager/ManagerGet`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          ManagerDrop: data,
        });
        // console.log("Manager", data)
      });
  }
  GetLocation() {
    let url = `${BASE_URLs}AdminLocation/GetLocation`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          LocationDrop: data,
        });
      });
  }

  Handledepartment() {
    var department = document.getElementById("department").value;

    if (department === "others") {
      document.getElementById("departmenttext").value = "";
      document.getElementById("departmenttext").type = "text";
    } else {
      document.getElementById("departmenttext").value = "None";
      document.getElementById("departmenttext").type = "hidden";
    }
  }
  Handledesignation() {
    var department = document.getElementById("Designation").value;

    if (department === "others") {
      document.getElementById("designationtext").value = "";
      document.getElementById("designationtext").type = "text";
    } else {
      document.getElementById("designationtext").value = "None";
      document.getElementById("designationtext").type = "hidden";
    }
  }
  Handleroles() {
    var department = document.getElementById("roles").value;

    if (department === "others") {
      document.getElementById("roletext").value = "";
      document.getElementById("roletext").type = "text";
    } else {
      document.getElementById("roletext").value = "None";
      document.getElementById("roletext").type = "hidden";
    }
  }

  handleTerminate = () => {
    if (document.getElementById("Deactive")) {
      document.getElementById("Deactive").checked = false;
    }
    if (document.getElementById("Active")) {
      document.getElementById("Active").checked = false;
    }
    if (document.getElementById("Terminate").checked) {
      this.setState({
        BoxSts: 3,
      });
      if (window.confirm("Are you sure you want to change to Terminate?")) {

      } else {
        document.getElementById("Terminate").checked = false;

        this.setState({
          BoxSts: this.state.TeamSts,
        });
        if (this.state.TeamSts === 0) {
          document.getElementById("Deactive").checked = true;
        } else if (this.state.TeamSts === 1) {
          document.getElementById("Active").checked = true;
        } else if (this.state.TeamSts === 3) {
          document.getElementById("Terminate").checked = true;

        }
        return;
      }

    } else {
      this.setState({
        BoxSts: 4,
      });
      this.Getdetail();
    }
  };
  handleDeactive = () => {
    if (document.getElementById("Deactive").checked) {
      this.setState({
        BoxSts: 0,
      });

      if (window.confirm("Are you sure you want to change to Inactive?")) {
      } else {
        document.getElementById("Deactive").checked = false;
        if (this.state.TeamSts === 0) {
          document.getElementById("Deactive").checked = true;
          document.getElementById('deactivereasonInput').style.display = 'block'
        } else if (this.state.TeamSts === 1) {
          document.getElementById("Active").checked = true;
        } else if (this.state.TeamSts === 3) {
          document.getElementById("Terminate").checked = true;
        }
        this.setState({
          BoxSts: this.state.TeamSts,
        });
        return;
      }
    } else {
      this.setState({
        BoxSts: 4,
      });
      this.Getdetail();
    }
    document.getElementById("Terminate").checked = false;
    document.getElementById("Active").checked = false;

  };
  SelectLocation = (e) => {
    let dept = document.getElementById("Locations").value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        LocationModel: !prevState.LocationModel,
      }));
      document.getElementById("Locations").value = "";
    }
  };
  handleActive = () => {
    document.getElementById("Deactive").checked = false;
    document.getElementById("Terminate").checked = false;

    if (document.getElementById("Active").checked) {
      this.setState({
        BoxSts: "",
      });
      if (window.confirm("Are you sure you want to change to active?")) {
      } else {
        document.getElementById("Active").checked = false;
        this.setState({
          BoxSts: this.state.TeamSts,
        });
        if (this.state.TeamSts === 0) {
          document.getElementById("Deactive").checked = true;
        } else if (this.state.TeamSts === 1) {
          document.getElementById("Active").checked = true;
        } else if (this.state.TeamSts === 3) {
          document.getElementById("Terminate").checked = true;
        }
      }
    }
  };

  LocationModel = () => {
    this.setState((prevState) => ({
      LocationModel: !prevState.LocationModel,
    }));

    this.GetLocation();
  };

  selectExperience() {
    this.setState((prevState) => ({
      Experience: !prevState.Experience,
    }));
  }

  handleCheckBox1Change = () => {
    // this.setState({
    //   checkBox1:true,
    //   //  checkBox2:false,

    // })

    // document.getElementById("Yes").checked = true
    // document.getElementById("No").checked = false

    this.setState({
      TrainerSts1: "0",
      TrainerSts2: "1",
      checkBox1: true,
      checkBox2: false,
    });
  };

  ManagerModel = () => {
    const departmentEditValue = document.getElementById('Departmentedit').value;
    this.setState((prevState) => ({
      ManagerModel: !prevState.ManagerModel,
    }));
    this.GetMangerNameDrop(departmentEditValue)

  };

  SelectManager = (e) => {
    let dept = document.getElementById("reportedit").value;
    const departmentEditValue = document.getElementById('Departmentedit').value;
    if (dept.toLowerCase() === "add") {
      this.setState((prevState) => ({
        ManagerModel: !prevState.ManagerModel,
      }));
      document.getElementById("reportedit").value = "";
      this.GetMangerNameDrop(departmentEditValue)
    }
  };

  handleCheckBox2Change = () => {
    // this.setState({
    //   //  checkBox1:false,
    //   checkBox2:true
    // })
    // document.getElementById("Yes").checked = false
    // document.getElementById("No").checked = true

    this.setState({
      TrainerSts1: "0",
      TrainerSts2: "1",
      checkBox1: false,
      checkBox2: true,
      BoxSts: ""
    });
  };
  DepthandleChange() {
    var Department = document.getElementById("Departmentedit").value;

    this.GetMangerNameDrop(Department)

  }
  render() {
    const {
      TerminateDateErr,
      DisplayNameErr,
      TeamIdErr,
      FirstNameErr,
      LastNameErr,
      EmailErr,
      MobileErr,
      rolesErr,
      departmentErr,
      designationErr,
      ReportErr,
      DeactivesErr,
      PrimaryErr,
      TerminateErr,
      JoinErr
    } = this.state.formErrors;
    const { Departmentdrop, Designationdrop, ManagerDrop, LocationDrop } =
      this.state;
    const { Details } = this.state;
    const currentDate = new Date().toISOString().split("T")[0];

    return (
      <div>
        {this.state.LocationModel ? (
          <AddLocationModel close={() => this.LocationModel()} />
        ) : null}
        {this.state.ManagerModel ? (
          <AddManagerPop close={() => this.ManagerModel()} />
        ) : null}
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header"></div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a
                              href
                              onClick={() => this.props.history.push("/Team")}
                            >
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">Team Edit</h4>
                        </div>
                        <div className="card-header-right">
                          <ToastContainer
                            id="toast"
                            position="top-center"
                            autoClose={1500}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                        </div>
                        <div className="loader" id="teamloader">
                          <MoonLoader
                            color={"#123abc"}
                            loading={this.props.loading}
                            size={30}
                          />
                        </div>
                        <div className="card-block2" id="maindiv">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="row" style={{ marginTop: "3%" }}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <table className="table m-0">
                                      <tbody>
                                        <div
                                          className="container"
                                          style={{ maxWidth: "765px" }}
                                        >
                                          <div
                                            className="row"
                                            style={{ padding: "8px" }}
                                          >
                                            <div className="col-sm-6">
                                              {Details.map((Details) => (
                                                <input
                                                  className="effect-16"
                                                  maxLength="8"
                                                  name="TeamId"
                                                  type="text"
                                                  onInputCapture={(event) => {
                                                    if (
                                                      !/[0-9]/.test(event.key)
                                                    ) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  defaultValue={Details.teamId}
                                                  id="TeamId"
                                                  onChange={this.handleChange}
                                                />
                                              ))}
                                              {!TeamIdErr ? (
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  Employee Id
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {TeamIdErr}
                                                </div>
                                              )}
                                              <span className="underline" />
                                            </div>
                                            <div className="col-sm-6">
                                              {Details.map((Details) => (
                                                <input
                                                  className="effect-16"
                                                  onKeyPress={(event) => {
                                                    if (
                                                      !/[a-zA-Z\s ]+/.test(
                                                        event.key
                                                      )
                                                    ) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  defaultValue={
                                                    Details.displayName
                                                  }
                                                  name="DisplayName"
                                                  maxlength="20"
                                                  id="DisplayName"
                                                  type="text"
                                                  onChange={this.handleChange}
                                                  placeholder="Display Name"
                                                />
                                              ))}
                                              {!DisplayNameErr ? (
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  Display Name
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {DisplayNameErr}
                                                </div>
                                              )}
                                              <span className="underline" />
                                            </div>

                                            <div className="col-sm-6">
                                              {Details.map((Details) => (
                                                <input
                                                  className="effect-16"
                                                  onKeyPress={(event) => {
                                                    if (
                                                      !/[a-zA-Z\s ]+/.test(
                                                        event.key
                                                      )
                                                    ) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  defaultValue={
                                                    Details.firstName
                                                  }
                                                  name="FirstName"
                                                  maxlength="20"
                                                  type="text"
                                                  id="FirstNameedit"
                                                  onChange={this.handleChange}
                                                  placeholder="First Name"
                                                />
                                              ))}
                                              {!FirstNameErr ? (
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  First Name
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {FirstNameErr}
                                                </div>
                                              )}
                                              <span className="underline" />
                                            </div>

                                            <div className="col-sm-6">
                                              {Details.map((Details) => (
                                                <input
                                                  className="effect-16"
                                                  onKeyPress={(event) => {
                                                    if (
                                                      !/[a-zA-Z\s ]+/.test(
                                                        event.key
                                                      )
                                                    ) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  defaultValue={
                                                    Details.lastName
                                                  }
                                                  name="LastName"
                                                  maxlength="20"
                                                  id="LastNameedit"
                                                  type="text"
                                                  onChange={this.handleChange}
                                                  placeholder="LastName"
                                                />
                                              ))}
                                              {!LastNameErr ? (
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  Last Name
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {LastNameErr}
                                                </div>
                                              )}
                                              <span className="underline" />
                                            </div>

                                            {Details.map((Details) => (
                                              <input
                                                type="hidden"
                                                Name="Id"
                                                defaultValue={Details.id}
                                              />
                                            ))}
                                            <div className="col-sm-6">
                                              {Details.map((Details) => (
                                                <input
                                                  className="effect-16"
                                                  defaultValue={Details.email}
                                                  maxlength="50"
                                                  readOnly
                                                  name="Email"
                                                  id="Emailedit"
                                                  type="text"
                                                  onChange={this.handleChange}
                                                  placeholder="Email"
                                                />
                                              ))}
                                              {!EmailErr ? (
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  Email
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {EmailErr}
                                                </div>
                                              )}
                                              <span className="underline" />
                                            </div>
                                            <div className="col-sm-6">
                                              {Details.map((Details) => (
                                                <input
                                                  className="effect-16"
                                                  maxlength="10"
                                                  onKeyPress={(event) => {
                                                    if (
                                                      !/[0-9]/.test(event.key)
                                                    ) {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  defaultValue={Details.mobile}
                                                  name="Mobile"
                                                  id="Mobileedit"
                                                  type="text"
                                                  onChange={this.handleChange}
                                                  placeholder="Mobile Number"
                                                />
                                              ))}
                                              {!MobileErr ? (
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  Mobile
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {MobileErr}
                                                </div>
                                              )}
                                              <span className="underline" />
                                            </div>

                                            <div className="col-sm-6">
                                              {Details.map((Details) => (
                                                <select
                                                  className="form"
                                                  style={{ color: "gray" }}
                                                  type="text"
                                                  defaultValue={
                                                    Details.department
                                                  }
                                                  name="Department"
                                                  id="Departmentedit"
                                                  onChange={() => {
                                                    // this.handleChange();
                                                    this.DepthandleChange();
                                                  }}
                                                >
                                                  <option
                                                    value={Details.department}
                                                    hidden
                                                  >
                                                    {Details.department}
                                                  </option>
                                                  <option value="">Select</option>
                                                  {Departmentdrop.map(
                                                    (Departmentdrop) => (
                                                      <option
                                                        value={
                                                          Departmentdrop.department
                                                        }
                                                        selected={
                                                          Details.department ===
                                                          Departmentdrop.department
                                                        }
                                                      >
                                                        {
                                                          Departmentdrop.department
                                                        }{" "}
                                                      </option>
                                                    )
                                                  )}
                                                  <option value="None">
                                                    None
                                                  </option>
                                                </select>
                                              ))}
                                              {!departmentErr ? (
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  Department
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {departmentErr}
                                                </div>
                                              )}
                                              <span className="underline" />
                                            </div>

                                            <div className="col-sm-6">
                                              {Details.map((Details) => (
                                                <select
                                                  className="form"
                                                  style={{ color: "gray" }}
                                                  name="Designation"
                                                  defaultValue={
                                                    Details.designation
                                                  }
                                                  onChange={this.handleChange}
                                                  id="Designationedit"
                                                  type="text"
                                                >
                                                  <option
                                                    value={Details.designation}
                                                    hidden
                                                  >
                                                    {Details.designation}
                                                  </option>
                                                  <option value="">Select</option>
                                                  {Designationdrop.map(
                                                    (Designationdrop) => (
                                                      <option
                                                        value={
                                                          Designationdrop.designation
                                                        }
                                                        selected={
                                                          Details.designation ===
                                                          Designationdrop.designation
                                                        }
                                                      >
                                                        {
                                                          Designationdrop.designation
                                                        }
                                                      </option>
                                                    )
                                                  )}
                                                  <option value="None">
                                                    None
                                                  </option>{" "}
                                                </select>
                                              ))}
                                              {!designationErr ? (
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  Designation
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {designationErr}
                                                </div>
                                              )}
                                              <span className="underline" />
                                            </div>

                                            <div className="col-sm-6">
                                              {Details.map((Details) => (
                                                <select
                                                  type="text"
                                                  className="effect-16"
                                                  id="RoleNameedit"
                                                  maxlength="20"
                                                  name="RoleName"
                                                  placeholder="Role Name"
                                                  defaultValue={Details.role}
                                                >
                                                  {/* <option value="" ></option> */}
                                                  <option value="">Select</option>
                                                  <option value="Admin">
                                                    Admin
                                                  </option>
                                                  <option value="HR Admin">
                                                    HR Admin
                                                  </option>
                                                  <option value="Team Admin">
                                                    Team Admin
                                                  </option>
                                                  <option value="Skill Admin">
                                                    Skill Admin
                                                  </option>
                                                  <option value="Education Admin">
                                                    Education Admin
                                                  </option>
                                                  <option value="User">
                                                    User
                                                  </option>
                                                </select>
                                              ))}
                                              {!rolesErr ? (
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  Role
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {rolesErr}
                                                </div>
                                              )}
                                              <span className="underline" />
                                            </div>

                                            <div className="col-sm-6" >
                                              {Details.map((Details) => (
                                                <select
                                                  className="effect-16"
                                                  style={{ color: "gray" }}
                                                  onInputCapture={
                                                    this.SelectManager
                                                  }
                                                  defaultValue={Details.report_Manager}
                                                  name="Report_Manager"
                                                  id="reportedit"
                                                  onChange={this.handleChange}
                                                  type="text"
                                                >
                                                  <option hidden>
                                                    {Details.report_Manager}
                                                  </option>
                                                  <option value="">Select</option>
                                                  <option id="Add" value="Add">
                                                    Add
                                                  </option>


                                                  {this.state.ManagerNamedrop.map((ManagerDrop) => (
                                                    <option
                                                      value={ManagerDrop.manager_Name}
                                                    >
                                                      {ManagerDrop.manager_Name}
                                                    </option>
                                                  ))}
                                                  <option value="None">
                                                    None
                                                  </option>{" "}
                                                </select>
                                              ))}
                                              {!ReportErr ? (
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  Reporting Manager{" "}
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {ReportErr}
                                                </div>
                                              )}
                                              <span className="underline" />
                                            </div>

                                            <div className="col-sm-6">
                                              {Details.map((Details) => (
                                                <select
                                                  className="form"
                                                  style={{ color: "gray" }}
                                                  defaultValue={
                                                    Details.primary_Ofc
                                                  }
                                                  onInputCapture={
                                                    this.SelectLocation
                                                  }
                                                  name="Primary_Ofc"
                                                  id="Locations"
                                                  onChange={this.handleChange}
                                                  type="text"
                                                >
                                                  {/* <option value="" ></option>  */}
                                                  <option hidden>
                                                    {Details.primary_Ofc}
                                                  </option>
                                                  <option id="Add" value="Add">
                                                    Add
                                                  </option>
                                                  {LocationDrop.map(
                                                    (LocationDrop) => (
                                                      <option
                                                        value={
                                                          LocationDrop.locationType
                                                        }
                                                        selected={
                                                          Details.primary_Ofc ===
                                                          LocationDrop.locationType
                                                        }
                                                      >
                                                        {
                                                          LocationDrop.locationType
                                                        }
                                                      </option>
                                                    )
                                                  )}
                                                  {/* <option id="Add" value="Primary Location">Primary Location</option> */}
                                                  <option value="None">
                                                    None
                                                  </option>{" "}
                                                </select>
                                              ))}
                                              {!PrimaryErr ? (
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  Locations
                                                </span>
                                              ) : (
                                                <div style={{ color: "red" }}>
                                                  {PrimaryErr}
                                                </div>
                                              )}
                                              <span className="underline" />
                                            </div>
                                            {/* {Details.map(Details =>
                                              <>
                                                {Details.status != null ?
                                                  <>
                                                    <div className="col-sm-6" >
                                                      <input type="text" onFocus={(e) => e.target.type = 'date'} defaultValue={this.formatDate(Details.joining_Date.slice(0, 10))} onFocus={(e) => { e.target.type = 'date'; e.target.value = this.formatDate(e.target.value) }} onBlur={(e) => { e.target.type = 'text'; e.target.value = this.formatDate(e.target.value) }} className="effect-16" id="primaryedit" maxlength="20" name="JoiningDate" />
                                                      <span style={{ color: '#a29d9d' }} >Join Date </span>
                                                    </div>
                                                  </>
                                                  :
                                                  null
                                                }</>
                                            )} */}
                                            {Details.map((Details) => (
                                              <>
                                                {Details.status != null ? (
                                                  <>
                                                    <div className="col-sm-6" style={{ marginTop: "-11px" }}>
                                                      <input
                                                        className="effect-16"
                                                        type="date"
                                                        onFocus={(e) =>
                                                        (e.target.type =
                                                          "date")
                                                        }
                                                        defaultValue={
                                                          Details.joining_Date.split(
                                                            "T"
                                                          )[0]
                                                        } // Set the defaultValue here
                                                        id="JoiningDate"
                                                        name="JoiningDate"
                                                      />
                                                      {/* <input
                                                        type="text"
                                                        onFocus={(e) =>
                                                        (e.target.type =
                                                          "date")
                                                        }
                                                        max={new Date(
                                                          new Date().getFullYear() - 18,
                                                          new Date().getMonth(),
                                                          new Date().getDate()
                                                        )
                                                          .toJSON()
                                                          .slice(0, 10)}
                                                        min={new Date(
                                                          new Date().getFullYear() - 80,
                                                          new Date().getMonth(),
                                                          new Date().getDate()
                                                        )
                                                          .toJSON()
                                                          .slice(0, 10)}
                                                        defaultValue={this.reformatDate(
                                                          Details.joining_Date.slice(0, 10)
                                                        )}
                                                        className="effect-16"
                                                        id="JoiningDate"
                                                        maxlength="20"
                                                        name="JoiningDate"
                                                      /> */}
                                                      {!JoinErr ? (
                                                        <span
                                                          style={{
                                                            color: "#a29d9d",
                                                          }}
                                                        >
                                                          Join Date
                                                        </span>
                                                      ) : (
                                                        <div
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          {JoinErr}
                                                        </div>
                                                      )}
                                                    </div>
                                                  </>
                                                ) : null}
                                              </>
                                            ))}
                                            <br />
                                            {this.state.TeamSts === 1 ||
                                              this.state.TeamSts === 2 || this.state.TeamSts === 0 ? (
                                              <>
                                                {Details.map((Details) => (
                                                  <>
                                                    <div className="col-sm-6" >
                                                      <h4>
                                                        Assigned as trainer
                                                      </h4>
                                                    </div>
                                                    <div className="col-sm-6">
                                                    </div>
                                                    <br></br>
                                                    <div className="col-sm-6" style={{ marginBottom: "10px" }}>
                                                      {this.state
                                                        .TrainerSts1 === "1" ? (
                                                        <span>
                                                          <label>
                                                            <input
                                                              type="checkbox"
                                                              id="Yes"
                                                              name="Yes"
                                                              defaultChecked={
                                                                this.state
                                                                  .TrainerSts1 ===
                                                                "1"
                                                              }
                                                              onChange={() =>
                                                                this.handleCheckBox1Change()
                                                              }
                                                            />
                                                          </label>
                                                          &nbsp;&nbsp;
                                                          <span>Yes</span>
                                                          &nbsp;&nbsp;
                                                        </span>
                                                      ) : (
                                                        <label>
                                                          <input
                                                            type="checkbox"
                                                            id="Yes"
                                                            name="Yes"
                                                            checked={
                                                              this.state
                                                                .checkBox1
                                                            }
                                                            onChange={
                                                              this
                                                                .handleCheckBox1Change
                                                            }
                                                          />
                                                          &nbsp;&nbsp;
                                                          <span>Yes</span>
                                                          &nbsp;&nbsp;
                                                        </label>
                                                      )}
                                                      {this.state
                                                        .TrainerSts2 === "0" ? (
                                                        <span>
                                                          <label id="No">
                                                            <input
                                                              type="checkbox"
                                                              id="No"
                                                              name="No"
                                                              defaultChecked={
                                                                this.state
                                                                  .TrainerSts2 ===
                                                                "0"
                                                              }
                                                              onChange={() =>
                                                                this.handleCheckBox2Change()
                                                              }
                                                            />
                                                          </label>
                                                          &nbsp;&nbsp;
                                                          <span>No</span>
                                                          &nbsp;&nbsp;
                                                        </span>
                                                      ) : (
                                                        <label>
                                                          <input
                                                            type="checkbox"
                                                            id="No"
                                                            name="No"
                                                            checked={
                                                              this.state
                                                                .checkBox2
                                                            }
                                                            onChange={
                                                              this
                                                                .handleCheckBox2Change
                                                            }
                                                          />
                                                          &nbsp;&nbsp;
                                                          <span>No</span>
                                                          &nbsp;&nbsp;
                                                        </label>
                                                      )}

                                                      {/* {this.state.Experience ?<select className="effect-16" type="text" id="Experience" onClick={this.selectExperience}  > */}
                                                      {/* {this.state.Experience ?<select className="effect-16" type="text" name="TrainerExper" id="Experience" onClick={this.selectExperience}  >
    <option value="" >Select</option> 
          <option value="0-1">0-1 year</option>
          <option value="1-2">1-2 year</option>
          <option value="2-3">2-3 year</option>
          <option value="3-4">3-4 year</option>
          <option value="4-5">4-5 year</option>
          <option value="More">More</option>
          </select>:null} */}

                                                      {this.state.checkBox1 ===
                                                        true || this.state
                                                          .TrainerSts1 === "1" ? (
                                                        <>
                                                          <input
                                                            className="form"
                                                            onFocus={(e) =>
                                                              e.target.select()
                                                            }
                                                            name="TrainerExperience"
                                                            id="Experience"
                                                            type="text"
                                                            onFocus={(e) =>
                                                            (e.target.type =
                                                              "date")
                                                            }
                                                            defaultValue={Details.experiencedate}
                                                            // max={this.state.maxDate}
                                                            max={currentDate}
                                                          />
                                                          <span
                                                            style={{
                                                              color: "#a29d9d",
                                                            }}
                                                          >
                                                            Trainer Experience
                                                          </span>
                                                        </>
                                                      ) : null}
                                                    </div>{" "}

                                                  </>
                                                ))}
                                              </>
                                            ) : null}
                                            <div className="col-sm-6">
                                            </div>
                                            <div></div>
                                            <div></div>
                                            {this.state.TeamSts === 2 ? null : (
                                              <>
                                                {/* {this.state.TeamSts === 0 ? <div className="col-sm-6">
                                                </div> : null} */}
                                                <div className="col-sm-6">
                                                  <h4>Status</h4>
                                                </div>
                                                <div className="col-sm-6">
                                                </div>

                                                <div className="col-lg-12">
                                                  <div className="row" style={{ marginLeft: "-16px" }}>
                                                    <br />
                                                    <div className="col-lg-4">
                                                      <span //style={{color:"black"}}
                                                      >
                                                        Active
                                                      </span>
                                                      &nbsp;&nbsp;
                                                      <label className="switch">
                                                        <input
                                                          type="checkbox"
                                                          id="Active"
                                                          defaultChecked={
                                                            this.state.TeamSts === 1
                                                          }
                                                          onInputCapture={
                                                            this.handleActive
                                                          }
                                                          name="Active"
                                                        />
                                                        <span className="slider round" />
                                                      </label>
                                                    </div>
                                                    <div className="col-lg-4">
                                                      <span //style={{color:"black"}}
                                                      >
                                                        Inactive
                                                      </span>
                                                      &nbsp;&nbsp;
                                                      <label
                                                        className="switch"
                                                        id="deactivecheck"
                                                      >
                                                        <input
                                                          type="checkbox"
                                                          id="Deactive"
                                                          defaultChecked={
                                                            this.state.TeamSts === 0
                                                          }
                                                          onClick={
                                                            this.handleDeactive
                                                          }
                                                          name="Deactive"
                                                        />
                                                        <span className="slider round" />
                                                      </label>
                                                    </div>
                                                    <div className="col-lg-4">
                                                      <span //style={{color:"black"}}
                                                      >
                                                        Terminate
                                                      </span>
                                                      &nbsp;&nbsp;
                                                      <label className="switch">
                                                        <input
                                                          type="checkbox"
                                                          id="Terminate"
                                                          defaultChecked={
                                                            this.state.TeamSts === 3
                                                          }
                                                          onInputCapture={
                                                            this.handleTerminate
                                                          }
                                                          name="Terminate"
                                                        />
                                                        <span className="slider round" />
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>

                                          {/* /// */}
                                          <div>
                                            {this.state.BoxSts === 3 ? (
                                              <div className="row" id="terminateReasonInput">
                                                <div className="col-sm-6">
                                                  {Details.map((Details) => (
                                                    <input
                                                      type="text"
                                                      className="effect-16"
                                                      id="terminateReason"
                                                      defaultValue={
                                                        Details.terminateReason
                                                      }
                                                      name="TerminateReason"
                                                    />
                                                  ))}
                                                  {!TerminateErr ? (
                                                    <span
                                                      style={{
                                                        color: "#a29d9d",
                                                      }}
                                                    >
                                                      Terminate Reason
                                                    </span>
                                                  ) : (
                                                    <div
                                                      style={{ color: "red" }}
                                                    >
                                                      {TerminateErr}
                                                    </div>
                                                  )}
                                                </div>
                                                <div className="col-sm-6">
                                                  {Details.map((Details) => (
                                                    <input
                                                      type="text"
                                                      onFocus={(e) =>
                                                        (e.target.type = "date")
                                                      }
                                                      className="effect-16"
                                                      id="TerminateDate"
                                                      maxlength="20"
                                                      name="TerminateDate"
                                                      defaultValue={new Date(
                                                        new Date().getFullYear(),
                                                        new Date().getMonth(),
                                                        new Date().getDate()
                                                      ).toLocaleDateString()}
                                                    />
                                                  ))}
                                                  {!TerminateDateErr ? (
                                                    <span
                                                      style={{
                                                        color: "#a29d9d",
                                                      }}
                                                    >
                                                      Terminate Date
                                                    </span>
                                                  ) : (
                                                    <div
                                                      style={{ color: "red" }}
                                                    >
                                                      {TerminateDateErr}
                                                    </div>
                                                  )}

                                                  {/* DeactiveReason    */}
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                {this.state.BoxSts === 0 ? (
                                                  <>
                                                    {" "}
                                                    <div className="row" id="deactivereasonInput">
                                                      <div className="col-sm-4"></div>
                                                      <div className="col-sm-4">
                                                        {Details.map(
                                                          (Details) => (
                                                            <input
                                                              type="text"
                                                              className="effect-16"
                                                              id="deactivereason"
                                                              defaultValue={
                                                                Details.decativeReason
                                                              }
                                                              name="DeactiveReason"
                                                            />
                                                          )
                                                        )}
                                                        {!DeactivesErr ? (
                                                          <span
                                                            style={{
                                                              color: "#a29d9d",
                                                            }}
                                                          >
                                                            Reason
                                                          </span>
                                                        ) : (
                                                          <div
                                                            style={{
                                                              color: "red",
                                                            }}
                                                          >
                                                            {DeactivesErr}
                                                          </div>
                                                        )}
                                                        {/* terminateReason */}
                                                      </div>
                                                      <div className="col-sm-4"></div>
                                                    </div>
                                                  </>
                                                ) : null}
                                              </>
                                            )}
                                          </div>
                                          {/* /// */}
                                          <br />
                                          {/* /// */}
                                          {/*  */}
                                          <div
                                            className="col-sm-12"
                                            style={{
                                              justifyContent: "center",
                                              alignContent: "baseline",
                                              display: "flex",
                                              marginBottom: "0px",
                                            }}
                                          >
                                            <button
                                              type="submit"
                                              disabled={
                                                this.state.ButtonDisabled
                                              }
                                              /*  onClick={()=>{this.AddTeam()}} */ className="but"
                                              style={{
                                                height: "40.4px",
                                                width: "83.36px",
                                              }}
                                            >
                                              Save
                                            </button>{" "}
                                            &nbsp;&nbsp;&nbsp;
                                            <button
                                              type="reset"
                                              className=" btn-Secondary"
                                              onClick={this.handleCheckBox2Change}
                                            >
                                              Cancel{Details.termninateDate}
                                            </button>
                                          </div>
                                          {/* /// */}
                                          <br /> <br />
                                        </div>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
