import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../../Config/Base";
export default class SkillMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      skills: [],
      Id: "",
      SkillName: "",
      SkillDescription: "",
      Category: "",
      id: this.props.location.state.id,
      value: this.props.location.state,
      formErrors: {},
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.state;
    if (this.props.location.state === undefined) {
      this.props.history.goBack();
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.handleFormValidation()) {
      const data = new FormData(event.target);

      const url = `${BASE_URLs}Skill/EditSkill`;
      const options = {
        method: "PUT",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      this.setState(this.initialState);
      const response = await fetch(url, options);
      await response.json();
      if (response.ok) {
        alert("success");
      }

      this.setState(this.initialState);
    }
  };

  handleFormValidation() {
    var category = document.getElementById("category").value;
    var skillname = document.getElementById("skillname").value;
    var SkillDescription = document.getElementById("SkillDescription").value;
    let formErrors = {};
    let formIsValid = true;

    if (!category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    }

    if (!skillname) {
      formIsValid = false;
      formErrors["SkillnameErr"] = "Skill Name is required";
    }

    if (!SkillDescription) {
      formIsValid = false;
      formErrors["SkillDescriptionErr"] = "Skill Description is required";
    }
    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  handleClick = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    fetch(`${BASE_URLs}Skill/EditSkill`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    });
  };

  //     EditSubmit = (event) =>{
  //       event.preventDefault();
  //       if(this.handleFormValidation()){
  //       const data = new FormData(event.target);
  //         let url=`${BASE_URLs}Skill/EditSuperSkill";
  //         fetch(url,{
  //             method:'POST',
  //       headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",  },
  //             body:data

  //         }).then((result)=>{
  //             result.json().then((resp)=>{

  // alert(resp);
  // this.props.history.goBack()

  //             })
  //         })
  //       }
  //       }

  EditSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    if (this.handleFormValidation()) {
      let user = JSON.parse(localStorage.getItem("user"));

      const accessToken = user;
      const url = `${BASE_URLs}Skill/EditSuperSkill`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      };

      const response = await fetch(url, options);
      const result = await response.json();

      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          if (result.role.toLowerCase() === "platform admin") {
            this.props.history.push("/Gridskil");
          } else {
            this.props.history.push("/MasterSkill");
          }
        }, 2000);
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  deleteSkill(id) {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}Skill/Delete?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          alert(resp);
        });
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  componentDidMount() {}

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  render() {
    const { skills } = this.state;
    const { SkillnameErr, SkillDescriptionErr, CategoryErr } =
      this.state.formErrors;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                        </div>
                        <div className="card-header-right"></div>
                        <div className="card-block2">
                          <form onSubmit={this.EditSubmit}>
                            <div className="container">
                              <div
                                className="row"
                                id="skill-form"
                                style={{ marginTop: "3%" }}
                              >
                                <div className="col-lg-4">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <input
                                        className="form"
                                        id="category"
                                        name="Category"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={
                                          this.props.location.state.category
                                        }
                                        placeholder=" Category"
                                      />
                                      {!CategoryErr ? (
                                        <span style={{ color: "#a29d9d" }}>
                                          Category
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {CategoryErr}
                                        </div>
                                      )}
                                      <span className="underline" />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      <input
                                        className="form"
                                        id="skillname"
                                        name="SkillName"
                                        type="text"
                                        onChange={this.handleChange}
                                        defaultValue={
                                          this.props.location.state.skillName
                                        }
                                        placeholder="Skill Name"
                                      />
                                      {!SkillnameErr ? (
                                        <span style={{ color: "#a29d9d" }}>
                                          Name
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {SkillnameErr}
                                        </div>
                                      )}
                                      <span className="underline" />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      <input
                                        className="form"
                                        id="SkillDescription"
                                        name="SkillDescription"
                                        type="text"
                                        defaultValue={
                                          this.props.location.state
                                            .skillDescription
                                        }
                                        onChange={this.handleChange}
                                        placeholder="Skill Description"
                                      />
                                      <input
                                        type="hidden"
                                        name="Id"
                                        value={this.props.location.state.Id}
                                      />
                                      {!SkillDescriptionErr ? (
                                        <span style={{ color: "#a29d9d" }}>
                                          Description
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {SkillDescriptionErr}
                                        </div>
                                      )}
                                      <span className="underline" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div
                                  className="col-sm-12"
                                  style={{ textAlign: "center" }}
                                >
                                  <button
                                    type="submit"
                                    className="but"
                                    style={{
                                      marginTop: "-0.3%",
                                      height: "39.5px",
                                      width: "83.36px",
                                    }}
                                  >
                                    Save
                                  </button>
                                  <button
                                    type="reset"
                                    className=" btn-Secondary"
                                    style={{
                                      marginTop: "0.9%",
                                      marginLeft: "1%",
                                      height: "39.5px",
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <br /> <br />
                                </div>
                              </div>
                            </div>
                          </form>
                          <br /> <br /> <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
