import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base";
import CloseIcon from "@material-ui/icons/Close";
var XLSX = require("xlsx");
export default class UserHoliday extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      formErrors: {},
      Pending: [],
      Notification: [],
      Profile_Pic: [],
      Holiday: [],
      AllHoliday: [],
      Filterpop: false,
      letters: [],
      FilterData:"",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();

    if (this.handleFormValidation()) {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}UserRequest/AddRequest`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          alert(resp.message);
        });
    }
  }

  handleFormValidation() {
    let description = document.getElementById("description").value;

    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!description) {
      formIsValid = false;
      formErrors["DescriptionErr"] = "Description is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  GetUserProfile() {
    let url = `${BASE_URLs}Profile/Userprofile`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Profile_Pic: data,
        });
      });
  }
  componentDidMount() {
    this.GetUserProfile();

    this.GetHoliday();
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
  }

  GetHoliday() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Holiday/UserHolidayGet`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Holiday: data });
        this.setState({ AllHoliday: data });
      });
  }

  formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  formatDate1 = (inputDate) => {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  exportXlsx(filename) {

    var table_elt = document.getElementById("UserLocationExport");

    var workbook = XLSX.utils.table_to_book(table_elt);

    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }
  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  handleHolidayFilter = (letter, event) => {
    event.preventDefault();
    const Holiday = this.state.AllHoliday.filter((item) => {
      return (
        item.reason.charAt(0).toLowerCase() === letter.toLowerCase() ||
        item.optional.charAt(0).toLowerCase() === letter.toLowerCase()
      );
    });
    this.setState({ Holiday, showResults: true, FilterData: Holiday});
  };
  onKeyUp = (e) => {
    const Holiday = this.state.AllHoliday.filter(
      (item) =>
        item.reason.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.optional.toLowerCase().includes(e.target.value.toLowerCase())

    );
    this.setState({ Holiday });
  }


  render() {
    var username = JSON.parse(localStorage.uname);
    const { Holiday, Filterpop,AllHoliday, FilterData } = this.state;
    var filename = `O_${username}_Location`;

    return (
      <div>
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "4%" }} className="title">
                            Holidays
                          </h4>
                        </div>
                        <div className="card-block">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                           
                              <button
                                className="btn"
                                type="button"
                                onClick={() => this.exportXlsx(filename)}
                              // onKeyPress={this.handleKeyPress}
                              >
                                Export to excel
                              </button>
                             
                           
                              <input
                                className="Searchbox"
                                // id="CNSearch"
                                placeholder="Search"
                                onClick={() => {
                                  this.setState({
                                    Filterpop: !this.state.Filterpop,
                                  });
                                }}
                                style={{float:"right"}}
                                readOnly
                              />
                             
                            <div>
                              <div className="col-lg-12">
                                <div className="dt-responsive table-responsive"
                                style={Filterpop ? {marginTop: '40px'} : {}}
                                >
                                 
                                  {AllHoliday.length !== 0 ? (
                                    <table
                                      id="add-row-table3"
                                      className="table nowrap "
                                    >
                                       <thead id="tablepaddingA">
                                        <tr>
                                          <th>Month</th>
                                          <th>Date </th>
                                          <th>Day </th>
                                          <th>Occasions</th>
                                          <th>Branch</th>
                                          <th>Optional </th>
                                        </tr>
                                      </thead>
                                      <tbody id="tablepadding">
                                      {FilterData.length === 0 && Holiday.length === 0 ? (
                                      <tr>
                                        <th
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Record
                                        </th>
                                      </tr>
                                    ) : null}
                                        {Holiday.map((Holiday) => (
                                          <tr>
                                            <td>{Holiday.month}</td>
                                            <td> {this.formatDate(Holiday.date)} </td>
                                            <td> {Holiday.day} </td>
                                            <td>{Holiday.reason}</td>
                                            <td>{Holiday.locationType}</td>
                                            <td>{Holiday.optional}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                      <table
                                        id="UserLocationExport"
                                        className="UserLocationExport"
                                        hidden
                                      >
                                        <thead>
                                          <tr>
                                          <th>Month</th>
                                          <th>Date </th>
                                          <th>Day </th>
                                          <th>Occasions</th>
                                          <th>Branch</th>
                                          <th>Optional </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Holiday.map((Holiday) => (
                                            <tr>
                                            <td>{Holiday.month}</td>
                                            <td> {this.formatDate1(Holiday.date)} </td>
                                            <td> {Holiday.day} </td>
                                            <td>{Holiday.reason}</td>
                                            <td>{Holiday.locationType}</td>
                                            <td>{Holiday.optional}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </table>
                                  ) : null}
                                </div>

                                <br />
                              </div>
                              <br /> <br />
                              <br /> <br />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {Filterpop && (
                <div className="popSearchTeam">
                  <div className="popSearchinnernormalTeam">
                    <div
                      className="popSearchinnertitle"
                      id="popSearchinnernormal"
                    >
                      <button
                        id="close"
                        style={{
                          float: "right",
                          backgroundColor: "#a0a3a305",
                          color: "#797070",
                          border: "1px solid #a3a5a505",
                          marginTop: "-20px",
                          marginRight: "-20px",
                        }}
                        onClick={() => {
                          this.setState({ Filterpop: false });
                           this.GetHoliday();
                        }}
                      >
                        <CloseIcon />
                      </button>

                      <div className="checkboxes">
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{
                            fontSize: "20px",
                            color: "gray",
                            marginLeft: "-1%",
                            position: "absolute",
                            marginTop: "0.5%",
                          }}
                        ></i>
                        <input
                          className="Searchbox"
                          placeholder="Search"
                          onKeyUp={this.onKeyUp}
                          style={{ float: "right", width: "80%" }}
                        />
                        <br></br>
                        {this.state.letters.map(
                          (letter, index) => (
                            <button
                              className="filter"
                              key={index}
                              onClick={(event) =>
                                this.handleHolidayFilter(
                                  letter,
                                  event
                                )
                              }
                            >
                              {letter}
                            </button>
                          )
                        )}
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
