import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "react-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class AddManagerPop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      EntityNameValidate: [],
      RelationshipDrop: [],
      formErrors: {},
      value: "",
      ManagerNAme: [],
      Departmentdrop: [],
      ButtonDisabled: false,
      countries: [
        { label: "United Kingdom", value: "UK" },
        { label: "United States", value: "US" },
        { label: "india", value: "US" },
        { label: "pakistan", value: "US" },
        { label: "srilanka", value: "US" },
      ],
    };
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.handleSubmit(e);
    }
  };

  Team() {
    let url = `${BASE_URLs}Team/GetTeam`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //         data.map((d) =>{
        // this.setState({
        //   EntityNameValidate: d.firstName + ' ' + d.lastName,
        // })
        this.setState({
          EntityNameValidate: data,
        });
      });
  }

  GetManagerName() {
    let url = `${BASE_URLs}Manager/GetManagerAutocomplete`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          ManagerNAme: data,
        });
        // // console.log(data);
      });
  }
  handleSubmit = async (e) => {
    let MN = document.getElementById("Manager_Name").value;
    let AddDepartment = document.getElementById("DepartmentNA").value;
    e.preventDefault();
    if (this.Validation()) {
      this.setState({
        ButtonDisabled: true,
      });
      const data = new FormData();
      data.append("Manager_Name", MN);
      data.append("AddDepartment", AddDepartment);
      // let formErrors = {};
      // let formIsValid = true;
      // let fullNameArray = this.state.EntityNameValidate.map(
      //   (person) => person.firstName + " " + person.lastName
      // );
      const url = `${BASE_URLs}Manager/AddManager`;
      const options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Accept: "application/json",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      const response = await fetch(url, options);
      const result = await response.json();
      if (result.status === "Success") {
        toast.success(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        document.getElementById("Addpop").style.display = "none";
        setTimeout(function () {
          document.getElementById("close").click();
        }, 4000);

        this.GetManager();
      } else {
        toast.error(result.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        document.getElementById("Addpop").style.display = "none";
        setTimeout(function () {
          document.getElementById("close").click();
        }, 2000);
      }
    }
    //  else {
    //   formIsValid = false;
    //   formErrors["Manager_Name"] = "Name should be from the entity";
    //   this.setState({ formErrors: formErrors });
    // }
  };
  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var Manager_Name = document.getElementById("Manager_Name").value;
    var Department = document.getElementById("DepartmentNA").value;

    if (!Manager_Name || Manager_Name.trim() == "") {
      formIsValid = false;
      formErrors["Manager_Name"] = " Name is required";
    }
    if (!Department || Department.trim() == "") {
      formIsValid = false;
      formErrors["Department"] = "Department is required";
    }

    this.setState({ formErrors: formErrors });
    //document.getElementById("add-row").style.display = "none";
    return formIsValid;
  }

  GetDepartment() {
    let url = `${BASE_URLs}Department/DepartmentGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Departmentdrop: data,
        });
      });
  }
  GetManager() {
    let url = `${BASE_URLs}Manager/ManagerGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    //true
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Manager: data,
        });
      });
    //fal
  }

  handleChange = (e) => {
    //const {Manager_Name,Department}= this.state;
    let formErrors = {};
    let formIsValid = true;
    var Manager_Name = document.getElementById("Manager_Name").value;
    var Department = document.getElementById("DepartmentNA").value;

    if (!Manager_Name || Manager_Name.trim() == "") {
      formIsValid = false;
      formErrors["Manager_Name"] = " Name is required";
    } else if (!Department || Department.trim() == "") {
      formIsValid = false;
      formErrors["Department"] = "Department is required";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  };
  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }

  componentDidMount() {
    this.GetManagerName();
    this.GetManager();
    this.GetDepartment();
    this.Team();
  }
  render() {
    const { Manager_Name, Department } = this.state.formErrors;
    const { Departmentdrop,ManagerNAme } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div className="popinnernormal" style={{ padding: "1%"}} id="Addpop">
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>
            <br />

            <form
              autoComplete="off"
              encType="multipart/form-data"
              onSubmit={this.handleSubmit}
            >
              <div className="row" style={{ padding: "8px" }}>
                <div className="col-sm-6" style={{ zIndex: "1000" ,display:"block"}}>
                <select
                    className="effect-16 selectleftalign"
                    id="Manager_Name"
                    name="Manager_Name"
                    // defaultValue={this.props.values.ManagerName}

                    type="text"
                    placeholder="Select"
                  >
                     <option value=""> Select</option>
                  
                   {ManagerNAme.map((name) => ( 
                  <option
                      
                      value={name.label}
                    >
                      {name.label}
                    </option> 
                    ))} 

                  </select>
                  {/* <Autocomplete
                    className="form"
                    // style={{ textAlign: "left" }}
                    items={this.state.ManagerNAme}
                    shouldItemRender={(item, value) =>
                      item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
                    }
                    getItemValue={(item) => item.label}
                    renderItem={(item, isHighlighted) => (
                      // Styling to highlight selected item
                      <div
                        style={{
                          background: isHighlighted ? "#bcf5bc" : "white",
                        }}
                        key={item.id}
                      >
                        {item.label}
                      </div>
                    )}
                    value={this.state.value}
                    onChange={(e) => {
                      this.setState({ value: e.target.value });
                      this.handleChange();
                    }}
                    onKeyDown={this._handleKeyDown}
                    // To set the state variable to value
                    // selected from dropdown
                    onSelect={(val) => this.setState({ value: val })}
                    // Added style in Autocomplete component
                    inputProps={{
                      style: {
                        display: "flex",
                      },
                      className: "form",
                      name: "Manager_Name",
                      id: "Manager_Name",
                      type: "text",
                    }}
                  /> */}
                  {!Manager_Name ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      {" "}
                      Name<span
                        style={{ color: "red", float: "left" }}
                      ></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {Manager_Name}
                    </div>
                  )}{" "}
                  <span className="underline" />
                </div>
                <div className="col-sm-6">
                  <select
                    className="effect-16"
                    type="text"
                    onInputCapture={this.SelectDepartment}
                    name="AddDepartment"
                    id="DepartmentNA"
                    onChange={this.handleChange}
                    onKeyDown={this._handleKeyDown}
                  >
                    <option value="">Select</option>
                    {Departmentdrop.map((Departmentdrop) => (
                      <option value={Departmentdrop.department}>
                        {Departmentdrop.department}{" "}
                      </option>
                    ))}
                    <option value="None">None</option>
                  </select>
                  <input
                    type="hidden"
                    name="otherdepartment"
                    className="form"
                    id="departmenttext"
                  />
                  {!Department ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      {" "}
                      Department{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {Department}
                    </div>
                  )}
                  <span className="underline" />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
              <br />
            </form>
          </div>
        </div>
      </>
    );
  }
}
