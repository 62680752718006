import CloseIcon from '@material-ui/icons/Close';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css';
import '../Css/Chat.css';
import AddTrainingAddTrainee from './AddTrainingAddTrainee';
import TrainingAddTrainerPop from './TrainingAddTrainerpop';
// import { Scheduler } from "@aldabil/react-scheduler";

import {
  Agenda,
  Day,
  Inject,
  Month,
  ScheduleComponent,
  Week,
} from '@syncfusion/ej2-react-schedule';

import { BASE_URLs } from '../Config/Base';
export default class TrainingClassPopup extends Component {
  constructor(props) {
    const divsWithoutId = document.querySelectorAll(
      'div[style*="z-index: 999999999"]'
    );
    divsWithoutId.forEach(function (div) {
      div.style.display = 'none';
      // });
    });

    const divsWithoutId1 = document.querySelectorAll(
      'div[style*="z-index: 99999"]'
    );
    divsWithoutId1.forEach(function (div) {
      div.style.display = 'none';
      // });
    });

    super(props);
    this.state = {
      selectedDateTime: dayjs(),
      selectedDate: '',
      selectedTime: '',
      firstPopupVisible: false,
      secondPopupVisible: false,
      thirdPopupVisible: false,
      selectedTrainee: [],
      selectedOption: [],
      selectedDepartment: '',
      selectedDescription: '',
      selectedTrainingItem: '',
      selectedTrainingTopicId: '',
      SelectedClass: [],
      WholeDates: [],
      ClassObj: {},
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const value = this.props.TrainingState;

      this.setState({
        selectedDepartment: value.department,
        selectedDescription: value.description,
        selectedTrainingItem: value.trainingitem,
        selectedTrainingTopicId: value.training_Id,
      });
    }, 500);
  }

  show = (e) => {
    e.preventDefault();

    let final = this.scheduleObj.eventsData.map((e) => {
      if (e.Subject) {
        e.Subject = this.props.TrainingState.trainingitem;
      }

      return e;
    });

    this.setState({ selectedOption: final });

    // [0].Subject = this.props.TrainingState.trainingitem;
  };

  toggleAddTrainerPopup = (e) => {
    e.preventDefault();
    if (this.scheduleObj.eventsData.length === 0) {
      toast.error('Please Select atlest one date', {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let final = this.scheduleObj.eventsData.map((e) => {
      if (e.Subject) {
        e.Subject = this.props.TrainingState.trainingitem;
      }

      return e;
    });

    let obj = {};
    let AllDates = [];

    let ffff = this.scheduleObj.eventsData.map((eventsData) => {
      if (eventsData.RecurrenceRule != null) {
        let data = this.scheduleObj.eventsProcessed.filter(
          (eventsProcessed) => eventsProcessed.Id === eventsData.Id
        );

        if (data.length !== 0) {
          let datediff =
            new Date(data[0].EndTime) - new Date(data[0].StartTime);
          let Dates = this.getDatesByRecurrenceRule(
            eventsData.RecurrenceRule,
            eventsData.StartTime
          );

          const dateWithTime = new Date(data[0].StartTime);

          // Display the separate components of time
          const hours = dateWithTime.getHours().toString().padStart(2, '0');
          const minutes = dateWithTime.getMinutes().toString().padStart(2, '0');
          const seconds = dateWithTime.getSeconds().toString().padStart(2, '0');

          // Formatted time string in HH:mm:ss format
          const formattedTime = `${hours}:${minutes}:${seconds}`;

          // Convert each date to the desired format
          const formattedDates = Dates.map(this.formatDate);
          let newArr = [];
          formattedDates.forEach((forDate) => {
            const date =
              forDate.split('T')[0] + `T${hours}:${minutes}:${seconds}`;
            newArr.push(date);
          });

          AllDates.push(...newArr);

          let events = newArr.map((date) => {
            let Added_Date = new Date(date).getTime() + datediff;

            return {
              Subject: eventsData.Subject,
              StartTime: new Date(date).toString(),
              EndTime: new Date(Added_Date).toString(),
              RecurrenceRule: eventsData.RecurrenceRule,
            };
          });

          obj[eventsData.Id] = events;
        }
      } else {
        const originalDate = new Date(eventsData.StartTime);
        const reducedDate = new Date(
          originalDate.getTime() + 5.5 * 60 * 60 * 1000
        ); // Subtracting 5 hours in milliseconds
        AllDates.push(reducedDate.toISOString());

        obj[eventsData.Id] = [
          {
            Subject: eventsData.Subject,
            StartTime: eventsData.StartTime,
            EndTime: eventsData.EndTime,
            RecurrenceRule: null,
          },
        ];
      }
    });

    this.setState({ WholeDates: AllDates });
    this.setState({ ClassObj: obj });

    // this.setState({ SelectedClass: final });

    // [0].Subject = this.props.TrainingState.trainingitem;

    if (
      this.checkValidDuration(
        obj,
        this.props.TrainingState.duration.split(' ')[0]
      )
    ) {
      // let Duration = this.props.TrainingState.duration.split(" ")[0]
      toast.error(
        'Training schedule duration should be ' +
          this.validmsg(this.props.TrainingState.duration),
        {
          position: 'top-center',
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      this.setState((prevState) => ({
        secondPopupVisible: !prevState.secondPopupVisible,
      }));
    }
  };

  validmsg(hours) {
    let duration = hours.split(' ')[0];
    const [Hours, minutes] = duration.split('.');
    if (minutes == undefined) {
      return hours;
    } else {
      return Hours + ':' + minutes * 6 + ' Hours';
    }
  }

  checkValidDuration(arr, validhr) {
    let sts = [];
    let time = 0;
    let ff = Object.keys(arr).map((e) => {
      arr[e].map((date) => {
        time += getHourDifference(date.StartTime, date.EndTime);
      });
    });
    // sts.push(validhr == getHourDifference(date.StartTime, date.EndTime));

    function getHourDifference(date1, date2) {
      const d1 = new Date(date1);
      const d2 = new Date(date2);
      const diff = Math.abs(d1 - d2);
      const diffHours = diff / (1000 * 60 * 60);
      return diffHours;
    }

    return time != validhr;
  }

  toggleSaveTrainerPopup = async (state, e) => {
    e.preventDefault();

    let selectedState = state.filter((e) => e.check === true);
    const {
      selectedTrainee,
      selectedDepartment,
      selectedDescription,
      selectedTrainingItem,
      selectedDate,
      selectedTime,
      WholeDates,
      ClassObj,
      selectedTrainingTopicId,
    } = this.state;
    const closeup = this.props.togglePopupclose(null);

    function convertTo24Hour(time12h) {
      const [time, modifier] = time12h.split(' ');

      let [hours, minutes] = time.split(':');

      if (hours === '12') {
        hours = '00';
      }

      if (modifier === 'PM') {
        hours = parseInt(hours, 10) + 12;
      }

      return `${hours}:${minutes}`;
    }

    let keys = Object.keys(ClassObj);
    var sts = 0;
    let dateArr = [];
    // keys.forEach((obj) => {
    for (let j = 0; j < keys.length; ) {
      let obj = keys[j];

      // ClassObj[obj].forEach((date) => {
      for (let index = 0; index < ClassObj[obj].length; ) {
        const date = ClassObj[obj][index];

        const data = new FormData();
        const startdate = this.convert(date.StartTime);
        const Enddate = this.convert(date.EndTime);
        const time = this.displayTimeFromDate(date.StartTime);
        const Endtime = this.displayTimeFromDate(date.EndTime);

        const hourDiff = this.DurationCalc(time, Endtime);
        //single date multiple class
        if (state.filter((e) => e.date === startdate).length > 1) {
          if (!dateArr.includes(startdate)) {
            if (!dateArr.includes(startdate)) {
              dateArr.push(startdate);
            }

            let Data = state
              .filter((e) => e.date === startdate)
              .forEach((arr) => {
                let data = new FormData();

                const startTime = convertTo24Hour(arr.StartTime);
                const endTime = convertTo24Hour(arr.EndTime);

                const hourDiff = this.DurationCalc(startTime, endTime);

                let dateData = arr.filteredAvailableArray.filter(
                  (ee) => ee.check === true
                );

                dateData.forEach((datas) => {
                  data.append('TrainerId', datas.trainer_UserId);
                  data.append('TrainerName', datas.trainerName);
                });

                data.append('Department', selectedDepartment);
                data.append('Trainingitem', selectedTrainingItem);
                data.append('TrainingDescription', selectedDescription);
                data.append('Training_Id', selectedTrainingTopicId);
                data.append('StartClassDate', startdate);
                data.append('EndClassDate', Enddate);
                data.append('ScheduleTime', startTime);
                data.append('ScheduleEndTime', endTime);
                data.append('ScheduleDuration', hourDiff);
                data.append('RecurrenceRule', date.RecurrenceRule);
                let myPromise = new Promise(async function (
                  myResolve,
                  myReject
                ) {
                  // "Producing Code" (May take some time)
                  const url = `${BASE_URLs}Learning/AdminAddLearning`; // Replace with your API endpoint
                  try {
                    const response = await fetch(url, {
                      method: 'POST',
                      headers: {
                        Authorization: `Bearer ${JSON.parse(
                          localStorage.user
                        )}`,
                      },
                      body: data,
                    });
                    const result = await response.json();

                    if (response.ok) {
                      sts += 1;

                      if (sts == ClassObj[obj].length) {
                        toast.success('Training schedule added Successfully ', {
                          position: 'top-center',
                          autoClose: 1700,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });

                        setTimeout(closeup(), 3000);
                      }

                      myResolve(); // Output the fetched data
                      // Perform any additional operations with the fetched data
                    } else {
                      //
                      myReject();
                    }
                  } catch (error) {
                    console.error('Error occurred:', error);
                  }
                });

                // "Consuming Code" (Must wait for a fulfilled Promise)

                myPromise.then();
              });
          }
        } else if (state.filter((e) => e.date === startdate).length === 1) {
          let dateData = state
            .filter((e) => e.date === startdate)[0]
            .filteredAvailableArray.filter((ee) => ee.check === true);

          dateData.forEach((datas) => {
            data.append('TrainerId', datas.trainer_UserId);
            data.append('TrainerName', datas.trainerName);
          });

          data.append('Department', selectedDepartment);
          data.append('Trainingitem', selectedTrainingItem);
          data.append('TrainingDescription', selectedDescription);
          data.append('Training_Id', selectedTrainingTopicId);
          data.append('StartClassDate', startdate);
          data.append('EndClassDate', Enddate);
          data.append('ScheduleTime', time);
          data.append('ScheduleEndTime', Endtime);
          data.append('ScheduleDuration', hourDiff);
          data.append('RecurrenceRule', date.RecurrenceRule);

          let myPromise = new Promise(async function (myResolve, myReject) {
            // "Producing Code" (May take some time)
            const url = `${BASE_URLs}Learning/AdminAddLearning`; // Replace with your API endpoint

            try {
              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
                },
                body: data,
              });
              const result = await response.json();

              if (response.ok) {
                sts += 1;

                if (sts == ClassObj[obj].length) {
                  toast.success('Training schedule added Successfully ', {
                    position: 'top-center',
                    autoClose: 1700,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });

                  setTimeout(closeup(), 3000);
                }

                myResolve(); // Output the fetched data
                // Perform any additional operations with the fetched data
              } else {
                //
                myReject();
              }
            } catch (error) {
              console.error('Error occurred:', error);
            }
          });

          // "Consuming Code" (Must wait for a fulfilled Promise)

          myPromise.then();
        }

        dateArr.push(startdate);
        index++; // Move the index increment outside of the forEach callback
      }

      j++;
    }
  };

  StoreScheduleclass = async (data) => {
    const url = `${BASE_URLs}Learning/AdminAddLearning`; // Replace with your API endpoint

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      });

      if (response.ok) {
        const data = await response.json();

        // Perform any additional operations with the fetched data
      } else {
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  DurationCalc(StartTime, EndTime) {
    const [hours, minutes] = StartTime.split(':').map(Number);
    const [hours1, minutes1] = EndTime.split(':').map(Number);

    let Time = hours * 60 + minutes;
    let Time1 = hours1 * 60 + minutes1;
    const minutesDifference = Time1 - Time;
    let hoursDifference = Math.floor(minutesDifference / 60);
    let remainingMinutes = minutesDifference % 60;

    if (hoursDifference >= 1 && hoursDifference <= 9) {
      hoursDifference = '0' + hoursDifference;
    }

    if (remainingMinutes === 0) {
      remainingMinutes = '00';
    } else if (remainingMinutes >= 1 && remainingMinutes <= 9) {
      remainingMinutes = '0' + remainingMinutes;
    }

    return hoursDifference + ':' + remainingMinutes;
  }

  convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  displayTimeFromDate(dateString) {
    const date = new Date(dateString);
    // Extracting hours, minutes, and seconds from the date
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Converting hours to 12-hour format (AM/PM)
    // let formattedHours = hours % 12;
    // formattedHours = formattedHours === 0 ? 12 : formattedHours;
    // const period = hours >= 12 ? 'PM' : 'AM';
    let hour = hours < 10 ? '0' + hours : hours;
    let minute = minutes < 10 && minutes != '00' ? '0' + minutes : minutes;
    const formattedTime = `${hour}:${minute}`;

    // Displaying the time in HH:MM:SS AM/PM format
    // const formattedTime = `${formattedHours}:${minutes}:${seconds} ${period}`;
    // const formattedTime = `${formattedHours}:${minutes} ${period}`;
    return formattedTime;
  }

  Closepop = () => {
    this.setState({
      secondPopupVisible: !this.state.secondPopupVisible,
    });
  };

  formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 19);
  };
  convertTime(dateString, hours) {
    const originalDate = new Date(dateString);
    originalDate.setHours(originalDate.getTime() + hours);
    const updatedDate = originalDate.toLocaleString('en-US', {
      timeZone: 'Asia/Kolkata',
    });
    return updatedDate;
  }

  getDatesByRecurrenceRule(recurrenceRule, startdate) {
    const ruleParts = recurrenceRule.split(';');
    const freq = ruleParts
      .find((part) => part.startsWith('FREQ='))
      .split('=')[1];
    const interval = parseInt(
      ruleParts.find((part) => part.startsWith('INTERVAL=')).split('=')[1]
    );
    const byDay = ruleParts.find((part) => part.startsWith('BYDAY='));
    const byMonthDay = ruleParts.find((part) => part.startsWith('BYMONTHDAY='));
    const byMonth = ruleParts.find((part) => part.startsWith('BYMONTH='));
    const byYearDay = ruleParts.find((part) => part.startsWith('BYYEARDAY='));
    const count =
      ruleParts.find((part) => part.startsWith('COUNT=')) !== undefined
        ? parseInt(
            ruleParts.find((part) => part.startsWith('COUNT=')).split('=')[1]
          )
        : parseInt('10');

    // Parsing values if present in the rule
    const parseValue = (param) =>
      param ? parseInt(param.split('=')[1]) : null;
    const parsedByDay = byDay ? byDay.split('=')[1].split(',') : null;
    const parsedByMonthDay = parseValue(byMonthDay);
    const parsedByMonth = parseValue(byMonth);
    const parsedByYearDay = parseValue(byYearDay);

    const weekdays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

    const today = new Date(startdate);
    let dates = [];
    let currentDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    let d = startdate;

    const checkByDay = (date) => {
      if (!parsedByDay) return true;

      const dayIndex = date.getDay();
      return parsedByDay.includes(weekdays[dayIndex]);
    };

    while (dates.length < count) {
      const currentMonth = currentDate.getMonth() + 1; // Month is 0-indexed

      if (
        (!parsedByMonth || currentMonth === parsedByMonth) &&
        (!parsedByMonthDay || currentDate.getDate() === parsedByMonthDay) &&
        (!parsedByYearDay || currentDate.getDayOfYear() === parsedByYearDay) &&
        checkByDay(currentDate)
      ) {
        dates.push(new Date(currentDate));
      }

      currentDate.setDate(currentDate.getDate() + interval);
    }

    dates = dates.map((e) => {
      return new Date(e).setHours(today.getHours(), today.getMinutes(), 0);
    });

    // startdate

    return dates;
  }

  // Example usage for a custom recurrence rule
  // const recurrenceRule = 'FREQ=MONTHLY;BYDAY=WE;BYSETPOS=-1;INTERVAL=1;';

  // const generatedDates = getDatesByRecurrenceRule(recurrenceRule); // Generating 10 occurrences

  // // Output the generated dates
  //
  // generatedDates.forEach(date => {
  //
  // });

  toggle_thirdPopupVisible = () => {
    this.setState((prevState) => ({
      thirdPopupVisible: !prevState.thirdPopupVisible,
    }));
  };

  handleFirstPopupOK = () => {
    const { selectedDateTime } = this.state;
    const selectedDate = selectedDateTime.format('DD-MM-YYYY');
    const selectedTime = selectedDateTime.format('HH:mm');

    this.setState({
      selectedDate,
      selectedTime,
    });

    this.setState({ secondPopupVisible: true });
  };

  handleDateTimeChange = (newValue) => {
    this.setState({
      selectedDateTime: newValue,
    });
  };

  handleSelectedDateAndTime = () => {
    const { selectedDateTime } = this.state;
    const selectedDate = selectedDateTime.format('DD-MM-YYYY');
    const selectedTime = selectedDateTime.format('HH:mm');

    this.setState({
      selectedDate,
      selectedTime,
      secondPopupVisible: true,
    });
  };

  data = [];

  render() {
    const divsWithoutId = document.querySelectorAll(
      'div[style*="z-index: 999999999"]'
    );
    divsWithoutId.forEach(function (div) {
      div.style.display = 'none';
      // });
    });

    const divsWithoutId1 = document.querySelectorAll(
      'div[style*="z-index: 99999"]'
    );
    divsWithoutId1.forEach(function (div) {
      div.style.display = 'none';
      // });
    });

    const currentDateTime = dayjs();
    const maxDate = currentDateTime.add(6, 'weeks');
    const { selectedDate, selectedTime, selectedDateTime } = this.state;
    const width1 = window.innerWidth;

    return (
      <div className="pop">
        <div
          className="popinnernormal"
          style={
            width1 > 800
              ? {
                  width: '45%',
                }
              : {
                  width: '80%',
                }
          }
        >
          <div className="row" style={{ padding: '8px' }}>
            <div className="col-sm-10">
              {this.props.TrainingState != null && (
                <b>
                  {this.props.TrainingState.trainingitem} {'('}
                  {this.validmsg(this.props.TrainingState.duration)}
                  {')'}
                </b>
              )}
            </div>

            {/* <div className="col-sm-4">{this.validmsg(this.props.TrainingState.duration)}</div> */}

            <div className="col-sm-1">
              <button
                style={{
                  backgroundColor: 'rgba(160, 163, 163, 0.02)',
                  color: 'rgb(121, 112, 112)',
                  border: '1px solid rgba(163, 165, 165, 0.02)',
                  float: 'right',
                }}
                onClick={(e) => this.props.onClose(e)}
              >
                <CloseIcon />
              </button>
            </div>

            {/* <LocalizationProvider dateAdapter={AdapterDayjs} style={{width:"1000px"}}>
              <DemoContainer
                components={[
                  "DateTimePicker",
                  "MobileDateTimePicker",
                  "DesktopDateTimePicker",
                  "StaticDateTimePicker",
                ]}
              >
                <DemoItem label="Training Schedule Time">
                  <StaticDateTimePicker
                    value={selectedDateTime}
                    onChange={this.handleDateTimeChange}
                    onCancel={this.props.onClose}
                    onAccept={this.handleSelectedDateAndTime}
                    minDateTime={currentDateTime}  
                    maxDateTime={maxDate}  
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider> */}

            <div className="Sama">
              <ScheduleComponent
                height={300}
                showQuickInfo={false}
                title="Double click to add"
                ref={(schedule) => (this.scheduleObj = schedule)}
                selectedDate={new Date()}
                eventSettings={{
                  dataSource: this.state.selectedOption,
                }}
                minDate={new Date(new Date()).setDate(new Date().getDate() - 1)}
                // quickInfoTemplates = {3}

                eventDelete={(e) => this.deleteEvent(e)}
                showWeekend={false}
              >
                <Inject services={[Day, Week, Month, Agenda]} />
              </ScheduleComponent>
            </div>
          </div>

          <div className="row">
            {/* <div className="col-lg-12"> */}
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <button
                // onMouseEnter ={(e) => this.show(e)}
                onClick={(e) => this.toggleAddTrainerPopup(e)}
                className="but"
                style={{ marginTop: '20px', marginLeft: '20px' }}
              >
                {' '}
                Save
              </button>
            </div>
            <div className="col-lg-4"></div>
            {/* </div> */}
          </div>
        </div>

        {this.state.secondPopupVisible && (
          <TrainingAddTrainerPop
            selectedTrainingTopicId={this.state.selectedTrainingTopicId}
            state={(state) => this.setState({ selectedOption: state })}
            third={this.toggle_thirdPopupVisible}
            closeThePopup={(state, e) => this.toggleSaveTrainerPopup(state, e)}
            closeThePopupNoSave={() =>
              this.setState((prevState) => ({
                secondPopupVisible: !prevState.secondPopupVisible,
              }))
            }
            WholeDates={this.state.WholeDates}
            ClassObj={this.state.ClassObj}
            FinalSubmit={(e) => {
              //this.handleSubmit(e);
            }}
          />
        )}

        {/*///Start date popup*/}

        {this.state.thirdPopupVisible && (
          <AddTrainingAddTrainee
            FinalSubmit={(e) => {
              // this.handleSubmit(e);
            }}
            state={(state) => this.setState({ selectedTrainee: state })}
            closeThePopup={this.toggleAddTrainerPopup}
            third={this.toggle_thirdPopupVisible}
          />
        )}
      </div>
    );
  }
}
