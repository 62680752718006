import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";

import { ToastContainer, toast } from "react-toastify";
import CommonSuccess from "../Common/CommonSuccess";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class AdminSkillPop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      RelationshipDrop: [],
      formErrors: {},
      Department: [],
      categ: [],
    };
  }
  handleSubmit = async (event) => {
    event.preventDefault();

    // if (this.handleFormValidation()) {
    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem("user"));

    const accessToken = user;
    const url = `${BASE_URLs}Skill/post`;
    const options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: data,
    };

    const response = await fetch(url, options);
    const result = await response.json();

    this.setState({ PopMessage: result.message });

    if (result.status === "Success") {
      this.SuccessModel();
      setTimeout(() => {
        this.props.history.push("/MasterSkill");
      }, 2000);
    } else {
      toast.error(result.message, {
        position: "top-center",
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    //  }
  };

  Validation() {
    const { Category } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Email

    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  Getcategory() {
    let url = `${BASE_URLs}Skill/GetAdminCategory`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          categ: data,
        });
      });
  }

  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));
  };

  componentDidMount() {
    // this.RelationshipDropList();
    this.Getcategory();
  }

  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }

  render() {
    const { categ } = this.state;
    //  const {CategoryErr}= this.state.formErrors;
    const { Department } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {this.state.SuccessModel ? (
          <CommonSuccess
            id="Success"
            message={this.state.PopMessage}
            close={() => this.SuccessModel()}
          />
        ) : null}
        <div className="pop">
          <div className="popinnernormal" style={{ padding: "1%" }}>
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>

            <form
              autoComplete="on"
              encType="multipart/form-data"
              onSubmit={this.handleSubmit}
            >
              <div className="container">
                <div className="row" id="skill-form">
                  <div className="col-lg-4">
                    <input
                      type="text"
                      className="form"
                      id="Category"
                      name="Category"
                      onInputCapture={this.HandlePg}
                      onChange={this.handleChange}
                    ></input>
                    <span style={{ color: "#a29d9d" }}> Category </span>
                    {/* {!CategoryErr ? <span style={{ color: '#a29d9d' }}>Category</span>: <div style={{ color: "red" }}>{CategoryErr}</div>}  */}
                    <span className="underline" />
                    <input
                      type="hidden"
                      className="form"
                      id="pgtext"
                      name="OtherCategory"
                      onChange={this.handleChange}
                      placeholder="Category"
                    />
                  </div>

                  <div className="col-lg-4">
                    <input
                      className="form"
                      id="SkillName"
                      name="SkillName"
                      type="text"
                      onChange={this.handleChange}
                    />
                    <span style={{ color: "#a29d9d" }}> Skill Name </span>
                    {/* {!SkillNameErr ?  <span style={{ color: '#a29d9d' }}> Name </span>:    <div style={{ color: "red" }}>{SkillNameErr}</div>          }  */}
                    <span className="underline" />
                  </div>

                  <div className="col-lg-4">
                    <input
                      className="form"
                      id="SkillDescription"
                      name="SkillDescription"
                      type="text"
                      onChange={this.handleChange}
                    />
                    <span style={{ color: "#a29d9d" }}>
                      {" "}
                      Skill Description{" "}
                    </span>
                    {/* {!SkillDescriptionErr ?<span style={{ color: '#a29d9d' }}> Description </span> :<div style={{ color: "red" }}>{SkillDescriptionErr}</div>    }  */}
                    <span className="underline" />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12" style={{ textAlign: "center" }}>
                    <button
                      type="submit"
                      className="but"
                      style={{
                        height: "39.5px",
                        width: "86.36px",
                        marginTop: "-0.3%",
                      }}
                    >
                      Save
                    </button>
                    &nbsp; &nbsp; &nbsp;
                    <button
                      type="reset"
                      className=" btn-Secondary"
                      style={{ height: "39.5px" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <br />
          </div>
        </div>
      </>
    );
  }
}
