import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../Config/Base";
export default class AddLocationModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      isChrome: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(event) {
    event.preventDefault();

    if (this.Validation()) {
      const data = new FormData(event.target);

      fetch(`${BASE_URLs}AdminLocation/AddLocation`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // 'Accept': 'application/json',
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            document.getElementById("AddForm").style.display = "none";
            setTimeout(function () {
              document.getElementById("close").click();
            }, 4000);
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(function () {
              document.getElementById("close").click();
            }, 2300);
          }
        });
    }
  }
  handlechange = (e) => {
    let formErrors = {};
    let formIsValid = true;
    var Address = document.getElementById("Address").value;
    var Branch = document.getElementById("Branch").value;
    var LocationType = document.getElementById("LocationType").value;

    if (!Address || Address.trim() == "") {
      formIsValid = false;
      formErrors["Address"] = "Address  is required";
    } else if (!Branch || Branch.trim() == "") {
      formIsValid = false;
      formErrors["Branch"] = "Branch  is required";
    } else if (Branch.length < 3) {
      formIsValid = false;
      formErrors["Branch"] = "Minimum 3 Characters Required";
    } else if (!LocationType || LocationType.trim() == "") {
      formIsValid = false;
      formErrors["LocationType"] = "Location is required";
    } else if (LocationType.length < 3) {
      formIsValid = false;
      formErrors["LocationType"] = "Minimum 3 Characters Required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  };

  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var Address = document.getElementById("Address").value;
    var Branch = document.getElementById("Branch").value;
    var LocationType = document.getElementById("LocationType").value;

    if (!Address) {
      formIsValid = false;
      formErrors["Address"] = "Address  is required";
    }
    if (!Branch) {
      formIsValid = false;
      formErrors["Branch"] = "Branch  is required";
    }
    if (!LocationType) {
      formIsValid = false;
      formErrors["LocationType"] = "Location Type  is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  componentDidMount() {
    const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
    this.setState({ isChrome });
  }


  render() {
    var w = window.innerWidth;
    const { Address, LocationType, Branch } = this.state.formErrors;
    const { isChrome } = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal threeFieldPop"
                : "popinnernormal threeFieldPopMobile"
            }
            style={{ padding: "1%" }}
            id="AddForm"
          >
            <button
              id="close"
              style={{
                float: "right",
                backgroundColor: "#a0a3a305",
                color: "#797070",
                border: "1px solid #a3a5a505",
              }}
              onClick={this.props.close}
            >
              <CloseIcon />
            </button>

            <br />
            <form autoComplete="off" onSubmit={this.handleSubmit.bind(this)}>
              <div className="row">
                <div className="col-sm-4">
                  <textarea
                    className="form"
                    id="Address"
                    onChange={this.handlechange}
                    name="Address"
                    style={{ height: "36px" }}
                  />
                  {Address == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Address
                      <span style={{ color: "red", float: "left" }}></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>{Address}</div>
                  )}{" "}
                  <span className="underline" />{" "}
                </div>
                <div className="col-sm-4">
                  <input
                    className="form"
                    onChange={this.handlechange}
                    maxLength="50"
                    id="Branch"
                    name="Branch"
                    type="text"
                  />
                  {Branch == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Branch
                      <span style={{ color: "red", float: "left" }}></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>{Branch}</div>
                  )}{" "}
                  <span className="underline" />{" "}
                </div>
                <div className="col-sm-4">
                  <select
                    className="effect-16"
                    onChange={this.handlechange}
                    maxLength="50"
                    id="LocationType"
                    name="LocationType"
                    type="text"
                    style={{
                      // Apply the marginLeft style only if the browser is Chrome
                      ...(isChrome && { marginLeft: '-11px' })
                    }}
                  >
                     <option value="">Select</option>
                     <option value="Primary Location">Primary Location</option>
                     <option value="Secondary Location">Secondary Location</option>

                   
                                          </select>
                  {LocationType == null ? (
                    <span style={{ color: "#a29d9d", float: "left" }}>
                      Location
                      <span style={{ color: "red", float: "left" }}></span>{" "}
                    </span>
                  ) : (
                    <div style={{ color: "red", float: "left" }}>
                      {LocationType}
                    </div>
                  )}{" "}
                </div>{" "}
                <span className="underline" />
              </div>

              <div className="row">
                <div className="col-sm-12" style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="but"
                    style={{
                      height: "39.5px",
                      width: "86.36px",
                      marginTop: "-0.3%",
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
            <br />
          </div>
        </div>
      </>
    );
  }
}
