import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessPopup1 from "../../Common/SuccessPopup1";
import { Button } from "@material-ui/core";
import { BASE_URLs } from "../../Config/Base";
export default class EntityList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Announcement: [],
      AllAnnouncement: [],
      Team: [],
      Teams: [],
      showing: true,
      profile: [],
      EditSkillModel: false,
      EditSkillValues: [],
      Id: "",
      SkillName: "",
      User: [],
      Alluser: [],
    };
    this.initialState = this.state; // this.handleContentClick = this.handleContentClick.bind(this);
  }
  UserOrg() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}AddOrganization/status1`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ User: data, Alluser: data });
        // console.log("user data", data);
      });
  }
  EditSkillModel = (e, fn) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditSkillValues: {
        id: e,
        firstName: fn,
      },
    });
    this.setState((prevState) => ({
      EditSkillModel: !prevState.EditSkillModel,
    }));
    //   this.GetAddSkill();
  };
  GetAnnouncement() {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(`${BASE_URLs}UserBank/GetAllUser`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Announcement: data,
          AllAnnouncement: data,
        });

        // console.log("New", data);

        //
      });
  }
  myFunction = (e) => {
    const Announcement = this.state.AllAnnouncement.filter((item) =>
      item.firstName.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Announcement });
  };

  OATSearch = (e) => {
    const User = this.state.Alluser.filter((item) =>
      item.personname.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ User });
  };
  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }

  TeamUser() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Team/GetTeam`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Team: data,
          Teams: data,
        });
        // console.log("hess", data);
      });
  }
  componentDidMount() {
    this.GetAnnouncement();
    this.UserOrg();
    this.TeamUser();
  }

  render() {
    var w = window.innerWidth;
    const { Announcement, User, Team } = this.state;

    return (
      <>
        <div>
          {this.state.EditSkillModel ? (
            <SuccessPopup1
              values={this.state.EditSkillValues}
              close={() => this.EditSkillModel()}
            />
          ) : null}
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body chat-bg">
                <div className="">
                  <div id="" className="">
                    <div className="page-body">
                      <div className="row">
                        <div className="chat-box">
                          <div id="sidebar" className="users p-chat-user">
                            <div className="had-container">
                              <div
                                className="card card_main p-fixed users-main "
                                style={{ overflow: "scroll" }}
                              >
                                <div className="user-box">
                                  <div id="tsum-tabs">
                                    <main id="maintab">
                                      {/* <input id="tab1" type="radio" name="tabs" checked/>
    <label for="tab1">In Entity</label> */}
                                      <input
                                        id="tab1"
                                        type="radio"
                                        name="tabs"
                                        defaultChecked
                                      />
                                      <label
                                        id="supertab"
                                        className="j-tabs-label"
                                        htmlFor="tab1"
                                        title="In Entity"
                                        color="white"
                                      >
                                        In Entity
                                      </label>

                                      {/* <input id="tab2" type="radio" name="tabs"/>
    <label for="tab2">In OAT</label> */}
                                      <input
                                        id="tab2"
                                        type="radio"
                                        name="tabs"
                                      />
                                      <label
                                        id="activetab"
                                        className="j-tabs-label"
                                        htmlFor="tab2"
                                        title="In OAT"
                                        color="white"
                                      >
                                        In OAT
                                      </label>

                                      {/* <input id="tab3" type="radio" name="tabs"/>
    <label for="tab3">Invite</label>  */}

                                      <div
                                        id="tabs-section-1"
                                        className="j-tabs-section"
                                      >
                                        {/* <section id="content1" style={{minHeight:"0"}} > */}
                                        {/* <div className="col-lg-3  btnalign">
                              <input className="Searchbox" placeholder="Search" onKeyUp={this.myFunction} style={{backgroundColor:"transparent"}} />
                              </div> */}
                                        <div className="right-icon-control">
                                          <input
                                            type="text"
                                            className="form-control  search-text"
                                            placeholder="Search Friend"
                                            onChange={this.myFunction}
                                          />
                                          <div className="form-icon">
                                            <i className="icofont icofont-search"></i>
                                          </div>
                                        </div>

                                        <>
                                          {" "}
                                          <br />
                                          {/* {Team.map((Team) =>(
      <>
    <div className="media userlist-box" data-id="1" data-status="online" data-username="Josephin Doe" data-toggle="tooltip" data-placement="left" title="Josephin Doe"
    // onClick={(e)=>{e.preventDefault();this.EditSkillModel(Team.id,Team.firstName) }} key={id}
    >
<a className="media-left" >
<img className="media-object img-circle" src="assets/images/avatar-1.png" alt="Generic placeholder image"/>

</a>
<div className="media-body" style={{textAlign:"initial"}}>
<div className="f-13 chat-header" style={{color:"black"}}>{Team.firstName}</div>
</div>
</div></>
    ))} */}
                                        </>
                                        {/* </section> */}
                                      </div>

                                      <div
                                        id="tabs-section-2"
                                        className="j-tabs-section"
                                      >
                                        {/* <section id="content2" style={{minHeight:"0"}}> */}
                                        {/* <div className="col-lg-3  btnalign">
                              <input className="Searchbox" placeholder="Search" onKeyUp={this.OATSearch} style={{backgroundColor:"transparent"}} />
                              </div> */}
                                        <div className="right-icon-control">
                                          <input
                                            type="text"
                                            className="form-control  search-text"
                                            placeholder="Search Friend"
                                            onChange={this.OATSearch}
                                          />
                                          <div className="form-icon">
                                            <i className="icofont icofont-search"></i>
                                          </div>
                                        </div>
                                        <br />
                                        {User.map((user) => (
                                          <div
                                            className="media userlist-box"
                                            data-id="2"
                                            data-status="online"
                                            data-username="Josephin Doe"
                                            data-toggle="tooltip"
                                            data-placement="left"
                                            title="Josephin Doe"
                                          >
                                            <a className="media-left">
                                              <img
                                                className="media-object img-circle"
                                                src="assets/images/avatar-1.png"
                                                alt="Generic placeholder image"
                                              />
                                            </a>
                                            <div
                                              className="media-body"
                                              style={{ textAlign: "initial" }}
                                            >
                                              <div
                                                className="f-13 chat-header"
                                                style={{ color: "black" }}
                                              >
                                                {user.personname}&nbsp;&nbsp;
                                                {user.company_Name}
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                        {/* </section> */}
                                      </div>

                                      {/* <section id="content3" style={{minHeight:"0"}}>
      <br/>
      <div className="media userlist-box" style={{display:"flow-root"}}>
      <input className="effect-16" onFocus={(e) => e.target.select()}  type="text" id="Email" name="Email"    placeholder="Enter Email" style={{borderBottomWidth:"1px solid black"}}/>
      <br/><br/>
<div className="media-body" >

<button    id="addRow" className="btn">Invite</button>
</div>
</div>
    </section> */}
                                    </main>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="styleSelector"></div>
            </div>
          </div>
        </div>
        {/* <div className="pop" style={{backgroundColor:"transparent"}}>
       <div className= {w>768 ?"popinnernormal oneFieldPop":"popinnernormal oneFieldPopMobile"} style={{padding:"1%"}}> 
      <form  autoComplete="off" encType="multipart/form-data"  >
 

           
 
   
      
    


   
  <br/>

           </form>
      </div> 
   </div>   */}
      </>
    );
  }
}
