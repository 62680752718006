import React, { Component } from "react";
import $ from "jquery";
import SideBar from "../Common/Sidebar";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessPopup from "../Common/SuccessPopup";
import { BASE_URLs } from "../Config/Base";
import LoadingPage from "../Common/LoadingPage";

$(document).ready(function () {
  $("#filePHOTO").change(function () {
    $("#file_error").html("");
    $(".file_upload1").css("border-color", "#F0F0F0");
    var file_size = $("#filePHOTO")[0].files[0].size;
    if (file_size > 300000) {
      $("#file_error").html(
        "<p style='color:#FF0000'>File size is greater than 300kb</p>"
      );
      $(".file_upload1").css("border-color", "#FF0000");
      return false;
    }
    return true;
  });
});

///for GSt
// $(document).on('change', "#GSTno", function () {
//   var inputvalues = $(this).val();
//   var gstinformat = new RegExp('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$');
//   if (gstinformat.test(inputvalues.trim())) {
//     return true;
//   } else {
//     alert('Please Enter Valid GSTIN Number');
//     $("#GSTno").val('');
//     $("#GSTno").focus();
//   }

// });
export default class AddOrganization extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();

    this.state = {
      company_Id: "",
      Company_Name: "",
      aliasname: "",
      Com_logo: "",
      adrs1: "",
      country: "",
      state: "",
      city: "",
      mobile: "",
      lanline: "",
      Email: "",
      SEmail: "",
      personname: "",
      Zip: "",
      GSTno: "",
      PANno: "",
      GST: "",
      Pan: "",
      formErrors: {},
      imagePreviewUrl: "",
      imagePreviewUrlPAN: "",
      imagePreviewUrlGST: "",
      Notification: [],
      OrgEmail: [],
      SaveOrg: [],
      savstatus: "",
      SuccessModel: false,
      Preview: null,
      Status: "",
      imagedisplayPAN: null,
      imagedisplayGST: null,
      loading: false,
      isSelected: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handlePanImageChange = this.handlePanImageChange.bind(this);
    this.handleGstImageChange = this.handleGstImageChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }
  HandleImage = (e) => {
    var src = URL.createObjectURL(e.target.files[0]);
    this.setState({ Preview: src });
  };

  handleReset() {
   window.location.reload(true);
  }

  HandlePanFormat(e) {
    var input = e.target.value.trim();
    var regex = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (!regex.test(input.trim())) {
      if (document.getElementById("PANno").value) {
        alert("Please Enter Valid PAN Number");
      }
      document.getElementById("PANno").value = "";

      return regex.test(input.trim());
    } else {
    }
  }
  handleGSTFormat = (e) => {
    const input = e.target.value.trim();
    const regex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/;
    if (!regex.test(input)) {
      if (document.getElementById("GSTno").value) {
        alert("Please Enter a Valid GST Number");
      }
      document.getElementById("GSTno").value = "";
      return regex.test(input.trim());
    } else {
    }
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    var personname = document.getElementById("personname").value;
    var lanline = document.getElementById("lanline").value;
    var mobile = document.getElementById("mobile").value;
    var Email = document.getElementById("Email").value;
    var SEmail = document.getElementById("SEmail").value;
    var adrs1 = document.getElementById("adrs1").value;
    var city = document.getElementById("city").value;
    var country = document.getElementById("country").value;
    var state = document.getElementById("state").value;
    var Zip = document.getElementById("Zip").value;
    var website = document.getElementById("website").value;
    var PANno = document.getElementById("PANno").value;

    var GSTno = document.getElementById("GSTno").value;
    var Pan = document.getElementById("Pan").value;
    var GST = document.getElementById("GST").value;

    var Com_logo = document.getElementById("Profilepicinfo").value;

    let formErrors = {};
    let formIsValid = true;
    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
  var urlpattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.(com|in|org|net|edu|gov)|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.(com|in|org|net|edu|gov)|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.(com|in|org|net|edu|gov)|www\.[a-zA-Z0-9]+\.(com|in|org|net|edu|gov))/gi;;
  // var urlpattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.(com|in|org|net|)|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.(com|in|org|net))/gi;

 //  var urlpattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}(com|net|org|edu|int|gov|mil|arpa|biz|info|name|museum|coop|aero)|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}(com|net|org|edu|int|gov|mil|arpa|biz|info|name|museum|coop|aero)|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}(com|net|org|edu|int|gov|mil|arpa|biz|info|name|museum|coop|aero)|www\.[a-zA-Z0-9]+\.[^\s]{2,}(com|net|org|edu|int|gov|mil|arpa|biz|info|name|museum|coop|aero))/gi;

    ;
    var zipf = Zip.slice(0, 1);
    var reg = /(.*?)\.(jpg|pdf|jpeg|png|docx|xlsx|pdf|doc|ppt)$/;
    var clogo = /(.*?)\.(jpg|jpeg|png)$/;

    if (country == "India") {
      // alert(country);

      this.setState({
        isSelected: true,
      });
    } else if (country != "India") {
      this.setState({
        isSelected: false,
      });
    }

    if (!personname || personname === " ") {
      formIsValid = false;
      formErrors["personnameErr"] = "Contact Person is required";
    } else if (personname.length < 3) {
      formIsValid = false;
      formErrors["personnameErr"] = "A Minimum of 3 characters are required";
    } else if (mobile === " " || !mobile) {
      formIsValid = false;
      formErrors["mobileErr"] = "Primary Mobile Number is required";
    } else if (!mobPattern.test(mobile)) {
      formIsValid = false;
      formErrors["mobileErr"] = "Invalid phone number";
    } 
    else if (lanline.trim()) {
      if (!mobPattern.test(lanline)) {
        formIsValid = false;
        formErrors["lanlineErr"] = "Invalid Mobile Number";
      } else if (mobile === lanline) {
        formIsValid = false;
        formErrors["lanlineErr"] =
          "Primary Number and Alternate Number should not be same";
      }
    } 
    else if (SEmail.trim()) {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(SEmail)) {
        formIsValid = false;
        formErrors["SEmailErr"] = "Invalid Email Id";
      } else if (Email === SEmail) {
        formIsValid = false;
        formErrors["SEmailErr"] =
          "Email and Secondary Email should not be same";
      }
    }
    // SEmail
    //  else if (lanline.trim()) {
    //     if (!mobPattern.test(lanline))
    //     {
    //       formIsValid = false;
    //       formErrors["lanlineErr"] = "Invalid Mobile Number";
    //     }
    //   }
    //   // SEmail

    //    else if (SEmail.trim()) {
    //          if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(SEmail)))
    //          {
    //            formIsValid = false;
    //            formErrors["SEmailErr"] = "Invalid Email Id";
    //          }
    //    }

    //contact person
    //addres
    else if (adrs1 === " " || !adrs1) {
      formIsValid = false;
      formErrors["adrs1Err"] = "Address is required";
    } else if (adrs1.length < 10) {
      formIsValid = false;
      formErrors["adrs1Err"] = "A Minimum of 10 characters are required";
    }
    //city
    else if (city === " " || !city) {
      formIsValid = false;
      formErrors["cityErr"] = " City is required";
    } else if (city.length < 3) {
      formIsValid = false;
      formErrors["cityErr"] = "A Minimum of 3 characters are required";
    }
    //country
    else if (country === "" || !country) {
      formIsValid = false;
      formErrors["countryErr"] = " Country is required";
    } else if (country.length < 3) {
      formIsValid = false;
      formErrors["countryErr"] = "A Minimum of 3 characters are required";
    }
    //state
    else if (state === " " || !state) {
      formIsValid = false;
      formErrors["stateErr"] = " State is required";
    } else if (state.length < 3) {
      formIsValid = false;
      formErrors["stateErr"] = "A Minimum of 3 characters are required";
    }

    //Pincode
    else if (!Zip || Zip === " ") {
      formIsValid = false;
      formErrors["ZipErr"] = "Pincode is required";
    } else if (zipf === "0") {
      formIsValid = false;
      formErrors["ZipErr"] = "Pincode should not start with 0";
    } else if (Zip.length < 6) {
      formIsValid = false;
      formErrors["ZipErr"] = " A Minimum of 6 characters are required";
    } 
    else if (!website || website.trim() === "") {
    formIsValid = false;
    formErrors["websiteErr"] = "Official website is required";
    } 
    else if (/\s/.test(website.charAt(0))) {
      formIsValid = false;
      formErrors["websiteErr"] = "Whitespace at the beginning is not allowed";
  } 
    else if
     (!urlpattern.test(website.trim())) {
      formIsValid = false;
      formErrors["websiteErr"] = "In valid website";
    } 
    else if (!PANno || PANno === " ") {
      formIsValid = false;
      formErrors["PANnoErr"] = "PAN Number is required";
    } else if (!GSTno || GSTno === " ") {
      formIsValid = false;
      formErrors["GSTnoErr"] = "GST Number is required";
    } else if (GSTno.includes(PANno) === false) {
      formIsValid = false;
      formErrors["GSTnoErr"] = "GST Number and PAN Number not matched";
    } else if (GSTno === PANno) {
      formIsValid = false;
      formErrors["GSTnoErr"] = "Invalid GST Number";
    } 
    else if (GSTno.length < 15) {
      formIsValid = false;
      formErrors["GSTnoErr"] = "Invalid GST Number";
    }else if (!Pan || Pan === " ") {
      formIsValid = false;
      formErrors["PanErr"] = "PAN Copy is required";
    } else if (!Pan.match(reg)) {
      formIsValid = false;
      formErrors["PanErr"] = "Only Image and Document can be Added";
    } else if (!GST || GST === " ") {
      formIsValid = false;
      formErrors["GSTErr"] = "GST Copy is required";
    } else if (!GST.match(reg)) {
      formIsValid = false;
      formErrors["GSTErr"] = "Only Image and Document can be Added";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  };

  handleFormValidation() {
    var personname = document.getElementById("personname").value;
    var lanline = document.getElementById("lanline").value;
    var mobile = document.getElementById("mobile").value;
    var Email = document.getElementById("Email").value;
    var SEmail = document.getElementById("SEmail").value;
    var adrs1 = document.getElementById("adrs1").value;
    var city = document.getElementById("city").value;
    var country = document.getElementById("country").value;
    var state = document.getElementById("state").value;
    var Zip = document.getElementById("Zip").value;
    var website = document.getElementById("website").value;
    var PANno = document.getElementById("PANno").value;
    var GSTno = document.getElementById("GSTno").value;
    var Pan = document.getElementById("Pan").value;
    var GST = document.getElementById("GST").value;

    var Com_logo = document.getElementById("Profilepicinfo").value;

    let formErrors = {};
    let formIsValid = true;
    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
    var reg = /(.*?)\.(jpg|pdf|jpeg|png|docx|xlsx|pdf|doc|ppt)$/;
    var clogo = /(.*?)\.(jpg|jpeg|png)$/;
    var zipf = Zip.slice(0, 1);
    var urlpattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.(com|in|org|net|edu|gov)|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.(com|in|org|net|edu|gov)|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.(com|in|org|net|edu|gov)|www\.[a-zA-Z0-9]+\.(com|in|org|net|edu|gov))/gi;;

    if (!personname || personname === " ") {
      window.scroll(0, 0);

      formIsValid = false;
      formErrors["personnameErr"] = "Contact Person is required";
    } else if (personname.length < 3) {
      formIsValid = false;
      formErrors["personnameErr"] = "A Minimum of 3 characters are required";
    }
    // SEmail
    if (mobile === " " || !mobile) {
      formIsValid = false;
      formErrors["mobileErr"] = "Primary Mobile Number is required";
    } else {
      if (!mobPattern.test(mobile)) {
        formIsValid = false;
        formErrors["mobileErr"] = "Invalid phone number";
      }
    }

    if (lanline.trim()) {
      if (!mobPattern.test(lanline)) {
        formIsValid = false;
        formErrors["lanlineErr"] = "Invalid Mobile Number";
      } else if (mobile === lanline) {
        formIsValid = false;
        formErrors["lanlineErr"] =
          "Primary Number and Alternate Number should not be same";
      }
    }

    // SEmail

    if (SEmail.trim()) {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(SEmail)) {
        formIsValid = false;
        formErrors["SEmailErr"] = "Invalid Email Id";
      } else if (Email === SEmail) {
        formIsValid = false;
        formErrors["SEmailErr"] =
          "Email and Secondary Email should not be same";
      }
    }

    //addres
    if (adrs1 === " " || !adrs1) {
      formIsValid = false;
      formErrors["adrs1Err"] = "Address is required";
    } else if (adrs1.length < 3) {
      formIsValid = false;
      formErrors["adrs1Err"] = "A Minimum of 3 characters are required";
    }
    //country
    if (country === "" || !country) {
      formIsValid = false;
      formErrors["countryErr"] = " Country is required";
    } else if (country.length < 3) {
      formIsValid = false;
      formErrors["countryErr"] = "A Minimum of 3 characters are required";
    }
    //state
    if (state === " " || !state) {
      formIsValid = false;
      formErrors["stateErr"] = " State is required";
    } else if (state.length < 3) {
      formIsValid = false;
      formErrors["stateErr"] = "A Minimum of 3 characters are required";
    }
    //city
    if (city === " " || !city) {
      formIsValid = false;
      formErrors["cityErr"] = " City is required";
    } else if (city.length < 3) {
      formIsValid = false;
      formErrors["cityErr"] = "A Minimum of 3 characters are required";
    }

    //Pincode
    if (!Zip.trim()) {
      formIsValid = false;
      formErrors["ZipErr"] = "Pincode is required";
    } else if (zipf === "0") {
      formIsValid = false;
      formErrors["ZipErr"] = "Pincode should not start with 0";
    } else if (Zip.length < 6) {
      formIsValid = false;
      formErrors["ZipErr"] = "A Minimum of 6 characters are required";
    }

    if (!website || website === " ") {
      formIsValid = false;
      formErrors["websiteErr"] = "Official website is required";
    }
    else if
    (!urlpattern.test(website.trim())) {
     formIsValid = false;
     formErrors["websiteErr"] = "In valid website";
   } 
    if (!PANno || PANno === " ") {
      formIsValid = false;
      formErrors["PANnoErr"] = "PAN Number is required";
    }
    if (!GSTno || GSTno === " ") {
      formIsValid = false;
      formErrors["GSTnoErr"] = "GST Number is required";
    }
    if (PANno != null) {
      if (GSTno.includes(PANno) === false) {
        formIsValid = false;
        formErrors["GSTnoErr"] = "GST Number and PAN Number not matched";
      }
    }

    if (PANno != null && GSTno != null) {
      if (GSTno === PANno) {
        formIsValid = false;
        formErrors["GSTnoErr"] = "Invalid GST Number";
      }
    }
    if (!Pan || Pan === " ") {
      formIsValid = false;
      formErrors["PanErr"] = "PAN Copy is required";
    }
    if (!Pan.match(reg)) {
      formIsValid = false;
      formErrors["PanErr"] = "Only Image and Document can be Added";
    }
    if (!GST || GST === " ") {
      formIsValid = false;
      formErrors["GSTErr"] = "GST Copy is required";
    }
    if (!GST.match(reg)) {
      formIsValid = false;
      formErrors["GSTErr"] = "Only Image and Document can be Added";
    }

    this.setState({ formErrors: formErrors });
    return formIsValid;
  }

  Getall() {
    fetch(`${BASE_URLs}AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }

  GetOrgEmail() {
    let url = `${BASE_URLs}AddOrganization/GetorgEmail`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ OrgEmail: data });
      });
  }

  GetSavedOrganization() {
    let url = `${BASE_URLs}AddOrganization/GetSavedOrganization`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ SaveOrg: data });
        this.setState({ Status: data[0].status });

        if (data[0].status !== 3) {
          if (data[0].status !== 4) {
            this.props.history.goBack();
          }
        }
      });
  }

  handleImageChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });

    this.setState({ imagePreviewUrl: URL.createObjectURL(e.target.files[0]) });
  }

  handlePanImageChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });

    this.setState({
      imagePreviewUrlPAN: URL.createObjectURL(e.target.files[0]),
    });

    var result = value.substring(value.lastIndexOf("\\") + 1);
    this.setState({ imagedisplayPAN: result });
  }

  handleGstImageChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value });

    this.setState({
      imagePreviewUrlGST: URL.createObjectURL(e.target.files[0]),
    });
    var result = value.substring(value.lastIndexOf("\\") + 1);
    this.setState({ imagedisplayGST: result });
  }

  uploadFile = (target) => {
    document.getElementById("file-name").innerHTML = target.files[0].name;
  };

  previewpan = (event) => {
    var reader = new FileReader();

    reader.onloadend = function (e) {
      this.setState({
        gstpic: [reader.result],
      });
    }.bind(this);
  };

  previewgst = (event) => {
    // Assuming only image

    var reader = new FileReader();

    reader.onloadend = function (e) {
      this.setState({
        panpic: [reader.result],
      });
    }.bind(this);
  };
  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));
  };

  handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);
    if (this.state.savstatus === 2) {
      if (this.handleFormValidation()) {
        setTimeout(() => {
          this.setState({ loading: true });
        }, 500);
        fetch(`${BASE_URLs}AddOrganization/post`, {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
          body: data,
        })
          .then((res) => res.json())
          .then((resp) => {
            this.setState({ message: resp.message });
            if (resp.status === "Success") {
              setTimeout(() => {
                this.setState({ loading: false });
              }, 500);
              this.SuccessModel();
              var allCookies = document.cookie.split(";");
              for (var i = 0; i < allCookies.length; i++) {
                document.cookie =
                  allCookies[i] + "=;expires=" + new Date(0).toUTCString();
              }
            }
          });
      }
    } else if (this.state.savstatus === 1) {
      setTimeout(() => {
        this.setState({ loading: true });
      }, 50);
      fetch(`${BASE_URLs}AddOrganization/SaveOrganize`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            setTimeout(() => {
              this.setState({ loading: false });
            }, 500);
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });

      this.setState({ imagePreviewUrl: null });
      this.setState({ imagePreviewUrlPAN: null });
      this.setState({ imagePreviewUrlGST: null });
    }
  }

  click = (e) => {
    e.preventDefault();
    const { id } = e.target;

    id === "save"
      ? this.setState({
          savstatus: 1,
        })
      : this.setState({
          savstatus: 2,
        });
  };

  componentDidMount() {
    this.GetOrgEmail();
    this.GetSavedOrganization();
  }
  handleFocus = (event) => event.target.select();

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    this.props.history.push("/Login_form");
  };
  handleredirect = (event) => {
    event.preventDefault();

    var page = {
      pathname: "./Organization",
    };
    this.props.history.push(page);
  };

  AutoCaps(e) {
    let value =
      e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    e.target.value = value;
  }

  render() {
    const {
      mobileErr,
      lanlineErr,
      EmailErr,
      personnameErr,
      adrs1Err,
      countryErr,
      stateErr,
      cityErr,
      ZipErr,
      ClogoErr,
      GSTnoErr,
      PANnoErr,
      GSTErr,
      PanErr,
      websiteErr,
      SEmailErr,
      isSelected,
    } = this.state.formErrors;

    const { OrgEmail, SaveOrg } = this.state;
    return (
      <div>
        <SideBar active={window.location.hash} />

        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header"></div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}></div>
                          <h4 style={{ marginRight: "1%" }} className="title">
                            {" "}
                            Entity Profile
                          </h4>
                        </div>
                        <div className="card-header-right"></div>
                        {this.state.SuccessModel ? (
                          <SuccessPopup
                            id="Success"
                            message={this.state.message}
                            close={() => this.SuccessModel()}
                          />
                        ) : null}
                        <div className="card-block2">
                          <form id="subOrg" onSubmit={this.handleSubmit}>
                            <div className="row" style={{ marginTop: "2%" }}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <table className="table m-0">
                                      <tbody>
                                        <div className="row">
                                          <div className="col-sm-3"></div>

                                          <div
                                            className="col-sm-6 clogo"
                                            style={{ flexDirection: "column" }}
                                          >
                                            <div>
                                              <label
                                                style={{ height: "105px" }}
                                              >
                                                {SaveOrg.map((SaveOrg) => (
                                                  <>
                                                    <img
                                                      alt="Entitylogo"
                                                      src={
                                                        !this.state.Preview
                                                          ? SaveOrg.com_logo
                                                          : this.state.Preview
                                                      }
                                                      id="clogo"
                                                      style={{
                                                        height: "100px",
                                                        width: "100px",
                                                        marginLeft: "4.6%",
                                                        borderRadius: "50px",
                                                      }}
                                                    />
                                                    <input
                                                      type="hidden"
                                                      name="OldCom_logo"
                                                      value={SaveOrg.com_logo}
                                                    />
                                                  </>
                                                ))}
                                                <i
                                                  className=" fa fa-pencil editbtn"
                                                  aria-hidden="true"
                                                  style={{ fontSize: "20px" }}
                                                ></i>
                                                <input
                                                  htmlFor="clogo"
                                                  onChange={this.HandleImage}
                                                  style={{ display: "none" }}
                                                  id="Profilepicinfo"
                                                  name="Com_logo"
                                                  type="file"
                                                />
                                              </label>
                                              {!ClogoErr ? (
                                                <span
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginLeft: "-20px",
                                                    color: "#a7a2a2",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Entity Logo
                                                </span>
                                              ) : (
                                                <span
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginLeft: "-20px",
                                                    color: "red",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {ClogoErr}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                          <div className="col-sm-3"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ padding: "8px" }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {OrgEmail.map((OrgEmail) => (
                                              <input
                                                className="effect-16"
                                                readOnly
                                                defaultValue={
                                                  OrgEmail.entityName
                                                }
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[a-zA-Z\s ]+/.test(
                                                      event.key
                                                    )
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                name="Company_Name"
                                                onChange={this.handleChange}
                                                type="text"
                                                maxLength="30"
                                              />
                                            ))}
                                            <span style={{ color: "#a29d9d" }}>
                                              Entity Name
                                            </span>
                                          </div>

                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <input
                                                className="effect-16"
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                id="personname"
                                                name="personname"
                                                defaultValue={
                                                  SaveOrg.personname
                                                }
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[a-zA-Z\s ]+/.test(
                                                      event.key
                                                    )
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={this.handleChange}
                                                type="text"
                                                maxLength="30"
                                              />
                                            ))}
                                            {!personnameErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Contact Person{" "}
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {personnameErr}
                                              </div>
                                            )}
                                          </div>

                                          <div className="col-sm-1"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ padding: "8px" }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {OrgEmail.map((OrgEmail) => (
                                              <input
                                                className="effect-16"
                                                readOnly
                                                type="text"
                                                id="Email"
                                                name="Email"
                                                defaultValue={OrgEmail.email}
                                                onChange={this.handleChange}
                                              />
                                            ))}
                                            <span style={{ color: "#a29d9d" }}>
                                              Email{" "}
                                            </span>
                                          </div>

                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <input
                                                className="effect-16"
                                                id="mobile"
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                name="mobile"
                                                defaultValue={SaveOrg.mobile}
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                maxLength="10"
                                                onChange={this.handleChange}
                                                type="text"
                                              />
                                            ))}
                                            {!mobileErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Primary Mobile Number
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>
                                              </span>
                                            ) : (
                                              <span
                                                style={{ color: "red" }}
                                                className={
                                                  mobileErr ? " showError" : ""
                                                }
                                              >
                                                {mobileErr}
                                              </span>
                                            )}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ padding: "8px" }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <input
                                                className="effect-16"
                                                id="lanline"
                                                onFocus={this.handleFocus}
                                                name="lanline"
                                                maxLength="10"
                                                defaultValue={SaveOrg.lanline}
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={this.handleChange}
                                                type="text"
                                              />
                                            ))}

                                            {!lanlineErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Alternate Mobile Number
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {lanlineErr}
                                              </div>
                                            )}
                                          </div>

                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <input
                                                className="effect-16"
                                                id="SEmail"
                                                onFocus={(e) =>
                                                  e.target.select()
                                                }
                                                type="text"
                                                name="SEmail"
                                                maxLength="50"
                                                defaultValue={SaveOrg.sEmail}
                                                onChange={this.handleChange}
                                              />
                                            ))}

                                            {!SEmailErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Secondary Email
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {SEmailErr}
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ padding: "8px" }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <textarea
                                                className="effect-16"
                                                id="adrs1"
                                                onFocus={this.handleFocus}
                                                style={{ height: "42px" }}
                                                type="text"
                                                defaultValue={SaveOrg.adrs1}
                                                name="adrs1"
                                                onChange={(e) => {
                                                  this.handleChange(e);
                                                  this.AutoCaps(e);
                                                }}
                                              />
                                            ))}

                                            {!adrs1Err ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Address
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {adrs1Err}
                                              </div>
                                            )}
                                          </div>

                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <input
                                                className="effect-16"
                                                id="city"
                                                onChange={(e) => {
                                                  this.handleChange(e);
                                                  this.AutoCaps(e);
                                                }}
                                                onFocus={this.handleFocus}
                                                style={{ width: "100%" }}
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[a-zA-Z\s ]+/.test(
                                                      event.key
                                                    )
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                name="city"
                                                maxLength="30"
                                                defaultValue={SaveOrg.city}
                                                type="text"
                                              />
                                            ))}
                                            {!cityErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                City
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {cityErr}
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ padding: "8px" }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <select
                                                className="effect-16"
                                                id="country"
                                                name="country"
                                                defaultValue={SaveOrg.country}
                                                onChange={this.handleChange}
                                                type="text"
                                              >
                                                <option value="">
                                                  Select Country
                                                </option>
                                                <option value="Afghanistan">
                                                  Afghanistan
                                                </option>
                                                <option value="Åland Islands">
                                                  Åland Islands
                                                </option>
                                                <option value="Albania">
                                                  Albania
                                                </option>
                                                <option value="Algeria">
                                                  Algeria
                                                </option>
                                                <option value="American Samoa">
                                                  American Samoa
                                                </option>
                                                <option value="Andorra">
                                                  Andorra
                                                </option>
                                                <option value="Angola">
                                                  Angola
                                                </option>
                                                <option value="Anguilla">
                                                  Anguilla
                                                </option>
                                                <option value="Antarctica">
                                                  Antarctica
                                                </option>
                                                <option value="Antigua and Barbuda">
                                                  Antigua and Barbuda
                                                </option>
                                                <option value="Argentina">
                                                  Argentina
                                                </option>
                                                <option value="Armenia">
                                                  Armenia
                                                </option>
                                                <option value="Aruba">
                                                  Aruba
                                                </option>
                                                <option value="Australia">
                                                  Australia
                                                </option>
                                                <option value="Austria">
                                                  Austria
                                                </option>
                                                <option value="Azerbaijan">
                                                  Azerbaijan
                                                </option>
                                                <option value="Bahamas">
                                                  Bahamas
                                                </option>
                                                <option value="Bahrain">
                                                  Bahrain
                                                </option>
                                                <option value="Bangladesh">
                                                  Bangladesh
                                                </option>
                                                <option value="Barbados">
                                                  Barbados
                                                </option>
                                                <option value="Belarus">
                                                  Belarus
                                                </option>
                                                <option value="Belgium">
                                                  Belgium
                                                </option>
                                                <option value="Belize">
                                                  Belize
                                                </option>
                                                <option value="Benin">
                                                  Benin
                                                </option>
                                                <option value="Bermuda">
                                                  Bermuda
                                                </option>
                                                <option value="Bhutan">
                                                  Bhutan
                                                </option>
                                                <option value="Bolivia">
                                                  Bolivia
                                                </option>
                                                <option value="Bosnia and Herzegovina">
                                                  Bosnia and Herzegovina
                                                </option>
                                                <option value="Botswana">
                                                  Botswana
                                                </option>
                                                <option value="Bouvet Island">
                                                  Bouvet Island
                                                </option>
                                                <option value="Brazil">
                                                  Brazil
                                                </option>
                                                <option value="British Indian Ocean Territory">
                                                  British Indian Ocean Territory
                                                </option>
                                                <option value="Brunei Darussalam">
                                                  Brunei Darussalam
                                                </option>
                                                <option value="Bulgaria">
                                                  Bulgaria
                                                </option>
                                                <option value="Burkina Faso">
                                                  Burkina Faso
                                                </option>
                                                <option value="Burundi">
                                                  Burundi
                                                </option>
                                                <option value="Cambodia">
                                                  Cambodia
                                                </option>
                                                <option value="Cameroon">
                                                  Cameroon
                                                </option>
                                                <option value="Canada">
                                                  Canada
                                                </option>
                                                <option value="Cape Verde">
                                                  Cape Verde
                                                </option>
                                                <option value="Cayman Islands">
                                                  Cayman Islands
                                                </option>
                                                <option value="Central African Republic">
                                                  Central African Republic
                                                </option>
                                                <option value="Chad">
                                                  Chad
                                                </option>
                                                <option value="Chile">
                                                  Chile
                                                </option>
                                                <option value="China">
                                                  China
                                                </option>
                                                <option value="Christmas Island">
                                                  Christmas Island
                                                </option>
                                                <option value="Cocos (Keeling) Islands">
                                                  Cocos (Keeling) Islands
                                                </option>
                                                <option value="Colombia">
                                                  Colombia
                                                </option>
                                                <option value="Comoros">
                                                  Comoros
                                                </option>
                                                <option value="Congo">
                                                  Congo
                                                </option>
                                                <option value="Congo, The Democratic Republic of The">
                                                  Congo, The Democratic Republic
                                                  of The
                                                </option>
                                                <option value="Cook Islands">
                                                  Cook Islands
                                                </option>
                                                <option value="Costa Rica">
                                                  Costa Rica
                                                </option>
                                                <option value="Cote D'ivoire">
                                                  Cote D'ivoire
                                                </option>
                                                <option value="Croatia">
                                                  Croatia
                                                </option>
                                                <option value="Cuba">
                                                  Cuba
                                                </option>
                                                <option value="Cyprus">
                                                  Cyprus
                                                </option>
                                                <option value="Czech Republic">
                                                  Czech Republic
                                                </option>
                                                <option value="Denmark">
                                                  Denmark
                                                </option>
                                                <option value="Djibouti">
                                                  Djibouti
                                                </option>
                                                <option value="Dominica">
                                                  Dominica
                                                </option>
                                                <option value="Dominican Republic">
                                                  Dominican Republic
                                                </option>
                                                <option value="Ecuador">
                                                  Ecuador
                                                </option>
                                                <option value="Egypt">
                                                  Egypt
                                                </option>
                                                <option value="El Salvador">
                                                  El Salvador
                                                </option>
                                                <option value="Equatorial Guinea">
                                                  Equatorial Guinea
                                                </option>
                                                <option value="Eritrea">
                                                  Eritrea
                                                </option>
                                                <option value="Estonia">
                                                  Estonia
                                                </option>
                                                <option value="Ethiopia">
                                                  Ethiopia
                                                </option>
                                                <option value="Falkland Islands (Malvinas)">
                                                  Falkland Islands (Malvinas)
                                                </option>
                                                <option value="Faroe Islands">
                                                  Faroe Islands
                                                </option>
                                                <option value="Fiji">
                                                  Fiji
                                                </option>
                                                <option value="Finland">
                                                  Finland
                                                </option>
                                                <option value="France">
                                                  France
                                                </option>
                                                <option value="French Guiana">
                                                  French Guiana
                                                </option>
                                                <option value="French Polynesia">
                                                  French Polynesia
                                                </option>
                                                <option value="French Southern Territories">
                                                  French Southern Territories
                                                </option>
                                                <option value="Gabon">
                                                  Gabon
                                                </option>
                                                <option value="Gambia">
                                                  Gambia
                                                </option>
                                                <option value="Georgia">
                                                  Georgia
                                                </option>
                                                <option value="Germany">
                                                  Germany
                                                </option>
                                                <option value="Ghana">
                                                  Ghana
                                                </option>
                                                <option value="Gibraltar">
                                                  Gibraltar
                                                </option>
                                                <option value="Greece">
                                                  Greece
                                                </option>
                                                <option value="Greenland">
                                                  Greenland
                                                </option>
                                                <option value="Grenada">
                                                  Grenada
                                                </option>
                                                <option value="Guadeloupe">
                                                  Guadeloupe
                                                </option>
                                                <option value="Guam">
                                                  Guam
                                                </option>
                                                <option value="Guatemala">
                                                  Guatemala
                                                </option>
                                                <option value="Guernsey">
                                                  Guernsey
                                                </option>
                                                <option value="Guinea">
                                                  Guinea
                                                </option>
                                                <option value="Guinea-bissau">
                                                  Guinea-bissau
                                                </option>
                                                <option value="Guyana">
                                                  Guyana
                                                </option>
                                                <option value="Haiti">
                                                  Haiti
                                                </option>
                                                <option value="Heard Island and Mcdonald Islands">
                                                  Heard Island and Mcdonald
                                                  Islands
                                                </option>
                                                <option value="Holy See (Vatican City State)">
                                                  Holy See (Vatican City State)
                                                </option>
                                                <option value="Honduras">
                                                  Honduras
                                                </option>
                                                <option value="Hong Kong">
                                                  Hong Kong
                                                </option>
                                                <option value="Hungary">
                                                  Hungary
                                                </option>
                                                <option value="Iceland">
                                                  Iceland
                                                </option>
                                                <option value="India">
                                                  India
                                                </option>
                                                <option value="Indonesia">
                                                  Indonesia
                                                </option>
                                                <option value="Iran, Islamic Republic of">
                                                  Iran, Islamic Republic of
                                                </option>
                                                <option value="Iraq">
                                                  Iraq
                                                </option>
                                                <option value="Ireland">
                                                  Ireland
                                                </option>
                                                <option value="Isle of Man">
                                                  Isle of Man
                                                </option>
                                                <option value="Israel">
                                                  Israel
                                                </option>
                                                <option value="Italy">
                                                  Italy
                                                </option>
                                                <option value="Jamaica">
                                                  Jamaica
                                                </option>
                                                <option value="Japan">
                                                  Japan
                                                </option>
                                                <option value="Jersey">
                                                  Jersey
                                                </option>
                                                <option value="Jordan">
                                                  Jordan
                                                </option>
                                                <option value="Kazakhstan">
                                                  Kazakhstan
                                                </option>
                                                <option value="Kenya">
                                                  Kenya
                                                </option>
                                                <option value="Kiribati">
                                                  Kiribati
                                                </option>
                                                <option value="Korea, Democratic People's Republic of">
                                                  Korea, Democratic People's
                                                  Republic of
                                                </option>
                                                <option value="Korea, Republic of">
                                                  Korea, Republic of
                                                </option>
                                                <option value="Kuwait">
                                                  Kuwait
                                                </option>
                                                <option value="Kyrgyzstan">
                                                  Kyrgyzstan
                                                </option>
                                                <option value="Lao People's Democratic Republic">
                                                  Lao People's Democratic
                                                  Republic
                                                </option>
                                                <option value="Latvia">
                                                  Latvia
                                                </option>
                                                <option value="Lebanon">
                                                  Lebanon
                                                </option>
                                                <option value="Lesotho">
                                                  Lesotho
                                                </option>
                                                <option value="Liberia">
                                                  Liberia
                                                </option>
                                                <option value="Libyan Arab Jamahiriya">
                                                  Libyan Arab Jamahiriya
                                                </option>
                                                <option value="Liechtenstein">
                                                  Liechtenstein
                                                </option>
                                                <option value="Lithuania">
                                                  Lithuania
                                                </option>
                                                <option value="Luxembourg">
                                                  Luxembourg
                                                </option>
                                                <option value="Macao">
                                                  Macao
                                                </option>
                                                <option value="Macedonia, The Former Yugoslav Republic of">
                                                  Macedonia, The Former Yugoslav
                                                  Republic of
                                                </option>
                                                <option value="Madagascar">
                                                  Madagascar
                                                </option>
                                                <option value="Malawi">
                                                  Malawi
                                                </option>
                                                <option value="Malaysia">
                                                  Malaysia
                                                </option>
                                                <option value="Maldives">
                                                  Maldives
                                                </option>
                                                <option value="Mali">
                                                  Mali
                                                </option>
                                                <option value="Malta">
                                                  Malta
                                                </option>
                                                <option value="Marshall Islands">
                                                  Marshall Islands
                                                </option>
                                                <option value="Martinique">
                                                  Martinique
                                                </option>
                                                <option value="Mauritania">
                                                  Mauritania
                                                </option>
                                                <option value="Mauritius">
                                                  Mauritius
                                                </option>
                                                <option value="Mayotte">
                                                  Mayotte
                                                </option>
                                                <option value="Mexico">
                                                  Mexico
                                                </option>
                                                <option value="Micronesia, Federated States of">
                                                  Micronesia, Federated States
                                                  of
                                                </option>
                                                <option value="Moldova, Republic of">
                                                  Moldova, Republic of
                                                </option>
                                                <option value="Monaco">
                                                  Monaco
                                                </option>
                                                <option value="Mongolia">
                                                  Mongolia
                                                </option>
                                                <option value="Montenegro">
                                                  Montenegro
                                                </option>
                                                <option value="Montserrat">
                                                  Montserrat
                                                </option>
                                                <option value="Morocco">
                                                  Morocco
                                                </option>
                                                <option value="Mozambique">
                                                  Mozambique
                                                </option>
                                                <option value="Myanmar">
                                                  Myanmar
                                                </option>
                                                <option value="Namibia">
                                                  Namibia
                                                </option>
                                                <option value="Nauru">
                                                  Nauru
                                                </option>
                                                <option value="Nepal">
                                                  Nepal
                                                </option>
                                                <option value="Netherlands">
                                                  Netherlands
                                                </option>
                                                <option value="Netherlands Antilles">
                                                  Netherlands Antilles
                                                </option>
                                                <option value="New Caledonia">
                                                  New Caledonia
                                                </option>
                                                <option value="New Zealand">
                                                  New Zealand
                                                </option>
                                                <option value="Nicaragua">
                                                  Nicaragua
                                                </option>
                                                <option value="Niger">
                                                  Niger
                                                </option>
                                                <option value="Nigeria">
                                                  Nigeria
                                                </option>
                                                <option value="Niue">
                                                  Niue
                                                </option>
                                                <option value="Norfolk Island">
                                                  Norfolk Island
                                                </option>
                                                <option value="Northern Mariana Islands">
                                                  Northern Mariana Islands
                                                </option>
                                                <option value="Norway">
                                                  Norway
                                                </option>
                                                <option value="Oman">
                                                  Oman
                                                </option>
                                                <option value="Pakistan">
                                                  Pakistan
                                                </option>
                                                <option value="Palau">
                                                  Palau
                                                </option>
                                                <option value="Palestinian Territory, Occupied">
                                                  Palestinian Territory,
                                                  Occupied
                                                </option>
                                                <option value="Panama">
                                                  Panama
                                                </option>
                                                <option value="Papua New Guinea">
                                                  Papua New Guinea
                                                </option>
                                                <option value="Paraguay">
                                                  Paraguay
                                                </option>
                                                <option value="Peru">
                                                  Peru
                                                </option>
                                                <option value="Philippines">
                                                  Philippines
                                                </option>
                                                <option value="Pitcairn">
                                                  Pitcairn
                                                </option>
                                                <option value="Poland">
                                                  Poland
                                                </option>
                                                <option value="Portugal">
                                                  Portugal
                                                </option>
                                                <option value="Puerto Rico">
                                                  Puerto Rico
                                                </option>
                                                <option value="Qatar">
                                                  Qatar
                                                </option>
                                                <option value="Reunion">
                                                  Reunion
                                                </option>
                                                <option value="Romania">
                                                  Romania
                                                </option>
                                                <option value="Russian Federation">
                                                  Russian Federation
                                                </option>
                                                <option value="Rwanda">
                                                  Rwanda
                                                </option>
                                                <option value="Saint Helena">
                                                  Saint Helena
                                                </option>
                                                <option value="Saint Kitts and Nevis">
                                                  Saint Kitts and Nevis
                                                </option>
                                                <option value="Saint Lucia">
                                                  Saint Lucia
                                                </option>
                                                <option value="Saint Pierre and Miquelon">
                                                  Saint Pierre and Miquelon
                                                </option>
                                                <option value="Saint Vincent and The Grenadines">
                                                  Saint Vincent and The
                                                  Grenadines
                                                </option>
                                                <option value="Samoa">
                                                  Samoa
                                                </option>
                                                <option value="San Marino">
                                                  San Marino
                                                </option>
                                                <option value="Sao Tome and Principe">
                                                  Sao Tome and Principe
                                                </option>
                                                <option value="Saudi Arabia">
                                                  Saudi Arabia
                                                </option>
                                                <option value="Senegal">
                                                  Senegal
                                                </option>
                                                <option value="Serbia">
                                                  Serbia
                                                </option>
                                                <option value="Seychelles">
                                                  Seychelles
                                                </option>
                                                <option value="Sierra Leone">
                                                  Sierra Leone
                                                </option>
                                                <option value="Singapore">
                                                  Singapore
                                                </option>
                                                <option value="Slovakia">
                                                  Slovakia
                                                </option>
                                                <option value="Slovenia">
                                                  Slovenia
                                                </option>
                                                <option value="Solomon Islands">
                                                  Solomon Islands
                                                </option>
                                                <option value="Somalia">
                                                  Somalia
                                                </option>
                                                <option value="South Africa">
                                                  South Africa
                                                </option>
                                                <option value="South Georgia and The South Sandwich Islands">
                                                  South Georgia and The South
                                                  Sandwich Islands
                                                </option>
                                                <option value="Spain">
                                                  Spain
                                                </option>
                                                <option value="Sri Lanka">
                                                  Sri Lanka
                                                </option>
                                                <option value="Sudan">
                                                  Sudan
                                                </option>
                                                <option value="Suriname">
                                                  Suriname
                                                </option>
                                                <option value="Svalbard and Jan Mayen">
                                                  Svalbard and Jan Mayen
                                                </option>
                                                <option value="Swaziland">
                                                  Swaziland
                                                </option>
                                                <option value="Sweden">
                                                  Sweden
                                                </option>
                                                <option value="Switzerland">
                                                  Switzerland
                                                </option>
                                                <option value="Syrian Arab Republic">
                                                  Syrian Arab Republic
                                                </option>
                                                <option value="Taiwan, Province of China">
                                                  Taiwan, Province of China
                                                </option>
                                                <option value="Tajikistan">
                                                  Tajikistan
                                                </option>
                                                <option value="Tanzania, United Republic of">
                                                  Tanzania, United Republic of
                                                </option>
                                                <option value="Thailand">
                                                  Thailand
                                                </option>
                                                <option value="Timor-leste">
                                                  Timor-leste
                                                </option>
                                                <option value="Togo">
                                                  Togo
                                                </option>
                                                <option value="Tokelau">
                                                  Tokelau
                                                </option>
                                                <option value="Tonga">
                                                  Tonga
                                                </option>
                                                <option value="Trinidad and Tobago">
                                                  Trinidad and Tobago
                                                </option>
                                                <option value="Tunisia">
                                                  Tunisia
                                                </option>
                                                <option value="Turkey">
                                                  Turkey
                                                </option>
                                                <option value="Turkmenistan">
                                                  Turkmenistan
                                                </option>
                                                <option value="Turks and Caicos Islands">
                                                  Turks and Caicos Islands
                                                </option>
                                                <option value="Tuvalu">
                                                  Tuvalu
                                                </option>
                                                <option value="Uganda">
                                                  Uganda
                                                </option>
                                                <option value="Ukraine">
                                                  Ukraine
                                                </option>
                                                <option value="United Arab Emirates">
                                                  United Arab Emirates
                                                </option>
                                                <option value="United Kingdom">
                                                  United Kingdom
                                                </option>
                                                <option value="United States">
                                                  United States
                                                </option>
                                                <option value="United States Minor Outlying Islands">
                                                  United States Minor Outlying
                                                  Islands
                                                </option>
                                                <option value="Uruguay">
                                                  Uruguay
                                                </option>
                                                <option value="Uzbekistan">
                                                  Uzbekistan
                                                </option>
                                                <option value="Vanuatu">
                                                  Vanuatu
                                                </option>
                                                <option value="Venezuela">
                                                  Venezuela
                                                </option>
                                                <option value="Viet Nam">
                                                  Viet Nam
                                                </option>
                                                <option value="Virgin Islands, British">
                                                  Virgin Islands, British
                                                </option>
                                                <option value="Virgin Islands, U.S.">
                                                  Virgin Islands, U.S.
                                                </option>
                                                <option value="Wallis and Futuna">
                                                  Wallis and Futuna
                                                </option>
                                                <option value="Western Sahara">
                                                  Western Sahara
                                                </option>
                                                <option value="Yemen">
                                                  Yemen
                                                </option>
                                                <option value="Zambia">
                                                  Zambia
                                                </option>
                                                <option value="Zimbabwe">
                                                  Zimbabwe
                                                </option>
                                              </select>
                                            ))}
                                            {!countryErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Country
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {countryErr}
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <>
                                                {this.state.isSelected ? (
                                                  <select
                                                    id="state"
                                                    name="state"
                                                    className="effect-16"
                                                    onChange={(e) => {
                                                      this.handleChange(e);
                                                      // this.AutoCaps(e);
                                                    }}
                                                    // onFocus={this.handleFocus}
                                                    defaultValue={SaveOrg.state}
                                                    onKeyPress={(event) => {
                                                      if (
                                                        !/[a-zA-Z\s ]+/.test(
                                                          event.key
                                                        )
                                                      ) {
                                                        event.preventDefault();
                                                      }
                                                    }}
                                                  >
                                                    <option value="">
                                                      Select state
                                                    </option>
                                                    <option value="Andaman and Nicobar Islands">
                                                      Andaman and Nicobar
                                                      Islands
                                                    </option>
                                                    <option value="Andhra Pradesh">
                                                      Andhra Pradesh
                                                    </option>
                                                    <option value="Arunachal Pradesh">
                                                      Arunachal Pradesh
                                                    </option>
                                                    <option value="Assam">
                                                      Assam
                                                    </option>
                                                    <option value="Bihar">
                                                      Bihar
                                                    </option>
                                                    <option value="Chandigarh">
                                                      Chandigarh
                                                    </option>
                                                    <option value="Chhattisgarh">
                                                      Chhattisgarh
                                                    </option>
                                                    <option value="Dadra and Nagar Haveli">
                                                      Dadra and Nagar Haveli
                                                    </option>
                                                    <option value="Daman and Diu">
                                                      Daman and Diu
                                                    </option>
                                                    <option value="Delhi">
                                                      Delhi
                                                    </option>
                                                    <option value="Goa">
                                                      Goa
                                                    </option>
                                                    <option value="Gujarat">
                                                      Gujarat
                                                    </option>
                                                    <option value="Haryana">
                                                      Haryana
                                                    </option>
                                                    <option value="Himachal Pradesh">
                                                      Himachal Pradesh
                                                    </option>
                                                    <option value="Jammu and Kashmir">
                                                      Jammu and Kashmir
                                                    </option>
                                                    <option value="Jharkhand">
                                                      Jharkhand
                                                    </option>
                                                    <option value="Karnataka">
                                                      Karnataka
                                                    </option>
                                                    <option value="Kerala">
                                                      Kerala
                                                    </option>
                                                    <option value="Ladakh">
                                                      Ladakh
                                                    </option>
                                                    <option value="Lakshadweep">
                                                      Lakshadweep
                                                    </option>
                                                    <option value="Madhya Pradesh">
                                                      Madhya Pradesh
                                                    </option>
                                                    <option value="Maharashtra">
                                                      Maharashtra
                                                    </option>
                                                    <option value="Manipur">
                                                      Manipur
                                                    </option>
                                                    <option value="Meghalaya">
                                                      Meghalaya
                                                    </option>
                                                    <option value="Mizoram">
                                                      Mizoram
                                                    </option>
                                                    <option value="Nagaland">
                                                      Nagaland
                                                    </option>
                                                    <option value="Odisha">
                                                      Odisha
                                                    </option>
                                                    <option value="Puducherry">
                                                      Puducherry
                                                    </option>
                                                    <option value="Punjab">
                                                      Punjab
                                                    </option>
                                                    <option value="Rajasthan">
                                                      Rajasthan
                                                    </option>
                                                    <option value="Sikkim">
                                                      Sikkim
                                                    </option>
                                                    <option value="Tamil Nadu">
                                                      Tamil Nadu
                                                    </option>
                                                    <option value="Telangana">
                                                      Telangana
                                                    </option>
                                                    <option value="Tripura">
                                                      Tripura
                                                    </option>
                                                    <option value="Uttar Pradesh">
                                                      Uttar Pradesh
                                                    </option>
                                                    <option value="Uttarakhand">
                                                      Uttarakhand
                                                    </option>
                                                    <option value="West Bengal">
                                                      West Bengal
                                                    </option>
                                                  </select>
                                                ) : (
                                                  <input
                                                    className="effect-16"
                                                    onChange={(e) => {
                                                      this.handleChange(e);
                                                      this.AutoCaps(e);
                                                    }}
                                                    onFocus={this.handleFocus}
                                                    id="state"
                                                    name="state"
                                                    maxLength="30"
                                                    defaultValue={SaveOrg.state}
                                                    onKeyPress={(event) => {
                                                      if (
                                                        !/[a-zA-Z\s ]+/.test(
                                                          event.key
                                                        )
                                                      ) {
                                                        event.preventDefault();
                                                      }
                                                    }}
                                                    type="text"
                                                  />
                                                )}
                                              </>
                                            ))}
                                            {!stateErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                State
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {stateErr}
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ padding: "8px" }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <input
                                                className="effect-16"
                                                onFocus={this.handleFocus}
                                                id="Zip"
                                                name="Zip"
                                                defaultValue={SaveOrg.zip}
                                                maxLength="6"
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/[0-9]/.test(event.key)
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={this.handleChange}
                                                type="text"
                                              />
                                            ))}
                                            {!ZipErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Pincode
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {ZipErr}
                                              </div>
                                            )}
                                          </div>

                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <input
                                                className="effect-16"
                                                id="website"
                                                name="website"
                                                maxLength="256"
                                                defaultValue={SaveOrg.website}
                                                // ===" "?"http://":SaveOrg.website}

                                                onChange={this.handleChange}
                                                type="text"
                                              />
                                            ))}
                                            {!websiteErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Official Website
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {websiteErr}
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ padding: "8px" }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <input
                                                className="effect-16"
                                                id="PANno"
                                                onFocus={(e) => {
                                                  if (e.target.value === " ") {
                                                    e.target.value = "";
                                                  }
                                                }}
                                                defaultValue={SaveOrg.paNno}
                                                maxLength="10"
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/^([a-zA-Z0-9 _-]+)$/.test(
                                                      event.key
                                                    )
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                name="PANno"
                                                onBlur={this.HandlePanFormat}
                                                onChange={this.handleChange}
                                                type="text"
                                              />
                                            ))}
                                            {!PANnoErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                PAN Number
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>{" "}
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {PANnoErr}
                                              </div>
                                            )}{" "}
                                          </div>

                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <input
                                                className="effect-16"
                                                id="GSTno"
                                                onFocus={this.handleFocus}
                                                onBlur={this.handleGSTFormat}
                                                defaultValue={SaveOrg.gsTno}
                                                maxLength="15"
                                                name="GSTno"
                                                onKeyPress={(event) => {
                                                  if (
                                                    !/^([a-zA-Z0-9 _-]+)$/.test(
                                                      event.key
                                                    )
                                                  ) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={this.handleChange}
                                                type="text"
                                              />
                                            ))}
                                            {!GSTnoErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                GST Number
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {GSTnoErr}
                                              </div>
                                            )}{" "}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ padding: "8px" }}
                                        >
                                          <div className="col-sm-1"></div>
                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <>
                                                <label>
                                                  +
                                                  <input
                                                    className="effect-16"
                                                    title="Pan"
                                                    id="Pan"
                                                    name="Pan"
                                                    accept="image/*,.doc,.docx,.pdf,.txt, .xls,.xlsx,.ppt,.pptx,.zip,.rar"
                                                    style={{
                                                      pointerEvents:
                                                        SaveOrg.panVerified &&
                                                        SaveOrg.reason ==
                                                          "Insufficient Document"
                                                          ? "none"
                                                          : "all",
                                                      width: "93px",
                                                      border: "0",
                                                    }}
                                                    defaultValue={SaveOrg.pan.substring(
                                                      SaveOrg.pan.lastIndexOf(
                                                        "/"
                                                      ) + 1
                                                    )}
                                                    onFocus={(e) =>
                                                      (e.target.type = "file")
                                                    }
                                                    onChange={
                                                      this.handlePanImageChange
                                                    }
                                                    onChangeCapture={
                                                      this.handleChange
                                                    }
                                                    // id="Pan"
                                                  />
                                                </label>
                                                <input
                                                  type="hidden"
                                                  id="oldpan"
                                                  name="OldPan"
                                                  defaultValue={SaveOrg.pan}
                                                />
                                              </>
                                            ))}
                                            <hr
                                              style={{
                                                margin: "0px",
                                                background: "lightgrey",
                                              }}
                                            />

                                            {!PanErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Upload PAN{" "}
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {PanErr}
                                              </div>
                                            )}
                                            <span>
                                              {this.state.imagedisplayPAN}
                                            </span>
                                            <span id="file_error"></span>
                                            {/* <img src={this.state.imagePreviewUrlPAN}  style={{height:"70px",marginLeft:"54%"}}/> */}
                                          </div>

                                          {/* {SaveOrg.map(SaveOrg =><div className="col-sm-5" 
                                            style={{pointerEvents:SaveOrg.gstVerified && SaveOrg.reason=='Insufficient Document'?"none":"all",width: '95px',border: '0' }}
                                             > 
                                            <>    
                                              <input type ="hidden" name="OldGST" defaultValue={SaveOrg.gst} style={{pointerEvents:""}}/>
                                           <label>+
                                            <input className="effect-16" style={{width: '95px',
                                               border: '0'}}  id="GST" name="GST"  defaultValue={SaveOrg.gst.substring(SaveOrg.gst.lastIndexOf('/') + 1)} 
                                            onFocus={(e) => e.target.type = 'file'}   
                                            onChange={this.handleGstImageChange} type="text" id="GST" /></label>
                                           
                                          
                                            </>
                                            <hr style={{margin: '0px',background: 'lightgrey'}} />
                                            {!GSTErr ? <span style={{ color: '#a29d9d' }}>Upload GST<span style={{ color: "red" }}>*</span></span> :
                                              <div style={{ color: "red" }}>{GSTErr}</div>}
                                                <span>{this.state.imagedisplayGST}</span>
                                            

                                          </div> )} */}
                                          <div className="col-sm-5">
                                            {SaveOrg.map((SaveOrg) => (
                                              <>
                                                <label>
                                                  +
                                                  <input
                                                    className="effect-16"
                                                    id="GST"
                                                    name="GST"
                                                    accept="image/*,.doc,.docx,.pdf,.txt, .xls,.xlsx,.ppt,.pptx,.zip,.rar"
                                                    style={{
                                                      pointerEvents:
                                                        SaveOrg.gstVerified &&
                                                        SaveOrg.reason ==
                                                          "Insufficient Document"
                                                          ? "none"
                                                          : "all",
                                                      width: "93px",
                                                      border: "0",
                                                    }}
                                                    defaultValue={SaveOrg.gst.substring(
                                                      SaveOrg.gst.lastIndexOf(
                                                        "/"
                                                      ) + 1
                                                    )}
                                                    onFocus={(e) =>
                                                      (e.target.type = "file")
                                                    }
                                                    onChange={
                                                      this.handleGstImageChange
                                                    }
                                                    onChangeCapture={
                                                      this.handleChange
                                                    }
                                                    ref={this.inputRef}
                                                    type="text"
                                                    // id="GST"
                                                  />
                                                </label>
                                                <input
                                                  type="hidden"
                                                  name="OldGST"
                                                  defaultValue={SaveOrg.gst}
                                                  style={{ pointerEvents: "" }}
                                                />
                                              </>
                                            ))}
                                            <hr
                                              style={{
                                                margin: "0px",
                                                background: "lightgrey",
                                              }}
                                            />

                                            {!GSTErr ? (
                                              <span
                                                style={{ color: "#a29d9d" }}
                                              >
                                                Upload GST
                                                <span style={{ color: "red" }}>
                                                  *
                                                </span>
                                              </span>
                                            ) : (
                                              <div style={{ color: "red" }}>
                                                {GSTErr}
                                              </div>
                                            )}
                                            <span>
                                              {this.state.imagedisplayGST}
                                            </span>
                                            {/* <span id="file_error"></span> */}
                                            {/* <img src={this.state.imagePreviewUrlPAN}  style={{height:"70px",marginLeft:"54%"}}/> */}
                                          </div>
                                          <div className="col-sm-1"></div>
                                        </div>
                                        <br />
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                className="col-sm-12"
                                style={{
                                  textAlign: "center",
                                  marginLeft: "24px",
                                }}
                              >
                                <button
                                  id="save"
                                  className="but"
                                  onClick={() => {
                                    this.setState({ savstatus: 1 });
                                  }}
                                  style={{
                                    height: "39.5px",
                                    width: "86.36px",
                                    marginTop: "-0.3%",
                                  }}
                                >
                                  Save
                                </button>
                                &nbsp; &nbsp; &nbsp;
                                <button
                                  id="submitbtn"
                                  className="but"
                                  onClick={() => {
                                    this.setState({ savstatus: 2 });
                                  }}
                                  style={{
                                    height: "39.5px",
                                    width: "86.36px",
                                    marginTop: "-0.3%",
                                  }}
                                >
                                  Submit
                                </button>
                                &nbsp; &nbsp; &nbsp;
                                <button
                                  type="reset"
                                  className=" btn-Secondary"
                                  onClick={this.handleReset}
                                  style={{ height: "39.5px" }}
                                >
                                  Cancel
                                </button>
                                <div>
                                  <br /> <br /> <br />
                                  <br />
                                </div>
                              </div>
                            </div>
                          </form>

                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End */}

        {this.state.loading === true ? <LoadingPage /> : null}
      </div>
    );
  }
}
