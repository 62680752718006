import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useDropzone } from "react-dropzone";
import Picker from "emoji-picker-react";
import { BASE_URLs } from "../Config/Base";
import Swal from "sweetalert2";
import video from "../Assets/images/videoplay.png";
const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};
const thumbsContainer = {
  // display: "flex",
  // flexDirection: "row",
  // flexWrap: "wrap",
  height: 10,
  marginTop: -21,
};
const thumbss = {
  display: "inline-flex",
  borderRadius: 10,
  border: "6px dotted #eaeaea",
  marginBottom: 20,
  marginTop: 80,
  marginRight: 4,
  alignItems: "center",
  textAlign: "center",
  width: "100%",
  height: 200,
  padding: 4,
  boxSizing: "border-box",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function Picturepop(props) {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);

  const [showEmoji, setShowEmoji] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const toggleEmoji = () => {
    setShowEmoji((prevShowEmoji) => !prevShowEmoji);
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
    setShowEmoji(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!message.trim() && files.length === 0) {
      alert("Please enter a message, select an image, or choose a document.");
    } else {
      files.forEach((file) => {
        if (file.size >= 2048000) {
          Swal.fire({
            text: "Upload less than 2MB",
            icon: "warning",
          });
        } else {
          const formData = new FormData();
          formData.append("Message", message);
          formData.append("Group_Name", props.values.GroupName);
          formData.append("Group_id", props.values.GroupId);
          formData.append("GroupMember", props.AllMembers);
          formData.append("Picture_Path", file);
          formData.append("Profile", props.values.Profile);
  
          const options = {
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
            },
          };
  
          axios.post(`${BASE_URLs}contact/GroupChatMessage`, formData, options)
            .then((response) => {
              const data = response.data;
              setFiles([]);
              setMessage("");
              props.handleClose();
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      });
    }
  };

  const handleTextChange = (event) => {
    setMessage(event.target.value);
  };

  const handleRemoveImage = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      'video/mp4': ['.mp4', '.MP4', '.gif', '.GIF','.mov', '.mkv'],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file, index) => (
    <li style={thumb}>
      <div style={thumbInner}>
        <img
          src={file.name.includes('mp4') ? video : file.preview}
          style={img}
          alt={`Thumbnail-${index}`}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <span onClick={() => handleRemoveImage(index)}>x</span>
    </li>
  ));

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <div className="container">
      <form id="contactForm" autoComplete="off" encType="multipart/form-data">
        <span
          className="GTclose-icon"
          onClick={props.handleClose}
          style={{ marginTop: "20px" }}
        >
          x
        </span>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} multiple />
          <h4 style={thumbss}>
            Drag & drop some files here, or click to select images
          </h4>
        </div>
        <aside style={thumbsContainer}>
          <div id="ImageList">
            {thumbs.length > 0 ? thumbs : <p>No images selected</p>}
          </div>
        </aside>
        <div id="imageInput">
          <div
            className="input-group write-msg"
            id="input-group write-msg"
            style={{
              border: "1px solid #bfbfbe",
              padding: "2px 2px",
              backgroundColor:"white",
            }}
          >
            <input
              type="text"
              className="form-control input-value"
              placeholder="Type a Message"
              value={message}
              onChange={handleTextChange}
              name="Message"
              style={{ border: "none" }}
            />
            <span
              className="fa fa-smile-o"
              aria-hidden="true"
              style={{ marginTop: "10px", fontSize: "18px" ,backgroundColor:"white"}}
              onClick={toggleEmoji}
            ></span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {showEmoji && (
              <div
                style={{
                  position: "absolute",
                  
                  
                }}
                id="DragpopEmoji"
              >
                {windowWidth < 400 ? (
        <Picker
          onEmojiClick={handleEmojiClick}
          height={297}
          width={260} // Adjusted width for windowWidth < 300
        />
      ) : windowWidth < 750 ? (
        <Picker
          onEmojiClick={handleEmojiClick}
          height={297}
          width={300} // Adjusted width for windowWidth < 750
        />
      ) : (
        <Picker
          onEmojiClick={handleEmojiClick}
          height={375}
          width={315}
        />
      )}
              </div>
            )}
            <span className="input-group-btn">
              <button
                id="paper-btn"
                className="btn btn-secondary"
                type="submit"
                onClick={handleSubmit}
              >
                <i className="icofont icofont-paper-plane"></i>
              </button>
            </span>
          </div>
        </div>
      </form>
    </div>
  );
}
export default Picturepop;
