import React, { Component } from "react";
import AddBusinessPop from "../../Common/AddBusinessPop";
import EditBusinessPop from "../../Common/EditBusinessPop";
import SideBar from "../../Common/Sidebar";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import CloseIcon from "@material-ui/icons/Close";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../../Config/Base"
import DataTable from 'react-data-table-component';
var XLSX = require("xlsx");
export default class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      DepartmentName: "",
      SkillModel: false,
      comlogo: [],
      Business: [],
      Busi: [],
      formErrors: {},
      Notification: [],
      EditModel: false,
      letters: [],
      Filterpop: false,
      EditBusinessValues: [],
      FilterData: "",
      filterValue:'',
      showSelectAll: false,
      showDelete: false,
      showDeleteCount: false,
      selectAllChecked: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("BusSheetExpo");
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }

  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }

  GetBusiness() {
    let url = `${BASE_URLs}Business/BusinessGet`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("bussin",data)
        this.setState({
          Business: data,
          Busi: data,
        });
      });
  }
  onKeyUp = (e) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    const Business = this.state.Busi.filter((item) =>
      item.businessUnit.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Business });
  };

  handleBusinessFilter = (letter, event) => {
    event.preventDefault();
    const Business = this.state.Busi.filter((item) => {
      return item.businessUnit.toLowerCase().includes(letter.toLowerCase());
    });
    this.setState({ Business, showResults: true, FilterData: Business });
  };

  SkillModel = () => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState((prevState) => ({
      SkillModel: !prevState.SkillModel,
    }));

    this.GetBusiness();
  };

  EditModel = (e, fn) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditBusinessValues: {
        id: e,
        BusinessUnit: fn,
      },
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));

    this.GetBusiness();
  };

  componentDidMount() {
    this.GetOrgLogo();
    this.GetBusiness();
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }
  Getall() {
    fetch(`${BASE_URLs}AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleFormValidation() {
    const {
      FirstName,
      LastName,
      Email,
      Mobile,
      Department,
      Designation,
      RoleName,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!FirstName) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "First Name is required";
    }
    if (!LastName) {
      formIsValid = false;
      formErrors["LastNameErr"] = "Last Name is required";
    }
    if (!Email) {
      formIsValid = false;
      formErrors["EmailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
      formErrors["EmailErr"] = "Invalid Email id.";
    }
    if (!Mobile) {
      formIsValid = false;
      formErrors["MobileErr"] = "Mobile is required";
    } else {
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
      if (!mobPattern.test(Mobile)) {
        formIsValid = false;
        formErrors["MobileErr"] = "Invalid phone number.";
      }
    }
    if (!Department) {
      formIsValid = false;
      formErrors["departmentErr"] = "department is required";
    }
    if (!Designation) {
      formIsValid = false;
      formErrors["designationErr"] = "designation is required";
    }
    if (!RoleName) {
      formIsValid = false;
      formErrors["rolesErr"] = "Role is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleSubmit(event) {
    event.preventDefault();

    const data = new FormData(event.target);

    fetch(`${BASE_URLs}Business/SP_AddBusiness`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        alert(resp.message);
      });
  }

  checkAll(e) {
    var checkboxes = document.getElementsByName("Id_delete");

    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;margin-top: 30px;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top: 30px;"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + "  selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  handlecheckbox = (state) => {
    const selectedRows = state.selectedRows;

    this.setState({ SelectedNames: selectedRows });
    this.setState({
      showSelectAll: selectedRows.length >= 2,
      showDelete: selectedRows.length >= 1,
      showDeleteCount: selectedRows.length > 0,
    });
  };

  // handlecheckbox() {
  //   let checkboxes = document.querySelectorAll(
  //     '#tablepadding input[type="checkbox"]:checked'
  //   );
  //   let Total = document.querySelectorAll('input[type="checkbox"]');
  //   // selectalldiv
  //   if (checkboxes.length >= 2) {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:visible ;");
  //   } else {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:hidden ;");
  //   }
  //   if (checkboxes.length >= 1) {
  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility:visible ;margin-top: 30px;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute(
  //         "style",
  //         "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top: 30px;"
  //       );
  //     var msg = document.getElementById("deletecount");
  //     let count = checkboxes.length - 1;

  //     if (document.getElementById("tablepadding").checked) {
  //       msg.innerHTML = "<span> " + count + " selected </span> ";
  //     } else {
  //       msg.innerHTML = "<span> " + checkboxes.length + " selected </span> ";
  //     }
  //   } else {
  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility: hidden;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute("style", "visibility: hidden;");
  //   }
  //   if (document.getElementById("selectall").checked === false) {
  //     if (checkboxes.length === Total.length - 1) {
  //       //
  //       document.getElementById("selectall").checked = true;
  //     }
  //   } else {
  //     if (document.getElementById("selectall").checked) {
  //       if (checkboxes.length - 2 !== Total.length) {
  //         document.getElementById("selectall").checked = false;
  //       }
  //     }
  //   }
  // }

  Multidelete = (event) => {
    event.preventDefault();
    const data = new FormData();
    this.state.SelectedNames.forEach((row) => {
      data.append("Id_delete", row.id);
    });
   

    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          Accept: "application/json",

          //Authorization: `Bearer ${JSON.parse(localStorage.user)}`
        },
        body: data,
      };
      fetch(`${BASE_URLs}Business/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {

          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      this.GetBusiness();

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
    this.GetBusiness();
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  DeleteButton = (id) => {
    const data = new FormData();
    data.append("Id_delete", id);
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}Business/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetBusiness();
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      //check.checked = false;
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
  };


  handleSelectAllChange = (event) => {
    const { checked } = event.target;
    this.setState({ selectAllChecked: checked });
  };


  render() {
    var username = JSON.parse(localStorage.uname);
    const { Business, Filterpop, FilterData } = this.state;
    var filename = `O_${username}_Business`;
    const customStyles = {
      headCells: {
        style: {
          flex: "0.5" 
        }
      },
      header: {
        style: {
          fontWeight: 'bold',
          fontSize: "14px",
          flex: "0"  
        },
      },
      headCells: {
        style: {
          fontWeight: 'bold',
          fontSize: "14px",
          flex: "0" ,
          borderBottom: "1px solid #ccc",
          
        },
      },
   
      columns: {
        idHeader: {
          style: {
            fontWeight: 'bold',
            fontSize: "14px",
            flex: "0" 
          },
        },
      },
    };

    const columns = [
      {
        name:'Business Unit',
        selector: row => row.businessUnit,
        id: "isheader",
        grow: 3.5,
        sortable: false, 
      },
     
      {
        name: 'Actions',
        grow: 0,
        cell: row => (
          <div>
            <button
              className="button-30 sk-del"
              onClick={(e) => {
                e.preventDefault();
                this.DeleteButton(row.id);
              }}
              title="Delete"
              style={{ marginLeft: "-10px" }}
            >
              <i className="fa fa-minus" aria-hidden="true"></i>
            </button>
            &nbsp;&nbsp;
            {" "}
            <button
              className="Annbuttons sk-edit"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.EditModel(
                  row.id,
                  row.businessUnit
                  
                 
                );
              }}
              title="Edit"
            >
              <i className="fa fa-edit" aria-hidden="true"></i>
            </button>
          </div>
        )
      }
    ];
    return (
      <div>
        <SideBar active={window.location.hash} />
        {this.state.EditModel ? (
          <EditBusinessPop
            values={this.state.EditBusinessValues}
            close={() => this.EditModel()}
          />
        ) : null}
        {this.state.SkillModel ? (
          <AddBusinessPop close={() => this.SkillModel()} />
        ) : null}
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "3%" }} className="title">
                            Business Unit{" "}
                          </h4>
                        </div>
                        <div className="card-block">
                          <div className="row">
                            <div className="col btnalign">
                              <h5 className="card-header-text">
                                <button
                                  onClick={this.SkillModel}
                                  onKeyPress={this.handleKeyPress}
                                  className="btn"
                                  style={{ width: "150px" }}
                                >
                                  Add{" "}
                                </button>
                              </h5>
                            </div>
                            <div className="col btnalign">
                              <button
                                className="btn"
                                type="button"
                                onClick={() => this.exportXlsx(filename)}
                                onKeyPress={this.handleKeyPress}
                              >
                                Export to excel
                              </button>
                              {/* <ReactHTMLTableToExcel                  
           className="btn "  
           table="BusSheet"  
           filename={filename}
           sheet="Sheet"  
           buttonText="Export to Excel " id="exportxlUG" /> */}
                            </div>
                            <div
                              className="col-lg-1"
                              style={{
                                overflow: "scroll",
                                height: "73px",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              {/* Just for design */}
                            </div>


                            <div className="col-lg-2 "></div>
                            <div className="col-lg-2 "></div>
                            <div>
                              {/* {this.state.Busi.length === 0 ? null : ( */}
                              <input
                                className="Searchbox"
                                id="CNSearch"
                                placeholder="Search"
                                onKeyUp={this.onKeyUp}
                                onClick={() => {
                                  this.setState({
                                    // Filterpop: true,
                                    Filterpop: !this.state.Filterpop,
                                  });
                                }}
                                readOnly
                              />
                              {/* )} */}
                            </div>
                          </div>

                          <div
                              >
                                <input
                                  className="Searchbox"
                                  id="CNSearch1"
                                  placeholder="Search"
                                  onKeyPress={this.handleKeyPress}
                                  onKeyUp={this.myFunction}
                                  // value={filterValue}
                                  onClick={() => {
                                    this.setState({
                                      Filterpop: !this.state.Filterpop,
                                    });
                                  }}
                                  readOnly
                                />
                              </div>
                              <br/>
                              </div>
                              <div className="card-block2">  
                          <form
                            onSubmit={this.Multidelete}
                            onKeyPress={this.handleKeyPress}
                            style={{ marginTop: "-20px" }}
                          >
                            <button
                              type="submit"
                              className="multitrash"
                              id="delete"
                              style={{ float: "right", visibility: this.state.showDelete ? 'visible' : 'hidden',marginTop:"30px" }}
                            >
                              <i
                                className="icofont icofont-delete-alt"
                                style={{ color: "darkgray" }}
                              ></i>
                            </button>
                            <span
                              id="deletecount"
                              className="multitrash"
                              style={{ visibility: this.state.showDeleteCount ? 'visible' : 'hidden', fontSize: "17px",marginTop:"30px" }}
                            >
                              {this.state.showDeleteCount && (
                              <span style={{backgroundColor: "#007bff",background: "linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)"}}>{this.state.SelectedNames.length} selected</span>
                               )}
                            </span>
                            <div id="selectalldiv" style={{ visibility: this.state.showSelectAll ? 'hidden' : 'hidden' }}>
                            <input type="checkbox" id="selectall" checked={this.state.selectAllChecked} onChange={this.handleSelectAllChange} />&nbsp;&nbsp;
                            </div>
                            <center>
                            <div className="dt-responsive table-responsive" style={{maxWidth:"60%"}}>
        <DataTable
          columns={columns}
          data={Business}
          selectableRows
          onSelectedRowsChange={this.handlecheckbox} // Assuming handlecheckbox is a method to handle row selection
          pagination
          customStyles={customStyles}
        />
      </div>
      </center>
                            {/* <div
                              className="dt-responsive table-responsive"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {this.state.Busi.length === 0 ? null : (
                                <table
                                  id="BusSheet"
                                  className="table nowrap"
                                  style={{ maxWidth: "70%" }}
                                >
                                  <thead id="tablepaddingA">
                                    <tr>
                                      <th>
                                        <input
                                          type="checkbox"
                                          id="selectall"
                                          onClick={() => {
                                            this.checkAll(this);
                                          }}
                                        />{" "}
                                      </th>
                                      <th>Business Unit</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody id="tablepadding">
                                    {FilterData.length === 0 && Business.length === 0 ? (
                                      <tr>
                                        <th
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Record
                                        </th>
                                      </tr>
                                    ) : null}
                                    {Business.map((Business) => (
                                      <tr>
                                        <td>
                                          <input
                                            type="checkbox"
                                            name="Id_delete"
                                            onChange={this.handlecheckbox}
                                            defaultValue={Business.id}
                                          />
                                        </td>
                                        <td>{Business.businessUnit}</td>
                                        <td>
                                          <button
                                            className="button-30 sk-del"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.DeleteButton(Business.id);
                                            }}
                                            title="Delete"
                                            style={{ marginLeft: "-24px" }}
                                          >
                                            {/* Delete&nbsp; */}
                                            {/* <i
                                              className="fa fa-minus"
                                              aria-hidden="true"
                                            ></i>
                                          </button>{" "}
                                          &nbsp;&nbsp;&nbsp;
                                          <button
                                            className="button-30 sk-edit"
                                            title="Edit"
                                            type="button"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.EditModel(
                                                Business.id,
                                                Business.businessUnit
                                              );
                                            }}
                                            onKeyPress={this.handleKeyPress}
                                          >
                                         
                                            <i
                                              className="fa fa-edit"
                                              aria-hidden="true"
                                            ></i>{" "}
                                          </button>
                                          &nbsp;&nbsp;&nbsp;
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div>{" "} */} 
                          </form>
                          </div>
                        <br />
                        <br />
                       

                    <table
                      id="BusSheetExpo"
                      className="table nowrap"
                      style={{ display: "none" }}
                    >
                      <thead>
                        <tr>
                          <th>Business Unit</th>
                        </tr>
                      </thead>
                      <tbody>

                        {Business.map((Business) => (
                          <tr>
                            <td>{Business.businessUnit}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {Filterpop && (
                <div className="popSearchTeam">
                  <div className="popSearchinnernormalTeam">
                    <div
                      className="popSearchinnertitle"
                      id="popSearchinnernormal"
                    >
                      <button
                        id="close"
                        style={{
                          float: "right",
                          backgroundColor: "#a0a3a305",
                          color: "#797070",
                          border: "1px solid #a3a5a505",
                          marginTop: "-20px",
                          marginRight: "-20px",
                        }}
                        onClick={() => {
                          this.setState({ Filterpop: false });
                          this.GetBusiness();
                        }}
                      >
                        <CloseIcon />
                      </button>

                      <div className="checkboxes">
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          style={{
                            fontSize: "20px",
                            color: "gray",
                            marginLeft: "-1%",
                            position: "absolute",
                            marginTop: "0.5%",
                          }}
                        ></i>
                        <input
                          className="Searchbox"
                          placeholder="Search"
                          onKeyUp={this.onKeyUp}
                          style={{ float: "right", width: "80%" }}
                        />
                        <br></br>
                        {this.state.letters.map(
                          (letter, index) => (
                            <button
                              className="filter"
                              key={index}
                              onClick={(event) =>
                                this.handleBusinessFilter(
                                  letter,
                                  event
                                )
                              }
                            >
                              {letter}
                            </button>
                          )
                        )}
                        <br></br>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
