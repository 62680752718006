import React, { Component } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import SideBar from "../../Common/Sidebar";
import $ from "jquery";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import EditDesignationModel from "../../Common/EditDesignationModel";
import AddDesignationModel from "../../Common/AddDesignationModel";
import Designationxl from "../../Assets/Files/Designationxl.xlsx";
import CloseIcon from "@material-ui/icons/Close";
import { BASE_URLs } from "../../Config/Base";
import "../../Css/excel.css";
var XLSX = require("xlsx");

$(document).ready(function () {
  $("#add-Educat").hide();
});
$(document).ready(function () {
  /*   $("#saverow2").click(function(){
      $("#add-row2").hide();
    }); */
  $("#Btn_edu").click(function () {
    $("#add-Educat").show();
  });
});
export default class SADegree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      Designation: [],
      AddDesignation: [],
      AddDesignationModel: false,
      EditDesignationModel: false,
      Id: "",
      SkillName: "",
      SkillDescription: "",
      Category: "",
      rows: [],
      cols: [],
      currentSheet: {},
      formErrors: {},
      allNotify: [],
      Notification: [],
      Designation: [],
      Filterpop: false,
      letters: [],
      FilterData:"",
      showExcel: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.state;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormValidation = this.handleFormValidation.bind(this);
    this.Edit = this.Edit.bind(this);
  }

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("designation-sec");
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    workbook.Sheets.Sheet1.A1.s = { font: { bold: true } };
    // Process Data (add a new row)
    var ws = workbook.Sheets["Sheet1"];
    //XLSX.utils.sheet_add_aoa(ws, [["Created "+new Date().toISOString()]], {origin:-1});
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }

  // handleSubmit(event){

  //   event.preventDefault();

  // if(this.handleFormValidation()){
  //   const data = new FormData(event.target);

  //   fetch(`${BASE_URLs}Designation/EditDesignation",{
  //     method: 'POST',
  //     headers:{
  //      // 'Accept': 'application/json',
  //                // 'Content-Type': 'application/json',
  //                  Authorization: `Bearer ${JSON.parse(localStorage.user)}`
  //     },
  //     body: data,
  //   }).then(res => res.json()).then((resp) => {

  //    if(resp.status === "Success"){

  //     toast.success(resp.message, {
  //       position: "top-center",
  //       autoClose: 3200,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       });
  //       setTimeout(function(){

  //         if(document.getElementById("close")){

  //           document.getElementById("close").click();

  //       }

  //       }, 4000);

  //          }
  //          else{
  //           toast.error(resp.message, {
  //             position: "top-center",
  //             autoClose: 3200,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             });
  //           }

  //    })
  //   // document.getElementById("close").click();

  //   }

  // }

  handleFormValidation() {
    //const {SkillName,SkillDescription,Category}= this.state;
    var SkillName = document.getElementById("SkillName").value;
    var SkillDescription = document.getElementById("SkillDescription").value;
    var Category = document.getElementById("Category").value;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!SkillName) {
      formIsValid = false;
      formErrors["SkillNameErr"] = "SkillName is required";
    }
    if (!SkillDescription) {
      formIsValid = false;
      formErrors["SkillDescriptionErr"] = "SkillDescription is required";
    }
    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  handleUpload = (event) => {
    this.setState({ showExcel: true });

    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document.getElementById("delete").setAttribute("style", "visibility: hidden;");
        document.getElementById("deletecount").setAttribute("style", "visibility: hidden;");
      }
    }

    // Get the uploaded file
    var inputElement = document.getElementById("files");
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf("."));
    var allowedExtensionsRegx = /(\.xlsx|\.xlsm)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);

    // Check if the file is allowed
    if (isAllowed) {
      // Read the file using FileReader
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        // Check if the parsed data is empty
        if (parsedData.length === 0) {
          toast.error("The uploaded Excel file is empty.",{ autoClose: 3200 });
          this.setState({ initialData: [], showExcel: false });
        } else {
          this.setState({ initialData: parsedData });
        }
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsBinaryString(files[0]);

      return true; // Indicate successful file upload
    } else {
      alert("Invalid File Type.");
      return false; // Indicate failed file upload
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}SuperAdmin/SADesignationExcel`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          this.setState({ initialData: undefined });
          var file = document.getElementById("files");
          var emptyFile = document.createElement("input");
          emptyFile.type = "file";
          file.files = emptyFile.files;
          this.GetDesignation();
          if (resp === undefined) {
            alert("0  Records  inserted ");
          }
          if (resp === 0) {
            alert("Records added Successfully");
          } else {
            alert(+resp + " Records not inserted ");
          }
          // this.GetAddSkill();
        });
    } catch (error) {}
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  GetDesignation() {
    let url = `${BASE_URLs}Designation/GetSAMasterDesignation`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Designation: data,
          Desi: data,
        });
      });
  }

  Edit(Id, category, skillName, skillDescription) {
    var page = {
      pathname: "/EditMasterSkill",
      state: {
        Id: Id,
        category: category,
        skillName: skillName,
        skillDescription: skillDescription,
      },
    };
    this.props.history.push(page);
  }

  deleteSkill(id) {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}Skill/DeleteSuperEMPS?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          toast.success(resp, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.GetDesignation();
          document.getElementById("Eduform").reset();
        });
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    var SkillName = document.getElementById("SkillName").value;
    var SkillDescription = document.getElementById("SkillDescription").value;
    var Category = document.getElementById("Category").value;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    } else if (!SkillName) {
      formIsValid = false;
      formErrors["SkillNameErr"] = "SkillName is required";
    } else if (!SkillDescription) {
      formIsValid = false;
      formErrors["SkillDescriptionErr"] = "SkillDescription is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  };
  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };
  componentDidMount() {
    this.GetDesignation();
    $(document).ready(function () {
      $("#add-Educat").hide();
    });
    $(document).ready(function () {
      /*   $("#saverow2").click(function(){
              $("#add-row2").hide();
            }); */
      $("#Btn_edu").click(function () {
        $("#add-Educat").show();
      });
    });

    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });
  }

  onKeyUp = (e) => {
    const Designation = this.state.Desi.filter((item) =>
      item.designation.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Designation });
  };

  handleFilter = (letter, event) => {
    event.preventDefault();
    const Designation = this.state.Desi.filter((item) => {
      return item.designation.charAt(0).toLowerCase() === letter.toLowerCase();
    });
    this.setState({ Designation, showResults: true });
  };

  checkAll(e) {
    var checkboxes = document.getElementsByName("Id_delete");

    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  handlecheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:visible ;");
    } else {
      document
        .getElementById("selectalldiv")
        .setAttribute("style", "visibility:hidden ;");
    }
    if (checkboxes.length >= 1) {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility:visible ;");
      document
        .getElementById("deletecount")
        .setAttribute(
          "style",
          "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
        );
      var msg = document.getElementById("deletecount");
      let count = checkboxes.length - 1;
      if (document.getElementById("selectall").checked) {
        msg.innerHTML = "<span> " + count + " Records are selected </span> ";
      } else {
        msg.innerHTML =
          checkboxes.length === 1
            ? "<span> " + checkboxes.length + " Record is selected </span> "
            : "<span> " + checkboxes.length + " Records are selected </span> ";
      }
    } else {
      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
    }
    if (document.getElementById("selectall").checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById("selectall").checked = true;
      }
    } else {
      if (document.getElementById("selectall").checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById("selectall").checked = false;
        }
      }
    }
  }

  AddDesignationModel = () => {
    this.setState((prevState) => ({
      AddDesignationModel: !prevState.AddDesignationModel,
    }));
    this.GetDesignation();
  };

  EditDesignationModel = (id, designationName) => {
    this.setState({
      EditDesignationValues: {
        id: id,
        designationName: designationName,
      },
    });
    this.setState((prevState) => ({
      EditDesignationModel: !prevState.EditDesignationModel,
    }));
    this.GetDesignation();
  };
  Multidelete = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    var role = JSON.parse(localStorage.Role);
    role = role.toString();

    fetch(`${BASE_URLs}Designation/Multidelete`, options)
      .then((response) => response.json())
      .then((data) => {
        this.GetDesignation();
        toast.success(data.message, {
          position: "top-center",
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
        var checkboxes = document.querySelectorAll(
          'input[type="checkbox"]:checked'
        );

        for (var i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = false;
        }
      });
  };
  cancel = () => {

    var inputElement = document.getElementById("files");
    inputElement.value = "";

    this.setState({ showExcel: false });

  };
  render() {
    var username = JSON.parse(localStorage.uname);
    var filename = `O_${username}_Designationsheet`;
    const { Designation, Filterpop,FilterData,showExcel} = this.state;
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.hash} />
        {this.state.AddDesignationModel ? (
          <AddDesignationModel
            id="Success"
            close={() => this.AddDesignationModel()}
          />
        ) : null}
        {this.state.EditDesignationModel ? (
          <EditDesignationModel
            id="Success"
            values={this.state.EditDesignationValues}
            close={() => this.EditDesignationModel()}
          />
        ) : null}
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="card">
                    <div className="glowBox">
                      <div>
                        <div style={{ float: "left" }}>
                          <a href onClick={() => this.props.history.goBack()}>
                            <i
                              className="icofont icofont-arrow-left"
                              style={{ fontSize: "45px", color: "white" }}
                            />
                          </a>{" "}
                        </div>
                      </div>
                      <h4 className="title" style={{ marginRight: "45px" }}>
                        Designation{" "}
                      </h4>
                    </div>

                    <div className="add-rowEduform">
                      <form
                        autoComplete="on"
                        id="Eduform"
                        encType="multipart/form-data"
                        onSubmit={this.handleSubmit}
                      >
                        <div className="card-block">
                          <div className="row">
                            {/* <div className="col-lg-12"> */}
                            {/* <div className="row"> */}
                            <div className="col btnalign">
                              <button
                                onClick={() => this.AddDesignationModel()}
                                onKeyPress={this.handleKeyPress}
                                type="button"
                                style={{ width: "150px", background: "white" }}
                                id="addRow"
                                className="dropdown_item-2"
                                className="btn "
                              >
                                Add{" "}
                              </button>
                            </div>
                            {/* <div className="col btnalign">   
            <label htmlFor="files" className="btn" id="addBtn" name="DesignationFile" >Import From Excel</label>
           <input
             id="files"
             type='file'
             accept='.xlsx'
             style={{ display:'none'}}
             onChange={this.handleUpload}
             name="DesignationFile"
           /> 
           </div> */}
                            <div className="col btnalign">
                              <label
                                htmlFor="files"
                                className="btn"
                                id="addBtn"
                                name="DesignationFile"
                              >
                                Import From Excel
                              </label>
                              <input
                                id="files"
                                type="file"
                                accept=".xlsx"
                                style={{ display: "none" }}
                                onChange={this.handleUpload}
                                name="DesignationFile"
                              />
                            </div>

                            <div className="col btnalign">
                              <button
                                className="btn"
                                type="button"
                                onClick={() => this.exportXlsx(filename)}
                                onKeyPress={this.handleKeyPress}
                              >
                                Export to excel
                              </button>
                              {/* <ReactHTMLTableToExcel                  
                        className="btn"  
                        table="designation-sec"  
                        filename={filename}
                        sheet="Sheet"  
                        buttonText="Export to Excel " id="designation" /> */}
                            </div>

                            <div
                              className="col btnalign"
                              onKeyDown={this.handleKeyPress}
                            >
                              <a
                                className="btn"
                                href={Designationxl}
                                type="button"
                                style={{ color: "#5035ce" }}
                              >
                                <i
                                  title="Download Template"
                                  className="icofont icofont-download"
                                ></i>{" "}
                                Download
                              </a>
                            </div>
                            {/* </div>  */}
                            {/* <div className="col-lg-2 btnalign"></div> */}

                            <div className="col-lg-4">
                              <input
                                className="Searchbox"
                                placeholder="Search"
                                onKeyUp={this.onKeyUp}
                                style={{ float: "right" }}
                                onKeyPress={this.handleKeyPress}
                                onClick={() => {
                                  this.setState({
                                    Filterpop: !this.state.Filterpop,
                                  });
                                }}
                                readOnly
                              />
                            </div>
                            {/* </div>         */}
                          </div>
                          <center>
                          {showExcel && this.state.initialData !== undefined && (
  <table className="table" id="tableDesign" style={{marginTop:'30px'}}>
    <thead>
      <tr>
        {Object.keys(this.state.initialData[0]).map((key) => (
          <th key={key}>{key}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {this.state.initialData.map((row, index) => (
        <tr key={index}>
          {Object.values(row).map((value, index) => (
            <td key={index} id="pdesign">{value}</td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
) }
</center> 
 {showExcel && this.state.initialData !== undefined && (
 
                            <div className="col-lg-12">
                              <div className="savebtndiv">
                                <button
                                  className="btn"
                                  id=""
                                  type="submit"
                                  style={{ width: "150px" }}
                                >
                                  Save
                                </button>
                                <span
                                  className="btn"
                                  id="add-row"
                                  style={{ width: "150px" }}
                                  onClick={this.cancel}
                                >
                                  Cancel
                                </span>
                            </div>
                            </div>
                          )}
                          <OutTable
                            data={this.state.rows}
                            columns={this.state.cols}
                            tableClassName="ExcelTable2007"
                            tableHeaderRowclassName="heading"
                          />
                        </div>
                      </form>
                    </div>
                    <br />

                    <div>
                      <form
                        onSubmit={this.Multidelete}
                        onKeyPress={this.handleKeyPress}
                      >
                        <div className="card-block2">
                          <div
                            id="selectalldiv"
                            style={{ visibility: "hidden" }}
                          />
                          &nbsp;&nbsp;
                        </div>
                        <button
                          type="submit"
                          className="multitrash"
                          id="delete"
                          style={{ float: "right", visibility: "hidden" }}
                        >
                          <i
                            className="icofont icofont-delete-alt"
                            style={{ color: "darkgray" }}
                          ></i>
                        </button>
                        <span
                          id="deletecount"
                          className="multitrash"
                          style={{ visibility: "hidden", fontSize: "17px" }}
                        ></span>
                        <div className="dt-responsive table-responsive">
                          {/* <table id="designation-sheet" className="table nowrap" style={{width:"70%"}}>
                     <thead>
                       <tr>
                      
                       <th> Designation</th>
                       
                       </tr>
                     </thead>
                     <tbody>  
                  {Designation.map(Designation=>
                       <tr>
                        
                           <td>{Designation.designation}</td>
                           
                       </tr>
                     )}
                   </tbody>
                   </table> */}
                          <div className="row">
                            <div className="col-lg-1"></div>
                            <div className="col-lg-10 skillname">
                              {/* <p>Designation</p> */}
                            </div>
                            <div className="col-lg-1"></div>
                          </div>
                          <br />
                          <div className="row" style={{ padding: "15px" }}>
                            {Designation.map((Designation) => (
                              <div className="col-lg-3">
                                <p>{Designation.designation}</p>
                              </div>
                            ))}
                          </div>
                          <center>
                          {Filterpop === true && FilterData.length === 0 && Designation.length === 0 ? (
                                       <div>No Record</div>
                                    ) : null}
                                    </center>
                          <br />
                        </div>{" "}
                      </form>
                      <table
                        id="designation-sec"
                        className="table nowrap"
                        hidden
                        style={{ width: "70%" }}
                      >
                        <thead>
                          <tr>
                            <th>Designation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Designation.map((Designation) => (
                            <tr>
                              <td>{Designation.designation}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>

                {Filterpop && (
                  <div className="popSearchTeam">
                    <div className="popSearchinnernormalTeam">
                      <div
                        className="popSearchinnertitle"
                        id="popSearchinnernormal"
                      >
                        <button
                          id="close"
                          style={{
                            float: "right",
                            backgroundColor: "#a0a3a305",
                            color: "#797070",
                            border: "1px solid #a3a5a505",
                            marginTop: "-20px",
                            marginRight: "-20px",
                          }}
                          onClick={() => {
                            this.setState({ Filterpop: false });
                            this.GetDesignation();
                          }}
                        >
                          <CloseIcon />
                        </button>

                        <div className="checkboxes">
                          <i
                            className="fa fa-search"
                            aria-hidden="true"
                            style={{
                              fontSize: "20px",
                              color: "gray",
                              marginLeft: "-1%",
                              position: "absolute",
                              marginTop: "0.5%",
                            }}
                          ></i>
                          <input
                            className="Searchbox"
                            placeholder="Search"
                            onKeyUp={this.onKeyUp}
                            style={{ float: "right", width: "80%" }}
                          />
                          <br></br>
                          {this.state.letters.map((letter, index) => (
                            <button
                              className="filter"
                              key={index}
                              onClick={(event) =>
                                this.handleFilter(letter, event)
                              }
                            >
                              {letter}
                            </button>
                          ))}
                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
