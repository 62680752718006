import React, { Component } from "react";
import AddHolidaysPop from "../../Common/AddHolidaysPop";
import EditHolidayPop from "../../Common/EditHolidayPop";
import SideBar from "../../Common/Sidebar";
import CloseIcon from "@material-ui/icons/Close";
import { ReactExcel, readFile } from "@ramonak/react-excel";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import HolidayTemplate from "../../Assets/Files/HolidayTemplate.xlsx";
import { BASE_URLs } from "../../Config/Base";
import { ToastContainer, toast } from "react-toastify";
import DataTable from 'react-data-table-component';
import "../../Css/excel.css";
var XLSX = require("xlsx");
export default class DashBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      DepartmentName: "",
      SkillModel: false,
      EditModel: false,
      SkillEditId: "",
      comlogo: [],
      Holiday: [],
      Holidays: [],
      Holi: [],
      rows: [],
      cols: [],
      currentSheet: {},
      formErrors: {},
      Notification: [],
      EditLocationValues: [],
      Filterpop: false,
      FilterData:"",
      showExcel: false,
      filterValue:'',
      showSelectAll: false,
      showDelete: false,
      showDeleteCount: false,
      selectAllChecked: false,
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  closefuncdisc = () => {
    this.setState({ Filterpop: false });
    var AllMemberlist = this.state.AllMemberAccess;
    this.setState({ MemberAccess: AllMemberlist, showResults: true });
  };

  setFocus = () => {
    this.setState(
      (prevState) => ({
        Filterpop: !prevState.Filterpop,
      }),
      () => {
        if (this.state.Filterpop) {
          this.searchForDiscRef.current.focus();
        }
      }
    );
  };

  exportXlsx(filename) {
    // Acquire Data (reference to the HTML table)
    var table_elt = document.getElementById("HolidayExport");
    // Extract Data (create a workbook object from the table)
    var workbook = XLSX.utils.table_to_book(table_elt);
    // Process Data (add a new row)
    // var ws = workbook.Sheets["Sheet1"];
    // Package and Release Data (`writeFile` tries to write and save an XLSX file)
    XLSX.writeFile(workbook, "" + filename + ".xlsx");
  }

  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }

  GetHolidays() {
    let url = `${BASE_URLs}Holiday/HolidayGet`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("holi",data)
        this.setState({
          Holiday: data,
          Holi: data,
        });
      });
  }
  onKeyUp = (e) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    const Holiday = this.state.Holi.filter(
      (item) =>
        item.date.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.reason.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.holiday.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.locationtype
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        item.day.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ Holiday });
  };

  LetterHoliSearch = (letter, e) => {
    e.preventDefault();
    const Holiday = this.state.Holi.filter(
      (item) =>
        item.date.toLowerCase().includes(letter.toLowerCase()) ||
        item.reason.toLowerCase().includes(letter.toLowerCase()) ||
        item.holiday.toLowerCase().includes(letter.toLowerCase()) ||
        item.locationtype.toLowerCase().includes(letter.toLowerCase()) ||
        item.day.toLowerCase().includes(letter.toLowerCase())
    );
    this.setState({ Holiday , showResults: true, FilterData: Holiday});
  };

  SkillModel = () => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState((prevState) => ({
      SkillModel: !prevState.SkillModel,
    }));
    this.GetHolidays();
  };

  EditModel = (id, Date, Reason, holiday, loctype) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditLocationValues: {
        id: id,
        Date: Date,
        Reason: Reason,
        holiday: holiday,
        locationtype: loctype,
      },
    });
    this.setState((prevState) => ({
      EditModel: !prevState.EditModel,
    }));

    this.GetHolidays();
  };

  checkAll(e) {
    var checkboxes = document.getElementsByName("Id_delete");
    var sa = document.getElementById("selectall");
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility:visible ;margin-top: 30px;");
        document
          .getElementById("deletecount")
          .setAttribute(
            "style",
            "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top: 30px;"
          );
        var msg = document.getElementById("deletecount");
        msg.innerHTML = "<span> " + count + "  selected </span> ";
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }
  }

  handlecheckbox = (state) => {
    const selectedRows = state.selectedRows;

    this.setState({ SelectedNames: selectedRows });
    this.setState({
      showSelectAll: selectedRows.length >= 2,
      showDelete: selectedRows.length >= 1,
      showDeleteCount: selectedRows.length > 0,
    });
  };

  // handlecheckbox() {
  //   let checkboxes = document.querySelectorAll(
  //     '#tablepadding input[type="checkbox"]:checked'
  //   );
  //   let Total = document.querySelectorAll('input[type="checkbox"]');
  //   // selectalldiv
  //   if (checkboxes.length >= 2) {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:visible ;");
  //   } else {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:hidden ;");
  //   }
  //   if (checkboxes.length >= 1) {
  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility:visible ;margin-top: 30px;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute(
  //         "style",
  //         "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);margin-top: 30px;"
  //       );
  //     var msg = document.getElementById("deletecount");
  //     let count = checkboxes.length - 1;

  //     if (document.getElementById("tablepadding").checked) {
  //       msg.innerHTML = "<span> " + count + " selected </span> ";
  //     } else {
  //       msg.innerHTML = "<span> " + checkboxes.length + " selected </span> ";
  //     }
  //   } else {
  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility: hidden;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute("style", "visibility: hidden;");
  //   }
  //   if (document.getElementById("selectall").checked === false) {
  //     if (checkboxes.length === Total.length - 1) {
  //       //
  //       document.getElementById("selectall").checked = true;
  //     }
  //   } else {
  //     if (document.getElementById("selectall").checked) {
  //       if (checkboxes.length - 2 !== Total.length) {
  //         document.getElementById("selectall").checked = false;
  //       }
  //     }
  //   }
  // }

  componentDidMount() {
    this.GetOrgLogo();
    this.GetHolidays();
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    this.setState({ letters: alphabet });
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }
  Getall() {
    fetch(`${BASE_URLs}AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleFormValidation() {
    const {
      FirstName,
      LastName,
      Email,
      Mobile,
      Department,
      Designation,
      RoleName,
    } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!FirstName) {
      formIsValid = false;
      formErrors["FirstNameErr"] = "First Name is required";
    }
    if (!LastName) {
      formIsValid = false;
      formErrors["LastNameErr"] = "Last Name is required";
    }
    if (!Email) {
      formIsValid = false;
      formErrors["EmailErr"] = "Email is required";
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(Email)) {
      formIsValid = false;
      formErrors["EmailErr"] = "Invalid Email id";
    }
    if (!Mobile) {
      formIsValid = false;
      formErrors["MobileErr"] = "Mobile is required";
    }
    // else if (!Mobile >= 10) {
    //   formIsValid = false;
    //   formErrors["MobileErr"] = "Invalid Mobile number";
    // }
    else {
      var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
      if (!mobPattern.test(Mobile)) {
        formIsValid = false;
        formErrors["MobileErr"] = "Invalid phone number";
      }
    }
    if (!Department) {
      formIsValid = false;
      formErrors["departmentErr"] = "department is required";
    }
    if (!Designation) {
      formIsValid = false;
      formErrors["designationErr"] = "designation is required";
    }
    if (!RoleName) {
      formIsValid = false;
      formErrors["rolesErr"] = "Role is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  // handleSubmit = (event)  => {
  //   event.preventDefault();

  //   const data = new FormData(event.target);
  //   fetch(`${BASE_URLs}Department/AddDepartment`, {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
  //     },
  //     body: data,
  //   })
  //     .then((res) => res.json())
  //     .then((resp) => {
  //       alert(resp.message);
  //     });
  // }

  Noticount = async (Id) => {
    await fetch(`${BASE_URLs}Notification/Noticount`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Notification_Id: Id,
      }),
    }).then((result) => {
      result.json().then((resp) => {
        localStorage.setItem("success", JSON.stringify(resp.status));

        var a = localStorage.getItem("success");
        var b = JSON.parse(a);
        if (b === "Success") {
          window.location.href = "/Organization";
          localStorage.removeItem("success");
        }
      });
    });
  };

  Handledepartment() {
    var department = document.getElementById("department").value;

    if (department === "others") {
      document.getElementById("departmenttext").value = "";
      document.getElementById("departmenttext").type = "text";
    } else {
      document.getElementById("departmenttext").value = "None";
      document.getElementById("departmenttext").type = "hidden";
    }
  }
  Handledesignation() {
    var department = document.getElementById("Designation").value;

    if (department === "others") {
      document.getElementById("designationtext").value = "";
      document.getElementById("designationtext").type = "text";
    } else {
      document.getElementById("designationtext").value = "None";
      document.getElementById("designationtext").type = "hidden";
    }
  }
  Handleroles() {
    var department = document.getElementById("roles").value;

    if (department === "others") {
      document.getElementById("roletext").value = "";
      document.getElementById("roletext").type = "text";
    } else {
      document.getElementById("roletext").value = "None";
      document.getElementById("roletext").type = "hidden";
    }
  }

  handleUpload = (event) => {
    this.setState({ showExcel: true });

    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("Id_delete")) {
      var checkboxes = document.getElementsByName("Id_delete");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document.getElementById("delete").setAttribute("style", "visibility: hidden;");
        document.getElementById("deletecount").setAttribute("style", "visibility: hidden;");
      }
    }

    // Get the uploaded file
    var inputElement = document.getElementById("files");
    var files = inputElement.files;
    var filename = files[0].name;
    var extension = filename.substr(filename.lastIndexOf("."));
    var allowedExtensionsRegx = /(\.xlsx|\.xlsm)$/i;
    var isAllowed = allowedExtensionsRegx.test(extension);

    // Check if the file is allowed
    if (isAllowed) {
      // Read the file using FileReader
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        // Check if the parsed data is empty
        if (parsedData.length === 0) {
          toast.error("The uploaded Excel file is empty.",{ autoClose: 3200 });
          this.setState({ initialData: [], showExcel: false });
        } else {
          this.setState({ initialData: parsedData });
        }
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsBinaryString(files[0]);

      return true; // Indicate successful file upload
    } else {
      alert("Invalid File Type.");
      return false; // Indicate failed file upload
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.target);
      fetch(`${BASE_URLs}Holiday/HolidayExcel`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp === undefined) {
            alert("0  Records  inserted ");
          }
          if (resp === 0) {
            alert("Records added Successfully");
          } else {
            alert(+resp + " Records not inserted ");
          }
          this.GetHolidays();
          this.setState({ initialData: undefined });
        });
      var file = document.getElementById("files");
      var emptyFile = document.createElement("input");
      emptyFile.type = "file";
      file.files = emptyFile.files;
    } catch (error) {}
  };

  excelSerialDateToJSDate = (serial) => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);
    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;
    total_seconds -= seconds;

    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  };

  // Format date function
  formatDate = (inputDate) => {
    let date;

    if (typeof inputDate === 'number') {
      // If the input is a number, treat it as an Excel serial date
      date = this.excelSerialDateToJSDate(inputDate);
    } else {
      // Otherwise, assume it's a date string and parse accordingly
      const dateParts = inputDate.split("-");
      if (dateParts.length === 3) {
        const [day, month, year] = dateParts;
        date = new Date(`${year}-${month}-${day}`);
      } else {
        date = new Date(inputDate);
      }
    }

    // Ensure date is valid
    if (isNaN(date.getTime())) {
      return inputDate; // Return the original input if it's not a valid date
    }

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Check if value is a date
  isDate = (value) => {
    if (typeof value === 'number') {
      return true; // Assume all numbers are valid Excel serial dates
    }

    if (typeof value !== 'string') {
      return false;
    }

    const dateParts = value.split("-");
    if (dateParts.length === 3) {
      const [day, month, year] = dateParts;
      const date = new Date(`${year}-${month}-${day}`);
      return !isNaN(date.getTime());
    }

    return !isNaN(Date.parse(value));
  };

  Multidelete = (event) => {
    event.preventDefault();
    const data = new FormData();
    this.state.SelectedNames.forEach((row) => {
      data.append("Id_delete", row.holiday_Id);
    });
  
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          Accept: "application/json",

          Authorization: `Bearer ${JSON.parse(localStorage.user)}`
        },
        body: data,
      };
      fetch(`${BASE_URLs}Holiday/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.setState({ initialData: undefined });
          this.GetHolidays();
          // alert(resp.message);
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
    this.GetHolidays();
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  DeleteButton = (holiday_Id) => {
    const data = new FormData();
    data.append("Id_delete", holiday_Id);
    if (
      window.confirm("Are you sure you want to delete this record permanently?")
    ) {
      var options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };
      fetch(`${BASE_URLs}Holiday/Multidelete`, options)
        .then((res) => res.json())
        .then((resp) => {
          this.GetHolidays();
          toast.success(resp.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });

      document
        .getElementById("delete")
        .setAttribute("style", "visibility: hidden;");
      document
        .getElementById("deletecount")
        .setAttribute("style", "visibility: hidden;");
      var checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:checked'
      );

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }

      //check.checked = false;
      document.querySelectorAll('input[type="checkbox"]').checked = false;
    }
  };

  cancel = () => {

    var inputElement = document.getElementById("files");
    inputElement.value = "";

    this.setState({ showExcel: false });

  };

  handleSelectAllChange = (event) => {
    const { checked } = event.target;
    this.setState({ selectAllChecked: checked });
  };

  render() {
    var username = JSON.parse(localStorage.uname);

    const { Holiday, Filterpop ,FilterData,showExcel} = this.state;
    var filename = `O_${username}_Holiday`;

    const customStyles = {
      headCells: {
        style: {
          flex: "0.5" 
        }
      },
      header: {
        style: {
          fontWeight: 'bold',
          fontSize: "14px",
          flex: "0"  
        },
      },
      headCells: {
        style: {
          fontWeight: 'bold',
          fontSize: "14px",
          flex: "0" ,
          borderBottom: "1px solid #ccc",
          
        },
      },
   
      columns: {
        idHeader: {
          style: {
            fontWeight: 'bold',
            fontSize: "14px",
            flex: "0" 
          },
        },
      },
    };

    const columns = [
      {
        name:'Date',
        selector: row => row.date,
        id: "isheader",
        grow: 3.5,
        sortable: false, 
      },
      {
        name:'Day',
        selector: row => row.day,
        id: "isheader",
        grow: 3.5,
        sortable: false, 
      },
      {
        name:'Occassions',
        selector: row => row.reason,
        id: "isheader",
        grow: 3.5,
        sortable: false, 
      },
      {
        name:'Branch',
        selector: row => row.locationtype,
        id: "isheader",
        grow: 3.5,
        sortable: false, 
      },
      {
        name:'Optional',
        selector: row => row.holiday,
        id: "isheader",
        grow: 3.5,
        sortable: false, 
      },
     
      {
        name: 'Actions',
        grow: 0,
        cell: row => (
          <div>
            <button
              className="button-30 sk-del"
              onClick={(e) => {
                e.preventDefault();
                this.DeleteButton(row.holiday_Id);
              }}
              title="Delete"
              style={{ marginLeft: "-10px" }}
            >
              <i className="fa fa-minus" aria-hidden="true"></i>
            </button>
            &nbsp;&nbsp;
            {" "}
            <button
              className="Annbuttons sk-edit"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                this.EditModel(
                  row.id,
                  row.date,
                  row.reason,
                  row.holiday,
                  row.locationtype,
                  
                 
                );
              }}
              title="Edit"
            >
              <i className="fa fa-edit" aria-hidden="true"></i>
            </button>
          </div>
        )
      }
    ];

    return (
      <div>

         <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <SideBar active={window.location.hash} />
        {this.state.SkillModel ? (
          <AddHolidaysPop close={() => this.SkillModel()} />
        ) : null}
        {this.state.EditModel ? (
          <EditHolidayPop
            id={this.state.SkillEditId}
            values={this.state.EditLocationValues}
            close={() => this.EditModel()}
          />
        ) : null}

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "4%" }} className="title">
                            {" "}
                            Official Holidays
                          </h4>
                        </div>

                        <div className="card-block">
                          <form onSubmit={(e) => this.handleSubmit(e)}>
                            <div className="row">
                              <div className="col btnalign">
                                <h5 className="card-header-text">
                                  <button
                                    onClick={this.SkillModel}
                                    type="button"
                                    className="btn"
                                    style={{ width: "150px" }}
                                    onKeyPress={this.handleKeyPress}
                                  >
                                    Add
                                  </button>
                                </h5>
                              </div>
                              <div className="col btnalign">
                                <label
                                  htmlFor="files"
                                  className="btn"
                                  id="addBtn"
                                  name="Holidays"
                                  style={{ width: "163px" }}
                                >
                                  Import From Excel
                                </label>{" "}
                                &nbsp;&nbsp;
                                <input
                                  id="files"
                                  type="file"
                                  accept=".xlsx"
                                  style={{ display: "none" }}
                                  onChange={this.handleUpload}
                                  name="Holidays"
                                />
                              </div>
                              <div style={{padding: "7px"}} ></div>
                              <div className="col btnalign">
                                <button
                                  className="btn"
                                  type="button"
                                  onClick={() => this.exportXlsx(filename)}
                                  onKeyPress={this.handleKeyPress}
                                >
                                  Export to excel
                                </button>
                                {/* <ReactHTMLTableToExcel                  
           className="btn "  
           table="HolidaySheet"  
           filename={filename}
           sheet="Sheet"  
           buttonText="Export to Excel" id="exportxlUG" />  */}
                              </div>

                              <div
                                className="col btnalign"
                                onKeyDown={this.handleKeyPress}
                              >
                                <a
                                  className="btn"
                                  href={HolidayTemplate}
                                  type="button"
                                  style={{ color: "#5035ce" }}
                                >
                                  <i
                                    title="Download Template"
                                    className="icofont icofont-download"
                                  ></i>{" "}
                                  Download a template
                                </a>
                              </div>

                              <div
                                className="col-lg-1"
                                style={{
                                  height: "73px",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {/* Just for design */}
                              </div>
                             
                              {/*           
           <div className="col btnalign" onKeyDown={this.handleKeyPress}>
           <a href={HolidayTemplate} className=" download" style={{padding:"2px 15px",boxShadow:" -2px  -2px 16px -2px #5f7ae14a"}}><i title="Download Template" className="icofont icofont-cloud-download"></i></a>
           </div> */}
                              {/*  <div className="co-lg-2 btnalign">                               
            <a href={HolidayTemplate} className="download" style={{padding:"2px 15px",boxShadow:" -2px  -2px 16px -2px #5f7ae14a"}}>
             <i title="Download Template" className="icofont icofont-cloud-download"></i> </a>                            
             </div> */}
                              {/* <div className="col-lg-2 ">


           </div> */}

                              <div>
                                {/* {this.state.Holi.length === 0 ? null : ( */}
                                  <input
                                    // onKeyUp={this.onKeyUp}
                                    className="Searchbox"
                                    id="CNSearch"
                                    placeholder="Search"
                                    // onKeyPress={this.handleKeyPress}
                                    onClick={() => {
                                      this.setState({
                                        // Filterpop: true,
                                        Filterpop: !this.state.Filterpop,
                                      });
                                    }}
                                    readOnly
                                  />
                                {/* )} */}
                              </div>
                            </div>
                            <div 
                              >
                                <input
                                  className="Searchbox"
                                  id="CNSearch1"
                                  placeholder="Search"
                                  onKeyPress={this.handleKeyPress}
                                  onKeyUp={this.myFunction}
                                  onClick={() => {
                                    this.setState({
                                      Filterpop: !this.state.Filterpop,
                                    });
                                  }}
                                  readOnly
                                />
                              </div>
                            <center>
        {showExcel && this.state.initialData !== undefined && (
          <table className="table" id="tableDesign">
            <thead>
              <tr>
                {Object.keys(this.state.initialData[0]).map((key) => (
                  <th key={key} id="team1design">{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.state.initialData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((value, cellIndex) => (
                    <td key={cellIndex} id="teamdesign">
                      {this.isDate(value) ? this.formatDate(value) : value}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </center>
                            {showExcel && this.state.initialData !== undefined && (
                              <div className="savebtndiv">
                                <button
                                  className="btn"
                                  id="add-row"
                                  style={{ width: "150px" }}
                                >
                                  Save
                                </button>
                                &nbsp;&nbsp;&nbsp;
                                <button
                                  className="btn"
                                  id="add-row"
                                  style={{ width: "150px" }}
                                  // onClick={(e) => {
                                  //   e.preventDefault();
                                  //   this.setState({ initialData: undefined });
                                  // }}

                                  onClick={this.cancel}
                                >
                                  Cancel
                                </button>
                              </div>
                            )}
                            <OutTable
                              data={this.state.rows}
                              columns={this.state.cols}
                              tableClassName="ExcelTable2007"
                              tableHeaderRowclassName="heading"
                            />
                            <br/>
                          </form>
                          
                          <div
                            id="selectalldiv"
                            style={{ visibility: "hidden" }}
                          >
                            {" "}
                            &nbsp;&nbsp;
                          </div>
                          <form
                            onSubmit={this.Multidelete}
                            onKeyPress={this.handleKeyPress}
                            style={{ marginTop: "-52px"}}
                          >
                            <button
                              type="submit"
                              className="multitrash"
                              id="delete"
                              style={{ float: "right", visibility: this.state.showDelete ? 'visible' : 'hidden' }}
                            >
                              <i
                                className="icofont icofont-delete-alt"
                                style={{ color: "darkgray" }}
                              ></i>
                            </button>
                            <span
                              id="deletecount"
                              className="multitrash"
                              style={{ visibility: this.state.showDeleteCount ? 'visible' : 'hidden', fontSize: "17px" }}
                            >
                              {this.state.showDeleteCount && (
                              <span style={{backgroundColor: "#007bff",background: "linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)"}}>{this.state.SelectedNames.length} selected</span>
                               )}
                            </span>
                            <div id="selectalldiv" style={{ visibility: this.state.showSelectAll ? 'hidden' : 'hidden' }}>
                            <input type="checkbox" id="selectall" checked={this.state.selectAllChecked} onChange={this.handleSelectAllChange} />&nbsp;&nbsp;
                            </div>
                            <center>
                            <div className="dt-responsive table-responsive" style={{maxWidth:"60%"}}>
        <DataTable
          columns={columns}
          data={Holiday}
          keyField="holiday_Id"
          selectableRows
          onSelectedRowsChange={this.handlecheckbox} // Assuming handlecheckbox is a method to handle row selection
          pagination
          customStyles={customStyles}
        />
      </div>
      </center>

                            {/* <div
                              className="dt-responsive table-responsive table-res"
                              //  style={{display:"flex",alignItems:"center",justifyContent:"center"}}
                            >
                              {this.state.Holi.length === 0 ? null : (
                                <table
                                  id="HolidaySheet"
                                  className="table nowrap"
                                  style={{ maxWidth: "85%" }}
                                >
                                  <thead id="tablepaddingA">
                                    <tr>
                                      <th>
                                        <input
                                          type="checkbox"
                                          id="selectall"
                                          onClick={() => {
                                            this.checkAll(this);
                                          }}
                                        />{" "}
                                      </th>
                                      <th>Date</th>
                                      <th>Day</th>
                                      <th>Occasions</th>
                                      <th>Branch</th>
                                      <th>Optional</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody id="tablepadding">
                                  {FilterData.length === 0 && Holiday.length === 0 ? (
                                      <tr>
                                        <th
                                          colSpan="9"
                                          style={{ textAlign: "center" }}
                                        >
                                          No Record
                                        </th>
                                      </tr>
                                    ) : null}
                                    {Holiday.map((Holiday) => (
                                      <tr>
                                        <td>
                                          <input
                                            type="checkbox"
                                            name="Id_delete"
                                            onChange={this.handlecheckbox}
                                            defaultValue={Holiday.holiday_Id}
                                          />
                                        </td>
                                        <td>{this.formatDate(Holiday.date)}</td>
                                        <td>{Holiday.day}</td>
                                        <td>{Holiday.reason}</td>
                                        <td>{Holiday.locationtype}</td>
                                        <td>{Holiday.holiday}</td>
                                        
                                        <td>
                                        <button
                                            className="button-30 sk-del"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.DeleteButton(Holiday.holiday_Id);
                                            }}
                                            title="Delete"
                                            style={{marginLeft:"-24px"}}
                                          > */}
                                            {/* Delete&nbsp; */}
                                            {/* <i
                                              className="fa fa-minus"
                                              aria-hidden="true"
                                            ></i>
                                          </button>{" "}
                                          &nbsp;&nbsp;&nbsp;
                                          <button
                                            title="Edit"
                                            className="button-30 sk-edit"
                                            type="button"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              this.EditModel(
                                                Holiday.holiday_Id,
                                                Holiday.date,
                                                Holiday.reason,
                                                Holiday.holiday,
                                                Holiday.locationtype
                                              );
                                            }}
                                            //  src="https://img.icons8.com/android/22/000000/pencil.png"/
                                          >
                                            <i
                                              className="fa fa-edit"
                                              aria-hidden="true"
                                            ></i>{" "}
                                          </button>
                                          &nbsp;&nbsp;&nbsp;
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              )}
                            </div> */}

                            <table
                              id="HolidayExport"
                              className="HolidayExport"
                              hidden
                            >
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Day</th>
                                  <th>Occasions</th>
                                  <th>Optional</th>
                                  <th>Location</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Holiday.map((Holiday) => (
                                  <tr>
                                    <td>{Holiday.date}</td>
                                    <td>{Holiday.day}</td>
                                    <td>{Holiday.reason}</td>
                                    <td>{Holiday.holiday}</td>
                                    <td>{Holiday.locationtype}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {Filterpop && (
                                <div className="popSearchTeam">
                                  <div className="popSearchinnernormalTeam">
                                    <div
                                      className="popSearchinnertitle"
                                      id="popSearchinnernormal"
                                    >
                                      <button
                                        id="close"
                                        style={{
                                          float: "right",
                                          backgroundColor: "#a0a3a305",
                                          color: "#797070",
                                          border: "1px solid #a3a5a505",
                                          marginTop: "-20px",
                                          marginRight: "-20px",
                                        }}
                                        onClick={() => {
                                          this.setState({
                                            Filterpop: false,
                                          });
                                          this.GetHolidays();
                                        }}
                                      >
                                        <CloseIcon />
                                      </button>

                                      <div className="checkboxes">
                                        <i
                                          className="fa fa-search"
                                          aria-hidden="true"
                                          style={{
                                            fontSize: "20px",
                                            color: "gray",
                                            marginLeft: "-1%",
                                            position: "absolute",
                                            marginTop: "0.5%",
                                          }}
                                        ></i>
                                        <input
                                          className="Searchbox"
                                          placeholder="Search"
                                          onKeyUp={this.onKeyUp}
                                          style={{
                                            float: "right",
                                            width: "80%",
                                          }}
                                        />
                                        <br></br>
                                        {this.state.letters.map(
                                          (letter, index) => (
                                            <button
                                              id="searchfordisc"
                                              className="filter"
                                              key={index}
                                              onClick={(event) =>
                                                this.LetterHoliSearch(
                                                  letter,
                                                  event
                                                )
                                              }
                                            >
                                              {letter}
                                            </button>
                                          )
                                        )}
                                        <br></br>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

              {/* End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
