import { React, useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import { BASE_URLs } from "../Config/Base";
const AddTraineePop = (props) => {
  useEffect(() => {
    GetVenture();
    // SelectParticular();
  }, []);

  const [allVentures, setAllVentures] = useState([]);
  // HandleSelect
  const options = allVentures.map((item) => {
    return {
      label: `${item.firstName} ${item.lastName}`,

      value: item.firstName,
      name: item.department,
    };
  });

  const [selectedOptions, setSelectedOptions] = useState();

  function handleSelect(data) {
    setSelectedOptions(data);
    // console.log("Dropdown data", data);
  }
  function GetVenture() {
    // // console.log("Token", token);
    fetch(`${BASE_URLs}Trainer/AddTraineePopDropdown`, {
      method: "GET",

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",

        Accept: "application/json",

        Authorization: "Bearer " + `${token}`,

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },

      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Dataaaa", data);
        setAllVentures(data);

        //setpaginatedPosts(_(data).slice(0).take(pageSize).value());
      });

    // console.log("check", allVentures);
  }

  const token = JSON.parse(localStorage.user);

  //  /*----Get select to the particular Id----*/

  //  const [EditCorp ,SetEditCorp] =useState([]);

  //             function SelectParticular(){
  //               // const body =
  //               // {

  //               //  id:Id
  //               // }

  //               axios({
  //                      method:"POST",
  //                      url :`${BASE_URLs}Trainer/AddTraineePopDropdown`,

  //       headers: {
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
  //                             "Content-type": "application/json",
  //                             "Authorization": "Bearer " +`${token}`
  //                       }
  //                   })

  //                   .then((response) =>{
  //                     // console.log(response.data);

  //                     SetEditCorp(response.data);

  //                     localStorage.setItem("EditData",JSON.stringify(response.data))

  //                  })
  //                  .catch(error => {
  //                    // console.log(error);

  //                  })
  //             }

  function fetchTraineesName() {
    var MultiVentureId = selectedOptions.map((item) => item.name);
    var MultiVentureName = selectedOptions.map((item) => item.value);

    // console.log(MultiVentureId);
    // console.log(MultiVentureName);

    let SVentureId = MultiVentureId[0];
    let SVentureName = MultiVentureName.toString();

    // console.log("sample", SVentureId);
    // console.log(SVentureName);

    let data = {
      department: SVentureId,

      trainees: SVentureName,
    };

    axios({
      method: "POST",

      url: `${BASE_URLs}Trainer/PostTraineesName`,

      data,

      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",

        Authorization: "Bearer " + `${token}`,
      },
    })
      .then((response) => {
        // console.log("valuee", response.data);

        // if (response.data.status === "Success") {
        // alert("User Registered Successfully");

        if (response.data.status === "Success") {
          toast.success(response.data.message, {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(function () {
            document.getElementById("close").click();
          }, 4000);

          // Paropcloseform();
          ///  Getusers();
        } else if (response.data.status === "UserError") {
          alert("User Already Exist !");
        } else if (response.data.status === "Error") {
          // toast.error("User creation failed !", {

          //   position: "top-center",

          //   autoClose: 3200,

          //   hideProgressBar: false,

          //   closeOnClick: true,

          //   pauseOnHover: true,

          //   draggable: true,

          //   progress: undefined,

          //   className: 'toast-message'

          //   });
          alert("User Creation Failed");
          // setTimeout(() => {

          // }, 5000);
        }
      })

      .catch((error) => {
        // console.log(error);
      });
  }

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="JoinLink">
        <div className="JoinLinkinnernormalpop" style={{ width: "28%" }}>
          <button
            id="close"
            style={{
              float: "right",
              backgroundColor: "#a0a3a305",
              color: "#797070",
              border: "1px solid #a3a5a505",
            }}
            onClick={props.close}
          >
            <CloseIcon />
          </button>
          <div className="row">
            <div className="col-lg-12">
              <Select
                className="effect-16"
                options={options}
                id="OatAselect"
                // placeholder="Venture Name"
                value={selectedOptions}
                onChange={handleSelect}
                isSearchable={true}
                isMulti
                isClearable
              />

              <label>Trainees Name</label>
            </div>
          </div>

          <br />
          <div className="row">
            <div className="col-lg-12" style={{ textAlign: "center" }}>
              <button
                type="submit"
                className="but"
                style={{ height: "39.5px", width: "100px", marginTop: "-0.3%" }}
                onClick={fetchTraineesName}
              >
                Join Request
              </button>
            </div>
          </div>
          <br />
        </div>
      </div>{" "}
    </div>
  );
};
export default AddTraineePop;