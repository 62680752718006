import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../Config/Base";
export default class AddDesignationModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Business: [],
    };
  }

  CheckGST(company_Id) {
    fetch(`${BASE_URLs}AddOrganization/verifygst`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        company_Id: company_Id,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          document.getElementById("noo").click();
        },
        (error) => {}
      );
  }

  render() {
    var w = window.innerWidth;

    return (
      <>
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "2%" }}
          >
            <h5>You are verifying GST? </h5>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <Link
                  type="button"
                  onClick={() => this.CheckGST(this.props.company_Id)}
                  className="btn"
                >
                  Yes
                </Link>
                &nbsp;&nbsp;&nbsp;
                <button id="noo" className="btn" onClick={this.props.close}>
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
