import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
export default class TrainingCard extends Component {
  render() {
    return (
      <div>
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-body">
                  <div className="row">
                    <div className="col-lg-12" style={{ display: "flex" }}>
                      <div className="col-lg-2">
                        <Link
                          to="/LearningSchedule"
                          type="button"
                          style={{ height: "40px", width: "150px" }}
                          id="addRow"
                          className="btn "
                        >
                          Learning Schedule
                        </Link>
                      </div>
                      <div className="col-lg-2">
                        &nbsp;&nbsp;
                        <Link
                          to="/TrainingSchedule"
                          type="button"
                          style={{ height: "40px", width: "150px" }}
                          id="addRow"
                          className="btn "
                        >
                          Training Class
                        </Link>
                      </div>
                      <div className="col-lg-4"></div>
                      <div className="col-lg-4"></div>
                    </div>{" "}
                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}
