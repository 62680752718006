import React, { Component } from "react";
import "../../Css/Textbox.css";
import SideBar from "../../Common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URLs } from "../../Config/Base";
export default class userprofile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: [],
      Name: "",
      HName: "",
      gender: "",
      DOB: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      maritalstatus: "",
      MobileNo: "",
      Profile: "",
      email: "",
      location: "",
      twitter: "",
      skype: "",
      website: "",
      imagePreviewUrl: "",
      imgCoverPreviewUrl: "",
      Degree: "",
      Institution: "",
      University: "",
      Graduation_Year: "",
      Contact: "",
      Email: "",
      Relationship: "",
      comlogo: [],
      Bloodgroup: "",
      formErrors: {},
      Profile_Pic: [],
      EducationDetail: [],
      SkillDetails: [],
      FamilyDetails: [],
      EmergencyDetails: [],
      Bankdetail: [],
      FamModel: false,
      FamilyEditId: "",
      EmergencyModel: false,
      EmergencyEditId: "",
      SkillModel: false,
      SkillEditId: "",
      RelationshipDrop: [],
      EditFamValues: [],
      HouseTypeDrop: [],
      EducationDrop: [],
      EditECValues: [],
    };
    this.initialState = this.state;

    //this.EducationDetailList=this.EducationDetailList.bind(this);
  }

  today = new Date();
  maxdate = new Date(
    new Date().getFullYear() - 18,
    new Date().getMonth(),
    new Date().getDate()
  )
    .toJSON()
    .slice(0, 10)
    .replace(/-/g, "/");
  mindate = new Date(
    new Date().getFullYear() - 61,
    new Date().getMonth(),
    new Date().getDate()
  )
    .toJSON()
    .slice(0, 10)
    .replace(/-/g, "/");

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });

        //
      });
  }
  componentDidMount() {
    this.GetOrgLogo();
  }

  LogOut = (e) => {
    e.preventDefault();

    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    this.props.history.push("/Login_form");
  };

  BankValidation() {
    let formErrors = {};
    let formIsValid = true;
    var Bankname = document.getElementById("Bankname").value;
    var Branchname = document.getElementById("Branchname").value;
    var Branchaddress = document.getElementById("Branchaddress").value;
    var IFSC = document.getElementById("IFSC").value;
    var AcName = document.getElementById("AcName").value;
    var AcNo = document.getElementById("AcNo").value;
    var AcType = document.getElementById("AcType").value;
    var PhoneNo = document.getElementById("PhoneNo").value;

    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[6789]\d{9}$/;
    if (!Bankname) {
      formIsValid = false;
      formErrors["Bankname"] = "Bank Name  is required";
    }

    if (!Branchname) {
      formIsValid = false;
      formErrors["Branchname"] = "Branch Name is required";
    }

    if (!Branchaddress) {
      formIsValid = false;
      formErrors["Branchaddress"] = "Branch Address is required";
    }
    if (!IFSC) {
      formIsValid = false;
      formErrors["IFSC"] = "IFSC Code is required";
    }

    if (!AcName) {
      formIsValid = false;
      formErrors["AcName"] = "Account Holder Name is required";
    }
    if (!AcNo) {
      formIsValid = false;
      formErrors["AcNo"] = "Account Number  is required";
    }
    if (!AcType) {
      formIsValid = false;
      formErrors["AcType"] = "Account Type is required";
    }
    if (!PhoneNo) {
      formIsValid = false;
      formErrors["PhoneNo"] = "Phone Number is required";
    } else if (!mobPattern.test(PhoneNo)) {
      formIsValid = false;
      formErrors["PhoneNo"] = " Enter valid Phone Number.";
    }
    this.setState({ formErrors: formErrors });

    //document.getElementById("add-row").style.display = "none";
    return formIsValid;
  }

  submit = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);

    if (this.ProfileValidation()) {
      var options = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      };

      await fetch(`${BASE_URLs}Profile/Put`, options)
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (resp.status === "Error") {
            toast.error(resp, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          //resp.errors.MiddleName===null?document.getElementById('middlename').innerHTML="MiddleName":document.getElementById('middlename').innerHTML=resp.errors.MiddleName,document.getElementById('middlename').style.color="red"

          this.setState({ imagePreviewUrl: null });
          this.setState({ imageCoverPreviewUrl: null });
        });

      this.profilelist();
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };

  handleFileSelected(event) {
    event.preventDefault();
    this.photofilename = event.target.files[0].name;
    const formData = new FormData();
    formData.append(
      "myFile",
      event.target.files[0],
      event.target.files[0].name
    );

    fetch("", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.imagesrc = process.env.REACT_APP_PHOTOPATH + result;
        },
        (error) => {
          toast.error("Failed", {
            position: "top-center",
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
    // setTimeout(this.profilelist(), 500)
  }

  Handledegree() {
    var Degreevalue = document.getElementById("degreedrop").value;
    if (Degreevalue === "12th" || Degreevalue === "10th") {
      document.getElementById("univtd").style.display = "none";
    } else {
      document.getElementById("univtd").style.display = "revert";
    }
  }

  GetUserProfile() {
    let url = `${BASE_URLs}Profile/Userprofile`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Profile_Pic: data,
        });

        //
      });
  }

  handleImageChange(e) {
    e.preventDefault();
    this.setState({ imagePreviewUrl: URL.createObjectURL(e.target.files[0]) });
  }

  handleCoverImageChange(e) {
    e.preventDefault();
    this.setState({
      imageCoverPreviewUrl: URL.createObjectURL(e.target.files[0]),
    });
  }
  FamModel = (e, fn, ln, cn, em, relationship, emergencyContact) => {
    this.setState({ FamilyEditId: e });
    this.setState({
      EditFamValues: {
        id: e,
        FirstName: fn,
        LastName: ln,
        Contact: cn,
        Email: em,
        Relationship: relationship,
        emergencyContact: emergencyContact,
      },
    });
    this.setState((prevState) => ({
      FamModel: !prevState.FamModel,
    }));

    this.FamilyDetailList();
  };

  EmergencyModel = (e, fn, ln, cn, em, relationship) => {
    this.setState({ EmergencyEditId: e });
    this.setState({
      EditECValues: {
        id: e,
        FirstName: fn,
        LastName: ln,
        Contact: cn,
        Email: em,
        Relationship: relationship,
      },
    });

    this.setState((prevState) => ({
      EmergencyModel: !prevState.EmergencyModel,
    }));

    this.EmergencyDetailList();
  };

  SkillModel = (id, categ, Skillname, Skilldescrp, exp, trainer) => {
    // this.setState({SkillEditId: id});
    this.setState({
      EditSkillValues: {
        id: id,
        categ: categ,
        Skillname: Skillname,
        Skilldescrp: Skilldescrp,
        exp: exp,
        trainer: trainer,
      },
    });
    this.setState((prevState) => ({
      SkillModel: !prevState.SkillModel,
    }));

    this.SkillDetailList();
  };

  HouseTypeDropdownList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}profile/HouseTypeDropdown`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ HouseTypeDrop: data });
      });
  }
  UserEducationDropdownList() {
    const options = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}UserEducationDetails/GetQualificationtype`, options)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ EducationDrop: data });
      });
  }
  test() {
    document.getElementById("pendingtab").click();
  }
  render() {
    return (
      <div>
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body user-profile">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title">{/* <h1></h1> */}</div>
                </div>
                <div className="page-body">
                  <div className="card">
                    <form>
                      <div className="dt-responsive table-responsive">
                        <table id="add-row-table3" className="table nowrap">
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  id="selectall"
                                  onClick={() => {
                                    this.checkAll(this);
                                  }}
                                />{" "}
                              </th>
                              <th>Department Name</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  name="Id_delete"
                                  onChange={this.handlecheckbox}
                                  defaultValue=""
                                />
                              </td>
                              <td>''</td>
                              <td>
                                <img
                                  alt="edit"
                                  src="https://img.icons8.com/android/22/000000/pencil.png"
                                />{" "}
                                &nbsp;&nbsp;&nbsp;
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </form>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div id="styleSelector"></div>
          </div>
        </div>
      </div>
    );
  }
}
