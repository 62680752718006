import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Picker from "emoji-picker-react";
import Swal from "sweetalert2";
import { BASE_URLs } from "../Config/Base";
import video from "../Assets/images/videoplay.png";
import axios from "axios";
const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};
const thumbsContainer = {
  // display: "flex",
  // flexDirection: "row",
  // flexWrap: "wrap",
  height: 10,
  marginTop: 2,
};
const thumbss = {
  display: "inline-flex",
  borderRadius: 10,
  border: "6px dotted #eaeaea",
  marginBottom: 20,
  marginTop: 80,
  marginRight: 4,
  alignItems: "center",
  textAlign: "center",
  width: "100%",
  height: 200,
  padding: 4,
  boxSizing: "border-box",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function OneonOnePicturepop(props) {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);

  const [showEmoji, setShowEmoji] = useState(false);

  const storedValue = localStorage.getItem("fileee");
  const emailA = storedValue ? storedValue.replace(/"/g, "") : "";

  const [email, setEmail] = useState(emailA);

  const toggleEmoji = () => {
    setShowEmoji((prevShowEmoji) => !prevShowEmoji);
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
    setShowEmoji(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!message.toString().trim() && files.length === 0) {
      alert("Please select an image");
    } else {
      try {
        for (const file of files) {
          if (file.size >= 2048000) {
            Swal.fire({
              text: "Upload less than 2MB",
              icon: "warning",
            });
          } else {
            const formData = new FormData();
  
            formData.append("Message", message);
            formData.append("UserName", email);
            formData.append("Picture_Path", file);
  
            const options = {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
                Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
              },
            };
  
            await axios.post(`${BASE_URLs}contact/OneOnOne`, formData, options);
          }
        }
  
        setFiles([]);
        setMessage("");
        props.handleClose();
      } catch (error) {
        // Handle errors
        console.error("Error:", error);
      }
    }
  };

  const handleTextChange = (event) => {
    setMessage(event.target.value);
  };

  const handleRemoveImage = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
      'video/mp4': ['.mp4', '.MP4', '.gif', '.GIF', '.mov', '.mkv'],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file, index) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.name.includes('mp4') ? video : file.preview}
          style={img}
          alt={`Thumbnail-${index}`}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
      <span onClick={() => handleRemoveImage(index)}>x</span>
    </div>
  ));

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <div className="container">
      <form id="contactForm" autoComplete="off" encType="multipart/form-data">
        <span
          className="GTclose-icon"
          onClick={props.handleClose}
          style={{ marginTop: "20px" }}
        >
          x
        </span>
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} multiple accept="image/png, image/jpg, image/jpeg, image/gif" />
          <h4 style={thumbss}>
            Drag & drop some image here, or click to select images
          </h4>
        </div>
        <aside style={thumbsContainer}>
          <div id="Imageoneonone"
          //  style={{ height: "25vh", overflow: "scroll", backgroundColor:'gray'}}
          >
            {thumbs.length > 0 ? thumbs : <p>No images selected</p>}
          </div>
          <div >
            <div
              className="input-group write-msg"
              id="input-group write-msg"
              style={{
                border: "1px solid #bfbfbe",
                padding: "2px 2px",
                // marginTop: "220px",
              }}
            >
              <input
                type="text"
                className="form-control input-value"
                placeholder="Type a Message"
                value={message}
                onChange={handleTextChange}
                name="Message"
                style={{ border: "none" }}
              />
              <span
                className="fa fa-smile-o"
                aria-hidden="true"
                style={{ marginTop: "10px", fontSize: "18px" }}
                onClick={toggleEmoji}
              ></span>
              &nbsp;&nbsp;&nbsp;&nbsp;
              {showEmoji && (
                <div
                  style={{
                    position: "absolute",
                    top: "-410%",

                    transform: "translate(-50%, -50%)",
                    maxWidth: "90vw",
                    maxHeight: "90vh",
                  }}
                  id='PickerDiv'
                >
                  <Picker
                    onEmojiClick={handleEmojiClick}

                    height={350}
                    width={"100%"}

                  />
                </div>
              )}
              <span className="input-group-btn">
                <button
                  id="paper-btn"
                  className="btn btn-secondary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  <i className="icofont icofont-paper-plane"></i>
                </button>
              </span>
            </div>
          </div>
        </aside>

      </form>
    </div>
  );
}
export default OneonOnePicturepop;
