import { React, useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import PlusOne from '@material-ui/icons/Add';
import { add } from 'date-fns';
import { ToastContainer, toast } from 'react-toastify';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  ScheduleComponent,
  ViewDirective,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
  Resize,
  DragAndDrop,
} from '@syncfusion/ej2-react-schedule';
import ScheduleEditTrainerpop from './ScheduleEditTrainerpop';

import { BASE_URLs } from '../Config/Base';
const TrainingEditTraineesPop = ({
  closePopup,
  array,
  SelectedtrainerArray ,
  state,
  third,
  trainingID,
}) => {
  const [Trainers, setTrainers] = useState(array);
  const [Train, setTrain] = useState([]);
  const [Selectall, setSelectall] = useState(false);
  const [selectedOption, setselectedOption] = useState([]);
  const [scheduleObj, setscheduleObj] = useState([]);
  const [WholeDates, setWholeDates] = useState([]);
  const [ClassObj, setClassObj] = useState({});
  const [TrainerPop, setTrainerPop] = useState(false);
  const [TrainingStartDate, setTrainingStartDate] = useState('');
  const [selectedDepartment, setselectedDepartment] = useState('');
  const [selectedDescription, setselectedDescription] = useState('');
  const [selectedTrainingItem, setselectedTrainingItem] = useState('');
  const [selectedTrainingTopicId, setselectedTrainingTopicId] = useState('');
  const [StartDate, setStartDate] = useState(array[0].startDate.split('T')[0]);
  const [StartTime, setStartTime] = useState(array[0].startTime);
  const [EndDate, setEndDate] = useState(array[0].endDate.split('T')[0]);
  const [EndTime, setEndTime] = useState(array[0].endTime);
  const [DisplayStartDate, setDisplayStartDate] = useState(
    StartDate + ' ' + StartTime
  );
  const [DisplayEndDate, setDisplayEndDate] = useState(EndDate + ' ' + EndTime);

  const [Startshowtimedropdown, setStartshowtimedropdown] = useState(false);

  const [Endshowtimedropdown, setEndshowtimedropdown] = useState(false);
  const [Droptime, setDroptime] = useState([
    { time24: '00:00', time12: '12:00 AM' },
    { time24: '00:30', time12: '12:30 AM' },
    { time24: '01:00', time12: '01:00 AM' },
    { time24: '01:30', time12: '01:30 AM' },
    { time24: '02:00', time12: '02:00 AM' },
    { time24: '02:30', time12: '02:30 AM' },
    { time24: '03:00', time12: '03:00 AM' },
    { time24: '03:30', time12: '03:30 AM' },
    { time24: '04:00', time12: '04:00 AM' },
    { time24: '04:30', time12: '04:30 AM' },
    { time24: '05:00', time12: '05:00 AM' },
    { time24: '05:30', time12: '05:30 AM' },
    { time24: '06:00', time12: '06:00 AM' },
    { time24: '06:30', time12: '06:30 AM' },
    { time24: '07:00', time12: '07:00 AM' },
    { time24: '07:30', time12: '07:30 AM' },
    { time24: '08:00', time12: '08:00 AM' },
    { time24: '08:30', time12: '08:30 AM' },
    { time24: '09:00', time12: '09:00 AM' },
    { time24: '09:30', time12: '09:30 AM' },
    { time24: '10:00', time12: '10:00 AM' },
    { time24: '10:30', time12: '10:30 AM' },
    { time24: '11:00', time12: '11:00 AM' },
    { time24: '11:30', time12: '11:30 AM' },
    { time24: '12:00', time12: '12:00 PM' },
    { time24: '12:30', time12: '12:30 PM' },
    { time24: '13:00', time12: '01:00 PM' },
    { time24: '13:30', time12: '01:30 PM' },
    { time24: '14:00', time12: '02:00 PM' },
    { time24: '14:30', time12: '02:30 PM' },
    { time24: '15:00', time12: '03:00 PM' },
    { time24: '15:30', time12: '03:30 PM' },
    { time24: '16:00', time12: '04:00 PM' },
    { time24: '16:30', time12: '04:30 PM' },
    { time24: '17:00', time12: '05:00 PM' },
    { time24: '17:30', time12: '05:30 PM' },
    { time24: '18:00', time12: '06:00 PM' },
    { time24: '18:30', time12: '06:30 PM' },
    { time24: '19:00', time12: '07:00 PM' },
    { time24: '19:30', time12: '07:30 PM' },
    { time24: '20:00', time12: '08:00 PM' },
    { time24: '20:30', time12: '08:30 PM' },
    { time24: '21:00', time12: '09:00 PM' },
    { time24: '21:30', time12: '09:30 PM' },
    { time24: '22:00', time12: '10:00 PM' },
    { time24: '22:30', time12: '10:30 PM' },
    { time24: '23:00', time12: '11:00 PM' },
    { time24: '23:30', time12: '11:30 PM' },
  ]);

  const [InitialDuration, setInitialDuration] = useState(0);

  function TimeMod(time) {
    console.log('timetimetimetimetime', time);
    const Hour = time.split(':')[0];
    const minutes = time.split(':')[1];
    if (Hour > 12) {
      time = Hour - 12;
      time = time < 10 ? '0' + time : time;

      return time + ':' + minutes + ' PM';
    } else if (Hour == 0) {
      return 12 + ':' + minutes + ' AM';
    } else if (Hour == 12) {
      return 12 + ':' + minutes + ' PM';
    } else {
      return time + ' AM';
    }
  }

  // useEffect(() => {}, [EndDate, StartDate]);

  useEffect(() => {
    setStartDate(array[0].startDate.split('T')[0]);
    setEndDate(array[0].endDate.split('T')[0]);

    setselectedDepartment(array[0].department);
    setselectedDescription(array[0].trainingDescription);
    setselectedTrainingItem(array[0].trainingitem);
    setselectedTrainingTopicId();

    console.log('StartTime', StartTime, '-', 'EndTime', EndTime);

    function initialduration(time) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours + '.' + minutes / 6;
    }

    setInitialDuration(
      initialduration(DurationCalc(array[0].startTime, array[0].endTime))
    );
    console.log(
      'DurationCalc(array[0].startTime,array[0].endTime)',
      initialduration(DurationCalc(array[0].startTime, array[0].endTime))
    );
  }, []);

  console.log('LearnId LearnId LearnId', trainingID);
  const toggle_thirdPopupVisible = () => {
    setTrainerPop(!TrainerPop);
  };

  const convert = (str) => {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  };

  const displayTimeFromDate = (dateString) => {
    const date = new Date(dateString);
    // Extracting hours, minutes, and seconds from the date
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // Converting hours to 12-hour format (AM/PM)
    // let formattedHours = hours % 12;
    // formattedHours = formattedHours === 0 ? 12 : formattedHours;
    // const period = hours >= 12 ? 'PM' : 'AM';

    const formattedTime = `${hours}:${minutes}`;

    // Displaying the time in HH:MM:SS AM/PM format
    // const formattedTime = `${formattedHours}:${minutes}:${seconds} ${period}`;
    // const formattedTime = `${formattedHours}:${minutes} ${period}`;
    return formattedTime;
  };

  const DurationCalc = (StartTime, EndTime) => {
    const [hours, minutes] = StartTime.split(':').map(Number);
    const [hours1, minutes1] = EndTime.split(':').map(Number);

    let Time = hours * 60 + minutes;
    let Time1 = hours1 * 60 + minutes1;
    const minutesDifference = Time1 - Time;
    let hoursDifference = Math.floor(minutesDifference / 60);
    if (hoursDifference < 0) {
      hoursDifference = hoursDifference + 24;
    }

    let remainingMinutes = minutesDifference % 60;

    if (hoursDifference >= 1 && hoursDifference <= 9) {
      hoursDifference = '0' + hoursDifference;
    }

    if (remainingMinutes === 0) {
      remainingMinutes = '00';
    } else if (remainingMinutes >= 1 && remainingMinutes <= 9) {
      remainingMinutes = '0' + remainingMinutes;
    }

    return hoursDifference + ':' + remainingMinutes;
  };

  const toggleSaveTrainerPopup = async (state, e) => {
    e.preventDefault();

    const data = new FormData();

    let selectedState = state.filter((e) => e.check === true);

    state
      .filter((e) => e.date === StartDate.split('T')[0])[0]
      .filteredAvailableArray.filter((ee) => ee.check === true)
      .forEach((datas) => {
        data.append('TrainerId', datas.trainer_UserId);
        data.append('TrainerName', datas.trainerName);
      });

    data.append('LearnId', trainingID);
    data.append('StartClassDate', StartDate);
    data.append('EndClassDate', EndDate);
    data.append('ScheduleTime', StartTime);
    data.append('ScheduleEndTime', EndTime);
    data.append(
      'ScheduleDuration',
      calculateDuration(
        StartDate + 'T' + StartTime + ':00',
        EndDate + 'T' + EndTime + ':00'
      )
    );

    for (var pair of data.entries()) {
      console.log(pair[0] + ',-- ' + pair[1]);
    }
    // data.append("RecurrenceRule", date.RecurrenceRule);
    //  return true
    const url = `${BASE_URLs}Learning/EditLearning`; // Replace with your API endpoint

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      });

      if (response.ok) {
        const result = await response.json();

        setTimeout(closePopup(e), 3000);
        toast.success('Training Updated Successfully ', {
          position: 'top-center',
          autoClose: 1700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // Output the fetched data
        // Perform any additional operations with the fetched data
      } else {
        // console.log(`Data error  `, result); // Output the fetched data
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }

    // keys.forEach((obj) => {
  };

  useEffect(() => {}, []);
  const show = (e) => {
    e.preventDefault();

    console.log(
      'scheduleObj eventsProcessed',
      scheduleObj.eventsProcessed.filter((e) => e.Subject === 'Add title')
    );
    console.log(
      'scheduleObj eventsData',
      scheduleObj.eventsData.filter((e) => e.Subject === 'Add title')
    );
    console.log(
      'scheduleObj',
      GetDates(
        scheduleObj.eventsData.filter((e) => e.Subject === 'Add title'),

        scheduleObj.eventsProcessed.filter((e) => e.Subject === 'Add title')
      )
    );

    third();
  };

  const GetDates = (EventDatas, eventsProcessed) => {
    let obj = {};
    let AllDates = [];

    let ffff = EventDatas.map((eventsData) => {
      if (eventsData.RecurrenceRule != null) {
        let data = eventsProcessed.filter(
          (eventsProcessed) => eventsProcessed.Id === eventsData.Id
        );

        if (data.length !== 0) {
          console.log('date [0] ', data[0]);
          let datediff =
            new Date(data[0].EndTime) - new Date(data[0].StartTime);
          let Dates = getDatesByRecurrenceRule(
            eventsData.RecurrenceRule,
            eventsData.StartTime
          );

          const dateWithTime = new Date(data[0].StartTime);

          // Display the separate components of time
          const hours = dateWithTime.getHours().toString().padStart(2, '0');
          const minutes = dateWithTime.getMinutes().toString().padStart(2, '0');
          const seconds = dateWithTime.getSeconds().toString().padStart(2, '0');

          // Formatted time string in HH:mm:ss format
          const formattedTime = `${hours}:${minutes}:${seconds}`;

          // Convert each date to the desired format
          const formattedDates = Dates.map(formatDate);
          let newArr = [];
          formattedDates.forEach((forDate) => {
            const date =
              forDate.split('T')[0] + `T${hours}:${minutes}:${seconds}`;
            newArr.push(date);
          });

          AllDates.push(...newArr);

          let events = newArr.map((date) => {
            let Added_Date = new Date(date).getTime() + datediff;

            return {
              Subject: eventsData.Subject,
              StartTime: new Date(date).toString(),
              EndTime: new Date(Added_Date).toString(),
              RecurrenceRule: eventsData.RecurrenceRule,
            };
          });

          obj[eventsData.Id] = events;
        }
      } else {
        const originalDate = new Date(eventsData.StartTime);
        const reducedDate = new Date(
          originalDate.getTime() + 5.5 * 60 * 60 * 1000
        ); // Subtracting 5 hours in milliseconds
        AllDates.push(reducedDate.toISOString());

        console.log(reducedDate.toISOString());

        obj[eventsData.Id] = [
          {
            Subject: eventsData.Subject,
            StartTime: eventsData.StartTime,
            EndTime: eventsData.EndTime,
            RecurrenceRule: null,
          },
        ];
      }
    });

    state(AllDates);
    return {
      dates: AllDates,
      obct: obj,
    };
  };

  const getDatesByRecurrenceRule = (recurrenceRule, startdate) => {
    const ruleParts = recurrenceRule.split(';');
    const freq = ruleParts
      .find((part) => part.startsWith('FREQ='))
      .split('=')[1];
    const interval = parseInt(
      ruleParts.find((part) => part.startsWith('INTERVAL=')).split('=')[1]
    );
    const byDay = ruleParts.find((part) => part.startsWith('BYDAY='));
    const byMonthDay = ruleParts.find((part) => part.startsWith('BYMONTHDAY='));
    const byMonth = ruleParts.find((part) => part.startsWith('BYMONTH='));
    const byYearDay = ruleParts.find((part) => part.startsWith('BYYEARDAY='));
    const count =
      ruleParts.find((part) => part.startsWith('COUNT=')) !== undefined
        ? parseInt(
            ruleParts.find((part) => part.startsWith('COUNT=')).split('=')[1]
          )
        : parseInt('10');

    // Parsing values if present in the rule
    const parseValue = (param) =>
      param ? parseInt(param.split('=')[1]) : null;
    const parsedByDay = byDay ? byDay.split('=')[1].split(',') : null;
    const parsedByMonthDay = parseValue(byMonthDay);
    const parsedByMonth = parseValue(byMonth);
    const parsedByYearDay = parseValue(byYearDay);

    const weekdays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

    const today = new Date(startdate);
    let dates = [];
    let currentDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    let d = startdate;

    const checkByDay = (date) => {
      if (!parsedByDay) return true;

      const dayIndex = date.getDay();
      return parsedByDay.includes(weekdays[dayIndex]);
    };

    while (dates.length < count) {
      const currentMonth = currentDate.getMonth() + 1; // Month is 0-indexed

      if (
        (!parsedByMonth || currentMonth === parsedByMonth) &&
        (!parsedByMonthDay || currentDate.getDate() === parsedByMonthDay) &&
        (!parsedByYearDay || currentDate.getDayOfYear() === parsedByYearDay) &&
        checkByDay(currentDate)
      ) {
        dates.push(new Date(currentDate));
      }

      currentDate.setDate(currentDate.getDate() + interval);
    }

    dates = dates.map((e) => {
      return new Date(e).setHours(today.getHours(), today.getMinutes(), 0);
    });

    // startdate

    return dates;
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().slice(0, 19);
  };
  const add = (e) => {
    e.preventDefault();
    state(Trainers.filter((e) => e.check));

    third();
  };

  const handleChange = (arr, i, event) => {
    let change = Trainers.map((e, index) => {
      if (i === index) return { ...e, ...{ check: event.target.checked } };
      return { ...e };
    });
    state(change);
    change.length === change.filter((e) => e.check === true).length
      ? setSelectall(true)
      : setSelectall(false);
    console.log('change=====', change);

    setTrainers(change);
  };

  const handleSelectAll = (event) => {
    setSelectall(!Selectall);
    let change = Trainers.map((e, index) => {
      return { ...e, ...{ check: event.target.checked } };
    });
    change.length === change.filter((e) => e.check === true).length
      ? setSelectall(true)
      : setSelectall(false);
    setTrainers(change);
  };

  const toggleAddTrainerPopup = (e) => {
    e.preventDefault();
    console.log('ddd', StartDate < EndDate);
    console.log(
      'ddd',
      StartDate + 'T' + StartTime + ':00',
      EndDate + 'T' + EndTime + ':00'
    );
    const date1 = new Date(StartDate + 'T' + StartTime + ':00');
    const date2 = new Date(EndDate + 'T' + EndTime + ':00');

    // Calculate the time difference in milliseconds
    const timeDifference = date2 - date1;

    // Convert milliseconds to hours
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    console.log('hoursDifference', hoursDifference);
    if (StartDate + 'T' + StartTime + ':00' > EndDate + 'T' + EndTime + ':00') {
      toast.error('The selected end date occurs before the start date.', {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else if (
      StartDate + 'T' + StartTime + ':00' ==
      EndDate + 'T' + EndTime + ':00'
    ) {
      toast.error("The selected start date and end date can't be same.", {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else if (hoursDifference > 8) {
      toast.error(
        "The duration between start date and end date can't be exceed 8 Hours.",
        {
          position: 'top-center',
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else if (hoursDifference != InitialDuration) {
      toast.error(
        'Training schedule duration should be ' +
          DurationCalc(array[0].startTime, array[0].endTime) +
          ' Hours',
        {
          position: 'top-center',
          autoClose: 3200,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
    console.log(DurationCalc(StartTime, EndTime));
    setTrainerPop(true);
    setWholeDates(StartDate);

    return;
    // if (scheduleObj.eventsData.length === 0) {
    //   toast.error("Please Select atlest one date", {
    //     position: "top-center",
    //     autoClose: 3200,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    //   return;
    // }

    let final = scheduleObj.eventsData.map((e) => {
      if (e.Subject) {
        e.Subject = array[0].trainingitem;
      }

      return e;
    });

    let obj = {};
    let AllDates = [];

    let ffff = scheduleObj.eventsData.map((eventsData) => {
      if (eventsData.RecurrenceRule != null) {
        let data = scheduleObj.eventsProcessed.filter(
          (eventsProcessed) => eventsProcessed.Id === eventsData.Id
        );

        if (data.length !== 0) {
          console.log('date [0] ', data[0]);
          let datediff =
            new Date(data[0].EndTime) - new Date(data[0].StartTime);
          let Dates = getDatesByRecurrenceRule(
            eventsData.RecurrenceRule,
            eventsData.StartTime
          );

          const dateWithTime = new Date(data[0].StartTime);

          // Display the separate components of time
          const hours = dateWithTime.getHours().toString().padStart(2, '0');
          const minutes = dateWithTime.getMinutes().toString().padStart(2, '0');
          const seconds = dateWithTime.getSeconds().toString().padStart(2, '0');

          // Formatted time string in HH:mm:ss format
          const formattedTime = `${hours}:${minutes}:${seconds}`;

          // Convert each date to the desired format
          const formattedDates = Dates.map(this.formatDate);
          let newArr = [];
          formattedDates.forEach((forDate) => {
            const date =
              forDate.split('T')[0] + `T${hours}:${minutes}:${seconds}`;
            newArr.push(date);
          });

          AllDates.push(...newArr);

          let events = newArr.map((date) => {
            let Added_Date = new Date(date).getTime() + datediff;

            return {
              Subject: eventsData.Subject,
              StartTime: new Date(date).toString(),
              EndTime: new Date(Added_Date).toString(),
              RecurrenceRule: eventsData.RecurrenceRule,
            };
          });

          obj[eventsData.Id] = events;
        }
      } else {
        const originalDate = new Date(eventsData.StartTime);
        const reducedDate = new Date(
          originalDate.getTime() + 5.5 * 60 * 60 * 1000
        ); // Subtracting 5 hours in milliseconds
        AllDates.push(reducedDate.toISOString());

        console.log(reducedDate.toISOString());

        obj[eventsData.Id] = [
          {
            Subject: eventsData.Subject,
            StartTime: eventsData.StartTime,
            EndTime: eventsData.EndTime,
            RecurrenceRule: null,
          },
        ];
      }
    });

    setWholeDates(AllDates);
    setClassObj(obj);
    //  this.setState({ ClassObj: obj });

    // this.setState((prevState) => ({
    //   secondPopupVisible: !prevState.secondPopupVisible,
    // }));
  };
  const cal = (e, id) => {
    console.log(id);
    e.preventDefault();
    let cal = document.getElementById(id);
    console.log(cal);

    document.getElementById(id).click();
    // cal.click()
    // cal.style.display = "block";
  };
  const setDates = (e) => {
    e.preventDefault();

    console.log('-------------------------', e.target.value);

    setStartDate(e.target.value);
  };

  const setEndDates = (e) => {
    e.preventDefault();
    setEndDate(e.target.value);
    // console.log((e.target.value).split("T")[1]);

    // let d = parseInt(d.split(":")[0])  + 1
    //  let f = String(e).length==1 ? 0+String(e)+":"+d.split(":")[1]:d
  };

  function addMinutesToTime(timeString) {
    const [hoursStr, minutesStr] = timeString.split(':');

    let hours = parseInt(hoursStr, 10);
    let minutes = parseInt(minutesStr, 10);

    if (isNaN(hours) || isNaN(minutes)) {
      return 'Invalid time format';
    }

    // Convert time to total minutes
    let totalMinutes = hours * 60 + minutes;

    // Add the specified minutes
    totalMinutes += 30;

    // Ensure the total remains within a day (24 * 60 minutes)
    totalMinutes = (totalMinutes + 24 * 60) % (24 * 60);

    // Calculate the new hours and minutes
    hours = Math.floor(totalMinutes / 60);
    minutes = totalMinutes % 60;

    // Format the time with leading zeroes if needed
    const newHours = hours < 10 ? `0${hours}` : hours.toString();
    const newMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();

    // Return the updated time
    return `${newHours}:${newMinutes}`;
  }
  function calculateDuration(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in milliseconds
    const durationInMs = end - start;

    // Convert milliseconds to hours, minutes, and seconds
    const hours = Math.floor(durationInMs / (1000 * 60 * 60));
    let minutes = Math.floor((durationInMs % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((durationInMs % (1000 * 60)) / 1000);

    minutes = minutes == 0 ? '00' : minutes;

    return hours + ':' + minutes;
  }

  useEffect(() => {
    const closeEndtimedropdown = (event) => {
      if (!event.target.matches('.Endtimedropbtn')) {
        setEndshowtimedropdown(false);
      }
    };
    const closeStarttimedropdown = (event) => {
      if (!event.target.matches('.Starttimedropbtn')) {
        setStartshowtimedropdown(false);
      }
    };

    window.addEventListener('click', closeEndtimedropdown);
    window.addEventListener('click', closeStarttimedropdown);

    return () => {
      window.removeEventListener('click', closeEndtimedropdown);
      window.removeEventListener('click', closeStarttimedropdown);
    };
  }, []);

  const toggleEndtimedropdown = () => {
    setEndshowtimedropdown(!Endshowtimedropdown);
  };
  const toggleStarttimedropdown = () => {
    setStartshowtimedropdown(!Startshowtimedropdown);
  };

  const SetDates = (e, state, value) => {
    // time24
    state(value.time24);
    console.log(value.time24);
  };

  return (
    <div className="pop">
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className="popinnernormal"
        style={{
          borderRadius: '0px',
          width: '36%',
          height: '25vh',
          padding: '6px',
        }}
      >
        <form autoComplete="off" encType="multipart/form-data">
          {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}} onClick={this.close}  ><CloseIcon/></button> */}
          <div className="row">
            <div className="col-lg-10"></div>
            <div className="col-lg-1 "></div>

            <div className="col-lg-1 ">
              <button
                style={{
                  backgroundColor: 'rgba(160, 163, 163, 0.02)',
                  color: 'rgb(121, 112, 112)',
                  border: '1px solid rgba(163, 165, 165, 0.02)',
                  float: 'right',
                }}
                onClick={(e) => third(e)}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
          <hr style={{ margin: '0' }} />
          <div className="row" tyle={{ padding: '8px' }}>
            <div className="col-lg-6">
              <span style={{ float: 'left' }}>Start</span>

              <div class="input-container">
                <input
                  type="text"
                  value={StartDate + ' ' + TimeMod(StartTime)}
                  // style={{ pointerEvents: "none" }}
                />
                {/* <span class="icon1 e-input-group-icon e-date-icon e-icons"> */}
                <input
                  className="icon1 effect-16"
                  id="Startcal"
                  style={{
                    border: '1px solid transparent',
                    padding: '0px 2px 12px 0px',
                    width: '9%',
                  }}
                  type="date"
                  value={StartDate}
                  onChange={(e) => setDates(e)}
                />

                {/* </span> */}
                <span class="icon2 ">
                  <AccessTimeIcon
                    className="Starttimedropbtn"
                    onClick={toggleStarttimedropdown}
                    onBlur={toggleStarttimedropdown}
                    style={{ fontSize: '20px' }}
                  />{' '}
                </span>
                {Startshowtimedropdown ? (
                  <div id="mytimedropdown" className="timedropdown-content">
                    {Droptime.map((e) => (
                      <a
                        style={{
                          color:
                            TimeMod(StartTime) == e.time12 ? 'red' : 'black',
                        }}
                        onClick={(event) => SetDates(event, setStartTime, e)}
                      >
                        {e.time12}
                      </a>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6">
              <span style={{ float: 'left' }}>End</span>

              <div class="input-container">
                <input
                  type="text"
                  value={EndDate + ' ' + TimeMod(EndTime)}
                  style={{ pointerEvents: 'none' }}
                />
                {/* <span class="icon1 e-input-group-icon e-date-icon e-icons"></span> */}

                <input
                  className="icon1 effect-16"
                  style={{
                    border: '1px solid transparent',
                    padding: '0px 2px 12px 0px',
                    width: '9%',
                  }}
                  type="date"
                  value={EndDate}
                  onChange={(e) => setEndDates(e)}
                />

                <span class="icon2 ">
                  <AccessTimeIcon
                    className="Endtimedropbtn"
                    onClick={toggleEndtimedropdown}
                    onBlur={toggleEndtimedropdown}
                    style={{ fontSize: '20px' }}
                  />
                </span>

                {Endshowtimedropdown ? (
                  <div id="mytimedropdown" className="timedropdown-content">
                    {Droptime.map((e) => (
                      <a
                        style={{
                          color: TimeMod(EndTime) == e.time12 ? 'red' : 'black',
                        }}
                        onClick={(event) => SetDates(event, setEndTime, e)}
                      >
                        {e.time12}
                      </a>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12" style={{ textAlign: 'center' }}></div>
          </div>
        </form>
        <br />
        <button
          // onMouseEnter ={(e) => this.show(e)}
          onClick={(e) => toggleAddTrainerPopup(e)}
          className="but"
          style={{ width: '55px', height: '25px' }}
        >
          {' '}
          Save
        </button>
      </div>
      {TrainerPop === true && (
        <ScheduleEditTrainerpop
          selectedTrainingTopicId={array[0].topicId}
          state={(state) => setselectedOption(state)}
          third={toggle_thirdPopupVisible}
          closeThePopup={(state, e) => toggleSaveTrainerPopup(state, e)}
          closeThePopupNoSave={() => setTrainerPop(!TrainerPop)}
          TrainingStartDate={StartDate}
          WholeDates={[WholeDates]}
          selectedDate={StartDate + 'T' + StartTime + ':00.000Z'}
          selectedEndDate={EndDate + 'T' + EndTime + ':00.000Z'}
          array={array}
          SelectedtrainerArray={SelectedtrainerArray}
          FinalSubmit={(e) => {
            //this.handleSubmit(e);
          }}
        />
      )}
    </div>
  );
};

export default TrainingEditTraineesPop;
