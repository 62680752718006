import PlusOne from '@material-ui/icons/Add';
import Info from '@material-ui/icons/Edit';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SideBar from '../Common/Sidebar';
import '../Css/Addskills.css';
import { BASE_URLs } from '../Config/Base';
import TrainingEditTraineesPop from '../Common/TrainingEditTraineesPop'
 import TrainingEditTrainerPop from './TrainingEditTrainerPop';

export default class EditSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Departmentdrop: [],
      seperateDept: [],
      trainer: [],
      trainerDrop: [],
      memberDrop: [],
      department: [],
      selectedDepartment: '',
      Learning: [],
      LearnerList: [],
      selectedOption: [],
      selectedLabel: [],
      selectedLabels: [],
      Selectedvalue: [],
      SelectedTrainer: [],
      SelectedTrainee: [],
      TraineePopupVisible: false,
      TrainerPopupVisible: false,
      selectedTrainingTopicId: '',
      LearnId: '',
      selectedCheckboxValues: [],
      selectedCheckboxValues2: [],
      selectedCheckboxValues3: [],
      Trainers: [],
      Train: [],
      Trainees: [],
      Trainee: [],
      SavedTrainees: [],
      SavedTrainee: [],
      SelectedTraineeEdit: [],
      SelectedDatesEdit: [],
      ScheduleDates: [],
      ScheduleEndDates: '',
      isCheckboxChecked: false,
      isButtonVisible: false,
      calendarpop: false,
      ScheduleTime: '',
      ScheduleEndTime: '',
      // startDate: this.props.location.state.startDate.slice(0, 10),
      // scheduleTime: this.props.location.state.scheduleTime,
    };
    this.addItem = this.addItem.bind(this);
    this.handleChangesss = this.handleChangesss.bind(this);
  }

  handleDateTimeChange = (e) => {
    const value = e.target.value;

    const values = value.split('T');

    this.setState({
      scheduleTime: values[1],
    });
  };

  ClearInputs = () => {
    this.setState({ isButtonVisible: false });

    document
      .getElementById('delete')
      .setAttribute('style', 'visibility: hidden;');
    document
      .getElementById('deletecount')
      .setAttribute('style', 'visibility: hidden;');
  };

  EditDeleteTrainer = (array, id) => {
    let changes = this.state.Trainers.map((e, index) => {
      if (e.trainer_UserId === id) return { ...e, ...{ check: false } };
      return { ...e };
    });
    this.setState({ selectedOption: changes.filter((e) => e.check === true) });
    this.setState({ Trainers: changes });
  };

  EditDeleteStartDate = (array, id) => {
    let changes = this.state.SelectedTraineeEdit.map((e, index) => {
      if (e[0] === id) return { ...e, ...{ check: false } };
      return { ...e };
    });
    this.setState({
      SelectedTraineeEdit: changes.filter((e) => e.check === true),
    });
    this.setState({ SelectedTraineeEdit: changes });
  };

  checkAll(e) {
    var checkboxes = document.getElementsByName('deletcateg');
    var selectedValues = [];

    var sa = document.getElementById('selectall');
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        selectedValues.push(checkboxes[i].value);
        document
          .getElementById('delete')
          .setAttribute('style', 'visibility:visible ;');
        document
          .getElementById('deletecount')
          .setAttribute(
            'style',
            'visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);'
          );
        var msg = document.getElementById('deletecount');
        msg.innerHTML = '<span> ' + count + ' Records are selected </span> ';
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById('delete')
          .setAttribute('style', 'visibility: hidden;');
        document
          .getElementById('deletecount')
          .setAttribute('style', 'visibility: hidden;');
      }
    }
    this.setState({
      selectedCheckboxValues: selectedValues,
    });
  }

  // handlecheckbox() {
  //   let checkboxes = document.querySelectorAll('input[id="selectall"]:checked');
  //   let Total = document.querySelectorAll('input[type="checkbox"]');
  //   // selectalldiv
  //   if (checkboxes.length >= 2) {
  //     this.setState({ isButtonVisible: true });

  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:visible ;");
  //   } else {
  //     document
  //       .getElementById("selectalldiv")
  //       .setAttribute("style", "visibility:hidden ;");
  //   }
  //   if (checkboxes.length >= 1) {
  //     this.setState({ isButtonVisible: true });

  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility:visible ;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute(
  //         "style",
  //         "visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);"
  //       );
  //     var msg = document.getElementById("deletecount");
  //     let count = checkboxes.length - 1;
  //     if (document.getElementById("selectall").checked) {
  //       msg.innerHTML = "<span> " + count + " Records are selected </span> ";
  //     } else {
  //       msg.innerHTML =
  //         checkboxes.length === 1
  //           ? "<span> " + checkboxes.length + " Record is selected </span> "
  //           : "<span> " + checkboxes.length + " Records are selected </span> ";
  //     }
  //   } else {
  //     document
  //       .getElementById("delete")
  //       .setAttribute("style", "visibility: hidden;");
  //     document
  //       .getElementById("deletecount")
  //       .setAttribute("style", "visibility: hidden;");
  //   }
  //   if (document.getElementById("selectall").checked === false) {
  //     if (checkboxes.length === Total.length - 1) {
  //       //
  //       document.getElementById("selectall").checked = true;
  //     }
  //   } else {
  //     if (document.getElementById("selectall").checked) {
  //       if (checkboxes.length - 2 !== Total.length) {
  //         document.getElementById("selectall").checked = false;
  //       }
  //     }
  //   }
  // }

  checkChange = (event) => {
    // this.handlecheckbox();

    this.setState({ isButtonVisible: event.target.checked });
    const { name, checked, value } = event.target;

    this.setState((prevState) => {
      const selectedCheckboxValues = checked
        ? [...prevState.selectedCheckboxValues, value]
        : prevState.selectedCheckboxValues.filter((val) => val !== value);

      return { selectedCheckboxValues };
    });
  };

  handleTrainersCheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[id="trainerscheckid"]:checked'
    );
    checkboxes.forEach((checkbox) => {});
    let Total = document.querySelectorAll('input[type="checkbox"]');
    // selectalldiv
    if (checkboxes.length >= 2) {
      this.setState({ isButtonVisible: true });

      document
        .getElementById('selectalldivs')
        .setAttribute('style', 'visibility:visible ;');
    } else {
      document
        .getElementById('selectalldivs')
        .setAttribute('style', 'visibility:hidden ;');
    }
    if (checkboxes.length >= 1) {
      this.setState({ isButtonVisible: true });

      document
        .getElementById('deletes')
        .setAttribute('style', 'visibility:visible ;');
      document
        .getElementById('deletecounts')
        .setAttribute(
          'style',
          'visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);'
        );
      var msg = document.getElementById('deletecounts');
      let count = checkboxes.length - 1;
      if (document.getElementById('checkAllTrainers').checked) {
        msg.innerHTML = '<span> ' + count + ' Records are selected </span> ';
      } else {
        this.setState({ isButtonVisible: true });

        msg.innerHTML =
          checkboxes.length === 1
            ? '<span> ' + checkboxes.length + ' Record is selected </span> '
            : '<span> ' + checkboxes.length + ' Records are selected </span> ';
      }
    } else {
      document
        .getElementById('deletes')
        .setAttribute('style', 'visibility: hidden;');
      document
        .getElementById('deletecounts')
        .setAttribute('style', 'visibility: hidden;');
    }
    if (document.getElementById('checkAllTrainers').checked === false) {
      if (checkboxes.length === Total.length - 1) {
        //
        document.getElementById('checkAllTrainers').checked = true;
      }
    } else {
      if (document.getElementById('checkAllTrainers').checked) {
        if (checkboxes.length - 2 !== Total.length) {
          document.getElementById('checkAllTrainers').checked = false;
        }
      }
    }
  }

  checkAllTrainers() {
    var checkboxes = document.getElementsByClassName('deletcategs');
    var selectedValues = [];
    var selectNames = [];

    var sa = document.getElementById('checkAllTrainers');
    let count = checkboxes.length;
    if (sa.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        selectedValues.push(checkboxes[i].value.split(','));
        selectNames.push(checkboxes[i].name);
        document
          .getElementById('deletes')
          .setAttribute('style', 'visibility:visible ;');
        document
          .getElementById('deletecounts')
          .setAttribute(
            'style',
            'visibility: visible;background-color: #007bff;background: linear-gradient(135deg, #ce9ffc00, #a6f2ddf7);'
          );
        var msg = document.getElementById('deletecounts');
        msg.innerHTML = '<span> ' + count + ' Records are selected </span> ';
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById('deletes')
          .setAttribute('style', 'visibility: hidden;');
        document
          .getElementById('deletecounts')
          .setAttribute('style', 'visibility: hidden;');
      }
    }

    this.setState({
      selectedCheckboxValues3: selectedValues,
    });
    this.setState({
      selectedCheckboxValues2: selectNames,
    });
  }

  checkChangeTrainees = (event) => {
    this.handleTrainersCheckbox();

    const { name, checked, value } = event.target;
    // value2.indexOf(value) === -1
    //   ? value2.push(value)
    //   : value2.splice(value2.indexOf(value), 1);
    // value3.indexOf(name) === -1
    //   ? value3.push(name)
    //   : value3.splice(value3.indexOf(name), 1);
    this.setState((prevState) => {
      const selectedCheckboxValues2 = checked
        ? [...prevState.selectedCheckboxValues2, name]
        : prevState.selectedCheckboxValues2.filter((value) => value !== name);

      return { selectedCheckboxValues2 };
    });

    this.setState((prevState) => {
      const selectedCheckboxValues3 = checked
        ? [...prevState.selectedCheckboxValues3, value.split(',')]
        : prevState.selectedCheckboxValues2.filter((value) => value !== value);

      return { selectedCheckboxValues3 };
    });
  };

  openAddTraineePopup = (e) => {
    e.preventDefault();

    let deptarr = this.state.Trainees.map((e) => e.department[0]);
    const { SavedTrainees } = this.state.SavedTrainees;

    let f = this.state.SavedTrainees.filter((e) => {
      if (!deptarr.includes(e[0])) {
        return e;
      }
    });
    this.setState({ SavedTrainees: f });
    this.setState({ SavedTrainee: f });

    this.setState({ TraineePopupVisible: !this.state.TraineePopupVisible });
  };

  openAddTrainerPopup = (e) => {
    e.preventDefault();
    this.setState({ TrainerPopupVisible: !this.state.TrainerPopupVisible });
  };

  opencalendarPopup = (e) => {
    e.preventDefault();
    this.setState({ calendarpop: !this.state.calendarpop });
  };

  toggleAddTrainerPopup = (e) => {
    e.preventDefault();
    this.props.history.push('/LearningSchedule');
  };

  GetDepartment() {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
    fetch(`${BASE_URLs}Team/GetTeam`, options)
      .then((response) => response.json())
      .then((data) => {
        // Filter out the duplicates
        const filteredData = data.filter((value, index, self) => {
          return (
            self.findIndex((item) => item.department === value.department) ===
            index
          );
        });
        this.setState({
          department: filteredData,
        });
      });
  }
  GetTrainers = async (department) => {
    // alert("Hi")
    const value = department
      ? department
      : this.props.location.state.department;

    //     this.GetMembers(value);
    let url = `${BASE_URLs}Learning/GetManagerBasedTrainer?Department=${
      this.props.location.state.department
    }&TrainerInfo=${1}`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    const reqData = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
      },
    });
    const resData = await reqData.json();
    // for (let i = 0; i < resData.length; i++) {
    //        this.state.trainerDrop.push(resData[i].label);
    // }
    //
    this.setState({ trainerDrop: resData });
    //  this.setState({ trainerDrop: this.state.trainerDrop });
  };

  GetMembers = async (department) => {
    let url = `${BASE_URLs}Learning/GetManagerBasedMember?Department=${
      this.props.location.state.department
    }&TrainerInfo=${0}`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    const reqData = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
      },
    });
    const resData = await reqData.json();
    // for (let i = 0; i < resData.length; i++) {
    //        this.state.memberDrop.push(resData[i].label);
    // }
    this.setState({ memberDrop: resData });
  };
  handleChange = (event) => {
    const department = event.target.value;
    this.setState({ selectedDepartment: department });
    this.setState({ trainerDrop: [] });
    //   this.setState({memberDrop : []})
    this.GetTrainers(department);
  };
  handleChangesss(value, actionDetails) {
    /// alert("hi");
    let items = [...this.state.selectedOption];

    if (actionDetails.action === 'remove-value') {
      if (items) {
        var index = -1;
        items.forEach((item, i) => {
          if (
            item.label === actionDetails.removedValue.label &&
            item.value === actionDetails.removedValue.value
          ) {
            index = i;
            return;
          }
        });
        if (index > -1) {
          items.splice(index, 1);
        }
      }
    } else {
      items.push(actionDetails.option);
    }

    this.setState({ selectedOption: items });
  }

  handleChangessstrainee(value, actionDetails) {
    let items = [...this.state.selectedOption];

    if (actionDetails.action === 'remove-value') {
      if (items) {
        var index = -1;
        items.forEach((item, i) => {
          if (
            item.label === actionDetails.removedValue.label &&
            item.value === actionDetails.removedValue.value
          ) {
            index = i;
            return;
          }
        });
        if (index > -1) {
          items.splice(index, 1);
        }
      }
    } else {
      items.push(actionDetails.option);
    }

    this.setState({ selectedOption: items });
  }

  TimeMod(time) {
    const Hour = time.split(':')[0];
    const minutes = time.split(':')[1];
    if (Hour > 12) {
      time = Hour - 12;
      time = time < 10 ? '0' + time : time;

      return time + ':' + minutes + ' PM';
    } else if (Hour == 0) {
      return 12 + ':' + minutes + ' AM';
    } else if (Hour == 12) {
      return 12 + ':' + minutes + ' PM';
    } else {
      return time + ' AM';
    }
  }

  Getdetail() {
    var Id = this.props.location.state.Id;
    var startDate = this.props.location.state.startDate;
    var upload_id = '1029';
    let url = `${BASE_URLs}Learning/detail?Id=${Id}`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((result) => result.json())
      .then((data) => {
        let addedDuration = data.map((e) => {
          let dur = e.schedulingDuration;
          dur = dur.split(':');

          const time = {
            hours: dur[0] < 0 ? dur[0] + 24 : dur[0],
            minutes: dur[1],
          };

          // let date = e.startDate.split(",").map(date=>date+"T"+this.TimeMod(e.startTime)
          //   );

          //

          this.setState({ ScheduleDates: e.startDate });
          this.setState({ ScheduleEndDates: e.endDate });
          this.setState({ ScheduleTime: e.scheduleTime });
          this.setState({ ScheduleEndTime: e.scheduleEndTime });

          return {
            ...e,
            // Hours: TimeMod(e.scheduleTime),
            Duration: this.Duration(e.schedulingDuration),
            ScheduledDates: [],
            TotalHours: this.addTimeMultiple(1, time),
          };
        });

        console.log('addedDuration==================', addedDuration);

        this.setState({
          Learning: addedDuration,
        });

        this.getValues(addedDuration);
      });
  }

  LearnerList = () => {
    var Id = this.props.location.state.Id;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    let url = `${BASE_URLs}Learning/LearningDetails?Id=${Id}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + accessToken,
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //  this.getValues(data);
        for (let i = 0; i < data.length; i++) {
          this.state.selectedLabel.push(data[i].trainerName);
        }

        for (let i = 0; i < data.length; i++) {
          this.state.selectedLabels.push(data[i].trainees);
        }

        this.setState({ selectedLabel: this.state.selectedLabel });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };
  GetTrainer = () => {
    //
    let url = `${BASE_URLs}Training/TrainingEditGetTrainer?LearnId=${this.props.location.state.Id}`;
    //

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let final = data.map((e) => {
          return { ...e, check: false };
        });

        this.setState({ Trainers: final });
        this.setState({ Train: final });
        this.setState({
          selectedOption: final.filter((e) => e.deletestatus === 1),
        });
        //  setTrainers(final.filter(e=>e.deleteStatus!==1))
        //  setTrain(final)
      });
      console.log("sdsdsdsdsdsdsdsdsdsd",this.state.Train)
  };
  GetTrainee = () => {
    //
    let url = `${BASE_URLs}Training/Training_GetTraineesEdit?LearnId=${this.props.location.state.Id}`;
    //

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let final = data.map((e) => {
          return { ...e, check: false };
        });

        let wholedept = [];
        final.filter((e) =>
          !wholedept.includes(e.department)
            ? wholedept.push(e.department)
            : null
        );

        let rr = wholedept.map((dep) => {
          let dpart = [];
          let UserId = [];
          let Name = [];

          let loop = final
            .filter((e) => e.department === dep)
            .map((ee) => {
              dpart.push(ee.department);
              UserId.push(ee.user_Id);
              Name.push(ee.firstName);
            });

          //if(ee.department)

          return {
            department: dpart,
            userId: UserId,
            Name: Name,
          };
        });

        this.setState({ Trainees: rr });
        this.setState({ Trainee: final });

        // setTrainers(final.filter(e=>e.deleteStatus!==1))
        // setTrain(final)
        //
      });
  };
  componentDidMount() {
    this.GetTrainee();

    this.Getdetail();
    this.GetDepartment();
    //   this.GetMembers();
    this.GetTrainer();
    this.GetTrainers();
    // this.LearnerList();
    this.GetSavedTrainee();
  }
  getValues(apiValues) {
    const value = apiValues;

    this.setState({
      selectedTrainingTopicId: value.id,
    });
    // if (apiValues[0].trainerName != null) {
    //   let previousValue = [];
    //   let names = apiValues[0].trainerName.split(",");
    //
    //   let ids = apiValues[0].trainerId.split(",");
    //   let index = 0;

    //   if (names.length === ids.length) {
    //     names.forEach((item) => {
    //       let data = { value: ids[index].toString(), label: item };
    //       index++;
    //       previousValue.push(data);
    //     });
    //   }

    //   this.setState({ selectedOption: previousValue });
    //
    //
    // }
  }

  handleChange(value, actionDetails) {
    let items = [...this.state.selectedOption];
    if (actionDetails.action === 'remove-value') {
      if (items) {
        var index = -1;
        items.forEach((item, i) => {
          if (
            item.label === actionDetails.removedValue.label &&
            item.value === actionDetails.removedValue.value
          ) {
            index = i;
            return;
          }
        });
        if (index > -1) {
          items.splice(index, 1);
        }
      }
    } else {
      items.push(actionDetails.option);
    }

    this.setState({ selectedOption: items });
  }

  // handleselectChange(value, actionDetails) {
  //        let items = [...this.state.selectedOption];
  //        if (actionDetails.action === "remove-value") {
  //               if (items) {
  //                      var index = -1;
  //                      items.forEach((item, i) => {
  //                             if (item.label === actionDetails.removedValue.label && item.value === actionDetails.removedValue.value) {
  //                                    index = i;
  //                                    return;
  //                             }
  //                      });
  //                      if (index > -1) {
  //                             items.splice(index, 1);
  //                      }
  //               }
  //        }
  //        else {
  //               items.push(actionDetails.option);
  //        }
  //        this.setState({ selectedOption: items });
  // }

  handleSelectChange = (event) => {
    // this.setState({selectedLabel:[]})
    this.setState({ ...(this.state.selectedOptions = event) });
  };

  handleSelectChangeTrainee = (event) => {
    // this.setState({memberDrop:[]})
    this.setState({ ...(this.state.SelectedTrainee = event) });
  };

  // handleSelectChange = (selectedOptions) => {
  //        const selectedValues = selectedOptions.map(option => option);
  //        this.setState({ selectedValues });
  // };

  addItem() {
    this.selectedValues.push({ key: 'Shaarik', id: '2' });
  }

  handleMultiselectChange = (event) => {
    //  alert("Handlemulti")
    this.setState({ ...(this.state.selectedOption = event) });
  };
  handleSubmit = (event) => {
    const {
      selectedLabel,
      memberDrop,
      SelectedTrainer,
      SelectedTrainee,
      selectedOption,
      SelectedTraineeEdit,
    } = this.state;
    event.preventDefault();

    const formData = {
      startDate: this.props.location.state.startDate.slice(0, 10),
      scheduleTime: this.state.scheduleTime,
    };
    const data = new FormData(event.target);

    data.append('LearnId', this.props.location.state.Id);
    data.append('ScheduleTime', this.state.scheduleTime);

    const values = this.state.selectedOption;

    values.forEach((e) => {
      data.append('TrainerId', e.trainer_Id);
    });
    const Traineevalues = this.state.SelectedTraineeEdit;

    Traineevalues.forEach((e) => {
      e[1].forEach((ee) => {
        data.append('TraineesId', ee);
      });
    });

    for (const [key, value] of data.entries()) {
    }

    //  data.append('TrainerName',"Vijayaasri");

    return true;
    //
    fetch('https://trainingapi.oneawesometeam.com/api/Learning/EditLearning', {
      method: 'POST',
      headers: {
        //  Accept: 'application/json',
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        // "Content-type": "application/json",
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
      },
      // body: data,
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === 'Success') {
          toast.success(resp.message, {
            position: 'top-center',
            autoClose: 1200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(function () {
            if (document.getElementById('close')) {
              document.getElementById('close').click();
            }
          }, 4000);
        } else {
          toast.error(resp.message, {
            position: 'top-center',
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        setTimeout(() => {
          this.props.history.push('/LearningSchedule');
        }, 1000);

        // this.LearnerList()
        this.GetTrainer();
        this.GetSavedTrainee();

        this.setState({ selectedOption: [] });
        this.setState({ status: resp.Status });
        this.setState({ message: resp.Message });
      });
  };

  DeleteMutipleTrainers = (event) => {
    event.preventDefault();

    const values = this.state.selectedCheckboxValues;

    const data = new FormData();
    values.forEach((e) => {
      data.append('Id_delete', e);
    });
    var options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    fetch(`${BASE_URLs}Learning/EditMultideleteTrainer`, options)
      .then((response) => response.json())
      .then((data) => {
        document
          .getElementById('delete')
          .setAttribute('style', 'visibility: hidden;');
        document
          .getElementById('deletecount')
          .setAttribute('style', 'visibility: hidden;');
        // this.LearnerList()
        this.GetTrainer();

        //  this.GetMasterUG();
        //  this.GetMasterPG();
        //  this.GetMasterPHD();
        //  toast.success(
        //    data.message, {
        //    position: "top-center",
        //    autoClose: 3200,
        //    hideProgressBar: false,
        //    closeOnClick: true,
        //    pauseOnHover: true,
        //    draggable: true,
        //    progress: undefined,
        //    })
      });
  };

  ////////////////

  DeleteMutipleTrainees = (event) => {
    event.preventDefault();

    const TraineeId = this.state.selectedCheckboxValues3;

    const data = new FormData();
    TraineeId.forEach((e) => {
      e.forEach((ee) => data.append('Id_delete', ee));
    });
    for (const [key, value] of data.entries()) {
    }
    return true;
    var options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    fetch(
      `https://trainingapi.oneawesometeam.com/api/Learning/EditMultideleteTrainee?learnId=${this.props.location.state.Id}`,
      options
    )
      .then((response) => response.json())
      .then((data) => {
        this.GetSavedTrainee();
        this.GetTrainee();

        document
          .getElementById('deletes')
          .setAttribute('style', 'visibility: hidden;');
        document
          .getElementById('deletecounts')
          .setAttribute('style', 'visibility: hidden;');
        // this.LearnerList()
        this.GetTrainer();

        //  this.GetMasterUG();
        //  this.GetMasterPG();
        //  this.GetMasterPHD();
        //  toast.success(
        //    data.message, {
        //    position: "top-center",
        //    autoClose: 3200,
        //    hideProgressBar: false,
        //    closeOnClick: true,
        //    pauseOnHover: true,
        //    draggable: true,
        //    progress: undefined,
        //    })
      });
  };
  GetSavedTrainee = () => {
    let obj = {};
    //
    let url = `${BASE_URLs}Training/GetTraineesForTrainingSchedule`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let uu = data.forEach((e) => {
          obj[e.department] === undefined
            ? (obj[e.department] = [e.user_Id])
            : obj[e.department].push(e.user_Id);
        });
        let final = [];
        Object.entries(obj).forEach(([key, value]) => {
          final.push([...[key], ...[value]]);
        });

        let f = final.map((e) => {
          return { ...e, ...{ check: false } };
        });

        this.setState({ SavedTrainees: f });
        this.setState({ SavedTrainee: f });
      });
  };
  Duration(duration) {
    let hour = duration.split(':')[0];
    let minute = duration.split(':')[1];
    let m = String(minute);
    m === '0' ? (m = '00') : (m = minute);
    hour = parseInt(hour) < 0 ? parseInt(hour) + 24 : hour;

    if (hour == 1 && minute == '00') {
      return Number(hour) + ' Hour';
    } else if (hour == 1 && minute != '00') {
      return Number(hour) + ':' + m + ' Hours';
    } else if (hour > 1 && minute != '00') {
      return Number(hour) + ':' + m + ' Hours';
    } else if (hour > 1 && minute == '00') {
      return Number(hour) + ':' + m + ' Hours';
    } else if (hour == 0 && m != '00') {
      return m + ' Minutes';
    }
    //  else if (hour == 0 && m == '00') {
    //   return 'Whole day';
    // } else {
    //   return 'Whole day';
    // }
  }

  addTimeMultiple(timesToAdd, time) {
    let totalHours = time.hours * timesToAdd;
    let totalMinutes = time.minutes * timesToAdd;

    totalHours += Math.floor(totalMinutes / 60);
    totalMinutes %= 60;
    if (time.hours == 0) {
      return `${timesToAdd} ${timesToAdd === 1 ? 'day' : 'days'}`;
    }
    return `${totalHours}:${totalMinutes < 10 ? '0' : ''}${totalMinutes} Hours`;
  }
  render() {
    const {
      plainArray,
      trainerDrop,
      memberDrop,
      Learning,
      LearnerList,
      selectedLabel,
      selectedLabels,
      TraineePopupVisible,
      ScheduleDates,
      ScheduleEndDates,

      ScheduleEndTime,
      ScheduleTime,
    } = this.state;
    let selectedValue = [];
    //  let selectedLabel = [];
    this.state.selectedOption &&
      this.state.selectedOption.forEach((item) => {
        selectedValue.push(item.value);
        selectedLabel.push(item.label);
      });

    this.state.selectedOptions &&
      this.state.selectedOptions.forEach((item) => {
        selectedValue.push(item.value);
        selectedLabel.push(item.label);
      });
    // let unique = [...new Set(selectedLabel)]
    //
    // let unique2 = [...new Set(selectedValue)]
    //

    let formatOptionLabel = ({ value, label, image }) => (
      <div className="grp-flex" style={{ display: 'flex' }}>
        <img className="grp-edit-img2" src={image} />
        <span>{label}</span>
      </div>
    );

    let formatOptionLabel1 = ({ value, label, image }) => (
      <div className="grp-flex" style={{ display: 'flex' }}>
        <img className="grp-edit-img2" src={image} />
        <span>{label}</span>
      </div>
    );
    let options = trainerDrop.map(function (item) {
      return { value: item.id, label: item.name };
    });
    let selectedNames = this.state.selectedOption.map((e) => e.label);

    const filteredArray = this.state.trainerDrop.filter(
      (value) => !selectedNames.includes(value.label)
    );

    const colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles) => ({
        ...styles,
        textAlign: 'center',
        display: 'flex',
        backgroundColor: 'white',
        'text-align-last': 'center',
        color: 'black',
      }),
      // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //  // const color = chroma(data.color);
      //   return {
      //     ...styles,
      //     backgroundColor: isDisabled ? 'white' : "red",
      //   //  color: 'white',
      //     textAlign:"left",
      //     cursor: isDisabled ? 'not-allowed' : 'default',

      //   };
      // },
    };

    return (
      <div>
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: 'left' }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                class="icofont icofont-arrow-left"
                                style={{ fontSize: '45px', color: 'white' }}
                              />
                            </a>{' '}
                          </div>
                          {Learning.map((Learning) => (
                            <h4 style={{ marginRight: '3%' }} className="title">
                              {Learning.trainingitem}
                            </h4>
                          ))}
                        </div>
                        <div className="card-header-right"></div>
                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="row" style={{ marginTop: '3%' }}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="container">
                                      <div
                                        className="row"
                                        style={{ padding: '8px' }}
                                      >
                                        {Learning.map((Learning) => (
                                          <div>
                                            <input
                                              type="hidden"
                                              name="LearnId"
                                              Id="LearnId"
                                              value={Learning.id}
                                            />
                                          </div>
                                        ))}
                                        <div className="col-sm-6">
                                          {/* {Learning.map((Learning) => (
                                            <select
                                              className="effect-16"
                                              type="text"
                                              value={Learning.department}
                                              name="Department"
                                              id="Department"
                                              onChange={this.handleChange}
                                            >
                                              <option value="">Select</option>
                                              {this.state.department.map(
                                                (Departmentdrop) => (
                                                  <option
                                                    value={
                                                      Departmentdrop.department
                                                    }
                                                  >
                                                    {Departmentdrop.department}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          ))} */}
                                          {Learning.map((Learning) => (
                                            <input
                                              className="effect-16"
                                              name="Department"
                                              id="Department"
                                              defaultValue={Learning.department}
                                              type="text"
                                              maxLength="50"
                                              disabled
                                            />
                                          ))}
                                          <span style={{ color: '#a29d9d' }}>
                                            Department
                                          </span>
                                        </div>
                                        <div className="col-sm-6">
                                          {Learning.map((Learning) => (
                                            <input
                                              className="effect-16"
                                              name="Trainingitem"
                                              id="Trainingitem"
                                              defaultValue={
                                                Learning.trainingitem
                                              }
                                              type="text"
                                              disabled
                                            />
                                          ))}
                                          <span style={{ color: '#a29d9d' }}>
                                            Training Topic
                                          </span>
                                        </div>

                                        <div className="col-sm-6">
                                          {Learning.map((Learning) => (
                                            <textarea
                                              className="effect-16"
                                              name="TrainingDescription"
                                              id="Trainingdescription"
                                              defaultValue={
                                                Learning.trainingDescription
                                              }
                                              type="text"
                                              disabled
                                            />
                                          ))}
                                          <span style={{ color: '#a29d9d' }}>
                                            Training Description
                                          </span>
                                        </div>

                                        {/* <div className="col-sm-6">
                                                <input
                                                  className="effect-16"
                                                  defaultValue={(this.props.location.state.startDate)}
                                                  name="StartDate"
                                                  id="StartDate"
                                                  onFocus={(e) =>
                                                    (e.target.type = "date")
                                                  }
                                                  maxLength="50"
                                                />
                                                <span
                                                  style={{ color: "#a29d9d" }}
                                                >
                                                  Start Date
                                                </span>
                                              </div> */}

                                        <div className="col-sm-6">
                                          <br></br>
                                          {Learning.map((Learning) => (
                                            <input
                                              type="text"
                                              className="effect-16"
                                              // defaultValue={`${this.props.location.state.startDate.slice(0, 10)} ${this.state.scheduleTime}`}   schedulingDuration

                                              defaultValue={Learning.Duration}
                                              // {this.Duration(Learning.schedulingDuration)}
                                              // {   `${this.state.startDate} ${this.state.scheduleTime}`}
                                              name="StartDate"
                                              id="StartDate"
                                              maxLength="50"
                                              disabled
                                            />
                                          ))}
                                          <span style={{ color: '#a29d9d' }}>
                                            Total duration
                                          </span>
                                        </div>

                                        <div className="col-sm-6">
                                          {/*                             
                                                                                                                                                   <Multiselect className="effect-16" showArrow
                                                                                                                                                         // options={this.state.selectedOption}
                                                                                                                                                          selectedValues={this.state.selectedOption}
                                                                                                                                                          value="label"
                                                                                                                                                          // onRemove={(selectedList) => this.setState({ selectedValues: selectedList })}
                                                                                                                                                          getSelectedItems={trainerDrop}
                                                                                                                                                          onSelect={this.handleChange}
                                                                                                                                                          isObject={false}
                                                                                                                                                          name="TrainerName"
                                                                                                                                                          labelledBy="Select"
                                                                                                                                                        //  value={this.state.selectedOption}
                                                                                                                                                          displayValue="label"
                                                                                                                                                   /> */}
                                          {/* {Learning.map(Learning =>
                                                                                                                                               {this.state.trainerDrop}
                                                                                                                                               )} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  {/* $$Trainers */}
                                  <div className="col-lg-3"></div>

                                  <div className="col-lg-6">
                                    <div
                                      style={{
                                        margin: '10px',
                                        padding: '20px',
                                        overflow: 'auto',
                                        height: '250px',
                                        backgroundColor: 'white',
                                        borderTop: '5px solid whitesmoke',
                                        borderBottom: '5px solid whitesmoke',
                                        borderLeft: '5px solid whitesmoke',
                                        borderRight: '5px solid whitesmoke',
                                        boxShadow: '10px 10px 5px lightblue',
                                      }}
                                    >
                                      {/* Rest of the code */}
                                      {/* <button
                                        style={{
                                          width: "40px",
                                          height: "30px",
                                        }}
                                        type="button"
                                        onClick={(e) =>
                                          this.openAddTrainerPopup(e)
                                        }
                                      >
                                        {" "}
                                        <Add />
                                      </button> */}
                                      &nbsp;
                                      {this.state.Trainers.length == 0 ? (
                                        <button
                                          style={{
                                            width: '40px',
                                            height: '30px',
                                          }}
                                          onClick={(e) =>
                                            this.opencalendarPopup(e)
                                          }
                                        >
                                          <PlusOne />
                                        </button>
                                      ) : (
                                        this.state.isButtonVisible && (
                                          <button
                                            style={{
                                              width: '40px',
                                              height: '30px',
                                            }}
                                            onClick={(e) =>
                                              this.opencalendarPopup(e)
                                            }
                                          >
                                            <Info />
                                          </button>
                                        )
                                      )}
                                      <span
                                        id="deletecount"
                                        className="multitrash"
                                        style={{
                                          visibility: 'hidden',
                                          fontSize: '17px',
                                        }}
                                      ></span>
                                      <div
                                        id="selectalldiv"
                                        style={{ visibility: 'hidden' }}
                                      >
                                        &nbsp;&nbsp;
                                        <button
                                          type="button"
                                          className="multitrash"
                                          id="delete"
                                          onClick={(e) =>
                                            this.DeleteMutipleTrainers(e)
                                          }
                                          style={{
                                            float: 'right',
                                            visibility: 'hidden',
                                          }}
                                        >
                                          <i
                                            class="icofont icofont-delete-alt"
                                            style={{ color: 'darkgray' }}
                                          ></i>
                                        </button>
                                      </div>
                                      <table
                                        id="skillsheet"
                                        className="table   nowrap SkillGrid"
                                      >
                                        <thead style={{ textAlign: 'left' }}>
                                          <tr>
                                            <th>
                                              {' '}
                                              <input
                                                type="checkbox"
                                                id="selectall"
                                                onClick={() => {
                                                  this.checkAll(this);
                                                }}
                                                hidden
                                              />{' '}
                                            </th>
                                            <th>
                                              Assign Trainers &nbsp;&nbsp;
                                            </th>

                                            {/* <th style={{ textAlign: "center" }}>
                                          Actions
                                        </th> */}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.Trainers.filter(
                                            (e) => e.deletestatus === 0
                                          ).map((value, index) => (
                                            <tr key={index}>
                                              <td>
                                                {' '}
                                                <input
                                                  type="checkbox"
                                                  className="deletcateg"
                                                  id="selectall"
                                                  name="deletcateg"
                                                  onClick={this.checkChange}
                                                  value={value.trainer_Id}
                                                />
                                              </td>
                                              <td>
                                                {' '}
                                                {value.trainerName +
                                                  ' ' +
                                                  ' at ' +
                                                  '  ' +
                                                  ScheduleDates.slice(0, 10) +
                                                  ' ' +
                                                  this.TimeMod(ScheduleTime) +
                                                  '  ' +
                                                  ' to ' +
                                                  '  ' +
                                                  this.TimeMod(
                                                    ScheduleEndTime
                                                  )}{' '}
                                              </td>
                                              {/* <td style={{ textAlign: "center" }}>
                                            <button
                                              name="delete"
                                              className="button-30 sk-del"
                                              //  onClick={()=>this.EditSkillModel(skill.id,skill.skillName)}
                                              // onClick={(e) => {
                                              //   e.preventDefault();
                                              //   this.DeleteButton(
                                              //     FileDocs.upload_id,
                                              //   );
                                              // }}
                                            >
                                              <i
                                                class="fa fa-trash-o"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </td> */}
                                            </tr>
                                          ))}

                                          {this.state.selectedOption
                                            .filter((e) => e.check === true)
                                            .map((value, index) => (
                                              <tr key={index}>
                                                <td
                                                  style={{ fontSize: '24px' }}
                                                >
                                                  {' '}
                                                  <i
                                                    className="icofont icofont-business-man-alt-3"
                                                    style={{ color: 'grey' }}
                                                  ></i>{' '}
                                                </td>
                                                <td>{value.trainerName}</td>
                                                <td>
                                                  <button
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.EditDeleteTrainer(
                                                        value,
                                                        value.trainer_UserId
                                                      );
                                                    }}
                                                    type="submit"
                                                    className="multitrash"
                                                    id="deletes"
                                                  >
                                                    <i
                                                      className="icofont icofont-delete-alt"
                                                      style={{ color: 'red' }}
                                                    ></i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))}
                                        </tbody>
                                        {/* <tbody>

                                    </tbody> */}
                                      </table>
                                    </div>
                                  </div>
                                  {/* $$StartDates */}
                                  {/* <div className="col-lg-6">
                                    <div
                                      style={{
                                        margin: "10px",
                                        padding: "20px",
                                        overflow: "auto",
                                        height: "250px",
                                        backgroundColor: "white",
                                        borderTop: "5px solid whitesmoke",
                                        borderBottom: "5px solid whitesmoke",
                                        borderLeft: "5px solid whitesmoke",
                                        borderRight: "5px solid whitesmoke",
                                        boxShadow: "10px 10px 5px lightblue",
                                      }}
                                    >
                                      {/* Rest of the code  
                                      <button
                                        style={{
                                          width: "40px",
                                          height: "30px",
                                        }}
                                        type="button"
                                        onClick={(e) =>
                                          this.openAddTraineePopup(e)
                                        }
                                      >
                                        {" "}
                                        <Add />
                                      </button>
                                      <span
                                        id="deletecounts"
                                        className="multitrash"
                                        style={{
                                          visibility: "hidden",
                                          fontSize: "17px",
                                        }}
                                      ></span>
                                      <div
                                        id="selectalldivs"
                                        style={{ visibility: "hidden" }}
                                      >
                                        &nbsp;&nbsp;
                                        <button
                                          className="multitrash"
                                          type="button"
                                          id="deletes"
                                          onClick={this.DeleteMutipleTrainees}
                                          style={{
                                            float: "right",
                                            visibility: "hidden",
                                          }}
                                        >
                                          <i
                                            class="icofont icofont-delete-alt"
                                            style={{ color: "darkgray" }}
                                          ></i>
                                        </button>
                                      </div>
                                      <table
                                        id="skillsheet"
                                        className="table   nowrap SkillGrid"
                                      >
                                        <thead style={{ textAlign: "left" }}>
                                          <tr>
                                            <th>
                                              {" "}
                                              <input
                                                type="checkbox"
                                                id="checkAllTrainers"
                                                onClick={() => {
                                                  this.checkAllTrainers(this);
                                                }}
                                              />{" "}
                                            </th>
                                            <th>Start Dates &nbsp;&nbsp;</th>

                                            {/* <th style={{ textAlign: "center" }}>
                                          Actions
                                        </th>  
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {/* {ScheduleDates.map((Learning1) => (
                                            // Learning1.ScheduledDates.map(
                                            // (e) => (
                                            <tr>
                                              <td>
                                                {" "}
                                                <input
                                                  type="checkbox"
                                                  className="deletcategs"
                                                  name="TraineesName"
                                                  defaultValue={
                                                    Learning1.split("$$")[1]
                                                  }
                                                  id="trainerscheckid"
                                                  onChange={
                                                    this.checkChangeTrainees
                                                  }
                                                />
                                              </td>

                                              <td>
                                                {Learning1.split("$$")[0]  +" at "+ Learning1.split("T")[1]  }
                                              </td>
                                            </tr>
                                            // )
                                            // );
                                          ))}  

                                          {this.state.SelectedDatesEdit.map(
                                            (value, index) => (
                                              <tr key={index}>
                                                <td
                                                  style={{ fontSize: "24px" }}
                                                >
                                                  {" "}
                                                  <i
                                                    className="icofont icofont-business-man-alt-3"
                                                    style={{ color: "grey" }}
                                                  ></i>{" "}
                                                </td>
                                                <td>
                                                  {value.split("T")[0] +
                                                    " at " +
                                                    this.TimeMod(
                                                      value
                                                        .split("T")[1]
                                                        .slice(0, 5)
                                                    )}
                                                </td>
                                                <td>
                                                  <button
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      this.EditDeleteStartDate(
                                                        value,
                                                        value[0]
                                                      );
                                                    }}
                                                    type="submit"
                                                    className="multitrash"
                                                    id="deletes"
                                                  >
                                                    <i
                                                      className="icofont icofont-delete-alt"
                                                      style={{ color: "red" }}
                                                    ></i>
                                                  </button>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div> */}

                                  <div className="col-lg-3"></div>
                                </div>
                                <br></br>
                                <div className="row">
                                  <div
                                    className="col-sm-12"
                                    style={{ textAlign: 'center' }}
                                  >
                                    {/* <button
                                      type="submit"
                                      className="but "
                                      style={{
                                        height: "39.5px",
                                        width: "86.36px",
                                        marginTop: "-0.3%",
                                      }}
                                    >
                                      Save
                                    </button>
                                    &nbsp; &nbsp; &nbsp; */}
                                    <button
                                      type="reset"
                                      className=" btn-Secondary"
                                      style={{ height: '39.5px' }}
                                      onClick={this.ClearInputs}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                                <br /> <br />
                                <br /> <br />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.TrainerPopupVisible && (
          <TrainingEditTrainerPop
            LearnId={this.props.location.state.Id}
            array={this.state.selectedOption}
            state={(state) => this.setState({ selectedOption: state })}
            closeThePopup={this.toggleAddTrainerPopup}
          />
        )}

        {/* ///Start date popup */}

        {this.state.TraineePopupVisible && (
          <TrainingEditTraineesPop
            array={this.state.Learning}
            // FinalSubmit = {(e)=>{this.handleSubmit(e)}}

            state={(state) =>
              //

              this.setState({ SelectedDatesEdit: state })
            }
            third={this.toggleAddTrainerPopup}
          />
        )}

        {this.state.calendarpop && (
          <TrainingEditTraineesPop
            array={this.state.Learning}
            SelectedtrainerArray={this.state.Trainers.filter(
              (e) => e.deleteStatus === 0
            )}
            trainingID={this.props.location.state.Id}
            state={(state) => this.setState({ SelectedDatesEdit: state })}
            third={(e) => this.opencalendarPopup(e)}
            closePopup={(e) => this.toggleAddTrainerPopup(e)}
          />
        )}
      </div>
    );
  }
}
