import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { BASE_URLs } from "../../Config/Base";
import CloseIcon from "@material-ui/icons/Close";
import fileImg from "../../Assets/images/document.png";
export default class UserCompanyDocument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Active: [],
      InActive: [],
      All: [],
      formErrors: {},
      Pending: [],
      Notification: [],
      Profile_Pic: [],
      OfficeDocument: [],
   
      AllOfficeDocument: [],
      letters: [],
      Filterpop: false,
      ID:'',
      delay: false,
      Document:[],
      isOpen: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.handleFormValidation()) {
      const data = new FormData(event.target);

      fetch(`${BASE_URLs}UserRequest/AddRequest`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          alert(resp.message);
        });
    }
  }

  handleFormValidation() {
    let description = document.getElementById("description").value;

    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!description) {
      formIsValid = false;
      formErrors["DescriptionErr"] = "Description is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }

  GetUserProfile() {
    let url = `${BASE_URLs}Profile/Userprofile`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Profile_Pic: data,
        });
      });
  }
  componentDidMount() {
    this.GetUserProfile();
    this.Getpending();

    setTimeout(() => {
      this.setState({
        delay: true
      })
    }, 500);

    var UserId = localStorage.getItem('adminId');
    UserId = UserId.replace(/^"(.*)"$/, '$1');

    this.GetOfficeDocument(UserId);
    this.setState({ID:UserId})
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    this.setState({ letters: alphabet });

    var role = JSON.parse(localStorage.Role);

    if (role.toString() === "Platform Admin") {
      document
        .getElementById("navright")
        .setAttribute("style", " display:none;");
      /* document.getElementById("sidedrop").setAttribute( 'style', '  display:none;'); */
      /*document.getElementById("sidebarimg").setAttribute( 'style', '  display:none;'); */
      document
        .getElementById("mainorg")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("sidebarteam")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("sidebarEducation")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("sidebarskills")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("paytimeof")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("sidebarTraining")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("sidebarContact")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("sidebardrop")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("AdminadOrg")
        .setAttribute("style", "  display:none;");
      document
        .getElementById("admindash")
        .setAttribute("style", "  display:none;");
    } else {
      //   document.getElementById("allcards").setAttribute( 'style', '  display:none;');
      //    document.getElementById("sidebarAnnouncement").setAttribute( 'style', '  display:none;');
      //    //document.getElementById("sidebarNotifications").setAttribute( 'style', '  display:none;');
      //     document.getElementById("sidebarchangepass").setAttribute( 'style', '  display:none;');
      //    document.getElementById("sidebarmasters").setAttribute( 'style', '  display:none;');
      //document.getElementById("sidebarremainder").setAttribute( 'style', '  display:none;');
      //document.getElementById("adorg").setAttribute( 'style', '  display:none;');
      /*   document.getElementById("dashall").setAttribute( 'style', '  display:none;');
              document.getElementById("dashactive").setAttribute( 'style', '  display:none;');
              document.getElementById("dashinactive").setAttribute( 'style', '  display:none;'); */
    }
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
        //
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
        //
      });
  }
  Getall() {
    fetch(`${BASE_URLs}AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
        //
      });
  }
  Getpending() {
    fetch(`${BASE_URLs}AddOrganization/pendingcount`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Pending: data });
      });
  }
  popupclose = () => {
    this.setState({
      isOpen: false
    })
  }
  GetOfficeDocument(Id) {
    
    let url = `${BASE_URLs}AdminOfficeDocuments/GetUserCompanyDocument?Id=` + Id;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          OfficeDocument: data,
          AllOfficeDocument: data,
        });
        // console.log("user", data);
        
      });
  }

  handleFilter = (letter, event) => {
    event.preventDefault();
    const OfficeDocument = this.state.AllOfficeDocument.filter((item) => {
    return (
      item.officeDocumentTitle.charAt(0).toLowerCase() === letter.toLowerCase() ||
      item.category.charAt(0).toLowerCase() === letter.toLowerCase() 
    );
  });
  this.setState({ OfficeDocument, showResults: true  });
  };

  
  filterfunction = (e) => {
    const OfficeDocument = this.state.AllOfficeDocument.filter(
      (item) =>
        item.officeDocumentTitle.toLowerCase().startsWith(e.target.value.toLowerCase()) ||
        item.category.toLowerCase().startsWith(e.target.value.toLowerCase()) 
    );
    this.setState({ OfficeDocument, showResults: true  });
  }

  getFileType(path) {
    const extension = path.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) {
      return 'image';
    } else if (['mp4', 'avi', 'mov', 'wmv', 'mkv'].includes(extension)) {
      return 'video';
    } else if (['pdf', 'xls', 'xlsx'].includes(extension)) {
      return 'document';
    } else {
      return 'file';
    }
  }


  GetDocument(Id,UserId) {

    this.setState({
      isOpen: true
    })

    let url = `${BASE_URLs}AdminOfficeDocuments/GetUserSideDocument?Id=` + Id +  "&UserId=" +
    UserId;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Document: data,
        });
        // console.log("user", data);
      });
  }

  handleImageClick = (url) => {
    window.open(url, '_blank');
     };
  render() {
    const { OfficeDocument, Filterpop,delay,isOpen,Document } = this.state;
    return (
      <div>
        {" "}
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 style={{ marginRight: "4%" }} className="title">
                            {" "}
                            Office Documents
                          </h4>
                        </div>

                        {isOpen ?
                          <div className="col-lg-12">
                            <div className="popup" style={{ textAlign: 'left' }}>
                              <div className="popup-content">
                                <div className="Announcedetailspop" >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                    style={{
                                      color: "gray",
                                      marginLeft: "90%",
                                      fontSize: "17px",
                                      cursor: 'pointer'
                                    }}
                                    onClick={this.popupclose}
                                  ></i>
                                  <br />
                                  <center><h6><b>Attachment</b></h6></center>
                                  <div className="card-block">

                                    {Document.map((OfficeDocument) => (
                                      <>
                                      <div style={{height: "237px", overflow: "scroll"}}>
                                        {OfficeDocument.officeDocument.split(',').map((imageUrl, index) => (
                                          <div style={{ display: "inline-block", marign: "10px" }} key={index}>
 
                                                <div style={{margin:"10px"}}>
                                                {this.getFileType(imageUrl) === 'image' ? (
                                                  
                                                  <span style={{ margin:"30px" }}>
                                                  <b>Image:&nbsp;</b>

{imageUrl.split(',').map((Url, index) => (
<img
key={index}
alt={`Image ${index}`}
src={imageUrl.trim()} 
style={{ width: "100px", height: "100px" }}
onClick={() => this.handleImageClick(Url.trim())}
/>
))}
                                                  </span>
                                                ) : this.getFileType(imageUrl) === 'video' ? (
                                                  <span style={{ display: "flex", margin:"30px" }}>
                                                    <span><b>video:</b></span>
                                                    <video
                                                      controls
                                                      style={{ width: "125px", height: '118px', marginTop: '-62px', marginLeft:"8px" }}
                                                    >
                                                      <source src={imageUrl} type="video/mp4" />
                                                      <source src={imageUrl} type="video/ogg" />
                                                    </video>
                                                  </span>
                                                ) : (
                                                  <span style={{margin:"30px"}}>
                                                    <b>File:</b> 
                                                    <img
                                                      src={fileImg}
                                                      style={{ height: "30px", width: "30px" }}
                                                    />
                                                    &nbsp;
                                                    <a
                                                      href={imageUrl}
                                                      name="picture"
                                                      readOnly
                                                      value={imageUrl}
                                                      title=""
                                                      target="_blank"
                                                    >
                                                      <span>
                                                      {imageUrl.includes(`https://localhost:44369/Images/OfficeDocument_`)?
                                                      <>  {imageUrl.replace("https://localhost:44369/Images/OfficeDocument_", "")}</>:
                                                      <>  {imageUrl.replace("https://apitest.oneawesometeam.com/Images/OfficeDocument_", "")}</>
                                                      }
                                                     {/* {imageUrl.replace("https://localhost:44369/Images/OfficeDocument_", "")} */}
                                                   
                                                      </span>
                                                    </a>
                                                  </span>
                                                )}
                                                </div>
                                                </div>
                                        ))} 
                                        </div>
                                         </>
                                    ))}
                                   
                                  </div>
                                </div> </div> </div></div> : null}
                        <div className="card-header-right"></div>
                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <br />
                            <br />

                            <div className="container">
                              <div className="col-lg-12">
                              <div>
                 <input
   type="text"
   className="Searchbox usersearch"
  //  id="CNSearch"
   placeholder="Search"
   onKeyPress={this.handleKeyPress}
   style={{float:'right'}}
   onClick={() => {
                            this.setState({
                            Filterpop: true,
                            Filterpop: !this.state.Filterpop,
                            });
                            }}
                            readOnly

 /></div>
                                <div className="dt-responsive table-responsive">
                                {OfficeDocument.length === 0 && delay? 
                                <center>
                             
                            <p>No Record</p>
                          </center> 
                                          : null}
                                          {OfficeDocument.length !== 0 ? (    <table
                                      id="add-row-table3"
                                      className="table nowrap"
                                    >
                                      <thead>
                                        <tr>
                                        <th> Title </th>
                                          <th>Category </th>
                                        
                                          <th>Office Document </th>
                                        </tr>
                                      </thead>
                                   
                                      <tbody>
                                        {OfficeDocument.map(
                                          (OfficeDocument) => (
                                            <tr>
                                            <td>
                                                {
                                                  OfficeDocument.officeDocumentTitle
                                                }
                                              </td>
                                              <td>
                                                {" "}
                                                {OfficeDocument.category}{" "}
                                              </td>
                                             
                                              <td>
                                                <span
                                                   style={{
                                            cursor: 'pointer'
                                          }}

                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.GetDocument(OfficeDocument.id,this.state.ID)
                                          }}
                                                >
                                                  Show Document
                                                </span>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody> 
                                    </table>) : null}
                                 
                                </div>

                                <br />
                              </div>
                              <br /> <br />
                              <br /> <br />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {Filterpop && (
                              <div className="popSearchTeam">
                                <div className="popSearchinnernormalTeam">
                                  <div
                                    className="popSearchinnertitle"
                                    id="popSearchinnernormal"
                                  >
                                    <button
                                      id="close"
                                      style={{
                                        float: "right",
                                        backgroundColor: "#a0a3a305",
                                        color: "#797070",
                                        border: "1px solid #a3a5a505",
                                        marginTop: "-20px",
                                        marginRight: "-20px",
                                      }}
                                      onClick={() => {
                                        this.setState({ Filterpop: false,selectedOption: ''});
                                            this.GetOfficeDocument(this.state.ID);
                                      }}
                                    >
                                      <CloseIcon />
                                    </button>

                                    <div className="checkboxes">
                                      <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "20px",
                                          color: "gray",
                                          marginLeft: "-1%",
                                          position: "absolute",
                                          marginTop: "0.5%",
                                        }}
                                      ></i>
                                      <input
                                        className="Searchbox"
                                        placeholder="Search"
                                        onChange={this.filterfunction}
                                        style={{ float: "right", width: "80%" }}
                                      />
                                      <br></br>
                                      {this.state.letters.map(
                                        (letter, index) => (
                                          <button
                                            className="filter"
                                            key={index}
                                            onClick={(event) =>
                                              this.handleFilter(
                                                letter,
                                                event
                                              )
                                            }
                                          >
                                            {letter}
                                          </button>
                                        )
                                      )}
                                      <br></br>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
            </div>
          </div>
          <div id="styleSelector"></div>
        </div>
      </div>
    );
  }
}
