import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URLs } from "../../Config/Base"
export default class SkillMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edus: [],
      Id: "",
      UG: "",
      PG: "",
      Specialization: "",
      University: "",
      InActive: [],
      Active: [],
      All: [],
      Notification: [],
      comlogo: [],
      formErrors: {},
      edusdrop: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFormValidation() {
    //const {CourseName,Specialization}= this.state;
    let Degree = document.getElementById("Degree").value;
    let CourseName = document.getElementById("CourseName").value;
    let Specialization = document.getElementById("Specialization").value;
    let formErrors = {};
    let formIsValid = true;

    if (!Degree) {
      formIsValid = false;
      formErrors["Degreetype"] = "DegreeType is required";
    }
    //Email
    if (!CourseName) {
      formIsValid = false;
      formErrors["UGErr"] = "CourseName is required";
    }

    if (!Specialization) {
      formIsValid = false;
      formErrors["SpecializationErr"] = "Specialization is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  handleSubmit(event) {
    event.preventDefault();
    if (this.handleFormValidation()) {
      const data = new FormData(event.target);

      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      fetch(`${BASE_URLs}Education/Post`, {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          // /  Authorization: `Bearer ${JSON.parse(localStorage.user)}` /
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      })
        .then((res) => res.json())
        .then((resp) => {
          if (resp.status === "Success") {
            toast.success(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            setTimeout(() => {
              this.props.history.push("/Education");
            }, 4000);
          } else {
            toast.error(resp.message, {
              position: "top-center",
              autoClose: 3200,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    }
  }

  deleteSkill(id) {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}Skill/Delete?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          alert(resp);
        });
      });
    }
  }

  handleChange = (e) => {
    let Degree = document.getElementById("Degree").value;
    let CourseName = document.getElementById("CourseName").value;
    let Specialization = document.getElementById("Specialization").value;
    let formErrors = {};
    let formIsValid = true;

    if (!Degree) {
      formIsValid = false;
      formErrors["Degreetype"] = "DegreeType is required";
    }
    //Email
    else if (!CourseName) {
      formIsValid = false;
      formErrors["UGErr"] = "CourseName is required";
    } else if (!Specialization) {
      formIsValid = false;
      formErrors["SpecializationErr"] = "Specialization is required";
    }
    this.setState({ formErrors: formErrors });

    return formIsValid;
  };
  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };

  GetOrgLogo() {
    let url = `${BASE_URLs}AddOrganization/clogo`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comlogo: data,
        });
      });
  }

  componentDidMount() {
    this.GetOrgLogo();
    this.GetEdudrop();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  Getallactive() {
    fetch(`${BASE_URLs}AddOrganization/Getallactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Active: data });
      });
  }
  GetallInactive() {
    fetch(`${BASE_URLs}AddOrganization/GetallInactive`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ InActive: data });
      });
  }
  Getall() {
    fetch(`${BASE_URLs}AddOrganization/Getall`)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ All: data });
      });
  }

  GetEdudrop() {
    let url = `${BASE_URLs}Education/getMasterEducationdrop`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          edusdrop: data,
        });
      });
  }

  render() {
    const { UGErr, SpecializationErr, Degreetype } = this.state.formErrors;
    return (
      <div>
        <SideBar active={window.location.hash} />
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: "left" }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                          <h4 className="title">Add</h4>
                        </div>
                        <div className="card-header-right"></div>

                        <div className="card-block2">
                          <form
                            autoComplete="on"
                            style={{ marginTop: "5%" }}
                            onSubmit={this.handleSubmit}
                          >
                            <div className="container">
                              <div className="row" id="skill-form">
                                <br />
                                <br />

                                <div className="col-lg-4">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <select
                                        className="form"
                                        name="Degree"
                                        id="Degree"
                                        onInputCapture={this.HandleUg}
                                        onChange={this.handleChange}
                                        type="text"
                                      >
                                        <option value=""> </option>
                                        <option value="UG" name="UG">
                                          {" "}
                                          UG{" "}
                                        </option>
                                        <option value="PG" name="PG">
                                          PG
                                        </option>
                                        <option value="PHD" name="PHD">
                                          PHD
                                        </option>
                                      </select>

                                      {!Degreetype ? (
                                        <span style={{ color: "#a29d9d" }}>
                                          Degree
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {Degreetype}
                                        </div>
                                      )}
                                      <span className="underline" />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      <input
                                        className="form"
                                        name="CourseName"
                                        id="CourseName"
                                        type="text"
                                        onChange={this.handleChange}
                                      />
                                      {!UGErr ? (
                                        <span style={{ color: "#a29d9d" }}>
                                          CourseName
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {UGErr}
                                        </div>
                                      )}
                                      <span className="underline" />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-lg-12">
                                      <input
                                        className="form"
                                        name="Specialization"
                                        id="Specialization"
                                        type="text"
                                        onChange={this.handleChange}
                                      />
                                      {!SpecializationErr ? (
                                        <span style={{ color: "#a29d9d" }}>
                                          Specialization
                                        </span>
                                      ) : (
                                        <div style={{ color: "red" }}>
                                          {SpecializationErr}
                                        </div>
                                      )}
                                      <span className="underline" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />

                              <div className="row">
                                <div
                                  className="col-sm-12"
                                  style={{ textAlign: "center" }}
                                >
                                  <button
                                    type="submit"
                                    className="but"
                                    style={{
                                      height: "39.5px",
                                      width: "86.36px",
                                      marginTop: "-0.3%",
                                    }}
                                  >
                                    Save
                                  </button>
                                  &nbsp; &nbsp; &nbsp;
                                  <button
                                    type="reset"
                                    className=" btn-Secondary"
                                    style={{ height: "39.5px" }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                          <br /> <br /> <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
