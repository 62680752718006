import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import SideBar from '../Common/Sidebar';
import { Link } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import PlusOne from '@material-ui/icons/Add';
import Add from '@material-ui/icons/Add';
import axios from 'axios';
import BookmarkAddIcon from '@material-ui/icons/PostAdd';
import SchoolIcon from '@material-ui/icons/School';
import AddTrainingAddTrainer from './AddTrainingAddTrainer';
import AddTrainingAddDesignation from './AddTrainingAddDesignation';
import _ from 'lodash';
import { BASE_URLs } from '../Config/Base';
export default class EditTrainingpop extends Component {
  fileInputRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Training: [],
      FileDocs: [],
      Departmentdrop: [],
      enabled: false,
      imageName: '',
      AssignedTraineePopModel: false,
      isOpen: false,
      Open: false,
      opens: false,
      Trainers: [],
      TrainersName: [],
      TrainingsName: [],
      selectedFiles: [],
      selectedFiless: [],
      selectedTrainer: [],
      selectedTrainerID: [],
      selectAll: false,
      showTick: false,
      files: [],
      message: '',
      error: '',
      showInput: false,
      isChecked: false,
      selectedCheckboxValues: [],
      selectedCheckboxValues1: [],
      selectedCheckboxValues2: [],
      selectedCheckboxValues3: [],
      DesignationCheckBoxValue: [],
      Designation: [],
      AddedDesignation: [],
      AddedDesignationNames: [],
      DesignationPopCheckBoxValue: [],
      DesignationPopCheckBoxValues: [],

      TrainerPopCheckBoxValue: [],
      isAddTrainerOpen: false,
      selectedDesignation: [],
      originalTraining: [],
      TrainingItem: [],
      selectedValue: 'default', // Replace 'default' with your initial selected value
      originalSelectedValue: 'default', // Store the original selected value
      Dpt: [],
      InDrop: false,
      InHourDrop: false,
      isChecking: false,
      Options: [
        {
          Hour: '1 Hour',

          Hour: '1.5 Hour',

          Hour: '2 Hours',

          Hour: '2.5 Hour',

          Hour: '3 Hours',

          Hour: '3.5 Hour',

          Hour: '4 Hours',

          Hour: '4.5 Hour',

          Hour: '5 Hours',

          Hour: '5.5 Hour',

          Hour: '6 Hours',

          Hour: '6.5 Hour',

          Hour: '7 Hours',

          Hour: '7.5 Hour',

          Hour: '8 Hours',

          Hour: '8.5 Hour',

          Hour: '9 Hour',

          Hour: '9.5 Hour',
        },
      ],
    };
    this.selectedFileValues = [];
    this.selectedTrainerValues = [];
    this.selectedTrainerValues1 = [];
    this.selectedDesignationValues = [];

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTrainersCheckbox = this.handleTrainersCheckbox.bind(this);
  }
  handleButtonsClick = () => {
    // Trigger the click event of the file input element
    this.fileInputRef.current.click();

    const deleteButton = document.getElementById('deletes');
    deleteButton.style.visibility = 'hidden';

    let Total = document.querySelectorAll('input[id="selectall"]:checked');
    if (document.getElementById('selectall')) {
      for (var i = 0; i < Total.length; i++) {
        Total[i].checked = false;
      }
    }

    document
      .getElementById('delete')
      .setAttribute('style', 'visibility: hidden;');
    document
      .getElementById('deletecount')
      .setAttribute('style', 'visibility: hidden;');

    let Trainer = document.querySelectorAll(
      'input[id="checkAllTrainers"]:checked'
    );
    if (document.getElementById('checkAllTrainers')) {
      for (var i = 0; i < Trainer.length; i++) {
        Trainer[i].checked = false;
      }
    }

    document
      .getElementById('deletes')
      .setAttribute('style', 'visibility: hidden;');
    document
      .getElementById('deletecounts')
      .setAttribute('style', 'visibility: hidden;');

    let Designation = document.querySelectorAll(
      'input[id="desigidcheck"]:checked'
    );
    if (document.getElementById('desigidcheck')) {
      for (var i = 0; i < Designation.length; i++) {
        Designation[i].checked = false;
      }
    }

    document
      .getElementById('deletesss')
      .setAttribute('style', 'visibility: hidden;');
    document
      .getElementById('deletecountsss')
      .setAttribute('style', 'visibility: hidden;');
  };

  handleInputChange = (index, value) => {
    console.log('index', index);
    console.log('value', value);

    const updatedTraining = [...this.state.Training];
    updatedTraining[index].trainingitem = value;

    this.setState({ Training: updatedTraining });
  };

  handleInputChangedes = (index, value) => {
    console.log('index', index);
    console.log('value', value);

    const updatedTraining = [...this.state.Training];
    updatedTraining[index].description = value;

    this.setState({ Training: updatedTraining });
  };

  // handleDropdownChange = (e) => {
  //   this.setState({ selectedValue: e.target.value });

  // };

  handleInputChangedep = (index, selectedValue) => {
    const updatedTraining = [...this.state.Training];
    updatedTraining[index].department = selectedValue;
    setTimeout(() => {
      this.setState({ Training: updatedTraining });
    }, 1000);
    this.setState({ Training: updatedTraining });

    console.log('updatedTraining', updatedTraining);
  };

  handleInputChangehour = (index, selectedValue) => {
    const updatedTraining = [...this.state.Training];
    updatedTraining[index].duration = selectedValue;
    setTimeout(() => {
      this.setState({ Training: updatedTraining });
    }, 1000);
    this.setState({ Training: updatedTraining });

    console.log('updatedTraining', updatedTraining);
  };

  //   handleCancelClick = () => {
  //     // Select all checkboxes and make the "select all" elements visible
  //     const checkboxes = document.querySelectorAll('.deletcategs, .deletcategsss, .deletcateg');
  //     document.getElementById('selectall').style.visibility = 'visible';
  //     document.getElementById('checkAllTrainers').style.visibility = 'visible';
  //     document.getElementById('desigidcheck').style.visibility = 'visible';

  //     // Loop through each checkbox and reset the row visibility
  //     checkboxes.forEach((checkbox) => {
  //         const tr = checkbox.closest('tr');
  //         if (tr) {
  //             tr.style.display = ''; // Reset display property to its default value
  //         }
  //     });

  //     // Reset visibility of the delete buttons and counters
  //     document.getElementById('deletes').style.visibility = 'hidden';
  //     document.getElementById('delete').style.visibility = 'hidden';
  //     document.getElementById('deletecount').style.visibility = 'hidden';
  //     document.getElementById('deletecounts').style.visibility = 'hidden';
  //     document.getElementById('deletesss').style.visibility = 'hidden';
  //     document.getElementById('deletecountsss').style.visibility = 'hidden';

  //     // Uncheck all "select all" checkboxes
  //     const selectAllCheckboxes = document.querySelectorAll('#selectall:checked, #checkAllTrainers:checked, #desigidcheck:checked');
  //     selectAllCheckboxes.forEach((checkbox) => {
  //         checkbox.checked = false;
  //     });

  //     // Deep clone the originalTraining array using lodash and set the state
  //     const originalTrainingCopy = _.cloneDeep(this.state.originalTraining);
  //     this.setState({ Training: originalTrainingCopy }, () => {
  //         console.log('this.state.Training after reset', this.state.Training);
  //     });

  //     // Reset other state variables
  //     this.setState({
  //         selectedFiless: [],
  //         Trainers: this.state.Trainers.map((e) => ({ ...e, check: false })),
  //         selectedTrainer: [],
  //         Designation: this.state.Designation.map((e) => ({ ...e, check: false })),
  //         selectedDesignation: [],
  //         selectedCheckboxValues: [],
  //         selectedCheckboxValues1: [],
  //         selectedCheckboxValues2: [],
  //         selectedCheckboxValues3: [],
  //         DesignationCheckBoxValue: [],
  //     });

  //     // Call GetTrainersName
  //     this.GetTrainersName();
  // };

  handleCancelClick = () => {
    // Select all checkboxes and make the "select all" elements visible
    const checkboxes = document.querySelectorAll(
      '.deletcategs, .deletcategsss, .deletcateg'
    );

    const selectAllElement = document.getElementById('selectall');
    if (selectAllElement) selectAllElement.style.visibility = 'visible';

    const checkAllTrainersElement = document.getElementById('checkAllTrainers');
    if (checkAllTrainersElement)
      checkAllTrainersElement.style.visibility = 'visible';

    const desigidcheckElement = document.getElementById('desigidcheck');
    if (desigidcheckElement) desigidcheckElement.style.visibility = 'visible';

    // Loop through each checkbox and reset the row visibility
    checkboxes.forEach((checkbox) => {
      const tr = checkbox.closest('tr');
      if (tr) {
        tr.style.display = ''; // Reset display property to its default value
      }
    });

    // Reset visibility of the delete buttons and counters
    const deletesElement = document.getElementById('deletes');
    if (deletesElement) deletesElement.style.visibility = 'hidden';

    const deleteElement = document.getElementById('delete');
    if (deleteElement) deleteElement.style.visibility = 'hidden';

    const deletecountElement = document.getElementById('deletecount');
    if (deletecountElement) deletecountElement.style.visibility = 'hidden';

    const deletecountsElement = document.getElementById('deletecounts');
    if (deletecountsElement) deletecountsElement.style.visibility = 'hidden';

    const deletesssElement = document.getElementById('deletesss');
    if (deletesssElement) deletesssElement.style.visibility = 'hidden';

    const deletecountsssElement = document.getElementById('deletecountsss');
    if (deletecountsssElement)
      deletecountsssElement.style.visibility = 'hidden';

    // Uncheck all "select all" checkboxes
    const selectAllCheckboxes = document.querySelectorAll(
      '#selectall:checked, #checkAllTrainers:checked, #desigidcheck:checked'
    );
    selectAllCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    // Deep clone the originalTraining array using lodash and set the state
    const originalTrainingCopy = _.cloneDeep(this.state.originalTraining);
    this.setState({ Training: originalTrainingCopy }, () => {
      console.log('this.state.Training after reset', this.state.Training);
    });

    // Reset other state variables
    this.setState({
      selectedFiless: [],
      Trainers: this.state.Trainers.map((e) => ({ ...e, check: false })),
      selectedTrainer: [],
      Designation: this.state.Designation.map((e) => ({ ...e, check: false })),
      selectedDesignation: [],
      selectedCheckboxValues: [],
      selectedCheckboxValues1: [],
      selectedCheckboxValues2: [],
      selectedCheckboxValues3: [],
      DesignationCheckBoxValue: [],
    });

    // Call GetTrainersName
    this.GetTrainersName();
  };

  // handleCancelClick = () => {
  //   const checkboxes = document.querySelectorAll('.deletcategs');
  //   const checkboxes1 = document.querySelectorAll('.deletcategsss');
  //   const checkboxes2 = document.querySelectorAll('.deletcateg');

  //   //While tempropry delet the Doc, Trainer, Desig after click cancel it shown the checkbox
  //   const checkallfile = document.getElementById('selectall')
  //  checkallfile.style.visibility = 'visible'

  //  const checkallTrainers = document.getElementById('checkAllTrainers')
  //  checkallTrainers.style.visibility = 'visible'

  //  const desigid = document.getElementById('desigidcheck')
  //  desigid.style.visibility = 'visible'

  //   // Loop through each checkbox
  //   checkboxes.forEach((checkbox) => {
  //     // If checkbox is checked, show the corresponding td element
  //     if (checkbox.checked) {
  //       // Assuming the checkbox is within a table row (tr)
  //       const tr = checkbox.closest('tr');
  //       if (tr) {
  //         // Show the entire row
  //         tr.style.display = ''; // Reset display property to its default value
  //       }
  //     }
  //   });

  //   checkboxes1.forEach((checkbox) => {
  //     // If checkbox is checked, show the corresponding td element
  //     if (checkbox.checked) {
  //       // Assuming the checkbox is within a table row (tr)
  //       const tr = checkbox.closest('tr');
  //       if (tr) {
  //         // Show the entire row
  //         tr.style.display = ''; // Reset display property to its default value
  //       }
  //     }
  //   });

  //   checkboxes2.forEach((checkbox) => {
  //     // If checkbox is checked, show the corresponding td element
  //     if (checkbox.checked) {
  //       // Assuming the checkbox is within a table row (tr)
  //       const tr = checkbox.closest('tr');
  //       if (tr) {
  //         // Show the entire row
  //         tr.style.display = ''; // Reset display property to its default value
  //       }
  //     }
  //   });

  //   // Reset the delete button visibility
  //   const deleteButton = document.getElementById('deletes');
  //   deleteButton.style.visibility = 'hidden';

  //   let Total = document.querySelectorAll('input[id="selectall"]:checked');
  //   if (document.getElementById('selectall')) {
  //     for (var i = 0; i < Total.length; i++) {
  //       Total[i].checked = false;
  //     }
  //   }

  //   document
  //     .getElementById('delete')
  //     .setAttribute('style', 'visibility: hidden;');
  //   document
  //     .getElementById('deletecount')
  //     .setAttribute('style', 'visibility: hidden;');

  //   let Trainer = document.querySelectorAll(
  //     'input[id="checkAllTrainers"]:checked'
  //   );
  //   if (document.getElementById('checkAllTrainers')) {
  //     for (var i = 0; i < Trainer.length; i++) {
  //       Trainer[i].checked = false;
  //     }
  //   }

  //   document
  //     .getElementById('deletes')
  //     .setAttribute('style', 'visibility: hidden;');
  //   document
  //     .getElementById('deletecounts')
  //     .setAttribute('style', 'visibility: hidden;');

  //   let Designation = document.querySelectorAll(
  //     'input[id="desigidcheck"]:checked'
  //   );
  //   if (document.getElementById('desigidcheck')) {
  //     for (var i = 0; i < Designation.length; i++) {
  //       Designation[i].checked = false;
  //     }
  //   }

  //   document
  //     .getElementById('deletesss')
  //     .setAttribute('style', 'visibility: hidden;');
  //   document
  //     .getElementById('deletecountsss')
  //     .setAttribute('style', 'visibility: hidden;');

  //   // Deep clone the originalTraining array using lodash
  //       // Deep clone the originalTraining array using lodash and set the state
  //       const originalTrainingCopy = _.cloneDeep(this.state.originalTraining);
  //       this.setState({ Training: originalTrainingCopy }, () => {
  //           console.log('this.state.Training after reset', this.state.Training);
  //       });

  //   this.setState({ selectedFiless: [] });
  //   const updatedState = this.state.Trainers.map((e) => ({
  //     ...e,
  //     check: false,
  //   }));
  //   this.setState({ Trainers: [updatedState] });
  //   this.setState({
  //     selectedTrainer: updatedState.filter((e) => e.check === false),
  //   });
  //   const updatedStates = this.state.Designation.map((e) => ({
  //     ...e,
  //     check: false,
  //   }));
  //   this.setState({ Designation: updatedStates });
  //   this.setState({ selectedDesignation: [] });
  //   this.setState({ selectedTrainer: [] });
  //   this.setState({
  //     selectedCheckboxValues: [],
  //   });

  //   this.setState({
  //     selectedCheckboxValues1: [],
  //   });

  //   this.setState({
  //     selectedCheckboxValues2: [],
  //   });

  //   this.setState({
  //     selectedCheckboxValues3: [],
  //   });

  //   this.setState({
  //     DesignationCheckBoxValue: [],
  //   });

  //   this.GetTrainersName();

  // };

  handleTrainerssCheckbox(event) {
    const { defaultValue } = event.target;
    this.setState({
      isChecked: event.target.checked,
      defaultValue: defaultValue,
    });
  }

  openAddTrainerPopup = () => {
    this.setState({ isAddTrainerOpen: !this.state.isAddTrainerOpen });

    const deleteButton = document.getElementById('deletes');
    deleteButton.style.visibility = 'hidden';

    let Total = document.querySelectorAll('input[id="selectall"]:checked');
    if (document.getElementById('selectall')) {
      for (var i = 0; i < Total.length; i++) {
        Total[i].checked = false;
      }
    }

    document
      .getElementById('delete')
      .setAttribute('style', 'visibility: hidden;');
    document
      .getElementById('deletecount')
      .setAttribute('style', 'visibility: hidden;');

    let Trainer = document.querySelectorAll(
      'input[id="checkAllTrainers"]:checked'
    );
    if (document.getElementById('checkAllTrainers')) {
      for (var i = 0; i < Trainer.length; i++) {
        Trainer[i].checked = false;
      }
    }

    document
      .getElementById('deletes')
      .setAttribute('style', 'visibility: hidden;');
    document
      .getElementById('deletecounts')
      .setAttribute('style', 'visibility: hidden;');

    let Designation = document.querySelectorAll(
      'input[id="desigidcheck"]:checked'
    );
    if (document.getElementById('desigidcheck')) {
      for (var i = 0; i < Designation.length; i++) {
        Designation[i].checked = false;
      }
    }

    document
      .getElementById('deletesss')
      .setAttribute('style', 'visibility: hidden;');
    document
      .getElementById('deletecountsss')
      .setAttribute('style', 'visibility: hidden;');
  };

  // fetchData = async (firstname, TrainerId) => {
  //   console.log("qwertyuiop", this.props.location.state);

  //   console.log("TrainerId and FirstName", firstname, TrainerId);

  //   const data = new FormData();
  //   for (const [key, value] of data.entries()) {
  //     console.log("------------------------------", key, value);
  //   }
  //   const url = `https://trainingapi.oneawesometeam.com/api/Training/EditAssignedTrainers?FirstName=${firstname}&TrainerId=${TrainerId}&TrainingId=${this.props.location.state.training_Id}`;
  //   console.log(url);
  //   // const url = `https://trainingapi.oneawesometeam.com/api/Training/EditAssignedTrainers?FirstName=${firstname}&TrainingId=${this.props.location.state.training_Id}`;
  //   const options = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //       Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
  //     },
  //     body: data,
  //   };
  //   const response = await fetch(url, options);
  //   const result = await response.json();

  //   if (result.status === "Success") {
  //     toast.success(result.message, {
  //       position: "top-center",
  //       autoClose: 3200,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   } else {
  //     toast.error(result.message, {
  //       position: "top-center",
  //       autoClose: 3200,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  //   setTimeout(() => {
  //     this.GetTrainersNameFromAssignedTable();
  //   }, 1000);
  //   this.setState({ Open: false });

  //   setTimeout(() => {
  //     this.GetTrainersName();
  //   }, 1000);
  // };

  fetchData = async () => {
    const data = new FormData();

    let dd = this.state.TrainerPopCheckBoxValue.forEach((e) => {
      let split = e.split('$$$');

      data.append('TrainerId', split[1]);
      data.append('FirstName', split[0]);
      data.append('TrainingId', this.props.location.state.training_Id);
    });

    for (const [key, value] of data.entries()) {
      console.log('FormData', key, value);
    }
    var is_selected = [];

    // const url = `https://trainingapi.oneawesometeam.com/api/Training/EditAssignedTrainers`;
    const url = `${BASE_URLs}Training/EditAssignedTrainers`;

    console.log(url);

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    const response = await fetch(url, options);
    const result = await response.json();

    if (result.status === 'Success') {
      toast.success(result.message, {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // setTimeout(() => {
    //   this.GetTrainersNameFromAssignedTable();
    // }, 1000);
    setTimeout(() => {
      this.GetTrainersNameFromAssignedTable();
    }, 1000);
    this.setState({ Open: false });

    setTimeout(() => {
      this.GetTrainersName();
    }, 1000);
  };

  PrinCheckBoxValue = (event) => {
    const isChecked = event.target.checked;
    this.setState({ isChecked }, () => {
      console.log('Checkbox value:', this.state.isChecked);
    });
  };

  handleButtonClick = () => {
    this.setState((prevState) => ({
      showInput: !prevState.showInput,
    }));
  };

  handleSelectChange = (event) => {
    this.setState({ files: event.target.files });
    this.AddSelectedFiles();
  };

  AddSelectedFiles = async () => {
    try {
      const formData = new FormData();
      for (let i = 0; i < this.state.files.length; i++) {
        formData.append('FileName', this.state.files[i]);
      }

      const response = await fetch(
        `${BASE_URLs}Training/Addmoredocuments?TrainingId=${this.props.location.state.training_Id}`,
        {
          method: 'POST',
          body: formData,
          headers: {
            // Add any required headers, such as authentication token
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        this.setState({ message: data.Message, error: '' });
      } else {
        const errorData = await response.json();
        this.setState({ error: errorData.Message, message: '' });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  handleCheckboxChange = (event, file) => {
    const { checked } = event.target;
    const { selectedFiles, selectAll } = this.state;

    if (file === 'all') {
      // If "Check All" checkbox is clicked
      this.setState({ selectAll: checked });
      if (checked) {
        // Select all files
        this.setState({ selectedFiles: this.props.files });
      } else {
        // Deselect all files
        this.setState({ selectedFiles: [] });
      }
    } else {
      if (checked) {
        // Add the file to the selectedFiles array
        const updatedSelectedFiles = [...selectedFiles, file];
        this.setState({ selectedFiles: updatedSelectedFiles });
      } else {
        // Remove the file from the selectedFiles array
        const updatedSelectedFiles = selectedFiles.filter(
          (selectedFile) => selectedFile !== file
        );
        this.setState({ selectedFiles: updatedSelectedFiles });
      }
    }
    console.log('checked', this.props.upload_id);
  };

  handleDeleteClick = () => {
    const { selectedFiles } = this.state;
    // Perform the delete operation for the selected files

    // Clear the selectedFiles array
    this.setState({ selectedFiles: [] });
  };

  openPopup = () => {
    this.setState({ isOpen: true });
  };

  closePopup = () => {
    this.setState({ isOpen: false });
  };

  openThePopup = () => {
    this.setState({ Open: true });
  };

  closeThePopup = (event) => {
    event.preventDefault();
    this.setState({ Open: false });
  };

  openThePopups = (e) => {
    e.preventDefault();
    this.setState({ opens: true });

    const deleteButton = document.getElementById('deletes');
    deleteButton.style.visibility = 'hidden';

    let Total = document.querySelectorAll('input[id="selectall"]:checked');
    if (document.getElementById('selectall')) {
      for (var i = 0; i < Total.length; i++) {
        Total[i].checked = false;
      }
    }

    document
      .getElementById('delete')
      .setAttribute('style', 'visibility: hidden;');
    document
      .getElementById('deletecount')
      .setAttribute('style', 'visibility: hidden;');

    let Trainer = document.querySelectorAll(
      'input[id="checkAllTrainers"]:checked'
    );
    if (document.getElementById('checkAllTrainers')) {
      for (var i = 0; i < Trainer.length; i++) {
        Trainer[i].checked = false;
      }
    }

    document
      .getElementById('deletes')
      .setAttribute('style', 'visibility: hidden;');
    document
      .getElementById('deletecounts')
      .setAttribute('style', 'visibility: hidden;');

    let Designation = document.querySelectorAll(
      'input[id="desigidcheck"]:checked'
    );
    if (document.getElementById('desigidcheck')) {
      for (var i = 0; i < Designation.length; i++) {
        Designation[i].checked = false;
      }
    }

    document
      .getElementById('deletesss')
      .setAttribute('style', 'visibility: hidden;');
    document
      .getElementById('deletecountsss')
      .setAttribute('style', 'visibility: hidden;');
  };

  closeThePopups = () => {
    this.setState({ opens: false });
    //this.setState({ DesignationPopCheckBoxValue: [] });
  };
  // Validation() {

  //   let formErrors = {};
  //   let formIsValid = true;
  //   var TrainingName = document.getElementById("TrainingName").value

  //   if (!TrainingName) {
  //     formIsValid = false;
  //     formErrors["TrainingName"] = "Training Name  is required";
  //   }

  //   this.setState({ formErrors: formErrors });
  //   return formIsValid;

  //}

  imageHandleChange(e) {
    if (e.target.files) {
      this.setState({
        imageName: [...e.target.files],
        showTick: true,
      });
    }
    console.log('image', this.state.imageName);
  }

  handleSubmit(event) {
    event.preventDefault();
    // Code By Arun
    const { Training } = this.state;

    // Check if any department is not selected
    const hasEmptyDepartment = Training.some(
      (training) => training.department === ''
    );
    const hasEmptyTopic = Training.some(
      (training) => training.trainingitem.trim() === ''
    );
    const hasEmptyDescription = Training.some(
      (training) => training.description.trim() === ''
    );

    if (hasEmptyDepartment) {
      toast.error('Department is required', {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (hasEmptyTopic) {
      toast.error('Training Topic is required', {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (hasEmptyDescription) {
      toast.error('Training Description is required', {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const { selectedTrainer, selectedDesignation } = this.state;
    // Code By Arun

    // if (this.Validation()) {
    const data = new FormData(event.target);
    console.log(data);
    for (const [key, value] of data.entries()) {
      console.log('------------------------------', key, value);
    }

    data.append('Ref_Docs', this.state.selectedFiless);

    for (let i = 0; i < this.state.selectedFiless.length; i++) {
      data.append('Ref_Docs', this.state.selectedFiless[i]);
      console.log('filearray', this.state.selectedFiless[i]);
    }

    // Code By Arun
    if (selectedTrainer.length > 0) {
      selectedTrainer
        .filter((e) => e.check === true)
        .forEach((ee) => {
          data.append('Training_Id', this.props.location.state.training_Id);
          data.append('Trainer', ee.trainerName);
          data.append('TrainerId', ee.trainer_UserId);
        });
    }
    // Code By Arun

    // Code By Balaji
    if (selectedDesignation.length > 0) {
      selectedDesignation.forEach((value) => {
        data.append('Designation', value.designation);
      });
    }

    // Code By Balaji

    fetch(`${BASE_URLs}Training/SAEditTraining`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === 'Success') {
          toast.success(resp.message, {
            position: 'top-center',
            autoClose: 1200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(function () {
            if (document.getElementById('close')) {
              document.getElementById('close').click();
            }
          }, 4000);
        } else {
          toast.error(resp.message, {
            position: 'top-center',
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    const updateTrainersPromise = new Promise((resolve, reject) => {
      this.UpdateTrainersDeletion((error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });

    const updateDesignationPromise = new Promise((resolve, reject) => {
      this.updateDeletionDesignation((error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });

    const updateFilesPromise = new Promise((resolve, reject) => {
      this.updateFileDeletion((error, result) => {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
    });

    Promise.all([
      updateTrainersPromise,
      updateDesignationPromise,
      updateFilesPromise,
    ])
      .then((results) => {
        const [trainersResult, designationResult, fileResult] = results;
        console.log('UpdateTrainersDeletion result:', trainersResult);
        console.log('updateDeletionDesignation result:', designationResult);
        console.log('fileResult result:', fileResult);

        // Further processing if needed
      })
      .catch((error) => {
        console.error('Error occurred:', error);
      });
    this.SuccessModel();
    // }
  }
  SuccessModel = () => {
    this.setState((prevState) => ({
      SuccessModel: !prevState.SuccessModel,
    }));

    this.props.history.push('/TrainingSchedule');
  };

  GetDepartment() {
    let url = `${BASE_URLs}Department/DepartmentGet`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Departmentdrop: data,
        });
      });
  }
  GetFileDocs() {
    var Id = this.props.location.state.training_Id;
    let url = `${BASE_URLs}Training/GetFileDocs?Training_Id=` + Id;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          FileDocs: data,
        });
        console.log('HHHHHHHH', data);
      });
  }

  GetTrainersName = (department) => {
    var Id = this.props.location.state.training_Id;

    let url =
      `${BASE_URLs}Training/GetTrainersNameFromTrainerInfoTable?Department=` +
      department +
      '&TrainingId=' +
      Id +
      '';
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('data', data);
        this.setState({
          Trainers: data,
        });
        console.log('TrainersssNameee', data);
      });
  };

  Getdetail = () => {
    var Id = this.props.location.state.training_Id;

    let url = `${BASE_URLs}Training/detail?Training_Id=` + Id;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((result) => result.json())
      .then((data) => {
        this.setState({
          Training: data,
          //originalTraining: data.map(training => ({ ...training })),
          originalTraining: _.cloneDeep(data),
          TrainingItem: data[0].trainingitem,
          Dpt: data[0].department,
        });

        this.GetTrainersName(data[0].department);

        console.log('-------------fdfdffdf-------', data[0].department);
        console.log('-------------kkkkkkkkkkkk-------', data[0].trainingitem);
      });
  };
  emailundisableTxt() {
    document.getElementById('department').disabled = false;
    document.getElementById('Trainingitem').disabled = false;
    document.getElementById('description').disabled = false;
    // document.getElementById("Ref_Docs").disabled = false;
    document.getElementById('Updatebtn').style.display = 'inline-block';
    document.getElementById('EditBtn').style.display = 'none';
  }

  handlingenable = (e) => {
    e.preventDefault();
    this.setState({ enabled: true });
  };
  handlingdisable = (e) => {
    e.preventDefault();
    this.setState({ enabled: false });
    document.getElementById('description').readOnly = true;
    document.getElementById('Trainingitem').readOnly = true;
    document.getElementById('department').disabled = true;
  };

  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push('/TrainingSchedule');
    } else {
      this.GetAddedDesignation();
      this.GetDepartment();
      this.GetFileDocs();
      this.Getdetail();
      this.GetTrainersName();
      this.GetTrainersNameFromAssignedTable();

      this.GetDesignation();
    }
  }

  GetTrainersNameFromAssignedTable() {
    var Id = this.props.location.state.training_Id;
    console.log('Training_ID', Id);
    let url =
      `${BASE_URLs}Training/GetTrainersNameFromAssignedTrainerTable?id=` + Id;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          TrainersName: data,
        });
        console.log('Naaameeeeee', data);
      });
  }

  AssignedTraineePopModel = () => {
    this.setState((prevState) => ({
      AssignedTraineePopModel: !prevState.AssignedTraineePopModel,
    }));
  };

  DeleteMutipleFiles = () => {
    const checkAll = document.getElementById('selectall');
    if (checkAll.checked) {
      checkAll.style.visibility = 'hidden';
    }
    const checkboxes = document.querySelectorAll('.deletcateg');
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        const tr = checkbox.closest('tr');
        if (tr) {
          tr.style.display = 'none';
        }
      }
    });

    const deleteButton = document.getElementById('delete');
    deleteButton.style.visibility = 'hidden';
    // this.setState({
    //   selectedCheckboxValues:[]
    // })
    document.getElementById('selectall').checked = false;
    document
      .getElementById('delete')
      .setAttribute('style', 'visibility: hidden;');
    document
      .getElementById('deletecount')
      .setAttribute('style', 'visibility: hidden;');
    const { selectedCheckboxValues } = this.state;

    // Return the selected values
    this.setState({ selectedCheckboxValues: this.selectedFileValues }, () => {
      // Log and return the selected values
      console.log(
        'SelectedDocumentValue after button click',
        this.state.selectedCheckboxValues
      );
    });

    var checkboxes1 = document.getElementsByName('deletcateg');
    var selectedValues = [];
    for (var i = 0; i < checkboxes1.length; i++) {
      if (checkboxes1[i].checked) {
        selectedValues.push(checkboxes1[i].value);
      }
    }
    this.setState({
      selectedCheckboxValues: selectedValues,
    });
    console.log('selectedValues', selectedValues);
  };
  updateFileDeletion = () => {
    var Id = this.state.selectedCheckboxValues;

    let url = `${BASE_URLs}Training/DeleteMutipleFiles`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;

    const data = {
      trainingIds: [Id],
    };

    const formData = new FormData();

    Id.forEach((e) => {
      formData.append('TrainingId', e);
    });
    for (const [key, value] of formData.entries()) {
      console.log('------------------------------', key, value);
    }

    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + accessToken,

        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
      },
      body: formData,
    })
      .then((result) => result.json())
      .then((responseData) => {
        // Handle the response here
        // if (responseData.status === 'Success') {
        //   toast.success(responseData.message, {
        //     position: 'top-center',
        //     autoClose: 3200,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });

        //   this.setState({
        //     // Reset the state to its initial values here
        //     selectedCheckboxValues: [],
        //   });

        //   setTimeout(() => {
        //     //   this.props.history.push("/TrainingSchedule");
        //     this.GetFileDocs();
        //     document
        //       .getElementById('delete')
        //       .setAttribute('style', 'visibility: hidden;');
        //     document
        //       .getElementById('deletecount')
        //       .setAttribute('style', 'visibility: hidden;');
        //   }, 500);
        //   let Total = document.querySelectorAll(
        //     'input[id="selectall"]:checked'
        //   );
        //   if (document.getElementById('selectall')) {
        //     for (var i = 0; i < Total.length; i++) {
        //       Total[i].checked = false;
        //     }
        //   }
        // } else {
        //   toast.error(responseData.message, {
        //     position: 'top-center',
        //     autoClose: 3200,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //   });
        // }

        console.log('Message', responseData);
        // Update the state or perform any necessary actions after successful deletion
      });
  };

  // DeleteMutipleTrainers = () => {
  //   var Ids = this.state.selectedCheckboxValues3;
  //   var UserId = this.state.selectedCheckboxValues2;

  //   let url = "https://trainingapi.oneawesometeam.com/api/Training/DeleteMultipleTrainers";
  //   let user = JSON.parse(localStorage.getItem("user"));
  //   const accessToken = user;

  //   const data = {
  //     trainingIds: [Ids],
  //   };

  //   const data1 = {
  //     UserId: [UserId],
  //   };

  //   const formData = new FormData();

  //   Ids.forEach((e) => {
  //     formData.append("TrainingId", e);
  //   });

  //   UserId.forEach((f) => {
  //     formData.append("UserId", f);
  //   });

  //   for (const [key, value] of formData.entries()) {
  //     console.log("============", key, value);
  //   }

  //   console.log("tgtgtgtgtgt", Ids, UserId);
  //   fetch(url, {
  //     method: "POST",
  //     headers: {
  //       Authorization: "Bearer " + accessToken,

  //       "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
  //     },
  //     body: formData,
  //   })
  //     .then((result) => result.json())
  //     .then((responseData) => {
  //       // Handle the response here
  //       if (responseData.status === "Success") {
  //         toast.success(responseData.message, {
  //           position: "top-center",
  //           autoClose: 3200,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });

  //         this.setState({
  //           // Reset the state to its initial values here
  //           selectedCheckboxValues2: [],
  //         });

  //         this.setState({
  //           // Reset the state to its initial values here
  //           selectedCheckboxValues3: [],
  //         });

  //         setTimeout(() => {
  //           this.GetTrainersNameFromAssignedTable();
  //           document
  //             .getElementById("deletes")
  //             .setAttribute("style", "visibility: hidden;");
  //           document
  //             .getElementById("deletecounts")
  //             .setAttribute("style", "visibility: hidden;");
  //         }, 500);

  //         let Trainer = document.querySelectorAll(
  //           'input[id="checkAllTrainers"]:checked'
  //         );
  //         if (document.getElementById("checkAllTrainers")) {
  //           for (var i = 0; i < Trainer.length; i++) {
  //             Trainer[i].checked = false;
  //           }
  //         }
  //         let Designation = document.querySelectorAll(
  //           'input[id="desigidcheck"]:checked'
  //         );
  //         if (document.getElementById("desigidcheck")) {
  //           for (var i = 0; i < Designation.length; i++) {
  //             Designation[i].checked = false;
  //           }
  //         }
  //       } else {
  //         toast.error(responseData.message, {
  //           position: "top-center",
  //           autoClose: 3200,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       }

  //       console.log("Message", responseData);
  //       // Update the state or perform any necessary actions after successful deletion
  //     });
  // };

  DeleteMutipleTrainers = () => {
    const checkAllTrainers = document.getElementById('checkAllTrainers');
    if (checkAllTrainers.checked) {
      checkAllTrainers.style.visibility = 'hidden';
    }
    const checkboxes = document.querySelectorAll('.deletcategs');
    let selectedValues = [];
    let selectNames = [];

    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        selectedValues.push(checkbox.value);
        selectNames.push(checkbox.name);
        const tr = checkbox.closest('tr');
        if (tr) {
          tr.style.display = 'none';
        }
      }
    });

    const deleteButton = document.getElementById('deletes');
    deleteButton.style.visibility = 'hidden';
    document.getElementById('checkAllTrainers').checked = false;
    document.getElementById('deletecounts').style.visibility = 'hidden';

    this.setState(
      {
        selectedCheckboxValues3: selectedValues,
        selectedCheckboxValues2: selectNames,
      },
      () => {
        console.log(
          'SelectedDocumentValue after button click',
          this.state.selectedCheckboxValues2
        );
        console.log(
          'SelectedDocumentValue after button click',
          this.state.selectedCheckboxValues3
        );
      }
    );
  };

  UpdateTrainersDeletion = () => {
    var Ids = this.state.selectedCheckboxValues3;
    var UserId = this.state.selectedCheckboxValues2;

    console.log('IDIDIDSSSS', Ids, UserId);

    let url = `${BASE_URLs}Training/DeleteMultipleTrainers`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;

    const data = {
      trainingIds: [Ids],
    };

    const data1 = {
      UserId: [UserId],
    };

    const formData = new FormData();

    Ids.forEach((e) => {
      formData.append('TrainingId', e);
    });

    UserId.forEach((f) => {
      formData.append('UserId', f);
    });

    for (const [key, value] of formData.entries()) {
      console.log('============', key, value);
    }

    console.log('tgtgtgtgtgt', Ids, UserId);
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + accessToken,

        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
      },
      body: formData,
    })
      .then((result) => result.json())
      .then((responseData) => {
        // Handle the response here
        if (responseData.status === 'Success') {
          // toast.success(responseData.message, {
          //   position: 'top-center',
          //   autoClose: 3200,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });

          this.setState({
            // Reset the state to its initial values here
            selectedCheckboxValues2: [],
          });

          this.setState({
            // Reset the state to its initial values here
            selectedCheckboxValues3: [],
          });

          setTimeout(() => {
            this.GetTrainersNameFromAssignedTable();
            // document
            //   .getElementById("deletes")
            //   .setAttribute("style", "visibility: hidden;");
            // document
            //   .getElementById("deletecounts")
            //   .setAttribute("style", "visibility: hidden;");
          }, 500);

          let Trainer = document.querySelectorAll(
            'input[id="checkAllTrainers"]:checked'
          );
          if (document.getElementById('checkAllTrainers')) {
            for (var i = 0; i < Trainer.length; i++) {
              Trainer[i].checked = false;
            }
          }
          let Designation = document.querySelectorAll(
            'input[id="desigidcheck"]:checked'
          );
          if (document.getElementById('desigidcheck')) {
            for (var i = 0; i < Designation.length; i++) {
              Designation[i].checked = false;
            }
          }
        } else {
          // toast.error(responseData.message, {
          //   position: 'top-center',
          //   autoClose: 3200,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //});
        }

        console.log('Message', responseData);
        // Update the state or perform any necessary actions after successful deletion
      });
  };

  DeleteMutipleDesignation = () => {
    const CheckAllDesig = document.getElementById('desigidcheck');
    if (CheckAllDesig.checked) {
      CheckAllDesig.style.visibility = 'hidden';
    }
    const checkboxes = document.querySelectorAll('.deletcategsss');
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        const tr = checkbox.closest('tr');
        if (tr) {
          tr.style.display = 'none';
        }
      }
    });
    const deleteButton = document.getElementById('deletesss');
    deleteButton.style.visibility = 'hidden';

    // this.setState({
    //   DesignationCheckBoxValue:[]
    // })
    document.getElementById('desigidcheck').checked = false;

    document
      .getElementById('deletesss')
      .setAttribute('style', 'visibility: hidden;');
    document
      .getElementById('deletecountsss')
      .setAttribute('style', 'visibility: hidden;');
    this.setState(
      { DesignationCheckBoxValue: this.selectedDesignationValues },
      () => {
        // Log and return the selected values
        console.log(
          'SelectedDocumentValue after button click',
          this.state.DesignationCheckBoxValue
        );
      }
    );

    const { DesignationCheckBoxValue } = this.state;
    if (DesignationCheckBoxValue.length > 0) {
      document
        .getElementById('desigidcheck')
        .setAttribute('style', 'visibility: hidden;');
    }

    var checkboxes1 = document.getElementsByName('deletcategsss');
    var selectedValues = [];
    for (var i = 0; i < checkboxes1.length; i++) {
      if (checkboxes1[i].checked) {
        selectedValues.push(checkboxes1[i].value);
      }
    }
    this.setState({
      DesignationCheckBoxValue: selectedValues,
    });
    console.log(selectedValues);
  };

  updateDeletionDesignation = () => {
    var Id = this.state.DesignationCheckBoxValue;

    let url = `${BASE_URLs}Training/DeleteMutipleDesignations`;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;

    const data = {
      trainingIds: [Id],
    };

    const formData = new FormData();

    Id.forEach((e) => {
      formData.append('TrainingId', e);
    });
    for (const [key, value] of formData.entries()) {
      console.log('------------------------------', key, value);
    }

    console.log('HHHHHHHHHHHHHHH', Id);
    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + accessToken,

        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers',
      },
      body: formData,
    })
      .then((result) => result.json())
      .then((responseData) => {
        // Handle the response here
        if (responseData.status === 'Success') {
          toast.success(responseData.message, {
            position: 'top-center',
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.setState({
            DesignationCheckBoxValue: [],
          });
          setTimeout(() => {
            this.GetAddedDesignation();
            // document
            //   .getElementById("deletesss")
            //   .setAttribute("style", "visibility: hidden;");
            // document
            //   .getElementById("deletecountsss")
            //   .setAttribute("style", "visibility: hidden;");
          }, 500);

          let Designation = document.querySelectorAll(
            'input[id="desigidcheck"]:checked'
          );
          if (document.getElementById('desigidcheck')) {
            for (var i = 0; i < Designation.length; i++) {
              Designation[i].checked = false;
            }
          }
        } else {
          toast.error(responseData.message, {
            position: 'top-center',
            autoClose: 3200,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        console.log('Message', responseData);
        // Update the state or perform any necessary actions after successful deletion
      });
  };

  // handleFileChange = (event) => {
  //   const selectedFiless = Array.from(event.target.files);
  //   this.setState({ selectedFiless });
  //   console.log("=-=-=-=-=", selectedFiless);
  // };

  // handleFileChange = (event) => {
  //   const newSelectedFiles = Array.from(event.target.files);
  //   this.setState((prevState) => ({
  //     selectedFiless: [...prevState.selectedFiless, ...newSelectedFiles],
  //   }));
  //   console.log("=-=-=-=-=", this.state.selectedFiless);
  // };
  handleFileChange = (event) => {
    const newSelectedFiles = Array.from(event.target.files);
    console.log('Newly selected files:', newSelectedFiles);

    const updatedFiles = newSelectedFiles.filter(
      (file) => file.name.length <= 25
    );
    const longNameFiles = newSelectedFiles.filter(
      (file) => file.name.length > 25
    );
    if (longNameFiles.length > 0) {
      const longNameFileNames = longNameFiles.map((file) => file.name);
      alert(
        `The following files have names longer than 25 characters: ${longNameFileNames.join(
          ', '
        )}`
      );
    }

    console.log('Updated files (<= 25 characters):', updatedFiles);

    // Extract file names from FileDocs state by removing 'Ref_Docs_' prefix
    const existingFileDocs = this.state.FileDocs.filter(
      (doc) => typeof doc.file_name === 'string'
    ) // Ensure each doc has a string file_name
      .map((doc) => doc.file_name.replace('Ref_Docs_', ''));
    console.log('Existing FileDocs:', existingFileDocs);

    // Extract names from selectedFiless state
    const existingSelectedFiles = this.state.selectedFiless
      .filter((file) => typeof file.name === 'string') // Ensure each file has a string name
      .map((file) => file.name);
    console.log('Existing selectedFiless:', existingSelectedFiles);

    // Combine existing file names from both states
    const allExistingFileNames = [
      ...existingFileDocs,
      ...existingSelectedFiles,
    ];

    // Filter out duplicates
    const nonDuplicateFiles = updatedFiles.filter(
      (file) => !allExistingFileNames.includes(file.name)
    );

    // Check for any files that are duplicates
    const duplicateFiles = updatedFiles.filter((file) =>
      allExistingFileNames.includes(file.name)
    );

    if (duplicateFiles.length == 1) {
      const duplicateFileNames = duplicateFiles.map((file) => file.name);
      alert(
        `The following file are already added in previous updates: ${duplicateFileNames.join(
          ', '
        )}`
      );
    } else if (duplicateFiles.length > 1) {
      const duplicateFileNames = duplicateFiles.map((file) => file.name);
      alert(
        `The following files are already added in previous updates: ${duplicateFileNames.join(
          ', '
        )}`
      );
    }

    if (nonDuplicateFiles.length > 0) {
      // Update state with non-duplicate files
      this.setState((prevState) => ({
        selectedFiless: [...prevState.selectedFiless, ...nonDuplicateFiles],
      }));
    }

    console.log('Final selectedFiless:', this.state.selectedFiless);
  };

  handleDeleteClicks = (index) => {
    // Remove the first value from displayedValues
    const [firstValue, ...remainingValues] = this.state.selectedFiless;

    // Update the state with the remaining values
    remainingValues.splice(index, 1);
    this.setState({ selectedFiless: remainingValues });
    console.log('remainingValuesremainingValues', remainingValues);
  };

  checkAll(e) {
    const checkboxes = document.querySelectorAll(
      'input[type="checkbox"].deletcateg'
    );
    const selectAllCheckbox = document.getElementById('selectall');

    let visibleCheckboxes = Array.from(checkboxes).filter(
      (checkbox) => checkbox.closest('tr').style.display !== 'none'
    );
    let count = visibleCheckboxes.length;

    visibleCheckboxes.forEach((checkbox) => {
      checkbox.checked = selectAllCheckbox.checked;
    });

    if (selectAllCheckbox.checked) {
      document.getElementById('delete').style.visibility = 'visible';
      document.getElementById('deletecount').style.visibility = 'visible';
      document.getElementById('deletecount').style.background =
        'linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)';
      document.getElementById(
        'deletecount'
      ).innerHTML = `<span> ${count} Record${
        count > 1 ? 's' : ''
      } selected </span>`;
    } else {
      document.getElementById('delete').style.visibility = 'hidden';
      document.getElementById('deletecount').style.visibility = 'hidden';
    }
  }

  checkAllDesignation(e) {
    const checkboxes = document.querySelectorAll(
      'input[type="checkbox"].deletcategsss'
    );
    const selectAllCheckbox = document.getElementById('desigidcheck');

    let visibleCheckboxes = Array.from(checkboxes).filter(
      (checkbox) => checkbox.closest('tr').style.display !== 'none'
    );
    let count = visibleCheckboxes.length;

    visibleCheckboxes.forEach((checkbox) => {
      checkbox.checked = selectAllCheckbox.checked;
    });

    if (selectAllCheckbox.checked) {
      document.getElementById('deletesss').style.visibility = 'visible';
      document.getElementById('deletecountsss').style.visibility = 'visible';
      document.getElementById('deletecountsss').style.background =
        'linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)';
      document.getElementById(
        'deletecountsss'
      ).innerHTML = `<span> ${count} Record${
        count > 1 ? 's' : ''
      } selected </span>`;
    } else {
      document.getElementById('deletesss').style.visibility = 'hidden';
      document.getElementById('deletecountsss').style.visibility = 'hidden';
    }
  }

  checkAllTrainers() {
    var checkboxes = document.getElementsByClassName('deletcategs');
    var sa = document.getElementById('checkAllTrainers');
    let visibleCheckboxes = Array.from(checkboxes).filter(
      (checkbox) => checkbox.closest('tr').style.display !== 'none'
    );
    let count = visibleCheckboxes.length;

    if (sa.checked) {
      visibleCheckboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
      document.getElementById('deletes').style.visibility = 'visible';
      document.getElementById('deletecounts').style.visibility = 'visible';
      document.getElementById('deletecounts').style.background =
        'linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)';
      document.getElementById('deletecounts').innerHTML =
        count > 1
          ? `<span> ${count} Records are selected </span>`
          : `<span> ${count} Record is selected </span>`;
    } else {
      visibleCheckboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
      document.getElementById('deletes').style.visibility = 'hidden';
      document.getElementById('deletecounts').style.visibility = 'hidden';
    }
  }

  handlecheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"].deletcateg:checked'
    );
    let totalCheckboxes = document.querySelectorAll(
      'input[type="checkbox"].deletcateg'
    );

    // Filter visible checkboxes
    let visibleCheckboxes = Array.from(totalCheckboxes).filter(
      (checkbox) => checkbox.closest('tr').style.display !== 'none'
    );

    let checkedVisibleCheckboxes = Array.from(checkboxes).filter(
      (checkbox) => checkbox.closest('tr').style.display !== 'none'
    );

    if (checkedVisibleCheckboxes.length >= 2) {
      document.getElementById('selectalldiv').style.visibility = 'visible';
    } else {
      document.getElementById('selectalldiv').style.visibility = 'hidden';
    }

    if (checkedVisibleCheckboxes.length >= 1) {
      document.getElementById('delete').style.visibility = 'visible';
      document.getElementById('deletecount').style.visibility = 'visible';
      document.getElementById('deletecount').style.background =
        'linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)';

      let count = checkedVisibleCheckboxes.length;
      let msg = document.getElementById('deletecount');
      msg.innerHTML = `<span> ${count} Record${
        count > 1 ? 's' : ''
      } selected </span>`;

      if (count === 0) {
        document.getElementById('delete').style.visibility = 'hidden';
        document.getElementById('deletecount').style.visibility = 'hidden';
      }
    } else {
      document.getElementById('delete').style.visibility = 'hidden';
      document.getElementById('deletecount').style.visibility = 'hidden';
    }

    let allChecked = visibleCheckboxes.every((checkbox) => checkbox.checked);

    document.getElementById('selectall').checked = allChecked;
    console.log(
      'Number of checked checkboxes:',
      checkedVisibleCheckboxes.length
    );
  }

  handleDesignationCheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"].deletcategsss:checked'
    );
    let totalCheckboxes = document.querySelectorAll(
      'input[type="checkbox"].deletcategsss'
    );

    // Filter visible checkboxes
    let visibleCheckboxes = Array.from(totalCheckboxes).filter(
      (checkbox) => checkbox.closest('tr').style.display !== 'none'
    );
    let checkedVisibleCheckboxes = Array.from(checkboxes).filter(
      (checkbox) => checkbox.closest('tr').style.display !== 'none'
    );

    if (checkedVisibleCheckboxes.length >= 2) {
      document.getElementById('selectalldivsss').style.visibility = 'visible';
    } else {
      document.getElementById('selectalldivsss').style.visibility = 'hidden';
    }

    if (checkedVisibleCheckboxes.length >= 1) {
      document.getElementById('deletesss').style.visibility = 'visible';
      document.getElementById('deletecountsss').style.visibility = 'visible';
      document.getElementById('deletecountsss').style.background =
        'linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)';

      let count = checkedVisibleCheckboxes.length;
      let msg = document.getElementById('deletecountsss');
      msg.innerHTML = `<span> ${count} Record${
        count > 1 ? 's' : ''
      } selected </span>`;

      if (count === 0) {
        document.getElementById('deletesss').style.visibility = 'hidden';
        document.getElementById('deletecountsss').style.visibility = 'hidden';
      }
    } else {
      document.getElementById('deletesss').style.visibility = 'hidden';
      document.getElementById('deletecountsss').style.visibility = 'hidden';
    }

    let allChecked = visibleCheckboxes.every((checkbox) => checkbox.checked);
    document.getElementById('desigidcheck').checked = allChecked;

    console.log(
      'Number of checked checkboxes:',
      checkedVisibleCheckboxes.length
    );
  }

  handleTrainersCheckbox() {
    let checkboxes = document.querySelectorAll(
      'input[type="checkbox"].deletcategs:checked'
    );
    let totalCheckboxes = document.querySelectorAll(
      'input[type="checkbox"].deletcategs'
    );
    let visibleCheckboxes = Array.from(totalCheckboxes).filter(
      (checkbox) => checkbox.closest('tr').style.display !== 'none'
    );
    let checkedVisibleCheckboxes = Array.from(checkboxes).filter(
      (checkbox) => checkbox.closest('tr').style.display !== 'none'
    );

    if (checkedVisibleCheckboxes.length >= 2) {
      document.getElementById('selectalldivs').style.visibility = 'visible';
    } else {
      document.getElementById('selectalldivs').style.visibility = 'hidden';
    }

    if (checkedVisibleCheckboxes.length >= 1) {
      document.getElementById('deletes').style.visibility = 'visible';
      document.getElementById('deletecounts').style.visibility = 'visible';
      document.getElementById('deletecounts').style.background =
        'linear-gradient(135deg, #ce9ffc00, #a6f2ddf7)';

      let count = checkedVisibleCheckboxes.length;
      let msg = document.getElementById('deletecounts');
      msg.innerHTML = `<span> ${count} Record${
        count > 1 ? 's' : ''
      } selected </span>`;

      if (count === 0) {
        document.getElementById('deletes').style.visibility = 'hidden';
        document.getElementById('deletecounts').style.visibility = 'hidden';
      }
    } else {
      document.getElementById('deletes').style.visibility = 'hidden';
      document.getElementById('deletecounts').style.visibility = 'hidden';
    }

    let allChecked = visibleCheckboxes.every((checkbox) => checkbox.checked);
    document.getElementById('checkAllTrainers').checked = allChecked;

    console.log(
      'Number of checked checkboxes:',
      checkedVisibleCheckboxes.length
    );
  }

  checkChange = (event) => {
    this.handlecheckbox();

    const { checked, value } = event.target;

    // Update the checkbox state
    if (checked) {
      this.selectedFileValues.push(value); // Add value to selected values
    } else {
      const index = this.selectedFileValues.indexOf(value);
      if (index !== -1) {
        this.selectedFileValues.splice(index, 1); // Remove value from selected values
      }
    }

    // Log the selected values
    console.log('SelectedDocumentValue', this.selectedValues);

    const checkboxes = document.querySelectorAll('.deletcateg');

    // Check if any checkbox is checked
    const anyChecked = Array.from(checkboxes).some(
      (checkbox) =>
        checkbox.checked && checkbox.closest('tr').style.display !== 'none'
    );

    // Get the delete button
    const deleteButton = document.getElementById('delete');

    // Set visibility of delete button based on whether any checkbox is checked
    deleteButton.style.visibility = anyChecked ? 'visible' : 'hidden';
  };

  checkChangeDesignation = (event) => {
    this.handleDesignationCheckbox();

    const { name, checked, value } = event.target;
    if (checked) {
      this.selectedDesignationValues.push(value); // Add value to selected values
    } else {
      const index = this.selectedDesignationValues.indexOf(value);
      if (index !== -1) {
        this.selectedDesignationValues.splice(index, 1); // Remove value from selected values
      }
    }

    const checkboxes = document.querySelectorAll('.deletcategsss');

    // Check if any checkbox is checked
    const anyChecked = Array.from(checkboxes).some(
      (checkbox) =>
        checkbox.checked && checkbox.closest('tr').style.display !== 'none'
    );

    // Get the delete button
    const deleteButton = document.getElementById('deletesss');

    // Set visibility of delete button based on whether any checkbox is checked
    deleteButton.style.visibility = anyChecked ? 'visible' : 'hidden';
  };

  checkChangePopDesignation = (event) => {
    //this.handleDesignationCheckbox();

    const { name, checked, value } = event.target;
    // value1.indexOf(name) === -1
    //   ? value1.push(name)
    //   : value1.splice(value1.indexOf(name), 1);

    this.setState((prevState) => {
      const DesignationPopCheckBoxValue = checked
        ? [...prevState.DesignationPopCheckBoxValue, value]
        : prevState.DesignationPopCheckBoxValue.filter((val) => val !== value);

      console.log('SelectedDesignation', DesignationPopCheckBoxValue);
      return { DesignationPopCheckBoxValue };
    });
  };

  checkChangePopTrainers = (event) => {
    //this.handleDesignationCheckbox();

    const { name, checked, value } = event.target;
    // value1.indexOf(name) === -1
    //   ? value1.push(name)
    //   : value1.splice(value1.indexOf(name), 1);

    this.setState((prevState) => {
      const TrainerPopCheckBoxValue = checked
        ? [...prevState.TrainerPopCheckBoxValue, value]
        : prevState.TrainerPopCheckBoxValue.filter((val) => val !== value);

      console.log('SelectedDesignation', TrainerPopCheckBoxValue);
      return { TrainerPopCheckBoxValue };
    });
  };

  checkChangeTrainers = (event) => {
    this.handleTrainersCheckbox();

    const { checked, value, name } = event.target;

    // Update the checkbox state
    if (checked) {
      this.selectedTrainerValues.push(name); // Add value to selected values
    } else {
      const index = this.selectedTrainerValues.indexOf(name);
      if (index !== -1) {
        this.selectedTrainerValues.splice(index, 1); // Remove value from selected values
      }
    }

    if (checked) {
      this.selectedTrainerValues1.push(value); // Add value to selected values
    } else {
      const index = this.selectedTrainerValues1.indexOf(value);
      if (index !== -1) {
        this.selectedTrainerValues1.splice(index, 1); // Remove value from selected values
      }
    }

    const checkboxes = document.querySelectorAll('.deletcategs');

    // Check if any checkbox is checked
    const anyChecked = Array.from(checkboxes).some(
      (checkbox) =>
        checkbox.checked && checkbox.closest('tr').style.display !== 'none'
    );

    // Get the delete button
    const deleteButton = document.getElementById('deletes');

    // Set visibility of delete button based on whether any checkbox is checked
    deleteButton.style.visibility = anyChecked ? 'visible' : 'hidden';
  };

  componentWillUnmount() {
    this.setState({
      // Reset the state to its initial values here
      selectedCheckboxValues: [],
    });
    this.setState({
      // Reset the state to its initial values here
      selectedCheckboxValues2: [],
    });

    this.setState({
      // Reset the state to its initial values here
      selectedCheckboxValues3: [],
    });
  }

  GetDesignation() {
    let url = `${BASE_URLs}Designation/GetMasterDesignation`;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        let final = data.filter(
          (obj) => !this.state.AddedDesignationNames.includes(obj.designation)
        );

        console.log(final.length);

        final = final.map((e) => {
          return { ...e, ...{ check: false } };
        });

        this.setState({ Designation: final });

        this.setState({
          // Designation: data,
          Desi: data,
        });
        //
      });
  }
  handleTrainerChange = (event, s) => {
    let selectedID = s
      .map((d) => {
        if (event.includes(d.trainerName)) {
          return d.trainer_UserId;
        }
      })
      .filter(function (x) {
        return x !== undefined;
      });
    this.setState({ ...(this.state.selectedTrainer = event) });
    this.setState({ ...(this.state.selectedTrainerID = selectedID) });
  };
  DeleteTrainer = (array, id) => {
    console.log('fffffffff', array, id);
    let changes = this.state.Trainers.map((e, index) => {
      if (e.trainer_UserId === id) return { ...e, ...{ check: false } };
      return { ...e };
    });
    this.setState({ selectedTrainer: changes.filter((e) => e.check === true) });
    this.setState({ Trainers: changes });
  };
  GetAddedDesignation() {
    let url = `${BASE_URLs}Training/GetAddedDesignation?id=${this.props.location.state.training_Id}`;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + accessToken,
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Headers': 'Access-Control-Request-Headers ',
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          AddedDesignation: data,
        });
        this.setState({
          AddedDesignationNames: data.map((e) => e.designation),
        });
        console.log('AddedDesignation', data);
        //
      });
  }

  // handleButtonClicks = async () => {

  //   const { selectedCheckboxValues } = this.state;

  //   for (const value of selectedCheckboxValues) {

  //     var index0 = value.split('$$$')[0]
  //     var index1 = value.split('$$$')[1]

  //     try {
  //       const response = await fetch(`https://trainingapi.oneawesometeam.com/api/Training/AddMultipleDesignation?Designation=${index1}&DesignationId=${index0}&TrainingId=${this.props.location.state.training_Id}`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ value }),
  //       });

  //       if (response.ok) {
  //         console.log(`Posted value: ${value}`);
  //       } else {
  //         console.error(`Failed to post value: ${value}`);
  //       }
  //     } catch (error) {
  //       console.error(`Error posting value: ${value}`, error);
  //     }
  //   }
  // };

  // handleSelectedRows = () => {
  //   // Do something with this.state.selectedRows

  //   console.log(this.state.selectedRows);
  // };
  fetchDesigData = async (desigantion, desigantionId, trainingId) => {
    console.log('TrainerId and Topic', desigantion);

    console.log('TrainerId and Topic', desigantionId);

    console.log('TrainerId and Topic', trainingId);

    const data = new FormData();

    let dd = this.state.DesignationPopCheckBoxValue.forEach((e) => {
      let split = e.split('$$$');

      data.append('Designation', split[1]);
      data.append('DesignationId', split[0]);
      data.append('TrainingId', this.props.location.state.training_Id);
    });

    for (const [key, value] of data.entries()) {
      console.log('FormData', key, value);
    }
    var is_selected = [];

    const url = `${BASE_URLs}Training/AddMultipleDesignation`;

    console.log(url);

    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      body: data,
    };
    const response = await fetch(url, options);
    const result = await response.json();

    if (result.status === 'Success') {
      toast.success(result.message, {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(result.message, {
        position: 'top-center',
        autoClose: 3200,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    this.GetAddedDesignation();

    this.setState({ opens: false });
  };

  DeleteDesignation = (array, id) => {
    console.log('fff', id, this.state.Designation);
    let changes = this.state.Designation.map((e) => {
      if (e.id === id) {
        return { ...e, ...{ check: false } };
      }
      return { ...e };
    });

    this.setState({
      Designation: changes,

      selectedDesignation: changes.filter((e) => e.check === true),
    });

    let arr = changes.filter((e) => e.check === true).map((e) => e.designation);
    let final = changes.map((obj) => {
      if (!arr.includes(obj.designation)) {
        return { ...obj, ...{ check: false } };
      }
      return { ...obj };
    });
    this.setState({ Designation: final });
  };

  render() {
    const { selectedFiles, selectAll } = this.state;
    const { files } = this.props;
    const { showInput } = this.state;
    const { isChecked, defaultValue } = this.state;

    var w = window.innerWidth;
    const {
      Training,
      Departmentdrop,
      enabled,
      FileDocs,
      TrainingsName,
      Trainers,
      TrainersName,
      Designation,
      AddedDesignation,
      selectedFiless,
      isAddTrainerOpen,
      selectedTrainer,
      TrainingItem,
      Dpt,
      InDrop,
      InHourDrop,
      Options,
    } = this.state;
    const { TrainingName } = this.state.formErrors;

    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                <div className="page-body">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="glowBox">
                          <div style={{ float: 'left' }}>
                            <a href onClick={() => this.props.history.goBack()}>
                              <i
                                class="icofont icofont-arrow-left"
                                style={{ fontSize: '45px', color: 'white' }}
                              />
                            </a>{' '}
                          </div>
                          {Training.map((Training) => (
                            <h4 style={{ marginRight: '3%' }} className="title">
                              {/* {Training.trainingitem} */}
                              {TrainingItem}
                            </h4>
                          ))}
                        </div>
                        <div className="card-header-right"></div>

                        <div className="card-block2">
                          <form
                            autoComplete="off"
                            encType="multipart/form-data"
                            onSubmit={this.handleSubmit}
                          >
                            <div className="row" style={{ marginTop: '3%' }}>
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12">
                                    <div className="container">
                                      <div
                                        className="row"
                                        style={{ padding: '8px' }}
                                      >
                                        {Training.map((Training) => (
                                          <div>
                                            <input
                                              type="hidden"
                                              name="Training_Id"
                                              defaultValue={
                                                Training.training_Id
                                              }
                                            />
                                          </div>
                                        ))}
                                        <div className="col-sm-6">
                                          {Training.map((training, index) =>
                                            !InDrop ? (
                                              <input
                                                key={index}
                                                className="effect-16"
                                                name="Department"
                                                id={`department-${index}`}
                                                value={training.department}
                                                type="text"
                                                maxLength="50"
                                                onMouseEnter={(e) =>
                                                  this.setState({
                                                    InDrop: true,
                                                  })
                                                }
                                                style={{ marginLeft: '-22px' }}
                                                onChange={(e) =>
                                                  this.handleInputChange(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ) : (
                                              <select
                                                key={index}
                                                className="effect-16"
                                                id={`department-${index}`}
                                                defaultValue={
                                                  training.department
                                                }
                                                name="Department"
                                                type="text"
                                                onBlur={(e) =>
                                                  this.setState({
                                                    InDrop: false,
                                                  })
                                                }
                                                style={{ marginLeft: '-22px' }}
                                                onChangeCapture={(e) =>
                                                  this.handleInputChangedep(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                              >
                                                <option value="">Select</option>

                                                {Departmentdrop.map(
                                                  (department) =>
                                                    Training.department ===
                                                    department.department ? (
                                                      <option
                                                        key={
                                                          department.department
                                                        }
                                                        selected
                                                        value={
                                                          department.department
                                                        }
                                                      >
                                                        {department.department}
                                                      </option>
                                                    ) : (
                                                      <option
                                                        key={
                                                          department.department
                                                        }
                                                        value={
                                                          department.department
                                                        }
                                                      >
                                                        {department.department}
                                                      </option>
                                                    )
                                                )}
                                              </select>
                                            )
                                          )}
                                          <span
                                            style={{
                                              color: '#a29d9d',
                                              marginLeft: '-22px',
                                            }}
                                          >
                                            Department
                                          </span>{' '}
                                          <span style={{ color: 'red' }}>
                                            *
                                          </span>
                                        </div>
                                        <div className="col-sm-6">
                                          {/* {Training.map((Training) => (
                                            <input
                                              className="effect-16"
                                              name="Trainingitem"
                                              id="Trainingitem"
                                              defaultValue={
                                                Training.trainingitem
                                              }
                                              type="text"
                                              maxLength="50"
                                            />
                                          ))} */}
                                          {this.state.Training.map(
                                            (training, index) => (
                                              <input
                                                key={index}
                                                className="effect-16"
                                                name="Trainingitem"
                                                id={`Trainingitem-${index}`}
                                                value={training.trainingitem}
                                                type="text"
                                                maxLength="50"
                                                onChange={(e) =>
                                                  this.handleInputChange(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                                style={{ marginLeft: '20px' }}
                                              />
                                            )
                                          )}
                                          <span
                                            style={{
                                              color: '#a29d9d',
                                              marginLeft: '22px',
                                            }}
                                          >
                                            Training Topic
                                          </span>{' '}
                                          <span style={{ color: 'red' }}>
                                            *
                                          </span>
                                        </div>
                                        <div
                                          className="col-sm-6"
                                          style={{ marginTop: '20px' }}
                                        >
                                          {this.state.Training.map(
                                            (training, index) => (
                                              <textarea
                                                key={index}
                                                className="effect-16"
                                                name="description"
                                                id={`description-${index}`}
                                                value={training.description}
                                                type="text"
                                                onChange={(e) =>
                                                  this.handleInputChangedes(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                                style={{
                                                  marginLeft: '-22px',
                                                  height: '42px',
                                                }}
                                              />
                                            )
                                          )}
                                          {/* {Training.map((Training) => (
                                            <textarea
                                              className="effect-16"
                                              name="description"
                                              defaultValue={
                                                Training.description
                                              }
                                              id="description"
                                              type="text"
                                              //maxLength="50"
                                            />
                                          ))} */}
                                          <span
                                            style={{
                                              color: '#a29d9d',
                                              marginLeft: '-22px',
                                            }}
                                          >
                                            Training Description
                                          </span>{' '}
                                          <span style={{ color: 'red' }}>
                                            *
                                          </span>
                                        </div>

                                        <div className="col-sm-6">
                                          <br></br>
                                          {/* {Training.map((Training) => (
                                             
                                            <select
                                            className="effect-16"
                                            name="Duration"
                                            id="duration"
                                            type="text"
                                          >
                                            <option   value= {Training.duration}> {Training.duration}</option>
                                            
                                            <option value="1 Hour" id="duration">
                                              1 Hour
                                            </option>
                                            <option value="1.5 Hour" id="duration">
                                              1.5 Hour
                                            </option>
                                            <option value="2 Hours" id="duration">
                                              2 Hours
                                            </option>
                                            <option value="2.5 Hour" id="duration">
                                              2.5 Hour
                                            </option>
                                            <option value="3 Hours" id="duration">
                                              3 Hours
                                            </option>
                                            <option value="3.5 Hour" id="duration">
                                              3.5 Hour
                                            </option>
                                            <option value="4 Hours" id="duration">
                                              4 Hours
                                            </option>
                                            <option value="4.5 Hour" id="duration">
                                              4.5 Hour
                                            </option>
                                            <option value="5 Hours" id="duration">
                                              5 Hours
                                            </option>
                                            <option value="5.5 Hour" id="duration">
                                              5.5 Hour
                                            </option>
                                            <option value="6 Hours" id="duration">
                                              6 Hours
                                            </option>
                                            <option value="6.5 Hour" id="duration">
                                              6.5 Hour
                                            </option>
                                            <option value="7 Hours" id="duration">
                                              7 Hours
                                            </option>
                                            <option value="7.5 Hour" id="duration">
                                              7.5 Hour
                                            </option>
                                            <option value="8 Hours" id="duration">
                                              8 Hours
                                            </option>
                                            <option value="8.5 Hour" id="duration">
                                              8.5 Hour
                                            </option>
                                            <option value="9 Hour" id="duration">
                                              9 Hour
                                            </option>
                                            <option value="9.5 Hour" id="duration">
                                              9.5 Hour
                                            </option>
                                          </select>
                                          ))} */}
                                          {Training.map((training, index) =>
                                            !InHourDrop ? (
                                              <input
                                                key={index}
                                                className="effect-16"
                                                name="Duration"
                                                id={`duration-${index}`}
                                                value={training.duration}
                                                type="text"
                                                maxLength="50"
                                                onMouseEnter={(e) =>
                                                  this.setState({
                                                    InHourDrop: true,
                                                  })
                                                }
                                                style={{ marginLeft: '20px' }}
                                                onChange={(e) =>
                                                  this.handleInputChange(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            ) : (
                                              <select
                                                key={index}
                                                className="effect-16"
                                                //id='department'
                                                id={`duration-${index}`}
                                                defaultValue={training.duration}
                                                name="Duration"
                                                type="text"
                                                onBlur={(e) =>
                                                  this.setState({
                                                    InHourDrop: false,
                                                  })
                                                }
                                                style={{ marginLeft: '20px' }}
                                                onChange={(e) =>
                                                  this.handleInputChangehour(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                                // value={this.state.selectedValue}
                                                //onChangeCapture={this.handleDropdownChange}
                                              >
                                                <option
                                                  hidden
                                                  value={Training.duration}
                                                >
                                                  {' '}
                                                  {Training.duration}
                                                </option>

                                                <option
                                                  value="1 Hour"
                                                  id="duration"
                                                >
                                                  1 Hour
                                                </option>
                                                <option
                                                  value="1.5 Hour"
                                                  id="duration"
                                                >
                                                  1.5 Hours
                                                </option>
                                                <option
                                                  value="2 Hours"
                                                  id="duration"
                                                >
                                                  2 Hours
                                                </option>
                                                <option
                                                  value="2.5 Hour"
                                                  id="duration"
                                                >
                                                  2.5 Hours
                                                </option>
                                                <option
                                                  value="3 Hours"
                                                  id="duration"
                                                >
                                                  3 Hours
                                                </option>
                                                <option
                                                  value="3.5 Hour"
                                                  id="duration"
                                                >
                                                  3.5 Hours
                                                </option>
                                                <option
                                                  value="4 Hours"
                                                  id="duration"
                                                >
                                                  4 Hours
                                                </option>
                                                <option
                                                  value="4.5 Hour"
                                                  id="duration"
                                                >
                                                  4.5 Hours
                                                </option>
                                                <option
                                                  value="5 Hours"
                                                  id="duration"
                                                >
                                                  5 Hours
                                                </option>
                                                <option
                                                  value="5.5 Hour"
                                                  id="duration"
                                                >
                                                  5.5 Hours
                                                </option>
                                                <option
                                                  value="6 Hours"
                                                  id="duration"
                                                >
                                                  6 Hours
                                                </option>
                                                <option
                                                  value="6.5 Hour"
                                                  id="duration"
                                                >
                                                  6.5 Hours
                                                </option>
                                                <option
                                                  value="7 Hours"
                                                  id="duration"
                                                >
                                                  7 Hours
                                                </option>
                                                <option
                                                  value="7.5 Hour"
                                                  id="duration"
                                                >
                                                  7.5 Hours
                                                </option>
                                                <option
                                                  value="8 Hours"
                                                  id="duration"
                                                >
                                                  8 Hours
                                                </option>
                                                <option
                                                  value="8.5 Hour"
                                                  id="duration"
                                                >
                                                  8.5 Hours
                                                </option>
                                                <option
                                                  value="9 Hour"
                                                  id="duration"
                                                >
                                                  9 Hours
                                                </option>
                                                <option
                                                  value="9.5 Hour"
                                                  id="duration"
                                                >
                                                  9.5 Hours
                                                </option>
                                              </select>
                                            )
                                          )}
                                          <span
                                            style={{
                                              color: '#a29d9d',
                                              marginLeft: '22px',
                                            }}
                                          >
                                            Duration Of Training
                                          </span>{' '}
                                          <span style={{ color: 'red' }}>
                                            *
                                          </span>
                                        </div>

                                        <div className="col-sm-6"></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="container">
                              <div className="row">
                                {/* Documents */}
                                <div className="col-lg-4">
                                  <div
                                    style={{
                                      margin: '10px',
                                      padding: '20px',
                                      overflow: 'auto',
                                      height: '250px',
                                      backgroundColor: 'white',
                                      borderTop: '5px solid whitesmoke',
                                      borderBottom: '5px solid whitesmoke',
                                      borderLeft: '5px solid whitesmoke',
                                      borderRight: '5px solid whitesmoke',
                                      boxShadow: '10px 10px 5px lightblue',
                                    }}
                                  >
                                    <button
                                      type="button"
                                      onClick={this.handleButtonsClick}
                                      style={{ width: '40px', height: '30px' }}
                                    >
                                      <PlusOne />
                                    </button>
                                    <input
                                      id="Ref_Docs"
                                      hidden
                                      onChange={this.handleFileChange}
                                      multiple
                                      type="file"
                                      ref={this.fileInputRef}
                                    />

                                    <span
                                      id="deletecount"
                                      className="multitrash"
                                      style={{
                                        visibility: 'hidden',
                                        fontSize: '17px',
                                      }}
                                    ></span>
                                    <br />

                                    <span>
                                      &nbsp;Note: Filename max 25 characters.
                                    </span>

                                    <div
                                      id="selectalldiv"
                                      style={{ visibility: 'hidden' }}
                                    >
                                      &nbsp;&nbsp;
                                      <button
                                        type="button"
                                        className="multitrash"
                                        id="delete"
                                        onClick={this.DeleteMutipleFiles}
                                        style={{
                                          float: 'right',
                                          visibility: 'hidden',
                                        }}
                                      >
                                        <i
                                          class="icofont icofont-delete-alt"
                                          style={{ color: 'darkgray' }}
                                        ></i>
                                      </button>
                                    </div>

                                    <table
                                      id="skillsheet"
                                      className="table nowrap SkillGrid"
                                    >
                                      <thead style={{ textAlign: 'left' }}>
                                        <tr>
                                          <th style={{ position: 'relative' }}>
                                            {this.state.FileDocs.length ===
                                            0 ? null : (
                                              <input
                                                type="checkbox"
                                                className="sampledoc"
                                                id="selectall"
                                                onClick={() => {
                                                  this.checkAll(this);
                                                }}
                                              />
                                            )}
                                          </th>
                                          <th style={{ position: 'relative' }}>
                                            Add Documents
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.FileDocs.map(
                                          (value, index) => (
                                            <tr>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  className="deletcateg"
                                                  id="selectall"
                                                  name="deletcateg"
                                                  onChange={this.checkChange}
                                                  defaultValue={value.file_id}
                                                />
                                              </td>
                                              <td>{value.file_name}</td>
                                            </tr>
                                          )
                                        )}

                                        {this.state.selectedFiless.map(
                                          (value, index) => (
                                            <tr key={index}>
                                              <td>
                                                <button
                                                  onClick={() =>
                                                    this.handleDeleteClicks(
                                                      index
                                                    )
                                                  }
                                                  type="button"
                                                  className="button-30 sk-del"
                                                  id="delete"
                                                >
                                                  <i
                                                    class="fa fa-trash-o"
                                                    style={{ color: 'red' }}
                                                  ></i>
                                                </button>
                                              </td>

                                              <td>{value.name}</td>

                                              {/* <td>
                                                <BookmarkAddIcon
                                                  style={{ color: "grey" }}
                                                />
                                              </td> */}
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                {/* Trainers */}
                                <div className="col-lg-4">
                                  <div
                                    style={{
                                      margin: '10px',
                                      padding: '20px',
                                      overflow: 'auto',
                                      height: '250px',
                                      backgroundColor: 'white',
                                      borderTop: '5px solid whitesmoke',
                                      borderBottom: '5px solid whitesmoke',
                                      borderLeft: '5px solid whitesmoke',
                                      borderRight: '5px solid whitesmoke',
                                      boxShadow: '10px 10px 5px lightblue',
                                    }}
                                  >
                                    {/* Rest of the code */}
                                    <button
                                      style={{ width: '40px', height: '30px' }}
                                      onClick={(e) =>
                                        this.openAddTrainerPopup(e)
                                      }
                                      type="button"
                                    >
                                      {' '}
                                      <Add />
                                    </button>
                                    <span
                                      id="deletecounts"
                                      className="multitrash"
                                      style={{
                                        visibility: 'hidden',
                                        fontSize: '17px',
                                      }}
                                    ></span>
                                    <div
                                      id="selectalldivs"
                                      style={{ visibility: 'hidden' }}
                                    >
                                      &nbsp;&nbsp;
                                      <button
                                        className="multitrash"
                                        type="button"
                                        id="deletes"
                                        onClick={this.DeleteMutipleTrainers}
                                        style={{
                                          float: 'right',
                                          visibility: 'hidden',
                                        }}
                                      >
                                        <i
                                          class="icofont icofont-delete-alt"
                                          style={{ color: 'darkgray' }}
                                        ></i>
                                      </button>
                                    </div>
                                    <table
                                      id="skillsheet"
                                      className="table   nowrap SkillGrid"
                                    >
                                      <thead style={{ textAlign: 'left' }}>
                                        <tr>
                                          <th style={{ position: 'relative' }}>
                                            {' '}
                                            {this.state.TrainersName.length ===
                                            0 ? null : (
                                              <input
                                                type="checkbox"
                                                id="checkAllTrainers"
                                                className="sample"
                                                onClick={() => {
                                                  this.checkAllTrainers(this);
                                                }}
                                              />
                                            )}{' '}
                                          </th>
                                          <th style={{ position: 'relative' }}>
                                            Assign Trainers &nbsp;&nbsp;
                                          </th>

                                          {/* <th style={{ textAlign: "center" }}>
                                          Actions
                                        </th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.TrainersName.map(
                                          (value) => (
                                            <tr>
                                              <td
                                                style={{ position: 'relative' }}
                                              >
                                                {' '}
                                                <input
                                                  type="checkbox"
                                                  className="deletcategs"
                                                  name={value.userId}
                                                  defaultValue={value.id}
                                                  id="checkAllTrainers"
                                                  onChange={
                                                    this.checkChangeTrainers
                                                  }
                                                />
                                              </td>
                                              <td>{value.trainerName}</td>
                                              {/* <td style={{ textAlign: "center" }}>
                                            <button
                                              name="delete"
                                              className="button-30 sk-del"
                                              //  onClick={()=>this.EditSkillModel(skill.id,skill.skillName)}
                                              // onClick={(e) => {
                                              //   e.preventDefault();
                                              //   this.DeleteButton(
                                              //     FileDocs.upload_id,
                                              //   );
                                              // }}
                                            >
                                              <i
                                                class="fa fa-trash-o"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </td> */}
                                            </tr>
                                          )
                                        )}

                                        {this.state.selectedTrainer.map(
                                          (value, index) => (
                                            <tr key={index}>
                                              <td>
                                                <button
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.DeleteTrainer(
                                                      value,
                                                      value.trainer_UserId
                                                    );
                                                  }}
                                                  type="button"
                                                  className="button-30 sk-del"
                                                  id="deletes"
                                                >
                                                  <i
                                                    class="fa fa-trash-o"
                                                    style={{ color: 'red' }}
                                                  ></i>
                                                </button>
                                              </td>

                                              <td>{value.trainerName}</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                {/* Designation */}

                                <div className="col-lg-4">
                                  <div
                                    style={{
                                      margin: '10px',
                                      padding: '20px',
                                      overflow: 'auto',
                                      height: '250px',
                                      backgroundColor: 'white',
                                      borderTop: '5px solid whitesmoke',
                                      borderBottom: '5px solid whitesmoke',
                                      borderLeft: '5px solid whitesmoke',
                                      borderRight: '5px solid whitesmoke',
                                      boxShadow: '10px 10px 5px lightblue',
                                    }}
                                  >
                                    {/* Rest of the code */}
                                    <button
                                      style={{ width: '40px', height: '30px' }}
                                      onClick={this.openThePopups}
                                    >
                                      {' '}
                                      <Add />
                                    </button>
                                    <span
                                      id="deletecountsss"
                                      className="multitrash"
                                      style={{
                                        visibility: 'hidden',
                                        fontSize: '17px',
                                      }}
                                    ></span>
                                    <div
                                      id="selectalldivsss"
                                      style={{ visibility: 'hidden' }}
                                    >
                                      &nbsp;&nbsp;
                                      <button
                                        className="multitrash"
                                        id="deletesss"
                                        type="button"
                                        onClick={this.DeleteMutipleDesignation}
                                        style={{
                                          float: 'right',
                                          visibility: 'hidden',
                                        }}
                                      >
                                        <i
                                          class="icofont icofont-delete-alt"
                                          style={{ color: 'darkgray' }}
                                        ></i>
                                      </button>
                                    </div>
                                    <table
                                      id="skillsheet"
                                      className="table   nowrap SkillGrid"
                                    >
                                      <thead style={{ textAlign: 'left' }}>
                                        <tr>
                                          <th style={{ position: 'relative' }}>
                                            {' '}
                                            {this.state.AddedDesignation
                                              .length === 0 ? null : (
                                              <input
                                                type="checkbox"
                                                id="desigidcheck"
                                                className="sampledes"
                                                onClick={() => {
                                                  this.checkAllDesignation(
                                                    this
                                                  );
                                                }}
                                              />
                                            )}{' '}
                                          </th>
                                          <th style={{ position: 'relative' }}>
                                            Assign to Designation &nbsp;&nbsp;
                                          </th>

                                          {/* <th style={{ textAlign: "center" }}>
                                          Actions
                                        </th> */}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.AddedDesignation.map(
                                          (value) => (
                                            <tr>
                                              <td>
                                                {' '}
                                                <input
                                                  type="checkbox"
                                                  //className="deletcategsss"
                                                  name="deletcategsss"
                                                  className="deletcategsss"
                                                  id="desigidcheck"
                                                  defaultValue={value.desigId}
                                                  onChange={
                                                    this.checkChangeDesignation
                                                  }
                                                />
                                              </td>
                                              <td>{value.designation}</td>
                                              {/* <td style={{ textAlign: "center" }}>
                                            <button
                                              name="delete"
                                              className="button-30 sk-del"
                                              //  onClick={()=>this.EditSkillModel(skill.id,skill.skillName)}
                                              // onClick={(e) => {
                                              //   e.preventDefault();
                                              //   this.DeleteButton(
                                              //     FileDocs.upload_id,
                                              //   );
                                              // }}
                                            >
                                              <i
                                                class="fa fa-trash-o"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </td> */}
                                            </tr>
                                          )
                                        )}
                                        {this.state.selectedDesignation.map(
                                          (value) => (
                                            <tr>
                                              {/* <td>
                                                <SchoolIcon
                                                  style={{ color: "grey" }}
                                                />
                                              </td> */}
                                              <td
                                                style={{ textAlign: 'center' }}
                                              >
                                                <button
                                                  type="button"
                                                  name="delete"
                                                  className="button-30 sk-del"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    this.DeleteDesignation(
                                                      value,
                                                      value.id
                                                    );
                                                  }}
                                                >
                                                  <i
                                                    class="fa fa-trash-o"
                                                    style={{ color: 'red' }}
                                                  ></i>
                                                </button>
                                              </td>

                                              <td>{value.designation}</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <div className="row">
                                <div
                                  className="col-sm-12"
                                  style={{ textAlign: 'center' }}
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <button
                                    type="submit"
                                    className="but"
                                    style={{ height: '39.5px' }}
                                  >
                                    Save
                                  </button>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <button
                                    type="reset"
                                    className=" but"
                                    style={{ height: '39.5px' }}
                                    //onClick={this.clearInputFields}
                                    onClick={() => this.handleCancelClick()}
                                  >
                                    Cancel
                                  </button>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                              </div>
                            </div>
                          </form>
                          <br />
                        </div>

                        {/* <div
                            class="flex-container"
                            style={{
                              display: "flex",
                              background:
                                "linear-gradient(135deg, #a45bec, #7367f0)",
                            }}
                          >
                            
                            <div
                              style={{
                                margin: "10px",
                                width: "50%",
                                padding: "20px",
                                overflow: "auto",
                                backgroundColor: "white",
                                borderTop: "5px solid whitesmoke",
                                borderBottom: "5px solid whitesmoke",
                                borderLeft: "5px solid whitesmoke",
                                borderRight: "5px solid whitesmoke",
                                boxShadow: "10px 10px 5px lightblue",
                              }}
                            >
     
                              <label
                                for="Ref_Docs"
                                style={{
                                  height: "30.5px",
                                  width: "10%",
                                  padding: "4px 12px",
                                  marginRight: "400px",
                                }}
                                className=" btn-Secondary"
                              >
                                <PlusOne style={{fontWeight:"bold",color:"black"}}/>
                              </label>

                              <input
                                id="Ref_Docs"
                                hidden
                                // onChange={this.handleSelectChange}
                                name="Ref_Docs"
                                multiple
                                type="file"
                              />
                              <span
                                id="deletecount"
                                className="multitrash"
                                style={{
                                  visibility: "hidden",
                                  fontSize: "17px",
                                }}
                              ></span>
<div id="selectalldiv" style={{visibility:"hidden"}}>
          &nbsp;&nbsp;
        <button type="submit" className="multitrash" id="delete" style={{float:"right",visibility:"hidden"}}><i class="icofont icofont-delete-alt" style={{ color: "darkgray" }}></i></button> 

        </div>

                              <table
                                id="skillsheet"
                                className="table   nowrap SkillGrid"
                              >
                                <thead style={{ textAlign: "left" }}>
                                  <tr>
                                    <th>
                                      {" "}
                                      <input
                                        type="checkbox"
                                        id="selectall"
                                        onClick={() => {
                                          this.checkAll(this);
                                        }}
                                      />{" "}
                                    </th>
                                    <th>Documents &nbsp;&nbsp;</th>

                                    <th style={{ textAlign: "center" }}>
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {FileDocs.map((FileDocs) => (
                                    <tr>
                                      <td>
                                        {" "}
                                        <input
                                          type="checkbox"
                                          name="deletcateg"
                                          defaultValue={FileDocs.upload_id}
                                          onChange={this.handlecheckbox}
                                        />
                                      </td>
                                      <td>{FileDocs.file_name}</td>
                                      <td style={{ textAlign: "center" }}>
                                        <button
                                          name="delete"
                                          className="button-30 sk-del"
                                          //  onClick={()=>this.EditSkillModel(skill.id,skill.skillName)}
                                          // onClick={(e) => {
                                          //   e.preventDefault();
                                          //   this.DeleteButton(
                                          //     FileDocs.upload_id,
                                          //   );
                                          // }}
                                        >
                                          <i
                                            class="fa fa-trash-o"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div
                              style={{
                                // backgroundColor: "#f1f1f1",
                                margin: "10px",
                                width: "50%",
                                padding: "20px",
                                overflow: "auto",
                                backgroundColor: "white",
                                borderTop: "5px solid whitesmoke",
                                borderBottom: "5px solid whitesmoke",
                                borderLeft: "5px solid whitesmoke",
                                borderRight: "5px solid whitesmoke",
                                boxShadow: "10px 10px 5px lightblue",
                              }}
                            >
                              <span
                                id="deletecounts"
                                className="multitrash"
                                style={{
                                  visibility: "hidden",
                                  fontSize: "17px",
                                }}
                              ></span>
 <div id="selectalldivs" style={{visibility:"hidden"}}>
          &nbsp;&nbsp;
          <button type="submit" className="multitrash" id="deletes" style={{float:"right",visibility:"hidden"}}><i class="icofont icofont-delete-alt" style={{ color: "darkgray" }}></i></button> 

        </div>
                              <table
                                id="skillsheet"
                                className="table   nowrap SkillGrid"
                              >
                                <thead style={{ textAlign: "left" }}>
                                  <tr>
                                    <th>
                                      {" "}
                                      <input
                                        type="checkbox"
                                        id="checkAllTrainers"
                                        onClick={() => {
                                          this.checkAllTrainers(this);
                                        }}
                                      />{" "}
                                    </th>
                                    <th>Trainers &nbsp;&nbsp;</th>

                                    <th style={{ textAlign: "center" }}>
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Trainers.map((Trainers) => (
                                    <tr>
                                      <td>
                                        {" "}
                                        <input
                                          type="checkbox"
                                          name="deletcategs"
                                          // defaultValue={FileDocs.upload_id}
                                          onChange={this.handleTrainersCheckbox}
                                        />
                                      </td>
                                      <td>{Trainers.trainerName}</td>
                                      <td style={{ textAlign: "center" }}>
                                        <button
                                          name="delete"
                                          className="button-30 sk-del"
                                          //  onClick={()=>this.EditSkillModel(skill.id,skill.skillName)}
                                          // onClick={(e) => {
                                          //   e.preventDefault();
                                          //   this.DeleteButton(
                                          //     FileDocs.upload_id,
                                          //   );
                                          // }}
                                        >
                                          <i
                                            class="fa fa-trash-o"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div> */}

                        {/* {FileDocs.map(FileDocs => 
                                <div class="container" 
                                style={{
                                  background: "#4CAF50",
                                  color: "white",
                                  padding: "15px",
                                  width: "50%",
                                  height: "100px",
                                  overFlow: "auto",
                                  border: "1px solid #ccc",
                                }}
                                >

    {FileDocs.file_name} 
    </div>)} */}

                        {/* <div style={{float: "left", width: "50%"}}>Left Div</div>
    <div style={{float: "right", width: "50%"}}>Right Div</div> */}

                        <div className="card-block">
                          <div className="dt-responsive table-responsive table-res">
                            {/* {this.state.Training.length === 0 ? null :

                              <table id="DepSheet" className="table nowrap" style={{ maxWidth: "90%" }}>
                                <thead>
                                  <tr>
                                    <th>Reference Document</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {FileDocs.map(FileDocs => <tr>
                                    <td>{FileDocs.file_name}</td>
                                  </tr>)}
                                </tbody>
                              </table>
                            } */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.isOpen && (
          <div className="pop">
            <div className="popinnernormal">
              <form autoComplete="off" encType="multipart/form-data">
                {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}} onClick={this.props.close}  ><CloseIcon/></button> */}
                <button
                  style={{
                    float: 'right',
                    backgroundColor: '#a0a3a305',
                    color: '#797070',
                    border: '1px solid #a3a5a505',
                  }}
                  onClick={this.closePopup}
                >
                  <CloseIcon />
                </button>

                <div className="row" tyle={{ padding: '8px' }}>
                  <div className="col-sm-3"></div>
                  <div className="col-sm-8">
                    <div className="dt-responsive table-responsive table-res">
                      {this.state.Training.length === 0 ? null : (
                        <table
                          id="DepSheet"
                          className="table nowrap"
                          style={{ maxWidth: '90%' }}
                        >
                          <thead>
                            <tr>
                              <th>Reference Document</th>
                            </tr>
                          </thead>
                          <tbody>
                            {FileDocs.map((FileDocs) => (
                              <tr>
                                <td>{FileDocs.file_name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-4"></div>
                </div>

                <div className="row">
                  <div
                    className="col-sm-12"
                    style={{ textAlign: 'center' }}
                  ></div>
                </div>
              </form>
              <br />
              {/* <button onClick={this.closePopup}><CloseIcon/></button> */}
            </div>
          </div>
        )}

        {this.state.isOpen && (
          <div className="pop">
            <div className="popinnernormal">
              <form autoComplete="off" encType="multipart/form-data">
                {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}} onClick={this.props.close}  ><CloseIcon/></button> */}
                <button
                  style={{
                    float: 'right',
                    backgroundColor: '#a0a3a305',
                    color: '#797070',
                    border: '1px solid #a3a5a505',
                  }}
                  onClick={this.closePopup}
                >
                  <CloseIcon />
                </button>

                <div className="row" tyle={{ padding: '8px' }}>
                  <div className="col-sm-3"></div>
                  <div className="col-sm-8">
                    <div className="dt-responsive table-responsive table-res">
                      {this.state.Training.length === 0 ? null : (
                        <table
                          id="DepSheet"
                          className="table nowrap"
                          style={{ maxWidth: '90%' }}
                        >
                          <thead>
                            <tr>
                              <th>Reference Document</th>
                            </tr>
                          </thead>
                          <tbody>
                            {FileDocs.map((FileDocs) => (
                              <tr>
                                <td>{FileDocs.file_name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-4"></div>
                </div>

                <div className="row">
                  <div
                    className="col-sm-12"
                    style={{ textAlign: 'center' }}
                  ></div>
                </div>
              </form>
              <br />
              {/* <button onClick={this.closePopup}><CloseIcon/></button> */}
            </div>
          </div>
        )}

        {isAddTrainerOpen ? (
          <AddTrainingAddTrainer
            closeThePopup={(e) => this.openAddTrainerPopup(e)}
            array={
              //  this.state.Trainers

              () => {
                const selected = selectedTrainer.map((e) => e.trainer_UserId);

                console.log('selectedselected', selected);

                const popupFilter = this.state.Trainers.map((item) => {
                  if (selected.includes(item.trainer_UserId)) {
                    return { ...item, ...{ check: true } };
                  }

                  return { ...item, ...{ check: false } };
                });

                return popupFilter;
              }
            }
            state={(state) => {
              this.setState({ Trainers: state });
              this.setState({
                selectedTrainer: state.filter((e) => e.check === true),
              });
            }}
          />
        ) : null}

        {this.state.opens ? (
          <AddTrainingAddDesignation
            closeThePopup={(e) => this.closeThePopups(e)}
            array={() => {
              const selected = this.state.selectedDesignation.map((e) => e.id);
              const popupFilter = this.state.Designation.map((item) => {
                if (selected.includes(item.id)) {
                  return { ...item, ...{ check: true } };
                }

                return item;
              });

              return popupFilter;
            }}
            state={(state) => {
              // this.setState({ Designation: state });
              // console.log(
              //   "selected",
              //   state.filter((e) => e.check === true)
              // );

              this.setState({
                Designation: state,
                selectedDesignation: state.filter((e) => e.check === true),
              });
            }}
          />
        ) : null}
      </div>
    );
  }
}
