import React, { useState, useEffect } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { BASE_URLs } from "../../Config/Base";
import CloseIcon from "@material-ui/icons/Close";
import fileImg from "../../Assets/images/document.png";

const AllAnnouncement = ({ history }) => {
  const [announcement, setAnnouncement] = useState([]);
  const [aAnnouncement, setaAnnouncement] = useState([]);
  const [allAnnouncement, setAllAnnouncement] = useState([]);
  const [announcementFilter, setAnnouncementFilter] = useState([]);
  const [delay, setdelay] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [filterPop, setFilterPop] = useState(false);
  const [letters, setLetters] = useState([]);
  const [filterData, setFilterData] = useState('');
  const [clickedContents, setClickedContents] = useState(
    JSON.parse(localStorage.getItem("clickedContents")) || {}

  );

  const handleContentClick = (id) => {
    const updatedClickedContents = { ...clickedContents, [id]: true };
    localStorage.setItem("clickedContents", JSON.stringify(updatedClickedContents));
    setClickedContents(updatedClickedContents);
  };

  const bothOnClick = (id) => {
    handleContentClick(id);
    GetAnnouncement(id);

  };

  const popupclose = () => {
    setisOpen(false)
  }

  const getAnnouncement = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(`${BASE_URLs}Announcement/GetActiveAnnounce`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAnnouncement(data);
        setAllAnnouncement(data)
        // console.log("Announcemtttt", data);
      });
    
    
  };

 const handleImageClick = (url) => {
    window.open(url, '_blank');
     };
  const GetAnnouncement = (id) => {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    // var Id = this.props.location.state.Id;
    fetch(`${BASE_URLs}Announcement/GetActiveAnnounceid?Id=` + id, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        setisOpen(true)
        setaAnnouncement(data)
        // console.log("Announcemt", data);
      
      });
  }

 const SeenUpdate = (id) => {
  
    var options = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
      // body: data
    };
    fetch(
      `${BASE_URLs}Announcement/EditPreviewannounceSeen?id=` + id,
      options
    )
      .then((res) => res.json())
      .then((resp) => {

        const options = {
          method: "GET",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        };
        fetch(`${BASE_URLs}AddOrganization/GetAdminAnnSeenCount`, options)
          .then((response) => response.json())
          .then((data) => {
            getAnnouncement();
          });

      });

};

  const announcementView = (id) => {
    const page = {
      pathname: "/AdminAnnouncmentpop",
      state: {
        Id: id,
      },
    };
    history.push(page);
  };

  const announcementPreview = () => {
    const page = {
      pathname: "/AnnouncementPreview",
      // state: {
      //   Id: id,
      // },
    };
    history.push(page);
  };

  useEffect(() => {
    getAnnouncement();

    setTimeout(() => {
      setdelay(true)
    }, 500);
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

    setLetters(alphabet);
  }, []);

  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return title.substring(0, maxLength) + "...";
    }
    return title;
  }
  const getFileType = (path) => {
    const extension = path.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) {
      return 'image';
    } else if (['mp4', 'avi', 'mov', 'wmv', 'mkv'].includes(extension)) {
      return 'video';
    } else if (['pdf', 'xls', 'xlsx'].includes(extension)) {
      return 'document';
    } else {
      return 'file';
    }
  };
  const logOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    history.push("/Login_form");
  };

  const handleFilter = (letter, event) => {
    event.preventDefault();
    const filteredAnnouncement = allAnnouncement.filter((item) => {
      return (
        item.title.charAt(0).toLowerCase().includes(letter.toLowerCase())
      );
    });
    setAnnouncement(filteredAnnouncement);
    setShowResults(true);
    setFilterData(filteredAnnouncement);
  };

  const filterfunction = (e) => {
    const filteredAnnouncement = allAnnouncement.filter(
      (item) =>
        item.title.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    setAnnouncement(filteredAnnouncement);
    setShowResults(true);
    setAnnouncementFilter(filteredAnnouncement);
  };

  return (
    <div>
      <SideBar active={window.location.hash} />

      <div className="pcoded-content">
        <div className="pcoded-inner-content">
          <div className="main-body">
            <div className="page-wrapper">
              <div className="page-header">
                <div className="page-header-title"></div>
              </div>

              <div className="page-body">
                <div className="col-sm-12">
                  {isOpen ?
                    <div className="popup" style={{ textAlign: 'left' }}>
                      <div className="popup-content">
                        <div className="Announcedetailspop" >
                          <i
                            className="fa fa-times"
                            aria-hidden="true"
                            style={{
                              color: "gray",
                              marginLeft: "90%",
                              fontSize: "17px",
                              cursor: 'pointer'
                            }}
                            onClick={popupclose}
                          ></i>
                          <br />
                          <center><h6><b>Announcement Details</b></h6></center>
                          <div className="card-block">

                          {aAnnouncement &&
                                      aAnnouncement.map &&
                                      aAnnouncement.map((Announcement) => (
                                        <>
                                          {Announcement.image === "-" &&
                                            Announcement.video === "-" ? (
                                            <div >
                                              <div
                                              >
                                                <span
                                                  key={Announcement.id}
                                                  style={{ margin: "0%" }}
                                                >

                                                  <b>Subject:</b> &nbsp; <span
                                                    style={{ overflowWrap: "break-word", width: "50%", }}
                                                  > {Announcement.title}</span><br /><br />
                                                  <b>Description:</b> &nbsp; <span
                                                    style={{ overflowWrap: "break-word", width: "50%", }}> {Announcement.message}</span>

                                                </span>
                                              </div>
                                            </div>
                                          ) : (
                                            
                                                    <>
                                                    {Announcement.image.split(',').map((imageUrl, index) => (
    <div  style={{display:"inline-block", marign:"10px" }} key={index}>
         {index === 0 && (
          <>
           <span style={{ margin: "38px" }}>
      <b>Subject:</b> &nbsp;
      <span style={{ overflowWrap: "break-word", width: "50%" }}>
        {Announcement.title}
      </span>
    </span>
    <br /><br />
    <span style={{ margin: "38px" }}>
      <b>Description:</b> &nbsp;
      <span style={{ overflowWrap: "break-word", width: "50%" }}>
        {Announcement.message}
      </span>
    </span>
    <br /><br />
                </> 
        )}
        <div style={{margin:"10px"}}>
        {getFileType(imageUrl) === 'image' ? (
            
            <span style={{margin:"30px"}}>
            <b>Image:&nbsp;</b>

{imageUrl.split(',').map((Url, index) => (
<img
key={index}
alt={`Image ${index}`}
src={imageUrl.trim()} 
style={{ width: "100px", height: "100px" }}
onClick={() => handleImageClick(Url.trim())}
/>
))}
            </span>
        ) : getFileType(imageUrl) === 'video' ? (
          <span style={{display:"flex",margin:"30px"}}>
          <span><b>video:</b></span>
                <video
                    controls
                    style={{ width: "125px", height: '118px', marginTop: '-61px' }}
                >
                    <source src={imageUrl} type="video/mp4" />
                    <source src={imageUrl} type="video/ogg" />
                </video>
            </span>
        ) : (
          <span style={{margin:"30px"}}>
          <b>File:</b> 
                <img
                    src={fileImg}
                    style={{ height: "30px", width: "30px" }}
                />
                &nbsp; 
                <a
                    href={imageUrl}
                    name="picture"
                    readOnly
                    value={imageUrl}
                    title=""
                    target="_blank"
                >
                    <span>
                        {imageUrl.slice("https://localhost:44369/Images/Announcement_Image_".length)}
                    </span>
                </a>
            </span>
           
        )}
         </div>
    </div>
))}
                                                  </>
                                                  )}
                                                </>
                                              ))}
                            <div>

                            </div>
                          </div>
                        </div> </div> </div> : null}

                  <div className="card"
                  // style={{ height: '450px' }}
                  >
                    <div className="glowBox">
                      <div style={{ float: "left" }}>
                        <a
                          href
                          onClick={announcementPreview}
                        >
                          <i
                            className="icofont icofont-arrow-left"
                            style={{ fontSize: "45px", color: "white" }}
                          />
                        </a>{" "}
                      </div>
                      <h4 className="title"
                      // style={{ marginRight: '56px' }}
                      >Announcements From OAT </h4>
                    </div>
                    
                    <div className="col-xl-12 "
                    >

                      <div className="col-lg-3" style={{ float: 'right' }}>
                        <input
                          type="text"
                          className="Searchbox"
                          id="CNSearch"
                          placeholder="Search"

                          onClick={() => {
                            setFilterPop(true)
                          }}
                          readOnly

                        /></div>
                    </div>


                    {/* <table style={{
                      // marginLeft:'5%',
                      // marginTop: '2%'
                    }}>
                      <tbody>
                        {announcement.length === 0 && delay ? (
                          <center>
                            <h5>No Announcements</h5>
                          </center>
                        ) : ( */}

                    <div className="card-block">
                      <div className="dt-responsive table-responsive"
                        id={announcement.length <= 7 ? "NoAnnLength" : "Above10"}
                      >
                        {announcement.length === 0 && delay && announcementFilter.length === 0 ?
                          <center>

                            <p>No Announcement</p>
                          </center>
                          : null}
                        {announcement.length !== 0 ? (<table
                          id="add-row-table3"
                          className="table nowrap"

                        >
                          <thead id='tablepadding'><tr>

                            <th style={{color:'#7367f0'}}>Announcement Details</th>
                          </tr>
                          </thead>

                          <tbody id='tablepadding'>

                            <>
                              {announcement.map((item) => (
                                <>

                                  <tr>

                                    <td>
                                      <Link
                                     
                                        onClick={() => {
                                        bothOnClick(item.id)
                                        SeenUpdate(item.id)
                                        }}
                                           style={{
                                          color:
                                          item.seen == true
                                              ? "gray"
                                              : "black",
                                          cursor: "pointer",
                                        }}
                                        
                                        id='SAannLists'
                                      >
                                        Subject:&nbsp;<span>{truncateTitle(item.title, 85)}</span>
                                        &nbsp;<span style={{fontSize: "12px"}}>See More</span>
                                        {/* Title:&nbsp;<span className='hoverable-span'>{truncateTitle(item.title, 85)}</span>
                                        &nbsp;<span style={{color:'#014c8c'}}>See More</span> */}

                                        &nbsp;&nbsp;&nbsp;
                                        <ReactTimeAgo
                                          date={item.createdDate}
                                          locale="en-US"
                                          style={{ color: "gray", fontSize: '12px' }}
                                        />

                                      </Link>


                                    </td>


                                  </tr>
                                </>
                              ))}
                            </>
                          </tbody>
                        </table>) : null}
                      </div> </div>




                    {/* )}
                      </tbody>

                    </table> */}

                  </div>


                </div>
              </div>
            </div>
            {filterPop && (
              <div className="popSearchTeam">
                <div className="popSearchinnernormalTeam">
                  <div className="popSearchinnertitle" id="popSearchinnernormal">
                    <button
                      id="close"
                      style={{
                        float: "right",
                        backgroundColor: "#a0a3a305",
                        color: "#797070",
                        border: "1px solid #a3a5a505",
                        marginTop: "-20px",
                        marginRight: "-20px",
                      }}
                      onClick={() => {
                        setFilterPop(false);
                        getAnnouncement();
                      }}
                    >
                      <CloseIcon />
                    </button>

                    <div className="checkboxes">
                      <i
                        className="fa fa-search"
                        aria-hidden="true"
                        style={{
                          fontSize: "20px",
                          color: "gray",
                          marginLeft: "-1%",
                          position: "absolute",
                          marginTop: "0.5%",
                        }}
                      ></i>
                      <input
                        className="Searchbox"
                        placeholder="Search"
                        onChange={filterfunction}

                        style={{ float: "right", width: "80%" }}
                      />
                      <br></br>
                      {letters.map((letter, index) => (
                        <button
                          className="filter"
                          key={index}
                          onClick={(event) => handleFilter(letter, event)}
                        >
                          {letter}
                        </button>
                      ))}
                      <br></br>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllAnnouncement;