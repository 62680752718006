import React, { Component } from "react";
import SideBar from "../../Common/Sidebar";
import { Link } from "react-router-dom";
import { BASE_URLs } from "../../Config/Base";
import ReactTimeAgo from "react-time-ago";
export default class AllAnnouncement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Announcement: [],
      status: true,
      clickedContents:
        JSON.parse(localStorage.getItem("clickedContents")) || {},
    };
    this.handleContentClick = this.handleContentClick.bind(this);
  }

  GetAnnouncement() {
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(`${BASE_URLs}Announcement/GetActiveAnnounce`, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Authorization: "Bearer " + accessToken,
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Announcement: data,
        });

        // console.log("New", data);

        //
      });
  }
  AnnouncementView(Id) {
    var page = {
      pathname: "/AdminAnnouncmentpop",
      state: {
        Id: Id,
      },
    };
    this.props.history.push(page);
  }

  componentDidMount() {
    this.GetAnnouncement();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };
  handleContentClick(id) {
    const clickedContents = { ...this.state.clickedContents };
    clickedContents[id] = true;
    localStorage.setItem("clickedContents", JSON.stringify(clickedContents));
    this.setState({ clickedContents });
  }
  bothonclick(id) {
    this.handleContentClick(id);
    this.AnnouncementView(id);
  }

  render() {
    const { Announcement, selected } = this.state;
    return (
      <div>
        <SideBar active={window.location.hash} />

        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                </div>
                {/* <div className="row" style={{ display: "flex", justifyContent: "flex-start", alignItems: "baseline", float: "right" }}>
                  <Link to="/AdminAnnouncement" style={{ width: "inherit", background: "white" }} id="addRow" className="btn"> Announcement to User</Link>
                </div> */}
                <div className="page-body">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="glowBox">
                      <div style={{ float: "left" }}>
                              <a
                                href
                                onClick={() => this.props.history.goBack()}
                              >
                                <i
                                  className="icofont icofont-arrow-left"
                                  style={{ fontSize: "45px", color: "white" }}
                                />
                              </a>{" "}
                            </div>
                        <h4 className="title">Announcements </h4>
                      </div>
                      <center>
                        <div
                          className="row"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "baseline",
                            float: "left",
                            marginLeft: "30px",
                            marginBottom: "3%",
                          }}
                        >
                          <Link
                            to="/Announcement"
                            style={{ width: "inherit", background: "white" }}
                            id="addRow"
                            className="btn"
                          >
                            {" "}
                            New Announcements
                          </Link>
                        </div>
                        <div
                          className="row"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "baseline",
                            float: "left",
                            marginLeft: "30px",
                          }}
                        >
                          <Link
                            to="/AnnouncementPreviewSP"
                            style={{ width: "inherit", background: "white" }}
                            id="addRow"
                            className="btn"
                          >
                            {" "}
                            Old Announcements
                          </Link>
                        </div>
                      </center>
                      {/* <table>
                        <tbody>
                          {Announcement.length == 0 ? (
                            <center>
                              <h5>No Announcements</h5>
                            </center>
                          ) : (
                            <div className="App">
                              <div className="row">
                                <ul className="show-notifications">
                                  {Announcement.map((Announcement, id) => (
                                    <>
                                      <div className="col-lg-12">
                                        <div
                                          className="card-Announcements"
                                          style={{
                                            position: "relative",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <li key={id}>
                                            <i
                                              className="fa fa-angle-double-right"
                                              aria-hidden="true"
                                              style={{
                                                color: "#7367f0",
                                                fontSize: "15px",
                                              }}
                                            ></i>
                                            &nbsp;&nbsp;
                                            <Link
                                              onClick={() =>
                                                this.bothonclick(
                                                  Announcement.id
                                                )
                                              }
                                              style={{
                                                color: this.state
                                                  .clickedContents[
                                                  Announcement.id
                                                ]
                                                  ? "gray"
                                                  : "black",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <b>{Announcement.title}</b>
                                              &nbsp;&nbsp;&nbsp;
                                              <ReactTimeAgo
                                                date={Announcement.createdDate}
                                                locale="en-US"
                                                style={{ color: "gray" }}
                                              />
                                              &nbsp;&nbsp;&nbsp; See More
                                            </Link>
                                          </li>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )}
                        </tbody>
                      </table> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
