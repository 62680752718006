import React, { Component } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import $ from "jquery";
import { BASE_URLs } from "../../Config/Base";
import SideBar from "../../Common/Sidebar";
$(document).ready(function () {
  $("#saverow1").click(function () {
    $("#add-row1").hide();
  });
  $("#addBtn1").click(function () {
    $("#add-row1").show();
  });
});

$(document).ready(function () {
  $("#add-row1").hide();
});
export default class SkillMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edus: [],
      Addedus: [],
      Id: "",
      UG: "",
      PG: "",
      Specialization: "",
      Notification: [],
    };
  }

  handleFormValidation() {
    const { SkillName, SkillDescription, Category } = this.state;
    let formErrors = {};
    let formIsValid = true;

    //Email
    if (!SkillName) {
      formIsValid = false;
      formErrors["SkillNameErr"] = "SkillName is required";
    }
    if (!SkillDescription) {
      formIsValid = false;
      formErrors["SkillDescriptionErr"] = "SkillDescription is required";
    }

    if (!Category) {
      formIsValid = false;
      formErrors["CategoryErr"] = "Category is required";
    }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  GetEdu() {
    let url = `${BASE_URLs}Education/Get`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        Authorization: "Bearer " + accessToken,
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          edus: data,
        });

        //
      });
  }

  EditSubmit = (event) => {
    event.preventDefault();
    let url = `${BASE_URLs}Skill/Put`;
    let data = this.state;
    fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((result) => {
      result.json().then((resp) => {
        alert("Updated Succesfully");
      });
    });
  };

  deleteSkill(id) {
    if (window.confirm("Are you sure you want to delete ?")) {
      fetch(`${BASE_URLs}Skill/Delete?Id=` + id, {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((result) => {
        result.json().then((resp) => {
          alert(resp);
        });
      });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleSubmit1 = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    this.setState({ isSubmitting: false });
  };
  componentDidMount() {
    this.GetEdu();
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("uname");
    localStorage.removeItem("Role");
    localStorage.removeItem("user");
    localStorage.removeItem("org");
    localStorage.clear();
    this.props.history.push("/Login_form");
  };

  render() {
    const { edus, Addedus } = this.state;
    return (
      <div>
        <SideBar active={window.location.hash} />

        <div className="pcoded-main-container">
          <div className="pcoded-wrapper">
            <div className="pcoded-content">
              <div className="pcoded-inner-content">
                <div className="main-body">
                  <div className="page-wrapper">
                    <div className="page-header"></div>
                    <div className="page-body">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="card">
                            <div className="glowBox">
                              <div style={{ float: "left" }}>
                                <a
                                  href
                                  onClick={() => this.props.history.goBack()}
                                >
                                  <i
                                    className="icofont icofont-arrow-left"
                                    style={{ fontSize: "45px", color: "white" }}
                                  />
                                </a>{" "}
                              </div>
                              <h4 className="title">Education</h4>
                            </div>
                            <div className="card-header-right"></div>
                            <div className="card-block">
                              <div>
                                <table
                                  id="add-row1"
                                  className="table  table-striped table-bordered nowrap"
                                >
                                  <tr>
                                    <td>
                                      <select
                                        className="form"
                                        name="Category"
                                        style={{ color: "darkgray" }}
                                        onChange={this.handleChange}
                                        type="text"
                                        placeholder="City"
                                      >
                                        <option value=""> UG</option>
                                        <option value="BBA">BBA</option>
                                        <option value="BBA">BCA</option>
                                        <option value="BBA">B.Com</option>
                                        <option value="BBA">B.Arch</option>
                                        <option value="BBA">B.D.S</option>
                                        <option value="BBA">B.Arch</option>
                                        <option value="BBA">B.Ed</option>
                                        <option value="PG">BSC</option>
                                      </select>
                                    </td>

                                    <td>
                                      <select
                                        className="form"
                                        name="Category"
                                        style={{ color: "darkgray" }}
                                        onChange={this.handleChange}
                                        type="text"
                                        placeholder="City"
                                      >
                                        <option value=""> PG</option>
                                        <option value="BBA">MBA</option>
                                        <option value="BBA">MCA</option>
                                        <option value="BBA">M.Com</option>
                                        <option value="BBA">M.Arch</option>
                                        <option value="BBA">M.D.S</option>
                                        <option value="BBA">M.Arch</option>
                                        <option value="BBA">M.Ed</option>
                                        <option value="PG">MSC</option>
                                      </select>
                                    </td>
                                    <td>
                                      <input
                                        className="form"
                                        name="SkillDescription"
                                        type="text"
                                        onChange={this.handleChange}
                                        placeholder="Specialization"
                                      />
                                      <span className="underline" />
                                    </td>
                                    <td>
                                      <button
                                        type="submit"
                                        id="saverow1"
                                        className="but"
                                        style={{
                                          margin: "2px -63px",
                                          height: "40.4px",
                                          width: "83.36px",
                                        }}
                                      >
                                        Save
                                      </button>
                                    </td>
                                  </tr>
                                </table>
                                <button
                                  className="btn btn-md btn-primary"
                                  id="addBtn1"
                                  type="button"
                                >
                                  Add
                                </button>
                              </div>
                            </div>

                            <div className="card-block2">
                              {/*         <div className="j-wrapper ">
                               */}
                              <form
                                autoComplete="on"
                                encType="multipart/form-data"
                                onSubmit={this.handleSubmit1}
                              >
                                <table
                                  id="add-row1-table"
                                  className="table  table-striped table-bordered nowrap"
                                >
                                  <thead>
                                    <tr>
                                      <th>UG </th>
                                      <th>PG</th>
                                      <th>Specialization</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {edus.map((edu) => (
                                      <tr>
                                        <td>{edu.ug}</td>
                                        <td>{edu.pg}</td>
                                        <td>{edu.specialization}</td>
                                        <td>
                                          <div className="dropdown">
                                            <MoreVertIcon />
                                            <div className="dropdown-content">
                                              <a href="Education">Add </a>
                                              <a href>Edit </a>
                                              <a href>Delete </a>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                    {Addedus.map((edu) => (
                                      <tr>
                                        <td>{edu.ug}</td>
                                        <td>{edu.pg}</td>
                                        <td>{edu.specialization}</td>
                                        <td>
                                          <div className="dropdown">
                                            <MoreVertIcon />
                                            <div className="dropdown-content">
                                              <a href="Education">Add </a>
                                              <a href>Edit </a>
                                              <a href>Delete </a>
                                            </div>
                                          </div>
                                        </td>
                                        {/* <td><button onClick={this.deleteSkill}>Delete</button></td> */}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <br />
                                <br />
                                <br />
                                <br />
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* End */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footerhome">
          <p>Contact Us: contact@oneawesometeam.com</p>
        </div>
      </div>
    );
  }
}
