import React, { Component } from "react";
import axios from 'axios';
import SideBar from "../Common/Sidebar";
import { Link } from "react-router-dom";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import CloseIcon from "@material-ui/icons/Close";
import Swal from "sweetalert2";
import { BASE_URLs } from "../Config/Base";

export default class MasterSkills extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Access: "",
      checkBox1: false,
      checkBox2: false,
      checkBox3: false,
      checkBox4: false,
      externalAcc: false,
      allowAcc1: true,
      allowAcc2: "",
      Filterpop: false,
      EntityShow: false,
      EntityShowOff: false,
      Entity: [],
      EName: "",
      EId: "",
      EntityUsers: [],
      AllAccess: [],
      DName: false,
      MemberAccess: [],
      AllMemberAccess: [],
      NonAccess: [],
      checking: false,
      toggleStates: {},
      selectedEntities: {},
      Teams: [],
      letters: [],
      refreshing: true
    };

    this.initialState = this.state;
    this.searchForDiscRef = React.createRef();
    this.firstInputRef = React.createRef();

  }

  HandleAllowAccessExternal1 = () => {
    this.setState({
      allowAcc1: "0",
      allowAcc2: "1",
      checkBox1: true,
      checkBox2: false,
      checkBox3: false,
      checkBox4: false,
    });
  };

  // setFocus = () => {
  //   this.setState(
  //     (prevState) => ({
  //       Filterpop: !prevState.Filterpop,
  //     }),
  //     () => {
  //       if (this.state.Filterpop) {
  //         this.searchForDiscRef.current.focus();
  //       }
  //     }
  //   );
  // };

  HandleAllowAccessExternal2 = () => {
    this.setState({
      allowAcc1: "0",
      allowAcc2: "1",
      checkBox1: false,
      checkBox2: true,
      checkBox3: false,
      checkBox4: false,
    });
  };
  HandleAllowAccessExternal3 = () => {
    this.setState({
      allowAcc1: "0",
      allowAcc2: "1",
      checkBox1: false,
      checkBox2: false,
      checkBox3: true,
      checkBox4: false,
    });
  };
  HandleAllowAccessExternal4 = () => {
    this.setState({
      allowAcc1: "0",
      allowAcc2: "1",
      checkBox1: false,
      checkBox2: false,
      checkBox3: false,
      checkBox4: true,
    });
  };

  AllEntityShow = (entityName, id) => {

    this.setState((prevState) => {
      const toggleStates = { ...prevState.toggleStates };
      const selectedEntities = Array.isArray(prevState.selectedEntities)
        ? [...prevState.selectedEntities]
        : [];
      toggleStates[id] = !toggleStates[id];

      if (toggleStates[id]) {
        selectedEntities.push({ id, entityName });

        const entityNames = selectedEntities.map(entity => entity.entityName).join(','); // Convert array to string
        const entityId = selectedEntities.map(entity => entity.id).join(','); // Convert array to string

        this.setState({
          EntityShow: true,
          EName: entityNames,
          EId: entityId,
        });
      } else {
        const indexToRemove = selectedEntities.findIndex(entity => entity.id === id);
        selectedEntities.splice(indexToRemove, 1);
        const SelectedEntitiesA = selectedEntities.length > 0;
        this.setState({
          EntityShow: SelectedEntitiesA,
          toggleStates,
          selectedEntities,
        });
      }


      return { toggleStates, selectedEntities };
    });
  };


  EntityShowPop = (entityName, id) => {

    // if (this.state.EntityShow === false) {
    //   this.setState({
    //     EntityShow: true,
    //     EName: entityName,
    //     EId: id,
    //   });
    // } else {
    //   this.setState({
    //     EntityShow: false,
    //   });
    // }
    // this.setState({
    //       EntityShow: true,
    //     })
    this.setState((prevState) => {
      const toggleStates = { ...prevState.toggleStates };
      const selectedEntities = Array.isArray(prevState.selectedEntities)
        ? [...prevState.selectedEntities]
        : [];
      toggleStates[id] = !toggleStates[id];

      if (toggleStates[id]) {
        selectedEntities.push({ id, entityName });

        const entityNames = selectedEntities.map(entity => entity.entityName).join(','); // Convert array to string
        const entityId = selectedEntities.map(entity => entity.id).join(','); // Convert array to string

        this.setState({
          // EntityShow: true,
          EName: entityNames,
          EId: entityId,
        });
      } else {
        const indexToRemove = selectedEntities.findIndex(entity => entity.id === id);
        selectedEntities.splice(indexToRemove, 1);
        const SelectedEntitiesA = selectedEntities.length > 0;
        this.setState({
          EntityShow: SelectedEntitiesA,
          toggleStates,
          selectedEntities,
        });
      }


      return { toggleStates, selectedEntities };
    });
  };

  EntityShow = (entityName, id) => {

    // if (this.state.EntityShow === false) {
    //   this.setState({
    //     EntityShow: true,
    //     EName: entityName,
    //     EId: id,
    //   });
    // } else {
    //   this.setState({
    //     EntityShow: false,
    //   });
    // }
    // this.setState({
    //       EntityShow: true,
    //     })
    this.setState((prevState) => {
      const toggleStates = { ...prevState.toggleStates };
      const selectedEntities = Array.isArray(prevState.selectedEntities)
        ? [...prevState.selectedEntities]
        : [];
      toggleStates[id] = !toggleStates[id];

      if (toggleStates[id]) {
        selectedEntities.push({ id, entityName });

        const entityNames = selectedEntities.map(entity => entity.entityName).join(','); // Convert array to string
        const entityId = selectedEntities.map(entity => entity.id).join(','); // Convert array to string

        this.setState({
          EntityShow: true,
          EName: entityNames,
          EId: entityId,
        });
      } else {
        const indexToRemove = selectedEntities.findIndex(entity => entity.id === id);
        selectedEntities.splice(indexToRemove, 1);
        const SelectedEntitiesA = selectedEntities.length > 0;
        this.setState({
          EntityShow: SelectedEntitiesA,
          toggleStates,
          selectedEntities,
        });
      }


      return { toggleStates, selectedEntities };
    });
  };


  EntityShowOff = (entityName, id) => {
    // if (this.state.EntityShowOff === false) {
    //   this.setState({
    //     EntityShowOff: true,
    //     EName: entityName,
    //     EId: id,
    //   });
    // } else {
    //   this.setState({
    //     EntityShowOff: false,
    //   });
    // }

    this.setState((prevState) => {
      const toggleStates = { ...prevState.toggleStates };
      const selectedEntities = Array.isArray(prevState.selectedEntities)
        ? [...prevState.selectedEntities]
        : [];
      toggleStates[id] = !toggleStates[id];

      if (toggleStates[id]) {
        selectedEntities.push({ id, entityName });

        const entityNames = selectedEntities.map(entity => entity.entityName).join(','); // Convert array to string
        const entityId = selectedEntities.map(entity => entity.id).join(','); // Convert array to string
        this.setState({
          EntityShowOff: true,
          EName: entityNames,
          EId: entityId,
        });

      } else {

        const indexToRemove = selectedEntities.findIndex(entity => entity.id === id);
        selectedEntities.splice(indexToRemove, 1);
        const SelectedEntitiesA = selectedEntities.length > 0;
        this.setState({
          EntityShowOff: SelectedEntitiesA,
          toggleStates,
          selectedEntities,
        });

      }

      return { toggleStates, selectedEntities };
    });
  };

  AllowAllEntity() {
    const { EName, EId } = this.state;
    Swal.fire({
      text: "Are you sure wants to give access to all Entity?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
      customClass: {
        title: "swal-title",
        content: "swal-text",
        confirmButton: "swal-button",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
          },
        };
  
        axios.post(`${BASE_URLs}Chat/AllowAllAccess`, {
          entity: EName,
          id: EId,
        }, options)
        .then((response) => {
          const data = response.data;
          if (data) {
            Swal.fire({
              title: "Access Given to All Entities",
              text: "Successfully",
              icon: "success",
              confirmButtonText: "Done",
            });
            // console.log("Accessssed", data);
            this.setState({
              AllAccess: data,
            });
          } else {
            console.error("Received data:", data);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
  
        window.location.reload(true);
      }
    });
  }

  TeamSearch = (e) => {


    const Entity = this.state.Teams.filter((item) =>
      item.entityName.toLowerCase().startsWith(e.target.value.toLowerCase())
    );

    this.setState({ Entity });
    // if (Entity.length === 0) {
    //   document.getElementById("FilterErr").innerHTML = "Entity Not Found";
    // } else {
    //   document.getElementById("FilterErr").innerHTML = "";
    // }
  };

  LetterTeamSearch = (letter, e) => {
    e.preventDefault();
    const Entity = this.state.Teams.filter((item) =>
      item.entityName.toLowerCase().startsWith(letter.toLowerCase())
    );
    this.setState({ Entity });
    if (Entity.length === 0) {
      document.getElementById("FilterErr").innerHTML = "Entity Not Found";
    } else {
      document.getElementById("FilterErr").innerHTML = "";
    }
  };

  GetAccessMembers() {
    const { EName, EId } = this.state;
  
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.post(`${BASE_URLs}Chat/Accessmembers`, {
      entity: EName,
      id: EId,
    }, options)
    .then((response) => {
      const data = response.data;
      // console.log("Accessss", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
  
    this.setState({
      EntityShow: false,
      EntityShowOff: false,
    });
  
    Swal.fire({
      text: "Access Given Successfully",
      icon: "success",
      confirmButtonText: "Done",
    });
  
    this.Team1();
    window.location.reload(true);
  }


  GetAccessMembersOff(entityName, id) {
    const { EName, EId } = this.state;
    
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.post(`${BASE_URLs}Chat/AccessmembersOff`, {
      entity: EName,
      id: EId,
    }, options)
    .then((response) => {
      const data = response.data;
      // console.log("AccessssOff", data);
      // this.setState({ Access: data });
    })
    .catch((error) => {
      console.error("Error:", error);
    });
  
    this.setState({
      EntityShow: false,
      EntityShowOff: false,
    });
  
    Swal.fire({
      text: "Access Turned off Successfully",
      icon: "success",
      confirmButtonText: "Done",
    });
  
    window.location.reload(true);
  }


  GetAccessMembersDenied(entityName, id) {
    const { EName, EId } = this.state;
  
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.post(`${BASE_URLs}Chat/AccessmembersDenied`, {
      entity: EName,
      id: EId,
    }, options)
    .then((response) => {
      const data = response.data;
      // console.log("AccessmembersDenied", data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
  
    this.setState({
      EntityShow: false,
      EntityShowOff: false,
    });
  
    Swal.fire({
      text: "Access Denied Successfully",
      icon: "success",
      confirmButtonText: "Done",
    });
  
    document.getElementById('EAccessPopA1').style.display = "none";
  }

  Team1() {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(localStorage.user)}`,
      },
    };
  
    axios.get(`${BASE_URLs}Team/GetEntity`, options)
      .then((response) => {
        const data = response.data;
        data.sort((a, b) => parseInt(a.teamId) - parseInt(b.teamId));
        this.setState({
          Entity: data,
          Teams: data,
        });
        // console.log("hess11", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  componentDidMount() {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    this.Team1();
    this.setState({ letters: alphabet });

    setTimeout(() => {
      this.setState({ refreshing: false });
    }, 1000);
  }

  handleClose = () => {
   document.getElementById('EAccessPopA1').style.display = "none"
  };

  render() {
    var username = JSON.parse(localStorage.uname);

    var EMaiL = JSON.parse(localStorage.email);

    var entity = JSON.parse(localStorage.entityName)

    const {
      Access,
      Entity,
      EName,
      EId,
      Filterpop
    } = this.state

    var Accessed = JSON.parse(localStorage.access);
    var Accessed_to = JSON.parse(localStorage.accessed_to);

    const accesspopstyle = {
      position: "absolute",
      // top: "350px",
      alignItems: "center",
      zIndex: "10",

      // width: "400px",
      backgroundColor: "white",
      padding: "10px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.5)",
      display: 'block',
      cursor: "pointer",
      borderRadius: "9px",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    };


    return (
      <div>
        <ToastContainer
          position="top-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <SideBar active={window.location.hash} />
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="main-body">
              <div className="page-wrapper">
                <div className="page-header">
                  <div className="page-header-title"></div>
                  <div className="container" style={{ width: "100%" }}>
                    <form id="settingrespform">
                      <div className="glowBox">
                        <div>
                          <div style={{ float: "left" }}>
                            <a
                              href
                              onClick={() => this.props.history.goBack()}
                            >
                              <i
                                className="icofont icofont-arrow-left"
                                style={{ fontSize: "45px", color: "white" }}
                              />
                            </a>{" "}
                          </div>
                        </div>
                        <h4 className="title">Settings</h4>
                      </div>


                      <div className="container-fluid">
                        <div className="col-lg-12">
                          <div className="col-lg-5">
                          <form style={{marginLeft:"3%"}}> 
                              <div>

                                {/* {Entity.map((Teams) => {
                                  return <>
                                    {(Teams.accessed_to == 0 || !Teams.accessed_From.split(",")
                                      .includes(
                                        EMaiL
                                      )) && Teams.accessed_From != null && Teams.access == 1 && Teams.accessed_For.split(",")
                                        .includes(
                                          entity
                                        ) ? <form id="entitylistform" style={{ height: "auto" }}>
                                      {Entity.map((Teams) => (
                                        <>
                                          <tr id="listhigh">
                                          </tr>
                                          <td>
                                            <label
                                              style={{ height: "30px" }}
                                              className="toggle"
                                            >
                                              &nbsp;&nbsp;
                                              <tr>
                                                {(Teams.accessed_to == 0 || !Teams.accessed_From.split(",")
                                                  .includes(
                                                    EMaiL
                                                  )) && Teams.accessed_From != null && Teams.access == 1 && Teams.accessed_For.split(",")
                                                    .includes(
                                                      entity
                                                    ) ? (
                                                  <>
                                                    <div
                                                      className="toggle-label-on"
                                                      style={{
                                                        color:
                                                          "Black",
                                                      }}
                                                    >
                                                      {
                                                        Teams.entityName
                                                      }
                                                    </div>{" "}
                                                    <input
                                                      type="checkbox"
                                                      name="tabs1"
                                                      className="toggle-checkbox"
                                                      onChange={() =>
                                                        this.EntityShow(
                                                          Teams.entityName,
                                                          Teams.id
                                                        )
                                                      }
                                                    />
                                                    &nbsp;&nbsp;
                                                    <div className="toggle-switch">
                                                      <p
                                                        style={{
                                                          fontSize:
                                                            "11px",
                                                          marginTop:
                                                            "11%",
                                                          marginLeft:
                                                            "14%",
                                                        }}
                                                      >
                                                        <b>
                                                          ON OFF
                                                        </b>
                                                      </p>
                                                    </div>
                                                  </>
                                                ) : null}
                                              </tr>

                                            </label>
                                          </td>
                                        </>
                                      ))}

                                    </form> : null}
                                  </>
                                })} */}

                                {/* <span
                                    style={{
                                      color: "#292b2c",
                                      fontFamily: "sans-serif",
                                    }}
                                  >
                                    Allow Access to All External Entities
                                  </span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                {/* <label className="radio1">
<input type="checkbox" onFocus={(e) => e.target.select()} id="ExternalAcc"
onInputCapture={
this.HandleAllowAccessExternal }
name="AllowEntity"/>
<span>Yes</span>
          &nbsp;&nbsp;&nbsp;
          <input
            type="checkbox"
            onFocus={(e) => e.target.select()}
            id="ExternalAcc"
            onInputCapture={
              this.HandleAllowAccessExternal
            }
            name="AllowEntity"
          />
          <span>No</span>

</label>  */}
                                {/* {this.state.allowAcc1 === "1" ? (
                                    <span>
                                      <label>
                                        <input
                                          type="checkbox"
                                          id="Yes"
                                          name="Yes"
                                          onChange={() =>
                                            this.HandleAllowAccessExternal1()
                                          }
                                          onClick={() => this.AllowAllEntity()}
                                        />
                                      </label>
                                      &nbsp;&nbsp;
                                      <span>Yes</span>
                                      &nbsp;&nbsp;
                                    </span>
                                  ) : (
                                    <label>
                                      <input
                                        type="checkbox"
                                        id="Yes"
                                        name="Yes"
                                        onChange={
                                          this.HandleAllowAccessExternal1
                                        }
                                        onClick={() => this.AllowAllEntity()}
                                      />
                                      &nbsp;&nbsp;
                                      <span>Yes</span>
                                      &nbsp;&nbsp;
                                    </label>
                                  )} */}
                                {/* {this.state.allowAcc2 === "0" ? (
                                    <span>
                                      <label id="No">
                                        <input
                                          type="checkbox"
                                          id="No"
                                          name="No"
                                          disabled={
                                            Accessed === 1 ? true : false
                                          }
                                          defaultChecked={
                                            this.state.allowAcc2 === "0"
                                          }
                                          onChange={() =>
                                            this.HandleAllowAccessExternal2()
                                          }
                                        />
                                      </label>
                                      &nbsp;&nbsp;
                                      <span>No</span>
                                      &nbsp;&nbsp;
                                    </span>
                                  ) : (
                                    <label>
                                      <input
                                        type="checkbox"
                                        id="No"
                                        name="No"
                                        checked={this.state.checkBox2}
                                        disabled={Accessed === 1 ? true : false}
                                        onChange={
                                          this.HandleAllowAccessExternal2
                                        }
                                      />
                                      &nbsp;&nbsp;
                                      <span>No</span>
                                      &nbsp;&nbsp;
                                    </label>
                                  )} */}
                              </div>
                              {/* <br></br> */}

                              <>
                                <div style={{marginLeft :"-3%"}} >
                                  <span
                                    style={{
                                      color: "#292b2c",
                                      fontFamily: "sans-serif",
                                      fontSize:'medium'
                                    }}
                                  >
                                    This are the other Entities are available for your forum.
                                  </span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  {/* <span style={{ marginLeft: "2%" }}> */}
                                  {/* {this.state.allowAcc1 === "1" ? (
                                        <span>
                                          <label>
                                            <input
                                              type="checkbox"
                                              id="Yes"
                                              name="Yes"
                                              defaultChecked={
                                                this.state.allowAcc1 === "1"
                                              }
                                              onChange={() =>
                                                this.HandleAllowAccessExternal3()
                                              }
                                            />
                                          </label>
                                          &nbsp;&nbsp;
                                          <span>Yes</span>
                                          &nbsp;&nbsp;
                                        </span>
                                      ) : (
                                        <label>
                                          <input
                                            type="checkbox"
                                            id="Yes"
                                            name="Yes"
                                            checked={this.state.checkBox3}
                                            onChange={
                                              this.HandleAllowAccessExternal3
                                            }
                                          />
                                          &nbsp;&nbsp;
                                          <span>Yes</span>
                                          &nbsp;&nbsp;
                                        </label>
                                      )} */}


                                  {/* {this.state.allowAcc2 === "0" ? (
                                        <span>
                                          <label id="No">
                                            <input
                                              type="checkbox"
                                              id="No"
                                              name="No"
                                              defaultChecked={
                                                this.state.allowAcc2 === "0"
                                              }
                                              onChange={() =>
                                                this.HandleAllowAccessExternal4()
                                              }
                                            />
                                          </label>
                                          &nbsp;&nbsp;
                                          <span>No</span>
                                          &nbsp;&nbsp;
                                        </span>
                                      ) : (
                                        <label>
                                          <input
                                            type="checkbox"
                                            id="No"
                                            name="No"
                                            checked={this.state.checkBox4}
                                            onChange={
                                              this.HandleAllowAccessExternal4
                                            }
                                          />
                                          &nbsp;&nbsp;
                                          <span>No</span>
                                          &nbsp;&nbsp;
                                        </label>
                                      )} */}

                                  {/* </span> */}


                                  <>
                                    <br></br><br></br>
                                    <input
                                      type="text"
                                      className="Searchbox"
                                      placeholder="Search"
                                      onChange={this.TeamSearch}
                                      id="settingsearch"
                                      style={{
                                        backgroundColor: "white",
                                      }}
                                      ref={this.firstInputRef}
                                      onClick={this.setFocus}
                                    />

                                    <br></br><br></br>
                                    {this.state.Entity.length === 0 && !this.state.refreshing?
                                      <span
                                        // id="FilterErr"
                                        // className="filterr"
                                        style={{ color: "red" }}
                                      >Entity Not Found
                                      </span> :

                                      <form style={{ height: '245px', overflowY: 'scroll' }}>
                                      <ul><li style={{ padding: '7px' }}>
                                                    <input
                                                      type="checkbox"
                                                      name="tabs1"
                                                      style={{accentColor:"#7f63efde"}}
                                                      // className="toggle-checkbox"
                                                      // onChange={() =>
                                                      //   this.EntityShow(
                                                      //     Teams.entityName,
                                                      //     Teams.id
                                                      //   )
                                                      // }
                                                      onClick={() => this.AllowAllEntity()}
                                                    /> &nbsp;&nbsp;
                                                    All
                                                  
                                                  </li>
                                              </ul>
                                        {Entity.map((Teams) => (
                                          <>
                                            <ul>
                                            <li style={{ padding: '7px' }}>
                                              {Teams.accessed_to ==
                                                0 ? (
                                                <>

                                                  <input
                                                    type="checkbox"
                                                    name="tabs1"
                                                    // className="toggle-checkbox"
                                                    onChange={() =>
                                                      this.EntityShow(
                                                        Teams.entityName,
                                                        Teams.id
                                                      )
                                                    }
                                                    style={{accentColor:"#7f63efde"}}
                                                  /> &nbsp;&nbsp;
                                                  {Teams.entityName}

                                                  {/* <input
=======
                                    <form >
                                      <div
                                        // className="toggle-label"
                                        style={{
                                          color: "Black",
                                        }}
                                        id="toff"
                                      >
                                        <input
                                          type="checkbox"
                                          name="tabs1"
                                          // className="toggle-checkbox"
                                          // onChange={() =>
                                          //   this.EntityShow(
                                          //     Teams.entityName,
                                          //     Teams.id
                                          //   )
                                          // }
                                          onClick={() => 
                                            this.AllowAllEntity(EName,EId)}
                                        /> &nbsp;&nbsp;
                                        All
                                      </div>
                                      {Entity.map((Teams) => (
                                        <>
                                          <tr id="listhigh">
                                            <td>
                                              <label
                                                style={{ height: "30px" }}
                                              // className="toggle"
                                              >
                                                &nbsp;&nbsp;
                                                <tr>
                                                  <td>
                                                    {Teams.accessed_to ==
                                                      0 ? (
                                                      <>
                                                        <div
                                                          // className="toggle-label"
                                                          style={{
                                                            color: "Black",
                                                          }}
                                                          id="toff"
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            name="tabs1"
                                                            // className="toggle-checkbox"
                                                            onChange={() =>
                                                              this.EntityShow(
                                                                Teams.entityName,
                                                                Teams.id
                                                              )
                                                            }
                                                          /> &nbsp;&nbsp;
                                                          {Teams.entityName}
                                                        </div>
                                                        {/* <input
>>>>>>> c6cd234de1ff34857d2612ce4aac4f1ce9b0fab7
                                                              type="checkbox"
                                                              name="tabs1"
                                                              // className="toggle-checkbox"
                                                              onChange={() =>
                                                                this.EntityShow(
                                                                  Teams.entityName,
                                                                  Teams.id
                                                                )
                                                              }
                                                            /> */}
                                                  &nbsp;&nbsp;
                                                  {/* <div className="toggle-switch" id="toff">
                                                              <p

                                                              id="toggleText"
                                                               
                                                              >
                                                                <b>ON OFF</b>
                                                              </p>
                                                            </div> */}
                                                </>
                                              ) : (
                                                <>
                                                  {Teams.accessed_to ==
                                                    1 &&
                                                    Teams.accessed_From
                                                      .split(",")
                                                      .includes(
                                                        EMaiL
                                                      )
                                                    ? (
                                                      <>

                                                        <input
                                                          type="checkbox"
                                                          name="tabs1"
                                                          // className="toggle-checkbox"
                                                          onChange={() =>
                                                            this.EntityShowOff(
                                                              Teams.entityName,
                                                              Teams.id
                                                            )
                                                          }
                                                          checked={this.state.EntityShowOff === true ? null : 'checked'}
                                                          style={{accentColor:"#7f63efde"}}
                                                        />
                                                        &nbsp;&nbsp;
                                                        {
                                                          Teams.entityName
                                                        }


                                                        {/* <div className="toggle-switch">
                                                                  <p
                                                                    id="toggleText"
                                                                  >
                                                                    <b>
                                                                      ON OFF
                                                                    </b>
                                                                  </p>
                                                                </div> */}
                                                      </>
                                                    ) : (
                                                      <>

                                                        <input
                                                          type="checkbox"
                                                          name="tabs1"
                                                          // className="toggle-checkbox"
                                                          onChange={() =>
                                                            this.EntityShow(
                                                              Teams.entityName,
                                                              Teams.id
                                                            )
                                                          }
                                                          style={{accentColor:"#7f63efde"}}
                                                        />
                                                        &nbsp;&nbsp;
                                                        {
                                                          Teams.entityName
                                                        }


                                                        {/* <div className="toggle-switch">
                                                                  <p
                                                                    style={{
                                                                      fontSize:
                                                                        "11px",
                                                                      marginTop:
                                                                        "11%",
                                                                      marginLeft:
                                                                        "14%",
                                                                    }}
                                                                  >
                                                                    <b>
                                                                      ON OFF
                                                                    </b>
                                                                  </p>
                                                                </div> */}
                                                      </>
                                                    )}
                                                </>
                                              )}
                                            </li>
                                            
                                       </ul>

                                            {/* <center>
                                            {this.state. EntityShow === true ?(<>
                                            <button
                                              type="submit"
                                              className="but"
                                              style={{
                                                height: "39.5px",
                                                width: "86.36px",
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.GetAccessMembers(Teams.entityName,Teams.id)
                                              }}
                                            // onClick={(e) => {
                                            //   e.preventDefault();
                                            //   this.toggleTitlePopupOpen();
                                            //   // this.EditSkillModel();
                                            //   // this.handleBackArrowClick2();
                                            //   // this.Lastgrpchatmodel();
                                            //   //this.formclick();
                                            //   // // console.log("dss", Announcement.firstName);
                                            // }}
                                            >
                                              OK
                                            </button>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <button
                                              type="submit"
                                              className="but"
                                              style={{
                                                height: "39.5px",
                                                width: "86.36px",
                                              }}
                                              onClick={(e) => {
                                                e.preventDefault();
                                                this.GetAccessMembers1(Teams.members_Id)
                                              }}
                                            // onClick={(e) => {
                                            //   e.preventDefault();
                                            //   this.toggleTitlePopupOpen();
                                            //   // this.EditSkillModel();
                                            //   // this.handleBackArrowClick2();
                                            //   // this.Lastgrpchatmodel();
                                            //   //this.formclick();
                                            //   // // console.log("dss", Announcement.firstName);
                                            // }}
                                            >
                                              Apply
                                            </button></>):null}
                                          </center> */}
                                          </>
                                        ))}

                                      </form>}
                                    <br />

                                    {/* {Entity.map((Teams) => ( */}

                                  </>

                                  {/* ))} */}
                                </div>
                                <div id="settingbutton">
                                  {this.state.EntityShow === true ? (
                                    <>
                                      <button
                                        type="submit"
                                        className="but"
                                        style={{
                                          height: "39.5px",
                                          width: "86.36px",
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.GetAccessMembers(
                                            EName,
                                            EId
                                          );
                                        }}
                                      // onClick={(e) => {
                                      //   e.preventDefault();
                                      //   this.toggleTitlePopupOpen();
                                      //   // this.EditSkillModel();
                                      //   // this.handleBackArrowClick2();
                                      //   // this.Lastgrpchatmodel();
                                      //   //this.formclick();
                                      //   // // console.log("dss", Announcement.firstName);
                                      // }}
                                      >
                                        OK
                                      </button>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <button
                                        type="reset"
                                        className="Addmemberpopbtns"
                                        style={{
                                          height: "39.5px",
                                          width: "86.36px",
                                          border: 'none'
                                        }}
                                        onClick={(e) => {
                                          window.location.reload(true);

                                        }}
                                      // onClick={(e) => {
                                      //   e.preventDefault();
                                      //   this.GetAccessMembers1();
                                      // }}
                                      // onClick={(e) => {
                                      //   e.preventDefault();
                                      //   this.toggleTitlePopupOpen();
                                      //   // this.EditSkillModel();
                                      //   // this.handleBackArrowClick2();
                                      //   // this.Lastgrpchatmodel();
                                      //   //this.formclick();
                                      //   // // console.log("dss", Announcement.firstName);
                                      // }}
                                      >
                                        Cancel
                                      </button>
                                    </>
                                  ) : <div id="settingbutton">
                                    {this.state.EntityShowOff === true ? (
                                      <>
                                        <button
                                          type="submit"
                                          className="but"
                                          style={{
                                            height: "39.5px",
                                            width: "86.36px",
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.GetAccessMembersOff(
                                              EName,
                                              EId
                                            );
                                          }}
                                        // onClick={(e) => {
                                        //   e.preventDefault();
                                        //   this.toggleTitlePopupOpen();
                                        //   // this.EditSkillModel();
                                        //   // this.handleBackArrowClick2();
                                        //   // this.Lastgrpchatmodel();
                                        //   //this.formclick();
                                        //   // // console.log("dss", Announcement.firstName);
                                        // }}
                                        >
                                          OK
                                        </button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <button
                                          type="reset"
                                          className="Addmemberpopbtns"
                                          style={{
                                            height: "39.5px",
                                            width: "86.36px",
                                            border: 'none'
                                          }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            window.location.reload(true);
                                            // Turn off the toggle button
                                            const toggleButton = document.getElementById('toff'); // Replace 'toggleButtonId' with the actual id of your toggle button
                                            if (toggleButton) {
                                              toggleButton.checked = false; // Assuming toggle button is an input type="checkbox" or input type="radio"
                                              // If it's a different type of element, you might need to use a different property to turn it off
                                            }
                                          }}
                                        // onClick={(e) => {
                                        //   e.preventDefault();
                                        //   this.GetAccessMembers1();
                                        // }}
                                        // onClick={(e) => {
                                        //   e.preventDefault();
                                        //   this.toggleTitlePopupOpen();
                                        //   // this.EditSkillModel();
                                        //   // this.handleBackArrowClick2();
                                        //   // this.Lastgrpchatmodel();
                                        //   //this.formclick();
                                        //   // // console.log("dss", Announcement.firstName);
                                        // }}
         
                                        >
                                          Cancel
                                        </button>
                                      </>
                                    ) : null}
                                  </div>}
                                </div>
                              </>
                            </form>
                          </div>
                        
                          <center>

                         <div>
                         {Entity.map((Teams) => {
                                  return <>
                                    {(Teams.accessed_to == 0 || !Teams.accessed_From.split(",")
                                      .includes(
                                        EMaiL
                                      )) && Teams.accessed_From != null && Teams.access == 1 && Teams.accessed_For.split(",")
                                        .includes(
                                          entity
                                        ) ?
                                        <div id="EAccessPopA1" style={accesspopstyle} >
                                         <form  style={{ height: "auto" }}>
                                      {Entity.map((Teams) => (
                                        <>
                                         
                                                {(Teams.accessed_to == 0 || !Teams.accessed_From.split(",")
                                                  .includes(
                                                    EMaiL
                                                  )) && Teams.accessed_From != null && Teams.access == 1 && Teams.accessed_For.split(",")
                                                    .includes(
                                                      entity
                                                    ) ? (
                                                  <>
                                                  <i
                                                          className="fa fa-times"
                                                           aria-hidden="true"
                                                           style={{
                                                       color: "gray",
                                                          marginLeft: "90%",
                                                         fontSize: "17px",
                                                          }}
                                                       onClick={this.handleClose}
                                                        ></i>
                                                  <span style={{fontSize:'17px'}}> Wants to give access to this Entity?</span>
                                                  <br />  <br />
                                                  <div
                                                               
                                                                style={{
                                                                  color:
                                                                    "Black",
                                                                }}
                                                              >
                                                                <input
                                                                  type="checkbox"
                                                                  name="tabs1"
                                                                
                                                                  onChange={() =>
                                                                    this.EntityShowPop(
                                                                      Teams.entityName,
                                                                      Teams.id
                                                                    )
                                                                  }
                                                                  style={{width:'15px',height:'15px',accentColor:"#7f63efde"}}
                                                                />
                                                                &nbsp;&nbsp;
                                                                <span style={{fontSize:'16px'}}>{
                                                                  Teams.entityName
                                                                }</span>
                                                              </div>
                                                          
                                                  
                                                  </>
                                                ) : null}
          
                                        </>
                                      ))}

                                    </form>   <br />  <br /> 
                                    <div style={{marginBottom:'5%'}}>
                                              <span
                                                id="GroupopYesbtn"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.GetAccessMembers(
                                                    EName,
                                                    EId
                                                  );
                                                }}
                                              >
                                                Yes
                                              </span>{" "}
                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                              <span
                                                id='GroupopNobtn'
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  this.GetAccessMembersDenied(
                                                    EName,
                                                    EId
                                                  );
                                                }}
                                              >
                                                No

                                              </span>
                                              <br /></div>
                                    
                                      </div>
                                    : null}
                                  </>
                                })}
                         </div>
                       
                         
                                              
                                              </center>
                       
                          {/* </div> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>


    );
  }
}
